import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { DataGrid, SearchItem, SearchPanel, ComboBox, ProgressPanel, DatePicker, DataColumn, PaperTitle } from '../../component';
import { callProc } from '../../common/DBConnector';
import { makeStyles, Paper, TextField, IconButton, Tooltip } from '@material-ui/core';
import { useFieldInputs } from '../../common/Utils';
import CheckIcon from '@material-ui/icons/Check';

const inType = {
    '0' : '전체',
    '1' : '입고대기',
    '2' : '입고완료',
}

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    transOut: {
        flex: 2,
        marginRight: theme.spacing(1),
    },
    transIn: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginLeft: theme.spacing(1),
    },
    transInEnroll: {
        height: '50%',
        marginBottom: theme.spacing(1),
    },
    transInInfo: {
        height: '50%',
        marginTop: theme.spacing(1),
    },
    paperContent: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: theme.spacing(2),
        paddingTop: 0,
    },
    inputField: {
        display: 'block',
    },
    chkButton: {
        alignSelf: 'flex-end',
        marginTop: 'auto',
    },
}))

const TankTransEnroll = () => {
    const [condition, setCondition] = useState(null);

    const [selectedRow, setSelectedRow] = useState();

    const [workData, setWorkData] = useState();
    const [gdsData, setGdsData] = useState();
    const [saleType, setSaleType] = useState();

    const nzlData = useRef();
    const [tnkData, setTnkData] = useState();

    const [inData, setInData] = useFieldInputs({
        IN_QTY : '',
        TNK_NO : ''
    });

    useEffect(() => {
        const fetchData = async () => {
            const workData = await callProc('SLT_WORKPLACEINFO_OIL_AUTH');
            const gdsData = await callProc('SLT_GOODSINFO');
            const saleType = await callProc('SLT_SALETYPE_TRANS');

            setWorkData(workData.data.reduce((result, item) => ({
                ...result,
                [item['WORK_CD']]: item['WORK_NM']
            }), {}));

            setGdsData(gdsData.data.reduce((result, item) => ({
                ...result,
                [item['GDS_CD']] : item['GDS_NM']
            }), {}));

            setSaleType(saleType.data.reduce((result, item) => ({
                ...result,
                [item['SALE_TYPE']]: item['SALE_TYPE_NM']
            }), {}));
        }
        fetchData();
    }, [])

    const fetchNzlData = async(row) => {
        const data = (await callProc('SLT_NOZZLEINFO_DATE', row))
            .data.reduce((result, item) => ({
                ...result,
                [item['NZL_NO']] : item['NZL_NO']
            }), {});

        nzlData.current = data;
    }

    const fetchTnkData = async(row) => {
        if (isEnrollable(row)) {
            setTnkData(null);
            const data = (await callProc('SLT_TANKINFO_DATE', row))
                .data.reduce((result, item) => ({
                    ...result,
                    [item['TNK_NO']] : item['TNK_NO']
                }), {});

            setTnkData(data);
            setInData('TNK_NO', Object.keys(data)[0]);
        } else {
            setInData('TNK_NO', '');
        }
    }

    const classes = useStyles();

    const onRowSelect = (row) => {
        setSelectedRow(row);
        setInData('IN_QTY', isEnrollable(row) ? row['WAIT_QTY'] : '');
        fetchTnkData(row);
        
    }

    const onInQtyChanged = (e) => {
        const value = e.target.value;

        selectedRow && selectedRow['WAIT_QTY'] < value ?
        setInData('IN_QTY', selectedRow['WAIT_QTY']) :
        setInData('IN_QTY', value);
    }

    const onTransIn = async() => {
        await callProc('INS_TANKTRANSININFO', { ...selectedRow, ...inData });
        setCondition({ ...condition });
    }

    const onTransInDelete = async(row) => {
        await callProc('DLT_TANKTRANSININFO', row);
        setCondition({ ...condition });
        setSelectedRow(null);
    }

    const isEnrollable = (row) => row != null && row['WAIT_QTY'] > 0

    return(
        (!workData || !gdsData || !saleType) ? <ProgressPanel/> :
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => setCondition(condition)}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={Object.keys(workData)[0]}
                    component={(state, dispatch) => (
                        <ComboBox
                            label='사업장명'
                            data={workData}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='IN_TYPE'
                    defaultValue={Object.keys(inType)[1]}
                    component={(state, dispatch) => (
                        <ComboBox
                            label='입고구분'
                            data={inType}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
            </SearchPanel>
            
            <div className={classes.content}>
                <div className={classes.transOut}>
                    <DataGrid 
                        title='출고 등록'
                        table='TB_TANKTRANSOUTINFO'
                        selectProc='SLT_TANKTRANSOUTINFO'
                        insertProc='INS_TANKTRANSOUTINFO'
                        updateProc='UPT_TANKTRANSOUTINFO'
                        deleteProc='DLT_TANKTRANSOUTINFO'
                        selectParam={condition}
                        onSelect={onRowSelect}
                    >
                        <DataColumn
                            fieldName='TNK_TRANS_CD'
                            visible = {false}
                        /> 
                        <DataColumn
                            fieldName='WORK_CD' 
                            title='사업장명'
                            value={(row, value) => workData[value]}
                            defaultValue={Object.keys(workData)[0]}
                            editable={(state, dispatch, rowState, setField) => (
                                <ComboBox
                                    data={workData}
                                    value={state}
                                    onChange={async(value) => {
                                        nzlData.current = null;
                                        dispatch(value);
                                        await fetchNzlData({ ...rowState, 'WORK_CD': value });
                                        setField('NZL_NO', Object.keys(nzlData.current)[0]);
                                    }}
                                />
                            )}
                        />
                        <DataColumn
                            fieldName='BIZ_DATE'
                            defaultValue={moment().format('YYYY-MM-DD')}
                            editable={(state, dispatch, rowState, setField) => (
                                <DatePicker
                                    value={state}
                                    onChange={async(value) => {
                                        nzlData.current = null;
                                        dispatch(value);
                                        await fetchNzlData({ ...rowState, 'BIZ_DATE': value });
                                        setField('NZL_NO', Object.keys(nzlData.current)[0]);
                                    }}
                                />
                            )}
                        />
                       <DataColumn
                            fieldName='GDS_CD'
                            title='상품명'
                            value={(row, value) => gdsData[value]}
                            defaultValue={Object.keys(gdsData)[0]}
                            editable={(state, dispatch, rowState, setField) => (
                                <ComboBox
                                    data={gdsData}
                                    value={state}
                                    onChange={async(value) => {
                                        nzlData.current = null;
                                        dispatch(value);
                                        await fetchNzlData({ ...rowState, 'GDS_CD': value });
                                        setField('NZL_NO', Object.keys(nzlData.current)[0]);
                                    }}
                                />
                            )}
                        />
                        <DataColumn
                            fieldName='NZL_NO'
                            defaultValue={''}
                            editable={(state, dispatch, rowState, setField, rowIdx) => (
                                rowIdx !== -1 ?
                                state : (
                                    !nzlData.current ?
                                    <ProgressPanel small /> :
                                    <ComboBox
                                        data={nzlData.current}
                                        value={state}
                                        onChange={(value) => dispatch(value)}
                                    />
                                )
                            )}
                        />
                        <DataColumn
                            fieldName='QTY'
                            defaultValue='0'
                            editable={(state, dispatch, rowState, setField, rowIdx) => (
                                rowIdx !== -1 ?
                                state :
                                <TextField
                                    value={state}
                                    onChange={(e) => dispatch(e.target.value)}
                                    InputProps={{ style: { width: 80, alignSelf: 'flex-end' } }}
                                    inputProps={{ style: { textAlign: 'right' } }}
                                    fullWidth
                                />
                            )}
                        />
                        <DataColumn
                            fieldName='WAIT_QTY'
                            title='입고가능수량'
                            editable={state => state}
                        />
                        <DataColumn
                            fieldName='SALE_TYPE'
                            title='판매유형'
                            value={(row,value) => saleType[value]}
                            defaultValue={Object.keys(saleType)[0]}
                            editable={(state, dispatch) => 
                                <ComboBox
                                    data={saleType}
                                    value={state}
                                    onChange={(value) => dispatch(value)}
                                />
                            }
                        />
                    </DataGrid>
                </div>
                <div className={classes.transIn}>
                    <div className={classes.transInEnroll}>
                        <Paper className={classes.paper}>
                            <PaperTitle>
                                재입고 등록
                            </PaperTitle>
                            <div className={classes.paperContent}>
                                <TextField
                                    label='입고가능수량'
                                    className={classes.inputField}
                                    value={selectedRow ? selectedRow['WAIT_QTY'] : ''}
                                    disabled
                                />
                                <TextField
                                    label='입고수량'
                                    className={classes.inputField}
                                    value={inData['IN_QTY']}
                                    onChange={onInQtyChanged}
                                    disabled={!isEnrollable(selectedRow)}
                                />
                                <div style={{ width: 168 }}>
                                    {tnkData === null ?
                                    <ProgressPanel small /> :    
                                    <ComboBox
                                        label='탱크번호'
                                        className={classes.inputField}
                                        data={tnkData}
                                        value={inData['TNK_NO']}
                                        onChange={(value) => setInData('TNK_NO', value)}
                                        disabled={!isEnrollable(selectedRow)}
                                        fullWidth
                                    />
                                    }
                                </div>
                                <Tooltip title='확인'>
                                <IconButton
                                    className={classes.chkButton}
                                    component='div'
                                    onClick={onTransIn}
                                    disabled={!isEnrollable(selectedRow)}
                                >
                                    <CheckIcon />
                                </IconButton>
                                </Tooltip>
                            </div>
                        </Paper>
                    </div>
                    <div className={classes.transInInfo}>
                        <DataGrid
                            title='입고 내역'
                            selectProc='SLT_TANKTRANSININFO_OUT'
                            selectParam={selectedRow}
                            onDelete={onTransInDelete}
                        >
                            <DataColumn
                                fieldName='SEQ'
                                visible={false}
                            />
                            <DataColumn
                                fieldName='TNK_TRANS_CD'
                                visible={false}
                            />
                            <DataColumn
                                fieldName='WORK_CD'
                                visible={false}
                            />
                        </DataGrid>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TankTransEnroll