import React, { Fragment, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import {  makeStyles } from '@material-ui/core';
import {ComboBox, DatePicker, DataGrid, DataColumn, Tabs, Tab, SearchItem, SearchPanel, TextField } from '../../component';
import moment from 'moment';
import { callProc } from '../../common/DBConnector';

const posTypes = {
    0: '스마트로',
    1: 'GS포스',
}

const posSaleTypesCombo = [
    { key: '01', value: '현금' },
    { key: '02', value: '외상' },
    { key: '03', value: '용역보관출고' },
    { key: '04', value: '매출보관(입고)' },
    { key: '05', value: '매출보관출고' },
    { key: '06', value: '이관출고' },
    { key: '07', value: '보관증출고' },
    { key: '08', value: '자가소비' },
    { key: '09', value: '검량출고' },
    { key: '10', value: '홈로리이관출고' },
    { key: '11', value: '홈로리판매' },
    { key: '12', value: '홈로리재입고' },
    { key: '13', value: '검량재입고' },
]



const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflow: 'auto',
    },
    content: {
        flex: 1,
        height: 0,
    },
    tabbar: {
        marginBottom: theme.spacing(1),
    },
    hide: {
        display: 'none'
    },
    searchItem: {
        display: 'inline-block',
        width: 200,
        marginRight: 100,
        marginBottom: theme.spacing(1),
    },
}))

const PosOriginListSaleType = () =>{
    const [condition, setCondition] = useState();

    const [slHeaderSmartroData, setSlHeaderSmartroData] = useState(); 
    const [slHeaderGsposData, setSlHeaderGsposData] = useState(); 

    const classes = useStyles();


    useEffect(() => {
        const fetchData = async () => {
            if(condition && condition['POS_TYPE'] === '0') {
                // 스마트로
                setSlHeaderSmartroData(null)
                setSlHeaderSmartroData(await callProc('SLT_SLHEADER_SMARTRO_SALETYPE', condition))

            } else if(condition && condition['POS_TYPE'] === '1'){
                // GS포스
                setSlHeaderGsposData(null)
                setSlHeaderGsposData(await callProc('SLT_SLHEADER_GSPOS_SALETYPE', condition))

            } 
            
        }
        condition && fetchData();
    }, [condition])


    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => setCondition(condition)}>
                <SearchItem
                    fieldName='WORK_CD'
                    component={(state, setState) => (
                        <ComboBox
                            label='사업장명'
                            selectProc='SLT_WORKPLACEINFO_OIL_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='POS_TYPE'
                    defaultValue={Object.keys(posTypes)[0]}
                    component={(state, setState) => (
                        <ComboBox
                            label='POS'
                            data={posTypes}
                            value={state}
                            onChange={(value) => {
                                setState(value);
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='SALETYPE_CD'
                    defaultValue='ALL'
                    component={(state, dispatch) => (
                        <ComboBox
                            label='판매유형'
                            data={posSaleTypesCombo}
                            keyField='key'
                            valueField='value'
                            value={state}
                            onChange={(value) => dispatch(value)}
                            onDataLoad={(jsonData, rawData) => [{ key: 'ALL', value: '전체' }, ...rawData]}
                        />
                    )}
                />
                <SearchItem
                    fieldName='CAR_NO'
                    defaultValue=''
                    component={(state, dispatch) => (
                        <TextField
                            label='차량번호'
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
            </SearchPanel>
            
            <TapSecBar 
                condition={condition} 
                slHeaderSmartroData={slHeaderSmartroData}
                slHeaderGsposData={slHeaderGsposData}
            />
            
        </div>
    )
}

const TapSecBar = ({condition, 
        slHeaderSmartroData, 
        slHeaderGsposData, 
    }) => {
    const [selectTab, setSelectTab] = useState(0);

    const classes = useStyles();

    useEffect(() => {
        if(condition && condition['POS_TYPE'] === '0') {
            setSelectTab(0)
        } else if(condition && condition['POS_TYPE'] === '1'){
            setSelectTab(1)
        } 
    }, [condition])

    return (
        <Fragment>
            <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                <Tab label='t_sal_slheader_smartro' className={(condition && condition['POS_TYPE'] !== '0') && classes.hide}/>
                <Tab label='t_sal_slheader_gspos' className={(condition && condition['POS_TYPE'] !== '1') && classes.hide}/>

            </Tabs>
            {/* 스마트로 */}
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <SlHeaderSmartro slHeaderSmartroData={slHeaderSmartroData} />
            </div>
            {/* GS포스 */}
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <SlHeaderGspos slHeaderGsposData={slHeaderGsposData} />
            </div>
        </Fragment>
    )
}

const SlHeaderSmartro = ({ slHeaderSmartroData }) => {

    const grid = useMemo(() => (
        <DataGrid
            title='t_sal_slheader_smartro'
            dataSet={slHeaderSmartroData}
            horizonMode
            virtualized
            stickyHeader
            excelDownload={{ fileName: 't_sal_slheader_smartro.xlsx', sheetName: 't_sal_slheader_smartro' }}
        >
            <DataColumn fieldName='WORK_NM' title='사업장명' />
            <DataColumn fieldName='SALESLIP_NO' title='SALESLIP_NO' />
            <DataColumn fieldName='BIZHOUR_DATE' title='BIZHOUR_DATE' />
            <DataColumn fieldName='SALETYPE_CD' title='SALETYPE_CD' />
            <DataColumn fieldName='GOODS_CD' title='GOODS_CD' />
            <DataColumn fieldName='PYMT_QTY' title='PYMT_QTY' />
            <DataColumn fieldName='PYMT_AMT' title='PYMT_AMT' />

        </DataGrid>
    ), [slHeaderSmartroData]);

    return grid;

}

const SlHeaderGspos = ({ slHeaderGsposData }) => {

    const grid = useMemo(() => (
        <DataGrid
            title='t_sal_slheader_gspos'
            dataSet={slHeaderGsposData}
            horizonMode
            virtualized
            stickyHeader
            excelDownload={{ fileName: 't_sal_slheader_gspos.xlsx', sheetName: 't_sal_slheader_gspos' }}
        >
            <DataColumn fieldName='WORK_NM' title='사업장명' />
            <DataColumn fieldName='SALESLIP_NO' title='SALESLIP_NO' />
            <DataColumn fieldName='BIZHOUR_DATE' title='BIZHOUR_DATE' />
            <DataColumn fieldName='SALETYPE_CD' title='SALETYPE_CD' />
            <DataColumn fieldName='GOODS_CD' title='GOODS_CD' />
            <DataColumn fieldName='CAR_NO' title='CAR_NO' />
            <DataColumn fieldName='PYMT_QTY' title='PYMT_QTY' />
            <DataColumn fieldName='PYMT_AMT' title='PYMT_AMT' />

        </DataGrid>
    ), [slHeaderGsposData]);

    return grid;
}


export default PosOriginListSaleType

