import React from 'react';
import clsx from 'clsx';
import { makeStyles, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    progress: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        paddingTop: 20,
        minHeight: 100,
    },
    smallContainer: {
        paddingTop: 0,
        minHeight: 0,
    }
}))

const ProgressPanel = ({ small, className }) => {
    const classes = useStyles();
    
    return (
        <div className={clsx(classes.progress, {
            [classes.smallContainer]: small
        }, className)}>
            <CircularProgress size={small ? 25 : 40} />
        </div>
    )
}

export default ProgressPanel;