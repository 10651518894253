import React from 'react';
import { Document, Link, StyleSheet, Text, View} from '@react-pdf/renderer';
import { PdfCell, PdfTable, PdfRow, PdfPage } from '../component';
import { cstType, trdStnd, trdType } from '../common/Dictionary';

const titleBackColor = '#EFEFEF';

const styles = StyleSheet.create({
    titleSort : {
        alignSelf: 'left',
        fontSize : 12,
        marginBottom : 0,
    },
    docContent : {
        display: 'flex',
        flexDirection: 'row',
        fontSize: 9,
        marginTop: 8,
        justifyContent: 'space-between',
    },
    subTitle : {
        fontSize: 10,
        marginTop: 8,
    },
    tableContent : {
        marginTop: 8,
        border: 1,
    },
    headerCell: {
        fontSize: 10,
        height: 20,
        justifyContent: 'center',
        // borderRight: 0,
        border: 0,
        backgroundColor: titleBackColor,
    },
    contentCell: {
        fontSize: 9,
        height: 20,
        justifyContent: 'center',
        // borderRight: 0,
        border: 0,
    },
    remarkTitle: {
        height: 20, 
        // border: 1,
        justifyContent: 'center',
        backgroundColor: titleBackColor,
    },
    remarkContainer: {
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
        border: 0,
    },
    remarkContent : {
        marginBottom: 4,
        fontSize: 9,
    },
    proDataHeaderCenter: { //processData
        display: 'flex', 
        flex: 'none', 
        fontSize: 7.5,
        height: 15,
        justifyContent: 'center',
        // borderRight: 0,
        border: 0,
        backgroundColor: titleBackColor,
    },
    proDataHeaderLeft: { //processData
        display: 'flex', 
        flex: 'none', 
        fontSize: 7.5,
        height: 15,
        justifyContent: 'center',
        textAlign: 'left',
        // borderRight: 0,
        border: 0,
        backgroundColor: titleBackColor,
    },
    proDataHeaderRight: { //processData
        display: 'flex', 
        flex: 'none', 
        fontSize: 7.5,
        height: 15,
        justifyContent: 'center',
        textAlign: 'right',
        // borderRight: 0,
        border: 0,
        backgroundColor: titleBackColor,
    },
    proDataContentCellCenter: {
        display: 'flex', 
        flex: 'none', 
        fontSize: 7.5,
        paddingTop: 3,
        paddingBottom: 3,
        // height: 15,
        justifyContent: 'center',
        // borderRight: 0,
        border: 0,
    },
    proDataContentCellLeft: {
        display: 'flex', 
        flex: 'none', 
        fontSize: 7.5,
        paddingTop: 3,
        paddingBottom: 3,
        // height: 15,
        justifyContent: 'center',
        textAlign: 'left',
        // borderRight: 0,
        border: 0,
    },
    proDataContentCellRight: {
        display: 'flex', 
        flex: 'none', 
        fontSize: 7.5,
        paddingTop: 3,
        paddingBottom: 3,
        // height: 15,
        justifyContent: 'center',
        textAlign: 'right',
        // borderRight: 0,
        border: 0,
    },
    pageNumber: {
        position: 'absolute',
        bottom: 15,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
    fileContainer: {
        flexDirection: 'row',
        marginTop: 16,
    },
    fileLink: {
        marginLeft: 4,
        marginBottom: 8,
    },
})

const EdocumentOrderPdf = ({ data, lineData, remarkData, fileData, fileKey}) => {
    return (
        <Document title={`전자결재_${data['REGDATE'].replace(/-/g, '')}_${data['DOC_CD']}`}>
            <PdfPage>
                <View style={styles.titleSort}>
                    <Text>{`${data['DOC_TITLE']}`}</Text>
                    <View style={styles.docContent}>
                        <Text>{`작성자 : ${data['DEP_NM'] || ''} / ${data['EMP_NM'] || ''} ${data['POSITION'] || ''} ${data['REGDTTM']}`}</Text>
                        <Text>{`문서번호 : ${data['DOC_CD']}`}</Text>
                    </View>
                </View>
                <View style={styles.subTitle}>
                    <Text>{`[결재 정보]`}</Text>
                </View>
                <View style={styles.tableContent}>
                    <PdfTable>
                        <PdfRow>
                            <PdfCell cellTop cellLeft style={{...styles.headerCell}}>분류</PdfCell>
                            <PdfCell cellTop style={{...styles.headerCell}}>부서</PdfCell>
                            <PdfCell cellTop style={{...styles.headerCell}}>성명</PdfCell>
                            <PdfCell cellTop style={{...styles.headerCell}}>상태</PdfCell>
                            <PdfCell cellTop style={{...styles.headerCell, borderRight: 1}}>결재일시</PdfCell>
                        </PdfRow>
                        {lineData && lineData.map((item, idx) => (
                            <PdfRow key={idx}>
                                <PdfCell cellLeft style={{ ...styles.contentCell }}>{item['LINE_TYPE']}</PdfCell>
                                <PdfCell style={{ ...styles.contentCell }}>{item['DEP_NM']}</PdfCell>
                                <PdfCell style={{ ...styles.contentCell }}>{item['EMP_NM']}</PdfCell>
                                <PdfCell style={{ ...styles.contentCell }}>{item['CONFM_TYPE']}</PdfCell>
                                <PdfCell style={{ ...styles.contentCell}}>{item['PRO_DTTM']}</PdfCell>
                            </PdfRow>
                        ))}
                    </PdfTable>
                </View>
                <View style={styles.subTitle}>
                    <Text>{`[참고 사항]`}</Text>
                </View>
                <View style={styles.tableContent}>
                    <View style={styles.remarkTitle}>
                        <Text style={{marginLeft: 16, fontSize: 10}}>내용</Text>
                    </View>
                    <View style={styles.remarkContainer}>
                        {!remarkData.length ?
                        <Text style={styles.remarkContent}>내용 없음</Text> :
                        remarkData.map(item => (
                        <View key={item['SEQ']}>
                                <Text style={styles.remarkContent}>
                                    {`${item['DEP_NM']} / ${item['POSITION']} / ${item['EMP_NM']} / ${item['EDTDATE']}`}
                                </Text>
                                <Text style={styles.remarkContent}>
                                    {`- ${item['REMARK']}`}
                                </Text>
                            </View>
                        ))}
                    </View>
                </View>
                <View style={{marginTop: 16, height: 20, fontSize: 15, alignSelf: 'center', justifyContent: 'center'}}>
                    <Text>기안 문서 내용</Text>
                </View>
                <View style={styles.subTitle}>
                    <Text>{`[문서 내용]`}</Text>
                </View>
                <View style={styles.tableContent}>
                    <View style={styles.remarkTitle}>
                        <Text style={{marginLeft: 16, fontSize: 10}}>내용</Text>
                    </View>
                    <View style={styles.remarkContainer}>
                        <Text style={styles.remarkContent}>사업장명: {data['WORK_NM']}</Text>
                        <Text style={styles.remarkContent}>거래처명: {data['CST_NM']}</Text>
                        <Text style={styles.remarkContent}>거래타입: {cstType[data['CST_TYPE']]}</Text>
                        <Text style={styles.remarkContent}>사업자등록번호: {data['WORK_BIZNO']}</Text>
                        <Text style={styles.remarkContent}>대표자: {data['CEO']}</Text>
                        <Text style={styles.remarkContent}>업태: {data['BIZ_COND']}</Text>
                        <Text style={styles.remarkContent}>종목: {data['BIZ_ITEM']}</Text>
                        <Text style={styles.remarkContent}>이메일: {data['EMAIL']}</Text>
                        <Text style={styles.remarkContent}>이메일2: {data['EMAIL2']}</Text>
                        <Text style={styles.remarkContent}>주소: {data['ADDR']}</Text>
                        <Text style={styles.remarkContent}>상세주소: {data['ADDR_DET']}</Text>
                        <Text style={styles.remarkContent}>전화번호1: {data['TELNO1']}</Text>
                        <Text style={styles.remarkContent}>전화번호2: {data['TELNO2']}</Text>
                        <Text style={styles.remarkContent}>시작일: {data['STA_DATE']}</Text>
                        <Text style={styles.remarkContent}>거래유형: {trdType[data['TRD_TYPE']]}</Text>
                        <Text style={styles.remarkContent}>거래기준: {trdStnd[data['TRD_STND']]}</Text>
                        <Text style={styles.remarkContent}>단위기준: {data['TRD_STND_USE']}</Text>
                        <Text style={styles.remarkContent}>거래기준일: {data['TRD_STND_DAY']}</Text>
                        <Text style={styles.remarkContent}>여신한도: {data['CREDIT_LIMIT']}</Text>
                    </View>
                </View>
                {fileData.length > 0 &&
                <View style={styles.fileContainer}>
                    <Text>
                        [첨부 파일]
                    </Text>
                    <View>
                        {fileData.map(item =>
                            <Link
                                key={item.seq}
                                style={styles.fileLink}
                                src={`${document.URL}api/file_down?fileKey=${fileKey}&seq=${item.seq}`}
                            >
                                {item.name}
                            </Link>
                        )}
                    </View>
                </View>}
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </PdfPage>
        </Document>
    )
}

export default EdocumentOrderPdf;