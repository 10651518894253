import { Dialog, DialogTitle, makeStyles } from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { IconButton, TextField } from '.';

const useDialogStyles = makeStyles(theme => ({
    dialogPaper: {
        width: 400,
        height: 300,
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    field: {
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    btnContainer: {
        display: 'flex',
        alignSelf: 'flex-end',
        marginTop: 'auto',
        padding: theme.spacing(2),
    }
}))

const MailDialog = ({ data, open, onClose, onSend }) => {
    const [mailAddr1, setMailAddr1] = useState('');
    const [mailAddr2, setMailAddr2] = useState('');
    
    const classes = useDialogStyles();
    
    const getValue = value => value.trim() === '' ? null : value

    useEffect(() => {
        setMailAddr1(data ? data['EMAIL'] || '' : '');
        setMailAddr2(data ? data['EMAIL2'] || '' : '');
    }, [data]);

    const reqSend = () => {
        onSend(getValue(mailAddr1), getValue(mailAddr2));
        onClose();
    }

    const onFieldKey = e => {
        if (e.key === 'Enter') {
            reqSend();
        }
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{ className: classes.dialogPaper }}
        >
            <div className={classes.dialogTitle}>
                <DialogTitle>이메일 발송</DialogTitle>
                <IconButton
                    tooltip='닫기'
                    onClick={() => onClose()}
                    icon={<Close />}
                />
            </div>
            <TextField
                label='이메일1'
                className={classes.field}
                value={mailAddr1}
                maxLength={50}
                onChange={value => setMailAddr1(value)}
                onKeyPress={onFieldKey}
            />
            <TextField
                label='이메일2'
                className={classes.field}
                value={mailAddr2}
                maxLength={50}
                onChange={value => setMailAddr2(value)}
                onKeyPress={onFieldKey}
            />
            <div className={classes.btnContainer}>
                <IconButton
                    tooltip='전송'
                    icon={<Check />}
                    onClick={reqSend}
                />
            </div>
        </Dialog>
    )
}

export default MailDialog;