import React, { useRef, useState } from 'react';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DataColumn, ComboSearch, NumberField, DatePicker, TextField } from '../../component';
import { makeStyles } from '@material-ui/core';
import { isFieldDiff } from '../../common/Utils';
import { callProc } from '../../common/DBConnector';

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    buttonField: {
        display: 'flex',
        alignItems: 'center',
    },
}))

const BasicAccountEnroll = () => {
    const [condition, setCondition] = useState(null);
    const storedRowData = useRef();
    const classes = useStyles();

    return(
    <div className={classes.container}>
        <SearchPanel
            onSearch={(condition) => {
                setCondition(condition);
            }}
        >
            <SearchItem
                fieldName='WORK_CD'
                component={(state, dispatch) => (
                    <ComboBox
                        label='사업장명'
                        selectProc='SLT_WORKPLACEINFO_AUTH'
                        keyField='WORK_CD'
                        valueField='WORK_NM'
                        value={state}
                        onChange={(value) => dispatch(value)}
                    />
                )}
            />
        </SearchPanel>
        <DataGrid
            title='계정과목 기초데이터'
            selectProc='SLT_BASIC_ACC'
            insertProc='INS_BASIC_ACC'
            updateProc='UPT_BASIC_ACC'
            deleteProc='DLT_BASIC_ACC'
            onInsert={async(row) => {
                const result = await callProc('INS_BASIC_ACC', row);
                if (result.err) {
                    return false;
                }
                storedRowData.current = { 
                    ACJ_DATE: row['ACJ_DATE'],  
                    ACC_CD: row['ACC_CD'],
                    CST_CD: row['CST_CD'] 
                };
            }}
            onInsertStart={(row) => ({ ...row, ...storedRowData.current })}
            selectParam={condition}
            horizonMode
            keys={['ACJ_CD', 'ACJ_DATE']}
        >
            <DataColumn
                fieldName='ACJ_DATE'
                defaultValue={storedRowData['ACJ_DATE']}
                editable={(state, dispatch) => (
                    <DatePicker
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />
            <DataColumn
                fieldName='WORK_CD'
                value={row => row['WORK_NM']}
                defaultValue={condition && condition['WORK_CD']}
                editable={(state, dispatch) => (
                    <ComboBox
                        selectProc='SLT_WORKPLACEINFO_AUTH'
                        keyField='WORK_CD'
                        valueField='WORK_NM'
                        value={state}
                        onChange={value => dispatch(value)}
                        disabled
                    />
                )}
            />
            <DataColumn
                fieldName='ACC_CD'
                value={row => row['ACC_NM']}
                defaultValue={storedRowData['ACC_CD']}
                editable={(state, dispatch) => (
                    <ComboSearch
                        style={{width: 200}}
                        selectProc='SLT_ACCOUNT_ALL'
                        keyField='ACC_CD'
                        valueField='ACC_NM'
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />
            <DataColumn
                fieldName='CST_CD'
                title='거래처명'
                value={row => row['CST_NM']}
                defaultValue={storedRowData['CST_CD']}
                editable={(state, dispatch, rowState, setField) => {
                    let param = rowState['CST_PARAM'] || null;
                    if (isFieldDiff(['WORK_CD'], rowState, param)) {
                        param = { WORK_CD: rowState['WORK_CD']};
                        setField('CST_PARAM', param);
                    }
                    return (
                        <ComboSearch
                            style={{width: 200}}
                            selectProc='SLT_CUSTOMERINFO_ALL'
                            selectParam={param}
                            keyField='CST_CD'
                            valueField='CST_NM'
                            value={state}
                            onChange={value => dispatch(value)}
                            nullable
                        />
                    )
                }}
            />
            <DataColumn
                fieldName='GDS_CD'
                title='상품명'
                value={row => row['GDS_NM']}
                defaultValue={storedRowData['GDS_CD']}
                editable={(state, dispatch, rowState, setField) => {
                    let param = rowState['GDS_PARAM'] || null;
                    if (isFieldDiff(['WORK_CD'], rowState, param)) {
                        param = { WORK_CD: rowState['WORK_CD']};
                        setField('GDS_PARAM', param);
                    }
                    return (
                        <ComboSearch
                            style={{width: 200}}
                            selectProc='SLT_GOODSINFO_GAUGE_ITEM_SERACHPANEL'
                            selectParam={param}
                            keyField='GDS_CD'
                            valueField='GDS_NM'
                            value={state}
                            onChange={value => dispatch(value)}
                            nullable
                        />
                    )
                }}
            />
            <DataColumn
                fieldName='DEBTOR'
                editable={(state, dispatch) => (
                    <NumberField
                        style={{width: 150}}
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />
            <DataColumn
                fieldName='CREDITOR'
                editable={(state, dispatch) => (
                    <NumberField
                        style={{width: 150}}
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />
            <DataColumn
                fieldName='SUMUP'
                editable={(state, dispatch) => (
                    <TextField
                        style={{width: 200}}
                        value={state}
                        onChange={value => dispatch(value)}
                        maxLength={100}
                    />
                )}
            />
            <DataColumn
                fieldName='WORK_NM'
                visible={false}
            />
            <DataColumn
                fieldName='ACC_NM'
                visible={false}
            />
            <DataColumn
                fieldName='CST_NM'
                visible={false}
            />
            <DataColumn
                fieldName='GDS_NM'
                visible={false}
            />
        </DataGrid>
    </div>
    )
}

export default BasicAccountEnroll