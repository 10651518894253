import React, { useState, useEffect, useMemo } from 'react';
import { DataGrid, ComboBox, PaperTitle, DatePicker, DataColumn, NumberField, IconButton } from '../../component';
import { callProc } from '../../common/DBConnector';
import {  makeStyles, Paper, TextField } from '@material-ui/core';
import { useFieldInputs } from '../../common/Utils';
import CheckIcon from '@material-ui/icons/Check';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
        flex: 2,
        marginRight: theme.spacing(1),
    },
    enroll: {
        flex: 1,
        marginRight: theme.spacing(1),
    },
    paperContent: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding: theme.spacing(2),
        paddingTop: 0
    },
    field: {
        width: 180,
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2),
    },
    iconbutton : {
        alignSelf: 'flex-end',
        marginTop: theme.spacing(4),
        marginRight: theme.spacing(1),
    },
    searchPaper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    searchContainer: {
        flex: 1,
        display: 'inline-block',
        flexDirection: 'row',
    },
    searchItem: {
        display: 'inline-block',
        width: 200,
        marginRight: 100,
        marginBottom: theme.spacing(1),
    },
}))

const ItemInOutEnroll = () => {
    const classes = useStyles();
    const clsState = useSelector(state => state.clsDateReducer);
    const [input, setInput] = useFieldInputs({
        WORK_CD: sessionStorage['WORK_CD'],
        BIZ_DATE: clsState[sessionStorage['WORK_CD']],
    });
    const [selectedRow, setSelectedRow] = useState();

    
    const condition = useMemo(() => input['WORK_CD'] && input['BIZ_DATE'] && input, [input]);

    const gridRefresh = () => {
        setInput({ type: 'UPDATE', value: { ...input } });
    }

    return(
        <div className={classes.container}>
            <Paper className={classes.searchPaper}>
                <div className={classes.searchContainer}>
                    <div className={classes.searchItem}>
                        <ComboBox
                            // style={{width: 200}}
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={condition && condition['WORK_CD']}
                            onChange={value => {
                                if (input['WORK_CD'] !== value) {
                                    sessionStorage['WORK_CD'] = value;
                                    setInput('BIZ_DATE', clsState[value]);
                                    setInput('WORK_CD', value);
                                }
                            }}
                        />
                    </div>
                    <div className={classes.searchItem}>
                        <DatePicker
                            label='영업일'
                            minDate={clsState[input['WORK_CD']]}
                            value={condition && condition['BIZ_DATE']}
                            onChange={value => {
                                if (input['BIZ_DATE'] !== value) {
                                    setInput('BIZ_DATE', value);
                                    if(condition && condition['BIZ_DATE'] < clsState[condition['WORK_CD']]) {
                                        setInput({ type: 'UPDATE', value: { ...input, 'BIZ_DATE': value } });
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                <IconButton
                    tooltip=''
                    icon={<SearchIcon />}
                    onClick={gridRefresh}
                />
            </Paper>
            <div className={classes.content}>
                <DataGrid
                    className={classes.info}
                    title='일반상품 입출고'
                    selectProc='SLT_ITEMINOUT'
                    selectParam={condition}
                    onSelect={row => setSelectedRow(row)}
                    selectionMode='single'
                >
                    <DataColumn fieldName='GDS_CD' visible={false} />
                    <DataColumn fieldName='BEF_STOCK_QTY' title='전일재고' />
                    <DataColumn fieldName='CUR_IN_QTY' title='당일입고' />
                    <DataColumn fieldName='CUR_RTN_QTY' title='당일반환입고' />
                    <DataColumn fieldName='CUR_OUT_QTY' title='당일출고' />
                    <DataColumn fieldName='CUR_DST_QTY' title='당일파기' />
                    <DataColumn fieldName='CUR_ETC_QTY' title='당일기타출고' />
                </DataGrid>
                <EnrollPaper selectedRow={selectedRow} condition={condition} gridRefresh={gridRefresh} />
            </div>
        </div>

    )
}

const paperStyle = makeStyles((theme) => ({
    enroll: {
        flex: 1,
        marginRight: theme.spacing(1),
    },
    paperContent: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding: theme.spacing(2),
        paddingTop: 0
    },
    field: {
        width: 180,
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2),
    },
    iconbutton : {
        alignSelf: 'flex-end',
        marginTop: theme.spacing(4),
        marginRight: theme.spacing(1),
    },
}))

const EnrollPaper = ({selectedRow, condition, gridRefresh}) => {
    const [insData, setInsData] = useFieldInputs();

    const { enqueueSnackbar } = useSnackbar();
    const classes = paperStyle();

    useEffect(() => {
        if(selectedRow != null){
            setInsData('WORK_CD', condition['WORK_CD']);
            setInsData('BIZ_DATE', condition['BIZ_DATE']);
            setInsData('GDS_CD', selectedRow['GDS_CD'])
            setInsData('GDS_NM', selectedRow['GDS_NM'])
            setInsData('BEF_STOCK_QTY', selectedRow['BEF_STOCK_QTY']);
            setInsData('CUR_IN_QTY', selectedRow['CUR_IN_QTY']);
            setInsData('CUR_RTN_QTY', selectedRow['CUR_RTN_QTY']);
            setInsData('CUR_OUT_QTY', selectedRow['CUR_OUT_QTY']);
            setInsData('CUR_DST_QTY', selectedRow['CUR_DST_QTY']);
            setInsData('CUR_ETC_QTY', selectedRow['CUR_ETC_QTY']);
        }
        else{
            setInsData('GDS_CD', null);
            setInsData('GDS_NM', null);
            setInsData('BEF_STOCK_QTY', null);
            setInsData('CUR_IN_QTY', null);
            setInsData('CUR_RTN_QTY', null);
            setInsData('CUR_OUT_QTY', null);
            setInsData('CUR_DST_QTY', null);
            setInsData('CUR_ETC_QTY', null);
        }
    }, [selectedRow, setInsData, condition])

    const onInsert = async() => {
        const result = await callProc('INS_ITEMINOUT_ENROLL', insData);

        if(!result.err) {
            gridRefresh();
            enqueueSnackbar('저장이 완료되었습니다.');
        }
    }

    return (
        <Paper className={classes.enroll}>
            <PaperTitle>
                일반상품 입/출등록
                    </PaperTitle>
            <div className={classes.paperContent}>
                <TextField
                    className={classes.field}
                    label='상품명'
                    value={insData['GDS_NM'] || ''}
                    onChange={e => {
                        setInsData('GDS_NM', e.target.value);
                    }}
                    disabled
                />
                <div>
                    <NumberField
                        className={classes.field}
                        label='전일재고'
                        value={insData['BEF_STOCK_QTY']}
                        onChange={value => setInsData('BEF_STOCK_QTY', value)}
                        disabled
                    />
                </div>
                <div>
                    <NumberField
                        className={classes.field}
                        label='당일입고'
                        value={insData['CUR_IN_QTY']}
                        onChange={value => {
                            setInsData('CUR_IN_QTY', value);
                        }}
                    />
                    <NumberField
                        className={classes.field}
                        label='당일 반환입고'
                        value={insData && insData['CUR_RTN_QTY']}
                        onChange={value => {
                            setInsData('CUR_RTN_QTY', value);
                        }}
                    />
                </div>
                <div>
                    <NumberField
                        className={classes.field}
                        label='당일 출고'
                        value={insData && insData['CUR_OUT_QTY']}
                        onChange={value => {
                            setInsData('CUR_OUT_QTY', value);
                        }}
                    />
                    <NumberField
                        className={classes.field}
                        label='당일 파기'
                        value={insData && insData['CUR_DST_QTY']}
                        onChange={value => {
                            setInsData('CUR_DST_QTY', value);
                        }}
                    />
                </div>
                <div>
                    <NumberField
                        className={classes.field}
                        label='당일 기타출고'
                        value={insData && insData['CUR_ETC_QTY']}
                        onChange={value => {
                            setInsData('CUR_ETC_QTY', value);
                        }}
                    />
                </div>
                    <IconButton
                        className={classes.iconbutton}
                        tooltip='확인'
                        icon={<CheckIcon />}
                        onClick={onInsert}
                    />
            </div>

        </Paper>
    )
}

export default ItemInOutEnroll