import React, { useEffect, useState } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DatePicker, DataColumn, Tab, Tabs } from '../../component';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        width: 0,
    },
    tabContainer: {
        display: 'flex',
    },
    tabbar: {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    tab: {
        minWidth: 100,
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
    hide: {
        display: 'none'
    },
}))


const SaleListSum = () => {
    const [condition, setCondition] = useState(null);
    const [selectTab, setSelectTab] = useState(0);

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => {
                sessionStorage['WORK_CD'] = condition['WORK_CD'];
                setCondition(condition)
            }}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, dispatch) => (
                        <ComboBox
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
            </SearchPanel>
            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab className={classes.tab} label='전체' />
                    <Tab className={classes.tab} label='현금' />
                    <Tab className={classes.tab} label='현금(소득공제)' />
                    <Tab className={classes.tab} label='외상' />
                    <Tab className={classes.tab} label='신용카드' />
                    <Tab className={classes.tab} label='전자지불' />
                    <Tab className={classes.tab} label='상품권' />
                    <Tab className={classes.tab} label='포인트' />
                    <Tab className={classes.tab} label='쿠폰' />
                    <Tab className={classes.tab} label='현장할인' />
                    <Tab className={classes.tab} label='조달청할인' />
                    <Tab className={classes.tab} label='간편결제' />
                </Tabs>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <All condition={condition} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <Cash condition={condition} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 2 })}>
                <CashRecept condition={condition} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 3 })}>
                <CstCredit condition={condition} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 4 })}>
                <Card condition={condition} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 5 })}>
                {/* <GiftCard condition={condition} /> */}
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 6 })}>
                <GiftCard condition={condition} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 7 })}>
                <Point condition={condition} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 8 })}>
                <Coupon condition={condition} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 9 })}>
                <PlaceDisc condition={condition} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 10 })}>
                {/* <SaleBondList condition={condition} /> */}
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 11 })}>
                <SimplePay condition={condition} />
            </div>
        </div>
    )
}

const All = ({ condition }) => {
    const [gridParam, setGridParam] = useState(null);

    useEffect(() => {
        condition && setGridParam({ ...condition, PAY_CD: 'ALL', CSH_RECEPT: 0 })
    }, [condition])

    return (
        <DataGrid
            title='전체'
            selectProc='SLT_SALE_LIST_SUM'
            selectParam={gridParam}
            selectionMode='single'
            horizonMode
        >
            <DataColumn fieldName='QTY' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='SPLY_AMT' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='VAT' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='AMOUNT' total={(values) => values.reduce((result, value) => result + value, 0)} />
        </DataGrid>
    )
}

const Cash = ({ condition }) => {
    const [gridParam, setGridParam] = useState(null);

    useEffect(() => {
        condition && setGridParam({ ...condition, PAY_CD: 'P0001', CSH_RECEPT: 0 })
    }, [condition])

    return (
        <DataGrid
            title='전체'
            selectProc='SLT_SALE_LIST_SUM'
            selectParam={gridParam}
            selectionMode='single'
            horizonMode
        >
            <DataColumn fieldName='QTY' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='SPLY_AMT' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='VAT' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='AMOUNT' total={(values) => values.reduce((result, value) => result + value, 0)} />
        </DataGrid>
    )
}

const CashRecept = ({ condition }) => {
    const [gridParam, setGridParam] = useState(null);

    useEffect(() => {
        condition && setGridParam({ ...condition, PAY_CD: 'P0001', CSH_RECEPT: 1 })
    }, [condition])

    return (
        <DataGrid
            title='전체'
            selectProc='SLT_SALE_LIST_SUM'
            selectParam={gridParam}
            selectionMode='single'
            horizonMode
        >
            <DataColumn fieldName='QTY' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='SPLY_AMT' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='VAT' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='AMOUNT' total={(values) => values.reduce((result, value) => result + value, 0)} />
        </DataGrid>
    )
}

const CstCredit = ({ condition }) => {
    const [gridParam, setGridParam] = useState(null);

    useEffect(() => {
        condition && setGridParam({ ...condition, PAY_CD: 'P0003', CSH_RECEPT: 0 })
    }, [condition])

    return (
        <DataGrid
            title='전체'
            selectProc='SLT_SALE_LIST_SUM'
            selectParam={gridParam}
            selectionMode='single'
            horizonMode
        >
            <DataColumn fieldName='QTY' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='SPLY_AMT' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='VAT' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='AMOUNT' total={(values) => values.reduce((result, value) => result + value, 0)} />
        </DataGrid>
    )
}

const Card = ({ condition }) => {
    const [gridParam, setGridParam] = useState(null);

    useEffect(() => {
        condition && setGridParam({ ...condition, PAY_CD: 'P0002', CSH_RECEPT: 0 })
    }, [condition])

    return (
        <DataGrid
            title='전체'
            selectProc='SLT_SALE_LIST_SUM'
            selectParam={gridParam}
            selectionMode='single'
            horizonMode
        >
            <DataColumn fieldName='QTY' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='SPLY_AMT' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='VAT' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='AMOUNT' total={(values) => values.reduce((result, value) => result + value, 0)} />
        </DataGrid>
    )
}

const GiftCard = ({ condition }) => {
    const [gridParam, setGridParam] = useState(null);

    useEffect(() => {
        condition && setGridParam({ ...condition, PAY_CD: 'P0008', CSH_RECEPT: 0 })
    }, [condition])

    return (
        <DataGrid
            title='전체'
            selectProc='SLT_SALE_LIST_SUM'
            selectParam={gridParam}
            selectionMode='single'
            horizonMode
        >
            <DataColumn fieldName='QTY' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='SPLY_AMT' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='VAT' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='AMOUNT' total={(values) => values.reduce((result, value) => result + value, 0)} />
        </DataGrid>
    )
}

const Point = ({ condition }) => {
    const [gridParam, setGridParam] = useState(null);

    useEffect(() => {
        condition && setGridParam({ ...condition, PAY_CD: 'P0007', CSH_RECEPT: 0 })
    }, [condition])

    return (
        <DataGrid
            title='전체'
            selectProc='SLT_SALE_LIST_SUM'
            selectParam={gridParam}
            selectionMode='single'
            horizonMode
        >
            <DataColumn fieldName='QTY' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='SPLY_AMT' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='VAT' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='AMOUNT' total={(values) => values.reduce((result, value) => result + value, 0)} />
        </DataGrid>
    )
}

const Coupon = ({ condition }) => {
    const [gridParam, setGridParam] = useState(null);

    useEffect(() => {
        condition && setGridParam({ ...condition, PAY_CD: 'P0004', CSH_RECEPT: 0 })
    }, [condition])

    return (
        <DataGrid
            title='전체'
            selectProc='SLT_SALE_LIST_SUM'
            selectParam={gridParam}
            selectionMode='single'
            horizonMode
        >
            <DataColumn fieldName='QTY' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='SPLY_AMT' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='VAT' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='AMOUNT' total={(values) => values.reduce((result, value) => result + value, 0)} />
        </DataGrid>
    )
}

const PlaceDisc = ({ condition }) => {
    const [gridParam, setGridParam] = useState(null);

    useEffect(() => {
        condition && setGridParam({ ...condition, PAY_CD: 'P0006', CSH_RECEPT: 0 })
    }, [condition])

    return (
        <DataGrid
            title='전체'
            selectProc='SLT_SALE_LIST_SUM'
            selectParam={gridParam}
            selectionMode='single'
            horizonMode
        >
            <DataColumn fieldName='QTY' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='SPLY_AMT' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='VAT' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='AMOUNT' total={(values) => values.reduce((result, value) => result + value, 0)} />
        </DataGrid>
    )
}

const SimplePay = ({ condition }) => {
    const [gridParam, setGridParam] = useState(null);

    useEffect(() => {
        condition && setGridParam({ ...condition, PAY_CD: 'P0009', CSH_RECEPT: 0 })
    }, [condition])

    return (
        <DataGrid
            title='전체'
            selectProc='SLT_SALE_LIST_SUM'
            selectParam={gridParam}
            selectionMode='single'
            horizonMode
        >
            <DataColumn fieldName='QTY' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='SPLY_AMT' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='VAT' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='AMOUNT' total={(values) => values.reduce((result, value) => result + value, 0)} />
        </DataGrid>
    )
}


export default SaleListSum;