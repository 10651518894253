import React, { useState, useEffect } from 'react';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DataColumn, Tabs, Tab, NumberField, IconButton, ProgressPanel } from '../../component';
import { makeStyles, lighten, Dialog, DialogTitle, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { YNType, orderType} from '../../common/Dictionary';
import { useSnackbar } from 'notistack';
import { callProc } from '../../common/DBConnector';
import { FormatLineSpacing, KeyboardArrowDown, KeyboardArrowUp, Check, Close } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },    
    tabContainer : {
        display : 'flex',
    },
    tabbar : {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    content : {
        display: 'flex',
        flex: 1,
        height : 0,
    },
    hide : {
        display: 'none'
    },
}));

const TankInfo = () => {
    const [condition, setCondition] = useState(null);
    const [selectTab, setSelectTab] = useState(0);
    const classes = useStyles();
    const [oderbyDialogOpen, setOrderbyDialogOpen] = useState(false);
    const [tankData, setTankData] = useState(null);

    const dialogOpen = (data) => {
        setOrderbyDialogOpen(true);
        setTankData(null);
        setTankData(data);
    }

    const gridRefresh = () => {
        setCondition({...condition});
    }

    return(
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => setCondition(condition)}>
                <SearchItem
                    fieldName='WORK_CD'
                    component={(state, dispatch) => (
                        <ComboBox
                            label='사업장명'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
            </SearchPanel>
            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab label='현재적용탱크' />
                    <Tab label='전체탱크' />
                </Tabs>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <TankNow condition={condition} dialogOpen={dialogOpen}/>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <TankAll condition={condition} />
            </div>
            <OrderbyDialog
                open={oderbyDialogOpen}
                onClose={() => setOrderbyDialogOpen(false)}
                tankData={tankData}
                condition={condition}
                gridRefresh={gridRefresh}
            />
        </div>
    )
}

const TankNow = ({condition, dialogOpen}) => {
    return(
        <DataGrid
            title='현재적용탱크'
            selectProc='SLT_TANKINFO_NOW'
            selectParam={condition}
            selectionMode='single'
            onDataLoad={dataSet => {
                dataSet.data = dataSet.data.map(row => ({
                    ...row,
                    ORD_TYPE: JSON.parse(row['ORD_TYPE'])
                }));
                return dataSet;
            }}
            horizonMode
            headerItem={({data}) => 
                <IconButton
                    tooltip='순서등록'
                    icon={<FormatLineSpacing />}
                    onClick={() => dialogOpen(data)}
                />
            }
        >
            <DataColumn fieldName='TMP_TNK' value={(row, value) => YNType[value]}/>
            <DataColumn
                fieldName='ORD_TYPE'
                value={(row, value) => {                  
                    if(value !== undefined){
                        return value.map(type => orderType[type]).join(', ');
                    }else{
                        return false
                    }
                }}
            />
            <DataColumn fieldName='WORK_CD' visible={false} />
            <DataColumn fieldName='WORK_NM' visible={false} />
            <DataColumn fieldName='REAL_STOCK_MNG' value={(row, value) => YNType[value]}/>
        </DataGrid>
    )
}

const TankAll = ({condition}) => {
    
    return(
        <DataGrid
            title='전체탱크'
            table='TB_TANKINFO'
            selectProc='SLT_TANKINFO'
            insertProc='INS_TANKINFO'
            updateProc='UPT_TANKINFO'
            deleteProc='DLT_TANKINFO'
            selectParam={condition}
            selectionMode='single'
            onDataLoad={dataSet => {
                dataSet.data = dataSet.data.map(row => ({
                    ...row,
                    ORD_TYPE: JSON.parse(row['ORD_TYPE'])
                }));
                return dataSet;
            }}
            horizonMode
        >
            <DataColumn
                fieldName='WORK_CD'
                title='사업장명'
                value={row => row['WORK_NM']}
                defaultValue={condition && condition['WORK_CD']}
                editable={(state, dispatch) => (
                    <ComboBox
                        selectProc='SLT_WORKPLACEINFO_AUTH'
                        keyField='WORK_CD'
                        valueField='WORK_NM'
                        value={state}
                        onChange={value => dispatch(value)}
                        disabled={true}
                    />
                )}
            />
            <DataColumn
                fieldName='GDS_TYPE'
                value={row => row['CATE_NM']}
                editable={(state, dispatch) => (
                    <ComboBox
                        selectProc='SLT_CATEGORYINFO'
                        keyField='GDS_TYPE'
                        valueField='CATE_NM'
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />
            <DataColumn
                fieldName='TOT_QTY'
                editable={(state, dispatch) => (
                    <NumberField
                        style={{ width: 100 }}
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />
            <DataColumn
                fieldName='REAL_STOCK_MNG'
                value={(row, value) => YNType[value]}
                editable={(state, dispatch) => (
                    <ComboBox
                        data={YNType}
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />
            <DataColumn
                fieldName='TMP_TNK'
                value={(row, value) => YNType[value]}
                editable={(state, dispatch) => (
                    <ComboBox
                        data={YNType}
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />
            <DataColumn
                fieldName='ORD_TYPE'
                defaultValue={[]}
                value={(row, value) => {                  
                    if(value !== undefined){
                        return value.map(type => orderType[type]).join(', ');
                    }else{
                        return false
                    }
                }}
                editable={(state, dispatch) => {
                    return (
                        <ComboBox                        
                            data={orderType}
                            value={state}
                            onChange={value => dispatch(value)}
                            multiple
                        />
                    )
                }}
            />
            <DataColumn
                fieldName='WORK_NM'
                visible={false}
            />
            <DataColumn
                fieldName='CATE_NM'
                visible={false}
            />
        </DataGrid>
    )
}

const orderbyDialogStyle = makeStyles((theme) => ({
    dialogPaper: {
        maxWidth: 'none',
        width: 700,
        height: 700,
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    actionButton: {
        display: 'flex',
    },
    content: {
        overflowY: 'auto',
    },
    itemContainer: {
        cursor: 'default',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    selection: {
        backgroundColor: lighten(theme.palette.primary.main, 0.85),
    },
}))

const OrderbyDialog = ({open, onClose, tankData, condition, gridRefresh}) => {
    const [data, setData] = useState();
    const [selected, setSelected] = useState();
    const classes = orderbyDialogStyle();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => setData(tankData), [tankData]);

    const onUpClick = () => {
        const index = data.indexOf(selected);
        data.splice(index, 1);
        data.splice(index - 1, 0, selected);
        const result = data.map((item, order) => ({
            ...item,
            SEQ: order + 1
        }));
        setData(result);
        setSelected(result.find(item => item['TNK_NO'] === selected['TNK_NO']));
    }

    const onDownClick = () => {
        const index = data.indexOf(selected);
        data.splice(index, 1);
        data.splice(index + 1, 0, selected);
        const result = data.map((item, order) => ({
            ...item,
            SEQ: order + 1
        }));
        setData(result);
        setSelected(result.find(item => item['TNK_NO'] === selected['TNK_NO']));
    }

    const onSave = async() => {
        const result = await callProc('UPT_TANKINFO_SEQ', {...condition, JSON: JSON.stringify(data)});
        if(!result.err){
            enqueueSnackbar('저장이 완료되었습니다.');
            onClose();
            gridRefresh();
        }
    }

    return (
        <Dialog
            open={open}
            PaperProps={{className: classes.dialogPaper}}
        >
            <div className={classes.dialogTitle}>
                <DialogTitle>순서정렬</DialogTitle>
                <div className={classes.actionButton}>
                    <IconButton
                        tooltip='확인'
                        icon={<Check />}
                        onClick={onSave}
                    />
                    <IconButton
                        tooltip='위로 이동'
                        icon={<KeyboardArrowUp />}
                        disabled={!data || !selected || data.indexOf(selected) === 0}
                        onClick={onUpClick}
                    />
                    <IconButton
                        tooltip='아래로 이동'
                        icon={<KeyboardArrowDown />}
                        disabled={!data || !selected || data.indexOf(selected) === data.length - 1}
                        onClick={onDownClick}
                    />
                </div>
                <IconButton
                    tooltip='닫기'
                    icon={<Close />}
                    onClick={onClose}
                />
            </div>
            <div className={classes.content}>
                {!data ? <ProgressPanel /> :
                data.map((item, idx) => (
                    <Typography
                        onClick={() => setSelected(item)}
                        className={clsx(classes.itemContainer, {
                            [classes.selection]: item['TNK_NO'] === (selected && selected['TNK_NO'])
                        })}
                    >
                        {`${item['TNK_NO']} : ${item['CATE_NM']} ${item['END_DATE'] ? `※종료일 : ${item['END_DATE']}※` : ``}`}
                    </Typography>
                ))}
            </div>
        </Dialog>
    )
}

export default TankInfo