import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { DataGrid, DataColumn, SearchItem, SearchPanel, ComboBox, ProgressPanel, DatePicker, ComboSearch, IconButton, CheckBox, PdfButton } from '../../component';
import { Dialog, makeStyles } from '@material-ui/core';
import { callProc } from '../../common/DBConnector';
import { issueLoc } from '../../common/Dictionary';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { useSelector } from 'react-redux';
import { AccountingListPdf } from '../../reports';

const sort = (a, b) => a['ISSUE_LOC'] > b['ISSUE_LOC'] ? 1 : -1;

const useStyle = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    }
}))

const AccountingList = () => {
    const classes = useStyle();
    const [workData, setWorkData] = useState();
    const [accData, setAccData] = useState();
    const [condition, setCondition] = useState(null);
    const [listDialog, setListDialog] = useState();
    const [pdfData, setPdfData] = useState();
    const loginState = useSelector(state => state.loginReducer);
    const [workData1, setWorkData1] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const workData2 = await callProc('SLT_WORKPLACEINFO_NOTMAIN_AUTH');

            setWorkData1(workData2.data.reduce((result, item) => ({
                ...result,
                [item['WORK_CD']]: item['WORK_NM']
            }), {}))

        }
        fetchData();
    }, [])

    

    useEffect(() => {
        const fetchData = async () => {
            const workData = (await callProc('SLT_WORKPLACEINFO_AUTH')).data;
            const accData = (await callProc('SLT_ACCOUNT_ALL')).data;

            workData.unshift({WORK_CD: 'ALL', WORK_NM: '전체'});
            accData.unshift({ACC_CD: 'ALL', ACC_NM: '전체'});

            setWorkData(workData);
            setAccData(accData);
        }
        fetchData();
    }, []);


    const condWorkName = workData1 && condition && workData1[condition['WORK_CD']];
    const pdfComponent = useMemo(() => (
        <PdfButton title='계정과목발행전표조회'>
            {pdfData &&
            <AccountingListPdf
                pdfData={pdfData}
                condition={condition}
                loginState={loginState}
                workName={condWorkName}
            />}
        </PdfButton>
    ), [pdfData, condition, loginState, condWorkName]);

    return (
        !workData ? <ProgressPanel /> :
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => {setCondition(condition)}}>
                <SearchItem
                    fieldName='WORK_CD'
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            data={workData}
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='ACC_CD'
                    width={350}
                    component={(state, setState, condition, dispatch) => (
                        <div style={{ display: 'flex' }}>
                            <ComboSearch 
                                label='계정과목'
                                data={accData}
                                keyField='ACC_CD'
                                valueField='ACC_NM'
                                value={state}
                                onChange={(value) => setState(value)}
                                style={{ flex: 1, marginRight: 20 }}
                            />
                            <CheckBox
                                label='관계 항목 포함'
                                checked={condition['RELATION']}
                                onChange={value => dispatch('RELATION', value)}
                            />
                        </div>
                    )}
                />

            </SearchPanel>

            <DataGrid
                title='계정과목발행전표조회'
                selectProc='SLT_ACCOUNTJOURNAL_LIST'
                selectParam={condition}
                totalGroup={['ISSUE_LOC']}
                totalSort={sort}
                selectionMode='single'
                horizonMode
                virtualized
                excelDownload
                headerItem={({visibleAoa}) => {
                    setPdfData(visibleAoa);
                    return pdfComponent;
                }}
                sortable
                filterable
                stickyHeader
            >
                <DataColumn 
                    position='ACJ_DATE'
                    value={row => (
                        <IconButton
                            tooltip='분개 확인'
                            icon={<ListAltIcon />}
                            onClick={() => setListDialog(row)}
                        />
                    )}
                />
                <DataColumn
                    fieldName='ISSUE_LOC'
                    value={(row, value) => issueLoc[value]}
                    total={(values, data, group) => group ? issueLoc[group['ISSUE_LOC']] + ' 소계' : '총계'}
                />
                <DataColumn fieldName='WORK_NM' title='대상'/>
                <DataColumn fieldName='DEBTOR' title='차변' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='CREDITOR' title='대변' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='CARD_NM' title='결제정보'/>

                <DataColumn
                    fieldName='WORK_CD'
                    visible={false}
                />
            </DataGrid>
            <ListDialog
                data={listDialog}
                onClose={() => setListDialog(null)}
            />
        </div>
    )
}

const useDialogStyles = makeStyles(theme => ({
    dialogPaper: {
        maxWidth: 'none',
        width: 1000,
        height: 500,
    },
}))

const ListDialog = ({data, onClose}) => {
    const classes = useDialogStyles();
    return (
        <Dialog
            open={data != null}
            onClose={onClose}
            PaperProps={{ className: classes.dialogPaper }}
        >
            <DataGrid
                selectProc='SLT_ACCOUNTJOURNAL_LIST_DETAIL'
                selectParam={data}
                title='분개 항목'
                horizonMode
                selectionMode='single'
                virtualized
            >
                <DataColumn
                    fieldName='ISSUE_LOC'
                    value={(row, value) => issueLoc[value]}
                />
                
                <DataColumn fieldName='DEBTOR' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='CREDITOR' total={(values) => values.reduce((result, value) => result + value, 0)} />
            </DataGrid>
        </Dialog>
    )
}



export default AccountingList;