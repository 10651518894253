import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DatePicker, DataColumn, Tab, Tabs, PdfButton } from '../../component';
import { makeStyles } from '@material-ui/core';
import { callProc } from '../../common/DBConnector';
import { issueLoc, orderType } from '../../common/Dictionary';
import { WorkAllDailyReportPdf } from '../../reports';
import { useSelector } from 'react-redux';


const useStyle = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    tabContainer : {
        display : 'flex',
    },
    subTabContainer : {
        backgroundColor: theme.palette.secondary.main,
        boxShadow: theme.shadows[4],
        marginBottom: 8,
    },
    tabbar : {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    content : {
        display: 'flex',
        flex: 1,
        height : 0,
    },
    hide : {
        display: 'none'
    },
}))

const MainWorkReport = () => {
    const classes = useStyle();
    const [condition, _setCondition] = useState(null);
    const [selectTab, setSelectTab] = useState(0);
    const [inSaleData, setInSaleData] = useState();
    const [inGoodsData, setInGoodsData] = useState();
    const [cstBondData, setCstBondData] = useState();
    const [stockData, setStockData] = useState();
    const [fundhData, setFundData] = useState();
    const [balanceData, setBalanceData] = useState();
    const [cashData, setCashData] = useState();
    const loginState = useSelector(state => state.loginReducer);
    const [workData, setWorkData] = useState();

    const setCondition = (value) => {
        setInSaleData(null);
        setInGoodsData(null);
        setCstBondData(null);
        setStockData(null);
        setFundData(null);
        setBalanceData(null);
        setCashData(null);

        _setCondition(value);
    }

    useEffect(() => {
        const fetchData = async () => {
            const workData = await callProc('SLT_WORKPLACEINFO_NOTMAIN_AUTH');

            setWorkData(workData.data.reduce((result, item) => ({
                ...result,
                [item['WORK_CD']]: item['WORK_NM']
            }), {}))

        }
        fetchData();
    }, [])

    useEffect(() => {
        const fetchData = async() => {
            setInSaleData(await callProc('SLT_TOT_IN_SALE', condition));
            setFundData(await callProc('SLT_FUND_LIST_REPORT', condition));
            setInGoodsData(await callProc('SLT_IN_CST_GOOD_LIST', condition));
            setCstBondData(await callProc('SLT_TOT_BONDPAY', condition));
            setStockData(await callProc('SLT_WORKALL_STOCK_REPORT', condition));
            setBalanceData(await callProc('SLT_BANKBOOK_BALANCE', condition));
            setCashData(await callProc('SLT_CASH_INOUT_LIST_REPORT', condition));
        }
        condition && fetchData();
    }, [condition])

    const condWorkName = (workData && condition && workData[condition['WORK_CD']]) || (workData && workData[Object.keys(workData)[0]]);
    const pdfComponent = useMemo(() => (
        <PdfButton title='통합 일일 보고서'>
            {inSaleData
            && inGoodsData 
            && cstBondData 
            && stockData 
            && fundhData 
            && balanceData
            && cashData &&
            <WorkAllDailyReportPdf
                inSaleData={inSaleData && inSaleData.data}
                inGoodsData={inGoodsData && inGoodsData.data}
                cstBondData={cstBondData && cstBondData.data}
                stockData={stockData && stockData.data}
                fundhData={fundhData && fundhData.data}
                balanceData={balanceData && balanceData.data}
                cashData={cashData && cashData.data}

                condition={condition}
                loginState={loginState}
                workName={condWorkName}
            />}
        </PdfButton>
    ), [inSaleData, 
        inGoodsData, 
        cstBondData, 
        stockData, 
        fundhData, 
        balanceData, 
        cashData, 
        condition, 
        loginState, 
        condWorkName]);


    return(
        <div className={classes.container}>
            <SearchPanel
                onSearch={(condition) => {
                    sessionStorage['WORK_CD'] = condition['WORK_CD'];
                    setCondition(condition);
                }}
            >
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            onDataLoad={(jsonData, rawData) => [ {WORK_CD: 'ALL', WORK_NM: '전체'}, ...rawData ]}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
            </SearchPanel>
            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab label='매입/매출 상세내역' />
                    <Tab label='상품매입장' />
                    <Tab label='채권현황' />
                    <Tab label='매출/재고현황' />
                    <Tab label='자금관리' />
                    <Tab label='계좌잔액' />
                    <Tab label='현금출납부' />
                </Tabs>
                <div className={classes.subTabContainer}>
                    {pdfComponent}
                </div>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <InSaleDetail inSaleData={inSaleData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <CstDebtGood inGoodsData={inGoodsData} />
            </div> 
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 2 })}>
                <CstBondList cstBondData={cstBondData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 3 })}>
                <Stock stockData={stockData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 4 })}>
                <Funds fundhData={fundhData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 5 })}>
                <Balance balanceData={balanceData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 6 })}>
                <CashReport cashData={cashData} />
            </div>
        </div>
    )
}

const inSaleDetailStyles = makeStyles(theme => ({
    total: {
        backgroundColor: '#a3a9d9 !important'
    }
}));

const InSaleDetail = ({inSaleData}) => {
    const classes = inSaleDetailStyles();

    const getCellProps = (row, fieldName) => {
        if(String(row['COLOR_TYPE']) === '2')
            return { className: classes.total }
    };

    return(
    <DataGrid
        title='매입/매출 상세내역'
        dataSet={inSaleData}
        horizonMode
        virtualized
        sortable
        filterable
        cellProps={getCellProps}
        excelDownload={{ fileName: '통합일일보고서-매입매출상세내역.xlsx', sheetName: '매입매출상세내역' }}
        stickyHeader
    >
        <DataColumn fieldName='ORD_TYPE' value={(row, value) => orderType[value]} />
        <DataColumn fieldName='BUY_CST_NM' title='매입처 및 출발지' />
        <DataColumn fieldName='REAL_DEST_NM' title='판매처 및 도착지' />
        <DataColumn fieldName='GS_TSP_CD' title='인도처 코드' />
        <DataColumn fieldName='QTY' title='오더수량' />
        <DataColumn fieldName='CALC_QTY' title='정산수량' />
        <DataColumn fieldName='SHIP_TRN_NM' title='출하처명' />
        <DataColumn fieldName='DEST_TRN_NM' title='인도처명' />
        <DataColumn fieldName='I_PRICE' title='매입단가' />
        <DataColumn fieldName='I_SPLY_AMT' title='매입 공급가액' />
        <DataColumn fieldName='I_VAT' title='매입 부가세' />
        <DataColumn fieldName='I_AMT' title='매입 금액' />
        <DataColumn fieldName='S_PRICE' title='매출단가' />
        <DataColumn fieldName='S_SPLY_AMT' title='매출 공급가액' />
        <DataColumn fieldName='S_VAT' title='매출 부가세' />
        <DataColumn fieldName='S_AMT' title='매출 금액' />
        <DataColumn fieldName='GS_SHIP_CD' title='출하처 코드' />
        <DataColumn fieldName='GS_REAL_CD' title='판매처 코드' />
        <DataColumn fieldName='SEQ' visible={false} />
        <DataColumn fieldName='SEQ_TYPE' visible={false} />
        <DataColumn fieldName='SUM_TYPE' visible={false} />
        <DataColumn fieldName='WORK_CD' visible={false} />
        <DataColumn fieldName='BUY_CST_CD' visible={false} />
        <DataColumn fieldName='REAL_DEST_CD' visible={false} />
        <DataColumn fieldName='GDS_CD' visible={false} />
        <DataColumn fieldName='SHIP_TRN_CD' visible={false} />
        <DataColumn fieldName='DEST_TRN_CD' visible={false} />
        <DataColumn fieldName='COLOR_TYPE' visible={false} />
    </DataGrid>

    )}

const CstDebtGood = ({inGoodsData}) => {
    return(
    <DataGrid
        title='상품매입장'
        dataSet={inGoodsData}
        totalGroup={['WORK_NM']}
        horizonMode
        virtualized
        sortable
        filterable
        excelDownload={{ fileName: '통합일일보고서-상품매입장.xlsx', sheetName: '상품매입장' }}
        stickyHeader
    >
        <DataColumn fieldName='WORK_NM' total={(values, data, group) => group == null ? '합 계' : '(소 계) ' + group['WORK_NM']} />
        <DataColumn fieldName='CST_NM' title='매입처' />
        <DataColumn fieldName='BEF_BAL_P' title='전기 매입잔액(+)' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='BEF_BAL_M' title='전기 매입잔액(-)' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='CUR_IN_AMT' title='매입 금액' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='CUR_OUT_AMT' title='지급 금액' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='CUR_BAL_P' title='당기 매입잔액(+)' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='CUR_BAL_M' title='당기 매입잔액(-)' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='WORK_CD' visible={false} />
        <DataColumn fieldName='CST_CD' visible={false} />
    </DataGrid>
    )
}

const bondStyles = makeStyles(theme => ({
    total: {
        backgroundColor: '#a3a9d9 !important'
    }
}));

const CstBondList = ({cstBondData}) => {
    const classes = bondStyles();
    const getCellProps = (row, fieldName) => {
        if(String(row['COLOR_TYPE']) === '2')
            return { className: classes.total }
    };

    return(<DataGrid
        title='채권현황'
        dataSet={cstBondData}
        horizonMode
        virtualized
        sortable
        filterable
        cellProps={getCellProps}
        excelDownload={{ fileName: '통합일일보고서-채권현황.xlsx', sheetName: '채권현황' }}
        stickyHeader
    >
        <DataColumn fieldName='WORK_NM'  />
        <DataColumn fieldName='BEF_BAL_P' title='전기 매출잔액(+)' />
        <DataColumn fieldName='BEF_BAL_M' title='전기 매출잔액(-)' />
        <DataColumn fieldName='CUR_SALE_AMT' title='당기 매출' />
        <DataColumn fieldName='CUR_IN_AMT' title='당기 입금' />
        <DataColumn fieldName='CUR_BAL_P' title='당기 매출잔액(+)' />
        <DataColumn fieldName='CUR_BAL_M' title='당기 매출잔액(-)' />
        <DataColumn fieldName='BEF_MONTH_BAL_P' title='전월 매출잔액(+)' visible={false}/>
        <DataColumn fieldName='BEF_MONTH_BAL_M' title='전월 매출잔액(-)' visible={false}/>
        <DataColumn fieldName='SEQ' visible={false} />
        <DataColumn fieldName='SEQ_TYPE' visible={false} />
        <DataColumn fieldName='SUM_TYPE' visible={false} />
        <DataColumn fieldName='COLOR_TYPE' visible={false} />
        <DataColumn fieldName='WORK_CD' visible={false} />

    </DataGrid>
)}

const Stock = ({ stockData }) =>{

    return (<DataGrid
        title='매출/재고현황'
        dataSet={stockData}
        selectionMode='single'
        totalGroup={['WORK_NM']}
        horizonMode
        virtualized
        sortable
        filterable
        excelDownload={{ fileName: '통합일일보고서-매출재고현황.xlsx', sheetName: '매출재고현황' }}
        stickyHeader
    >
        <DataColumn fieldName='WORK_NM' total={(values, data, group) => group == null ? '합 계' : '(소 계) ' + group['WORK_NM']} />
        <DataColumn fieldName='GDS_NM' />
        <DataColumn fieldName='BEF_STOCK_QTY' title='전기재고' total={(values) => values.reduce((result, value) => result + value, 0)}/>
        <DataColumn fieldName='CUR_IN_QTY' title='당기입고' total={(values) => values.reduce((result, value) => result + value, 0)}/>
        <DataColumn fieldName='CUR_SALE_QTY' title='당기출고' total={(values) => values.reduce((result, value) => result + value, 0)}/>
        <DataColumn fieldName='SALE_AMOUNT' title='판매금액' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='CUR_STOCK_QTY' title='당기재고' total={(values) => values.reduce((result, value) => result + value, 0)}/>
        <DataColumn fieldName='LAST_REAL_QTY' title='실재고' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='CUR_GAP_QTY' title='당일실재고차' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='BEF_GAP_QTY' title='전일실재고차' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='CUR_TEST_QTY' title='검량' total={(values) => values.reduce((result, value) => result + value, 0)}/>
        <DataColumn fieldName='CUR_MOVE_QTY' title='자체이관' total={(values) => values.reduce((result, value) => result + value, 0)}/>
        <DataColumn fieldName='CUR_ADJ_QTY' title='재고조정' total={(values) => values.reduce((result, value) => result + value, 0)}/>
        <DataColumn fieldName='WORK_CD' visible={false} />

    </DataGrid>
)}

const Funds = ({ fundhData }) => {

    return (
    <DataGrid
        title='자금 관리'
        dataSet={fundhData}
        selectionMode='single'
        horizonMode
        virtualized
        sortable
        filterable
        excelDownload={{ fileName: '통합일일보고서-자금관리.xlsx', sheetName: '자금관리' }}
        stickyHeader
    >
        <DataColumn fieldName='ACC_NM' title='계정과목명' total={(values) => '합계'} />
        <DataColumn fieldName='IN_AMT' title='수입' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='OUT_AMT' title='지출' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='BAL_AMT' title='잔액' />
        <DataColumn fieldName='ISSUE_LOC' value={(row, value) => issueLoc[value]} />
    </DataGrid>
)}

const balanceStyle = makeStyles(() => ({
    total: {
        backgroundColor: '#a3a9d9 !important'
    }
}))

const Balance = ({ balanceData }) => {
    const classes = balanceStyle();
    const getCellProps = (row, fieldName) => {
        if(String(row['COLOR_TYPE']) === '2')
            return { className: classes.total }
    };

    return (
    <DataGrid
        title='계좌 잔액'
        dataSet={balanceData}
        selectionMode='single'
        horizonMode
        virtualized
        sortable
        filterable
        excelDownload={{ fileName: '통합일일보고서-계좌잔액.xlsx', sheetName: '계좌잔액' }}
        stickyHeader
        cellProps={getCellProps}
    >
        <DataColumn fieldName='BBK_NM' title='통장명' />
        <DataColumn fieldName='AMOUNT' title='잔액'  />
        <DataColumn fieldName='BBK_CD' visible={false} />
        <DataColumn fieldName='REMARK' visible={false} />
        <DataColumn fieldName='COLOR_TYPE' visible={false} />
        <DataColumn fieldName='SEQ' visible={false} />
    </DataGrid>
)}

const CashReport = ({ cashData }) => {

    return(<DataGrid
        title='현금출납부'
        dataSet={cashData}
        selectionMode='single'
        horizonMode
        virtualized
        sortable
        filterable
        excelDownload={{ fileName: '통합일일보고서-현금출납부.xlsx', sheetName: '현금출납부' }}
        stickyHeader
    >
        <DataColumn fieldName='ACJ_DATE' title='영업일' />
        <DataColumn fieldName='ACC_NM' title='계정과목명' total={(values) => '합계'} />
        <DataColumn fieldName='DEBTOR' title='수입' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='CREDITOR' title='지출' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='BAL_AMT' title='잔액' />
    </DataGrid>
)}

export default MainWorkReport