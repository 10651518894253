import React, { Fragment, useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DatePicker, DataColumn, Tab, Tabs, PdfButton, IconButton } from '../../component';
import { makeStyles } from '@material-ui/core';
import { BondPayListPdf, BondPayListCardPdf, BondPayListEtcPdf } from '../../reports';
import { useSelector } from 'react-redux';
import { GridOn } from '@material-ui/icons';
import { aoaToSheet, downloadWorkbook, sheetToWorkbook, totalCount } from '../../common/Utils';

const useStyle = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    tabContainer : {
        display : 'flex',
    },
    tabbar : {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    content : {
        display: 'flex',
        flex: 1,
        height : 0,
    },
    hide : {
        display: 'none'
    },
}))

const BondPayList = () => {
    const classes = useStyle();
    const [condition, setCondition] = useState(null);
    const [selectTab, setSelectTab] = useState(0);
    const [workData, setWorkData] = useState();
    const [excelAoa, setExcelAoa] = useState([null]);

    const onXlsLoad = useCallback((index, data) => {
        setExcelAoa(prevState => {
            const result = [...prevState];
            result[index] = data;
            return result;
        });
    }, []);

    return(
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => {
                sessionStorage['WORK_CD'] = condition['WORK_CD'];
                setCondition(condition);
                setExcelAoa(new Array(5).fill(null));
                }}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            onDataLoad={(jsonData, rawData) => {
                                const result = [{ WORK_CD: 'ALL', WORK_NM: '전체' }, ...rawData];
                                setWorkData(result);
                                return result;
                            }}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
            </SearchPanel>
            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab label='전체' />
                    <Tab label='외상 매출처' />
                    <Tab label='카드사' />
                    <Tab label='간편결제' />
                    <Tab label='기타 관리' />
                    <Tab label='비상품 매출처' />
                </Tabs>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <All condition={condition} workData={workData} xlsData={excelAoa} onLoad={onXlsLoad} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <CstBondPay condition={condition} workData={workData} onLoad={onXlsLoad} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 2 })}>
                <CardBondPay condition={condition} workData={workData} onLoad={onXlsLoad} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 3 })}>
                <SimplePay condition={condition} workData={workData} onLoad={onXlsLoad} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 4 })}>
                <EtcBondPay condition={condition} workData={workData} onLoad={onXlsLoad} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 5 })}>
                <NotGoodsCst condition={condition} workData={workData} onLoad={onXlsLoad} />
            </div>
            
        </div>
    )
}

const All = ({condition, workData, xlsData, onLoad}) => {
    const [pdfData, setPdfData] = useState();
    const loginState = useSelector(state => state.loginReducer);

    const handleLoad = useCallback(({visibleAoa}) => onLoad(0, visibleAoa), [onLoad]);

    const pdfComponent = useMemo(() => (
        <PdfButton 
            title='채권현황'>
            {pdfData &&
            <BondPayListPdf
                pdfData={pdfData}
                condition={condition}
                loginState={loginState}
                workName={workData.find(item => item['WORK_CD'] === condition['WORK_CD'])['WORK_NM']}
            />}
        </PdfButton>
    ), [pdfData, condition, loginState, workData]);

    const excelDownload = () => {
        const sheetName = ['전체', '외상 매출처', '카드사', '간편결제', '기타 관리', '비상품 매출처'];
        
        const sheets = xlsData.map((data, index) => ({
            sheet: aoaToSheet(data),
            name: sheetName[index]
        }));
        downloadWorkbook('채권현황-전체.xlsx', sheetToWorkbook(sheets));
    }
    
    return(
        <>
        <DataGrid
            title='전체'
            selectProc='SLT_BONDPAY_ALL'
            selectParam={condition}
            horizonMode
            totalGroup={['WORK_CD']}
            virtualized
            sortable
            filterable
            headerItem={({visibleAoa}) => {
                setPdfData(visibleAoa);
                return (
                <Fragment>
                    {pdfComponent}
                    <IconButton
                        tooltip='엑셀 다운로드'
                        icon={<GridOn />}
                        onClick={excelDownload}
                        disabled={xlsData.reduce((result, item) => result || (item == null), false)}
                    />
                </Fragment>
                );
            }}
            onLoad={handleLoad}
            stickyHeader
        >
            <DataColumn 
                fieldName='WORK_NM' 
                total={(values, data, group) => 
                    group == null ? `- 합 계 ${totalCount(values)} 건 -` : `(소 계 ${totalCount(values)} 건) ${data[0]['WORK_NM']}`
                } 
            />
            <DataColumn fieldName='BEF_BAL_P' title='전기 발생잔액(+)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='BEF_BAL_M' title='전기 발생잔액(-)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_SALE_AMT' title='당기 발생' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_IN_AMT' title='당기 입금' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_BAL_P' title='당기 발생잔액(+)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_BAL_M' title='당기 발생잔액(-)' total={(values) => values.reduce((result, value  ) => result + value, 0)}/>
            <DataColumn fieldName='BEF_MONTH_BAL_P' title='전월 발생잔액(+)' total={(values) => values.reduce((result, value) => result + value, 0)} visible={false}/>
            <DataColumn fieldName='BEF_MONTH_BAL_M' title='전월 발생잔액(-)' total={(values) => values.reduce((result, value) => result + value, 0)} visible={false}/>
            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
        </>
    )
}

const CstBondPay = ({condition, workData, onLoad}) => {
    const [pdfData, setPdfData] = useState();
    const loginState = useSelector(state => state.loginReducer);

    const pdfComponent = useMemo(() => (
        <PdfButton title='채권현황'>
            {pdfData &&
            <BondPayListPdf
                pdfData={pdfData}
                condition={condition}
                loginState={loginState}
                workName={workData.find(item => item['WORK_CD'] === condition['WORK_CD'])['WORK_NM']}
            />}
        </PdfButton>
    ), [pdfData, condition, loginState, workData]);
    const handleLoad = useCallback(({visibleAoa}) => onLoad(1, visibleAoa), [onLoad]);

    
    return(
        <>
        <DataGrid
            title='외상 매출처'
            selectProc='SLT_BONDPAY_CUSTOMER'
            selectParam={condition}
            horizonMode
            totalGroup={['WORK_CD']}
            virtualized
            sortable
            filterable
            excelDownload={{ fileName: '채권현황-외상매출처.xlsx', sheetName: '외상매출처' }}
            headerItem={({visibleAoa}) => {
                setPdfData(visibleAoa);
                return pdfComponent;
            }}
            onLoad={handleLoad}
            stickyHeader
        >
            <DataColumn 
                fieldName='WORK_NM' 
                total={(values, data, group) => 
                    group == null ? `- 합 계 ${totalCount(values)} 건 -` : `(소 계 ${totalCount(values)} 건) ${data[0]['WORK_NM']}`
                } 
            />
            <DataColumn fieldName='BEF_BAL_P' title='전기 발생잔액(+)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='BEF_BAL_M' title='전기 발생잔액(-)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_SALE_AMT' title='당기 발생' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_IN_AMT' title='당기 입금' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_BAL_P' title='당기 발생잔액(+)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_BAL_M' title='당기 발생잔액(-)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='BEF_MONTH_BAL_P' title='전월 발생잔액(+)' total={(values) => values.reduce((result, value) => result + value, 0)} visible={false}/>
            <DataColumn fieldName='BEF_MONTH_BAL_M' title='전월 발생잔액(-)' total={(values) => values.reduce((result, value) => result + value, 0)} visible={false}/>
            <DataColumn fieldName='BEF_BAL' visible={false} />
            <DataColumn fieldName='CUR_BAL' visible={false} />
            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
        </>
    )
}

const CardBondPay = ({condition, workData, onLoad}) => {
    const [pdfData, setPdfData] = useState();
    const loginState = useSelector(state => state.loginReducer);

    const pdfComponent = useMemo(() => (
        <PdfButton title='채권현황'>
            {pdfData &&
            <BondPayListCardPdf
                pdfData={pdfData}
                condition={condition}
                loginState={loginState}
                workName={workData.find(item => item['WORK_CD'] === condition['WORK_CD'])['WORK_NM']}
            />}
        </PdfButton>
    ), [pdfData, condition, loginState, workData]);
    const handleLoad = useCallback(({visibleAoa}) => onLoad(2, visibleAoa), [onLoad]);


    return(
        <>
        <DataGrid
            title='카드사'
            selectProc='SLT_BONDPAY_CARD'
            selectParam={condition}
            horizonMode
            totalGroup={['WORK_CD']}
            virtualized
            sortable
            filterable
            excelDownload={{ fileName: '채권현황-카드사.xlsx', sheetName: '카드사' }}
            headerItem={({visibleAoa}) => {
                setPdfData(visibleAoa);
                return pdfComponent;
            }}
            onLoad={handleLoad}
            stickyHeader
        >
            <DataColumn 
                fieldName='WORK_NM' 
                total={(values, data, group) => 
                    group == null ? `- 합 계 ${totalCount(values)} 건 -` : `(소 계 ${totalCount(values)} 건) ${data[0]['WORK_NM']}`
                } 
            />
            <DataColumn fieldName='BEF_NOT_IN_AMT' title='전기 미입금액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_SALE_AMT' title='당기 발생금액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_IN_AMT' title='당기 입금액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_COMM_AMT' title='당기 카드수수료' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_IN_SUM_AMT' title='합계 금액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_NOT_IN_AMT' title='당기 미입금액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
        </>
    )
}

const SimplePay = ({condition, workData, onLoad}) => {
    const [pdfData, setPdfData] = useState();
    const loginState = useSelector(state => state.loginReducer);

    const pdfComponent = useMemo(() => (
        <PdfButton title='채권현황'>
            {pdfData &&
            <BondPayListPdf
                pdfData={pdfData}
                condition={condition}
                loginState={loginState}
                workName={workData.find(item => item['WORK_CD'] === condition['WORK_CD'])['WORK_NM']}
            />}
        </PdfButton>
    ), [pdfData, condition, loginState, workData]);
    const handleLoad = useCallback(({visibleAoa}) => onLoad(3, visibleAoa), [onLoad]);


    return(
        <>
        <DataGrid
            title='간편결제'
            selectProc='SLT_BONDPAY_SIMPLEPAY'
            selectParam={condition}
            horizonMode
            totalGroup={['WORK_CD']}
            virtualized
            sortable
            filterable
            excelDownload={{ fileName: '채권현황-간편결제.xlsx', sheetName: '간편결제' }}
            headerItem={({visibleAoa}) => {
                setPdfData(visibleAoa);
                return pdfComponent;
            }}
            onLoad={handleLoad}
            stickyHeader
        >
            <DataColumn 
                fieldName='WORK_NM' 
                total={(values, data, group) => 
                    group == null ? `- 합 계 ${totalCount(values)} 건 -` : `(소 계 ${totalCount(values)} 건) ${data[0]['WORK_NM']}`
                } 
            />
            <DataColumn fieldName='BEF_NOT_IN_AMT' title='전기 미입금액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_SALE_AMT' title='당기 발생금액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_IN_AMT' title='당기 입금액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_COMM_AMT' title='당기 지급수수료' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_SUM_AMT' title='합계 금액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_NOT_IN_AMT' title='당기 미입금액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
        </>
    )
}


const EtcBondPay = ({condition, workData, onLoad}) => {
    const [pdfData, setPdfData] = useState();
    const loginState = useSelector(state => state.loginReducer);

    const pdfComponent = useMemo(() => (
        <PdfButton title='채권현황'>
            {pdfData &&
            <BondPayListEtcPdf
                pdfData={pdfData}
                condition={condition}
                loginState={loginState}
                workName={workData.find(item => item['WORK_CD'] === condition['WORK_CD'])['WORK_NM']}
            />}
        </PdfButton>
    ), [pdfData, condition, loginState, workData]);
    const handleLoad = useCallback(({visibleAoa}) => onLoad(4, visibleAoa), [onLoad]);


    return(
        <>
        <DataGrid
            title='기타관리'
            selectProc='SLT_BONDPAY_ETC'
            selectParam={condition}
            horizonMode
            totalGroup={['WORK_CD']}
            virtualized
            sortable
            filterable
            excelDownload={{ fileName: '채권현황-기타관리.xlsx', sheetName: '기타관리' }}
            headerItem={({visibleAoa}) => {
                setPdfData(visibleAoa);
                return pdfComponent;
            }}
            onLoad={handleLoad}
            stickyHeader
        >
            <DataColumn 
                fieldName='WORK_NM' 
                total={(values, data, group) => 
                    group == null ? `- 합 계 ${totalCount(values)} 건 -` : `(소 계 ${totalCount(values)} 건) ${data[0]['WORK_NM']}`
                } 
            />
            <DataColumn fieldName='PAY_NM' title='발생수단' />
            <DataColumn fieldName='BEF_BAL_AMT' title='전기 발생잔액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_SALE_AMT' title='당기 발생금액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_IN_AMT' title='당기 입금액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_BAL_AMT' title='당기 발생잔액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='BEF_MONTH_BAL_AMT' title='전월 발생잔액' total={(values) => values.reduce((result, value) => result + value, 0)} visible={false}/>
            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
        </>
    )
}


const NotGoodsCst = ({condition, workData, onLoad}) => {
    const [pdfData, setPdfData] = useState();
    const loginState = useSelector(state => state.loginReducer);

    const pdfComponent = useMemo(() => (
        <PdfButton title='채권현황'>
            {pdfData &&
            <BondPayListCardPdf
                pdfData={pdfData}
                condition={condition}
                loginState={loginState}
                workName={workData.find(item => item['WORK_CD'] === condition['WORK_CD'])['WORK_NM']}
            />}
        </PdfButton>
    ), [pdfData, condition, loginState, workData]);
    const handleLoad = useCallback(({visibleAoa}) => onLoad(5, visibleAoa), [onLoad]);


    return(
        <>
        <DataGrid
            title='비상품 매출처'
            selectProc='SLT_BONDPAY_NOTGOODS_CST'
            selectParam={condition}
            horizonMode
            totalGroup={['WORK_CD']}
            virtualized
            sortable
            filterable
            excelDownload={{ fileName: '채권현황-비상품매출처.xlsx', sheetName: '비상품매출처' }}
            headerItem={({visibleAoa}) => {
                setPdfData(visibleAoa);
                return pdfComponent;
            }}
            
            onLoad={handleLoad}
            stickyHeader
        >
            <DataColumn 
                fieldName='WORK_NM' 
                total={(values, data, group) => 
                    group == null ? `- 합 계 ${totalCount(values)} 건 -` : `(소 계 ${totalCount(values)} 건) ${data[0]['WORK_NM']}`
                } 
            />
            <DataColumn fieldName='BEF_BAL_P' title='전기 발생잔액(+)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='BEF_BAL_M' title='전기 발생잔액(-)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_SALE_AMT' title='당기 발생' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_IN_AMT' title='당기 입금' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_BAL_P' title='당기 발생잔액(+)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_BAL_M' title='당기 발생잔액(-)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='BEF_MONTH_BAL_P' title='전월 발생잔액(+)' total={(values) => values.reduce((result, value) => result + value, 0)} visible={false} />
            <DataColumn fieldName='BEF_MONTH_BAL_M' title='전월 발생잔액(-)' total={(values) => values.reduce((result, value) => result + value, 0)} visible={false} />
            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
        </>
    )
}


export default BondPayList