import React, { useEffect, useRef, useState } from 'react';
import { DataGrid, ComboBox, DataColumn, DatePicker, NumberField, SearchPanel, SearchItem, ComboSearch } from '../../component';
import { IconButton as IconBtn, makeStyles } from '@material-ui/core';
import { callProc } from '../../common/DBConnector';
import { OrderInCalcPdf } from '../../reports';
import PdfDialog from '../../component/PdfDialog';
import PrintIcon from '@material-ui/icons/Print';
import moment from 'moment';
import { isFieldDiff, numberFormat } from '../../common/Utils';

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
    searchPaper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    searchContainer: {
        flex: 1,
        display: 'inline-block',
        flexDirection: 'row',
    },
    searchItem: {
        display: 'inline-block',
        width: 200,
        marginRight: 100,
        marginBottom: theme.spacing(1),
    },
}))

const OrderInCalc = () => {
    const [condition, setCondition] = useState(null);
    const [tankAvg, setTankAvg] = useState();
    const [gdsAvg, setGdsAvg] = useState();
    const [qtyDay, setQtyDay] = useState(1);
    const [workData, setWorkData] = useState();
    const [pdfOpen, setPdfOpen] = useState(false);

    const inQty = useRef();
    const [inQtySum, setInQtySum] = useState();

    const [tankAvgList, setTankAvgList] = useState([])
    const [qtySum, setQtySum] = useState({})
    const [qtyGapSum, setQtyGapSum] = useState({})
    const [gdsAvgSum, setGdsAvgSum] = useState({})

    const classes = useStyles();



    useEffect(() => {
        const fetchData = async () => {
            const workData = await callProc('SLT_WORKPLACEINFO_AUTH');

            setWorkData(workData.data.reduce((result, item) => ({
                ...result,
                [item['WORK_CD']]: item['WORK_NM']
            }), {}))

        }
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setTankAvg(null);
            setGdsAvg(null);
            inQty.current = {};
            setInQtySum({});

            const tankAvg = await callProc('SLT_TANK_AVG_SALEQTY', condition);
            const gdsAvg = await callProc('SLT_GOODS_AVG_SALEQTY', condition);

            setTankAvg(tankAvg);
            setGdsAvg(gdsAvg);

        }

        condition && fetchData();
    }, [condition])

    useEffect(() => {
        if (tankAvg && gdsAvg && qtyDay !== '') {
            const tankData = tankAvg.data;
            setTankAvg({
                fields: tankAvg.fields,
                data: tankData.map(row => ({
                    ...row,
                    CALC_QTY: row['AVG_GAUGE_ELE'] * qtyDay
                }))
            });

            const gdsData = gdsAvg.data;
            setGdsAvg({
                fields: gdsAvg.fields,
                data: gdsData.map(row => ({
                    ...row,
                    CALC_QTY: row['AVG_GAUGE_ELE'] * qtyDay
                }))
            });
        }
        // eslint-disable-next-line
    }, [qtyDay]);

    useEffect(() => {
        if (gdsAvg && inQtySum) {
            const data = gdsAvg.data;
            setGdsAvg({
                fields: gdsAvg.fields,
                data: data.map(row => ({
                    ...row,
                    INQTY_SUM: inQtySum[row['CATE_NM']]
                }))
            })
        }
        // eslint-disable-next-line
    }, [inQtySum]);

    useEffect(() => {
        // 탱크입고현황 소계 구해주기위한계산
        const tankAvgList1 = tankAvg && tankAvg.data.reduce((result, row) => ([
            ...result,
            row['CATE_NM']
        ]), [])
        const tankAvgList = tankAvgList1 && tankAvgList1.filter((item, index) => tankAvgList1.indexOf(item) === index)
        const qtySum = tankAvg && tankAvg.data.reduce((result, row) => ({
            ...result,
            [row['CATE_NM']]: (result[row['CATE_NM']] || 0) + row['QTY']
        }), {})
        const qtyGapSum = tankAvg && tankAvg.data.reduce((result, row) => ({
            ...result,
            [row['CATE_NM']]: (result[row['CATE_NM']] || 0) + row['GAP_QTY']
        }), {})

        setQtySum(qtySum)
        setQtyGapSum(qtyGapSum)
        setTankAvgList(tankAvgList)
    }, [inQty, tankAvg])

    useEffect(() => {
        //입력 매입합계금액 출력으로 보내주기위한계산
        const gdsList = gdsAvg && gdsAvg.data.reduce((result, row) => ([
            ...result,
            row['CATE_NM'] 
        ]), [])
        // const calcQtyArr = gdsAvg && gdsAvg.data.reduce((result, row) => ({
        //     ...result,
        //     [row['CATE_NM']]: (row['CALC_QTY'] || 0) 
        // }), {})
        const priceArr = gdsAvg && gdsAvg.data.reduce((result, row) => ({
            ...result,
            [row['CATE_NM']]: (row['PRICE'] || 0) 
        }), {})
        setGdsAvgSum(gdsList && gdsList.reduce((result, item) => ({
            ...result,
            [item]: priceArr[item] * qtyDay * (inQtySum[item] || 0) * 200
        }), {}))

    }, [inQtySum, qtyDay, gdsAvg])

    const condWorkName = workData && condition && workData[condition['WORK_CD']];

    const toInt = (value) =>
        value != null && value.toString().trim() !== '' ? parseInt(value) : 0

    return(
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => {
                sessionStorage['WORK_CD'] = condition['WORK_CD'];
                setCondition(condition)}}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                
                <SearchItem
                    fieldName='BIZ_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='영업일'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                            }}
                        />
                    )}
                />

                <SearchItem
                    fieldName='CST_CD'
                    component={(state, setState, condition, setField) => {
                        let param = condition['CST_PARAM'] || null;
                        if (isFieldDiff(['WORK_CD'], condition, param)) {
                            param = { WORK_CD: condition['WORK_CD'] };
                            setField('CST_PARAM', param);
                        }
                        return(
                            <ComboSearch
                                label='거래처명'
                                selectProc='SLT_CUSTOMERINFO_BUY_NOTEND '
                                selectParam={param}
                                keyField='CST_CD'
                                valueField='CST_NM'
                                value={state}
                                onChange={(value) => setState(value)}
                            />
                        )}
                    }
                />
                
            </SearchPanel>
            
            {tankAvg && gdsAvg && inQty.current &&
            <PdfDialog
                open={pdfOpen}
                onClose={() => setPdfOpen(false)}
                title='출력'
            >
                <OrderInCalcPdf
                    tankAvg={tankAvg}
                    gdsAvg={gdsAvg}
                    inQty={inQty.current}
                    inQtySum={inQtySum}
                    tankAvgList={tankAvgList}
                    qtySum={qtySum}
                    qtyGapSum={qtyGapSum}
                    gdsAvgSum={gdsAvgSum}
                    condWorkName={condWorkName}
                />
            </PdfDialog>}
            <div className={classes.content}>
                <DataGrid
                    style={{ marginRight: 8, flex: 1 }}
                    title='탱크별 평균 수량'
                    dataSet={tankAvg}
                    horizonMode
                > 
                    <DataColumn fieldName='DS_CATE_NM' title='상품명' />
                    <DataColumn fieldName='AVG_GAUGE_ELE' title='평균 판매량' />
                    <DataColumn 
                        fieldName='CALC_QTY' 
                        title={<div>
                            {'예상 판매량 X'}
                            <NumberField
                                value={qtyDay}
                                style={{ width: 30 }}
                                min={1}
                                max={31}
                                onChange={value => setQtyDay(value)}
                            />
                        </div>}
                    />
                    <DataColumn fieldName='TOT_QTY' title='최대용량'/>
                    <DataColumn fieldName='QTY' title='현재수량'/>
                    <DataColumn fieldName='GAP_QTY' title='입고가능용량'/>
                    <DataColumn
                        title='입력 입고량'
                        value={(row, value, index) => (
                            <NumberField
                                oneWay
                                value={inQty.current[row['TNK_NO']]}
                                onChange={value => {
                                    inQty.current = {
                                        ...inQty.current,
                                        [row['TNK_NO']]: value ? parseInt(value) : 0
                                    };
                                }}
                                onBlur={e => {
                                    setInQtySum(tankAvg.data.reduce((result, dataRow) => ({
                                        ...result,
                                        [dataRow['CATE_NM']]: toInt(result[dataRow['CATE_NM']]) + toInt(inQty.current[dataRow['TNK_NO']])
                                    }), {}));
                                }}
                            />
                        )}
                    />
                    <DataColumn fieldName='WORK_CD' visible={false} />
                    <DataColumn fieldName='CATE_NM' visible={false} />
                </DataGrid>

                <DataGrid
                    style={{ marginLeft: 8, flex: 0.7  }}
                    title='상품별 평균 수량'
                    dataSet={gdsAvg}
                    horizonMode
                    headerItem={() =>
                        <IconBtn
                            onClick={() => setPdfOpen(true)}
                        >
                            <PrintIcon />
                        </IconBtn>
                    }
                > 
                    <DataColumn fieldName='CATE_NM' title='상품명' />
                    <DataColumn fieldName='AVG_GAUGE_ELE' title='평균 판매량' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                    <DataColumn 
                        fieldName='CALC_QTY' 
                        title={<div>
                            {'예상 판매량 X'}
                            <NumberField
                                value={qtyDay}
                                style={{ width: 30 }}
                                min={1}
                                max={31}
                                onChange={value => setQtyDay(value)}
                            />
                        </div>}
                        total={(values) => values.reduce((result, value) => result + value, 0)}
                    />
                    <DataColumn 
                        fieldName='IN_AMT' 
                        title='매입 금액' 
                        value={(row, value, index) => (numberFormat(row['CALC_QTY'] * row['PRICE'] * 200))} />
                    <DataColumn 
                        fieldName='INQTY_SUM'
                        title='입력 입고량'
                        value={(row, value, index) => (value)}
                    />
                    <DataColumn 
                        fieldName='IN_EMT_AMT' 
                        title='입력매입금액'
                        value={(row, value, index) => (
                            row['INQTY_SUM'] ? numberFormat(row['PRICE'] * row['INQTY_SUM'] * 200) : 0
                        )}
                    />
                    <DataColumn fieldName='WORK_CD' visible={false} />
                    <DataColumn fieldName='GDS_TYPE' visible={false} />
                </DataGrid>
            </div>
        </div>
    )
}

export default OrderInCalc