import React, { useState, useEffect } from 'react';
import { DataGrid, ComboBox, PaperTitle, DatePicker, DataColumn, NumberField, IconButton } from '../../component';
import { callProc } from '../../common/DBConnector';
import {  makeStyles, Paper, TextField } from '@material-ui/core';
import { useFieldInputs } from '../../common/Utils';
import CheckIcon from '@material-ui/icons/Check';
import { useSnackbar } from 'notistack';
import { adjType } from '../../common/Dictionary';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
        flex: 2,
        marginRight: theme.spacing(1),
    },
    enroll: {
        flex: 1,
        marginRight: theme.spacing(1),
    },
    paperContent: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding: theme.spacing(2),
        paddingTop: 0
    },
    field: {
        width: 180,
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2),
    },
    iconbutton : {
        alignSelf: 'flex-end',
        marginTop: theme.spacing(4),
        marginRight: theme.spacing(1),
    },
    searchPaper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    searchContainer: {
        flex: 1,
        display: 'inline-block',
        flexDirection: 'row',
    },
    searchItem: {
        display: 'inline-block',
        width: 200,
        marginRight: 100,
        marginBottom: theme.spacing(1),
    },
}))

const GaugeAdjustEnrollDel = () => {
    const classes = useStyles();
    const clsState = useSelector(state => state.clsDateReducer);
    const [input, setInput] = useFieldInputs({
        WORK_CD: sessionStorage['WORK_CD'],
        BIZ_DATE: clsState[sessionStorage['WORK_CD']],
    });
    const [selectedRow, setSelectedRow] = useState();

    const condition = useMemo(() => input['WORK_CD'] && input['BIZ_DATE'] && input, [input]);

    const gridRefresh = () => {
        setInput({ type: 'UPDATE', value: { ...input } });
    }



    const onDelete = async(row) => {
        const result = await callProc('DLT_GAUGE_ADJUST_ENROLL', row);

        if(!result.err){
            gridRefresh();
        }
    }

    return(
        <div className={classes.container}>
            <Paper className={classes.searchPaper}>
                <div className={classes.searchContainer}>
                    <div className={classes.searchItem}>
                        <ComboBox
                            // style={{width: 200}}
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={condition && condition['WORK_CD']}
                            onChange={value => {
                                if (input['WORK_CD'] !== value) {
                                    sessionStorage['WORK_CD'] = value;
                                    setInput('BIZ_DATE', clsState[value]);
                                    setInput('WORK_CD', value);
                                }
                            }}
                        />
                    </div>
                    <div className={classes.searchItem}>
                        <DatePicker
                            label='영업일'
                            minDate={clsState[input['WORK_CD']]}
                            value={condition && condition['BIZ_DATE']}
                            onChange={value => {
                                if (input['BIZ_DATE'] !== value) {
                                    setInput('BIZ_DATE', value);
                                    if(condition && condition['BIZ_DATE'] < clsState[condition['WORK_CD']]) {
                                        setInput({ type: 'UPDATE', value: { ...input, 'BIZ_DATE': value } });
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                <IconButton
                    tooltip=''
                    icon={<SearchIcon />}
                    onClick={gridRefresh}
                />
            </Paper>
            <div className={classes.content}>
                <DataGrid
                    className={classes.info}
                    title='계기'
                    selectProc='SLT_GAUGE_ADJUST_ENROLL_DEL'
                    selectParam={condition}
                    onDelete={onDelete}
                    onSelect={row => setSelectedRow(row)}
                    selectionMode='single'
                    horizonMode
                >
                    <DataColumn fieldName='NZL_NO' title='노즐번호' />
                    <DataColumn fieldName='ADJ_TYPE' value={(row, value) => adjType[value]}/>
                    <DataColumn fieldName='BEF_GAUGE_ELE' title='시작계기' />
                    <DataColumn fieldName='CUR_GAUGE_ELE' title='마감계기' />
                    <DataColumn fieldName='GAUGE_ELE' title='계기차' />
                    <DataColumn fieldName='OUT_QTY' title='출하량' />
                    <DataColumn fieldName='EMPTY_QTY' title='공회전' />
                    <DataColumn fieldName='MOVE_QTY' title='이관' />
                    <DataColumn fieldName='HOME_QTY' title='홈로리이관' />
                    <DataColumn fieldName='TEST_QTY' title='검량' />
                    <DataColumn fieldName='SELF_QTY' title='자가소비' />
                    <DataColumn fieldName='KEEP_OUT_QTY' title='보관출고' />
                    <DataColumn fieldName='SERVICE_QTY' title='서비스' />
                    <DataColumn fieldName='ETC_QTY' title='기타' />
                    <DataColumn fieldName='GDS_TYPE' visible={false} />
                    <DataColumn fieldName='REG_TYPE' visible={false} />
                    <DataColumn fieldName='GAUGE_CD' visible={false} />
                    <DataColumn fieldName='WORK_CD' visible={false} />
                    <DataColumn fieldName='BIZ_DATE' visible={false} />
                    <DataColumn fieldName='REMARK' visible={false} />
                </DataGrid>
                <EnrollPaper selectedRow={selectedRow} condition={condition} gridRefresh={gridRefresh} />
            </div>
        </div>

    )
}


const paperStyle = makeStyles((theme) => ({
    enroll: {
        flex: 1,
        marginRight: theme.spacing(1),
        height: '100%',
        overflowY: 'auto',
    },
    paperContent: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding: theme.spacing(2),
        paddingTop: 0
    },
    field: {
        width: 180,
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2),
    },
    iconbutton : {
        alignSelf: 'flex-end',
        marginTop: theme.spacing(4),
        marginRight: theme.spacing(1),
    },
}))

const EnrollPaper = ({selectedRow, condition, gridRefresh}) => {
    const classes = paperStyle();
    const [insData, setInsData] = useFieldInputs();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if(selectedRow != null){
            setInsData('GDS_TYPE', selectedRow['GDS_TYPE']);
            setInsData('ADJ_TYPE', selectedRow['ADJ_TYPE']);
            setInsData('REMARK', selectedRow['REMARK']);
            setInsData('NZL_NO', selectedRow['NZL_NO']);
            setInsData('BEF_GAUGE_ELE', selectedRow['BEF_GAUGE_ELE']);
            setInsData('CUR_GAUGE_ELE', selectedRow['CUR_GAUGE_ELE']);
            setInsData('GAUGE_ELE', selectedRow['GAUGE_ELE']);
            setInsData('OUT_QTY', selectedRow['OUT_QTY']);
            setInsData('EMPTY_QTY', selectedRow['EMPTY_QTY']);
            setInsData('MOVE_QTY', selectedRow['MOVE_QTY']);
            setInsData('HOME_QTY', selectedRow['HOME_QTY']);
            setInsData('TEST_QTY', selectedRow['TEST_QTY']);
            setInsData('SELF_QTY', selectedRow['SELF_QTY']);
            setInsData('KEEP_OUT_QTY', selectedRow['KEEP_OUT_QTY']);
            setInsData('SERVICE_QTY', selectedRow['SERVICE_QTY']);
            setInsData('ETC_QTY', selectedRow['ETC_QTY']);
            setInsData('GAUGE_CD', selectedRow['GAUGE_CD']);
            setInsData('WORK_CD', selectedRow['WORK_CD']);
            setInsData('BIZ_DATE', selectedRow['BIZ_DATE']);
        }
        else{
            setInsData('WORK_CD', condition && condition['WORK_CD']);
            setInsData('BIZ_DATE', condition && condition['BIZ_DATE']);
            setInsData('REG_TYPE', 0);
            setInsData('ADJ_TYPE', 0);
            setInsData('REMARK', '');
            setInsData('NZL_NO', null);
            setInsData('GDS_TYPE', null);
            setInsData('NZL_NO', null);
            setInsData('BEF_GAUGE_ELE', 0);
            setInsData('CUR_GAUGE_ELE', 0);
            setInsData('GAUGE_ELE', 0);
            setInsData('OUT_QTY', 0);
            setInsData('EMPTY_QTY', 0);
            setInsData('MOVE_QTY', 0);
            setInsData('HOME_QTY', 0);
            setInsData('TEST_QTY', 0);
            setInsData('SELF_QTY', 0);
            setInsData('KEEP_OUT_QTY', 0);
            setInsData('SERVICE_QTY', 0);
            setInsData('ETC_QTY', 0);
            setInsData('GAUGE_CD', null);
        }
    }, [selectedRow, condition, setInsData])

    const valid = () => {
        if(String(insData['REG_TYPE']) === '2' && selectedRow == null){
            enqueueSnackbar('대체 대상을 선택해주세요.', {variant: 'error'});
            return false;
        }

        if(insData['REMARK'] == null || insData['REMARK'] === ''){
            enqueueSnackbar('조정/대체 시 사유를 작성해주세요.', {variant: 'error'});
            return false;
        }

        return true;
    }

    const isEnrollable = useMemo(() => selectedRow != null, [selectedRow]);

    const onUpdate = async() => {
        if (valid()) {
            const result = await callProc('UPT_GAUGE_ADJUST_ENROLL_DEL', insData);

            if (!result.err) {
                gridRefresh();
                enqueueSnackbar('저장이 완료되었습니다.');
            }
        }
    }

    return (
        <Paper className={classes.enroll}>
            <PaperTitle>
                계기 조정/대체 수정
                    </PaperTitle>
            <div className={classes.paperContent}>
                <ComboBox
                    className={classes.field}
                    label='조정/대체'
                    data={adjType}
                    value={insData['ADJ_TYPE'] || 0}
                    onChange={value => {
                        setInsData('ADJ_TYPE', value);
                    }}
                    disabled={true}
                />
                <TextField
                    className={classes.field}
                    style={{ width: 300 }}
                    label='조정/대체사유'
                    value={insData['REMARK'] || ''}
                    onChange={e => setInsData('REMARK', e.target.value)}
                    disabled={!isEnrollable}
                />
                <ComboBox
                    className={classes.field}
                    label='노즐번호'
                    selectProc='SLT_NOZZLEINFO_DATE_ALL'
                    selectParam={condition}
                    keyField='NZL_NO'
                    valueField='NZL_NO'
                    value={insData['NZL_NO'] || null}
                    onChange={value => {
                        setInsData('NZL_NO', value);
                    }}
                    disabled
                />
                <div>
                    <NumberField
                        className={classes.field}
                        label='시작계기'
                        value={insData && insData['BEF_GAUGE_ELE']}
                        onChange={value => setInsData('BEF_GAUGE_ELE', value)}
                        disabled={!isEnrollable}
                    />
                    <NumberField
                        className={classes.field}
                        label='마감계기'
                        value={insData && insData['CUR_GAUGE_ELE']}
                        onChange={value => {
                            setInsData('GAUGE_ELE', value - insData['BEF_GAUGE_ELE']);
                            setInsData('CUR_GAUGE_ELE', value);
                        }}
                        disabled={!isEnrollable}
                    />
                </div>
                <div>
                    <NumberField
                        className={classes.field}
                        label='계기차'
                        value={insData && insData['GAUGE_ELE']}
                        onChange={value => setInsData('GAUGE_ELE', value)}
                        disabled
                    />
                </div>
                <div>
                    <NumberField
                        className={classes.field}
                        label='공회전'
                        value={insData && insData['EMPTY_QTY']}
                        onChange={value => {
                            setInsData('EMPTY_QTY', value);
                        }}
                        disabled={!isEnrollable}
                    />
                    <NumberField
                        className={classes.field}
                        label='이관'
                        value={insData && insData['MOVE_QTY']}
                        onChange={value => {
                            setInsData('MOVE_QTY', value);
                        }}
                        disabled={!isEnrollable}
                    />
                </div>
                <div>
                    <NumberField
                        className={classes.field}
                        label='홈로리이관'
                        value={insData && insData['HOME_QTY']}
                        onChange={value => {
                            setInsData('HOME_QTY', value);
                        }}
                        disabled={!isEnrollable}
                    />
                    <NumberField
                        className={classes.field}
                        label='검량'
                        value={insData && insData['TEST_QTY']}
                        onChange={value => {
                            setInsData('TEST_QTY', value);
                        }}
                        disabled={!isEnrollable}
                    />
                </div>
                <div>
                    <NumberField
                        className={classes.field}
                        label='자가소비'
                        value={insData && insData['SELF_QTY']}
                        onChange={value => {
                            setInsData('SELF_QTY', value);
                        }}
                        disabled={!isEnrollable}
                    />
                    <NumberField
                        className={classes.field}
                        label='보관출고'
                        value={insData && insData['KEEP_OUT_QTY']}
                        onChange={value => {
                            setInsData('KEEP_OUT_QTY', value);
                        }}
                        disabled={!isEnrollable}
                    />
                </div>
                <div>
                    <NumberField
                        className={classes.field}
                        label='서비스'
                        value={insData && insData['SERVICE_QTY']}
                        onChange={value => {
                            setInsData('SERVICE_QTY', value);
                        }}
                        disabled={!isEnrollable}
                    />
                    <NumberField
                        className={classes.field}
                        label='기타'
                        value={insData && insData['ETC_QTY']}
                        onChange={value => {
                            setInsData('ETC_QTY', value);
                        }}
                        disabled={!isEnrollable}
                    />
                </div>
                <IconButton
                    className={classes.iconbutton}
                    tooltip='확인'
                    icon={<CheckIcon />}
                    onClick={onUpdate}
                />
            </div>

        </Paper>
    )
}

export default GaugeAdjustEnrollDel