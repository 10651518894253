import React, { useState, useEffect, useReducer, useRef, Fragment, useCallback, useMemo } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { Checkbox, Typography, Toolbar, CircularProgress, lighten, TextField, Collapse, Box, Button, Select, MenuItem } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import FilterListIcon from '@material-ui/icons/FilterList';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import GridOnIcon from '@material-ui/icons/GridOn';
import { getColumnName, callProc, getProcInfo, getTable, getKeys } from '../common/DBConnector';
import { aoaToWorkbook, downloadWorkbook, moveArrayItem, numberFormat } from '../common/Utils';
import { useSnackbar } from 'notistack';
import { DataColumn, ProgressDialog, DateTimePicker, DatePicker, TimePicker, NumberField, GridHeaderBand, IconButton } from '.';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const rowHeight = 49;
const cellPadding = '12px';
const virtualPaddingCnt = 10;
const virtualPadding = virtualPaddingCnt * rowHeight;

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        position: 'relative',
        '&& .MuiTable-root': {
            borderCollapse: 'separate',
        },
    },
    virtualizediContainer: {
        userSelect: 'none',
    },
    toolbar: {
        position: 'sticky',
        top: 0,
        left: 0,
        backgroundColor: ({ stickyHeader }) => stickyHeader ? theme.palette.secondary.main : 'transparent',
        zIndex: 2,
    },
    highlight: {
        backgroundColor: lighten(theme.palette.primary.main, 0.85) + ' !important',
    },
    table: {
        [theme.breakpoints.down('sm')]: {
            whiteSpace: 'nowrap',
        },
    },
    row: {
        height: rowHeight + 'px',
        '--cell-color': theme.palette.secondary.main,
        backgroundColor: 'var(--cell-color)',
        '& td': {
            backgroundColor: 'var(--cell-color)',
        },
        '&:hover': {
            '--cell-color': lighten(theme.palette.primary.main, 0.95),
        },
    },
    headerRow: {
        height: rowHeight + 'px',
        '--cell-color': theme.palette.background.default,
        backgroundColor: 'var(--cell-color)',
        '& th': {
            backgroundColor: 'var(--cell-color)',
            position: 'sticky',
            top: 0,
        },
    },
    sortableHeader: {
        cursor: 'pointer',
    },
    selectedRow: {
        '--cell-color': lighten(theme.palette.primary.main, 0.85) + ' !important',
    },
    unEditRow: {
        opacity: 0.5,
    },
    virtualRow: {
        visibility: 'collapse',
    },
    tableCell: {
        borderBottomColor: 'transparent',
        padding: '0px ' + cellPadding,
        '--fixed-z-index': 3,
    },
    headerCell: {
        padding: '0px ' + cellPadding,
        paddingRight: '0px',
        left: 'unset',
        zIndex: 4,
        '--fixed-z-index': 5,
    },
    fixedCell: {
        position: 'sticky',
        left: 0,
        zIndex: 'var(--fixed-z-index)',
    },
    gradientCell: {
        background: `linear-gradient(to right,
            var(--cell-color) 90%,
            transparent
        ) !important`,
    },
    totalCell: {
        '--cell-color': theme.palette.background.dark,
        borderBottomColor: theme.palette.divider,
    },
    footerCell: {
        zIndex: 2,
        position: 'sticky',
        bottom: 0,
        left: 0,
        borderWidth: 0,
    },
    headerContainer: {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: theme.palette.divider,
        paddingRight: cellPadding,
        whiteSpace: 'nowrap'
    },
    numberCell: {
        textAlign: 'right',
    },
    mergeCell: {
        '--cell-color': theme.palette.background.default,
    },
    expandCell: {
        borderBottomColor: theme.palette.divider,
        padding: 0,
    },
    hide: {
        display: 'none',
    },
    horContainer: {
        width: 0,
    },
    horMod: {
        whiteSpace: 'nowrap',
    },
    cellDivider: {
        borderBottomColor: theme.palette.divider,
    },
    progress: {
        display : 'flex',
        justifyContent : 'center',
    },
}));

const PaddingCell = styled(TableCell)`
    &:first-child {
        padding-left: 12px;
    }
    &:last-child {
        padding-right: 12px;
    }
`
export { PaddingCell };

const DataGrid = (props) => {
    const {
        children,
        dataSet,
        selectProc,
        selectParam,
        updateProc,
        insertProc,
        deleteProc,
        table,
        keys,
        onLoad,
        onDataLoad,
        onDelete,
        onInsert,
        onUpdate,
        onUpdateStart,
        onInsertStart,
        refreshable,
        expandable,
        defaultExpand,
        title,
        onSelect,
        selectionMode,
        selectable,
        deleteMode,
        headerItem,
        stickyHeader,
        fixedColumns,
        totalGroup,
        totalSort,
        eachTotalGroup,
        hideTotal,
        style,
        rowProps,
        cellProps,
        headerRowProps,
        headerCellProps,
        headerLess,
        borderLess,
        className,
        horizonMode,
        virtualized,
        excelDownload,
        sortable,
        filterable,
    } = props;

    const [data, setData] = useState({
        data: [],
        fields: [],
        headerBand: [],
        columns: [],
    });
    const [selected, setSelected] = useState([]);
    const [editRow, setEditRow] = useState(null);
    const [renderPos, setRenderPos] = useState(0);
    const [filterInput, setFilterInput] = useState({});
    const [filterVisible, setFilterVisible] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const columnName = useRef({});
    const keyFields = useRef([]);
    const headerRowRef = useRef();

    const multiSelIdx = useRef();
    const lastSelIdx = useRef();

    const delSnack = useRef();

    useEffect(() => {
        if (selectParam) {
            setDataLoading(true);
            refresh();
        }
        // eslint-disable-next-line
    }, [selectProc, selectParam, dataSet])

    const classes = useStyles(props);

    const selectableCnt = useMemo(() => data.data.reduce((result, itm) => selectable(itm) ? result + 1 : result, 0), [data, selectable]);

    const handleSelectAllClick = (event) => {
        let newSelecteds;
        if (event.target.checked) {
            newSelecteds = data.data.reduce((result, itm, i) => selectable(itm) ? [...result, i] : result, []);
        } else {
            newSelecteds = [];
        }
        onSelect && onSelect(newSelecteds.map(idx => data.data[idx]));
        setSelected(newSelecteds);
    };

    const handleClick = (event, index) => {
        const row = data.data[index];
        switch (selectionMode) {
            case 'multi':
                let newSelected = [...selected];
                if (event.shiftKey && multiSelIdx.current != null) {
                    const multiSel = multiSelIdx.current;
                    const lastSel = lastSelIdx.current;

                    for (let i = Math.min(lastSel, index); i <= Math.max(lastSel, index); i++) {
                        if (newSelected.indexOf(i) !== -1) {
                            newSelected.splice(newSelected.indexOf(i), 1);
                        }
                    }
                    for (let i = Math.min(multiSel, index); i <= Math.max(multiSel, index); i++) {
                        if (newSelected.indexOf(i) === -1 && selectable(data.data[i])) {
                            newSelected.push(i);
                        }
                    }
                    getSelection().empty();
                } else {
                    const arrIdx = selected.indexOf(index);

                    if (arrIdx === -1) {
                        selectable(data.data[index]) && newSelected.push(index);
                    } else {
                        newSelected.splice(arrIdx, 1);
                    }

                    multiSelIdx.current = index;
                }

                newSelected.sort((a, b) => a - b);

                lastSelIdx.current = index;
                onSelect && onSelect(newSelected.map(idx => data.data[idx]));
                setSelected(newSelected);
                break;
            case 'single':
                if (selectable(row)) {
                    onSelect && onSelect(row);
                    setSelected([index]);
                }
                break;
            default:
                if (selectable(row)) {
                    onSelect && onSelect(row);
                }
                break;
        }
    };

    const runSort = (fieldName, filterData, filterInputData) => {
        let visibleTotal;
        let sortData;
        if (filterData) {
            visibleTotal = getTotalRows(filterData, data.columns, data.hasTotal)
            sortData = data.sorted;
        } else {
            filterData = data.filterData;
            filterInputData = filterInput;
            visibleTotal = data.visibleTotal;

            sortData = data.sorted.filter(item => item.fieldName !== fieldName);
            if (data.sorted[0] && data.sorted[0].fieldName === fieldName) {
                if (data.sorted[0].asc) {
                    sortData = [{ fieldName, asc: false }, ...sortData];
                } else {
                    // do nothing
                }
            } else {
                sortData = [{ fieldName, asc: true }, ...sortData];
            }
        }

        const result = sortData.reduceRight((result, sortItem) => (
            result.sort((a, b) => {
                const groupA = JSON.stringify(getGroup(a));
                const groupB = JSON.stringify(getGroup(b));
                const itemA = a[sortItem.fieldName];
                const itemB = b[sortItem.fieldName];
                if (!totalSort && totalGroup && groupA !== groupB) {
                    return 0;
                }
                return sortItem.asc ?
                    (itemA > itemB ? 1 : itemA === itemB ? 0 : -1) :
                    (itemA < itemB ? 1 : itemA === itemB ? 0 : -1)
            })
        ), [...filterData]);

        const visibleAoa = getAoa(result, data.columns, visibleTotal, data.hasTotal);
        setData({
            ...data,
            data: result,
            sorted: sortData,
            filterData,
            visibleAoa,
            visibleTotal,
        });
        onLoad({
            srcAoa: data.srcAoa,
            visibleAoa,
            filterInput: filterInputData,
        });
    }

    const runFilter = (fieldName, values) => {
        const filterResult = {
            ...filterInput,
            [fieldName]: values,
        };
        if (values.length === 0) {
            delete filterResult[fieldName];
        }

        const visibleColumn = data.columns.reduce((result, item) => (
            item.props.visible ?
            {
                ...result,
                [item.props.fieldName]: item.props.value
            } :
            result
        ), {});

        const result = data.srcData.filter((row, rowPos) => {
            for (let key in filterResult) {
                const toValue = visibleColumn[key];
                const isNumber = typeof(row[key]) === 'number';
                let rowValue = toValue ?
                    toValue(row, row[key], rowPos) : row[key];
                rowValue = String(rowValue == null ? '' : rowValue);

                let visible = false;
                for (let filterValue of filterResult[key]) {
                    if (isNumber) {
                        filterValue = filterValue.replaceAll(',', '');
                    }
                    if (rowValue === filterValue) {
                        visible = true;
                        break;
                    }
                }
                if (!visible) return false;
            }
            return true;
        });

        setFilterInput(filterResult);
        runSort(fieldName, result, filterResult);
    }

    const refresh = async() => {
        delSnack.current && closeSnackbar(delSnack.current);

        onSelect && onSelect(undefined);
        setFilterInput({});
        setEditRow(null);
        setSelected([]);
        multiSelIdx.current = null;
        lastSelIdx.current = null

        let data = dataSet ||
            (selectProc && await callProc(selectProc, selectParam)) ||
            (table && await getTable(table));

        if (!data) return;

        const child = React.Children.toArray(children);
        let dataColumns = [];
        let headerBand = [];
        for (let i = 0; i < child.length; i++) {
            const item = child[i];
            switch (item.type) {
                case DataColumn:
                    dataColumns = [
                        ...dataColumns,
                        item,
                    ]
                    break;
                case GridHeaderBand:
                    headerBand = [
                        ...headerBand,
                        React.Children.toArray(item.props.children),
                    ]
                    break;
                default:
                    break;
            }
        }

        columnName.current = await getColumnName();
        const uptMeta = updateProc && await getProcInfo(updateProc);
        const insMeta = insertProc && await getProcInfo(insertProc);
        
        keyFields.current = keys || await getKeys(table);

        data = onDataLoad ? onDataLoad(data) : data;

        
        if (data) {
            const columns = getColumns(data.fields, dataColumns);
            const hasTotal = columns.reduce((result, col) => result || (col.props.total != null), false);
            const totalRows = getTotalRows(data.data, columns, hasTotal);
            const longestRow = getLongestRow(data.data, columns, totalRows);
            const filterableData = getFilterableData(data.data, columns);
            const srcAoa = getAoa(data.data, columns, totalRows, hasTotal);

            onLoad({
                srcAoa,
                visibleAoa: srcAoa,
                filterInput: {},
            });
            setData({
                srcData: data.data,
                filterData: [...data.data],
                data: [...data.data],
                fields: data.fields,
                sorted: [],
                columns,
                headerBand,
                hasTotal,
                totalRows,
                visibleTotal: totalRows,
                filterableData,
                longestRow,
                uptMeta,
                insMeta,
                srcAoa,
                visibleAoa: srcAoa,
            });
        }
        setDataLoading(false);
    }

    const getColumns = (fields, children) => {
        const childrenObj = React.Children.toArray(children).reduce((result, item, i) => ({
            ...result,
            [item.props.fieldName || 'child' + i]: item
        }), {});

        let fieldNames = Object.keys(childrenObj).reduce((result, key) => (
            result.includes(key) ? result : [...result, key]
        ), [...fields]);
        fieldNames = fieldNames.reduce((result, item) => (
            childrenObj[item] && childrenObj[item].props.position ?
            moveArrayItem(
                result,
                result.indexOf(item),
                result.indexOf(childrenObj[item].props.position)
            ) :
            result
        ), fieldNames);
        
        return fieldNames.reduce((result, fieldName) => [
            ...result, childrenObj[fieldName] || <DataColumn fieldName={fieldName} />
        ], []);
    }

    const getTotalRow = useCallback((data, group, columns) => (
        columns.reduce((result, col, i) => {
            const { fieldName, total, visible } = col.props;
            return visible ? {
                ...result,
                [fieldName || i]: total && total(data.map(row => row[fieldName]), data, group)
            } : result;
        }, {})
    ), []);

    const getTotalRows = (data, columns, hasTotal) => {
        if (hasTotal && totalGroup.length > 0) {
            return data.reduce((result, row, i) => {
                const curGroup = getGroup(row);
                const curGroupStr = JSON.stringify(curGroup);
                const nextGroup = getGroup(data[i + 1]);
                const nextGroupStr = JSON.stringify(nextGroup);

                if (curGroupStr !== nextGroupStr && !Object.values(result).find(item => item.key === curGroupStr)) {
                    let totalRows;
                    let components;
                    if (eachTotalGroup) {
                        totalRows = [];
                        components = [];
                        totalGroup.reduce((group, fieldName) => {
                            group = { ...group, [fieldName]: curGroup[fieldName] };
                            const arrGroup = Object.keys(group);
                            if (!nextGroup || curGroup[fieldName] !== nextGroup[fieldName]) {
                                const groupStr = JSON.stringify(getGroup(row, arrGroup));
                                const totalData = data.reduce((result, tRow) => (
                                    JSON.stringify(getGroup(tRow, arrGroup)) === groupStr ? [...result, tRow] : result
                                ), []);

                                totalRows = [getTotalRow(totalData, group, columns), ...totalRows];
                                components =  [
                                    <TotalRow
                                        key={groupStr}
                                        row={totalRows[0]}
                                        group={group}
                                        expandable={expandable}
                                        isUpdatable={isUpdatable()}
                                        isRowDeletable={isRowDeletable()}
                                        selectionMode={selectionMode}
                                        editRow={editRow}
                                        cellProps={cellProps}
                                        rowProps={rowProps}
                                        fixedColumns={fixedColumns}
                                    />,
                                    ...components
                                ]
                            }

                            return group;
                        }, [])
                    } else {
                        const totalData = data.reduce((result, tRow) => (
                            JSON.stringify(getGroup(tRow)) === curGroupStr ? [...result, tRow] : result
                        ), []);
    
                        totalRows = [getTotalRow(totalData, curGroup, columns)];
                        components = [
                            <TotalRow
                                key={curGroupStr}
                                row={totalRows[0]}
                                group={curGroup}
                                expandable={expandable}
                                isUpdatable={isUpdatable()}
                                isRowDeletable={isRowDeletable()}
                                selectionMode={selectionMode}
                                editRow={editRow}
                                cellProps={cellProps}
                                rowProps={rowProps}
                                fixedColumns={fixedColumns}
                            />
                        ]
                    }

                    return {
                        ...result,
                        [curGroupStr]: {
                            key: curGroupStr,
                            group: curGroup,
                            rows: totalRows,
                            rowPos: i,
                            components
                        }
                    }
                } else {
                    return result;
                }
            }, {})
        } else {
            return null;
        }
    }

    const getLongestRow = (data, columns, totalRows) => {
        if (!virtualized) return {};

        const visibleColumn = columns.reduce((result, item) => (
            item.props.visible ?
            {
                ...result,
                [item.props.fieldName]: item.props.value
            } :
            result
        ), {});

        let result = data.reduce((prevData, row, rowPos) => (
            Object.keys(visibleColumn).reduce((prevValue, field) => {
                const toValue = visibleColumn[field];
                let resValue = prevValue[field];
                let value = toValue ?
                    toValue(row, row[field], rowPos) :
                    typeof(row[field]) === 'number' ? numberFormat(row[field]) : row[field];

                if (value == null) {
                    value = '';
                }

                return value != null && String(resValue).length < String(value).length ?
                {
                    ...prevValue,
                    [field]: value
                } :
                prevValue;
            }, prevData)
        ), Object.keys(visibleColumn).reduce((result, field) => ({ ...result, [field]: '' }), {}));

        if (totalRows) {
            result = Object.values(totalRows).reduce((prevData, item) => {
                return item.rows.reduce((prevData, row) => {
                    return Object.keys(visibleColumn).reduce((prevValue, field) => {
                        let resValue = prevValue[field];
                        let value = typeof(row[field]) === 'number' ? numberFormat(row[field]) : row[field];

                        if (value == null) {
                            value = '';
                        }

                        return value != null && String(resValue).length < String(value).length ?
                        {
                            ...prevValue,
                            [field]: value
                        } :
                        prevValue;
                    }, prevData)
                }, prevData)
            }, result);
        }

        return result;
    }

    const getFilterableData = (data, columns) => {
        if (!filterable) return;

        const visibleColumn = columns.reduce((result, item) => (
            item.props.visible ?
            {
                ...result,
                [item.props.fieldName]: item.props.value
            } :
            result
        ), {});

        return data.reduce((prevData, row, rowPos) => (
            Object.keys(visibleColumn).reduce((prevValue, field) => {
                const toValue = visibleColumn[field];
                let value = toValue ?
                    toValue(row, row[field], rowPos) :
                    typeof(row[field]) === 'number' ? numberFormat(row[field]) : row[field];

                return value != null && typeof(value) !== 'object' && !prevValue[field].includes(value) ?
                {
                    ...prevValue,
                    [field]: [...prevValue[field], value]
                } :
                prevValue;
            }, prevData)
        ), Object.keys(visibleColumn).reduce((result, field) => ({ ...result, [field]: [] }), {}));
    }

    const getAoa = (data, columns, totalRows, hasTotal) => {
        const formatMap = value => typeof(value) === 'number' ? numberFormat(value) : (value || '');

        let res = [columns.reduce((result, col) => (
            col.props.visible ? [
                ...result,
                col.props.title || (columnName.current[col.props.fieldName] ? columnName.current[col.props.fieldName][0] : '')
            ] : result
        ), [])];

        res = [...res, ...data.flatMap((row, rowPos) => {
            const visibleRow = columns.reduce((result, col, i) => {
                const { value: toValue } = col.props;
                const rowValue = row[col.props.fieldName];
                const value = toValue ?
                    toValue(row, rowValue, rowPos) :
                    typeof(rowValue) === 'number' ? numberFormat(rowValue) : rowValue;

                return col.props.visible ?
                    [ ...result, value ] :
                    result;
            }, []);

            const curGroupStr = JSON.stringify(getGroup(row));
            const nextGroupStr = JSON.stringify(getGroup(data[rowPos + 1]));
            if (totalRows && !totalSort && curGroupStr !== nextGroupStr) {
                return [
                    visibleRow,
                    ...totalRows[curGroupStr].rows.map(totalRow => Object.values(totalRow).map(formatMap))
                ]
            } else {
                return [visibleRow];
            }
        })]

        if (!hideTotal && hasTotal) {
            if (totalRows && totalSort) {
                Object.values(totalRows)
                    .sort((a, b) => totalSort(a.group, b.group))
                    .flatMap(({ rows }) => rows.map(row => res.push(Object.values(row).map(formatMap))))
            }
            const allTotalRow = getTotalRow(data, undefined, columns);
            res.push(Object.values(allTotalRow).map(formatMap));
        }

        return res;
    }

    const isUpdatable = () => onUpdate != null || updateProc != null

    const isInsertable = () => onInsert != null || insertProc != null

    const isDeletable = () => onDelete != null || deleteProc != null

    const isRowDeletable = () => isDeletable() && selectionMode !== 'multi'

    const onRequestUpdate = async(row) => {
        setLoading(true);

        let result;
        if (onUpdate) {
            result = await onUpdate(row);
        } else if(updateProc) {
            result = await callProc(updateProc, row);
        }

        if ((result == null || !result.err) && result !== false) {
            setEditRow(null);
            await refresh();
            enqueueSnackbar('저장이 완료되었습니다.');
        }

        setLoading(false);
    }

    const onRequestInsert = async(row) => {
        setLoading(true);

        let result;
        if (onInsert) {
            result = await onInsert(row);
        } else if(insertProc) {
            result = await callProc(insertProc, row);
        }

        if ((result == null || !result.err) && result !== false) {
            setEditRow(null);
            await refresh();
            enqueueSnackbar('저장이 완료되었습니다.');
        }

        setLoading(false);
    }
    
    const onRequestDelete = async(items) => {
        setEditRow(null);
        delSnack.current && closeSnackbar(delSnack.current);
        delSnack.current = enqueueSnackbar('삭제할까요?', {
            variant: 'warning',
            autoHideDuration: null,
            action: key => <>
                <Button onClick={() => onConfirmDelete(items, key)}>
                    예
                </Button>
                <Button onClick={() => closeSnackbar(key)}>
                    아니오
                </Button>
            </>,
        })
    }

    const onConfirmDelete = async(items, progKey) => {
        closeSnackbar(progKey);
        setLoading(true);
        let result = false;
        
        if (onDelete) {
            result = await onDelete(items);
        } else if(deleteProc) {
            if (typeof(items) === 'object') {
                await callProc(deleteProc, items);
            } else {
                for (let i=0; i<items.length; i++) {
                    await callProc(deleteProc, items[i]);
                }
            }
            result = true;
        }

        if (result !== false) {
            setSelected([]);
            await refresh();

            enqueueSnackbar('삭제가 완료되었습니다.')
        }

        setLoading(false);
    }

    const closeDelSnack = useCallback(() => {
        delSnack.current && closeSnackbar(delSnack.current)
    }, [closeSnackbar])

    const isSelected = (index) => selected.indexOf(index) !== -1;

    const isMultiSelected = () => selectionMode === 'multi' && selected.length > 0;

    const getSelectedItem = () => selected.map(index => data.data[index]);

    const getGroup = useCallback((row, group) => (
        row && (group || totalGroup).reduce((result, item) => ({ ...result, [item]: row[item] }), {})
    ), [totalGroup]);

    const getSortedIcon = (fieldName) => {
        const item = data.sorted.find(sortItem => sortItem.fieldName === fieldName);
        if (!item) return;

        let style;
        if (data.sorted[0].fieldName === fieldName) {
            style = { height: 18 };
        } else {
            style = { height: 18, color: 'darkgray' }
        }

        if (item.asc) {
            return <ArrowDropUpIcon fontSize='small' style={style} />
        } else {
            return <ArrowDropDownIcon fontSize='small' style={style} />
        }
    }

    const insertRow = useMemo(() => data.columns && data.columns.reduce((result, col) => {
        const fieldName = col.props.fieldName;
        if (!fieldName) {
            return result;
        } else {
            const fieldInfo = data.insMeta && data.insMeta[fieldName];
            const type = fieldInfo ? fieldInfo['TYPE'] : null;
            let value = col.props.defaultValue;
            if (value === undefined) {
                switch (type) {
                    case 'decimal' :
                        value = 0;
                        break;
                    case 'date' :
                        value = moment().format('YYYY-MM-DD');
                        break;
                    case 'time' :
                        value = '00:00:00';
                        break;
                    case 'datetime' :
                        value = moment().format('YYYY-MM-DD') + ' 00:00:00';
                        break;
                    default :
                        value = undefined;
                        break;
                }
            }
            return { ...result, [col.props.fieldName]: value }
        }
    }, {}), [data]);

    const bandRowCnt = data.headerBand ? data.headerBand.length : 0;
    const bandStickyStyles = useMemo(() => {
        let result = {};
        for (let i = 0; i <= bandRowCnt + 1; i++) {
            result = {
                [`& :nth-child(${i + 1}) > th`]: {
                    top: 49 * i + (stickyHeader ? 64 : 0)
                },
                ...result
            }
        }
        return makeStyles(() => ({ stickyTop: result }));
    }, [bandRowCnt, stickyHeader])
    const bandStickyClass = bandStickyStyles();

    const renderIdx = useMemo(() => {
        if (virtualized) {
            const result = [];
            const visibleHeight = window.innerHeight + virtualPadding * 2;
            const renderCnt = Math.floor(visibleHeight / rowHeight);
            for (let i = 0; i < renderCnt; i++) {
                let pos = i + renderPos;
                if (pos >= 0 && pos < data.data.length) {
                    result.push(pos);
                }
            }
            return result;
        } else {
            return Object.keys(data.data);
        }
    }, [virtualized, renderPos, data]);

    const headerRow = headerRowRef.current;
    useEffect(() => {
        if (!headerRow) return;
        const rowObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.intersectionRatio > 0) {
                    const tds = entry.target.children;
                    let prevFixedWidth = 0;
                    for (let i = 0; i < tds.length; i++) {
                        if (tds[i].className.includes(classes.fixedCell)) {
                            tds[i].style.left = prevFixedWidth + 'px';
                            prevFixedWidth += tds[i].offsetWidth;
                        }
                    }
                }
            })
        })
        rowObserver.observe(headerRow);
    }, [headerRow, classes.fixedCell, classes.gradientCell]);

    return (
        <TableContainer
            className={clsx(classes.container, className, {
                [classes.virtualizediContainer]: virtualized,
            })}
            component={Paper}
            style={{...style, ...(borderLess && { boxShadow: 'none' })}}
            onScroll={evt => {
                if (evt.target.className.includes('MuiTableContainer')) {
                    let pos = Math.floor((evt.target.scrollTop - virtualPadding) / rowHeight);
                    if (!totalSort && data.visibleTotal) {
                        pos -= Object.values(data.visibleTotal).reduce((result, item) => (
                            item.rowPos < pos ? result + item.rows.length : result
                        ), 0)
                    }
                    setRenderPos(pos);
                }
            }}
        >
            <ProgressDialog open={loading} />
            {!headerLess &&
                <Toolbar
                    className={clsx(classes.toolbar, {
                        [classes.highlight]: isMultiSelected(),
                    })}
                >
                    {isMultiSelected() ?
                    <Typography style={{flex : 1}} color="inherit" variant="subtitle1" component="div">
                        {selected.length}건 선택
                    </Typography> :
                    <Typography style={{flex : 1}} variant="h6" id="tableTitle" component="div">
                        {title}
                    </Typography>}

                    {data.columns &&
                    isMultiSelected() ? 
                    <Fragment>
                        {headerItem && headerItem({
                            data: data.data,
                            selection: getSelectedItem(),
                            fields: data.fields,
                            visibleAoa: data.visibleAoa,
                            srcAoa: data.srcAoa,
                            filterInput,
                        })}
                        {deleteMode === 'multi' && isDeletable() &&
                        <IconButton
                            tooltip='삭제'
                            onClick={() => onRequestDelete(getSelectedItem())}
                            icon={<DeleteIcon />}
                        />}
                    </Fragment> :
                    <Fragment>
                        {headerItem && headerItem({
                            data: data.data,
                            selection: getSelectedItem(),
                            fields: data.fields,
                            visibleAoa: data.visibleAoa,
                            srcAoa: data.srcAoa,
                            filterInput,
                        })}
                        {filterable &&
                        <IconButton
                            tooltip='필터'
                            onClick={() => setFilterVisible(!filterVisible)}
                            icon={<FilterListIcon />}
                        />}
                        {excelDownload &&
                        <IconButton
                            tooltip='엑셀 다운로드'
                            onClick={() =>
                                downloadWorkbook(excelDownload.fileName || title + '.xlsx',
                                    aoaToWorkbook(excelDownload.sheetName || title, data.visibleAoa))
                            }
                            icon={<GridOnIcon />}
                        />}
                        {refreshable &&
                        <IconButton
                            tooltip='새로고침'
                            onClick={refresh}
                            icon={<RefreshIcon />}
                        />}
                        {isInsertable() &&
                        <IconButton
                            tooltip='추가'
                            onClick={() => setEditRow(-1)}
                            icon={<AddIcon />}
                        />}
                    </Fragment>}
                </Toolbar>
            }
            {dataLoading ?
            <div className={classes.progress}>
                <CircularProgress />
            </div> :
            <div
                className={clsx({ [classes.horContainer]: horizonMode })}
            >
                <Table
                    className={clsx(classes.table, {
                        [classes.horMod]: horizonMode
                    })}
                    style={virtualized ? { height:
                        data.data.length * rowHeight +
                        rowHeight + // header
                        (data.hasTotal ? rowHeight : 0) + // total
                        (data.visibleTotal ? Object.values(data.visibleTotal).reduce((result, totalRow) => (
                            result + totalRow.rows.length * rowHeight
                        ), 0) : 0) // group total
                    } : null}
                >
                    <TableHead className={bandStickyClass.stickyTop}>
                        {data.headerBand.map((bandItems, i) => (
                            <TableRow
                                className={classes.headerRow}
                                {...headerRowProps}
                                key={i}
                            >
                                {selectionMode === 'multi' &&
                                    <PaddingCell className={classes.headerCell} padding="checkbox" {...headerCellProps} />
                                }
                                {expandable &&
                                    <PaddingCell className={classes.headerCell} padding='checkbox' {...headerCellProps} />
                                }
                                {(isUpdatable() || isRowDeletable() || editRow != null) &&
                                    <PaddingCell
                                        className={clsx(classes.headerCell, classes.fixedCell)}
                                        padding="checkbox"
                                        {...headerCellProps}
                                    />
                                }
                                {bandItems}
                            </TableRow>
                        ))}
                        <TableRow
                            ref={headerRowRef}
                            className={classes.headerRow}
                            {...headerRowProps}
                        >
                            {selectionMode === 'multi' &&
                                <PaddingCell className={classes.headerCell} padding="checkbox" {...headerCellProps}>
                                    <Checkbox
                                        indeterminate={selected.length > 0 && selected.length < selectableCnt}
                                        checked={selected.length > 0 && selected.length === selectableCnt}
                                        onChange={handleSelectAllClick}
                                        color='default'
                                    />
                                </PaddingCell>
                            }
                            {expandable &&
                                <PaddingCell className={classes.headerCell} padding='checkbox' {...headerCellProps} />
                            }
                            {(isUpdatable() || isRowDeletable() || editRow != null) &&
                                <PaddingCell
                                    className={clsx(classes.headerCell, classes.fixedCell)}
                                    padding="checkbox"
                                    {...headerCellProps}
                                />
                            }
                            {data.columns.map((item, i) => (
                                item.props.visible && (
                                <PaddingCell
                                    key={item.props.fieldName || i}
                                    className={clsx(classes.headerCell, {
                                        [classes.numberCell]: data.data[0] && typeof(data.data[0][item.props.fieldName]) === 'number',
                                        [classes.fixedCell]: fixedColumns.includes(item.props.fieldName),
                                        [classes.sortableHeader]: sortable,
                                    })}
                                    onClick={() => sortable && runSort(item.props.fieldName)}
                                    field={item.props.fieldName}
                                    style={item.props.headerStyle}
                                    {...headerCellProps}
                                >
                                    <div className={classes.headerContainer}>
                                        {item.props.title || columnName.current[item.props.fieldName]}
                                        {getSortedIcon(item.props.fieldName)}
                                    </div>
                                </PaddingCell>
                                )
                            ))}
                        </TableRow>
                        {filterable &&
                        <TableRow
                            className={clsx(classes.headerRow, {
                                [classes.hide]: !filterVisible,
                            })}
                        >
                            {selectionMode === 'multi' &&
                                <PaddingCell className={classes.headerCell} padding="checkbox" {...headerCellProps}>
                                    <Checkbox
                                        indeterminate={selected.length > 0 && selected.length < selectableCnt}
                                        checked={selected.length > 0 && selected.length === selectableCnt}
                                        onChange={handleSelectAllClick}
                                        color='default'
                                    />
                                </PaddingCell>
                            }
                            {expandable &&
                                <PaddingCell className={classes.headerCell} padding='checkbox' {...headerCellProps} />
                            }
                            {(isUpdatable() || isRowDeletable() || editRow != null) &&
                                <PaddingCell
                                    className={clsx(classes.headerCell, classes.fixedCell)}
                                    padding="checkbox"
                                    {...headerCellProps}
                                />
                            }
                            {data.columns.map((item, i) => item.props.visible && (
                                <PaddingCell
                                    key={item.props.fieldName || i}
                                    className={clsx(classes.headerCell, {
                                        [classes.numberCell]: data.data[0] && typeof(data.data[0][item.props.fieldName]) === 'number',
                                        [classes.fixedCell]: fixedColumns.includes(item.props.fieldName),
                                        [classes.sortableHeader]: sortable,
                                    })}
                                    field={item.props.fieldName}
                                    style={item.props.headerStyle}
                                    {...headerCellProps}
                                >
                                    {data.filterableData[item.props.fieldName] &&
                                    data.filterableData[item.props.fieldName].length !== 0 &&
                                    <FilterField
                                        fieldName={item.props.fieldName}
                                        data={data.filterableData[item.props.fieldName]}
                                        runFilter={runFilter}
                                    />}
                                </PaddingCell>
                            ))}
                        </TableRow>}
                    </TableHead>
                    <TableBody>
                        {virtualized && renderIdx.length > 0 &&
                        <Fragment>
                            <tr style={{
                                height: (renderIdx[0] * rowHeight +
                                    (!totalSort && data.visibleTotal ? Object.values(data.visibleTotal).reduce((result, item) => (
                                        item.rowPos < renderIdx[0] ? result + item.rows.length * rowHeight : result
                                    ), 0) : 0)
                                ) || 0.1
                            }}/>
                            <DataRow
                                columns={data.columns}
                                row={data.longestRow}
                                rowPos={-2}
                                data={data.data}
                                selectionMode={selectionMode}
                                selectable={selectable}
                                deleteMode={deleteMode}
                                expandable={expandable}
                                isSelected={false}
                                onClick={handleClick}
                                onUpdateStart={onInsertStart}
                                onUpdate={isUpdatable() && onRequestUpdate}
                                onDelete={isDeletable() && onRequestDelete}
                                setEditRow={setEditRow}
                                editRow={editRow}
                                cellProps={cellProps}
                                rowProps={rowProps}
                                closeDelSnack={closeDelSnack}
                                fixedColumns={fixedColumns}
                            />
                        </Fragment>}
                        {editRow === -1 &&
                        <DataRow
                            columns={data.columns}
                            row={insertRow}
                            rowPos={-1}
                            data={data.data}
                            metaData={data.insMeta}
                            selectionMode={selectionMode}
                            selectable={() => false}
                            deleteMode={deleteMode}
                            expandable={expandable}
                            isSelected={false}
                            onClick={handleClick}
                            onUpdate={isInsertable() && onRequestInsert}
                            onUpdateStart={onInsertStart}
                            setEditRow={setEditRow}
                            editRow={editRow}
                            cellProps={cellProps}
                            rowProps={rowProps}
                            closeDelSnack={closeDelSnack}
                            fixedColumns={fixedColumns}
                        />}
                        {data.data.length > 0 &&
                        renderIdx.map((posKey, i) => {
                            const rowPos = Number(posKey);
                            const curGroupStr = JSON.stringify(getGroup(data.data[rowPos]));
                            const nextGroupStr = JSON.stringify(getGroup(data.data[rowPos + 1]));
                            return [
                                <DataRow
                                    key={i}
                                    keyFields={keyFields.current}
                                    columns={data.columns}
                                    row={data.data[rowPos]}
                                    rowPos={rowPos}
                                    data={data.data}
                                    metaData={data.uptMeta}
                                    selectionMode={selectionMode}
                                    selectable={selectable}
                                    deleteMode={deleteMode}
                                    expandable={expandable}
                                    defaultExpand={defaultExpand}
                                    isSelected={isSelected(rowPos)}
                                    onClick={handleClick}
                                    onUpdateStart={onUpdateStart}
                                    onUpdate={isUpdatable() && onRequestUpdate}
                                    onDelete={isDeletable() && onRequestDelete}
                                    setEditRow={setEditRow}
                                    editRow={editRow}
                                    cellProps={cellProps}
                                    rowProps={rowProps}
                                    closeDelSnack={closeDelSnack}
                                    fixedColumns={fixedColumns}
                                />,
                                data.visibleTotal && !totalSort && curGroupStr !== nextGroupStr && data.visibleTotal[curGroupStr].components
                            ]
                        })}
                        <tr/>
                        {data.visibleTotal && totalSort &&
                        Object.values(data.visibleTotal)
                            .sort((a, b) => totalSort(a.group, b.group))
                            .map(item => item.components)}
                        {!hideTotal && data.hasTotal &&
                        <TotalRow
                            row={getTotalRow(data.data, undefined, data.columns)}
                            expandable={expandable}
                            isUpdatable={isUpdatable()}
                            isRowDeletable={isRowDeletable()}
                            selectionMode={selectionMode}
                            editRow={editRow}
                            cellProps={cellProps}
                            rowProps={rowProps}
                            fixedColumns={fixedColumns}
                            sticky
                        />}
                    </TableBody>
                </Table>
            </div>}
        </TableContainer>
    )
};

const ACTION_UPDATE = 'ACTION_UPDATE';
const ACTION_INIT = 'ACTION_INIT';

const DataRow = (props) => {
    const {
        data,
        metaData,
        row,
        rowPos,
        keyFields = [],
        isSelected,
        columns,
        expandable,
        defaultExpand,
        onClick,
        onDelete,
        onUpdate,
        onUpdateStart,
        setEditRow,
        selectionMode,
        selectable,
        deleteMode,
        editRow,
        cellProps,
        rowProps,
        closeDelSnack,
        fixedColumns,
    } = props;

    const getInitRow = useCallback(() => (
        columns.reduce((result, col) => (
            col.props.fieldName && col.props.value && row[col.props.fieldName] === undefined ?
            { ...result, [col.props.fieldName]: col.props.value(row) } :
            result
        ), row)
    ), [columns, row]);

    const [updateData, dispatch] = useReducer(
        (state, action) => {
            switch(action.type) {
                case ACTION_UPDATE:
                    const { field, value } = action.value;
                    return {
                        ...state,
                        [field]: value,
                    }
                case ACTION_INIT:
                    return action.value || getInitRow();
                default:
                    return state;
            }
        },
        getInitRow()
    );
    const [expand, setExpand] = useState(defaultExpand);
    const [expandItem, setExpandItem] = useState();
    const [editMode, setEditMode] = useState(false);
    const rowRef = useRef();

    const classes = useStyles();
    const prevRow = data[rowPos - 1];

    useEffect(() => {
        setExpandItem(expandable && expandable(row));
        // eslint-disable-next-line
    }, [row])

    useEffect(() => {
        if (editRow === rowPos) {
            closeDelSnack();

            const row = onUpdateStart && onUpdateStart(getInitRow());
            if (row === false) {
                setEditRow();
                return;
            }

            setEditMode(true);
            dispatch({ type : ACTION_INIT, value: row });
        } else {
            setEditMode(false);
        }
    }, [editRow, closeDelSnack, getInitRow, onUpdateStart, rowPos, setEditRow]);

    const reqUpdate = () => {
        onUpdate(updateData);
    }

    const onFieldUpdate = (field, value) => {
        dispatch({
            type: ACTION_UPDATE,
            value: { field : field, value : value }
        })
    }

    const getRowSpan = (mergeField) => {
        let cnt = 1;
        if (mergeField) {
            for (let pos = rowPos + 1; pos < data.length; pos++) {
                if (row[mergeField] === data[pos][mergeField]) {
                    cnt++;
                } else {
                    break;
                }
            }
        }
        return cnt;
    }

    const getDefEditable = (fieldName) => {
        const fieldInfo = metaData && metaData[fieldName];
        const type = fieldInfo ? fieldInfo['TYPE'] : null;
        const size = fieldInfo ? fieldInfo['SIZE'] : null;

        if (metaData && !fieldInfo) {
            return updateData[fieldName];
        }
        
        switch(type) {
            case 'decimal' :
                return (
                    <NumberField
                        value={updateData[fieldName]}
                        onChange={(value) => onFieldUpdate(fieldName, value)}
                        fullWidth
                    />
                )
            case 'date' :
                return (
                    <DatePicker
                        value={updateData[fieldName]}
                        onChange={(value) => onFieldUpdate(fieldName, value)}
                    />
                )
            case 'time' :
                return (
                    <TimePicker
                        value={updateData[fieldName]}
                        onChange={(value) => onFieldUpdate(fieldName, value)}
                    />
                )
            case 'datetime' :
                return (
                    <DateTimePicker
                        value={updateData[fieldName]}
                        onChange={(value) => onFieldUpdate(fieldName, value)}
                    />
                )
            default :
                return (
                    <TextField
                        value={updateData[fieldName] || ''}
                        onChange={(e) => onFieldUpdate(fieldName, e.target.value)}
                        inputProps={{ maxLength: size }}
                        fullWidth
                    />
                )
        }
    }

    useEffect(() => {
        const rowObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.intersectionRatio > 0) {
                    const tds = entry.target.children;
                    let prevFixedWidth = 0;
                    let lastFixedCell;
                    for (let i = 0; i < tds.length; i++) {
                        if (tds[i].className.includes(classes.fixedCell)) {
                            tds[i].style.left = prevFixedWidth + 'px';
                            prevFixedWidth += tds[i].offsetWidth;
                            lastFixedCell = tds[i];
                        }
                    }
                    if (lastFixedCell && !lastFixedCell.className.includes(classes.gradientCell)) {
                        lastFixedCell.classList.add(classes.gradientCell);
                    }
                }
            })
        })
        rowObserver.observe(rowRef.current);
    }, [classes.fixedCell, classes.gradientCell]);

    return (
        <Fragment>
            <TableRow
                ref={rowRef}
                role="checkbox"
                aria-checked={isSelected}
                tabIndex={-1}
                key={rowPos}
                className={clsx(classes.row, {
                    [classes.selectedRow]: isSelected || editMode,
                    [classes.unEditRow]: editRow != null && !editMode,
                    [classes.virtualRow]: rowPos === -2,
                })}
                {...rowProps}
            >
                {selectionMode === 'multi' &&
                <PaddingCell
                    className={clsx(classes.tableCell, {
                        [classes.cellDivider] : !expandItem,
                    })}
                    padding="checkbox"
                    {...cellProps(row, 'check')}
                >
                    {selectable(row) &&
                    <Checkbox
                        checked={isSelected}
                        onMouseDown={(event) => editRow == null && onClick(event, rowPos)}
                        color='default'
                    />}
                </PaddingCell>}
                {expandable &&
                <PaddingCell
                    className={clsx(classes.tableCell, {
                        [classes.cellDivider] : !expandItem,
                    })}
                    {...cellProps(row, 'expand')}
                >
                    {expandItem != null &&
                    <IconButton
                        disableTooltip
                        onClick={() => setExpand(!expand)}
                        icon={expand ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    />}
                </PaddingCell>}
                {(onUpdate || (onDelete && deleteMode === 'single') || editRow != null) &&
                <PaddingCell
                    className={clsx(classes.tableCell, classes.fixedCell, {
                        [classes.cellDivider]: !expandItem,
                    })}
                    {...cellProps(row, 'button')}
                >
                    <div style={{ display: 'flex' }}>
                        {editMode ?
                        <Fragment>
                            <IconButton
                                tooltip='확인'
                                onClick={reqUpdate}
                                icon={<CheckIcon/>}
                            />
                            <IconButton
                                tooltip='취소'
                                onClick={() => setEditRow(null)}
                                icon={<CloseIcon/>}
                            />
                        </Fragment> :
                        <Fragment>
                            {onUpdate &&
                            <IconButton
                                tooltip='수정'
                                onClick={() => setEditRow(rowPos)}
                                icon={<EditIcon/>}
                            />}
                            {(onDelete && deleteMode === 'single') &&
                            <IconButton
                                tooltip='삭제'
                                onClick={() => onDelete(row)}
                                icon={<DeleteIcon/>}
                            />}
                        </Fragment>}
                    </div>
                </PaddingCell>
                }
                {columns.map((item, i) => {
                    const { fieldName, editable, mergeField, visible, cellStyle } = item.props;
                    const toValue = rowPos !== -2 && item.props.value;
                    const value = row[fieldName];
                    const rowSpan = getRowSpan(mergeField);
                    
                    return (
                        visible &&
                        <DataCell
                            key={fieldName || i}
                            fieldName={fieldName}
                            rowSpan={rowSpan}
                            style={cellStyle}
                            edit={editMode}
                            value={toValue ?
                                toValue(row, value, rowPos) :
                                typeof(value) === 'number' ? numberFormat(value) : value
                            }
                            editable={!fieldName || keyFields.indexOf(fieldName) !== -1 ?
                                (toValue ? toValue(row, value, rowPos) : value) :
                                editable ?
                                editable(
                                    updateData[fieldName],
                                    (newValue) => onFieldUpdate(fieldName, newValue),
                                    updateData,
                                    onFieldUpdate,
                                    rowPos
                                ) :
                                getDefEditable(fieldName)
                            }
                            className={clsx(classes.tableCell, {
                                [classes.numberCell]: typeof(value) === 'number',
                                [classes.cellDivider]: !expandItem,
                                [classes.mergeCell]: mergeField,
                                [classes.hide]: mergeField && prevRow && prevRow[mergeField] === row[mergeField],
                                [classes.fixedCell]: fixedColumns.includes(item.props.fieldName),
                            })}
                            onClick={(event) => editRow == null && onClick(event, rowPos)}
                            cellProps={cellProps(row, fieldName)}
                        />
                    )
                })}
            </TableRow>
            {expandItem &&
                <TableRow>
                    <TableCell
                        className={classes.expandCell}
                        colSpan={
                            2 +
                            (selectionMode === 'multi' ? 1 : 0) +
                            (expandable ? 1 : 0) +
                            (onUpdate || (onDelete && deleteMode === 'single') || editRow != null ? 1 : 0) +
                            columns.length
                        }>
                        <Collapse in={expand} timeout='auto' unmountOnExit>
                            <Box margin={1}>
                                {expandItem}
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            }
        </Fragment>
    )
}

const DataCell = ({ value, onClick, rowSpan, className, style, edit, editable, fieldName, cellProps = {} }) => {
    const key = 'style';
    const { [key]: gridStyle, className: cellClassName, ...props } = cellProps;
    const colStyle = style;

    return (
        <PaddingCell
            field={fieldName}
            className={clsx(className, cellClassName)}
            style={{...gridStyle, ...colStyle}}
            rowSpan={rowSpan}
            onMouseDown={onClick}
            {...props}
        >
            {edit ? editable : value}
        </PaddingCell>
    )
}

const TotalRow = (props) => {
    const {
        row,
        group,
        expandable,
        isUpdatable,
        isRowDeletable,
        selectionMode,
        editRow,
        cellProps,
        rowProps,
        fixedColumns,
        sticky = false,
    } = props;

    const classes = useStyles();
    const rowRef = useRef();

    useEffect(() => {
        const rowObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.intersectionRatio > 0) {
                    const tds = entry.target.children;
                    let prevFixedWidth = 0;
                    let lastFixedCell;
                    for (let i = 0; i < tds.length; i++) {
                        if (tds[i].className.includes(classes.fixedCell)) {
                            tds[i].style.left = prevFixedWidth + 'px';
                            prevFixedWidth += tds[i].offsetWidth;
                            lastFixedCell = tds[i];
                        }
                    }
                    if (lastFixedCell && !lastFixedCell.className.includes(classes.gradientCell)) {
                        lastFixedCell.classList.add(classes.gradientCell);
                    }
                }
            })
        })
        rowObserver.observe(rowRef.current);
    }, [classes.fixedCell, classes.gradientCell]);

    return (
        <TableRow
            ref={rowRef}
            className={classes.row}
            role="checkbox"
            tabIndex={-1}
            {...rowProps}
        >
            {selectionMode === 'multi' &&
                <PaddingCell
                    className={clsx(classes.tableCell, classes.totalCell, {
                        [classes.footerCell]: sticky
                    })}
                    {...cellProps({}, 'check', group)}
                />
            }
            {expandable &&
                <PaddingCell
                    className={clsx(classes.tableCell, classes.totalCell, {
                        [classes.footerCell]: sticky
                    })}
                    {...cellProps({}, 'expand', group)}
                />
            }
            {isUpdatable && (isRowDeletable || editRow != null) &&
                <PaddingCell
                    className={clsx(classes.tableCell, classes.totalCell, {
                        [classes.footerCell]: sticky
                    })}
                    {...cellProps({}, 'button', group)}
                />
            }
            {Object.keys(row).map((fieldName, i) => {
                const value = row[fieldName];
                const tmpProps = cellProps({}, fieldName, group);
                let className;
                if (tmpProps && tmpProps.className) {
                    className = tmpProps.className;
                    delete tmpProps.className;
                }

                return (
                    <PaddingCell
                        key={fieldName || i}
                        className={clsx(className, classes.tableCell, classes.totalCell, {
                            [classes.footerCell]: sticky,
                            [classes.numberCell] : typeof(value) === 'number',
                            [classes.fixedCell]: fixedColumns.includes(fieldName),
                        })}
                        {...tmpProps}
                    >
                        {typeof(value) === 'number' ? numberFormat(value) : (value || undefined)}
                    </PaddingCell>
                )
            })}
        </TableRow>
    )
}

const useFilterFieldStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        fontSize: '13px',
    },
    menuItem: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    menuText: {
        fontSize: '14px',
    },
}));

const FilterField = ({ data, fieldName, runFilter }) => {
    const [value, setValue] = useState([]);
    const classes = useFilterFieldStyles();

    const handleFilterSelect = (value) => {
        setValue(value);
        runFilter(fieldName, value);
    }

    return (
        <Select
            className={classes.container}
            multiple
            value={value}
            onChange={evt => handleFilterSelect(evt.target.value)}
            renderValue={(selected) => selected.length > 1 ? `${selected[0]} 외 ${selected.length - 1}건` : selected[0]}
            MenuProps={{ getContentAnchorEl: () => null }}
        >
            {data.map(item => (
                <MenuItem key={item} value={item}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                        checkedIcon={<CheckBoxIcon fontSize='small' />}
                        checked={value.includes(item)}
                    />
                    <Typography className={classes.menuText}>{item}</Typography>
                </MenuItem>
            ))}
        </Select>
    )
}

DataGrid.defaultProps = {
    children : [],
    selectParam: {},
    defaultExpand: false,
    selectionMode: 'none',
    selectable: () => true,
    deleteMode: 'single',
    totalGroup: [],
    fixedColumns: [],
    cellProps: () => {},
    sortable: false,
    filterable: false,
    onFilterInput: () => {},
    onLoad: () => {},
};

DataGrid.propTypes = {
    /**
     * Grid에 표현할 DataSet
     * @description
     * data와 field의 key를 가지고 있는 쿼리 결과 오브젝트
     * @description
     * 우선순위 : dataSet > selectProc > table
     */
    dataSet : PropTypes.object,
    /**
     * 조회할 프로시져명
     * @description
     * selectParam 속성 지정하지 않을 경우 모든 파라미터 null로 실행
     * @description
     * 우선순위 : dataSet > selectProc > table
     */
    selectProc : PropTypes.string,
    /**
     * 조회할 프로시져의 파라미터
     * @description
     * 필수값 : selectProc
     * @example
     * {
     *     파라미터명1 : 파라미터값1,
     *     파라미터값1 : 파라미터값2,
     *     ...
     * }
     */
    selectParam : PropTypes.object,
    /**
     * Insert시 호출할 프로시져명
     * @description
     * 프로시져의 파라미터에 따라서 Insert Field 생성
     * @description
     * 우선순위 : onInsert > insertProc
     */
    insertProc : PropTypes.string,
    /**
     * Update시 호출할 프로시져명
     * @description
     * 프로시져의 파라미터에 따라서 Update Field 생성
     * @description
     * 우선순위 : onUpdate > updateProc
     */
    updateProc : PropTypes.string,
    /**
     * Delete시 호출할 프로시져명
     * @description
     * 우선순위 : onDelete > deleteProc
     */
    deleteProc : PropTypes.string,
    /**
     * 조회 및 KeyField지정할 테이블명
     * @description
     * Data 우선순위 : dataSet > selectProc > table
     * @description
     * Key 우선순위 : keys > table
     */
    table : PropTypes.string,
    /**
     * KeyField(PK)로 지정
     * @description
     * 해당 컬럼은 insert에만 입력 가능, update에는 입력 불가능
     * @description
     * 우선순위 : keys > table
     * @example
     * ['FieldName1', 'FieldName2', ...]
     */
    keys: PropTypes.array,
    /**
     * Grid 데이터 로드 또는 변경(filter/sort) 시 발생하는 Event
     * @param {Array} srcAoa
     * 원본 Data의 AOA Format
     * @param {Array} visibleAoa
     * 현재 보여지는 Data의 AOA Format
     * @param {JSON} filterInput
     * 사용자가 입력한 필터 데이터
     * { 필드명: ['필터값1', '필터값2'], ... }
     */
    onLoad : PropTypes.func,
    /**
     * DataSet 호출 완료 시 실행될 Callback
     * @description
     * return값으로 실제 표현할 DataSet 사용
     * @param {object} dataSet
     * DB호출 결과 dataSet(dataSet.data / dataSet.field)
     * @example
     * (dataSet) => schData.current = dataSet.data
     */
    onDataLoad : PropTypes.func,
    /**
     * 삭제 시 실행될 Callback
     * @description
     * async를 반드시 사용해야 갱신 시점과 충돌 미발생
     * @description
     * return false 할 경우 완료 event 미발생
     * @param {object} row
     * Row 데이터
     * @example
     * async(row) => await callProc('DLT_PROC', row)
     */
    onDelete : PropTypes.func,
    /**
     * 추가 시 실행될 Callback
     * @description
     * async를 반드시 사용해야 갱신 시점과 충돌 미발생
     * @description
     * return false 할 경우 완료 event 미발생
     * @param {object} row
     * Row 데이터
     * @example
     * async(row) => await callProc('INS_PROC', row)
     */
    onInsert : PropTypes.func,
    /**
     * 수정 시 실행될 Callback
     * @description
     * async를 반드시 사용해야 갱신 시점과 충돌 미발생
     * @description
     * return false 할 경우 완료 event 미발생
     * @param {object} row
     * Row 데이터
     * @example
     * async(row) => await callProc('UPT_PROC', row)
     */
    onUpdate : PropTypes.func,
    /**
     * 추가 버튼 클릭 시 실행될 Callback
     * @description
     * Json return 하면 Row 데이터 반영
     * @description
     * return false 할 경우 EditRow 비활성
     * @param {object} row
     * Row 데이터
     * @example
     *  row => ({ ...row, newField: newValue })
     */
    onInsertStart : PropTypes.func,
    /**
     * 수정 버튼 클릭 시 실행될 Callback
     * @description
     * Json return 하면 Row 데이터 반영
     * @description
     * return false 할 경우 EditRow 비활성
     * @param {object} row
     * Row 데이터
     * @example
     *  row => ({ ...row, newField: newValue })
     */
    onUpdateStart : PropTypes.func,
    /**
     * 새로고침 버튼 생성 여부
     */
    refreshable : PropTypes.bool,
    /**
     * Row별 expand 기능 사용할 Callback
     * @description
     * null return할 경우 expandable 버튼 invisible
     * @param {object} row
     * Row 데이터
     * @example
     *  (row) => (
     *      <DataGrid
     *          title='제목'
     *          selectProc='SLT_PROC'
     *          selectParam={row}
     *      />
     *  )
     */
    expandable : PropTypes.func,
    /**
     * expand 초기값
     * @description
     * true일 경우 전체 Row expand
     */
    defaultExpand : PropTypes.bool,
    /**
     * 로우 선택가능 모드
     * @default
     * 'none'
     * @example
     * 'none' || 'single' || 'multi'
     */
    selectionMode : PropTypes.oneOf(['none', 'single', 'multi']),
    /**
     * Row의 값에 따라 selection 가능 여부 부여
     * @description
     * true일 경우 가능, false일 경우 불가능
     * @param {object} row
     * 조건을 검사할 row object
     * @default
     * () => true
     * @example
     * row => row['FIELD'] === 1
     */
    selectable : PropTypes.func,
    /**
     * 삭제 방식
     * @description
     * single일 경우 삭제버튼 row에 표현
     * @description
     * multi일 경우 삭제버튼 header에 표현
     * @default
     * 'single'
     * @example
     * 'single' || 'multi'
     */
    deleteMode : PropTypes.oneOf(['single', 'multi']),
    /**
     * Grid 상단 제목
     */
    title : PropTypes.string,
    /**
     * 행 선택 시 Event
     * @param {object} rows
     * 선택한 row의 오브젝트 또는 배열
     * @description
     * selectionMode가 multi일 경우 배열, 그렇지 않은 경우 오브젝트로 호출
     * @example
     * (rows) => setSelected(rows)
     */
    onSelect : PropTypes.func,
    /**
     * 헤더 우측 Event Button
     * @param {array} data
     * 현재 로딩된 data
     * @param {array} selection
     * 현재 선택된 row
     * @param {array} fields
     * 현재 로딩된 data의 fields
     * @param {array} visibleAoa
     * 현재 보여지는 Data의 AOA Format
     * @param {Array} srcAoa
     * 원본 Data의 AOA Format
     * @param {JSON} filterInput
     * 사용자가 입력한 필터 데이터
     * { 필드명: ['필터값1', '필터값2'], ... }
     * @description
     * selectionMode가 multi일 경우 배열, 그렇지 않은 경우 오브젝트로 호출
     * 데이터 로드 및 변경(filter/sort) 시 호출
     * @example
     * (data, selection) => (
     *    <IconButton
     *        tooltip='실행'
     *        onClick={async() => {rows.map(row => {
     *            await callProc('UPT_PROC', row);
     *            refresh();
     *        })}}
     *        icon={<ConfirmIcon />}
     *    />
     * )
     */
    headerItem : PropTypes.func,
    /**
     * Header Toolbar를 상단에 고정
     */
    stickyHeader : PropTypes.bool,
    /**
     * 소계를 그룹화 시킬 필드명
     * @description
     * 자식 DataColumn에서 total 속성을 정의해야만 그룹화
     * @example
     * [field1, field2, ...]
     */
    totalGroup : PropTypes.array,
    /**
     * TotalRow를 정렬할 array sort callback
     * @description
     * sort 적용 시 TotalRow를 DataGrid 하단에 표현
     * @description
     * callback argument로 group 데이터 전달
     * @example
     * () => 0
     * @example
     * (a, b) => a['FIELD'] > b['FIELD'] ? -1 : 1
     */
    totalSort : PropTypes.func,
    /**
     * totalGroup의 필드들을 각개 집계
     * @description
     * totalGroup의 값 순서에 따라 우선순위 결정
     */
    eachTotalGroup : PropTypes.bool,
    /**
     * 총계 Row 숨김
     */
    hideTotal : PropTypes.bool,
    /**
     * sticky column으로 지정할 FieldName의 배열
     */
    fixedColumns: PropTypes.array,
    /**
     * TableRow component에 적용할 props
     * @example
     *  {
     *      className: {classes.row},
     *      ...
     *  }
     */
    rowProps : PropTypes.object,
    /**
     * TableCell component에 적용할 props
     * @param {Object} row
     * 적용할 row data
     * @param {String} fieldName
     * 적용할 필드명
     * @param {Array} group
     * Total 사용 시 적용할 group
     * @example
     *  (row, fieldName, group) => row['TYPE'] === '1' && ({
     *      className: {classes.cell},
     *      ...
     *  })
     */
    cellProps : PropTypes.func,
    /**
     * Header의 TableCell component에 적용할 props
     * @example
     *  {
     *      className: {classes.headerCell},
     *      ...
     *  }
     */
    headerCellProps : PropTypes.object,
    /**
     * Header의 TableRow component에 적용할 props
     * @example
     *  {
     *      className: {classes.headerRow},
     *      ...
     *  }
     */
    headerRowProps : PropTypes.object,
    /**
     * true일 경우 Grid의 Header를 숨김
     * @description
     * multiSelection, refreshable 등 header를 사용하는 Props를 쓰게 되면 위험
     */
    headerLess : PropTypes.bool,
    /**
     * true일 경우 Grid의 Border를 표현하지 않음
     */
    borderLess : PropTypes.bool,
    /**
     * true일 경우 가로스크롤 사용
     */
    horizonMode : PropTypes.bool,
    /**
     * 대용량 Rendering 경우 Row 가상화 처리
     * @description
     * TotalRow, Expandable, drag 사용 불가능
     */
    virtualized : PropTypes.bool,
    /**
     * Excel Download button 활성화
     * @description
     * fileName과 sheetName 전달
     * @description
     * fileName 또는 sheetName 생략 시 grid title로 다운로드
     * @description
     * fileName xls/xlsx 지원, 확장자 생략가능(default: xlsx)
     * @example
     * { fileName: '파일명.xlsx', sheetName: '시트명' }
     */
    excelDownload : PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    /**
     * Header Cell 클릭 시 정렬기능 활성화
     * @default
     * false
     */
    sortable: PropTypes.bool,
    /**
     * Filter 버튼 활성화
     * @default
     * false
     */
    filterable: PropTypes.bool,
    /**
     * 사용자가 Filter값 변경 시 호출되는 Callback
     * @param {JSON} values
     * { 필드명: 필터값 }
     * @description
     * 
     */
    onFilterInput: PropTypes.func,
    style: PropTypes.object,
};

export default DataGrid;