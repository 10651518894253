import React from 'react';
import { Document, StyleSheet, Text, View } from '@react-pdf/renderer';
import { PdfCell, PdfTable, PdfRow, PdfPage } from '../component';
import { numberFormat } from '../common/Utils';

const blueColor = {
    borderDark: '#5b9adf',
    textDark: '#105dd6',
    backDark: '#c6dbf7',
    border: '#c6cbde',
    text: '#4279ad',
    back: '#f7f7ff',
}

const redColor = {
    borderDark: '#f77573',
    textDark: '#ef1418',
    backDark: '#ffdbde',
    border: '#f79e9c',
    text: '#d04b50',
    back: '#fff6f7',
}

const grayBorder = '#dddfde';
const tableBorder ='#4f6285';
const headerBack = '#f4f5f9';

const styles = StyleSheet.create({
    title: {
        fontSize : 16,
        textAlign : 'center',
    },
    table: {
        width: '100%',
        // height: 30,
        border : '1px solid ' + tableBorder,
        // borderBottom : 1,
    },
    titleCell: {
        flex: 2,
        height: 20,
        border: '1px solid ' + grayBorder,
        borderTop: 0,
        borderLeft: 0,
        justifyContent: 'center',
        fontSize: 9,
        backgroundColor: headerBack, 
    },
    headerText: {
        // paddingLeft: 20,
        fontSize: 9,
    },
    valueCell: {
        flex: 2,
        height: 20,
        border: '1px solid ' + grayBorder,
        borderTop: 0,
        borderLeft: 0,
        justifyContent: 'center',
        fontSize: 9,
    },
    pblCell: {
        flex: 1,
        justifyContent: 'center',
        textAlign: 'center',
        borderBottom: 1,
        borderColor: grayBorder,
    },
    leftText: {
        paddingLeft: 4,
    },
    rightText: {
        paddingRight: 4,
    }
})

const TaxInvoicePdf = ({ workData, taxData }) => {
    workData = workData || {};
    taxData = taxData || {};

    const getTitle = () => {
        const workCd = workData['WORK_CD'];
        const cstBizNo = taxData['CST_BIZNO'];
        const wrtDate = taxData['WRT_DATE'] && taxData['WRT_DATE'].replace(/-/g, '');
        return `세금계산서_${workCd}_${cstBizNo}_${wrtDate}`;
    }

    const calcItmPrice = field => {
        let result = 0;
        for (let i = 1; i <= 4; i++) {
            result += taxData[field + i];
        }
        return result;
    }

    const getItemRow = idx => {
        const enable = taxData['TRD_DATE' + idx] != null;
        return (
            <PdfRow>
                <PdfCell style={{ ...styles.valueCell, flex: 1 }}>
                    {enable ? taxData['WRT_DATE'].substring(5, 7) : ''}
                </PdfCell>
                <PdfCell style={{ ...styles.valueCell, flex: 1 }}>
                    {taxData['TRD_DATE' + idx]}
                </PdfCell>
                <PdfCell style={{ ...styles.valueCell, flex: 6, textAlign: 'left' }} textStyle={styles.leftText}>
                    {taxData['ITM_NM' + idx]}
                </PdfCell>
                <PdfCell style={{ ...styles.valueCell, flex: 2 }}>
                    {taxData['UNIT' + idx]}
                </PdfCell>
                <PdfCell style={{ ...styles.valueCell, flex: 3, textAlign: 'right' }} textStyle={styles.rightText}>
                    {enable ? numberFormat(taxData['QTY' + idx]) : ''}
                </PdfCell>
                <PdfCell style={{ ...styles.valueCell, flex: 4, textAlign: 'right' }} textStyle={styles.rightText}>
                    {enable ? numberFormat(taxData['PRICE' + idx]) : ''}
                </PdfCell>
                {/* <PdfCell style={{ ...styles.valueCell, flex: 4, textAlign: 'right' }} textStyle={styles.rightText}>
                    {enable ? numberFormat(taxData['SPLY_AMT' + idx] + taxData['VAT' + idx]) : ''}
                </PdfCell> */}
                <PdfCell style={{ ...styles.valueCell, flex: 4, textAlign: 'right' }} textStyle={styles.rightText}>
                    {enable ? numberFormat(taxData['SPLY_AMT' + idx]) : ''}
                </PdfCell>
                <PdfCell style={{ ...styles.valueCell, flex: 4, textAlign: 'right' }} textStyle={styles.rightText}>
                    {enable ? numberFormat(taxData['VAT' + idx]) : ''}
                </PdfCell>
                <PdfCell style={{ ...styles.valueCell, flex: 4, textAlign: 'left', borderRightWidth: 0 }} textStyle={styles.leftText}>
                    {taxData['ITM_REMARK'+ idx]}
                </PdfCell>
            </PdfRow>
        )
    }
    
    return(
        <Document title={getTitle()}>
            <PdfPage>
                {/* <Text style={styles.title}>세 금 계 산 서</Text> */}
                <PdfTable style={{...styles.table, height: 30}}>
                    <PdfRow>
                        <PdfCell style={{ ...styles.valueCell, flex: 3, borderBottomWidth: 0, height: 28, fontSize: 12, backgroundColor: headerBack, }}>
                            전자세금계산서
                        </PdfCell>
                        <PdfCell style={{ ...styles.titleCell, flex: 1, borderBottomWidth: 0, height: 28 }}>
                            승인번호
                        </PdfCell>
                        <PdfCell style={{ ...styles.valueCell, flex: 2, borderBottomWidth: 0, height: 28, fontSize: 12, backgroundColor: headerBack, }}>
                            {taxData['TAX_CD']}
                        </PdfCell>
                    </PdfRow>
                </PdfTable>
                <PdfTable style={{ ...styles.table, flexDirection: 'row', border: 0}}>
                    <SplyInfo
                        title={'공\n\n급\n\n자'}
                        color={redColor}
                        data={workData}
                    />
                    <SplyInfo
                        title={'공\n급\n받\n는\n자'}
                        color={blueColor}
                        data={taxData}
                    />
                </PdfTable>
                <PdfTable style={{ ...styles.table, borderTop: 0 }}>
                    <PdfRow>
                        <PdfCell style={{ ...styles.titleCell, flex: 1 }} textStyle={styles.headerText}>
                            작성 일자
                        </PdfCell>
                        <PdfCell style={{ ...styles.titleCell, flex: 1 }} textStyle={styles.headerText}>
                            공급가액
                        </PdfCell>
                        <PdfCell style={{ ...styles.titleCell, flex: 1 }} textStyle={styles.headerText}>
                            세액
                        </PdfCell>
                        <PdfCell style={{ ...styles.titleCell, flex: 3, borderRightWidth: 0 }} textStyle={styles.headerText}>
                            수정사유
                        </PdfCell>
                    </PdfRow>
                    <PdfRow>
                        <PdfCell style={{ ...styles.valueCell, flex: 1, }}>
                            {taxData['WRT_DATE']}
                        </PdfCell>
                        <PdfCell style={{ ...styles.valueCell, flex: 1, textAlign: 'right' }} textStyle={styles.rightText}>
                            {numberFormat(calcItmPrice('SPLY_AMT'))}
                        </PdfCell>
                        <PdfCell style={{ ...styles.valueCell, flex: 1, textAlign: 'right' }} textStyle={styles.rightText}>
                            {numberFormat(calcItmPrice('VAT'))}
                        </PdfCell>
                        <PdfCell style={{ ...styles.valueCell, flex: 3, borderRightWidth: 0 }} />
                    </PdfRow>
                    <PdfRow>
                        <PdfCell style={{ ...styles.titleCell, flex: 1, borderBottomWidth: 0 }} textStyle={styles.headerText}>
                            비고
                        </PdfCell>
                        <PdfCell style={{ ...styles.valueCell, flex: 5, textAlign: 'left', borderRightWidth: 0, borderBottomWidth: 0 }} textStyle={styles.leftText}>
                            {taxData['REMARK']}
                        </PdfCell>
                    </PdfRow>
                </PdfTable>

                <PdfTable style={{ ...styles.table, borderTop: 0 }}>
                    <PdfRow>
                        <PdfCell style={{ ...styles.titleCell, flex: 1 }}>
                            월
                        </PdfCell>
                        <PdfCell style={{ ...styles.titleCell, flex: 1 }}>
                            일
                        </PdfCell>
                        <PdfCell style={{ ...styles.titleCell, flex: 6 }}>
                            품목
                        </PdfCell>
                        <PdfCell style={{ ...styles.titleCell, flex: 2 }}>
                            규격
                        </PdfCell>
                        <PdfCell style={{ ...styles.titleCell, flex: 3 }}>
                            수량
                        </PdfCell>
                        <PdfCell style={{ ...styles.titleCell, flex: 4 }}>
                            단가
                        </PdfCell>
                        {/* <PdfCell style={{ ...styles.titleCell, flex: 4 }}>
                            합계
                        </PdfCell> */}
                        <PdfCell style={{ ...styles.titleCell, flex: 4 }}>
                            공급가액
                        </PdfCell>
                        <PdfCell style={{ ...styles.titleCell, flex: 4 }}>
                            세액
                        </PdfCell>
                        <PdfCell style={{ ...styles.titleCell, flex: 4, borderRightWidth : 0 }}>
                            비고
                        </PdfCell>
                    </PdfRow>
                    {getItemRow(1)}
                    {getItemRow(2)}
                    {getItemRow(3)}
                    {getItemRow(4)}
                </PdfTable>
                <PdfTable style={{ ...styles.table, flexDirection: 'row', borderTop: 0 }}>
                    <PdfTable style={{ flex: 2 }}>
                        <PdfRow>
                            <PdfCell style={{ ...styles.titleCell }}>
                                합계 금액
                            </PdfCell>
                            <PdfCell style={{ ...styles.titleCell }}>
                                현금
                            </PdfCell>
                            <PdfCell style={{ ...styles.titleCell }}>
                                수표
                            </PdfCell>
                            <PdfCell style={{ ...styles.titleCell }}>
                                어음
                            </PdfCell>
                            <PdfCell style={{ ...styles.titleCell }}>
                                외상미수금
                            </PdfCell>
                        </PdfRow>
                        <PdfRow>
                            <PdfCell style={{ ...styles.valueCell, textAlign: 'right' }} textStyle={styles.rightText}>
                                {numberFormat(calcItmPrice('SPLY_AMT') + calcItmPrice('VAT'))}
                            </PdfCell>
                            <PdfCell style={{ ...styles.valueCell, textAlign: 'right' }} textStyle={styles.rightText}>
                                {numberFormat(taxData['CASH'])}
                            </PdfCell>
                            <PdfCell style={{ ...styles.valueCell, textAlign: 'right' }} textStyle={styles.rightText}>
                                {numberFormat(taxData['CHECK'])}
                            </PdfCell>
                            <PdfCell style={{ ...styles.valueCell, textAlign: 'right' }} textStyle={styles.rightText}>
                                {numberFormat(taxData['BILL'])}
                            </PdfCell>
                            <PdfCell style={{ ...styles.valueCell, textAlign: 'right' }} textStyle={styles.rightText}>
                                {numberFormat(taxData['CREDIT'])}
                            </PdfCell>
                        </PdfRow>
                    </PdfTable>

                    <View style={styles.pblCell}>
                        <Text>
                            이 금액을 ( {taxData['PBL_TYPE'] === '01' ? '영수' : '청구'} ) 함
                        </Text>
                    </View>
                </PdfTable>
            </PdfPage>
        </Document>
    )
}

const SplyInfo = ({ title, color, data }) => {
    const styles = StyleSheet.create({
        container: {
            flexDirection: 'row',
            width:'50%',
            borderTop: 0,
            borderBottom: 1,
            borderLeft: 1,
            borderRight: 1,
            borderColor: tableBorder,
        },
        verticalText : {
            borderBottom : 0,
            borderTop : 0,
            // borderLeft: 1,
            padding : 4,
            justifyContent : 'center',
            backgroundColor : color.back,
            borderColor : color.borderDark,
            color: color.textDark,
        },
        table: {
            width: '100%',
            borderLeft: 1,
            borderTop: 0,
            borderBottom: 0,
            borderRight: 0,
            borderColor : color.borderDark,
        },
        titleCell : {
            backgroundColor : color.back,
            borderColor : color.border,
            color: color.text,
            flex: 2,
            height: 24,
            justifyContent: 'center',
            fontSize: 9,
        },
        valueCell : {
            borderColor : color.border,
            flex: 4,
            height: 24,
            justifyContent: 'center',
            textAlign: 'left',
            fontSize: 9,
        },
        wideCell : {
            borderColor : color.border,
            flex: 10,
            height: 24,
            justifyContent: 'center',
            textAlign: 'left',
            fontSize: 9,
        },
        leftText: {
            paddingLeft : 4,
        },
     })
  
    return (
        <View style={{ ...styles.container, borderRight: data['WORK_BIZNO'] ? 0 : 1 }}>
            <View style={{ ...styles.verticalText }}>
                <Text>
                    {title}
                </Text>
            </View>
            <PdfTable style={styles.table}>
                <PdfRow>
                    <PdfCell style={styles.titleCell}>
                        등록번호
                    </PdfCell>
                    <PdfCell style={{ ...styles.valueCell, textAlign: 'center' }}>
                        {(data['WORK_BIZNO'] && 
                        data['WORK_BIZNO'].substr(0, 3) + '-' + 
                        data['WORK_BIZNO'].substr(3, 2) + '-' + 
                        data['WORK_BIZNO'].substr(5, 5)) || 
                        (data['CST_BIZNO'] &&
                        data['CST_BIZNO'].substr(0, 3) + '-' + 
                        data['CST_BIZNO'].substr(3, 2) + '-' + 
                        data['CST_BIZNO'].substr(5, 5))}
                    </PdfCell>
                    <PdfCell style={styles.titleCell}>
                        종사업장{'\n'}번호
                    </PdfCell>
                    <PdfCell style={{ ...styles.valueCell, textAlign: 'center', borderRight: 0 }}>
                        {data['CST_SUB_BIZNO']}
                    </PdfCell>
                </PdfRow>
                <PdfRow>
                    <PdfCell style={styles.titleCell}>
                        상 호
                    </PdfCell>
                    <PdfCell style={{ ...styles.valueCell, textAlign: 'center' }}>
                        {data['WORK_PRNT_NM'] || data['CST_NM']}
                    </PdfCell>
                    <PdfCell style={styles.titleCell}>
                        성 명
                    </PdfCell>
                    <PdfCell style={{ ...styles.valueCell, textAlign: 'center', borderRight: 0 }}>
                        {data['CEO'] || data['CST_CEO']}
                    </PdfCell>
                </PdfRow>
                <PdfRow>
                    <PdfCell style={styles.titleCell}>
                        사업장
                    </PdfCell>
                    <PdfCell style={{ ...styles.wideCell, borderRight: 0}} textStyle={styles.leftText}>
                        {data['WORK_BIZNO'] ?
                        (data['ADDR'] || '') + ' ' + (data['ADDR_DET'] || '') :
                        data['CST_ADDR']}
                    </PdfCell>
                </PdfRow>
                <PdfRow>
                    <PdfCell style={styles.titleCell}>
                        업 태
                    </PdfCell>
                    <PdfCell style={styles.valueCell} textStyle={styles.leftText}>
                        {data['BIZ_COND'] || data['CST_COND']}
                    </PdfCell>
                    <PdfCell style={styles.titleCell}>
                        종 목
                    </PdfCell>
                    <PdfCell style={{ ...styles.valueCell, borderRight: 0 }} textStyle={styles.leftText}>
                        {data['BIZ_ITEM'] || data['CST_ITEM']}
                    </PdfCell>
                </PdfRow>
                {data['WORK_BIZNO'] ?
                    <PdfRow>
                        <PdfCell style={{ ...styles.titleCell, height: 48, borderBottom: 0 }}>
                            이메일
                        </PdfCell>
                        <PdfCell style={{ ...styles.wideCell, height: 48, borderRight: 0, borderBottom: 0 }} textStyle={styles.leftText}>
                            {data['EMAIL']}
                        </PdfCell>
                    </PdfRow> : <>
                    <PdfRow>
                        <PdfCell style={styles.titleCell}>
                            이메일
                        </PdfCell>
                        <PdfCell style={{ ...styles.wideCell, borderRight: 0 }} textStyle={styles.leftText}>
                            {data['CST_EMAIL1']}
                        </PdfCell>
                    </PdfRow>
                    <PdfRow>
                        <PdfCell style={{ ...styles.titleCell, borderBottom: 0 }}>
                            이메일
                        </PdfCell>
                        <PdfCell style={{ ...styles.wideCell, borderRight: 0, borderBottom: 0 }} textStyle={styles.leftText}>
                            {data['CST_EMAIL2']}
                        </PdfCell>
                    </PdfRow>
                </>}
            </PdfTable>
        </View>
    )
}

export default TaxInvoicePdf;