import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DatePicker, DataColumn, Tab, Tabs, PdfButton } from '../../component';
import { makeStyles } from '@material-ui/core';
import { callProc } from '../../common/DBConnector';
import { issueLoc, orderType } from '../../common/Dictionary';
import { WorkAllDailyAgencyReportPdf } from '../../reports';
import { useSelector } from 'react-redux';

const AgencyOrdType = {
    ...orderType,
    4 : '기타'
}

const useStyle = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    tabContainer : {
        display : 'flex',
    },
    subTabContainer : {
        backgroundColor: theme.palette.secondary.main,
        boxShadow: theme.shadows[4],
        marginBottom: 8,
    },
    tabbar : {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    content : {
        display: 'flex',
        flex: 1,
        height : 0,
    },
    hide : {
        display: 'none'
    },
    detailTotal: {
        backgroundColor: '#a3a9d9 !important'
    },
}))

const AgencyMainWorkReport = () => {
    const classes = useStyle();
    const [condition, _setCondition] = useState(null);
    const [selectTab, setSelectTab] = useState(0);
    const [inSaleData, setInSaleData] = useState();
    const [inGoodsData, setInGoodsData] = useState();
    const [cstBondData, setCstBondData] = useState();
    const [notGoodsBondData, setNotGoodsBondData] = useState();
    const [stockData, setStockData] = useState();
    const [fundhData, setFundData] = useState();
    const [balanceData, setBalanceData] = useState();
    const [cashData, setCashData] = useState();
    const loginState = useSelector(state => state.loginReducer);
    const [workData, setWorkData] = useState();

    const setCondition = (value) => {
        setInSaleData(null);
        setInGoodsData(null);
        setCstBondData(null);
        setNotGoodsBondData(null);
        setStockData(null);
        setFundData(null);
        setBalanceData(null);
        setCashData(null);

        _setCondition(value);
    }

    useEffect(() => {
        const fetchData = async () => {
            const workData = await callProc('SLT_WORKPLACEINFO_NOTMAIN_AUTH');

            setWorkData(workData.data.reduce((result, item) => ({
                ...result,
                [item['WORK_CD']]: item['WORK_NM']
            }), {}))

        }
        fetchData();
    }, [])

    useEffect(() => {
        const fetchData = async() => {
            setInSaleData(await callProc('SLT_ORDERINFO_LIST_AGENCY', condition));
            setFundData(await callProc('SLT_FUND_LIST_REPORT', condition));
            setInGoodsData(await callProc('SLT_IN_CST_GOOD_LIST_AGENCY', condition));
            setNotGoodsBondData(await callProc('SLT_BONDPAY_NOTGOODS_CST_AGENCY', condition));
            setStockData(await callProc('SLT_WORKALL_STOCK_REPORT_AGENCY', condition));
            setCstBondData(await callProc('SLT_BONDPAY_ALL_AGENCY', condition));
            setBalanceData(await callProc('SLT_BANKBOOK_BALANCE', condition));
            setCashData(await callProc('SLT_CASH_INOUT_LIST_REPORT', condition));
        }
        condition && fetchData();
    }, [condition])

    

    const condWorkName = workData && condition && workData[condition['WORK_CD']];
    const pdfComponent = useMemo(() => (
        <PdfButton title='대리점 통합 일일 보고서'>
            {inSaleData
            && inGoodsData 
            && cstBondData
            && notGoodsBondData 
            && stockData 
            && fundhData 
            && balanceData
            && cashData &&
            <WorkAllDailyAgencyReportPdf
                inSaleData={inSaleData && inSaleData.data}
                inGoodsData={inGoodsData && inGoodsData.data}
                cstBondData={cstBondData && cstBondData.data}
                notGoodsBondData={notGoodsBondData && notGoodsBondData.data}
                stockData={stockData && stockData.data}
                fundhData={fundhData && fundhData.data}
                balanceData={balanceData && balanceData.data}
                cashData={cashData && cashData.data}

                condition={condition}
                loginState={loginState}
                workName={condWorkName}
            />}
        </PdfButton>
    ), [inSaleData, 
        inGoodsData, 
        cstBondData,
        notGoodsBondData, 
        stockData, 
        fundhData, 
        balanceData, 
        cashData, 
        condition, 
        loginState, 
        condWorkName]);


    return(
        <div className={classes.container}>
            <SearchPanel
                onSearch={(condition) => {
                    sessionStorage['WORK_CD'] = condition['WORK_CD'];
                    setCondition(condition);
                }}
            >
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH_AGENCY'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            onDataLoad={(jsonData, rawData) => [ {WORK_CD: 'ALL', WORK_NM: '전체'}, ...rawData ]}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
            </SearchPanel>
            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab label='매입/매출 상세내역' />
                    <Tab label='상품매입장' />
                    <Tab label='채권현황(외상매출처)' />
                    <Tab label='채권현황(비상품)' />
                    <Tab label='재고현황' />
                    <Tab label='자금관리' />
                    <Tab label='계좌잔액' />
                    <Tab label='현금출납부' />
                </Tabs>
                <div className={classes.subTabContainer}>
                    {pdfComponent}
                </div>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <InSaleDetail inSaleData={inSaleData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <CstDebtGood inGoodsData={inGoodsData} />
            </div> 
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 2 })}>
                <CstBondList cstBondData={cstBondData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 3 })}>
                <NotGoodsBondList notGoodsBondData={notGoodsBondData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 4 })}>
                <Stock stockData={stockData}/>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 5 })}>
                <Funds fundhData={fundhData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 6 })}>
                <Balance balanceData={balanceData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 7 })}>
                <CashReport cashData={cashData} />
            </div>
        </div>
    )
}


const inSaleDetailStyles = makeStyles(theme => ({
    total: {
        backgroundColor: '#a3a9d9 !important'
    }
}));

const InSaleDetail = ({inSaleData}) => {
    const classes = inSaleDetailStyles();

    const getCellProps = (row, fieldName) => {
        if(String(row['COLOR_TYPE']) === '2')
            return { className: classes.total }
    };

    return(
    <DataGrid
        title='매입/매출 상세내역'
        dataSet={inSaleData}
        hideTotal
        horizonMode
        virtualized
        sortable
        filterable
        cellProps={getCellProps}
        excelDownload={{ fileName: '대리점통합일일보고서-매입상세내역.xlsx', sheetName: '매입상세내역' }}
        stickyHeader
    >
        <DataColumn fieldName='WORK_NM' />
        <DataColumn
            fieldName='BUY_CST_NM'
            title='매입처 및 출발지'
        />
        <DataColumn fieldName='GS_SHIP_CD' title='출하처코드' />
        <DataColumn 
            fieldName='DEST_TRN_NM'
            title='인도처'
        />
        <DataColumn 
            fieldName='CALC_QTY' 
            title='정산수량' 
        />
        <DataColumn fieldName='IN_PRICE' title='매입 단가' />
        <DataColumn 
            fieldName='IN_AMT' 
            title='매입금액' 
        />
        <DataColumn 
            fieldName='SALE_CST_NM' 
            title='판매처 및 도착지' 
        />
        <DataColumn fieldName='SALE_PRICE' title='판매단가' />
        <DataColumn 
            fieldName='SALE_AMT' 
            title='판매금액' 
        />
        <DataColumn 
            fieldName='ORD_TYPE'
            value={(row, value) => AgencyOrdType[value]}
        />
        <DataColumn 
            fieldName='QTY' 
            title='오더수량' 
            visible={false}
        />
        <DataColumn fieldName='IN_SPLY_AMT' title='매입 공급가액' />
        <DataColumn fieldName='IN_VAT_AMT' title='매입 부가세' />
        <DataColumn fieldName='SALE_SPLY_AMT' title='매출 공급가액' />
        <DataColumn fieldName='SALE_VAT_AMT' title='매출 부가세' />
        <DataColumn fieldName='GS_REAL_CD' title='판매처코드' />
        <DataColumn fieldName='GS_TSP_CD' title='인도처코드' />
        <DataColumn fieldName='SEQ' visible={false} />
        <DataColumn fieldName='SEQ_TYPE' visible={false} />
        <DataColumn fieldName='WORK_CD' visible={false} />
        <DataColumn fieldName='SHIP_REQ_DATE' visible={false} />
        <DataColumn fieldName='BUY_CST_CD' visible={false} />
        <DataColumn fieldName='DEST_TRN_CD' visible={false} />
        <DataColumn fieldName='GDS_CD' visible={false} />
        <DataColumn fieldName='QTY' visible={false} />
        <DataColumn fieldName='REAL_DEST_CD' visible={false} />
        <DataColumn fieldName='SHIP_TRN_CD' visible={false} />
        <DataColumn fieldName='SHIP_TRN_NM' visible={false} />
        <DataColumn fieldName='ENT_PRICE' visible={false} />
        <DataColumn fieldName='ENT_AMOUNT' visible={false} />
        <DataColumn fieldName='SUM_TYPE' visible={false} />
        <DataColumn fieldName='COLOR_TYPE' visible={false} />
    </DataGrid>

    )}

const CstDebtGood = ({inGoodsData}) => {



    return(
    <DataGrid
        title='매입처 상품'
        dataSet={inGoodsData}
        totalGroup={['WORK_NM']}
        horizonMode
        virtualized
        sortable
        filterable
        excelDownload={{ fileName: '통합일일보고서-매입처상품.xlsx', sheetName: '매입처상품' }}
        stickyHeader
    >
        <DataColumn fieldName='WORK_NM' total={(values, data, group) => group == null ? '합 계' : '(소 계) ' + group['WORK_NM']} />
        <DataColumn fieldName='CST_NM' title='매입처' />
        <DataColumn fieldName='BEF_BAL_P' title='전기 매입잔액(+)' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='BEF_BAL_M' title='전기 매입잔액(-)' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='CUR_IN_AMT' title='매입 금액' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='CUR_OUT_AMT' title='지급 금액' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='CUR_BAL_P' title='당기 매입잔액(+)' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='CUR_BAL_M' title='당기 매입잔액(-)' total={(values) => values.reduce((result, value) => result + value, 0)} />
    </DataGrid>
    )
}

const CstBondList = ({cstBondData}) => {

    return(<DataGrid
        title='채권현황(외상매출처)'
        dataSet={cstBondData}
        horizonMode
        totalGroup={['WORK_NM']}
        virtualized
        sortable
        filterable
        excelDownload={{ fileName: '대리점통합일일보고서-외상매출처채권현황.xlsx', sheetName: '외상매출처채권현황' }}
        stickyHeader
    >
        <DataColumn fieldName='WORK_NM' total={(values, data, group) => group == null ? '합 계' : '(소 계) ' + group['WORK_NM']} />
        <DataColumn fieldName='BEF_BAL_P' title='전기 매출잔액(+)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
        <DataColumn fieldName='BEF_BAL_M' title='전기 매출잔액(-)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
        <DataColumn fieldName='CUR_SALE_AMT' title='당기 매출' total={(values) => values.reduce((result, value) => result + value, 0)}/>
        <DataColumn fieldName='CUR_IN_AMT' title='당기 입금' total={(values) => values.reduce((result, value) => result + value, 0)}/>
        <DataColumn fieldName='CUR_BAL_P' title='당기 매출잔액(+)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
        <DataColumn fieldName='CUR_BAL_M' title='당기 매출잔액(-)' total={(values) => values.reduce((result, value  ) => result + value, 0)}/>
        <DataColumn fieldName='BEF_MONTH_BAL_P' title='전월 매출잔액(+)' total={(values) => values.reduce((result, value) => result + value, 0)} visible={false}/>
        <DataColumn fieldName='BEF_MONTH_BAL_M' title='전월 매출잔액(-)' total={(values) => values.reduce((result, value) => result + value, 0)} visible={false}/>
    </DataGrid>
)}

const NotGoodsBondList = ({notGoodsBondData}) => {

    return(<DataGrid
        title='채권현황(비상품)'
        dataSet={notGoodsBondData}
        horizonMode
        totalGroup={['WORK_NM']}
        virtualized
        sortable
        filterable
        excelDownload={{ fileName: '대리점통합일일보고서-채권현황(비상품).xlsx', sheetName: '채권현황(비상품)' }}
        stickyHeader
    >
        <DataColumn fieldName='BEF_BAL_P' title='전기 매출잔액(+)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
        <DataColumn fieldName='BEF_BAL_M' title='전기 매출잔액(-)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
        <DataColumn fieldName='CUR_SALE_AMT' title='당기 매출' total={(values) => values.reduce((result, value) => result + value, 0)}/>
        <DataColumn fieldName='CUR_IN_AMT' title='당기 입금' total={(values) => values.reduce((result, value) => result + value, 0)}/>
        <DataColumn fieldName='CUR_BAL_P' title='당기 매출잔액(+)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
        <DataColumn fieldName='CUR_BAL_M' title='당기 매출잔액(-)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
        <DataColumn fieldName='BEF_MONTH_BAL_P' title='전월 매출잔액(+)' total={(values) => values.reduce((result, value) => result + value, 0)} visible={false}/>
        <DataColumn fieldName='BEF_MONTH_BAL_M' title='전월 매출잔액(-)' total={(values) => values.reduce((result, value) => result + value, 0)} visible={false}/>
    </DataGrid>
)}


const Stock = ({ stockData }) =>{
    const classes = useStyle();

    const getCellProps = row => {
        let className;
        switch (row['SUM_TYPE']) {
            default:
            case 1:
                break;
            case 2:
                className = classes.detailTotal;
                break;
        }
        return { className };
    }
    

    return (<DataGrid
        title='재고현황'
        dataSet={stockData}
        selectionMode='single'
        horizonMode
        virtualized
        sortable
        filterable
        excelDownload={{ fileName: '대리점통합일일보고서-재고현황.xlsx', sheetName: '재고현황' }}
        stickyHeader
        cellProps={getCellProps}
    >
        <DataColumn fieldName='TNK_NO' />
        <DataColumn fieldName='CATE_NM' />
        <DataColumn fieldName='BEF_QTY' title='전기재고'/>
        <DataColumn fieldName='CUR_IN_QTY' title='당기입고'/>
        <DataColumn fieldName='CUR_ADJ_QTY' title='재고조정'/>
        <DataColumn fieldName='CUR_MOVE_IN_QTY' title='당기이관입고'/>
        <DataColumn fieldName='CUR_SALE_QTY' title='당기출고' />
        <DataColumn fieldName='CUR_MOVE_OUT_QTY' title='당기이관출고'/>
        <DataColumn fieldName='CUR_QTY' title='당기재고량' />
        <DataColumn fieldName='GDS_TYPE' visible={false} />
        <DataColumn fieldName='SUM_TYPE' visible={false} />
    </DataGrid>
)}

const Funds = ({ fundhData }) => {

    return (
    <DataGrid
        title='자금 관리'
        dataSet={fundhData}
        selectionMode='single'
        horizonMode
        virtualized
        sortable
        filterable
        excelDownload={{ fileName: '대리점통합일일보고서-자금관리.xlsx', sheetName: '자금관리' }}
        stickyHeader
    >
        <DataColumn fieldName='ACC_NM' title='계정과목명' total={(values) => '합계'} />
        <DataColumn fieldName='IN_AMT' title='수입' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='OUT_AMT' title='지출' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='BAL_AMT' title='잔액' />
        <DataColumn fieldName='ISSUE_LOC' value={(row, value) => issueLoc[value]} />
    </DataGrid>
)}

const balanceStyle = makeStyles(() => ({
    total: {
        backgroundColor: '#a3a9d9 !important'
    }
}))

const Balance = ({ balanceData }) => {
    const classes = balanceStyle();
    const getCellProps = (row, fieldName) => {
        if(String(row['COLOR_TYPE']) === '2')
            return { className: classes.total }
    };

    return (
    <DataGrid
        title='계좌 잔액'
        dataSet={balanceData}
        selectionMode='single'
        horizonMode
        virtualized
        sortable
        filterable
        excelDownload={{ fileName: '대리점통합일일보고서-계좌잔액.xlsx', sheetName: '계좌잔액' }}
        stickyHeader
        cellProps={getCellProps}
    >
        <DataColumn fieldName='BBK_NM' title='통장명' />
        <DataColumn fieldName='AMOUNT' title='잔액'  />
        <DataColumn fieldName='BBK_CD' visible={false} />
        <DataColumn fieldName='REMARK' visible={false} />
        <DataColumn fieldName='COLOR_TYPE' visible={false} />
        <DataColumn fieldName='SEQ' visible={false} />
    </DataGrid>
)}

const CashReport = ({ cashData }) => {

    return(<DataGrid
        title='현금출납부'
        dataSet={cashData}
        selectionMode='single'
        horizonMode
        virtualized
        sortable
        filterable
        excelDownload={{ fileName: '대리점통합일일보고서-현금출납부.xlsx', sheetName: '현금출납부' }}
        stickyHeader
    >
        <DataColumn fieldName='ACJ_DATE' title='영업일' />
        <DataColumn fieldName='ACC_NM' title='계정과목명' total={(values) => '합계'} />
        <DataColumn fieldName='DEBTOR' title='수입' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='CREDITOR' title='지출' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='BAL_AMT' title='잔액' />
    </DataGrid>
)}

export default AgencyMainWorkReport
