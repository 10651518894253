import React, { forwardRef, Fragment, useMemo, useRef } from 'react'
import { Button, Dialog, DialogTitle, ListItemIcon, makeStyles, Paper, Menu as PopupMenu, MenuItem as PopupMenuItem } from "@material-ui/core";
import { ComboBox, ComboSearch, DataColumn, DataGrid, DatePicker, EditableField, EditableList, FilePicker, IconButton, NumberField, PaperTitle, PdfButton, SearchItem, SearchPanel, Tab, Tabs, TextField } from '../../component';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import RepeatIcon from '@material-ui/icons/Repeat';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ViewListIcon from '@material-ui/icons/ViewList';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AddIcon from '@material-ui/icons/Add';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import PostAddIcon from '@material-ui/icons/PostAdd';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import GridOnIcon from '@material-ui/icons/GridOn';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ListAltIcon from '@material-ui/icons/ListAlt';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import BlockIcon from '@material-ui/icons/Block';
import CreateIcon from '@material-ui/icons/Create';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { callProc, downloadFile, getFileList, uploadFile } from '../../common/DBConnector';
import { cstKind, docState } from '../../common/Dictionary';
import { dataToWorkbook, downloadWorkbook, numberFormat, useFieldInputs } from '../../common/Utils';
import ElectRemarkDialog from './ElectRemarkDialog';
import { sortedProType } from '../../common/Dictionary';
import { IOType } from '../../common/Dictionary';
import { EdocumentStatePdf } from '../../reports';

const titleBackColor = '#EFEFEF';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 3,
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
    infoContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: 430,
    },
    info: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflowX: 'hidden',
    },
    actionButton: {
        alignSelf: 'center',
        display: 'flex',
    },
    viewr: {
        display: 'flex',
        flex: 2,
        flexDirection: 'column',
        marginLeft: theme.spacing(1),
    },
    tab: {
        minWidth: 'unset',
        flex: 1,
    },
    docSeq: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    docTitle: {
        width: 0,
        whiteSpace: 'pre',
        paddingTop: 4,
        color: 'gray'
    },
    pdfList: {
        minWidth: 450,
        flex: 1,
    },
    paperTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
        maxWidth: 1200,
    },
    pdfIcons: {
        display: 'inline-block',
        marginLeft: theme.spacing(2),
    },
    viewContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflowY: 'auto',
        padding: theme.spacing(3),
        '& > *': {
            marginBottom: theme.spacing(1),
        },
        maxWidth: 1200
    },
    viewTitleText: {
        fontSize: 24,
        fontWeight: 'bold',
    },
    viewWriterContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    viewWriter: {
        fontSize: 15,
    },
    viewSubTitleText: {
        fontSize: 18,
    },
    viewTable: {
        borderCollapse: 'collapse',
        width: '100%',
        borderWidth: 1,
        borderStyle: 'solid',
    },
    viewHeaderRow: {
        backgroundColor: titleBackColor,
        height: 30,
        fontSize: 14,
    },
    viewRow: {
        height: 30,
        fontSize: 12,
    },
    viewLeftCell: {
        textAlign: 'left',
        fontSize: 16
    },
    viewRightCell: {
        textAlign: 'right',
        fontSize: 16
    },
    viewCenterCell: {
        textAlign: 'center',
        fontSize: 16
    },
    viewDataLeftCell: {
        fontWeight: 'normal',
        textAlign: 'left',
        fontSize: 15
    },
    viewDataRightCell: {
        fontWeight: 'normal',
        textAlign: 'right',
        fontSize: 15
    },
    viewDataCenterCell: {
        fontWeight: 'normal',
        textAlign: 'center',
        fontSize: 15
    },
    viewNotifyContainer: {
        borderWidth: 1, 
        borderStyle: 'solid',
    },
    viewNotifyHeader: {
        display: 'flex',
        backgroundColor: titleBackColor,
        height: 30
    },
    viewNotifyHeaderText: {
        alignSelf: 'center',
        marginLeft: theme.spacing(3),
        fontSize: 16,
        fontWeight: 'bold',
    },
    viewNotifyContent: {
        display: 'flex',
        height: 30
    },
    viewRemarkContent: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 5,
        paddingBottom: 5,
        '& > div:nth-of-type(n+2)': {
            borderTop: '1px solid #ddd',
        }
    },
    viewRemarkContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    viewRemarkText: {
        marginLeft: theme.spacing(3),
        fontSize: 15,
        marginBottom: theme.spacing(1),
        whiteSpace: 'pre-wrap',
    },
    viewNotifyContentText: {
        alignSelf: 'center',
        marginLeft: theme.spacing(3),
        fontSize: 15,
    },
    approvalTitle: {
        fontSize: 22,
        fontWeight: 'bold',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        alignSelf: 'center',
    },
    approvalSubTtile: {
        fontSize: 17,
    },
    fileContainer: {
        display: 'flex',
        fontSize: 16,
    },
    fileContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    fileItem: {
        fontSize: 15,
        marginLeft: theme.spacing(2),
    },
}))

const searchType = {
    0: '제목',
    1: '작성자',
    2: '문서번호',
}

const lineType = {
    CONFIRM: 'CONFIRM',
    PROCESS: 'PROCESS',
    REF: 'REF'
}

const docOpenType = {
    NEW: 'NEW',
    EDIT: 'EDIT',
    COPY: 'COPY',
    RECREATE: 'RECREATE',
    REEDIT: 'REEDIT',
}

const saveType = {
    APPROVAL: 'APPROVAL',
    PROCESS_CONFIRM: 'PROCESS_CONFIRM',
    BLOCK: 'BLOCK',
    REMOVE: 'REMOVE',
}

const stateType = {
    0: '차변',
    1: '대변'
}

const ElecAccounting = () => {
    const classes = useStyles();
    const [condition, setCondition] = useState(null);
    const [selectTab, setSelectTab] = useState(0);
    const [gridData, setGridData] = useState();
    const [fileData, setFileData] = useState(null);
    const [fileKey, setFileKey] = useState(null);
    const [selectRow, setSelectRow] = useState(null);
    const [infoEdocLine, setInfoEdocLine] = useState(null);
    const [infoLineRef, setInfoLineRef] = useState(null);
    const [infoRemark, setInfoRemark] = useState(null);
    const [infoList, setInfoList] = useState(null);
    const [infoEfms, setInfoEfms] = useState(null);
    const [infoEdocAcc, setInfoEdocAcc] = useState(null);
    const [remarkOpen, setRemarkOpen] = useState(false);
    const [newCstOpen, setNewCstOpen] = useState(false);
    const [empOpen, setEmpOpen] = useState(false);
    const [empDialogLoadType, setEmpDialogLoadType] = useState(null);
    const [enrollOpen, setEnrollOpen] = useState(null);
    const [docHeaderData, setDocHeaderData] = useState(null);
    const [docLineData, setDocLineData] = useState(null);
    const [docProData, setDocProData] = useState({
        data: [],
        fields: [
            'SEQ', 'PRO_TYPE', 'IO_TYPE', 'WORK_CD', 
            'CST_CD', 'GDS_NM', 'ACC_CD', 'CRT_CD', 
            'PRICE', 'QTY', 'SPLY_AMT', 'AMOUNT', 
            'BANK_CD', 'BBK_NO', 'IN_PERSON', 'OUT_REMARK', 'OUT_AMOUNT', 
            'ISSUE_DATE', 'SUMUP', 'CST_NM', 'WORK_BIZNO', 'WORK_NM'
        ],
        metaData: {}
    });
    const [docAccData, setDocAccData] = useState({})
    const [workInfo, setWorkInfo] = useState(null);
    const [cstInfo, setCstInfo] = useState(null);
    const [cstBankInfo, setCstBankInfo] = useState(null);
    const [accInfo, setAccInfo] = useState(null);
    const [bankInfo, setBankInfo] = useState(null);
    const [empInfo, setEmpInfo] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const snack = useRef();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const loginState = useSelector(state => state.loginReducer);

    useEffect(() => {
        const fetchData = async() => {
            const workInfo = (await callProc('SLT_WORKPLACEINFO_AUTH')).data;
            const accInfo = (await callProc('SLT_ACCOUNT_COMBO')).data;
            const bankInfo = (await callProc('SLT_BANK_COMBO')).data;
            const empInfo = (await callProc('SLT_EMPINFO_ALL')).data;

            setWorkInfo(workInfo);
            setAccInfo(accInfo);
            setBankInfo(bankInfo);
            setEmpInfo(empInfo);
        };
        fetchData();
    }, [])

    useEffect(() => {
        const fetchData = async() => {
            const cstData = (await callProc('SLT_CUSTOMER_EDOC_COMBO', 
            { 
                CST_TYPE: 'ALL',
                CST_KIND: 'ALL'
            })).data;

            const cstInfo = cstData.reduce((result, item) => ({
                ...result,
                [item['WORK_CD']]: [
                    ...(result[item['WORK_CD']] || []),
                    item
                ]
            }), workInfo.reduce((result, item) =>({
                ...result,
                [item['WORK_CD']] : []
            }), {}));

            const cstBankInfo = cstData.reduce((result, item) => ({
                ...result,
                [item['WORK_CD']]: {
                    ...result[item['WORK_CD']],
                    [item['CST_CD']]: item
                }
            }), {});
            setCstInfo(cstInfo);
            setCstBankInfo(cstBankInfo);
        };
        !newCstOpen && workInfo && fetchData();
    }, [newCstOpen, workInfo]);

    useEffect(() => {
        const fetchData = async() => {
            let selectProc;
            switch (selectTab) {
                case 0:
                    selectProc = 'SLT_EDOC_ACCOUNTING_APPROVAL';
                    break;
                case 1:
                    selectProc = 'SLT_EDOC_ACCOUNTING_NOT_CONFIRM';
                    break;
                case 2:
                    selectProc = 'SLT_EDOC_ACCOUNTING_BLOCK';
                    break;
                case 3:
                    selectProc = 'SLT_EDOC_ACCOUNTING_NOT_PROCESS';
                    break;
                case 4:
                    selectProc = 'SLT_EDOC_ACCOUNTING_LIST';
                    break;
                default: return;
            }
            const gridData = await callProc(selectProc, { ...condition, LOGIN_EMP_CD: loginState['EMP_CD'] });
            setGridData(gridData);
        }
        condition && fetchData();
    }, [selectTab, condition, loginState]);

    const onKeyPress = (e, condition) => {
        if(e.key === 'Enter') {
            setCondition({...condition})
        }
    }

    const onSelected = async(row) => {
        if(row && row['FILE_KEY']) {
            const fileList = await getFileList(row['FILE_KEY']);
            setFileKey(row['FILE_KEY']);
            setFileData(fileList);
        }else {
            setFileKey(null);
            setFileData(null);
        }
        setSelectRow(row);
    }

    const getTotal = (data, field) => data && numberFormat(data.reduce((result, item) => result + item[field], 0));

    const IconMenuItem = forwardRef(({ onClick, icon, label }, ref) => {
        return (
            <PopupMenuItem onClick={onClick} ref={ref}>
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                {label}
            </PopupMenuItem>
        )
    })

    const reqSave = (type) => {
        snack.current && closeSnackbar(snack.current);

        let msg;
        let variant = 'default';
        switch (type) {
            default:
            case saveType.APPROVAL:
                msg = '승인할까요?';
                break;
            case saveType.BLOCK:
                msg = '보류할까요?';
                variant = 'warning';
                break;
            case saveType.PROCESS_CONFIRM:
                msg = '처리확인할까요?';
                break;
            case saveType.REMOVE:
                msg = '파기할까요?';
                variant = 'warning';
                break;
        }
        snack.current = enqueueSnackbar(msg, {
            variant: variant,
            autoHideDuration: null,
            action: key => <>
                <Button onClick={() => {
                    closeSnackbar(key);
                    onSave(type);
                }}>
                    예
                </Button>
                <Button onClick={() => closeSnackbar(key)}>
                    아니오
                </Button>
            </>,
        });
    }

    const onSave = async (type) => {
        if(!selectRow){
            enqueueSnackbar('선택된 문서가 없습니다.', {variant: 'error'});
            return;
        }
        
        let procName;
            let msg;
            switch (type) {
                default:
                case saveType.APPROVAL:
                    procName = 'INS_EDOC_APPROVAL';
                    msg = '승인이 완료되었습니다.';
                    break;
                case saveType.BLOCK:
                    procName = 'INS_EDOC_BLOCK';
                    msg = '보류가 완료되었습니다.';
                    break;
                case saveType.PROCESS_CONFIRM:
                    procName = 'INS_EDOC_PROCESS_CONFIRM';
                    msg = '처리확인이 완료되었습니다.';
                    break;
                case saveType.REMOVE:
                    procName = 'INS_EDOC_REMOVE';
                    msg = '파기가 완료되었습니다.';
                    break;
            }

            const result = await callProc(procName, {
                DOC_CD: selectRow['DOC_CD'],
                EMP_CD: loginState['EMP_CD'],
            })

            if (!result.err) {
                enqueueSnackbar(msg);
                refreshGrid();
            }
    }

    const docConfirm = () => {
        switch (selectTab) {
            default:
            case 0:
                return <Fragment>
                    {String(selectRow['DOC_STATE']) === '0' || String(selectRow['DOC_STATE']) === '1' ?
                    <IconButton
                        tooltip='문서확인'
                        icon={<CheckIcon />}
                        onClick={() => {
                            setEmpDialogLoadType('confirm');
                            setEmpOpen(true);
                        }}
                    /> : 
                    <IconButton
                        tooltip='승인'
                        icon={<CheckIcon />}
                        onClick={() => reqSave(saveType.APPROVAL)}
                    />}
                    <IconButton
                        tooltip='보류'
                        icon={<BlockIcon />}
                        onClick={() => reqSave(saveType.BLOCK)}
                    />
                </Fragment>
                case 1:
                    return (
                        (selectRow['DOC_STATE'].toString() === '0' &&
                        selectRow['EMP_CD'] === loginState['EMP_CD']) &&
                        <IconButton
                            tooltip='수정'
                            icon={<EditIcon />}
                            onClick={() => {
                                setEnrollOpen(docOpenType.EDIT);
                            }}
                        />
                    )
                case 2:
                    return <Fragment>
                        <IconButton
                            tooltip='재작성'
                            icon={<CreateIcon />}
                            onClick={() => {
                                setEnrollOpen(docOpenType.REEDIT);
                            }}
                        />
                        <IconButton
                            tooltip='파기'
                            icon={<DeleteForeverIcon />}
                            onClick={() => reqSave(saveType.REMOVE)}
                        />
                    </Fragment>
                case 3:
                    return (
                        selectRow['DOC_STATE'].toString() !== '6' ? 
                        <IconButton
                            tooltip='처리완료'
                            icon={<CheckIcon />}
                            onClick={() => {
                                setEmpDialogLoadType('process');
                                setEmpOpen(true);
                            }}
                        /> : 
                        <IconButton
                            tooltip='처리확인'
                            icon={<CheckIcon />}
                            onClick={() => reqSave(saveType.PROCESS_CONFIRM)}
                        />
                    )
        }
    }

    const refreshGrid = () => {
        setCondition({...condition});
    }

    useEffect(() => {
        const fetchData = async() => {
            setInfoEdocLine(null);
            setInfoLineRef(null);
            setInfoRemark(null);
            setInfoList(null);
            setInfoEfms(null);
            setInfoEdocAcc(null);

            const infoEdocLine = (await callProc('SLT_EDOCLINE_STATE', selectRow)).data;
            const infoLineRef = (await callProc('SLT_EDOCLINE_REF', selectRow)).data;
            const infoRemark = (await callProc('SLT_EDOCREMARK', selectRow)).data;
            const infoList = (await callProc('SLT_EDOCLIST_SALE_INBOUND', selectRow)).data;
            const infoEfms = (await callProc('SLT_EDOCLIST_EFMS_IN_OUTMONEY', selectRow)).data;
            const infoEdocAcc = (await callProc('SLT_EDOCACC_STATEMENT', selectRow)).data;

            setInfoEdocLine(infoEdocLine);
            setInfoLineRef(infoLineRef);
            setInfoRemark(infoRemark);
            setInfoList(infoList);
            setInfoEfms(infoEfms);
            setInfoEdocAcc(infoEdocAcc);
        };

        selectRow && fetchData();
    }, [selectRow]) 

    useEffect(() => {
        const fetchData = async() => {
            const docHeaderData = (await callProc('SLT_EDOCHEADER_COPY', {DOC_CD: selectRow['DOC_CD']})).data[0];
            const docProData = await callProc('SLT_EDOCLIST_COPY', {DOC_CD: selectRow['DOC_CD']});
            const docAccData = (await callProc('SLT_EDOCACC_COPY', {DOC_CD: selectRow['DOC_CD']})).data.reduce((result, item) => ({
                ...result,
                [item['PRO_SEQ']] : [
                    ...(result[item['PRO_SEQ']] || []),
                    item
                ]
            }), {});


            const docLineData = (await callProc('SLT_EDOCLINE_COPY', {DOC_CD: selectRow['DOC_CD']})).data.reduce((result, item) => ({
                ...result,
                [item['LINE_TYPE']]: [
                    ...(result[item['LINE_TYPE']] || []),
                    item
                ]
            }), []);

            setDocHeaderData(docHeaderData);
            setDocProData(docProData);
            setDocAccData(docAccData);
            setDocLineData(docLineData);
        };

        selectRow && (enrollOpen === docOpenType.COPY || enrollOpen === docOpenType.EDIT || enrollOpen === docOpenType.REEDIT) && fetchData();
    }, [selectRow, enrollOpen]);

    const pdfComponent = useMemo(() => (
        <PdfButton>
            {selectRow && 
            infoEdocLine && 
            infoLineRef && 
            infoList &&
            infoEfms &&
            infoEdocAcc &&
            infoRemark &&
            <EdocumentStatePdf
                data={selectRow}
                lineData={infoEdocLine}
                lineRef={infoLineRef}
                listData={infoList}
                efmsData={infoEfms}
                edocAccData={infoEdocAcc}
                remarkData={infoRemark}
                fileData={fileData}
                fileKey={selectRow['FILE_KEY']}
            />}
        </PdfButton>
    ), [selectRow, infoEdocLine, infoLineRef, infoList, infoEfms, infoEdocAcc, infoRemark, fileData]);

    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => {
                setCondition(condition);
                }}>
                <SearchItem
                    fieldName='SEARCH_TYPE'
                    component={(value, setState) => (
                        <ComboBox
                            label='검색구분'
                            data={searchType}
                            value={value}
                            onChange={value => setState(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='SEARCH_ITEM'
                    defaultValue=''
                    component={(value, setState, condition) => (
                        <TextField
                            label='검색어'
                            value={value}
                            onChange={value => setState(value)}
                            onKeyPress={e => onKeyPress(e, condition)}
                        />
                    )}      
                />
            </SearchPanel>
            <div className={classes.content}>
                <div className={classes.infoContainer}>
                    <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps>
                        <Tab label='결재' className={classes.tab} icon={<PlaylistAddCheckIcon />} />
                        <Tab label='미결' className={classes.tab} icon={<AssignmentTurnedInIcon />} />
                        <Tab label='재결' className={classes.tab} icon={<RepeatIcon />} />
                        <Tab label='미처리' className={classes.tab} icon={<HourglassEmptyIcon />} />
                        <Tab label='조회' className={classes.tab} icon={<ViewListIcon />} />
                    </Tabs>
                    <DataGrid
                        className={classes.info}
                        dataSet={gridData}
                        onSelect={onSelected}
                        selectionMode='single'
                        headerLess
                        virtualized
                        horizonMode
                    >
                        <DataColumn
                            fieldName='DOC_SEQ'
                            title={`문서번호 (${gridData && gridData.data.length})`}
                            value={(row, value) => {
                            return <div>
                                <div className={classes.docSeq}>
                                    <div>
                                        {row['FILE_KEY'] ?
                                        <AttachFileIcon fontSize='small' /> : null}
                                    </div>
                                    <div>
                                        {value}
                                    </div>
                                </div>
                                <div className={classes.docTitle}>{row['DOC_TITLE']}</div>
                            </div>}}
                        />
                        <DataColumn
                            fieldName='DOC_STATE'
                            title='상태'
                            value={(row, value) => docState[value] + (row['DLT_DATE'] ? `(${row['DLT_DATE']})` : '')}
                            cellStyle={{ height: 60, paddingBottom: 22 }}
                        />
                        <DataColumn
                            fieldName='EMP_NM'
                            title='작성자'
                            cellStyle={{ paddingBottom: 22 }}
                        />
                        <DataColumn
                            fieldName='REGDATE'
                            title='등록일'
                            cellStyle={{ paddingBottom: 22 }}
                        />
                        <DataColumn fieldName='DOC_TITLE' visible={false} />
                        <DataColumn fieldName='DOC_CD' visible={false} />
                        <DataColumn fieldName='DOC_DATE' visible={false} />
                        <DataColumn fieldName='REMARK' visible={false} />
                        <DataColumn fieldName='DEP_NM' visible={false} />
                        <DataColumn fieldName='POSITION' visible={false} />
                        <DataColumn fieldName='REGDTTM' visible={false} />
                        <DataColumn fieldName='FILE_KEY' visible={false} />
                        <DataColumn fieldName='CONFM_TYPE' visible={false} />
                        <DataColumn fieldName='EMP_CD' visible={false} />
                        <DataColumn fieldName='DLT_DATE' visible={false} />
                    </DataGrid>
                </div>
                <Paper className={classes.viewr}>
                    <div className={classes.paperTitle}>
                        <PaperTitle>
                            문서 내용
                            {(!selectRow || (selectRow && String(selectRow['CONFM_TYPE']) === '1')) ? null : docConfirm()}
                        </PaperTitle>
                        <div className={classes.actionButton}>
                            {selectRow && pdfComponent}
                            <PopupMenu
                                anchorEl={anchorEl}
                                open={anchorEl != null}
                                onClose={() => setAnchorEl(null)}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                {fileKey && fileData && Object.keys(fileData)[0] !== 'err' && fileData.map((fileItem, idx) =>
                                    <IconMenuItem 
                                        key={idx}
                                        label={fileItem.name}
                                        icon={<GetAppOutlinedIcon />}
                                        onClick={() => downloadFile(fileKey, fileItem.seq)}
                                    />
                                )}
                            </PopupMenu>
                            {fileData && fileData.length > 0 &&
                            <IconButton
                                tooltip='첨부파일'
                                icon={<AttachFileIcon />}
                                onClick={e => setAnchorEl(e.currentTarget)}
                            />}
                            <IconButton
                                tooltip='참고사항'
                                onClick={() => setRemarkOpen(true)}
                                icon={<PostAddIcon />}
                            />
                            <IconButton
                                tooltip='거래처등록'
                                onClick={() => setNewCstOpen(true)}
                                icon={<NoteAddIcon />}
                            />
                            {selectRow?.DOC_CD &&
                            <IconButton
                                tooltip='문서복사'
                                onClick={() => setEnrollOpen(docOpenType.COPY)}
                                icon={<FileCopyIcon />}
                            />}
                            <IconButton
                                tooltip='추가'
                                onClick={() => setEnrollOpen(docOpenType.NEW)}
                                icon={<AddIcon />}
                            />
                        </div>
                    </div>
                    {selectRow && infoEdocLine && infoList && infoEfms &&
                    <div className={classes.viewContainer}>
                        <label className={classes.viewTitleText}>{`${selectRow['DOC_TITLE']}`}</label>
                        <div className={classes.viewWriterContainer}>
                            <label className={classes.viewWriter}>
                                {`작성자 : ${selectRow['DEP_NM'] || ''} / ${selectRow['EMP_NM'] || ''} / ${selectRow['POSITION'] || ''} / ${selectRow['REGDTTM']}`}
                            </label>
                            <label className={classes.viewWriter}>{`문서번호 : ${selectRow['DOC_CD']}`}</label>
                        </div>
                        
                        <label className={classes.viewSubTitleText}>{`[결제 정보]`}</label>
                        <table className={classes.viewTable}>
                            <thead>
                                <tr className={classes.viewHeaderRow}>
                                    <th className={classes.viewCenterCell}>분류</th>
                                    <th className={classes.viewCenterCell}>부서</th>
                                    <th className={classes.viewCenterCell}>성명</th>
                                    <th className={classes.viewCenterCell}>상태</th>
                                    <th className={classes.viewCenterCell}>결제일시</th>
                                </tr>
                            </thead>
                            <tbody>
                                {infoEdocLine && infoEdocLine.length !== 0 &&
                                infoEdocLine.map((item, idx) => (
                                    <tr key={idx} className={classes.viewRow}>
                                        <th className={classes.viewDataCenterCell}>{item['LINE_TYPE']}</th>
                                        <th className={classes.viewDataCenterCell}>{item['DEP_NM']}</th>
                                        <th className={classes.viewDataCenterCell}>{item['EMP_NM']}</th>
                                        <th className={classes.viewDataCenterCell}>{item['CONFM_TYPE']}</th>
                                        <th className={classes.viewDataCenterCell}>{item['PRO_DTTM']}</th>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <label className={classes.viewSubTitleText}>{`[참조자]`}</label>
                        <div className={classes.viewNotifyContainer}>
                            <div className={classes.viewNotifyHeader}>
                                <label className={classes.viewNotifyHeaderText}>{`참조자`}</label>
                            </div>
                            <div className={classes.viewNotifyContent}>
                                <label className={classes.viewNotifyContentText}>{infoLineRef ? infoLineRef[0]['REF_NM'] : `참조자 없음.`}</label>
                            </div>
                        </div>
                        <label className={classes.viewSubTitleText}>{`[참고 사항]`}</label>
                        <div className={classes.viewNotifyContainer}>
                            <div className={classes.viewNotifyHeader}>
                                <label className={classes.viewNotifyHeaderText}>{`내용`}</label>
                            </div>
                            <div className={classes.viewRemarkContent}>
                                {(!infoRemark || infoRemark.length === 0) ?
                                <label className={classes.viewRemarkText} style={{ marginBottom: 0, }}>{`내용 없음`}</label> :
                                infoRemark && infoRemark.length !== 0 &&
                                infoRemark.map((item, idx) => (
                                    <div key={idx} className={classes.viewRemarkContainer}>
                                        <label className={classes.viewRemarkText} style={{ fontWeight: 'bold' }}>
                                            {`< ${item['DEP_NM']} /  ${item['POSITION']} / ${item['EMP_NM']} / ${item['EDTDATE']} >`}
                                        </label>
                                        <label className={classes.viewRemarkText}>
                                            {`- ${item['REMARK']}`}
                                        </label>
                                    </div>
                                ))}
                            </div>  
                        </div>
                        <label className={classes.approvalTitle}>{`결재 항목`}</label>
                        <label className={classes.approvalSubTtile}>{`1. 매입 / 매출`}</label>
                        <table className={classes.viewTable}>
                            <thead>
                                <tr className={classes.viewHeaderRow}>
                                    <th className={classes.viewCenterCell} style={{ width: '4%' }}>{`번호`}</th>
                                    <th className={classes.viewLeftCell} style={{ width: '8%' }}>{`유형`}</th>
                                    <th className={classes.viewLeftCell} style={{ width: '4%' }}>{`구분`}</th>
                                    <th className={classes.viewLeftCell} style={{ width: '4%' }}>{`대상`}</th>
                                    <th className={classes.viewLeftCell} style={{ width: '8%' }}>{`계정과목`}</th>
                                    <th className={classes.viewLeftCell} style={{ width: '9%' }}>{`발행(생)일`}</th>
                                    <th className={classes.viewLeftCell}>{`거래처`}</th>
                                    <th className={classes.viewLeftCell} style={{ width: '16%' }}>{`상품`}</th>
                                    <th className={classes.viewRightCell} style={{ width: '5%' }}>{`단가`}</th>
                                    <th className={classes.viewRightCell} style={{ width: '5%' }}>{`수량`}</th>
                                    <th className={classes.viewRightCell} style={{ width: '7%' }}>{`공급가액`}</th>
                                    <th className={classes.viewRightCell} style={{ width: '7%' }}>{`합계금액`}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {infoList && infoList.length !== 0 &&
                                infoList.map((item, idx) => (
                                    <tr key={idx} className={classes.viewRow}>
                                        <th className={classes.viewDataCenterCell}>{item['SEQ']}</th>
                                        <th className={classes.viewDataLeftCell}>{item['PRO_TYPE']}</th>
                                        <th className={classes.viewDataLeftCell}>{item['IO_TYPE']}</th>
                                        <th className={classes.viewDataLeftCell}>{item['WORK_SHORT_NM']}</th>
                                        <th className={classes.viewDataLeftCell}>{item['ACC_NM']}</th>
                                        <th className={classes.viewDataLeftCell}>{item['ISSUE_DATE']}</th>
                                        <th className={classes.viewDataLeftCell}>{item['CST_NM']}</th>
                                        <th className={classes.viewDataLeftCell}>{item['GDS_NM']}</th>
                                        <th className={classes.viewDataRightCell}>{numberFormat(item['PRICE'])}</th>
                                        <th className={classes.viewDataRightCell}>{numberFormat(item['QTY'])}</th>
                                        <th className={classes.viewDataRightCell}>{numberFormat(item['SPLY_AMT'])}</th>
                                        <th className={classes.viewDataRightCell}>{numberFormat(item['AMOUNT'])}</th>
                                    </tr>
                                ))}
                                {infoList && infoList.length !== 0 &&
                                <tr className={classes.viewRow}>
                                    <th className={classes.viewDataCenterCell}></th>
                                    <th className={classes.viewDataLeftCell}>{`(소 계)`}</th>
                                    <th className={classes.viewDataLeftCell}></th>
                                    <th className={classes.viewDataLeftCell}></th>
                                    <th className={classes.viewDataLeftCell}></th>
                                    <th className={classes.viewDataLeftCell}></th>
                                    <th className={classes.viewDataLeftCell}></th>
                                    <th className={classes.viewDataLeftCell}></th>
                                    <th className={classes.viewDataRightCell}></th>
                                    <th className={classes.viewDataRightCell}>{getTotal(infoList, 'QTY')}</th>
                                    <th className={classes.viewDataRightCell}>{getTotal(infoList, 'SPLY_AMT')}</th>
                                    <th className={classes.viewDataRightCell}>{getTotal(infoList, 'AMOUNT')}</th>
                                </tr>}
                            </tbody>
                        </table>
                        <label className={classes.approvalSubTtile}>{`2. eFMS - 입 / 출금`}</label>
                        <table className={classes.viewTable}>
                            <thead>
                                <tr className={classes.viewHeaderRow}>
                                    <th className={classes.viewCenterCell} style={{ width: '4%' }}>{`번호`}</th>
                                    <th className={classes.viewLeftCell} style={{ width: '8%' }}>{`유형`}</th>
                                    <th className={classes.viewLeftCell} style={{ width: '4%' }}>{`구분`}</th>
                                    <th className={classes.viewLeftCell} style={{ width: '4%' }}>{`대상`}</th>
                                    <th className={classes.viewLeftCell} style={{ width: '18%' }}>{`입/출금 계좌 정보`}</th>
                                    <th className={classes.viewLeftCell} style={{ width: '11%' }}>{`출금통장표시`}</th>
                                    <th className={classes.viewLeftCell} style={{ width: '9%' }}>{`입/출금일`}</th>
                                    <th className={classes.viewLeftCell} style={{ width: '8%' }}>{`신용카드`}</th>
                                    <th className={classes.viewLeftCell}>{`적요`}</th>
                                    <th className={classes.viewRightCell} style={{ width: '7%' }}>{`출금액`}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {infoEfms && infoEfms.length !== 0 &&
                                infoEfms.map((item, idx) => (
                                    <tr key={idx} className={classes.viewRow}>
                                        <th className={classes.viewDataCenterCell}>{item['SEQ']}</th>
                                        <th className={classes.viewDataLeftCell}>{item['PRO_TYPE']}</th>
                                        <th className={classes.viewDataLeftCell}>{item['IO_TYPE']}</th>
                                        <th className={classes.viewDataLeftCell}>{item['WORK_SHORT_NM']}</th>
                                        <th className={classes.viewDataLeftCell}>{item['INOUT_BBKNO']}</th>
                                        <th className={classes.viewDataLeftCell}>{item['OUT_REMARK']}</th>
                                        <th className={classes.viewDataLeftCell}>{item['INOUT_DATE']}</th>
                                        <th className={classes.viewDataLeftCell}>{item['CRT_NM']}</th>
                                        <th className={classes.viewDataLeftCell}>{item['SUMUP']}</th>
                                        <th className={classes.viewDataRightCell}>{numberFormat(item['OUT_AMOUNT'])}</th>
                                    </tr>
                                ))}
                                {infoEfms && infoEfms.length !== 0 &&
                                <tr className={classes.viewRow}>
                                    <th className={classes.viewDataCenterCell}></th>
                                    <th className={classes.viewDataLeftCell}>{`(소 계)`}</th>
                                    <th className={classes.viewDataLeftCell}></th>
                                    <th className={classes.viewDataLeftCell}></th>
                                    <th className={classes.viewDataLeftCell}></th>
                                    <th className={classes.viewDataLeftCell}></th>
                                    <th className={classes.viewDataLeftCell}></th>
                                    <th className={classes.viewDataLeftCell}></th>
                                    <th className={classes.viewDataLeftCell}></th>
                                    <th className={classes.viewDataRightCell}>{getTotal(infoEfms, 'OUT_AMOUNT')}</th>
                                </tr>}
                            </tbody>
                        </table>
                        {infoEdocAcc && infoEdocAcc.length !== 0 &&
                        <label className={classes.approvalSubTtile}>{`3. 분개 항목`}</label>}
                        {infoEdocAcc && infoEdocAcc.length !== 0 &&
                        <table className={classes.viewTable}>
                            <thead>
                                <tr className={classes.viewHeaderRow}>
                                    <th className={classes.viewCenterCell} style={{ width: '4%' }}>{`번호`}</th>
                                    <th className={classes.viewLeftCell} style={{ width: '8%' }}>{`유형`}</th>
                                    <th className={classes.viewLeftCell} style={{ width: '4%' }}>{`구분`}</th>
                                    <th className={classes.viewLeftCell} style={{ width: '4%' }}>{`대상`}</th>
                                    <th className={classes.viewLeftCell} style={{ width: '8%' }}>{`계정과목`}</th>
                                    <th className={classes.viewLeftCell} style={{ width: '16%' }}>{`거래처`}</th>
                                    <th className={classes.viewRightCell} style={{ width: '9%' }}>{`차변`}</th>
                                    <th className={classes.viewRightCell} style={{ width: '9%' }}>{`대변`}</th>
                                    <th className={classes.viewLeftCell} style={{ paddingLeft: 32 }}>{`적요`}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {infoEdocAcc && infoEdocAcc.length !== 0 && 
                                infoEdocAcc.map((item, idx) => {
                                    const prevItem = infoEdocAcc[idx - 1];
                                    const nextItem = infoEdocAcc[idx + 1];
                                    const isEqualPrev = prevItem && item['SEQ'] === prevItem['SEQ'];
                                    const isEqualNext = nextItem && item['SEQ'] === nextItem['SEQ'];
                                    let totalRow;
                                    
                                    if (!isEqualNext) {
                                        const groupData = infoEdocAcc.reduce((result, row) => (
                                            row['SEQ'] === item['SEQ'] ?
                                                [...result, row] :
                                                result
                                        ), [])
                                        totalRow = (
                                            <tr key={'s' + idx}>
                                                <th className={classes.viewDataCenterCell}></th> 
                                                <th className={classes.viewDataLeftCell}></th>
                                                <th className={classes.viewDataLeftCell}></th>
                                                <th className={classes.viewDataLeftCell}></th>
                                                <th className={classes.viewDataLeftCell}>{`(소 계)`}</th>
                                                <th className={classes.viewDataLeftCell}></th>
                                                <th className={classes.viewDataRightCell}>{getTotal(groupData, 'DEBTOR')}</th>
                                                <th className={classes.viewDataRightCell}>{getTotal(groupData, 'CREDITOR')}</th>
                                                <th className={classes.viewDataLeftCell}></th>
                                            </tr>
                                        )
                                    }
                                    return ([
                                        <tr key={idx} className={classes.viewRow}>
                                            <th className={classes.viewDataCenterCell}>{!isEqualPrev && item['SEQ']}</th>
                                            <th className={classes.viewDataLeftCell}>{!isEqualPrev && item['PRO_TYPE']}</th>
                                            <th className={classes.viewDataLeftCell}>{!isEqualPrev && item['IO_TYPE']}</th>
                                            <th className={classes.viewDataLeftCell}>{!isEqualPrev && item['WORK_SHORT_NM']}</th>
                                            <th className={classes.viewDataLeftCell}>{item['ACC_NM']}</th>
                                            <th className={classes.viewDataLeftCell}>{item['CST_NM']}</th>
                                            <th className={classes.viewDataRightCell}>{numberFormat(item['DEBTOR'])}</th>
                                            <th className={classes.viewDataRightCell}>{numberFormat(item['CREDITOR'])}</th>
                                            <th className={classes.viewDataLeftCell} style={{ paddingLeft: 32 }}>{item['SUMUP']}</th>
                                        </tr>,
                                        totalRow
                                    ])
                                })}
                            </tbody>
                        </table>}
                        {fileKey && fileData && Object.keys(fileData)[0] !== 'err' &&
                        <div className={classes.fileContainer}>
                            <label>{`[첨부파일]`}</label>
                            <div className={classes.fileContent}>
                                {fileData.map((file, index) => (
                                    <div key={index} className={classes.fileItem}>
                                        {file.name}
                                    </div>
                                ))}
                            </div>
                        </div>}
                    </div>}
                </Paper>
                {infoRemark &&
                <ElectRemarkDialog
                    open={remarkOpen}
                    onClose={() => setRemarkOpen(false)}
                    onInserted={() => setSelectRow({...selectRow})}
                    docCd={selectRow && selectRow['DOC_CD']}
                    data={infoRemark}
                />}
                <NewCstDialog
                    open={newCstOpen}
                    onClose={() => {
                        setNewCstOpen(false);
                        
                    }}
                />
                <DocConfirmEmpDialog
                    open={empOpen}
                    onClose={() => setEmpOpen(false)}
                    empDialogLoadType={empDialogLoadType}
                    selectRow={selectRow}
                    empInfo={empInfo}
                    refreshGrid={refreshGrid}
                    loginState={loginState}
                />
                {workInfo && cstInfo && cstBankInfo &&
                accInfo && bankInfo &&
                <EnrollDialog
                    open={enrollOpen}
                    onClose={() => setEnrollOpen(null)}
                    docHeaderData={docHeaderData}
                    docProData={docProData}
                    docAccData={docAccData}
                    docLineData={docLineData}
                    infoRemark={infoRemark}
                    workInfo={workInfo}
                    cstInfo={cstInfo}
                    cstBankInfo={cstBankInfo}
                    accInfo={accInfo}
                    bankInfo={bankInfo}
                    fileKey={fileKey}
                    fileData={fileData}
                    refreshGrid={refreshGrid}
                    setNewCstOpen={(isOpen) => setNewCstOpen(isOpen)}
                />}
            </div>
        </div>
    )
}

const docConfirmStyle = makeStyles((theme) => ({
    dialogPaper: {
        width: 400,
        height: 200,
        '& > *': {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    fieldContainer: {
        overflowY: 'auto',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
            marginBottom: theme.spacing(2),
        },
    },
    btnContainer: {
        alignSelf: 'flex-end',
        padding: theme.spacing(1),
    },
}))

const DocConfirmEmpDialog = ({open, onClose, selectRow, empDialogLoadType, empInfo, refreshGrid, loginState}) => {
    const classes = docConfirmStyle();
    const [empCd, setEmpCd] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    const onSave = async () => {
        if(!selectRow){
            enqueueSnackbar('선택된 문서가 없습니다.', {variant: 'error'});
            return;
        }

        let result;
        if(empDialogLoadType === 'confirm'){
            result = await callProc('INS_EDOC_CONFIRM', {
                DOC_CD: selectRow['DOC_CD'],
                CONF_EMP_CD: empCd,
                EMP_CD: loginState['EMP_CD'],
            })
        }else{
            result = await callProc('INS_EDOC_PROCESS', {
                DOC_CD: selectRow['DOC_CD'],
                CONF_EMP_CD: empCd,
                EMP_CD: loginState['EMP_CD'],
            })
        }

        if(!result.err){
            enqueueSnackbar('저장이 완료되었습니다.');
            refreshGrid();
            onClose();
        }
    }

    return (
        <Dialog
            open={open}
            PaperProps={{ className: classes.dialogPaper }}
        >
            <div className={classes.dialogTitle}>
                <DialogTitle>
                    {empDialogLoadType === 'confirm' ? '승인자 등록' : '처리확인자 등록'}
                </DialogTitle>
                <IconButton
                    tooltip='닫기'
                    onClick={onClose}
                    icon={<CloseIcon />}
                />
            </div>
            <div className={classes.fieldContainer}>
                <ComboBox
                    label='사원명'
                    data={empInfo}
                    keyField='EMP_CD'
                    valueField='EMP_NM'
                    value={empCd}
                    onChange={value => setEmpCd(value)}
                />
            </div>
            <div className={classes.btnContainer}>
                <IconButton
                    tooltip='확인'
                    onClick={onSave}
                    icon={<CheckIcon />}
                />
            </div>
        </Dialog>
    )
}

const newCstkStyle = makeStyles((theme) => ({
    dialogPaper: {
        width: 400,
        height: 600,
        '& > *': {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    fieldContainer: {
        overflowY: 'auto',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
            marginBottom: theme.spacing(2),
        },
    },
    btnContainer: {
        alignSelf: 'flex-end',
        padding: theme.spacing(1),
    },
}))

const NewCstDialog = ({ open, onClose }) => {
    const classes = newCstkStyle();
    const [insData, setInsData] = useFieldInputs({
        CST_KIND: '2',
        CST_NM: '',
        WORK_BIZNO: '',
        CEO: '',
        BIZ_COND: '',
        BIZ_ITEM: '',
        BIZ_EMAIL: '',
    })
    const { enqueueSnackbar } = useSnackbar();

    const valid = () => {
        if (insData['CST_NM'] === '') {
            enqueueSnackbar('거래처명을 입력해주세요.', { variant: 'error' });
            return false;
        }

        return true;
    }


    const onSave = async () => {
        if (valid()) {
            const result = await callProc('INS_CUSTOMERINFO_STATEMENT', insData);

            if (!result.err) {
                enqueueSnackbar('저장이 완료되었습니다.');
            }
        }
    }

    return (
        <Dialog
            open={open}
            PaperProps={{ className: classes.dialogPaper }}
        >
            <div className={classes.dialogTitle}>
                <DialogTitle>거래처 등록</DialogTitle>
                <IconButton
                    tooltip='닫기'
                    onClick={onClose}
                    icon={<CloseIcon />}
                />
            </div>
            <div className={classes.fieldContainer}>
                <ComboBox
                    label='사업장'
                    selectProc='SLT_WORKPLACEINFO_AUTH'
                    keyField='WORK_CD'
                    valueField='WORK_NM'
                    value={insData['WORK_CD']}
                    onChange={value => setInsData('WORK_CD', value)}
                />
                <ComboBox
                    label='거래처종류'
                    data={cstKind}
                    value={insData['CST_KIND']}
                    onChange={value => setInsData('CST_KIND', value)}
                />
                <TextField
                    label='거래처명'
                    value={insData['CST_NM']}
                    onChange={value => {
                        setInsData('CST_NM', value);
                    }}
                />
                <TextField
                    label='사업자번호'
                    value={insData['WORK_BIZNO']}
                    onChange={value => setInsData('WORK_BIZNO', value)}
                />
                <TextField
                    label='대표자'
                    value={insData['CEO']}
                    onChange={value => setInsData('CEO', value)}
                />
                <TextField
                    label='업태'
                    value={insData['BIZ_COND']}
                    onChange={value => setInsData('BIZ_COND', value)}
                />
                <TextField
                    label='종목'
                    value={insData['BIZ_KIND']}
                    onChange={value => setInsData('BIZ_KIND', value)}
                />
                <TextField
                    label='이메일'
                    value={insData['BIZ_EMAIL']}
                    onChange={value => setInsData('BIZ_EMAIL', value)}
                />
            </div>
            <div className={classes.btnContainer}>
                <IconButton
                    tooltip='확인'
                    onClick={onSave}
                    icon={<CheckIcon />}
                />
            </div>
        </Dialog>
    )
}

const enrollDialogStyle = makeStyles((theme) => ({
    dialogPaper: {
        maxWidth: 'none',
        width: 1550,
        height: '80%',
        '& > *': {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    fieldContainer: {
        overflowY: 'auto',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
            marginBottom: theme.spacing(2),
        },
    },
    containerRowFlex: {
        display: 'flex',
        flexDirection: 'row',
    },
    empFields: {
        marginRight: theme.spacing(1),
        width: 350,
    },
    cancelBtn: {
        marginRight: theme.spacing(1)
    },

    actionButton: {
        display: 'flex',
    },
    empButton: {
        marginTop: theme.spacing(1),
    },
    titleField: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        flex: 1,
    },
    sumFiled: {
        marginLeft: theme.spacing(1),
    },
    content: {
        flex: 1,
        height: 0,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        overflowY: 'scroll',
    },
}))

const EnrollDialog = ({ open, onClose, docHeaderData, docProData, docAccData, docLineData, infoRemark, workInfo, cstInfo, cstBankInfo, accInfo, bankInfo, fileKey, fileData, refreshGrid, setNewCstOpen }) => {
    const classes = enrollDialogStyle();
    const [insData, setInsData] = useFieldInputs({
        DOC_TITLE: '',
        CON_JSON: null,
        ACT_JSON: null,
        REF_JSON: null,
    }); 
    const [lineOpenType, setLineOpenType] = useState(null);
    const [accOpen, setAccOpen] = useState(false);
    const [selectAccData, setSelectAccData] = useState({
        data:[],
        fields: [
            'PRO_SEQ', 'SEQ', 'WORK_CD', 'STATE_TYPE', 'ACC_CD', 'CST_CD', 'DEBTOR', 'CREDITOR', 'SUMUP'
        ],
        metaData: {}
    });
    const [selectWorkCd, setSelectWorkCd] = useState(null);
    const [files, setFiles] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const fetchData = () => {
            setInsData({ 
                type: 'UPDATE', 
                value: {
                    DOC_TITLE: docHeaderData?.DOC_TITLE,
                    CON_JSON: docLineData[2], // 확인자,
                    ACT_JSON: docLineData[3], // 처리자,
                    REF_JSON: docLineData[5], // 참조자,
                }
            })
        };
        
        docHeaderData && docLineData && fetchData();
    }, [docHeaderData, docLineData, setInsData])

    useEffect(() => setFiles(fileData && fileData.length > 0 && (open === docOpenType.EDIT || open === docOpenType.REEDIT) ? [...fileData] : []), [fileData, open]);

    const getDocLine = (items) => items ? items.reduce((result, item, idx) => result + (idx === 0 ? '' : ', ') + item['EMP_NM'], '') : '';
    const onCancel = (field) => setInsData(field, null);
    const onDialogOpen = (rowData) => {
        setSelectWorkCd(rowData['WORK_CD']);
        const data = {
            data: !docAccData[rowData['SEQ']] ?
                [
                    {
                        PRO_SEQ: rowData['SEQ'],
                        SEQ: 1,
                        WORK_CD: rowData['WORK_CD'],
                        STATE_TYPE: 0,
                        ACC_CD: rowData['ACC_CD'],
                        CST_CD: null,
                        DEBTOR: rowData['AMOUNT'],
                        CREDITOR: 0,
                        SUMUP: ''
                    },
                    {
                        PRO_SEQ: rowData['SEQ'],
                        SEQ: 2,
                        WORK_CD: rowData['WORK_CD'],
                        STATE_TYPE: 1,
                        ACC_CD: String(rowData['IO_TYPE']) === '1' ? '0001' : '0003',
                        CST_CD: null,
                        DEBTOR: 0,
                        CREDITOR: 0,
                        SUMUP: ''
                    },
                ] :
                [
                    ...docAccData[rowData['SEQ']].map(item => 
                        ({...item, 
                            ACC_CD: item.SEQ === 1 ? rowData['ACC_CD'] : item.ACC_CD,
                            DEBTOR: item.SEQ === 1 ? rowData['AMOUNT'] : item.DEBTOR
                        }))
                ],
            fields: [
                'PRO_SEQ', 'SEQ', 'WORK_CD', 'STATE_TYPE', 'ACC_CD', 'CST_CD', 'DEBTOR', 'CREDITOR', 'SUMUP'
            ],
            metaData: {}
        };
        
        setSelectAccData(data);
        setAccOpen(true);
    }

    const onCopy = (data, newSEQ) => {
        if(docAccData[data['SEQ']])
            docAccData[newSEQ] = docAccData[data['SEQ']]?.map(item => ({...item, PRO_SEQ: newSEQ}));
    }

    const onAccSave = (data, proSeq) => {
        docAccData[proSeq] = data;
    }

    const onRemove = (row) => {
        delete docAccData[row['SEQ']];
    }

    const onSave = async (data) => {
        if(!insData['DOC_TITLE']) {
            enqueueSnackbar('제목을 입력해주세요.', { variant: 'error' });
            return false;
        }

        if(!insData['CON_JSON']) {
            enqueueSnackbar('확인자를 선택해주세요.', { variant: 'error' });
            return false;
        }

        if(!insData['ACT_JSON']) {
            enqueueSnackbar('처리자를 선택해주세요.', { variant: 'error' });
            return false;
        }

        if(open === docOpenType.COPY || open === docOpenType.NEW ){
            fileKey = null;
        }

        if(fileKey || files.length > 0){
            fileKey = await uploadFile(files, fileKey);
            if(fileKey.err) return false;
        }

        const result = await callProc('INS_EDOCHEADER_STATEMENT', {
            DOC_CD_EDIT: open === (docOpenType.EDIT || docOpenType.REEDIT) ? docHeaderData['DOC_CD'] : null,
            DOC_TITLE: insData['DOC_TITLE'],
            REMARK: open === docOpenType.EDIT ? infoRemark : null,
            FILE_KEY: fileKey,
            CON_JSON: insData['CON_JSON'],
            ACT_JSON: insData['ACT_JSON'],
            REF_JSON: insData['REF_JSON'],
            PRO_JSON: data,
            INDIG_JSON: Object.values(docAccData).flatMap(item => item),
            RECREATE: open === docOpenType.RECREATE,
        });

        if(open === docOpenType.REEDIT){
            await callProc('INS_EDOC_REMOVE', {
                DOC_CD: docHeaderData['DOC_CD']
            });
        }

        if(!result.err){
            enqueueSnackbar('저장이 완료되었습니다.');
        }
        
        return true;
    }

    const isDisabled = (rowData, preKeyValue, field) => {
        let PRO_TYPE = '';
        let IO_TYPE = '';
        if(preKeyValue) {
            if(Object.keys(preKeyValue)[0] === 'PRO_TYPE') {
                PRO_TYPE = String(preKeyValue['PRO_TYPE']);
                IO_TYPE = String(rowData['IO_TYPE']);
            } else if(Object.keys(preKeyValue)[0] === 'IO_TYPE') {
                PRO_TYPE = String(rowData['PRO_TYPE']);
                IO_TYPE = String(preKeyValue['IO_TYPE']);
            }
        } else {
            PRO_TYPE = String(rowData['PRO_TYPE']);
            IO_TYPE = String(rowData['IO_TYPE']);
        }

        switch(field) {
            case 'IO_TYPE':
                return PRO_TYPE === '6' || 
                       PRO_TYPE === '7';
            case 'CST_CD':
                return PRO_TYPE === '10' &&
                       (IO_TYPE === '1' ||
                        IO_TYPE === '2' ||
                        IO_TYPE === '3' ||
                        IO_TYPE === '4');
            case 'GDS_NM':
                return (PRO_TYPE === '8' ||
                       PRO_TYPE === '9' ||
                       PRO_TYPE === '10') &&
                       (IO_TYPE === '1' ||
                        IO_TYPE === '2' ||
                        IO_TYPE === '3' ||
                        IO_TYPE === '4');
            case 'CRT_CD':
                return PRO_TYPE !== '3' &&
                       PRO_TYPE !== '9';
            case 'ACC_ICON':
                return PRO_TYPE !== '10';
            case 'PRICE':
            case 'QTY':
            case 'SPLY_AMT':
                return (PRO_TYPE === '8' ||
                       PRO_TYPE === '9' ||
                       PRO_TYPE === '10') &&
                       (IO_TYPE === '1' ||
                        IO_TYPE === '2' ||
                        IO_TYPE === '3' ||
                        IO_TYPE === '4');
            case 'BANK_CD':
            case 'BBK_NO':
            case 'IN_PERSON':
            case 'OUT_REMARK':
                return ((PRO_TYPE === '1' ||
                        PRO_TYPE === '2' ||
                        PRO_TYPE === '3' ||
                        PRO_TYPE === '4' ||
                        PRO_TYPE === '5' ||
                        PRO_TYPE === '8' ||
                        PRO_TYPE === '9' ||
                        PRO_TYPE === '10') && 
                        (IO_TYPE === '1' ||
                         IO_TYPE === '4')) ||
                        PRO_TYPE === '6' ||
                        PRO_TYPE === '7';
            case 'OUT_AMOUNT':
                return ((PRO_TYPE === '1' ||
                        PRO_TYPE === '2' ||
                        PRO_TYPE === '3' ||
                        PRO_TYPE === '4' ||
                        PRO_TYPE === '5' ||
                        PRO_TYPE === '8' ||
                        PRO_TYPE === '9' ||
                        PRO_TYPE === '10') &&
                        (IO_TYPE === '1')) ||
                        PRO_TYPE === '6' ||
                        PRO_TYPE === '7';
            case 'ISSUE_DATE':
                return (PRO_TYPE === '8' ||
                       PRO_TYPE === '10') &&
                       (IO_TYPE === '1' ||
                        IO_TYPE === '2' ||
                        IO_TYPE === '3' ||
                        IO_TYPE === '4');
            case 'SUMUP':
                return PRO_TYPE === '10' &&
                       (IO_TYPE === '1' ||
                        IO_TYPE === '2' ||
                        IO_TYPE === '3' ||
                        IO_TYPE === '4');
            default:
                return false;
        }
    }

    const excelDown = async(row) => {
        const fields = ['PRO_TYPE', 'IO_TYPE', 'WORK_NM', 'CST_NM', 'WORK_BIZNO', 'GDS_NM', 'ACC_CD', 'CRT_CD', 'PRICE', 'QTY', 'SPLY_AMT', 'AMOUNT', 'BANK_CD', 'BBK_NO', 'IN_PERSON', 'OUT_REMARK', 'OUT_AMOUNT', 'ISSUE_DATE', 'SUMUP']

        downloadWorkbook('전자회계결제.xls', 
        await dataToWorkbook('전자회계결제', {data: row, fields}, { 
            PRO_TYPE: '유형',
            IO_TYPE: '구분',
            WORK_NM: '대상',
            CST_NM: '거래처',
            WORK_BIZNO: '사업자등록번호',
            GDS_NM: '상품',
            ACC_CD: '계정과목',
            CRT_CD: '신용카드',
            PRICE: '단가',
            QTY: '수량',
            SPLY_AMT: '공급가액',
            AMOUNT: '합계금액',
            BANK_CD: '입금 은행',
            BBK_NO: '계좌번호',
            IN_PERSON: '입금인',
            OUT_REMARK: '출금통장표시',
            OUT_AMOUNT: '출금액',
            ISSUE_DATE: '발행일',
            SUMUP: '적요',
        }));
    }


    return (
        <Fragment>
            <Dialog
                open={true}
                PaperProps={{ className: classes.dialogPaper }}
                style={{ display: open != null ? 'block' : 'none' }}
            >
                <div className={classes.dialogTitle}>
                    <DialogTitle>전표등록</DialogTitle>
                    <IconButton
                        tooltip='닫기'
                        onClick={onClose}
                        icon={<CloseIcon />}
                    />
                </div>
                <div className={classes.fieldContainer}>
                    <div className={classes.containerRowFlex}>
                        <TextField
                            style={{ flex: 1 }}
                            label='제목'
                            value={insData['DOC_TITLE']}
                            onChange={value => setInsData('DOC_TITLE', value)}
                        />
                        <IconButton
                            tooltip='거래처 등록'
                            icon={<NoteAddIcon />}
                            onClick={() => setNewCstOpen(true)}
                        />
                        <IconButton
                            tooltip='엑셀 다운로드'
                            icon={<GridOnIcon />}
                            onClick={() => excelDown(docProData.data)}
                        />
                    </div>
                    <div className={classes.containerRowFlex}>
                        <IconButton
                            tooltip='확인자'
                            onClick={() => setLineOpenType(lineType.CONFIRM)}
                            icon={<GroupAddIcon />}
                        />
                        <TextField
                            className={classes.empFields}
                            label='확인자'
                            value={getDocLine(insData['CON_JSON'])}
                            onClick={() => setLineOpenType(lineType.CONFIRM)}
                            disabled
                        />
                        {insData?.CON_JSON &&
                        <IconButton
                            className={classes.cancelBtn}
                            tooltip='취소'
                            onClick={() => onCancel('CON_JSON')}
                            icon={<CloseIcon />}
                        />}
                        <IconButton
                            tooltip='처리자'
                            onClick={() => setLineOpenType(lineType.PROCESS)}
                            icon={<GroupAddIcon />}
                        />
                        <TextField
                            className={classes.empFields}
                            label='처리자'
                            value={getDocLine(insData['ACT_JSON'])}
                            onClick={() => setLineOpenType(lineType.PROCESS)}
                            disabled
                        />
                        {insData?.ACT_JSON &&
                        <IconButton
                            className={classes.cancelBtn}
                            tooltip='취소'
                            onClick={() => onCancel('ACT_JSON')}
                            icon={<CloseIcon />}
                        />}
                        <IconButton
                            tooltip='참조자'
                            onClick={() => setLineOpenType(lineType.REF)}
                            icon={<GroupAddIcon />}
                        />
                        <TextField
                            className={classes.empFields}
                            label='참조자'
                            value={getDocLine(insData['REF_JSON'])}
                            onClick={() => setLineOpenType(lineType.REF)}
                            disabled
                        />
                        {insData?.REF_JSON &&
                        <IconButton
                            className={classes.cancelBtn}   
                            tooltip='취소'
                            onClick={() => onCancel('REF_JSON')}
                            icon={<CloseIcon />}
                        />}
                    </div>
                </div>
                <EditableList
                    dataSet={docProData}
                    headerLess
                    alwaysAddable
                    defaultValue={{
                        PRO_TYPE: sortedProType[0]['KEY'],
                        IO_TYPE: Object.keys(IOType)[0],
                        WORK_CD: workInfo[0]['WORK_CD'], 
                        ACC_CD: '0001', // 현금
                        CST_CD: null,
                        CRT_CD: null,
                        BANK_CD: null,
                        PRICE: 0,
                        QTY: 0,
                        SPLY_AMT: 0,
                        AMOUNT: 0,
                        OUT_AMOUNT: 0,
                    }}
                    onAdd={(newRow, data, idx) => {
                        newRow.SEQ = data.length + 1;
                        if(idx >= 0 && String(data[idx]['PRO_TYPE']) === '10') {
                            onCopy(data[idx], newRow.SEQ);
                        }
                        return newRow;
                    }}
                    onRemove={(row, data) => {
                        onRemove(row);
                    }}
                    bottomSticky={(data) => {
                        return (
                            <div style={{ display: 'flex' }}>
                                <DialogTitle style={{ padding: 8 }}>첨부파일</DialogTitle>
                                <div style={{ display: 'flex', overflowX: 'auto' }}>
                                    {files.map((item, i) => (
                                        <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }} key={i}>
                                            {item.name}
                                            <IconButton
                                                tooltip='삭제'
                                                icon={<CloseIcon />}
                                                onClick={() => {
                                                    files.splice(files.indexOf(item), 1);
                                                    setFiles([...files]);
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>
                                <FilePicker
                                    multiple
                                    onChange={newFiles => {
                                        setFiles([...files, ...newFiles]);
                                    }}
                                />
                            </div>
                        )
                    }}
                    onUpdate={async (data) => {
                        const result = await onSave(data);
                        if(result) {
                            onClose();
                            refreshGrid();
                        }
                        return false;
                    }}
                >
                    <EditableField
                        fieldName='PRO_TYPE'
                        component={({ value, setState, rowData, dispatch }) => (
                            <ComboBox
                                style={{ width: 120 }}
                                label='처리유형'
                                data={sortedProType}
                                keyField='KEY'
                                valueField='VALUE'
                                value={value}
                                onChange={value => {
                                    setState(value);
                                    const preKeyValue = { PRO_TYPE: value };
                                    if(isDisabled(rowData, preKeyValue, 'IO_TYPE')) {
                                        dispatch('IO_TYPE', null);
                                    }

                                    if(isDisabled(rowData, preKeyValue, 'CST_CD')){
                                        dispatch('CST_CD', null);    
                                    }

                                    if(isDisabled(rowData, preKeyValue, 'GDS_NM')){
                                        dispatch('GDS_NM', '');
                                    }

                                    if(isDisabled(rowData, preKeyValue, 'CRT_CD')){
                                        dispatch('CRT_CD', null);
                                    }

                                    if(isDisabled(rowData, preKeyValue, 'PRICE')){
                                        dispatch('PRICE', 0);
                                    }

                                    if(isDisabled(rowData, preKeyValue, 'QTY')){
                                        dispatch('QTY', 0);
                                    }

                                    if(isDisabled(rowData, preKeyValue, 'SPLY_AMT')){
                                        dispatch('SPLY_AMT', 0);
                                    }

                                    if(isDisabled(rowData, preKeyValue, 'BANK_CD')){
                                        dispatch('BANK_CD', null);
                                    }

                                    if(isDisabled(rowData, preKeyValue, 'BBK_NO')){
                                        dispatch('BBK_NO', '');
                                    }

                                    if(isDisabled(rowData, preKeyValue, 'IN_PERSON')){
                                        dispatch('IN_PERSON', '');
                                    }   

                                    if(isDisabled(rowData, preKeyValue, 'OUT_REMARK')){
                                        dispatch('OUT_REMARK', '');
                                    }

                                    if(isDisabled(rowData, preKeyValue, 'OUT_AMOUNT')){
                                        dispatch('OUT_AMOUNT', 0);
                                    }

                                    if(isDisabled(rowData, preKeyValue, 'ISSUE_DATE')){
                                        dispatch('ISSUE_DATE', null);
                                    }

                                    if(isDisabled(rowData, preKeyValue, 'SUMUP')){
                                        dispatch('SUMUP', '');
                                    }
                                }}
                            />
                        )}
                    />
                    <EditableField
                        fieldName='IO_TYPE'
                        component={({ value, setState, rowData, dispatch }) => (
                            <ComboBox
                                style={{ width: 60 }}
                                label='구분'
                                data={IOType}
                                value={value}
                                onChange={value => {
                                    setState(value);
                                    const preKeyValue = { IO_TYPE: value };
                                    if(isDisabled(rowData, preKeyValue, 'CST_CD')){
                                        dispatch('CST_CD', null);    
                                    }

                                    if(isDisabled(rowData, preKeyValue, 'GDS_NM')){
                                        dispatch('GDS_NM', '');
                                    }

                                    if(isDisabled(rowData, preKeyValue, 'CRT_CD')){
                                        dispatch('CRT_CD', null);
                                    }

                                    if(isDisabled(rowData, preKeyValue, 'PRICE')){
                                        dispatch('PRICE', 0);
                                    }

                                    if(isDisabled(rowData, preKeyValue, 'QTY')){
                                        dispatch('QTY', 0);
                                    }

                                    if(isDisabled(rowData, preKeyValue, 'SPLY_AMT')){
                                        dispatch('SPLY_AMT', 0);
                                    }

                                    if(isDisabled(rowData, preKeyValue, 'BANK_CD')){
                                        dispatch('BANK_CD', null);
                                    }

                                    if(isDisabled(rowData, preKeyValue, 'BBK_NO')){
                                        dispatch('BBK_NO', '');
                                    }

                                    if(isDisabled(rowData, preKeyValue, 'IN_PERSON')){
                                        dispatch('IN_PERSON', '');
                                    }   

                                    if(isDisabled(rowData, preKeyValue, 'OUT_REMARK')){
                                        dispatch('OUT_REMARK', '');
                                    }

                                    if(isDisabled(rowData, preKeyValue, 'OUT_AMOUNT')){
                                        dispatch('OUT_AMOUNT', 0);
                                    }

                                    if(isDisabled(rowData, preKeyValue, 'ISSUE_DATE')){
                                        dispatch('ISSUE_DATE', null);
                                    }

                                    if(isDisabled(rowData, preKeyValue, 'SUMUP')){
                                        dispatch('SUMUP', '');
                                    }
                                }}
                                nullable
                                disabled={isDisabled(rowData, null, 'IO_TYPE')}
                            />
                        )}
                    />
                    <EditableField
                        fieldName='WORK_CD'
                        component={({ value, setState }) => (
                            <ComboSearch
                                style={{ width: 180 }}
                                label='대상'
                                data={workInfo}
                                keyField='WORK_CD'
                                valueField='WORK_NM'
                                value={value}
                                onChange={value => setState(value)}
                            />
                        )}
                    />
                    <EditableField
                        fieldName='CST_CD'
                        component={({ value, setState, rowData, dispatch }) => (
                            <ComboSearch
                                style={{ width: 190 }}
                                label='거래처'
                                data={cstInfo[rowData['WORK_CD']]}
                                keyField='CST_CD'
                                valueField='CST_NM'
                                value={value}
                                onChange={value => {
                                    setState(value);
                                    if(value && !isDisabled(rowData, null, 'BANK_CD')) {
                                        dispatch('BANK_CD', cstBankInfo[rowData['WORK_CD']][value]['BANK_CD'])
                                    }

                                    if(value && !isDisabled(rowData, null, 'BBK_NO')) {
                                        dispatch('BBK_NO', cstBankInfo[rowData['WORK_CD']][value]['BBK_NO'])
                                    }

                                    if(value && !isDisabled(rowData, null, 'IN_PERSON')) {
                                        dispatch('IN_PERSON', cstBankInfo[rowData['WORK_CD']][value]['IN_PERSON'])
                                    }
                                }}
                                nullable
                                disabled={isDisabled(rowData, null, 'CST_CD')}
                            />
                        )}
                    />
                    <EditableField
                        fieldName='GDS_NM'
                        component={({ value, setState, rowData }) => (
                            <TextField
                                style={{ width: 130 }}
                                label='상품'
                                value={value}
                                onChange={value => setState(value)}
                                disabled={isDisabled(rowData, null, 'GDS_NM')}
                            />
                        )}      
                    />
                    <EditableField
                        fieldName='ACC_CD'
                        component={({ value, setState }) => (
                            <ComboSearch
                                style={{ width: 230 }}
                                label='계정과목'
                                data={accInfo}
                                keyField='ACC_CD'
                                valueField='ACC_NM'
                                value={value}
                                onChange={value => setState(value)}
                                nullable
                            />
                        )}
                    />
                    <EditableField
                        fieldName='CRT_CD'
                        component={({ value, setState, rowData }) => (
                            <ComboSearch
                                style={{ width: 190 }}
                                label='신용카드'
                                data={cstInfo[rowData['WORK_CD']]}
                                keyField='CST_CD'
                                valueField='CST_NM'
                                value={value}
                                onChange={value => setState(value)}
                                nullable
                                disabled={isDisabled(rowData, null, 'CRT_CD')}
                            />
                        )}
                    />
                    <EditableField
                        component={({value, setState, rowData}) => (
                            <IconButton
                                style={{ marginLeft: 16 }}
                                tooltip='분개 등록'
                                icon={<ListAltIcon />}
                                onClick={() => {
                                    onDialogOpen(rowData);
                                }}
                                disabled={isDisabled(rowData, null, 'ACC_ICON')}
                            />
                        )}
                        position='PRICE'
                    />
                    <EditableField
                        fieldName='PRICE'
                        defaultValue={0}
                        component={({ value, setState, rowData }) => (
                            <NumberField
                                style={{ width: 75 }}
                                label='단가'
                                value={value}
                                onChange={value => setState(value)}
                                disabled={isDisabled(rowData, null, 'PRICE')}
                            />
                        )}
                    />
                    <EditableField
                        fieldName='QTY'
                        defaultValue={0}
                        component={({ value, setState, rowData }) => (
                            <NumberField
                                style={{ width: 70 }}
                                label='수량'
                                value={value}
                                onChange={value => setState(value)}
                                disabled={isDisabled(rowData, null, 'QTY')}
                            />
                        )}
                    />
                    <EditableField
                        fieldName='SPLY_AMT'
                        defaultValue={0}
                        component={({ value, setState, rowData, dispatch }) => (
                            <NumberField
                                style={{ width: 100 }}
                                label='공급가액'
                                value={value}
                                onChange={value => setState(value)}
                                onBlur={() => {
                                    if(!isDisabled(rowData, null, 'OUT_AMOUNT')) {
                                        if(String(rowData['PRO_TYPE']) !== '4' && String(rowData['PRO_TYPE']) !== '7' && String(rowData['PRO_TYPE']) !== '11') {
                                            dispatch('OUT_AMOUNT', Math.floor((value * 1.1).toFixed(2)));
                                        } else {
                                            dispatch('OUT_AMOUNT', value);
                                        }
                                    }

                                    if(String(rowData['PRO_TYPE']) !== '4' && String(rowData['PRO_TYPE']) !== '7' && String(rowData['PRO_TYPE']) !== '11') {
                                        dispatch('AMOUNT', Math.floor((value * 1.1).toFixed(2)));
                                    } else {
                                        dispatch('AMOUNT', value);
                                    }
                                }}
                                disabled={isDisabled(rowData, null, 'SPLY_AMT')}
                            />
                        )}
                    />
                    <EditableField
                        fieldName='AMOUNT'
                        defaultValue={0}
                        component={({ value, setState, rowData, dispatch }) => (
                            <NumberField
                                style={{ width: 100 }}
                                label='합계금액'
                                value={value}
                                onChange={value => setState(value)}
                                onBlur={() => {
                                    if(!isDisabled(rowData, null, 'OUT_AMOUNT')) {
                                        dispatch('OUT_AMOUNT', value);
                                    }
                                    if(!isDisabled(rowData, null, 'SPLY_AMT') && String(rowData['SPLY_AMT']) === '0') {
                                        dispatch('SPLY_AMT', value);
                                    }
                                }}
                            />
                        )}
                    />
                    <EditableField
                        fieldName='BANK_CD'
                        component={({ value, setState, rowData }) => (
                            <ComboSearch
                                style={{ width: 120 }}
                                label='은행'
                                data={bankInfo}
                                keyField='BANK_CD'
                                valueField='BANK_NM'
                                value={value}
                                onChange={value => setState(value)}
                                nullable
                                disabled={isDisabled(rowData, null, 'BANK_CD')}
                            />
                        )}
                    />
                    <EditableField
                        fieldName='BBK_NO'
                        component={({ value, setState, rowData }) => (
                            <TextField
                                style={{ width: 120 }}
                                label='계좌번호'
                                value={value}
                                onChange={value => {
                                    setState(value);
                                }}
                                disabled={isDisabled(rowData, null, 'BBK_NO')}
                            />
                        )}      
                    />
                    <EditableField
                        fieldName='IN_PERSON'
                        component={({ value, setState, rowData }) => (
                            <TextField
                                style={{ width: 110 }}
                                label='입금인'
                                value={value}
                                onChange={value => setState(value)}
                                disabled={isDisabled(rowData, null, 'IN_PERSON')}
                            />
                        )}      
                    />
                    <EditableField
                        fieldName='OUT_REMARK'
                        component={({ value, setState, rowData }) => (
                            <TextField
                                style={{ width: 120 }}
                                label='출금통장표시'
                                value={value}
                                onChange={value => setState(value)}
                                disabled={isDisabled(rowData, null, 'OUT_REMARK')}
                            />
                        )}      
                    />
                    <EditableField
                        fieldName='OUT_AMOUNT'
                        defaultValue={0}
                        component={({ value, setState, rowData }) => (
                            <NumberField
                                style={{ width: 90 }}
                                label='출금액'
                                value={value}
                                onChange={value => setState(value)}
                                disabled={isDisabled(rowData, null, 'OUT_AMOUNT')}
                            />
                        )}
                    />
                    <EditableField
                        fieldName='ISSUE_DATE'
                        component={({ value, setState, rowData }) => (
                            <DatePicker
                                label='발행(생)일'
                                value={value}
                                onChange={value => setState(value)}
                                disabled={isDisabled(rowData, null, 'ISSUE_DATE')}
                            />
                        )}
                    />
                    <EditableField
                        fieldName='SUMUP'
                        component={({ value, setState, rowData }) => (
                            <TextField
                                style={{ width: 135 }}
                                label='적요'
                                value={value}
                                onChange={value => setState(value)}
                                disabled={isDisabled(rowData, null, 'SUMUP')}
                            />
                        )}      
                    />
                    <EditableField fieldName='SEQ' visible={false}/>
                    <EditableField fieldName='WORK_NM' visible={false} />
                    <EditableField fieldName='CST_NM' visible={false} />
                    <EditableField fieldName='WORK_BIZNO' visible={false} />
                </EditableList>
                <EmpListDialog 
                    open={lineOpenType != null}
                    onClose={() => setLineOpenType(null)}
                    onConfirm={(value) => {
                        switch (lineOpenType) {
                            default:
                            case lineType.CONFIRM:
                                setInsData('CON_JSON', value);
                                break;
                            case lineType.PROCESS:
                                setInsData('ACT_JSON', value);
                                break;
                            case lineType.REF:
                                setInsData('REF_JSON', value)
                                break;
                        }
                        setLineOpenType(null);
                    }}
                />
            </Dialog>
            {accOpen &&
            <AccDialog
                open={accOpen}
                onClose={() => setAccOpen(false)}
                selectAccData={selectAccData}
                selectWorkCd={selectWorkCd}
                onAccSave={(data, proSeq) => onAccSave(data, proSeq)}
                accInfo={accInfo}
                cstInfo={cstInfo}
            />}
        </Fragment>
    )
}

const accDialogStyle = makeStyles((theme) => ({
    dialogPaper: {
        maxWidth: 'none',
        width: 1200,
        height: '60%',
        '& > *': {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    fieldContainer: {
        overflowY: 'auto',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
            marginBottom: theme.spacing(2),
        },
    },
    containerRowFlex: {
        display: 'flex',
        flexDirection: 'row',
    },
}))

const AccDialog = ({ open, onClose, selectAccData, selectWorkCd, onAccSave, accInfo, cstInfo }) => {
    const classes = accDialogStyle();
    const isSumEqual = useRef();
    const { enqueueSnackbar } = useSnackbar();

    const isValid = (data) => {
        if(data.reduce((result, item) => (result === true || item['ACC_CD'] == null) ? true : false , false)) {
            enqueueSnackbar('계정과목이 없는 행이 있습니다. 확인해주세요.', {variant: 'error'});
            return false;
        }

        if(data.length === 0){
            enqueueSnackbar('생성된 행이 없습니다. 확인해주세요.', {variant: 'error'});
            return false;
        }

        if(!isSumEqual.current){
            enqueueSnackbar('차변 / 대변 합이 다릅니다. 확인해주세요.', {variant: 'error'});
            return false;
        }
        
        return true;
    }

    const isDisabled = (rowData) => {
        return String(rowData['SEQ']) === '1' || String(rowData['SEQ']) === '2';
    }

    return (
        <Dialog
            open
            PaperProps={{ className: classes.dialogPaper }}
            style={{ display: open ? 'block' : 'none' }}
        >
            <div className={classes.dialogTitle}>
                <DialogTitle>분개등록</DialogTitle>
                <IconButton
                    tooltip='닫기'
                    onClick={onClose}
                    icon={<CloseIcon />}
                />
            </div>
            <EditableList
                dataSet={selectAccData}
                headerLess
                alwaysAddable
                defaultValue={{
                    STATE_TYPE: Object.keys(stateType)[0],
                    WORK_CD: selectWorkCd,
                    CST_CD: null,
                    SUMUP: null,
                    DEBTOR: 0,
                    CREDITOR: 0,
                }}
                onAdd={(newRow, data) => {
                    newRow.SEQ = data.length + 1;
                    return newRow;
                }}
                onUpdate={(data) => {
                    if(isValid(data)) {
                        const proSeq = data[0]['PRO_SEQ'];
                        onAccSave(data, proSeq);
                        onClose();
                    } else
                        return false;
                }}
                bottomSticky={(data) => {
                    const debtor = data.reduce((result, item) => result + parseInt(item['DEBTOR'] || 0), 0);
                    const creditor = data.reduce((result, item) => result + parseInt(item['CREDITOR'] || 0), 0);
                    isSumEqual.current = debtor === creditor;

                    return (
                        <div>
                            <NumberField
                                label='차변 합'
                                value={debtor}
                                disabled
                            />
                            <NumberField
                                style={{marginLeft: 8}}
                                label='대변 합'
                                value={creditor}
                                disabled
                            />
                        </div>
                    )
                }}
            >
                <EditableField
                    fieldName='STATE_TYPE'
                    component={({value, setState, rowData}) => (
                        <ComboBox
                            style={{ width: 80 }}
                            label='전표구분'
                            data={stateType}
                            value={value}
                            onChange={value => setState(value)}
                            disabled={isDisabled(rowData)}
                        />
                    )}
                />
                <EditableField
                    fieldName='ACC_CD'
                    component={({ value, setState, rowData }) => (
                        <ComboSearch
                            style={{ width: 230 }}
                            label='계정과목'
                            data={accInfo}
                            keyField='ACC_CD'
                            valueField='ACC_NM'
                            value={value}
                            onChange={value => setState(value)}
                            nullable
                            disabled={isDisabled(rowData)}
                        />
                    )}
                />
                <EditableField
                    fieldName='CST_CD'
                    component={({ value, setState, rowData, dispatch }) => (
                        <ComboSearch
                            style={{ width: 190 }}
                            label='거래처'
                            data={cstInfo[rowData['WORK_CD']]}
                            keyField='CST_CD'
                            valueField='CST_NM'
                            value={value}
                            onChange={value => {
                                setState(value);
                            }}
                            nullable
                        />
                    )}
                />
                <EditableField
                    fieldName='DEBTOR'
                    defaultValue={0}
                    component={({ value, setState, rowData }) => (
                        <NumberField
                            style={{ width: 100 }}
                            label='차변'
                            value={value}
                            onChange={value => setState(value)}
                            disabled={isDisabled(rowData)}
                        />
                    )}
                />
                <EditableField
                    fieldName='CREDITOR'
                    defaultValue={0}
                    component={({ value, setState, rowData }) => (
                        <NumberField
                            style={{ width: 100 }}
                            label='대변'
                            value={value}
                            onChange={value => setState(value)}
                            disabled={String(rowData['SEQ']) === '1'}
                        />
                    )}
                />
                <EditableField
                    fieldName='SUMUP'
                    component={({ value, setState }) => (
                        <TextField
                            style={{ width: 150 }}
                            label='적요'
                            value={value}
                            onChange={value => setState(value)}
                        />
                    )}      
                />
                <EditableField fieldName='PRO_SEQ' visible={false} />
                <EditableField fieldName='SEQ' visible={false} />
                <EditableField fieldName='WORK_CD' visible={false} />
            </EditableList>
        </Dialog>
    )
};

const empDialogStyle = makeStyles((theme) => ({
    dialogPaper: {
        width: 400,
        '& > *': {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    grid: {
        height: 400,
    },
    btnContainer: {
        alignSelf: 'flex-end',
        padding: theme.spacing(1),
    },
}))

const EmpListDialog = ({ open, onClose, onConfirm }) => {
    const classes = empDialogStyle();
    const [selectedRows, setSelectedRows] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    
    const valid = () => {
        if (!selectedRows || selectedRows.length === 0) {
            enqueueSnackbar('사원을 선택하세요', { variant: 'error' });
            return false;
        }

        return true;
    }
    
    const onOk = () => {
        if(valid()) {
            onConfirm(selectedRows);
            setSelectedRows(null);
            onClose();
        }
    }

    

    return (
        <Dialog
            open={open}
            PaperProps={{ className: classes.dialogPaper }}
            onClose={onClose}
        >
            <div className={classes.dialogTitle}>
                <DialogTitle>사원 선택</DialogTitle>
                <IconButton
                    tooltip='닫기'
                    onClick={onClose}
                    icon={<CloseIcon />}
                />
            </div>
            <DataGrid
                className={classes.grid}
                selectProc='SLT_EMPINFO_ALL'
                onSelect={row => setSelectedRows(row)}
                headerLess
                borderLess
                selectionMode='multi'
            >
            </DataGrid>
            <div className={classes.btnContainer}>
                <IconButton
                    tooltip='확인'
                    onClick={() => onOk()}
                    icon={<CheckIcon />}
                />
            </div>
        </Dialog>
    )
}


export default ElecAccounting