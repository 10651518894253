import React, { useState, useEffect, useMemo } from 'react';
import { DataGrid, ComboBox, PaperTitle, DatePicker, DataColumn, NumberField, ComboSearch, IconButton, ProgressDialog, TextField } from '../../component';
import { callProc } from '../../common/DBConnector';
import {  makeStyles, Paper,  } from '@material-ui/core';
import { useFieldInputs } from '../../common/Utils';
import { useSnackbar } from 'notistack';
import { adjType, regType } from '../../common/Dictionary';
import { useSelector } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import CachedIcon from '@material-ui/icons/Cached';

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
    info: {
        flex: 1,
        marginRight: theme.spacing(1),
    },
    input: {
        width: 300,
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(1),
    },
    enroll: {
        flex: 1,
        marginRight: theme.spacing(1),
    },
    paperContent: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        overflowY: 'auto',
        '& > *': {
            marginBottom: theme.spacing(2),
        },
    },
    field: {
        width: 180,
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2),
    },
    iconbutton : {
        alignSelf: 'flex-end',
        marginTop: theme.spacing(4),
        marginRight: theme.spacing(1),
    },
    searchPaper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    searchContainer: {
        flex: 1,
        display: 'inline-block',
        flexDirection: 'row',
    },
    searchItem: {
        display: 'inline-block',
        width: 200,
        marginRight: 100,
        marginBottom: theme.spacing(1),
    },
}))

const GaugeAdjustEnroll = () => {
    const classes = useStyles();
    const clsState = useSelector(state => state.clsDateReducer);
    const [input, setInput] = useFieldInputs({
        WORK_CD: sessionStorage['WORK_CD'],
        BIZ_DATE: clsState[sessionStorage['WORK_CD']],
    });
    const [editRow, setEditRow] = useState();
    const condition = useMemo(() => (input['WORK_CD'] && input['BIZ_DATE']) ? input : null, [input]);
    const { enqueueSnackbar } = useSnackbar();

    const gridRefresh = () => {
        setInput({ type: 'UPDATE', value: { ...input } });
    }

    const valid = (row) => {
        if (String(row['REG_TYPE']) === '1' || String(row['REG_TYPE']) === '2') {
            enqueueSnackbar('POS데이터 및 일반등록 데이터는 삭제가 불가합니다.', { variant: 'error' });
            return false;
        }
        return true;
    }

    const onDelete = async (row) => {
        if (valid(row)) {
            const result = await callProc('DLT_GAUGE_ADJUST_ENROLL', row);

            if (!result.err) {
                setInput({ ...input });
            }
        } else {
            return false;
        }
    }

    const onUpdateStart = row => {
        setEditRow({...row});
        return false;
    }

    return(
        <div className={classes.container}>
            <Paper className={classes.searchPaper}>
                <div className={classes.searchContainer}>
                    <div className={classes.searchItem}>
                        <ComboBox
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={condition && condition['WORK_CD']}
                            onChange={value => {
                                if (input['WORK_CD'] !== value) {
                                    sessionStorage['WORK_CD'] = value;
                                    setInput('BIZ_DATE', clsState[value]);
                                    setInput('WORK_CD', value);
                                }
                            }}
                        />
                    </div>
                    <div className={classes.searchItem}>
                        <DatePicker
                            label='영업일'
                            minDate={clsState[input['WORK_CD']]}
                            value={condition && condition['BIZ_DATE']}
                            onChange={value => {
                                if (input['BIZ_DATE'] !== value) {
                                    setInput('BIZ_DATE', value);
                                    if(condition && condition['BIZ_DATE'] < clsState[condition['WORK_CD']]) {
                                        setInput({ type: 'UPDATE', value: { ...input, 'BIZ_DATE': value } });
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                <IconButton
                    tooltip=''
                    icon={<SearchIcon />}
                    onClick={gridRefresh}
                />
            </Paper>
            <div className={classes.content}>
                <div className={classes.info}>
                    <DataGrid
                        title='계기'
                        selectProc='SLT_GAUGE_ADJUST_ENROLL'
                        selectParam={condition}
                        updateProc=''
                        onDelete={(row) => onDelete(row)}
                        onUpdateStart={onUpdateStart}
                        onLoad={() => setEditRow()}
                        horizonMode
                        virtualized
                        selectionMode='single'
                    >
                        <DataColumn fieldName='REG_TYPE' value={(row, value) => regType[value]} />
                        <DataColumn fieldName='NZL_NO' title='노즐번호' />
                        <DataColumn fieldName='CATE_NM' title='카테고리명' />
                        <DataColumn fieldName='ADJ_TYPE' title='조정/대체' value={(row, value) => adjType[value]}/>
                        <DataColumn fieldName='BEF_GAUGE_ELE' title='시작계기' />
                        <DataColumn fieldName='CUR_GAUGE_ELE' title='마감계기' />
                        <DataColumn fieldName='GAUGE_ELE' title='계기차' />
                        <DataColumn fieldName='OUT_QTY' title='출하량' />

                        <DataColumn fieldName='EMPTY_QTY' title='공회전' />
                        <DataColumn fieldName='MOVE_QTY' title='이관' />
                        <DataColumn fieldName='HOME_QTY' title='홈로리이관' />
                        <DataColumn fieldName='TEST_QTY' title='검량' />
                        <DataColumn fieldName='SELF_QTY' title='자가소비' />
                        <DataColumn fieldName='KEEP_OUT_QTY' title='보관출고' />
                        <DataColumn fieldName='SERVICE_QTY' title='서비스' />
                        <DataColumn fieldName='ETC_QTY' title='기타' />

                        <DataColumn fieldName='GDS_TYPE' visible={false} />
                        <DataColumn fieldName='REMARK' title='조정/대체 사유' />
                        <DataColumn fieldName='WORK_CD' title='사업자코드' visible={false} />
                        <DataColumn fieldName='ADJ_GAUGE_CD' visible={false} />
                    </DataGrid>
                </div>
                <div className={classes.input}>
                    <EnrollPanel
                        condition={condition}
                        editRow={editRow}
                        initEditRow={() => setEditRow()}
                        gridRefresh={gridRefresh}
                        clsState={clsState}
                    />
                </div>
            </div>
        </div>
    )
}

const usePanelStyles = makeStyles((theme) => ({
    panelContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: 0,
    },
    panelContent: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        overflowY: 'auto',
        '& > *': {
            marginBottom: theme.spacing(2),
        },
    },
    btnContainer: {
        alignSelf: 'flex-end',
        padding: theme.spacing(1),
    },
}))

const EnrollPanel = ({editRow, initEditRow, condition, gridRefresh}) => {
    const classes = usePanelStyles();
    const [nzzParam, setNzzParam] = useState();
    const [insData, setInsData] = useFieldInputs({
        WORK_CD: condition && condition['WORK_CD'],
        BIZ_DATE: condition && condition['BIZ_DATE'],        
        ADJ_TYPE: '1',
        NZL_NO: null,        
        REMARK: '',
        BEF_GAUGE_ELE: 0,
        CUR_GAUGE_ELE: 0,
        GAUGE_ELE: 0,
        EMPTY_QTY: 0,
        MOVE_QTY: 0,
        HOME_QTY: 0,
        TEST_QTY: 0,
        SELF_QTY: 0,
        KEEP_OUT_QTY: 0,
        SERVICE_QTY: 0,
        ETC_QTY: 0,
    });
    const { enqueueSnackbar } = useSnackbar();
    const [befValue, setBefValue] = useState();
    const [editState, setEditState] = useState(0); // 0 : 조정 / 1 : 대체 / 2 : 수정
    
    useEffect(() => {
        if (!editRow) {
            setEditState(0);
            setInsData({ type: 'INIT' });
        } else {
            if (editRow['ADJ_TYPE'] === null) {
                setEditState(1);
            } else {
                setEditState(2);
            }
            setInsData({ type: 'UPDATE', value: {...editRow, ADJ_TYPE: editRow['ADJ_TYPE'] === null ? '2' : editRow['ADJ_TYPE']} });
        }
    }, [editRow, setInsData])

    useEffect(() => {
        if (condition) {
            setInsData({ type: 'INIT' });
            setNzzParam({
                WORK_CD: condition && condition['WORK_CD'],
                BIZ_DATE: condition && condition['BIZ_DATE'],
                TNK_NO: null,
            })
            setInsData('WORK_CD', condition['WORK_CD']);
            setInsData('BIZ_DATE', condition['BIZ_DATE']);
        }
    }, [condition, setInsData])

    const valid = () => {
        if(insData['REMARK'] == null || insData['REMARK'] === ''){
            enqueueSnackbar('조정/대체 시 사유를 작성해주세요.', {variant: 'error'});
            return false;
        }
        return true;
    }

    const paperTitle = useMemo(() => {
        let title = '';
        switch (String(editState)) {
            case '0':
                title = '조정'
                break;
            case '1':
                title = '대체'
                break;
            default:
                title = '수정'
                break;
        }

        return `${title}`;
    }, [editState]);

    const [loading, setLoading] = useState(false);

    const onInsert = async() => {
        setLoading(true);
        let procName;
        if (valid()) {
            let befData = {...insData};
            delete befData.ADJ_TYPE;
            delete befData.NZL_NO;
            delete befData.GAUGE_CD;
            setBefValue(befData);
            if(String(editState) === '0' || String(editState) === '1') {
                procName = 'INS_GAUGE_ADJUST_ENROLL'
            } else {
                procName = 'UPT_GAUGE_ADJUST_ENROLL'
            }
            const result = await callProc(procName, insData);

            if (!result.err) {
                enqueueSnackbar('저장이 완료되었습니다.');
                gridRefresh();
            }
        }
        setLoading(false);
    }
   
    const onExitEdit = () => {
        initEditRow();
    }

    const getBefValue = () => {
        befValue && setInsData({ type: 'UPDATE', value: {
            ...befValue,
        } })
    }

    return (
        <Paper className={classes.panelContainer} >
            <ProgressDialog open={loading} />

            <PaperTitle>
                {paperTitle}
            </PaperTitle>
            <div className={classes.panelContent}>
                {(String(editState) === '1' || String(editState) === '2') &&
                <TextField
                    label='계기코드'
                    value={insData['GAUGE_CD']}
                    disabled
                />}                
                <ComboBox
                    className={classes.field}
                    label='조정/대체'
                    data={adjType}
                    value={insData['ADJ_TYPE']}
                    onChange={value => setInsData('ADJ_TYPE', value)}
                    disabled
                />
                <TextField
                    label='조정/대체사유'
                    value={insData['REMARK']}
                    onChange={value => setInsData('REMARK', value)}
                />
                {nzzParam &&
                <ComboSearch
                    label='노즐번호'
                    selectProc='SLT_NOZZLE_COMBO'
                    selectParam={nzzParam}
                    keyField='NZL_NO'
                    valueField='NZL_NO'
                    value={insData['NZL_NO']}
                    onChange={value => {
                    setInsData('NZL_NO', value);
                    }}
                    nullable
                    disabled={String(editState) === '1' || String(editState) === '2'}
                />}
                <NumberField
                    label='시작계기'
                    value={insData && insData['BEF_GAUGE_ELE']}
                    onChange={value => setInsData('BEF_GAUGE_ELE', value)}
                />
                <NumberField
                    label='마감계기'
                    value={insData && insData['CUR_GAUGE_ELE']}
                    onChange={value => {
                        setInsData('GAUGE_ELE', value - insData['BEF_GAUGE_ELE']);
                        setInsData('CUR_GAUGE_ELE', value);
                    }}
                />
                <NumberField
                    label='계기차'
                    value={insData && insData['GAUGE_ELE']}
                    onChange={value => setInsData('GAUGE_ELE', value)}
                    disabled
                />
                <NumberField
                    label='공회전'
                    value={insData && insData['EMPTY_QTY']}
                    onChange={value => {
                        setInsData('EMPTY_QTY', value);
                    }}
                />
                <NumberField
                    label='이관'
                    value={insData && insData['MOVE_QTY']}
                    onChange={value => {
                        setInsData('MOVE_QTY', value);
                    }}
                />
                <NumberField
                    className={classes.field}
                    label='홈로리이관'
                    value={insData && insData['HOME_QTY']}
                    onChange={value => {
                        setInsData('HOME_QTY', value);
                    }}
                />
                <NumberField
                    className={classes.field}
                    label='검량'
                    value={insData && insData['TEST_QTY']}
                    onChange={value => {
                        setInsData('TEST_QTY', value);
                    }}
                />
                <NumberField
                    className={classes.field}
                    label='자가소비'
                    value={insData && insData['SELF_QTY']}
                    onChange={value => {
                        setInsData('SELF_QTY', value);
                    }}
                />
                <NumberField
                    className={classes.field}
                    label='보관출고'
                    value={insData && insData['KEEP_OUT_QTY']}
                    onChange={value => {
                        setInsData('KEEP_OUT_QTY', value);
                    }}
                />
                <NumberField
                    className={classes.field}
                    label='서비스'
                    value={insData && insData['SERVICE_QTY']}
                    onChange={value => {
                        setInsData('SERVICE_QTY', value);
                    }}
                />
                <NumberField
                    className={classes.field}
                    label='기타'
                    value={insData && insData['ETC_QTY']}
                    onChange={value => {
                        setInsData('ETC_QTY', value);
                    }}
                />
            </div>
            <div className={classes.btnContainer}>                
                {(String(editState) === '1' || String(editState) === '2') &&
                <IconButton
                    tooltip='취소'
                    icon={<CloseIcon />}
                    onClick={() => onExitEdit()}
                />}
                <IconButton
                    tooltip='이전값 불러오기'
                    icon={<CachedIcon />}
                    onClick={() => getBefValue()}
                />
                <IconButton
                    tooltip='확인'
                    icon={<CheckIcon />}
                    onClick={() => onInsert()}
                />
            </div>
        </Paper>        
    )
}

export default GaugeAdjustEnroll