import React from 'react';
import { makeStyles, IconButton, Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { PdfViewer } from '.';

const PdfDialog = ({ onClose, open, children, ...props }) => {
    const classes = useStyles();

    return(
        <Dialog 
            PaperProps={{ className: classes.dialog }} 
            onClose={onClose}
            open={open}
        >
            <PdfViewer
                className={classes.pdf}
                header={
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                }
                {...props}
            >
                {children}
            </PdfViewer>
        </Dialog>
    )
}

const useStyles = makeStyles((theme) => ({
    dialog : {
        maxWidth : 'none',
        width : '60%',
        height : '80%',
        overflow: 'hidden',
    },
    pdf : {
        flex : 1
    }
}))

export default PdfDialog;