import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { ComboBox, DatePicker, SearchItem, SearchPanel, DataColumn, DataGrid } from '../../component';
import moment from 'moment';
import {posType} from '../../common/Dictionary'



const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    }
}))

const PosErrorData = () => {
    const classes = useStyles();
    const [condition, setCondition] = useState();

    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => setCondition(condition)}>
                <SearchItem
                    fieldName='WORK_CD'
                    component={(state, setState) => (
                        <ComboBox
                            label='사업장명'
                            selectProc='SLT_WORKPLACEINFO_OIL_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='BIZ_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState) => (
                        <DatePicker
                            label='영업일'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='POS_TYPE'
                    defaultValue={Object.keys(posType)[0]}
                    component={(state, setState) => (
                        <ComboBox
                            label='POS'
                            data={posType}
                            value={state}
                            onChange={(value) => {
                                setState(value);
                            }}
                        />
                    )}
                />
            </SearchPanel>
            <DataGrid
                title='POS 오류데이터 참고'
                selectProc='SLT_POS_ERRDATA'
                selectParam={condition}
                selectionMode='single'
                horizonMode
            >
                <DataColumn title='POS전표번호' fieldName='SALESLIP_NO' />
                <DataColumn title='금액' fieldName='PYMT_AMT' />
            </DataGrid>
        </div>
    )
}

export default PosErrorData