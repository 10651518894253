import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DatePicker, DataColumn, ProgressDialog, IconButton, NumberField, ComboSearch } from '../../component';
import {  Dialog, DialogTitle, makeStyles } from '@material-ui/core';
import IsoIcon from '@material-ui/icons/Iso';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { isFieldDiff, useFieldInputs } from '../../common/Utils';
import { useSnackbar } from 'notistack';
import { callProc } from '../../common/DBConnector';
import { adjType } from '../../common/Dictionary';
import { useMemo } from 'react';


const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
}))

const OrderPriceChange = () => {
    const [condition, setCondition] = useState(null);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    const gridRefresh = () => setCondition({...condition});
    const progressOpen = (value) => setLoading(value);

    return (
        <div className={classes.container}>
            <ProgressDialog open={loading} />
            <SearchPanel onSearch={(condition) => {
                sessionStorage['WORK_CD'] = condition['WORK_CD'];
                setCondition(condition)}}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                        fieldName='CST_CD'
                        component={(state, setState, condition, setField) => {
                            let param = condition['CST_PARAM'] || null;
                            if (isFieldDiff(['WORK_CD'], condition, param)) {
                                param = { WORK_CD: condition['WORK_CD'] };
                                setField('CST_PARAM', param);
                            }
                            return(
                                <ComboSearch
                                    label='거래처명'
                                    selectProc='SLT_CUSTOMERINFO_SALE '
                                    selectParam={param}
                                    keyField='CST_CD'
                                    valueField='CST_NM'
                                    value={state}
                                    onChange={(value) => setState(value)}
                                />
                            )}
                        }
                    />
            </SearchPanel>
            <DataGrid
                title='매출 가격 대체'
                selectProc='SLT_ORDER_SALE'
                selectParam={condition}
                selectionMode='single'
                horizonMode
                virtualized
                stickyHeader
                headerItem={() => (
                    <IconButton
                        tooltip='일괄 등록'
                        icon={<IsoIcon />}
                        onClick={() => setOpen(true)}
                    />
                )}
            >
                <DataColumn fieldName='ADJ_TYPE' value={(row, value) => adjType[value]} />
                <DataColumn fieldName='SHIP_TRN_NM' title='출하처' />
                <DataColumn fieldName='REAL_DEST_NM' title='도착지' />
                <DataColumn fieldName='CST_CD' visible={false} />
                <DataColumn fieldName='GDS_CD' visible={false} />
                <DataColumn fieldName='CHG_PRICE' visible={false} />
                <DataColumn fieldName='CHG_REMARK' visible={false} />
            </DataGrid>
            <EnrollDialog
                open={open}
                close={() => setOpen(false)}
                condition={condition}
                gridRefresh={gridRefresh}
                progressOpen={progressOpen}
            />
        </div>
    )
}

const useDialogStyles = makeStyles(theme => ({
    dialogPaper: {
        maxWidth: 'none',
        width: 300,
        height: 500,
        '& > *': {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    okButton: {
        display: 'flex',
    },
    fieldContainer: {
        overflowY: 'auto',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
            marginBottom: theme.spacing(2),
        },
    },
    btnContainer: {
        alignSelf: 'flex-end',
        padding: theme.spacing(1),
    },
}))

const EnrollDialog = ({ open, close, condition, gridRefresh, progressOpen }) => {
    const [insData, setInsData] = useFieldInputs(condition);
    const { enqueueSnackbar } = useSnackbar();
    const classes = useDialogStyles();

    useEffect(() => {
        setInsData('WORK_CD', condition && condition['WORK_CD']);
        setInsData('CST_CD', condition && condition['CST_CD']);
        setInsData('STA_DATE', condition && condition['STA_DATE']);
        setInsData('END_DATE', condition && condition['END_DATE']);
        setInsData('PRICE', 0);
    }, [condition, setInsData])
    
    const onUpdate = async() => {
        progressOpen(true);

        let result;
        result = await callProc('UPT_ORDER_SALE_RECALC', insData);
        if(!result.err){
            enqueueSnackbar('저장이 완료되었습니다.');
            close();
            gridRefresh();
        }

        progressOpen(false);
    }

    const getFirstDay = value => value && value.substring(0, 8) + '01';

    const getLastDay = value =>
        moment(getFirstDay(value), 'YYYY-MM-DD')
            .add(1, 'month')
            .add(-1, 'day')
            .format('YYYY-MM-DD');

    const workCd = insData && insData['WORK_CD'];   
    const staDate = insData && insData['STA_DATE'];
    const gdsParam = useMemo(() => (
        workCd == null || staDate == null ? undefined : {
        WORK_CD: workCd,
        GDS_TYPE: 'ALL',
        NOT_TYPE: 0,
        GDS_TYPE_M: 'ALL',
        NOT_TYPE_M: 0,
        GAUGE_TYPE: 'ALL',
        STA_DATE: staDate,
    }), [workCd, staDate])

    return (
        <Dialog
            open={open}
            PaperProps={{ className: classes.dialogPaper }}
        >
            <div className={classes.dialogTitle}>
                <DialogTitle>일괄 등록</DialogTitle>
                <IconButton
                    tooltip='닫기'
                    onClick={close}
                    icon={<CloseIcon />}
                />
            </div>
            <div className={classes.fieldContainer}>
                <ComboBox
                    label='사업장명'
                    selectProc='SLT_WORKPLACEINFO_AUTH'
                    keyField='WORK_CD'
                    valueField='WORK_NM'
                    value={insData && insData['WORK_CD']}
                    disabled
                    fullWidth={false}
                />
                <ComboBox
                    label='거래처명'
                    selectProc='SLT_CUSTOMERINFO_SALE'
                    selectParam={condition}
                    keyField='CST_CD'
                    valueField='CST_NM'
                    value={insData && insData['CST_CD']}
                    disabled
                    fullWidth={false}
                />
                <DatePicker
                    label='적용 시작일'
                    value={insData && insData['STA_DATE']}
                    onChange={(value) => setInsData('STA_DATE', value)}
                />
                <DatePicker
                    label='적용 종료일'
                    minDate={insData && getFirstDay(insData['STA_DATE'])}
                    maxDate={insData && getLastDay(insData['STA_DATE'])}
                    value={insData && insData['END_DATE']}
                    onChange={(value) => setInsData('END_DATE', value)}
                />
                <ComboSearch
                    label='상품명'
                    selectProc='SLT_GAUGE_ITEM_GOODS_COMBO'
                    selectParam={gdsParam}
                    keyField='GDS_CD'
                    valueField='GDS_NM'
                    value={insData && insData['GDS_CD']}
                    onChange={value => setInsData('GDS_CD', value)}
                    fullWidth={false}
                />
                <NumberField
                    style={{width: 150}}
                    label='대체 단가'
                    value={insData && insData['PRICE']}
                    onChange={value => setInsData('PRICE', value)}
                />
            </div>
            <div className={classes.btnContainer}>
                    <IconButton
                        tooltip='확인'
                        onClick={onUpdate}
                        icon={<CheckIcon />}
                    />
                </div>
        </Dialog>
    )
}



export default OrderPriceChange;