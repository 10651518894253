import { makeStyles } from "@material-ui/core";
import { ComboBox, DataColumn, DataGrid, NumberField, TextField } from "../../component";
import { YNType, rewdType } from "../../common/Dictionary";
import React from 'react';


const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
}))

const TransCarCommon = () => {
    const classes = useStyles();
    return (
        <div className={classes.container}> 
            <DataGrid
                title='수송처 차량(공통)'
                selectProc='SLT_TRANSCAR_COMM'
                insertProc='INS_TRANSCAR_COMM'
                updateProc='UPT_TRANSCAR_COMM'
                deleteProc='DLT_TRANSCAR_COMM'
                horizonMode
                refreshable
            > 
                <DataColumn
                    fieldName='TSP_CAR_CD'
                    editable={(state, dispatch) => (
                        <TextField
                            style={{width: 150}}
                            value={state}
                            onChange={(value) => dispatch(value)}
                            disabled
                        />
                    )}
                />
                <DataColumn
                    fieldName='CAR_NO'
                    editable={(state, dispatch) => (
                        <TextField
                            style={{width: 100}}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <DataColumn
                    fieldName='TELNO1'
                    editable={(state, dispatch) => (
                        <TextField
                            style={{width: 150}}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <DataColumn
                    fieldName='CAR_OWN'
                    editable={(state, dispatch) => (
                        <TextField
                            style={{width: 100}}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <DataColumn
                    fieldName='STC_QTY'
                    editable={(state, dispatch) => (
                        <NumberField
                            style={{width: 100}}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <DataColumn
                    fieldName='REWD_QTY'
                    editable={(state, dispatch) => (
                        <NumberField
                            style={{width: 100}}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <DataColumn
                    fieldName='IN_TRN_TYPE'
                    title='매입운임요율적용구분'
                    value={(row, value) => YNType[value]}
                    defaultValue={Object.keys(YNType)[1]}
                    editable={(state, dispatch) => (
                        <ComboBox
                            data={YNType}
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                />
                <DataColumn
                    fieldName='REWD_TYPE'
                    title='보상구분'
                    value={(row, value) => rewdType[value]}
                    defaultValue={Object.keys(rewdType)[0]}
                    editable={(state, dispatch) => (
                        <ComboBox
                            data={rewdType}
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                />
                <DataColumn
                    fieldName='REMARK'
                    editable={(state, dispatch) => (
                        <TextField
                            style={{width: 200}}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
            </DataGrid>
        </div>
        
    )
}

export default TransCarCommon;