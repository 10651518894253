import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssessmentIcon from '@material-ui/icons/Assessment';
import SendIcon from '@material-ui/icons/Send';
import AccountBalanceWalletRoundedIcon from '@material-ui/icons/AccountBalanceWalletRounded';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import LockIcon from '@material-ui/icons/Lock';
import ContactsIcon from '@material-ui/icons/Contacts';
import { ItemInbound, InboundAdJust, InboundReCalc, ItemInboundList, InboundTaxList, GoodsNotGoodsInHistory, CstGoodsNotGoodsInHistory, SupplyGoodsHistory, InboundTaxEnroll, InboundSale, KeepStockHistory } from './containers/inboundInfo';
import { SaleEnroll, SaleList, CustomerSale, CardSaleInfo, CreditCheck, TaxInvoiceEnroll, CstTaxInvoiceEnroll, GdsTaxInvoiceEnroll, TaxInvoiceMng, TaxInvoiceInfo, SaleAdjustEnroll, PosErrorData, OilTradeReport, SaleDetail, SaleAdjustEnrollDelDetail, SaleAdjustEnrollDelDetailList, SaleAdjustEnrollCarWash, VenExcleUpload, AgencyTradeReport, AgencySale, SaleEnrollManual, TaxInvoiceList, CustomerSaleHistory } from './containers/saleInfo';
import { RealStockInfo, StockAdjustEnroll, StockAdjustList, InboundCalcEnroll, ItemRealStock, ItemInOutEnroll, ProductTypesStock, InboundCalcNotOrder, OrderInCalc, InboundCalcNotOrderManual } from './containers/stockInfo';
import { OrderEnroll, OrderList, OrderListB, TransList, TransPriceList, TransAdjustEnroll, TransAdjustEnrollDel, OrderBondList, OrderPriceChange } from './containers/orderInfo';
import { EmpInfo, LoginInfo, TransLoginInfo } from './containers/hrinfo';
import { DailyReport, SupplyInfo, OilbankSummary, OilbankSummaryDYCNC, MonthReport, CstBondPayReport, SelfCarWash, SupplyInfoRange, PosOriginList, PosOriginListSaleType, AchieveReport, ProfitLossReport, AgencySummary } from './containers/reportInfo';
import { WorkPlaceInfo, CustomerInfo, CustomerTrdType, CstCarInfo, TransInfo, TransCarInfo, CardInfo, GoodsInfo, PriceInfo, CstPriceInfo, TankInfo, NozzleInfo, BasicDataEnroll, InPriceInfo, BankBook, Account, BasicAccountEnroll, ConfigInfo, ShippingCostChargeTariffBuy, ShippingCostChargeTariffSale, Incustomer, InPriceStandard, InpriceWork, Achieve, CategoryInfo, TransCarCommon } from './containers/generalInfo';
import { SendMoneyEnroll, WorkInMoneyEnroll, BondPayList, BankBookBalance, CautionMoney, CashInOutMoney, CashReport, WorkInMoneyList, SendMoneyList, InMoneyCheck, BankBookBalanceList } from './containers/inoutMoney';
import { AllClose, AllCloseUnlock, CloseList, OilbankClose, OilbankCloseUnlock } from './containers/CloseInfo';
import { GaugeList, GaugeAdjustEnroll, GaugeEnroll, GaugeAdjustEnrollDel } from './containers/gaugeInfo';
import { AccountingList, AccountingStatementEnroll, BusinessReport, CardCommEnroll, InOutAccountingEnroll, InOutAccountingList, InSaleList, MainWorkReport, MoneyMoveEnroll, ValueAddedTax, InOutBondPayList, AgencyMainWorkReport, SaleCostList } from './containers/Accounting';
import { EFMSList, ElecAccounting, ElecDocument, ElecLineEdit, ElectAccounting, ElectAccountingInOut, ElectDocument, ElectDocumentOrderCst } from './containers/ElectApproval';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import { SendPosList } from './containers/Pos';
import OutItemlist from './containers/stockInfo/OutItemlist';

// const defaultMenu = {
//     // SC ver
//     0: {
        
//     },
//     // 공통 ver
//     1: {

//     }
// }

const menuOverride = {
    // 삼보
    0: { 
        //기초정보
        H16: {
            visible: false
        },
        H07: {
            visible: false
        },
        H20: {
            visible: false
        },
        H17: {
            visible: false
        },
        H28: {
            visible: false
        },
        H18: {
            visible: false
        },
        //매입
        A10: {
            visible: false
        },
        //매출
        B26: {
            visible: false
        },
        B22: {
            visible: false
        },
        D11: {
            visible: false
        },
        B25: {
            visible: false
        },
        B14: {
            visible: false
        },
        B27: {
            visible: false
        },
        B24: {
            visible: false
        },
        B19: {
            visible: false
        },
        B23: {
            visible: false
        },
        //재고
        C10: {
            visible: false
        },
        C06: {
            visible: false
        },
        C17: {
            visible: false
        },
        D04: {
            visible: false
        },
        C11: {
            visible: false
        },
        C05: {
            visible: false
        },
        //오더
        D00: {
            visible: false
        },
        //입금
        E03: {
            visible: false
        },
        E12: {
            visible: false
        },
        E07: {
            visible: false
        },
        E08: {
            visible: false
        },
        E14: {
            visible: false
        },
        E09: {
            visible: false
        },
        E10: {
            visible: false
        },
        //인사
        F03: {
            visible: false
        },
        //보고서
        G05: {
            visible: false
        },
        G12: {
            visible: false
        },
        G10: {
            visible: false
        },
        G16: {
            visible: false
        },
        G13: {
            visible: false
        },
        G17: {
            visible: false
        },
        G19: {
            visible: false
        },
        //회계
        J00: {
            visible: false
        },
        //전자결재
        M00: {
            visible: false
        },
    },
    // SC, 시큐, 플라트
    1: {
        //기초정보
        H23: {
            visible: false
        },
        H24: {
            visible: false
        },
        H25: {
            visible: false
        },
        // H28: {
        //     visible: false
        // },
        //매입
        //매출
        B26: {
            visible: false
        },
        B04: {
            visible: false
        },
        B12: {
            visible: false
        },
        B23: {
            visible: false
        },
        //재고
        C16: {
            visible: false
        },
        C17: {
            visible: false
        },
        D04: {
            visible: false
        },
        C05: {
            visible: false
        },
        //대리점
        D13: {
            visible: false
        },
        //입출금
        E13: {
            visible: false
        },
        //보고서
        G03: {
            visible: false
        },
        G04: {
            visible: false
        },
        G12: {
            visible: false
        },
        G16: {
            visible: false
        },
        G07: {
            visible: false
        },
        G08: {
            visible: false
        },
        G15: {
            visible: false
        },
        M05: {
            visible: false
        },
        G17: { 
            visible: false
        },
        G19: {
            visible: false
        }
    },
    // 대양CNC, 대양산업
    2: {
        //기초정보
        H23: {
            visible: false
        },
        H24: {
            visible: false
        },
        H25: {
            visible: false
        },
        H07: {
            visible: false
        },
        H20: {
            visible: false
        },
        H17: {
            visible: false
        },
        H18: {
            visible: false
        },
        // 매입
        A02: {
            visible: false
        },
        A07: {
            visible: false
        },
        A08: {
            visible: false
        },
        A10: {
            visible: false
        },
        //매출
        B22: {
            visible: false
        },
        D11: {
            visible: false
        },
        B25: {
            visible: false
        },
        B04: {
            visible: false
        },
        B12: {
            visible: false
        },
        B14: {
            visible: false
        },
        B27: {
            visible: false
        },
        B24: {
            visible: false
        },
        B19: {
            visible: false
        },
        B23: {
            visible: false
        },
        C10: {
            visible: false
        },
        C16: {
            visible: false
        },
        C06: {
            visible: false
        },
        C15: {
            visible: false
        },
        C11: {
            visible: false
        },
        C05: {
            visible: false
        },
        //오더
        D01: {
            visible: false
        },
        D10: {
            visible: false
        },
        D07: {
            visible: false
        },
        D06: {
            visible: false
        },
        //입출금
        E07: {
            visible: false
        },
        E14: {
            visible: false
        },
        E09: {
            visible: false
        },
        E10: {
            visible: false
        },
        E13: {
            visible: false
        },
        //인사
        F03: {
            visible: false
        },
        //보고서
        G03: {
            visible: false
        },
        G04: {
            visible: false
        },
        G05: {
            visible: false
        },
        G07: {
            visible: false
        },
        G08: {
            visible: false
        },
        G15: {
            visible: false
        },
        //회계
        J03: {
            visible: false
        },
        //전자결재
        M00: {
            visible: false
        },
    },
}

export { menuOverride };

export default {
    H00: {
        text: '기초정보',
        icon: AssignmentIcon,
        children: {
            H01: {
                text: '사업장',
                to: WorkPlaceInfo
            },
            H26: {
                text: '거래처',
                children: {
                    H02: {
                        text: '거래처 기초정보',
                        to: CustomerInfo
                    },
                    H27: {
                        text: '거래처 거래조건',
                        to: CustomerTrdType
                    },
                },
            },
            H03: {
                text: '거래처 차량',
                to: CstCarInfo
            },
            H08: {
                text: '상품',
                to: GoodsInfo
            },
            H29: {
                text: '카테고리',
                to: CategoryInfo
            },
            H11: {
                text: '탱크',
                to: TankInfo
            },
            H12: {
                text: '노즐',
                to: NozzleInfo
            },
            H06: {
                text: '세부결제수단',
                to: CardInfo
            },
            H16: {
                text: '계좌',
                to: BankBook
            },
            H15: {
                text: '단가',
                children: {           
                    H23: {
                        text: '기준매입처',
                        to: Incustomer
                    },
                    H24: {
                        text: '기준매입처 단가 ',
                        to: InPriceStandard
                    },
                    H25: {
                        text: '사업장별 매입단가 ',
                        to: InpriceWork
                    },
                    H14: {
                        text: '매입단가',
                        to: InPriceInfo
                    },
                    H09: {
                        text: '매출단가',
                        to: PriceInfo
                    },
                    H10: {
                        text: '거래처 매출 단가',
                        to: CstPriceInfo
                    },
                },
            },
            H07: {
                text: '수송',
                children: {
                    H04: {
                        text: '수송처',
                        to: TransInfo
                    },
                    H30: {
                        text: '수송처 차량(공통)',
                        to: TransCarCommon
                    },
                    H05: {
                        text: '수송처 차량',
                        to: TransCarInfo
                    },
                },
            },
            H20: {
                text: '운임요율',
                children: {
                    H22: {
                        text: '매입 운임요율',
                        to: ShippingCostChargeTariffBuy
                    },
                    H21: {
                        text: '매출 운임요율',
                        to: ShippingCostChargeTariffSale
                    },                   
                },
            },
            H17: {
                text: '계정과목',
                to: Account
            },
            H28: {
                text: '목표량',
                to: Achieve
            },
            H13: {
                text: '기초데이터',
                to: BasicDataEnroll
            },
            H18: {
                text: '기초데이터(계정과목)',
                to: BasicAccountEnroll
            },
            H19: {
                text: '시스템 설정',
                to: ConfigInfo
            },            
        }
    },
    A00: {
        text: '매입',
        icon: ShoppingCartIcon,
        children: {
            A01: {
                text: '일반상품 매입등록', // 공통
                to: ItemInbound
            },
            A12: {
                text: '일반상품 매입/매출 등록', // 공통
                to: InboundSale
            },
            A03: {
                text: '매입 조회', // 공통
                to: ItemInboundList
            },
            C09 : {
                text: '매입 조정', // 공통
                to: InboundAdJust
            },
            A02: {
                text: '매입가격 재계산', // 공통
                to: InboundReCalc
            },
            A06: {
                text: '보고서',
                children: {
                    A07: {
                        text: '(비)상품 매입원장', // 공통
                        to: GoodsNotGoodsInHistory
                    },
                    A08: {
                        text: '(비)상품 채무현황', // 공통
                        to: CstGoodsNotGoodsInHistory
                    },
                    A09: {
                        text: '상품 수불원장', // 공통
                        to: SupplyGoodsHistory
                    },
                    A13: {
                        text: '매출보관 수불원장', // 공통
                        to: KeepStockHistory
                    },
                }
            },
            A10: {
                text: '세금계산서',
                children: {
                    A11: {
                        text: '매입계산서 수취등록', // 공통
                        to: InboundTaxEnroll
                    },
                    A04: {
                        text: '매입계산서 조회', // 공통
                        to: InboundTaxList
                    }
                }
            }
        }
    },
    B00: {
        text: '매출',
        icon: TrendingUpIcon,
        children: {
            B01: {
                text: '매출 등록', // 공통
                to: SaleEnroll
            },
            B26: {
                text: '매출 상세 등록', // 공통
                to: SaleEnrollManual
            },
            B02: {
                text: '매출 조회', // 공통
                to: SaleList
            },
            B11: {
                text: '매출 조정/대체', // 공통
                to: SaleAdjustEnroll
            },
            B20: {
                text: '매출단수 조정', // 공통
                to: SaleAdjustEnrollDelDetail
            },
            B21: {
                text: '매출단수 조정 조회', // 공통
                to: SaleAdjustEnrollDelDetailList
            },
            B22: {
                text: '일반상품 매출조정', // 에쓰시, 시큐, 플라트
                to: SaleAdjustEnrollCarWash
            },
            B04: {
                text: '결제수단별 조회', // 삼보
                to: CardSaleInfo
            },
            E06:{
                text:'채권',
                children:{
                    E01: {
                        text: '채권 현황',
                        to: BondPayList
                    },
                    G09: {
                        text: '외상거래처 채권',
                        to: CstBondPayReport
                    },
                    B28: {
                        text: '거래처 매출원장',
                        to: CustomerSaleHistory
                    }
                }
            },
            B12: {
                text: 'POS데이터',
                children: {
                    B06: {
                        text: 'POS 신용카드 체크',
                        to: CreditCheck
                    },
                    B13: {
                        text: 'POS 오류데이터 확인',
                        to: PosErrorData
                    },
                }
            },
            B07: {
                text: '세금계산서',
                children: {
                    B08: {
                        text: '거래처 일괄 발행',
                        to: CstTaxInvoiceEnroll
                    },
                    B17: {
                        text: '상품별 발행',
                        to: GdsTaxInvoiceEnroll
                    },
                    B09: {
                        text: '개별 발행',
                        to: TaxInvoiceEnroll
                    },
                    B10: {
                        text: '계산서 관리',
                        to: TaxInvoiceMng
                    },
                    B14: {
                        text: '계산서 조회',
                        to: TaxInvoiceInfo
                    },
                    B27: {
                        text: '계산서 집계 조회',
                        to: TaxInvoiceList
                    },
                    B03: {
                        text: '거래명세 발행',
                        to: CustomerSale
                    },
                }
            },
            B15: {
                text: '주유소 수급보고서',
                to: OilTradeReport
            },
            B19: {
                text: '매출 상세내역 비교 조회',
                to: SaleDetail
            },
            B23: {
                text: 'VEN 엑셀 업로드',
                to: VenExcleUpload
            },
        }
    },
    C00: {
        text: '재고',
        icon: AllInboxIcon,
        children: {
            C01: {
                text: '재고 조정 등록',
                to: StockAdjustEnroll
            },
            C18: {
                text: '재고 조정 조회',
                to: StockAdjustList
            },
            C02: {
                text: '실재고',
                to: RealStockInfo
            },
            C10: {
                text: '일반상품 실재고',
                to: ItemRealStock
            },
            C16: {
                text: '발주 계산기',
                to: OrderInCalc
            },  
            C06: {
                text: '실입고 정산',
                to: InboundCalcEnroll,
            },
            C15: {
                text: '입고 등록',
                to: InboundCalcNotOrder,
            },
            C17: {
                text: '입고 상세 등록',
                to: InboundCalcNotOrderManual,
            },
            // D03: {
            //     text: '입고 등록',
            //     to: TransInEnroll
            // },
            D04: {
                text: '출고 내역 조회',
                to: OutItemlist
            },
            C11: {
                text: '셀프세차장',
                children: {
                    C12: {
                        text: '관리용품 등록',
                        to: ItemInOutEnroll
                    },
                    C13: {
                        text: '회원카드 보증금',
                        to: CautionMoney
                    },
                }
            },
            // C03: {
            //     text: '탱크별 재고',
                
            // },
            C04: {
                text: '상품/탱크 재고',
                to: ProductTypesStock
            },
            C05: {
                text: '탱크 이관 등록',
                to: PosOriginList
            },
        }
    },
    K00: {
        text: '계기',
        icon: AvTimerIcon,
        children: {
            C08 : {
                text: '계기 등록',
                to: GaugeEnroll
            },
            B05: {
                text: '계기 조회',
                to: GaugeList
            },
            // K01: {
            //     text: '계기 집계',
            //     to: GaugeSum
            // },
            C07 : {
                text: '계기 조정/대체',
                to: GaugeAdjustEnroll
            },
            C14 : {
                text: '계기 조정/대체 수정/삭제',
                to: GaugeAdjustEnrollDel
            },
        },
    },
    D00: {
        text: '대리점',
        icon: LocalShippingIcon,
        children: {
            D01: {
                text: '오더 등록',
                to: OrderEnroll
            },
            D06: {
                text: '오더 조회(A)',
                to: OrderList
            },
            D13: {
                text: '오더 조회(B)',
                to: OrderListB
            },
            D10: {
                text: '오더채권 조회',
                to: OrderBondList
            },
            D11: {
                text: '오더매출 대체', // 에쓰시
                to: OrderPriceChange
            },
            B25: {
                text: '거래명세 발행(대리점용)',
                to: AgencySale
            },
            B24: {
                text: '대리점 수급보고서',
                to: AgencyTradeReport
            },
            D07: {
                text: '수송',
                children: {
                    D05: {
                        text: '배차 조회',
                        to: TransList
                    },
                    D08: {
                        text: '수송비 조회',
                        to: TransPriceList
                    },
                    D09: {
                        text: '수송비 조정',
                        to: TransAdjustEnroll
                    },
                    D12: {
                        text: '수송비 조정 수정/삭제',
                        to: TransAdjustEnrollDel
                    },
                },
            },

        }
    },
    E00: {
        text: '입출금',
        icon: AttachMoneyIcon,
        children:{
            E05: {
                text: '사업장 입금 및 송금',
                children:{
                    E02: {
                        text: '사업장 입금 등록',
                        to: WorkInMoneyEnroll
                    },
                    E11: {
                        text: '사업장 입금 조회',
                        to: WorkInMoneyList
                    },
                    E03: {
                        text: '송금 등록',
                        to: SendMoneyEnroll
                    },
                    // E04: {
                    //     text: '기타 입금',
                    //     to: EtcBondMoneyEnroll
                    // },
                    E12: {
                        text: '송금 조회',
                        to: SendMoneyList
                    },

                }
            },
            E07:{
                text: '계좌잔액 등록',
                to: BankBookBalance
            },
            E14:{
                text: '계좌잔액 조회',
                to: BankBookBalanceList
            },
            E08:{
                text: '카드수수료 정산',
                to: CardCommEnroll
            },
            E09:{
                text: '출납부(입/출금) 조회',
                to: CashInOutMoney
            },
            E10:{
                text: '자금 관리 조회',
                to: CashReport,
            },
            E13: {
                text: '입금내역 검증',
                to: InMoneyCheck,
            },
        }
    },
    F00: {
        text: '인사',
        icon: SupervisorAccountIcon,
        children: {
            F01: {
                text: '인사 정보',
                to: EmpInfo
            },
            F02: {
                text: '로그인 정보',
                to: LoginInfo
            },
            F03: {
                text: '수송로그인 정보',
                to: TransLoginInfo
            },
        }
    },
    G00: {
        text: '보고서',
        icon: AssessmentIcon,
        children: {
            G06: {
                text: '매출',
                children: {
                    G03: {
                        text: '일보',
                        to: DailyReport
                    },
                    G04: {
                        text: '월보',
                        to: MonthReport
                    },
                    G05: {
                        text: '주유소 집계표(A)',
                        to: OilbankSummary
                    },
                    G12: {
                        text: '주유소 집계표(B)',
                        to: OilbankSummaryDYCNC
                    },
                    G19: {
                        text: '대리점 집계표',
                        to: AgencySummary
                    },
                    G10: {
                        text: '셀프세차 집계표',
                        to: SelfCarWash
                    },
                    G16: {
                        text: '매출 달성률',
                        to: AchieveReport
                    },
                }
            },
            G07: {
                text: '수불',
                children: {
                    G02: {
                        text: '상품 수불장',
                        to: SupplyInfo
                    },
                    G11: {
                        text: '상품 수불장 (기간별)',
                        to: SupplyInfoRange
                    },
                },
            },
            G17: {
                text: '회계',
                children: {
                    G18: {
                        text: '손익계산서',
                        to: ProfitLossReport
                    }
                }
            },
            G13: {
                text: 'POS',
                children: {
                    G14: {
                        text: 'POS 원본데이터조회',
                        to: PosOriginList
                    },
                    G15: {
                        text: 'POS 판매유형조회',
                        to: PosOriginListSaleType
                    }
                }
            },
        }
    },
    J00: {
        text: '회계',
        icon: AccountBalanceWalletRoundedIcon,
        children: {
            J01: {
                text: '회계 분개 등록',
                to: AccountingStatementEnroll
            },
            J02: {
                text: '입/출금 전표 등록',
                to: InOutAccountingEnroll
            },
            J10: {
                text: '입/출금 전표 조회',
                to: InOutAccountingList
            },
            J03: {
                text: '사업장 통합 이체',
                to: MoneyMoveEnroll
            },
            J04: {
                text: '계정과목 발행전표 조회',
                to: AccountingList
            },
            J08: {
                text: '매입/매출 상세 내역',
                to: InSaleList
            },
            J05: {
                text: '보고서',
                children: {
                    J12: {
                      text: '채무/채권 확인 조회서',  
                      to: InOutBondPayList
                    },
                    J06: {
                      text: '부가가치세 보고서',  
                      to: ValueAddedTax
                    },
                    J07: {
                        text: '통합 일일 보고서',  
                        to: MainWorkReport
                    },
                    // J11: {
                    //     text: '합계 잔액 시산표',  
                    //     to: TotalTrialBalance
                    // },
                    J13: {
                        text: '대리점 통합 일일 보고서',  
                        to: AgencyMainWorkReport
                    },
                    J14: {
                        text: '매출원가 보고서',  
                        to: SaleCostList
                    },
                    J09: {
                        text: '영업 보고서',  
                        to: BusinessReport
                    },
                }
            }
        }
    },
    L00:{
        text: '마감',
        icon: LockIcon,
        children: {
            L01:{
                text: '영업장 마감 등록',
                to: OilbankClose
            },
            L02:{
                text: '영업장 마감 해제',
                to: OilbankCloseUnlock
            },
            L03:{
                text: '본사 마감 등록',
                to: AllClose
            },
            L04:{
                text: '본사 마감 해제',
                to: AllCloseUnlock
            },
            L05:{
                text: '마감 조회',
                to: CloseList
            },
        }
    },
    M00:{
        text: '전자결재',
        icon: ContactsIcon,
        children: {
            M01:{
                text: '전자회계 결재',
                to: ElecAccounting
            },
            M02:{
                text: '전자기안문서',
                to: ElecDocument
            },
            M05:{
                text: '전자기안문서(오더거래처)',
                to: ElectDocumentOrderCst
            },
            M03:{
                text: '전자회계 입/출금 등록',
                to: ElectAccountingInOut
            },
            M04:{
                text: '전자회계 eFMS 자료조회',
                to: EFMSList
            },
            // M06:{
            //     text: '결재라인 수정',
            //     to: ElecLineEdit
            // }
        }
    },
    N00: {
        text: 'POS 전송 내역',
        icon: FindInPageIcon,
        to: SendPosList,
    },
    I00: {
        text: 'POS 전송',
        icon: SendIcon,
    },
}