import React, { useState } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DatePicker, Tabs, Tab, DataColumn } from '../../component';
import { makeStyles } from '@material-ui/core';
import { totalCount } from '../../common/Utils';
import { inMoneyRegType, occType } from '../../common/Dictionary';


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        width: 0,
    },
    tabContainer: {
        display: 'flex',
    },
    tabbar: {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    tab: {
        minWidth: 100,
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
    hide: {
        display: 'none'
    },
}))

const WorkInMoneyList = () => {
    const [condition, setCondition] = useState(null);
    const [selectTab, setSelectTab] = useState(0);
    const classes = useStyles();


    return (
        <div className={classes.container}>
            <SearchPanel onSearch={condition => {
                sessionStorage['WORK_CD'] = condition['WORK_CD'];
                setCondition(condition);
                }}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, setState) => (
                        <ComboBox
                            label='사업장명'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            onDataLoad={(jsonData, rawData) => {
                                const result = [{ WORK_CD: 'ALL', WORK_NM: '전체' }, ...rawData];
                                return result;
                            }}
                            value={state}
                            onChange={(value) => {
                                setState(value);
                            }}
                        />
                    )}
                />
               <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={sessionStorage['END_DATE'] || moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
            </SearchPanel>
            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab className={classes.tab} label='외상대 입금' />
                    <Tab className={classes.tab} label='외상대 입금(비상품)' />
                    <Tab className={classes.tab} label='제카드 입금' />
                    <Tab className={classes.tab} label='간편결제 입금' />
                </Tabs>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <InCst condition={condition} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <InNotCst condition={condition}/>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 2 })}>
                <InCard condition={condition}/>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 3 })}>
                <InSimplePay condition={condition}/>
            </div>
        </div>
    )  
}

const InCst = ({ condition }) => {


    return (
        <DataGrid
            title='외상대 입금'
            selectProc='SLT_WORK_INMONEY_CST'
            selectParam={condition}
            totalGroup={['WORK_CD']}
            horizonMode
            selectionMode='single'
            virtualized
            sortable
            filterable
            excelDownload={{ fileName: '사업장입금조회-외상대입금.xlsx', sheetName: '외상대입금' }}
        >
            <DataColumn fieldName='OCC_TYPE' value={(row, value)=>occType[value]}/>
            <DataColumn fieldName='REG_TYPE' value={(row, value)=>inMoneyRegType[value]}/>
            <DataColumn fieldName='WORK_CD' visible={false} />
            <DataColumn 
                    fieldName='WORK_NM' 
                    title='사업장명' 
                    total={(values, data, group) => 
                        group == null ? `- 합 계 ${totalCount(values)} 건 -` : `(소 계 ${totalCount(values)} 건) ${data[0]['WORK_NM']}`} 
                />
            <DataColumn fieldName='AMOUNT' title='금액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
        </DataGrid>
    )
}

const InNotCst = ({ condition }) => {


    return (
        <DataGrid
            title='외상대 입금(비상품)'
            selectProc='SLT_WORK_INMONEY_NOTCST'
            selectParam={condition}
            totalGroup={['WORK_CD']}
            horizonMode
            selectionMode='single'
            virtualized
            sortable
            filterable
            excelDownload={{ fileName: '사업장입금조회-비상품외상대입금.xlsx', sheetName: '비상품외상대입금' }}
        >
            <DataColumn fieldName='OCC_TYPE' value={(row, value)=>occType[value]}/>
            <DataColumn fieldName='REG_TYPE' value={(row, value)=>inMoneyRegType[value]}/>
            <DataColumn fieldName='WORK_CD' visible={false} />
            <DataColumn 
                    fieldName='WORK_NM' 
                    title='사업장명' 
                    total={(values, data, group) => 
                        group == null ? `- 합 계 ${totalCount(values)} 건 -` : `(소 계 ${totalCount(values)} 건) ${data[0]['WORK_NM']}`} 
                />
            <DataColumn fieldName='AMOUNT' title='금액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
        </DataGrid>
    )
}

const InCard = ({ condition }) => {


    return (
        <DataGrid
            title='제카드 입금'
            selectProc='SLT_WORK_INMONEY_CARD'
            selectParam={condition}
            totalGroup={['WORK_CD']}
            horizonMode
            selectionMode='single'
            virtualized
            sortable
            filterable
            excelDownload={{ fileName: '사업장입금조회-재카드입금.xlsx', sheetName: '재카드입금' }}
        >
            <DataColumn fieldName='OCC_TYPE' value={(row, value)=>occType[value]}/>
            <DataColumn fieldName='REG_TYPE' value={(row, value)=>inMoneyRegType[value]}/>
            <DataColumn fieldName='WORK_CD' visible={false} />
            <DataColumn 
                    fieldName='WORK_NM' 
                    title='사업장명' 
                    total={(values, data, group) => 
                        group == null ? `- 합 계 ${totalCount(values)} 건 -` : `(소 계 ${totalCount(values)} 건) ${data[0]['WORK_NM']}`} 
                />
            <DataColumn fieldName='AMOUNT' title='금액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
        </DataGrid>
    )
}

const InSimplePay = ({ condition }) => {

    return (
        <DataGrid
            title='간편결제 입금'
            selectProc='SLT_WORK_INMONEY_SIMPLEPAY'
            selectParam={condition}
            totalGroup={['WORK_CD']}
            horizonMode
            selectionMode='single'
            virtualized
            sortable
            filterable
            excelDownload={{ fileName: '사업장입금조회-간편결제입금.xlsx', sheetName: '간편결제입금' }}
        >
            <DataColumn fieldName='OCC_TYPE' value={(row, value)=>occType[value]}/>
            <DataColumn fieldName='REG_TYPE' value={(row, value)=>inMoneyRegType[value]}/>
            <DataColumn fieldName='WORK_CD' visible={false} />
            <DataColumn 
                    fieldName='WORK_NM' 
                    title='사업장명' 
                    total={(values, data, group) => 
                        group == null ? `- 합 계 ${totalCount(values)} 건 -` : `(소 계 ${totalCount(values)} 건) ${data[0]['WORK_NM']}`} 
                />
            <DataColumn fieldName='AMOUNT' title='금액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
        </DataGrid>
    )
}

export default WorkInMoneyList;