import React, { useState, useEffect, useMemo } from 'react';
import { ComboBox, ProgressPanel, IconButton, DatePicker, NumberField, ProgressDialog, PaperTitle } from '../../component';
import { Paper, makeStyles, TextField } from '@material-ui/core';
import { callProc } from '../../common/DBConnector';
import { useSnackbar } from 'notistack';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { useSelector } from 'react-redux';
import { useFieldInputs } from '../../common/Utils';
import SearchIcon from '@material-ui/icons/Search';



const unitType = {
    1: '리터',
    2: '드럼',
}

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    paperTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    insertBox : {
        height: '100%',
        paddingTop: theme.spacing(2),
        overflowY: 'auto',
    },
    field : {
        paddingBottom: theme.spacing(1),
        marginRight: theme.spacing(4),
        display: 'inline-block',
    },
    paperContent: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: 'calc(100% - 64px)',
        padding: theme.spacing(4),
        paddingTop: 0,
    },
    btnContainer: {
        display: 'flex',
        alignSelf: 'flex-end',
        marginTop: 'auto',
        padding: theme.spacing(2),
    },
    unit: {
        width: 100,
    },
    searchPaper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    searchContainer: {
        flex: 1,
        display: 'inline-block',
        flexDirection: 'row',
    },
    searchItem: {
        display: 'inline-block',
        width: 200,
        marginRight: 100,
        marginBottom: theme.spacing(1),
    },
}))

const RealStockInfo = () => {
    const clsState = useSelector(state => state.clsDateReducer);
    const [input, setInput] = useFieldInputs({
        WORK_CD: sessionStorage['WORK_CD'],
        BIZ_DATE: clsState[sessionStorage['WORK_CD']],
    }); 
    const conf = useSelector(state => state.configReducer);
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);

    const [workData, setWorkData] = useState();
    const [tnkData, setTnkData] = useState();
    const [unit, setUnit] = useState(conf['REALSTOCK_UNIT']);
    

    const { enqueueSnackbar } = useSnackbar();

    const condition = useMemo(() => input['WORK_CD'] && input['BIZ_DATE'] && input, [input]);

    const gridRefresh = () => {
        setInput({ type: 'UPDATE', value: { ...input } });
    }

    useEffect(() => {
        const fetchData = async () => {
            const workData = await callProc('SLT_WORKPLACEINFO_OIL_AUTH');

            setWorkData(workData.data.reduce((result, item) => ({
                ...result,
                [item['WORK_CD']]: item['WORK_NM']
            }), {}))
        }
        fetchData();
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            const tnkData = (await callProc('SLT_TANKREALSTOCKINFO', condition)).data;
            setTnkData(tnkData);
        }
        setEdit(false);
        condition && fetchData();
    }, [condition])

    const classes = useStyles();

    const valid = () => {
        if (tnkData.reduce((result, item) => result || item['QTY'] === '', false)) {
            enqueueSnackbar('수량을 입력하여 주세요.', { variant: 'error' });
            return false;
        }
        return true;
    }

    const onInsert = async() => {
        setLoading(true);

        if (valid()) {
            const result = await callProc('INS_TANKREALSTOCKINFO', {
                ...condition,
                JSON: JSON.stringify(tnkData),
                UNIT: unit,
            })
            if (!result.err) {
                gridRefresh();
                enqueueSnackbar('저장이 완료되었습니다.');
            }
        }

        setLoading(false);
    }

    return(
        !workData ? <ProgressPanel/> :
        <div className={classes.container}>
            <ProgressDialog open={loading} />
            <Paper className={classes.searchPaper}>
                <div className={classes.searchContainer}>
                    <div className={classes.searchItem}>
                        <ComboBox
                            // style={{width: 200}}
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={condition && condition['WORK_CD']}
                            onChange={value => {
                                if (input['WORK_CD'] !== value) {
                                    sessionStorage['WORK_CD'] = value;
                                    setInput('BIZ_DATE', clsState[value]);
                                    setInput('WORK_CD', value);
                                }
                            }}
                        />
                    </div>
                    <div className={classes.searchItem}>
                        <DatePicker
                            label='영업일'
                            minDate={clsState[input['WORK_CD']]}
                            value={condition && condition['BIZ_DATE']}
                            onChange={value => {
                                if (input['BIZ_DATE'] !== value) {
                                    setInput('BIZ_DATE', value);
                                    if(condition && condition['BIZ_DATE'] < clsState[condition['WORK_CD']]) {
                                        setInput({ type: 'UPDATE', value: { ...input, 'BIZ_DATE': value } });
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                <IconButton
                    tooltip=''
                    icon={<SearchIcon />}
                    onClick={gridRefresh}
                />
            </Paper>
            <Paper className={classes.paper}>
                <div className={classes.paperTitle}>
                    <PaperTitle>
                        실재고
                    </PaperTitle>
                    {edit ?
                    <div>
                        <IconButton 
                            tooltip='취소'
                            icon={<CloseIcon />}
                            onClick={() => gridRefresh()}
                            
                        />
                        <IconButton
                            tooltip='확인'
                            icon={<CheckIcon />}
                            onClick={onInsert}
                        />
                    </div>:
                    <IconButton 
                        tooltip='수정'
                        icon={<EditIcon />}
                        onClick={() => setEdit(true)}   
                     />}              
                </div>
                <div className={classes.paperContent}>
                    <div>
                        <TextField
                            className={classes.field}
                            label='사업장명'
                            value={(condition && workData[condition['WORK_CD']]) || ''}
                            disabled
                        />
                        <TextField
                            className={classes.field}
                            label='영업일'
                            value={(condition && condition['BIZ_DATE']) || ''}
                            disabled
                        />
                        <ComboBox
                            label='단위'
                            className={classes.unit}
                            data={unitType}
                            value={unit}
                            onChange={value => setUnit(value)}
                        />
                    </div>
                    <div className={classes.insertBox}>
                        {tnkData && tnkData.map((item, idx) => (
                            <div key={idx} className={classes.field}>
                                {item['TNK_NO'] + ' - ' + item['GDS_NM']}
                                <NumberField
                                    value={unit === '2' ? Math.round(item['QTY'] / 200) : item['QTY']}
                                    onChange={value => {
                                        item['QTY'] = unit === '2' ? value * 200 : value;
                                        setTnkData([...tnkData]);
                                    }}
                                    style={{ display: 'block' }}
                                    disabled={!edit}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </Paper>
        </div>
    )
}

export default RealStockInfo