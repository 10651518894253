import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, lighten, makeStyles, TextField, Typography } from '@material-ui/core';
import { SearchPanel, SearchItem, ComboBox, DataGrid, DataColumn, DatePicker, IconButton, ProgressPanel } from '../../component';
import { bbkType } from '../../common/Dictionary';
import { FormatLineSpacing, KeyboardArrowDown, KeyboardArrowUp, Check, Close } from '@material-ui/icons';
import clsx from 'clsx';
import { callProc } from '../../common/DBConnector';
import { useSnackbar } from 'notistack';

const useStyle = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
}))

const BankBook = () => {
    const classes = useStyle();
    const [condition, setCondition] = useState(null);
    const [oderbyDialogOpen, setOrderbyDialogOpen] = useState(false);
    const [bbkData, setBbkData] = useState(null);

    const dialogOpen = (data) => {
        setOrderbyDialogOpen(true);
        setBbkData(null);
        setBbkData(data);
    }

    const gridRefresh = () => {
        setCondition({...condition});
    }

    return(
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => setCondition(condition)}>
                <SearchItem
                    fieldName='WORK_CD'
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => {
                                dispatch(value);
                            }}
                        />
                    )}
                />
            </SearchPanel>

            <DataGrid
                title='계좌정보'
                table='TB_BANKBOOK'
                selectProc='SLT_BANKBOOK'
                insertProc='INS_BANKBOOK'
                updateProc='UPT_BANKBOOK'
                deleteProc='DLT_BANKBOOK'
                selectParam={condition}
                selectionMode='single'
                horizonMode
                headerItem={({data}) => 
                    <IconButton
                        tooltip='순서등록'
                        icon={<FormatLineSpacing />}
                        onClick={() => dialogOpen(data)}
                    />
                }
            >
                <DataColumn
                    fieldName='WORK_CD'
                    title='사업장명'
                    value={row => row['WORK_NM']}
                    defaultValue={condition && condition['WORK_CD']}
                    editable={(state, dispatch) => (
                        <ComboBox
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={value => dispatch(value)}
                            disabled
                        />
                    )}
                />
                <DataColumn
                    fieldName='BBK_CD'
                    title='계좌코드'
                />
                <DataColumn
                    fieldName='BBK_NM'
                    title='계좌명'
                    editable={(state, dispatch) => (
                        <TextField
                            style={{width: 150}}
                            value={state}
                            onChange={e => dispatch(e.target.value)}
                        />
                    )}
                />
                <DataColumn
                    fieldName='BBK_NO'
                    editable={(state, dispatch) => (
                        <TextField
                            style={{width: 200}}
                            value={state}
                            onChange={e => dispatch(e.target.value)}
                        />
                    )}
                />
                <DataColumn
                    fieldName='MAIN_DEPO'
                    editable={(state, dispatch) => (
                        <TextField
                            style={{width: 200}}
                            value={state}
                            onChange={e => dispatch(e.target.value)}
                        />
                    )}
                />
                <DataColumn
                    fieldName='BBK_TYPE'
                    value={(row, value) => bbkType[value]}
                    editable={(state, dispatch) => (
                        <ComboBox
                            data={bbkType}
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                />
                <DataColumn
                    fieldName='STA_DATE'
                    title='시작일'
                    editable={(state, dispatch) => (
                        <DatePicker
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                />
                <DataColumn
                    fieldName='END_DATE'
                    title='종료일'
                    defaultValue={null}
                    editable={(state, dispatch) => (
                        <DatePicker
                            value={state}
                            onChange={value => dispatch(value)}
                            nullable
                        />
                    )}
                />
                <DataColumn
                    fieldName='WORK_NM'
                    visible={false}
                />
            </DataGrid>
            <OrderbyDialog
                open={oderbyDialogOpen}
                onClose={() => setOrderbyDialogOpen(false)}
                bbkData={bbkData}
                condition={condition}
                gridRefresh={gridRefresh}
            />
        </div>
    )
}

const orderbyDialogStyle = makeStyles((theme) => ({
    dialogPaper: {
        maxWidth: 'none',
        width: 700,
        height: 700,
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    actionButton: {
        display: 'flex',
    },
    content: {
        overflowY: 'auto',
    },
    itemContainer: {
        cursor: 'default',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    selection: {
        backgroundColor: lighten(theme.palette.primary.main, 0.85),
    },
}))

const OrderbyDialog = ({open, onClose, bbkData, condition, gridRefresh}) => {
    const [data, setData] = useState();
    const [selected, setSelected] = useState();
    const classes = orderbyDialogStyle();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => setData(bbkData), [bbkData]);

    const onUpClick = () => {
        const index = data.indexOf(selected);
        data.splice(index, 1);
        data.splice(index - 1, 0, selected);
        const result = data.map((item, order) => ({
            ...item,
            SEQ: order + 1
        }));
        setData(result);
        setSelected(result.find(item => item['BBK_CD'] === selected['BBK_CD']));
    }

    const onDownClick = () => {
        const index = data.indexOf(selected);
        data.splice(index, 1);
        data.splice(index + 1, 0, selected);
        const result = data.map((item, order) => ({
            ...item,
            SEQ: order + 1
        }));
        setData(result);
        setSelected(result.find(item => item['BBK_CD'] === selected['BBK_CD']));
    }

    const onSave = async() => {
        const result = await callProc('UPT_BANKBOOK_SEQ', {...condition, JSON: JSON.stringify(data)});
        if(!result.err){
            enqueueSnackbar('저장이 완료되었습니다.');
            onClose();
            gridRefresh();
        }
    }

    return (
        <Dialog
            open={open}
            PaperProps={{className: classes.dialogPaper}}
        >
            <div className={classes.dialogTitle}>
                <DialogTitle>순서정렬</DialogTitle>
                <div className={classes.actionButton}>
                    <IconButton
                        tooltip='확인'
                        icon={<Check />}
                        onClick={onSave}
                    />
                    <IconButton
                        tooltip='위로 이동'
                        icon={<KeyboardArrowUp />}
                        disabled={!data || !selected || data.indexOf(selected) === 0}
                        onClick={onUpClick}
                    />
                    <IconButton
                        tooltip='아래로 이동'
                        icon={<KeyboardArrowDown />}
                        disabled={!data || !selected || data.indexOf(selected) === data.length - 1}
                        onClick={onDownClick}
                    />
                </div>
                <IconButton
                    tooltip='닫기'
                    icon={<Close />}
                    onClick={onClose}
                />
            </div>
            <div className={classes.content}>
                {!data ? <ProgressPanel /> :
                data.map((item, idx) => (
                    <Typography
                        onClick={() => setSelected(item)}
                        className={clsx(classes.itemContainer, {
                            [classes.selection]: item['BBK_CD'] === (selected && selected['BBK_CD'])
                        })}
                    >
                        {`${item['BBK_CD']} : ${item['BBK_NM']} (${bbkType[item['BBK_TYPE']]}) ${item['END_DATE'] ? `※종료일 : ${item['END_DATE']}※` : ``}`}
                    </Typography>
                ))}
            </div>
        </Dialog>
    )
}

export default BankBook