import { makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { callProc } from '../../common/DBConnector';
import { pricType } from '../../common/Dictionary';
import { ComboBox, DataColumn, DataGrid, FilePicker, IconButton, NumberField, TextField } from '../../component';
import * as actions from '../../redux/actions';

const realStockUnit = {
    1: '리터',
    2: '드럼'
}

const useStyles = makeStyles(theme => ({
    imgContainer: {
        display: 'flex',
    },
    iconImg: {
        maxWidth: 50,
        maxHeight: 50,
    },
}))

const ConfigInfo = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const gridParam = useMemo(() => ({ AUTH: 1 }), []);

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const onUpdate = async(row) => {
        const result = await callProc('UPT_CONFIG', row);
        if (result.err) {
            return false;
        } else {
            dispatch(actions.fetchConfig());
        }
    }

    return (
        <DataGrid
            title='시스템 설정'
            table='TB_CONFINFO'
            selectProc='SLT_CONF'
            selectParam={gridParam}
            updateProc='UPT_CONFIG'
            onUpdate={onUpdate}
            horizonMode
        >
            <DataColumn fieldName='CONF_CD' visible={false} />
            <DataColumn
                fieldName='CONF_VALUE'
                editable={(state, dispatch, rowState) => {
                    switch (rowState['CONF_CD']) {
                        case 'DOC_DLT_DAY': return (
                            <NumberField
                                value={state}
                                onChange={value => dispatch(value)}
                            />
                        )
                        case 'PROGRAM_ICON': return (
                            <div className={classes.imgContainer}>
                                <img
                                    className={classes.iconImg}
                                    src={state}
                                    alt=''
                                />
                                <FilePicker
                                    onChange={async(file) => {
                                        const base64Data = await toBase64(file);
                                        const fileType = base64Data.substr(0, 10);
                                        if (fileType === 'data:image') {
                                            dispatch(base64Data);
                                        } else {
                                            enqueueSnackbar('이미지 파일만 첨부가 가능합니다.', { variant: 'error' });
                                        }
                                    }}
                                    accept="image/*"
                                />
                                {state &&
                                <IconButton
                                    tooltip='삭제'
                                    icon={<Close />}
                                    onClick={() => dispatch()}
                                />}
                            </div>
                        )
                        case 'INPRICE_TYPE': return (
                            <ComboBox
                                data={pricType}
                                value={state}
                                onChange={value => dispatch(value)}
                            />
                        )
                        case 'REALSTOCK_UNIT': return (
                            <ComboBox
                                data={realStockUnit}
                                value={state}
                                onChange={value => dispatch(value)}
                            />
                        )
                        default: return (
                            <TextField
                                value={state}
                                onChange={value => dispatch(value)}
                            />
                        )
                    }
                }}
                value={(row, value) => {
                    switch (row['CONF_CD']) {
                        case 'PROGRAM_ICON': return (
                            <div className={classes.imgContainer}>
                                <img
                                    className={classes.iconImg}
                                    src={value}
                                    alt=''
                                />
                            </div>
                        )
                        case 'INPRICE_TYPE': return pricType[value];
                        case 'REALSTOCK_UNIT': return realStockUnit[value];
                        default: return value;
                    }
                }}
                headerStyle={{ minWidth: 200 }}
            />
        </DataGrid>
    )
}

export default ConfigInfo;