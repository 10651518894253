import * as xlsx from 'xlsx';
import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import crypto from 'crypto';
import moment from 'moment';
import { getColumnName } from './DBConnector';
import { pdf } from '@react-pdf/renderer';

const moveArrayItem = (items, moveFromIndex, moveToIndex) => {
    const movingItem = items[moveFromIndex];
    items.splice(moveFromIndex, 1);
    items.splice(moveToIndex, 0, movingItem);

    return items;
}

const toNumber = (value) => {
    value = (value != null && value.replaceAll) ? value.replaceAll(',', '') : '';
    value = value !== '' ? Number(value) : NaN;
    return isNaN(value) ? null : value;
}

const workbookToJson = (workbook) => {
    const sheets = workbook.Sheets;
    return Object.keys(sheets).reduce((result, sheetName) => {
        const sheet = sheets[sheetName];
        const ref = sheet['!ref'] || 'A1:A1';
        const lastCell = ref.split(':')[1];
        const lastRow = lastCell.replace(/[a-z]/gi, '');
        let lastCol = lastCell.replace(/[0-9]/g, '');
        let headerRow;

        while (lastCol > '@') {
            for (let r=0; r<=lastRow; r++) {
                if (sheet[lastCol + r] != null) {
                    headerRow = r;
                    break;
                }
            }
            if (headerRow != null) {
                break;
            }
            lastCol = String.fromCharCode(lastCol.charCodeAt() - 1);
        }

        if (headerRow == null) {
            return {
                ...result,
                [sheetName]: {}
            }
        }

        for (let c=0; c<=xlsx.utils.decode_col(lastCol); c++) {
            const cell = xlsx.utils.encode_cell({ c: c, r: headerRow - 1 });
            if (sheet[cell]) {
                sheet[cell].w = sheet[cell].w.trim();
                sheet[cell].w = sheet[cell].w.replace(/\r\n/g, ' ');
                sheet[cell].w = sheet[cell].w.replace(/\r/g, ' ');
                sheet[cell].w = sheet[cell].w.replace(/\n/g, ' ');
            }
        }

        return {
            ...result,
            [sheetName]: xlsx.utils.sheet_to_json(sheet, { range : `A${headerRow}:${lastCell}` }),
        }
    }, {});
}

const workbookToAoa = (workbook) => {
    const sheets = workbook.Sheets;
    return Object.keys(sheets).reduce((result, sheetName) => {
        const sheet = sheets[sheetName];
        const ref = sheet['!ref'] || 'A1:A1';
        const lastCell = ref.split(':')[1];
        const lastRow = lastCell.replace(/[a-z]/gi, '');
        let lastCol = lastCell.replace(/[0-9]/g, '');
        let headerRow;

        while (lastCol > '@') {
            for (let r=0; r<=lastRow; r++) {
                if (sheet[lastCol + r] != null) {
                    headerRow = r;
                    break;
                }
            }
            if (headerRow != null) {
                break;
            }
            lastCol = String.fromCharCode(lastCol.charCodeAt() - 1);
        }

        if (headerRow == null) {
            return {
                ...result,
                [sheetName]: {}
            }
        }

        const aoa = [];
        for (let r = headerRow; r <= lastRow; r++) {
            const row = [];
            for (let c=0; c<=xlsx.utils.decode_col(lastCol); c++) {
                const cell = xlsx.utils.encode_cell({ c: c, r: r - 1 });
                if (sheet[cell]) {
                    if (sheet[cell].t === 'n'){
                        row.push(sheet[cell].v);
                    } else {
                        sheet[cell].w = sheet[cell].w.trim();
                        sheet[cell].w = sheet[cell].w.replace(/\r\n/g, ' ');
                        sheet[cell].w = sheet[cell].w.replace(/\r/g, ' ');
                        sheet[cell].w = sheet[cell].w.replace(/\n/g, ' ');
                        row.push(sheet[cell].w);
                    }
                } else {
                    row.push('');
                }
            }
            aoa.push(row);
        }

        return {
            ...result,
            [sheetName]: aoa,
        }
    }, {});
}

const xlsToJson = (file, callback) => {
    const reader = new FileReader();
    reader.onload = (evt) => {
        const workbook = xlsx.read(evt.target.result, {type: 'binary'});
        callback(workbookToJson(workbook));
    };
    reader.readAsBinaryString(file);
}

const xlsToJsonAsync = async(file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (evt) => {
        const workbook = xlsx.read(evt.target.result, {type: 'binary'});
        resolve(workbookToJson(workbook));
    };
    reader.onerror = reject;
    reader.readAsBinaryString(file);
})

const xlsToAoa = (file, callback) => {
    const reader = new FileReader();
    reader.onload = (evt) => {
        const workbook = xlsx.read(evt.target.result, {type: 'binary'});
        callback(workbookToAoa(workbook));
    };
    reader.readAsBinaryString(file);
}

const xlsToAoaAsync = async(file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (evt) => {
        const workbook = xlsx.read(evt.target.result, {type: 'binary'});
        resolve(workbookToAoa(workbook));
    };
    reader.onerror = reject;
    reader.readAsBinaryString(file);
})

const dataToSheet = async(dataSet, columnName) => {
    const { data, fields } = dataSet;
    const colNameJson = { ...await getColumnName(), ...columnName };
    const aoa = [fields.map(field => colNameJson[field])];
    for (let i = 0; i < data.length; i++) {
        const row = data[i];
        aoa.push(fields.map(field => row[field]));
    }
    return xlsx.utils.aoa_to_sheet(aoa);
}

const aoaToSheet = (aoa) => {
    const result = [];
    for (let rowIdx = 0; rowIdx < aoa.length; rowIdx++) {
        const row = aoa[rowIdx];
        const rowResult = [];
        for (let i = 0; i < row.length; i++) {
            const numValue = toNumber(row[i]);
            if (numValue == null) {
                rowResult.push(row[i]);
            } else {
                rowResult.push(numValue);
            }
        }
        result.push(rowResult);
    }
    return xlsx.utils.aoa_to_sheet(result);
}

const dataToWorkbook = async(sheetName, dataSet, columnName) => {
    const workbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workbook, await dataToSheet(dataSet, columnName), sheetName);
    return workbook;
}

const aoaToWorkbook = (sheetName, aoa) => {
    const workbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workbook, aoaToSheet(aoa), sheetName);
    return workbook;
}

const sheetToWorkbook = (sheets) => {
    const workbook = xlsx.utils.book_new();
    sheets.map(sheet => xlsx.utils.book_append_sheet(workbook, sheet.sheet, sheet.name));
    return workbook;
}

const downloadWorkbook = (fileName, workbook) => {
    const nameFrag = fileName.split('.');
    const nameExt = nameFrag[nameFrag.length - 1];
    let ext;
    if (nameExt === 'xls' || nameExt === 'xlsx') {
        ext = '';
    } else {
        ext = '.xlsx';
    }
    xlsx.writeFile(workbook, fileName + ext, { bookSST: true, cellStyles: true })
};

const useFieldInputs = (defValue = {}) => {
    const [state, dispatch] = useReducer(
        (state, action) => {
            const { type, value } = action;
            switch (type) {
                case 'FIELD_UPDATE':
                    return { ...state, [value.field]: value.value };
                case 'UPDATE':
                    return { ...state, ...value };
                case 'INIT':
                    return value || defValue;
                default:
                    return state;
            }
        }, defValue);
    const onChange = useCallback((fieldName, value) => {
        if (typeof(fieldName) === 'object') {
            dispatch(fieldName)
        } else {
            dispatch({
                type : 'FIELD_UPDATE',
                value : {
                    field: fieldName,
                    value: value
                }
            });
        }
    }, []);
    const init = useCallback(() => {
        dispatch({ type : 'INIT' })
    }, []);
    return [state, onChange, init];
}

const usePdfRender = (document) => {
    const [url, setUrl] = useState();
    const worker = useRef();

    useEffect(() => {
        setUrl();
        if (worker.current) {
            worker.current.terminate();
        }
        if (!document) {
            return;
        }
        
        const pdfData = pdf({});
        pdfData.updateContainer(document);

        worker.current = new Worker('pdf_worker.bundle.js');
        worker.current.onmessage = (e) => setUrl(e.data);
        worker.current.postMessage(JSONfn.stringify(pdfData.container.document));
    }, [document])

    return url;
}

const numberFormat = (value) => typeof(value) !== 'number' ? value : value.toLocaleString(undefined, { maximumFractionDigits: 4 })
const numberPointFormat = (value, digit) => typeof(value) !== 'number' ? value : value.toLocaleString(undefined, { maximumFractionDigits: digit, minimumFractionDigits : digit })

const totalCount = (values) => values.reduce((result) => result + 1, 0)

const toHash = (value) => crypto.createHmac('sha256', process.env.REACT_APP_HASH_KEY).update(value).digest('hex')

const getSupplyAmt = (value, config) => {
    const amt = value / 1.1;
    switch (config) {
        default:
        case '0':
            return Math.round(amt);
        case '1':
            return Math.floor(amt);
        case '2':
            return Math.ceil(amt);
        case '3':
            const endNum = Math.floor((value / 1.1).toFixed(2)).toString().substr(Math.floor((value / 1.1).toFixed(2)).toString().length -1);
            if(endNum < 9){
                const supply = Math.ceil((value / 1.1));
                return supply;
            }
            else{
                const supply = Math.floor((value / 1.1));
                return supply;
            }
    }
}

const getVat = (value, config) => {
    const amt = value / 11;
    switch (config) {
        default:
        case '0':
            return Math.round(amt);
        case '1':
            return Math.ceil(amt);
        case '2':
            return Math.floor(amt);
        case '3':
            const endNum = Math.floor((value / 11).toFixed(2)).toString().substr(Math.floor((value / 11).toFixed(2)).toString().length -1);
            if(endNum < 9){
                const vat = Math.floor((value / 11));
                return vat;
            }
            else{
                const vat = Math.ceil((value / 11));
                return vat;
            }
    }
}

const weekOfYear = value => {
    return moment(value).isoWeek();
}

const rpadByteOfStr = (text, padLen, padStr) => {
    text = text == null ? '' : text;
    text += '';
    padStr += '';

    let str = text;
    let useLen = 0;
    let usePadLen = 0;
    if (padStr.length > padLen) {
        console.log("오류 : 채우고자 하는 문자열이 요청 길이보다 큽니다");
        return str + "";
    }
    

    for (let i = 0; i < str.length; i++) {
        escape(str.charAt(i)).length >= 4 ? useLen = useLen + 2 : useLen = useLen + 1;
    }
    
    usePadLen = padLen - useLen;
    for (let i = 0; i < usePadLen; i++) {
        str += padStr;
    }

        
    return str;
}

const lpadByteOfStr = (text, padLen, padStr) => {
    text = text == null ? '' : text;
    text += '';
    padStr += '';

    let str = text;
    let useLen = 0;
    let usePadLen = 0;
    if (padStr.length > padLen) {
        console.log("오류 : 채우고자 하는 문자열이 요청 길이보다 큽니다");
        return str + "";
    }
    

    for (let i = 0; i < str.length; i++) {
        escape(str.charAt(i)).length >= 4 ? useLen = useLen + 2 : useLen = useLen + 1;
    }
    
    usePadLen = padLen - useLen;
    for (let i = 0; i < usePadLen; i++) {
        str = padStr + str;
    }

        
    return str;
}

const readBlobAsync = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
        resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
})

const isFieldDiff = (fields, condition, param) => {
    const diff = fields.reduce((result, field) => (
        result || condition[field] !== (param && param[field])
    ), false);
    return fields.reduce((result, field) => (
        result && condition[field] !== undefined)
    , diff);
}

let JSONfn = {};

JSONfn.stringify = function(obj) {
    return JSON.stringify(obj, function(key, value) {
        return (typeof value === 'function' ) ? value.toString() : value;
    });
}

JSONfn.parse = function(str) {
    return JSON.parse(str, function(key, value) {
        if(typeof value != 'string') return value;
        // eslint-disable-next-line
        return (value.substring(0,8) === 'function' || value.includes('=>')) ? eval('('+value+')') : value;
    });
}

export {
    moveArrayItem,
    toNumber,
    xlsToJson,
    xlsToJsonAsync,
    xlsToAoa,
    xlsToAoaAsync,
    dataToSheet,
    aoaToSheet,
    dataToWorkbook,
    aoaToWorkbook,
    sheetToWorkbook,
    downloadWorkbook,
    useFieldInputs,
    usePdfRender,
    numberFormat,
    numberPointFormat,
    totalCount,
    toHash,
    weekOfYear,
    getSupplyAmt,
    getVat,
    rpadByteOfStr,
    lpadByteOfStr,
    readBlobAsync,
    isFieldDiff,
    JSONfn
}