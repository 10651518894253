export const LOGIN = 'LOGIN';
export const REQ_LOGIN = 'REQ_LOGIN';
export const LOGOUT = 'LOGOUT';

export const M_LOGIN = 'M_LOGIN';
export const REQ_M_LOGIN = 'REQ_M_LOGIN';
export const M_LOGOUT = 'M_LOGOUT';

export const CLS_DATE = 'CLS_DATE';

export const FETCH_CONFIG = 'FETCH_CONFIG';
export const SET_CONFIG = 'SET_CONFIG';