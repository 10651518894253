import React from 'react';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core';
import SplitterLayout from 'react-splitter-layout';

const useStyles = makeStyles(theme => ({
    splitter: {
        display: 'flex',
        flex: 1,
        '& .layout-pane': {
            display: 'flex',
        },
        '& .layout-pane-primary': {
            flex: 1,
        },
        '& .layout-splitter': {
            backgroundColor: lighten(theme.palette.primary.main, 0.85),
            width: 4,
            cursor: 'col-resize',
        },
    }
}));

const SplitPanel = ({ children, className, secondaryInitialSize, pixel, ...props }) => {
    const classes = useStyles();
    return (
        <SplitterLayout
            customClassName={clsx(classes.splitter, className)}
            secondaryInitialSize={secondaryInitialSize}
            percentage={!pixel}
            {...props}
        >
            {children}
        </SplitterLayout>
    )
}

SplitPanel.defaultProps = {
    secondaryInitialSize: 50,
    pixel: false,
}

export default SplitPanel;