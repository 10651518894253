import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { ComboBox, DataColumn, DataGrid, DatePicker, PdfButton, SearchItem, SearchPanel, SmallGrid, Tab, Tabs } from '../../component';
import { SelfCarWashPdf } from '../../reports';
import { callProc } from '../../common/DBConnector';
import { useSelector } from 'react-redux';

const useStyle = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    subTabContainer: {
        backgroundColor: theme.palette.secondary.main,
        boxShadow: theme.shadows[4],
        marginBottom: theme.spacing(1),
    },
    subcontainer: {
        marginBottom: theme.spacing(1),
    },
    tabContainer: {
        display: 'flex',
    },
    tabbar: {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
    hide: {
        display: 'none'
    },
}))

const SelfCarWash = () => {
    const classes = useStyle();
    const [condition, setCondition] = useState(null);
    const [selectTab, setSelectTab] = useState(0);

    const [saleStockCarWash, setSaleStockCarWash] = useState();
    const [stockCarWash, setStockCarWash] = useState();
    const [bondpayCarWash, setBondpayCarWash] = useState();
    const [itemMixSolution, setitemMixSolution] = useState();
    const [cautionMoney, setCautionMoney] = useState();
    const [itemSignCard, setItemSignCard] = useState();
    const [saleBondListWashExceptCardFee, setSaleBondListWashExceptCardFee] = useState();
    const [saleBondListDetailWash, setSaleBondListDetailWash] = useState();

    const loginState = useSelector(state => state.loginReducer);
    const [workData, setWorkData] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const workData = await callProc('SLT_WORKPLACEINFO_NOTMAIN_AUTH');

            setWorkData(workData.data.reduce((result, item) => ({
                ...result,
                [item['WORK_CD']]: item['WORK_NM']
            }), {}))

        }
        fetchData();
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            setSaleStockCarWash(null);
            setStockCarWash(null);
            setBondpayCarWash(null);
            setitemMixSolution(null);
            setCautionMoney(null);
            setItemSignCard(null); 
            setSaleBondListWashExceptCardFee(null);   
            setSaleBondListDetailWash(null);


            setSaleStockCarWash(await callProc('SLT_SALE_STOCK_CARWASH', condition));
            setStockCarWash(await callProc('SLT_STOCK_CARWASH', condition));
            setBondpayCarWash(await callProc('SLT_BONDPAY_CARWASH_CARD', condition));
            setitemMixSolution(await callProc('SLT_ITEMMIXSOLUTION', condition));
            setCautionMoney(await callProc('SLT_CAUTIONMONEY',condition));
            setItemSignCard(await callProc('SLT_ITEMSIGNCARD', condition));          
            setSaleBondListWashExceptCardFee(await callProc('SLT_SALE_BOND_LIST_WASH_EXCEPT_CARD_FEE', condition));            
            setSaleBondListDetailWash(await callProc('SLT_SALE_BOND_LIST_WASH_DETAIL_PRINT', condition));


        }
        condition && fetchData();
    }, [condition])


    const condWorkName = workData && condition && workData[condition['WORK_CD']];
    const pdfComponent = useMemo(() => (
        <PdfButton title='셀프세차 집계표'>
            {saleStockCarWash 
            && stockCarWash
            && bondpayCarWash 
            && itemMixSolution 
            && cautionMoney 
            && itemSignCard 
            && saleBondListWashExceptCardFee
            && saleBondListDetailWash &&
            <SelfCarWashPdf
                saleStockCarWash={saleStockCarWash && saleStockCarWash.data}
                stockCarWash={stockCarWash && stockCarWash.data}
                bondpayCarWash={bondpayCarWash && bondpayCarWash.data}
                itemMixSolution={itemMixSolution && itemMixSolution.data}
                cautionMoney={cautionMoney && cautionMoney.data}
                itemSignCard={itemSignCard && itemSignCard.data}
                saleBondListWashExceptCardFee={saleBondListWashExceptCardFee && saleBondListWashExceptCardFee.data}

                saleBondListDetailWash={saleBondListDetailWash && saleBondListDetailWash.data}

                condition={condition}
                loginState={loginState}
                workName={condWorkName}
            />}
        </PdfButton>
    ), [saleStockCarWash, 
        stockCarWash,
        bondpayCarWash, 
        itemMixSolution, 
        cautionMoney, 
        itemSignCard, 
        saleBondListWashExceptCardFee, 
        saleBondListDetailWash, 
        condition, 
        loginState, 
        condWorkName]);

    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => { 
                if (condition['WORK_CD'] !== 'ALL'){
                    sessionStorage['WORK_CD'] = condition['WORK_CD'];
                }
                setCondition(condition); 
                }}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, dispatch) => (
                        <ComboBox
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_CARWASH_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            onDataLoad={jsonData => ({ ALL: '전체', ...jsonData })}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
            </SearchPanel>


            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab label='판매 현황' />
                    <Tab label='재고 현황' />
                    <Tab label='카드사 채권현황' />
                    <Tab label='기타 현황' />
                    <Tab label='매출 시재 현황' />
                </Tabs>
                <div className={classes.subTabContainer}>

                    {pdfComponent}
                </div>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <SaleStock saleStockCarWash={saleStockCarWash}/>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <Stock stockCarWash={stockCarWash} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 2 })}>
                <Supply bondpayCarWash={bondpayCarWash} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 3 })}>
                <GaugeEtc itemMixSolution={itemMixSolution} cautionMoney={cautionMoney} itemSignCard={itemSignCard} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 4 })}>
                <SaleBondList condition={condition} />
            </div>
        </div>
    )
}

const SaleStock = ({ saleStockCarWash }) => {

    return(    
        <>
        <DataGrid
            title='판매 현황'
            dataSet={saleStockCarWash}
            totalGroup={['GDS_TYPE']}
            stickyHeader
            horizonMode
        >
            <DataColumn fieldName='GDS_TYPE' visible={false} />
            <DataColumn fieldName='RNG_SALE_AMT' title='판매금액' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='MONTH_SALE_AMT' title='월판매금액' total={(values) => values.reduce((result, value) => result + value, 0)} />
        </DataGrid>

        </>
    )
}

const Stock = ({ stockCarWash }) => {
    const classes = useStyle();

    return (
        <div className={classes.container}>
            <DataGrid
            title='재고 현황'
            dataSet={stockCarWash}
            horizonMode
            virtualized
        >
            <DataColumn fieldName='GDS_CD' visible={false} />
            <DataColumn fieldName='BEF_QTY' title='전기 재고' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='IN_QTY' title='당기 입고' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='SALE_QTY' title='당기 출고' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_QTY' title='당기 재고' total={(values) => values.reduce((result, value) => result + value, 0)}/>
        </DataGrid>

        </div>
    )
}

const Supply = ({ bondpayCarWash }) => {
    const classes = useStyle();

    return (
        <div className={classes.container}>
            <DataGrid
            title='카드사 채권현황'
            dataSet={bondpayCarWash}
            horizonMode
            virtualized
        >
            <DataColumn fieldName='WORK_NM' total={(values, data, group) => '총계'} />
            <DataColumn fieldName='BEF_NOT_IN_AMT' title='전기 미입금액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_SALE_AMT' title='당기 발생금액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_IN_AMT' title='당기 입금액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_COMM_AMT' title='당기 카드수수료' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_IN_SUM_AMT' title='합계 금액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_NOT_IN_AMT' title='당기 미입금액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
        </DataGrid>

        </div>
    )
}

const GaugeEtc = ({ itemMixSolution, cautionMoney, itemSignCard }) => {
    const classes = useStyle();
    return (
        <div className={classes.container}>
            <DataGrid
                className={classes.subcontainer}
                title='세차 세제'
                dataSet={itemMixSolution}
            >
                <DataColumn fieldName='BEF_QTY' title='전일재고' />
                <DataColumn fieldName='CUR_IN_QTY' title='입고' />
                <DataColumn fieldName='CUR_OUT_QTY' title='출고' />
                <DataColumn fieldName='CUR_QTY' title='당일재고' />
                <DataColumn fieldName='REAL_QTY' title='실재고' />
            </DataGrid>

            <DataGrid
                className={classes.subcontainer}
                title='보증금현황'
                dataSet={cautionMoney}
            >
                <DataColumn fieldName='BEF_AMT' title='전일잔액' />
                <DataColumn fieldName='CUR_IN_AMT' title='당일입금' />
                <DataColumn fieldName='CUR_OUT_AMT' title='당일지급' />
                <DataColumn fieldName='CUR_AMT' title='당일잔액' />
            </DataGrid>

            <DataGrid
                title='회원카드 현황'
                dataSet={itemSignCard}
            >
                <DataColumn fieldName='BEF_QTY' title='전일재고' />
                <DataColumn fieldName='CUR_IN_QTY' title='입고' />
                <DataColumn fieldName='CUR_RTN_QTY' title='반환입고' />
                <DataColumn fieldName='CUR_OUT_QTY' title='출고' />
                <DataColumn fieldName='CUR_DEST_QTY' title='파기' />
                <DataColumn fieldName='CUR_ETC_QTY' title='기타출고' />
                <DataColumn fieldName='CUR_QTY' title='당일재고' />
                <DataColumn fieldName='REAL_QTY' title='실재고' />
            </DataGrid>
        </div>
    )
}

const SaleBondList = ({ condition }) => (
    <DataGrid
        title='매출 시재 현황'
        selectProc='SLT_SALE_BOND_LIST_WASH'
        selectParam={condition}
        expandable={(row) => (
            row['EXPAND_TYPE'] === 0 ? null :
            <SmallGrid
                selectProc='SLT_SALE_BOND_LIST_DETAIL_WASH'
                selectParam={{ ...row, ...condition }}
            >
                <DataColumn fieldName='CATE_NM' title='구분' />
                <DataColumn fieldName='BEF_AMOUNT' title='전기잔액' />
                <DataColumn fieldName='SALE_AMOUNT' title='당기발생' />
                <DataColumn fieldName='IN_AMOUNT' title='당기입금' />
                <DataColumn fieldName='CUR_AMOUNT' title='당기잔액' />
            </SmallGrid>
        )}
        defaultExpand
        
    >
        <DataColumn fieldName='PAY_CD' visible={false} />
        <DataColumn fieldName='PAY_NM' title='구분' />
        <DataColumn fieldName='BEF_AMOUNT' title='전기잔액' />
        <DataColumn fieldName='SALE_AMOUNT' title='당기발생' />
        <DataColumn fieldName='IN_AMOUNT' title='당기입금' />
        <DataColumn fieldName='CUR_AMOUNT' title='당기잔액' />
        <DataColumn fieldName='EXPAND_TYPE' title='확장구분' visible={false} />
    </DataGrid>
)

export default SelfCarWash