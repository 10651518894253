import React, { useState } from 'react';
import { DataGrid, SearchPanel, DataColumn, Tabs, Tab, TextField } from '../../component';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { callProc } from '../../common/DBConnector';

const notDeleteSmall = [
    '1',
    '2',
    '3',
    '4',
    '5',
]

const notDeleteMiddle = [
    '1',
]

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },    
    tabContainer : {
        display : 'flex',
    },
    tabbar : {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    content : {
        display: 'flex',
        flex: 1,
        height : 0,
    },
    hide : {
        display: 'none'
    },
}));

const CategoryInfo = () => {
    const [condition, setCondition] = useState(null);
    const [selectTab, setSelectTab] = useState(0);
    const classes = useStyles();

    return(
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => setCondition(condition)}>
            </SearchPanel>
            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab label='카테고리(소)' />
                    <Tab label='카테고리(중)' />
                </Tabs>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <CategorySmall condition={condition} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <CategoryMiddle condition={condition} />
            </div>
        </div>
    )
}

const CategorySmall = ({condition}) => {
    const { enqueueSnackbar } = useSnackbar();
    const onDelete = async(row) => {
        const isValid = valid(row);
        if(isValid) {
            await callProc('DLT_CATEGORYINFO', row);
        }

        return isValid;
    }

    const valid = (row) => {
        if(notDeleteSmall.includes(String(row['GDS_TYPE']))){
            enqueueSnackbar('해당 코드는 시스템에 사용중이므로 삭제 할 수 없습니다.', { variant: 'error' });
            return false;
        }

        return true;
    }

    return(
        <DataGrid
            title='카테고리(소)'
            selectProc='SLT_CATEGORYINFO'
            insertProc='INS_CATEGORYINFO'
            updateProc='UPT_CATEGORYINFO'
            onDelete={row => onDelete(row)}
            selectParam={condition}
            selectionMode='single'
            horizonMode
            keys={['GDS_TYPE']}
            virtualized
            excelDownload={{ fileName: '카테고리(소).xlsx', sheetName: '카테고리(소)' }}
            
        >
            <DataColumn
                fieldName='CATE_NM'
                editable={(state, dispatch, rowState) => {
                    return (
                        notDeleteSmall.includes(String(rowState['GDS_TYPE'])) ? state :
                        <TextField
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )
                }}
            />
        </DataGrid>
    )
}

const CategoryMiddle = ({condition}) => {
    const { enqueueSnackbar } = useSnackbar();
    const onDelete = async(row) => {
        const isValid = valid(row);
        if(isValid) {
            await callProc('DLT_CATEGORY_MIDDLE', row);
        }

        return isValid;
    }

    const valid = (row) => {
        if(notDeleteMiddle.includes(String(row['GDS_TYPE_M']))){
            enqueueSnackbar('해당 코드는 시스템에 사용중이므로 삭제 할 수 없습니다.', { variant: 'error' });
            return false;
        }

        return true;
    }

    return(
        <DataGrid
            title='카테고리(중)'
            selectProc='SLT_CATEGORY_MIDDLE'
            insertProc='INS_CATEGORY_MIDDLE'
            updateProc='UPT_CATEGORY_MIDDLE'
            onDelete={row => onDelete(row)}
            selectParam={condition}
            selectionMode='single'
            horizonMode
            keys={['GDS_TYPE_M']}
            virtualized
            excelDownload={{ fileName: '카테고리(중).xlsx', sheetName: '카테고리(중)' }}
            
        >
            <DataColumn
                fieldName='CATE_NM_M'
                editable={(state, dispatch, rowState) => {
                    return (
                        notDeleteMiddle.includes(String(rowState['GDS_TYPE_M'])) ? state :
                        <TextField
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )
                }}
            />
        </DataGrid>
    )
}


export default CategoryInfo;