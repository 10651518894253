import React, { useState } from 'react';
import { DataGrid, SearchItem, SearchPanel, DataColumn, Tabs, Tab, ComboSearch, TextField } from '../../component';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { callProc } from '../../common/DBConnector';

const notDelete = [
    '0001', 
    '0003',
    '0008', 
    '0020',
    '0023', 
    '0024', 
    '0030',
    '0065',
    '0100', 
    '3001', 
    '3003', 
    '3005', 
    '7084',
    '7090'
]

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },    
    tabContainer : {
        display : 'flex',
    },
    tabbar : {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    content : {
        display: 'flex',
        flex: 1,
        height : 0,
    },
    hide : {
        display: 'none'
    },
}));

const Account = () => {
    const [condition, setCondition] = useState(null);
    const [selectTab, setSelectTab] = useState(0);
    const classes = useStyles();

    return(
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => setCondition(condition)}>
                <SearchItem
                    fieldName='CLASS_CD'
                    component={(state, dispatch) => (
                        <ComboSearch
                            label='분류코드'
                            selectProc='SLT_ACCOUNT_CLASS_ALL'
                            keyField='CLASS_CD'
                            valueField='CLASS_NM'
                            onDataLoad={jsonData => ({'ALL': '전체', ...jsonData})}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
            </SearchPanel>
            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab label='계정과목' />
                </Tabs>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <AccountList condition={condition} />
            </div>
        </div>
    )
}

const AccountList = ({condition}) => {
    const { enqueueSnackbar } = useSnackbar();
    const onDelete = async(row) => {
        const isValid = valid(row);
        if(isValid) {
            await callProc('DLT_ACCOUNT', row);
        }

        return isValid;
    }

    const valid = (row) => {
        if(notDelete.includes(row['ACC_CD'])){
            enqueueSnackbar('해당 코드는 시스템에 사용중이므로 삭제 할 수 없습니다.', { variant: 'error' });
            return false;
        }

        return true;
    }

    return(
        <DataGrid
            title='계정과목'
            selectProc='SLT_ACCOUNT'
            insertProc='INS_ACCOUNT'
            updateProc='UPT_ACCOUNT'
            onDelete={row => onDelete(row)}
            selectParam={condition}
            selectionMode='single'
            horizonMode
            keys={['ACC_CD']}
            virtualized
            excelDownload={{ fileName: '계정과목.xlsx', sheetName: '계정과목' }}
            
        >
            <DataColumn
                fieldName='ACC_NM'
                editable={(state, dispatch, rowState) => {
                    return (
                        notDelete.includes(rowState['ACC_CD']) ? state :
                        <TextField
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )
                }}
            />
            <DataColumn
                fieldName='ACC_PRT_NM'
                editable={(state, dispatch, rowState) => {
                    return (
                        notDelete.includes(rowState['ACC_CD']) ? state :
                        <TextField
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )
                }}
            />
            <DataColumn
                fieldName='CLASS_CD'
                value={(row) => row['CLASS_NM']}
                editable={(state, dispatch, rowState) => {
                    return (
                        notDelete.includes(rowState['ACC_CD']) ? rowState['CLASS_NM'] :
                        <ComboSearch
                            style={{width: 200}}
                            selectProc='SLT_ACCOUNT_CLASS_ALL'
                            keyField='CLASS_CD'
                            valueField='CLASS_NM'
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )
                }}
            />
            <DataColumn
                fieldName='CLASS_NM'
                visible={false}
            />
        </DataGrid>
    )
}


export default Account;