import { combineReducers } from "redux";
import { CLS_DATE, LOGIN, LOGOUT, M_LOGIN, M_LOGOUT, SET_CONFIG } from './constants';

const getLoginState = () => ({
    sessionId: sessionStorage['ID'],
    sessionPw: sessionStorage['PW'],
})

const loginReducer = (state = getLoginState(), action) => {
    switch (action.type) {
        case LOGIN:
            return action.loginData;
        case LOGOUT:
            sessionStorage.removeItem('ID');
            sessionStorage.removeItem('PW');
            return getLoginState();
        default:
            return state;
    }
}

const getMobileLoginState = () => ({
    sessionId: sessionStorage['M_ID'],
    sessionPw: sessionStorage['M_PW'],
})

const mobileLoginReducer = (state = getMobileLoginState(), action) => {
    switch (action.type) {
        case M_LOGIN:
            return action.loginData;
        case M_LOGOUT:
            localStorage.removeItem('M_LOGIN_RMB_ID');
            localStorage.removeItem('M_LOGIN_RMB_PW');
            sessionStorage.removeItem('M_ID');
            sessionStorage.removeItem('M_PW');
            return getMobileLoginState();
        default:
            return state;
    }
}

const clsDateReducer = (state = {}, action) => {
    switch (action.type) {
        case CLS_DATE:
            return action.clsData;
        default:
            return state;
    }
}

const configReducer = (state = { isEmpty: true }, action) => {
    switch (action.type) {
        case SET_CONFIG:
            const title = action.confData['PROGRAM_TITLE'];
            if (title) {
                document.title = title;
            } else {
                document.title = 'ERP System';
            }
            const favicon = action.confData['PROGRAM_ICON'];
            if (favicon) {
                document.querySelector("link[rel~='icon']").href = favicon;
            } else {
                document.querySelector("link[rel~='icon']").href = "/logo192.png";
            }
            return action.confData;
        default:
            return state;
    }
}

const rootReducer = combineReducers({ loginReducer, clsDateReducer, mobileLoginReducer, configReducer });

export default rootReducer;