import React, { useState, useEffect, useRef, useMemo } from 'react';
import './App.css';
import { Menu, MenuItem, MenuDivider, ProgressPanel, IconButton, TextField } from './component';
import SettingsIcon from '@material-ui/icons/Settings';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { makeStyles, Avatar, Menu as PopupMenu, MenuItem as PopupMenuItem, ListItemIcon, Dialog, Button, DialogTitle } from '@material-ui/core';
import { SendPos } from './containers';
import MenuInfo, { menuOverride } from './MenuInfo';
import { useHistory } from 'react-router-dom';
import { callProc, getErpRelease, setLoginId } from './common/DBConnector';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from './redux/actions'
import { Fragment } from 'react';
import { Close, Info } from '@material-ui/icons';
import release from './releaseNote/erp.json';
import { DefaultHome, Home } from './containers/home';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import { toHash } from './common/Utils';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: '100%',
        height: '100%',
    },
    top: {
        ...theme.mixins.toolbar,
      },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

const App = () => {
    const [sendPosVisible, setSendPosVisible] = useState(false);
    const [menuAuth, setMenuAuth] = useState();
    const [newRelease, setNewRelease] = useState();

    const loginState = useSelector(state => state.loginReducer);
    const conf = useSelector(state => state.configReducer);
    const dispatch = useDispatch();
    
    const { enqueueSnackbar } = useSnackbar();
    window.enqueueSnackbar = enqueueSnackbar;

    const history = useHistory();
    const classes = useStyles();

    const prevCls = useRef();

    useEffect(() => { conf.isEmpty && dispatch(actions.fetchConfig()) }, [conf.isEmpty, dispatch]);

    useEffect(() => {
        const fetchData = async() => {
            const menuData = await callProc('SLT_MENUAUTHINFO', { ID: loginState['ID'] });
            setMenuAuth(menuData.data.map(item => item['MENU_CD']));
        }

        const chkClsDate = async() => {
            const edtDttm = (await callProc('SLT_CLS_EDT')).data[0]['EDT_DTTM'];
            if (prevCls.current !== edtDttm) {
                const clsData = (await callProc('SLT_WORK_CLS')).data.reduce((result, item) => ({
                    ...result,
                    [item['WORK_CD']]: item['CLS_DATE']
                }), {});
                dispatch(actions.clsDate(clsData))
                prevCls.current && enqueueSnackbar('마감일이 변경되었습니다.');
                prevCls.current = edtDttm;
            }
        }

        const chkErpVersion = async() => {
            const chkRelease = await getErpRelease();
            if (chkRelease && release.version !== chkRelease.version) {
                setNewRelease(chkRelease);
            }
        }

        const check = async() => {
            chkClsDate();
            chkErpVersion();
        }

        if (loginState['ID']) {
            setLoginId(loginState['ID']);
            fetchData();

            check();
            const refreshSec = 10;
            const interval = setInterval(check, refreshSec * 1000);

            return () => clearInterval(interval);
        } else {
            dispatch(actions.reqLogin(
                loginState.sessionId,
                loginState.sessionPw,
                () => history.push('/login')
            ))
        }
    }, [loginState, dispatch, enqueueSnackbar, history])

    const attr = {
        I00: {
            onClick: () => setSendPosVisible(true)
        }
    }

    const createMenu = (menu, divider) => Object.keys(menu).map(key => {
        const {children, ...props} = menu[key];
        let override;
        let visible = menu[key].visible;

        if (menuOverride[conf['MENU_TYPE']]) {
            override = menuOverride[conf['MENU_TYPE']][key];
            if (override && override.visible !== undefined) {
                visible = override.visible;
            }
        }

        if (menuAuth.indexOf(key) !== -1 || visible === false) {
            return null;
        }

        return ([
            <MenuItem key={key} menuKey={key} {...props} {...attr[key]} {...override}>
                {children && createMenu(children)}
            </MenuItem>,
            divider && <MenuDivider />
        ])
    })

    const homeType = conf['HOME_TYPE'];
    const main = useMemo(() => {
        switch (homeType) {
            default:
                return DefaultHome;
            case '0':
                return Home;
            case undefined:
                return ProgressPanel;
        }
    }, [homeType]);

    return (
        conf.isEmpty || !menuAuth ? <ProgressPanel /> :
        <div className={classes.root}>
            <Menu
                title={conf['PROGRAM_TITLE']}
                favicon={conf['PROGRAM_ICON']}
                toolbar={<Toolbar userData={loginState} onLogout={() => dispatch(actions.logout())} />}
                width={320}
                main={main}
            >
                {!menuAuth ? <ProgressPanel /> :
                createMenu(MenuInfo, true)}
            </Menu>
            <SendPos visible={sendPosVisible} onClose={() => setSendPosVisible(false)} />
            <VersionDialog release={newRelease} />
        </div>
    )
}

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: 1,
        // backgroundColor: 'red',
        justifyContent: 'space-between',
    },
    container: {
        display: 'flex',
        // backgroundColor: 'green',
        alignItems: 'center',
    },
    profile: {
        fontSize: 14,
        textAlign: 'left',
    },
    textLight: {
        color: '#888'
    },
    textContainer: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    }
}));

const Toolbar = ({ userData, onLogout }) => {
    const [infoDialog, setInfoDialog] = useState(false);
    const [passWordDialog, setPassWordDialog] = useState(false);
    
    const [anchorEl, setAnchorEl] = useState(null);
    const [settingEl, setSettingEl] = useState(null);

    const classes = useToolbarStyles();
    return (
        <div className={classes.root}>
            <PopupMenu
                anchorEl={anchorEl}
                open={anchorEl != null}
                onClose={() => setAnchorEl(null)}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <IconMenuItem
                    label='로그아웃'
                    icon={<ExitToAppIcon />}
                    onClick={onLogout}
                />
                <IconMenuItem
                    label='비밀번호 변경'
                    icon={<EditIcon />}
                    onClick={() => setPassWordDialog(true)}
                />
            </PopupMenu>
            <IconButton
                className={classes.profile}
                icon={<Fragment>
                    <Avatar />
                    <div className={classes.textContainer}>
                        <span>
                            <b>{userData['EMP_NM']}</b>
                        </span>
                        <br/>
                        <span className={classes.textLight}>
                            {userData['POSITION']}
                        </span>
                    </div>
                    <ExpandMore />
                </Fragment>}
                onClick={e => setAnchorEl(e.currentTarget)}
                disableTooltip
            />
            <div className={classes.container}>
                <PopupMenu
                    anchorEl={settingEl}
                    open={settingEl != null}
                    onClose={() => setSettingEl(null)}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <IconMenuItem
                        label='프로그램 정보'
                        icon={<Info />}
                        onClick={() => setInfoDialog(true)}
                    />
                </PopupMenu>
                <IconButton
                    icon={<SettingsIcon />}
                    onClick={e => setSettingEl(e.currentTarget)}
                    disableTooltip
                />
                <ProgramInfoDialog
                    open={infoDialog}
                    onClose={() => setInfoDialog(false)}
                />
                <PasswordDialog
                    open={passWordDialog}
                    onClose={() => setPassWordDialog(false)}
                />
            </div>
        </div>
    )
}

const IconMenuItem = ({ onClick, icon, label }) => {
    return (
        <PopupMenuItem onClick={onClick}>
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            {label}
        </PopupMenuItem>
    )
}

const passDialogStyles = makeStyles(theme => ({
    dialogPaper: {
        maxWidth: 'none',
        width: 300,
        height: 200,
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    okButton: {
        display: 'flex',
    },
    fieldContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    field: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1),
    }
}))

const PasswordDialog = ({ open, onClose }) => {
    const classes = passDialogStyles();
    const [password, setPassword] = useState();
    const {enqueueSnackbar} = useSnackbar();

    const valid = () => {
        if (!password || password < 4) {
            enqueueSnackbar('비밀번호가 너무 짧습니다.(최소 4자)', { variant: 'error' });
            return false;
        }

        return true;
    }

    const onUpdate = () => {
        if(valid()){
            const result = callProc('UPT_LOGIN_PASSWORD', { PASSWORD: toHash(password) });

            if(!result.err){
                onClose();
            }
        }
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{ className: classes.dialogPaper }}
        >
            <div className={classes.dialogTitle}>
                <DialogTitle>비밀번호 변경</DialogTitle>
                <div className={classes.okButton}>
                    <IconButton
                        tooltip='확인'
                        onClick={onUpdate}
                        icon={<CheckIcon />}
                    />
                </div>
                <IconButton
                    tooltip='닫기'
                    onClick={onClose}
                    icon={<Close />}
                />
            </div>
            <div className={classes.fieldContainer}>
                <TextField
                    className={classes.field}
                    label='비밀번호'
                    type='password'
                    value={password}
                    onChange={value => setPassword(value)}
                />
            </div>
        </Dialog>
    )
}

const useDialogStyles = makeStyles(theme => ({
    content: {
        padding: theme.spacing(2),
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
}));

const ProgramInfoDialog = ({ open, onClose }) => {
    const classes = useDialogStyles();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{ className: classes.content }}
        >
            <div className={classes.dialogTitle}>
                <DialogTitle>
                    프로그램 정보
                </DialogTitle>
                <IconButton
                    tooltip='닫기'
                    onClick={onClose}
                    icon={<Close />}
                />
            </div>
            <div className={classes.content}>
                {`ERP Version : ${release.version}`}
            </div>
        </Dialog>
    )
}

const VersionDialog = ({ release }) => {
    const classes = useDialogStyles();

    return (
        <Dialog open={release != null}>
            <div className={classes.content}>
                프로그램 버전이 업데이트 되어 새로고침합니다.<br/>
                저장하지 않은 작업 내역은 사라집니다.<br/><br/>
                업데이트 내역<br/>
                {release && release.note.map((item, idx) => (
                    <Fragment key={idx}>
                        {'- ' + item}
                        <br/>
                    </Fragment>
                ))}
            </div>
            <Button onClick={() => window.location.reload()}>
                확인
            </Button>
        </Dialog>
    )
}

export default App;
