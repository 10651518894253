import PropTypes from 'prop-types';

const DataColumn = () => (null);

DataColumn.propTypes = {
    /**
     * 필드명
     * @description
     * Grid에 존재하는 필드명일 경우 Overiding,
     * 그렇지 않으면 새 컬럼 생성
     * @description
     * 미입력 시 UPDATE/INSERT 불가능
     */
    fieldName : PropTypes.string,
    /**
     * 컬럼 헤더
     */
    title : PropTypes.node,
    /**
     * 위치를 지정할 컬럼명
     * @description
     * 지정한 컬럼의 좌측에 위치
     * @description
     * 미지정 시 우측 가장자리에 위치
     * @description
     * 동일한 위치 참조 시 children 순서에 따라 위치
     */
    position : PropTypes.string,
    /**
     * Column Span의 기준을 둘 필드명
     */
    mergeField : PropTypes.string,
    /**
     * Cell value로 지정할 Callback
     * @param {object} row
     * 해당 Cell이 위치한 Row의 값
     * @param {string} value
     * 해당 Cell의 DB값(DB에 없는 필드일 경우 null)
     * @param {number} index
     * 해당 Row의 index
     * @example
     * (row) => row.field1
     * @example
     * (row, value, index) => `${index} : ${value}원`
     */
    value : PropTypes.func,
    /**
     * 추가 및 수정 시의 Cell value
     * @description
     * return type이 component인 callback
     * @param {string} state
     * 해당 Cell값의 state
     * @param {func} dispatch
     * setState
     * @param {object} rowState
     * 해당 Row의 state
     * @param {func} setField
     * 필드명으로 state 변경 : setField(fieldName, value);
     * @param {number} rowIdx
     * edit 요청한 row의 위치(insert일 경우 -1)
     * @example
     * (state, dispatch) =>
     *    <TextField
     *        value={state}
     *        onChange={(evt) => disaptch(evt.target.value)}
     *    />
     * @example
     * (state, dispatch, rowState, setField) =>
     *    <TextField
     *        value={state}
     *        onChange={(evt) => {
     *            disaptch(evt.target.value);
     *            setField('Field1', null);
     *        }}
     *    />
     */
    editable : PropTypes.func,
    /**
     * 집계 Cell의 값
     * @description
     * 집계 결과값을 return하는 callback
     * @param {array} values
     * 집계된 해당 필드의 값 배열
     * @param {array} data
     * 집계된 전체 데이터
     * @param {object} group
     * DataGrid의 totalGroup 값에 따라 grouping된 항목의 object
     * @description
     * 총계 Row는 group값 undefined으로 호출
     * @example
     * (values) => values.reduce((result, value) => result + value, 0)
     * @example
     * (values, data) => data.reduce((result, row) => result + row[field], 0)
     * @example
     * (values, data, group) => group ? group['GDS_NM'] + ' 소계' : '총계'
     */
    total : PropTypes.func,
    /**
     * 추가 시 초기값
     */
    defaultValue : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * Header cell의 style
     */
    headerStyle : PropTypes.object,
    /**
     * Row cell의 style
     */
    cellStyle : PropTypes.object,
    visible : PropTypes.bool,
}

DataColumn.defaultProps = {
    visible: true,
}

export default DataColumn;