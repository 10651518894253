import React from 'react';
import { Document, StyleSheet, Text, View} from '@react-pdf/renderer';
import { PdfCell, PdfTable, PdfRow, PdfPage } from '../component';
import { numberFormat } from '../common/Utils';

const styles = StyleSheet.create({
    titleSort : {
        textAlign : 'center',
        fontSize : 25,
        marginBottom : 15
    },
    tableMargin : {
        marginBottom : 20
    },
    headerCell : {
        backgroundColor : 'lightyellow',
    },
    footerCell : {
        backgroundColor : 'honeydew'
    },
    colSpanCell : {
        flex : 2,
        paddingRight : 1,
        backgroundColor : 'honeydew'
    },
    signText : {
        borderBottom : 1,
        borderTop : 1,
        borderLeft : 1,
        padding : 4,
        justifyContent : 'center',
    },
    numberSort : {
        textAlign : 'right'
    },
    
    pageNumber: {
        position: 'absolute',
        bottom: 15,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
})

const DailyReportPdf = ({ workCd, workName, printDttm, date, gaugeData, gdsSaleData, paySaleData, supplyData, payMentSumData, monthly }) => {
    const getTotal = (data, field) => (
        numberFormat(data.reduce((result, item) => result + item[field], 0))
    )

    gaugeData = [...gaugeData].sort((a, b) => a['NZL_NO'] < b['NZL_NO'] ? -1 : 1)
    gaugeData = [...gaugeData].sort((a, b) => a['GDS_NM'] < b['GDS_NM'] ? -1 : 1)

    return (
        <Document title={`${monthly ? '판매월보' : '판매일보'}_${workCd}_${date.replace(/-/g, '')}`}>
            <PdfPage>
                <Text style={styles.titleSort}>{workName} {monthly ? '판매월보' : '판매일보'} </Text>

                <View style={{ flexDirection : 'row', justifyContent: 'space-between', marginBottom : 8 }}>
                    <View style={{ alignSelf : 'flex-end' }}>
                        <Text style={{ fontSize : 12 }}> 일자 : {date} </Text>
                        <Text style={{ fontSize : 12 }}> 출력 : {printDttm} </Text>
                    </View>
                    <View style={{ flexDirection : 'row' }}>
                        <View style={styles.signText}>
                            <Text>결{"\n"}재</Text>
                        </View>
                        <PdfTable style={{ width : 250 }}>
                            <PdfRow>
                                <PdfCell cellTop cellLeft>담당</PdfCell>
                                <PdfCell cellTop>부장</PdfCell>
                                <PdfCell cellTop>상무</PdfCell>
                                <PdfCell cellTop>전무</PdfCell>
                                <PdfCell cellTop>사장</PdfCell>
                            </PdfRow>
                            <PdfRow>
                                <PdfCell cellLeft style={{ height : 30 }}></PdfCell>
                                <PdfCell></PdfCell>
                                <PdfCell></PdfCell>
                                <PdfCell style={{ justifyContent : 'center' }}>전결</PdfCell>
                                <PdfCell></PdfCell>
                            </PdfRow>
                        </PdfTable>
                    </View>
                </View>

                <PdfTable style={styles.tableMargin}>
                    <PdfRow>
                        <PdfCell cellTop cellLeft>총 판 매 량</PdfCell>
                    </PdfRow>
                    <PdfRow fixed>
                        <PdfCell style={styles.headerCell} cellLeft> 유 종</PdfCell>
                        <PdfCell style={styles.headerCell}>총출하</PdfCell>
                        <PdfCell style={styles.headerCell}>(-)검량</PdfCell>
                        <PdfCell style={styles.headerCell}>(-)홈로리</PdfCell>
                        <PdfCell style={styles.headerCell}>판매수량</PdfCell>
                    </PdfRow>
                    {gdsSaleData.map((item, idx) => {
                        let totalRow
                        if (!gdsSaleData[idx + 1] || item['GROUP_TOTAL'] !== gdsSaleData[idx + 1]['GROUP_TOTAL']) {
                            const groupData = gdsSaleData.reduce((result, row) => (
                                row['GROUP_TOTAL'] === item['GROUP_TOTAL'] ?
                                [...result, row] :
                                result
                            ), [])
                            totalRow = (
                                <PdfRow>                                    
                                    <PdfCell cellLeft style={{backgroundColor : 'honeydew'}}>소 계</PdfCell>
                                    <PdfCell style={styles.numberSort}>{getTotal(groupData, 'TOTAL_OUT_QTY')}</PdfCell>
                                    <PdfCell style={styles.numberSort}>{getTotal(groupData, 'TEST_QTY')}</PdfCell>
                                    <PdfCell style={styles.numberSort}>{getTotal(groupData, 'HOME_OUT_QTY')}</PdfCell>
                                    <PdfCell style={styles.numberSort}>{getTotal(groupData, 'SALE_QTY')}</PdfCell>
                                </PdfRow>
                            )
                        }
                        return ([
                        <PdfRow key={idx}>
                            <PdfCell cellLeft>{item.GDS_NM}</PdfCell>
                            <PdfCell style={styles.numberSort}>{numberFormat(item.TOTAL_OUT_QTY)}</PdfCell>
                            <PdfCell style={styles.numberSort}>{numberFormat(item.TEST_QTY)}</PdfCell>
                            <PdfCell style={styles.numberSort}>{numberFormat(item.HOME_OUT_QTY)}</PdfCell>
                            <PdfCell style={styles.numberSort}>{numberFormat(item.SALE_QTY)}</PdfCell>
                        </PdfRow>,
                        totalRow
                        ])
                    })}
                    <PdfRow>
                        <PdfCell cellLeft style={styles.footerCell}>총 계</PdfCell>
                        <PdfCell style={styles.numberSort}>{getTotal(gdsSaleData, 'TOTAL_OUT_QTY')}</PdfCell>
                        <PdfCell style={styles.numberSort}>{getTotal(gdsSaleData, 'TEST_QTY')}</PdfCell>
                        <PdfCell style={styles.numberSort}>{getTotal(gdsSaleData, 'HOME_OUT_QTY')}</PdfCell>
                        <PdfCell style={styles.numberSort}>{getTotal(gdsSaleData, 'SALE_QTY')}</PdfCell>
                    </PdfRow>
                </PdfTable>

                <PdfTable style={styles.tableMargin}>
                    <PdfRow>
                        <PdfCell cellTop cellLeft>결제 유형 별 판매</PdfCell>
                    </PdfRow>
                    <PdfRow fixed>
                        <PdfCell style={styles.headerCell} cellLeft>명칭</PdfCell>
                        <PdfCell style={styles.headerCell}>수량</PdfCell>
                        <PdfCell style={styles.headerCell}>금액</PdfCell>
                    </PdfRow>
                    {payMentSumData.map((item, idx) => (
                    <PdfRow key={idx}>
                        <PdfCell cellLeft>{item.PAY_NM}</PdfCell>
                        <PdfCell style={styles.numberSort}>{numberFormat(item.QTY)}</PdfCell>
                        {item.PAY_NM === '현금' ?
                        <View style={{ flex: 1, flexDirection: 'row' }}>
                            <PdfCell style={styles.numberSort}>{numberFormat(item.SPLY_AMT)}</PdfCell>
                            <PdfCell style={styles.numberSort}>{numberFormat(item.VAT)}</PdfCell>
                            <PdfCell style={styles.numberSort}>{numberFormat(item.AMOUNT)}</PdfCell>
                        </View> : 
                        <PdfCell style={styles.numberSort}>{numberFormat(item.AMOUNT)}</PdfCell>
                        }
                    </PdfRow>
                    ))}
                    <PdfRow>
                            <PdfCell cellLeft style={styles.footerCell}>총 계</PdfCell>
                            <PdfCell style={styles.numberSort}>{getTotal(payMentSumData, 'QTY')}</PdfCell>
                            <PdfCell style={styles.numberSort}>{getTotal(payMentSumData, 'AMOUNT')}</PdfCell>
                    </PdfRow>
                </PdfTable>

                <PdfTable style={styles.tableMargin}>
                    <PdfRow fixed>
                        <PdfCell cellTop cellLeft>판 매</PdfCell>
                    </PdfRow>
                    <PdfRow fixed>
                        <PdfCell style={styles.headerCell} cellLeft>상품명</PdfCell>
                        <PdfCell style={styles.headerCell}>명칭</PdfCell>
                        <PdfCell style={styles.headerCell}>단가</PdfCell>
                        <PdfCell style={styles.headerCell}>수량</PdfCell>
                        <PdfCell style={styles.headerCell}>금액</PdfCell>
                    </PdfRow>
                    {paySaleData.map((item, idx) => {
                        let totalRow;
                        if (!paySaleData[idx + 1] || item['GDS_NM'] !== paySaleData[idx + 1]['GDS_NM']) {
                            const groupData = paySaleData.reduce((result, row) => (
                                row['GDS_NM'] === item['GDS_NM'] ?
                                [...result, row] :
                                result
                            ), [])
                            totalRow = (
                                <PdfRow>                                    
                                    <PdfCell cellLeft style={styles.colSpanCell}>소 계</PdfCell>
                                    <PdfCell/>
                                    <PdfCell style={styles.numberSort}>{getTotal(groupData, 'QTY')}</PdfCell>
                                    <PdfCell style={styles.numberSort}>{getTotal(groupData, 'AMOUNT')}</PdfCell>
                                </PdfRow>
                            )
                        }
                        return([
                            <PdfRow key={idx}>
                                <PdfCell cellLeft>{item.GDS_NM}</PdfCell>
                                <PdfCell>{item.PAY_NM}</PdfCell>
                                <PdfCell style={styles.numberSort}>{numberFormat(item.PRICE)}</PdfCell>
                                <PdfCell style={styles.numberSort}>{numberFormat(item.QTY)}</PdfCell>
                                <PdfCell style={styles.numberSort}>{numberFormat(item.AMOUNT)}</PdfCell>
                            </PdfRow>,
                            totalRow
                        ])
                    })}
                        <PdfRow>
                            <PdfCell cellLeft style={styles.colSpanCell}>총 계</PdfCell>
                            <PdfCell/>
                            <PdfCell style={styles.numberSort}>{getTotal(paySaleData, 'QTY')}</PdfCell>
                            <PdfCell style={styles.numberSort}>{getTotal(paySaleData, 'AMOUNT')}</PdfCell>
                        </PdfRow>
                </PdfTable>

                <PdfTable>
                    <PdfRow fixed>
                        <PdfCell cellTop cellLeft>상 품 수 불 현 황</PdfCell>
                    </PdfRow>
                    <PdfRow fixed>
                        <PdfCell style={styles.headerCell} cellLeft>상품명</PdfCell>
                        <PdfCell style={styles.headerCell}>전일재고</PdfCell>
                        <PdfCell style={styles.headerCell}>입고수량</PdfCell>
                        <PdfCell style={styles.headerCell}>판매수량</PdfCell>
                        <PdfCell style={styles.headerCell}>이관출고</PdfCell>
                        <PdfCell style={styles.headerCell}>금일재고</PdfCell>
                    </PdfRow>
                    {supplyData.map((item, idx) => (
                    <PdfRow key={idx}>
                        <PdfCell cellLeft>{item.GDS_NM}</PdfCell>
                        <PdfCell style={styles.numberSort}>{numberFormat(item.BEF_STOCK_QTY)}</PdfCell>
                        <PdfCell style={styles.numberSort}>{numberFormat(item.IN_QTY)}</PdfCell>
                        <PdfCell style={styles.numberSort}>{numberFormat(item.SALE_QTY)}</PdfCell>
                        <PdfCell style={styles.numberSort}>{numberFormat(item.OUT_QTY)}</PdfCell>
                        <PdfCell style={styles.numberSort}>{numberFormat(item.STOCK_QTY)}</PdfCell>
                    </PdfRow>
                    ))}
                    <PdfRow>
                        <PdfCell cellLeft style={styles.footerCell}>총 계</PdfCell>
                        <PdfCell style={styles.numberSort}>{getTotal(supplyData, 'BEF_STOCK_QTY')}</PdfCell>
                        <PdfCell style={styles.numberSort}>{getTotal(supplyData, 'IN_QTY')}</PdfCell>
                        <PdfCell style={styles.numberSort}>{getTotal(supplyData, 'SALE_QTY')}</PdfCell>
                        <PdfCell style={styles.numberSort}>{getTotal(supplyData, 'OUT_QTY')}</PdfCell>
                        <PdfCell style={styles.numberSort}>{getTotal(supplyData, 'STOCK_QTY')}</PdfCell>
                    </PdfRow>
                </PdfTable>
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </PdfPage>

            <PdfPage>
                <Text style={styles.titleSort}>{workName} 계기</Text>
                <Text style={{ fontSize : 12 }}> 일자 : {date} </Text>
                <PdfTable style={{ marginTop : 8 }}>
                    <PdfRow fixed>
                        <PdfCell style={styles.headerCell} cellTop cellLeft>상품</PdfCell>
                        <PdfCell style={styles.headerCell} cellTop>계기</PdfCell>
                        <PdfCell style={styles.headerCell} cellTop>전일계기</PdfCell>
                        <PdfCell style={styles.headerCell} cellTop>금일계기</PdfCell>
                        <PdfCell style={styles.headerCell} cellTop>총 계기</PdfCell>
                        <PdfCell style={styles.headerCell} cellTop>(-)검량</PdfCell>
                        <PdfCell style={styles.headerCell} cellTop>(-)홈로리</PdfCell>
                        <PdfCell style={styles.headerCell} cellTop>판매계기</PdfCell>
                    </PdfRow>
                    {gaugeData.map((item, idx) => {
                        let totalRow;
                        if (!gaugeData[idx + 1] || item['GDS_NM'] !== gaugeData[idx + 1]['GDS_NM']) {
                            const groupData = gaugeData.reduce((result, row) => (
                                row['GDS_NM'] === item['GDS_NM'] ?
                                [...result, row] :
                                result
                            ), [])
                            totalRow = (
                                <PdfRow>                                    
                                    <PdfCell cellLeft style={styles.colSpanCell}>소 계</PdfCell>
                                    <PdfCell style={styles.numberSort}>{getTotal(groupData, 'BEF_GAUGE_ELE')}</PdfCell>
                                    <PdfCell style={styles.numberSort}>{getTotal(groupData, 'CUR_GAUGE_ELE')}</PdfCell>
                                    <PdfCell style={styles.numberSort}>{getTotal(groupData, 'GAUGE_ELE')}</PdfCell>
                                    <PdfCell style={styles.numberSort}>{getTotal(groupData, 'TEST_QTY')}</PdfCell>
                                    <PdfCell style={styles.numberSort}>{getTotal(groupData, 'HOME_QTY')}</PdfCell>
                                    <PdfCell style={styles.numberSort}>{getTotal(groupData, 'SALE_QTY')}</PdfCell>
                                </PdfRow>
                            )
                        }
                        return([
                        <PdfRow key={idx}>
                            <PdfCell cellLeft>{item.GDS_NM}</PdfCell>
                            <PdfCell>{item.NZL_NO}</PdfCell>
                            <PdfCell style={styles.numberSort}>{numberFormat(item.BEF_GAUGE_ELE)}</PdfCell>
                            <PdfCell style={styles.numberSort}>{numberFormat(item.CUR_GAUGE_ELE)}</PdfCell>
                            <PdfCell style={styles.numberSort}>{numberFormat(item.GAUGE_ELE)}</PdfCell>
                            <PdfCell style={styles.numberSort}>{numberFormat(item.TEST_QTY)}</PdfCell>
                            <PdfCell style={styles.numberSort}>{numberFormat(item.HOME_QTY)}</PdfCell>
                            <PdfCell style={styles.numberSort}>{numberFormat(item.SALE_QTY)}</PdfCell>
                        </PdfRow>,
                            totalRow
                        ])
                    })}
                    <PdfRow>                        
                        <PdfCell cellLeft style={styles.colSpanCell}>총 계</PdfCell>
                        <PdfCell style={styles.numberSort}>{getTotal(gaugeData, 'BEF_GAUGE_ELE')}</PdfCell>
                        <PdfCell style={styles.numberSort}>{getTotal(gaugeData, 'CUR_GAUGE_ELE')}</PdfCell>
                        <PdfCell style={styles.numberSort}>{getTotal(gaugeData, 'GAUGE_ELE')}</PdfCell>
                        <PdfCell style={styles.numberSort}>{getTotal(gaugeData, 'TEST_QTY')}</PdfCell>
                        <PdfCell style={styles.numberSort}>{getTotal(gaugeData, 'HOME_QTY')}</PdfCell>
                        <PdfCell style={styles.numberSort}>{getTotal(gaugeData, 'SALE_QTY')}</PdfCell>
                    </PdfRow>
                </PdfTable>
            </PdfPage>
        </Document>
    )
}

export default DailyReportPdf;