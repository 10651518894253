import React, { useEffect, useState } from 'react';
import { TextField, Button, Paper, makeStyles, Checkbox, FormControlLabel } from '@material-ui/core';
import { useFieldInputs, toHash } from '../common/Utils';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { ProgressPanel } from '../component';
import ReactIsCapsLockActive from '@matsun/reactiscapslockactive';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../redux/actions';
import release from '../releaseNote/erp.json';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        height: '100vh',
        backgroundImage: 'url(login_back.jpg)',
    },
    overlay: {
        display: 'flex',
        flex: 1,
        height: '100vh',
        backgroundColor: 'rgba(50, 50, 90, 0.7)',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        flexDirection: 'column',
        display: 'flex',
        width: '80%',
        maxWidth: 250,
        padding: theme.spacing(4),
        textAlign: 'center',
    },
    header: {
        position: 'absolute',
        top: '10vh',
        color: 'white',
    },
    version: {
        position: 'absolute',
        bottom: 8,
        right: 8,
    }
}))

const Login = () => {
    const [input, setInput] = useFieldInputs({
        ID: localStorage.getItem('LOGIN_RMB_ID') || '',
        PASSWORD: '',
    });
    const [rmbId, setRmbId] = useState(localStorage.getItem('LOGIN_RMB_ID') != null);
    const [loading, setLoading] = useState(false);
    
    const loginState = useSelector(state => state.loginReducer);
    const dispatch = useDispatch();

    const history = useHistory();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => { dispatch(actions.fetchConfig()) }, [dispatch])

    useEffect(() => {
        if (loginState['ID']) {
            if (rmbId) {
                localStorage['LOGIN_RMB_ID'] = input['ID'];
            } else {
                localStorage.removeItem('LOGIN_RMB_ID');
            }
            history.push('/')
        }
    }, [loginState, history, rmbId, input])

    const valid = () => {
        if (input['ID'] === '') {
            enqueueSnackbar('아이디를 입력하여 주세요', { variant: 'error' });
            return false;
        }
        if (input['PASSWORD'] === '') {
            enqueueSnackbar('비밀번호를 입력하여 주세요', { variant: 'error' });
            return false;
        }
        return true;
    }

    const requestLogin = async() => {
        setLoading(true);

        if (valid()) {
            dispatch(actions.reqLogin(
                input['ID'],
                toHash(input['PASSWORD']),
                () => enqueueSnackbar('아이디 또는 비밀번호를 확인하여 주세요', { variant: 'error' })
            ));
        }

        setLoading(false);
    }

    const onFieldKey = e => {
        if (e.key === 'Enter') {
            requestLogin();
        }
    }

    return (
        <div className={classes.container}>
            <div className={classes.overlay}>
                <h1 className={classes.header}>OIL Management<br/>ERP System</h1>
                <Paper className={classes.paper}>
                    <h3>Login</h3>
                    <TextField
                        label='아이디'
                        value={input['ID']}
                        onChange={e => setInput('ID', e.target.value)}
                        onKeyPress={onFieldKey}
                        autoFocus={!rmbId}
                    />
                    <ReactIsCapsLockActive>
                        {isCapslock =>
                        <TextField
                            label='비밀번호'
                            type='password'
                            value={input['PASSWORD']}
                            onChange={e => setInput('PASSWORD', e.target.value)}
                            onKeyPress={onFieldKey}
                            autoFocus={rmbId}
                            helperText={isCapslock && 'CapsLock이 켜져 있습니다.'}
                        />}
                    </ReactIsCapsLockActive>
                    <FormControlLabel
                        label='아이디 기억하기'
                        control={
                            <Checkbox
                                checked={rmbId}
                                onChange={e => setRmbId(e.target.checked)}
                                color='primary'
                            />
                        }
                    />
                    {loading ? <ProgressPanel small /> :
                    <Button onClick={requestLogin}>
                        로그인
                    </Button>}
                </Paper>
                <div className={classes.version}>
                    v{release.version}
                </div>
            </div>
        </div>
    )
}

export default Login;