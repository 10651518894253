import React from 'react';
import moment from 'moment';
import { Document, StyleSheet, Text, View } from '@react-pdf/renderer';
import { PdfCell, PdfTable, PdfRow, PdfPage } from '../component';
import { numberFormat } from '../common/Utils';

const styles = StyleSheet.create({
    title: {
        fontSize : 16,
        textAlign : 'center',
    },
    header: {
        flex: 1,
        borderRight: 0,
        fontSize: 9,
    },
    texts: {
        flex: 1,
        borderRight: 0,
        borderBottom: 0,
        fontSize: 9,
    },
    sums: {
        borderRight: 0,
        backgroundColor: '#D3D3D3',
        fontSize: 9,
    },
    tabletitle:{
        marginTop: '10px',
        marginBottom: '3px',
    },
    middleSummaryNormalCell: {
        fontSize: 9,
        borderRight: 0, 
        textAlign: 'left',
        backgroundColor : 'lightyellow',
    },
    middleSummaryNumCell: {
        fontSize: 9,
        borderRight: 0, 
        textAlign: 'right',
        backgroundColor : 'lightyellow',
    },
    bottom: {
        position: 'absolute',
        bottom: 13,
        left: 25,
        right: 25,
    },
})


const SelfCarWashPdf = ({saleStockCarWash, 
                            stockCarWash,
                            bondpayCarWash, 
                            itemMixSolution, 
                            cautionMoney, 
                            itemSignCard,  
                            saleBondListDetailWash,
                            saleBondListWashExceptCardFee,
                            condition,
                            loginState, 
                            workName}) => {
    
    const getTotal = (data, field) => (
        numberFormat(data.reduce((result, item) => result + item[field], 0))
    )

    const getBondListDetails = () => (
        saleBondListDetailWash.reduce((result, item) => {
            if(!result[item.ACC_CD]) {
                result[item.ACC_CD] = [];
            }

            result[item.ACC_CD].push(item);

            return result;
        }, {})
    );

    const getSaleDetailRow = (item) => {
        const bondPayDetail = getBondListDetails() ;
        let rowData = [];
        //['0001','0023']
        const val = Object.keys(bondPayDetail).find(arr => {
                    if(arr === item['PAY_CD']) return arr;
                    else return false;
                });
        console.log(val);
        if(item['PAY_CD'] === val){
            rowData = (
            bondPayDetail[item['PAY_CD']].map((item, idx) => (
            <PdfRow key={idx}>
                <PdfCell style={{ ...styles.texts, textAlign: 'left', paddingLeft: 15}}>({item['GDS_TYPE_NM']})</PdfCell>
                <PdfCell style={{ ...styles.texts, textAlign: 'right', paddingRight: 7}}>{numberFormat(item['BEF_AMOUNT'])}</PdfCell>
                <PdfCell style={{ ...styles.texts, textAlign: 'right', paddingRight: 7}}>{numberFormat(item['SALE_AMOUNT'])}</PdfCell>
                <PdfCell style={{ ...styles.texts, textAlign: 'right', paddingRight: 7}}>{numberFormat(item['IN_AMOUNT'])}</PdfCell>
                <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_AMOUNT'])}</PdfCell>
            </PdfRow>)))
        }

        return rowData;
    }




    return (
        <Document>
            <PdfPage>
                <Text style={{ ...styles.title}}>셀프세차 집계표</Text>
                <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Text>{`인쇄기간: ${moment().format('YYYY-MM-DD HH:mm:ss')}`}</Text>
                    <Text>{`조회기간: ${condition['STA_DATE']} ~ ${condition['END_DATE']}`}</Text> 
                </View>
                <View style={{height: '10'}}>

                </View>                
                <Text style={{...styles.tabletitle}}>1. 판매 현황</Text>
                <PdfTable>
                    <PdfRow fixed> 
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'left'}}>상품명</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>판매금액</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>월판매금액</PdfCell>
                    </PdfRow>
                    {saleStockCarWash && saleStockCarWash.map((item, idx) =>(
                        <PdfRow key={idx}>
                            <PdfCell style={{ ...styles.texts, textAlign: 'left'}}>{item['GDS_NM']}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['RNG_SALE_AMT'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['MONTH_SALE_AMT'])}</PdfCell>
                        </PdfRow>
                    ))}
                    
                    <PdfRow>  
                        <PdfCell style={{ ...styles.sums, textAlign: 'left'}}>합계</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(saleStockCarWash, 'RNG_SALE_AMT')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(saleStockCarWash, 'MONTH_SALE_AMT')}</PdfCell>
                    </PdfRow>
                </PdfTable>

                <Text style={{...styles.tabletitle}}>2. 재고 현황</Text>
                <PdfTable>
                    <PdfRow fixed> 
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'left'}}>상품명</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>전기 재고</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>당기 입고</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>당기 출고</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>당기 재고</PdfCell>
                    </PdfRow>
                    {stockCarWash && stockCarWash.map((item, idx) =>(
                        <PdfRow key={idx}>
                            <PdfCell style={{ ...styles.texts, textAlign: 'left'}}>{item['GDS_NM']}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['BEF_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['IN_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['SALE_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_QTY'])}</PdfCell>
                        </PdfRow>
                    ))}
                    
                    <PdfRow>  
                        <PdfCell style={{ ...styles.sums, textAlign: 'left'}}>합계</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(stockCarWash, 'BEF_QTY')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(stockCarWash, 'IN_QTY')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(stockCarWash, 'SALE_QTY')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(stockCarWash, 'CUR_QTY')}</PdfCell>
                    </PdfRow>
                </PdfTable>

                <Text style={{...styles.tabletitle}}>3. 카드사 채권현황</Text>
                <PdfTable>
                    <PdfRow fixed> 
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'left'}}>거래처명</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>전기 미입금액</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>당기 발생금액</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>당기 입금액</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>당기 카드수수료</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>합계 금액</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>당기 미입금액</PdfCell>
                    </PdfRow>
                    {bondpayCarWash && bondpayCarWash.map((item, idx) =>(
                        [<PdfRow key={idx}>
                            <PdfCell style={{ ...styles.texts, textAlign: 'left'}}>{item['CST_NM']}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['BEF_NOT_IN_AMT'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_SALE_AMT'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_IN_AMT'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_COMM_AMT'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_IN_SUM_AMT'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_NOT_IN_AMT'])}</PdfCell>
                        </PdfRow>]
                    ))}
                    <View style={{borderBottom: 1}}></View>
                    <PdfRow>
                        <PdfCell style={{ ...styles.sums, textAlign: 'left'}}>합계</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(bondpayCarWash, 'BEF_NOT_IN_AMT')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(bondpayCarWash, 'CUR_SALE_AMT')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(bondpayCarWash, 'CUR_IN_AMT')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(bondpayCarWash, 'CUR_COMM_AMT')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(bondpayCarWash, 'CUR_IN_SUM_AMT')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(bondpayCarWash, 'CUR_NOT_IN_AMT')}</PdfCell>
                    </PdfRow>
                </PdfTable>

                <Text style={{...styles.tabletitle}}>4. 매출 시재 현황</Text>
                <PdfTable>
                    <PdfRow fixed> 
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'left'}}>구분</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>전기잔액</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>당기발생</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>당기입금</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>당기잔액</PdfCell>
                    </PdfRow>

                    {saleBondListWashExceptCardFee && saleBondListWashExceptCardFee.map((item, idx) => (
                        [<PdfRow key={idx}>
                            <PdfCell style={{ ...styles.texts, textAlign: 'left'}}>{item['PAY_NM']}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['BEF_AMOUNT'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['SALE_AMOUNT'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['IN_AMOUNT'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_AMOUNT'])}</PdfCell>
                        </PdfRow>,
                        getSaleDetailRow(item)]
                    ))}

                    <PdfRow>  
                        <PdfCell style={{ ...styles.sums, textAlign: 'left'}}>매출 합계</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(saleBondListWashExceptCardFee, 'BEF_AMOUNT')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(saleBondListWashExceptCardFee, 'SALE_AMOUNT')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(saleBondListWashExceptCardFee, 'IN_AMOUNT')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(saleBondListWashExceptCardFee, 'CUR_AMOUNT')}</PdfCell>
                    </PdfRow>
                </PdfTable>               

                <Text style={{...styles.tabletitle}}>5. 세차세제</Text>
                <PdfTable>
                    <PdfRow fixed> 
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'left'}}>상품명</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>전일재고</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>입고</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>출고</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>당일재고</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>실재고</PdfCell>

                    </PdfRow>
                    {itemMixSolution && itemMixSolution.map((item, idx) =>(
                        <PdfRow key={idx}>
                            <PdfCell style={{ ...styles.texts, textAlign: 'left'}}>{item['GDS_NM']}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['BEF_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_IN_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_OUT_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['REAL_QTY'])}</PdfCell>
                        </PdfRow>
                    ))}
                    <View style={{borderBottom: 1}}></View>
                </PdfTable>

                <Text style={{...styles.tabletitle}}>6. 보증금현황</Text>
                <PdfTable>
                    <PdfRow fixed> 
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'left'}}>거래처명</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>전일잔액</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>당일입금</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>당일지급</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>당일잔액</PdfCell>

                    </PdfRow>
                    {cautionMoney && cautionMoney.map((item, idx) =>(
                        <PdfRow key={idx}>
                            <PdfCell style={{ ...styles.texts, textAlign: 'left'}}>{item['GDS_NM']}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['BEF_AMT'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_IN_AMT'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_OUT_AMT'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_AMT'])}</PdfCell>
                        </PdfRow>
                    ))}
                    <View style={{borderBottom: 1}}></View>
                </PdfTable>

                <Text style={{...styles.tabletitle}}>7. 회원카드 현황</Text>
                <PdfTable>
                    <PdfRow fixed> 
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'left'}}>상품명</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>전일재고</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>입고</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>반환입고</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>출고</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>파기</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>기타출고</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>당일재고</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>실재고</PdfCell>

                    </PdfRow>
                    {itemSignCard && itemSignCard.map((item, idx) =>(
                        <PdfRow key={idx}>
                            <PdfCell style={{ ...styles.texts, textAlign: 'left'}}>{item['GDS_NM']}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['BEF_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_IN_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_RTN_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_OUT_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_DEST_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_ETC_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['REAL_QTY'])}</PdfCell>

                        </PdfRow>
                    ))}
                    <View style={{borderBottom: 1}}></View>
                </PdfTable>

                <Text style={{ ...styles.bottom, textAlign: 'left' }} fixed>
                    {workName} / {loginState['EMP_NM']}
                </Text>
                <Text
                    style={{ ...styles.bottom, textAlign: 'right'  }}
                    render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                    fixed
                />
            </PdfPage>
        </Document>
    )
}


export default SelfCarWashPdf;
