import React, { useState } from 'react';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DataColumn,} from '../../component';
import { makeStyles } from '@material-ui/core';
import { rewdType, YNType } from '../../common/Dictionary';

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
}))

const TransCarInfo = () => {
    const [condition, setCondition] = useState(null); 

    const classes = useStyles();

    return(
        <div className={classes.container}>
             <SearchPanel onSearch={(condition) => setCondition(condition)}>
                <SearchItem
                    fieldName='WORK_CD'
                    component={(state, dispatch) => (
                        <ComboBox
                            label='사업장명'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD' 
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
            </SearchPanel>

            <div className={classes.content}>
                <DataGrid
                    title='수송처 차량'
                    selectProc='SLT_TRANSCARINFO'
                    insertProc='INS_TRANSCARINFO'
                    updateProc='UPT_TRANSCARINFO'
                    deleteProc='DLT_TRANSCARINFO'
                    selectParam={condition}
                    onDataLoad={dataSet => {
                        dataSet.data = dataSet.data.map(row => ({
                            ...row,
                            GDS_CDS: JSON.parse(row['GDS_CDS'])
                        }));
                        return dataSet;
                    }}
                    horizonMode
                > 
                    <DataColumn
                        fieldName='WORK_CD'
                        title='사업장명'
                        value={(row)=>row['WORK_NM']}
                        defaultValue={condition && condition['WORK_CD']}
                        editable={(state, dispatch) => (
                            <ComboBox 
                                selectProc='SLT_WORKPLACEINFO_AUTH'
                                keyField='WORK_CD' 
                                valueField='WORK_NM'
                                value={state}
                                onChange={(value) => dispatch(value)} 
                                disabled={true}/>                                
                        )}
                    />
                    <DataColumn
                        fieldName='TSP_CAR_CD'
                        title='수송차량'
                        value={row => row['CAR_NO']}
                        editable={(state, dispatch) => (
                            <ComboBox
                                style={{width: 150}}
                                selectProc='SLT_TRANSCAR_COMM_COMBO'
                                keyField='TSP_CAR_CD'
                                valueField='CAR_NO'
                                value={state}
                                onChange={(value) => dispatch(value)}
                            />
                        )}
                    />
                    <DataColumn
                        fieldName='TELNO1'
                        editable={state => state}
                    />
                    <DataColumn
                        fieldName='CAR_OWN'
                        editable={state => state}
                    />
                    <DataColumn
                        fieldName='STC_QTY'
                        editable={state => state}
                    />
                    <DataColumn
                        fieldName='REWD_QTY'
                        editable={state => state}
                    />
                    <DataColumn
                        fieldName='GDS_CDS'
                        title='상품명'
                        value={(row)=>row['GDS_NMS']}
                        editable={(state, dispatch) => (
                            <ComboBox
                                selectProc='SLT_GOODSINFO_OIL'
                                selectParam={condition}
                                keyField='GDS_CD'
                                valueField='GDS_NM'
                                value={state}
                                onChange={value => dispatch(value)}
                                multiple
                            />
                        )}
                        
                    />
                    <DataColumn
                        fieldName='IN_TRN_TYPE'
                        title='매입운임요율적용구분'
                        value={(row, value) => YNType[value]}
                        editable={state => state}
                    />
                    <DataColumn
                        fieldName='REWD_TYPE'
                        title='보상구분'
                        value={(row, value) => rewdType[value]}
                        editable={state => state}
                    />
                    <DataColumn
                        fieldName='REMARK'
                        editable={state => state}
                    />
                    <DataColumn
                        fieldName='WORK_NM'
                        visible={false}
                    />
                    <DataColumn
                        fieldName='GDS_NMS'
                        visible={false}
                    />
                    <DataColumn
                        fieldName='CAR_NO'
                        visible={false}
                    />
                </DataGrid>
            </div>
        </div>
    )
}

export default TransCarInfo