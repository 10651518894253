import React, { useState } from 'react';
import moment from 'moment';
import { DataGrid, ComboBox, DatePicker, SearchPanel, SearchItem, DataColumn} from '../../component';
import {  makeStyles  } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
    searchPaper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    searchContainer: {
        flex: 1,
        display: 'inline-block',
        flexDirection: 'row',
    },
    searchItem: {
        display: 'inline-block',
        width: 200,
        marginRight: 100,
        marginBottom: theme.spacing(1),
    },
}))

const TaxInvoiceList = () => {
    const [condition, setCondition] = useState(null);

    const classes = useStyles();

    return(
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => {
                setCondition(condition)}}>
                <SearchItem
                    fieldName='WORK_CD'
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
            </SearchPanel>
            <div className={classes.content}>
                <DataGrid
                    title='계산서 데이터 조회'
                    selectProc='SLT_TAXINVOICEINFO_LIST'
                    selectParam={condition}
                    horizonMode
                    totalGroup={['GDS_NM']}
                    totalSort={(a, b) => a['GDS_NM'] < b['GDS_NM'] ? -1 : 1}
                    excelDownload={{ fileName: '계산서 데이터 조회.xlsx', sheetName: '계산서 데이터 조회' }}
                    virtualized
                >
                    <DataColumn fieldName='WORK_CD' visible={false} />
                    <DataColumn fieldName='GDS_NM' total={(values, data, group) => group == null ? '총계' : group['GDS_NM'] + ' 소계'} />
                    <DataColumn fieldName='QTY' total={(values) => values.reduce((result, value) => result + value, 0)} />
                    <DataColumn fieldName='SPLY_AMT' total={(values) => values.reduce((result, value) => result + value, 0)} />
                    <DataColumn fieldName='VAT' total={(values) => values.reduce((result, value) => result + value, 0)} />
                    <DataColumn fieldName='AMOUNT' total={(values) => values.reduce((result, value) => result + value, 0)} />
                </DataGrid>
            </div>
        </div>
    )
}

export default TaxInvoiceList