import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { Paper, makeStyles, Dialog, IconButton, DialogTitle, Tooltip } from '@material-ui/core';
import { ComboBox, DataColumn, DataGrid, DatePicker, PaperTitle, ProgressDialog, ProgressPanel } from '../../component';
import { callProc } from '../../common/DBConnector';
import { numberFormat, useFieldInputs } from '../../common/Utils';
import { cstType } from '../../common/Dictionary';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { useSnackbar } from 'notistack';

const blueBorderDark = '#5b9adf';
const blueTextDark = '#105dd6';
const blueBackDark = '#c6dbf7';
const blueBorder = '#c6cbde';
const blueText = '#4279ad';
const blueBack = '#f7f7ff'

const redBorderDark = '#f77573';
const redTextDark = '#ef1418';
const redBackDark = '#ffdbde';
const redBorder = '#f79e9c';
const redText = '#d04b50';
const redBack = '#fff6f7'

const grayBack = '#f8f8f8';
const grayBorder = '#ececec';

const toInt = (value) =>
    value != null && value.toString().trim() !== '' ? parseInt(value) : 0

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
    },
    paperContent: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(2),
        marginTop: 0,
        width: 960,
        alignSelf: 'center',
    },
    workInfo: {
        display: 'flex',
    },
    workTable: {
        borderSpacing: 0,
        width: 447,
        height: '100%',
    },
    redContent: {
        width: 480,
        display: 'flex',
        border: '2px solid ' + redBorderDark,
        borderLeft: 'none',
        borderRight: 'none',
    },
    redTableTitle: {
        display: 'flex',
        width: 33,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        backgroundColor: redBackDark,
        color: redTextDark,
        fontWeight: 700,
    },
    redHeaderCell: {
        backgroundColor: redBack,
        border: '1px solid ' + redBorder,
        borderTop: 'none',
        borderRight: 'none',
        color: redText,
    },
    redValueCell: {
        height: 32,
        border: '1px solid ' + redBorder,
        borderTop: 'none',
        borderRight: 'none',
        padding: '3px 10px',
        paddingRight: 22,
    },
    blueContent: {
        width: 480,
        display: 'flex',
        border: '2px solid ' + blueBorderDark,
        borderLeft: 'none',
        borderRight: 'none',
    },
    blueTableTitle: {
        display: 'flex',
        width: 33,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        backgroundColor: blueBackDark,
        color: blueTextDark,
        fontWeight: 700,
    },
    blueHeaderCell: {
        backgroundColor: blueBack,
        border: '1px solid ' + blueBorder,
        borderTop: 'none',
        borderRight: 'none',
        color: blueText,
    },
    blueValueCell: {
        height: 32,
        border: '1px solid ' + blueBorder,
        borderTop: 'none',
        borderRight: 'none',
        padding: '3px 10px',
        paddingRight: 22,
    },
    table: {
        borderSpacing: 0,
    },
    middleHeaderCell: {
        height: 32,
        padding: 0,
        paddingLeft: 23,
        textAlign: 'left',
        backgroundColor: grayBack,
        border: '1px solid ' + grayBorder,
        borderTop: 'none',
        borderLeft: 'none',
    },
    middleValueCell: {
        border: '1px solid ' + grayBorder,
        borderTop: 'none',
        borderLeft: 'none',
        padding: '3px 10px',
        paddingRight: 22,
    },
    bottomTable: {
        borderSpacing: 0,
        border: '1px solid',
        borderLeft: 'none',
        borderRight: 'none',
    },
    bottomHeaderCell: {
        height: 32,
        backgroundColor: grayBack,
        borderRight: '1px solid ' + grayBorder,
        borderBottom: '1px solid #999',
    },
    bottomValueCell: {
        height: 32,
        border: '1px solid ' + grayBorder,
        borderTop: 'none',
        borderLeft: 'none',
        paddingLeft: 5.5,
        paddingRight: 5.5,
    },
    edgeCell: {
        borderRight: 'none',
    },
    btnContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(1),
    }
}))

const TaxInvoiceEnroll = () => {
    const [workData, setWorkData] = useState();
    const [workCd, setWorkCd] = useState();
    const [insData, setInsData, initInsData] = useFieldInputs({
        TAX_TYPE: '01',
        PBL_TYPE: '02',
        WRT_DATE: moment().format('YYYY-MM-DD'),
    });

    const [cstDialogOpen, setCstDialogOpen] = useState(false);
    const [calcDialogIdx, setCalcDialogIdx] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async() => {
            const workData = (await callProc('SLT_WORKPLACEINFO_PRNT_AUTH'))
                .data.reduce((result, item) => ({
                    ...result,
                    [item['WORK_CD']]: item,
                }), {});
            setWorkData(workData);
            setWorkCd(Object.keys(workData)[0]);
        }
        fetchData();
    }, [])

    const workCombo = useMemo(() => workData && Object.values(workData), [workData]);

    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const onCstSelect = (row) => {
        if (row) {
            initInsData();
            setInsData('CST_BIZNO', row['WORK_BIZNO']);
            setInsData('CST_NM', row['CST_NM']);
            setInsData('CST_CEO', row['CEO']);
            setInsData('CST_ADDR', (row['ADDR'] ? row['ADDR'] : '') + ' ' + (row['ADDR_DET'] ? row['ADDR_DET'] : ''));
            setInsData('CST_COND', row['BIZ_COND']);
            setInsData('CST_ITEM', row['BIZ_ITEM']);
            setInsData('CST_EMAIL1', row['EMAIL']);
            setInsData('CST_EMAIL2', '');
            setCstDialogOpen(false);
        }
    }

    const calcItmPrice = field => {
        let result = 0;
        for (let i = 1; i <= 4; i++) {
            result += toInt(insData[field + i]);
        }
        return result;
    }

    const hasValue = value => value != null && value.toString().trim() !== '';

    const isRowActive = (idx) => {
        if (idx === 1) return true;

        return (
            hasValue(insData['TRD_DATE' + idx]) ||
            hasValue(insData['ITM_NM' + idx]) ||
            hasValue(insData['UNIT' + idx]) ||
            hasValue(insData['QTY' + idx]) ||
            hasValue(insData['PRICE' + idx]) ||
            hasValue(insData['SPLY_AMT' + idx]) ||
            hasValue(insData['VAT' + idx]) ||
            hasValue(insData['ITM_REMARK' + idx])
        )
    }

    const valid = () => {
        if (!hasValue(workData[workCd] && workData[workCd]['WORK_BIZNO'])) {
            enqueueSnackbar('공급자 등록번호는 필수항목 입니다.', { variant: 'error' });
            return false;
        }
        if (!hasValue(workData[workCd] && workData[workCd]['CEO'])) {
            enqueueSnackbar('공급자 성명은 필수항목 입니다.', { variant: 'error' });
            return false;
        }
        if (!hasValue(insData['CST_BIZNO'])) {
            enqueueSnackbar('공급받는자 등록번호는 필수항목 입니다.', { variant: 'error' });
            return false;
        }
        if (!hasValue(insData['CST_NM'])) {
            enqueueSnackbar('공급받는자 상호는 필수항목 입니다.', { variant: 'error' });
            return false;
        }
        if (!hasValue(insData['CST_CEO'])) {
            enqueueSnackbar('공급받는자 성명은 필수항목 입니다.', { variant: 'error' });
            return false;
        }

        for (let i = 1; i <= 4; i++) {
            if (isRowActive(i)) {
                if (!hasValue(insData['TRD_DATE' + i])) {
                    enqueueSnackbar('일자는 필수항목 입니다.', { variant: 'error' });
                    return false;
                }
                if (!hasValue(insData['SPLY_AMT' + i])) {
                    enqueueSnackbar('공급가액은 필수항목 입니다.', { variant: 'error' });
                    return false;
                }
                if (!hasValue(insData['VAT' + i])) {
                    enqueueSnackbar('세액은 필수항목 입니다.', { variant: 'error' });
                    return false;
                }
            }
        }
        return true;
    }

    const onSave = async() => {
        setLoading(true);

        if (valid()) {
            const result = await callProc('INS_TAXINVOICEINFO', { WORK_CD: workCd, ...insData });
            if (!result.err) {
                initInsData();
                enqueueSnackbar('저장이 완료되었습니다.');
            }
        }

        setLoading(false);
    }

    const onBizKeyPress = async(e) => {
        if (e.key === 'Enter') {
            setLoading(true);

            const result = (await callProc('SLT_CST_BIZNO', { CST_BIZNO: insData['CST_BIZNO'] })).data;
            if (result.length) {
                setInsData({ type: 'UPDATE', value: result[0] });
            }

            setLoading(false);
        }
    }

    return (
        !workData ? <ProgressPanel /> :
        <Paper className={classes.paper}>
            <ProgressDialog open={loading} />
            <CstDialog
                open={cstDialogOpen}
                onClose={() => setCstDialogOpen(false)}
                workCd={workCd}
                onCstSelect={onCstSelect}
            />
            <CalcDialog
                open={calcDialogIdx != null}
                onClose={() => setCalcDialogIdx(null)}
                splyAmt={insData['SPLY_AMT' + calcDialogIdx]}
                vat={insData['VAT' + calcDialogIdx]}
                setSplyAmt={(value) => setInsData('SPLY_AMT' + calcDialogIdx, value)}
                setVat={(value) => setInsData('VAT' + calcDialogIdx, value)}
            />
            <PaperTitle>
                세금계산서 발행
            </PaperTitle>
            <div className={classes.paperContent}>
                <table
                    className={classes.table}
                    style={{ width: '50%', borderTop: '1px solid ' + grayBorder }}
                >
                    <tbody>
                        <tr>
                            <th className={classes.middleHeaderCell}>
                                사업장
                            </th>
                            <td className={classes.middleValueCell}>
                                <ComboBox
                                    data={workCombo}
                                    keyField='WORK_CD'
                                    valueField='WORK_NM'
                                    value={workCd}
                                    onChange={value => {
                                        setWorkCd(value);
                                        initInsData();
                                    }}
                                    InputProps={{ style: { fontSize: '14px' } }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className={classes.middleHeaderCell}>
                                종류
                            </th>
                            <td className={classes.middleValueCell}>
                                <RadioItem
                                    label='일반'
                                    checked={insData['TAX_TYPE'] === '01'}
                                    onChange={() => setInsData('TAX_TYPE', '01')}
                                    style={{ display: 'inline' }}
                                />
                                <RadioItem
                                    label='영세율'
                                    checked={insData['TAX_TYPE'] === '02'}
                                    onChange={() => setInsData('TAX_TYPE', '02')}
                                    style={{ display: 'inline' }}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className={classes.workInfo}>
                    <div className={classes.redContent}>
                        <div className={classes.redTableTitle}>
                            공<br/>급<br/>자
                        </div>
                        <table className={classes.workTable}>
                            <colgroup>
                                <col style={{ width: '15%' }} />
                                <col style={{ width: '36%' }} />
                                <col style={{ width: '17%' }} />
                                <col/>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th className={classes.redHeaderCell}>
                                        등록번호
                                    </th>
                                    <td className={classes.redValueCell}>
                                        <Input
                                            value={workData[workCd] && workData[workCd]['WORK_BIZNO']}
                                            disabled
                                        />
                                    </td>
                                    <th className={classes.redHeaderCell}>
                                        종사업장<br/>
                                        번호
                                    </th>
                                    <td className={classes.redValueCell}>
                                    </td>
                                </tr>
                                <tr>
                                    <th className={classes.redHeaderCell}>
                                        상호
                                    </th>
                                    <td className={classes.redValueCell}>
                                        <Input
                                            value={workData[workCd] && (workData[workCd]['WORK_PRNT_NM'] || workData[workCd]['WORK_NM'])}
                                            disabled
                                        />
                                    </td>
                                    <th className={classes.redHeaderCell}>
                                        성명
                                    </th>
                                    <td className={classes.redValueCell}>
                                        <Input
                                            value={workData[workCd] && workData[workCd]['CEO']}
                                            disabled
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th className={classes.redHeaderCell}>
                                        사업장
                                    </th>
                                    <td className={classes.redValueCell} colSpan={3}>
                                        <Input
                                            value={(workData[workCd] && workData[workCd]['ADDR'] ? workData[workCd]['ADDR'] : '') +
                                                ' ' +
                                                (workData[workCd] && workData[workCd]['ADDR_DET'] ? workData[workCd]['ADDR_DET'] : '')}
                                            disabled
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th className={classes.redHeaderCell}>
                                        업태
                                    </th>
                                    <td className={classes.redValueCell}>
                                        <Input
                                            value={workData[workCd] && workData[workCd]['BIZ_COND']}
                                            disabled
                                        />
                                    </td>
                                    <th className={classes.redHeaderCell}>
                                        종목
                                    </th>
                                    <td className={classes.redValueCell}>
                                        <Input
                                            value={workData[workCd] && workData[workCd]['BIZ_ITEM']}
                                            disabled
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th className={classes.redHeaderCell} style={{ height: 64 }}>
                                        이메일
                                    </th>
                                    <td className={classes.redValueCell} colSpan={3}>
                                        <Input
                                            value={workData[workCd] && workData[workCd]['EMAIL']}
                                            disabled
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className={classes.blueContent}>
                        <div className={classes.blueTableTitle}>
                            공<br/>급<br/>받<br/>는<br/>자
                        </div>
                        <table className={classes.workTable}>
                            <colgroup>
                                <col style={{ width: '15%' }} />
                                <col style={{ width: '36%' }} />
                                <col style={{ width: '17%' }} />
                                <col/>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th className={classes.blueHeaderCell}>
                                        등록번호
                                    </th>
                                    <td className={classes.blueValueCell} style={{ paddingRight: 0 }}>
                                        <Input
                                            value={insData['CST_BIZNO']}
                                            style={{ textAlign: 'start' }}
                                            type='number'
                                            onChange={value => setInsData('CST_BIZNO', value)}
                                            width={95}
                                            maxLength={13}
                                            onKeyPress={onBizKeyPress}
                                        />
                                        <Button
                                            value='조회'
                                            onClick={() => setCstDialogOpen(true)}
                                            style={{ marginLeft: 5 }}
                                        />
                                    </td>
                                    <th className={classes.blueHeaderCell}>
                                        종사업장<br/>
                                        번호
                                    </th>
                                    <td className={classes.blueValueCell}>
                                    </td>
                                </tr>
                                <tr>
                                    <th className={classes.blueHeaderCell}>
                                        상호
                                    </th>
                                    <td className={classes.blueValueCell}>
                                        <Input
                                            value={insData['CST_NM']}
                                            onChange={value => setInsData('CST_NM', value)}
                                            maxLength={15}
                                        />
                                    </td>
                                    <th className={classes.blueHeaderCell}>
                                        성명
                                    </th>
                                    <td className={classes.blueValueCell}>
                                        <Input
                                            value={insData['CST_CEO']}
                                            onChange={value => setInsData('CST_CEO', value)}
                                            maxLength={10}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th className={classes.blueHeaderCell}>
                                        사업장
                                    </th>
                                    <td className={classes.blueValueCell} colSpan={3}>
                                        <Input
                                            value={insData['CST_ADDR']}
                                            onChange={value => setInsData('CST_ADDR', value)}
                                            maxLength={100}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th className={classes.blueHeaderCell}>
                                        업태
                                    </th>
                                    <td className={classes.blueValueCell}>
                                        <Input
                                            value={insData['CST_COND']}
                                            onChange={value => setInsData('CST_COND', value)}
                                            maxLength={50}
                                        />
                                    </td>
                                    <th className={classes.blueHeaderCell}>
                                        종목
                                    </th>
                                    <td className={classes.blueValueCell}>
                                        <Input
                                            value={insData['CST_ITEM']}
                                            onChange={value => setInsData('CST_ITEM', value)}
                                            maxLength={50}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th className={classes.blueHeaderCell}>
                                        이메일
                                    </th>
                                    <td className={classes.blueValueCell} colSpan={3}>
                                        <Input
                                            value={insData['CST_EMAIL1']}
                                            onChange={value => setInsData('CST_EMAIL1', value)}
                                            maxLength={50}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th className={classes.blueHeaderCell}>
                                        이메일
                                    </th>
                                    <td className={classes.blueValueCell} colSpan={3}>
                                        <Input
                                            value={insData['CST_EMAIL2']}
                                            onChange={value => setInsData('CST_EMAIL2', value)}
                                            maxLength={50}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <table className={classes.table}>
                    <colgroup>
                        <col style={{ width: '15%' }} />
                        <col style={{ width: '35%' }} />
                        <col style={{ width: '15%' }} />
                        <col/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className={classes.middleHeaderCell}>
                                작성일자
                            </th>
                            <td className={classes.middleValueCell}>
                                <DatePicker
                                    value={insData['WRT_DATE']}
                                    onChange={value => setInsData('WRT_DATE', value)}
                                    InputProps={{ style: { fontSize: '14px' } }}
                                />
                            </td>
                            <th className={classes.middleHeaderCell}>
                                비고
                            </th>
                            <td className={clsx(classes.middleValueCell, classes.edgeCell)}>
                                <Input
                                    value={insData['REMARK']}
                                    onChange={value => setInsData('REMARK', value)}
                                    maxLength={150}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className={classes.table}>
                    <colgroup>
                        <col style={{ width: '15%' }} />
                        <col style={{ width: '20%' }} />
                        <col style={{ width: '15%' }} />
                        <col style={{ width: '18%' }} />
                        <col style={{ width: '15%' }} />
                        <col/>
                        <col/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className={classes.middleHeaderCell}>
                                합계금액
                            </th>
                            <td
                                className={classes.middleValueCell}
                                style={{ textAlign: 'end' }}
                            >
                                {numberFormat(calcItmPrice('SPLY_AMT') + calcItmPrice('VAT'))}
                            </td>
                            <th className={classes.middleHeaderCell}>
                                공급가액
                            </th>
                            <td
                                className={classes.middleValueCell}
                                style={{ textAlign: 'end' }}
                            >
                                {numberFormat(calcItmPrice('SPLY_AMT'))}
                            </td>
                            <th className={classes.middleHeaderCell}>
                                세액
                            </th>
                            <td
                                className={clsx(classes.middleValueCell, classes.edgeCell)}
                                style={{ textAlign: 'end' }}
                            >
                                {numberFormat(calcItmPrice('VAT'))}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className={classes.bottomTable}>
                    <colgroup>
                        <col style={{ width: '4%' }} />
                        <col style={{ width: '4%' }} />
                        <col/>
                        <col style={{ width: '8%' }} />
                        <col style={{ width: '6%' }} />
                        <col style={{ width: '11%' }} />
                        <col style={{ width: '5%' }} />
                        <col style={{ width: '11%' }} />
                        <col style={{ width: '11%' }} />
                        <col style={{ width: '9%' }} />
                        <col style={{ width: '5%' }} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className={classes.bottomHeaderCell}>월</th>
                            <th className={classes.bottomHeaderCell}>일</th>
                            <th className={classes.bottomHeaderCell}>품목</th>
                            <th className={classes.bottomHeaderCell}>규격</th>
                            <th className={classes.bottomHeaderCell}>수량</th>
                            <th className={classes.bottomHeaderCell}>단가</th>
                            <th className={classes.bottomHeaderCell}>합계</th>
                            <th className={classes.bottomHeaderCell}>공급가액</th>
                            <th className={classes.bottomHeaderCell}>세액</th>
                            <th className={classes.bottomHeaderCell}>비고</th>
                            <th className={clsx(classes.bottomHeaderCell, classes.edgeCell)}>삭제</th>
                        </tr>
                        <ItmRow
                            idx={1}
                            insData={insData}
                            setInsData={setInsData}
                            setCalcDialogIdx={setCalcDialogIdx}
                        />
                        <ItmRow
                            idx={2}
                            insData={insData}
                            setInsData={setInsData}
                            setCalcDialogIdx={setCalcDialogIdx}
                        />
                        <ItmRow
                            idx={3}
                            insData={insData}
                            setInsData={setInsData}
                            setCalcDialogIdx={setCalcDialogIdx}
                        />
                        <ItmRow
                            idx={4}
                            insData={insData}
                            setInsData={setInsData}
                            setCalcDialogIdx={setCalcDialogIdx}
                        />
                    </tbody>
                </table>
                <div style={{ display: 'flex' }}>
                    <table className={classes.table} style={{ flex: 1 }}>
                        <tbody>
                            <tr>
                                <th className={classes.bottomHeaderCell}>현금</th>
                                <th className={classes.bottomHeaderCell}>수표</th>
                                <th className={classes.bottomHeaderCell}>어음</th>
                                <th className={classes.bottomHeaderCell}>외상미수금</th>
                            </tr>
                            <tr>
                                <td className={classes.bottomValueCell}>
                                    <Input
                                        type='number'
                                        value={insData['CASH']}
                                        onChange={value => setInsData('CASH', value)}
                                    />
                                </td>
                                <td className={classes.bottomValueCell}>
                                    <Input
                                        type='number'
                                        value={insData['CHECK']}
                                        onChange={value => setInsData('CHECK', value)}
                                    />
                                </td>
                                <td className={classes.bottomValueCell}>
                                    <Input
                                        type='number'
                                        value={insData['BILL']}
                                        onChange={value => setInsData('BILL', value)}
                                    />
                                </td>
                                <td className={classes.bottomValueCell}>
                                    <Input
                                        type='number'
                                        value={insData['CREDIT']}
                                        onChange={value => setInsData('CREDIT', value)}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div
                        className={clsx(classes.bottomValueCell, classes.edgeCell)}
                        style={{
                            display: 'inline-flex',
                            width: '32%',
                            height: 'auto',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        이 금액을 (
                        <div>
                            <RadioItem
                                label='청구'
                                checked={insData['PBL_TYPE'] === '02'}
                                onChange={() => {
                                    setInsData('PBL_TYPE', '02')
                                }}
                            />
                            <RadioItem
                                label='영수'
                                checked={insData['PBL_TYPE'] === '01'}
                                onChange={() => {
                                    setInsData('PBL_TYPE', '01')
                                }}
                            />
                        </div>
                        ) 함
                    </div>
                </div>
                <div className={classes.btnContainer}>
                    <Tooltip title='확인'>
                        <IconButton onClick={onSave}>
                            <CheckIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        </Paper>
    )
}

const useCstDialogStyles = makeStyles(theme => ({
    paper: {
        maxWidth: 'none',
        width: '60%',
        height: '50%',
    }
}));

const CstDialog = ({ open, onClose, workCd, onCstSelect }) => {
    const classes = useCstDialogStyles();
    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{ className: classes.paper }} 
        >
            <DataGrid
                title='거래처'
                headerItem={() => (
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                )}
                selectProc='SLT_CUSTOMERINFO'
                selectParam={{ WORK_CD: workCd, CST_TYPE: 1 }}
                onSelect={row => onCstSelect(row)}
                horizonMode
            >
                <DataColumn
                    fieldName='CST_PAYNM'
                    title='결제수단'
                />
                <DataColumn
                    fieldName='CST_TYPE'
                    value={(row, value) => cstType[value]}
                />
                <DataColumn fieldName='CST_CD' visible={false} />
                <DataColumn fieldName='WORK_CD' visible={false} />
                <DataColumn fieldName='CST_PAYCD' visible={false} />
                <DataColumn fieldName='MAIN_CST' visible={false} />
                <DataColumn fieldName='CST_GROUP' visible={false} />
            </DataGrid>
        </Dialog>
    )
}

const useCalcDialogStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        height: 250,
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    dialogContent: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: theme.spacing(2),
        paddingTop: 0,
    },
    table: {
        borderSpacing: 0,
        width: 550,
        borderTop: '1px solid #c5cfd8',
    },
    headerCell: {
        height: 32,
        padding: 0,
        paddingLeft: 23,
        textAlign: 'left',
        backgroundColor: '#e9edf6',
        border: '1px solid #c5cfd8',
        borderTop: 0,
        borderLeft: 0,
    },
    valueCell: {
        border: '1px solid #c5cfd8',
        borderTop: 0,
        borderLeft: 0,
        paddingRight: 32
    },
    btnContainer: {
        display: 'flex',
        marginTop: 'auto',
        justifyContent: 'center',
    },
    edgeCell: {
        borderRight: 'none',
    },
}));

const CalcDialog = ({ open, onClose, splyAmt, vat, setSplyAmt, setVat }) => {
    const [calcData, setCalcData] = useFieldInputs();

    useEffect(() => {
        const totalAmt = toInt(splyAmt) + toInt(vat);
        setCalcData('SPLY_AMT', toInt(splyAmt));
        setCalcData('VAT', toInt(vat));
        setCalcData('TOTAL_AMT', totalAmt);
    }, [setCalcData, splyAmt, vat, open])

    const classes = useCalcDialogStyles();

    const onCalc = () => {
        const totalAmt = toInt(calcData['TOTAL_AMT']);
        setCalcData('SPLY_AMT', Math.round(totalAmt / 1.1));
        setCalcData('VAT', Math.round(totalAmt / 11));
    }

    const onConfirm = () => {
        setSplyAmt(toInt(calcData['SPLY_AMT']));
        setVat(toInt(calcData['VAT']));
        onClose();
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{ className: classes.paper }} 
        >
            <div className={classes.dialogTitle}>
                <DialogTitle>
                    공급가액, 세액 계산
                </DialogTitle>
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </div>
            <div className={classes.dialogContent}>
                <table className={classes.table}>
                    <colgroup>
                        <col style={{ width: '20%' }} />
                        <col style={{ width: '30%' }} />
                        <col style={{ width: '20%' }} />
                        <col/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className={classes.headerCell}>합계</th>
                            <td className={clsx(classes.valueCell, classes.edgeCell)} colspan={3}>
                                <Input
                                    type='number'
                                    value={calcData['TOTAL_AMT']}
                                    onChange={value => setCalcData('TOTAL_AMT', value)}
                                    onKeyPress={e => e.key === 'Enter' && onCalc()}
                                    width={131}
                                />
                                <Button
                                    value='계산'
                                    onClick={onCalc}
                                    style={{ marginLeft: 8 }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className={classes.headerCell}>공급가액</th>
                            <td className={classes.valueCell}>
                                <Input
                                    type='number'
                                    value={calcData['SPLY_AMT']}
                                    onChange={value => setCalcData('SPLY_AMT', value)}
                                />
                            </td>
                            <th className={classes.headerCell}>세액</th>
                            <td className={clsx(classes.valueCell, classes.edgeCell)}>
                                <Input
                                    type='number'
                                    value={calcData['VAT']}
                                    onChange={value => setCalcData('VAT', value)}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className={classes.btnContainer}>
                    <Button
                        value='취소'
                        onClick={onClose}
                    />
                    <Button
                        value='확인'
                        onClick={onConfirm}
                        buttonColor='#4173b1'
                        style={{ marginLeft: 8 }}
                    />
                </div>
            </div>
        </Dialog>
    )
}

const useItmStyles = makeStyles(theme => ({
    valueCell: {
        height: 32,
        border: '1px solid ' + grayBorder,
        borderTop: 'none',
        borderLeft: 'none',
        paddingLeft: 5.5,
        paddingRight: 5.5,
    },
    edgeCell: {
        borderRight: 'none',
    },
}))

const ItmRow = ({ idx, insData, setInsData, setCalcDialogIdx }) => {
    const classes = useItmStyles();

    const hasValue = (field) =>
            insData[field + idx] != null &&
            insData[field + idx].trim() !== '';

    const isMonthVisible = () => {
        if (idx === 1) return true;

        return (
            hasValue('TRD_DATE') ||
            hasValue('ITM_NM') ||
            hasValue('UNIT') ||
            hasValue('QTY') ||
            hasValue('PRICE') ||
            hasValue('SPLY_AMT') ||
            hasValue('VAT') ||
            hasValue('ITM_REMARK')
        )
    }

    const clear = () => {
        setInsData('TRD_DATE' + idx, '');
        setInsData('ITM_NM' + idx, '');
        setInsData('UNIT' + idx, '');
        setInsData('QTY' + idx, '');
        setInsData('PRICE' + idx, '');
        setInsData('SPLY_AMT' + idx, '');
        setInsData('VAT' + idx, '');
        setInsData('ITM_REMARK' + idx, '');
    }

    const onPriceChange = () => {
        if (hasValue('QTY') && hasValue('PRICE')) {
            const totalPrice = insData['QTY' + idx] * insData['PRICE' + idx];
            setInsData('SPLY_AMT' + idx, Math.round(totalPrice / 1.1));
            setInsData('VAT' + idx, Math.round(totalPrice / 11));
        } else {
            setInsData('SPLY_AMT' + idx, '');
            setInsData('VAT' + idx, '');
        }
    }

    return (
        <tr>
            <td className={classes.valueCell}>
                <Input
                    value={isMonthVisible() ? insData['WRT_DATE'] && insData['WRT_DATE'].substring(5, 7) : ''}
                    textAlign='center'
                    disabled
                />
            </td>
            <td className={classes.valueCell}>
                <Input
                    value={insData['TRD_DATE' + idx]}
                    onChange={value => setInsData('TRD_DATE' + idx, value)}
                    textAlign='center'
                    maxLength={2}
                />
            </td>
            <td className={classes.valueCell}>
                <Input
                    value={insData['ITM_NM' + idx]}
                    onChange={value => setInsData('ITM_NM' + idx, value)}
                    maxLength={100}
                />
            </td>
            <td className={classes.valueCell}>
                <Input
                    value={insData['UNIT' + idx]}
                    onChange={value => setInsData('UNIT' + idx, value)}
                    maxLength={60}
                    textAlign='center'
                />
            </td>
            <td className={classes.valueCell}>
                <Input
                    type='number'
                    value={insData['QTY' + idx]}
                    onChange={value => setInsData('QTY' + idx, value)}
                    onBlur={onPriceChange}
                />
            </td>
            <td className={classes.valueCell}>
                <Input
                    type='number'
                    value={insData['PRICE' + idx]}
                    onChange={value => setInsData('PRICE' + idx, value)}
                    onBlur={onPriceChange}
                />
            </td>
            <td className={classes.valueCell}>
                <Button
                    value='계산'
                    onClick={() => setCalcDialogIdx(idx)}
                />
            </td>
            <td className={classes.valueCell}>
                <Input
                    type='number'
                    value={insData['SPLY_AMT' + idx]}
                    onChange={value => setInsData('SPLY_AMT' + idx, value)}
                />
            </td>
            <td className={classes.valueCell}>
                <Input
                    type='number'
                    value={insData['VAT' + idx]}
                    onChange={value => setInsData('VAT' + idx, value)}
                />
            </td>
            <td className={classes.valueCell}>
                <Input
                    value={insData['ITM_REMARK' + idx]}
                    onChange={value => setInsData('ITM_REMARK' + idx, value)}
                />
            </td>
            <td className={clsx(classes.valueCell, classes.edgeCell)}>
                <Button
                    value='삭제'
                    onClick={clear}
                />
            </td>
        </tr>
    )
}

const useInputStyles = makeStyles(theme => ({
    input: {
        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0
        },
        height: 24,
        border: 'solid 1px #bbb',
        padding: '4px 5px',
    },
}))

const Input = ({ width = '100%', onChange, value, type = 'text', style, textAlign, maxLength, ...props }) => {
    const classes = useInputStyles();

    const handleChange = (e) => {
        const value = e.target.value;
        if (!maxLength || value.length <= maxLength){
            onChange && onChange(value);
        }
    }

    return (
        <input
            type={type}
            className={classes.input}
            onChange={handleChange}
            style={{
                width: width,
                textAlign: textAlign ? textAlign : (type === 'number' ? 'end' : 'start'),
                ...style
            }}
            value={value != null ? value : ''}
            {...props}
        />
    )
}

const useButtonStyles = makeStyles(theme => ({
    button : {
        color: '#fff',
        paddingTop: 0,
        paddingBottom: 2,
        borderStyle: 'solid',
        borderRadius: 2,
    }
}))

const Button = ({ style, buttonColor = '#606773', ...props }) => {
    const classes = useButtonStyles();

    return (
        <input
            className={classes.button}
            type='button'
            style={{
                borderColor: buttonColor,
                backgroundColor: buttonColor,
                ...style,
            }}
            {...props}
        />
    )
}

const useRadioStyles = makeStyles(theme => ({
    container: {
        margin: '0px 10px',
    },
    input: {
        cursor: 'pointer',
    },
    label: {
        cursor: 'pointer',
    }
}))

const RadioItem = ({ label, onChange, style, ...props }) => {
    const classes = useRadioStyles();
    return (
        <div
            className={classes.container}
            style={style}
        >
            <input
                className={classes.input}
                type='radio'
                onChange={onChange}
                {...props}
            />
            <label
                className={classes.label}
                onClick={onChange}
            >
                {label}
            </label>
        </div>
    )
}

export default TaxInvoiceEnroll;