import React, { Fragment, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Dialog, DialogTitle, Divider, Fab, List, ListItem, ListItemText, makeStyles, Paper, Slider } from '@material-ui/core';
import { Check, Close, Refresh } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { callProc } from '../../common/DBConnector';
import { trnState } from '../../common/Dictionary';
import { numberFormat, useFieldInputs } from '../../common/Utils';
import { IconButton, NumberField, PaperTitle, ProgressPanel, ProgressDialog, TextField, SearchPanel, DatePicker, SearchItem } from '../../component';

const useStyles = makeStyles(theme => ({
    errMsgBox: {
        textAlign: 'center',
        padding: theme.spacing(2),
    },
    button: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    }
}))

const TransInfo = ({ isEnroll }) => {
    const [data, setData] = useState();
    const [condition, setCondition] = useState(null);
    const [enrollData, setEnrollData] = useState();

    const loginState = useSelector(state => state.mobileLoginReducer);
    const classes = useStyles();

    useEffect(() => {
        isEnroll && loginState['TSP_CAR_CD'] && setCondition({
            ...loginState,
            TRN_STATE: isEnroll ? 1 : 2,
        })
    }, [loginState, isEnroll]);

    const refreshList = () => setCondition({ ...condition });

    useEffect(() => {
        const fetchData = async() => {
            setData(null);

            let selectProc = '';
            if(isEnroll){
                selectProc = 'SLT_TRANSPORT_ORDER';
            } else {
                selectProc = 'SLT_TRANSPORT_COMPLETE';
            }
            const data = (await callProc(selectProc, condition)).data.reduce((result, item) => ({
                ...result,
                [item['TRN_CD']]: [
                    ...(result[item['TRN_CD']] || []),
                    item
                ]
            }), {});
            setData(Object.values(data));
        }
        condition && fetchData();
    }, [condition, isEnroll])

    return (!loginState['TSP_CAR_CD'] ? <ProgressPanel /> :
        <Fragment>
            {!isEnroll &&
            <SearchPanel onSearch={(condition) => setCondition({ ...loginState, ...condition, TRN_STATE: isEnroll ? 1 : 2 })}>
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().format('YYYY-MM-DD')}
                    component={(state, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().format('YYYY-MM-DD')}
                    component={(state, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                />
            </SearchPanel>}
            <Paper>
                <PaperTitle>
                    {isEnroll ? '진행 내역' : '완료 내역'}
                </PaperTitle>
                {!data ? <ProgressPanel /> :
                data.length === 0 ? <div className={classes.errMsgBox}>조회된 결과가 없습니다.</div> :
                <List>
                    {data.map((item, idx) => (
                        <TransItem
                            key={item[0]['TRN_CD']}
                            data={item}
                            idx={idx + 1}
                            openDialog={setEnrollData}
                            isEnroll={isEnroll}
                        />
                    ))}
                </List>}
                {isEnroll &&
                <Fab
                    className={classes.button}
                    color='primary'
                    onClick={refreshList}
                    disabled={!condition}
                >
                    <Refresh />
                </Fab>}
                <DetailDialog
                    data={enrollData}
                    onClose={() => setEnrollData()}
                    refreshList={refreshList}
                    isEnroll={isEnroll}
                />
            </Paper>
        </Fragment>
    )
}

const useTransStyles = makeStyles(theme => ({
    item: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    title: {
        margin: 0,
    },
    ordList: {
        width: '100%',
        padding: 0,
    },
}));

const TransItem = ({ data, idx, openDialog, isEnroll }) => {
    const classes = useTransStyles();

    return (
        <ListItem className={classes.item}>
            <ListItemText className={classes.title}>
                {`${idx}. ${data[0]['ORD_CONT']} - ${trnState[data[0]['TRN_STATE']]}`}
            </ListItemText>
            <List className={classes.ordList}>
                {data.map(item => (
                    <OrderItem
                        key={item['ORD_CD']}
                        data={item}
                        openDialog={openDialog}
                        isEnroll={isEnroll}
                    />
                ))}
            </List>
        </ListItem>
    )
}

const useOrderStyles = makeStyles(theme => ({
    item: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    dest: {
        fontSize: 15,
        fontWeight: 'bold',
    },
    goods: {
        fontWeight: 'bold',
    },
}));

const OrderItem = ({ data, openDialog, isEnroll }) => {
    const classes = useOrderStyles();
    return (
        <Fragment>
            <ListItem
                className={classes.item}
                onClick={() => openDialog(data)}
                button
            >
                <div className={classes.dest}>
                    {`도착지 : ${data['REAL_DEST_NM']}`}
                </div>
                <div className={classes.goods}>
                    {`출하처 : ${data['SHIP_TRN_NM']} > 인도처 : ${data['DEST_TRN_NM'] || ''}`}
                </div>
                <div className={classes.goods}>
                    {`상품 : ${data['GDS_NM']}`}
                </div>
                <div className={classes.goods}>
                    {`수량 : ${numberFormat(data['QTY'])}`}{!isEnroll && ` (${numberFormat(data['CALC_QTY'])})`}
                </div>
                <div>
                    {`매입전표 : ${data['TRN_INNO'] || ''}`}
                </div>
                <div>
                    {data['CALC_DTTM']}
                </div>
            </ListItem>
            <Divider component='li' />
        </Fragment>
    )
}

const useDialogStyles = makeStyles(theme => ({
    dialog: {
        width: '90%',
        maxWidth: 400,
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        overflowX: 'hidden',
        overflowY: 'auto',
        '& > *': {
            paddingBottom: theme.spacing(2),
        },
    },
    btnContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(2),
    },
}));

const DetailDialog = ({ data, onClose, refreshList, isEnroll }) => {
    const [insData, setInsData] = useFieldInputs({});
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const classes = useDialogStyles();

    useEffect(() => {
        data && setInsData({ type: 'INIT', value: { ...data, CALC_QTY: data['QTY'] } });
    }, [data, setInsData]);

    const valid = () => {
        if (Number(insData['CALC_QTY']) <= 0) {
            enqueueSnackbar('정산수량을 입력해주세요.', { variant: 'error' });
            return false;
        }
        return true;
    }

    const onSave = async() => {
        setLoading(true);

        if (valid()) {
            const result = await callProc('INS_INBOUNDCALCINFO', { ...insData, TSP_CHK: 2 });
            if (!result.err) {
                refreshList();
                enqueueSnackbar('정산이 완료되었습니다.');
                onClose();
            }
        }

        setLoading(false);
    }

    const qty = insData['QTY'];
    const minQty = useMemo(() => Math.round(qty * 0.97), [qty]);
    const maxQty = useMemo(() => Math.round(qty * 1.03), [qty]);

    return (
        <Dialog
            PaperProps={{ className: classes.dialog }}
            open={data != null}
            onClose={onClose}
        >
            <div className={classes.dialogTitle}>
                <DialogTitle >
                    상세 내역
                </DialogTitle>
                <IconButton
                    tooltip='닫기'
                    icon={<Close />}
                    onClick={onClose}
                />
            </div>
            <div className={classes.content}>
                <TextField
                    label='출발지'
                    value={insData['SHIP_TRN_NM']}
                    disabled
                />
                <TextField
                    label='도착지'
                    value={insData['REAL_DEST_NM']}
                    disabled
                />
                <TextField
                    label='인도처'
                    value={insData['DEST_TRN_NM']}
                    disabled
                />
                <TextField
                    label='상품'
                    value={insData['GDS_NM']}
                    disabled
                />
                <NumberField
                    label='수량'
                    value={insData['QTY']}
                    disabled
                />
                <NumberField
                    label='정산수량'
                    value={insData['CALC_QTY']}
                    onChange={value => setInsData('CALC_QTY', value === '' ? '' : Number(value))}
                    disabled={!isEnroll}
                />
                {isEnroll ?
                <Slider
                    value={Number(insData['CALC_QTY'])}
                    min={minQty}
                    max={maxQty}
                    onChange={(event, value) => setInsData('CALC_QTY', value)}
                /> :
                <TextField
                    label='배송일'
                    value={insData['CALC_DTTM']}
                    disabled
                />}
            </div>
            {isEnroll &&
            <div className={classes.btnContainer}>
                <IconButton
                    tooltip='초기화'
                    icon={<Refresh />}
                    onClick={() => setInsData('CALC_QTY', insData['QTY'])}
                />
                <IconButton
                    tooltip='정산'
                    icon={<Check />}
                    onClick={onSave}
                />
            </div>}
            <ProgressDialog open={loading} />
        </Dialog>
    )
}

export default TransInfo;