import React, { useState } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DatePicker, DataColumn, Tabs, Tab } from '../../component';
import { makeStyles } from '@material-ui/core';
import { issueLoc } from '../../common/Dictionary';



const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        width: 0,
    },
    tabContainer: {
        display: 'flex',
    },
    tabbar: {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    tab: {
        minWidth: 100,
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
    hide: {
        display: 'none'
    },
}))

const InOutAccountingList = () => {
    const [condition, setCondition] = useState(null);
    const [selectTab, setSelectTab] = useState(0);
    const classes = useStyles();


    return (
        <div className={classes.container}>
            <SearchPanel onSearch={condition => {
                sessionStorage['WORK_CD'] = condition['WORK_CD'];
                setCondition(condition);
                }}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, setState) => (
                        <ComboBox
                            label='사업장명'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                            }}
                        />
                    )}
                />
               <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
            </SearchPanel>
            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab className={classes.tab} label='전체' />
                    <Tab className={classes.tab} label='입금' />
                    <Tab className={classes.tab} label='출금' />
                    <Tab className={classes.tab} label='혼합' />
                </Tabs>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <All condition={condition} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <In condition={condition}/>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 2 })}>
                <Out condition={condition}/>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 3 })}>
                <Mix condition={condition}/>
            </div>
        </div>
    )  
}

const All = ({ condition }) => {


    return (
        <DataGrid
            title='전체'
            selectProc='SLT_INOUT_ACCOUNTING_LIST_ALL'
            selectParam={condition}
            horizonMode
            totalGroup={['ISSUE_LOC']}
            totalSort={(a, b) => a['ISSUE_LOC'] > b['ISSUE_LOC'] ? 1 : -1}
            selectionMode='single'
            virtualized
            sortable
            filterable
            excelDownload={{ fileName: '입출금전표조회-전체.xlsx', sheetName: '전체' }}
        >
            <DataColumn
                fieldName='ISSUE_LOC'
                value={(row, value) => issueLoc[value]}
                total={(values, data, group) => group ? issueLoc[group['ISSUE_LOC']] + ' 소계' : '총계'}
            />
            <DataColumn
                fieldName='CARD_NM'
                title='결제수단'
            />
            <DataColumn
                fieldName='DEBTOR'
                total={(values) => values.reduce((result, value) => result + value, 0)}
            />
            <DataColumn
                fieldName='CREDITOR'
                total={(values) => values.reduce((result, value) => result + value, 0)}
            />
            <DataColumn
                fieldName='ACC_CD'
                visible={false}
            />
            <DataColumn
                fieldName='CST_CD'
                visible={false}
            />
            <DataColumn
                fieldName='PAY_CD'
                visible={false}
            />
            <DataColumn
                fieldName='PAY_NM'
                visible={false}
            /> 
            <DataColumn
                fieldName='CARD_CD'
                visible={false}
            />
        </DataGrid>
    )
}

const In = ({ condition }) => {


    return (
        <DataGrid
            title='입금'
            selectProc='SLT_INOUT_ACCOUNTING_LIST_IN'
            selectParam={condition}
            horizonMode
            totalGroup={['ISSUE_LOC']}
            totalSort={(a, b) => a['ISSUE_LOC'] > b['ISSUE_LOC'] ? 1 : -1}
            selectionMode='single'
            virtualized
            sortable
            filterable
            excelDownload={{ fileName: '입출금전표조회-입금.xlsx', sheetName: '입금' }}
        >
            <DataColumn
                fieldName='ISSUE_LOC'
                value={(row, value) => issueLoc[value]}
                total={(values, data, group) => group ? issueLoc[group['ISSUE_LOC']] + ' 소계' : '총계'}
            />
            <DataColumn
                fieldName='CARD_NM'
                title='결제수단'
            />
            <DataColumn
                fieldName='DEBTOR'
                total={(values) => values.reduce((result, value) => result + value, 0)}
            />
            <DataColumn
                fieldName='CREDITOR'
                total={(values) => values.reduce((result, value) => result + value, 0)}
            />
            <DataColumn
                fieldName='ACC_CD'
                visible={false}
            />
            <DataColumn
                fieldName='CST_CD'
                visible={false}
            />
            <DataColumn
                fieldName='PAY_CD'
                visible={false}
            />
            <DataColumn
                fieldName='PAY_NM'
                visible={false}
            /> 
            <DataColumn
                fieldName='CARD_CD'
                visible={false}
            />
        </DataGrid>
    )
}

const Out = ({ condition }) => {


    return (
        <DataGrid
            title='출금'
            selectProc='SLT_INOUT_ACCOUNTING_LIST_OUT'
            selectParam={condition}
            horizonMode
            totalGroup={['ISSUE_LOC']}
            totalSort={(a, b) => a['ISSUE_LOC'] > b['ISSUE_LOC'] ? 1 : -1}
            selectionMode='single'
            virtualized
            sortable
            filterable
            excelDownload={{ fileName: '입출금전표조회-출금.xlsx', sheetName: '출금' }}
        >
            <DataColumn
                fieldName='ISSUE_LOC'
                value={(row, value) => issueLoc[value]}
                total={(values, data, group) => group ? issueLoc[group['ISSUE_LOC']] + ' 소계' : '총계'}
            />
            <DataColumn
                fieldName='CARD_NM'
                title='결제수단'
            />
            <DataColumn
                fieldName='DEBTOR'
                total={(values) => values.reduce((result, value) => result + value, 0)}
            />
            <DataColumn
                fieldName='CREDITOR'
                total={(values) => values.reduce((result, value) => result + value, 0)}
            />
            <DataColumn
                fieldName='ACC_CD'
                visible={false}
            />
            <DataColumn
                fieldName='CST_CD'
                visible={false}
            />
            <DataColumn
                fieldName='PAY_CD'
                visible={false}
            />
            <DataColumn
                fieldName='PAY_NM'
                visible={false}
            /> 
            <DataColumn
                fieldName='CARD_CD'
                visible={false}
            />
        </DataGrid>
    )
}

const Mix = ({ condition }) => {

    return (
        <DataGrid
            title='혼합'
            selectProc='SLT_INOUT_ACCOUNTING_LIST_MIX'
            selectParam={condition}
            horizonMode
            totalGroup={['ISSUE_LOC']}
            totalSort={(a, b) => a['ISSUE_LOC'] > b['ISSUE_LOC'] ? 1 : -1}
            selectionMode='single'
            virtualized
            sortable
            filterable
            excelDownload={{ fileName: '입출금전표조회-혼합.xlsx', sheetName: '혼합' }}
        >
            <DataColumn
                fieldName='ISSUE_LOC'
                value={(row, value) => issueLoc[value]}
                total={(values, data, group) => group ? issueLoc[group['ISSUE_LOC']] + ' 소계' : '총계'}
            />
            <DataColumn
                fieldName='CARD_NM'
                title='결제수단'
            />
            <DataColumn
                fieldName='DEBTOR'
                total={(values) => values.reduce((result, value) => result + value, 0)}
            />
            <DataColumn
                fieldName='CREDITOR'
                total={(values) => values.reduce((result, value) => result + value, 0)}
            />
            <DataColumn
                fieldName='ACC_CD'
                visible={false}
            />
            <DataColumn
                fieldName='CST_CD'
                visible={false}
            />
            <DataColumn
                fieldName='PAY_CD'
                visible={false}
            />
            <DataColumn
                fieldName='PAY_NM'
                visible={false}
            /> 
            <DataColumn
                fieldName='CARD_CD'
                visible={false}
            />
        </DataGrid>
    )
}

export default InOutAccountingList;