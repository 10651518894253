import React, { useState } from 'react';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DatePicker, DataColumn } from '../../component';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(() => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    total: {
        backgroundColor: '#a3a9d9 !important'
    }
}))

const BankBookBalanceList = () =>{
    const classes = useStyle();
    const [condition, setCondition] = useState(null);
    const getCellProps = (row, fieldName) => {
        if(String(row['COLOR_TYPE']) === '2')
            return { className: classes.total }
    };

    return(
        <div className={classes.container}>
            <SearchPanel onSearch={condition => {
                sessionStorage['WORK_CD'] = condition['WORK_CD'];
                setCondition(condition);
                }}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, setState) => (
                        <ComboBox
                            label='사업장명'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                            }}
                        />
                    )}
                />
               <SearchItem
                    fieldName='BIZ_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState) => (
                        <DatePicker
                            label='영업일'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                            }}
                        />
                    )}
                />
            </SearchPanel>
            <DataGrid
                title='계좌잔액 조회'
                selectProc='SLT_BANKBOOKBAL_LIST'
                selectParam={condition}
                horizonMode
                selectionMode='single'
                virtualized
                cellProps={getCellProps}
                excelDownload
            >
                <DataColumn fieldName='AMOUNT' title='금액'/>
                <DataColumn fieldName='SEQ' visible={false} />
                <DataColumn fieldName='COLOR_TYPE' visible={false} />
            </DataGrid>
        </div>

    )
}

export default BankBookBalanceList