import React, { useState } from 'react';
import moment from 'moment';
import { IconButton, makeStyles, Paper, Tooltip } from '@material-ui/core';
import { SearchPanel, SearchItem, ComboBox, DatePicker, DataGrid, PaperTitle, DataColumn, ProgressDialog, RadioGroup, RadioItem } from '../../component';
import { useFieldInputs } from '../../common/Utils';
import CheckIcon from '@material-ui/icons/Check';
import { useSnackbar } from 'notistack';
import { callProc } from '../../common/DBConnector';
import { gdsKind } from '../../common/Dictionary';

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
    info: {
        flex: 2,
        marginRight: theme.spacing(1),
    },
    input: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginLeft: theme.spacing(1),
    },
    paperContent: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding: theme.spacing(2),
        paddingTop: 0,
        overflowY: 'auto',
    },
    field: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(3),
    },
    chkButton: {
        marginTop: 'auto',
        alignSelf: 'flex-end',
    },
}))

const CstTaxInvoiceEnroll = () => {
    const [insData, setInsData] = useFieldInputs({
        WRT_DATE: moment().format('YYYY-MM-DD'),
        TRD_DATE: moment().format('DD'),
        CST_DAY: moment().add(-1, 'month').endOf('month').format('YYYY-MM-DD'),
        TAX_TYPE: '01',
        ISSUE_TYPE: '1',
        PBL_TYPE: '02',
        PRICE_TYPE: '1',
    });
    const [condition, setCondition] = useState(null);
    const [selection, setSelection] = useState();
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const hasValue = value => value != null && value.toString().trim() !== '';

    const valid = () => {
        if (!selection || selection.length === 0) {
            enqueueSnackbar('선택된 항목이 없습니다.', { variant: 'error' });
            return false;
        }
        if (selection.reduce((result, item) => result || !hasValue(item['WORK_BIZNO']), false)) {
            enqueueSnackbar('사업자 등록번호가 없으면 발행할 수 없습니다.', { variant: 'error' });
            return false;
        }
        return true;
    }

    const onInsert = async() => {
        setLoading(true);

        if (valid()) {
            const result = await callProc('INS_TAXINVOICEINFO_CST',{
                ...condition,
                ...insData,
                ARR_CST_CD: JSON.stringify(selection.map(item => item['CST_CD']))
            });
            if (!result.err) {
                setCondition({ ...condition });
                enqueueSnackbar('저장이 완료되었습니다.');
            }
        }

        setLoading(false);
    }

    const getFirstDay = value => value.substring(0, 8) + '01';

    const getLastDay = value =>
        moment(getFirstDay(value), 'YYYY-MM-DD')
            .add(1, 'month')
            .add(-1, 'day')
            .format('YYYY-MM-DD');

    return(
        <div className={classes.container}>
            <ProgressDialog open={loading} />
            <SearchPanel onSearch={(condition) => setCondition(condition)}>
                <SearchItem
                    fieldName='WORK_CD'
                    component={(state, dispatch) =>(
                        <ComboBox
                            label='사업장명'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem 
                    fieldName='GDS_KIND'
                    component={(state, dispatch) => (
                        <ComboBox
                            label='상품 구분'
                            data={gdsKind}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
            </SearchPanel>
            <div className={classes.content}>
                <DataGrid
                    title='거래처별 판매내역'
                    className={classes.info}
                    selectProc='SLT_SALEINFO_CSTTAX'
                    selectParam={condition}
                    selectionMode='multi'
                    onSelect={rows => setSelection(rows)}
                >
                    <DataColumn
                        fieldName='CST_CD'
                        visible={false}
                    />
                    <DataColumn
                        fieldName='PRICE'
                        title='평균단가'
                    />
                    <DataColumn
                        fieldName='QTY'
                        title='미발행 수량'
                    />
                    <DataColumn
                        fieldName='AMOUNT'
                        title='미발행 금액'
                    />
                    <DataColumn
                        fieldName='WRT_DATE'
                        title='최근 작성일'
                    />
                    <DataColumn
                        fieldName='GDS_KIND'
                        visible={false}
                    />
                </DataGrid>
                <Paper className={classes.input}>
                    <PaperTitle>
                        세금계산서 발행
                    </PaperTitle>
                    <div className={classes.paperContent}>
                        <DatePicker
                            label='작성일'
                            value={insData['WRT_DATE']}
                            onChange={value => setInsData('WRT_DATE', value)}
                        />
                        <DatePicker
                            label='거래일'
                            className={classes.field}
                            minDate={getFirstDay(insData['WRT_DATE'])}
                            maxDate={getLastDay(insData['WRT_DATE'])}
                            value={insData['WRT_DATE'].substring(0, 8) + insData['TRD_DATE']}
                            onChange={value => setInsData('TRD_DATE', value.substring(8))}
                        />
                        <div>
                            <RadioGroup
                                label='계산서 종류'
                                className={classes.field}
                                value={insData['TAX_TYPE']}
                                onChange={value => setInsData('TAX_TYPE', value)}
                            >
                                <RadioItem value='01' label='일반' />
                                <RadioItem value='02' label='영세율' />
                                <RadioItem value='05' label='면세' />
                            </RadioGroup>
                            <RadioGroup
                                label='발행구분'
                                className={classes.field}
                                value={insData['PBL_TYPE']}
                                onChange={value => setInsData('PBL_TYPE', value)}
                            >
                                <RadioItem value='01' label='영수' />
                                <RadioItem value='02' label='청구' />
                                <RadioItem value='03' label='자동' />
                            </RadioGroup>
                        </div>
                        <RadioGroup
                            label='발행방식'
                            className={classes.field}
                            value={insData['ISSUE_TYPE']}
                            onChange={value => setInsData('ISSUE_TYPE', value)}
                        >
                            <RadioItem value='0' label='상품별 발행' />
                            <RadioItem value='1' label='상품별 각개 발행' />
                            <RadioItem value='2' label='합산 발행' />
                        </RadioGroup>
                        <RadioGroup
                            label='단가구분'
                            className={classes.field}
                            value={insData['PRICE_TYPE']}
                            onChange={value => setInsData('PRICE_TYPE', value)}
                        >
                            <RadioItem value='1' label='평균단가' />
                            <RadioItem value='2' label='숨김' />
                        </RadioGroup>
                        <Tooltip title='확인' className={classes.chkButton}>
                            <IconButton onClick={onInsert}>
                                <CheckIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </Paper>
            </div>
        </div>
    )
}

export default CstTaxInvoiceEnroll;