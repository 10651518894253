import React, { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { DialogTitle, Fab, Fade, makeStyles, Slide } from '@material-ui/core';
import { Add, Fullscreen, GetApp, Print, Remove, RotateRight } from '@material-ui/icons';
import { pdfjs, Document, Page } from 'react-pdf';
import { IconButton, ProgressPanel } from '.';
import { usePdfRender } from '../common/Utils';

const useStyles = makeStyles(theme => ({
    container: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: 0,
        overflowX: 'hidden',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    scroll: {
        display: 'flex',
        flex: 1,
        overflow: 'auto',
        backgroundColor: '#666',
        paddingTop: props => theme.spacing(1) * props.scale,
        paddingBottom: props => theme.spacing(1) * props.scale,
    },
    document: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
    },
    page: {
        margin: props => theme.spacing(1) * props.scale,
    },
    fabContainer: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        bottom: theme.spacing(2),
        right: theme.spacing(3),
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const PdfViewer = ({ children, className, url, title, fileName, scale, scaleUnit, rotate, header }) => {
    const [totalPage, setTotalPage] = useState();
    const [width, setWidth] = useState();
    const [realScale, setRealScale] = useState(1.0);
    const [realRotate, setRealRotate] = useState();

    const [fabVisible, setFabVisible] = useState(false);
    const renderUrl = usePdfRender(children);

    const documentRef = useRef();
    const containerRef = useRef();
    const printFrameRef = useRef();

    const classes = useStyles({ scale: realScale });

    useEffect(() => setRealScale(scale), [scale]);
    useEffect(() => setRealRotate(rotate), [rotate]);
    
    useEffect(() => setRealScale(Math.round(realScale * 100) / 100), [realScale]);
    useEffect(() => setWidth(containerRef.current.offsetWidth - 50), []);

    const realUrl = useMemo(() => renderUrl || url, [renderUrl, url]);

    const pageComponents = useMemo(() => {
        if (totalPage) {
            const result = [];
            for (let i = 1; i <= totalPage; i++) {
                result.push(
                    <Page
                        key={i}
                        className={classes.page}
                        pageNumber={i}
                        scale={realScale}
                        width={width}
                        renderTextLayer={false}
                        rotate={realRotate}
                    />
                )
            }
            return result;
        }
    }, [classes.page, totalPage, realScale, width, realRotate]);

    return(
        <div ref={containerRef} className={clsx(classes.container, className)}>
            <div className={classes.header}>
                <DialogTitle>
                    {title || fileName}
                </DialogTitle>
                <div>
                    <IconButton
                        tooltip='회전'
                        icon={<RotateRight />}
                        onClick={() => setRealRotate((realRotate + 90) % 360)}
                    />
                    <a href={realUrl} download={(fileName || title) && (fileName || title) + '.pdf'}>
                        <IconButton
                            tooltip='다운로드'
                            icon={<GetApp />}
                        />
                    </a>
                    <IconButton
                        tooltip='출력'
                        icon={<Print />}
                        onClick={() => printFrameRef.current.contentWindow.print()}
                    />
                    {header}
                </div>
            </div>
            <div
                className={classes.scroll}
                onMouseEnter={() => setFabVisible(true)}
                onMouseLeave={() => setFabVisible(false)}
            >
                <Document
                    ref={documentRef}
                    className={classes.document}
                    file={realUrl}
                    loading={<ProgressPanel />}
                    noData=''
                    onLoadSuccess={({ numPages }) => setTotalPage(numPages)}
                    options={{
                        cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
                        cMapPacked: true,
                    }}
                >
                    {pageComponents}
                </Document>
            </div>
            <Slide in={fabVisible} direction='left' mountOnEnter unmountOnExit>
                <div className={classes.fabContainer} onMouseEnter={() => setFabVisible(true)}>
                    <Fade in={realScale !== 1.0} mountOnEnter unmountOnExit>
                        <Fab onClick={() => setRealScale(1.0)}>
                            <Fullscreen />
                        </Fab>
                    </Fade>
                    <div 
                    />
                    <Fab onClick={() => setRealScale(realScale + scaleUnit)} disabled={realScale >= 2.0}>
                        <Add />
                    </Fab>
                    <Fab onClick={() => setRealScale(realScale - scaleUnit)} disabled={realScale <= 0.3}>
                        <Remove />
                    </Fab>
                </div>
            </Slide>
            <iframe
                title='printFrame'
                ref={printFrameRef}
                type='application/pdf'
                src={realUrl}
                style={{ display: 'none' }}
            />
        </div>
    )
}

PdfViewer.defaultProps = {
    scaleUnit: 0.15,
    rotate: 0
}

export default PdfViewer;