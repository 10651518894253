import React, { useEffect, useMemo } from 'react';
import { DataGrid, ComboBox, DataColumn, DatePicker, NumberField, ComboSearch, IconButton, PaperTitle, TextField, TimePicker, ProgressDialog} from '../../component';
import { makeStyles, Paper } from '@material-ui/core';
import { useSelector } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import { useFieldInputs } from '../../common/Utils';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { callProc } from '../../common/DBConnector';
import CheckIcon from '@material-ui/icons/Check';
import CachedIcon from '@material-ui/icons/Cached';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
    info: {
        flex: 1,
        marginRight: theme.spacing(1),
    },
    input: {
        width: 300,
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(1),
    },
    searchPaper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    searchContainer: {
        flex: 1,
        display: 'inline-block',
        flexDirection: 'row',
    },
    searchItem: {
        display: 'inline-block',
        width: 200,
        marginRight: 100,
        marginBottom: theme.spacing(1),
    },
}))

const InboundCalcNotOrder = () => {
    const clsState = useSelector(state => state.clsDateReducer);
    const [editRow, setEditRow] = useState();
    const [input, setInput] = useFieldInputs({
        WORK_CD: sessionStorage['WORK_CD'] === 'ALL' ? null : sessionStorage['WORK_CD'],
        SHIP_DATE: clsState[sessionStorage['WORK_CD']],
    });

    const classes = useStyles();

    const condition = useMemo(() => (input['WORK_CD'] && input['SHIP_DATE']) ? input : null, [input]);

    const gridRefresh = () => {
        setInput({ type: 'UPDATE', value: { ...input } });
    }
    const onUpdateStart = row => {
        setEditRow({...row});
        return false;
    }
    return(
        <div className={classes.container}>
            <Paper className={classes.searchPaper}>
                <div className={classes.searchContainer}>
                    <div className={classes.searchItem}>                    
                        <ComboBox
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={condition && condition['WORK_CD']}
                            onChange={value => {
                                if (input['WORK_CD'] !== value) {
                                    sessionStorage['WORK_CD'] = value;
                                    setInput('BIZ_DATE', clsState[value]);
                                    setInput('WORK_CD', value);
                                }
                            }}
                        />                        
                    </div>
                    <div className={classes.searchItem}>
                        <DatePicker
                            label='영업일'
                            minDate={clsState[input['WORK_CD']]}
                            value={condition && condition['SHIP_DATE']}
                            onChange={value => {
                                if (input['SHIP_DATE'] !== value) {
                                    setInput('SHIP_DATE', value);
                                    if(condition && condition['SHIP_DATE'] < clsState[condition['WORK_CD']]) {
                                        setInput({ type: 'UPDATE', value: { ...input, 'SHIP_DATE': value } });
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                <IconButton
                    tooltip=''
                    icon={<SearchIcon />}
                    onClick={gridRefresh}
                />
            </Paper>
            <div className={classes.content}>
                <div className={classes.info}>
                    <DataGrid
                        title='입고 정산(미오더)'
                        selectProc='SLT_INBOUNDCALC_NOTORDER'
                        updateProc=''
                        deleteProc='DLT_ORDERCALCINFO'
                        selectParam={condition}
                        onUpdateStart={onUpdateStart}
                        horizonMode
                        virtualized
                    >
                        <DataColumn fieldName='CALC_CD' title='정산코드' />
                        <DataColumn fieldName='WORK_NM' title='사업장명' />                        
                        <DataColumn fieldName='SHIP_DATE' title='입고일' />
                        <DataColumn fieldName='SHIP_TIME' title='일시' />
                        <DataColumn fieldName='GDS_NM' title='상품' />
                        <DataColumn fieldName='BUY_CST_NM' title='매입처' />
                        <DataColumn fieldName='SHIP_TRN_NM' title='출하처' />
                        <DataColumn fieldName='DEST_TRN_NM' title='인도처' />
                        <DataColumn fieldName='REAL_DEST_NM' title='도착지' />
                        <DataColumn fieldName='CALC_QTY' title='정산수량' /> 
                        <DataColumn fieldName='WORK_CD' visible={false} />
                        <DataColumn fieldName='GDS_CD' visible={false} />
                        <DataColumn fieldName='BUY_CST_CD' visible={false} />
                        <DataColumn fieldName='SHIP_TRN_CD' visible={false} />
                        <DataColumn fieldName='DEST_TRN_CD' visible={false} />
                        <DataColumn fieldName='REAL_DEST_CD' visible={false} />
                        <DataColumn fieldName='SPLY_AMT' visible={false} />
                        <DataColumn fieldName='VAT' visible={false} />
                        <DataColumn fieldName='AMOUNT' visible={false} />
                    </DataGrid>
                </div>
                <div className={classes.input}>
                    <EnrollPanel
                        condition={condition}
                        editRow={editRow}
                        gridRefresh={gridRefresh}
                        clsState={clsState}
                    />
                </div>
            </div>
        </div>
    )
}

const usePanelStyles = makeStyles((theme) => ({
    panelContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: 0,
    },
    panelContent: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        overflowY: 'auto',
        '& > *': {
            marginBottom: theme.spacing(2),
        },
    },
    btnContainer: {
        alignSelf: 'flex-end',
        padding: theme.spacing(1),
    },
}))

const EnrollPanel = ({ condition, editRow, gridRefresh, clsState }) => {
    const classes = usePanelStyles();
    const [outTrnParam, setOutTrnParam] = useState();
    const [inTrnParam, setInTrnParam] = useState();
    const [realinTrnParam, setRealInTrnParam] = useState();
    const [cstParam, setCstParam] = useState();
    const [gdsParam, setGdsParam] = useState();
    const [editState, setEditState] = useState(0);
    const [befValue, setBefValue] = useState();
    const { enqueueSnackbar } = useSnackbar();

    const [insData, setInsData] = useFieldInputs({
        WORK_CD: condition && condition['WORK_CD'],
        SHIP_DATE: condition && condition['SHIP_DATE'],
        SHIP_TIME: '00:00:00',
        REAL_DEST_CD : null,
        GDS_CD: null,
        BUY_CST_CD: null,
        CALC_QTY: 0,
        SHIP_TRN_CD: null,
        DEST_TRN_CD: null,
        PRICE: 0
    });
    
    useEffect(() => {
        const fetchData = () => {
            setInsData({ type: 'INIT' });
            setEditState(0);
            setGdsParam({
                WORK_CD: condition && condition['WORK_CD'],
                GDS_TYPE: 'ALL',
                NOT_TYPE: 0,
                GDS_TYPE_M: '1',
                GAUGE_TYPE: '0',
                NOT_TYPE_M: 0,
                STA_DATE: condition && condition['SHIP_DATE'],
            });
            setCstParam({
                WORK_CD: condition && condition['WORK_CD'],
                CST_TYPE: [0, 2],
                CST_KIND: [1],
                STA_DATE: condition && condition['SHIP_DATE'],
            })
            setRealInTrnParam({
                WORK_CD: condition && condition['WORK_CD'],
                TSP_TYPE: 1,
                DEST_WORK_CD :condition && condition['WORK_CD']
            });
            setInTrnParam({
                WORK_CD: condition && condition['WORK_CD'],
                TSP_TYPE: 1
            });
            setOutTrnParam({
                WORK_CD: condition && condition['WORK_CD'],
                TSP_TYPE: 0
            });
            setInsData('WORK_CD', condition['WORK_CD']);
            setInsData('BIZ_DATE', condition['BIZ_DATE']);
        }

        condition && fetchData();
    }, [condition, setInsData])

    const isValid = () => {
        if(insData['GDS_CD'] === null) {
            enqueueSnackbar('상품을 선택해 주세요.', { variant: 'error' });
            return false;
        }

        if(insData['BUY_CST_CD'] === null) {
            enqueueSnackbar('매입처를 선택해 주세요.', { variant: 'error' });
            return false;
        }

        if(insData['REAL_DEST_CD'] === null) {
            enqueueSnackbar('도착지를 선택해 주세요.', { variant: 'error' });
            return false;
        }

        if(String(insData['CALC_QTY']) === '' || String(insData['CALC_QTY']) === '0') {
            enqueueSnackbar('정산수량을 입력해 주세요.', { variant: 'error' });
            return false;
        }
        return true;
    }
    const [loading, setLoading] = useState(false);

    const onInsert = async() => {
        setLoading(true);

        if(isValid()){
            let befData = {...insData};
            delete befData.CALC_CD;
            delete befData.WORK_CD;
            delete befData.SHIP_DATE;
            setBefValue(befData);
            let procName;
            if(String(editState) === '0'){
                procName = 'INS_ORDERCALCINFO'
            } else {
                procName = 'UPT_ORDERCALCINFO'
            }
            const result = await callProc(procName, insData);
            if(!result.err) {
                enqueueSnackbar('저장이 완료되었습니다.');
                setInsData({ type: 'INIT' });
                setEditState(0);
                gridRefresh();
            }
        }

        setLoading(false);
    }

    useEffect(() => {
        const fetchData = () => {
            setInsData({ type: 'UPDATE', value: editRow })
            setEditState(1);
        }
        editRow && fetchData();
    }, [editRow, setInsData, setEditState])

    const onExitEdit = () => {
        setInsData({ type: 'INIT' });
        setEditState(0);
    }

    const getBefValue = () => {
        befValue && setInsData({ type: 'UPDATE', value: {...befValue} })
    }

    return (
        <Paper className={classes.panelContainer} >
            <ProgressDialog open={loading} />

            <PaperTitle>{String(editState) === '0' ? '추가' : '수정'}</PaperTitle>
            <div className={classes.panelContent}>
                {String(editState) === '1' &&
                <TextField
                    label='정산코드'
                    value={insData['CALC_CD']}
                    disabled
                />}
                <ComboBox
                    label='사업장'
                    selectProc='SLT_WORKPLACEINFO_AUTH'
                    keyField='WORK_CD'
                    valueField='WORK_NM'
                    value={insData['WORK_CD']}
                    disabled
                />
                <DatePicker
                    label='입고일'
                    minDate={clsState[insData['WORK_CD']]}
                    value={insData['SHIP_DATE']}
                    onChange={value => setInsData('SHIP_DATE', value)}
                    disabled
                />
                <TimePicker
                    style={{ width: 140 }}
                    label='일시'
                    value={insData['SHIP_TIME']}
                    onChange={value => setInsData('SHIP_TIME', value)}
                />
                {gdsParam &&
                <ComboSearch
                    label='상품'
                    selectProc='SLT_GAUGE_ITEM_GOODS_COMBO'
                    selectParam={gdsParam}
                    keyField='GDS_CD'
                    valueField='GDS_NM'
                    value={insData['GDS_CD']}
                    onChange={value => setInsData('GDS_CD', value)}
                    nullable
                />}
                {cstParam &&
                <ComboSearch
                    label='매입처'
                    selectProc='SLT_CUSTOMER_COMBO'
                    selectParam={cstParam}
                    keyField='CST_CD'
                    valueField='CST_NM'
                    value={insData['BUY_CST_CD']}
                    onChange={value => setInsData('BUY_CST_CD', value)}
                    nullable
                />}
                {outTrnParam &&
                <ComboSearch
                    label='출하처'
                    selectProc='SLT_TRANSPLACE'
                    selectParam={outTrnParam}
                    keyField='TSP_CD'
                    valueField='TSP_NM'
                    value={insData['SHIP_TRN_CD']}
                    onChange={value => setInsData('SHIP_TRN_CD', value)}
                    nullable
                />}
                {inTrnParam &&
                <ComboSearch
                    label='인도처'
                    selectProc='SLT_TRANSPLACE'
                    selectParam={inTrnParam}
                    keyField='TSP_CD'
                    valueField='TSP_NM'
                    value={insData['DEST_TRN_CD']}
                    onChange={value => setInsData('DEST_TRN_CD', value)}
                    nullable
                />}
                {realinTrnParam &&
                <ComboSearch
                    label='도착지'
                    selectProc='SLT_TRANSPLACE'
                    selectParam={realinTrnParam}
                    keyField='TSP_CD'
                    valueField='TSP_NM'
                    value={insData['REAL_DEST_CD']}
                    onChange={value => setInsData('REAL_DEST_CD', value)}
                    nullable
                />}                
                <NumberField
                    label='정산수량'
                    value={insData['CALC_QTY']}
                    onChange={value => setInsData('CALC_QTY', value)}
                />
            </div>
            <div className={classes.btnContainer}>
                {String(editState) === '1' &&
                <IconButton
                    tooltip='취소'
                    icon={<CloseIcon />}
                    onClick={() => onExitEdit()}
                />}
                <IconButton
                    tooltip='이전값 불러오기'
                    icon={<CachedIcon />}
                    onClick={() => getBefValue()}
                />
                <IconButton
                    tooltip='확인'
                    icon={<CheckIcon />}
                    onClick={() => onInsert()}
                />
            </div>
        </Paper>
    )
}

export default InboundCalcNotOrder