import React, { useRef } from 'react';
import { Document, StyleSheet, Text, View} from '@react-pdf/renderer';
import { PdfCell, PdfTable, PdfRow, PdfPage } from '../component';
import { numberFormat } from '../common/Utils';

const styles = StyleSheet.create({
    numberSort : {
        textAlign : 'right'
    },
    colSpanCell : {
        flex : 2,
        paddingRight : 1,
        backgroundColor : 'honeydew'
    },
    headerCell : {
        backgroundColor : 'lightyellow',
    },
    pageNumber: {
        position: 'absolute',
        bottom: 15,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
    signText : {
        borderBottom : 1,
        borderTop : 1,
        borderLeft : 1,
        padding : 4,
        justifyContent : 'center',
    },
})

const SupplyPdf = ({ workCd, workName, month, supplyData }) => {
    const stockTotalData = useRef();
    const toNumStr = value => value != null && numberFormat(value)

    const getTotal = (data, field) => (
        numberFormat(data.reduce((result, item) => result + item[field], 0))
    )

    const getRow = () => {
        const result = [];
        let items;
        stockTotalData.current = [];
        for (let i = 0; i < supplyData.length; i++) {
            const item = supplyData[i];
            const prevItem = supplyData[i - 1];
            const nextItem = supplyData[i + 1];
            const isEqualPrev = prevItem && item['BIZ_DATE'] === prevItem['BIZ_DATE'];
            const isEqualNext = nextItem && item['BIZ_DATE'] === nextItem['BIZ_DATE'];

            if (!isEqualPrev) {
                items = [];
            }

            items.push(
                <PdfRow key={i}>
                    <PdfCell style={isEqualNext && { borderBottom : 0 }} cellLeft>
                        {!isEqualPrev && item['BIZ_DATE']}
                    </PdfCell>
                    <PdfCell style={isEqualNext && { borderBottom : 0 }}>
                        {!isEqualPrev && item['GDS_NM']}
                    </PdfCell>
                    <PdfCell style={{ ...styles.numberSort, borderBottom: isEqualNext ? 0 : 1 }}>
                        {toNumStr(item['IN_QTY'])}
                    </PdfCell>
                    <PdfCell style={{ ...styles.numberSort, borderBottom: isEqualNext ? 0 : 1 }}>
                        {toNumStr(item['IN_PRICE'])}
                    </PdfCell>
                    <PdfCell style={{ ...styles.numberSort, borderBottom: isEqualNext ? 0 : 1 }}>
                        {toNumStr(item['SALE_QTY'])}
                    </PdfCell>
                    <PdfCell style={{ ...styles.numberSort, borderBottom: isEqualNext ? 0 : 1 }}>
                        {toNumStr(item['SALE_PRICE'])}
                    </PdfCell>
                    <PdfCell style={{ ...styles.numberSort, borderBottom: isEqualNext ? 0 : 1 }}>
                        {toNumStr(item['STOCK_QTY'])}
                    </PdfCell>
                    <PdfCell style={{ ...styles.numberSort, borderBottom: isEqualNext ? 0 : 1 }}>
                        {toNumStr(item['STOCK_PRICE'])}
                    </PdfCell>
                </PdfRow>
            );

            if (!supplyData[i + 1] || item['GDS_NM'] !== supplyData[i + 1]['GDS_NM']) {
                const groupData = supplyData.reduce((result, row) => (
                    row['GDS_NM'] === item['GDS_NM'] ?
                    [...result, row] :
                    result
                ), [])
                const lastDayData = supplyData.reduce((result, row) => (
                    row['BIZ_DATE'] === item['BIZ_DATE'] && row['GDS_NM'] === item['GDS_NM'] ?
                    [...result, row] :
                    result
                ), [])
                stockTotalData.current.push(...lastDayData);
                items.push(
                    <PdfRow>                                    
                        <PdfCell cellLeft style={styles.colSpanCell}>소 계</PdfCell>
                        <PdfCell style={styles.numberSort}>{getTotal(groupData,'IN_QTY')}</PdfCell>
                        <PdfCell/>
                        <PdfCell style={styles.numberSort}>{getTotal(groupData,'SALE_QTY')}</PdfCell>
                        <PdfCell/>
                        <PdfCell style={styles.numberSort}>{getTotal(lastDayData,'STOCK_QTY')}</PdfCell>
                        <PdfCell/>
                    </PdfRow>
                )
            }

            if (!isEqualNext) {
                result.push(
                    <View children={items} wrap={false} />
                )
            }
        }
        return result;
    }

    return(
        <Document title={`상품수불장_${workCd}_${month.replace(/-/g, '')}`}>
            <PdfPage orientation='landscape'>
                <Text style={{fontSize : 14, textAlign : 'center'}}>상 품 수 불 명 세 서</Text>
                <View style={{ flexDirection : 'row', justifyContent: 'space-between', marginBottom : 8 , marginTop : 8}}>
                    <Text style={{ fontSize : 12, alignSelf : 'flex-end' }}> {month} 상품 수불 명세서 </Text>
                    <View style={{ flexDirection : 'row' }}>
                        <View style={styles.signText}>
                            <Text>결{"\n"}재</Text>
                        </View>
                        <PdfTable style={{ width : 250, marginRight : 12 }}>
                            <PdfRow>
                                <PdfCell cellTop cellLeft>담당</PdfCell>
                                <PdfCell cellTop>부장</PdfCell>
                                <PdfCell cellTop>상무</PdfCell>
                                <PdfCell cellTop>전무</PdfCell>
                                <PdfCell cellTop>사장</PdfCell>
                            </PdfRow>
                            <PdfRow>
                                <PdfCell cellLeft style={{ height : 30 }}></PdfCell>
                                <PdfCell></PdfCell>
                                <PdfCell></PdfCell>
                                <PdfCell style={{ justifyContent : 'center' }}>전결</PdfCell>
                                <PdfCell></PdfCell>
                            </PdfRow>
                        </PdfTable>
                        <Text style={{ fontSize : 12, alignSelf : 'flex-end' }}>{workName}</Text>
                    </View>
                </View>
                <PdfTable>
                    <PdfRow fixed>
                        <PdfCell cellTop cellLeft  style={styles.headerCell}>영업일</PdfCell>
                        <PdfCell cellTop style={styles.headerCell}>상품명</PdfCell>
                        <PdfCell cellTop style={styles.headerCell}>입고수량</PdfCell>
                        <PdfCell cellTop style={styles.headerCell}>입고단가</PdfCell>
                        <PdfCell cellTop style={styles.headerCell}>판매수량</PdfCell>
                        <PdfCell cellTop style={styles.headerCell}>판매단가</PdfCell>
                        <PdfCell cellTop style={styles.headerCell}>재고</PdfCell>
                        <PdfCell cellTop style={styles.headerCell}>단가</PdfCell>
                    </PdfRow>
                    {getRow()}
                    <PdfRow>                                    
                        <PdfCell cellLeft style={styles.colSpanCell}>총 계</PdfCell>
                        <PdfCell style={styles.numberSort}>{getTotal(supplyData,'IN_QTY')}</PdfCell>
                        <PdfCell/>
                        <PdfCell style={styles.numberSort}>{getTotal(supplyData,'SALE_QTY')}</PdfCell>
                        <PdfCell/>
                        <PdfCell style={styles.numberSort}>{getTotal(stockTotalData.current,'STOCK_QTY')}</PdfCell>
                        <PdfCell/>
                    </PdfRow>
                </PdfTable>
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </PdfPage>
        </Document>
    )
}

export default SupplyPdf;