import React, { useState, useEffect, useMemo } from "react";
import moment from 'moment';
import clsx from 'clsx';
import { callProc, sendMail } from "../../common/DBConnector";
import { SearchPanel, SearchItem, ComboBox, DatePicker, DataGrid, DataColumn, Tabs, Tab, ComboSearch, IconButton, ProgressDialog, MailDialog } from "../../component";
import { makeStyles } from "@material-ui/core";
import PrintIcon from '@material-ui/icons/Print';
import PdfDialog from "../../component/PdfDialog";
import { TradingReportCarPdf, TradingReportGoodsPdf } from "../../reports";
import MailIcon from '@material-ui/icons/Mail';
import { isFieldDiff, numberPointFormat, readBlobAsync, totalCount } from '../../common/Utils';
import { useSnackbar } from "notistack";
import { Fragment } from "react";
import { BlobProvider } from "@react-pdf/renderer";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },

    tabContainer: {
        display: 'flex',
    },

    tabbar: {
        flex: 1,
        marginBottom: theme.spacing(1),
    },

    subTabContainer: {
        backgroundColor: theme.palette.secondary.main,
        boxShadow: theme.shadows[4],
        marginBottom: 8,
    },

    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },

    hide: {
        display: 'none'
    },
}))

const CustomerSale = () => {
    const [condition, setCondition] = useState(null);
    const [selectTab, setSelectTab] = useState(0);

    const [workDetail, setWorkDetail] = useState();
    const [cstDetail, setCstDetail] = useState();

    const [gdsSumData, setGdsSumData] = useState();
    const [cstBondData, setCstBondData] = useState();
    const [inMoneyData, setInMoneyData] = useState();
    const conf = useSelector(state => state.configReducer);

    const classes = useStyles();


    useEffect(() => {
        const fetchData = async () => {
            setInMoneyData(null);
            setGdsSumData(null);
            setCstBondData(null);
            
            setInMoneyData(await callProc('SLT_TRADEREPORT_INMONEY', condition));
            setGdsSumData(await callProc('SLT_CUSTOMER_SALE_GOODSSUM', condition));
            setCstBondData(await callProc('SLT_CUSTOMER_BOND_LIST', condition));
        }
        condition && fetchData();
    }, [condition])

    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => {
                sessionStorage['WORK_CD'] = condition['WORK_CD'];
                setCondition(condition);
                }}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_PRNT_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            onDataLoad={(jsonData, rawData) => {
                                setWorkDetail(rawData.reduce((result, item) => ({
                                    ...result,
                                    [item['WORK_CD']] : item    
                                }), {}));
                                return rawData;
                            }}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='CST_CD'
                    component={(state, setState, condition, setField) => {
                        let param = condition['CST_PARAM'] || null;
                        if (isFieldDiff(['WORK_CD', 'STA_DATE'], condition, param)) {
                            param = { 
                                WORK_CD: condition['WORK_CD'],
                                CST_TYPE: [1, 2],
                                CST_KIND: [1, 2],
                                STA_DATE: condition['STA_DATE'],
                            };
                            setField('CST_PARAM', param);
                        }
                        return (
                            <ComboSearch
                                label='거래처'
                                selectProc='SLT_CUSTOMER_COMBO'
                                selectParam={param}
                                keyField='CST_CD'
                                valueField='CST_NM'
                                value={state}
                                onChange={(value) => setState(value)}
                                onDataLoad={(jsonData, rawData) => {
                                    setCstDetail(rawData.reduce((result, item) => ({
                                        ...result,
                                        [item['CST_CD']] : item
                                    }), {}));
                                    return rawData;
                                }}
                            />
                        )
                    }}
                />
            </SearchPanel>
            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab label='차량별 일자별' />
                    <Tab label='상품별 일자별' />
                </Tabs>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <Car
                    condition={condition}
                    workDetail={workDetail}
                    cstDetail={cstDetail}
                    gdsSumData={gdsSumData}
                    cstBondData={cstBondData}
                    inMoneyData={inMoneyData}
                    conf={conf}
                />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <div></div>
                <Goods
                    condition={condition}
                    workDetail={workDetail}
                    cstDetail={cstDetail}
                    gdsSumData={gdsSumData}
                    cstBondData={cstBondData}
                    inMoneyData={inMoneyData}
                    conf={conf}
                />
            </div>
        </div>
    )
}

const carStyle = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
    },
    info: {
        flex: 2,
        marginRight: theme.spacing(1),
    },
    inMoneyinfo: {
        flex: 0.8,
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    carInfo: {
        flex: 0.5,
        marginLeft: theme.spacing(1),
    },
}))

const Car = ({ condition, workDetail, cstDetail, gdsSumData, cstBondData, inMoneyData, conf }) => {
    const [carData, setCarData] = useState();
    const [selectCar, setSelectCar] = useState([]);
    const [loading, setLoading] = useState(false);
    const [mailReciever, setMailReciever] = useState();

    const [pdfVisible, setPdfVisible] = useState(false);
    const [mailDialog, setMailDialog] = useState(false);

    const classes = carStyle();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const fetchData = async () => {
            setCarData(null);
            setCarData(await callProc('SLT_CUSTOMER_CARNO_SORT', condition));
            
        }
        condition && fetchData();
    }, [condition])

    const carGridData = useMemo(() => carData && {
        fields: carData.fields,
        data : selectCar.length ?
            carData.data.filter(row => (
                selectCar.find(carRow => carRow['CAR_NO'] === row['CAR_NO'])
            )) :
            carData.data
    }, [carData, selectCar]);

    const carFilterData = useMemo(() => ({
        fields: ['CAR_NO'],
        data: !carData ? [] : carData.data.reduce((result, row) => (
            result.find(resItm => resItm['CAR_NO'] === row['CAR_NO']) ?
            result :
            [...result, { CAR_NO: row['CAR_NO'] }]
        ), [])
    }), [carData]);

    const reqMailSend = (addr1, addr2) => {
        setLoading(true);
        setMailReciever([addr1, addr2]);
    }

    const onMailSend = async({ blob, error }) => {
        if (!error) {
            const base64data = await readBlobAsync(blob);

            const attach = {
                filename: `${workDetail[condition['WORK_CD']]['WORK_PRNT_NM']} 세금계산서.pdf`,
                path: base64data,
            };

            const result = await sendMail(
                workDetail[condition['WORK_CD']]['EMAIL'],
                workDetail[condition['WORK_CD']]['EMAIL_PASS'],
                workDetail[condition['WORK_CD']]['WORK_PRNT_NM'],
                mailReciever,
                `${workDetail[condition['WORK_CD']]['WORK_PRNT_NM']} 세금계산서`,
                '내용',
                attach
            )

            if (!result.err) {
                enqueueSnackbar('발송이 완료되었습니다.');
            }
        }
        
        setMailReciever(null);
        setLoading(false);
    }

    return (
        <div className={classes.container}>
            <DataGrid
                className={classes.info}
                title='차량별 일자별'
                totalGroup={['CAR_NO']}
                dataSet={carGridData}
                headerItem={() => (
                    <Fragment>
                        <IconButton
                            tooltip='메일 발송'
                            icon={<MailIcon />}
                            onClick={() => setMailDialog(true)}
                            disabled={!condition || !condition['WORK_CD'] || !condition['CST_CD'] || !carGridData || !inMoneyData || !cstBondData}
                        />
                        <IconButton
                            tooltip='출력'
                            icon={<PrintIcon />}
                            onClick={() => setPdfVisible(true)}
                            disabled={!condition || !condition['WORK_CD'] || !condition['CST_CD'] || !carGridData || !inMoneyData || !cstBondData}
                        />
                    </Fragment>
                )}
                virtualized
                cellProps={(row, fieldName, group) => fieldName === 'QTY' && ({
                    style: { textAlign: 'right' },
                })}
                horizonMode
                excelDownload
            >
                <DataColumn
                    fieldName='CAR_NO'
                    total={(values, data, group) => 
                        group == null ? `- 합 계 ${totalCount(values)} 건 -` : 
                        `(소 계 ${totalCount(values)} 건) ${data[0]['CAR_NO']}` 
                    } 
                />
                <DataColumn 
                    fieldName='QTY' 
                    value={(row, value) => numberPointFormat(value, 3)} 
                    total={(values) => numberPointFormat(values.reduce((result, value) => result + value, 0), 3)} 
                />
                <DataColumn fieldName='PRICE' value={(row, value) => numberPointFormat(value, 3)} />
                <DataColumn fieldName='SPLY_AMT' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='VAT' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='AMOUNT' total={(values) => values.reduce((result, value) => result + value, 0)} />
            </DataGrid>
            <DataGrid
                className={classes.inMoneyinfo}
                title='입금내역'
                dataSet={inMoneyData}
                horizonMode
                virtualized
            >
                <DataColumn fieldName='CARD_NM' title='입금수단' />
                <DataColumn
                    fieldName='AMOUNT'
                    title='금액'
                    total={(values) => values.reduce((result, value) => result + value, 0)}
                />
            </DataGrid>
            <DataGrid
                className={classes.carInfo}
                title='차량 선택'
                dataSet={carFilterData}
                onSelect={rows => setSelectCar(rows || [])}
                selectionMode='multi'
                horizonMode
            >
                <DataColumn
                    fieldName='CAR_CD'
                    visible={false}
                />
            </DataGrid>
            <ProgressDialog open={loading} />
            <PdfDialog
                open={pdfVisible}
                onClose={() => setPdfVisible(false)}
                title='거래명세서'
            >
                <TradingReportCarPdf
                    workInfo={condition && workDetail && workDetail[condition['WORK_CD']]}
                    cstInfo={condition && condition['CST_CD'] && cstDetail && cstDetail[condition['CST_CD']]}
                    date={condition}
                    data={carGridData && carGridData.data}
                    sumData={gdsSumData && gdsSumData.data}
                    cstBondData={cstBondData && cstBondData.data}
                    inMoneyData={inMoneyData && inMoneyData.data}
                    conf={conf}
                />
            </PdfDialog>
            <MailDialog
                open={mailDialog}
                data={condition && cstDetail[condition['CST_CD']]}
                onClose={() => setMailDialog(false)}
                onSend={reqMailSend}
            />
            {mailReciever && carGridData && cstDetail && workDetail && gdsSumData && cstBondData &&
            <BlobProvider
                document={
                    <TradingReportCarPdf
                        workInfo={condition && workDetail && workDetail[condition['WORK_CD']]}
                        cstInfo={condition && condition['CST_CD'] && cstDetail && cstDetail[condition['CST_CD']]}
                        date={condition}
                        data={carGridData && carGridData.data}
                        sumData={gdsSumData && gdsSumData.data}
                        cstBondData={cstBondData && cstBondData.data}
                        inMoneyData={inMoneyData && inMoneyData.data}
                        conf={conf}
                    />
                }
            >
                {(state) => {
                    !state.loading && onMailSend(state);
                    return null;
                }}
            </BlobProvider>}
        </div>
    )
}

const goodsStyle = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
    },
    info: {
        flex: 2,
        marginRight: theme.spacing(1),
    },
    inMoneyinfo: {
        flex: 1,
        marginLeft: theme.spacing(1),
    },
}))

const Goods = ({ condition, workDetail, cstDetail, gdsSumData, cstBondData, inMoneyData, conf }) => {
    const [oilData, setOilData] = useState();
    const [loading, setLoading] = useState(false);
    const [mailReciever, setMailReciever] = useState();

    const [pdfVisible, setPdfVisible] = useState(false);
    const [mailDialog, setMailDialog] = useState(false);

    const classes = goodsStyle();
    const { enqueueSnackbar } = useSnackbar();
    
    useEffect(() => {
        const fetchData = async () => {
            setOilData(null);
            setOilData(await callProc('SLT_CUSTOMER_GOODS_SORT', condition));
        }
        condition && fetchData();
    }, [condition])

    const reqMailSend = (addr1, addr2) => {
        setLoading(true);
        setMailReciever([addr1, addr2]);
    }

    const onMailSend = async({ blob, error }) => {
        if (!error) {
            const base64data = await readBlobAsync(blob);

            const attach = {
                filename: `${workDetail[condition['WORK_CD']]['WORK_PRNT_NM']} 세금계산서.pdf`,
                path: base64data,
            };

            const result = await sendMail(
                workDetail[condition['WORK_CD']]['EMAIL'],
                workDetail[condition['WORK_CD']]['EMAIL_PASS'],
                workDetail[condition['WORK_CD']]['WORK_PRNT_NM'],
                mailReciever,
                `${workDetail[condition['WORK_CD']]['WORK_PRNT_NM']} 세금계산서`,
                '내용',
                attach
            )

            if (!result.err) {
                enqueueSnackbar('발송이 완료되었습니다.');
            }
        }
        
        setMailReciever(null);
        setLoading(false);
    }

    return (
        <div className={classes.container}>
            <DataGrid
                title='상품별 일자별'
                className={classes.info}
                totalGroup={['GDS_NM']}
                dataSet={oilData}
                headerItem={() => (
                    <Fragment>
                        <IconButton
                            tooltip='메일 발송'
                            icon={<MailIcon />}
                            onClick={() => setMailDialog(true)}
                            disabled={!condition || !condition['WORK_CD'] || !condition['CST_CD'] || !oilData || !inMoneyData || !cstBondData}
                        />
                        <IconButton
                            tooltip='출력'
                            icon={<PrintIcon />}
                            onClick={() => setPdfVisible(true)}
                            disabled={!condition || !condition['WORK_CD'] || !condition['CST_CD'] || !oilData || !inMoneyData || !cstBondData}
                        />
                    </Fragment>
                )}
                virtualized
                cellProps={(row, fieldName, group) => fieldName === 'QTY' && ({
                    style: { textAlign: 'right' },
                })}
                horizonMode
                excelDownload
            >
                <DataColumn
                    fieldName='GDS_NM'
                    total={(values, data, group) => 
                        group == null ? `- 합 계 ${totalCount(values)} 건 -` : 
                        `(소 계 ${totalCount(values)} 건) ${data[0]['GDS_NM']}` 
                    } 
                />
                <DataColumn 
                    fieldName='QTY' 
                    value={(row, value) => numberPointFormat(value, 3)} 
                    total={(values) => numberPointFormat(values.reduce((result, value) => result + value, 0), 3)} 
                />
                <DataColumn fieldName='PRICE' value={(row, value) => value.toFixed(3)} />
                <DataColumn fieldName='SPLY_AMT' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='VAT' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='AMOUNT' total={(values) => values.reduce((result, value) => result + value, 0)} />
            </DataGrid>
            <DataGrid
                className={classes.inMoneyinfo}
                title='입금내역'
                dataSet={inMoneyData}
                horizonMode
                virtualized
            >
                <DataColumn fieldName='CARD_NM' title='입금수단' />
                <DataColumn 
                    fieldName='AMOUNT' 
                    title='금액' 
                    total={(values) => values.reduce((result, value) => result + value, 0)}
                />
            </DataGrid>
            <ProgressDialog open={loading} />
            <PdfDialog
                open={pdfVisible}
                onClose={() => setPdfVisible(false)}
                title='거래명세서'
            >
                <TradingReportGoodsPdf
                    workInfo={condition && workDetail && workDetail[condition['WORK_CD']]}
                    cstInfo={condition && condition['CST_CD'] && cstDetail && cstDetail[condition['CST_CD']]}
                    date={condition}
                    data={oilData && oilData.data}
                    sumData={gdsSumData && gdsSumData.data}
                    cstBondData={cstBondData && cstBondData.data}
                    inMoneyData={inMoneyData && inMoneyData.data}
                    conf={conf}
                />
            </PdfDialog>
            <MailDialog
                open={mailDialog}
                data={condition && cstDetail[condition['CST_CD']]}
                onClose={() => setMailDialog(false)}
                onSend={reqMailSend}
            />
            {mailReciever && oilData && cstDetail && workDetail && gdsSumData && cstBondData &&
            <BlobProvider
                document={
                    <TradingReportGoodsPdf
                        workInfo={condition && workDetail && workDetail[condition['WORK_CD']]}
                        cstInfo={condition && condition['CST_CD'] && cstDetail && cstDetail[condition['CST_CD']]}
                        date={condition}
                        data={oilData && oilData.data}
                        sumData={gdsSumData && gdsSumData.data}
                        cstBondData={cstBondData && cstBondData.data}
                        inMoneyData={inMoneyData && inMoneyData.data}
                        conf={conf}
                    />
                }
            >
                {(state) => {
                    !state.loading && onMailSend(state);
                    return null;
                }}
            </BlobProvider>}
        </div>
    )
}

export default CustomerSale;