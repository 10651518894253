import React from 'react';
import { Tabs, makeStyles, AppBar } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    tabs: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
    },
    tabIndicator: {
        backgroundColor: theme.palette.primary.main,
    },
}));

export default (props) => {
    const { appbarProps, ...tabProps } = props;
    const classes = useStyles();

    return (
        <AppBar
            position='relative'
            {...appbarProps}
        >
            <Tabs
                className={classes.tabs}
                TabIndicatorProps={{ className: classes.tabIndicator }}
                variant="scrollable"
                scrollButtons="auto"
                {...tabProps}
            />
        </AppBar>
    )
}