import React, { useState, useRef, useMemo } from 'react';
import { ComboBox, DatePicker, DataGrid, NumberField, ComboSearch, DataColumn, IconButton, EditableField, EditableList, TextField } from '../../component';
import { Paper, makeStyles } from '@material-ui/core';
import { callProc } from '../../common/DBConnector';
import { useSnackbar } from 'notistack';
import { isFieldDiff, useFieldInputs } from '../../common/Utils';
import { useSelector } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';

const stateType = {
    0: '차변',
    1: '대변'
}

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flex: 3,
        height: 0,
        flexDirection: 'column'
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginTop: theme.spacing(1),
    },
    enroll: {
        display: 'flex',
        flexDirection: 'column',
        flex: 2,
        marginBottom: theme.spacing(1),
        height: 0,
    },
    paperTitle: {
        display: 'flex',
        justifyContent: 'end',
        padding: theme.spacing(1),
    },
    field: {
        width: 180,
        marginRight: theme.spacing(1),
    },
    searchPaper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    searchContainer: {
        flex: 1,
        display: 'inline-block',
        flexDirection: 'row',
    },
    searchItem: {
        display: 'inline-block',
        width: 200,
        marginRight: 100,
        marginBottom: theme.spacing(1),
    },
}))

const AccountingStatementEnroll = () => {
    const [rowSelect, setRowSelect] = useState({});
    const isSumEqual = useRef();
    const { enqueueSnackbar } = useSnackbar();
    
    const classes = useStyles();

    const clsState = useSelector(state => state.clsDateReducer);

    const [input, setInput] = useFieldInputs({
        WORK_CD: sessionStorage['WORK_CD'],
        ACJ_DATE: clsState[sessionStorage['WORK_CD']],
    });

    const condition = useMemo(() => {
        setRowSelect({});
        return input['WORK_CD'] && input['ACJ_DATE'] && input
    }, [input]);

    const gridRefresh = () => {
        setRowSelect({});
        setInput({ type: 'UPDATE', value: { ...input } });
    }

    const onDelete = async(row) => {
        const result = await callProc('DLT_ACCOUNTJOURNAL_STATEMENT_SUM', row);

        if(!result.err){
            gridRefresh();
        }
    }

    const onSelect = async (row) => {
        if (row != null) {
            setRowSelect(row);
        }
    } 


    const valid = (data) => {
        if(data.reduce((result, item) => (result == true || item['ACC_CD'] == null) ? true : false , false)) {
            enqueueSnackbar('계정과목이 없는 행이 있습니다. 확인해주세요.', {variant: 'error'});
            return false;
        }

        if(data.length === 0){
            enqueueSnackbar('생성된 행이 없습니다. 확인해주세요.', {variant: 'error'});
            return false;
        }

        if(!isSumEqual.current){
            enqueueSnackbar('차변 / 대변 합이 다릅니다. 확인해주세요.', {variant: 'error'});
            return false;
        }
        
        return true;
    }

    const onUpdate = async(data) => {
        if(valid(data)){
            const result = await callProc('INS_ACCOUNTJOURNAL_STATEMENT', {  
                ACJ_CD: rowSelect && rowSelect['ACJ_CD'],
                ...condition, 
                JSON: data });

            if (!result.err) {
                enqueueSnackbar('저장이 완료되었습니다.');
                gridRefresh();
            }
        } else {
            return false;
        }
    }

    return(
            <div className={classes.container}>
                <Paper className={classes.searchPaper}>
                    <div className={classes.searchContainer}>
                        <div className={classes.searchItem}>
                            <ComboBox
                                label='사업장'
                                selectProc='SLT_WORKPLACEINFO_AUTH'
                                keyField='WORK_CD'
                                valueField='WORK_NM'
                                value={condition && condition['WORK_CD']}
                                onChange={value => {
                                    if (input['WORK_CD'] !== value) {
                                        sessionStorage['WORK_CD'] = value;
                                        setInput('ACJ_DATE', clsState[value]);
                                        setInput('WORK_CD', value);
                                    }
                                }}
                            />
                        </div>
                        <div className={classes.searchItem}>
                            <DatePicker
                                label='영업일'
                                minDate={clsState[input['WORK_CD']]}
                                value={condition && condition['ACJ_DATE']}
                                onChange={value => {
                                    if (input['ACJ_DATE'] !== value) {
                                        setInput('ACJ_DATE', value);
                                        if(condition && condition['ACJ_DATE'] < clsState[condition['WORK_CD']]) {
                                            setInput({ type: 'UPDATE', value: { ...input, 'ACJ_DATE': value } });
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <IconButton
                        tooltip=''
                        icon={<SearchIcon />}
                        onClick={gridRefresh}
                    />
                </Paper>
                <div className={classes.content}>
                    <EditableList
                        className={classes.enroll}
                        title='분개 등록'
                        selectProc='SLT_ACCOUNTJOURNAL_STATEMENT_SUM_DETAIL'
                        selectParam={rowSelect}
                        bottomSticky={data => {
                            const debtor = data.reduce((result, item) => result + parseInt(item['DEBTOR'] || 0), 0);
                            const creditor = data.reduce((result, item) => result + parseInt(item['CREDITOR'] || 0), 0);
                            isSumEqual.current = debtor === creditor;

                            return (
                                <div>
                                    <NumberField
                                        label='차변 합'
                                        value={debtor}
                                        disabled
                                    />
                                    <NumberField
                                        style={{marginLeft: 8}}
                                        label='대변 합'
                                        value={creditor}
                                        disabled
                                    />
                                </div>
                            )
                        }}
                        onUpdate={onUpdate}
                        alwaysAddable
                        defaultValue={{DEBTOR: 0, CREDITOR: 0, ACC_CD: null, GDS_CD: null, CST_CD: null, ...condition}} 
                    >
                        <EditableField
                            fieldName='STATE_TYPE'
                            component={({value, setState, dispatch}) => (
                                <ComboBox
                                    style={{ width: 100 }}
                                    label='전표구분'
                                    data={stateType}
                                    value={value}
                                    onChange={value => {
                                        String(value) === '0' ? dispatch('CREDITOR', 0) : dispatch('DEBTOR', 0);
                                        setState(value);
                                    }}
                                />
                            )}
                        />
                        <EditableField
                            fieldName='ACC_CD'
                            component={({value, setState}) => (
                                <ComboSearch
                                    style={{ width: 180 }}
                                    label='계정과목'
                                    selectProc='SLT_ACCOUNT_ALL'
                                    keyField='ACC_CD'
                                    valueField='ACC_NM'
                                    value={value}
                                    onChange={value => setState(value)}
                                    nullable
                                />
                            )}
                        />
                        <EditableField
                            fieldName='DEBTOR'
                            component={({value, setState, rowData}) => (
                                <NumberField
                                    style={{ width: 120 }}
                                    label='차변'
                                    value={value}
                                    onChange={value => setState(value)}
                                    disabled={String(rowData['STATE_TYPE']) === '1'}
                                />
                            )}
                        />
                        <EditableField
                            fieldName='CREDITOR'
                            component={({value, setState, rowData}) => (
                                <NumberField
                                    style={{ width: 120 }}
                                    label='대변'
                                    value={value}
                                    onChange={value => setState(value)}
                                    disabled={String(rowData['STATE_TYPE']) === '0'}
                                />
                            )}
                        />
                        <EditableField
                            fieldName='GDS_CD'
                            component={({value, setState, rowData, dispatch}) => {   
                                let param = rowData['GDS_PARAM'] || null;
                                if(isFieldDiff(['WORK_CD'],rowData, param)){
                                    param = {
                                        WORK_CD: rowData['WORK_CD'],
                                        GDS_TYPE: 'ALL',
                                        NOT_TYPE: 0,
                                        GDS_TYPE_M: 'ALL',
                                        NOT_TYPE_M: 0,
                                        GAUGE_TYPE: 'ALL',
                                        STA_DATE: rowData['ACJ_DATE'],
                                    };
                                    dispatch('GDS_PARAM', param);
                                }      
                                return(                    
                                <ComboSearch
                                    style={{ width: 250 }}
                                    label='상품명'
                                    selectProc='SLT_GAUGE_ITEM_GOODS_COMBO'
                                    selectParam={param}
                                    keyField='GDS_CD'
                                    valueField='GDS_NM'
                                    value={value}
                                    onChange={value => setState(value)}
                                    nullable
                                />)
                            }}
                        />
                        <EditableField
                            fieldName='CST_CD'
                            component={({value, setState, rowData, dispatch}) => {   
                                let param = rowData['CST_PARAM'] || null;
                                if(isFieldDiff(['WORK_CD'],rowData, param)){
                                    param = {
                                        WORK_CD: rowData['WORK_CD'],
                                        CST_TYPE: 'ALL',
                                        CST_KIND: 'ALL',
                                        STA_DATE: rowData['ACJ_DATE'],
                                    };
                                    dispatch('CST_PARAM', param);
                                }      
                                return(                    
                                <ComboSearch
                                    style={{ width: 250 }}
                                    label='거래처명'
                                    selectProc='SLT_CUSTOMER_COMBO'
                                    selectParam={param}
                                    keyField='CST_CD'
                                    valueField='CST_NM'
                                    value={value}
                                    onChange={value => setState(value)}
                                    nullable
                                />)
                            }}
                        />
                        <EditableField
                            fieldName='SUMUP'
                            component={({value, setState}) => (
                                <TextField
                                    label='적요'
                                    value={value}
                                    onChange={value => setState(value)}
                                />
                            )}
                        />
                        <EditableField fieldName='ACJ_CD' visible={false}/>
                        <EditableField fieldName='SEQ' visible={false}/>
                        <EditableField fieldName='WORK_CD' visible={false}/>
                        <EditableField fieldName='ACJ_DATE' visible={false}/>
                    </EditableList>
                    <DataGrid
                        className={classes.info}
                        title='회계전표'
                        selectProc='SLT_ACCOUNTJOURNAL_STATEMENT_SUM'
                        onDelete={onDelete}
                        selectParam={condition}
                        onSelect={row => onSelect(row)}
                        selectionMode='single'
                        horizonMode
                    >
                        <DataColumn fieldName='WORK_CD' visible={false} />
                    </DataGrid>
                </div>
            </div>
    )
}

export default AccountingStatementEnroll;