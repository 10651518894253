
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { DataGrid, DataColumn, SearchPanel } from '../../component';

const useStyle = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },

}))

const CloseList = () => {
    const classes = useStyle();
    const [condition, setCondition] = useState(null);

    return (
        <div className={classes.container}>
            <SearchPanel onSearch={condition => setCondition(condition)}>
            </SearchPanel>
            <DataGrid
                title='마감 조회'
                selectProc='SLT_CLOSDATE_LIST'
                selectParam={condition}
                horizonMode
                selectionMode='single'
                sortable
                filterable
            >
                <DataColumn fieldName='OIL_WORK_DT' title='영업장 마감' />
                <DataColumn fieldName='WORK_DT' title='사업장 일 마감' />
            </DataGrid>
        </div>
    )
}

export default CloseList;