import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';


const CheckBox = ({ label, onChange, ...props }) => {
    return (
        <FormControlLabel
            label={label}
            control={
                <Checkbox
                    onChange={e => onChange(e.target.checked)}
                    color='primary'
                    {...props}
                />
            }
        />
    )
}

export default CheckBox;