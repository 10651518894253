import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DatePicker, DataColumn, Tab, Tabs, IconButton, PdfButton } from '../../component';
import { Dialog, DialogTitle, makeStyles } from '@material-ui/core';
import { proType } from '../../common/Dictionary';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { callProc } from '../../common/DBConnector';
import { useSnackbar } from 'notistack';
import { useFieldInputs } from '../../common/Utils';
import { Fragment } from 'react';
import { InboundTaxEnrollListPdf, InboundTaxEnrollTodoPdf } from '../../reports';
import { useSelector } from 'react-redux';

const sort = (a, b) => a['GDS_NM'] > b['GDS_NM'] ? 1 : -1

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        width: 0,
    },
    tabContainer: {
        display: 'flex',
    },
    tabbar: {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    tab: {
        minWidth: 100,
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
    hide: {
        display: 'none'
    },
}))


const InboundTaxEnroll = () => {
    const [condition, setCondition] = useState(null);
    const [selectTab, setSelectTab] = useState(0);
    const refreshGrid = () => setCondition({...condition});
    const classes = useStyles();
    const [workData, setWorkData] = useState();

    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => {
                sessionStorage['WORK_CD'] = condition['WORK_CD'];
                setCondition(condition)}}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            onDataLoad={(jsonData, rawData) => {
                                const result = [...rawData]
                                setWorkData(result);
                                return result;
                            }}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
            </SearchPanel>
            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab className={classes.tab} label='발행 예정' />
                    <Tab className={classes.tab} label='발행 내역' />
                </Tabs>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <IssueEnroll condition={condition} refreshGrid={refreshGrid} workData={workData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <IssueList condition={condition} refreshGrid={refreshGrid} workData={workData} />
            </div>
        </div>
    )
}

const IssueEnroll = ({ condition, refreshGrid, workData }) => {
    const [gridParam, setGridParam] = useState(null);
    const [saveOpen, setSaveOpen] = useState(null);
    const [pdfData, setPdfData] = useState();
    const loginState = useSelector(state => state.loginReducer);


    useEffect(() => {
        condition && setGridParam({...condition, TAX_TYPE: 0})
    }, [condition])

    const pdfComponent = useMemo(() => (
        <PdfButton title='매입계산서수취발행 발행예정'>
            {pdfData &&
            <InboundTaxEnrollTodoPdf
                pdfData={pdfData}
                condition={condition}
                loginState={loginState}
                workName={workData.find(item => item['WORK_CD'] === condition['WORK_CD'])['WORK_NM']}
            />}
        </PdfButton>
    ), [pdfData, condition, loginState, workData]);

    return (
        <Fragment>
            <DataGrid
                title='발행 예정'
                selectProc='SLT_INBOUNDTAX'
                selectParam={gridParam}
                selectionMode='multi'
                headerItem={({selection, visibleAoa}) => {
                    setPdfData(visibleAoa);
                    return (
                    <>
                    <IconButton
                        tooltip='확인'
                        icon={<CheckIcon />}
                        onClick={() => setSaveOpen(selection)}
                    />
                    {pdfComponent}
                    </>)
                }}
                horizonMode
                virtualized
                totalGroup={['GDS_NM']}
                totalSort={sort}
                excelDownload={{ fileName: '매입계산서수취발행-발행예정.xlsx', sheetName: '발행예정' }}
                stickyHeader
                
            >
                <DataColumn fieldName='GDS_NM' total={(values, data, group) => group ? group['GDS_NM'] + ' 소계' : '총계'} />
                <DataColumn fieldName='PRO_TYPE' title='구분' value={(row, value) => proType[value]} />
                <DataColumn fieldName='SPLY_AMT' title='공급가액' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='VAT' title='부가세' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='AMOUNT' title='금액' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='CODE' visible={false} />
                <DataColumn fieldName='CST_CD' visible={false} />
                <DataColumn fieldName='GDS_CD' visible={false} />
            </DataGrid> 
            <SaveDialog
                selectionData={saveOpen}
                close={() => setSaveOpen(null)}
                condition={condition}
                refreshGrid={refreshGrid}
            />

        </Fragment>
    )
}

const IssueList = ({ condition, refreshGrid, workData }) => {
    const [gridParam, setGridParam] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const [pdfData, setPdfData] = useState();
    const loginState = useSelector(state => state.loginReducer);


    useEffect(() => {
        condition && setGridParam({...condition, TAX_TYPE: 1})
    }, [condition])

    const onClick = async(selection) => {
        const result = await callProc('UPT_INBOUNDTAX_CANCEL', { ...condition, JSON: selection, });
        
        if(!result.err){
            enqueueSnackbar('저장이 완료되었습니다.');
            refreshGrid();
        }
    }

    const pdfComponent = useMemo(() => (
        <PdfButton title='매입계산서수취발행 발행내역'>
            {pdfData &&
            <InboundTaxEnrollListPdf
                pdfData={pdfData}
                condition={condition}
                loginState={loginState}
                workName={workData.find(item => item['WORK_CD'] === condition['WORK_CD'])['WORK_NM']}
            />}
        </PdfButton>
    ), [pdfData, condition, loginState, workData]);

    return (
        <>
        <DataGrid
            title='발행 내역'
            selectProc='SLT_INBOUNDTAX_ISSUE_LIST'
            selectParam={gridParam}
            selectionMode='multi'
            headerItem={({selection, visibleAoa}) => {
                setPdfData(visibleAoa);
                return (
                <>
                <IconButton
                    tooltip='취소'
                    icon={<CloseIcon />}
                    onClick={() => onClick(selection)}
                />
                {pdfComponent}
                </>)
            }}
            horizonMode
            virtualized
            totalGroup={['GDS_NM']}
            excelDownload={{ fileName: '매입계산서수취발행-발행내역.xlsx', sheetName: '발행내역' }}
            stickyHeader
        >
            <DataColumn fieldName='GDS_NM' total={(values, data, group) => group ? group['GDS_NM'] + ' 소계' : '총계'} />
            <DataColumn fieldName='PRO_TYPE' title='구분' value={(row, value) => proType[value]} />
            <DataColumn fieldName='SPLY_AMT' title='공급가액' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='VAT' title='부가세' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='AMOUNT' title='금액' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='CODE' visible={false} />
            <DataColumn fieldName='CST_CD' visible={false} />
            <DataColumn fieldName='GDS_CD' visible={false} />
        </DataGrid>

        </>
    )
}

const useDialogStyles = makeStyles(theme => ({
    dialogPaper: {
        maxWidth: 'none',
        width: 300,
        height: 200,
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    okButton: {
        display: 'flex',
    },
    fieldContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    Field: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1),
    }
}))

const SaveDialog = ({ selectionData, close, condition, refreshGrid }) => {
    const [insData, setInsData] = useFieldInputs(condition);
    const { enqueueSnackbar } = useSnackbar();
    const classes = useDialogStyles();
    

    useEffect(() => {
        setInsData('WORK_CD', condition && condition['WORK_CD']);
        setInsData('TAX_DATE', condition && condition['END_DATE']);
    }, [condition, setInsData]) 

    const onClick = async() => {
        const result = await callProc('UPT_INBOUNDTAX', {...condition, CODE: selectionData, TAX_DATE: insData['TAX_DATE']});
        
        if(!result.err){
            enqueueSnackbar('저장이 완료되었습니다.');
            close();
            refreshGrid();
        }
    }

    return (
        <Dialog
            open={selectionData != null && selectionData.length > 0}
            PaperProps={{ className: classes.dialogPaper }}
        >
            <div className={classes.dialogTitle}>
                <DialogTitle>매입계산서 발행</DialogTitle>
                <div className={classes.okButton}>
                    <IconButton
                        tooltip='확인'
                        onClick={onClick}
                        icon={<CheckIcon />}
                    />
                </div>
                <IconButton
                    tooltip='닫기'
                    onClick={close}
                    icon={<CloseIcon />}
                />
            </div>
            <div className={classes.fieldContainer}>
                <ComboBox
                    className={classes.Field}
                    label='사업장명'
                    selectProc='SLT_WORKPLACEINFO_AUTH'
                    keyField='WORK_CD'
                    valueField='WORK_NM'
                    value={insData && insData['WORK_CD']}
                    disabled
                    fullWidth={false}
                />
                <DatePicker
                    className={classes.Field}
                    label='영업일'
                    value={insData && insData['TAX_DATE']}
                    onChange={(value) => setInsData('TAX_DATE', value)}
                />
            </div>
            
        </Dialog>
    )
}

export default InboundTaxEnroll;