import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import { SearchPanel, SearchItem, ComboBox, DataGrid, DataColumn, DatePicker, PdfButton } from '../../component';
import { GaugeListPdf } from '../../reports';
import { useSelector } from 'react-redux';
import { callProc } from '../../common/DBConnector';

const sort = (a, b) => a['GDS_TYPE'] > b['GDS_TYPE'] ? 1 : -1;

const useStyle = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
}))

const GaugeList = () => {
    const classes = useStyle();
    const [condition, setCondition] = useState(null);
    const [pdfData, setPdfData] = useState();
    const loginState = useSelector(state => state.loginReducer);
    const [workData, setWorkData] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const workData = await callProc('SLT_WORKPLACEINFO_NOTMAIN_AUTH');

            setWorkData(workData.data.reduce((result, item) => ({
                ...result,
                [item['WORK_CD']]: item['WORK_NM']
            }), {}))

        }
        fetchData();
    }, [])

    const condWorkName = workData && condition && workData[condition['WORK_CD']];
    const pdfComponent = useMemo(() => (
        <PdfButton title='계기 조회'>
            {pdfData &&
            <GaugeListPdf
                pdfData={pdfData}
                condition={condition}
                loginState={loginState}
                workName={condWorkName}
            />}
        </PdfButton>
    ), [pdfData, condition, loginState, condWorkName]);


    return(
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => {
                if (condition['WORK_CD'] !== 'ALL'){
                    sessionStorage['WORK_CD'] = condition['WORK_CD'];
                }
                setCondition(condition);
                }}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_OIL_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
            </SearchPanel>
            <DataGrid
                title='계기 조회'
                selectProc='SLT_GAUGE_LIST'
                selectParam={condition}
                selectionMode='single'          
                totalGroup={['GDS_TYPE', 'GDS_NM']}
                totalSort={sort}
                virtualized
                horizonMode
                sortable
                filterable
                excelDownload
                headerItem={({visibleAoa}) => {
                    setPdfData(visibleAoa);
                    return pdfComponent;
                }}
                stickyHeader
            >
                <DataColumn 
                    fieldName='GDS_NM' 
                    title='상품명' 
                    total={(values, data, group) => group ? group['GDS_NM'] + ' 소계' : '총계'}  />
                <DataColumn fieldName='BEF_GAUGE_ELE' title='전일계기'/>
                <DataColumn fieldName='CUR_GAUGE_ELE' title='현재계기'/>
                <DataColumn fieldName='GAUGE_ELE' title='계기차' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='OUT_QTY' title='출하량' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='EMPTY_QTY' title='공회전' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='MOVE_QTY' title='이관' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='HOME_QTY' title='홈로리이관' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='TEST_QTY' title='검량' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='SELF_QTY' title='자가소비' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='KEEP_OUT_QTY' title='보관출고' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='SERVICE_QTY' title='서비스' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='ETC_QTY' title='기타' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='GAUGE_ETC' title='계기외판매' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='GDS_TYPE'  visible={false}  />
            </DataGrid>
        </div>
    )
}




export default GaugeList