import React, { useEffect, useRef, useState } from 'react';
import { DataGrid, DataColumn, ComboBox, AddressPicker, IconButton, DatePicker, TextField, Tabs, Tab, SearchPanel, ProgressPanel } from '../../component';
import { bizType, stockType, } from '../../common/Dictionary';
import { Dialog, DialogTitle, makeStyles, lighten, Typography } from '@material-ui/core';
import { useFieldInputs } from '../../common/Utils';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';
import ReactIsCapsLockActive from '@matsun/reactiscapslockactive'
import { Close, Check, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { callProc } from '../../common/DBConnector';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    content: {
        flex: 1,
        height: 0,
    },
    tabContainer : {
        display : 'flex',
    },
    tabbar : {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    hide : {
        display: 'none'
    },
}));

const WorkPlaceInfo = () => {
    const [condition, setCondition] = useState(null);
    const [selectTab, setSelectTab] = useState(0);
    const classes = useStyles();
    const gridRefresh = () => setCondition({...condition});


    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => setCondition(condition)}></SearchPanel>
            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab label='사업장' />
                    <Tab label='종료 사업장' />
                </Tabs>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <Workplace condition={condition} endType={0} workName={'사업장'} gridRefresh={gridRefresh}/> 
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <Workplace condition={condition} endType={1} workName={'종료 사업장'} gridRefresh={gridRefresh}/>
            </div>
        </div>
    )
}

const Workplace = ({condition, endType, workName, gridRefresh}) => {
    const schData = useRef();
    const [gridparam , setGridParam] = useState(null);

    const [oderbyDialogOpen, setOrderbyDialogOpen] = useState(false);
    const [wpiData, setWpiData] = useState(null);
    const [totalData, settotalData] = useState();

    // useEffect(() => {
    //     const fetchData = async() => {
    //         settotalData(null);

    //         const totaldata = await callProc('SLT_WORKPLACEINFO', { END_TYPE: 2 });
    //         settotalData(totaldata.data);

    //     }
    //     fetchData();
    // }, [gridRefresh])

    const dialogOpen = () => {
        
        setOrderbyDialogOpen(true);
        setWpiData(totalData);
    }

    useEffect(() => {
        condition && setGridParam({...condition, END_TYPE: endType});
    },[condition, endType])

    const getFieldValue = (row, fieldName) => 
        row['WORK_PRNT'] != null && schData.current && schData.current[row['WORK_PRNT']] != null ?
        schData.current[row['WORK_PRNT']][fieldName] :
        row[fieldName];
    
    const onInsert = async(row) => {
        row['WORK_TELNO1_ARR'] = row['WORK_TELNO1'] ? row['WORK_TELNO1'].split('-') : null;
        row['WORK_TELNO2_ARR'] = row['WORK_TELNO2'] ? row['WORK_TELNO2'].split('-') : null;

        const result = await callProc('INS_WORKPLACEINFO', row);

        if(!result.err) {
            gridRefresh();
            return false;
        }
    }

    const onUpdate = async(row) => {
        row['WORK_TELNO1_ARR'] = row['WORK_TELNO1'] ? row['WORK_TELNO1'].split('-') : null;
        row['WORK_TELNO2_ARR'] = row['WORK_TELNO2'] ? row['WORK_TELNO2'].split('-') : null;
        const result = await callProc('UPT_WORKPLACEINFO', row);

        if(!result.err) {
            gridRefresh();
            return false;
        }
    }

    const onDelete = async(row) => {
        const result = await callProc('DLT_WORKPLACEINFO', row);

        if(!result.err) {
            gridRefresh();
            return false;
        }
    }

    return (
        <>
            <DataGrid
                title={workName}
                table='tb_workplaceinfo'
                selectProc='SLT_WORKPLACEINFO'
                onInsert={onInsert}
                onUpdate={onUpdate}
                onDelete={onDelete}
                selectParam={gridparam}
                selectionMode='single'
                onDataLoad={dataSet => {
                    schData.current = dataSet.data.reduce((result, row) => ({
                        ...result,
                        [row['WORK_CD']]: row
                    }), {});

                    dataSet.data = dataSet.data.map(row => ({
                        ...row,
                        WORK_TYPE: JSON.parse(row['WORK_TYPE'])
                    }));
                    return dataSet;
                }}
                horizonMode
                // headerItem={() => 
                //     {return(endType === 0 && <IconButton
                //         tooltip='순서등록'
                //         icon={<FormatLineSpacing />}
                //         onClick={() => dialogOpen()}
                //     />)}
                // }
            >            
                <DataColumn
                    fieldName='WORK_CD'
                    editable={(state) => (state)}
                />
                <DataColumn
                    fieldName='WORK_NM'
                    editable={(state, dispatch) => (
                        <TextField
                            style={{width: 150}}
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                />
                <DataColumn
                    fieldName='WORK_SHORT_NM'
                    editable={(state, dispatch) => (
                        <TextField
                            style={{width: 100}}
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                />
                <DataColumn
                    fieldName='WORK_PRNT'
                    value={row => row['WORK_PRNT_NM']}
                    defaultValue={null}
                    editable={(state, dispatch, rowState) =>
                        <ComboBox
                            style={{width: 100}}
                            selectProc='SLT_WORKPLACEINFO'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            onDataLoad={jsonData => {
                                delete jsonData[rowState['WORK_CD']];
                                return jsonData;
                            }}
                            value={state}
                            onChange={(value) => dispatch(value)}
                            nullable
                        />
                    }
                />
                <DataColumn
                    fieldName='CEO'
                    value={row => getFieldValue(row, 'CEO')}
                    editable={(state, dispatch, rowState) =>
                        rowState['WORK_PRNT'] ?
                        getFieldValue(rowState, 'CEO') :
                        <TextField
                            style={{width: 100}}
                            value={state}
                            onChange={value => dispatch(value)}
                        />}
                />
                <DataColumn
                    fieldName='BIZ_TYPE'
                    value={row => bizType[getFieldValue(row, 'BIZ_TYPE')]}
                    defaultValue={Object.keys(bizType)[0]}
                    editable={(state, dispatch, rowState) =>
                        rowState['WORK_PRNT'] ?
                        bizType[getFieldValue(rowState, 'BIZ_TYPE')] :
                        <ComboBox
                            data={bizType}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />}
                />
                <DataColumn
                    fieldName='RRN'
                    value={row => getFieldValue(row, 'RRN')}
                    editable={(state, dispatch, rowState) =>
                        rowState['WORK_PRNT'] ?
                        getFieldValue(rowState, 'RRN') :
                        <TextField
                            style={{width: 130}}
                            value={state}
                            onChange={value => dispatch(value)}
                        />}
                />
                <DataColumn
                    fieldName='WORK_BIZNO'
                    value={row => getFieldValue(row, 'WORK_BIZNO')}
                    editable={(state, dispatch, rowState) =>
                        rowState['WORK_PRNT'] ?
                        getFieldValue(rowState, 'WORK_BIZNO') :
                        <TextField
                            style={{width: 130}}
                            value={state}
                            onChange={value => dispatch(value)}
                        />}
                />
                <DataColumn
                    fieldName='SUB_BIZNO'
                    value={row => getFieldValue(row, 'SUB_BIZNO')}
                    editable={(state, dispatch, rowState) =>
                        rowState['WORK_PRNT'] ?
                        getFieldValue(rowState, 'SUB_BIZNO') :
                        <TextField
                            style={{width: 130}}
                            value={state}
                            onChange={value => dispatch(value)}
                        />}
                />
                <DataColumn
                    fieldName='EMAIL'
                    value={row => getFieldValue(row, 'EMAIL')}
                    editable={(state, dispatch, rowState, setField) => 
                        <div>
                            <EmailDialog
                                open={rowState['EMAIL_DIALOG'] || false}
                                onClose={() => setField('EMAIL_DIALOG', false)}
                                row={rowState}
                                setField={setField}
                            />
                            {getFieldValue(rowState, 'EMAIL')}
                            {!rowState['WORK_PRNT'] &&
                            <IconButton
                                tooltip='수정'
                                icon={<EditIcon />}
                                onClick={() => setField('EMAIL_DIALOG', true)}
                            />}
                        </div>
                    }
                />
                <DataColumn
                    fieldName='EMAIL_PASS'
                    visible={false}
                />
                <DataColumn
                    fieldName='WORK_TELNO1'
                    value={row => getFieldValue(row, 'WORK_TELNO1')}
                    editable={(state, setState) =>{
                        const telNo1 = state ? state.split('-')[0] : '';
                        const telNo2 = state ? state.split('-')[1] : '';
                        const telNo3 = state ? state.split('-')[2] : '';
                        return (
                            <div style={{ width: 180 }}>
                                <TextField
                                    style={{width: 50}}
                                    value={telNo1}
                                    onChange={value => setState(`${value}-${telNo2}-${telNo3}`)}
                                />
                                <TextField
                                    style={{width: 50, marginLeft: 8}}
                                    value={telNo2}
                                    onChange={value => setState(`${telNo1}-${value}-${telNo3}`)}
                                />
                                <TextField
                                    style={{width: 50, marginLeft: 8}}
                                    value={telNo3}
                                    onChange={value => setState(`${telNo1}-${telNo2}-${value}`)}
                                />
                            </div>
                        )
                    }}
                />
                <DataColumn
                    fieldName='WORK_TELNO2'
                    value={row => getFieldValue(row, 'WORK_TELNO2')}
                    editable={(state, setState) =>{
                        const telNo1 = state ? state.split('-')[0] : '';
                        const telNo2 = state ? state.split('-')[1] : '';
                        const telNo3 = state ? state.split('-')[2] : '';
                        return (
                            <div style={{ width: 180 }}>
                                <TextField
                                    style={{width: 50}}
                                    value={telNo1}
                                    onChange={value => setState(`${value}-${telNo2}-${telNo3}`)}
                                />
                                <TextField
                                    style={{width: 50, marginLeft: 8}}
                                    value={telNo2}
                                    onChange={value => setState(`${telNo1}-${value}-${telNo3}`)}
                                />
                                <TextField
                                    style={{width: 50, marginLeft: 8}}
                                    value={telNo3}
                                    onChange={value => setState(`${telNo1}-${telNo2}-${value}`)}
                                />
                            </div>
                        )
                    }}
                />
                <DataColumn
                    fieldName='ZIP_CD'
                    value={row => getFieldValue(row, 'ZIP_CD')}
                    editable={(state, dispatch, rowState) => getFieldValue(rowState, 'ZIP_CD')}
                />
                <DataColumn
                    fieldName='ADDR'
                    value={row => getFieldValue(row, 'ADDR')}
                    editable={(state, dispatch, rowState, setField) =>
                        <div>
                            <AddressPicker
                                open={rowState['ADDR_DIALOG'] || false}
                                onClose={() => setField('ADDR_DIALOG', false)}
                                onSelect={data => {
                                    dispatch(data.roadAddr);
                                    setField('ZIP_CD', data.zipNo);
                                }}
                            />
                            {getFieldValue(rowState, 'ADDR')}
                            {!rowState['WORK_PRNT'] &&
                            <IconButton
                                tooltip='수정'
                                icon={<EditIcon />}
                                onClick={() => setField('ADDR_DIALOG', true)}
                            />}
                            {!rowState['WORK_PRNT'] && (state || '') !== '' &&
                            <IconButton
                                tooltip='삭제'
                                icon={<Close />}
                                onClick={() => dispatch(null)}
                            />}
                        </div>
                    }
                />
                <DataColumn
                    fieldName='ADDR_DET'
                    value={row => getFieldValue(row, 'ADDR_DET')}
                    editable={(state, dispatch, rowState) =>
                        rowState['WORK_PRNT'] ?
                        getFieldValue(rowState, 'ADDR_DET') :
                        <TextField
                            style={{width: 250}}
                            value={state}
                            onChange={value => dispatch(value)}
                        />}
                />
                <DataColumn
                    fieldName='BIZ_COND'
                    value={row => getFieldValue(row, 'BIZ_COND')}
                    editable={(state, dispatch, rowState) =>
                        rowState['WORK_PRNT'] ?
                        getFieldValue(rowState, 'BIZ_COND') :
                        <TextField
                            style={{width: 250}}
                            value={state}
                            onChange={value => dispatch(value)}
                        />}
                />
                <DataColumn
                    fieldName='BIZ_ITEM'
                    value={row => getFieldValue(row, 'BIZ_ITEM')}
                    editable={(state, dispatch, rowState) =>
                        rowState['WORK_PRNT'] ?
                        getFieldValue(rowState, 'BIZ_ITEM') :
                        <TextField
                            style={{width: 250}}
                            value={state}
                            onChange={value => dispatch(value)}
                        />}
                />
                <DataColumn
                    fieldName='IN_BANK'
                    value={row => getFieldValue(row, 'IN_BANK')}
                    editable={(state, dispatch, rowState) =>
                        rowState['WORK_PRNT'] ?
                        getFieldValue(rowState, 'IN_BANK') :
                        <TextField
                            style={{width: 150}}
                            value={state}
                            onChange={value => dispatch(value)}
                        />}
                />
                <DataColumn
                    fieldName='IN_BANKBOOK'
                    value={row => getFieldValue(row, 'IN_BANKBOOK')}
                    editable={(state, dispatch, rowState) =>
                        rowState['WORK_PRNT'] ?
                        getFieldValue(rowState, 'IN_BANKBOOK') :
                        <TextField
                            style={{width: 150}}
                            value={state}
                            onChange={value => dispatch(value)}
                        />}
                />
                <DataColumn
                    fieldName='IN_OWNBANK'
                    value={row => getFieldValue(row, 'IN_OWNBANK')}
                    editable={(state, dispatch, rowState) =>
                        rowState['WORK_PRNT'] ?
                        getFieldValue(rowState, 'IN_OWNBANK') :
                        <TextField
                            style={{width: 100}}
                            value={state}
                            onChange={value => dispatch(value)}
                        />}
                />
                <DataColumn
                    fieldName='WORK_TYPE'
                    value={(row) => getFieldValue(row, 'WORK_TYPE_NM')}
                    editable={(state, dispatch, rowState) =>
                        rowState['WORK_PRNT'] ? 
                        getFieldValue(rowState, 'WORK_TYPE_NM') :
                        <ComboBox
                            selectProc='SLT_WORKTYPEINFO_ALL'
                            keyField='WORK_TYPE'
                            valueField='WORK_TYPE_NM'
                            value={state}
                            onChange={(value) => dispatch(value)}
                            multiple
                        />}
                />
                <DataColumn
                    fieldName='END_DATE'
                    defaultValue={null}
                    editable={(state, dispatch, rowState) => (
                        <DatePicker
                            value={state}
                            onChange={value => dispatch(value)}
                            nullable
                        />
                    )}
                />
                <DataColumn
                    fieldName='OILTRD_CD'
                    value={row => getFieldValue(row, 'OILTRD_CD')}
                    editable={(state, dispatch, rowState) =>
                        rowState['WORK_PRNT'] ?
                        getFieldValue(rowState, 'OILTRD_CD') :
                        <TextField
                            style={{width: 100}}
                            value={state}
                            onChange={value => dispatch(value)}
                        />}
                />
                <DataColumn
                    fieldName='OILTRD_AREA_CD'
                    value={row => getFieldValue(row, 'OILTRD_AREA_CD')}
                    editable={(state, dispatch, rowState) =>
                        rowState['WORK_PRNT'] ?
                        getFieldValue(rowState, 'OILTRD_AREA_CD') :
                        <TextField
                            style={{width: 100}}
                            value={state}
                            onChange={value => dispatch(value)}
                        />}
                />
                <DataColumn
                    fieldName='TRD_COND'
                    value={row => getFieldValue(row, 'TRD_COND')}
                    editable={(state, dispatch, rowState) =>
                        rowState['WORK_PRNT'] ?
                        getFieldValue(rowState, 'TRD_COND') :
                        <TextField
                            style={{width: 100}}
                            value={state}
                            onChange={value => dispatch(value)}
                        />}
                />
                <DataColumn
                    fieldName='ACC_INFO'
                    value={row => getFieldValue(row, 'ACC_INFO')}
                    editable={(state, dispatch, rowState) =>
                        rowState['WORK_PRNT'] ?
                        getFieldValue(rowState, 'ACC_INFO') :
                        <TextField
                            style={{width: 150}}
                            value={state}
                            onChange={value => dispatch(value)}
                        />}
                />
                <DataColumn
                    fieldName='IN_EXC_WORK'
                    value={row => getFieldValue(row, 'IN_EXC_WORK')}
                    editable={(state, dispatch, rowState) =>
                        rowState['WORK_PRNT'] ?
                        getFieldValue(rowState, 'IN_EXC_WORK') :
                        <TextField
                            style={{width: 100}}
                            value={state}
                            onChange={value => dispatch(value)}
                        />}
                />
                <DataColumn
                    fieldName='STOCK_TYPE'
                    value={row => stockType[getFieldValue(row, 'STOCK_TYPE')]}
                    editable={(state, dispatch, rowState) =>(
                            rowState['WORK_PRNT'] ?
                            stockType[getFieldValue(rowState, 'STOCK_TYPE')] :
                            <ComboBox
                                data={stockType}
                                value={state}
                                onChange={value => dispatch(value)}
                            />
                        )
                    }
                />
                <DataColumn
                    fieldName='EFMS_NM'
                    value={row => getFieldValue(row, 'EFMS_NM')}
                    editable={(state, dispatch, rowState) =>
                        rowState['WORK_PRNT'] ?
                        getFieldValue(rowState, 'EFMS_NM') :
                        <TextField
                            style={{width: 100}}
                            value={state}
                            onChange={value => dispatch(value)}
                        />}
                />
                <DataColumn
                    fieldName='WORK_PRNT_NM'
                    visible={false}
                />
                <DataColumn
                    fieldName='WORK_PRNT_NM'
                    visible={false}
                />
                <DataColumn
                    fieldName='WORK_TYPE_NM'
                    visible={false}
                />
                <DataColumn
                    fieldName='REGDATE'
                    editable={(state) => state}
                />
                <DataColumn
                    fieldName='REGUSER'
                    editable={(state) => state}
                />
                <DataColumn
                    fieldName='EDTDATE'
                    editable={(state) => state}
                />
                <DataColumn
                    fieldName='EDTUSER'
                    editable={(state) => state}
                />
            </DataGrid>
            <OrderbyDialog
                open={oderbyDialogOpen}
                onClose={() => setOrderbyDialogOpen(false)}
                wpiData={wpiData}
                condition={condition}
                gridRefresh={gridRefresh}
            />
        </>
    )
}

const useDialogStyle = makeStyles(theme => ({
    dialog: {
        width: 300,
        height: 300,
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    content: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: theme.spacing(2),
        paddingTop: 0,
        height: '100%',
        overflowY: 'auto',
    },
    btnContainer: {
        alignSelf: 'flex-end',
        marginTop: 'auto',
        padding: theme.spacing(2),
    },
}))

const EmailDialog = ({ open, onClose, row, setField }) => {
    const [insData, setInsData] = useFieldInputs();

    const { enqueueSnackbar } = useSnackbar();
    const classes = useDialogStyle();

    useEffect(() => {
        setInsData('EMAIL', row['EMAIL']);
        setInsData('EMAIL_PASS', row['EMAIL_PASS']);
    }, [row, setInsData])

    const hasValue = value => value != null && value.toString().trim() !== '';

    const valid = () => {
        if (!hasValue(insData['EMAIL'])) {
            return true;
        }
        if (insData['EMAIL'].indexOf('@') === -1 || !hasValue(insData['EMAIL'].split('@')[1])) {
            enqueueSnackbar('이메일 형식이 잘못되었습니다.', { variant: 'error' });
            return false;
        }
        return true;
    }

    const onConfirm = () => {
        if (valid()) {
            setField('EMAIL', insData['EMAIL']);
            setField('EMAIL_PASS', insData['EMAIL_PASS']);
            onClose();
        }
    }

    return (
        <Dialog
            open={open}
            PaperProps={{ className: classes.dialog }}
            onClose={onClose}
        >
            <div className={classes.title}>
                <DialogTitle>
                    이메일
                </DialogTitle>
                <IconButton
                    tooltip='닫기'
                    icon={<CloseIcon />}
                    onClick={onClose}
                />
            </div>
            <div className={classes.content}>
                <TextField
                    label='메일주소'
                    value={insData['EMAIL'] || ''}
                    onChange={value => setInsData('EMAIL', value)}
                />
                <ReactIsCapsLockActive>
                    {isCapslock =>
                    <TextField
                        label='비밀번호'
                        type='password'
                        value={insData['EMAIL_PASS'] || ''}
                        onChange={value => setInsData('EMAIL_PASS', value)}
                        helperText={isCapslock && 'CapsLock이 켜져 있습니다.'}
                    />}
                </ReactIsCapsLockActive>
            </div>
            <div className={classes.btnContainer}>
                    <IconButton
                        tooltip='확인'
                        icon={<CheckIcon />}
                        onClick={onConfirm}
                    />
            </div>
        </Dialog>
    )
}

const orderbyDialogStyle = makeStyles((theme) => ({
    dialogPaper: {
        maxWidth: 'none',
        width: 700,
        height: 700,
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    actionButton: {
        display: 'flex',
    },
    content: {
        overflowY: 'auto',
    },
    itemContainer: {
        cursor: 'default',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    selection: {
        backgroundColor: lighten(theme.palette.primary.main, 0.85),
    },
}))

const OrderbyDialog = ({open, onClose, wpiData, condition, gridRefresh}) => {
    const [data, setData] = useState();
    const [selected, setSelected] = useState();
    const classes = orderbyDialogStyle();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => setData(wpiData), [wpiData]);

    const onUpClick = () => {
        const index = data.indexOf(selected);
        data.splice(index, 1);
        data.splice(index - 1, 0, selected);
        const result = data.map((item, order) => ({
            ...item,
            SEQ: order + 1
        }));
        setData(result);
        setSelected(result.find(item => item['WORK_CD'] === selected['WORK_CD']));
    }

    const onDownClick = () => {
        const index = data.indexOf(selected);
        data.splice(index, 1);
        data.splice(index + 1, 0, selected);
        const result = data.map((item, order) => ({
            ...item,
            SEQ: order + 1
        }));
        setData(result);
        setSelected(result.find(item => item['WORK_CD'] === selected['WORK_CD']));
    }

    const onSave = async() => {
        const result = await callProc('UPT_WORKPLACEINFO_SEQ', {...condition, JSON: JSON.stringify(data)});
        if(!result.err){
            enqueueSnackbar('저장이 완료되었습니다.');
            onClose();
            gridRefresh();
        }
    }

    const onCloseClick = () => {
        gridRefresh();
        onClose();
    }

    return (
        <Dialog
            open={open}
            PaperProps={{className: classes.dialogPaper}}
        >
            <div className={classes.dialogTitle}>
                <DialogTitle>순서정렬</DialogTitle>
                <div className={classes.actionButton}>
                    <IconButton
                        tooltip='확인'
                        icon={<Check />}
                        onClick={onSave}
                    />
                    <IconButton
                        tooltip='위로 이동'
                        icon={<KeyboardArrowUp />}
                        disabled={!data || !selected || data.indexOf(selected) === 0}
                        onClick={onUpClick}
                    />
                    <IconButton
                        tooltip='아래로 이동'
                        icon={<KeyboardArrowDown />}
                        disabled={!data || !selected || data.indexOf(selected) === data.length - 1}
                        onClick={onDownClick}
                    />
                </div>
                <IconButton
                    tooltip='닫기'
                    icon={<Close />}
                    onClick={onCloseClick}
                />
            </div>
            <div className={classes.content}>
                {!data ? <ProgressPanel /> :
                data.map((item, idx) => (
                    <Typography
                        onClick={() => setSelected(item)}
                        className={clsx(classes.itemContainer, {
                            [classes.selection]: item['WORK_CD'] === (selected && selected['WORK_CD'])
                        })}
                    >
                        {`${item['WORK_CD']} : ${item['WORK_NM']} (${item['WORK_SHORT_NM']}) ${item['END_DATE'] ? `※종료일 : ${item['END_DATE']}※` : ``}`}
                    </Typography>
                ))}
            </div>
        </Dialog>
    )
}

export default WorkPlaceInfo;