import React, { useMemo } from 'react';
import { DataGrid, ComboBox, DataColumn, DatePicker, NumberField, IconButton, ComboSearch } from '../../component';
import { makeStyles, Paper } from '@material-ui/core';
import { useSelector } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import { isFieldDiff, useFieldInputs } from '../../common/Utils';

const useStyle = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    searchPaper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    searchContainer: {
        flex: 1,
        display: 'inline-block',
        flexDirection: 'row',
    },
    searchItem: {
        display: 'inline-block',
        width: 200,
        marginRight: 100,
        marginBottom: theme.spacing(1),
    },
}))

const CautionMoney = () =>{
    const classes = useStyle();
    const clsState = useSelector(state => state.clsDateReducer);
    const [input, setInput] = useFieldInputs({
        WORK_CD: sessionStorage['WORK_CD'],
        BIZ_DATE: clsState[sessionStorage['WORK_CD']],
    });


    const condition = useMemo(() => input['WORK_CD'] && input['BIZ_DATE'] && input, [input]);

    const gridRefresh = () => {
        setInput({ type: 'UPDATE', value: { ...input } });
    }

    return(
        <div className={classes.container}>
             <Paper className={classes.searchPaper}>
                <div className={classes.searchContainer}>
                    <div className={classes.searchItem}>
                        <ComboBox
                            // style={{width: 200}}
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={condition && condition['WORK_CD']}
                            onChange={value => {
                                if (input['WORK_CD'] !== value) {
                                    sessionStorage['WORK_CD'] = value;
                                    setInput('BIZ_DATE', clsState[value]);
                                    setInput('WORK_CD', value);
                                }
                            }}
                        />
                    </div>
                    <div className={classes.searchItem}>
                        <DatePicker
                            label='영업일'
                            minDate={clsState[input['WORK_CD']]}
                            value={condition && condition['BIZ_DATE']}
                            onChange={value => {
                                if (input['BIZ_DATE'] !== value) {
                                    setInput('BIZ_DATE', value);
                                    if(condition && condition['BIZ_DATE'] < clsState[condition['WORK_CD']]) {
                                        setInput({ type: 'UPDATE', value: { ...input, 'BIZ_DATE': value } });
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                <IconButton
                    tooltip=''
                    icon={<SearchIcon />}
                    onClick={gridRefresh}
                />
            </Paper>
            <DataGrid
                title='보증금 관리'
                selectProc='SLT_CAUTIONINOUT'
                insertProc='INS_CAUTIONINOUT'
                updateProc='UPT_CAUTIONINOUT'
                selectParam={condition}
                horizonMode
            >
                <DataColumn
                    fieldName='BIZ_DATE'
                    defaultValue={condition && condition['BIZ_DATE']}
                    editable={(state, dispatch, rowState) => (
                        <DatePicker
                            style={{ width: 150 }}
                            value={state}
                            onChange={value => {
                                dispatch(value);
                            }}
                            disabled
                        />
                    )}
                    
                />

                <DataColumn
                    fieldName='WORK_CD'
                    title='사업장명'
                    value={row => row['WORK_NM']}
                    defaultValue={condition && condition['WORK_CD']}
                    editable={(state, dispatch, rowState, setField) => (
                        <ComboBox
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => {
                                dispatch(value);
                            }}
                            disabled
                        />
                    )}
                />

                <DataColumn
                    fieldName='CST_CD'
                    title='보증금 거래처'
                    value={row => row['CST_NM']}
                    editable={(state, dispatch, rowState, setField) => {
                        let param = rowState['CST_PARAM'] || null;
                        if (isFieldDiff(['WORK_CD'], rowState, param)) {
                            param = { WORK_CD: rowState['WORK_CD']};
                            setField('CST_PARAM', param);
                        }
                        return (
                            <ComboSearch
                                style={{width: 200}}
                                selectProc='SLT_CUSTOMERINFO_ALL'
                                selectParam={param}
                                keyField='CST_CD'
                                valueField='CST_NM'
                                value={state}
                                onChange={value => dispatch(value)}
                                nullable
                            />
                        )
                    }}
                />

                <DataColumn
                    fieldName='BEF_AMT'
                    title='전일 잔액'
                    editable={(state, dispatch) => state}
                />

                <DataColumn
                    fieldName='CUR_IN_AMT'
                    title='당일 입금'
                    editable={(state, dispatch) => (
                        <NumberField
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                />

                <DataColumn
                    fieldName='CUR_OUT_AMT'
                    title='딩일 반환'
                    editable={(state, dispatch) => (
                        <NumberField
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                />

                <DataColumn
                    fieldName='CUR_AMT'
                    title='당일 잔액'
                />

                <DataColumn
                    fieldName='WORK_NM'
                    visible={false}
                />

                <DataColumn
                    fieldName='CST_NM'
                    visible={false}
                />
            </DataGrid>
        </div>

    )
}

export default CautionMoney