import React, { useMemo } from 'react';
import { DataGrid, ComboBox, DataColumn, DatePicker, NumberField, IconButton } from '../../component';
import { makeStyles, Paper } from '@material-ui/core';
import { useSelector } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import { useFieldInputs } from '../../common/Utils';

// const cardType = {
//     P0004: 3, // 모바일쿠폰
//     P0007: 2, // 포인트
// }

const useStyle = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    searchPaper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    searchContainer: {
        flex: 1,
        display: 'inline-block',
        flexDirection: 'row',
    },
    searchItem: {
        display: 'inline-block',
        width: 200,
        marginRight: 100,
        marginBottom: theme.spacing(1),
    },
}))

const EtcBondMoneyEnroll = () => {
    const classes = useStyle();
    const clsState = useSelector(state => state.clsDateReducer);
    const [input, setInput] = useFieldInputs({
        WORK_CD: sessionStorage['WORK_CD'],
        BIZ_DATE: clsState[sessionStorage['WORK_CD']],
    });

    const condition = useMemo(() => input['WORK_CD'] && input['BIZ_DATE'] && input, [input]);

    const gridRefresh = () => {
        setInput({ type: 'UPDATE', value: { ...input } });
    }
    return(
        <div className={classes.container}>
            <Paper className={classes.searchPaper}>
                <div className={classes.searchContainer}>
                    <div className={classes.searchItem}>
                        <ComboBox
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={condition && condition['WORK_CD']}
                            onChange={value => {
                                if (input['WORK_CD'] !== value) {
                                    sessionStorage['WORK_CD'] = value;
                                    setInput('BIZ_DATE', clsState[value]);
                                    setInput('WORK_CD', value);
                                }
                            }}
                        />
                    </div>
                    <div className={classes.searchItem}>
                        <DatePicker
                            label='영업일'
                            minDate={clsState[input['WORK_CD']]}
                            value={condition && condition['BIZ_DATE']}
                            onChange={value => {
                                if (input['BIZ_DATE'] !== value) {
                                    setInput('BIZ_DATE', value);
                                    if(condition && condition['BIZ_DATE'] < clsState[condition['WORK_CD']]) {
                                        setInput({ type: 'UPDATE', value: { ...input, 'BIZ_DATE': value } });
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                <IconButton
                    tooltip=''
                    icon={<SearchIcon />}
                    onClick={gridRefresh}
                />
            </Paper>
            <DataGrid
                selectProc='SLT_ETCBONDPAY_ENROLL'
                insertProc='INS_ETCBONDPAY_ENROLL'
                updateProc='UPT_ETCBONDPAY_ENROLL'
                deleteProc='DLT_ETCBONDPAY_ENROLL'
                selectParam={condition}
                keys={['ACJ_CD']}
            >
                <DataColumn
                    fieldName='ACJ_DATE'
                    title='입금일'
                    defaultValue={condition && condition['BIZ_DATE']}
                    editable={(state, dispatch, rowState) => (
                        <DatePicker
                            value={state}
                            onChange={value => dispatch(value)}
                            disabled
                        />
                    )}
                />

                <DataColumn
                    fieldName='WORK_CD'
                    title='사업장명'
                    defaultValue={condition && condition['WORK_CD']}
                    value={row => row['WORK_NM']}
                    editable={(state, dispatch) => (
                        <ComboBox
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={value => {
                                dispatch(value);
                            }}
                            disabled
                        />
                    )}
                />

                <DataColumn
                    fieldName='PAY_CD'
                    title='결제수단'
                    value={row => row['PAY_NM']}
                    editable={(state, dispatch, rowState, setField) => (
                        <ComboBox
                            selectProc='SLT_PAYMENTINFO_ETCBONDPAY'
                            keyField='PAY_CD'
                            valueField='PAY_NM'
                            value={state}
                            onChange={value => {
                                dispatch(value);
                                setField('CARD_PARAM', { WORK_CD: rowState['WORK_CD'], PAY_CD: value });
                                // cardPram.current = null;
                            }}
                        />
                    )}
                />

                <DataColumn
                    fieldName='CARD_CD'
                    title='발생수단'
                    value={row => row['CARD_NM']}
                    editable={(state, dispatch, rowState) => (
                        <ComboBox
                            selectProc='SLT_CARDINFO_COMBO'
                            selectParam={rowState['CARD_PARAM'] || null}
                            keyField='CARD_CD'
                            valueField='CARD_NM'
                            value={state}
                            onChange={value => {
                                dispatch(value);
                            }}
                        />
                    )}
                />

                <DataColumn
                    fieldName='IN_AMT'
                    title='입금액'
                    editable={(state, dispatch) => (
                        <NumberField
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                />

                <DataColumn
                    fieldName='SHARE_AMT'
                    title='부담금'
                    editable={(state, dispatch) => (
                        <NumberField
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                />

                <DataColumn
                    fieldName='WORK_NM'
                    visible={false}
                />

                <DataColumn
                    fieldName='PAY_NM'
                    visible={false}
                />

                <DataColumn
                    fieldName='CARD_NM'
                    visible={false}
                />
            </DataGrid>
        </div>
    )
}

export default EtcBondMoneyEnroll