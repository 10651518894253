import React, { useState } from 'react';
import moment from 'moment';
import { DataGrid, SearchItem, SearchPanel, DataColumn, ComboBox, DatePicker } from '../../component';
import { makeStyles } from '@material-ui/core';



const useStyle = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },    
}));

const CashInOutMoney = () => {
    const classes = useStyle();
    const [condition, setCondition] = useState(null);

    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => {
                sessionStorage['WORK_CD'] = condition['WORK_CD'];
                setCondition(condition) }}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, dispatch) => (
                        <ComboBox
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            onDataLoad={jsonData => ({ALL: '전체', ...jsonData})}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
            </SearchPanel>
            <DataGrid
                title='출납부(입/출금) 조회'
                selectProc='SLT_CASH_INOUT_LIST'
                selectParam={condition}
                horizonMode
                selectionMode='single'
                virtualized
                excelDownload={{ fileName: '출납부 입출금 조회.xlsx', sheetName: '출납부 입출금 조회' }}
            >
                <DataColumn 
                    fieldName='ACJ_DATE' 
                    title='영업일' 
                />
                <DataColumn 
                    fieldName='ACC_NM' 
                    title='계정과목명'
                    total={(values) => '합계'}
                />

                <DataColumn 
                    fieldName='DEBTOR' 
                    title='수입' 
                    total={(values) => values.reduce((result, value) => result + value, 0)}
                />

                <DataColumn 
                    fieldName='CREDITOR' 
                    title='지출' 
                    total={(values) => values.reduce((result, value) => result + value, 0)}
                />

                <DataColumn 
                    fieldName='BAL_AMT' 
                    title='잔액' 
                />
            </DataGrid>
        </div>
    )
}

export default CashInOutMoney;