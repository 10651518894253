import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DatePicker, DataColumn, ProgressPanel } from '../../component';
import { IconButton, makeStyles } from '@material-ui/core';
import PdfDialog from '../../component/PdfDialog';
import { CstBondPayReportPdf } from '../../reports';
import PrintIcon from '@material-ui/icons/Print';
import { callProc } from '../../common/DBConnector';
import { useSelector } from 'react-redux';

const useStyle = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    }
}))

const CstBondPayReport = () => {
    const classes = useStyle();
    const [condition, setCondition] = useState(null);
    const [pdfData, setPdfData] = useState();
    const [workData, setWorkData] = useState();
    const loginState = useSelector(state => state.loginReducer);

    useEffect(() => {
        const fetchData = async () => {
            const workData = await callProc('SLT_WORKPLACEINFO_NOTMAIN_AUTH');

            setWorkData(workData.data.reduce((result, item) => ({
                ...result,
                [item['WORK_CD']]: item['WORK_NM']
            }), {}))
        }
        fetchData();
    }, [])

    return(
        !workData ? <ProgressPanel /> :
        <div className={classes.container} >
            <SearchPanel onSearch={(condition) => {setCondition(condition)}}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={Object.keys(workData)[0]}
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            data={workData}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='BIZ_MONTH'
                    defaultValue={moment().add(-1, 'month').format('YYYY-MM')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='날짜'
                            monthPicker
                            value={state}
                            onChange={(value) => {
                                setState(value);
                            }}
                        />
                    )}
                />
            </SearchPanel>
            <PdfDialog
            open={pdfData != null}
            onClose={() => setPdfData(null)}
            title='외상거래처 매출'
            >
                <CstBondPayReportPdf
                    data={pdfData}
                    workName={condition && workData[condition['WORK_CD']]}
                    bizMonth={condition && condition['BIZ_MONTH']}
                    loginState={loginState}
                />
            </PdfDialog>

            <DataGrid
                selectProc='SLT_REPORT_BONDPAY_CUSTOMER'
                selectParam={condition}
                headerItem={({data}) =>
                    <IconButton
                        onClick={() => setPdfData(data)}
                    >
                        <PrintIcon />
                    </IconButton>
                }
                title='외상거래처 채권'
            >
                <DataColumn fieldName='OLD_CREDIT_AMT' title='장기미수금' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='BEF_MONTH_BAL_AMT' title='전월미수금' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='CUR_MONTH_SALE_AMT' title='당월 매출' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='CUR_MONTH_IN_AMT' title='당월 입금' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='CUR_MONTH_BAL_AMT' title='당월 잔액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='CUR_MONTH_BAL_SPLY' title='공급가액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='CUR_MONTH_BAL_VAT' title='부가세' total={(values) => values.reduce((result, value) => result + value, 0)}/>

            </DataGrid>
        </div>
    )
}

export default CstBondPayReport