import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { SearchItem, SearchPanel, ComboBox, ProgressPanel, DatePicker, DataGrid, DataColumn } from '../../component';
import { makeStyles } from '@material-ui/core';
import { callProc } from '../../common/DBConnector';


const useStyle = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    detailTotal: {
        backgroundColor: '#a3a9d9 !important'
    }
}))

const SaleDetail = () => {
    const classes = useStyle();
    const [condition, setCondition] = useState(null);
    const [workData, setWorkData] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const workData = (await callProc('SLT_WORKPLACEINFO_AUTH')).data;

            setWorkData(workData);
        }
        fetchData();
    }, []);

    return (
        !workData ? <ProgressPanel /> :
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => setCondition(condition)}>
                <SearchItem
                    fieldName='WORK_CD'
                    component={(state, dispatch) => (
                        <ComboBox
                            label='사업장명'
                            data={workData}
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_MONTH'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작월'
                            monthPicker
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_MONTH']) {
                                    dispatch('END_MONTH', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_MONTH'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료월'
                            monthPicker
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_MONTH']) {
                                    dispatch('STA_MONTH', value);
                                }
                            }}
                        />
                    )}
                />
            </SearchPanel>

            <DataGrid
                title='매출 상세내역 비교 조회'
                selectProc='SLT_SALE_DETAIL'
                selectParam={condition}
                totalGroup={['BIZ_QUARTER', 'BIZ_MONTH']}
                eachTotalGroup
                selectionMode='single'
                horizonMode
                virtualized
                excelDownload
                stickyHeader
            >
                <DataColumn 
                    fieldName='BIZ_MONTH'
                    title='영업월'
                    total={(values, data, group) => {
                        let result = `-합 계-`;
                        if(group && group['BIZ_QUARTER']) {
                            result = `(분기별 소계) ${data[0]['BIZ_QUARTER']}/4 분기`;
                        }
                        if(group && group['BIZ_MONTH'] && group['BIZ_QUARTER']){
                            result = `(월별 소계) ${data[0]['BIZ_MONTH']}`;
                        }
                        return result;
                    }} 
                />
                <DataColumn fieldName='GDS_TYPE_NM' title='상품' />
                <DataColumn fieldName='CREDIT_SPLY_AMT' title='공급가액(신용)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='CREDIT_AMT' title='매출합계(신용)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='CREDIT_RATE' title='비율(%)(신용)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='SIMPLE_SPLY_AMT' title='공급가액(간편)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='SIMPLE_AMT' title='매출합계(간편)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='SIMPLE_RATE' title='비율(%)(간편)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='RECEPT_SPLY_AMT' title='공급가액(소득공제)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='RECEPT_AMT' title='매출합계(소득공제)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='CSH_BOND_SPLY_AMT' title='공급가액(현금+외상)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='CSH_BOND_AMT' title='매출합계(현금+외상)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='TOT_SPLY_AMT' title='공급가액(총매출)' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='TOT_AMT' title='매출합계(총매출)' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='TAX_SPLY_AMT' title='공급가액(세금계산서)' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='TAX_AMT' title='매출합계(세금계산서)' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='NOT_CRD_RATE' title='카드제외 비율' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='OIL_RATE' title='유류매출 대비' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='BIZ_QUARTER' visible={false} />
                <DataColumn fieldName='GDS_TYPE' visible={false} />
                <DataColumn fieldName='CATE_NM' visible={false} />
                <DataColumn fieldName='GDS_CD' visible={false} />
                <DataColumn fieldName='SUM_TYPE' visible={false} />

            </DataGrid>

        </div>
    )
}

export default SaleDetail;