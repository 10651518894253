import React, { useState } from 'react';
import { Dialog, DialogTitle, makeStyles } from '@material-ui/core';
import { SearchPanel, SearchItem, ComboBox, DataGrid, DataColumn, NumberField, ComboSearch, IconButton, DateTimePicker, ProgressDialog } from '../../component';
import { pricType } from '../../common/Dictionary';
import { isFieldDiff, useFieldInputs } from '../../common/Utils';
import PostAddIcon from '@material-ui/icons/PostAdd';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { callProc } from '../../common/DBConnector';

const useStyle = makeStyles(() => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
}))

const InPriceInfo = () => {
    const classes = useStyle();
    const [condition, setCondition] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    const refreshGrid = () => setCondition({...condition})

    return(
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => setCondition(condition)}>
                <SearchItem
                    fieldName='WORK_CD'
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => {
                                dispatch(value);
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='GDS_CD'
                    component={(state, setState, condition, setField) => {
                        let param = condition['GDS_PARAM'] || null;
                        if (isFieldDiff(['WORK_CD'], condition, param)) {
                            param = { 
                                WORK_CD: condition['WORK_CD'],
                                GDS_TYPE: 'ALL',
                                NOT_TYPE: 0,
                                GDS_TYPE_M: 'ALL',
                                NOT_TYPE_M: 0,
                                GAUGE_TYPE: 'ALL',
                                STA_DATE: '2000-01-01',
                            };
                            setField('GDS_PARAM', param);
                        }
                        return (
                            <ComboSearch
                                label='상품명'
                                selectProc='SLT_GAUGE_ITEM_GOODS_COMBO'
                                selectParam={param}
                                keyField='GDS_CD'
                                valueField='GDS_NM'
                                value={state}
                                onChange={value => setState(value)}
                            />
                        )
                    }}
                />
                <SearchItem
                    fieldName='CST_CD'
                    component={(state, dispatch, condition, setField) => {
                        let param = condition['CST_PARAM'] || null;
                        if (isFieldDiff(['WORK_CD'], condition, param)) {
                            param = { 
                                WORK_CD: condition['WORK_CD'],
                                CST_TYPE: [0, 2],
                                CST_KIND: [1, 2],
                            };
                            setField('CST_PARAM', param);
                        }
                        return (
                            <ComboSearch
                                label='거래처명'
                                selectProc='SLT_CUSTOMER_COMBO '
                                selectParam={param}
                                keyField='CST_CD'
                                valueField='CST_NM'
                                onDataLoad={(jsonData, rawData) => [{ CST_CD: 'ALL', CST_NM: '전체' }, ...rawData ]}
                                value={state}
                                onChange={(value) => dispatch(value)}
                            />
                        )
                    }}
                />
            </SearchPanel>

            <DataGrid
                title='매입단가'
                selectProc='SLT_INPRICE'
                insertProc='INS_INPRICE'
                deleteProc='DLT_INPRICE'
                selectParam={condition}
                selectionMode='single'
                horizonMode
                headerItem={() => (
                    <IconButton
                        tooltip='단가 불러오기'
                        icon={<PostAddIcon />}
                        onClick={() => setDialogOpen(true)}
                    />
                )}
            >
                <DataColumn
                    fieldName='WORK_CD'
                    title='사업장명'
                    value={row => row['WORK_NM']}
                    defaultValue={condition && condition['WORK_CD']}
                    visible={false}
                />
                <DataColumn
                    fieldName='CST_CD'
                    title='거래처명'
                    value={row => row['CST_NM']}
                    editable = {(state, dispatch, rowState, setField) => {
                        let param = rowState['CST_PARAM'] || null;
                        if (param == null ||
                            rowState['WORK_CD'] !== param['WORK_CD'] ||
                            rowState['STA_DTTM'].substring(0,10) !== param['STA_DATE']) {
                            param = {
                                WORK_CD: rowState['WORK_CD'],
                                CST_TYPE: [0, 2],
                                CST_KIND: [1, 2, 3],
                                STA_DATE: rowState['STA_DTTM'].substring(0,10),
                            };
                            setField('CST_PARAM', param);
                        }
                        return(
                            <ComboSearch
                                selectProc='SLT_CUSTOMER_COMBO'
                                style={{width: 200}}
                                selectParam={param}
                                keyField='CST_CD'
                                valueField='CST_NM'
                                value={state}
                                onChange={value => dispatch(value)}
                            />
                        )
                    }}
                />
                <DataColumn
                    fieldName='GDS_CD'
                    title='상품명'
                    value={row => row['GDS_NM']}
                    defaultValue={condition && condition['GDS_CD']}
                    editable={(state, dispatch) => (
                        <ComboBox
                            selectProc='SLT_GOODSINFO_GAUGE_ITEM_SERACHPANEL'
                            selectParam={condition}
                            keyField='GDS_CD'
                            valueField='GDS_NM'
                            value={state}
                            onChange={value => dispatch(value)}
                            disabled={true}
                        />
                    )}
                />
                <DataColumn
                    fieldName='PRICE'
                    editable={(state, dispatch) => (
                        <NumberField
                            style={{width: 150}}
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                /> 
                <DataColumn
                    fieldName='PRICE_TYPE'
                    value={(row, value) => pricType[value]}
                />
                <DataColumn
                    fieldName='STA_DTTM'
                    editable={(state, dispatch) => (
                        <DateTimePicker
                            value={state}
                            onChange={value => dispatch(value)}
                            nullable
                        />
                    )}
                />
                <DataColumn
                    fieldName='END_DTTM'
                    title='종료일시'
                    defaultValue={'9999-12-31 23:59:59'}
                    visible={false}
                />
                <DataColumn
                    fieldName='WORK_NM'
                    visible={false}
                />
                <DataColumn
                    fieldName='GDS_NM'
                    visible={false}
                />
                <DataColumn
                    fieldName='SEQ'
                    visible={false}
                />
                <DataColumn
                    fieldName='CST_NM'
                    visible={false}
                />
            </DataGrid>
            <PriceDialog 
                dialogOpen={dialogOpen}
                onClose={() => setDialogOpen(false)}
                condition={condition && condition['GDS_CD']}
                refreshGrid={refreshGrid}
            />

        </div>
    )
}


const useDialogStyles = makeStyles(theme => ({
    dialogPaper: {
        maxWidth: 'none',
        width: 300,
        height: 350,
    },
    topSpace: {
        display: 'flex',
    },
    actionButton: {
        display: 'flex',
        justifyContent: 'space-between',
        flex: 1,
    },
    ComboList: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: theme.spacing(2),
    },

}))

const PriceDialog = ({dialogOpen, onClose, condition, refreshGrid}) => {
    const [insData, setInsData] = useFieldInputs({
        STA_DTTM: moment().format('YYYY-MM-DD 00:00:00'),
    });
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const classes = useDialogStyles();

    const valid = () => {
        if (insData['JSON_WORK_CD'] === null || insData['JSON_WORK_CD'].length === 0) {
            enqueueSnackbar('사업장을 선택해주세요.', { variant: 'error' });
            return false;
        }
        return true;
    }

    const onClick = async() => {
        setLoading(true);

        if (valid()) {
            const result = await callProc('INS_INPRICE_MULTI', insData);
            if (!result.err) {
                enqueueSnackbar('입력이 완료되었습니다.');
                refreshGrid();
                onClose();
            }
        }

        setInsData('JSON_WORK_CD', null);
        setLoading(false);
    }

    return (
        <Dialog
            open={dialogOpen}
            PaperProps={{ className: classes.dialogPaper }}
        >

            <div className={classes.topSpace}>    
                
                <div className={classes.titleName}>
                    <DialogTitle>매입단가</DialogTitle>
                </div>
                <div className={classes.actionButton}>
                    <IconButton
                        tooltip='확인'
                        icon={<CheckIcon />}
                        onClick={onClick}
                    /> 
                    <IconButton
                        tooltip='닫기'
                        icon={<CloseIcon />}
                        onClick={onClose}
                    />
                </div>
                
            </div>
            <div className={classes.ComboList}>
                  
                    <ComboBox
                        label='사업장명'
                        selectProc='SLT_WORKPLACEINFO_AUTH'
                        keyField='WORK_CD'
                        valueField='WORK_NM'
                        value={insData['JSON_WORK_CD']}
                        onChange={(value) => setInsData('JSON_WORK_CD', value)}
                        style={{width: "100%"}}
                        multiple
                    />

                    <ComboBox
                        label='거래처명'
                        selectProc='SLT_INCUSTOMER_SEARCH'
                        keyField='IN_CST_CD'
                        valueField='IN_CST_NM'
                        value={insData['IN_CST_CD']}
                        onChange={(value) => setInsData('IN_CST_CD', value)}
                        style={{width: "100%", marginTop: 10}}
                    />

                    <ComboSearch 
                        label='상품명'
                        selectProc='SLT_GOODSINFO_OIL_GROUP'
                        keyField='GDS_CD'
                        valueField='GDS_NM'
                        value={condition}
                        onChange={(value) => setInsData('GDS_CD', value)}
                        style={{width: "100%", marginTop: 10 }}
                        disabled
                    />

                    <DateTimePicker
                        label='시작일시'
                        value={insData['STA_DTTM']}
                        onChange={(value) => setInsData('STA_DTTM', value)}
                        style={{width: "100%", marginTop: 10  }}
                        nullable
                    />
            </div>
            <ProgressDialog open={loading} />
        </Dialog>
    )
}

export default InPriceInfo