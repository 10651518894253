import React, { useState } from 'react';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DataColumn, GridHeaderBand, BandItem } from '../../component';
import { makeStyles } from '@material-ui/core';
import { totalCount } from '../../common/Utils';


const useStyle = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    }
}))

const OrderBondList = () => {
    const classes = useStyle();
    const [condition, setCondition] = useState(null);

    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => {
                sessionStorage['WORK_CD'] = condition['WORK_CD'];
                setCondition(condition)}}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            onDataLoad={(jsonData, rawData) => {
                                const result = [{ WORK_CD: 'ALL', WORK_NM: '전체' }, ...rawData];
                                return result;
                            }}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
            </SearchPanel>
            <DataGrid
                title='오더채권 조회'
                selectProc='SLT_BONDPAY_ORDER'
                selectParam={condition}
                selectionMode='single'
                totalGroup={['WORK_NM']}
                horizonMode
                virtualized
                excelDownload            
                stickyHeader
            >
                <GridHeaderBand>
                    <BandItem
                        span={4}
                    >
                        거래처 정보
                    </BandItem>
                    <BandItem
                        span={2}
                    >
                        전기 채권
                    </BandItem>
                    <BandItem
                        span={2}
                    >
                        오더
                    </BandItem>
                    <BandItem
                        span={2}
                    >
                        매출 / 입금
                    </BandItem>
                    <BandItem
                        span={2}
                    >
                        당기 채권
                    </BandItem>
                </GridHeaderBand>
                <DataColumn fieldName='BEF_BAL_P' title='미수' />
                <DataColumn fieldName='BEF_BAL_M' title='선수' />
                <DataColumn fieldName='ORD_AMT' title='등록' />
                <DataColumn fieldName='TRN_AMT' title='출하' />
                <DataColumn fieldName='CUR_SALE_AMT' title='매출' />
                <DataColumn fieldName='CUR_IN_AMT' title='입금' />
                <DataColumn fieldName='BAL_P' title='미수' />
                <DataColumn fieldName='BAL_M' title='선수' />
                <DataColumn 
                    fieldName='WORK_NM' 
                    title='사업장명' 
                    total={(values, data, group) => 
                        group == null ? `- 합 계 ${totalCount(values)} 건 -` : `(소 계 ${totalCount(values)} 건) ${data[0]['WORK_NM']}`} 
                />
                <DataColumn fieldName='BEF_BAL_P' title='미수' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='BEF_BAL_M' title='선수' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='ORD_AMT' title='등록' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='TRN_AMT' title='출하' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='CUR_SALE_AMT' title='매출' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='CUR_IN_AMT' title='입금' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='BAL_P' title='미수' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='BAL_M' title='선수' total={(values) => values.reduce((result, value) => result + value, 0)} />
            </DataGrid>
        </div>
    )
}

export default OrderBondList;
