import React from 'react';
import { Document, StyleSheet, Text, View } from '@react-pdf/renderer';
import { PdfCell, PdfTable, PdfRow, PdfPage, } from '../component';
import { numberFormat } from '../common/Utils';

const styles = StyleSheet.create({
    titleSort : {
        textAlign : 'center',
        fontSize : 25,
        marginBottom : 15
    },
    tableMargin : {
        marginBottom : 20
    },
    headerCell : {
        backgroundColor : 'lightyellow',
    },
    headerGroup : {
        flex: 3,
        // fontSize: 8
    },
    footerCell : {
        backgroundColor : 'honeydew'
    },
    signText : {
        borderBottom : 1,
        borderTop : 1,
        borderLeft : 1,
        padding : 4,
        justifyContent : 'center',
    },
    numberSort : {
        textAlign : 'right',
        fontSize: 7,
        height: 18
    },

})

const Test = ({ data, workCd, workName, sta_Month, end_Month, range = true }) =>{
    const toNumStr = value => value != null && numberFormat(value)

    const getTotal = (data, field) => (
        numberFormat(data.reduce((result, item) => result + item[field], 0))
    )
    
    return(
        <Document title={`상품수불장_${workCd}_${sta_Month.replace(/-/g, '')}`}>
            <PdfPage orientation='landscape'>  
                <View style={{ flexDirection : 'row', justifyContent: 'space-between', marginBottom : 8 , marginTop : 8}}>
                    <Text style={{ fontSize : 12, alignSelf : 'flex-end' }}> {range ? sta_Month + ' ~ ' + end_Month : sta_Month} 상품 수불 명세서 </Text>
                    <View style={{ flexDirection : 'row' }}>
                        <View style={styles.signText}>
                            <Text>결{"\n"}재</Text>
                        </View>
                        <PdfTable style={{ width : 250, marginRight : 12 }}>
                            <PdfRow>
                                <PdfCell cellTop cellLeft>담당</PdfCell>
                                <PdfCell cellTop>부장</PdfCell>
                                <PdfCell cellTop>상무</PdfCell>
                                <PdfCell cellTop>전무</PdfCell>
                                <PdfCell cellTop>사장</PdfCell>
                            </PdfRow>
                            <PdfRow>
                                <PdfCell cellLeft style={{ height : 30 }}></PdfCell>
                                <PdfCell></PdfCell>
                                <PdfCell></PdfCell>
                                <PdfCell style={{ justifyContent : 'center' }}>전결</PdfCell>
                                <PdfCell></PdfCell>
                            </PdfRow>
                        </PdfTable>
                        <Text style={{ fontSize : 12, alignSelf : 'flex-end' }}>{workName}</Text>
                    </View>
                </View>
                <PdfTable>
                    <PdfRow fixed>
                        <PdfCell style={{ borderBottom: 0 }} cellLeft cellTop>품명</PdfCell>
                        <PdfCell style={styles.headerGroup} cellTop>전기이월</PdfCell>
                        <PdfCell style={styles.headerGroup} cellTop>당기매입</PdfCell>
                        <PdfCell style={styles.headerGroup} cellTop>당기판매</PdfCell>
                        <PdfCell style={styles.headerGroup} cellTop>차기이월</PdfCell>
                    </PdfRow>
                    <PdfRow fixed>
                        <PdfCell cellLeft></PdfCell>
                        <PdfCell>수량</PdfCell>
                        <PdfCell>단가</PdfCell>
                        <PdfCell>금액</PdfCell>
                        <PdfCell>수량</PdfCell>
                        <PdfCell>단가</PdfCell>
                        <PdfCell>금액</PdfCell>
                        <PdfCell>수량</PdfCell>
                        <PdfCell>단가</PdfCell>
                        <PdfCell>금액</PdfCell>
                        <PdfCell>수량</PdfCell>
                        <PdfCell>단가</PdfCell>
                        <PdfCell>금액</PdfCell>
                    </PdfRow>
                    {data.map((item, idx) => {
                        const prevItem = data[idx - 1];
                        const nextItem = data[idx + 1];
                        const isEqualPrev = prevItem && item['GDS_NM'] === prevItem['GDS_NM'];
                        const isEqualNext = nextItem && item['GDS_NM'] === nextItem['GDS_NM'];
                        let totalRow;
                        if (!isEqualNext) {
                            const groupData = data.reduce((result, row) => (
                                row['GDS_NM'] === item['GDS_NM'] ?
                                [...result, row] :
                                result
                            ), [])
                            totalRow = (
                                <PdfRow>                                    
                                    <PdfCell cellLeft style={styles.footerCell}>소 계</PdfCell>
                                    <PdfCell style={styles.numberSort}>{getTotal(groupData, 'BEF_STOCK_QTY')}</PdfCell>
                                    <PdfCell/>
                                    <PdfCell style={styles.numberSort}>{getTotal(groupData, 'BEF_STOCK_AMOUNT')}</PdfCell>
                                    <PdfCell style={styles.numberSort}>{getTotal(groupData, 'IN_QTY')}</PdfCell>
                                    <PdfCell/>
                                    <PdfCell style={styles.numberSort}>{getTotal(groupData, 'IN_AMOUNT')}</PdfCell>
                                    <PdfCell style={styles.numberSort}>{getTotal(groupData, 'SALE_QTY')}</PdfCell>
                                    <PdfCell/>
                                    <PdfCell style={styles.numberSort}>{getTotal(groupData, 'SALE_AMOUNT')}</PdfCell>
                                    <PdfCell style={styles.numberSort}>{getTotal(groupData, 'STOCK_QTY')}</PdfCell>
                                    <PdfCell/>
                                    <PdfCell style={styles.numberSort}>{getTotal(groupData, 'STOCK_AMOUNT')}</PdfCell>
                                </PdfRow>
                            )
                        }
                        return([
                            <PdfRow key={idx}>
                                <PdfCell style={isEqualNext ? { fontSize: 7.5, borderBottom : 0 } : { fontSize: 7.5 }} cellLeft>
                                    {!isEqualPrev && item['GDS_NM']}
                                </PdfCell>
                                <PdfCell style={{ ...styles.numberSort, borderBottom: isEqualNext ? 0 : 1 }}>
                                    {toNumStr(item['BEF_STOCK_QTY'])}
                                </PdfCell>
                                <PdfCell style={{ ...styles.numberSort, borderBottom: isEqualNext ? 0 : 1 }}>
                                    {toNumStr(item['BEF_STOCK_PRICE'])}
                                </PdfCell>
                                <PdfCell style={{ ...styles.numberSort, borderBottom: isEqualNext ? 0 : 1 }}>
                                    {toNumStr(item['BEF_STOCK_AMOUNT'])}
                                </PdfCell>
                                <PdfCell style={{ ...styles.numberSort, borderBottom: isEqualNext ? 0 : 1 }}>
                                    {toNumStr(item['IN_QTY'])}
                                </PdfCell>
                                <PdfCell style={{ ...styles.numberSort, borderBottom: isEqualNext ? 0 : 1 }}>
                                    {toNumStr(item['IN_PRICE'])}
                                </PdfCell>
                                <PdfCell style={{ ...styles.numberSort, borderBottom: isEqualNext ? 0 : 1 }}>
                                    {toNumStr(item['IN_AMOUNT'])}
                                </PdfCell>
                                <PdfCell style={{ ...styles.numberSort, borderBottom: isEqualNext ? 0 : 1 }}>
                                    {toNumStr(item['SALE_QTY'])}
                                </PdfCell>
                                <PdfCell style={{ ...styles.numberSort, borderBottom: isEqualNext ? 0 : 1 }}>
                                    {toNumStr(item['SALE_PRICE'])}
                                </PdfCell>
                                <PdfCell style={{ ...styles.numberSort, borderBottom: isEqualNext ? 0 : 1 }}>
                                    {toNumStr(item['SALE_AMOUNT'])}
                                </PdfCell>
                                <PdfCell style={{ ...styles.numberSort, borderBottom: isEqualNext ? 0 : 1 }}>
                                    {toNumStr(item['STOCK_QTY'])}
                                </PdfCell>
                                <PdfCell style={{ ...styles.numberSort, borderBottom: isEqualNext ? 0 : 1 }}>
                                    {toNumStr(item['STOCK_PRICE'])}
                                </PdfCell>
                                <PdfCell style={{ ...styles.numberSort, borderBottom: isEqualNext ? 0 : 1 }}>
                                    {toNumStr(item['STOCK_AMOUNT'])}
                                </PdfCell>
                            </PdfRow>,
                            totalRow
                        ])
                    })}
                    <PdfRow>
                        <PdfCell cellLeft style={styles.footerCell}>총 계</PdfCell>
                        <PdfCell style={styles.numberSort}>{getTotal(data, 'BEF_STOCK_QTY')}</PdfCell>
                        <PdfCell/>
                        <PdfCell style={styles.numberSort}>{getTotal(data, 'BEF_STOCK_AMOUNT')}</PdfCell>
                        <PdfCell style={styles.numberSort}>{getTotal(data, 'IN_QTY')}</PdfCell>
                        <PdfCell/>
                        <PdfCell style={styles.numberSort}>{getTotal(data, 'IN_AMOUNT')}</PdfCell>
                        <PdfCell style={styles.numberSort}>{getTotal(data, 'SALE_QTY')}</PdfCell>
                        <PdfCell/>
                        <PdfCell style={styles.numberSort}>{getTotal(data, 'SALE_AMOUNT')}</PdfCell>
                        <PdfCell style={styles.numberSort}>{getTotal(data, 'STOCK_QTY')}</PdfCell>
                        <PdfCell/>
                        <PdfCell style={styles.numberSort}>{getTotal(data, 'STOCK_AMOUNT')}</PdfCell>
                    </PdfRow>
                </PdfTable>
            </PdfPage>
        </Document>
    )
}

export default Test