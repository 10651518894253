import React, { useState, useEffect } from "react";
import moment from 'moment';
import clsx from 'clsx';
import { callProc, sendMail } from "../../common/DBConnector";
import { ProgressPanel, SearchPanel, SearchItem, ComboBox, DatePicker, DataGrid, DataColumn, Tabs, Tab, ComboSearch, IconButton, ProgressDialog, MailDialog } from "../../component";
import { makeStyles } from "@material-ui/core";
import PrintIcon from '@material-ui/icons/Print';
import PdfDialog from "../../component/PdfDialog";
import { TradingReportGoodsPdf } from "../../reports";
import MailIcon from '@material-ui/icons/Mail';
import { readBlobAsync } from '../../common/Utils';
import { useSnackbar } from "notistack";
import { Fragment } from "react";
import { BlobProvider } from "@react-pdf/renderer";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },

    tabContainer: {
        display: 'flex',
    },

    tabbar: {
        flex: 1,
        marginBottom: theme.spacing(1),
    },

    subTabContainer: {
        backgroundColor: theme.palette.secondary.main,
        boxShadow: theme.shadows[4],
        marginBottom: 8,
    },

    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },

    hide: {
        display: 'none'
    },
}))

const AgencySale = () => {
    const conf = useSelector(state => state.configReducer);
    const [condition, setCondition] = useState(null);
    const [selectTab, setSelectTab] = useState(0);

    const [workData, setWorkData] = useState();
    const [cstData, setCstData] = useState();
    const [workDetail, setWorkDetail] = useState();
    const [cstDetail, setCstDetail] = useState();

    const [gdsSumData, setGdsSumData] = useState();
    const [cstBondData, setCstBondData] = useState();

    const classes = useStyles();

    useEffect(() => {
        const fetchData = async () => {
            const workData = await callProc('SLT_WORKPLACEINFO_AUTH_AGENCY');
            const cstData = await callProc('SLT_CUSTOMERINFO_SALE_ALL');

            setWorkData(workData.data.reduce((result, item) => ({
                ...result,
                [item['WORK_CD']]: item['WORK_NM']
            }), {}))

            setWorkDetail(workData.data.reduce((result, item) => ({
                ...result,
                [item['WORK_CD']]: item
            }), {}))

            setCstData(cstData.data.reduce((result, item) => ({
                ...result,
                [item['WORK_CD']]: {
                    ...result[item['WORK_CD']],
                    [item['CST_CD']]: item['CST_NM']
                }
            }), {}))

            setCstDetail(cstData.data.reduce((result, item) => ({
                ...result,
                [item['WORK_CD']]: {
                    ...result[item['WORK_CD']],
                    [item['CST_CD']]: item
                }
            }), {}))
        }
        fetchData();
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            setGdsSumData(await callProc('SLT_CUSTOMER_GOODSSUM_AGENCY', condition));
            setCstBondData(await callProc('SLT_CUSTOMER_BOND_LIST', condition));
        }
        condition && fetchData();
    }, [condition])

    return (
        (!workData || !cstData || !workDetail || !cstDetail) ? <ProgressPanel /> :
            <div className={classes.container}>
                
                <SearchPanel onSearch={(condition) => {
                    setCondition(condition);
                    }}>
                    <SearchItem
                        fieldName='WORK_CD'
                        defaultValue={Object.keys(workData)[0]}
                        component={(state, setState, condition, dispatch) => (
                            <ComboBox
                                label='사업장명'
                                data={workData}
                                value={condition['WORK_CD']}
                                onChange={(value) => {
                                    dispatch('WORK_CD', value);
                                    dispatch('CST_CD', cstData[value] && Object.keys(cstData[value])[0]);
                                }}
                            />
                        )}
                    />
                    <SearchItem
                        fieldName='CST_CD'
                        defaultValue={(cstData[Object.keys(workData)[0]] && Object.keys(cstData[Object.keys(workData)[0]])[0]) || null}
                        component={(state, setState, condition, dispatch) => (
                            <ComboSearch
                                label='거래처명'
                                data={cstData[condition['WORK_CD']]}
                                value={condition['CST_CD']}
                                onChange={(value) => {
                                    dispatch('CST_CD', value);
                                }}
                            />
                        )}
                    />
                    <SearchItem
                        fieldName='STA_DATE'
                        defaultValue={moment().format('YYYY-MM-DD')}
                        component={(state, setState, condition, dispatch) => (
                            <DatePicker
                                label='시작일'
                                value={state}
                                onChange={(value) => {
                                    setState(value);
                                    if (value > condition['END_DATE']) {
                                        dispatch('END_DATE', value);
                                    }
                                }}
                            />
                        )}
                    />
                    <SearchItem
                        fieldName='END_DATE'
                        defaultValue={moment().format('YYYY-MM-DD')}
                        component={(state, setState, condition, dispatch) => (
                            <DatePicker
                                label='종료일'
                                value={state}
                                onChange={(value) => {
                                    setState(value);
                                    if (value < condition['STA_DATE']) {
                                        dispatch('STA_DATE', value);
                                    }
                                }}
                            />
                        )}
                    />
                </SearchPanel>
                <div className={classes.tabContainer}>
                    <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                        <Tab label='상품별 일자별' />
                    </Tabs>
                </div>
                <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                    <Goods
                        condition={condition}
                        workDetail={workDetail}
                        cstDetail={cstDetail}
                        gdsSumData={gdsSumData}
                        cstBondData={cstBondData}
                        conf={conf}
                    />
                </div>
            </div>
    )
}


const goodsStyle = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
    },
    info: {
        flex: 1.5,
        marginRight: theme.spacing(1),
    },
    immoney: {
        flex: 1,
        marginLeft: theme.spacing(1),
    },
}))

const Goods = ({ condition, workDetail, cstDetail, gdsSumData, cstBondData, conf }) => {
    const [oilData, setOilData] = useState();
    const [inMoneyData, setInMoneyData] = useState();
    const [loading, setLoading] = useState(false);
    const [mailReciever, setMailReciever] = useState();

    const [pdfVisible, setPdfVisible] = useState(false);
    const [mailDialog, setMailDialog] = useState(false);

    const classes = goodsStyle();
    const { enqueueSnackbar } = useSnackbar();
    
    useEffect(() => {
        const fetchData = async () => {
            setOilData(null);
            setInMoneyData(null);

            setOilData(await callProc('SLT_CUSTOMER_GOODS_AGENCY', condition));
            setInMoneyData(await callProc('SLT_CUSTOMER_INMONEY_AGENCY', condition));
        }
        condition && fetchData();
    }, [condition])

    const reqMailSend = (addr1, addr2) => {
        setLoading(true);
        setMailReciever([addr1, addr2]);
    }

    const onMailSend = async({ blob, error }) => {
        if (!error) {
            const base64data = await readBlobAsync(blob);

            const attach = {
                filename: `${workDetail[condition['WORK_CD']]['WORK_PRNT_NM']} 세금계산서.pdf`,
                path: base64data,
            };

            const result = await sendMail(
                workDetail[condition['WORK_CD']]['EMAIL'],
                workDetail[condition['WORK_CD']]['EMAIL_PASS'],
                workDetail[condition['WORK_CD']]['WORK_PRNT_NM'],
                mailReciever,
                `${workDetail[condition['WORK_CD']]['WORK_PRNT_NM']} 세금계산서`,
                '내용',
                attach
            )

            if (!result.err) {
                enqueueSnackbar('발송이 완료되었습니다.');
            }
        }
        
        setMailReciever(null);
        setLoading(false);
    }

    return (
        <div className={classes.container}>
            <DataGrid
                title='상품별 일자별'
                className={classes.info}
                totalGroup={['GDS_NM']}
                dataSet={oilData}
                horizonMode
                headerItem={() => (
                    <Fragment>
                        <IconButton
                            tooltip='메일 발송'
                            icon={<MailIcon />}
                            onClick={() => setMailDialog(true)}
                            disabled={!condition || !condition['WORK_CD'] || !condition['CST_CD'] || !oilData}
                        />
                        <IconButton
                            tooltip='출력'
                            icon={<PrintIcon />}
                            onClick={() => setPdfVisible(true)}
                            disabled={!condition || !condition['WORK_CD'] || !condition['CST_CD'] || !oilData}
                        />
                    </Fragment>
                )}
                virtualized
            >
                <DataColumn fieldName='QTY' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='SPLY_AMT' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='VAT' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='AMOUNT' total={(values) => values.reduce((result, value) => result + value, 0)} />
            </DataGrid>
            <DataGrid
                className={classes.immoney}
                title='외상수금 합계'
                dataSet={inMoneyData}
                horizonMode
            >
                <DataColumn fieldName='CARD_NM' title='입금수단' />
                <DataColumn fieldName='ACJ_DATE' title='영업일' />
                <DataColumn fieldName='AMOUNT' total={(values) => values.reduce((result, value) => result + value, 0)} />
            </DataGrid>
            <ProgressDialog open={loading} />
            <PdfDialog
                open={pdfVisible}
                onClose={() => setPdfVisible(false)}
                title='거래명세서'
            >
                <TradingReportGoodsPdf
                    workInfo={condition && workDetail && workDetail[condition['WORK_CD']]}
                    cstInfo={condition && condition['CST_CD'] && cstDetail && cstDetail[condition['WORK_CD']][condition['CST_CD']]}
                    date={condition}
                    data={oilData && oilData.data}
                    sumData={gdsSumData && gdsSumData.data}
                    cstBondData={cstBondData && cstBondData.data}
                    inMoneyData={inMoneyData && inMoneyData.data}
                    loadType={'1'}
                    conf={conf}
                />
            </PdfDialog>
            <MailDialog
                open={mailDialog}
                data={condition && cstDetail[condition['WORK_CD']][condition['CST_CD']]}
                onClose={() => setMailDialog(false)}
                onSend={reqMailSend}
            />
            {mailReciever && oilData && cstDetail && workDetail && gdsSumData && cstBondData &&
            <BlobProvider
                document={
                    <TradingReportGoodsPdf
                        workInfo={condition && workDetail && workDetail[condition['WORK_CD']]}
                        cstInfo={condition && condition['CST_CD'] && cstDetail && cstDetail[condition['WORK_CD']][condition['CST_CD']]}
                        date={condition}
                        data={oilData && oilData.data}
                        sumData={gdsSumData && gdsSumData.data}
                        cstBondData={cstBondData && cstBondData.data}
                        inMoneyData={inMoneyData && inMoneyData.data}
                        loadType={'1'}
                        conf={conf}
                    />
                }
            >
                {(state) => {
                    !state.loading && onMailSend(state);
                    return null;
                }}
            </BlobProvider>}
        </div>
    )
}

export default AgencySale;