import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, TextField } from '@material-ui/core';

const SearchItem = ({ fieldName, title, component, condition, dispatch, width, spacing }) => {
    const useStyles = makeStyles((theme) => ({
        contaier: {
            display: 'inline-block',
            width: width,
            marginRight: spacing,
            marginBottom: 8,
        },
    }))

    const classes = useStyles();
    return (
        <div className={classes.contaier}>
            {component ?
            component(condition[fieldName], (value) => dispatch(fieldName, value), condition, dispatch) :
            <TextField
                label={title}
                defaultValue={condition}
                onChange={(e) => dispatch(fieldName, e.target.value)}
                fullWidth
            />}
        </div>
    )
}

SearchItem.propTypes = {
    fieldName: PropTypes.string.isRequired,
    title: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    component: PropTypes.func,
    width: PropTypes.number,
    spacing: PropTypes.number,
}

SearchItem.defaultProps = {
}

export default SearchItem;