import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { SearchItem, SearchPanel, ComboBox, DatePicker, DataGrid, DataColumn, GridHeaderBand, BandItem, Tab, Tabs, PdfButton } from '../../component';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { ProductTypesStockPdf, ProductTypesStockTankPdf } from '../../reports';
import { totalCount } from '../../common/Utils';


const useStyle = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    tabContainer: {
        display: 'flex',
    },
    tabbar: {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    tab: {
        minWidth: 100,
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
    hide: {
        display: 'none'
    },

}))

const ProductTypesStock = () => {
    const classes = useStyle();
    const [condition, setCondition] = useState(null);
    const [selectTab, setSelectTab] = useState(0);
    const [workData, setWorkData] = useState();

    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => { 
               sessionStorage['WORK_CD'] = condition['WORK_CD'];
               setCondition(condition)}}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            onDataLoad={(jsonData, rawData) => {
                                const result = [...rawData];
                                setWorkData(result);
                                return result;
                            }}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
            </SearchPanel>

            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab className={classes.tab} label='상품별 재고현황' />
                    <Tab className={classes.tab} label='탱크별 재고현황' />
                </Tabs>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <Product condition={condition} workData={workData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <Storeroom condition={condition} workData={workData} />
            </div>
 
        </div>
    )
}

const Product = ({condition, workData}) => {
    const [pdfData, setPdfData] = useState();
    const loginState = useSelector(state => state.loginReducer);

    const pdfComponent = useMemo(() => (
        <PdfButton title='상품별 재고현황'>
            {pdfData &&
            <ProductTypesStockPdf
                pdfData={pdfData}
                condition={condition}
                loginState={loginState}
                workName={workData.find(item => item['WORK_CD'] === condition['WORK_CD'])['WORK_NM']}
            />}
        </PdfButton>
    ), [pdfData, condition, loginState, workData]);

    return (
        <>
            <DataGrid
                title='상품별 재고현황'
                selectProc='SLT_PRODUCT_STOCK'
                selectParam={condition}
                selectionMode='single'
                horizonMode
                virtualized
                excelDownload
                headerItem={({visibleAoa}) => {
                    setPdfData(visibleAoa);
                    return pdfComponent;
                }}
                stickyHeader
                filterable
            >
                <GridHeaderBand>
                    <BandItem
                        span={1}
                    >
                        정보
                    </BandItem>
                    <BandItem
                        span={1}
                    >
                        전기
                    </BandItem>
                    <BandItem
                        span={4}
                    >
                        입고량
                    </BandItem>
                    <BandItem
                        span={2}
                    >
                        출고량
                    </BandItem>
                    <BandItem
                        span={1}
                    >
                        당기
                    </BandItem>
                </GridHeaderBand>

                <DataColumn fieldName='GDS_NM' title='상품' />
                <DataColumn fieldName='GDS_CD' visible={false} />
                <DataColumn fieldName='BEF_QTY' title='전기 재고량' />

                <DataColumn fieldName='CUR_IN_QTY' title='매입' />
                <DataColumn fieldName='CUR_IN_MOVE_QTY' title='이관' />
                <DataColumn fieldName='CUR_ADJ_QTY' title='재고조정' />
                <DataColumn fieldName='CUR_IN_SUM_QTY' title='총 입고' />

                <DataColumn fieldName='CUR_SALE_QTY' title='매출' />
                <DataColumn fieldName='CUR_OUT_MOVE_QTY' title='이관' />

                <DataColumn fieldName='CUR_QTY' title='당기재고량' />

            </DataGrid>
        </>
    )
}
const Storeroom = ({condition, workData}) =>{
    const [pdfData, setPdfData] = useState();
    const loginState = useSelector(state => state.loginReducer);

    const pdfComponent = useMemo(() => (
        <PdfButton title='탱크별 재고현황'>
            {pdfData &&
            <ProductTypesStockTankPdf
                pdfData={pdfData}
                condition={condition}
                loginState={loginState}
                workName={workData.find(item => item['WORK_CD'] === condition['WORK_CD'])['WORK_NM']}
            />}
        </PdfButton>
    ), [pdfData, condition, loginState, workData]);
    return (
        <DataGrid
            title='탱크별 재고현황'
            selectProc='SLT_TANK_STOCK'
            selectParam={condition}
            selectionMode='single'
            horizonMode
            totalGroup={['GDS_TYPE']}
            totalSort={(a, b) => a['GDS_TYPE'] < b['GDS_TYPE'] ? -1 : 1}
            virtualized
            sortable
            filterable
            excelDownload
            headerItem={({visibleAoa}) => {
                setPdfData(visibleAoa);
                return pdfComponent;
            }}
            stickyHeader
        >
            <DataColumn fieldName='TNK_NO' />
            <DataColumn fieldName='CATE_NM' />
            <DataColumn fieldName='BEF_QTY' title='전기재고'/>
            <DataColumn fieldName='CUR_IN_QTY' title='당기입고'/>
            <DataColumn fieldName='CUR_MOVE_IN_QTY' title='당기이관입고'/>
            <DataColumn fieldName='CUR_SALE_QTY' title='당기출고' />
            <DataColumn fieldName='CUR_MOVE_OUT_QTY' title='당기이관출고'/>
            <DataColumn fieldName='CUR_QTY' title='당기재고량' />
            <DataColumn fieldName='GDS_TYPE' visible={false} />
            <DataColumn fieldName='SUM_TYPE' visible={false} />
            <DataColumn 
                    fieldName='CATE_NM' 
                    total={(values, data, group) => 
                        group == null ? `- 합 계 ${totalCount(values)} 건 -` : 
                        `(소 계 ${totalCount(values)} 건) ${data[0]['CATE_NM']}` 
                    } 
                />
            <DataColumn fieldName='BEF_QTY' title='전기재고' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='CUR_IN_QTY' title='당기입고' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='CUR_MOVE_IN_QTY' title='당기이관입고' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='CUR_SALE_QTY' title='당기출고' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='CUR_MOVE_OUT_QTY' title='당기이관출고' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='CUR_QTY' title='당기재고량' total={(values) => values.reduce((result, value) => result + value, 0)} />
        </DataGrid>
    )
}


export default ProductTypesStock;