import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles,TextField, Dialog, DialogTitle } from '@material-ui/core';
import { DataGrid, ComboBox, ProgressDialog, DataColumn, IconButton } from '../../component';
import CheckIcon from '@material-ui/icons/Check';
import { useFieldInputs, toHash } from '../../common/Utils';
import { callProc } from '../../common/DBConnector';
import { useSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
    },
    info: {
        flex: 1,
        marginRight: theme.spacing(1),
    },
    enroll: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(1),
        width: 350,
    },
}))


const TransLoginInfo = () => {
    const [condition, setCondition] = useState({});
    const [signUpPopup, setSignUpPopup] = useState(false);
    const [editData, setEditData] = useState();

    const classes = useStyles();

    const refreshGrid = () => setCondition({ ...condition })

    return(
    <div className={classes.container}>
        <div className={classes.info}>
            <DataGrid
                title='수송로그인 정보'
                table='TB_TRANSLOGIN'
                selectProc='SLT_TRANSLOGIN'
                selectParam={condition}
                selectionMode='single'
                headerItem={() => (
                    <IconButton
                        tooltip='추가'
                        icon={<AddIcon />}
                        onClick={() => setSignUpPopup(true)}
                    />
                )}
                refreshable
            >
                <DataColumn
                    position='WORK_NM'
                    title='비밀번호'
                    value={(row) => (
                        <IconButton
                            tooltip='수정'
                            icon={<EditIcon />}
                            onClick={() => setEditData(row)}
                        />
                    )}
                />
                <DataColumn fieldName={'TSP_CAR_CD'} visible={false} />
                <DataColumn fieldName={'WORK_CD'} visible={false} />
            </DataGrid>
        </div>
        
        <EnrollDialog
            open={signUpPopup}
            onClose={() => setSignUpPopup(false)}
            onInserted={refreshGrid}
        />

        <EditDialog
            data={editData}
            onClose={() => setEditData(null)}
            onInserted={refreshGrid}
        />
    </div>
    )
}

const useDialogStyle = makeStyles(theme => ({
    signUpDialog: {
        width: 300,
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    paperContent: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: theme.spacing(2),
        paddingTop: 0,
        height: '100%',
        overflowY: 'auto',
    },
    btnContainer: {
        alignSelf: 'flex-end',
        marginTop: 'auto',
        padding: theme.spacing(2),
    },
    field: {
        marginBottom: 16,
    }
}))

const EnrollDialog = ({ onInserted, open, onClose }) => {
    const [insData, setInsData, insClear] = useFieldInputs({
        ID: '',
        PASSWORD: '',
        PASS_CONFIRM: '',
        WORK_CD: null,
        TSP_CAR_NO: null,
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        insClear();
    }, [open, insClear])

    const { enqueueSnackbar } = useSnackbar();
    const classes = useDialogStyle();

    const valid = () => {
        if (insData['TSP_CAR_CD'] == null) {
            enqueueSnackbar('차량을 선택하여 주세요.', { variant: 'error' });
            return false;
        }
        if (insData['ID'].length < 4) {
            enqueueSnackbar('아이디가 너무 짧습니다.(최소 4자)', { variant: 'error' });
            return false;
        }
        if (insData['PASSWORD'] < 4) {
            enqueueSnackbar('비밀번호가 너무 짧습니다.(최소 4자)', { variant: 'error' });
            return false;
        }
        if (insData['PASSWORD'] !== insData['PASS_CONFIRM']) {
            enqueueSnackbar('비밀번호가 일치하지 않습니다.', { variant: 'error' });
            return false;
        }
        return true;
    }

    const onInsert = async() => {
        setLoading(true);

        if (valid()) {
            const result = await callProc('INS_TRANSLOGIN', { ...insData, PASSWORD: toHash(insData['PASSWORD']) });
            if (!result.err) {
                onClose();
                enqueueSnackbar('저장이 완료되었습니다.');
                onInserted && onInserted();
            }
        }

        setLoading(false);
    }

    const insWorkCd = insData['WORK_CD'];
    const carParam = useMemo(() => (
        insWorkCd ?
        { WORK_CD: insWorkCd } :
        null
    ), [insWorkCd])

    return (
        <Dialog
            open={open}
            PaperProps={{ className: classes.signUpDialog }}
            onClose={onClose}
        >
            <ProgressDialog open={loading} />
            <div className={classes.title}>
                <DialogTitle>
                    회원가입
                </DialogTitle>
                <IconButton
                    tootip='닫기'
                    icon={<CloseIcon />}
                    onClick={() => onClose()}
                />
            </div>
            <div className={classes.paperContent}>
                <ComboBox
                    label='사업장명'
                    className={classes.field}
                    selectProc='SLT_WORKPLACEINFO_AUTH'
                    keyField='WORK_CD'
                    valueField='WORK_NM'
                    onChange={value => setInsData('WORK_CD', value)}
                    value={insData['WORK_CD']}
                />
                <ComboBox
                    label='차량번호'
                    className={classes.field}
                    selectProc='SLT_TRANSCARINFO'
                    selectParam={carParam}
                    keyField='TSP_CAR_CD'
                    valueField='CAR_NO'
                    onChange={value => setInsData('TSP_CAR_CD', value)}
                    value={insData['TSP_CAR_CD']}
                />
                <TextField
                    label='아이디'
                    className={classes.field}
                    value={insData['ID']}
                    onChange={e => setInsData('ID', e.target.value)}
                />
                <TextField
                    label='비밀번호'
                    className={classes.field}
                    type='password'
                    value={insData['PASSWORD']}
                    onChange={e => setInsData('PASSWORD', e.target.value)}
                />
                <TextField
                    label='비밀번호 확인'
                    className={classes.field}
                    type='password'
                    value={insData['PASS_CONFIRM']}
                    onChange={e => setInsData('PASS_CONFIRM', e.target.value)}
                />
            </div>
            <div className={classes.btnContainer}>
                <IconButton
                    tooltip='확인'
                    icon={<CheckIcon />}
                    onClick={onInsert}
                />
            </div>
        </Dialog>
    )
}

const EditDialog = ({ data, onClose, onInserted }) => {
    const [insData, setInsData] = useFieldInputs({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (data == null)
            return;
        setInsData('ID', data['ID']);
        setInsData('PASSWORD', '');
        setInsData('PASS_CONFIRM', '');
        setInsData('WORK_CD', data['WORK_CD']);
        setInsData('TSP_CAR_CD', data['TSP_CAR_CD']);
    }, [data, setInsData])

    const { enqueueSnackbar } = useSnackbar();
    const classes = useDialogStyle();

    const valid = () => {
        if (insData['PASSWORD'].length < 4) {
            enqueueSnackbar('비밀번호가 너무 짧습니다.(최소 4자)', { variant: 'error' });
            return false;
        }
        if (insData['PASSWORD'] !== insData['PASS_CONFIRM']) {
            enqueueSnackbar('비밀번호가 일치하지 않습니다.', { variant: 'error' });
            return false;
        }
        return true;
    }

    const onInsert = async() => {
        setLoading(true);

        if (valid()) {
            const result = await callProc('UPT_TRANSLOGIN', {
                ...insData,
                PASSWORD: toHash(insData['PASSWORD']),
            });
            if (!result.err) {
                onClose();
                enqueueSnackbar('저장이 완료되었습니다.');
                onInserted && onInserted();
            }
        }

        setLoading(false);
    }

    return (
        <Dialog
            open={data != null}
            PaperProps={{ className: classes.signUpDialog }}
            onClose={onClose}
        >
            <ProgressDialog open={loading} />
            <div className={classes.title}>
                <DialogTitle>
                    비밀번호 변경
                </DialogTitle>
                <IconButton
                    tooltip='닫기'
                    icon={<CloseIcon />}
                    onClick={() => onClose()}
                />
            </div>
            <div className={classes.paperContent}>
                <TextField
                    label='아이디'
                    className={classes.field}
                    value={insData['ID']}
                    disabled
                />
                <TextField
                    label='비밀번호'
                    className={classes.field}
                    type='password'
                    value={insData['PASSWORD']}
                    onChange={e => setInsData('PASSWORD', e.target.value)}
                />
                <TextField
                    label='비밀번호 확인'
                    className={classes.field}
                    type='password'
                    value={insData['PASS_CONFIRM']}
                    onChange={e => setInsData('PASS_CONFIRM', e.target.value)}
                />
            </div>
            <div className={classes.btnContainer}>
                <IconButton
                    tooltip='확인'
                    icon={<CheckIcon />}
                    onClick={onInsert}
                />
            </div>
        </Dialog>
    )
}

export default TransLoginInfo;
