import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';
import { ComboBox, DataColumn, DataGrid, DatePicker, SearchItem, SearchPanel } from '../../component';
import { numberPointFormat } from '../../common/Utils';

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
}))

const AchieveReport = () => {
    const [condition, setCondition] = useState(null);
    const classes = useStyles();

    const getTotalAchPer = (values, data) => {
        const totalAchQty = data.reduce((result, row) => result + row['ACH_QTY'], 0);
        const totalQty = data.reduce((result, row) => result + row['QTY'], 0);
        return numberPointFormat(totalQty / totalAchQty * 100, 2)
    }

    return(
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => setCondition(condition)}>
                <SearchItem
                    fieldName='WORK_CD'
                    component={(state, dispatch) => (
                        <ComboBox
                            label='사업장명'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            onDataLoad={(jsonData, rawData) => [ {WORK_CD: 'ALL', WORK_NM: '전체'}, ...rawData ]}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_MONTH'
                    defaultValue={moment().format('YYYY-MM')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작월'
                            value={state}
                            monthPicker
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_MONTH']) {
                                    dispatch('END_MONTH', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_MONTH'
                    defaultValue={moment().format('YYYY-MM')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료월'
                            value={state}
                            monthPicker
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_MONTH']) {
                                    dispatch('STA_MONTH', value);
                                }
                            }}
                        />
                    )}
                />
            </SearchPanel>
            <DataGrid
                title='매출 달성률'
                selectProc='SLT_ACHIEVE_WORK'
                selectParam={condition}
                selectionMode='single'
                totalGroup={['WORK_CD']}
                horizonMode
                virtualized
                excelDownload
                stickyHeader
            >
                <DataColumn fieldName='STA_DATE' title='기준시작일' />
                <DataColumn fieldName='END_DATE' title='기준종료일' />
                <DataColumn fieldName='ACH_QTY' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='QTY' title='매출 수량' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='ACH_PER' title='달성률' total={getTotalAchPer} />
                <DataColumn fieldName='WORK_CD' visible={false} />
                <DataColumn fieldName='GDS_CD' visible={false} />
            </DataGrid>
        </div>
    )
    // cons
    // const monthTickFormatter = (tick) => {
    //     const date = new Date(tick);
      
    //     return date.getMonth() + 1;
    //   };
      
    //   const renderQuarterTick = (tickProps) => {
    //     const { x, y, payload } = tickProps;
    //     const { value, offset } = payload;
    //     const date = new Date(value);
    //     const month = date.getMonth();
    //     const quarterNo = Math.floor(month / 3) + 1;
    //     const isMidMonth = month % 3 === 1;
      
    //     if (month % 3 === 1) {
    //       return <text x={x} y={y - 4} textAnchor="middle">{`Q${quarterNo}`}</text>;
    //     }
      
    //     const isLast = month === 11;
      
    //     if (month % 3 === 0 || isLast) {
    //       const pathX = Math.floor(isLast ? x + offset : x - offset) + 0.5;
      
    //       return <path d={`M${pathX},${y - 4}v${-35}`} stroke="red" />;
    //     }
    //     return null;
    //   };
    // return (
    //           <ResponsiveContainer width="100%" height="100%">
    //             <BarChart
    //               width={500}
    //               height={300}
    //               data={data}
    //               margin={{
    //                 top: 5,
    //                 right: 30,
    //                 left: 20,
    //                 bottom: 5,
    //               }}
    //             >
    //               <CartesianGrid strokeDasharray="3 3" />
    //               <XAxis dataKey="date" tickFormatter={monthTickFormatter} />
    //               <XAxis
    //                 dataKey="date"
    //                 axisLine={false}
    //                 tickLine={false}
    //                 interval={0}
    //                 tick={renderQuarterTick}
    //                 height={1}
    //                 scale="band"
    //                 xAxisId="quarter"
    //               />
    //               <YAxis />
    //               <Tooltip />
    //               <Legend />
    //               <Bar dataKey="pv" fill="#8884d8" />
    //               <Bar dataKey="uv" fill="#82ca9d" />
    //             </BarChart>
    //           </ResponsiveContainer>
    // )
}

export default AchieveReport;