import React, { useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';
import { SearchPanel, SearchItem, ComboBox, DataGrid, DataColumn, ComboSearch, Tabs, Tab, NumberField, DateTimePicker } from '../../component';
import { isFieldDiff } from '../../common/Utils';
import clsx from 'clsx';

const useStyle = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    tabContainer : {
        display : 'flex',
    },
    tabbar : {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    content : {
        display: 'flex',
        flex: 1,
        height : 0,
    },
    hide : {
        display: 'none'
    },
}))

const ShippingCostChargeTariffBuy = () => {
    const classes = useStyle();
    const [condition, setCondition] = useState(null);
    const [selectTab, setSelectTab] = useState(0);

    return(
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => setCondition(condition)}>
                <SearchItem
                    fieldName='WORK_CD'
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => {
                                dispatch(value);
                            }}
                        />
                    )}
                />

                <SearchItem
                    fieldName='CST_CD'
                    component={(state, setState, condition, setField) => {
                        let param = condition['CST_PARAM'] || null;
                        if (isFieldDiff(['WORK_CD'], condition, param)) {
                            param = { WORK_CD: condition['WORK_CD'] };
                            setField('CST_PARAM', param);
                        }
                        return (
                            <ComboSearch
                                label='매입처'
                                selectProc='SLT_CUSTOMERINFO_BUY'
                                selectParam={param}
                                keyField='CST_CD'
                                valueField='CST_NM'
                                value={state}
                                onChange={value => setState(value)}
                            />
                        )
                    }}
                />

                <SearchItem
                    fieldName='TRN_TYPE'
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='상품분류'
                            selectProc='SLT_CATEGORY_TRANS'
                            keyField='TRN_TYPE'
                            valueField='TRN_CATE_NM'
                            value={state}
                            onChange={(value) => {
                                dispatch(value);
                            }}
                        />
                    )}
                />


            </SearchPanel>

            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab label='현재적용 매입 운임요율' />
                    <Tab label='전체 매입 운임요율' />
                </Tabs>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <IntransPriceNow condition={condition} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <IntransPriceAll condition={condition} />
            </div>



        </div>
    )
}

const IntransPriceNow = ({condition}) => {


    return(
        <DataGrid
        title='현재적용 매입 운임요율'
        table='TB_INTRANSPRICE'
        selectProc='SLT_INTRANSPRICE'
        selectParam={condition}
        selectionMode='single'
        horizonMode
        excelDownload={{ fileName: '현재적용 매입 운임요율.xlsx', sheetName: '매입운임요율' }}
    >
        
        <DataColumn
            fieldName='SHIP_TRN_CD'
            value={row => row['SHIP_TRN_NM']}
            title='출하처'
        />

        <DataColumn
            fieldName='DEST_TRN_CD'
            value={row => row['DEST_TRN_NM']}
            title='인도처'
        />

        <DataColumn
            fieldName='STA_DTTM'
            title='시작일시'
        />

        <DataColumn
            fieldName='SPLY_AMT'
            title='공급가액'
        />

        <DataColumn
            fieldName='AMOUNT'
            title='합계금액'
        />
        
        <DataColumn
                fieldName='SHIP_TRN_NM'
                visible={false}
        />
        <DataColumn
                fieldName='DEST_TRN_NM'
                visible={false}
        />

 
    </DataGrid>
    )
}


const IntransPriceAll = ({condition}) => {



    return(
        <DataGrid
        title='전체 매입 운임요율'
        selectProc='SLT_INTRANSPRICE_ALL'
        insertProc='INS_INTRANSPRICE'
        updateProc='UPT_INTRANSPRICE'
        deleteProc='DLT_INTRANSPRICE'
        selectParam={condition}
        keys={['WORK_CD', 'CST_CD', 'TRN_TYPE', 'SHIP_TRN_CD', 'DEST_TRN_CD', 'STA_DTTM', 'SEQ']}
        selectionMode='single'
        horizonMode
    >

        <DataColumn
            fieldName='WORK_CD'
            title='사업장'
            value={row => row['WORK_NM']}
            defaultValue={condition && condition['WORK_CD']}
            editable={(state, dispatch) => (
                <ComboBox
                    selectProc='SLT_WORKPLACEINFO_AUTH'
                    selectParam={condition}
                    keyField='WORK_CD'
                    valueField='WORK_NM'
                    value={state}
                    onChange={value => dispatch(value)}
                    disabled={true}
                />
            )}
        />

        <DataColumn
            fieldName='CST_CD'
            title='거래처'
            value={row => row['CST_NM']}
            defaultValue={condition && condition['CST_CD']}
            editable={(state, dispatch) => (
                <ComboBox
                    selectProc='SLT_CUSTOMERINFO_BUY'
                    selectParam={condition}
                    keyField='CST_CD'
                    valueField='CST_NM'
                    value={state}
                    onChange={value => dispatch(value)}
                    disabled={true}
                />
            )}
        />

        <DataColumn
            fieldName='TRN_TYPE'
            title='상품분류'
            value={row => row['TRN_CATE_NM']}
            defaultValue={condition && condition['TRN_TYPE']}
            editable={(state, dispatch) => (
                <ComboBox
                    selectProc='SLT_CATEGORY_TRANS'
                    selectParam={condition}
                    keyField='TRN_TYPE'
                    valueField='TRN_CATE_NM'
                    value={state}
                    onChange={value => dispatch(value)}
                    disabled={true}
                />
            )}
        />

        <DataColumn
            fieldName='SHIP_TRN_CD'
            title='출하처'
            value={row => row['SHIP_TRN_NM']}
            editable={(state, dispatch, rowState, setField) => {
                let param = rowState['SHIP_PARAM'] || null;
                if (isFieldDiff(['WORK_CD'], rowState, param)) {
                    param = { WORK_CD: rowState['WORK_CD'], TSP_TYPE: 0 };
                    setField('SHIP_PARAM', param);
                }

                return(
                <ComboSearch
                    style={{width: 200}}
                    selectProc='SLT_TRANSPLACE'
                    selectParam={param} //0
                    keyField='TSP_CD'
                    valueField='TSP_NM'
                    value={state}
                    onChange={value => dispatch(value)}
                />
            )}}
        />

        <DataColumn
            fieldName='DEST_TRN_CD'
            title='인도처'
            value={row => row['DEST_TRN_NM']}
            editable={(state, dispatch, rowState, setField) => {
                let param = rowState['DEST_PARAM'] || null;
                if (isFieldDiff(['WORK_CD'], rowState, param)) {
                    param = { WORK_CD: rowState['WORK_CD'], TSP_TYPE: 1 };
                    setField('DEST_PARAM', param);
                }

                return(
                <ComboSearch
                    style={{width: 200}}
                    selectProc='SLT_TRANSPLACE'
                    selectParam={param} //0
                    keyField='TSP_CD'
                    valueField='TSP_NM'
                    value={state}
                    onChange={value => dispatch(value)}
                />
            )}}
        />

        <DataColumn
            fieldName='STA_DTTM'
            title='시작일시'
            defaultValue={moment().format('YYYY-MM-DD 00:00:00')}
            editable={(state, dispatch) => (
                <DateTimePicker
                    value={state}
                    onChange={value => {
                        dispatch(value);
                    }}
                />
            )}
        />

        <DataColumn
            fieldName='SPLY_AMT'
            title='공급가액'
            editable={(state, dispatch, rowState, setField) => (
                <NumberField
                    style={{width: 100}}
                    value={state}
                    onChange={value => dispatch(value)}
                    onBlur={() => {
                        let AmountNum = Math.round(rowState['SPLY_AMT'] * 1.1 * 10000) / 10000
                        setField('AMOUNT', AmountNum);
                    }}
                />
            )}
        />

        <DataColumn
            fieldName='AMOUNT'
            title='합계금액'
            editable={(state, dispatch, rowState, setField) => (
                <NumberField
                    style={{width: 100}}
                    value={state}
                    onChange={value => dispatch(value)}
                    onBlur={() => {
                        if (rowState['SPLY_AMT'].toString() === '0') {
                            setField('SPLY_AMT', rowState['AMOUNT']);
                        }
                    }}
                />
            )}
        />
        
        <DataColumn
                fieldName='SEQ'
                visible={false}
        />
        <DataColumn
                fieldName='WORK_NM'
                visible={false}
        />

        <DataColumn
                fieldName='CST_NM'
                visible={false}
        />
        <DataColumn
                fieldName='TRN_CATE_NM'
                visible={false}
        />

        <DataColumn
                fieldName='SHIP_TRN_NM'
                visible={false}
        />
        <DataColumn
                fieldName='DEST_TRN_NM'
                visible={false}
        />



    </DataGrid>
    )
}
export default ShippingCostChargeTariffBuy