import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { ComboBox, DataColumn, DataGrid, DatePicker, PdfButton, SearchItem, SearchPanel, SmallGrid, Tab, Tabs } from '../../component';
import { callProc } from '../../common/DBConnector';
import { OilbankSummaryPdf } from '../../reports';
import { useSelector } from 'react-redux';


const useStyle = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    subTabContainer: {
        backgroundColor: theme.palette.secondary.main,
        boxShadow: theme.shadows[4],
        marginBottom: theme.spacing(1),
    },
    tabContainer: {
        display: 'flex',
    },
    tabbar: {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
    hide: {
        display: 'none'
    },
}))

const OilbankSummary = () => {
    const classes = useStyle();
    const [condition, setCondition] = useState(null);
    const [selectTab, setSelectTab] = useState(0);

    const [salestockData, setSalestockData] = useState();
    const [supplyDataDay, setSupplyDataDay] = useState();
    const [supplyDataMonth, setSupplyDataMonth] = useState();
    const [salePriceChangeData, setSalePriceChangeData] = useState();
    const [gaugeInfoListDataDay, setGaugeInfoListDataDay] = useState();
    const [gaugeInfoListDataMonth, setGaugeInfoListDataMonth] = useState();
    
    const [saleBondList, setSaleBondList] = useState();
    const [saleBondListExceptCardFee, setSaleBondListExceptCardFee] = useState();
    const [saleBondListCardFee, setSaleBondListCardFee] = useState();
    
    const [saleBondListDetail, setSaleBondListDetail] = useState();
    const [saleCstViolationData, setSaleCstViolationData] = useState();
    const [etcData, setEtcData] = useState();

    const loginState = useSelector(state => state.loginReducer);
    const [workData, setWorkData] = useState();
 
    useEffect(() => {
        const fetchData = async () => {
            const workData = await callProc('SLT_WORKPLACEINFO_NOTMAIN_AUTH');

            setWorkData(workData.data.reduce((result, item) => ({
                ...result,
                [item['WORK_CD']]: item['WORK_NM']
            }), {}))

        }
        fetchData();
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            setSalestockData(null);
            setSupplyDataDay(null);
            setSupplyDataMonth(null);
            setSalePriceChangeData(null);
            setGaugeInfoListDataDay(null);
            setGaugeInfoListDataMonth(null);
            
            setSaleBondList(null);
            setSaleBondListExceptCardFee(null);
            setSaleBondListCardFee(null);
            
            setSaleBondListDetail(null);
            setSaleCstViolationData(null);
            setEtcData(null);

            setSalestockData(await callProc('SLT_SALE_STOCK', condition));
            setSupplyDataDay(await callProc('SLT_RANGE_STOCK_DETAIL', condition));
            setSupplyDataMonth(await callProc('SLT_RANGE_STOCK_DETAIL_MONTH', condition));
            setSalePriceChangeData(await callProc('SLT_SALEPRICEINFO_CHANGE_HIS',condition));
            setGaugeInfoListDataDay(await callProc('SLT_GAUGEINFO_ETC_GAUGE', condition)); //이름변경
            setGaugeInfoListDataMonth(await callProc('SLT_GAUGEINFO_ETC_GAUGE_MONTH', condition)); //이름변경 
            
            setSaleBondList(await callProc('SLT_SALE_BOND_LIST', condition));
            setSaleCstViolationData(await callProc('SLT_SALE_CST_CREDIT_VIOLATION', condition));
            setEtcData(await callProc('SLT_ETC_LIST', condition));

            // 출력 전용
            setSaleBondListExceptCardFee(await callProc('SLT_SALE_BOND_LIST_EXCEPT_CARD_FEE', condition));          
            setSaleBondListCardFee(await callProc('SLT_SALE_BOND_LIST_CARD_FEE', condition));
            
            setSaleBondListDetail(await callProc('SLT_SALE_BOND_LIST_DETAIL_PRINT', condition));
        }
        condition && fetchData();
    }, [condition])


    const condWorkName = workData && condition && workData[condition['WORK_CD']];
    const pdfComponent = useMemo(() => (
        <PdfButton title='주유소 집계표'>
            {salestockData 
            && supplyDataDay 
            && supplyDataMonth 
            && salePriceChangeData 
            && gaugeInfoListDataDay 
            && gaugeInfoListDataMonth
            && saleBondListExceptCardFee
            && saleBondListCardFee
            && saleBondListDetail
            && saleCstViolationData
            && etcData &&
            <OilbankSummaryPdf
                salestockData={salestockData && salestockData.data}
                supplyDataDay={supplyDataDay && supplyDataDay.data}
                supplyDataMonth={supplyDataMonth && supplyDataMonth.data}
                salePriceChangeData={salePriceChangeData && salePriceChangeData.data}
                gaugeInfoListDataDay={gaugeInfoListDataDay && gaugeInfoListDataDay.data}
                gaugeInfoListDataMonth={gaugeInfoListDataMonth && gaugeInfoListDataMonth.data}

                saleBondListExceptCardFee={saleBondListExceptCardFee && saleBondListExceptCardFee.data}
                saleBondListCardFee={saleBondListCardFee && saleBondListCardFee.data}
                
                saleBondListDetail={saleBondListDetail && saleBondListDetail.data}
                saleCstViolationData={saleCstViolationData && saleCstViolationData.data}
                etcData={etcData && etcData.data}

                condition={condition}
                loginState={loginState}
                workName={condWorkName}
            />}
        </PdfButton>
    ), [salestockData, 
        supplyDataDay, 
        supplyDataMonth, 
        salePriceChangeData, 
        gaugeInfoListDataDay, 
        gaugeInfoListDataMonth, 
        saleBondListExceptCardFee, 
        saleBondListCardFee, 
        saleBondListDetail,
        saleCstViolationData,
        etcData,
        condition, 
        loginState, 
        condWorkName]);



    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => { 
                if (condition['WORK_CD'] !== 'ALL'){
                    sessionStorage['WORK_CD'] = condition['WORK_CD'];
                }
                setCondition(condition); }}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, dispatch) => (
                        <ComboBox
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_OIL_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            onDataLoad={jsonData => ({ ALL: '전체', ...jsonData })}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
            </SearchPanel>

            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab label='판매량 및 재고 현황' />
                    <Tab label='일/월 상세 재고 현황' />
                    <Tab label='점두가' />
                    <Tab label='일반유 계기외 현황' />
                    <Tab label='매출 시재 현황' />
                    <Tab label='위배 거래처 현황' />
                    <Tab label='기타' />
                </Tabs>
                <div className={classes.subTabContainer}>

                    {pdfComponent}
                </div>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <SaleStock salestockData={salestockData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <Supply supplyDataDay={supplyDataDay} supplyDataMonth={supplyDataMonth} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 2 })}>
                <SalePriceChange salePriceChangeData={salePriceChangeData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 3 })}>
                <GaugeEtc gaugeInfoListDataDay={gaugeInfoListDataDay} gaugeInfoListDataMonth={gaugeInfoListDataMonth} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 4 })}>
                <SaleBondList saleBondList={saleBondList} condition={condition} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 5 })}>
                <SaleCstViolation saleCstViolationData={saleCstViolationData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 6 })}>
                <Etc etcData={etcData} />
            </div>
        </div>
    )
}

const SaleStock = ({ salestockData }) => (
    <DataGrid
        title='판매량 및 재고 현황'
        dataSet={salestockData}
        totalGroup={['GDS_TYPE']}
    >
        <DataColumn fieldName='GDS_TYPE' visible={false} />
        <DataColumn fieldName='SALE_QTY' title='판매량' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='SALE_AMOUNT' title='판매금액' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='CUR_STOCK_QTY' title='장부재고' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='LAST_REAL_QTY' title='실재고' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='CUR_GAP_QTY' title='당일실재고차' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='BEF_GAP_QTY' title='전일실재고차' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='MONTH_SALE_QTY' title='월 판매량' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='MONTH_SALE_AMOUNT' title='월 판매금액' total={(values) => values.reduce((result, value) => result + value, 0)} />
    </DataGrid>
)

const Supply = ({ supplyDataDay, supplyDataMonth }) => {
    const classes = useStyle();

    return (
        <div className={classes.container}>
            <DataGrid
                style={{ marginBottom: 8 }}
                title='기간별 상세 재고 현황'
                dataSet={supplyDataDay}
                
            >
                <DataColumn fieldName='BEF_STOCK_QTY' title='전기재고' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='CUR_IN_QTY' title='당기입고' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='CUR_SALE_QTY' title='당기출고' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='CUR_STOCK_QTY' title='당기재고' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='CUR_ADJ_QTY' title='재고조정' position='CUR_MOVE_QTY' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='CUR_TEST_QTY' title='검량' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='CUR_MOVE_QTY' title='자체이관' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            </DataGrid>

            <DataGrid
                title='월별 상세 재고 현황'
                dataSet={supplyDataMonth}
            >
                <DataColumn fieldName='BEF_STOCK_QTY' title='전기재고' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='CUR_IN_QTY' title='당기입고' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='CUR_SALE_QTY' title='당기출고' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='CUR_STOCK_QTY' title='당기재고' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='CUR_ADJ_QTY' title='재고조정' position='CUR_MOVE_QTY' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='CUR_TEST_QTY' title='검량' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='CUR_MOVE_QTY' title='자체이관' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            </DataGrid>
        </div>
    )
}

const SalePriceChange = ({ salePriceChangeData }) => (
    <DataGrid
        title='점두가'
        dataSet={salePriceChangeData}
    >
        <DataColumn fieldName='BEF_PRICE' title='변경전' />
        <DataColumn fieldName='CUR_PRICE' title='현재' />
        <DataColumn fieldName='STA_DTTM' title='변경시간' />
    </DataGrid>
)

const GaugeEtc = ({ gaugeInfoListDataDay, gaugeInfoListDataMonth }) => {
    const classes = useStyle();
    return (
        <div className={classes.container}>
            <DataGrid
                style={{ marginBottom: 8 }}
                title='일반유 계기외 현황'
                dataSet={gaugeInfoListDataDay}

            >
                <DataColumn fieldName='BEF_GAUGE_ELE' title='전기계기' />
                <DataColumn fieldName='CUR_GAUGE_ELE' title='당기계기' />
                <DataColumn fieldName='GAUGE_ELE' title='회전량' />
                <DataColumn fieldName='NOT_QTY' title='공회전' />
                <DataColumn fieldName='ETC_QTY' title='기타' />
                <DataColumn fieldName='SVC_QTY' title='서비스' position='ETC_QTY' />
                <DataColumn fieldName='SALE_QTY' title='판매량' />
            </DataGrid>

            <DataGrid
                title='월별 일반유 계기외 현황'
                dataSet={gaugeInfoListDataMonth}
            >
                <DataColumn fieldName='BEF_GAUGE_ELE' title='전기계기' />
                <DataColumn fieldName='CUR_GAUGE_ELE' title='당기계기' />
                <DataColumn fieldName='GAUGE_ELE' title='회전량' />
                <DataColumn fieldName='NOT_QTY' title='공회전' />
                <DataColumn fieldName='ETC_QTY' title='기타' />
                <DataColumn fieldName='SVC_QTY' title='서비스' position='ETC_QTY' />
                <DataColumn fieldName='SALE_QTY' title='판매량' />
            </DataGrid>
        </div>
    )
}

const SaleBondList = ({ saleBondList, condition }) => (
    <DataGrid
        title='매출 시재 현황'
        dataSet={saleBondList}
        expandable={(row) => (
            row['EXPAND_TYPE'] === 0 ? null :
            <SmallGrid
                selectProc='SLT_SALE_BOND_LIST_DETAIL'
                selectParam={{ ...row, ...condition }}
            >
                <DataColumn fieldName='GDS_TYPE_CD' visible={false} />
                <DataColumn fieldName='GDS_TYPE_NM' title='구분' />
                <DataColumn fieldName='BEF_AMOUNT' title='전기잔액' />
                <DataColumn fieldName='SALE_AMOUNT' title='당기발생' />
                <DataColumn fieldName='IN_AMOUNT' title='당기입금' />
                <DataColumn fieldName='CUR_AMOUNT' title='당기잔액' />
            </SmallGrid>
        )}
        defaultExpand
        
    >
        <DataColumn fieldName='PAY_CD' visible={false} />
        <DataColumn fieldName='PAY_NM' title='구분' />
        <DataColumn fieldName='BEF_AMOUNT' title='전기잔액' />
        <DataColumn fieldName='SALE_AMOUNT' title='당기발생' />
        <DataColumn fieldName='IN_AMOUNT' title='당기입금' />
        <DataColumn fieldName='CUR_AMOUNT' title='당기잔액' />
        <DataColumn fieldName='EXPAND_TYPE' title='확장구분' visible={false} />
    </DataGrid>
)

const SaleCstViolation = ({ saleCstViolationData }) => (
    <DataGrid
        title='위배 거래처 현황'
        dataSet={saleCstViolationData}
        horizonMode
    >
        <DataColumn fieldName='OVER_DATE' title='위배일수' />
        <DataColumn fieldName='OVER_AMOUNT' title='위배금액' />
    </DataGrid>
)

const Etc = ({etcData}) => (
    <DataGrid
        title='기타'
        dataSet={etcData}
        horizonMode
    >
        <DataColumn fieldName='REMARK' title='내용' />
        <DataColumn fieldName='REMARK_VAL' title='값' />
    </DataGrid>
)

export default OilbankSummary