import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DatePicker, DataColumn, ComboSearch, IconButton, GridHeaderBand, BandItem, ProgressDialog } from '../../component';
import { makeStyles } from '@material-ui/core';
import IsoIcon from '@material-ui/icons/Iso';
import { callProc } from '../../common/DBConnector';
import { useSnackbar } from 'notistack';
import { isFieldDiff } from '../../common/Utils';

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    }
}))

const InboundReCalc = () => {
    const [condition, setCondition] = useState(null);
    const [reCalcData, setReCalcData] = useState();
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const fetchData = async() => {
            setReCalcData(null);

            const reCalcData = await callProc('SLT_INPRICE_RECALC', condition);
            
            setReCalcData(reCalcData);
        }
        condition && fetchData();
    }, [condition]) 

    const hasValue = value => {
        return value != null && value.toString().trim() !== '';
    }

    const valid = () => {
        if(reCalcData.data.reduce((result, item) => result || !hasValue(item['ENT_PRICE']), false)){
            enqueueSnackbar('매입 단가가 등록되어 있지 않은 상품이 존재합니다.', {variant: 'error'});
            return false;
        }

        return true;
    }

    const onInsert = async() => {
        setLoading(true);
        if(valid()){
            const result = await callProc('UPT_INPRICE_RECALC', condition);

            if(!result.err){
                enqueueSnackbar('저장이 완료되었습니다.');
                setCondition({...condition});
            }
        }
        setLoading(false);
    }

    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => {
                sessionStorage['WORK_CD'] = condition['WORK_CD'];
                setCondition(condition);
                }}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => {
                                dispatch(value);
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(value, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={value}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(value, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={value}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='GDS_CD'
                    component={(value, setState, condition, dispatch) => {
                        let param = condition['GDS_PARAM'] || null;
                        if (isFieldDiff(['WORK_CD', 'STA_DATE'], condition, param)) {
                            param = { 
                                WORK_CD: condition['WORK_CD'],
                                GDS_TYPE: 'ALL',
                                NOT_TYPE: 0,
                                GDS_TYPE_M: 'ALL',
                                NOT_TYPE_M: 0,
                                GAUGE_TYPE: 'ALL',
                                STA_DATE: condition['STA_DATE'],
                            };
                            dispatch('GDS_PARAM', param);
                        }
                        return (
                            <ComboSearch
                                label='상품명'
                                selectProc='SLT_GAUGE_ITEM_GOODS_COMBO'
                                selectParam={param}
                                keyField='GDS_CD'
                                valueField='GDS_NM'
                                value={value}
                                onChange={value => setState(value)}
                                onDataLoad={(jsonData, rawData) => [{ GDS_CD: 'ALL', GDS_NM: '전체' }, ...rawData]}
                            />
                        )
                    }}
                />
                <SearchItem
                    fieldName='CST_CD'
                    component={(value, setState, condition, dispatch) => {
                        let param = condition['CST_PARAM'] || null;
                        if (isFieldDiff(['WORK_CD', 'STA_DATE'], condition, param)) {
                            param = {
                                WORK_CD: condition['WORK_CD'],
                                CST_TYPE: [0, 2],
                                CST_KIND: [1, 2],
                                STA_DATE: condition['STA_DATE'],
                            };
                            dispatch('CST_PARAM', param);
                        }
                        return (
                            <ComboSearch
                                label='거래처명'
                                selectProc='SLT_CUSTOMER_COMBO'
                                selectParam={param}
                                keyField='CST_CD'
                                valueField='CST_NM'
                                value={value}
                                onChange={(value) => setState(value)}
                                onDataLoad={(jsonData, rawData) => [{ CST_CD: 'ALL', CST_NM: '전체'}, ...rawData ]}
                            />
                        )
                    }}
                />
            </SearchPanel>
                <DataGrid
                    title='매입 가격 재계산'
                    dataSet={reCalcData}
                    headerItem={() => (
                        <IconButton
                            tooltip='재계산'
                            icon={<IsoIcon />}
                            onClick={onInsert}
                        />
                    )}
                    horizonMode
                    selectionMode='single'
                    virtualized
                >
                    <GridHeaderBand>
                        <BandItem
                            span={4}
                        >
                            정보
                        </BandItem>
                        <BandItem
                            span={3}
                        >
                            등록된 내용
                        </BandItem>
                        <BandItem
                            span={3}
                        >
                            변경될 내용
                        </BandItem>
                        <BandItem
                            span={2}
                        >
                            차이
                        </BandItem>
                        <BandItem
                            span={3}
                        >
                            정보2
                        </BandItem>
                    </GridHeaderBand>
                    <DataColumn fieldName='GDS_NM' total={(values) => '합계'} />
                    <DataColumn fieldName='CST_NM' title='매입처' />
                    <DataColumn fieldName='QTY' title='정산 수량' total={(values) => values.reduce((result, value) => result + value, 0)} />
                    <DataColumn fieldName='ENT_PRICE' title='적용된 단가'  />
                    <DataColumn fieldName='ENT_TRANS_PRICE' title='수송단가' />
                    <DataColumn fieldName='ENT_AMT' title='적용된 합계금액' total={(values) => values.reduce((result, value) => result + value, 0)} />
                    <DataColumn fieldName='CHG_PRICE' title='변경될 단가' />
                    <DataColumn fieldName='CHG_TRANS_PRICE' title='수송단가' />
                    <DataColumn fieldName='CHG_AMT' title='변경될 합계금액' total={(values) => values.reduce((result, value) => result + value, 0)} />
                    <DataColumn fieldName='GAP_PRICE' title='단가 차이' />
                    <DataColumn fieldName='GAP_AMT' title='합계금액 차이' />
                    <DataColumn fieldName='SHIP_TRN_NM' title='출하처' />
                    <DataColumn fieldName='DEST_TRN_NM' title='인도처' />
                    <DataColumn fieldName='CALC_CD' title='정산 코드' />
                    <DataColumn fieldName='BUY_CST_CD' visible={false} />
                    <DataColumn fieldName='GDS_CD' visible={false} />
                </DataGrid>
                <ProgressDialog open={loading} />
        </div>
    )
}
export default InboundReCalc;