import React, { useState, useEffect, useMemo } from 'react';
import { DataGrid, ComboBox, PaperTitle, DatePicker, DataColumn, NumberField, ProgressDialog, IconButton } from '../../component';
import { callProc } from '../../common/DBConnector';
import { makeStyles, Paper, TextField } from '@material-ui/core';
import { useFieldInputs } from '../../common/Utils';
import CheckIcon from '@material-ui/icons/Check';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import CachedIcon from '@material-ui/icons/Cached';
import CloseIcon from '@material-ui/icons/Close';
import { Fragment } from 'react';

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
    info: {
        flex: 1,
        marginRight: theme.spacing(1),
    },
    input: {
        width: 300,
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(1),
    },
    enroll: {
        flex: 1,
        marginRight: theme.spacing(1),
    },
    paperContent: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        overflowY: 'auto',
        '& > *': {
            marginBottom: theme.spacing(2),
        },
    },
    field: {
        width: 180,
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2),
    },
    iconbutton : {
        alignSelf: 'flex-end',
        marginTop: theme.spacing(4),
        marginRight: theme.spacing(1),
    },
    searchPaper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    searchContainer: {
        flex: 1,
        display: 'inline-block',
        flexDirection: 'row',
    },
    searchItem: {
        display: 'inline-block',
        width: 200,
        marginRight: 100,
        marginBottom: theme.spacing(1),
    },
}))

const GaugeEnroll = () => {
    const classes = useStyles();
    const clsState = useSelector(state => state.clsDateReducer);
    const [input, setInput] = useFieldInputs({
        WORK_CD: sessionStorage['WORK_CD'],
        BIZ_DATE: clsState[sessionStorage['WORK_CD']],
    });
    const [editRow, setEditRow] = useState();
    const [loading, setLoading] = useState(false);
    
    const { enqueueSnackbar } = useSnackbar();

    const onUpdateStart = row => {
        setEditRow({...row});
        return false;
    }

    const condition = useMemo(() => input['WORK_CD'] && input['BIZ_DATE'] && input, [input]);

    const gridRefresh = () => {
        setInput({ type: 'UPDATE', value: { ...input } });
    }

    const onRefreshData = async() => {
        setLoading(true);

        const result = await callProc('UPT_GAUGE_REFRESH', condition);

        if(!result.err) {
            enqueueSnackbar('저장이 완료되었습니다.');
            gridRefresh();
        }
        setLoading(false);
    }

    const onDataInit = async() => {
        setLoading(true);

        const result = await callProc('DLT_GAUGEINFO_DATAINIT', condition);

        if(!result.err) {
            enqueueSnackbar('저장이 완료되었습니다.');
            gridRefresh();
        }   
        setLoading(false);
    }

    return(
        <div className={classes.container}>
            <ProgressDialog open={loading} />
            <Paper className={classes.searchPaper}>
                <div className={classes.searchContainer}>
                    <div className={classes.searchItem}>
                        <ComboBox
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={condition && condition['WORK_CD']}
                            onChange={value => {
                                if (input['WORK_CD'] !== value) {
                                    sessionStorage['WORK_CD'] = value;
                                    setInput('BIZ_DATE', clsState[value]);
                                    setInput('WORK_CD', value);
                                }
                            }}
                        />
                    </div>
                    <div className={classes.searchItem}>
                        <DatePicker
                            label='영업일'
                            minDate={clsState[input['WORK_CD']]}
                            value={condition && condition['BIZ_DATE']}
                            onChange={value => {
                                if (input['BIZ_DATE'] !== value) {
                                    setInput('BIZ_DATE', value);
                                    if(condition && condition['BIZ_DATE'] < clsState[condition['WORK_CD']]) {
                                        setInput({ type: 'UPDATE', value: { ...input, 'BIZ_DATE': value } });
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                <IconButton
                    tooltip=''
                    icon={<SearchIcon />}
                    onClick={gridRefresh}
                />
            </Paper>
            <div className={classes.content}>
                <div className={classes.info}>
                    <DataGrid
                        title='계기'
                        updateProc=''
                        selectProc='SLT_GAUGE_ENROLL'
                        selectParam={condition}
                        selectionMode='single'
                        onUpdateStart={onUpdateStart}
                        onLoad={() => setEditRow()}
                        headerItem={() => (
                            <Fragment>
                                <IconButton
                                    tooltip='이월값 불러오기'
                                    icon={<RefreshIcon />}
                                    onClick={onRefreshData}
                                    disabled
                                />
                                <IconButton
                                    tooltip='데이터 초기화'
                                    icon={<DeleteIcon />}
                                    onClick={onDataInit}
                                />
                            </Fragment>
                        )}
                    >
                        <DataColumn fieldName='GAUGE_CD' visible={false} />
                        <DataColumn fieldName='NZL_NO' title='노즐번호' />
                        <DataColumn fieldName='BEF_GAUGE_ELE' title='시작계기' />
                        <DataColumn fieldName='CUR_GAUGE_ELE' title='마감계기' />
                        <DataColumn fieldName='GAUGE_ELE' title='계기차' />
                        <DataColumn fieldName='OUT_QTY' title='출하량' />
                        <DataColumn fieldName='EMPTY_QTY' title='공회전' />
                        <DataColumn fieldName='MOVE_QTY' title='이관' />
                        <DataColumn fieldName='HOME_QTY' title='홈로리이관' />
                        <DataColumn fieldName='TEST_QTY' title='검량' />
                        <DataColumn fieldName='SELF_QTY' title='자가소비' />
                        <DataColumn fieldName='KEEP_OUT_QTY' title='보관출고' />
                        <DataColumn fieldName='SERVICE_QTY' title='서비스' />
                        <DataColumn fieldName='ETC_QTY' title='기타' />
                        <DataColumn fieldName='GDS_TYPE' visible={false} />
                        <DataColumn fieldName='TMP_TNK' visible={false} />
                        <DataColumn fieldName='EDT_TYPE' visible={false} />
                    </DataGrid>
                </div>
                <div className={classes.input}>
                    <EnrollPanel
                        condition={condition}
                        editRow={editRow}
                        initEditRow={() => setEditRow()}
                        gridRefresh={gridRefresh}
                        clsState={clsState}
                    />
                </div>
            </div>
        </div>

    )
}

const usePanelStyles = makeStyles((theme) => ({
    panelContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: 0,
    },
    panelContent: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        overflowY: 'auto',
        '& > *': {
            marginBottom: theme.spacing(2),
        },
    },
    btnContainer: {
        alignSelf: 'flex-end',
        padding: theme.spacing(1),
    },
}))

const EnrollPanel = ({ condition, editRow, gridRefresh, clsState, initEditRow }) => {
    const classes = usePanelStyles();
    const [befValue, setBefValue] = useState();
    const { enqueueSnackbar } = useSnackbar();
    const [editState, setEditState] = useState(0);

    const isValid = () => {
        return true;
    }

    const [insData, setInsData] = useFieldInputs({
        NZL_NO: '',
        WORK_CD: condition && condition['WORK_CD'],
        BIZ_DATE: condition && condition['BIZ_DATE']
    });

    useEffect(() => {
        if (!editRow) {
            setEditState(0);
            setInsData({ type: 'INIT' });
        } else {
            setInsData({ type: 'UPDATE', value: editRow })
            setEditState(1);
        }
    }, [editRow, setInsData])

    useEffect(() => {
        if (condition) {
            setInsData({ type: 'INIT' });
            setEditState(0);
            setInsData('WORK_CD', condition['WORK_CD']);
            setInsData('BIZ_DATE', condition['BIZ_DATE']);
        }
    }, [condition, setInsData])

    const onInsert = async() => {
        if(isValid()){
            let befData = {...insData};
            delete befData.GAUGE_CD;
            delete befData.NZL_NO;
            delete befData.WORK_CD;
            delete befData.BIZ_DATE;
            delete befData.BEF_GAUGE_ELE;
            delete befData.CUR_GAUGE_ELE;
            delete befData.GAUGE_ELE;
            setBefValue(befData);
            let procName;
            if(String(editState) === '0'){
                procName = ''
            } else {
                procName = 'INS_GAUGE_ENROLL'
            }
            const result = await callProc(procName, insData);
            if(!result.err) {
                enqueueSnackbar('저장이 완료되었습니다.');
                setInsData({ type: 'INIT' });
                setEditState(0);
                gridRefresh();
            }
        }
    }

    const onExitEdit = () => {
        initEditRow();
    }

    const getBefValue = () => {
        befValue && setInsData({ type: 'UPDATE', value: {...befValue} })
    }

    return(
        <Paper className={classes.panelContainer}>
            <PaperTitle>{String(editState) === '0' ? '추가' : '수정'}</PaperTitle>
            <div className={classes.panelContent}>
            {String(editState) === '1' &&
                <TextField
                    label='계기코드'
                    value={insData['GAUGE_CD']}
                    disabled
                />}
                <ComboBox
                    label='사업장'
                    selectProc='SLT_WORKPLACEINFO_AUTH'
                    keyField='WORK_CD'
                    valueField='WORK_NM'
                    value={insData['WORK_CD']}
                    disabled
                />
                <DatePicker
                    label='영업일'
                    minDate={clsState[insData['WORK_CD']]}
                    value={insData['BIZ_DATE']}
                    disabled
                />
                <TextField
                    className={classes.field}
                    label='노즐번호'
                    value={insData['NZL_NO']}
                    onChange={value => setInsData('NZL_NO', value)}
                    disabled
                />
                <NumberField
                    className={classes.field}
                    label='시작계기'
                    value={insData['BEF_GAUGE_ELE']}
                    onChange={value => setInsData('BEF_GAUGE_ELE', value)}
                    disabled
                />
                <NumberField
                    className={classes.field}
                    label='마감계기'
                    value={insData['CUR_GAUGE_ELE']}
                    onChange={value => {
                        setInsData('GAUGE_ELE', value - insData['BEF_GAUGE_ELE']);
                        setInsData('CUR_GAUGE_ELE', value);
                    }}
                    disabled={String(editState) === '0' || String(insData['EDT_TYPE']) === '0'}
                />
                <NumberField
                    className={classes.field}
                    label='계기차'
                    value={insData && insData['GAUGE_ELE']}
                    onChange={value => setInsData('GAUGE_ELE', value)}
                    disabled
                />
                <NumberField
                    className={classes.field}
                    label='공회전'
                    value={insData['EMPTY_QTY']}
                    onChange={value => {
                        setInsData('EMPTY_QTY', value);
                    }}
                    disabled={String(editState) === '0' || String(insData['EDT_TYPE']) === '0'}
                />
                <NumberField
                    className={classes.field}
                    label='이관'
                    value={insData && insData['MOVE_QTY']}
                    onChange={value => {
                        setInsData('MOVE_QTY', value);
                    }}
                    disabled={String(editState) === '0' || String(insData['EDT_TYPE']) === '0'}
                />
                <NumberField
                    className={classes.field}
                    label='홈로리이관'
                    value={insData && insData['HOME_QTY']}
                    onChange={value => {
                        setInsData('HOME_QTY', value);
                    }}
                    disabled={String(editState) === '0' || String(insData['EDT_TYPE']) === '0'}
                />
                <NumberField
                    className={classes.field}
                    label='검량'
                    value={insData && insData['TEST_QTY']}
                    onChange={value => {
                        setInsData('TEST_QTY', value);
                    }}
                    disabled={String(editState) === '0' || String(insData['EDT_TYPE']) === '0'}
                />
                <NumberField
                    className={classes.field}
                    label='자가소비'
                    value={insData && insData['SELF_QTY']}
                    onChange={value => {
                        setInsData('SELF_QTY', value);
                    }}
                    disabled={String(editState) === '0' || String(insData['EDT_TYPE']) === '0'}
                />
                <NumberField
                    className={classes.field}
                    label='보관출고'
                    value={insData && insData['KEEP_OUT_QTY']}
                    onChange={value => {
                        setInsData('KEEP_OUT_QTY', value);
                    }}
                    disabled={String(editState) === '0' || String(insData['EDT_TYPE']) === '0'}
                />
                <NumberField
                    className={classes.field}
                    label='서비스'
                    value={insData && insData['SERVICE_QTY']}
                    onChange={value => {
                        setInsData('SERVICE_QTY', value);
                    }}
                    disabled={String(editState) === '0'|| String(insData['EDT_TYPE']) === '0'}
                />
                <NumberField
                    className={classes.field}
                    label='기타'
                    value={insData && insData['ETC_QTY']}
                    onChange={value => {
                        setInsData('ETC_QTY', value);
                    }}
                    disabled={String(editState) === '0' || String(insData['EDT_TYPE']) === '0'}
                />
            </div>
            <div className={classes.btnContainer}>
                {String(editState) === '1' &&
                <IconButton
                    tooltip='취소'
                    icon={<CloseIcon />}
                    onClick={() => onExitEdit()}
                />}
                <IconButton
                    tooltip='이전값 불러오기'
                    icon={<CachedIcon />}
                    onClick={() => getBefValue()}
                    disabled={String(editState) === '0' || String(insData['EDT_TYPE']) === '0'}
                />
                <IconButton
                    tooltip='확인'
                    icon={<CheckIcon />}
                    onClick={() => onInsert()}
                    disabled={String(editState) === '0' || String(insData['EDT_TYPE']) === '0'}
                />
            </div>
        </Paper>
    )
}

export default GaugeEnroll