import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { DataGrid, DataColumn, FilePicker, BandItem, GridHeaderBand, IconButton, EditableList, EditableField, TextField, NumberField, DatePicker} from '../../component';
import { Dialog, DialogTitle, makeStyles } from '@material-ui/core';
import { callProc } from '../../common/DBConnector';
import { useFieldInputs, xlsToAoaAsync } from '../../common/Utils';
import RefreshIcon from '@material-ui/icons/Refresh';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
}))

const InMoneyCheck = () => {
    const [cardsData, setCardsData] = useState(null);
    const classes = useStyles();
    const [EdtOpen, setEdtOpen] = useState(false);

    const fileSelected = async(file) => {
        setCardsData(null);
        const sheet = (await xlsToAoaAsync(file))['카드사별정산확인'];
        sheet.splice(0, 2);
        const cardsData = await callProc('SLT_INMONEY_CARD_CHECK', { JSON: JSON.stringify(sheet) });

        setCardsData(cardsData);
    }

    const onUpdate = (dataSet) => {
        setCardsData(dataSet);
    }

    useEffect(() => {

        setCardsData({
            data: [],
            fields: ['WORK_CD', 'WORK_NM', 'CARD_CD', 'CARD_NM', 'AMOUNT', 'DIFF_AMT', 'PLAN_VAT', 'PLAN_PRICE', 'PLAN_AMOUNT', 'STA_DATE', 'END_DATE']
        })
    }, [])

    const refreshGrid = () => setCardsData({
        data: [],
        fields: ['WORK_CD', 'WORK_NM', 'CARD_CD', 'CARD_NM', 'AMOUNT', 'DIFF_AMT', 'PLAN_VAT', 'PLAN_PRICE', 'PLAN_AMOUNT', 'STA_DATE', 'END_DATE']
    })

    return(
        <div className={classes.container}>
            <div className={classes.content}>
                <DataGrid
                    title='카드사별 매출집계'
                    dataSet={cardsData}
                    totalGroup={['WORK_NM']}
                    horizonMode
                    headerItem={() => (
                        <>
                            <FilePicker onChange={fileSelected} />
                            <IconButton
                                tooltip='저장'
                                icon={<CheckIcon />}
                                onClick={() => setEdtOpen(true)}
                            />
                            <IconButton
                                tooltip='새로고침'
                                icon={<RefreshIcon />}
                                onClick={() => refreshGrid()}
                            />
                        </>
                    )}
                > 
                    <GridHeaderBand>
                        <BandItem
                            span={3}
                        >
                            정보
                        </BandItem>
                        <BandItem
                            span={2}
                        >
                            매출
                        </BandItem>
                        <BandItem
                            span={5}
                        >
                            입금
                        </BandItem>
                    </GridHeaderBand>
                    <DataColumn fieldName='WORK_CD' visible={false} />
                    <DataColumn fieldName='WORK_NM' total={(values, data, group) => group ? group['WORK_NM'] + ' 소계' : '총계'} />
                    <DataColumn fieldName='AMOUNT' title='합계' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                    <DataColumn fieldName='DIFF_AMT' title='차액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                    <DataColumn fieldName='PLAN_VAT' title='입금예정 수수료' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                    <DataColumn fieldName='PLAN_PRICE' title='입금예정 금액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                    <DataColumn fieldName='PLAN_AMOUNT' title='입금예정 합계' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                  
                </DataGrid>
            </div>
            <EdtDialog
                open={EdtOpen}
                close={() => setEdtOpen(false)}
                cardsData={cardsData}
                onUpdate={onUpdate}
            />
        </div>
    )
}

const useDialogStyles = makeStyles(theme => ({
    dialogPaper: {
        maxWidth: 'none',
        width: 1500,
        '& > *': {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    okButton: {
        display: 'flex',
    },
    fieldContainer: {
        overflowY: 'auto',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
            marginBottom: theme.spacing(2),
        },
    },
    btnContainer: {
        alignSelf: 'flex-end',
        padding: theme.spacing(1),
    },
    content: {
        display: 'flex',
        flex: 3,
        height: 0,
        flexDirection: 'column'
    },
    inmoneycheck: {
        display: 'flex',
        flexDirection: 'column',
        flex: 2,
        marginBottom: theme.spacing(1),
        height: 0,
    },
}))

const EdtDialog = ({ open, close, cardsData, onUpdate }) => {
    const classes = useDialogStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [insData, setInsData] = useFieldInputs({
        INM_DATE: moment().add(-1, 'day').format('YYYY-MM-DD'),
        REG_TYPE: 6,
    });
    const [dataSet, setDataSet] = useState();

    useEffect(() => {
        if (!cardsData) return;

        const data = cardsData.data.filter(item => item['AMOUNT'] !== item['PLAN_AMOUNT']);
        setDataSet({
            ...cardsData,
            data: data.map(item => ({ ...item })),
        });
    }, [cardsData])

    const valid = () => {
        if(cardsData.data.length === 0){
            enqueueSnackbar('생성된 행이 없습니다. 확인해주세요.', {variant: 'error'});
            return false;
        }
        
        return true;
    }

    const handleUpdate = async(data) => {
        if(valid()){
            const mergedData = {
                ...cardsData,
                data: cardsData.data.map(item => ({ ...item })),
            }
            data.forEach(editedItem => {
                const index = mergedData.data.findIndex(item => item['WORK_CD'] === editedItem['WORK_CD'] && item['CARD_CD'] === editedItem['CARD_CD']);
                mergedData.data[index] = editedItem;
            })
            const result = await callProc('INS_INM_CHECK', {
                INM_DATE : insData['INM_DATE'],
                JSON: mergedData.data,
            })
            if (!result.err) {
                console.log(result);
                enqueueSnackbar('저장이 완료되었습니다.');
                onUpdate(mergedData);
                close();
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    return (
        <Dialog
            open={open}
            PaperProps={{ className: classes.dialogPaper }}
        >
            <div className={classes.dialogTitle}>
                <DialogTitle></DialogTitle>
                <IconButton
                    tooltip='닫기'
                    onClick={close}
                    icon={<CloseIcon />}
                />
            </div>
            <div className={classes.content}>
                <EditableList
                    className={classes.inmoneycheck}
                    title='입금내역 검증'
                    dataSet={dataSet}
                    bottomSticky={() => {
                        return (
                            <div>
                                <DatePicker
                                    label='입금일자'
                                    value={insData['INM_DATE']}
                                    onChange={(value) => setInsData('INM_DATE' ,value)}
                                />
                            </div>
                        )
                    }}
                    onUpdate={handleUpdate}
                    fixedRowCount
                >
                    <EditableField
                        fieldName='WORK_NM'
                        component={({value, setState}) => (
                            <TextField
                                label='사업장명'
                                value={value}
                                onChange={value => setState(value)}
                                disabled
                            />
                        )}
                    />
                    <EditableField
                        fieldName='CARD_CD'
                        component={({value, setState}) => (
                            <TextField
                                label='카드사코드'
                                style={{ width: 90 }}
                                value={value}
                                onChange={value => setState(value)}
                                disabled
                            />
                        )}
                    />
                    <EditableField
                        fieldName='CARD_NM'
                        component={({value, setState}) => (
                            <TextField
                                label='카드사명'
                                style={{ width: 100 }}
                                value={value}
                                onChange={value => setState(value)}
                                disabled
                            />
                        )}
                    />
                    <EditableField
                        fieldName='AMOUNT'
                        component={({value, setState}) => (
                            <NumberField
                                label='합계'
                                style={{ width: 100 }}
                                value={value}
                                onChange={value => setState(value)}
                                disabled
                            />
                        )}
                    />
                    <EditableField
                        fieldName='DIFF_AMT'
                        component={({value, setState}) => (
                            <NumberField
                                label='차액'
                                style={{ width: 100 }}
                                value={value}
                                onChange={value => setState(value)}
                                disabled
                            />
                        )}
                    />
                    <EditableField
                        fieldName='PLAN_VAT'
                        component={({value, setState}) => (
                            <NumberField
                                label='입금예정 수수료'
                                style={{ width: 120 }}
                                value={value}
                                onChange={value => setState(parseInt(value))}
                            />
                        )}
                    />
                    <EditableField
                        fieldName='PLAN_PRICE'
                        component={({value, setState}) => (
                            <NumberField
                                label='입금예정 금액'
                                style={{ width: 110 }}
                                value={value}
                                onChange={value => setState(parseInt(value))}
                            />
                        )}
                    />
                     <EditableField
                        fieldName='PLAN_AMOUNT'
                        component={({value, setState}) => (
                            <NumberField
                                label='입금예정 합계'
                                style={{ width: 110 }}
                                value={value}
                                onChange={value => setState(value)}
                                disabled
                            />
                        )}
                    />
                    <EditableField
                        fieldName='STA_DATE'
                        component={({value, setState}) => (
                            <TextField
                                label='시작일'
                                style={{ width: 110 }}
                                value={value}
                                onChange={value => setState(value)}
                                disabled
                            />
                        )}
                    />
                    <EditableField
                        fieldName='END_DATE'
                        component={({value, setState}) => (
                            <TextField
                                label='종료일'
                                style={{ width: 110 }}
                                value={value}
                                onChange={value => setState(value)}
                                disabled
                            />
                        )}
                    />
                    <EditableField fieldName='WORK_CD' visible={false}/>
                </EditableList>
            </div>
        </Dialog>
    )
}
export default InMoneyCheck