import React, { useState, useMemo } from 'react';
import moment from 'moment';
import { DataGrid, DataColumn, SearchItem, SearchPanel, ComboBox, DatePicker, ComboSearch, PdfButton } from '../../component';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { InOutBondPayListPdf, InOutBondPayPrintPdf } from '../../reports';
import { Fragment } from 'react';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import { isFieldDiff } from '../../common/Utils';


const useStyle = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    }
}))

const InOutBondPayList = () => {
    const [condition, setCondition] = useState(null);
    const loginState = useSelector(state => state.loginReducer);
    const [workData, setWorkData] = useState();
    const [pdfData, setPdfData] = useState();
    const [pdfData2, setPdfData2] = useState();
    const classes = useStyle();

    const pdfComponent = useMemo(() => (
        <PdfButton title='채무 채권 확인 조회서'>
            {pdfData &&
            <InOutBondPayListPdf
                pdfData={pdfData}
                condition={condition}
                loginState={loginState}
                workName={workData.find(item => item['WORK_CD'] === condition['WORK_CD'])['WORK_NM']}
            />}
        </PdfButton>
    ), [pdfData, condition, loginState, workData]);


    const pdfComponent2 = useMemo(() => {
        return (
        <PdfButton 
            title='매입 매출 채무 및 채권 확인 조회서'
            icon={<PrintOutlinedIcon/>}
        >
            {pdfData2 && pdfData2.length !== 0 &&
            <InOutBondPayPrintPdf
                pdfData2={pdfData2}
                loginState={loginState}
                workName={workData.find(item => item['WORK_CD'] === condition['WORK_CD'])['WORK_NM']}
                condition={condition}
            />}
            
        </PdfButton>)
    }, [pdfData2, loginState, workData, condition]);


    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => {
                sessionStorage['WORK_CD'] = condition['WORK_CD'];
                setCondition(condition);
                }}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, setState, condition, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => {
                                setState(value)
                            }}
                            onDataLoad={(jsonData, rawData) => {
                                setWorkData(rawData);
                                return rawData;
                            }}
                        />
                    )}
                />

                <SearchItem
                    fieldName='BIZ_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='영업일'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                            }}
                        />
                    )}
                />

                <SearchItem
                    fieldName='CST_CD'
                    component={(state, setState, condition, setField) => {
                        let param = condition['CST_PARAM'] || null;
                        if (isFieldDiff(['WORK_CD'], condition, param)) {
                            param = { WORK_CD: condition['WORK_CD'] };
                            setField('CST_PARAM', param);
                        }
                        return (
                            <ComboSearch
                                label='거래처명'
                                selectProc='SLT_CUSTOMERINFO_ALL'
                                keyField='CST_CD'
                                valueField='CST_NM'
                                selectParam={param}
                                onDataLoad={(jsonData, rawData) => [{ CST_CD: 'ALL', CST_NM: '전체' }, ...rawData]}
                                value={state}
                                onChange={(value) => setState(value)}
                            />
                        )
                    }}
                />

                

            </SearchPanel>

            <DataGrid
                title='채무/채권 확인 조회서'
                selectProc='SLT_INOUTBONDPAYLIST'
                selectParam={condition}
                selectionMode='single'
                horizonMode
                virtualized
                sortable
                filterable
                stickyHeader
                headerItem={({data, visibleAoa}) => {
                    setPdfData(visibleAoa);
                    setPdfData2(data);
                    return (
                        <Fragment>
                            {pdfComponent}
                            {pdfComponent2}
                        </Fragment>
                    );
                }}
            >

                <DataColumn fieldName='CST_CD' visible={false} />
                <DataColumn fieldName='CEO' title='대표자' />
                <DataColumn fieldName='ACC_PAY' title='외상매입금' />
                <DataColumn fieldName='ACC_PAY_NON' title='미지급금' />
                <DataColumn fieldName='ACC_PAY_ETC' title='기타재무' />
                <DataColumn fieldName='ACC_PAY_SUM' title='통합재무잔액' />
                <DataColumn fieldName='ACC_REC' title='외상매출금' />
                <DataColumn fieldName='ACC_REC_NON' title='미수금' />
                <DataColumn fieldName='ACC_REC_ETC' title='기타채권' />
                <DataColumn fieldName='ACC_REC_SUM' title='통합채권잔액' />
                <DataColumn fieldName='CST_ADDR' visible={false} />
                <DataColumn fieldName='WORK_BIZNO' visible={false} />
                <DataColumn fieldName='WORK_NM' visible={false} />
                <DataColumn fieldName='WORK_ADDR' visible={false} />
                <DataColumn fieldName='WORK_TELNO1' visible={false} />
                <DataColumn fieldName='ACC_INFO' visible={false} />

            </DataGrid>

        </div>
    )
}



export default InOutBondPayList;