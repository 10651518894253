import React from 'react';
import { YNType } from '../../common/Dictionary';
import { ComboBox, DataColumn, DataGrid, DatePicker } from '../../component';

const EmpInfo = () => {
    return(
            <DataGrid
                title='인사정보'
                table='TB_EMPINFO'
                selectProc='SLT_EMPINFO'
                insertProc='INS_EMPINFO'
                updateProc='UPT_EMPINFO'
                deleteProc='DLT_EMPINFO'
                refreshable
            >

                <DataColumn
                    fieldName='EDOC_TYPE'
                    value={(row, value) => YNType[value]}
                    defaultValue={Object.keys(YNType)[0]}
                    editable={(state, dispatch) => (
                        <ComboBox
                            data={YNType}
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                />
                <DataColumn
                    fieldName='LEAVE_DATE'
                    defaultValue={null}
                    editable={(state, dispatch) => (
                        <DatePicker
                            value={state}
                            onChange={value => dispatch(value)}
                            nullable
                        />
                    )}
                />
            </DataGrid>
    )
}

export default EmpInfo