import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DatePicker, DataColumn, PdfButton } from '../../component';
import { makeStyles } from '@material-ui/core';
import { orderType, trnState } from '../../common/Dictionary';
import { OrderListPdf } from '../../reports';
import { callProc } from '../../common/DBConnector';
import { useSelector } from 'react-redux';
import { totalCount } from '../../common/Utils';

const useStyle = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    total: {
        backgroundColor: '#a3a9d9 !important'
    }
}))

const OrderListB = () => {
    const classes = useStyle();
    const [condition, setCondition] = useState(null);
    const [pdfData, setPdfData] = useState();
    const loginState = useSelector(state => state.loginReducer);
    const [workData, setWorkData] = useState();

    const getCellProps = (row, fieldName) => {
        if(String(row['COLOR_TYPE']) === '2')
            return { className: classes.total }
    };

    useEffect(() => {
        const fetchData = async () => {
            const workData = await callProc('SLT_WORKPLACEINFO_NOTMAIN_AUTH');

            setWorkData(workData.data.reduce((result, item) => ({
                ...result,
                [item['WORK_CD']]: item['WORK_NM']
            }), {}))

        }
        fetchData();
    }, [])



    const condWorkName = workData && condition && workData[condition['WORK_CD']];
    const pdfComponent = useMemo(() => (
        <PdfButton title='오더조회'>
            {pdfData &&
            <OrderListPdf
                pdfData={pdfData}
                condition={condition}
                loginState={loginState}
                workName={condWorkName}
            />}
        </PdfButton>
    ), [pdfData, condition, loginState, condWorkName]);


    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => {
                sessionStorage['WORK_CD'] = condition['WORK_CD'];
                setCondition(condition)}}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
            </SearchPanel>
            <DataGrid
                title='오더 조회'
                selectProc='SLT_ORDERINFO_LIST_B'
                selectParam={condition}
                selectionMode='single'
                totalGroup={['GDS_NM']}
                horizonMode
                virtualized
                filterable
                excelDownload
                headerItem={({visibleAoa}) => {
                    setPdfData(visibleAoa);
                    return pdfComponent;
                }}
                stickyHeader
                cellProps={getCellProps}
            >
                <DataColumn fieldName='GS_TSP_CD' title='인도처 코드' />
                <DataColumn 
                    fieldName='ORD_TYPE'
                    title='유형'
                    value={(row, value) => orderType[value]}
                />
                <DataColumn fieldName='BUY_CST_NM' title='구매처' />
                <DataColumn 
                    fieldName='SALE_CST_NM' 
                    title='판매처' 
                />
                <DataColumn fieldName='SALE_PRICE' title='판매단가' />
                <DataColumn fieldName='QTY' title='수량' />
                <DataColumn fieldName='CALC_QTY' title='정산수량' />
                <DataColumn fieldName='SALE_SPLY_AMT' title='판매 공급가액' />
                <DataColumn fieldName='SALE_VAT_AMT' title='판매 부가세' />
                <DataColumn fieldName='SALE_AMT' title='판매 금액' />
                <DataColumn fieldName='SHIP_TRN_NM' title='출하처' />
                <DataColumn fieldName='DEST_TRN_NM' title='인도처' />
                <DataColumn fieldName='IN_PRICE' title='매입 단가' />
                <DataColumn fieldName='IN_SPLY_AMT' title='구매 공급가액' />
                <DataColumn fieldName='IN_VAT_AMT' title='구매 부가세' />
                <DataColumn fieldName='IN_AMT' title='구매 금액' />
                <DataColumn fieldName='ENT_PRICE' title='매입 수송단가' />
                <DataColumn fieldName='ENT_AMOUNT' title='매입 수송비' />
                <DataColumn fieldName='TSP_CHK' title='수송구분'  value={(row, value) => trnState[value]}  />
                <DataColumn fieldName='EST_PRICE' title='추정정산단가' />
                <DataColumn 
                    fieldName='GDS_NM' 
                    title='상품명' 
                    total={(values, data, group) => 
                        group == null ? `- 합 계 ${totalCount(values)} 건 -` : `(소 계 ${totalCount(values)} 건) ${data[0]['GDS_NM']}`} 
                />
                <DataColumn fieldName='QTY' title='수량' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='CALC_QTY' title='정산수량' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='SALE_SPLY_AMT' title='판매 공급가액' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='SALE_VAT_AMT' title='판매 부가세' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='SALE_AMT' title='판매 금액' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='IN_SPLY_AMT' title='구매 공급가액' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='IN_VAT_AMT' title='구매 부가세' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='IN_AMT' title='구매 금액' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='ENT_AMOUNT' title='매입 수송비' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='EST_PRICE' title='추정정산단가' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='WORK_CD' visible={false} />
                <DataColumn fieldName='BUY_CST_CD' visible={false} />
                <DataColumn fieldName='REAL_DEST_CD' visible={false} />
                <DataColumn fieldName='GDS_CD' visible={false} />
                <DataColumn fieldName='SHIP_TRN_CD' visible={false} />
                <DataColumn fieldName='DEST_TRN_CD' visible={false} />
                <DataColumn fieldName='SEQ' visible={false} />
                <DataColumn fieldName='SEQ_TYPE' visible={false} />
                <DataColumn fieldName='SUM_TYPE' visible={false} />
                <DataColumn fieldName='COLOR_TYPE' visible={false} />
            </DataGrid>
        </div>
    )
}

export default OrderListB;
