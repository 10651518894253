import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { makeStyles, Paper, Button } from '@material-ui/core';
import { callProc } from '../../common/DBConnector';
import { SearchPanel, SearchItem, ComboBox, DatePicker, PaperTitle, ProgressDialog, DataGrid, DataColumn, IconButton, ProgressPanel } from '../../component';
import { useFieldInputs } from '../../common/Utils';
import CheckIcon from '@material-ui/icons/Check';
import { useSnackbar } from 'notistack';

const clsType = {
    1: '해제',
    2: '등록'
}

const useStyle = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },

    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },

    info: {
        flex: 1,
        marginLeft: theme.spacing(1),
    },

    input: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginRight: theme.spacing(1),
    },

    paperContent: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding: theme.spacing(2),
        paddingTop: 0
    },

    componetField: {
        width: 230,
        marginTop: theme.spacing(2)
    },

    closeProgressPanel: {
        paddingTop: 10,
        paddingBottom: 10
    }
}))

const AllClose = () => {
    const classes = useStyle();
    const [condition, setCondition] = useState(null);
    const [clsDate, setClsDate] = useState();
    const [insData, setInsData] = useFieldInputs();
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [confm , setConfm] = useState(false);

    const snack = useRef();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const curClsDate = (await callProc('SLT_WORK_CLS_ALL_DATE', { ...condition, CLS_AUTH: 2 })).data[0]['CLS_DATE'];
            const clsDate = (await callProc('SLT_WORK_CLS_CUR_DATE_ALL', { ...condition, CLS_AUTH: 2 })).data[0]['CLS_DATE'];
            setClsDate(clsDate);
            setInsData('CLS_DATE', clsDate);
            setInsData('CUR_CLS_DATE', curClsDate);
            setInsData('WORK_CD', condition['WORK_CD']);
            setLoading(false);
        }
        condition && fetchData();
    }, [condition, setInsData])

    const onInsert = async() => {
        setConfm(true);
        const result = await callProc('INS_CLOSEDATE_ALL', insData);

        if(!result.err) {
            setCondition({...condition});
            enqueueSnackbar('저장이 완료되었습니다.');
        }
        setConfm(false);
    }

    const reqDec = () => {
        snack.current && closeSnackbar(snack.current);
        snack.current = enqueueSnackbar(`${insData['CLS_DATE']} 마감등록 하시겠습니까?`, {
            autoHideDuration: null,
            variant: 'warning',
            action: key => <>
                <Button onClick={() => {
                    closeSnackbar(key);
                    onInsert();
                }}>
                    예
                </Button>
                <Button onClick={() => closeSnackbar(key)}>
                    아니오
                </Button>
            </>,
        })
    }

    return (
        <div className={classes.container}>
            <ProgressDialog open={loading} />
            <SearchPanel onSearch={condition => {
                sessionStorage['WORK_CD'] = condition['WORK_CD'];
                setCondition(condition);
                }}>
                <SearchItem
                    fieldName='WORK_CD'
                    component={(state, dispatch) => (
                        <ComboBox
                            label='사업장명'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => {
                                dispatch(value);
                                setCondition({...condition, WORK_CD: value})
                            }}
                        />
                    )}
                />
            </SearchPanel>
            <div className={classes.content}>
                <Paper className={classes.input}>
                    <PaperTitle>
                        사업장 마감
                    </PaperTitle>
                    <div className={classes.paperContent}>
                        <ComboBox
                            className={classes.componetField}
                            disabled={true}
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={insData['WORK_CD']}
                            onChange={value => setInsData('WORK_CD', value)}
                        />
                        <DatePicker
                            className={classes.componetField}
                            label='현재 적용마감일'
                            value={insData['CUR_CLS_DATE']}
                            disabled
                        />
                        <DatePicker
                            className={classes.componetField}
                            label='마감일'
                            minDate={clsDate}
                            maxDate={moment(clsDate).endOf('month').format('YYYY-MM-DD')}
                            value={insData['CLS_DATE']}
                            onChange={value => setInsData('CLS_DATE', value)}
                        />
                        <IconButton
                            style={{ marginRight: 700, marginTop: 20, paddingTop: 10, paddingBottom: 10}}
                            tooltip='확인'
                            icon={!insData['CLS_DATE'] || confm ? <ProgressPanel className={classes.closeProgressPanel}/> : <CheckIcon />}
                            onClick={reqDec}
                        />
                    </div>
                </Paper>
                <DataGrid
                        className={classes.info}
                        title='사업장 마감 등록 내역'
                        selectProc='SLT_CLOSEDATE_ALL_LIST'
                        selectParam={condition}
                        horizonMode
                    >
                        <DataColumn fieldName='CLS_DATE' title='등록일(~까지)' />
                        <DataColumn fieldName='CLS_TYPE' title='상태' value={(row, value) => clsType[value]}/>
                        <DataColumn fieldName='REGDATE' title='등록일시' />
                        <DataColumn fieldName='EMP_NM' title='등록자' />

                </DataGrid>
            </div>
        </div>
    )
}

export default AllClose