import React, { useState } from 'react';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DataColumn } from '../../component';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    }
}))

const SendPosList = () => {
    const classes = useStyles();
    const [condition, setCondition] = useState(null);

    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => setCondition(condition)}>
                <SearchItem
                    fieldName='WORK_CD'
                    component={(state, dispatch) => (
                        <ComboBox
                            label='사업장명'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
            </SearchPanel>
            <DataGrid
                title='POS 전송 내역'
                selectProc='SLT_POSTRANS_LIST'
                selectParam={condition}
                horizonMode
                selectionMode='single'
            >
                <DataColumn fieldName='TRANS_TYPE' title='전송 구분' />
                <DataColumn fieldName='STA_DATE' title='POS요청 영업일' />
                <DataColumn fieldName='END_TIME' title='미들웨어 처리시간' />
                <DataColumn fieldName='COMP_DTTM' title='서버 처리시간' />
                <DataColumn fieldName='TRANS_SEQ' title='처리 구분' />
                <DataColumn fieldName='PROCESS_WORKNM' title='처리중인 사업장' />
            </DataGrid>
        </div>
    )
}

export default SendPosList;