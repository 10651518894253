import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core';

const format = 'YYYY-MM-DD HH:mm:ss';

const useStyles = makeStyles(theme => ({
    picker: {
        width: 205,
    },
    selectNull: {
        display: 'flex',
        height: 40,
        justifyContent: 'center',
        color: theme.palette.primary.main,
        cursor: 'pointer',
    },
}))

const DateTimePicker = (props) => {   
    const { className, onChange, value, minDate, maxDate, nullable, ...fieldProps } = props;
    const [inputValue, setInputValue] = useState();
    const tmpValue = useRef();
    const classes = useStyles();

    const isValid = useCallback(date => {
        if (!date) {
            if (nullable) {
                return true;
            } else {
                return false;
            }
        }

        if (typeof(date) === 'string') {
            date = moment(date);
        }
        return date.isValid();
    }, [nullable]);

    const setTmpValue = useCallback(value => {
        if (minDate > value) {
            tmpValue.current = minDate;
        } else if (maxDate < value) {
            tmpValue.current = maxDate;
        } else {
            tmpValue.current = value;
        }
    }, [maxDate, minDate])

    useEffect(() => {
        if (onChange) {
            if (value) {
                onChange(value);
            } else {
                onChange(moment().format(format));
            }
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (isValid(value)) {
            setTmpValue(value);
            setInputValue(tmpValue.current);
        }
    }, [value, maxDate, minDate, isValid, setTmpValue])

    useEffect(() => {
        (inputValue || nullable) && onChange && onChange(tmpValue.current);
        // eslint-disable-next-line
    }, [inputValue]);

    const onEdit = (date, value) => {
        setInputValue(undefined);
        if (isValid(value)) {
            setTmpValue(value);
        }
    }

    const onBlur = () => {
        setInputValue(tmpValue.current);
    }

    const setPopoverProps = popup => {
        if (popup) {
            const toolbar = popup.querySelectorAll('.MuiPickersToolbar-toolbar')[0];
            toolbar.style = 'display: none';

            const initComponent = () => {
                const header = popup.querySelectorAll('.MuiPickersCalendarHeader-transitionContainer')[0];
                const btnYear = popup.querySelectorAll('.MuiPickersToolbarButton-toolbarBtn')[0];

                if (header && btnYear && !header.onclick) {
                    header.onclick = () => btnYear.click();
                    header.style = 'cursor: pointer';
                }
                
                if (nullable && !popup.querySelectorAll(`div[class*=${classes.selectNull}]`)[0]) {
                    const element = document.createElement('div');
                    element.className = classes.selectNull;
                    element.innerText = '선택 안 함';
                    element.onclick = () => {
                        popup.children[0].click();
                        setTmpValue(null);
                        setInputValue(null);
                    }

                    const container = popup.querySelectorAll('.MuiPickersBasePicker-container')[0];
                    container.appendChild(element);
                }
            }
            popup.onmouseover = () => initComponent();
        }
    }

    return (
        <KeyboardDateTimePicker
            className={clsx(classes.picker, className)}
            ampm={false}
            value={value}
            inputValue={inputValue}
            onChange={onEdit}
            onBlur={onBlur}
            onClose={onBlur}
            variant='inline'
            format={format}
            autoOk
            PopoverProps={{ ref: setPopoverProps }}
            minDate={minDate}
            maxDate={maxDate}
            {...fieldProps}
        />
    )
}

export default DateTimePicker;