import React, { useState, useEffect, Fragment } from 'react';
import { makeStyles, ListItem, ListItemIcon, ListItemText, Collapse } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles((theme) => ({
    listItem: {
        paddingLeft: theme.spacing(3),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

const iconStyles = {
    smallIcon: {
        fontSize: 'small',
    },
}

const MenuItem = ({ children, icon, text, to, menuKey, opened, handler, onClick }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(opened);
    useEffect(() => setOpen(opened), [opened])

    const Icon = icon;

    const handleClick = () => {
        onClick && onClick();
        to && handler(menuKey, text, to);
        setOpen(!open);
        opened = !open;
    };

    return (
        <Fragment>
            <ListItem className={classes.listItem} button onClick={handleClick}>
                <ListItemIcon>
                    {(Icon && <Icon/>) ||
                        <FiberManualRecordIcon style={iconStyles.smallIcon} />}
                </ListItemIcon>
                <ListItemText primary={text} />
                {children &&
                    (open ?
                        <ExpandLess /> :
                        <ExpandMore />)}
            </ListItem>
            {children &&
                <Collapse in={open} timeout="auto" unmountOnExit className={classes.nested}>
                    {children}
                </Collapse>
            }
        </Fragment>
    )
}

MenuItem.defaultProps = {
    opened: false,
}

export default MenuItem;