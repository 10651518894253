import React from 'react';
import { Document, StyleSheet, Text, View } from '@react-pdf/renderer';
import { PdfCell, PdfPage, PdfRow, PdfTable } from '../component';
import { numberFormat } from '../common/Utils';
import moment from 'moment';

const borderColor = '#D3D3D3';
const totalColor ='#eff0f1';

const styles = StyleSheet.create({
    titleSort: {
        textAlign: 'center'
    },
    subInfoContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 10,
    },
    tableTitle: {
        border: 0,
        marginTop: 10,
        marginBottom: 4,
        textAlign: 'left'
    },
    header: {
        display: 'flex',
        flex: 'none',
        justifyContent: 'center',
        padding: 3,
        borderColor: borderColor
    },
    cellContentLeft: {
        fontSize: 9,
        display: 'flex',
        flex: 'none',
        justifyContent: 'center',
        textAlign: 'left',
        padding: 3,
        borderColor: borderColor
    },
    cellContentRight: {
        fontSize: 9,
        display: 'flex',
        flex: 'none',
        justifyContent: 'center',
        textAlign: 'right',
        padding: 3,
        borderColor: borderColor
    },
    cellContentCenter: {
        fontSize: 9,
        display: 'flex',
        flex: 'none',
        justifyContent: 'center',
        padding: 3,
        borderColor: borderColor
    },
    pdfRowHorizontal: {
        display: 'flex', 
        flexDirection: 'row', 
    },
    bottom: {
        position: 'absolute',
        bottom: 13,
        left: 25,
        right: 25,
    },
})

const WorkAllDailyAgencyReportPdf = ({ condition, 
                                 inSaleData, 
                                 inGoodsData, 
                                 cstBondData, 
                                 notGoodsBondData,
                                 stockData, 
                                 fundhData, 
                                 balanceData, 
                                 cashData,
                                 loginState, 
                                 workName
                                 }) => {

    const getTotal = (data, field) => (
        numberFormat(data.reduce((result, item) => result + item[field], 0))
    )

    const getTotalRowinGoodsData = (row, fieldName, idx) => {
        let totalRow;
        if (!inGoodsData[idx + 1] ||
            fieldName.reduce((result, field) => result || row[field] !== inGoodsData[idx + 1][field], false)) {

            const groupData = inGoodsData.reduce((result, item) => (
                fieldName.reduce((result, field) => result && item[field] === row[field], true) ?
                    [...result, item] :
                    result
            ), []);

            totalRow = (
                <PdfRow key={fieldName.reduce((result, field) => result + field) + idx}>
                    <PdfCell style={{ ...styles.cellContentLeft, flex: 0.5, borderLeft: "1px solid #D3D3D3" }}>
                        {`(소 계) ${row['WORK_NM']}`}
                    </PdfCell>
                    <PdfCell style={{ ...styles.cellContentLeft, flex: 1 }}>-</PdfCell>
                    <PdfCell style={{...styles.cellContentRight, flex: 0.8 }}>
                        {getTotal(groupData, 'BEF_BAL_P')}
                    </PdfCell>
                    <PdfCell style={{...styles.cellContentRight, flex: 0.8 }}>
                        {getTotal(groupData, 'BEF_BAL_M')}
                    </PdfCell>
                    <PdfCell style={{...styles.cellContentRight, flex: 0.8 }}>
                        {getTotal(groupData, 'CUR_IN_AMT')}
                    </PdfCell>
                    <PdfCell style={{...styles.cellContentRight, flex: 0.8 }}>
                        {getTotal(groupData, 'CUR_OUT_AMT')}
                    </PdfCell>
                    <PdfCell style={{...styles.cellContentRight, flex: 0.8 }}>
                        {getTotal(groupData, 'CUR_BAL_P')}
                    </PdfCell>
                    <PdfCell style={{...styles.cellContentRight, flex: 0.8 }}>
                        {getTotal(groupData, 'CUR_BAL_M')}
                    </PdfCell>
                </PdfRow>
            )
        }
        return totalRow
    }
    const getTotalRowcstBondData = (row, fieldName, idx) => {
        let totalRow;
        if (!cstBondData[idx + 1] ||
            fieldName.reduce((result, field) => result || row[field] !== cstBondData[idx + 1][field], false)) {

            const groupData = cstBondData.reduce((result, item) => (
                fieldName.reduce((result, field) => result && item[field] === row[field], true) ?
                    [...result, item] :
                    result
            ), []);

            totalRow = (
                <PdfRow key={fieldName.reduce((result, field) => result + field) + idx}>
                    <PdfCell style={{ ...styles.cellContentLeft, flex: 0.5, borderLeft: "1px solid #D3D3D3"  }}>
                        {`(소 계) ${row['WORK_NM']}`}
                    </PdfCell>
                    <PdfCell style={{ ...styles.cellContentLeft, flex: 1 }}>-</PdfCell>
                    <PdfCell style={{...styles.cellContentRight, flex: 1 }}>
                        {getTotal(groupData, 'BEF_BAL_P')}
                    </PdfCell>
                    <PdfCell style={{...styles.cellContentRight, flex: 1 }}>
                        {getTotal(groupData, 'BEF_BAL_M')}
                    </PdfCell>
                    <PdfCell style={{...styles.cellContentRight, flex: 0.8 }}>
                        {getTotal(groupData, 'CUR_SALE_AMT')}
                    </PdfCell>
                    <PdfCell style={{...styles.cellContentRight, flex: 0.8 }}>
                        {getTotal(groupData, 'CUR_IN_AMT')}
                    </PdfCell>
                    <PdfCell style={{...styles.cellContentRight, flex: 1 }}>
                        {getTotal(groupData, 'CUR_BAL_P')}
                    </PdfCell>
                    <PdfCell style={{...styles.cellContentRight, flex: 1 }}>
                        {getTotal(groupData, 'CUR_BAL_M')}
                    </PdfCell>
                </PdfRow>
            )
        }
        return totalRow
    }

    const getTotalRowNotGoodsBondData = (row, fieldName, idx) => {
        let totalRow;
        if (!notGoodsBondData[idx + 1] ||
            fieldName.reduce((result, field) => result || row[field] !== notGoodsBondData[idx + 1][field], false)) {

            const groupData = notGoodsBondData.reduce((result, item) => (
                fieldName.reduce((result, field) => result && item[field] === row[field], true) ?
                    [...result, item] :
                    result
            ), []);

            totalRow = (
                <PdfRow key={fieldName.reduce((result, field) => result + field) + idx}>
                    <PdfCell style={{ ...styles.cellContentLeft, flex: 0.5, borderLeft: "1px solid #D3D3D3"  }}>
                        {`(소 계) ${row['WORK_NM']}`}
                    </PdfCell>
                    <PdfCell style={{ ...styles.cellContentLeft, flex: 1 }}>-</PdfCell>
                    <PdfCell style={{...styles.cellContentRight, flex: 1 }}>
                        {getTotal(groupData, 'BEF_BAL_P')}
                    </PdfCell>
                    <PdfCell style={{...styles.cellContentRight, flex: 1 }}>
                        {getTotal(groupData, 'BEF_BAL_M')}
                    </PdfCell>
                    <PdfCell style={{...styles.cellContentRight, flex: 0.8 }}>
                        {getTotal(groupData, 'CUR_SALE_AMT')}
                    </PdfCell>
                    <PdfCell style={{...styles.cellContentRight, flex: 0.8 }}>
                        {getTotal(groupData, 'CUR_IN_AMT')}
                    </PdfCell>
                    <PdfCell style={{...styles.cellContentRight, flex: 1 }}>
                        {getTotal(groupData, 'CUR_BAL_P')}
                    </PdfCell>
                    <PdfCell style={{...styles.cellContentRight, flex: 1 }}>
                        {getTotal(groupData, 'CUR_BAL_M')}
                    </PdfCell>
                </PdfRow>
            )
        }
        return totalRow
    }

    const getTotalColor = (items) => items['TNK_NO'].includes('(소계)') || items['TNK_NO'].includes('(총계)') ? totalColor : null;


    return (
        <Document>
            <PdfPage orientation='landscape'>
                <Text style={styles.titleSort}>{`대리점 통합 보고서`}</Text>
                <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Text>{`인쇄기간: ${moment().format('YYYY-MM-DD HH:mm:ss')}`}</Text>
                    <Text>{`조회기간: ${condition['STA_DATE']} ~ ${condition['END_DATE']}`}</Text> 
                </View>
                <PdfRow fixed>
                    <PdfCell style={styles.tableTitle}>{`[매입/매출 상세 내역]`}</PdfCell>
                </PdfRow>
                <PdfTable>
                    <PdfRow fixed>
                        <PdfCell cellTop cellLeft style={{...styles.header, flex: 0.6}}>영업일</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 1}}>매입처</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 0.4}}>{`출하처\n코드`}</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 1}}>인도처</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 0.5}}>상품</PdfCell>

                        <PdfCell cellTop style={{...styles.header, flex: 0.6}}>정산수량</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 0.5}}>구매단가</PdfCell>

                        <PdfCell cellTop style={{...styles.header, flex: 0.8}}>구매금액</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 1}}>판매처</PdfCell>

                        <PdfCell cellTop style={{...styles.header, flex: 0.4}}>구분</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 0.5}}>판매단가</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 0.8}}>판매금액</PdfCell>
                    </PdfRow>
                    {inSaleData && inSaleData.map((items, idx) =>(
                        <PdfRow key={idx}>
                            <PdfCell cellLeft style={{ ...styles.cellContentLeft, flex: 0.6 }}>{items['BIZ_DATE']}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentLeft, flex: 1 }}>{items['BUY_CST_NM']}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentLeft, flex: 0.4 }}>{items['GS_SHIP_CD']}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentLeft, flex: 1 }}>{items['DEST_TRN_NM']}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentLeft, flex: 0.5 }}>{items['GDS_NM']}</PdfCell>

                            <PdfCell style={{ ...styles.cellContentRight, flex: 0.6 }}>{numberFormat(items['CALC_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 0.5 }}>{numberFormat(items['IN_PRICE'])}</PdfCell>

                            <PdfCell style={{ ...styles.cellContentRight, flex: 0.8 }}>{numberFormat(items['IN_AMT'])}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentLeft, flex: 1 }}>{items['SALE_CST_NM']}</PdfCell>

                            <PdfCell style={{ ...styles.cellContentLeft, flex: 0.4 }}>과세</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 0.5 }}>{numberFormat(items['SALE_PRICE'])}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 0.8 }}>{numberFormat(items['SALE_AMT'])}</PdfCell>
                        </PdfRow>
                    ))}
                </PdfTable>
                <Text style={{ ...styles.bottom, textAlign: 'left' }} fixed>
                    {workName} / {loginState['EMP_NM']}
                </Text>
                <Text
                    style={{ ...styles.bottom, textAlign: 'right'  }}
                    render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                    fixed
                />
            </PdfPage>
            <PdfPage orientation='landscape'>
                <PdfRow>
                    <PdfCell style={styles.tableTitle}>{`[상품 매입장]`}</PdfCell>
                </PdfRow>
                <PdfTable>
                    <PdfRow fixed>
                        <PdfCell cellTop cellLeft style={{...styles.header, flex: 0.5 }}>사업장명</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 1}}>매입처</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 0.8}}>전기 매입잔액(+)</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 0.8}}>전기 매입잔액(-)</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 0.8}}>매입 금액</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 0.8}}>지급 금액</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 0.8}}>당기 매입잔액(+)</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 0.8}}>당기 매입잔액(-)</PdfCell>
                    </PdfRow>
                    {inGoodsData && inGoodsData.map((items, idx) => (
                        [<PdfRow key={idx}>
                            <PdfCell cellLeft style={{ ...styles.cellContentLeft, flex: 0.5 }}>{items['WORK_NM']}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentLeft, flex: 1 }}>{items['CST_NM']}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 0.8 }}>{numberFormat(items['BEF_BAL_P'])}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 0.8 }}>{numberFormat(items['BEF_BAL_M'])}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 0.8 }}>{numberFormat(items['CUR_IN_AMT'])}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 0.8 }}>{numberFormat(items['CUR_OUT_AMT'])}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 0.8 }}>{numberFormat(items['CUR_BAL_P'])}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 0.8 }}>{numberFormat(items['CUR_BAL_M'])}</PdfCell>
                        </PdfRow>,
                        getTotalRowinGoodsData(items, ['WORK_NM'], idx)]
                    ))}

                        <PdfRow>
                            <PdfCell cellLeft style={{ ...styles.cellContentLeft, flex: 0.5 }}>합 계</PdfCell>
                            <PdfCell style={{ ...styles.cellContentLeft, flex: 1 }}>-</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 0.8 }}>{getTotal(inGoodsData, 'BEF_BAL_P')}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 0.8 }}>{getTotal(inGoodsData, 'BEF_BAL_M')}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 0.8 }}>{getTotal(inGoodsData, 'CUR_IN_AMT')}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 0.8 }}>{getTotal(inGoodsData, 'CUR_OUT_AMT')}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 0.8 }}>{getTotal(inGoodsData, 'CUR_BAL_P')}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 0.8 }}>{getTotal(inGoodsData, 'CUR_BAL_M')}</PdfCell>
                        </PdfRow>
                </PdfTable>

                <Text style={{ ...styles.bottom, textAlign: 'left' }} fixed>
                    {workName} / {loginState['EMP_NM']}
                </Text>
                <Text
                    style={{ ...styles.bottom, textAlign: 'right'  }}
                    render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                    fixed
                />
            </PdfPage>
            <PdfPage orientation='landscape'>
                <PdfRow fixed>
                    <PdfCell style={styles.tableTitle}>{`[채권현황(외상매출처)]`}</PdfCell>
                </PdfRow>
                <PdfTable>
                    <PdfRow fixed>
                        <PdfCell cellTop cellLeft style={{...styles.header, flex: 0.5}}>사업장명</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 1}}>거래처명</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 1}}>전기 매출잔액(+)</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 1}}>전기 매출잔액(-)</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 0.8}}>당기 매출</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 0.8}}>당기 입금</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 1}}>당기 매출잔액(+)</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 1}}>당기 매출잔액(-)</PdfCell>
                    </PdfRow>
                    {cstBondData && cstBondData.map((items, idx) => (
                        [<PdfRow key={idx}>
                            <PdfCell cellLeft style={{ ...styles.cellContentLeft, flex: 0.5 }}>{items['WORK_NM']}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentLeft, flex: 1 }}>{items['CST_NM']}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 1 }}>{numberFormat(items['BEF_BAL_P'])}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 1 }}>{numberFormat(items['BEF_BAL_M'])}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 0.8 }}>{numberFormat(items['CUR_SALE_AMT'])}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 0.8 }}>{numberFormat(items['CUR_IN_AMT'])}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 1 }}>{numberFormat(items['CUR_BAL_P'])}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 1 }}>{numberFormat(items['CUR_BAL_M'])}</PdfCell>
                        </PdfRow>,
                        getTotalRowcstBondData(items, ['WORK_NM'], idx)]
                    ))}

                    <PdfRow>
                        <PdfCell cellLeft style={{ ...styles.cellContentLeft, flex: 0.5 }}>합 계</PdfCell>
                        <PdfCell style={{ ...styles.cellContentLeft, flex: 1 }}>-</PdfCell>
                        <PdfCell style={{ ...styles.cellContentRight, flex: 1 }}>{getTotal(cstBondData, 'BEF_BAL_P')}</PdfCell>
                        <PdfCell style={{ ...styles.cellContentRight, flex: 1 }}>{getTotal(cstBondData, 'BEF_BAL_M')}</PdfCell>
                        <PdfCell style={{ ...styles.cellContentRight, flex: 0.8 }}>{getTotal(cstBondData, 'CUR_SALE_AMT')}</PdfCell>
                        <PdfCell style={{ ...styles.cellContentRight, flex: 0.8 }}>{getTotal(cstBondData, 'CUR_IN_AMT')}</PdfCell>
                        <PdfCell style={{ ...styles.cellContentRight, flex: 1 }}>{getTotal(cstBondData, 'CUR_BAL_P')}</PdfCell>
                        <PdfCell style={{ ...styles.cellContentRight, flex: 1 }}>{getTotal(cstBondData, 'CUR_BAL_M')}</PdfCell>
                    </PdfRow>
                    
                </PdfTable>
                <Text style={{ ...styles.bottom, textAlign: 'left' }} fixed>
                    {workName} / {loginState['EMP_NM']}
                </Text>
                <Text
                    style={{ ...styles.bottom, textAlign: 'right'  }}
                    render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                    fixed
                />
            </PdfPage>
            <PdfPage orientation='landscape'>
                <PdfRow fixed>
                    <PdfCell style={styles.tableTitle}>{`[채권현황(비상품)]`}</PdfCell>
                </PdfRow>
                <PdfTable>
                    <PdfRow fixed>
                        <PdfCell cellTop cellLeft style={{...styles.header, flex: 0.5}}>사업장명</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 1}}>거래처명</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 1}}>전기 매출잔액(+)</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 1}}>전기 매출잔액(-)</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 0.8}}>당기 매출</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 0.8}}>당기 입금</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 1}}>당기 매출잔액(+)</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 1}}>당기 매출잔액(-)</PdfCell>
                    </PdfRow>
                    {notGoodsBondData && notGoodsBondData.map((items, idx) => (
                        [<PdfRow key={idx}>
                            <PdfCell cellLeft style={{ ...styles.cellContentLeft, flex: 0.5 }}>{items['WORK_NM']}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentLeft, flex: 1 }}>{items['CST_NM']}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 1 }}>{numberFormat(items['BEF_BAL_P'])}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 1 }}>{numberFormat(items['BEF_BAL_M'])}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 0.8 }}>{numberFormat(items['CUR_SALE_AMT'])}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 0.8 }}>{numberFormat(items['CUR_IN_AMT'])}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 1 }}>{numberFormat(items['CUR_BAL_P'])}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 1 }}>{numberFormat(items['CUR_BAL_M'])}</PdfCell>
                        </PdfRow>,
                        getTotalRowNotGoodsBondData(items, ['WORK_NM'], idx)]
                    ))}

                    <PdfRow>
                        <PdfCell cellLeft style={{ ...styles.cellContentLeft, flex: 0.5 }}>합 계</PdfCell>
                        <PdfCell style={{ ...styles.cellContentLeft, flex: 1 }}>-</PdfCell>
                        <PdfCell style={{ ...styles.cellContentRight, flex: 1 }}>{getTotal(notGoodsBondData, 'BEF_BAL_P')}</PdfCell>
                        <PdfCell style={{ ...styles.cellContentRight, flex: 1 }}>{getTotal(notGoodsBondData, 'BEF_BAL_M')}</PdfCell>
                        <PdfCell style={{ ...styles.cellContentRight, flex: 0.8 }}>{getTotal(notGoodsBondData, 'CUR_SALE_AMT')}</PdfCell>
                        <PdfCell style={{ ...styles.cellContentRight, flex: 0.8 }}>{getTotal(notGoodsBondData, 'CUR_IN_AMT')}</PdfCell>
                        <PdfCell style={{ ...styles.cellContentRight, flex: 1 }}>{getTotal(notGoodsBondData, 'CUR_BAL_P')}</PdfCell>
                        <PdfCell style={{ ...styles.cellContentRight, flex: 1 }}>{getTotal(notGoodsBondData, 'CUR_BAL_M')}</PdfCell>
                    </PdfRow>
                    
                </PdfTable>
                <Text style={{ ...styles.bottom, textAlign: 'left' }} fixed>
                    {workName} / {loginState['EMP_NM']}
                </Text>
                <Text
                    style={{ ...styles.bottom, textAlign: 'right'  }}
                    render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                    fixed
                />
            </PdfPage>
            <PdfPage orientation='landscape'>
                <PdfRow>
                    <PdfCell style={styles.tableTitle}>{`[재고현황]`}</PdfCell>
                </PdfRow>
                <PdfTable>
                    <PdfRow fixed>
                        <PdfCell cellTop cellLeft style={{...styles.header, flex: 0.8}}>탱크번호</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 0.8}}>카테고리명</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 1}}>전기재고</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 1}}>당기입고</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 1}}>재고조정</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 1}}>당기이관입고</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 1}}>당기출고</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 1}}>당기이관출고</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 1}}>당기재고량</PdfCell>
                    </PdfRow>
                    {stockData && stockData.map((items, idx) => (
                        <PdfRow key={idx}>
                            <PdfCell cellLeft style={{ ...styles.cellContentLeft, flex: 0.8, backgroundColor: getTotalColor(items) }}>{items['TNK_NO']}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentLeft, flex: 0.8, backgroundColor: getTotalColor(items) }}>{items['CATE_NM']}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 1, backgroundColor: getTotalColor(items) }}>{numberFormat(items['BEF_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 1, backgroundColor: getTotalColor(items) }}>{numberFormat(items['CUR_IN_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 1, backgroundColor: getTotalColor(items) }}>{numberFormat(items['CUR_ADJ_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 1, backgroundColor: getTotalColor(items) }}>{numberFormat(items['CUR_MOVE_IN_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 1, backgroundColor: getTotalColor(items) }}>{numberFormat(items['CUR_SALE_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 1, backgroundColor: getTotalColor(items) }}>{numberFormat(items['CUR_MOVE_OUT_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 1, backgroundColor: getTotalColor(items) }}>{numberFormat(items['CUR_QTY'])}</PdfCell>
                        </PdfRow>
                    ))}
                </PdfTable>
                <Text style={{ ...styles.bottom, textAlign: 'left' }} fixed>
                    {workName} / {loginState['EMP_NM']}
                </Text>
                <Text
                    style={{ ...styles.bottom, textAlign: 'right'  }}
                    render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                    fixed
                />
            </PdfPage>
            <PdfPage orientation='landscape'>
                <PdfRow fixed>
                    <PdfCell style={styles.tableTitle}>{`[자금관리]`}</PdfCell>
                </PdfRow>
                <PdfTable>
                    <PdfRow fixed>                     
                        <PdfCell cellTop cellLeft style={{...styles.header, flex: 0.3}}>사업장명</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 0.3}}>영업일</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 0.3}}>계정과목명</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 1.5}}>적요</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 0.5}}>수입</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 0.5}}>지출</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 0.7}}>잔액</PdfCell>

                    </PdfRow>
                    {fundhData && fundhData.map((items, idx) => (
                        <PdfRow key={idx}>
                            <PdfCell cellLeft style={{ ...styles.cellContentLeft, flex: 0.3 }}>{items['WORK_NM']}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentLeft, flex: 0.3 }}>{items['BIZ_DATE']}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentLeft, flex: 0.3 }}>{items['ACC_NM']}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentLeft, flex: 1.5 }}>{items['SUMUP']}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 0.5 }}>{numberFormat(items['IN_AMT'])}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 0.5 }}>{numberFormat(items['OUT_AMT'])}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 0.7 }}>{numberFormat(items['BAL_AMT'])}</PdfCell>
                        </PdfRow>
                    ))}
                    <PdfRow>
                            <PdfCell cellLeft style={{ ...styles.cellContentLeft, flex: 0.3 }}>합계</PdfCell>
                            <PdfCell style={{ ...styles.cellContentLeft, flex: 0.3 }}>-</PdfCell>
                            <PdfCell style={{ ...styles.cellContentLeft, flex: 0.3 }}>-</PdfCell>
                            <PdfCell style={{ ...styles.cellContentLeft, flex: 1.5 }}>-</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 0.5 }}>{getTotal(fundhData, 'IN_AMT')}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 0.5 }}>{getTotal(fundhData, 'OUT_AMT')}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 0.7 }}>-</PdfCell>
                        </PdfRow>
                </PdfTable>
                <Text style={{ ...styles.bottom, textAlign: 'left' }} fixed>
                    {workName} / {loginState['EMP_NM']}
                </Text>
                <Text
                    style={{ ...styles.bottom, textAlign: 'right'  }}
                    render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                    fixed
                />
            </PdfPage>
            <PdfPage orientation='landscape'>
                <PdfRow fixed>
                    <PdfCell style={styles.tableTitle}>{`[계좌잔액]`}</PdfCell>
                </PdfRow>
                <PdfTable>
                    <PdfRow fixed>
                        <PdfCell cellTop cellLeft style={{...styles.header, flex: 1}}>계좌번호</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 1}}>통장명</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 1}}>잔액</PdfCell>
                    </PdfRow>
                    {balanceData && balanceData.map((items, idx) => (
                        <PdfRow key={idx}>
                            <PdfCell cellLeft style={{ ...styles.cellContentLeft, flex: 1 }}>{items['BBK_NO']}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentLeft, flex: 1 }}>{items['BBK_NM']}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 1 }}>{numberFormat(items['AMOUNT'])}</PdfCell>
                        </PdfRow>
                    ))}
                    
                </PdfTable>
                <Text style={{ ...styles.bottom, textAlign: 'left' }} fixed>
                    {workName} / {loginState['EMP_NM']}
                </Text>
                <Text
                    style={{ ...styles.bottom, textAlign: 'right'  }}
                    render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                    fixed
                />
            </PdfPage>
            <PdfPage orientation='landscape'>
                <PdfRow fixed>
                    <PdfCell style={styles.tableTitle}>{`[현금출납부]`}</PdfCell>
                </PdfRow>
                <PdfTable>
                    <PdfRow fixed>
                        <PdfCell cellTop cellLeft style={{...styles.header, flex: 0.3}}>사업장명</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 0.3}}>영업일</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 0.3}}>계정과목명</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 1.5}}>적요</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 0.5}}>수입</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 0.5}}>지출</PdfCell>
                        <PdfCell cellTop style={{...styles.header, flex: 0.5}}>잔액</PdfCell>
                    </PdfRow>
                    {cashData && cashData.map((items, idx) => (
                        <PdfRow key={idx}>
                            <PdfCell cellLeft style={{ ...styles.cellContentLeft, flex: 0.3 }}>{items['WORK_NM']}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentLeft, flex: 0.3 }}>{items['ACJ_DATE'] != null && items['ACJ_DATE']}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentLeft, flex: 0.3 }}>{items['ACC_NM']}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentLeft, flex: 1.5 }}>{items['SUMUP']}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 0.5 }}>{numberFormat(items['DEBTOR'])}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 0.5 }}>{numberFormat(items['CREDITOR'])}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 0.5 }}>{numberFormat(items['BAL_AMT'])}</PdfCell>
                        </PdfRow>
                    ))}
                    <PdfRow>
                            <PdfCell cellLeft style={{ ...styles.cellContentLeft, flex: 0.3 }}>합 계</PdfCell>
                            <PdfCell style={{ ...styles.cellContentLeft, flex: 0.3 }}>-</PdfCell>
                            <PdfCell style={{ ...styles.cellContentLeft, flex: 0.3 }}>-</PdfCell>
                            <PdfCell style={{ ...styles.cellContentLeft, flex: 1.5 }}>-</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 0.5 }}>{getTotal(cashData, 'DEBTOR')}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 0.5 }}>{getTotal(cashData, 'CREDITOR')}</PdfCell>
                            <PdfCell style={{ ...styles.cellContentRight, flex: 0.5 }}>-</PdfCell>
                    </PdfRow>
                </PdfTable>
                <Text style={{ ...styles.bottom, textAlign: 'left' }} fixed>
                    {workName} / {loginState['EMP_NM']}
                </Text>
                <Text
                    style={{ ...styles.bottom, textAlign: 'right'  }}
                    render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                    fixed
                />
            </PdfPage>
        </Document>
    )
}

export default WorkAllDailyAgencyReportPdf;