import React, { useState } from 'react';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DataColumn, ComboSearch, NumberField, DatePicker} from '../../component';
import { makeStyles } from '@material-ui/core';
import { trdStnd, trdType } from '../../common/Dictionary';
import { isFieldDiff } from '../../common/Utils';


const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
}))

const CustomerTrdType = () => {
    const [condition, setCondition] = useState(null);

    const classes = useStyles();




    return (
            <div className={classes.container}>
                <SearchPanel onSearch={(condition) => setCondition(condition)}>
                    <SearchItem
                        fieldName='WORK_CD'
                        component={(state, dispatch) => (
                            <ComboBox
                                label='사업장명'
                                selectProc='SLT_WORKPLACEINFO_AUTH'
                                keyField='WORK_CD'
                                valueField='WORK_NM'
                                value={state}
                                onChange={(value) => {
                                    dispatch(value);
                                }}
                            />
                        )}
                    />
                    <SearchItem
                        fieldName='CST_CD'
                        component={(state, setState, condition, setField) => {
                            let param = condition['CST_PARAM'] || null;
                            if (isFieldDiff(['WORK_CD'], condition, param)) {
                                param = { WORK_CD: condition['WORK_CD'] };
                                setField('CST_PARAM', param);
                            }
                            return(
                                <ComboSearch
                                    label='거래처명'
                                    selectProc='SLT_CUSTOMERINFO_NOTEND '
                                    selectParam={param}
                                    keyField='CST_CD'
                                    valueField='CST_NM'
                                    onDataLoad={(jsonData, rawData) => [ {CST_CD: 'ALL', CST_NM: '전체'}, ...rawData ]}
                                    value={state}
                                    onChange={(value) => setState(value)}
                                />
                            )}
                        }
                    />
                </SearchPanel>
                <DataGrid
                        title='거래처 거래조건'
                        selectProc='SLT_CUSTOMERTRDTYPE'
                        insertProc='INS_CUSTOMERTRDTYPE'
                        updateProc='UPT_CUSTOMERTRDTYPE'
                        deleteProc='DLT_CUSTOMERTRDTYPE'
                        selectParam={condition}
                        horizonMode
                        excelDownload={{ fileName: '거래처 거래조건.xlsx', sheetName: '거래조건' }}
                        keys={['CST_CD']}
                    >

                        <DataColumn
                            fieldName='CST_CD'
                            value={row => row['CST_NM']}
                            defaultValue={condition && condition['CST_CD']}
                            editable={(state, dispatch) => (
                                <ComboBox
                                    selectProc='SLT_CUSTOMERINFO_NOTEND'
                                    selectParam={condition}
                                    keyField='CST_CD'
                                    valueField='CST_NM'
                                    value={state}
                                    onChange={value => dispatch(value)}
                                    disabled={true}
                                />
                            )}
                        />

                        <DataColumn
                            fieldName='WORK_CD'
                            title='사업장명'
                            value={row => row['WORK_NM']}
                            defaultValue={condition && condition['WORK_CD']}
                            editable={(state, dispatch) => (
                                <ComboBox
                                    selectProc='SLT_WORKPLACEINFO_AUTH'
                                    keyField='WORK_CD'
                                    valueField='WORK_NM'
                                    value={state}
                                    onChange={(value) => dispatch(value)}
                                    disabled
                                />
                            )} 
                        />

                        <DataColumn
                            fieldName='STA_DATE'
                            title='시작일'
                            editable={(state, dispatch) => (
                                <DatePicker
                                    value={state}
                                    onChange={value => {
                                        dispatch(value);
                                    }}
                                />
                            )}
                        />
        
                        <DataColumn
                            fieldName='TRD_TYPE'
                            value={(row, value) => trdType[value]}
                            defaultValue={Object.keys(trdType)[0]}
                            editable={(state, dispatch, rowState) => (
                                <ComboBox
                                    data={trdType}
                                    value={state}
                                    onChange={value => dispatch(value)}
                                />
                            )}
                        />
                        <DataColumn
                            fieldName='TRD_STND'
                            defaultValue={row => row['TRD_STND'] ? row['TRD_STND'] :  '1'}
                            value={(row, value) => trdStnd[value]}
                            editable={(state, dispatch, rowState) => (
                                <ComboBox
                                    data={trdStnd}
                                    value={state}
                                    onChange={value => dispatch(value)}
                                />
                            )}
                        />

                        <DataColumn
                            fieldName='TRD_STND_USE'
                            editable={(state, dispatch, rowState) => (
                                <NumberField
                                    style={{ width: 50 }}
                                    value={state}
                                    onChange={value => dispatch(String(rowState['TRD_STND']) === '1' || 
                                                                String(rowState['TRD_STND']) === '2' || 
                                                                value === '' ? null : value)}
                                    disabled={String(rowState['TRD_STND']) === '1' || String(rowState['TRD_STND']) === '2'}
                                />
                            )}
                        />
                        
                        <DataColumn
                            fieldName='TRD_STND_DAY'
                            editable={(state, dispatch, rowState) => (
                                <NumberField
                                    style={{ width: 50 }}
                                    value={state}
                                    onChange={value => dispatch(value)}
                                />
                            )}
                        />
                        <DataColumn
                            fieldName='CREDIT_LIMIT'
                            editable={(state, dispatch, rowState) => (
                                <NumberField
                                    style={{ width: 160 }}
                                    value={state}
                                    onChange={value => dispatch(value)}
                                />
                            )}
                        />
                        <DataColumn fieldName='CST_NM' visible={false} />
                        <DataColumn fieldName='WORK_NM' visible={false} />
                        <DataColumn fieldName='SEQ' visible={false} />
                    
                </DataGrid>

            </div>

        
    )
}



export default CustomerTrdType