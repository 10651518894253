import React, { useState, useEffect, Fragment, useMemo } from 'react';
import moment from 'moment';
import { DataGrid, SearchItem, SearchPanel, ComboBox, ProgressPanel, DatePicker, DataColumn, PdfButton } from '../../component';
import { callProc } from '../../common/DBConnector';
import { makeStyles } from '@material-ui/core';
import { CardSaleInfoPdf } from '../../reports';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    }
}))

const CardSaleInfo = () => {
    const [condition, setCondition] = useState(null);
    const [workData, setWorkData] = useState();
    const [cardData, setCardData] = useState();
    const [payData, setPayData] = useState();
    const [data, setData] = useState(null);
    const [pdfData, setPdfData] = useState();
    const loginState = useSelector(state => state.loginReducer);

    useEffect(() => {
        const fetchData = async () => {
            const workData = await callProc('SLT_WORKPLACEINFO_NOTMAIN_AUTH');
            const payData = await callProc('SLT_PAYMENTINFO');

            setWorkData(workData.data.reduce((result, item) => ({
                ...result,
                [item['WORK_CD']]: item['WORK_NM']
            }), {}))
           
            setPayData(payData.data.reduce((result, item) => ({
                ...result,
                [item['PAY_CD']]: item['PAY_NM']
            }), {}))
        }
        fetchData();
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            setData(null);
            setCardData(null);
            const cardData = await callProc('SLT_CARDINFO', condition);
            setCardData(cardData.data.reduce((result, item) => ({
                ...result,
                [item['CARD_CD']] : item['CARD_NM']
            }), {}))
            const data = await callProc('SLT_SALEINFO_CARD',condition);
            setData(data);
        }
        condition && fetchData();
    }, [condition])

    const classes = useStyles();


    const pdfComponent = useMemo(() => (
        <PdfButton title='카드사매출 조회'>
            {pdfData &&
            <CardSaleInfoPdf
                data={pdfData}
                fieldsData={data}
                cardData={cardData}
                payData={payData}
                workCd={condition && condition['WORK_CD']}
                workName={condition && workData[condition['WORK_CD']]}
                bizMonth={condition && condition['BIZ_MONTH']}
                loginState={loginState}
            />}
        </PdfButton>
    ), [pdfData, data, cardData, payData, workData, condition, loginState ]);


    return (
        (!workData || !payData) ? <ProgressPanel /> :
            <div className={classes.container}>
                <SearchPanel onSearch={(condition) => setCondition(condition)}>
                    <SearchItem
                        fieldName='WORK_CD'
                        defaultValue={Object.keys(workData)[0]}
                        component={(state, dispatch) => (
                            <ComboBox
                                label='사업장명'
                                data={workData}
                                value={state}
                                onChange={(value) => dispatch(value)}
                            />
                        )}
                    />
                    <SearchItem
                        fieldName='BIZ_MONTH'
                        defaultValue={moment().format('YYYY-MM')}
                        component={(state, dispatch) => (
                            <DatePicker
                                label='날짜'
                                value={state}
                                onChange={(value) => dispatch(value)}
                                monthPicker
                            />
                        )}
                    />
                </SearchPanel>
                <Fragment>
                    <DataGrid
                        title='카드사 매출'
                        dataSet={data}
                        excelDownload
                        headerItem={({data}) => {
                            setPdfData(data);
                            return pdfComponent;
                        }}
                        horizonMode
                        virtualized
                        selectionMode='single'
                    >
                        {data && data.fields.map(item => (
                            item !== 'BIZ_DATE' &&
                            <DataColumn
                                key={item}
                                fieldName={item}
                                title={(cardData[item]) || payData[item]}
                                total={(values) => values.reduce((result, value) => result + value, 0)}
                            />
                        ))}
                    </DataGrid>
                </Fragment>
            </div>
    )
}

export default CardSaleInfo;