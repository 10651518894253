import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { DataGrid, SearchItem, SearchPanel, DatePicker, DataColumn, Tab, Tabs, ProgressPanel, SmallGrid, IconButton, SplitPanel } from '../../component';
import { makeStyles } from '@material-ui/core';
import { callProc } from '../../common/DBConnector';
import GridOnIcon from '@material-ui/icons/GridOn';
import { aoaToSheet, sheetToWorkbook, downloadWorkbook, useFieldInputs } from '../../common/Utils';

const saleLoadType = {
    '0': '수량',
    '1': '금액(원 - 공급가액)',
}

const inLoadType = {
    '0': '수량',
    '1': '금액(원 - 합계금액)',
}

const accType = {
    1 : '현금성 계정',
    2 : '예금성 계정'
}

const useStyle = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    tabContainer : {
        display : 'flex',
    },
    subTabContainer : {
        backgroundColor: theme.palette.secondary.main,
        boxShadow: theme.shadows[4],
        marginBottom: 8,
    },
    tabbar : {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    tab: {
        minWidth: 100,
    },
    content : {
        display: 'flex',
        flex: 1,
        height : 0,
    },
    hide : {
        display: 'none'
    },
}))

const BusinessReport = () => {
    const classes = useStyle();
    const [condition, setCondition] = useState(null);
    const [selectTab, setSelectTab] = useState(0);
    const [closeData, setCloseData] = useState();
    const [cstDebtData, setCstDebtData] = useState();
    const [stockData, setStockData] = useState();
    const [fundsData, setFundsData] = useState();
    const [balanceData, setBalanceData] = useState();
    const [profitLossData, setProfitLossData] = useState();


    useEffect(() => {
        const fetchData = async() => {
            setCloseData(null);
            setCstDebtData(null);
            setStockData(null);
            setFundsData(null);
            setBalanceData(null);
            setProfitLossData(null);

            const closeData = await callProc('SLT_CLOSEDATE_REPORT', condition);
            const cstDebtData = await callProc('SLT_DEBT_REPORT', condition);
            const stockData = await callProc('SLT_STOCK_REPORT', condition);
            const fundsData = await callProc('SLT_FUND_REPORT', condition);
            const balanceData = await callProc('SLT_BANKBOOK_BALANCE_REPORT', condition);
            const profitLossData = await callProc('SLT_PROFIT_LOSS_REPORT', condition);

            setCloseData(closeData);
            setCstDebtData(cstDebtData);
            setStockData(stockData);
            setFundsData(fundsData);
            setBalanceData(balanceData);
            setProfitLossData(profitLossData);

        }
        condition && fetchData();
    }, [condition])






    return(
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => {
                setCondition(condition);
                }}>
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                dispatch('WORK_CD', 'ALL');
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
            </SearchPanel>
            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab className={classes.tab} label='마감현황' />
                    <Tab className={classes.tab} label='매출' />
                    <Tab className={classes.tab} label='매입' />
                    <Tab className={classes.tab} label='채무잔액' />
                    <Tab className={classes.tab} label='재고' />
                    <Tab className={classes.tab} label='자금관리대장' />
                    <Tab className={classes.tab} label='계좌잔액' />
                    <Tab className={classes.tab} label='채권잔액' />
                    <Tab className={classes.tab} label='위배채권' />
                    <Tab className={classes.tab} label='재무상태표' />
                    <Tab className={classes.tab} label='손익계산서' />
                </Tabs>
            </div>
            {selectTab === 0 &&
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <CloseReport condition={condition} closeData={closeData}/>
            </div>}
            {selectTab === 1 &&
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <Sales condition={condition} />
            </div>} 
            {selectTab === 2 &&
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 2 })}>
                <Inbound condition={condition} />
            </div>}
            {selectTab === 3 &&
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 3 })}>
                <CstDebt condition={condition} cstDebtData={cstDebtData}/>
            </div>}
            {selectTab === 4 &&
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 4 })}>
                <Stock condition={condition} stockData={stockData}/>
            </div>}
            {selectTab === 5 &&
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 5 })}>
                <Funds condition={condition} fundsData={fundsData}/>
            </div>}
            {selectTab === 6 &&
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 6 })}>
                <Balance condition={condition} balanceData={balanceData}/>
            </div>}
            {selectTab === 7 &&
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 7 })}>
                <CstCredit condition={condition} />
            </div>}
            {selectTab === 8 &&
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 8 })}>
                <Violation condition={condition} />
            </div>}
            {selectTab === 9 &&
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 9 })}>
                <Financial condition={condition} />
            </div>}
            {selectTab === 10 &&
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 10 })}>
                <ProfitLoss condition={condition} profitLossData={profitLossData}/>
            </div>}
        </div>
    )
}


const CloseReport = ({condition}) => {

    return (
    <DataGrid
        title='마감현황'
        selectProc='SLT_CLOSEDATE_REPORT'
        selectParam={condition}
        selectionMode='single'
        horizonMode
        excelDownload
        sortable
        filterable
    >
        <DataColumn fieldName='WORK_NM' />
        <DataColumn fieldName='CLS_DAY' title='일' />
        <DataColumn fieldName='CLS_MONTH' title='월' />
        <DataColumn fieldName='WORK_CD' visible={false} />
    </DataGrid>

    )
}

const Sales = ({ condition }) => {
    const [smallData, setSmallData] = useFieldInputs({});
    const gridData = useMemo(() => condition && {
        data: [ 
            {LOAD_TYPE: '0', EXPAND_TYPE: 1}, 
            {LOAD_TYPE: '1', EXPAND_TYPE: 1},
        ],
        fields: ['LOAD_TYPE', 'EXPAND_TYPE']
    }, [condition]);

    const onExcelDown = () => {
        downloadWorkbook('영업보고서 매출', sheetToWorkbook([
            { name: saleLoadType['0'], sheet: aoaToSheet(smallData['0']) },
            { name: saleLoadType['1'], sheet: aoaToSheet(smallData['1']) },
        ]))
    }

    return (
        <DataGrid
            title='매출'
            dataSet={gridData}
            headerItem={() => (
                <IconButton
                    tooltip='엑셀 다운로드'
                    icon={<GridOnIcon />}
                    onClick={onExcelDown}
                    disabled={Object.keys(smallData).length !== 2}
                />
            )}
            selectionMode='single'
            defaultExpand
            sortable
            filterable
            expandable={(row) => (
                String(row['EXPAND_TYPE']) === '0' ? null :
                <SmallGrid
                    selectProc='SLT_SALES_REPORT'
                    selectParam={{ ...condition, ...row }}
                    horizonMode
                    headerLess
                    totalGroup={['WORK_TYPE', 'WORK_TYPE_NM']}
                    onLoad={({visibleAoa}) => {
                        setSmallData(row['LOAD_TYPE'], visibleAoa)}
                    }
                >
                    <DataColumn fieldName='SEQ' visible={false} />
                    <DataColumn fieldName='WORK_NM' total={(values, data, group) => group == null ? '합 계' : `(소 계) ${group['WORK_TYPE_NM']}`}/>
                    <DataColumn fieldName='WORK_TYPE' visible={false}/>
                    <DataColumn fieldName='WORK_TYPE_NM' visible={false}/>
                    <DataColumn fieldName='P_GASOL' title='일(고급 휘발유)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                    <DataColumn fieldName='P_GASOL_R' title='기간(고급 휘발유)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                    <DataColumn fieldName='GASOL' title='일(휘발유)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                    <DataColumn fieldName='GASOL_R' title='기간(휘발유)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                    <DataColumn fieldName='KERO' title='일(실내등유)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                    <DataColumn fieldName='KERO_R' title='기간(실내등유)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                    <DataColumn fieldName='VIA' title='일(경유)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                    <DataColumn fieldName='VIA_R' title='기간(경유)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                    <DataColumn fieldName='OIL_SUM' title='일(일반유 합계)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                    <DataColumn fieldName='OIL_SUM_R' title='기간(일반유 합계)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                    <DataColumn fieldName='WASH' title='일(세차)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                    <DataColumn fieldName='WASH_R' title='기간(세차)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                    <DataColumn fieldName='MACH' title='일(부대장비)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                    <DataColumn fieldName='MACH_R' title='기간(부대장비)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                    <DataColumn fieldName='ETC' title='일(기타)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                    <DataColumn fieldName='ETC_R' title='기간(기타)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                    <DataColumn fieldName='TOTAL' title='일(전체)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                    <DataColumn fieldName='TOTAL_R' title='기간(전체)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                </SmallGrid>
            )}
        >
            <DataColumn fieldName='LOAD_TYPE' title='구분' value={(row, value) => saleLoadType[value]} />
            <DataColumn fieldName='EXPAND_TYPE' title='확장구분' visible={false} />
        </DataGrid>
        
    )
}

const Inbound = ({ condition }) => {
    const [smallData, setSmallData] = useFieldInputs({});
    const gridData = useMemo(() => condition && {
        data: [ 
            {LOAD_TYPE: '0', EXPAND_TYPE: 1}, 
            {LOAD_TYPE: '1', EXPAND_TYPE: 1},
        ],
        fields: ['LOAD_TYPE', 'EXPAND_TYPE']
    }, [condition]);

    const onExcelDown = () => {
        downloadWorkbook('영업보고서 매입', sheetToWorkbook([
            { name: saleLoadType['0'], sheet: aoaToSheet(smallData['0']) },
            { name: saleLoadType['1'], sheet: aoaToSheet(smallData['1']) },
        ]))
    }

    return (
        <DataGrid
            title='매입'
            dataSet={gridData}
            headerItem={() => (
                <IconButton
                    tooltip='엑셀 다운로드'
                    icon={<GridOnIcon />}
                    onClick={onExcelDown}
                    disabled={Object.keys(smallData).length !== 2}
                />
            )}
            selectionMode='single'
            defaultExpand
            sortable
            filterable
            expandable={(row) => (
                String(row['EXPAND_TYPE']) === '0' ? null :
                <SmallGrid
                    selectProc='SLT_INBOUND_REPORT'
                    selectParam={{ ...condition, ...row }}
                    totalGroup={['WORK_NM']}
                    headerLess
                    horizonMode
                    onLoad={({visibleAoa}) => {
                        setSmallData(row['LOAD_TYPE'], visibleAoa)}
                    }
                >
                    <DataColumn fieldName='WORK_NM' total={(values, data, group) => group == null ? '합 계' : '(소 계) ' + group['WORK_NM']} />
                    <DataColumn fieldName='SEQ' visible={false} />
                    <DataColumn fieldName='CST_NM' title='매입처' />
                    <DataColumn fieldName='P_GASOL' title='일(고급 휘발유)' total={(values, ) => values.reduce((result, value) => result + value, 0)}/>
                    <DataColumn fieldName='P_GASOL_R' title='기간(고급 휘발유)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                    <DataColumn fieldName='GASOL' title='일(휘발유)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                    <DataColumn fieldName='GASOL_R' title='기간(휘발유)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                    <DataColumn fieldName='KERO' title='일(실내등유)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                    <DataColumn fieldName='KERO_R' title='기간(실내등유)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                    <DataColumn fieldName='VIA' title='일(경유)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                    <DataColumn fieldName='VIA_R' title='기간(경유)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                    <DataColumn fieldName='DC' title='일(현장할인)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                    <DataColumn fieldName='DC_R' title='기간(현장할인)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                    <DataColumn fieldName='TOTAL' title='일(전체)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                    <DataColumn fieldName='TOTAL_R' title='기간(전체)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                </SmallGrid>
            )}
        >
            <DataColumn fieldName='LOAD_TYPE' title='구분' value={(row, value) => inLoadType[value]} />
            <DataColumn fieldName='EXPAND_TYPE' title='확장구분' visible={false} />
        </DataGrid>
    )
}

const CstDebt = ({ condition }) => {
    
    return(
    <DataGrid
        title='채무잔액'
        selectProc='SLT_DEBT_REPORT'
        selectParam={condition}
        selectionMode='single'
        totalGroup={['WORK_NM']}       
        horizonMode
        excelDownload
        sortable
        filterable
    >
        <DataColumn fieldName='WORK_NM' total={(values, data, group) => group == null ? '합 계' : '(소 계) ' + group['WORK_NM']} />
        <DataColumn fieldName='CST_NM' title='매입처' />
        <DataColumn fieldName='BEF_BAL_M' title='전기 채무잔액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
        <DataColumn fieldName='CUR_IN_AMT' title='당기 발생 채무' total={(values) => values.reduce((result, value) => result + value, 0)}/>
        <DataColumn fieldName='CUR_OUT_AMT' title='당기 지급액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
        <DataColumn fieldName='CUR_BAL_M' title='당기 채무잔액' total={(values) => values.reduce((result, value  ) => result + value, 0)}/>
    </DataGrid>
    )
}
const Stock = ({ condition }) => {

    return (
    <DataGrid
        title='재고현황'
        selectProc='SLT_STOCK_REPORT'
        selectParam={condition}
        selectionMode='single'
        horizonMode
        totalGroup={['WORK_NM']}
        fixedColumns={['WORK_NM', 'GDS_NM']}
        hideTotal
        excelDownload
        sortable
        filterable
    >
        <DataColumn fieldName='WORK_NM' total={(values, data, group) => group ? group['WORK_NM'] + ' 소계' : '합계'}/>
        <DataColumn fieldName='GDS_NM' />
        <DataColumn fieldName='BEF_QTY' title='전기 재고' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='CUR_IN_QTY' title='구매(입고)' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='CUR_IN_MOVE_QTY' title='이관(입고)' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='CUR_IN_STOCK_QTY' title='보관(입고)' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='CUR_SALE_QTY' title='판매(출고)' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='CUR_OUT_MOVE_QTY' title='이관(출고)' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='CUR_OUT_STOCK_QTY' title='보관(출고)' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='CUR_QTY' title='당기 재고량' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='CUR_REAL_QTY' title='실재고량' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='GAP_STOCK_QTY' title='실재고차' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='MAIN_IN_QTY' title='본사이관(입고)' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='SELF_IN_QTY' title='자체이관(입고)' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='HOME_IN_QTY' title='홈로리(입고)' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='MAIN_OUT_QTY' title='본사이관(출고)' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='SELF_OUT_QTY' title='자체이관(출고)' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='HOME_OUT_QTY' title='홈로리(출고)' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='LST_STOCK_AMT' title='당기 재고자산' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='WORK_CD' visible={false} />
        <DataColumn fieldName='GDS_TYPE' visible={false} />
    </DataGrid>
    )
}

const Funds = ({ condition }) => {

return (
    <DataGrid
        title='자금관리대장'
        selectProc='SLT_FUND_REPORT'
        selectParam={condition}
        selectionMode='single'
        totalGroup={['ACC_TYPE']}
        totalSort={(a, b) => a['ACC_TYPE'] > b['ACC_TYPE'] ? 1 : -1}
        horizonMode
        excelDownload
        sortable
        filterable
    >
        <DataColumn fieldName='ACC_NM' total={(values, data, group) => group ? '(소계) ' + accType[group['ACC_TYPE']] : '(합계)'} />
        <DataColumn fieldName='ACC_CD' visible={false} />
        <DataColumn fieldName='BEF_BAL' title='전기잔액' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='CUR_IN_AMT' title='수입' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='CUR_OUT_AMT' title='지출' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='CUR_BAL' title='잔액' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='ACC_TYPE' visible={false} />
    </DataGrid>
    )
}

const balanceStyle = makeStyles(() => ({
    total: {
        backgroundColor: '#a3a9d9 !important'
    }
}))

const Balance = ({ condition }) => {
    const classes = balanceStyle();
    const getCellProps = (row, fieldName) => {
        if(String(row['COLOR_TYPE']) === '2')
            return { className: classes.total }
    };

    return (
    <DataGrid
        title='계좌 잔액'
        selectProc='SLT_BANKBOOK_BALANCE_REPORT'
        selectParam={condition}
        selectionMode='single'
        cellProps={getCellProps}    
        horizonMode
        excelDownload
        sortable
        filterable
    >
        <DataColumn fieldName='BBK_NM' title='통장명' />
        <DataColumn fieldName='AMOUNT' title='잔액'  />
        <DataColumn fieldName='BBK_CD' visible={false} />
        <DataColumn fieldName='REMARK' visible={false} />
        <DataColumn fieldName='COLOR_TYPE' visible={false} />
        <DataColumn fieldName='SEQ' visible={false} />
    </DataGrid>
    )
}

const useCstCreditStyles = makeStyles(theme => ({
    total: {
        backgroundColor: '#a3a9d9 !important',
    }
}));

const CstCredit = ({ condition }) => {
    const classes = useCstCreditStyles();
    const [gridParam, setGridParam] = useState(null);

    useEffect(() => {
        condition && setGridParam({ ...condition, WORK_CD: 'ALL' });
    }, [condition]);

    const getCellProps = row => {
        let className;
        switch (row['SEQ']) {
            case 2:
            case 3:
            case 4:
                className = classes.total;
                break;
            default:
                break;
        }
        return { className };
    }
    
    return (
        <DataGrid
            title='채권잔액'
            selectProc='SLT_BONDPAY_ALL_REPORT'
            selectParam={gridParam}
            selectionMode='single'
            cellProps={getCellProps}
            horizonMode
            excelDownload
            virtualized
            sortable
            filterable
        >
            <DataColumn fieldName='SEQ' visible={false} />
            <DataColumn fieldName='BEF_BAL' title='전기 매출잔액' />
            <DataColumn fieldName='CUR_SALE_AMT' title='당기 매출' />
            <DataColumn fieldName='CUR_IN_AMT' title='당기 입금' />
            <DataColumn fieldName='CUR_COMM_AMT' title='당기 카드수수료' />
            <DataColumn fieldName='CUR_IN_SUM_AMT' title='합계금액' />
            <DataColumn fieldName='CUR_BAL' title='당기 매출잔액' />
        </DataGrid>
    )
}

const Violation = ({ condition }) => {

    return (
    <DataGrid
        title='위배 채권'
        selectProc='SLT_CST_VIOLATION_REPORT'
        selectParam={condition}
        selectionMode='single'
        horizonMode
        excelDownload
        sortable
        filterable
    >
        <DataColumn fieldName='TRD_STND' title='거래 조건' />
        <DataColumn fieldName='BOND_AMT' title='채권 잔액' />
        <DataColumn fieldName='OVER_AMOUNT' title='위배 금액' />
        <DataColumn fieldName='OVER_DATE' title='위배 일수' />
        <DataColumn fieldName='CST_CD' visible={false} />
        <DataColumn fieldName='WORK_CD' visible={false} />
    </DataGrid>
    )
}

const useFinanStyles = makeStyles(theme => ({
    contentRight: {
        flex: 2,
        marginLeft: theme.spacing(1),
    },
    contentLeft: {
        flex: 1,
        marginRight: theme.spacing(1),
    },
    mainTitle: {
        fontWeight: 'bold',
        backgroundColor: '#f1f2f4 !important',
    },
    title: {
        fontWeight: 'bold',
    },
}));

const Financial = ({ condition }) => {
    const classes = useFinanStyles();
    const [gridParam, setGridParam] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {
        condition && setGridParam({ ...condition, WORK_CD: 'ALL', LOAD_TYPE: 1 });
    }, [condition])

    const getCellProps = row => {
        let className;
        switch (row['SEQ']) {
            case 0:
                className = classes.mainTitle;
                break;
            case 1:
            case 3:
            case 4:
                className = classes.title;
                break;
            case 2:
                className = classes.subTitle;
                break;
            default:
                break;
        }
        return { className };
    }

    return (
        <SplitPanel secondaryInitialSize={50}>
            <DataGrid
                className={classes.contentLeft}
                title='재무상태표'
                selectProc='SLT_FINANCIAL_STATEMENT_REPORT'
                selectParam={gridParam}
                selectionMode='single'
                onSelect={row => setSelectedRow({ ...row, ...condition })}
                cellProps={getCellProps}
                horizonMode
                excelDownload
                sortable
                filterable
            >
                <DataColumn fieldName='SEQ' visible={false} />
                <DataColumn fieldName='CLASS_NM' title='구분' />
                <DataColumn fieldName='DEBTOR' visible={false} />
                <DataColumn fieldName='CREDITOR' visible={false} />
                <DataColumn fieldName='BAL_AMT' title='기간 잔액' />
                <DataColumn fieldName='WORK_CD' visible={false} />
                <DataColumn fieldName='ACC_CD' visible={false} />
            </DataGrid>

            <DataGrid
                className={classes.contentRight}
                title={selectedRow ? selectedRow['CLASS_NM'] !== undefined ? selectedRow['CLASS_NM'] + ' 내역' : '내역' : ' 내역'}
                selectProc='SLT_ACCOUNTJOURNAL_DETAIL_REPORT'
                selectParam={selectedRow}
                selectionMode='single'
                cellProps={getCellProps}
                horizonMode
                virtualized
                excelDownload
                sortable
                filterable
            >
                <DataColumn fieldName='BAL_AMT' title='잔액' />
            </DataGrid>
        </SplitPanel>
    )
}


const useProfitLossStyles = makeStyles(theme => ({
    contentRight: {
        flex: 2,
        marginLeft: theme.spacing(1),
    },
    contentLeft: {
        flex: 1,
        marginRight: theme.spacing(1),
    },
    title: {
        fontWeight: 'bold',
        '--cell-color': '#f1f2f4 !important',
    },
    detailTotal: {
        backgroundColor: '#a3a9d9 !important'
    }
}));

const ProfitLoss = ({condition}) => {
    const classes = useProfitLossStyles();
    const [data, setData] = useState();
    const [workData, setWorkData] = useState();
    const [gridParam, setGridParam] = useState(null);
    const [selectedRow, setSelectedRow] = useState({});

    useEffect(() => {
        if (condition) {
            setGridParam({ ...condition, LOAD_TYPE: 1 });
            setSelectedRow({});
        }
    }, [condition])

    const getCellProps = (row, fieldName) => {
        if (row['SEQ'] === 1) {
            return { className: classes.title };
        }

        switch (fieldName) {
            case 'SEQ':
            case 'CLASS_CD':
            case 'ACC_NM':
                break;
            default:
                return {
                    onClick: () => setSelectedRow({
                        ...row,
                        ...condition,
                        'WORK_CD': fieldName
                    })
                }
        }
    };

    const getDetailCellProps = (row, fieldName) => {
        if (row['SUMUP'] === '소계') {
            return { className: classes.detailTotal };
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const workData = await callProc('SLT_WORKPLACEINFO_AUTH');
            setWorkData(workData.data.reduce((result, item) => ({
                ...result,
                [item['WORK_CD']]: item['WORK_NM']
            }), {}))
        }
        condition && fetchData();
    }, [condition])

    useEffect(() => {
        const fetchData = async () => {
            setData(null);

            const data = await callProc('SLT_PROFIT_LOSS_REPORT', gridParam);
            setData(data);
        }
        gridParam && fetchData();
    }, [gridParam]);

    return (
        <SplitPanel secondaryInitialSize={20}>
            {(!data || !workData) ? <ProgressPanel /> :
            <DataGrid
                className={classes.contentLeft}
                title='손익계산서'
                dataSet={data}
                selectionMode='single'
                cellProps={getCellProps}
                fixedColumns={['ACC_CD', 'ACC_NM']}
                horizonMode
                excelDownload
                sortable
                filterable
            >
                <DataColumn fieldName='SEQ' visible={false} />
                <DataColumn fieldName='CLASS_CD' visible={false} />
                <DataColumn fieldName='ACC_CD' visible={false} />

                {data && data.fields.map((fieldName, idx) => fieldName !== 'SEQ' && fieldName !== 'CLASS_CD' && fieldName !== 'ACC_NM' &&
                    <DataColumn
                        key={fieldName}
                        fieldName={fieldName}
                        title={fieldName === 'TOTAL' ? workData[data.fields[idx + 1]] + ' 통합' : workData[fieldName]}
                    />
                )}
                
            </DataGrid>}
            <DataGrid
                className={classes.contentRight}
                cellProps={getDetailCellProps}
                title={selectedRow ? selectedRow['ACC_NM'] !== undefined ? 
                        `${workData[selectedRow['WORK_CD']] || '전체'} ${selectedRow['ACC_NM']}  내역` : '내역' : ' 내역'}
                selectProc='SLT_PROFIT_LOSS_DETAIL_REPORT'
                selectParam={selectedRow}
                selectionMode='single'
                horizonMode
                virtualized
                excelDownload
                sortable
                filterable
            >
                <DataColumn
                    fieldName='BAL_AMT'
                    title='잔액'
                />
            </DataGrid>
        </SplitPanel>
    )
}

export default BusinessReport