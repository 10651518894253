import React from 'react';
import { Document, Link, StyleSheet, Text, View} from '@react-pdf/renderer';
import { PdfCell, PdfTable, PdfRow, PdfPage } from '../component';
import { numberFormat } from '../common/Utils';

const titleBackColor = '#EFEFEF';

const styles = StyleSheet.create({
    titleSort : {
        alignSelf: 'left',
        fontSize : 12,
        marginBottom : 0,
    },
    docContent : {
        display: 'flex',
        flexDirection: 'row',
        fontSize: 9,
        marginTop: 8,
        justifyContent: 'space-between',
    },
    subTitle : {
        fontSize: 10,
        marginTop: 8,
    },
    tableContent : {
        marginTop: 8,
        border: 1,
    },
    headerCell: {
        fontSize: 10,
        height: 20,
        justifyContent: 'center',
        // borderRight: 0,
        border: 0,
        backgroundColor: titleBackColor,
    },
    contentCell: {
        fontSize: 9,
        height: 20,
        justifyContent: 'center',
        // borderRight: 0,
        border: 0,
    },
    remarkTitle: {
        height: 20, 
        // border: 1,
        justifyContent: 'center',
        backgroundColor: titleBackColor,
    },
    remarkContainer: {
        border: 0,
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
    },
    remarkContent : {
        marginBottom: 4,
        fontSize: 9,
        whiteSpace: 'pre',
        
    },
    proDataHeaderCenter: { //processData
        display: 'flex', 
        flex: 'none', 
        fontSize: 7.5,
        height: 15,
        justifyContent: 'center',
        // borderRight: 0,
        border: 0,
        backgroundColor: titleBackColor,
    },
    proDataHeaderLeft: { //processData
        display: 'flex', 
        flex: 'none', 
        fontSize: 7.5,
        height: 15,
        justifyContent: 'center',
        textAlign: 'left',
        // borderRight: 0,
        border: 0,
        backgroundColor: titleBackColor,
    },
    proDataHeaderRight: { //processData
        display: 'flex', 
        flex: 'none', 
        fontSize: 7.5,
        height: 15,
        justifyContent: 'center',
        textAlign: 'right',
        // borderRight: 0,
        border: 0,
        backgroundColor: titleBackColor,
    },
    proDataContentCellCenter: {
        display: 'flex', 
        flex: 'none', 
        fontSize: 7.5,
        paddingTop: 3,
        paddingBottom: 3,
        // height: 15,
        justifyContent: 'center',
        // borderRight: 0,
        border: 0,
    },
    proDataContentCellLeft: {
        display: 'flex', 
        flex: 'none', 
        fontSize: 7.5,
        paddingTop: 3,
        paddingBottom: 3,
        // height: 15,
        justifyContent: 'center',
        textAlign: 'left',
        // borderRight: 0,
        border: 0,
    },
    proDataContentCellRight: {
        display: 'flex', 
        flex: 'none', 
        fontSize: 7.5,
        paddingTop: 3,
        paddingBottom: 3,
        // height: 15,
        justifyContent: 'center',
        textAlign: 'right',
        // borderRight: 0,
        border: 0,
    },
    pageNumber: {
        position: 'absolute',
        bottom: 15,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
    fileContainer: {
        flexDirection: 'row',
        marginTop: 16,
    },
    fileLink: {
        marginLeft: 4,
        marginBottom: 8,
    }
})

const EdocumentStatePdf = ({ data, lineData, lineRef, listData, efmsData, edocAccData, remarkData, fileData, fileKey }) => {

    const getTotal = (data, field) => (
        numberFormat(data.reduce((result, item) => result + item[field], 0))
    )
        
    return (
        <Document title={`전자결재_${data['REGDATE'].replace(/-/g, '')}_${data['DOC_CD']}`}>
            <PdfPage>
                <View style={styles.titleSort}>
                    <Text>{`${data['DOC_TITLE']}`}</Text>
                    <View style={styles.docContent}>
                        <Text>{`작성자 : ${data['DEP_NM'] || ''} / ${data['EMP_NM'] || ''} ${data['POSITION'] || ''} ${data['REGDTTM']}`}</Text>
                        <Text>{`문서번호 : ${data['DOC_CD']}`}</Text>
                    </View>
                </View>
                <View style={styles.subTitle}>
                    <Text>{`[결재 정보]`}</Text>
                </View>
                <View style={styles.tableContent}>
                    <PdfTable>
                        <PdfRow>
                            <PdfCell cellTop cellLeft style={{...styles.headerCell}}>분류</PdfCell>
                            <PdfCell cellTop style={{...styles.headerCell}}>부서</PdfCell>
                            <PdfCell cellTop style={{...styles.headerCell}}>성명</PdfCell>
                            <PdfCell cellTop style={{...styles.headerCell}}>상태</PdfCell>
                            <PdfCell cellTop style={{...styles.headerCell, borderRight: 1}}>결재일시</PdfCell>
                        </PdfRow>
                        {lineData && lineData.map((item, idx) => (
                            <PdfRow key={idx}>
                                <PdfCell cellLeft style={{ ...styles.contentCell }}>{item['LINE_TYPE']}</PdfCell>
                                <PdfCell style={{ ...styles.contentCell }}>{item['DEP_NM']}</PdfCell>
                                <PdfCell style={{ ...styles.contentCell }}>{item['EMP_NM']}</PdfCell>
                                <PdfCell style={{ ...styles.contentCell }}>{item['CONFM_TYPE']}</PdfCell>
                                <PdfCell style={{ ...styles.contentCell}}>{item['PRO_DTTM']}</PdfCell>
                            </PdfRow>
                        ))}
                    </PdfTable>
                </View>
                <View style={styles.subTitle}>
                    <Text>{`[참조자]`}</Text>
                </View>
                <View style={styles.tableContent}>
                    <View style={styles.remarkTitle}>
                        <Text style={{marginLeft: 16, fontSize: 10}}>참조자</Text>
                    </View>
                    <View style={styles.remarkContainer}>
                        {!lineRef.length ?
                        <Text style={styles.remarkContent}>참조자 없음</Text> :
                        <View>
                            <Text style={styles.remarkContent}>
                                {`${lineRef[0]['REF_NM']}`}
                            </Text>
                        </View>
                        }
                    </View>
                </View>
                <View style={styles.subTitle}>
                    <Text>{`[참고 사항]`}</Text>
                </View>
                <View style={styles.tableContent}>
                    <View style={styles.remarkTitle}>
                        <Text style={{marginLeft: 16, fontSize: 10}}>내용</Text>
                    </View>
                    <View style={styles.remarkContainer}>
                        {!remarkData.length ?
                        <Text style={styles.remarkContent}>내용 없음</Text> :
                        remarkData.map(item => (
                            <View key={item['SEQ']}>
                                <Text style={styles.remarkContent}>
                                    {`${item['DEP_NM']} / ${item['POSITION']} / ${item['EMP_NM']} / ${item['EDTDATE']}`}
                                </Text>
                                <Text style={styles.remarkContent}>
                                    {`- ${item['REMARK']}`}
                                </Text>
                            </View>
                        ))}
                    </View>
                </View>
                <View style={{marginTop: 16, height: 20, fontSize: 15, alignSelf: 'center', justifyContent: 'center'}}>
                    <Text>결재 항목</Text>
                </View>
                <View style={styles.subTitle}>
                    <Text>{`1. 매입/매출`}</Text>
                </View>
                <View style={styles.tableContent}>
                    <PdfTable>
                        <PdfRow>
                            <PdfCell cellTop cellLeft style={{...styles.proDataHeaderCenter, width: 20}}>번호</PdfCell>
                            <PdfCell cellTop style={{...styles.proDataHeaderLeft, width: 50}}>유형</PdfCell>
                            <PdfCell cellTop style={{...styles.proDataHeaderLeft, width: 20}}>구분</PdfCell>
                            <PdfCell cellTop style={{...styles.proDataHeaderLeft, width: 20}}>대상</PdfCell>
                            <PdfCell cellTop style={{...styles.proDataHeaderLeft, width: 50}}>계정과목</PdfCell>
                            <PdfCell cellTop style={{...styles.proDataHeaderCenter, width: 40}}>발행(생)일</PdfCell>
                            <PdfCell cellTop style={{...styles.proDataHeaderLeft, flex: 1}}>거래처</PdfCell>
                            <PdfCell cellTop style={{...styles.proDataHeaderLeft, width: 80}}>상품</PdfCell>
                            <PdfCell cellTop style={{...styles.proDataHeaderRight, width: 30}}>단가</PdfCell>
                            <PdfCell cellTop style={{...styles.proDataHeaderRight, width: 20}}>수량</PdfCell>
                            <PdfCell cellTop style={{...styles.proDataHeaderRight, width: 45}}>공급가액</PdfCell>
                            <PdfCell cellTop style={{...styles.proDataHeaderRight, width: 45}}>합계금액</PdfCell>
                        </PdfRow>
                        {listData && listData.map((item, idx) => (
                            <PdfRow key={idx}>
                                <PdfCell cellLeft style={{ ...styles.proDataContentCellCenter, width: 20 }}>{item['SEQ']}</PdfCell>
                                <PdfCell style={{ ...styles.proDataContentCellLeft, width: 50 }}>{item['PRO_TYPE']}</PdfCell>
                                <PdfCell style={{ ...styles.proDataContentCellLeft, width: 20 }}>{item['IO_TYPE']}</PdfCell>
                                <PdfCell style={{ ...styles.proDataContentCellLeft, width: 20 }}>{item['WORK_SHORT_NM']}</PdfCell>
                                <PdfCell style={{ ...styles.proDataContentCellLeft, width: 50 }}>{item['ACC_NM']}</PdfCell>
                                <PdfCell style={{ ...styles.proDataContentCellCenter, width: 40 }}>{item['ISSUE_DATE'] != null ? item['ISSUE_DATE'].replace(/-/g, '') : ''}</PdfCell>
                                <PdfCell style={{ ...styles.proDataContentCellLeft, flex: 1 }}>{item['CST_NM']}</PdfCell>
                                <PdfCell style={{ ...styles.proDataContentCellLeft, width: 80 }}>{item['GDS_NM']}</PdfCell>
                                <PdfCell style={{ ...styles.proDataContentCellRight, width: 30 }}>{item['PRICE']}</PdfCell>
                                <PdfCell style={{ ...styles.proDataContentCellRight, width: 20 }}>{numberFormat(item['QTY'])}</PdfCell>
                                <PdfCell style={{ ...styles.proDataContentCellRight, width: 45 }}>{numberFormat(item['SPLY_AMT'])}</PdfCell>
                                <PdfCell style={{ ...styles.proDataContentCellRight, width: 45}}>{numberFormat(item['AMOUNT'])}</PdfCell>
                            </PdfRow>
                        ))}
                        <PdfRow>
                            <PdfCell cellLeft style={{ ...styles.proDataContentCellCenter, width: 20 }}></PdfCell>
                            <PdfCell style={{ ...styles.proDataContentCellLeft, width: 50 }}>(소 계)</PdfCell>
                            <PdfCell style={{ ...styles.proDataContentCellLeft, width: 20 }}></PdfCell>
                            <PdfCell style={{ ...styles.proDataContentCellLeft, width: 20 }}></PdfCell>
                            <PdfCell style={{ ...styles.proDataContentCellLeft, width: 50 }}></PdfCell>
                            <PdfCell style={{ ...styles.proDataContentCellCenter, width: 40 }}></PdfCell>
                            <PdfCell style={{ ...styles.proDataContentCellLeft, flex: 1 }}></PdfCell>
                            <PdfCell style={{ ...styles.proDataContentCellLeft, width: 80 }}></PdfCell>
                            <PdfCell style={{ ...styles.proDataContentCellRight, width: 30 }}></PdfCell>
                            <PdfCell style={{ ...styles.proDataContentCellRight, width: 20 }}>{getTotal(listData, 'QTY')}</PdfCell>
                            <PdfCell style={{ ...styles.proDataContentCellRight, width: 45 }}>{getTotal(listData, 'SPLY_AMT')}</PdfCell>
                            <PdfCell style={{ ...styles.proDataContentCellRight, width: 45 }}>{getTotal(listData, 'AMOUNT')}</PdfCell>
                        </PdfRow>
                    </PdfTable>
                </View>
                <View style={styles.subTitle}>
                    <Text>{`2. eFMS - 입/출금`}</Text>
                </View>
                <View style={styles.tableContent}>
                    <PdfTable>
                        <PdfRow>
                            <PdfCell cellTop cellLeft style={{...styles.proDataHeaderCenter, width: 20}}>번호</PdfCell>
                            <PdfCell cellTop style={{...styles.proDataHeaderLeft, width: 50}}>유형</PdfCell>
                            <PdfCell cellTop style={{...styles.proDataHeaderLeft, width: 20}}>구분</PdfCell>
                            <PdfCell cellTop style={{...styles.proDataHeaderLeft, width: 20}}>대상</PdfCell>
                            <PdfCell cellTop style={{...styles.proDataHeaderLeft, width: 90}}>입/출금 계좌 정보</PdfCell>
                            <PdfCell cellTop style={{...styles.proDataHeaderCenter, width: 65}}>출금통장표시</PdfCell>
                            <PdfCell cellTop style={{...styles.proDataHeaderCenter, width: 45}}>입출금일</PdfCell>
                            <PdfCell cellTop style={{...styles.proDataHeaderLeft, width: 55}}>신용카드</PdfCell>
                            <PdfCell cellTop style={{...styles.proDataHeaderLeft, flex: 1}}>적요</PdfCell>
                            <PdfCell cellTop style={{...styles.proDataHeaderRight, width: 45, borderRight: 1}}>출금액</PdfCell>
                        </PdfRow>
                        {efmsData && efmsData.map((item, idx) => (
                            <PdfRow key={idx}>
                                <PdfCell cellLeft style={{ ...styles.proDataContentCellCenter, width: 20 }}>{item['SEQ']}</PdfCell>
                                <PdfCell style={{ ...styles.proDataContentCellLeft, width: 50 }}>{item['PRO_TYPE']}</PdfCell>
                                <PdfCell style={{ ...styles.proDataContentCellLeft, width: 20 }}>{item['IO_TYPE']}</PdfCell>
                                <PdfCell style={{ ...styles.proDataContentCellLeft, width: 20 }}>{item['WORK_SHORT_NM']}</PdfCell>
                                <PdfCell style={{ ...styles.proDataContentCellLeft, width: 90 }}>{item['INOUT_BBKNO']}</PdfCell>
                                <PdfCell style={{ ...styles.proDataContentCellCenter, width: 65 }}>{item['OUT_REMARK']}</PdfCell>
                                <PdfCell style={{ ...styles.proDataContentCellCenter, width: 45 }}>{item['INOUT_DATE'] != null ? item['INOUT_DATE'].replace(/-/g, '') : ''}</PdfCell>
                                <PdfCell style={{ ...styles.proDataContentCellLeft, width: 55 }}>{item['CRT_NM']}</PdfCell>
                                <PdfCell style={{ ...styles.proDataContentCellLeft, flex: 1 }}>{item['SUMUP']}</PdfCell>
                                <PdfCell style={{ ...styles.proDataContentCellRight, width: 45, borderRight: 1 }}>{numberFormat(item['OUT_AMOUNT'])}</PdfCell>
                            </PdfRow>
                        ))}
                        <PdfRow >
                            <PdfCell cellLeft style={{ ...styles.proDataContentCellCenter, width: 20 }}></PdfCell>
                            <PdfCell style={{ ...styles.proDataContentCellLeft, width: 50 }}>(소 계)</PdfCell>
                            <PdfCell style={{ ...styles.proDataContentCellLeft, width: 20 }}></PdfCell>
                            <PdfCell style={{ ...styles.proDataContentCellLeft, width: 20 }}></PdfCell>
                            <PdfCell style={{ ...styles.proDataContentCellLeft, width: 90 }}></PdfCell>
                            <PdfCell style={{ ...styles.proDataContentCellCenter, width: 65 }}></PdfCell>
                            <PdfCell style={{ ...styles.proDataContentCellCenter, width: 45 }}></PdfCell>
                            <PdfCell style={{ ...styles.proDataContentCellLeft, width: 55 }}></PdfCell>
                            <PdfCell style={{ ...styles.proDataContentCellLeft, flex: 1 }}></PdfCell>
                            <PdfCell style={{ ...styles.proDataContentCellRight, width: 45, borderRight: 1 }}>{getTotal(efmsData, 'OUT_AMOUNT')}</PdfCell>
                        </PdfRow>
                    </PdfTable>
                </View>
                {edocAccData.length === 0 ? null : 
                <View style={styles.subTitle}>
                    <Text>{`3. 분개 항목`}</Text>
                </View>}
                {edocAccData.length === 0 ? null : 
                <View style={styles.tableContent}>
                    <PdfTable>
                        <PdfRow>
                            <PdfCell cellTop cellLeft style={{...styles.proDataHeaderCenter, width: 20}}>번호</PdfCell>
                            <PdfCell cellTop style={{...styles.proDataHeaderLeft, width: 50}}>유형</PdfCell>
                            <PdfCell cellTop style={{...styles.proDataHeaderLeft, width: 20}}>구분</PdfCell>
                            <PdfCell cellTop style={{...styles.proDataHeaderLeft, width: 20}}>대상</PdfCell>
                            <PdfCell cellTop style={{...styles.proDataHeaderLeft, width: 50}}>계정과목</PdfCell>
                            <PdfCell cellTop style={{...styles.proDataHeaderLeft, flex: 1}}>거래처</PdfCell>
                            <PdfCell cellTop style={{...styles.proDataHeaderRight, width: 50}}>차변</PdfCell>
                            <PdfCell cellTop style={{...styles.proDataHeaderRight, width: 50}}>대변</PdfCell>
                            <PdfCell cellTop style={{...styles.proDataHeaderLeft, flex: 1, paddingLeft: 8, borderRight: 1}}>적요</PdfCell>
                        </PdfRow>
                        {edocAccData && edocAccData.map((item, idx) => {
                            const prevItem = edocAccData[idx - 1];
                            const nextItem = edocAccData[idx + 1];
                            const isEqualPrev = prevItem && item['SEQ'] === prevItem['SEQ'];
                            const isEqualNext = nextItem && item['SEQ'] === nextItem['SEQ'];
                            let totalRow;
                            if (!isEqualNext) {
                                const groupData = edocAccData.reduce((result, row) => (
                                    row['SEQ'] === item['SEQ'] ?
                                        [...result, row] :
                                        result
                                ), [])
                                totalRow = (
                                    <PdfRow key={'s' + idx}>
                                        <PdfCell style={{...styles.proDataContentCellCenter, width: 20}} /> 
                                        <PdfCell style={{ ...styles.proDataContentCellLeft, width: 50 }} />
                                        <PdfCell style={{ ...styles.proDataContentCellLeft, width: 20 }} />
                                        <PdfCell style={{ ...styles.proDataContentCellLeft, width: 20 }} />
                                        <PdfCell style={{ ...styles.proDataContentCellLeft, width: 50 }}>
                                            (소 계)
                                        </PdfCell>
                                        <PdfCell style={{ ...styles.proDataContentCellLeft, flex: 1 }} />
                                        <PdfCell style={{ ...styles.proDataContentCellRight, width: 50 }}>
                                            {getTotal(groupData, 'DEBTOR')}
                                        </PdfCell>
                                        <PdfCell style={{ ...styles.proDataContentCellRight, width: 50 }}>
                                            {getTotal(groupData, 'CREDITOR')}
                                        </PdfCell>
                                        <PdfCell style={{ ...styles.proDataContentCellLeft, flex: 1, paddingLeft: 8, borderRight: 1 }} />
                                    </PdfRow>
                                )
                            }
                            return([
                                <PdfRow key={idx}>
                                    <PdfCell cellLeft style={{ ...styles.proDataContentCellCenter, width: 20 }}>
                                        {!isEqualPrev && item['SEQ']}
                                    </PdfCell>
                                    <PdfCell style={{ ...styles.proDataContentCellLeft, width: 50 }}>
                                        {!isEqualPrev && item['PRO_TYPE']}
                                    </PdfCell>
                                    <PdfCell style={{ ...styles.proDataContentCellLeft, width: 20 }}>
                                        {!isEqualPrev && item['IO_TYPE']}
                                    </PdfCell>
                                    <PdfCell style={{ ...styles.proDataContentCellLeft, width: 20 }}>
                                        {!isEqualPrev && item['WORK_SHORT_NM']}
                                    </PdfCell>
                                    <PdfCell style={{ ...styles.proDataContentCellLeft, width: 50 }}>{item['ACC_NM']}</PdfCell>
                                    <PdfCell style={{ ...styles.proDataContentCellLeft, flex: 1 }}>{item['CST_NM']}</PdfCell>
                                    <PdfCell style={{ ...styles.proDataContentCellRight, width: 50 }}>{numberFormat(item['DEBTOR'])}</PdfCell>
                                    <PdfCell style={{ ...styles.proDataContentCellRight, width: 50 }}>{numberFormat(item['CREDITOR'])}</PdfCell>
                                    <PdfCell style={{ ...styles.proDataContentCellLeft, flex: 1, paddingLeft: 8, borderRight: 1 }}>{item['SUMUP']}</PdfCell>
                                </PdfRow>,
                                totalRow
                            ])
                        })}
                    </PdfTable>
                </View>}
                {fileData && fileData.length > 0 &&
                <View style={styles.fileContainer}>
                    <Text>
                        [첨부 파일]
                    </Text>
                    <View>
                        {fileData.map(item =>
                            <Link
                                key={item.seq}
                                style={styles.fileLink}
                                src={`${document.URL}api/file_down?fileKey=${fileKey}&seq=${item.seq}`}
                            >
                                {item.name}
                            </Link>
                        )}
                    </View>
                </View>}
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </PdfPage>
        </Document>
    )
}

export default EdocumentStatePdf;