import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { makeStyles, Paper } from '@material-ui/core';
import { callProc } from '../../common/DBConnector';
import { ComboBox, DatePicker, DataGrid, DataColumn, NumberField, DateTimePicker, IconButton, ComboSearch, PaperTitle, TextField, ProgressDialog } from '../../component';
import { useFieldInputs } from '../../common/Utils';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import CheckIcon from '@material-ui/icons/Check';
import CachedIcon from '@material-ui/icons/Cached';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
    info: {
        flex: 1,
        marginRight: theme.spacing(1),
    },
    input: {
        width: 300,
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(1),
    },
    searchPaper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    searchContainer: {
        flex: 1,
        display: 'inline-block',
        flexDirection: 'row',
    },
    searchItem: {
        display: 'inline-block',
        width: 200,
        marginRight: 100,
        marginBottom: theme.spacing(1),
    },
}))

const InboundCalcEnroll = () => {
    const clsState = useSelector(state => state.clsDateReducer);
    const [editRow, setEditRow] = useState();
    const [input, setInput] = useFieldInputs({
        WORK_CD: sessionStorage['WORK_CD'] === 'ALL' ? null : sessionStorage['WORK_CD'],
        SHIP_DATE: clsState[sessionStorage['WORK_CD']],
    });
    const condition = useMemo(() => (input['WORK_CD'] && input['SHIP_DATE']) ? input : null, [input]);
    
    const classes = useStyles();

    const gridRefresh = () => {
        setInput({ type: 'UPDATE', value: { ...input } });
    }

    const onUpdateStart = row => {
        setEditRow({...row});
        return false;
    }

    return (
        <div className={classes.container}>
            <Paper className={classes.searchPaper}>
                <div className={classes.searchContainer}>
                    <div className={classes.searchItem}>
                        <ComboBox
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={condition && condition['WORK_CD']}
                            onChange={value => {
                                if (input['WORK_CD'] !== value) {
                                    sessionStorage['WORK_CD'] = value;
                                    setInput('SHIP_DATE', clsState[value]);
                                    setInput('WORK_CD', value);
                                }
                            }}
                        />
                    </div>
                    <div className={classes.searchItem}>
                        <DatePicker
                            label='영업일'
                            minDate={clsState[input['WORK_CD']]}
                            value={condition && condition['SHIP_DATE']}
                            onChange={value => {
                                if (input['SHIP_DATE'] !== value) {
                                    setInput('SHIP_DATE', value);
                                    if (condition && condition['SHIP_DATE'] < clsState[condition['WORK_CD']]) {
                                        setInput({ type: 'UPDATE', value: { ...input, 'SHIP_DATE': value } });
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                <IconButton
                    tooltip=''
                    icon={<SearchIcon />}
                    onClick={gridRefresh}
                />
            </Paper>
            <div className={classes.content}>
                <div className={classes.info}>
                    <DataGrid
                        title='실입고 정산'
                        selectProc='SLT_ORDERINFO_INBOUNDCALC'
                        updateProc=''
                        selectParam={condition}
                        onUpdateStart={onUpdateStart}
                        selectionMode='single'
                        horizonMode
                        virtualized
                    >
                        <DataColumn fieldName='QTY' title='오더수량' />
                        <DataColumn fieldName='CALC_QTY' title='정산수량' />
                        <DataColumn fieldName='BIZ_DATE' title='오더일자' />
                        <DataColumn fieldName='BUY_CST_NM' title='매입처' />
                        <DataColumn fieldName='SHIP_TRN_NM' title='출하처' />
                        <DataColumn fieldName='REAL_DEST_NM' title='도착지' />
                        <DataColumn fieldName='SHIP_DATE' title='정산시간' />
                        <DataColumn fieldName='REAL_WORK_CD' title='도착지사업장' visible={false} />
                    </DataGrid>
                </div>
                <div className={classes.input}>
                <EnrollPanel
                    condition={condition}
                    editRow={editRow}
                    gridRefresh={gridRefresh}
                    clsState={clsState}
                />
                </div>
            </div>
        </div>
    )
}

const usePanelStyles = makeStyles((theme) => ({
    panelContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: 0,
    },
    panelContent: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        overflowY: 'auto',
        '& > *': {
            marginBottom: theme.spacing(2),
        },
    },
    btnContainer: {
        alignSelf: 'flex-end',
        padding: theme.spacing(1),
    },
}))

const EnrollPanel = ({ condition, editRow, gridRefresh, clsState }) => {
    const classes = usePanelStyles();
    const [editState, setEditState] = useState(0);
    const [befValue, setBefValue] = useState();
    const [gdsParam, setGdsParam] = useState();
    const [tnkParam, setTnkParam] = useState();
    const { enqueueSnackbar } = useSnackbar();

    const [insData, setInsData] = useFieldInputs({
        WORK_CD: condition && condition['WORK_CD'],
        SHIP_DATE: condition && moment(condition['SHIP_DATE']).format('YYYY-MM-DD 00:00:00'),
        CALC_QTY: 0,
        GDS_CD: null,
        TNK_NO: null,
    });
    
    useEffect(() => {
        const fetchData = () => {
            setInsData({ type: 'INIT' });
            setEditState(0);
            setGdsParam({
                WORK_CD: condition && condition['WORK_CD'],
                GDS_TYPE: 'ALL',
                NOT_TYPE: 0,
                GDS_TYPE_M: 'ALL',
                NOT_TYPE_M: 0,
                GAUGE_TYPE: 0,
                STA_DATE: condition && condition['SHIP_DATE'],
            });
            setInsData('WORK_CD', condition['WORK_CD']);
            setInsData('SHIP_DATE', moment(condition['SHIP_DATE']).format('YYYY-MM-DD 00:00:00'));
        }
        
        condition && fetchData();
    }, [condition, setInsData])

    const isValid = () => {
        if(String(insData['CALC_QTY']) === '0') {
            enqueueSnackbar('정산수량을 입력해 주세요.', { variant: 'error' });
            return false;
        }

        return true;
    }

    const [loading, setLoading] = useState(false);

    const onInsert = async() => {
        setLoading(true);

        if(isValid()){
            let befData = {...insData};
            delete befData.ORD_CD;
            delete befData.WORK_CD;
            delete befData.SHIP_DATE;
            setBefValue(befData);
            let procName;
            if(String(editState) === '0'){
                procName = ''
            } else {
                procName = 'INS_INBOUNDCALCINFO'
            }
            const result = await callProc(procName, {...insData, TSP_CHK: 2});
            if(!result.err) {
                enqueueSnackbar('저장이 완료되었습니다.');
                setInsData({ type: 'INIT' });
                setEditState(0);
                gridRefresh();
            }
        }

        setLoading(false);
    }

    useEffect(() => {
        const fetchData = () => {
            setInsData({ type: 'UPDATE', value: editRow })
            setEditState(1);
        }
        editRow && fetchData();
    }, [editRow, setInsData, setEditState])

    const onExitEdit = () => {
        setInsData({ type: 'INIT' });
        setEditState(0);
    }

    const getBefValue = () => {
        befValue && setInsData({ type: 'UPDATE', value: {...befValue} })
    }
    
    const gdsCd = insData && insData['GDS_CD'];
    useEffect(() => setTnkParam((prevTnkParam) => (
        gdsCd === prevTnkParam?.GDS_CD ? prevTnkParam : {
            WORK_CD: condition?.WORK_CD,
            BIZ_DATE: condition?.SHIP_DATE,
            GDS_CD: gdsCd
        }
    )), [condition, gdsCd])

    return (
        <Paper className={classes.panelContainer} >
            <ProgressDialog open={loading} />

            <PaperTitle>{'실입고 정산'}</PaperTitle>
            <div className={classes.panelContent}>
                {String(editState) === '1' &&
                <TextField
                    label='오더코드'
                    value={insData['ORD_CD']}
                    disabled
                />}
                <ComboBox
                    label='사업장'
                    selectProc='SLT_WORKPLACEINFO_AUTH'
                    keyField='WORK_CD'
                    valueField='WORK_NM'
                    value={insData['WORK_CD']}
                    disabled
                />
                <DateTimePicker
                    label='입고일시'
                    minDate={clsState[insData['WORK_CD']]}
                    value={insData['SHIP_DATE']}
                    onChange={value => setInsData('SHIP_DATE', value)}
                />
                {gdsParam &&
                <ComboSearch
                    label='상품'
                    selectProc='SLT_GAUGE_ITEM_GOODS_COMBO'
                    selectParam={gdsParam}
                    keyField='GDS_CD'
                    valueField='GDS_NM'
                    value={insData['GDS_CD']}
                    onChange={value => setInsData('GDS_CD', value)}
                    nullable
                    disabled
                />}
                <NumberField
                    label='오더수량'
                    value={insData['QTY']}
                    onChange={value => setInsData('QTY', value)}
                    disabled
                />
                <NumberField
                    label='정산수량'
                    value={insData['CALC_QTY']}
                    onChange={value => setInsData('CALC_QTY', value)}
                />
                {tnkParam &&
                <ComboSearch
                    label='탱크번호'
                    selectProc='SLT_TANKINFO_NOW_GOODS'
                    selectParam={tnkParam}
                    keyField='TNK_NO'
                    valueField='TNK_NM'
                    value={insData['TNK_NO']}
                    onChange={value => setInsData('TNK_NO', value)}
                />}
            </div>
            <div className={classes.btnContainer}>
                {String(editState) === '1' &&
                <IconButton
                    tooltip='취소'
                    icon={<CloseIcon />}
                    onClick={() => onExitEdit()}
                />}
                <IconButton
                    tooltip='이전값 불러오기'
                    icon={<CachedIcon />}
                    onClick={() => getBefValue()}
                    disabled={String(editState) === '0'}
                />
                <IconButton
                    tooltip='확인'
                    icon={<CheckIcon />}
                    onClick={() => onInsert()}
                    disabled={String(editState) === '0'}
                />
            </div>
        </Paper>
    )
}


export default InboundCalcEnroll;