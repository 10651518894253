import React, { useState } from 'react';
import moment from 'moment';
import { IconButton, makeStyles, Paper, Tooltip } from '@material-ui/core';
import { SearchPanel, SearchItem, ComboBox, DatePicker, DataGrid, PaperTitle, DataColumn, ProgressDialog, RadioGroup, RadioItem, TextField, ComboSearch } from '../../component';
import { isFieldDiff, useFieldInputs } from '../../common/Utils';
import CheckIcon from '@material-ui/icons/Check';
import { useSnackbar } from 'notistack';
import { callProc } from '../../common/DBConnector';

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
    info: {
        flex: 2,
        marginRight: theme.spacing(1),
    },
}))

const GdsTaxInvoiceEnroll = () => {
    const [condition, setCondition] = useState(null);
    const [selection, setSelection] = useState();

    const classes = useStyles();

    return(
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => setCondition(condition)}>
                <SearchItem
                    fieldName='WORK_CD'
                    component={(state, dispatch) =>(
                        <ComboBox
                            label='사업장명'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => {
                                dispatch(value);
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='CST_CD'
                    component={(state, setState, condition, setField) => {
                        let param = condition['CST_PARAM'] || null;
                        if (isFieldDiff(['WORK_CD', 'STA_DATE'], condition, param)) {
                            param = { 
                                WORK_CD: condition['WORK_CD'],
                                CST_TYPE: [1, 2],
                                CST_KIND: [1, 2],
                                STA_DATE: condition['STA_DATE'],
                            };
                            setField('CST_PARAM', param);
                        }

                        return (
                            <ComboSearch
                                label='거래처명'
                                selectProc='SLT_CUSTOMER_COMBO'
                                selectParam={param}
                                keyField='CST_CD'
                                valueField='CST_NM'
                                value={state}
                                onChange={(value) => setState(value)}
                            />
                        )
                    }}
                />
            </SearchPanel>
            <div className={classes.content}>
                <DataGrid
                    title='상품별 판매내역'
                    className={classes.info}
                    selectProc='SLT_SALEINFO_GDSTAX'
                    selectParam={condition}
                    selectionMode='multi'
                    onSelect={rows => setSelection(rows)}
                >
                    <DataColumn
                        fieldName='WORK_BIZNO'
                        visible={false}
                    />
                    <DataColumn
                        fieldName='PRICE'
                        title='평균단가'
                    />
                    <DataColumn
                        fieldName='QTY'
                        title='미발행 수량'
                    />
                    <DataColumn
                        fieldName='AMOUNT'
                        title='미발행 금액'
                    />
                    <DataColumn
                        fieldName='GDS_CD'
                        visible={false}
                    />
                </DataGrid>
                <EnrollPanel
                    condition={condition}
                    selection={selection}
                    refreshGrid={() => setCondition({ ...condition })}
                />
            </div>
        </div>
    )
}

const usePanelStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginLeft: theme.spacing(1),
    },
    paperContent: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding: theme.spacing(2),
        paddingTop: 0,
        overflowY: 'auto',
    },
    field: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(3),
    },
    chkButton: {
        marginTop: 'auto',
        alignSelf: 'flex-end',
    },
}))

const EnrollPanel = ({ condition, selection, refreshGrid }) => {
    const [insData, setInsData] = useFieldInputs({
        WRT_DATE: moment().format('YYYY-MM-DD'),
        TRD_DATE: moment().format('DD'),
        CST_DAY: moment().add(-1, 'month').endOf('month').format('YYYY-MM-DD'),
        TAX_TYPE: '01',
        ISSUE_TYPE: '1',
        PBL_TYPE: '02',
        ITM_NM: '',
        PRICE_TYPE: '1',
    });
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const classes = usePanelStyles();

    const hasValue = value => value != null && value.toString().trim() !== '';

    const valid = () => {
        if (!selection || selection.length === 0) {
            enqueueSnackbar('선택된 항목이 없습니다.', { variant: 'error' });
            return false;
        }
        if (selection.reduce((result, item) => result || !hasValue(item['WORK_BIZNO']), false)) {
            enqueueSnackbar('사업자 등록번호가 없는 거래처는 발행할 수 없습니다.', { variant: 'error' });
            return false;
        }
        return true;
    }

    const onInsert = async() => {
        setLoading(true);

        if (valid()) {
            const result = await callProc('INS_TAXINVOICEINFO_GDS',{
                ...condition,
                ...insData,
                SALE_JSON: selection.map(({ CAR_CD, GDS_CD, GDS_NM }) => ({ CAR_CD, GDS_CD, GDS_NM }))
            });
            if (!result.err) {
                refreshGrid();
                enqueueSnackbar('저장이 완료되었습니다.');
            }
        }

        setLoading(false);
    }

    const getFirstDay = value => value.substring(0, 8) + '01';

    const getLastDay = value =>
        moment(getFirstDay(value), 'YYYY-MM-DD')
            .add(1, 'month')
            .add(-1, 'day')
            .format('YYYY-MM-DD');

    return (
        <Paper className={classes.paper}>
            <ProgressDialog open={loading} />
            <PaperTitle>
                세금계산서 발행
            </PaperTitle>
            <div className={classes.paperContent}>
                <DatePicker
                    label='작성일'
                    value={insData['WRT_DATE']}
                    onChange={value => setInsData('WRT_DATE', value)}
                />
                <DatePicker
                    label='거래일'
                    className={classes.field}
                    minDate={getFirstDay(insData['WRT_DATE'])}
                    maxDate={getLastDay(insData['WRT_DATE'])}
                    value={insData['WRT_DATE'].substring(0, 8) + insData['TRD_DATE']}
                    onChange={value => setInsData('TRD_DATE', value.substring(8))}
                />
                <div>
                    <RadioGroup
                        label='계산서 종류'
                        className={classes.field}
                        value={insData['TAX_TYPE']}
                        onChange={value => setInsData('TAX_TYPE', value)}
                    >
                        <RadioItem value='01' label='일반' />
                        <RadioItem value='02' label='영세율' />
                        <RadioItem value='05' label='면세' />
                    </RadioGroup>
                    <RadioGroup
                        label='발행구분'
                        className={classes.field}
                        value={insData['PBL_TYPE']}
                        onChange={value => setInsData('PBL_TYPE', value)}
                    >
                        <RadioItem value='01' label='영수' />
                        <RadioItem value='02' label='청구' />
                        <RadioItem value='03' label='자동' />
                    </RadioGroup>
                </div>
                <RadioGroup
                    label='발행방식'
                    className={classes.field}
                    value={insData['ISSUE_TYPE']}
                    onChange={value => {
                        setInsData('ISSUE_TYPE', value);
                        setInsData('ITM_NM', '');
                    }}
                >
                    <RadioItem value='0' label='상품별 발행' />
                    <RadioItem value='1' label='상품별 각개 발행' />
                    <div>
                        <RadioItem value='2' label='합산 발행' />
                        {insData['ISSUE_TYPE'] === '2' &&
                        <TextField
                            label='상품명'
                            value={insData['ITM_NM']}
                            onChange={value => setInsData('ITM_NM', value)}
                            maxLength={20}
                        />}
                    </div>
                </RadioGroup>
                <RadioGroup
                        label='단가구분'
                        className={classes.field}
                        value={insData['PRICE_TYPE']}
                        onChange={value => setInsData('PRICE_TYPE', value)}
                    >
                        <RadioItem value='1' label='평균단가' />
                        <RadioItem value='2' label='숨김' />
                    </RadioGroup>
                <Tooltip title='확인' className={classes.chkButton}>
                    <IconButton onClick={onInsert}>
                        <CheckIcon />
                    </IconButton>
                </Tooltip>
            </div>
        </Paper>
    )
}

export default GdsTaxInvoiceEnroll;