import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { CircularProgress, TextField } from '@material-ui/core';
import { callProc } from '../common/DBConnector';

const ComboSearch = (props) => {
    const { data, onChange, value, label, selectProc, selectParam, keyField = 'k', valueField = 'v', onDataLoad, nullable, ...fieldProps } = props;
    const [list, setList] = useState();
    const [loading, setLoading] = useState(false);

    const hasValue = () => value != null && list && list.find(item => item[keyField] === value)

    const getValue = () => hasValue() || null
    
    const handleChange = (value) => {
        onChange && onChange(value === '' ? null : value)
    }

    useEffect(() => {
        hasValue() && onChange && onChange(value);
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const fetchData = async() => {
            setLoading(true);
            
            const rawData = !selectProc ?
                data :
                (await callProc(selectProc, selectParam)).data;
            const jsonData = !selectProc && !Array.isArray(rawData) ?
                rawData :
                rawData.reduce((result, item) => ({
                    ...result,
                    [item[keyField]]: item[valueField]
                }), {});

            let result;
            result = onDataLoad ? onDataLoad(jsonData, rawData) : rawData;
            result = Array.isArray(result) ?
                result :
                Object.keys(result).map(item => ({
                    [keyField]: item,
                    [valueField]: result[item]
                }));
            setList(nullable ? [
                {
                    [keyField]: null,
                    [valueField]: '선택 안함',
                },
                ...result
            ] : result);

            if (result.find(item => item[keyField] === value) || (nullable && value === null)) {
                handleChange(value);
            } else {
                handleChange((result[0] && result[0][keyField]) || null);
            }

            setLoading(false);
        }
        ((selectProc && selectParam) || data) && fetchData();
        // eslint-disable-next-line
    }, [data, selectProc, selectParam, keyField, valueField])

    return (
        !list || loading ? <CircularProgress size={25} /> :
        <Autocomplete
            {...fieldProps}
            options={list}
            getOptionLabel={(option) => option[valueField]}
            noOptionsText='항목 없음'
            value={getValue()}
            onChange={(e, v) => {
                v && handleChange(v[keyField])
            }}
            filterOptions={(options, { inputValue }) => options.filter(opt =>
                inputValue === '' || (
                    opt[valueField] != null && opt[keyField] != null && (
                        opt[valueField].toString().includes(inputValue) ||
                        opt[keyField].toString().includes(inputValue))
                    )
                )
            }
            onInputChange={(e, v, r) => r === 'clear' && nullable && handleChange(null)}
            renderInput={(params) =>
                <TextField
                    {...params}
                    label={label}
                />}
        />
    )
}

ComboSearch.defaultProps = {
    selectParam: {},
};

export default ComboSearch;