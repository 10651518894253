import React, { useMemo, useRef } from 'react';
import moment from 'moment';
import { Document, StyleSheet, Text, View} from '@react-pdf/renderer';
import { PdfCell, PdfTable, PdfRow, PdfPage } from '../component';
import { numberFormat } from '../common/Utils';

const styles = StyleSheet.create({
    titleSort : {
        alignSelf: 'center',
        fontSize : 20,
        marginBottom : 0,
    },
    signText : {
        borderBottom : 1,
        borderTop : 1,
        borderLeft : 1,
        padding : 4,
        justifyContent : 'center',
    },
    headerField: {
        height: 20, 
        justifyContent: 'center',
        fontSize: 8
    },
    headerNumField: {
        height: 20, 
        justifyContent: 'center',
        fontSize: 8,
        paddingRight: 2,
    },
    sumField: {
        height: 20, 
        justifyContent: 'center',
        fontSize: 8
    },
    normalField : {
        // height: 20, 
        justifyContent: 'center',
        fontSize: 8
    },
    numberSort : {
        textAlign : 'right',
        justifyContent: 'center',
        paddingRight: 2,
        fontSize: 8,
    },
    pageNumber: {
        position: 'absolute',
        bottom: 15,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
    bottom: {
        position: 'absolute',
        bottom: 13,
        left: 25,
        right: 25,
    },
    
})

const COL_CNT = 10;

const CardSaleInfoPdf = ({data, fieldsData, cardData, payData, workCd, workName, bizMonth, loginState}) => {
    const supplySum = useRef(0);
    const vatSum = useRef(0);
    const pageFields = useMemo(() => {
        if (!fieldsData) return [];
        const result = [];
        const length = Math.ceil(fieldsData.fields.length / COL_CNT);
        for (let i = 0; i < length; i++) {
            result.push(fieldsData.fields.slice(i * COL_CNT, (i + 1) * COL_CNT));
        }
        return result;
    }, [fieldsData]);

    const getTotal = (data, field) => (
        numberFormat(data && data.reduce((result, item) => result + item[field], 0))
    )

    const getSupply = (data, field) => {
        const total = data && data.reduce((result, item) => result + item[field], 0);
        const endNum = Math.floor((total / 1.1).toFixed(2)).toString().substr(Math.floor((total / 1.1).toFixed(2)).toString().length -1);
        if(endNum < 9){
            const supply = Math.ceil((total / 1.1));
            supplySum.current = supplySum.current + supply;
            return numberFormat(supply);
        }
        else{
            const supply = Math.floor((total / 1.1));
            supplySum.current = supplySum.current + supply;
            return numberFormat(supply);
        }
    }

    const getVat = (data, field) => {
        const total = data && data.reduce((result, item) => result + item[field], 0);
        const endNum = Math.floor((total / 1.1).toFixed(2)).toString().substr(Math.floor((total / 1.1).toFixed(2)).toString().length -1);
        if(endNum < 9){
            const vat = Math.floor((total / 11));
            vatSum.current = vatSum.current + vat;
            return numberFormat(vat);
        }
        else{
            const vat = Math.ceil((total / 11));
            vatSum.current = vatSum.current + vat;
            return numberFormat(vat);
        }
    }

    return (
        <Document title={`카드사매출_${workName}_${bizMonth}`}>
            {pageFields.map((pageField, pageNum) => (
                <PdfPage key={pageNum} orientation='landscape'>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 8, marginTop: 8 }}>
                        <View style={{ alignSelf : 'flex-end' }}>
                            <Text style={{ fontSize : 12 }}> 일자 : {bizMonth} </Text>
                            <Text style={{ fontSize : 12 }}> 출력 : {moment().format('YYYY-MM-DD HH:mm:ss')} </Text>
                        </View>
                        <Text style={styles.titleSort}> {`${workName} - 카드사 매출`} </Text>
                        <View style={{ flexDirection: 'row' }}>
                            <View style={styles.signText}>
                                <Text>결{"\n"}재</Text>
                            </View>
                            <PdfTable style={{ width: 250 }}>
                                <PdfRow>
                                    <PdfCell cellTop cellLeft>담당</PdfCell>
                                    <PdfCell cellTop>부장</PdfCell>
                                    <PdfCell cellTop>상무</PdfCell>
                                    <PdfCell cellTop>전무</PdfCell>
                                    <PdfCell cellTop>사장</PdfCell>
                                </PdfRow>
                                <PdfRow>
                                    <PdfCell cellLeft style={{ height: 30 }}></PdfCell>
                                    <PdfCell></PdfCell>
                                    <PdfCell></PdfCell>
                                    <PdfCell style={{ justifyContent: 'center' }}>전결</PdfCell>
                                    <PdfCell></PdfCell>
                                </PdfRow>
                            </PdfTable>
                        </View>
                    </View>
                    <PdfTable>
                        <PdfRow fixed>
                            <PdfCell cellLeft cellTop style={styles.headerField}>영업일</PdfCell>
                            {pageField.map((item, idx) => (
                                item !== 'BIZ_DATE' && item !== 'AMOUNT' &&
                                <PdfCell key={idx} cellTop style={styles.headerNumField}>
                                    {cardData[item] || payData[item]}
                                </PdfCell>
                            ))}
                            {pageNum === (pageFields.length - 1) &&
                            <PdfCell cellTop style={{...styles.headerNumField}}>합계금액</PdfCell>}
                        </PdfRow>
                        {data && data.map((items, idx) => (
                            <PdfRow key={idx}>
                                <PdfCell cellLeft style={styles.normalField}>{items['BIZ_DATE']}</PdfCell>

                                {pageField.map((item, idx) => (
                                    item !== 'BIZ_DATE' &&
                                    <PdfCell key={idx} style={styles.numberSort}>{numberFormat(items[item])}</PdfCell>
                                ))}
                            </PdfRow>
                        ))}
                        <PdfRow>
                            <PdfCell cellLeft style={styles.sumField}>합 계</PdfCell>
                            {pageField.map((item, idx) => (
                                item !== 'BIZ_DATE' &&
                                <PdfCell key={idx} style={styles.numberSort}>{getTotal(data, item)}</PdfCell>
                            ))}
                        </PdfRow>
                        <PdfRow>
                            <PdfCell cellLeft style={styles.sumField}>공급가액</PdfCell>
                            {pageField.map((item, idx) => (
                                item !== 'BIZ_DATE' && item !== 'AMOUNT' &&
                                <PdfCell key={idx} style={styles.numberSort}>{getSupply(data, item)}</PdfCell>
                            ))}
                            {pageNum === (pageFields.length - 1) &&
                            <PdfCell style={styles.numberSort}>{numberFormat(supplySum.current)}</PdfCell>}
                        </PdfRow>
                        <PdfRow>
                            <PdfCell cellLeft style={styles.sumField}>부가세</PdfCell>
                            {pageField.map((item, idx) => (
                                item !== 'BIZ_DATE' && item !== 'AMOUNT' &&
                                <PdfCell key={idx} style={styles.numberSort}>{getVat(data, item)}</PdfCell>
                            ))}
                            {pageNum === (pageFields.length - 1) &&
                            <PdfCell style={styles.numberSort}>{numberFormat(vatSum.current)}</PdfCell>}
                        </PdfRow>
                    </PdfTable>
                    <Text style={{ ...styles.bottom, textAlign: 'left' }} fixed>
                        {workName == null ? '전체' : workName} / {loginState['EMP_NM']}
                    </Text>
                    <Text
                        style={{ ...styles.bottom, textAlign: 'right'  }}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </PdfPage>
            ))}
        </Document>
    )
}

export default CardSaleInfoPdf