import { makeStyles } from "@material-ui/core";
import { DataColumn, DataGrid, SearchItem, SearchPanel, DatePicker } from "../../component";
import React, { useState, useEffect } from "react";
import { callProc } from "../../common/DBConnector";
import moment from 'moment';


const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    title: {
        fontWeight: 'bold',
        '--cell-color': '#f1f2f4 !important',
    },
    detailTotal: {
        backgroundColor: '#a3a9d9 !important'
    }
}))


const ProfitLossReport = () => {
    const [condition, setCondition] = useState(null);
    const [profitLossData, setProfitLossData] = useState();
    const [workData, setWorkData] = useState();
    const classes = useStyles();

    const getCellProps = (row, fieldName) => {
        if (row['SEQ'] === 1) {
            return { className: classes.title };
        }

        switch (fieldName) {
            case 'SEQ':
            case 'CLASS_CD':
            case 'ACC_NM':
                break;
            default:
                // return {
                //     onClick: () => setSelectedRow({
                //         ...row,
                //         ...condition,
                //         'WORK_CD': fieldName
                //     })
                // }
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setProfitLossData(null);
            
            const workData = await callProc('SLT_WORKPLACEINFO_AUTH');
            setWorkData(workData.data.reduce((result, item) => ({
                ...result,
                [item['WORK_CD']]: item['WORK_NM']
            }), {}));

            const profitLossData = await callProc('SLT_WORK_PROFIT_LOSS_REPORT', condition);

            setProfitLossData(profitLossData);
        }
        condition && fetchData();
    }, [condition])

    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => {
                sessionStorage['WORK_CD'] = condition['WORK_CD'];
                setCondition({...condition, LOAD_TYPE: 1})}}>
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
            </SearchPanel>
            <DataGrid
                className={classes.contentLeft}
                title='손익계산서'
                dataSet={profitLossData}
                selectParam={condition}
                selectionMode='single'
                cellProps={getCellProps}
                fixedColumns={['ACC_CD', 'ACC_NM']}
                horizonMode
                excelDownload
                sortable
                filterable
            >
                <DataColumn fieldName='SEQ' visible={false} />
                <DataColumn fieldName='CLASS_CD' visible={false} />
                <DataColumn fieldName='ACC_CD' visible={false} />

                {profitLossData && profitLossData.fields.map((fieldName, idx) => fieldName !== 'SEQ' && fieldName !== 'CLASS_CD' && fieldName !== 'ACC_NM' &&
                    <DataColumn
                        key={fieldName}
                        fieldName={fieldName}
                        title={fieldName === 'TOTAL' ? workData[profitLossData.fields[idx + 1]] + ' 통합' : workData[fieldName]}
                    />
                )}
                
            </DataGrid>
        </div>
    )
}

export default ProfitLossReport;