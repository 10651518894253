import React, { useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { DataColumn, DataGrid, DatePicker, ProgressPanel, SearchItem, SearchPanel } from '../../component';

const TransMonthlyInfo = () => {
    const [condition, setCondition] = useState(null);
    const loginState = useSelector(state => state.mobileLoginReducer);

    return (!loginState['TSP_CAR_CD'] ? <ProgressPanel /> :
        <Fragment>
            <SearchPanel onSearch={(condition) => setCondition({ ...loginState, ...condition })}>
                <SearchItem
                    fieldName='BIZ_MONTH'
                    defaultValue={moment().format('YYYY-MM')}
                    component={(state, dispatch) => (
                        <DatePicker
                            label='기간'
                            value={state}
                            onChange={value => dispatch(value)}
                            monthPicker
                        />
                    )}
                />
            </SearchPanel>
            <DataGrid
                title='월별 수송량'
                selectProc={'SLT_TRANSPORT_MONTH_QTY'}
                selectParam={condition}
            >
                <DataColumn
                    fieldName='BIZ_DAY'
                    title='날짜'
                    value={(row, value) => Number(value) + '일'}
                />
                <DataColumn
                    fieldName='ORD_QTY'
                    title='주문수량'
                    value={(row, value) => value.toFixed(3)}
                    total={(values, data) => data.reduce((result, row) => result + row['ORD_QTY'], 0)}
                />
                <DataColumn
                    fieldName='CALC_QTY'
                    title='정산수량'
                    value={(row, value) => value.toFixed(3)}
                    total={(values, data) => data.reduce((result, row) => result + row['CALC_QTY'], 0)}
                />
                <DataColumn
                    fieldName='SUM_QTY'
                    title='누적수량'
                    value={(row, value) => value.toFixed(3)}
                />
            </DataGrid>
        </Fragment>
    )
}

export default TransMonthlyInfo;