import { callProc } from "../common/DBConnector";
import { REQ_LOGIN, REQ_M_LOGIN, FETCH_CONFIG } from "./constants";
import * as actions from './actions'

const LoginChecker = store => next => async(action) => {
    switch (action.type) {
        case REQ_LOGIN: {
            const { id, pw, onFailed } = action;
            if (id == null) {
                onFailed && onFailed();
                break;
            }

            const result = await callProc('SLT_LOGININFO_LOGIN', { ID: id, PASSWORD: pw });
            if (result.data && result.data[0]) {
                sessionStorage['ID'] = id;
                sessionStorage['PW'] = pw;

                const loginData = result.data[0];

                return next(actions.login(loginData));
            } else {
                onFailed && onFailed();
            }
            break
        };
        case REQ_M_LOGIN: {
            const { id, pw, onFailed } = action;
            if (id == null) {
                onFailed && onFailed();
                break;
            }

            const result = await callProc('SLT_TRANSLOGIN_LOGIN', { ID: id, PASSWORD: pw });
            if (result.data && result.data[0]) {
                sessionStorage['M_ID'] = id;
                sessionStorage['M_PW'] = pw;

                const loginData = result.data[0];

                return next(actions.mobileLogin(loginData));
            } else {
                onFailed && onFailed();
            }
            break;
        }
        default:
            break;
    }
    return next(action);
}

const ConfigFetcher = store => next => async(action) => {
    if (action.type === FETCH_CONFIG) {
        const conf = (await callProc('SLT_CONF')).data.reduce((result, item) => ({
            ...result,
            [item['CONF_CD']]: item['CONF_VALUE']
        }), {});
        return next(actions.setConfig(conf));
    }
    return next(action);
}

export { LoginChecker, ConfigFetcher };