import React from 'react';
import { FormControl, FormLabel, RadioGroup } from '@material-ui/core';

const RG = ({ children, value, label, className, style, onChange, ...props }) => {

    const handleChange = (e) => {
        onChange && onChange(e.target.value);
    }

    return (
        <FormControl
            component='fieldset'
            className={className}
            style={style}
        >
            <FormLabel
                component="legend"
            >
                {label}
            </FormLabel>
            <RadioGroup
                value={value}
                onChange={handleChange}
                {...props}
            >
                {children}
            </RadioGroup>
        </FormControl>
    )
}

export default RG;