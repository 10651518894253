import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DatePicker, DataColumn, PdfButton } from '../../component';
import {  makeStyles } from '@material-ui/core';
import { ItemInBoundListPdf } from '../../reports';
import { totalCount } from '../../common/Utils';
import { useSelector } from 'react-redux';


const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
}))

const ItemInboundList = () => {
    const [condition, setCondition] = useState(null);
    const [pdfData, setPdfData] = useState();
    const classes = useStyles();
    const loginState = useSelector(state => state.loginReducer);
    const [workData, setWorkData] = useState();

    const pdfComponent = useMemo(() => (
        <PdfButton title='매입조회'>
            {pdfData &&
            <ItemInBoundListPdf
                pdfData={pdfData}
                condition={condition}
                loginState={loginState}
                workName={workData.find(item => item['WORK_CD'] === condition['WORK_CD'])['WORK_NM']}
            />}
        </PdfButton>
    ), [pdfData, condition, loginState, workData]);



    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => {
                sessionStorage['WORK_CD'] = condition['WORK_CD'];
                setCondition(condition)}}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            onDataLoad={(jsonData, rawData) => {
                                const result = [{ WORK_CD: 'ALL', WORK_NM: '전체' }, ...rawData];
                                setWorkData(result);
                                return result;
                            }}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
            </SearchPanel>
            <DataGrid
                title='매입 조회'
                selectProc='SLT_ITEMINBOUND_LIST'
                selectParam={condition}
                selectionMode='single'
                totalGroup={['WORK_CD']}
                horizonMode
                virtualized
                excelDownload
                sortable
                filterable
                headerItem={({visibleAoa}) => {
                    setPdfData(visibleAoa);
                    return pdfComponent;
                }}
                stickyHeader
            >
                <DataColumn 
                    fieldName='WORK_NM' 
                    title='사업장명' 
                    total={(values, data, group) => 
                        group == null ? `- 합 계 ${totalCount(values)} 건 -` : `(소 계 ${totalCount(values)} 건) ${data[0]['WORK_NM']}`} 
                />
                <DataColumn fieldName='QTY' title='수량' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='SPLY_AMT' title='공급가액' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='VAT' title='부가세' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='AMOUNT' title='금액' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='WORK_CD' visible={false} />
                

            </DataGrid>
        </div>
    )
}
export default ItemInboundList;