import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles, Paper, TextField, Tooltip, IconButton } from '@material-ui/core';
import { callProc } from '../../common/DBConnector';
import { ProgressPanel, SearchPanel, SearchItem, ComboBox, DatePicker, DataGrid, DataColumn, NumberField, PaperTitle } from '../../component';
import { useFieldInputs } from '../../common/Utils';
import CheckIcon from '@material-ui/icons/Check';

const transInType = {
    '0' : '전체',
    '1' : '입고 대기',
    '2' : '입고 완료',
}

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
    info: {
        flex: 5,
        marginRight: theme.spacing(1),
    },
    input: {
        display: 'flex',
        flexDirection: 'column',
        flex: 2,
        marginLeft: theme.spacing(1),
    },
    btnContainer: {
        alignSelf: 'flex-end',
        marginTop: 'auto',
    },
}))

const TransInEnroll = () => {
    const [condition, setCondition] = useState(null);
    const [selectRow, setSelectRow] = useState();

    const [workData, setWorkData] = useState(); // 사업장
    const [gdsData, setGdsData] = useState(); // 상품

    const classes = useStyles();

    useEffect(() => {
        const fetchData = async () => {
            const workData = await callProc('SLT_WORKPLACEINFO_AUTH');
            const gdsData = await callProc('SLT_GOODSINFO');

            setWorkData(workData.data.reduce((result, item) => ({
                ...result,
                [item['WORK_CD']]: item['WORK_NM']
            }), {}))

            setGdsData(gdsData.data.reduce((result, item) => ({
                ...result,
                [item['GDS_CD']] : item['GDS_NM']
            }), {}))
        }
        fetchData();
    }, [])

    const refreshGrid = () => setCondition({...condition})

    return (
        (!workData || !gdsData) ? <ProgressPanel/> :
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => setCondition(condition)}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={'ALL'}
                    component={(state, dispatch) => (
                        <ComboBox
                            label='인도사업장'
                            data={{ 'ALL': '전체', ...workData }}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='SHIP_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, dispatch) => (
                        <DatePicker
                            label='정산일자'
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='TRANS_IN_TYPE'
                    defaultValue={Object.keys(transInType)[1]}
                    component={(state, dispatch) => (
                        <ComboBox
                            label='출고상태'
                            data={transInType}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
            </SearchPanel>

            <div className={classes.content}>
                <div className={classes.info}>
                    <DataGrid
                        title='오더 정보'
                        selectProc='SLT_ORDERINFO_TRANSIN'
                        selectParam={condition}
                        onSelect={row => setSelectRow(row)}
                        selectionMode='single'
                    >
                        <DataColumn
                            fieldName='GDS_CD'
                            title='상품명'
                            value={(row, value) => gdsData[value]}
                        />
                        <DataColumn
                            fieldName='BIZ_DATE'
                            title='오더일자'
                        />
                        <DataColumn
                            fieldName='SHIP_TRN_NM'
                            title='출하처'
                        />
                        <DataColumn
                            fieldName='DEST_TRN_NM'
                            title='인도처'
                        />
                        <DataColumn
                            fieldName='SHIP_TRN_CD'
                            visible={false}
                        />
                        <DataColumn
                            fieldName='DEST_TRN_CD'
                            visible={false}
                        />
                        <DataColumn
                            fieldName='QTY'
                            title='오더수량'
                        />
                        <DataColumn
                            fieldName='CALC_QTY'
                            title='정산수량'
                        />
                        <DataColumn
                            fieldName='SHIP_DATE'
                            visible={false}
                        />
                    </DataGrid>
                </div>
                <div className={classes.input}>
                    <TransInPanel
                        refreshGrid={refreshGrid}
                        selectRow={selectRow}
                    />
                    <TransInInfo
                        selectRow={selectRow}
                    />
                </div>
            </div>
        </div>
    )
}

const usePanelStyle = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginBottom: theme.spacing(1),
    },
    content: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: theme.spacing(2),
    },
    inputField: {
        display: 'block',
        flex: 'none',
    },
    btnContainer: {
        alignSelf: 'flex-end',
        marginTop: 'auto',
    },
}))

const TransInPanel = ({ refreshGrid, selectRow }) => {
    const [insData, setInsData] = useFieldInputs({});
    const [insProg, setInsProg] = useState(false);
    const [tnkData, setTnkData] = useState();

    const classes = usePanelStyle();

    useEffect(() => {
        if (isEnrollable()) {
            fetchTnkData();
            setInsData('IN_QTY', (selectRow['CALC_QTY'] - selectRow['IN_QTY']).toString());
        } else {
            setInsData('TNK_NO', null);
            setInsData('IN_QTY', null);
        }
        // eslint-disable-next-line
    }, [selectRow])

    const fetchTnkData = async() => {
        setTnkData(null);
        const data = (await callProc(
            'SLT_TANKINFO_DATE',
            {
                'WORK_CD': selectRow['DEST_TRN_CD'],
                'BIZ_DATE': selectRow['SHIP_DATE'],
                'GDS_CD': selectRow['GDS_CD'],
            })).data.reduce((result, item) => ({
                ...result,
                [item['TNK_NO']] : item['TNK_NO']
            }), {});
        setInsData('TNK_NO', Object.keys(data)[0]);

        setTnkData(data);
    }

    const isEnrollable = () => selectRow != null && selectRow['CALC_QTY'] > selectRow['IN_QTY']

    const onInsert = async() => {
        setInsProg(true);

        await callProc('INS_TANKINBOUNDINFO', { ...selectRow, ...insData })
        refreshGrid();

        setInsProg(false);
    }

    const onInQtyChanged = (value) => {
        selectRow && selectRow['CALC_QTY'] - selectRow['IN_QTY'] < value ?
        setInsData('IN_QTY', selectRow['CALC_QTY'] - selectRow['IN_QTY']) :
        setInsData('IN_QTY', value);
    }

    return (
        <Paper className={classes.paper}>
            <PaperTitle>
                입고 등록
            </PaperTitle>
            <div className={classes.content}>
                <DatePicker
                    label='출하일'
                    value={selectRow ? selectRow['SHIP_DATE'] : moment().format('YYYY-MM-DD')}
                    disabled
                />
                <TextField
                    label='정산수량'
                    value={selectRow ? selectRow['CALC_QTY'] : ''}
                    disabled
                />
                <TextField
                    label='입고가능수량'
                    value={selectRow ? selectRow['CALC_QTY'] - selectRow['IN_QTY'] : ''}
                    disabled
                />
                {tnkData === null ?
                <ProgressPanel className={classes.inputField} small /> :
                <ComboBox
                    label='탱크번호'
                    data={tnkData}
                    value={insData['TNK_NO']}
                    onChange={value => setInsData('TNK_NO', value)}
                    disabled={!isEnrollable()}
                />
                }
                <NumberField
                    label='입고수량'
                    value={insData['IN_QTY']}
                    onChange={onInQtyChanged}
                    disabled={!isEnrollable()}
                />
                <div className={classes.btnContainer}>
                    {insProg ? <ProgressPanel /> :
                    <Tooltip title='확인'>
                    <IconButton
                        component='div'
                        onClick={onInsert}
                        disabled={!isEnrollable()}
                    >
                        <CheckIcon />
                    </IconButton>
                    </Tooltip>}
                </div>
            </div>
        </Paper>
    )
}

const useInfoStyle = makeStyles((theme) => ({
    grid: {
        marginTop: theme.spacing(1),
    },
}))

const TransInInfo = ({ selectRow }) => {
    const classes = useInfoStyle();
    return (
        <DataGrid
            title='입고 내역'
            className={classes.grid}
            selectProc='SLT_TANKINBOUNDINFO_ORDER'
            selectParam={selectRow}
        />
    )
}

export default TransInEnroll;