import React, { useEffect, useMemo, useState } from 'react';
import { DataGrid, ComboBox, DataColumn, DatePicker, NumberField, ComboSearch, IconButton, TextField, PaperTitle} from '../../component';
import {  makeStyles, Paper  } from '@material-ui/core';
import { useSelector } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import { useFieldInputs } from '../../common/Utils';
import { useSnackbar } from 'notistack';
import { callProc } from '../../common/DBConnector';
import CheckIcon from '@material-ui/icons/Check';
import CachedIcon from '@material-ui/icons/Cached';
import CloseIcon from '@material-ui/icons/Close';


const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
    info: {
        flex: 1,
        marginRight: theme.spacing(1),
    },
    input: {
        width: 300,
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(1),
    },
    searchPaper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    searchContainer: {
        flex: 1,
        display: 'inline-block',
        flexDirection: 'row',
    },
    searchItem: {
        display: 'inline-block',
        width: 200,
        marginRight: 100,
        marginBottom: theme.spacing(1),
    },
}))

const SaleAdjustEnrollDelDetail = () => {
    const clsState = useSelector(state => state.clsDateReducer);
    const [editRow, setEditRow] = useState();
    const [input, setInput] = useFieldInputs({
        WORK_CD: sessionStorage['WORK_CD'] === 'ALL' ? null : sessionStorage['WORK_CD'],
        BIZ_DATE: clsState[sessionStorage['WORK_CD']],
    });

    const classes = useStyles();

    const condition = useMemo(() => (input['WORK_CD'] && input['BIZ_DATE']) ? input : null, [input]);

    const gridRefresh = () => {
        setInput({ type: 'UPDATE', value: { ...input } });
    }

    const onUpdateStart = row => {
        setEditRow({...row});
        return false;
    }

    return(
        <div className={classes.container}>
            <Paper className={classes.searchPaper}>
                <div className={classes.searchContainer}>
                    <div className={classes.searchItem}>
                        <ComboBox
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={condition && condition['WORK_CD']}
                            onChange={value => {
                                if (input['WORK_CD'] !== value) {
                                    sessionStorage['WORK_CD'] = value;
                                    setInput('BIZ_DATE', clsState[value]);
                                    setInput('WORK_CD', value);
                                }
                            }}
                        />
                    </div>
                    <div className={classes.searchItem}>
                        <DatePicker
                            label='영업일'
                            minDate={clsState[input['WORK_CD']]}
                            value={condition && condition['BIZ_DATE']}
                            onChange={value => {
                                if (input['BIZ_DATE'] !== value) {
                                    setInput('BIZ_DATE', value);
                                    if(condition && condition['BIZ_DATE'] < clsState[condition['WORK_CD']]) {
                                        setInput({ type: 'UPDATE', value: { ...input, 'BIZ_DATE': value } });
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                <IconButton
                    tooltip=''
                    icon={<SearchIcon />}
                    onClick={gridRefresh}
                />
            </Paper>
            <div className={classes.content}>
                <div className={classes.info}>
                    <DataGrid
                        title='매출단수 조정'
                        table='TB_OILSALEINFO'
                        selectProc='SLT_OILSALEINFO_DETAIL'
                        updateProc=''
                        deleteProc='DLT_OILSALEINFO_DETAIL'
                        selectParam={condition}
                        onUpdateStart={onUpdateStart}
                        onLoad={() => setEditRow()}
                        selectionMode='single'
                        horizonMode
                        virtualized
                    >
                        <DataColumn fieldName='CST_NM' title='거래처' />  
                        <DataColumn fieldName='CAR_NO' title='차량번호' />
                        <DataColumn fieldName='GDS_NM' title='상품' />
                        <DataColumn fieldName='QTY' /> 
                        <DataColumn fieldName='SPLY_AMT' /> 
                        <DataColumn fieldName='AMOUNT' /> 
                        <DataColumn fieldName='REMARK' /> 
                        <DataColumn fieldName='WORK_CD' visible={false} />
                        <DataColumn fieldName='CST_CD' visible={false} />
                        <DataColumn fieldName='GDS_CD' visible={false} />
                        <DataColumn fieldName='CAR_CD' visible={false} />
                    </DataGrid>
                </div>
                <div className={classes.input}>
                    <EnrollPanel
                        condition={condition}
                        editRow={editRow}
                        initEditRow={() => setEditRow()}
                        gridRefresh={gridRefresh}
                        clsState={clsState}
                    />
                </div>
            </div>
        </div>
    )
}

const usePanelStyles = makeStyles((theme) => ({
    panelContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: 0,
    },
    panelContent: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        overflowY: 'auto',
        '& > *': {
            marginBottom: theme.spacing(2),
        },
    },
    btnContainer: {
        alignSelf: 'flex-end',
        padding: theme.spacing(1),
    },
}))

const EnrollPanel = ({ condition, editRow, gridRefresh, clsState, initEditRow }) => {
    const classes = usePanelStyles();
    const [cstParam, setCstParam] = useState();
    const [gdsParam, setGdsParam] = useState();
    const [carParam, setCarParam] = useState();
    const [editState, setEditState] = useState(0);
    const [befValue, setBefValue] = useState();
    const { enqueueSnackbar } = useSnackbar();

    const [insData, setInsData] = useFieldInputs({
        WORK_CD: condition && condition['WORK_CD'],
        BIZ_DATE: condition && condition['BIZ_DATE'],
        CST_CD: null,
        GDS_CD: null,
        CAR_CD: null,
        QTY: 0,
        SPLY_AMT: 0,
        VAT: 0,
        AMOUNT: 0,
        REMARK: ''
    });

    useEffect(() => {
        if (!editRow) {
            setEditState(0);
            setInsData({ type: 'INIT' });
        } else {
            setInsData({ type: 'UPDATE', value: editRow })
            setEditState(1);
        }
    }, [editRow, setInsData])
    
    useEffect(() => {
        if (condition) {
            setInsData({ type: 'INIT' });
            setEditState(0);
            setGdsParam({
                WORK_CD: condition && condition['WORK_CD'],
                GDS_TYPE: 'ALL',
                NOT_TYPE: 0,
                GDS_TYPE_M: 'ALL',
                NOT_TYPE_M: 0,
                GAUGE_TYPE: 'ALL',
                STA_DATE: condition && condition['BIZ_DATE'],
            });
            setCstParam({
                WORK_CD: condition && condition['WORK_CD'],
                CST_TYPE: [1, 2],
                CST_KIND: [1, 2],
                STA_DATE: condition && condition['BIZ_DATE'],
            })
            setInsData('WORK_CD', condition['WORK_CD']);
            setInsData('BIZ_DATE', condition['BIZ_DATE']);
        }
    }, [condition, setInsData])

    const isValid = () => {
        if(insData['CST_CD'] === null) {
            enqueueSnackbar('매입처를 선택해 주세요.', { variant: 'error' });
            return false;
        }

        if(insData['GDS_CD'] === null) {
            enqueueSnackbar('상품을 선택해 주세요.', { variant: 'error' });
            return false;
        }

        return true;
    }

    const onInsert = async() => {
        if(isValid()){
            let befData = {...insData};
            delete befData.SALE_CD;
            delete befData.WORK_CD;
            delete befData.BIZ_DATE;
            setBefValue(befData);
            let procName;
            if(String(editState) === '0'){
                procName = 'INS_OILSALEINFO_DETAIL'
            } else {
                procName = 'UPT_OILSALEINFO_DETAIL'
            }
            const result = await callProc(procName, insData);
            if(!result.err) {
                enqueueSnackbar('저장이 완료되었습니다.');
                setInsData({ type: 'INIT' });
                setEditState(0);
                gridRefresh();
            }
        }
    }

    const onExitEdit = () => {
        initEditRow();
    }

    const getBefValue = () => {
        befValue && setInsData({ type: 'UPDATE', value: {...befValue} })
    }

    const cstCd = insData && insData['CST_CD'];

    useEffect(() => setCarParam((prevCarParam) => (
        cstCd === prevCarParam?.CST_CD ? prevCarParam : {
            WORK_CD: condition?.WORK_CD,
            CST_CD: cstCd
        }
    )), [condition, cstCd]);

    return (
        <Paper className={classes.panelContainer} >
            <PaperTitle>{String(editState) === '0' ? '추가' : '수정'}</PaperTitle>
            <div className={classes.panelContent}>
                {String(editState) === '1' &&
                <TextField
                    label='판매코드'
                    value={insData['SALE_CD']}
                    disabled
                />}
                <ComboBox
                    label='사업장'
                    selectProc='SLT_WORKPLACEINFO_AUTH'
                    keyField='WORK_CD'
                    valueField='WORK_NM'
                    value={insData['WORK_CD']}
                    disabled
                />
                <DatePicker
                    label='영업일'
                    minDate={clsState[insData['WORK_CD']]}
                    value={insData['BIZ_DATE']}
                    disabled
                />
                {cstParam &&
                <ComboSearch
                    label='거래처'
                    selectProc='SLT_CUSTOMERINFO_SALE'
                    selectParam={cstParam}
                    keyField='CST_CD'
                    valueField='CST_NM'
                    value={insData['CST_CD']}
                    onChange={value => setInsData('CST_CD', value)}
                    nullable
                />}
                {carParam &&
                <ComboSearch
                    label='차량번호'
                    selectProc='SLT_CSTCARINFO_CAR'
                    selectParam={carParam}
                    keyField='CAR_CD'
                    valueField='CAR_NO'
                    value={insData['CAR_CD']}
                    onChange={value => setInsData('CAR_CD', value)}
                    nullable
                />}
                {gdsParam &&
                <ComboSearch
                    label='상품'
                    selectProc='SLT_GAUGE_ITEM_GOODS_COMBO'
                    selectParam={gdsParam}
                    keyField='GDS_CD'
                    valueField='GDS_NM'
                    value={insData['GDS_CD']}
                    onChange={value => setInsData('GDS_CD', value)}
                    nullable
                />}
                <NumberField
                    label='수량'
                    value={insData['QTY']}
                    onChange={value => setInsData('QTY', value)}
                />
                <NumberField
                    label='공급가액'
                    value={insData['SPLY_AMT']}
                    onChange={value => setInsData('SPLY_AMT', value)}
                    onBlur={() => {
                        let AmountNum = insData['SPLY_AMT'] * 1.1
                        setInsData('AMOUNT', Math.round(AmountNum));
                        setInsData('VAT', Math.round(AmountNum - insData['SPLY_AMT']));
                    }}
                />
                <NumberField
                    label='부가세'
                    value={insData['VAT']}
                    onChange={value => setInsData('VAT', value)}
                />
                <NumberField
                    label='금액'
                    value={insData['AMOUNT']}
                    onChange={value => setInsData('AMOUNT', value)}
                    onBlur={() => {
                        if (String(insData['SPLY_AMT']) === '0') {
                            setInsData('SPLY_AMT', insData['AMOUNT']);
                        }
                        else {
                            setInsData('VAT', insData['AMOUNT'] - insData['SPLY_AMT'])
                        }
                    }}
                />
                <TextField
                    label='비고'
                    value={insData['REMARK']}
                    onChange={value => setInsData('REMARK', value)}
                />
            </div>
            <div className={classes.btnContainer}>
                {String(editState) === '1' &&
                <IconButton
                    tooltip='취소'
                    icon={<CloseIcon />}
                    onClick={() => onExitEdit()}
                />}
                <IconButton
                    tooltip='이전값 불러오기'
                    icon={<CachedIcon />}
                    onClick={() => getBefValue()}
                />
                <IconButton
                    tooltip='확인'
                    icon={<CheckIcon />}
                    onClick={() => onInsert()}
                />
            </div>
        </Paper>
    )
}

export default SaleAdjustEnrollDelDetail