import React, { useEffect, useState } from 'react';
import { TextField, IconButton } from '.';
import { Dialog, DialogTitle, List, ListItem, makeStyles, RadioGroup } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import CheckIcon from '@material-ui/icons/Check';
import { getAddress } from '../common/DBConnector';
import ProgressPanel from './ProgressPanel';
import RadioItem from './RadioItem';

const useStyles = makeStyles((theme) => ({
    dialog : {
        display: 'flex',
        flexDirection: 'column',
        maxWidth : 'none',
        width : 450,
        height : 600,
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    searchPanel: {
        display: 'flex',
        padding: theme.spacing(2),
        paddingTop: 0,
    },
    list: {
        flex: 1,
        overflowY: 'auto',
    },
    itemContent: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    itemSubContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    itemSubContent: {
        display: 'flex',
        fontSize: 12,
        color: 'gray',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(2),
    },
}))

const AddressPicker = ({ open, onClose, onSelect }) => {
    const [keyword, setKeyword] = useState('');
    const [list, setList] = useState([]);
    const [select, setSelect] = useState();
    const classes = useStyles();

    useEffect(() => {
        setKeyword(null);
        setList([]);
        setSelect(null);
    }, [open])

    const onSearch = async() => {
        setList(null);
        setSelect(null);

        const res = await getAddress(keyword);
        if (!res.err) {
            setList(res);
        } else {
            setList([]);
        }
    }

    const onFieldKey = e => {
        if (e.key === 'Enter') {
            onSearch();
        }
    }

    return (
        <Dialog
            PaperProps={{ className: classes.dialog }} 
            open={open}
            onClose={onClose}
        >
            <div className={classes.dialogTitle}>
                <DialogTitle>
                    주소
                </DialogTitle>
                <IconButton
                    tooltip='닫기'
                    onClick={onClose}
                    icon={<CloseIcon />}
                />
            </div>
            <div className={classes.searchPanel}>
                <TextField
                    label='검색어'
                    value={keyword}
                    onChange={value => setKeyword(value)}
                    onKeyPress={onFieldKey}
                    fullWidth
                />
                <IconButton
                    tooltip='검색'
                    onClick={onSearch}
                    icon={<SearchIcon />}
                />
            </div>
            {list == null ? <ProgressPanel/> :
            <List className={classes.list}>
                <RadioGroup value={select}>
                    {list.map(item => (
                        <ListItem
                            key={item.bdMgtSn}
                            onClick={() => setSelect(item.bdMgtSn)}
                            button
                        >
                            <RadioItem value={item.bdMgtSn} />
                            <div className={classes.itemContent}>
                                {item.roadAddr}
                                <div className={classes.itemSubContainer}>
                                    <div className={classes.itemSubContent}>
                                        <MiniLabel>지번</MiniLabel>
                                        {item.jibunAddr}
                                    </div>
                                    <div className={classes.itemSubContent}>
                                        <MiniLabel>우편</MiniLabel>
                                        {item.zipNo}
                                    </div>
                                </div>
                            </div>
                        </ListItem>
                    ))}
                </RadioGroup>
            </List>}
            <div className={classes.buttonContainer}>
                <IconButton
                    tooltip='확인'
                    icon={<CheckIcon />}
                    disabled={select == null}
                    onClick={() => {
                        onSelect && onSelect(list.find(item => item.bdMgtSn === select));
                        onClose();
                    }}
                />
            </div>
        </Dialog>
    )
}

const useLabelStyles = makeStyles(theme => ({
    label: {
        display: 'inline-block',
        fontSize: 7,
        marginRight: theme.spacing(1),
        whiteSpace: 'nowrap',
    },
    border: {
        border: '1px darkgray solid',
    }
}));

const MiniLabel = ({ children }) => {
    const classes = useLabelStyles();
    return (
        <div className={classes.label}>
            <div className={classes.border}>
                {children}
            </div>
        </div>
    )
}

export default AddressPicker;