import React, { useState } from 'react';
import moment from 'moment';
import { DataGrid, DataColumn, SearchItem, SearchPanel, ComboBox, DatePicker } from '../../component';
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    }
}))

const SaleCostList = () => {
    const classes = useStyle();
    const [condition, setCondition] = useState(null);

    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => {setCondition(condition)}}>
                <SearchItem
                    fieldName='WORK_CD'
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            onDataLoad={(jsonData, rawData) => {
                                const result = [{ WORK_CD: 'ALL', WORK_NM: '전체' }, ...rawData];
                                return result;
                            }}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
            </SearchPanel>
            <DataGrid
                title='매출원가 보고서'
                selectProc='SLT_STOCK_AMT_B'
                selectParam={condition}
                selectionMode='single'
                totalGroup={['WORK_CD']}
                horizonMode
                virtualized
                stickyHeader
                excelDownload={{ fileName: '매출원가 보고서.xlsx', sheetName: '매출원가 보고서' }}
            >
                <DataColumn fieldName='WORK_SHORT_NM' title='사업장명' />
                <DataColumn fieldName='GDS_NM' title='상품명' />
                <DataColumn fieldName='FST_QTY' title='기초수량' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='FST_AMT' title='기초재고액' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='IN_QTY' title='매입수량' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='IN_AMT' title='상품매입액' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='LST_QTY' title='기말수량' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='LST_AMT' title='기말재고액' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='SELF_QTY' title='자가소비 수량' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='SELF_SALECOST' title='자가소비 매출원가' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='SALE_QTY' title='매출수량' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='SALE_COST' title='매출원가' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn
                    fieldName='WORK_CD'
                    visible={false}
                />
                <DataColumn
                    fieldName='WORK_NM'
                    visible={false}
                />
                <DataColumn
                    fieldName='GDS_CD'
                    visible={false}
                />
            </DataGrid>
        </div>
    )
}

export default SaleCostList;