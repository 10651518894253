import React, { Fragment, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { DataGrid, ComboBox, DatePicker, DataColumn, IconButton, FilePicker, ProgressDialog } from '../../component';
import {  Dialog, DialogTitle, makeStyles, Paper } from '@material-ui/core';
import { useFieldInputs, xlsToJsonAsync } from '../../common/Utils';
import { callProc } from '../../common/DBConnector';
import PublishIcon from '@material-ui/icons/Publish';
import CloseIcon from '@material-ui/icons/Close';
import IsoIcon from '@material-ui/icons/Iso';
import CheckIcon from '@material-ui/icons/Check';
import SearchIcon from '@material-ui/icons/Search';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    searchPaper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    searchContainer: {
        flex: 1,
        display: 'inline-block',
        flexDirection: 'row',
    },
    searchItem: {
        display: 'inline-block',
        width: 200,
        marginRight: 100,
        marginBottom: theme.spacing(1),
    },
}))

const VenExcleUpload = () => {
    const classes = useStyles();
    const [input, setInput] = useFieldInputs({
        WORK_CD: sessionStorage['WORK_CD'],
    });
    const [dialogOpen, setDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openType, setOpenType] = useState('UPLOAD');

    const condition = useMemo(() => input['WORK_CD'] && input, [input]);

    const openDialog = (openType) => {
        setOpenType(openType);
        setDialogOpen(true);
    }

    const gridRefresh = () => {
        setInput({ type: 'UPDATE', value: { ...input } });
    }

    const progressOpen = (value) => setLoading(value);

    return (
        <div className={classes.container}>
            <ProgressDialog open={loading} />
            <Paper className={classes.searchPaper}>
                <div className={classes.searchContainer}>
                    <div className={classes.searchItem}>
                        <ComboBox
                            // style={{width: 200}}
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={condition && condition['WORK_CD']}
                            onChange={value => {
                                if (input['WORK_CD'] !== value) {
                                    sessionStorage['WORK_CD'] = value;
                                    setInput('WORK_CD', value);
                                }
                            }}
                        />
                    </div>
                </div>
                <IconButton
                    tooltip=''
                    icon={<SearchIcon />}
                    onClick={gridRefresh}
                />
            </Paper>

            <DataGrid
                title='VEN 엑셀 업로드'
                selectProc='SLT_VEN'
                selectionMode='single'
                horizonMode
                virtualized
                headerItem={() => (
                    <Fragment>
                        <IconButton
                            tooltip='엑셀 업로드'
                            icon={<PublishIcon />}
                            onClick={() => openDialog('UPLOAD')}
                        />
                        <IconButton
                            tooltip='재계산'
                            icon={<IsoIcon />}
                            onClick={() => openDialog('RECALC')}
                        />
                    </Fragment>
                )}
                stickyHeader
            >
                <DataColumn fieldName='SEQ' visible={false} />
            </DataGrid>

            <ExcelDialog
                open={dialogOpen}
                close={() => setDialogOpen(false)}
                condition={condition}
                gridRefresh={gridRefresh}
                progressOpen={progressOpen}
                openType={openType}
            />
        </div>
    )
}

const useDialogStyles = makeStyles(theme => ({
    dialogPaper: {
        maxWidth: 'none',
        width: 300,
        height: 300,
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    okButton: {
        display: 'flex',
    },
    fieldContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    Field: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1),
    }
}))

const ExcelDialog = ({ open, close, condition, gridRefresh, progressOpen, openType }) => {
    const [insData, setInsData] = useFieldInputs(condition);
    const { enqueueSnackbar } = useSnackbar();
    const classes = useDialogStyles();

    useEffect(() => {
        setInsData('WORK_CD', condition && condition['WORK_CD']);
        setInsData('BIZ_DATE', moment().add(-1, 'day').format('YYYY-MM-DD'));
    }, [condition, setInsData]) 

    const fileSelected = async(file) => {
        progressOpen(true);
        const xls = await xlsToJsonAsync(file);
        let result;
        result = await callProc('UPT_SMARTRO_VEN', { JSON_CARD: Object.values(xls)[0], JSON_ETC: Object.values(xls)[1], ...insData });
        if(result.err) {
            progressOpen(false);
            return false;
        }

        result = await callProc

        if(!result.err){
            enqueueSnackbar('엑셀 저장이 완료되었습니다.');
            close();
            gridRefresh();
        }

        progressOpen(false);
    }

    const reCalc = async() => {
        progressOpen(true);
        const result = await callProc('UPT_VEN_RECALC', insData);
        if(!result.err) {
            enqueueSnackbar('재계산이 완료되었습니다.');
            close();
            gridRefresh();
        }

        progressOpen(false);
    }

    return (
        <Dialog
            open={open}
            PaperProps={{ className: classes.dialogPaper }}
        >
            <div className={classes.dialogTitle}>
                <DialogTitle>엑셀 업로드</DialogTitle>
                <IconButton
                    tooltip='닫기'
                    onClick={close}
                    icon={<CloseIcon />}
                />
            </div>
            <div className={classes.fieldContainer}>
                <ComboBox
                    className={classes.Field}
                    label='사업장명'
                    selectProc='SLT_WORKPLACEINFO_AUTH'
                    keyField='WORK_CD'
                    valueField='WORK_NM'
                    value={insData && insData['WORK_CD']}
                    disabled
                    fullWidth={false}
                />
                <DatePicker
                    className={classes.Field}
                    label='영업일'
                    value={insData && insData['BIZ_DATE']}
                    onChange={(value) => setInsData('BIZ_DATE', value)}
                />
                {openType === 'UPLOAD' ?
                <FilePicker
                    onChange={fileSelected}
                /> : 
                <IconButton
                    tooltip='재계산'
                    onClick={() => reCalc()}
                    icon={<CheckIcon />}
                />}
            </div>
            
        </Dialog>
    )
}
export default VenExcleUpload;