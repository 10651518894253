import React, { useState, useEffect } from 'react';
import { MenuItem, MenuDivider } from '../component';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { makeStyles, Avatar, IconButton, Menu as PopupMenu, MenuItem as PopupMenuItem, ListItemIcon } from '@material-ui/core';
import MenuInfo from './MenuInfo';
import { Route, useHistory } from 'react-router-dom';
import { setLoginId } from '../common/DBConnector';
import { useSnackbar } from 'notistack';
import { Menu } from '.';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../redux/actions';
import { TransProgress } from './screen';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: 0,
        flex: 1,
    },
    top: {
        ...theme.mixins.toolbar,
      },
    content: {
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        padding: 16,
    },
}));

const App = () => {
    const { enqueueSnackbar } = useSnackbar();
    window.enqueueSnackbar = enqueueSnackbar;

    const loginState = useSelector(state => state.mobileLoginReducer);
    const dispatch = useDispatch();

    const history = useHistory();
    const classes = useStyles();

    useEffect(() => {
        if (loginState['ID']) {
            setLoginId(loginState['ID']);
        } else {
            dispatch(actions.reqMobileLogin(
                loginState.sessionId || localStorage['M_LOGIN_RMB_ID'],
                loginState.sessionPw || localStorage['M_LOGIN_RMB_PW'],
                () => history.push('/login/m')
            ))
        }
    }, [loginState, dispatch, history])

    const createMenu = (menu, divider) => Object.keys(menu).map(key => {
        const { children, ...props } = menu[key];
        return ([
            <MenuItem key={key} menuKey={key} {...props}>
                {children && createMenu(children)}
            </MenuItem>,
            divider && <MenuDivider />
        ])
    })

    const createRoute = (menu, root) => Object.keys(menu).map(key => {
        const { children, to } = menu[key];
        const path = root + '/' + (to ? to.name : key);
    
        return to ?
            <Route key={key} exact path={path} component={to} /> :
            children && createRoute(children, path)
    })

    return (
        <div className={classes.root}>
            <Menu
                toolbar={<Toolbar userData={loginState} onLogout={() => dispatch(actions.mobileLogout())} />}
                width={280}
            >
                {createMenu(MenuInfo, true)}
            </Menu>
            <div className={classes.content}>
                <Route exact path='/m' component={TransProgress} />
                {createRoute(MenuInfo, '/m')}
            </div>
        </div>
    )
}

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
    },
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    profile: {
        fontSize: 14,
        textAlign: 'left',
    },
    textLight: {
        color: '#888'
    },
    textContainer: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    }
}));

const Toolbar = ({ userData, onLogout }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const classes = useToolbarStyles();
    return (
        <div className={classes.root}>
            <PopupMenu
                anchorEl={anchorEl}
                open={anchorEl != null}
                onClose={() => setAnchorEl(null)}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <IconMenuItem
                    label='로그아웃'
                    icon={<ExitToAppIcon />}
                    onClick={onLogout}
                />
            </PopupMenu>
            <div/>
            <IconButton
                className={classes.profile}
                onClick={e => setAnchorEl(e.currentTarget)}
            >
                <Avatar src="/broken-image.jpg" />
                <div className={classes.textContainer}>
                    <span>
                        <b>{userData['CAR_NO']}</b>
                    </span>
                    <br/>
                    <span className={classes.textLight}>
                        {userData.loginPosition}
                    </span>
                </div>
                <ExpandMore />
            </IconButton>
        </div>
    )
}

const IconMenuItem = ({ onClick, icon, label }) => {
    return (
        <PopupMenuItem onClick={onClick}>
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            {label}
        </PopupMenuItem>
    )
}

export default App;
