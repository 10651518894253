import React from 'react';
import TransInfo from './TransInfo';
import TransMonthlyInfo from './TransMonthlyInfo';

const TransProgress = () => <TransInfo isEnroll />;
const TransComplete = () => <TransInfo />;

export {
    TransProgress,
    TransComplete,
    TransMonthlyInfo,
}