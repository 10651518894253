import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DatePicker, DataColumn, PdfButton, GridHeaderBand, BandItem, ComboSearch } from '../../component';
import { makeStyles } from '@material-ui/core';
import { SupplyGoodsHistoryPdf } from '../../reports';
import { useSelector } from 'react-redux';
import { isFieldDiff } from '../../common/Utils';

const useStyles = makeStyles(() => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    }
}))

const SupplyGoodsHistory = () => {
    const [condition, setCondition] = useState(null);
    const [pdfData, setPdfData] = useState();
    const classes = useStyles();
    const loginState = useSelector(state => state.loginReducer);
    const [workData, setWorkData] = useState();

    const pdfComponent = useMemo(() => (
        <PdfButton title='상품 수불장'>
            {pdfData &&
            <SupplyGoodsHistoryPdf
                pdfData={pdfData}
                condition={condition}
                loginState={loginState}
                workName={workData.find(item => item['WORK_CD'] === condition['WORK_CD'])['WORK_NM']}
            />}
        </PdfButton>
    ), [pdfData, condition, loginState, workData]);


    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => {
                sessionStorage['WORK_CD'] = condition['WORK_CD'];
                setCondition(condition)}}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            onDataLoad={(jsonData, rawData) => {
                                const result = [...rawData]
                                setWorkData(result);
                                return result;
                            }}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='GDS_CD'
                    component={(state, setState, condition, setField) => {
                        let param = condition['GDS_PARAM'] || null;
                        if (isFieldDiff(['WORK_CD', 'STA_DATE'], condition, param)) {
                            param = { 
                                WORK_CD: condition['WORK_CD'],
                                GDS_TYPE: 'ALL',
                                NOT_TYPE: 0,
                                GDS_TYPE_M: 'ALL',
                                NOT_TYPE_M: 0,
                                GAUGE_TYPE: 'ALL',
                                STA_DATE: condition['STA_DATE'],
                            };
                            setField('GDS_PARAM', param);
                        }
                        return (
                            <ComboSearch
                                label='상품명'
                                selectProc='SLT_GAUGE_ITEM_GOODS_COMBO'
                                selectParam={param}
                                keyField='GDS_CD'
                                valueField='GDS_NM'
                                onDataLoad={(jsonData, rawData) => [{ GDS_CD: 'ALL', GDS_NM: '전체' }, ...rawData]}
                                value={state}
                                onChange={(value) => setState(value)}
                            />
                        )
                    }}
                />
            </SearchPanel>
                <DataGrid
                    title='상품 수불장'
                    selectProc='SLT_SUPPLY_GOODS'
                    selectParam={condition}
                    totalGroup={['GDS_CD']}
                    selectionMode='single'
                    horizonMode
                    virtualized
                    excelDownload
                    headerItem={({visibleAoa}) => {
                        setPdfData(visibleAoa);
                        return pdfComponent;
                    }}
                    stickyHeader
                    filterable
                >
                    <GridHeaderBand>
                        <BandItem
                            span={2}
                        >
                            정보
                        </BandItem>
                        <BandItem
                            span={1}
                        >
                            전기 재고
                        </BandItem>
                        <BandItem
                            span={5}
                        >
                            당기 입고
                        </BandItem>
                        <BandItem
                            span={5}
                        >
                            당기 출고
                        </BandItem>
                        <BandItem
                            span={1}
                        >
                            당기 재고
                        </BandItem>
                    </GridHeaderBand>
                    <DataColumn fieldName='GDS_NM' total={(values, data, group) => group ? `(소 계) ${data[0]['GDS_NM']}` : `- 합 계 -`} />
                    <DataColumn fieldName='BEF_QTY' title='전기 재고량' />
                    <DataColumn fieldName='IN_QTY' title='매입 수량' total={(values) => values.reduce((result, value) => result + value, 0)} />
                    <DataColumn fieldName='IN_SPLY_AMT' title='매입 공급가액' total={(values) => values.reduce((result, value) => result + value, 0)} />
                    <DataColumn fieldName='IN_AMT' title='매입 합계' total={(values) => values.reduce((result, value) => result + value, 0)} />
                    <DataColumn fieldName='IN_MOVE_QTY' title='이관 입고량' total={(values) => values.reduce((result, value) => result + value, 0)} />
                    <DataColumn fieldName='ADJ_QTY' title='조정 수량' total={(values) => values.reduce((result, value) => result + value, 0)} />
                    <DataColumn fieldName='SALE_QTY' title='매출 수량' total={(values) => values.reduce((result, value) => result + value, 0)} />
                    <DataColumn fieldName='SALE_SPLY_AMT' title='매출 공급가액' total={(values) => values.reduce((result, value) => result + value, 0)} />
                    <DataColumn fieldName='SALE_AMT' title='매출 합계' total={(values) => values.reduce((result, value) => result + value, 0)} />
                    <DataColumn fieldName='OUT_MOVE_QTY' title='이관 출고량' total={(values) => values.reduce((result, value) => result + value, 0)} />
                    <DataColumn fieldName='SELF_QTY' title='자가소비' total={(values) => values.reduce((result, value) => result + value, 0)} />
                    <DataColumn fieldName='CUR_QTY' title='당기 재고량' />
                    <DataColumn fieldName='WORK_CD' visible={false} />
                    <DataColumn fieldName='GDS_CD' visible={false} />
                    <DataColumn fieldName='BEF_GDS_CD' visible={false} />
                </DataGrid>
        </div>
    )
}
export default SupplyGoodsHistory;