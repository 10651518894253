import React, { useState, useEffect } from 'react';
import { xlsToJsonAsync } from '../../common/Utils';
import { callProc } from '../../common/DBConnector';
import { DataGrid, DataColumn, FilePicker, ProgressPanel } from '../../component';
import { makeStyles, Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },

    paper : {
        marginBottom: theme.spacing(2),
        padding : theme.spacing(2),
    },

    combobox : {
        width : 168,
    }
}))

const CreditCheck = () => {
    const classes = useStyles();
    const [workCd, setWorkCd] = useState();
    const [data, setData] = useState();
    const [workData, setWorkData] = useState();

    const fileSelected = async(file) => {
        setData(null);
        const xls = await xlsToJsonAsync(file);
        const data = await callProc('UPT_SALT_EXCEL_UP', { JSON: JSON.stringify(Object.values(xls)[0]), WORK_CD : workCd });
        setData(data);
    }

    useEffect(() => {
        const fetchData = async () => {
            const workData = (await callProc('SLT_WORKPLACEINFO_AUTH')).data.reduce((result, item) => ({
                ...result,
                [item['WORK_CD']]: item
            }), {})
            setWorkData(workData);
            setWorkCd(Object.keys(workData)[0]);
        }
        fetchData();
    }, [])

    useEffect(() => {
        setData({
            data: [],
            fields: ['SALE_CD', 'GDS_NM', 'ISSUE', 'CARD_NO', 'APPV_NO', 'PRICE', 'QTY', 'AMOUNT', 'JODAL']
        });
    }, [workCd])

    return (
        !workData ? <ProgressPanel /> :
        <div className={classes.container}>
            <Paper className={classes.paper}>
                <FilePicker onChange={fileSelected} />
            </Paper>
            <DataGrid
                title='엑셀'
                dataSet={data}
                selectionMode={workData[workCd] && workData[workCd]['WORK_TYPE'] === 4 ? 'multi' : 'none'}
            >
                <DataColumn fieldName='ISSUE' title='카드사'/>
                <DataColumn fieldName='CARD_NO' title='카드번호'/>
                <DataColumn fieldName='JODAL' title='조달청금액'/>
                
            </DataGrid>
        </div>
    )
}

export default CreditCheck;