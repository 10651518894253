import React from 'react';
import moment from 'moment';
import { Document, StyleSheet, Text, View} from '@react-pdf/renderer';
import { PdfCell, PdfTable, PdfRow, PdfPage } from '../component';
import { numberFormat } from '../common/Utils';

const styles = StyleSheet.create({
    titleSort : {
        alignSelf: 'center',
        fontSize : 20,
        marginBottom : 0,
    },
    signText : {
        borderBottom : 1,
        borderTop : 1,
        borderLeft : 1,
        padding : 4,
        justifyContent : 'center',
    },
    numberSort : {
        textAlign : 'right',
        // height: 18,
        // justifyContent: 'center',
        paddingRight: 2
    },
    pageNumber: {
        position: 'absolute',
        bottom: 15,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
    bottom: {
        position: 'absolute',
        bottom: 13,
        left: 25,
        right: 25,
    },
})

const CstBondPayReportPdf = ({data, workName, bizMonth, loginState}) => {

    const getTotal = (data, field) => (
        numberFormat(data && data.reduce((result, item) => result + item[field], 0))
    )

    return (
        <Document title={`외상거래처 매출_${workName}_${bizMonth}`}>
            <PdfPage orientation='landscape'>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 8, marginTop: 8 }}>
                    <View style={{ alignSelf: 'flex-end' }}>
                        <Text style={{ fontSize: 12 }}> 일자 : {bizMonth} </Text>
                        <Text style={{ fontSize: 12 }}> 출력 : {moment().format('YYYY-MM-DD HH:mm:ss')} </Text>
                    </View>
                    <Text style={styles.titleSort}> {`${workName} - 외상거래처 매출`} </Text>
                    <View style={{ flexDirection: 'row' }}>
                        <View style={styles.signText}>
                            <Text>결{"\n"}재</Text>
                        </View>
                        <PdfTable style={{ width: 250 }}>
                            <PdfRow>
                                <PdfCell cellTop cellLeft>담당</PdfCell>
                                <PdfCell cellTop>부장</PdfCell>
                                <PdfCell cellTop>상무</PdfCell>
                                <PdfCell cellTop>전무</PdfCell>
                                <PdfCell cellTop>사장</PdfCell>
                            </PdfRow>
                            <PdfRow>
                                <PdfCell cellLeft style={{ height: 30 }}></PdfCell>
                                <PdfCell></PdfCell>
                                <PdfCell></PdfCell>
                                <PdfCell style={{ justifyContent: 'center' }}>전결</PdfCell>
                                <PdfCell></PdfCell>
                            </PdfRow>
                        </PdfTable>
                    </View>
                </View>
                <PdfTable>
                    <PdfRow fixed>
                        <PdfCell cellLeft cellTop>거래처 명</PdfCell>
                        <PdfCell cellTop>장기 미수금</PdfCell>
                        <PdfCell cellTop>전월 미수금</PdfCell>
                        <PdfCell cellTop>당월 매출</PdfCell>
                        <PdfCell cellTop>당월 입금</PdfCell>
                        <PdfCell cellTop>당월 잔액</PdfCell>
                        <PdfCell cellTop>공급가액</PdfCell>
                        <PdfCell cellTop>부가세</PdfCell>
                    </PdfRow>
                    {data && data.map((item, idx) => (
                        <PdfRow key={idx}>
                            <PdfCell cellLeft>{item.CST_NM}</PdfCell>
                            <PdfCell style={styles.numberSort}>{numberFormat(item.OLD_CREDIT_AMT)}</PdfCell>
                            <PdfCell style={styles.numberSort}>{numberFormat(item.BEF_MONTH_BAL_AMT)}</PdfCell>
                            <PdfCell style={styles.numberSort}>{numberFormat(item.CUR_MONTH_SALE_AMT)}</PdfCell>
                            <PdfCell style={styles.numberSort}>{numberFormat(item.CUR_MONTH_IN_AMT)}</PdfCell>
                            <PdfCell style={styles.numberSort}>{numberFormat(item.CUR_MONTH_BAL_AMT)}</PdfCell>
                            <PdfCell style={styles.numberSort}>{numberFormat(item.CUR_MONTH_BAL_SPLY)}</PdfCell>
                            <PdfCell style={styles.numberSort}>{numberFormat(item.CUR_MONTH_BAL_VAT)}</PdfCell>
                        </PdfRow>
                    ))}
                    <PdfRow>
                        <PdfCell cellLeft>합 계</PdfCell>
                        <PdfCell style={styles.numberSort}>{getTotal(data, 'OLD_CREDIT_AMT')}</PdfCell>
                        <PdfCell style={styles.numberSort}>{getTotal(data, 'BEF_MONTH_BAL_AMT')}</PdfCell>
                        <PdfCell style={styles.numberSort}>{getTotal(data, 'CUR_MONTH_SALE_AMT')}</PdfCell>
                        <PdfCell style={styles.numberSort}>{getTotal(data, 'CUR_MONTH_IN_AMT')}</PdfCell>
                        <PdfCell style={styles.numberSort}>{getTotal(data, 'CUR_MONTH_BAL_AMT')}</PdfCell>
                        <PdfCell style={styles.numberSort}>{getTotal(data, 'CUR_MONTH_BAL_SPLY')}</PdfCell>
                        <PdfCell style={styles.numberSort}>{getTotal(data, 'CUR_MONTH_BAL_VAT')}</PdfCell>
                    </PdfRow>
                </PdfTable>
                <Text style={{ ...styles.bottom, textAlign: 'left' }} fixed>
                    {workName == null ? '전체' : workName} / {loginState['EMP_NM']}
                </Text>
                <Text
                    style={{ ...styles.bottom, textAlign: 'right'  }}
                    render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                    fixed
                />
            </PdfPage>
        </Document>
    )
}

export default CstBondPayReportPdf;