import React, { useState, useEffect, useRef, useMemo } from 'react';
import { DataGrid, ComboBox, DatePicker, DataColumn, NumberField, ComboSearch, PaperTitle, IconButton } from '../../component';
import {  makeStyles, Paper, TextField } from '@material-ui/core';
import { callProc } from '../../common/DBConnector';
import { adjType } from '../../common/Dictionary';
import { useFieldInputs } from '../../common/Utils';
import CheckIcon from '@material-ui/icons/Check';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';

const cshRecept = {
    0: '미발행',
    1: '발행'
}

const useStyle = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    tabContainer : {
        display : 'flex',
    },
    tabbar : {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    tabContent : {
        display: 'flex', 
        flex: 3, 
        height: 0
    },
    content : {
        display: 'flex',
        flex: 2,
        height : '100%',
    },
    hide : {
        display: 'none'
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
        flex: 2,
        marginRight: theme.spacing(1),
    },
    enroll: {
        display: 'flex', 
        flex: 1,
        marginLeft: theme.spacing(1),
    },
    searchPaper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    searchContainer: {
        flex: 1,
        display: 'inline-block',
        flexDirection: 'row',
    },
    searchItem: {
        display: 'inline-block',
        width: 200,
        marginRight: 100,
        marginBottom: theme.spacing(1),
    },
}))

const SaleAdjustEnrollDel = () => {
    const classes = useStyle();
    const clsState = useSelector(state => state.clsDateReducer);
    const [input, setInput] = useFieldInputs({
        WORK_CD: sessionStorage['WORK_CD'],
        BIZ_DATE: clsState[sessionStorage['WORK_CD']],
    });
    const [selectedRow, setSelectedRow] = useState();
    const { enqueueSnackbar } = useSnackbar();


    const condition = useMemo(() => input['WORK_CD'] && input['BIZ_DATE'] && input, [input]);

    const gridRefresh = () => {
        setInput({ type: 'UPDATE', value: { ...input } });
    }

    const valid = (row) => {
        if(row['REG_TYPE'].toString() === '1'){
            enqueueSnackbar('POS 데이터는 수정 및 삭제가 불가합니다.', {variant: 'error'});
            return false;
        }
        
        return true;
    }

    const onDelete = async(row) => {
        if(valid(row)){
            const result = await callProc('DLT_SALE_ADJUST_ENROLL_DEL', row);

            if(!result.err){
                // enqueueSnackbar('삭제가 완료되었습니다.');
                gridRefresh();
            }
        }else{
            return false;
        }
    }

    return (
        <div className={classes.container}>
            <Paper className={classes.searchPaper}>
                <div className={classes.searchContainer}>
                    <div className={classes.searchItem}>
                        <ComboBox
                            // style={{width: 200}}
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={condition && condition['WORK_CD']}
                            onChange={value => {
                                if (input['WORK_CD'] !== value) {
                                    sessionStorage['WORK_CD'] = value;
                                    setInput('BIZ_DATE', clsState[value]);
                                    setInput('WORK_CD', value);
                                }
                            }}
                        />
                    </div>
                    <div className={classes.searchItem}>
                        <DatePicker
                            label='영업일'
                            minDate={clsState[input['WORK_CD']]}
                            value={condition && condition['BIZ_DATE']}
                            onChange={value => {
                                if (input['BIZ_DATE'] !== value) {
                                    setInput('BIZ_DATE', value);
                                    if(condition && condition['BIZ_DATE'] < clsState[condition['WORK_CD']]) {
                                        setInput({ type: 'UPDATE', value: { ...input, 'BIZ_DATE': value } });
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                <IconButton
                    tooltip=''
                    icon={<SearchIcon />}
                    onClick={gridRefresh}
                />
            </Paper>
            <div className={classes.tabContent}>
                <DataGrid
                    className={classes.info}
                    title='매출 조정/대체 수정/삭제'
                    selectProc='SLT_SALE_ADJUST_ENROLL_DEL'
                    selectParam={condition}
                    onDelete={onDelete}
                    onSelect={rows => setSelectedRow(rows)}
                    selectionMode='single'
                    horizonMode
                >
                    <DataColumn fieldName='SALE_CD' visible={false} />
                    <DataColumn fieldName='REG_TYPE' visible={false} />
                    <DataColumn fieldName='ADJ_TYPE' value={(row, value) => adjType[value]} />
                    <DataColumn fieldName='BIZ_DATE' title='영업일' />
                    <DataColumn fieldName='WORK_CD' title='사업장명' value={row => row['WORK_NM']} />
                    <DataColumn fieldName='CST_CD' title='거래처명' value={row => row['CST_NM']} />
                    <DataColumn fieldName='CAR_CD' title='차량번호' value={row => row['CAR_NO']} />
                    <DataColumn fieldName='GDS_CD' title='상품명' value={row => row['GDS_NM']} />
                    <DataColumn fieldName='PRICE' title='단가' />
                    <DataColumn fieldName='QTY' title='수량' />
                    <DataColumn fieldName='AMOUNT' title='합계금액' />
                    <DataColumn fieldName='CARD_NM' title='결제수단' />
                    <DataColumn fieldName='CSH_RECEPT' title='현금영수증' visible={false} />
                    <DataColumn fieldName='SALE_TIME' title='판매시간' />
                    <DataColumn fieldName='PAY_CD' title='결제수단' visible={false} />
                    <DataColumn fieldName='PAY_NM' title='PAY_NM' visible={false} />
                    <DataColumn fieldName='WORK_NM' visible={false} />
                    <DataColumn fieldName='CST_NM' visible={false} />
                    <DataColumn fieldName='CAR_NO' visible={false} />
                    <DataColumn fieldName='GDS_NM' visible={false} />
                    <DataColumn fieldName='TAX_TYPE' visible={false} />
                    <DataColumn fieldName='CARD_CD' visible={false} />
                    <DataColumn fieldName='TRUCK_DIS' visible={false} />
                </DataGrid>
                <EnrollPaper 
                    selectedRow={selectedRow}
                    condition={condition} 
                    gridRefresh={gridRefresh}
                    onDelete={onDelete} 
                />
            </div>
            
        </div>
    )
}

const enrollType = {
    0: '수량',
    1: '합계금액',
    2: '수량 및 금액'
}

const truckDis = {
    0: '일반',
    1: '영업화물'
}

const paperStyle = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column'
    },
    paperContent: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding: theme.spacing(2),
        paddingTop: 0,
        overflow: 'auto'
    },
    paperTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    field: {
        width: 180,
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2),
    },
    iconbutton : {
        alignSelf: 'flex-end',
        marginTop: theme.spacing(4),
        marginRight: theme.spacing(1),
    },
}))

const EnrollPaper = ({ selectedRow, condition, gridRefresh }) => {
    const classes = paperStyle();
    const [insData, setInsData, clear] = useFieldInputs({
        ADJ_TYPE: 1,
        QTY: 0,
        AMOUNT: 0,
        SPLY_AMT: 0,
        ENROLL_TYPE: 0,
        GDS_CD: 0,
        TRUCK_DIS: 0,
    });
    const saletimeParam = useRef();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (selectedRow != null){
            setInsData({ type: 'UPDATE', value: selectedRow });
            setInsData('VIEW_QTY', 0); 
            setInsData('VIEW_AMOUNT', 0);
            setInsData('VIEW_SPLY_AMT', 0);
        } else {
            clear();
        }
    }, [setInsData, clear, selectedRow])

    const valid = () => {
        // if(selectedRow == null && (insData['REG_TYPE'] === 1 || insData['REG_TYPE'] === '1')){
        //     enqueueSnackbar('대체 대상을 선택해주세요.', {variant: 'error'});
        //     return false;
        // }
        if(insData['REMARK'] == null || insData['REMARK'] === ''){
            enqueueSnackbar('조정/대체 시 사유를 작성해주세요.', {variant: 'error'});
            return false;
        }
        return true;
    }

    const onUpdate = async() => {
        if(valid()){
            const result = await callProc('UPT_SALE_ADJUST_ENROLL_DEL', {...insData, ...condition, SALE_CD: selectedRow != null ? selectedRow['SALE_CD'] : null});

            if (!result.err) {
                gridRefresh();
                enqueueSnackbar('저장이 완료되었습니다.');
            }
        }
    }

    const isEnrollable = useMemo(() => selectedRow != null, [selectedRow]);

    const selWorkCd = insData['WORK_CD'];
    const selCstCd = insData['CST_CD'];
    const selGdsCd = insData['GDS_CD'];
    const selBizDate = insData['BIZ_DATE'];
    const selPayCd = insData['PAY_CD'];

    const cstParam = useMemo(() => ({ WORK_CD: selWorkCd }), [selWorkCd]);
    const carParam = useMemo(() => ({
        WORK_CD: selWorkCd,
        CST_CD: selCstCd
    }), [selWorkCd, selCstCd]);
    const gdsParam = useMemo(() => ({
        WORK_CD: selWorkCd
    }), [selWorkCd]);
    const saleTimeParam = useMemo(() => ({
        WORK_CD: selWorkCd,
        GDS_CD: selGdsCd,
        BIZ_DATE: selBizDate,
    }), [selWorkCd, selGdsCd, selBizDate]);
    const payParam = useMemo(() => ({
        PAY_CD: selPayCd,
        WORK_CD: selWorkCd
    }), [selWorkCd, selPayCd])

    return (

        <Paper className={classes.paper}>
            <div className={classes.paperTitle}>
                <PaperTitle>
                    매출 조정/대체 수정
                </PaperTitle>
                <IconButton
                    className={classes.iconbutton}
                    tooltip='확인'
                    icon={<CheckIcon />}
                    onClick={onUpdate}
                />
            </div>
            <div className={classes.paperContent}>
                <ComboBox
                    className={classes.field}
                    data={adjType}
                    value={insData['ADJ_TYPE'] || 0}
                    onChange={value => setInsData('ADJ_TYPE', value)}
                    disabled={true}
                />
                <TextField
                    className={classes.field}
                    style={{ width: 300 }}
                    label='조정/대체사유'
                    value={insData['REMARK'] || ''}
                    onChange={e => setInsData('REMARK', e.target.value)}
                    disabled={!isEnrollable}
                />
                <div>
                    <ComboSearch
                        className={classes.field}
                        style={{ width: 300 }}
                        label='거래처명'
                        selectProc='SLT_CUSTOMERINFO_SALE'
                        selectParam={cstParam}
                        keyField='CST_CD'
                        valueField='CST_NM'
                        value={insData['CST_CD']}
                        onChange={value => setInsData('CST_CD', value)}
                        disabled={!isEnrollable}
                    />
                </div>
                <div>
                    <ComboSearch
                        className={classes.field}
                        label='차량번호'
                        selectProc='SLT_CSTCARINFO_CAR'
                        selectParam={carParam}
                        keyField='CAR_CD'
                        valueField='CAR_NO'
                        value={insData['CAR_CD'] || null}
                        onChange={value => setInsData('CAR_CD', value)}
                        disabled={!isEnrollable}
                    />
                </div>
                <div>
                    <ComboBox
                        className={classes.field}
                        label='결제수단'
                        selectProc='SLT_CARDINFO_COMBO'
                        selectParam={payParam}
                        keyField='CARD_CD'
                        valueField='CARD_NM'
                        value={insData['CARD_CD']}
                        onChange={value => setInsData('CARD_CD', value)}
                        disabled={(condition &&
                            (condition['PAY_CD'] === 'P0001' || condition['PAY_CD'] === 'P0003' || condition['PAY_CD'] === 'P0008')) || !isEnrollable}
                    />
                </div>
                <div>
                    <ComboSearch
                        className={classes.field}
                        label='상품명'
                        selectProc='SLT_GOODSINFO_GAUGE_ITEM_SERACHPANEL'
                        selectParam={gdsParam}
                        keyField='GDS_CD'
                        valueField='GDS_NM'
                        value={insData['GDS_CD'] || null}
                        onChange={value => {
                            saletimeParam.current = { ...condition, GDS_CD: value }
                            setInsData('GDS_CD', value);
                        }}
                        disabled={!isEnrollable}
                    />
                </div>
                <div>
                    <ComboBox
                        className={classes.field}
                        label='입력구분'
                        data={enrollType}
                        value={insData['ENROLL_TYPE'] || 0}
                        onChange={value => {
                            setInsData('ENROLL_TYPE', value);
                        }}
                        disabled={!isEnrollable}
                    />
                    <ComboBox
                        className={classes.field}
                        label='영업화물 구분'
                        data={truckDis}
                        value={insData['TRUCK_DIS'] || 0}
                        onChange={value => setInsData('TRUCK_DIS', value)}
                        disabled={!isEnrollable}
                    />
                </div>
                <div>
                    <NumberField
                        className={classes.field}
                        label='수량'
                        value={String(insData['ENROLL_TYPE']) !== '1' ? insData['QTY'] : insData['VIEW_QTY']}
                        onChange={value => {
                            String(insData['ENROLL_TYPE']) !== '1' ? setInsData('QTY', value) : setInsData('VIEW_QTY', value);
                        }}
                        disabled={insData['ENROLL_TYPE'].toString() === '1' || !isEnrollable}
                    />
                    <NumberField
                        className={classes.field}
                        label='합계금액'
                        value={String(insData['ENROLL_TYPE']) !== '0' ? insData['AMOUNT'] : insData['VIEW_AMOUNT']}
                        onChange={value =>  {
                            String(insData['ENROLL_TYPE']) !== '0' ? setInsData('AMOUNT', value) : setInsData('VIEW_AMOUNT', value);
                        }}
                        disabled={insData['ENROLL_TYPE'].toString() === '0' || !isEnrollable}
                    />
                </div>
                <div>
                    <NumberField
                        className={classes.field}
                        label='공급가액'
                        value={String(insData['ENROLL_TYPE']) !== '0' ? insData['SPLY_AMT'] : insData['VIEW_SPLY_AMT']}
                        onChange={value =>  {
                            String(insData['ENROLL_TYPE']) !== '0' ? setInsData('SPLY_AMT', value) : setInsData('VIEW_SPLY_AMT', value);
                        }}
                        disabled={insData['ENROLL_TYPE'].toString() === '0' || !isEnrollable}
                    />
                </div>
                <div>
                    <ComboBox
                        className={classes.field}
                        label='현금영수증'
                        data={cshRecept}
                        value={insData['CSH_RECEPT'] || 0}
                        onChange={value => setInsData('CSH_RECEPT', value)}
                        disabled={!isEnrollable}
                    />
                    <ComboBox
                        className={classes.field}
                        label='판매시간'
                        selectProc='SLT_SALETIME'
                        selectParam={saleTimeParam}
                        keyField='SALE_TIME'
                        valueField='SALE_TIME'
                        value={insData['SALE_TIME']}
                        onChange={value => setInsData('SALE_TIME', value)}
                        disabled={!isEnrollable}
                    />
                </div>
            </div>
        </Paper>
    )
}

export default SaleAdjustEnrollDel