import React, { useState } from 'react';
import {  makeStyles } from '@material-ui/core';
import moment from 'moment';
import {ComboBox, DatePicker, DataGrid, DataColumn, SearchPanel, SearchItem } from '../../component';
import { totalCount } from '../../common/Utils';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
}))

const StockAdjustList = () =>{
    const classes = useStyles();
    const [condition, setCondition] = useState(null);

    return (
        <div className={classes.container}>
           <SearchPanel onSearch={(condition) => { 
                sessionStorage['WORK_CD'] = condition['WORK_CD'];
                setCondition(condition); }}>
                <SearchItem
                    fieldName='WORK_CD'
                    component={(state, dispatch) => (
                        <ComboBox
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            onDataLoad={(jsonData, rawData) => {
                                const result = [{ WORK_CD: 'ALL', WORK_NM: '전체' }, ...rawData];
                                return result;
                            }}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
            </SearchPanel>

            <DataGrid 
                title='재고 조정 내역'
                selectProc='SLT_STOCK_ADJUST_LIST'
                selectParam={condition}
                selectionMode='single'
                horizonMode
                virtualized
                sortable
                filterable
                excelDownload
                totalGroup={['WORK_CD']}
            >
                <DataColumn 
                    fieldName='WORK_NM' 
                    title='사업장명' 
                    total={(values, data, group) => 
                        group == null ? `- 합 계 ${totalCount(values)} 건 -` : `(소 계 ${totalCount(values)} 건) ${data[0]['WORK_NM']}`} 
                />
                <DataColumn
                    fieldName='ADJ_QTY'
                    total={(values) => values.reduce((result, value) => result + value, 0)}
                />
                <DataColumn fieldName='WORK_CD' visible={false} />
            </DataGrid>
        </div>
    )
}
export default StockAdjustList