import React, { useState } from 'react';
import moment from 'moment';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DatePicker, DataColumn, ComboSearch, } from '../../component';
import { makeStyles } from '@material-ui/core';
import { isFieldDiff, numberPointFormat } from '../../common/Utils';

const useStyles = makeStyles(() => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    }
}))

const CustomerSaleHistory = () => {
    const [condition, setCondition] = useState(null);
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => {
                sessionStorage['WORK_CD'] = condition['WORK_CD'];
                setCondition(condition)}}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            onDataLoad={(jsonData, rawData) => {
                                const result = [...rawData]
                                return result;
                            }}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='CST_CD'
                    component={(value, setState, condition, dispatch) => {
                        let param = condition['CST_PARAM'] || null;
                        if (isFieldDiff(['WORK_CD', 'STA_DATE'], condition, param)) {
                            param = {
                                WORK_CD: condition['WORK_CD'],
                                CST_TYPE: [1, 2],
                                CST_KIND: [1, 2],
                                STA_DATE: condition['STA_DATE'],
                            };
                            dispatch('CST_PARAM', param);
                        }
                        return (
                            <ComboSearch
                                label='거래처명'
                                selectProc='SLT_CUSTOMER_COMBO'
                                selectParam={param}
                                keyField='CST_CD'
                                valueField='CST_NM'
                                value={value}
                                onChange={(value) => setState(value)}
                            />
                        )
                    }}
                />
            </SearchPanel>
                <DataGrid
                    title='거래처 매출원장'
                    selectProc='SLT_CST_SALEHISTORY'
                    selectParam={condition}
                    totalGroup={['BIZ_YEAR', 'BIZ_MONTH']}
                    eachTotalGroup
                    selectionMode='single'
                    horizonMode
                    virtualized
                    excelDownload
                    stickyHeader
                    filterable
                >
                    <DataColumn 
                        fieldName='BIZ_DATE' 
                        total={(values, data, group) => {
                            let result = '- 합 계 -'
                            if(group && !group['BIZ_MONTH'] && group['BIZ_YEAR']) {
                                result = `(집 계) ${data[0]['BIZ_YEAR']}`;
                            } else if (group && group['BIZ_MONTH'] && group['BIZ_YEAR']){
                                result = `(집 계) ${data[0]['BIZ_MONTH']}`;
                            }
                            return result;
                        }} 
                    />
                    <DataColumn fieldName='QTY' title='수량' value={(row, value) => numberPointFormat(value, 3)} total={(values) => numberPointFormat(values.reduce((result, value) => result + value, 0), 3)} />
                    <DataColumn fieldName='SALE_AMT' title='매출액' total={(values) => values.reduce((result, value) => result + value, 0)} />
                    <DataColumn fieldName='IN_AMT' title='입금액' total={(values) => values.reduce((result, value) => result + value, 0)} />
                    <DataColumn fieldName='CUR_BAL' title='잔액' />
                    <DataColumn fieldName='WORK_CD' visible={false} />
                    <DataColumn fieldName='CST_CD' visible={false} />
                    <DataColumn fieldName='GDS_CD' visible={false} />
                    <DataColumn fieldName='MONTH_BAL' visible={false} />
                    <DataColumn fieldName='YEAR_BAL' visible={false} />
                    <DataColumn fieldName='BIZ_YEAR' visible={false} />
                    <DataColumn fieldName='BIZ_MONTH' visible={false} />
                    <DataColumn fieldName='SEQ' visible={false} />
                </DataGrid>
        </div>
    )
}
export default CustomerSaleHistory;