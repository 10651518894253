import React, { useState } from 'react';
import moment from 'moment';
import { DataGrid, SearchItem, SearchPanel, DataColumn, ComboBox, DatePicker } from '../../component';
import { makeStyles } from '@material-ui/core';
import { proType } from '../../common/Dictionary';


const useStyle = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },    
}));

const InboundTaxSum = () => {
    const classes = useStyle();
    const [condition, setCondition] = useState(null);

    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => { 
                sessionStorage['WORK_CD'] = condition['WORK_CD'];
                setCondition(condition) }}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, dispatch) => (
                        <ComboBox
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
            </SearchPanel>
            <DataGrid
                title='매입 세금계산서 유형별 집계'
                selectProc='SLT_INBOUND_TAXINVOICE_SUM'
                selectParam={condition}
                horizonMode
                selectionMode='single'
            >
                <DataColumn
                    fieldName='PRO_TYPE'
                    value={(row, value) => proType[value]}
                />

                <DataColumn
                    fieldName='SPLY_AMT'
                    total={(values) => values.reduce((result, value) => result + value, 0)}
                />

                <DataColumn
                    fieldName='VAT'
                    total={(values) => values.reduce((result, value) => result + value, 0)}
                />

                <DataColumn
                    fieldName='AMOUNT'
                    total={(values) => values.reduce((result, value) => result + value, 0)}
                />
            </DataGrid>
        </div>
    )
}

export default InboundTaxSum;