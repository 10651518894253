import React, { useState } from 'react';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DatePicker, DataColumn } from '../../component';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';
import { totalCount } from '../../common/Utils';

const useStyle = makeStyles(() => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    }
}))

const SendMoneyList = () =>{
    const classes = useStyle();
    const [condition, setCondition] = useState(null);

    return(
        <div className={classes.container}>
            <SearchPanel onSearch={condition => {
                sessionStorage['WORK_CD'] = condition['WORK_CD'];
                setCondition(condition);
                }}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, setState) => (
                        <ComboBox
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            onDataLoad={(jsonData, rawData) => {
                                const result = [{ WORK_CD: 'ALL', WORK_NM: '전체' }, ...rawData];
                                return result;
                            }}
                            value={state}
                            onChange={(value) => setState(value)}
                        />
                    )}
                />
               <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
            </SearchPanel>
            <DataGrid
                title='송금 조회'
                selectProc='SLT_SENDMONEY_LIST'
                selectParam={condition}
                selectionMode='single'
                totalGroup={['WORK_CD']}
                horizonMode
                virtualized
                excelDownload
                sortable
                filterable
            >
                <DataColumn 
                    fieldName='WORK_NM' 
                    title='사업장명' 
                    total={(values, data, group) => 
                        group == null ? `- 합 계 ${totalCount(values)} 건 -` : `(소 계 ${totalCount(values)} 건) ${data[0]['WORK_NM']}`} 
                />
                <DataColumn fieldName='OUTM_DATE' title='출금일' />
                <DataColumn fieldName='AMOUNT' title='금액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='WORK_CD' visible={true} />
            </DataGrid>
        </div>

    )
}

export default SendMoneyList