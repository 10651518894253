import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, TextField, CircularProgress, Checkbox, ListItemText, Input } from '@material-ui/core';
import { callProc } from '../common/DBConnector';

/*eslint-disable eqeqeq*/
const ComboBox = (props) => {
    const { data, onChange, value, selectProc, selectParam, keyField = 'k', valueField = 'v', onDataLoad, nullable, multiple, ...fieldProps } = props;
    const [list, setList] = useState();
    const [loading, setLoading] = useState(false);

    const hasValue = () => multiple ? value : (value != null && list && list.find(item => item[keyField] == value))

    const getValue = () => multiple ? value || [] : (hasValue() ? value : '')

    const handleChange = (value) => {
        onChange && onChange(value === '' ? null : value)
    }

    useEffect(() => {
        hasValue() && onChange && onChange(value);
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const fetchData = async() => {
            setLoading(true);
            
            const rawData = !selectProc ?
                data :
                (await callProc(selectProc, selectParam)).data;
            const jsonData = !selectProc && !Array.isArray(rawData) ?
                rawData :
                rawData.reduce((result, item) => ({
                    ...result,
                    [item[keyField]]: item[valueField]
                }), {});

            let result;
            result = onDataLoad ? onDataLoad(jsonData, rawData) : rawData;
            result = Array.isArray(result) ?
                result :
                Object.keys(result).map(item => ({
                    [keyField]: item,
                    [valueField]: result[item]
                }));
            setList(nullable ? [
                {
                    [keyField]: null,
                    [valueField]: '선택 안함',
                },
                ...result
            ] : result);

            if (result.find(item => item[keyField] == value) || (nullable && value === null) || multiple) {
                handleChange(value);
            } else {
                handleChange((result[0] && result[0][keyField]) || null);
            }

            setLoading(false);
        }
        ((selectProc && selectParam) || data) && fetchData();
        // eslint-disable-next-line
    }, [data, selectProc, selectParam, keyField, valueField])

    return (
        loading || !list ? <CircularProgress size={25} /> :
        <TextField
            select
            fullWidth
            onChange={e => handleChange(e.target.value)}
            value={getValue()}
            SelectProps={multiple ? {
                renderValue: (selected) => list && selected.map(item => list.find(e => e[keyField] == item)[valueField]).join(', '),
                multiple: true,
                MenuProps: { getContentAnchorEl: null }
            } : {
                renderValue: (selected) => list.find(item => item[keyField] == selected)[valueField]
            }}
            input={<Input />}
            {...fieldProps}
        >
            {!list ?
            <MenuItem /> : 
            list.map(item => (
                <MenuItem key={item[keyField]} value={item[keyField]}>
                    <ListItemText primary={item[valueField]} />
                    {multiple &&
                    <Checkbox checked={list.find(listItem => listItem[keyField] == item[keyField])} />}
                </MenuItem>
            ))}
        </TextField>
    )
}

ComboBox.defaultProps = {
    selectParam: {},
};

ComboBox.propTypes = {
    data: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]),
}

export default ComboBox;