import React, { useState, Fragment, useRef, useMemo } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { DataGrid, SearchItem, DatePicker, ComboBox, SearchPanel, DataColumn, Tabs, Tab, GridHeaderBand, BandItem, PdfButton } from "../../component";
import { makeStyles } from '@material-ui/core';
import { SupplySumPdf } from '../../reports';

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    content : {
        display: 'flex',
        flex: 1,
        height : 0,
    },
    tabbar : {
        marginBottom: theme.spacing(1),
    },
    hide : {
        display: 'none'
    },
}))

const SupplyInfoRange = () => {
    const [condition, setCondition] = useState(null);
    const [selectTab, setSelectTab] = useState(0);
    const workNm = useRef();

    const classes = useStyles();
    
    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => setCondition(condition)}>
                <SearchItem
                    fieldName='WORK_CD'
                    component={(state, dispatch) => (
                        <ComboBox
                            label='사업장명'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => dispatch(value)}
                            onDataLoad={jsonData => {
                                workNm.current = jsonData;
                                return jsonData;
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_MONTH'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작월'
                            monthPicker
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_MONTH']) {
                                    dispatch('END_MONTH', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_MONTH'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료월'
                            monthPicker
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_MONTH']) {
                                    dispatch('STA_MONTH', value);
                                }
                            }}
                        />
                    )}
                />

            </SearchPanel>
            <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                <Tab label='집계' />
            </Tabs>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <SumInfo condition={condition} workNm={workNm.current} />
            </div>
        </div>
    )
}

const SumInfo = ({ condition, workNm }) => {
    const [pdfData, setPdfData] = useState();

    const pdfComponent = useMemo(() => (
        <PdfButton title='상품수불장'>
            {pdfData && 
            condition &&
            <SupplySumPdf
                workCd={condition['WORK_CD']}
                workName={workNm[condition['WORK_CD']]}
                sta_Month={condition['STA_MONTH']}
                end_Month={condition['END_MONTH']}
                data={pdfData || []}
                range
            />}
        </PdfButton>
    ), [condition, pdfData, workNm]);


    return (
        <Fragment>
            <DataGrid
                title='상품 수불장'
                selectProc='SLT_SUPPLY_MONTHLY_SUM_RANGE'
                selectParam={condition}
                totalGroup={['GROUP_CD']}
                headerItem={({data}) => {
                    setPdfData(data);
                    return pdfComponent;
                }}
                stickyHeader
                horizonMode
            >
                <GridHeaderBand>
                    <BandItem
                        span={1}
                    >
                        정보
                    </BandItem>
                    <BandItem
                        span={3}
                    >
                        전기 이월
                    </BandItem>
                    <BandItem
                        span={3}
                    >
                        당기 매입
                    </BandItem>
                    <BandItem
                        span={3}
                    >
                        당기 판매
                    </BandItem>
                    <BandItem
                        span={3}
                    >
                        차기 이월
                    </BandItem>
                </GridHeaderBand>
                <DataColumn
                    fieldName='GROUP_CD'
                    visible={false}
                />
                <DataColumn
                    fieldName='GDS_NM'
                    mergeField='GDS_NM'
                />
                <DataColumn
                    fieldName='BEF_STOCK_QTY'
                    title='수량'
                    total={(values) => values.reduce((result, value) => result + value, 0)}
                />
                <DataColumn
                    fieldName='BEF_STOCK_PRICE'
                    title='단가'
                />
                <DataColumn
                    fieldName='BEF_STOCK_AMOUNT'
                    title='금액'
                    total={(values) => values.reduce((result, value) => result + value, 0)}
                />
                <DataColumn
                    fieldName='IN_QTY'
                    title='수량'
                    total={(values) => values.reduce((result, value) => result + value, 0)}
                />
                <DataColumn
                    fieldName='IN_PRICE'
                    title='단가'
                />
                <DataColumn
                    fieldName='IN_AMOUNT'
                    title='금액'
                    total={(values) => values.reduce((result, value) => result + value, 0)}
                />
                <DataColumn
                    fieldName='SALE_QTY'
                    title='수량'
                    total={(values) => values.reduce((result, value) => result + value, 0)}
                />
                <DataColumn
                    fieldName='SALE_PRICE'
                    title='단가'
                />
                <DataColumn
                    fieldName='SALE_AMOUNT'
                    title='금액'
                    total={(values) => values.reduce((result, value) => result + value, 0)}
                />
                <DataColumn
                    fieldName='STOCK_QTY'
                    title='수량'
                    total={(values) => values.reduce((result, value) => result + value, 0)}
                />
                <DataColumn
                    fieldName='STOCK_PRICE'
                    title='단가'
                />
                <DataColumn
                    fieldName='STOCK_AMOUNT'
                    title='금액'
                    total={(values) => values.reduce((result, value) => result + value, 0)}
                />
                <DataColumn fieldName='DEL_TYPE' visible={false} />
            </DataGrid>
        </Fragment>
    )
}

export default SupplyInfoRange;