import React, { useEffect, useState } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DatePicker, DataColumn, Tab, Tabs, IconButton } from '../../component';
import { makeStyles } from '@material-ui/core';
import GridOnIcon from '@material-ui/icons/GridOn';
import { dataToWorkbook, downloadWorkbook } from '../../common/Utils';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        width: 0,
    },
    tabContainer: {
        display: 'flex',
    },
    tabbar: {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    tab: {
        minWidth: 100,
    },
    // content: {
    //     display: 'flex',
    //     flex: 1,
    //     height: 0,
    // },
    // hide: {
    //     display: 'none'
    // },
}))


const EFMSList = () => {
    const [condition, setCondition] = useState(null);
    const [selectTab, setSelectTab] = useState(0);

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <SearchPanel onSearch={condition => {
                if (condition['WORK_CD'] !== 'ALL'){
                    sessionStorage['WORK_CD'] = condition['WORK_CD'];
                }
                sessionStorage['STA_DATE'] = condition['STA_DATE'];
                sessionStorage['END_DATE'] = condition['END_DATE'];
                setCondition(condition);
                }}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => dispatch(value)}
                            onDataLoad={jsonData => ({ALL: '전체', ...jsonData})}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={sessionStorage['STA_DATE'] || moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={sessionStorage['END_DATE'] || moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
            </SearchPanel>
            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab className={classes.tab} label='은행이체' />
                    <Tab className={classes.tab} label='자동이체' />
                    <Tab className={classes.tab} label='지로' />
                    <Tab className={classes.tab} label='급여' />
                </Tabs>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <BankMove condition={condition} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <AutoMove condition={condition} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 2 })}>
                <Jiro condition={condition} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 3 })}>
                <Salary condition={condition} />
            </div>
        </div>
    )
}

const BankMove = ({ condition }) => {
    const [gridParam, setGridParam] = useState(null);
    const [selectedRow, setSelectedRow] = useState();

    useEffect(() => {
        condition && setGridParam({...condition, IO_TYPE: 2}) // 계좌
    }, [condition])

    const excelDown = async(fields) => {
        downloadWorkbook('eFMS 자료조회(은행이체).xls', 
        await dataToWorkbook('eFMS', {data: selectedRow, fields}, { WORK_NM: '입금 통장 표시', IN_PERSON_CD: '입금인 코드', CST_KEY: '업체 사용 KEY' }));
    }

    return (
        <DataGrid
            title='은행이체'
            selectProc='SLT_EFMS_LIST'
            selectParam={gridParam}
            selectionMode='multi'
            onSelect={row => setSelectedRow(row)}
            horizonMode
            headerItem={({fields}) => (
                <IconButton
                    tooltip='엑셀 다운로드'
                    icon={<GridOnIcon />}
                    onClick={() => excelDown(fields)}
                />
            )}
        >
            <DataColumn fieldName='EFMS_NM' title='입금 통장 표시' />
            <DataColumn fieldName='IN_PERSON_CD' title='입금인 코드' />
            <DataColumn fieldName='CST_KEY' title='업체 사용 KEY' />
        </DataGrid> 
    )
}

const AutoMove = ({ condition }) => {
    const [gridParam, setGridParam] = useState(null);
    const [selectedRow, setSelectedRow] = useState();

    useEffect(() => {
        condition && setGridParam({...condition, IO_TYPE: 3}) // 자동
    }, [condition])

    const excelDown = async(fields) => {
        downloadWorkbook('eFMS 자료조회(자동이체).xls', 
        await dataToWorkbook('eFMS', {data: selectedRow, fields}, { EFMS_NM: '입금 통장 표시', IN_PERSON_CD: '입금인 코드', CST_KEY: '업체 사용 KEY' }));
    }

    return (
        <DataGrid
            title='자동이체'
            selectProc='SLT_EFMS_LIST'
            selectParam={gridParam}
            selectionMode='multi'
            onSelect={row => setSelectedRow(row)}
            horizonMode
            headerItem={({fields}) => (
                <IconButton
                    tooltip='엑셀 다운로드'
                    icon={<GridOnIcon />}
                    onClick={() => excelDown(fields)}
                />
            )}
        >
            <DataColumn fieldName='EFMS_NM' title='입금 통장 표시' />
            <DataColumn fieldName='IN_PERSON_CD' title='입금인 코드' />
            <DataColumn fieldName='CST_KEY' title='업체 사용 KEY' />
        </DataGrid> 
    )
}

const Jiro = ({ condition }) => {
    const [gridParam, setGridParam] = useState(null);
    const [selectedRow, setSelectedRow] = useState();

    useEffect(() => {
        condition && setGridParam({...condition, IO_TYPE: 4}) // 지로
    }, [condition])

    const excelDown = async(fields) => {
        downloadWorkbook('eFMS 자료조회(지로).xls', 
        await dataToWorkbook('eFMS', {data: selectedRow, fields}, { EFMS_NM: '입금 통장 표시', IN_PERSON_CD: '입금인 코드', CST_KEY: '업체 사용 KEY' }));
    }

    return (
        <DataGrid
            title='지로'
            selectProc='SLT_EFMS_LIST'
            selectParam={gridParam}
            selectionMode='multi'
            onSelect={row => setSelectedRow(row)}
            horizonMode
            headerItem={({fields}) => (
                <IconButton
                    tooltip='엑셀 다운로드'
                    icon={<GridOnIcon />}
                    onClick={() => excelDown(fields)}
                />
            )}
        >
            <DataColumn fieldName='EFMS_NM' title='입금 통장 표시' />
            <DataColumn fieldName='IN_PERSON_CD' title='입금인 코드' />
            <DataColumn fieldName='CST_KEY' title='업체 사용 KEY' />
        </DataGrid> 
    )
}

const Salary = ({ condition }) => {
    const [selectedRow, setSelectedRow] = useState();

    const excelDown = async(fields) => {
        downloadWorkbook('eFMS 자료조회(급여).xls', 
        await dataToWorkbook('eFMS', {data: selectedRow, fields}, { WORK_NM: '입금 통장 표시', IN_PERSON_CD: '입금인 코드', CST_KEY: '업체 사용 KEY' }));
    }

    return (
        <DataGrid
            title='급여'
            selectProc='SLT_EFMS_SALARY_LIST'
            selectParam={condition}
            selectionMode='multi'
            onSelect={row => setSelectedRow(row)}
            horizonMode
            headerItem={({fields}) => (
                <IconButton
                    tooltip='엑셀 다운로드'
                    icon={<GridOnIcon />}
                    onClick={() => excelDown(fields)}
                />
            )}
        >
            <DataColumn fieldName='EFMS_NM' title='입금 통장 표시' />
            <DataColumn fieldName='IN_PERSON_CD' title='입금인 코드' />
            <DataColumn fieldName='CST_KEY' title='업체 사용 KEY' />
        </DataGrid> 
    )
}

export default EFMSList;