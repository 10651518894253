import React, { Fragment, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import * as xlsx from 'xlsx';
import { Button, Dialog, DialogTitle, makeStyles, TextField } from '@material-ui/core';
import { ComboBox, DataColumn, DataGrid, DatePicker, IconButton, ProgressDialog, ProgressPanel, SearchItem, SearchPanel } from '../../component';
import { callProc, sendMail } from '../../common/DBConnector';
import { taxType, pblType } from '../../common/Dictionary';
import { TaxInvoicePdf, TradingReportCarPdf, TradingReportGoodsPdf } from '../../reports';
import { BlobProvider } from '@react-pdf/renderer';
import PdfDialog from '../../component/PdfDialog';
import GridOnIcon from '@material-ui/icons/GridOn';
import PageviewIcon from '@material-ui/icons/Pageview';
import MailIcon from '@material-ui/icons/Mail';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';


const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
}));

const sendType = {
    0: '미발송',
    1: '발송완료',
}

const confmTypeCombo = [
    { key: 0, value: '미승인' },
    { key: 1, value: '승인' },
]

const confmType = confmTypeCombo.reduce((result, item) => ({
    ...result,
    [item.key]: item.value
}), {})

const decTypeCombo = [
    { key: 0, value: '미신고' },
    { key: 1, value: '신고완료' },
]

const decType = decTypeCombo.reduce((result, item) => ({
    ...result,
    [item.key]: item.value
}), {})


const dltTypeCombo = [
    { key: 0, value: '정상' },
    { key: 1, value: '서손' },
]

const dltType = dltTypeCombo.reduce((result, item) => ({
    ...result,
    [item.key]: item.value
}), {})

const pblLoc = {
    1: '일괄발행',
    2: '상품별발행',
    3: '개별발행',
}

const TaxInvoiceMng = ({ restAuth }) => {
    const [condition, setCondition] = useState(null);
    const [schWorkData, setSchWorkData] = useState();
    const [workData, setWorkData] = useState();
    const [cstData, setCstData] = useState();
    
    const [loading, setLoading] = useState(false);
    const [preview, setPreview] = useState();
    const [mailDialog, setMailDialog] = useState();
    const [selectedRow, setSelectedRow] = useState([]);
    const [selectedRowSort, setSelectedRowSort] = useState([]);

    const [taxData, setTaxData] = useState();
    const [taxDatas, setTaxDatas] = useState();
    const [noTaxDatas, setNoTaxDatas] = useState();

    const [taxRenderer, setTaxRenderer] = useState([]);
    const [cstRenderer, setCstRenderer] = useState([]);
    const [carRenderer, setCarRenderer] = useState([]);

    const conf = useSelector(state => state.configReducer);
    const taxInvPdf = useRef([]);
    const trdCarPdf = useRef({});
    const trdCstPdf = useRef({});

    const snack = useRef();
    const sended = useRef({
        success: 0,
        failed: 0,
    });

    useEffect(() => {
        const taxTypes = selectedRow && selectedRow.length > 0 && selectedRow.reduce((result, value) => [...result, value['TAX_TYPE']], [])
        setSelectedRowSort(taxTypes && Array.from(new Set(taxTypes)))
    }, [selectedRow])

    useEffect(() => {
        const fetchData = async() => {
            const workData = (await callProc('SLT_WORKPLACEINFO_PRNT_AUTH')).data;
            const cstData = await callProc('SLT_CUSTOMERINFO_SALE_ALL');

            setWorkData(workData.reduce((result, row) => ({
                ...result,
                [row['WORK_CD']]: row
            }), {}));
            setSchWorkData(workData.reduce((result, row) => ({
                ...result,
                [row['WORK_PRNT']]: row['WORK_PRNT_NM']
            }), {}));
            setCstData(cstData.data.reduce((result, item) => ({
                ...result,
                [item['WORK_CD']]: {
                    ...result[item['WORK_CD']],
                    [item['CST_CD']]: item
                }
            }), {}))
        }
        fetchData();
    }, [])

    useEffect(() => {
        const fetchData = async() => {
            setTaxData(await callProc('SLT_TAXINVOICEINFO', condition));
        }
        condition && fetchData();
    }, [condition])

    useEffect(() => {
        let taxDatas = taxData && taxData.data.reduce((result, value) => (value['TAX_TYPE'] !== '05' ? [...result, value] : [...result]), [])
        let noTaxDatas = taxData && taxData.data.reduce((result, value) => (value['TAX_TYPE'] === '05' ? [...result, value] : [...result]), [])
        setTaxDatas(taxDatas)
        setNoTaxDatas(noTaxDatas)
    }, [taxData])

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const classes = useStyles();

    const hasValue = value => value != null && value.toString().trim() !== '';
    
    const toInt = value => hasValue(value) ? parseInt(value) : 0

    const calcItmPrice = (row, field) => {
        let result = 0;
        for (let i = 1; i <= 4; i++) {
            result += toInt(row[field + i]);
        }
        return result;
    }


    const createCell = (value) => ({
        v: value != null ? value.toString() : '',
        w: value != null ? value.toString() : '',
        t: 's',
        z: '@',
    });

    const setItemCell = (sheet, col, row, data, idx) => {
        const colIdx = xlsx.utils.decode_col(col);
        sheet[xlsx.utils.encode_col(colIdx) + row] = createCell(data['TRD_DATE' + idx]);
        sheet[xlsx.utils.encode_col(colIdx + 1) + row] = createCell(data['ITM_NM' + idx]);
        sheet[xlsx.utils.encode_col(colIdx + 2) + row] = createCell(data['UNIT' + idx]);
        sheet[xlsx.utils.encode_col(colIdx + 3) + row] = createCell(data['QTY' + idx]);
        sheet[xlsx.utils.encode_col(colIdx + 4) + row] = createCell(data['PRICE' + idx]);
        sheet[xlsx.utils.encode_col(colIdx + 5) + row] = createCell(data['SPLY_AMT' + idx]);
        sheet[xlsx.utils.encode_col(colIdx + 6) + row] = createCell(data['VAT' + idx]);
        sheet[xlsx.utils.encode_col(colIdx + 7) + row] = createCell(data['ITM_REMARK' + idx]);
    }

    const downXls = async(data) => {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", "/tax_enroll.xls");
        xhr.responseType = "blob";
        xhr.onload = () => {
            const reader = new FileReader();
            reader.onload = async(evt) => {
                const workbook = xlsx.read(evt.target.result, { type: 'binary', cellStyles: true,  });
                const sheets = workbook.Sheets;
                const sheet = sheets[workbook.SheetNames[0]];
                for (let i = 0; i < data.length; i++) {
                    const row = data[i];
                    const workRow = workData[row['WORK_CD']];
                    sheet['A' + (7 + i)] = createCell(row['TAX_TYPE']);
                    sheet['B' + (7 + i)] = createCell(row['WRT_DATE'].replace(/-/g, ''));
                    sheet['C' + (7 + i)] = createCell(workRow['WORK_BIZNO']);
                    sheet['E' + (7 + i)] = createCell(workRow['WORK_PRNT_NM']);
                    sheet['F' + (7 + i)] = createCell(workRow['CEO']);
                    sheet['G' + (7 + i)] = createCell((workRow['ADDR'] || '') + ' ' + (workRow['ADDR_DET'] || ''));
                    sheet['H' + (7 + i)] = createCell(workRow['BIZ_COND']);
                    sheet['I' + (7 + i)] = createCell(workRow['BIZ_ITEM']);
                    sheet['J' + (7 + i)] = createCell(workRow['EMAIL']);
                    sheet['K' + (7 + i)] = createCell(row['CST_BIZNO']);
                    sheet['L' + (7 + i)] = createCell(row['CST_SUB_BIZNO']);
                    sheet['M' + (7 + i)] = createCell(row['CST_NM']);
                    sheet['N' + (7 + i)] = createCell(row['CST_CEO']);
                    sheet['O' + (7 + i)] = createCell(row['CST_ADDR']);
                    sheet['P' + (7 + i)] = createCell(row['CST_COND']);
                    sheet['Q' + (7 + i)] = createCell(row['CST_ITEM']);
                    sheet['R' + (7 + i)] = createCell(row['CST_EMAIL1']);
                    sheet['S' + (7 + i)] = createCell(row['CST_EMAIL2']);
                    sheet['T' + (7 + i)] = createCell(calcItmPrice(row, 'SPLY_AMT'));
                    sheet['U' + (7 + i)] = createCell(calcItmPrice(row, 'VAT'));
                    sheet['V' + (7 + i)] = createCell(row['REMARK']);
                    setItemCell(sheet, 'W', 7 + i, row, 1);
                    setItemCell(sheet, 'AE', 7 + i, row, 2);
                    setItemCell(sheet, 'AM', 7 + i, row, 3);
                    setItemCell(sheet, 'AU', 7 + i, row, 4);
                    sheet['BC' + (7 + i)] = createCell(row['CASH']);
                    sheet['BD' + (7 + i)] = createCell(row['CHECK']);
                    sheet['BE' + (7 + i)] = createCell(row['BILL']);
                    sheet['BF' + (7 + i)] = createCell(row['CREDIT']);
                    sheet['BG' + (7 + i)] = createCell(row['PBL_TYPE']);
                }
                xlsx.writeFile(workbook, '세금계산서 등록.xls', { bookSST: true, cellStyles: true });
            };
            reader.readAsBinaryString(xhr.response);
        }
        xhr.send();
    }

    const setItemCellNoTax = (sheet, col, row, data, idx) => {
        const colIdx = xlsx.utils.decode_col(col);
        sheet[xlsx.utils.encode_col(colIdx) + row] = createCell(data['TRD_DATE' + idx]);
        sheet[xlsx.utils.encode_col(colIdx + 1) + row] = createCell(data['ITM_NM' + idx]);
        sheet[xlsx.utils.encode_col(colIdx + 2) + row] = createCell(data['UNIT' + idx]);
        sheet[xlsx.utils.encode_col(colIdx + 3) + row] = createCell(data['QTY' + idx]);
        sheet[xlsx.utils.encode_col(colIdx + 4) + row] = createCell(data['PRICE' + idx]);
        sheet[xlsx.utils.encode_col(colIdx + 5) + row] = createCell(data['SPLY_AMT' + idx]);
        sheet[xlsx.utils.encode_col(colIdx + 6) + row] = createCell(data['ITM_REMARK' + idx]);
    }

    const downXlsNoTax = async(data) => {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", "/notax_enroll.xls");
        xhr.responseType = "blob";
        xhr.onload = () => {
            const reader = new FileReader();
            reader.onload = async(evt) => {
                const workbook = xlsx.read(evt.target.result, { type: 'binary', cellStyles: true,  });
                const sheets = workbook.Sheets;
                const sheet = sheets[workbook.SheetNames[0]];
                for (let i = 0; i < data.length; i++) {
                    const row = data[i];
                    const workRow = workData[row['WORK_CD']];
                    sheet['A' + (7 + i)] = createCell(row['TAX_TYPE']);
                    sheet['B' + (7 + i)] = createCell(row['WRT_DATE'].replace(/-/g, ''));
                    sheet['C' + (7 + i)] = createCell(workRow['WORK_BIZNO']);
                    sheet['E' + (7 + i)] = createCell(workRow['WORK_PRNT_NM']);
                    sheet['F' + (7 + i)] = createCell(workRow['CEO']);
                    sheet['G' + (7 + i)] = createCell((workRow['ADDR'] || '') + ' ' + (workRow['ADDR_DET'] || ''));
                    sheet['H' + (7 + i)] = createCell(workRow['BIZ_COND']);
                    sheet['I' + (7 + i)] = createCell(workRow['BIZ_ITEM']);
                    sheet['J' + (7 + i)] = createCell(workRow['EMAIL']);
                    sheet['K' + (7 + i)] = createCell(row['CST_BIZNO']);
                    sheet['L' + (7 + i)] = createCell(row['CST_SUB_BIZNO']);
                    sheet['M' + (7 + i)] = createCell(row['CST_NM']);
                    sheet['N' + (7 + i)] = createCell(row['CST_CEO']);
                    sheet['O' + (7 + i)] = createCell(row['CST_ADDR']);
                    sheet['P' + (7 + i)] = createCell(row['CST_COND']);
                    sheet['Q' + (7 + i)] = createCell(row['CST_ITEM']);
                    sheet['R' + (7 + i)] = createCell(row['CST_EMAIL1']);
                    sheet['S' + (7 + i)] = createCell(row['CST_EMAIL2']);
                    sheet['T' + (7 + i)] = createCell(calcItmPrice(row, 'SPLY_AMT'));
                    sheet['U' + (7 + i)] = createCell(row['REMARK']);
                    setItemCellNoTax(sheet, 'V', 7 + i, row, 1);
                    setItemCellNoTax(sheet, 'AC', 7 + i, row, 2);
                    setItemCellNoTax(sheet, 'AJ', 7 + i, row, 3);
                    setItemCellNoTax(sheet, 'AQ', 7 + i, row, 4);
                    sheet['AX' + (7 + i)] = createCell(row['CASH']);
                    sheet['AY' + (7 + i)] = createCell(row['CHECK']);
                    sheet['AZ' + (7 + i)] = createCell(row['BILL']);
                    sheet['BA' + (7 + i)] = createCell(row['CREDIT']);
                    sheet['BB' + (7 + i)] = createCell(row['PBL_TYPE']);
                }
                xlsx.writeFile(workbook, '세금계산서 등록(면세).xls', { bookSST: true, cellStyles: true });
            };
            reader.readAsBinaryString(xhr.response);
        }
        xhr.send();
    }

    const validMail = (selection) => {
        for (let i = 0; i < selection.length; i++) {
            const row = selection[i];
            if (!hasValue(row['CST_EMAIL1']) && !hasValue(row['CST_EMAIL2'])) {
                enqueueSnackbar('이메일이 등록되지 않은 계산서가 있습니다.', { variant: 'error' });
                return false;
            }
        }
        return true;
    }

    const onSendMailConfirm = async(selection) => {
        snack.current && closeSnackbar(snack.current);
        setLoading(true);

        const trdParam = selection.reduce((result, row) => (
            !row['TRD_STA_DATE'] ||
            !row['TRD_END_DATE'] ||
            result.find(item =>
                item['WORK_CD'] == row['WORK_CD'] &&
                item['CST_CD'] === row['CST_CD'] &&
                item['STA_DATE'] === row['TRD_STA_DATE'] &&
                item['END_DATE'] === row['TRD_END_DATE']) ?
            result :
            [...result, {
                WORK_CD: row['WORK_CD'],
                CST_CD: row['CST_CD'],
                STA_DATE: row['TRD_STA_DATE'],
                END_DATE: row['TRD_END_DATE'],
            }]
        ), [])

        const cstData = [];
        const carData = [];
        for (let i = 0; i < trdParam.length; i++) {
            const sumData = (await callProc('SLT_CUSTOMER_SALE_GOODSSUM', trdParam[i])).data;
            const bondData = (await callProc('SLT_CUSTOMER_BOND_LIST', trdParam[i])).data;
            cstData.push({
                param: trdParam[i],
                data: (await callProc('SLT_CUSTOMER_GOODS_SORT', trdParam[i])).data,
                sumData: sumData,
                bondData: bondData,
            });
            carData.push({
                param: trdParam[i],
                data: (await callProc('SLT_CUSTOMER_CARNO_SORT', trdParam[i])).data,
                sumData: sumData,
                bondData: bondData,
            });
        }
        setTaxRenderer(selection);
        setCstRenderer(cstData);
        setCarRenderer(carData);
    }

    const reqSendMail = (selection) => {
        snack.current && closeSnackbar(snack.current);
        if (validMail(selection)) {
            snack.current = enqueueSnackbar('메일을 발송할까요?', {
                autoHideDuration: null,
                action: key => <>
                    <Button onClick={() => onSendMailConfirm(selection)}>
                        예
                    </Button>
                    <Button onClick={() => closeSnackbar(key)}>
                        아니오
                    </Button>
                </>,
            })
        }
    }

    const readBlobAsync = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    })

    const getTrdKey = param => {
        const result = 
        param['WORK_CD'] +
        param['CST_CD'] +
        (param['STA_DATE'] || param['TRD_STA_DATE']) +
        (param['END_DATE'] || param['TRD_END_DATE']);
        return result;
    }
        

    const onTaxRender = async({ blob, error }, param) => {
        if (!error) {
            const base64data = await readBlobAsync(blob);
            taxInvPdf.current.push({
                pdf: base64data,
                param: param,
            });
        } else {
            taxInvPdf.current.push(null);
        }

        const [, ...rest] = taxRenderer;
        setTaxRenderer(rest);
    }

    const onCstRender = async({ blob, error }, param) => {
        if (!error) {
            const base64data = await readBlobAsync(blob);
            trdCstPdf.current = {
                ...trdCstPdf.current,
                [getTrdKey(param)]: base64data
            };
        }

        const [, ...rest] = cstRenderer;
        setCstRenderer(rest);
    }

    const onCarRender = async({ blob, error }, param) => {
        if (!error) {
            const base64data = await readBlobAsync(blob);
            trdCarPdf.current = {
                ...trdCarPdf.current,
                [getTrdKey(param)]: base64data
            };
        }

        const [, ...rest] = carRenderer;
        setCarRenderer(rest);
    }

    useEffect(() => {
        const onSend = async() => {
            for (let i = 0; i < taxInvPdf.current.length; i++) {
                const row = taxInvPdf.current[i].param;
                const taxInv = taxInvPdf.current[i].pdf;
                let trdCst;
                let trdCar;
                const fileList = [{
                    filename: `${workData[row['WORK_CD']]['WORK_PRNT_NM']} 세금계산서.pdf`,
                    path: taxInv,
                }];
                if (row['TRD_STA_DATE']) {
                    trdCst = trdCstPdf.current[getTrdKey(row)];
                    trdCar = trdCarPdf.current[getTrdKey(row)];
                    fileList.push({
                        filename: `${workData[row['WORK_CD']]['WORK_PRNT_NM']} 거래명세서.pdf`,
                        path: trdCst,
                    });
                    fileList.push({
                        filename: `${workData[row['WORK_CD']]['WORK_PRNT_NM']} 차량별 거래명세서.pdf`,
                        path: trdCar,
                    });
                }

                const valid = () => {
                    if (row['TRD_STA_DATE']) {
                        return taxInv && trdCst && trdCar;
                    } else {
                        return taxInv;
                    }
                }

                if (valid()) {
                    const result = await sendMail(
                        workData[row['WORK_CD']]['EMAIL'],
                        workData[row['WORK_CD']]['EMAIL_PASS'],
                        workData[row['WORK_CD']]['WORK_PRNT_NM'],
                        [row['CST_EMAIL1'], row['CST_EMAIL2']],
                        `${workData[row['WORK_CD']]['WORK_PRNT_NM']} 세금계산서`,
                        getMailContent(workData[row['WORK_CD']], row),
                        fileList
                    )
        
                    if (!result.err) {
                        sended.current.success++;
                        await callProc('UPT_TAXINVOICE_MAIL', { WORK_CD: row['WORK_CD'], TAX_CD: row['TAX_CD'] });
                    } else {
                        sended.current.failed++;
                    }
                } else {
                    sended.current.failed++;
                }
            }
    
            setCondition(condition => ({ ...condition }));
            taxInvPdf.current = [];
            trdCstPdf.current = {};
            trdCarPdf.current = {};
            
            enqueueSnackbar(`발송이 완료되었습니다.\n
            발송 : ${sended.current.success}건
            실패 : ${sended.current.failed}건`);
            sended.current.success = 0;
            sended.current.failed = 0;
            setLoading(false);
        }
        taxRenderer.length === 0 && cstRenderer.length === 0 && carRenderer.length === 0 &&
        taxInvPdf.current.length > 0 && onSend();
    }, [taxRenderer, cstRenderer, carRenderer, enqueueSnackbar, workData])

    const reqConfirm = (data) => {
        snack.current && closeSnackbar(snack.current);
        snack.current = enqueueSnackbar('승인할까요?', {
            autoHideDuration: null,
            action: key => <>
                <Button onClick={() => {
                    closeSnackbar(key);
                    onConfirm(data);
                }}>
                    예
                </Button>
                <Button onClick={() => closeSnackbar(key)}>
                    아니오
                </Button>
            </>,
        })
    }

    const onConfirm = async(data) => {
        setLoading(true);

        const result = await callProc('UPT_TAXINVOICE_CONFIRM', { ARR_TAX: JSON.stringify(data) });
        if (!result.err) {
            setCondition({ ...condition });
            enqueueSnackbar('승인이 완료되었습니다.');
        }

        setLoading(false);
    }

    const reqDec = (data) => {
        snack.current && closeSnackbar(snack.current);
        snack.current = enqueueSnackbar('국세청 신고처리를 할까요?', {
            autoHideDuration: null,
            action: key => <>
                <Button onClick={() => {
                    closeSnackbar(key);
                    onDec(data);
                }}>
                    예
                </Button>
                <Button onClick={() => closeSnackbar(key)}>
                    아니오
                </Button>
            </>,
        })
    }

    const onDec = async(data) => {
        setLoading(true);

        const result = await callProc('UPT_TAXINVOICE_DEC', { ARR_TAX: JSON.stringify(data) });
        if (!result.err) {
            setCondition({ ...condition });
            enqueueSnackbar('신고처리가 완료되었습니다.');
        }

        setLoading(false);
    }

    const validDelete = (data) => {
        return true;
    }

    const reqDelete = (data) => {
        snack.current && closeSnackbar(snack.current);
        if (validDelete(data)) {
            snack.current = enqueueSnackbar('서손할까요?', {
                variant: 'warning',
                autoHideDuration: null,
                action: key => <>
                    <Button onClick={() => {
                        closeSnackbar(key);
                        onDelete(data);
                    }}>
                        예
                    </Button>
                    <Button onClick={() => closeSnackbar(key)}>
                        아니오
                    </Button>
                </>,
            })
        }
    }

    const onDelete = async(data) => {
        setLoading(true);

        const result = await callProc('DLT_TAXINVOICE_JSON', { ARR_TAX: data.map(item => item['TAX_CD']) });
        if (!result.err) {
            setCondition({ ...condition });
            enqueueSnackbar('서손이 완료되었습니다.');
        }

        setLoading(false);
    }

    const getItemColumn = (idx) => [
        <DataColumn key={1} fieldName={'TRD_DATE' + idx} visible={false} />,
        <DataColumn key={2} fieldName={'ITM_NM' + idx} visible={false} />,
        <DataColumn key={3} fieldName={'UNIT' + idx} visible={false} />,
        <DataColumn key={4} fieldName={'QTY' + idx} visible={false} />,
        <DataColumn key={5} fieldName={'PRICE' + idx} visible={false} />,
        <DataColumn key={6} fieldName={'SPLY_AMT' + idx} visible={false} />,
        <DataColumn key={7} fieldName={'VAT' + idx} visible={false} />,
        <DataColumn key={8} fieldName={'ITM_REMARK' + idx} visible={false} />,
    ]

    return (
        !schWorkData || !workData ? <ProgressPanel /> :
        <div className={classes.container}>
            <MailDialog
                data={mailDialog}
                onClose={() => setMailDialog(null)}
                onSend={(mailAddr1, mailAddr2) => {
                    const mail = { ...mailDialog };
                    mail['CST_EMAIL1'] = mailAddr1;
                    mail['CST_EMAIL2'] = mailAddr2;
                    onSendMailConfirm([mail]);
                }}
            />
            <ProgressDialog open={loading} />
            {taxRenderer[0] &&
            <BlobProvider
                key={taxRenderer[0]['TAX_CD']}
                document={
                    <TaxInvoicePdf
                        workData={workData[taxRenderer[0]['WORK_CD']]}
                        taxData={taxRenderer[0]}
                    />
                }
            >
                {(state) => {
                    !state.loading && onTaxRender(state, taxRenderer[0]);
                    return null;
                }}
            </BlobProvider>}
            {!taxRenderer.length && cstRenderer[0] &&
            <BlobProvider
                key={'0' + getTrdKey(cstRenderer[0].param)}
                document={
                    <TradingReportGoodsPdf
                        workInfo={workData[cstRenderer[0].param['WORK_CD']]}
                        cstInfo={cstData[cstRenderer[0].param['WORK_CD']][cstRenderer[0].param['CST_CD']]}
                        date={cstRenderer[0].param}
                        data={cstRenderer[0].data}
                        sumData={cstRenderer[0].sumData}
                        cstBondData={cstRenderer[0].bondData}
                        conf={conf}
                    />
                }
            >
                {(state) => {
                    !state.loading && onCstRender(state, cstRenderer[0].param);
                    return null;
                }}
            </BlobProvider>}
            {!taxRenderer.length && !cstRenderer.length && carRenderer[0] &&
            <BlobProvider
                key={'1' + getTrdKey(carRenderer[0].param)}
                document={
                    <TradingReportCarPdf
                        workInfo={workData[carRenderer[0].param['WORK_CD']]}
                        cstInfo={cstData[carRenderer[0].param['WORK_CD']][carRenderer[0].param['CST_CD']]}
                        date={carRenderer[0].param}
                        data={carRenderer[0].data}
                        sumData={carRenderer[0].sumData}
                        cstBondData={carRenderer[0].bondData}
                        conf={conf}
                    />
                }
            >
                {(state) => {
                    !state.loading && onCarRender(state, carRenderer[0].param);
                    return null;
                }}
            </BlobProvider>}
            <PdfDialog
                open={preview != null}
                onClose={() => setPreview(null)}
                title='세금계산서'
            >
                <TaxInvoicePdf
                    workData={preview && workData[preview['WORK_CD']]}
                    taxData={preview}
                />
            </PdfDialog>
            
            <SearchPanel onSearch={(condition) => setCondition(condition)}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={schWorkData && Object.keys(schWorkData)[0]}
                    component={(state, dispatch) => (
                        <ComboBox
                            label='사업장명'
                            data={schWorkData}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />

                <SearchItem
                    fieldName='STA_MONTH'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작월'
                            monthPicker
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_MONTH']) {
                                    dispatch('END_MONTH', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_MONTH'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료월'
                            monthPicker
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_MONTH']) {
                                    dispatch('STA_MONTH', value);
                                }
                            }}
                        />
                    )}
                />

                {restAuth ?
                <SearchItem
                    fieldName='CONFM_TYPE'
                    defaultValue='ALL'
                    component={(state, dispatch) => (
                        <ComboBox
                            label='승인구분'
                            data={confmTypeCombo}
                            keyField='key'
                            valueField='value'
                            value={state}
                            onChange={(value) => dispatch(value)}
                            onDataLoad={(jsonData, rawData) => [{ key: 'ALL', value: '전체' }, ...rawData]}
                        />
                    )}
                /> :
                <SearchItem
                    fieldName='DLT'
                    defaultValue='0'
                    component={(state, dispatch) => (
                        <ComboBox
                            label='서손구분'
                            data={dltType}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />}

                <SearchItem
                    fieldName='DEC_TYPE'
                    defaultValue='ALL'
                    component={(state, dispatch) => (
                        <ComboBox
                            label='국세청 신고 구분'
                            data={decTypeCombo}
                            keyField='key'
                            valueField='value'
                            value={state}
                            onChange={(value) => dispatch(value)}
                            onDataLoad={(jsonData, rawData) => [{ key: 'ALL', value: '전체' }, ...rawData]}
                        />
                    )}
                />  
            </SearchPanel>
            <DataGrid
                title='세금계산서'
                dataSet={taxData}
                selectionMode='multi'
                selectable={row => !restAuth || row['CONFM_TYPE'] === 1}
                onSelect={setSelectedRow}
                horizonMode
                headerItem={({selection}) => (condition && <Fragment>
                    {!restAuth && condition['DLT'] === '0' &&
                    <IconButton
                        tooltip='승인'
                        icon={<CheckIcon />}
                        onClick={() => reqConfirm(selection)}
                        disabled={!selection.length}
                    />}
                    {!restAuth && condition['DLT'] === '0' &&
                    <IconButton
                        tooltip='국세청 신고완료'
                        icon={<CloudDoneIcon />}
                        onClick={() => reqDec(selection)}
                        disabled={!selection.length}
                    />}
                    {!restAuth && condition['DLT'] === '0' &&
                    <IconButton
                        tooltip='서손'
                        icon={<DeleteIcon />}
                        onClick={() => reqDelete(selection)}
                        disabled={!selection.length}
                    />}
                    {(restAuth || condition['DLT'] !== '1') &&
                    <IconButton
                        tooltip='메일발송'
                        icon={<MailIcon />}
                        onClick={() => reqSendMail(selection)}
                        disabled={!selection.length}
                    />}
                    {!restAuth &&
                    <IconButton
                        tooltip='엑셀 다운로드'
                        icon={<GridOnIcon />}
                        onClick={() => {
                            if((selectedRowSort && (selectedRowSort.includes('05')) && !(selectedRowSort.includes('01') || selectedRowSort.includes('02')))){
                                return (downXlsNoTax(selection.length ? 
                                    selection.reduce((result, value) => (value['TAX_TYPE'] === '05' ? [...result, value] : [...result]), []) : noTaxDatas))
                            } else if(selectedRowSort && ((selectedRowSort.includes('01') || selectedRowSort.includes('02')) && !selectedRowSort.includes('05'))) {
                                return downXls(selection.length ? selection.reduce((result, value) => (value['TAX_TYPE'] !== '05' ? [...result, value] : [...result]), []) : taxDatas)
                            } else {
                                return [downXls(selection.length ? selection.reduce((result, value) => (value['TAX_TYPE'] !== '05' ? [...result, value] : [...result]), []) : taxDatas), 
                                        (downXlsNoTax(selection.length ? selection.reduce((result, value) => (value['TAX_TYPE'] === '05' ? [...result, value] : [...result]), []) : noTaxDatas))]
                            }
                        }}
                    />}
                </Fragment>)}
            >
                <DataColumn
                    position='CST_NM'
                    value={row => (
                        <IconButton
                            tooltip='미리보기'
                            icon={<PageviewIcon />}
                            onClick={() => setPreview(row)}
                        />
                    )}
                />

    
                <DataColumn
                    position='CST_NM'
                    value={row => (
                        (!restAuth ? condition['DLT'] === '0' : row['CONFM_TYPE'] === 1) &&
                        <IconButton
                            tooltip='개별메일발송'
                            icon={<MailIcon />}
                            onClick={() => setMailDialog(row)}
                        />
                    )}
                /> 

                

                <DataColumn
                    fieldName='PBL_LOC'
                    value={(row, value) => pblLoc[value]}
                />
                <DataColumn
                    fieldName='TAX_TYPE'
                    value={(row, value) => taxType[value]}
                />
                <DataColumn
                    fieldName='PBL_TYPE'
                    value={(row, value) => pblType[value]}
                />
                <DataColumn
                    fieldName='TOTAL_AMT'
                    title='합계금액'
                    total={(values) => values.reduce((result, value) => result + value, 0)}
                />
                <DataColumn
                    fieldName='SPLY_AMT'
                    title='공급가액'
                    total={(values) => values.reduce((result, value) => result + value, 0)}
                />
                <DataColumn
                    fieldName='VAT'
                    title='부가세'
                    total={(values) => values.reduce((result, value) => result + value, 0)}
                />
                <DataColumn
                    fieldName='SEND_TYPE'
                    title='메일'
                    value={(row, value) => sendType[value]}
                />
                <DataColumn
                    fieldName='CONFM_TYPE'
                    title='승인구분'
                    value={(row, value) => confmType[value]}
                />
                <DataColumn
                    fieldName='DEC_TYPE'
                    title='국세청 신고'
                    value={(row, value) => decType[value]}
                />
                <DataColumn fieldName='TRD_STA_DATE' visible={false} />
                <DataColumn fieldName='TRD_END_DATE' visible={false} />
                <DataColumn fieldName='CST_CD' visible={false} />
                <DataColumn fieldName='CST_SUB_BIZNO' visible={false} />
                <DataColumn fieldName='CST_CEO' visible={false} />
                <DataColumn fieldName='CST_ADDR' visible={false} />
                <DataColumn fieldName='CST_COND' visible={false} />
                <DataColumn fieldName='CST_ITEM' visible={false} />
                {getItemColumn(1)}
                {getItemColumn(2)}
                {getItemColumn(3)}
                {getItemColumn(4)}
                <DataColumn fieldName='CASH' visible={false} />
                <DataColumn fieldName='CHECK' visible={false} />
                <DataColumn fieldName='BILL' visible={false} />
                <DataColumn fieldName='CREDIT' visible={false} />
                <DataColumn fieldName='WORK_CD' visible={false} />
                <DataColumn fieldName='TELNO1' visible={false} />
            </DataGrid>
        </div>
    )
}

const useDialogStyles = makeStyles(theme => ({
    dialogPaper: {
        width: 400,
        height: 300,
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    field: {
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    btnContainer: {
        display: 'flex',
        alignSelf: 'flex-end',
        marginTop: 'auto',
        padding: theme.spacing(2),
    }
}))

const MailDialog = ({ data, onClose, onSend }) => {
    const [mailAddr1, setMailAddr1] = useState('');
    const [mailAddr2, setMailAddr2] = useState('');
    
    const classes = useDialogStyles();
    
    const getValue = value => value.trim() === '' ? null : value

    useEffect(() => {
        if (data) {
            setMailAddr1(data['CST_EMAIL1'] || '');
            setMailAddr2(data['CST_EMAIL2'] || '');
        }
    }, [data]);

    const reqSend = () => {
        onSend(getValue(mailAddr1), getValue(mailAddr2));
        onClose();
    }

    const onFieldKey = e => {
        if (e.key === 'Enter') {
            reqSend();
        }
    }

    return (
        <Dialog
            open={data != null}
            onClose={onClose}
            PaperProps={{ className: classes.dialogPaper }}
        >
            <div className={classes.dialogTitle}>
                <DialogTitle>이메일 발송</DialogTitle>
                <IconButton
                    tooltip='닫기'
                    onClick={() => onClose()}
                    icon={<CloseIcon />}
                />
            </div>
            <TextField
                label='이메일1'
                className={classes.field}
                value={mailAddr1}
                onChange={e => setMailAddr1(e.target.value)}
                onKeyPress={onFieldKey}
            />
            <TextField
                label='이메일2'
                className={classes.field}
                value={mailAddr2}
                onChange={e => setMailAddr2(e.target.value)}
                onKeyPress={onFieldKey}
            />
            <div className={classes.btnContainer}>
                <IconButton
                    tooltip='전송'
                    icon={<CheckIcon />}
                    onClick={reqSend}
                />
            </div>
        </Dialog>
    )
}

const getMailContent = (workData, taxData) => `
    <div id="readFrame">
        <div style="text-align:left;font-size:9pt;width:700px;font-family:'Malgun Gothic','맑은 고딕','돋움',Dotum,'굴림',Gulim;background-color:White;">
            <ul>
                <li style="font-size: medium;">
                    <strong><span style="font-size: large">
                        ${workData['WORK_NM']}
                    </span></strong>
                    (으)로 부터 (세금)계산서가 도착 하였습니다.
                </li>
                <li style="font-size: medium;">
                    <strong><span style="font-size: large">
                        첨부파일
                    </span></strong>
                    에 (세금)계산서와 매출거래명세서 정보를 확인 하실 수 있습니다.
                </li>
            </ul>
            <div>
                <table style="font-size:9pt;font-family:'Malgun Gothic','맑은 고딕','돋움',Dotum,'굴림',Gulim;border:0px;margin:20px 20px 20px 20px;width:600px;text-align:center;border-collapse:collapse;">
                    <tbody>
                        <tr style="background-color:White;height:40px;font-size: medium;">
                            <th colspan="1" rowspan="1" style="width:100px;border:1px solid silver;">&nbsp;</th>
                            <th colspan="1" rowspan="1" style="border:1px solid silver;border-left:0px;">공급자</th>
                            <th colspan="1" rowspan="1" style="border:1px solid silver;border-left:0px;">공급 받는자</th>
                        </tr>
                        <tr style="background-color:White;height:40px;font-size: medium;">
                            <td style="border:1px solid silver;border-top:0px;text-align:left;">상호</td>
                            <td style="border:1px solid silver;border-top:0px;border-left:0px;text-align:left;padding-left:5px;">${workData['WORK_NM']}</td>
                            <td style="border:1px solid silver;border-top:0px;border-left:0px;text-align:left;padding-left:5px;">${taxData['CST_NM']}</td>
                        </tr>
                        <tr style="background-color:White;height:40px;font-size: medium;">
                            <td style="border:1px solid silver;border-top:0px;text-align:left;">연락처</td>
                            <td style="border:1px solid silver;border-top:0px;border-left:0px;text-align:left;padding-left:5px;">${workData['WORK_TELNO1'] || ''}</td>
                            <td style="border:1px solid silver;border-top:0px;border-left:0px;text-align:left;padding-left:5px;">${taxData['TELNO1'] || ''}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style="text-align:left;margin-left:20px;font-family:'Malgun Gothic','맑은 고딕','돋움',Dotum,'굴림',Gulim;font-size: small;">
                <div>▶ 이용 안내</div>
                <ul style="font-family:'Malgun Gothic','맑은 고딕','돋움',Dotum,'굴림',Gulim;">
                    <li>
                        본 첨부파일은 모바일과 브라우저에서 편리하게 볼수 있는 PDF 문서로 생성되었습니다.
                    </li>
                    <li>
                        PDF 문서가 보이지 않을 경우 PDF 뷰어를 검색 설치 하신 후 보실수 있습니다.
                    </li>
                    <li>
                        본 메일은 발신 전용 입니다. 문의 사항은 아래 연락처를 이용해 주세요.
                    </li>
                </ul>
            </div>
        </div>

        <div style="text-align:left;width:700px;border:1px solid Silver;font-family:'Malgun Gothic','맑은 고딕','돋움',Dotum,'굴림',Gulim;font-size: small;color:#7d7d7d;">
            <div>이 메일은 지정된 수취인만을 위해 작성되었으며, 기업이나 개인의 중요한 정보나 저작권을 포함하고 있을 수 있습니다. 만약, 본 메일이 잘못 전송된 경우, 발신인 또는 당사에 알려주시고, 본 메일을 즉시 삭제하여 주시기 바랍니다. </div>
            <div>This e-mail may contain confidential information and / or copyrighted material. This email is intended solely for the recipient. If you receive this email in error, please do not delete it, copy it, distribute it, or retain it, or notify the sender immediately.</div>
        </div>

        <div style="text-align:right;width:700px;border:0px solid Silver;line-height:1;font-family:'Malgun Gothic','맑은 고딕','돋움',Dotum,'굴림',Gulim;font-size: small;color:#7d7d7d;">
            ${workData['WORK_NM']} ${workData['ADDR'] || ''} ${workData['ADDR_DET'] || ''}  대표번호 : ${workData['WORK_TELNO1'] || ''}
        </div>        
    </div>
`

export default TaxInvoiceMng;