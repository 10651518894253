import React, { useState, useEffect, useCallback } from 'react';
import { Paper, makeStyles,TextField, ListItem, ListItemIcon, Checkbox, ListItemText, List, Dialog, DialogTitle } from '@material-ui/core';
import { DataGrid, ComboBox, PaperTitle, ProgressPanel, MenuDivider, ProgressDialog, DataColumn, IconButton } from '../../component';
import CheckIcon from '@material-ui/icons/Check';
import { useFieldInputs, toHash } from '../../common/Utils';
import { callProc } from '../../common/DBConnector';
import { useSnackbar } from 'notistack';
import MenuInfo, { menuOverride } from '../../MenuInfo';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { clsAuth } from '../../common/Dictionary';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
    },
    info: {
        flex: 1,
        marginRight: theme.spacing(1),
    },
    enroll: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginLeft: theme.spacing(1),
    },
}))


const LoginInfo = () => {
    const [condition, setCondition] = useState({});
    const [selectRow, setSelectRow] = useState();
    const [signUpPopup, setSignUpPopup] = useState(false);
    const [editData, setEditData] = useState();

    const classes = useStyles();

    const refreshGrid = () => setCondition({ ...condition })

    return(
    <div className={classes.container}>
        <div className={classes.info}>
            <DataGrid
                title='로그인 정보'
                table='TB_LOGININFO'
                selectProc='SLT_LOGININFO'
                selectParam={condition}
                onSelect={row => setSelectRow(row)}
                selectionMode='single'
                headerItem={() => (
                    <IconButton
                        tooltip='추가'
                        icon={<AddIcon />}
                        onClick={() => setSignUpPopup(true)}
                    />
                )}
            >
                <DataColumn
                    position='ID'
                    value={(row) => (
                        <IconButton
                            tooltip='수정'
                            icon={<EditIcon />}
                            onClick={() => setEditData(row)}
                        />
                    )}
                    cellStyle={{ width: 1 }}
                />
                <DataColumn
                    fieldName='EMP_NM'
                    title='사원'
                />
                <DataColumn
                    fieldName='CLS_AUTH'
                    value={(row, value) => clsAuth[value]}
                    editable={(state, dipatch) => (
                        <ComboBox
                            label='마감권한'
                            data={clsAuth}
                            value={state}
                            onChange={value => dipatch('CLS_AUTH', value)}
                        />
                    )}
                />
            </DataGrid>
        </div>
        <div className={classes.enroll}>
            <AuthPanel selectRow={selectRow} />
        </div>
        
        <EnrollDialog
            open={signUpPopup}
            onClose={() => setSignUpPopup(false)}
            onInserted={refreshGrid}
        />

        <EditDialog
            data={editData}
            onClose={() => setEditData(null)}
            onInserted={refreshGrid}
        />
    </div>
    )
}

const useDialogStyle = makeStyles(theme => ({
    signUpDialog: {
        width: 300,
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    paperContent: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: theme.spacing(2),
        paddingTop: 0,
        height: '100%',
        overflowY: 'auto',
    },
    btnContainer: {
        alignSelf: 'flex-end',
        marginTop: 'auto',
        padding: theme.spacing(2),
    },
    field: {
        marginBottom: 16,
    }
}))

const EnrollDialog = ({ onInserted, open, onClose }) => {
    const [insData, setInsData, insClear] = useFieldInputs({
        ID: '',
        PASSWORD: '',
        PASS_CONFIRM: '',
        CLS_AUTH: 1,
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        insClear();
    }, [open, insClear])

    const { enqueueSnackbar } = useSnackbar();
    const classes = useDialogStyle();

    const valid = () => {
        if (!insData['ID'] || insData['ID'].length < 4) {
            enqueueSnackbar('아이디가 너무 짧습니다.(최소 4자)', { variant: 'error' });
            return false;
        }
        if (!insData['PASSWORD'] || insData['PASSWORD'] < 4) {
            enqueueSnackbar('비밀번호가 너무 짧습니다.(최소 4자)', { variant: 'error' });
            return false;
        }
        if (insData['PASSWORD'] !== insData['PASS_CONFIRM']) {
            enqueueSnackbar('비밀번호가 일치하지 않습니다.', { variant: 'error' });
            return false;
        }
        return true;
    }

    const onInsert = async() => {
        setLoading(true);

        if (valid()) {
            const result = await callProc('INS_LOGININFO', { ...insData, PASSWORD: toHash(insData['PASSWORD']) });
            if (!result.err) {
                onClose();
                enqueueSnackbar('저장이 완료되었습니다.');
                onInserted && onInserted();
            }
        }

        setLoading(false);
    }

    return (
        <Dialog
            open={open}
            PaperProps={{ className: classes.signUpDialog }}
            onClose={onClose}
        >
            <ProgressDialog open={loading} />
            <div className={classes.title}>
                <DialogTitle>
                    회원가입
                </DialogTitle>
                <IconButton
                    tootip='닫기'
                    icon={<CloseIcon />}
                    onClick={() => onClose()}
                />
            </div>
            <div className={classes.paperContent}>
                <ComboBox
                    label='사원'
                    className={classes.field}
                    selectProc='SLT_EMPINFO'
                    keyField='EMP_CD'
                    valueField='EMP_NM'
                    onChange={value => setInsData('EMP_CD', value)}
                    value={insData['EMP_CD']}
                />
                <TextField
                    label='아이디'
                    className={classes.field}
                    value={insData['ID']}
                    onChange={e => setInsData('ID', e.target.value)}
                />
                <TextField
                    label='비밀번호'
                    className={classes.field}
                    type='password'
                    value={insData['PASSWORD']}
                    onChange={e => setInsData('PASSWORD', e.target.value)}
                />
                <TextField
                    label='비밀번호 확인'
                    className={classes.field}
                    type='password'
                    value={insData['PASS_CONFIRM']}
                    onChange={e => setInsData('PASS_CONFIRM', e.target.value)}
                />
                <ComboBox
                    label='마감권한'
                    className={classes.field}
                    data={clsAuth}
                    value={insData['CLS_AUTH']}
                    onChange={value => setInsData('CLS_AUTH', value)}
                />
            </div>
            <div className={classes.btnContainer}>
                <IconButton
                    tooltip='확인'
                    icon={<CheckIcon />}
                    onClick={onInsert}
                />
            </div>
        </Dialog>
    )
}

const EditDialog = ({ data, onClose, onInserted }) => {
    const [insData, setInsData] = useFieldInputs({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (data == null)
            return;
        setInsData('ID', data['ID']);
        setInsData('PASSWORD', '');
        setInsData('PASS_CONFIRM', '');
        setInsData('CLS_AUTH', data['CLS_AUTH']);
    }, [data, setInsData])

    const { enqueueSnackbar } = useSnackbar();
    const classes = useDialogStyle();

    const valid = () => {
        if (insData['PASSWORD'] !== '' || insData['PASS_CONFIRM'] !== '') {
            if (insData['PASSWORD'].length < 4) {
                enqueueSnackbar('비밀번호가 너무 짧습니다.(최소 4자)', { variant: 'error' });
                return false;
            }
            if (insData['PASSWORD'] !== insData['PASS_CONFIRM']) {
                enqueueSnackbar('비밀번호가 일치하지 않습니다.', { variant: 'error' });
                return false;
            }
        }
        return true;
    }

    const onInsert = async() => {
        setLoading(true);

        if (valid()) {
            const result = await callProc('UPT_LOGININFO', {
                ...insData,
                PASSWORD: insData['PASSWORD'] === '' ? null : toHash(insData['PASSWORD']),
            });
            if (!result.err) {
                onClose();
                enqueueSnackbar('저장이 완료되었습니다.');
                onInserted && onInserted();
            }
        }

        setLoading(false);
    }

    return (
        <Dialog
            open={data != null}
            PaperProps={{ className: classes.signUpDialog }}
            onClose={onClose}
        >
            <ProgressDialog open={loading} />
            <div className={classes.title}>
                <DialogTitle>
                    회원정보 수정
                </DialogTitle>
                <IconButton
                    tooltip='닫기'
                    icon={<CloseIcon />}
                    onClick={() => onClose()}
                />
            </div>
            <div className={classes.paperContent}>
                <TextField
                    label='아이디'
                    className={classes.field}
                    value={insData['ID']}
                    disabled
                />
                <TextField
                    label='비밀번호'
                    className={classes.field}
                    type='password'
                    value={insData['PASSWORD']}
                    onChange={e => setInsData('PASSWORD', e.target.value)}
                />
                <TextField
                    label='비밀번호 확인'
                    className={classes.field}
                    type='password'
                    value={insData['PASS_CONFIRM']}
                    onChange={e => setInsData('PASS_CONFIRM', e.target.value)}
                />
                <ComboBox
                    label='마감권한'
                    className={classes.field}
                    data={clsAuth}
                    value={insData['CLS_AUTH']}
                    onChange={value => setInsData('CLS_AUTH', value)}
                />
            </div>
            <div className={classes.btnContainer}>
                <IconButton
                    tooltip='확인'
                    icon={<CheckIcon />}
                    onClick={onInsert}
                />
            </div>
        </Dialog>
    )
}

const usePanelStyle = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    paperContent: {
        display: 'flex',
        height: '100%',
        overflow: 'auto',
    },
    btnContainer: {
        alignSelf: 'flex-end',
        marginTop: 'auto',
        padding: theme.spacing(2),
    },
    listContainer: {
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        padding: theme.spacing(2),
    },
    list: {
        width: '100%',
    },
    enrollContent: {
        padding: theme.spacing(2),
        flex: 1,
    },
    smallIcon: {
        fontSize: 'small',
    },
}))

const AuthPanel = ({ selectRow }) => {
    const [workData, setWorkData] = useState();

    const [menuAuth, setMenuAuth] = useState(null);
    const [workAuth, setWorkAuth] = useState(null);

    const [dupDialog, setDupDialog] = useState(false);
    const [loading, setLoading] = useState(false);

    const conf = useSelector(state => state.configReducer);

    useEffect(() => {
        const fetchData = async() => {
            const workData = await callProc('SLT_WORKPLACEINFO');

            setWorkData(workData.data.reduce((result, item) => ({
                ...result,
                [item['WORK_CD']]: item['WORK_NM']
            }), {}))
        }
        fetchData();
    }, [])

    const fetchData = useCallback(async(id) => {
        setMenuAuth(null);
        setWorkAuth(null);

        const menuData = await callProc('SLT_MENUAUTHINFO', { ID: id });
        const workData = await callProc('SLT_WORKAUTHINFO', { ID: id });

        setMenuAuth(menuData.data.map(item => item['MENU_CD']));
        setWorkAuth(workData.data.map(item => item['WORK_CD']));
    }, [])

    useEffect(() => {
        selectRow && fetchData(selectRow['ID']);
    }, [selectRow, fetchData])

    const { enqueueSnackbar } = useSnackbar();
    const classes = usePanelStyle();

    const onMenuClick = (value) => () => {
        const currentIndex = menuAuth.indexOf(value);
        const result = [...menuAuth];
    
        if (currentIndex === -1) {
            result.push(value);
        } else {
            result.splice(currentIndex, 1);
        }
    
        setMenuAuth(result);
    }

    const onWorkAllClick = () => {
        if (workAuth.length === 0) {
            const newSelecteds = Object.keys(workData).map((item) => item);
            setWorkAuth(newSelecteds);
        } else {
            setWorkAuth([]);
        }
    };

    const onWorkClick = (value) => () => {
        const currentIndex = workAuth.indexOf(value);
        const result = [...workAuth];
    
        if (currentIndex === -1) {
            result.push(value);
        } else {
            result.splice(currentIndex, 1);
        }
    
        setWorkAuth(result);
    }

    const isEnrollable = () => menuAuth != null && workAuth != null

    const createMenu = (menu, depth, childVisible) => Object.keys(menu).map(key => {
        const {children, icon } = menu[key];
        let override;
        let Icon = icon;
        let text = menu[key].text;
        let visible = menu[key].visible;

        if (menuOverride[conf['MENU_TYPE']]) {
            override = menuOverride[conf['MENU_TYPE']][key];
            if (override) {
                if (override.icon !== undefined) {
                    Icon = override.icon;
                }
                if (override.text !== undefined) {
                    text = override.text;
                }
                if (override.visible !== undefined) {
                    visible = override.visible;
                }
            }
        }

        if (visible === false) {
            return null;
        }

        return ([
            <ListItem
                key={key}
                onClick={onMenuClick(key)}
                disabled={!isEnrollable()}
                button
                style={{
                    paddingLeft: depth * 16,
                    display: childVisible ? 'flex' : 'none'
                }}
            >
                <ListItemIcon>
                    {Icon ?
                    <Icon /> :
                    <FiberManualRecordIcon className={classes.smallIcon} />}
                </ListItemIcon>
                <ListItemText primary={text} />
                <ListItemIcon>
                    <Checkbox
                        edge="end"
                        checked={isEnrollable() && menuAuth.indexOf(key) === -1}
                        tabIndex={-1}
                        disableRipple
                        color='default'
                    />
                </ListItemIcon>
            </ListItem>,
            children && createMenu(children, depth + 1, childVisible && menuAuth && menuAuth.indexOf(key) === -1),
            depth === 1 && <MenuDivider key={key + 'd'} />
        ])
    })

    const onInsert = async() => {
        setLoading(true);

        const result = await callProc('INS_AUTHINFO', {
            ID: selectRow['ID'],
            ARR_MENU_CD: JSON.stringify(menuAuth),
            ARR_WORK_CD: JSON.stringify(workAuth),
        });
        if (!result.err) {
            enqueueSnackbar('저장이 완료되었습니다.');
        }

        setLoading(false);
    }

    return (
        !workData ? <ProgressPanel /> :
        <Paper className={classes.paper}>
            <PaperTitle>
                권한 관리
            </PaperTitle>
            <div className={classes.paperContent}>
                <div className={classes.listContainer}>
                    <List className={classes.list}>
                        {createMenu(MenuInfo, 1, true)}
                    </List>
                </div>
                <div className={classes.listContainer}>
                    <List className={classes.list}>
                        <ListItem
                            onClick={onWorkAllClick}
                            disabled={!isEnrollable()}
                            button
                        >
                            <ListItemText primary={'전체'} />
                            <ListItemIcon>
                                <Checkbox
                                    edge="end"
                                    checked={isEnrollable() && workAuth.length === 0}
                                    tabIndex={-1}
                                    disableRipple
                                    color='default'
                                />
                            </ListItemIcon>
                        </ListItem>
                        {Object.keys(workData).map(key => (
                            <ListItem
                                key={key}
                                onClick={onWorkClick(key)}
                                disabled={!isEnrollable()}
                                button
                            >
                                <ListItemText primary={workData[key]} />
                                <ListItemIcon>
                                    <Checkbox
                                        edge="end"
                                        checked={isEnrollable() && workAuth.indexOf(key) === -1}
                                        tabIndex={-1}
                                        disableRipple
                                        color='default'
                                    />
                                </ListItemIcon>
                            </ListItem>
                        ))}
                    </List>
                </div>
            </div>
            <div className={classes.btnContainer}>
                <IconButton
                    tooltip='불러오기'
                    icon={<OpenInNewIcon />}
                    onClick={() => setDupDialog(true)}
                    disabled={!isEnrollable()}
                />
                <IconButton
                    tooltip='확인'
                    icon={<CheckIcon />}
                    onClick={onInsert}
                    disabled={!isEnrollable()}
                />
            </div>
            <DuplicateDialog
                open={dupDialog}
                onClose={() => setDupDialog(false)}
                fetchData={fetchData}
            />
            <ProgressDialog open={loading} />
        </Paper>
    )
}

const useDupDialogStyle = makeStyles(theme => ({
    dialog: {
        width: 600,
        height: 500,
    }
}))

const DuplicateDialog = ({ open, onClose, fetchData }) => {
    const classes = useDupDialogStyle();
    return (
        <Dialog
            open={open}
            PaperProps={{ className: classes.dialog }}
            onClose={onClose}
        >
            <DataGrid
                title='권한 불러오기'
                selectProc='SLT_LOGININFO'
                headerItem={() => (
                    <IconButton
                        tooltip='닫기'
                        icon={<CloseIcon />}
                        onClick={() => onClose()}
                    />
                )}
                selectionMode='single'
                onSelect={row => {
                    if (row) {
                        fetchData(row['ID']);
                        onClose();
                    }
                }}
            />
        </Dialog>
    )
}

export default LoginInfo;
