import axios from 'axios';


let loginId;

const setLoginId = (id) => loginId = id;

const getErpRelease = async() => {
    try {
        const res = await fetch(`/release_erp.json?t=${new Date().getTime()}`);
        return await res.json();
    } catch (e) { }
}

const callProc = async(procName, params) => {
    const res = await axios.post('/api/proc/' + procName, { ...params, USER: loginId });
    if (res.data.err) {
        window.enqueueSnackbar &&
        window.enqueueSnackbar(
            `데이터 통신이 실패하였습니다.
            코드 : ${res.data.err.code}
            메세지 : ${res.data.err.msg}`,
            { variant: 'error' }
        )
    }
    return res.data;
}

const getProcInfo = async(procName) => {
    const res = await axios.post('/api/procinfo/' + procName);
    return res.data.reduce((result, item) => ({
        ...result,
        [item['NAME']]: item
    }), {});
}

const getTable = async(tableName) => {
    const res = await axios.get('/api/data/' + tableName);
    return res.data;
}

const getKeys = async(tableName) => {
    const res = await axios.get('/api/keys/' + tableName);
    return Object.values(res.data).map(item => item.COLUMN_NAME);
}

const getColumnName = async() => {
    const res = await axios.get('/api/fields');
    const obj = JSON.parse(res.data[0].obj);

    let duplicateList = {};
    Object.values(obj).map((item, i) => {
        if (item.length > 1) {
            duplicateList = {
                ...duplicateList,
                [Object.keys(obj)[i]]: item,
            }
        }
        return null;
    })
    if (Object.keys(duplicateList).length > 0){
        console.error(`Database column comment duplicated${'\n' + JSON.stringify(duplicateList)}`);
    }

    return obj;
}

const uploadFile = async(files, fileKey) => {
    const formData = new FormData();
    if (Array.isArray(files)) {
        files.forEach(file => formData.append('files', file.seq == null ? file : JSON.stringify(file)));
    } else {
        formData.append('files', files);
    }

    if (fileKey) {
        formData.append('fileKey', fileKey);
    }
    const res = await axios.post('/api/file_upload', formData);

    if (res.data.err) {
        window.enqueueSnackbar &&
        window.enqueueSnackbar(
            `파일 업로드가 실패하였습니다.
            코드 : ${res.data.err.errno || res.data.err.code}`,
            { variant: 'error' }
        )
    }
    return res.data;
}

const downloadFile = async(fileKey, seq) => window.open(`/api/file_down?fileKey=${fileKey}&seq=${seq}`)

const getFileList = async(fileKey) => {
    const res = await axios.post('/api/file_list', { fileKey });
    if (res.data.err) {
        window.enqueueSnackbar &&
        window.enqueueSnackbar(
            `파일정보 불러오기를 실패하였습니다.
            코드 : ${res.data.err.errno || res.data.err.code}`,
            { variant: 'error' }
        )
    }
    return res.data;
}

const sendMail = async(id, pw, name, to, subject, content, attach = []) => {
    if (!id || id.indexOf('@') === -1) {
        window.enqueueSnackbar &&
        window.enqueueSnackbar('발신자 메일이 잘못되었습니다.', { variant: 'error' })
        return { err: {} };
    }

    const res = await axios.post('/api/mail', {
        id,
        pw,
        name,
        to,
        subject,
        content,
        attach,
    });

    if (res.data.err) {
        window.enqueueSnackbar &&
        window.enqueueSnackbar(
            `데이터 통신이 실패하였습니다.
            코드 : ${res.data.err.code}
            메세지 : ${res.data.err.msg}`,
            { variant: 'error' }
        )
    }

    return res.data;
}

const sendKakao = async(to, templateId, conf, text, buttons = []) => {
    const res = await axios.post('/api/kakao', {
        apiKey: conf['SOLAPI_KEY'],
        apiSecret: conf['SOLAPI_SECRET'],
        to,
        text,
        from: conf['SOLAPI_SENDER'],
        pfId: conf['SOLAPI_PFID'],
        templateId,
        buttons
    });

    if (res.data.err) {
        window.enqueueSnackbar &&
        window.enqueueSnackbar(
            `알림톡 전송에 실패하였습니다.
            메세지 : ${res.data.err.msg}`,
            { variant: 'error' }
        )
    }

    return res.data;
}

const getAddress = async(keyword) => {
    const form = new FormData();
    form.append('confmKey', 'U01TX0FVVEgyMDIwMTIwMzE0NTYwNzExMDQ5NjU=');
    form.append('keyword', keyword);
    form.append('countPerPage', 100);
    form.append('resultType', 'json');

    const httpRes = await axios.post('/addrlink/addrLinkApiJsonp.do', form);
    if (httpRes.data.err) {
        window.enqueueSnackbar &&
        window.enqueueSnackbar(
            `서버와 통신에 실패하였습니다.
            코드 : ${httpRes.data.err.errno || httpRes.data.err.code}`,
            { variant: 'error' }
        )
        return httpRes.data;
    }

    const res = JSON.parse(httpRes.data.substr(1, httpRes.data.length - 2)).results;
    if (res.common.errorCode !== '0') {
        res.err = res.common;
        window.enqueueSnackbar &&
        window.enqueueSnackbar(
            `${res.err.errorMessage}
            코드 : ${res.err.errorCode}`,
            { variant: 'error' }
        )
    }

    return res.err ? res : res.juso;
}

export {
    getErpRelease,
    callProc,
    getProcInfo,
    getTable,
    getKeys,
    getColumnName,
    sendMail,
    sendKakao,
    uploadFile,
    downloadFile,
    getFileList,
    getAddress,
    setLoginId,
}