import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import HistoryIcon from '@material-ui/icons/History';
import { TransComplete, TransMonthlyInfo, TransProgress } from './screen';


export default {
    MA0: {
        text: '수송',
        icon: LocalShippingIcon,
        children: {
            MA1: {
                text: '진행 내역',
                to: TransProgress
            },
        }
    },
    MB0: {
        text: '기록',
        icon: HistoryIcon,
        children: {
            MB1: {
                text: '완료 내역',
                to: TransComplete
            },
            MB2: {
                text: '월별 수송량',
                to: TransMonthlyInfo
            }
        }
    },
}