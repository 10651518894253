import React, { useMemo } from 'react';
import { ComboBox, DatePicker, NumberField, ComboSearch, EditableList, EditableField, TextField, IconButton } from '../../component';
import { Paper, makeStyles } from '@material-ui/core';
import { callProc } from '../../common/DBConnector';
import { useSnackbar } from 'notistack';
import { useFieldInputs } from '../../common/Utils';
import SearchIcon from '@material-ui/icons/Search';
import { useSelector } from 'react-redux';


const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flex: 3,
        height: 0,
        flexDirection: 'column'
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginTop: theme.spacing(1),
    },
    enroll: {
        display: 'flex',
        flexDirection: 'column',
        flex: 2,
        // marginBottom: theme.spacing(1),
        height: 0,
    },
    paperTitle: {
        display: 'flex',
        justifyContent: 'end',
        padding: theme.spacing(1),
    },
    field: {
        width: 180,
        marginRight: theme.spacing(1),
    },
    searchPaper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    searchContainer: {
        flex: 1,
        display: 'inline-block',
        flexDirection: 'row',
    },
    searchItem: {
        display: 'inline-block',
        width: 200,
        marginRight: 100,
        marginBottom: theme.spacing(1),
    },
}))

const MoneyMoveEnroll = () => {
    const clsState = useSelector(state => state.clsDateReducer);
    const [input, setInput] = useFieldInputs();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const condition = useMemo(() => input['WORK_CD'] && input['ACJ_DATE'] && input, [input]);

    const gridRefresh = () => {
        setInput({ type: 'UPDATE', value: { ...input } });
    }

    const valid = async(data) => {
        const result = (await callProc('UPT_MONEYMOVE_CLOSE_VALID', {
            ACJ_DATE: condition && condition['ACJ_DATE'],
            JSON: data
        })).data
        
        if(String(result[0]['OVER_TYPE']) === '1') {
            enqueueSnackbar('사업장 마감을 확인해주세요.', {variant: 'error'});
            return false;
        }

        return true;
    }

    const onInsert = async(data) => {
        if(valid(data)){
            const result = await callProc('INS_WORKPLACE_MONEYMOVE_ENROLL', {
                ACJ_DATE: condition && condition['ACJ_DATE'],
                JSON: data
            });

            if (!result.err) {
                enqueueSnackbar('저장이 완료되었습니다.');
                gridRefresh();
            }
        }
    }

    return(
        <div className={classes.container}>
            <Paper className={classes.searchPaper}>
                <div className={classes.searchContainer}>
                    <div className={classes.searchItem}>
                        <ComboBox
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={condition && condition['WORK_CD']}
                            onChange={value => {
                                if (input['WORK_CD'] !== value) {
                                    setInput('ACJ_DATE', clsState[value]);
                                    setInput('WORK_CD', value);
                                }
                            }}
                        />
                    </div>
                    <div className={classes.searchItem}>
                        <DatePicker
                            label='영업일'
                            minDate={clsState[input['WORK_CD']]}
                            value={condition && condition['ACJ_DATE']}
                            onChange={value => {
                                if (input['ACJ_DATE'] !== value) {
                                    setInput('ACJ_DATE', value);
                                    if (condition && condition['ACJ_DATE'] < clsState[condition['WORK_CD']]) {
                                        setInput({ type: 'UPDATE', value: { ...input, 'ACJ_DATE': value } });
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                <IconButton
                    tooltip=''
                    icon={<SearchIcon />}
                    onClick={gridRefresh}
                />
            </Paper>
            <div className={classes.content}>
                <EditableList
                    title='사업장 통합 이체'
                    selectProc='SLT_WORKPLACE_MOVEMONEY_ENROLL'
                    selectParam={condition}
                    onUpdate={onInsert}
                    alwaysAddable
                    defaultValue={{ AMOUNT: 0}}
                >
                    <EditableField
                        fieldName='WORK_CD'
                        component={({value, setState}) => (
                            <ComboSearch
                                style={{ width: 210 }}
                                label='출금 사업장'
                                selectProc='SLT_WORKPLACEINFO_AUTH'
                                keyField='WORK_CD'
                                valueField='WORK_NM'
                                value={value}
                                onChange={value => setState(value)}
                            />
                        )}
                    />
                    <EditableField
                        fieldName='ACC_CD'
                        component={({value, setState}) => (
                            <ComboSearch
                                style={{ width: 210 }}
                                label='출금 계정과목'
                                selectProc='SLT_ACCOUNT_ALL'
                                keyField='ACC_CD'
                                valueField='ACC_NM'
                                value={value}
                                onChange={value => setState(value)}
                            />
                        )}
                    />
                    <EditableField
                        fieldName='AMOUNT'
                        component={({value, setState}) => (
                            <NumberField
                                style={{ width: 120 }}
                                label='출금액'
                                value={value}
                                onChange={value => setState(value)}
                            />
                        )}
                    />
                    <EditableField
                        fieldName='MOVEWORK_CD'
                        component={({value, setState}) => (
                            <ComboSearch
                                style={{ width: 210 }}
                                label='입금 사업장'
                                selectProc='SLT_WORKPLACEINFO_AUTH'
                                keyField='WORK_CD'
                                valueField='WORK_NM'
                                value={value}
                                onChange={value => setState(value)}
                            />
                        )}
                    />
                    <EditableField
                        fieldName='MOVEACC_CD'
                        component={({value, setState}) => (
                            <ComboSearch
                                style={{ width: 210 }}
                                label='입금 계정과목'
                                selectProc='SLT_ACCOUNT_ALL'
                                keyField='ACC_CD'
                                valueField='ACC_NM'
                                value={value}
                                onChange={value => setState(value)}
                            />
                        )}
                    />
                    <EditableField
                        fieldName='MOVEREMARK'
                        component={({value, setState}) => (
                            <TextField
                                style={{ width: 180 }}
                                label='비고'
                                value={value}
                                onChange={value => setState(value)}
                            />
                        )}
                    />
                    <EditableField fieldName='SEQ' visible={false} />
                    <EditableField fieldName='ACJ_CD' visible={false} />
                </EditableList>
            </div>
        </div>
    )
}

export default MoneyMoveEnroll;