import { CircularProgress, Fab, lighten, makeStyles, Paper, Toolbar, Typography } from '@material-ui/core';
import { Add, FileCopy, RemoveCircle, Save } from '@material-ui/icons';
import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { IconButton } from '.';
import { callProc, getColumnName } from '../common/DBConnector';
import { useFieldInputs } from '../common/Utils';
import EditableField from './EditableField';
import clsx from 'clsx';

const moveArrayItem = (items, moveFromIndex, moveToIndex) => {
    const movingItem = items[moveFromIndex];
    items.splice(moveFromIndex, 1);
    items.splice(moveToIndex, 0, movingItem);

    return items;
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        flex: 1,
        height: 0,
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding: 4,
        backgroundColor: 'lightgray',
        overflowY: 'auto',
    },
    stickyContainer: {
        display: 'flex',
        flex: 1,
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        position: 'sticky',
        bottom: 0,
        padding: 4,
    },
    addButton: {
        padding: 16,
        alignSelf: 'center',
    },
    borderless: {
        boxShadow: 'none',
        borderRadius: 0,
    },
    progressModal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 1,
    }
}));

const EditableList = ({ children, dataSet, selectProc, selectParam, defaultValue, bottomSticky, onSelect, title, alwaysAddable, fixedRowCount, onUpdate, onAdd, onRemove, headerItem, headerLess, className, style }) => {
    const [_dataSet, setDataSet] = useState();
    const [selected, setSelected] = useState();
    const [loading, setLoading] = useState(false);
    const data = useRef();
    const columnLabels = useRef({});
    const defaultValues = useRef({});
    const stickyCard = useRef();

    const classes = useStyles();

    const [_force, _forceSel] = useState();
    const forceSelect = () => _forceSel({});

    const fieldData = useMemo(() => {
        if (!_dataSet) return null;
        data.current = _dataSet.data;

        defaultValues.current = defaultValue;
        const child = React.Children.toArray(children).reduce((result, item, i) => {
            const fieldName = item.props.fieldName || 'child' + i;
            if (item.props.defaultValue) {
                defaultValues.current = {
                    ...defaultValues.current,
                    [fieldName]: item.props.defaultValue,
                }
            }
            return { ...result, [fieldName]: item };
        }, {});

        let fieldNames = Object.keys(child).reduce((result, key) => (
            result.includes(key) ? result : [...result, key]
        ), [..._dataSet.fields]);
        fieldNames = fieldNames.reduce((result, item) => (
            child[item] && child[item].props.position ?
            moveArrayItem(
                result,
                result.indexOf(item),
                result.indexOf(child[item].props.position)
            ) :
            result
        ), fieldNames);
        
        return fieldNames.reduce((result, fieldName) => [
            ...result, child[fieldName] || <EditableField fieldName={fieldName} />
        ], []);
    }, [_dataSet, children, defaultValue]);

    useEffect(() => {
        const fetchData = async() => {
            setLoading(true);

            data.current = null;
            setSelected();

            columnLabels.current = await getColumnName();
            const result = selectProc ? await callProc(selectProc, selectParam) : { ...dataSet };
            setDataSet(result);

            setLoading(false);
        }
        fetchData();
    }, [selectProc, selectParam, dataSet, _force]);

    const handleAdd = (rowData, index) => {
        if (onAdd) {
            rowData = onAdd({ ...rowData }, JSON.parse(JSON.stringify(data.current)), index);
        }
        const newData = [...data.current, { ...rowData }];
        setDataSet({ ..._dataSet, data: newData });
    }

    const handleRemove = (index) => {
        let newData = [...data.current];
        let callbackResult;
        
        if (onRemove) {
            callbackResult = onRemove(data.current[index], newData);
        }

        if (callbackResult) {
            newData = callbackResult;
        } else {
            newData.splice(index, 1);
        }

        setDataSet({ ..._dataSet, data: newData });
    }

    const handleUpdate = async() => {
        setLoading(true);
        const result = await onUpdate(data.current);
        if (result === false) {
            setLoading(false);
            return;
        }
        forceSelect();
    }

    const handleSelect = (idx) => {
        if (onSelect) {
            setSelected(idx);
            onSelect(data.current[idx]);
        }
    }

    return (
        <Paper style={style} className={clsx(classes.container, className, {
            [classes.borderless]: headerLess,
        })}>
            {!headerLess &&
            <Toolbar>
                <Typography style={{flex : 1}} variant="h6" id="tableTitle" component="div">
                    {title}
                </Typography>
                {headerItem && headerItem()}
            </Toolbar>}
            <div className={classes.contentContainer}>
                {data.current &&
                <Fragment>
                    {data.current.map((item, i) => (
                        <EditableCard
                            key={i}
                            index={i}
                            rowData={item}
                            fieldData={fieldData}
                            metaData={_dataSet.metaData}
                            columnLabels={columnLabels.current}
                            fixedRowCount={fixedRowCount}
                            onDuplicate={handleAdd}
                            onRemove={handleRemove}
                            onSelect={() => handleSelect(i)}
                            onDispatch={(fieldName, value) => {
                                item[fieldName] = value;
                                stickyCard.current && stickyCard.current.forceUpdate();
                            }}
                            isSelect={selected === i}
                        />
                    ))}
                    {(data.current.length === 0 || alwaysAddable) && !fixedRowCount &&
                    <IconButton
                        className={classes.addButton}
                        tooltip='추가'
                        icon={<Add />}
                        onClick={() => handleAdd(defaultValues.current)}
                    />}
                </Fragment>}
                <div className={classes.stickyContainer}>
                    {bottomSticky && data.current &&
                    <StickyCard
                        ref={stickyCard}
                        data={data.current}
                        callback={bottomSticky}
                    />}
                    <Fab
                        size='large'
                        color='primary'
                        onClick={handleUpdate}
                    >
                        <Save />
                    </Fab>
                </div>
            </div>
            {loading &&
            <div className={classes.progressModal}>
                <CircularProgress />
            </div>}
        </Paper>
    )
}

const useItemStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
        margin: 4,
    },
    selected: {
        backgroundColor: lighten(theme.palette.primary.main, 0.85),
    },
    contents: {
        display: 'inline-flex',
        flexWrap: 'wrap',
        flex: 1,
    },
}));

const EditableCard = ({ index, rowData, fieldData, metaData, columnLabels, fixedRowCount, onDuplicate, onRemove, onSelect, onDispatch, isSelect }) => {
    const [data, dispatch] = useFieldInputs(rowData);
    const classes = useItemStyles();

    useEffect(() => {
        dispatch({
            type: 'INIT',
            value: rowData,
        });
    }, [rowData, dispatch]);

    const contents = useMemo(() => fieldData.map(item => React.cloneElement(item, {
            key: item.props.fieldName,
            title: item.props.title || (columnLabels[item.props.fieldName] && columnLabels[item.props.fieldName][0]),
            rowData: data,
            _type: metaData[item.props.fieldName] ? metaData[item.props.fieldName].type : 'VAR_STRING',
            _value: data[item.props.fieldName],
            _dispatch: (fieldName, value) => {
                dispatch(fieldName, value);
                onDispatch(fieldName, value);
            },
    })), [fieldData, columnLabels, data, dispatch, onDispatch, metaData]);

    return (
        <Paper
            className={clsx(classes.container, {
                [classes.selected]: isSelect,
            })}
            onClick={onSelect}
        >
            <div className={classes.contents}>
                {contents}
            </div>
            {!fixedRowCount &&
            <div>
                <IconButton
                    tooltip='추가'
                    icon={<FileCopy />}
                    onClick={() => onDuplicate(data, index)}
                />
                <IconButton
                    tooltip='삭제'
                    icon={<RemoveCircle />}
                    onClick={() => onRemove(index)}
                />
            </div>}
        </Paper>
    )
}

const useCardStyles = makeStyles(theme => ({
    stickyCard: {
        flex: 1,
        width: 0,
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
        marginRight: 8,
        borderTop: 'lightgray 1px solid',
    },
}));

const StickyCard = forwardRef(({ data, callback }, ref) => {
    const [, updateState] = useState();
    
    const classes = useCardStyles();

    useImperativeHandle(ref, () => ({
        forceUpdate() { updateState({}) }
    }));

    return (
        <Paper className={classes.stickyCard}>
            {callback(data)}
        </Paper>
    )
})

EditableList.defaultProps = {
    defaultValue: {},
    headerLess: false,
    fixedRowCount: false,
    onUpdate: () => null,
}

export default EditableList;