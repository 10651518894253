import React from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid, DataColumn } from '../../component';

const useStyles = makeStyles((theme) => ({
    container : {
        flex: 1,
        overflowY: 'auto',
    },
    topContainer : {
        display: 'flex',
        marginBottom: theme.spacing(1),
    },
    leftGrid : {
        marginRight: theme.spacing(1),
    },
    rightGrid : {
        marginLeft: theme.spacing(1),
    }
}))


const Home = () => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div className={classes.topContainer}>
                <DataGrid
                    className={classes.leftGrid}
                    title={`${moment().add(-1, 'day').format('YYYY년 MM월 DD일')}`}
                    selectProc='SLT_HOME_1'
                    totalGroup={['WORK_NM']}
                >
                    <DataColumn fieldName='QTY' total={(values) => values.reduce((result, value) => result + value, 0)} />
                    <DataColumn fieldName='AMOUNT' total={(values) => values.reduce((result, value) => result + value, 0)} />
                </DataGrid>

                <DataGrid
                    className={classes.rightGrid}
                    title={`${moment().startOf('month').format('YYYY년 MM월 DD일')} ~${moment().add(-1, 'day').format('YYYY년 MM월 DD일')}`}
                    selectProc='SLT_HOME_2'
                    totalGroup={['WORK_NM']}
                >
                    <DataColumn fieldName='QTY' total={(values) => values.reduce((result, value) => result + value, 0)} />
                    <DataColumn fieldName='AMOUNT' total={(values) => values.reduce((result, value) => result + value, 0)} />
                </DataGrid>
            </div>
            <div className={classes.topContainer}>
                <DataGrid
                    className={classes.leftGrid}
                    title={`${moment().add(-2, 'month').format('YYYY년 MM월')}`}
                    selectProc='SLT_HOME_3_1'
                    totalGroup={['WORK_NM']}
                >
                    <DataColumn fieldName='QTY' total={(values) => values.reduce((result, value) => result + value, 0)} />
                    <DataColumn fieldName='AMOUNT' total={(values) => values.reduce((result, value) => result + value, 0)} />
                </DataGrid>
                <DataGrid
                    className={classes.rightGrid}
                    title={`${moment().add(-1, 'month').format('YYYY년 MM월')}`}
                    selectProc='SLT_HOME_3'
                    totalGroup={['WORK_NM']}
                >
                    <DataColumn fieldName='QTY' total={(values) => values.reduce((result, value) => result + value, 0)} />
                    <DataColumn fieldName='AMOUNT' total={(values) => values.reduce((result, value) => result + value, 0)} />
                </DataGrid>
            </div>
            <div className={classes.topContainer}>
                <DataGrid
                    className={classes.leftGrid}
                    title={`${moment().add(-1, 'year').format('YYYY년')}`}
                    selectProc='SLT_HOME_4'
                    totalGroup={['WORK_NM']}
                >
                    <DataColumn fieldName='QTY' total={(values) => values.reduce((result, value) => result + value, 0)} />
                    <DataColumn fieldName='AMOUNT' total={(values) => values.reduce((result, value) => result + value, 0)} />
                </DataGrid>
                <DataGrid
                    className={classes.rightGrid}
                    title={`${moment().format('YYYY년')}`}
                    selectProc='SLT_HOME_4_1'
                    totalGroup={['WORK_NM']}
                >
                    <DataColumn fieldName='QTY' total={(values) => values.reduce((result, value) => result + value, 0)} />
                    <DataColumn fieldName='AMOUNT' total={(values) => values.reduce((result, value) => result + value, 0)} />
                </DataGrid>
            </div>
            <div className={classes.topContainer}>
                <DataGrid
                    className={classes.leftGrid}
                    title={`${moment().add(-1, 'day').format('YYYY년 MM월 DD일')} 상품별 재고`}
                    selectProc='SLT_HOME_5'
                    totalGroup={['WORK_NM']}
                >
                    <DataColumn fieldName='STOCK_QTY' title='재고량' total={(values) => values.reduce((result, value) => result + value, 0)} />
                </DataGrid>
            </div>
        </div>
    )
}

export default Home;