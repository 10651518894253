import React from 'react';
import { View, StyleSheet} from '@react-pdf/renderer';

const PdfTable = ({ children, style }) => {
    return(
        <View style={{...styles.table, ...style}}>
            {children}
        </View>
    )
}
const styles = StyleSheet.create({
    table : {
        display : 'table'
    }
}) 

export default PdfTable