import React from 'react';
import { Document, StyleSheet, Text, View} from '@react-pdf/renderer';
import { PdfPage } from '../component';
import { numberFormat } from '../common/Utils';

const styles = StyleSheet.create({
    mainContainer: {
        borderTop: 1,
        flex: 1,
    },
    titleContainer: {
        borderBottom: 1,
        justifyContent: 'center',
        height: 20
    },
    titleSubContainer : {
        display: 'flex', 
        flex: 'none', 
        borderRight: 1,
        height: 20,
        justifyContent: 'center',
    },
    contentCenter: {
        alignSelf: 'center',
    },
    contentLeft: {
        alignSelf: 'flex-start',
        paddingLeft: 8,
    },
    contentRight: {
        alignSelf: 'flex-end',
        paddingRight: 8,
    }
})

const ValueAddedTaxPdf = ({data}) => {
    
    return (
        <Document title='부가가치세 보고서'>
            <PdfPage>
                <View style={{justifyContent: 'center'}}>
                    <Text style={{alignSelf: 'center', fontSize: 20}}>{`${data['WORK_NM']} 부가가치세 보고서`}</Text>
                </View>
                <View style={{height: 20}} />
                
                <View style={styles.mainContainer}>
                    <View style={{...styles.titleContainer}}>
                        <Text style={styles.contentCenter}>신 고 내 용</Text>
                    </View>
                    <View style={{...styles.titleContainer, display: 'flex', flexDirection: 'row'}}>
                        <View style={{...styles.titleSubContainer, flex: 1}}>
                            <Text style={styles.contentCenter}>{`구    분`}</Text>
                        </View>
                        <View style={{...styles.titleSubContainer, width: 100}}>
                            <Text style={styles.contentCenter}>{`금    액`}</Text>
                        </View>
                        <View style={{...styles.titleSubContainer, width: 50}}>
                            <Text style={styles.contentCenter}>{`세    율`}</Text>
                        </View>
                        <View style={{...styles.titleSubContainer, width: 100, borderRight: 0}}>
                            <Text style={styles.contentCenter}>{`세    액`}</Text>
                        </View>
                    </View>
                    <View style={{...styles.titleContainer, display: 'flex', height: 'none', flexDirection: 'row'}}>
                        <View style={{width: 50, height: 180, borderRight: 1, justifyContent: 'center'}}>
                            <Text style={styles.contentCenter}>{`과세\n표준\n및\n매출\n세액`}</Text>
                        </View>
                        <View style={{display: 'flex', flex: 1, height: 180}}>
                            <View style={{display: 'flex', flexDirection: 'row', height: 80, borderBottom: 1}}>
                                <View style={{width: 50, borderRight: 1, justifyContent: 'center'}}>
                                    <Text style={styles.contentCenter}>과세</Text>
                                </View>
                                <View style={{display: 'flex', flex: 1}}>
                                    <View style={{display: 'flex', flexDirection: 'row', borderBottom: 1, height: 20}}>
                                        <View style={{display: 'flex', flexDirection: 'row', flex: 1, borderRight: 1}}>
                                            <View style={{flex: 1, borderRight: 1, justifyContent: 'center'}}>
                                                <Text style={styles.contentLeft}>세 금 계 산 서 발 급 분</Text>
                                            </View>
                                            <View style={{width: 25, justifyContent: 'center'}}>
                                                <Text style={styles.contentCenter}>(1)</Text>
                                            </View>
                                        </View>
                                        <View style={{width: 100, borderRight: 1, justifyContent: 'center'}}>
                                            <Text style={styles.contentRight}>{numberFormat(data['SALE_TAX_SPLY_AMT'])}</Text>
                                        </View>
                                        <View style={{width: 50, borderRight: 1, justifyContent: 'center'}}>
                                            <Text style={styles.contentCenter}>10/100</Text>
                                        </View>
                                        <View style={{width: 100, justifyContent: 'center'}}>
                                            <Text style={styles.contentRight}>{numberFormat(data['SALE_TAX_VAT_AMT'])}</Text>
                                        </View>
                                    </View>
                                    <View style={{display: 'flex', flexDirection: 'row', borderBottom: 1, height: 20}}>
                                        <View style={{display: 'flex', flexDirection: 'row', flex: 1, borderRight: 1}}>
                                            <View style={{flex: 1, borderRight: 1, justifyContent: 'center'}}>
                                                <Text style={styles.contentLeft}>매 입 자 발 행 세 금 계 산 서</Text>
                                            </View>
                                            <View style={{width: 25, justifyContent: 'center'}}>
                                                <Text style={styles.contentCenter}>(2)</Text>
                                            </View>
                                        </View>
                                        <View style={{width: 100, borderRight: 1, justifyContent: 'center'}}>
                                            <Text style={styles.contentRight}></Text>
                                        </View>
                                        <View style={{width: 50, borderRight: 1, justifyContent: 'center'}}>
                                            <Text style={styles.contentCenter}>10/100</Text>
                                        </View>
                                        <View style={{width: 100, justifyContent: 'center'}}>
                                            <Text style={styles.contentRight}></Text>
                                        </View>
                                    </View>
                                    <View style={{display: 'flex', flexDirection: 'row', borderBottom: 1, height: 20}}>
                                        <View style={{display: 'flex', flexDirection: 'row', flex: 1, borderRight: 1}}>
                                            <View style={{flex: 1, borderRight: 1, justifyContent: 'center'}}>
                                                <Text style={styles.contentLeft}>신 용 카 드 . 현 금 영 수 증 발 행 분</Text>
                                            </View>
                                            <View style={{width: 25, justifyContent: 'center'}}>
                                                <Text style={styles.contentCenter}>(3)</Text>
                                            </View>
                                        </View>
                                        <View style={{width: 100, borderRight: 1, justifyContent: 'center'}}>
                                            <Text style={styles.contentRight}>{numberFormat(data['CARD_CSH_SALE_SPLY_AMT'])}</Text>
                                        </View>
                                        <View style={{width: 50, borderRight: 1, justifyContent: 'center'}}>
                                            <Text style={styles.contentCenter}>10/100</Text>
                                        </View>
                                        <View style={{width: 100, justifyContent: 'center'}}>
                                            <Text style={styles.contentRight}>{numberFormat(data['CARD_CSH_SALE_VAT_AMT'])}</Text>
                                        </View>
                                    </View>
                                    <View style={{display: 'flex', flexDirection: 'row', height: 20}}>
                                        <View style={{display: 'flex', flexDirection: 'row', flex: 1, borderRight: 1}}>
                                            <View style={{flex: 1, borderRight: 1, justifyContent: 'center'}}>
                                                <Text style={styles.contentLeft}>기 타 ( 정 규 영 수 증 외 매 출 분 )</Text>
                                            </View>
                                            <View style={{width: 25, justifyContent: 'center'}}>
                                                <Text style={styles.contentCenter}>(4)</Text>
                                            </View>
                                        </View>
                                        <View style={{width: 100, borderRight: 1, justifyContent: 'center'}}>
                                            <Text style={styles.contentRight}>{numberFormat(data['SALE_ETC_SPLY_AMT'])}</Text>
                                        </View>
                                        <View style={{width: 50, borderRight: 1, justifyContent: 'center'}}>
                                            <Text style={styles.contentCenter}>10/100</Text>
                                        </View>
                                        <View style={{width: 100, justifyContent: 'center'}}>
                                            <Text style={styles.contentRight}>{numberFormat(data['SALE_ETC_VAT_AMT'])}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{display: 'flex', height: 40, flexDirection: 'row', borderBottom: 1}}>
                                <View style={{width: 50, borderRight: 1, justifyContent: 'center' }}>
                                    <Text style={styles.contentCenter}>영세율</Text>
                                </View>
                                <View style={{display: 'flex', flex: 1}}>
                                    <View style={{display: 'flex', flexDirection: 'row', borderBottom: 1, height: 20}}>
                                        <View style={{display: 'flex', flexDirection: 'row', flex: 1, borderRight: 1}}>
                                            <View style={{flex: 1, borderRight: 1, justifyContent: 'center'}}>
                                                <Text style={styles.contentLeft}>세 금 계 산 서 발 급 분</Text>
                                            </View>
                                            <View style={{width: 25, justifyContent: 'center'}}>
                                                <Text style={styles.contentCenter}>(5)</Text>
                                            </View>
                                        </View>
                                        <View style={{width: 100, borderRight: 1, justifyContent: 'center'}}>
                                            <Text style={styles.contentRight}></Text>
                                        </View>
                                        <View style={{width: 50, borderRight: 1, justifyContent: 'center'}}>
                                            <Text style={styles.contentCenter}>0/100</Text>
                                        </View>
                                        <View style={{width: 100, justifyContent: 'center'}}>
                                            <Text style={styles.contentRight}></Text>
                                        </View>
                                    </View>
                                    <View style={{display: 'flex', flexDirection: 'row', height: 20}}>
                                        <View style={{display: 'flex', flexDirection: 'row', flex: 1, borderRight: 1}}>
                                            <View style={{flex: 1, borderRight: 1, justifyContent: 'center'}}>
                                                <Text style={styles.contentLeft}>기 타</Text>
                                            </View>
                                            <View style={{width: 25, justifyContent: 'center'}}>
                                                <Text style={styles.contentCenter}>(6)</Text>
                                            </View>
                                        </View>
                                        <View style={{width: 100, borderRight: 1, justifyContent: 'center'}}>
                                            <Text style={styles.contentRight}></Text>
                                        </View>
                                        <View style={{width: 50, borderRight: 1, justifyContent: 'center'}}>
                                            <Text style={styles.contentCenter}>0/100</Text>
                                        </View>
                                        <View style={{width: 100, justifyContent: 'center'}}>
                                            <Text style={styles.contentRight}></Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{display: 'flex', flexDirection: 'row', height: 20, borderBottom: 1}}>
                                <View style={{ display: 'flex', flexDirection: 'row', flex: 1, borderRight: 1 }}>
                                    <View style={{ flex: 1, borderRight: 1, justifyContent: 'center' }}>
                                        <Text style={styles.contentLeft}>예 정 신 고 누 락 분</Text>
                                    </View>
                                    <View style={{ width: 25, justifyContent: 'center' }}>
                                        <Text style={styles.contentCenter}>(7)</Text>
                                    </View>
                                </View>
                                <View style={{ width: 100, borderRight: 1, justifyContent: 'center' }}>
                                    <Text style={styles.contentRight}></Text>
                                </View>
                                <View style={{ width: 50, borderRight: 1, justifyContent: 'center' }}>
                                    <Text style={styles.contentCenter}></Text>
                                </View>
                                <View style={{ width: 100, justifyContent: 'center' }}>
                                    <Text style={styles.contentRight}></Text>
                                </View>
                            </View>
                            <View style={{display: 'flex', flexDirection: 'row', height: 20, borderBottom: 1}}>
                                <View style={{ display: 'flex', flexDirection: 'row', flex: 1, borderRight: 1 }}>
                                    <View style={{ flex: 1, borderRight: 1, justifyContent: 'center' }}>
                                        <Text style={styles.contentLeft}>대 손 세 액 가 감</Text>
                                    </View>
                                    <View style={{ width: 25, justifyContent: 'center' }}>
                                        <Text style={styles.contentCenter}>(8)</Text>
                                    </View>
                                </View>
                                <View style={{ width: 100, borderRight: 1, justifyContent: 'center' }}>
                                    <Text style={styles.contentRight}></Text>
                                </View>
                                <View style={{ width: 50, borderRight: 1, justifyContent: 'center' }}>
                                    <Text style={styles.contentCenter}></Text>
                                </View>
                                <View style={{ width: 100, justifyContent: 'center' }}>
                                    <Text style={styles.contentRight}></Text>
                                </View>
                            </View>
                            <View style={{display: 'flex', flexDirection: 'row', height: 20}}>
                                <View style={{ display: 'flex', flexDirection: 'row', flex: 1, borderRight: 1 }}>
                                    <View style={{ flex: 1, borderRight: 1, justifyContent: 'center' }}>
                                        <Text style={styles.contentLeft}>합 계</Text>
                                    </View>
                                    <View style={{ width: 25, justifyContent: 'center' }}>
                                        <Text style={styles.contentCenter}>(9)</Text>
                                    </View>
                                </View>
                                <View style={{ width: 100, borderRight: 1, justifyContent: 'center' }}>
                                    <Text style={styles.contentRight}>{numberFormat(data['SALE_SPLY_AMT'])}</Text>
                                </View>
                                <View style={{ width: 50, borderRight: 1, justifyContent: 'center' }}>
                                    <Text style={styles.contentCenter}>㉮</Text>
                                </View>
                                <View style={{ width: 100, justifyContent: 'center' }}>
                                    <Text style={styles.contentRight}>{numberFormat(data['SALE_VAT_AMT'])}</Text>
                                </View>
                            </View>
                        </View>
                    </View>


                    <View style={{...styles.titleContainer, display: 'flex', height: 'none', flexDirection: 'row'}}>
                        <View style={{width: 50, height: 160, borderRight: 1, justifyContent: 'center'}}>
                            <Text style={styles.contentCenter}>{`과세\n세액`}</Text>
                        </View>
                        <View style={{display: 'flex', flex: 1, height: 160}}>
                            <View style={{display: 'flex', flexDirection: 'row', height: 40, borderBottom: 1}}>
                                <View style={{width: 50, borderRight: 1, justifyContent: 'center'}}>
                                    <Text style={styles.contentCenter}>{`세금계산서\n수취분`}</Text>
                                </View>
                                <View style={{display: 'flex', flex: 1}}>
                                    <View style={{display: 'flex', flexDirection: 'row', borderBottom: 1, height: 20}}>
                                        <View style={{display: 'flex', flexDirection: 'row', flex: 1, borderRight: 1}}>
                                            <View style={{flex: 1, borderRight: 1, justifyContent: 'center'}}>
                                                <Text style={styles.contentLeft}>일 반 매 입</Text>
                                            </View>
                                            <View style={{width: 25, justifyContent: 'center'}}>
                                                <Text style={styles.contentCenter}>(10)</Text>
                                            </View>
                                        </View>
                                        <View style={{width: 100, borderRight: 1, justifyContent: 'center'}}>
                                            <Text style={styles.contentRight}>{numberFormat(data['IN_TAX_SPLY_AMT'])}</Text>
                                        </View>
                                        <View style={{width: 50, borderRight: 1, justifyContent: 'center'}}>
                                            <Text style={styles.contentCenter}></Text>
                                        </View>
                                        <View style={{width: 100, justifyContent: 'center'}}>
                                            <Text style={styles.contentRight}>{numberFormat(data['IN_TAX_VAT_AMT'])}</Text>
                                        </View>
                                    </View>
                                    <View style={{display: 'flex', flexDirection: 'row', height: 20}}>
                                        <View style={{display: 'flex', flexDirection: 'row', flex: 1, borderRight: 1}}>
                                            <View style={{flex: 1, borderRight: 1, justifyContent: 'center'}}>
                                                <Text style={styles.contentLeft}>고 정 자 산 매 입</Text>
                                            </View>
                                            <View style={{width: 25, justifyContent: 'center'}}>
                                                <Text style={styles.contentCenter}>(11)</Text>
                                            </View>
                                        </View>
                                        <View style={{width: 100, borderRight: 1, justifyContent: 'center'}}>
                                            <Text style={styles.contentRight}>{numberFormat(data['IN_FIX_SPLY_AMT'])}</Text>
                                        </View>
                                        <View style={{width: 50, borderRight: 1, justifyContent: 'center'}}>
                                            <Text style={styles.contentCenter}></Text>
                                        </View>
                                        <View style={{width: 100, justifyContent: 'center'}}>
                                            <Text style={styles.contentRight}>{numberFormat(data['IN_FIX_VAT_AMT'])}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{display: 'flex', flexDirection: 'row', height: 20, borderBottom: 1}}>
                                <View style={{ display: 'flex', flexDirection: 'row', flex: 1, borderRight: 1 }}>
                                    <View style={{ flex: 1, borderRight: 1, justifyContent: 'center' }}>
                                        <Text style={styles.contentLeft}>예 정 신 고 누  락 분</Text>
                                    </View>
                                    <View style={{ width: 25, justifyContent: 'center' }}>
                                        <Text style={styles.contentCenter}>(12)</Text>
                                    </View>
                                </View>
                                <View style={{ width: 100, borderRight: 1, justifyContent: 'center' }}>
                                    <Text style={styles.contentRight}></Text>
                                </View>
                                <View style={{ width: 50, borderRight: 1, justifyContent: 'center' }}>
                                    <Text style={styles.contentCenter}></Text>
                                </View>
                                <View style={{ width: 100, justifyContent: 'center' }}>
                                    <Text style={styles.contentRight}></Text>
                                </View>
                            </View>
                            <View style={{display: 'flex', flexDirection: 'row', height: 20, borderBottom: 1}}>
                                <View style={{ display: 'flex', flexDirection: 'row', flex: 1, borderRight: 1 }}>
                                    <View style={{ flex: 1, borderRight: 1, justifyContent: 'center' }}>
                                        <Text style={styles.contentLeft}>매 입 자 발 행 세 금 계 산 서</Text>
                                    </View>
                                    <View style={{ width: 25, justifyContent: 'center' }}>
                                        <Text style={styles.contentCenter}>(13)</Text>
                                    </View>
                                </View>
                                <View style={{ width: 100, borderRight: 1, justifyContent: 'center' }}>
                                    <Text style={styles.contentRight}></Text>
                                </View>
                                <View style={{ width: 50, borderRight: 1, justifyContent: 'center' }}>
                                    <Text style={styles.contentCenter}></Text>
                                </View>
                                <View style={{ width: 100, justifyContent: 'center' }}>
                                    <Text style={styles.contentRight}></Text>
                                </View>
                            </View>
                            <View style={{display: 'flex', flexDirection: 'row', height: 20, borderBottom: 1}}>
                                <View style={{ display: 'flex', flexDirection: 'row', flex: 1, borderRight: 1 }}>
                                    <View style={{ flex: 1, borderRight: 1, justifyContent: 'center' }}>
                                        <Text style={styles.contentLeft}>그 밖 의 공 제 매 입 세 액</Text>
                                    </View>
                                    <View style={{ width: 25, justifyContent: 'center' }}>
                                        <Text style={styles.contentCenter}>(14)</Text>
                                    </View>
                                </View>
                                <View style={{ width: 100, borderRight: 1, justifyContent: 'center' }}>
                                    <Text style={styles.contentRight}>{numberFormat(data['IN_ETC_SPLY_AMT'])}</Text>
                                </View>
                                <View style={{ width: 50, borderRight: 1, justifyContent: 'center' }}>
                                    <Text style={styles.contentCenter}></Text>
                                </View>
                                <View style={{ width: 100, justifyContent: 'center' }}>
                                    <Text style={styles.contentRight}>{numberFormat(data['IN_ETC_VAT_AMT'])}</Text>
                                </View>
                            </View>
                            <View style={{display: 'flex', flexDirection: 'row', height: 20, borderBottom: 1}}>
                                <View style={{ display: 'flex', flexDirection: 'row', flex: 1, borderRight: 1 }}>
                                    <View style={{ flex: 1, borderRight: 1, justifyContent: 'center' }}>
                                        <Text style={styles.contentLeft}>합계(10) + (11) + (12) + (13) + (14)</Text>
                                    </View>
                                    <View style={{ width: 25, justifyContent: 'center' }}>
                                        <Text style={styles.contentCenter}>(15)</Text>
                                    </View>
                                </View>
                                <View style={{ width: 100, borderRight: 1, justifyContent: 'center' }}>
                                    <Text style={styles.contentRight}>{numberFormat(data['IN_SPLY_AMT'])}</Text>
                                </View>
                                <View style={{ width: 50, borderRight: 1, justifyContent: 'center' }}>
                                    <Text style={styles.contentCenter}></Text>
                                </View>
                                <View style={{ width: 100, justifyContent: 'center' }}>
                                    <Text style={styles.contentRight}>{numberFormat(data['IN_VAT_AMT'])}</Text>
                                </View>
                            </View>
                            <View style={{display: 'flex', flexDirection: 'row', height: 20, borderBottom: 1}}>
                                <View style={{ display: 'flex', flexDirection: 'row', flex: 1, borderRight: 1 }}>
                                    <View style={{ flex: 1, borderRight: 1, justifyContent: 'center' }}>
                                        <Text style={styles.contentLeft}>공 제 받 지 못 할 매 입 세 액</Text>
                                    </View>
                                    <View style={{ width: 25, justifyContent: 'center' }}>
                                        <Text style={styles.contentCenter}>(16)</Text>
                                    </View>
                                </View>
                                <View style={{ width: 100, borderRight: 1, justifyContent: 'center' }}>
                                    <Text style={styles.contentRight}>{numberFormat(data['IN_NOTDED_SPLY_AMT'])}</Text>
                                </View>
                                <View style={{ width: 50, borderRight: 1, justifyContent: 'center' }}>
                                    <Text style={styles.contentCenter}></Text>
                                </View>
                                <View style={{ width: 100, justifyContent: 'center' }}>
                                    <Text style={styles.contentRight}>{numberFormat(data['IN_NOTDED_VAT_AMT'])}</Text>
                                </View>
                            </View>
                            <View style={{display: 'flex', flexDirection: 'row', height: 20}}>
                                <View style={{ display: 'flex', flexDirection: 'row', flex: 1, borderRight: 1 }}>
                                    <View style={{ flex: 1, borderRight: 1, justifyContent: 'center' }}>
                                        <Text style={styles.contentLeft}>차 감 계 (15) - (16)</Text>
                                    </View>
                                    <View style={{ width: 25, justifyContent: 'center' }}>
                                        <Text style={styles.contentCenter}>(17)</Text>
                                    </View>
                                </View>
                                <View style={{ width: 100, borderRight: 1, justifyContent: 'center' }}>
                                    <Text style={styles.contentRight}>{numberFormat(data['IN_GAP_SPLY_AMT'])}</Text>
                                </View>
                                <View style={{ width: 50, borderRight: 1, justifyContent: 'center' }}>
                                    <Text style={styles.contentCenter}>㉯</Text>
                                </View>
                                <View style={{ width: 100, justifyContent: 'center' }}>
                                    <Text style={styles.contentRight}>{numberFormat(data['IN_GAP_VAT_AMT'])}</Text>
                                </View>
                            </View>
                        </View>
                    </View>


                    <View style={{...styles.titleContainer, display: 'flex', height: 'none', flexDirection: 'row'}}>
                        <View style={{flex: 1, height: 20, borderRight: 1, justifyContent: 'center'}}>
                            <Text style={styles.contentLeft}>납 부 ( 환 급 ) 세 액 ( 매 출 세 액 ㉮ - 매 입 세 액 ㉯ )</Text>
                        </View>
                        <View style={{width: 50, height: 20, borderRight: 1, justifyContent: 'center'}}>
                            <Text style={styles.contentCenter}>㉰</Text>
                        </View>
                        <View style={{width: 100, height: 20, justifyContent: 'center'}}>
                            <Text style={styles.contentRight}>{numberFormat(data['PAY_VAT_AMT'])}</Text>
                        </View>
                    </View>


                    <View style={{...styles.titleContainer, display: 'flex', height: 'none', flexDirection: 'row'}}>
                        <View style={{width: 50, height: 60, borderRight: 1, justifyContent: 'center'}}>
                            <Text style={styles.contentCenter}>{`경감\n.\n공제\n세액`}</Text>
                        </View>
                        <View style={{display: 'flex', flex: 1, height: 60}}>
                            <View style={{display: 'flex', flexDirection: 'row', height: 20, borderBottom: 1}}>
                                <View style={{ display: 'flex', flexDirection: 'row', flex: 1, borderRight: 1 }}>
                                    <View style={{ flex: 1, borderRight: 1, justifyContent: 'center' }}>
                                        <Text style={styles.contentLeft}>그 밖 의 경 감 . 공 제 세 액</Text>
                                    </View>
                                    <View style={{ width: 25, justifyContent: 'center' }}>
                                        <Text style={styles.contentCenter}>(18)</Text>
                                    </View>
                                </View>
                                <View style={{ width: 100, borderRight: 1, justifyContent: 'center' }}>
                                    <Text style={styles.contentRight}></Text>
                                </View>
                                <View style={{ width: 50, borderRight: 1, justifyContent: 'center' }}>
                                    <Text style={styles.contentCenter}></Text>
                                </View>
                                <View style={{ width: 100, justifyContent: 'center' }}>
                                    <Text style={styles.contentRight}></Text>
                                </View>
                            </View>
                            <View style={{display: 'flex', flexDirection: 'row', height: 20, borderBottom: 1}}>
                                <View style={{ display: 'flex', flexDirection: 'row', flex: 1, borderRight: 1 }}>
                                    <View style={{ flex: 1, borderRight: 1, justifyContent: 'center' }}>
                                        <Text style={styles.contentLeft}>신 용 카 드 매 출 전 표 등 발 행 공 제 등</Text>
                                    </View>
                                    <View style={{ width: 25, justifyContent: 'center' }}>
                                        <Text style={styles.contentCenter}>(19)</Text>
                                    </View>
                                </View>
                                <View style={{ width: 100, borderRight: 1, justifyContent: 'center' }}>
                                    <Text style={styles.contentRight}>{numberFormat(data['SALE_ISSUE_DEDUCT'])}</Text>
                                </View>
                                <View style={{ width: 50, borderRight: 1, justifyContent: 'center' }}>
                                    <Text style={styles.contentCenter}></Text>
                                </View>
                                <View style={{ width: 100, justifyContent: 'center' }}>
                                    <Text style={styles.contentRight}></Text>
                                </View>
                            </View>
                            <View style={{display: 'flex', flexDirection: 'row', height: 20}}>
                                <View style={{ display: 'flex', flexDirection: 'row', flex: 1, borderRight: 1 }}>
                                    <View style={{ flex: 1, borderRight: 1, justifyContent: 'center' }}>
                                        <Text style={styles.contentLeft}>합 계</Text>
                                    </View>
                                    <View style={{ width: 25, justifyContent: 'center' }}>
                                        <Text style={styles.contentCenter}>(20)</Text>
                                    </View>
                                </View>
                                <View style={{ width: 100, borderRight: 1, justifyContent: 'center' }}>
                                    <Text style={styles.contentRight}>{numberFormat(data['SALE_ISSUE_DEDUCT'])}</Text>
                                </View>
                                <View style={{ width: 50, borderRight: 1, justifyContent: 'center' }}>
                                    <Text style={styles.contentCenter}>㉱</Text>
                                </View>
                                <View style={{ width: 100, justifyContent: 'center' }}>
                                    <Text style={styles.contentRight}></Text>
                                </View>
                            </View>
                        </View>
                    </View>


                    <View style={{...styles.titleContainer, display: 'flex', height: 'none', flexDirection: 'row'}}>
                        <View style={{display: 'flex', flexDirection: 'row', flex: 1, height: 20, borderRight: 1}}>
                            <View style={{flex: 1, borderRight: 1, justifyContent: 'center'}}>
                                <Text style={styles.contentLeft}>예 정 신 고 미 환 급 세 액</Text>
                            </View>
                            <View style={{width: 25, justifyContent: 'center'}}>
                                <Text style={styles.contentCenter}>(21)</Text>
                            </View>
                        </View>
                        <View style={{width: 100, height: 20, borderRight: 1, justifyContent: 'center'}}>
                            <Text style={styles.contentRight}></Text>
                        </View>
                        <View style={{width: 50, height: 20, borderRight: 1, justifyContent: 'center'}}>
                            <Text style={styles.contentCenter}>㉲</Text>
                        </View>
                        <View style={{width: 100, height: 20, justifyContent: 'center'}}>
                            <Text style={styles.contentRight}></Text>
                        </View>
                    </View>


                    <View style={{...styles.titleContainer, display: 'flex', height: 'none', flexDirection: 'row'}}>
                        <View style={{display: 'flex', flexDirection: 'row', flex: 1, height: 20, borderRight: 1}}>
                            <View style={{flex: 1, borderRight: 1, justifyContent: 'center'}}>
                                <Text style={styles.contentLeft}>예 정 고 지 세 액</Text>
                            </View>
                            <View style={{width: 25, justifyContent: 'center'}}>
                                <Text style={styles.contentCenter}>(22)</Text>
                            </View>
                        </View>
                        <View style={{width: 100, height: 20, borderRight: 1, justifyContent: 'center'}}>
                            <Text style={styles.contentRight}></Text>
                        </View>
                        <View style={{width: 50, height: 20, borderRight: 1, justifyContent: 'center'}}>
                            <Text style={styles.contentCenter}>㉳</Text>
                        </View>
                        <View style={{width: 100, height: 20, justifyContent: 'center'}}>
                            <Text style={styles.contentRight}></Text>
                        </View>
                    </View>


                    <View style={{...styles.titleContainer, display: 'flex', height: 'none', flexDirection: 'row'}}>
                        <View style={{display: 'flex', flexDirection: 'row', flex: 1, height: 20, borderRight: 1}}>
                            <View style={{flex: 1, borderRight: 1, justifyContent: 'center'}}>
                                <Text style={styles.contentLeft}>사 업 양 수 자 의 대 리 납 부 기 납 부 세 액</Text>
                            </View>
                            <View style={{width: 25, justifyContent: 'center'}}>
                                <Text style={styles.contentCenter}>(23)</Text>
                            </View>
                        </View>
                        <View style={{width: 100, height: 20, borderRight: 1, justifyContent: 'center'}}>
                            <Text style={styles.contentRight}></Text>
                        </View>
                        <View style={{width: 50, height: 20, borderRight: 1, justifyContent: 'center'}}>
                            <Text style={styles.contentCenter}>㉴</Text>
                        </View>
                        <View style={{width: 100, height: 20, justifyContent: 'center'}}>
                            <Text style={styles.contentRight}></Text>
                        </View>
                    </View>


                    <View style={{...styles.titleContainer, display: 'flex', height: 'none', flexDirection: 'row'}}>
                        <View style={{display: 'flex', flexDirection: 'row', flex: 1, height: 20, borderRight: 1}}>
                            <View style={{flex: 1, borderRight: 1, justifyContent: 'center'}}>
                                <Text style={styles.contentLeft}>매 입 자 납 부 특 례 기 납 부 세 액</Text>
                            </View>
                            <View style={{width: 25, justifyContent: 'center'}}>
                                <Text style={styles.contentCenter}>(24)</Text>
                            </View>
                        </View>
                        <View style={{width: 100, height: 20, borderRight: 1, justifyContent: 'center'}}>
                            <Text style={styles.contentRight}></Text>
                        </View>
                        <View style={{width: 50, height: 20, borderRight: 1, justifyContent: 'center'}}>
                            <Text style={styles.contentCenter}>㉵</Text>
                        </View>
                        <View style={{width: 100, height: 20, justifyContent: 'center'}}>
                            <Text style={styles.contentRight}></Text>
                        </View>
                    </View>


                    <View style={{...styles.titleContainer, display: 'flex', height: 'none', flexDirection: 'row'}}>
                        <View style={{display: 'flex', flexDirection: 'row', flex: 1, height: 20, borderRight: 1}}>
                            <View style={{flex: 1, borderRight: 1, justifyContent: 'center'}}>
                                <Text style={styles.contentLeft}>가 산 세 액 계</Text>
                            </View>
                            <View style={{width: 25, justifyContent: 'center'}}>
                                <Text style={styles.contentCenter}>(25)</Text>
                            </View>
                        </View>
                        <View style={{width: 100, height: 20, borderRight: 1, justifyContent: 'center'}}>
                            <Text style={styles.contentRight}></Text>
                        </View>
                        <View style={{width: 50, height: 20, borderRight: 1, justifyContent: 'center'}}>
                            <Text style={styles.contentCenter}>㉶</Text>
                        </View>
                        <View style={{width: 100, height: 20, justifyContent: 'center'}}>
                            <Text style={styles.contentRight}></Text>
                        </View>
                    </View>


                    <View style={{...styles.titleContainer, display: 'flex', height: 'none', flexDirection: 'row'}}>
                        <View style={{flex: 1, height: 20, borderRight: 1, justifyContent: 'center'}}>
                            <Text style={styles.contentLeft}>차 감 . 가 감 하 여 납 부 할 세 액 ( 환 급 받 을 세 액 ) ㉰-㉱-㉲-㉳-㉴-㉵+㉶</Text>
                        </View>
                        <View style={{width: 50, height: 20, borderRight: 1, justifyContent: 'center'}}>
                            <Text style={styles.contentCenter}>(26)</Text>
                        </View>
                        <View style={{width: 100, height: 20, justifyContent: 'center'}}>
                            <Text style={styles.contentRight}>{numberFormat(data['TOTAL_PAY_VAT_AMT'])}</Text>
                        </View>
                    </View>


                    <View style={{...styles.titleContainer, display: 'flex', height: 'none', flexDirection: 'row'}}>
                        <View style={{flex: 1, height: 20, borderRight: 1, justifyContent: 'center'}}>
                            <Text style={styles.contentLeft}>총 괄 납 부 사 업 자 가 납 부 할 세 액 ( 환 급 받 을 세 액 )</Text>
                        </View>
                        <View style={{width: 100, height: 20, justifyContent: 'center'}}>
                            <Text style={styles.contentRight}></Text>
                        </View>
                    </View>


                    <View style={{...styles.titleContainer, display: 'flex', height: 'none', flexDirection: 'row'}}>
                        <View style={{width: 50, height: 40, borderRight: 1, justifyContent: 'center'}}>
                            <Text style={styles.contentCenter}>{`계산서발금\n및수취명세`}</Text>
                        </View>
                        <View style={{display: 'flex', flex: 1, height: 40}}>
                            <View style={{display: 'flex', flexDirection: 'row', height: 20, borderBottom: 1}}>
                                <View style={{flex: 1, borderRight: 1, justifyContent: 'center' }}>
                                    <Text style={styles.contentLeft}>(82) 계산서 발급금액</Text>
                                </View>
                                <View style={{ width: 100, justifyContent: 'center' }}>
                                    <Text style={styles.contentRight}>{numberFormat(data['SALE_NOTTAX_SPLY_AMT'])}</Text>
                                </View>
                            </View>
                            <View style={{display: 'flex', flexDirection: 'row', height: 20}}>
                                <View style={{flex: 1, borderRight: 1, justifyContent: 'center' }}>
                                    <Text style={styles.contentLeft}>(83) 계산서 수취금액</Text>
                                </View>
                                <View style={{ width: 100, justifyContent: 'center' }}>
                                    <Text style={styles.contentRight}>{numberFormat(data['IN_NOTTAX_SPLY_AMT'])}</Text>
                                </View>
                            </View>
                        </View>
                    </View>

                </View>
            </PdfPage>
        </Document>
    )
}

export default ValueAddedTaxPdf;