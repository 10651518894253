import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { SearchItem, SearchPanel, ComboBox, ProgressPanel, DatePicker } from '../../component';
import { makeStyles } from '@material-ui/core';
import { callProc } from '../../common/DBConnector';
import { PDFViewer } from '@react-pdf/renderer';
import ValueAddedTaxPdf from '../../reports/ValueAddedTaxPdf';

const useStyle = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    pdfContainer : {
        flex: 1,
    }
}))

const ValueAddedTax = () => {
    const classes = useStyle();
    const [condition, setCondition] = useState();
    const [workData, setWorkData] = useState();
    const [valueTax, setValueTax] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const workData = (await callProc('SLT_WORKPLACEINFO_AUTH')).data;

            setWorkData(workData);
        }
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setValueTax(null);

            const valueTax = await callProc('SLT_VALUEADDEDTAX', condition);
            
            setValueTax(valueTax);
        }
        condition && fetchData();
    }, [condition]);

    return (
        !workData ? <ProgressPanel /> :
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => setCondition(condition)}>
                <SearchItem
                    fieldName='WORK_CD'
                    component={(state, dispatch) => (
                        <ComboBox
                            label='사업장명'
                            data={workData}
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_MONTH'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작월'
                            monthPicker
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_MONTH']) {
                                    dispatch('END_MONTH', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_MONTH'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료월'
                            monthPicker
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_MONTH']) {
                                    dispatch('STA_MONTH', value);
                                }
                            }}
                        />
                    )}
                />
            </SearchPanel>
            {!valueTax ? <ProgressPanel /> :
            <PDFViewer className={classes.pdfContainer}>
                <ValueAddedTaxPdf 
                    data={valueTax.data[0]}
                />
            </PDFViewer>}
        </div>
    )
}

export default ValueAddedTax;