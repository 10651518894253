import React, { useState } from 'react';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DataColumn, ComboSearch, NumberField, DateTimePicker} from '../../component';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import { dscrateType } from '../../common/Dictionary';
import { isFieldDiff } from '../../common/Utils';

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
}))

const InpriceWork = () => {
    const classes = useStyles();
    const [condition, setCondition] = useState(null);

    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => setCondition(condition)}>
                <SearchItem
                    fieldName='WORK_CD'
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => {
                                dispatch(value);
                            }}
                        />
                    )}
                />


                <SearchItem
                    fieldName='GDS_CD'
                    component={(state, setState, condition, setField) => {
                        let param = condition['GDS_PARAM'] || null;
                        if (isFieldDiff(['WORK_CD'], condition, param)) {
                            param = { WORK_CD: condition['WORK_CD'] };
                            setField('GDS_PARAM', param);
                        }
                        return (
                            <ComboSearch
                                label='상품명'
                                selectProc='SLT_GOODSINFO'
                                selectParam={param}
                                keyField='GDS_CD'
                                valueField='GDS_NM'
                                value={state}
                                onChange={value => setState(value)}
                            />
                        )
                    }}
                />
            </SearchPanel>
            <DataGrid
                title='사업장매입단가정보'
                table='TB_INPRICE_WORK'
                selectProc='SLT_INPRICE_WORK'
                insertProc='INS_INPRICE_WORK'
                deleteProc='DLT_INPRICE_WORK'
                selectParam={condition}
                selectionMode='single'
                horizonMode
            >
                <DataColumn
                    fieldName='WORK_CD'
                    title='사업장'
                    value={row => row['WORK_NM']}
                    defaultValue={condition && condition['WORK_CD']}
                    editable={(state, dispatch) => (
                        <ComboBox
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            selectParam={condition}
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={value => dispatch(value)}
                            disabled={true}
                        />
                    )}
                />



                <DataColumn
                    fieldName='GDS_CD'
                    title='상품명'
                    value={row => row['GDS_NM']}
                    defaultValue={condition && condition['GDS_CD']}
                    editable={(state, dispatch) => (
                        <ComboBox
                            selectProc='SLT_GOODSINFO'
                            selectParam={condition}
                            keyField='GDS_CD'
                            valueField='GDS_NM'
                            value={state}
                            onChange={value => dispatch(value)}
                            disabled={true}
                        />
                    )}
                />

                <DataColumn
                    fieldName='IN_CST_CD'
                    title='매입처'
                    value={row => row['IN_CST_NM']}
                    defaultValue={condition && condition['IN_CST_CD']}
                    editable={(state, dispatch) => (
                        <ComboBox
                            selectProc='SLT_INCUSTOMER'
                            selectParam={condition}
                            keyField='IN_CST_CD'
                            valueField='IN_CST_NM'
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                />

               <DataColumn
                    fieldName='STA_DTTM'
                    title='시작일시'
                    defaultValue={moment().format('YYYY-MM-DD')}
                    editable={(state, dispatch) => (
                        <DateTimePicker
                            value={state}
                            onChange={value => {
                                dispatch(value);
                            }}
                        />
                    )}
                />

                <DataColumn
                    fieldName='DSCRATE_TYPE'
                    title='할인/할증구분'
                    value={(row, value) => dscrateType[value]}
                    defaultValue={Object.keys(dscrateType)[0]}
                    editable={(state, dispatch) => (
                        <ComboBox
                            data={dscrateType}
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                />

                <DataColumn
                    fieldName='APPLY_AMT'
                    editable={(state, dispatch) => (
                        <NumberField
                            style={{width: 150}}
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                /> 

                <DataColumn
                    fieldName='END_DTTM'
                    title='종료일시'
                    defaultValue={'9999-12-31 23:59:59'}
                    visible={false}
                />

                <DataColumn
                    fieldName='WORK_NM'
                    visible={false}
                />
                <DataColumn
                    fieldName='GDS_NM'
                    visible={false}
                />
                <DataColumn
                    fieldName='SEQ'
                    visible={false}
                />


            </DataGrid>
        </div>
    )
}

export default InpriceWork