import { TextField } from '@material-ui/core';
import React from 'react';

export default ({ value, onChange, maxLength, inputProps, ...props }) => {

    const handleChange = (event) => {
        onChange && onChange(event.target.value)
    }

    return (
        <TextField
            onChange={handleChange}
            inputProps={{ maxLength: maxLength, ...inputProps }}
            value={value == null ? '' : value}
            {...props}
        />
    )
}