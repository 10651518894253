import React, { useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';
import { SearchPanel, SearchItem, ComboBox, DataGrid, DataColumn, ComboSearch, Tabs, Tab, NumberField, DateTimePicker } from '../../component';
import { isFieldDiff } from '../../common/Utils';
import clsx from 'clsx';


const useStyle = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    tabContainer : {
        display : 'flex',
    },
    tabbar : {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    content : {
        display: 'flex',
        flex: 1,
        height : 0,
    },
    hide : {
        display: 'none'
    },
}))

const ShippingCostChargeTariffSale = () => {
    const classes = useStyle();
    const [condition, setCondition] = useState(null);
    const [selectTab, setSelectTab] = useState(0);

    return(
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => setCondition(condition)}>
                <SearchItem
                    fieldName='WORK_CD'
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => {
                                dispatch(value);
                            }}
                        />
                    )}
                />

                <SearchItem
                    fieldName='TSP_CAR_CD'
                    component={(state, setState, condition, setField) => {
                        let param = condition['TSP_CAR_PARAM'] || null;
                        if (isFieldDiff(['WORK_CD'], condition, param)) {
                            param = { WORK_CD: condition['WORK_CD'] };
                            setField('TSP_CAR_PARAM', param);
                        }
                        return (
                            <ComboSearch
                                label='운송수단'
                                selectProc='SLT_TRANSCAR_COMBO'
                                selectParam={param}
                                keyField='TSP_CAR_CD'
                                valueField='CAR_NO'
                                value={state}
                                onChange={value => setState(value)}
                            />
                        )
                    }}
                />
            </SearchPanel>

            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab label='현재적용 매출 운임요율' />
                    <Tab label='전체 매출 운임요율' />
                </Tabs>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <OuttransPriceNow condition={condition} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <OuttransPriceAll condition={condition} />
            </div>

        </div>
    )
}

const OuttransPriceNow = ({condition}) => {


    return(
        <DataGrid
        title='현재적용 매출 운임요율'
        table='TB_OUTTRANSPRICE'
        selectProc='SLT_OUTTRANSPRICE'
        selectParam={condition}
        selectionMode='single'
        horizonMode
        excelDownload={{ fileName: '현재적용 매출 운임요율.xlsx', sheetName: '매출운임요율' }}
    >
        
        <DataColumn
            fieldName='SHIP_TRN_CD'
            value={row => row['SHIP_TRN_NM']}
            title='출하처'
        />

        <DataColumn
            fieldName='DEST_TRN_CD'
            value={row => row['DEST_TRN_NM']}
            title='인도처'
        />

        <DataColumn
            fieldName='STA_DTTM'
            title='시작일시'
        />

        <DataColumn
            fieldName='SPLY_AMT'
            title='공급가액'
        />

        <DataColumn
            fieldName='AMOUNT'
            title='금액'
        />
        
        <DataColumn
                fieldName='SHIP_TRN_NM'
                visible={false}
        />
        <DataColumn
                fieldName='DEST_TRN_NM'
                visible={false}
        />

 
    </DataGrid>
    )
}


const OuttransPriceAll = ({condition}) => {


    return(
        <DataGrid
            title='전체 매출 운임요율'
            selectProc='SLT_OUTTRANSPRICE_ALL'
            insertProc='INS_OUTTRANSPRICE'
            updateProc='UPT_OUTTRANSPRICE'
            deleteProc='DLT_OUTTRANSPRICE'
            selectParam={condition}
            keys={['WORK_CD', 'TSP_CAR_CD', 'SHIP_TRN_CD', 'DEST_TRN_CD', 'STA_DTTM', 'SEQ']}
            selectionMode='single'
            horizonMode
        >
            <DataColumn
                fieldName='WORK_CD'
                title='사업장'
                value={row => row['WORK_NM']}
                defaultValue={condition && condition['WORK_CD']}
                editable={(state, dispatch) => (
                    <ComboBox
                        selectProc='SLT_WORKPLACEINFO_AUTH'
                        selectParam={condition}
                        keyField='WORK_CD'
                        valueField='WORK_NM'
                        value={state}
                        onChange={value => dispatch(value)}
                        disabled={true}
                    />
                )}
            />
            <DataColumn
                fieldName='TSP_CAR_CD'
                title='운송수단'
                value={row => row['CAR_NO']}
                defaultValue={condition && condition['TSP_CAR_CD']}
                editable={(state, dispatch) => (
                    <ComboBox
                        selectProc='SLT_TRANSCARINFO'
                        selectParam={condition}
                        keyField='TSP_CAR_CD'
                        valueField='CAR_NO'
                        value={state}
                        onChange={value => dispatch(value)}
                        disabled={true}
                    />
                )}
            />
            <DataColumn
                fieldName='SHIP_TRN_CD'
                title='출하처'
                value={row => row['SHIP_TRN_NM']}
                editable={(state, dispatch, rowState, setField) => {
                    let param = rowState['SHIP_PARAM'] || null;
                    if (isFieldDiff(['WORK_CD'], rowState, param)) {
                        param = { WORK_CD: rowState['WORK_CD'], TSP_TYPE: 0 };
                        setField('SHIP_PARAM', param);
                    }

                    return(
                    <ComboSearch
                        style={{width: 200}}
                        selectProc='SLT_TRANSPLACE'
                        selectParam={param} //0
                        keyField='TSP_CD'
                        valueField='TSP_NM'
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}}
            />
            <DataColumn
                fieldName='DEST_TRN_CD'
                title='인도처'
                value={row => row['DEST_TRN_NM']}
                editable={(state, dispatch, rowState, setField) => {
                    let param = rowState['DEST_PARAM'] || null;
                    if (isFieldDiff(['WORK_CD'], rowState, param)) {
                        param = { WORK_CD: rowState['WORK_CD'], TSP_TYPE: 1 };
                        setField('DEST_PARAM', param);
                    }

                    return(
                    <ComboSearch
                        style={{width: 200}}
                        selectProc='SLT_TRANSPLACE'
                        selectParam={param} //0
                        keyField='TSP_CD'
                        valueField='TSP_NM'
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}}
            />
            <DataColumn
                fieldName='STA_DTTM'
                title='시작일시'
                defaultValue={moment().format('YYYY-MM-DD 00:00:00')}
                editable={(state, dispatch) => (
                    <DateTimePicker
                        value={state}
                        onChange={value => {
                            dispatch(value);
                        }}
                    />
                )}
            />
            <DataColumn
                fieldName='SPLY_AMT'
                title='공급가액'
                editable={(state, dispatch, rowState, setField) => (
                    <NumberField
                        style={{width: 100}}
                        value={state}
                        onChange={value => dispatch(value)}
                        onBlur={() =>  {
                            setField('AMOUNT', (rowState['SPLY_AMT'] * 1.1).toFixed(3));
                        }}
                    />
                )}
            />
            <DataColumn
                fieldName='AMOUNT'
                title='금액'
                editable={(state, dispatch, rowState, setField) => (
                    <NumberField
                        style={{width: 100}}
                        value={state}
                        onChange={value => dispatch(value)}
                        onBlur={() =>  {
                            if(String(rowState['SPLY_AMT']) === '0'){
                                setField('SPLY_AMT', (rowState['AMOUNT'] / 1.1).toFixed(3));
                            }
                        }}
                    />
                )}
            />
            <DataColumn
                    fieldName='SEQ'
                    visible={false}
            />
            <DataColumn
                    fieldName='WORK_NM'
                    visible={false}
            />
            <DataColumn
                    fieldName='CAR_NO'
                    visible={false}
            />
            <DataColumn
                    fieldName='SHIP_TRN_NM'
                    visible={false}
            />
            <DataColumn
                    fieldName='DEST_TRN_NM'
                    visible={false}
            />
        </DataGrid>
    )
}

export default ShippingCostChargeTariffSale