import React from 'react';
import { Toolbar, Typography } from '@material-ui/core';

const PaperTitle = ({ children }) => {
    return (
        <Toolbar>
            <Typography component="h2" variant="h6" gutterBottom>
                {children}
            </Typography>
        </Toolbar>
    )
}

export default PaperTitle;