import React from 'react';
import { FormControlLabel, Radio } from '@material-ui/core';

const RadioItem = (props) => {
    return (
        <FormControlLabel
            control={<Radio color='primary'/>}
            {...props}
        />
    )
}

export default RadioItem;