import React, { useState, useRef, useEffect } from 'react';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DataColumn, AddressPicker, IconButton, TextField, Tabs, Tab, DatePicker} from '../../component';
import { makeStyles } from '@material-ui/core';
import { cstType, YNType, cstKind } from '../../common/Dictionary';
import EditIcon from '@material-ui/icons/Edit';
import { Close } from '@material-ui/icons';
import { callProc } from '../../common/DBConnector';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';

const seachCstType = {
    'ALL' : '전체',
    0 : '매입처',
    1 : '매출처',
}

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    content: {
        flex: 1,
        height: 0,
    },
    buttonField: {
        display: 'flex',
        alignItems: 'center',
    },
    tabContainer : {
        display : 'flex',
    },
    tabbar : {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    hide : {
        display: 'none'
    },
}))

const CustomerInfo = () => {
    const [condition, setCondition] = useState(null);
    const [selectTab, setSelectTab] = useState(0);
    
    const groupParam = useRef();

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <SearchPanel
                onSearch={(condition) => {
                    groupParam.current = { ...condition };
                    setCondition(condition);
                }}
            >
                <SearchItem
                    fieldName='WORK_CD'
                    component={(state, dispatch) => (
                        <ComboBox
                            label='사업장명'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='CST_TYPE'
                    defaultValue={'ALL'}
                    component={(state, dispatch) => (
                        <ComboBox
                            label='거래타입'
                            data={seachCstType}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='CST_NM'
                    defaultValue={''}
                    component={(state, dispatch) => (
                        <TextField
                            label='거래처명'
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                />
            </SearchPanel>

            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab label='외상' />
                    <Tab label='비상품 거래처' />
                    <Tab label='단순 지출처' />
                    <Tab label='기타' />
                    <Tab label='이관거래처' />
                    <Tab label='자가소비거래처' />
                    <Tab label='매출보관거래처' />
                    <Tab label='종료 거래처' />
                </Tabs>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <CommonCustomer condition={condition} sltCstKind={1} sltCstKindName={'외상'}/> {/* 외상 */}
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <CommonCustomer condition={condition} sltCstKind={2} sltCstKindName={'비상품 거래처'}/> {/* 비상품 거래처 */}
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 2 })}>
                <CommonCustomer condition={condition} sltCstKind={3} sltCstKindName={'단순 지출처'}/> {/* 단순 지출처 */}
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 3 })}>
                <CommonCustomer condition={condition} sltCstKind={4} sltCstKindName={'기타'}/> {/* 기타 */}
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 4 })}>
                <CommonCustomer condition={condition} sltCstKind={6} sltCstKindName={'이관거래처'}/> {/* 이관거래처 */}
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 5 })}>
                <CommonCustomer condition={condition} sltCstKind={7} sltCstKindName={'자가소비거래처'}/> {/* 자가소비거래처 */}
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 6 })}>
                <CommonCustomer condition={condition} sltCstKind={8} sltCstKindName={'매출보관거래처'}/> {/* 매출보관거래처 */}
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 7 })}>
                <CommonCustomer condition={condition} sltCstKind={99} sltCstKindName={'종료거래처'}/> {/* 종료거래처 */}
            </div>
                            
            
        </div>
    )
}


const CommonCustomer = ({ condition, sltCstKind, sltCstKindName }) => {

    const [gridparam , setGridParam] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    useEffect(() => {
        condition && setGridParam({...condition, CST_KIND: sltCstKind});
    },[condition, sltCstKind])


    const onInsert = async(row) => {
        if (valid) {
            row['TELNO1_ARR'] = row['TELNO1'] ? row['TELNO1'].split('-') : null;
            row['TELNO2_ARR'] = row['TELNO2'] ? row['TELNO2'].split('-') : null;
            return await callProc('INS_CUSTOMERINFO', row);
        }
    }

    const onUpdate = async(row) => {
        if (valid) {
            row['TELNO1_ARR'] = row['TELNO1'] ? row['TELNO1'].split('-') : null;
            row['TELNO2_ARR'] = row['TELNO2'] ? row['TELNO2'].split('-') : null;
            return await callProc('UPT_CUSTOMERINFO', row);
        }
    }

    const valid = async(row) => {
        if (row['CST_WORK_CD']) {
            const validResult = (await callProc('SLT_CSTWORKCD_VALID', row)).data.length === 0;
            if (!validResult) {
                enqueueSnackbar('이미 등록된 이관사업장입니다.', { variant: 'error' });
                return false;
            }
        }

        return true;
    }

    return (
        <DataGrid
            title='거래처'
            selectProc='SLT_CUSTOMERINFO_NOTEND'
            onInsert={onInsert}
            onUpdate={onUpdate}
            deleteProc='DLT_CUSTOMERINFO'
            selectParam={gridparam}
            onDataLoad={dataSet => {
                dataSet.data = dataSet.data.map(row => ({
                    ...row,
                    CST_PAYCD: JSON.parse(row['CST_PAYCD'])
                }));
                return dataSet;
            }}
            horizonMode
            excelDownload={{ fileName: '거래처_'+sltCstKindName+'.xlsx', sheetName: '거래처_'+sltCstKindName }}
            virtualized
            keys={['CST_CD']}
        >
            <DataColumn
                fieldName='CST_CD'
                editable={(state, dispatch) => (
                    <TextField
                        style={{ width: 90 }}
                        value={state}
                        onChange={value => dispatch(value)}
                        maxLength={6}
                    />
                )}
            />
            <DataColumn
                fieldName='WORK_CD'
                title='사업장명'
                value={row => row['WORK_NM']}
                defaultValue={condition && condition['WORK_CD']}
                editable={(state, dispatch) => (
                    <ComboBox
                        selectProc='SLT_WORKPLACEINFO_AUTH'
                        keyField='WORK_CD'
                        valueField='WORK_NM'
                        value={state}
                        onChange={(value) => dispatch(value)}
                        disabled
                    />
                )} 
            />
            <DataColumn
                fieldName='CST_NM'
                editable={(state, dispatch) => (
                    <TextField
                        style={{width: 160}}
                        value={state}
                        onChange={value => dispatch(value)}
                        maxLength={15}
                    />
                )}
            />
            <DataColumn
                fieldName='CST_TYPE'
                value={(row, value) => cstType[value]}
                defaultValue={Object.keys(cstType)[0]}
                editable={(state, dispatch) => (
                    <ComboBox
                        data={cstType}
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />
            <DataColumn
                fieldName='CST_KIND'
                value={(row, value) => cstKind[value]}
                defaultValue={Object.keys(cstKind)[0]}
                editable={(state, dispatch, rowState) => (
                    <ComboBox
                        data={cstKind}
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />
            <DataColumn
                fieldName='CST_PAYCD'
                title='결제수단'
                value={row => row['CST_PAYNM']}
                editable={(state, dispatch, rowState) => (
                    <ComboBox
                        selectProc='SLT_PAYMENTINFO_ALL'
                        keyField='PAY_CD'
                        valueField='PAY_NM'
                        value={state}
                        onChange={value => dispatch(value)}
                        multiple
                    />
                )}
                
            />
            <DataColumn
                fieldName='WORK_BIZNO'
                editable={(state, dispatch, rowState) => (
                    <TextField
                        style={{ width: 80 }}
                        value={state}
                        onChange={value => dispatch(value)}
                        maxLength={10}
                    />
                )}
            />
            <DataColumn
                fieldName='CEO'
                editable={(state, dispatch, rowState) => (
                    <TextField
                        style={{ width: 80 }}
                        value={state}
                        onChange={value => dispatch(value)}
                        maxLength={10}
                    />
                )}
            />
            <DataColumn
                fieldName='BIZ_COND'
                editable={(state, dispatch, rowState) => (
                    <TextField
                        style={{ width: 200 }}
                        value={state}
                        onChange={value => dispatch(value)}
                        maxLength={50}
                    />
                )}
            />
            <DataColumn
                fieldName='BIZ_ITEM'
                editable={(state, dispatch, rowState) => (
                    <TextField
                        style={{ width: 200 }}
                        value={state}
                        onChange={value => dispatch(value)}
                        maxLength={50}
                    />
                )}
            />
            <DataColumn
                fieldName='EMAIL'
                editable={(state, dispatch, rowState) => (
                    <TextField
                        style={{ width: 200 }}
                        value={state}
                        onChange={value => dispatch(value)}
                        maxLength={50}
                    />
                )}
            />
            <DataColumn
                fieldName='EMAIL2'
                editable={(state, dispatch, rowState) => (
                    <TextField
                        style={{ width: 200 }}
                        value={state}
                        onChange={value => dispatch(value)}
                        maxLength={50}
                    />
                )}
            />
            <DataColumn
                fieldName='ZIP_CD'
                editable={state => state}
            />
            <DataColumn
                fieldName='ADDR'
                editable={(state, dispatch, rowState, setField) =>
                    <div className={classes.buttonField}>
                        <AddressPicker
                            open={rowState['ADDR_DIALOG'] || false}
                            onClose={() => setField('ADDR_DIALOG', false)}
                            onSelect={data => {
                                dispatch(data.roadAddr);
                                setField('ZIP_CD', data.zipNo);
                            }}
                        />
                        {state}
                        <IconButton
                            tooltip='수정'
                            icon={<EditIcon />}
                            onClick={() => setField('ADDR_DIALOG', true)}
                        />
                        {(state || '') !== '' &&
                        <IconButton
                            tooltip='삭제'
                            icon={<Close />}
                            onClick={() => dispatch(null)}
                        />}
                    </div>
                }
            />
            <DataColumn
                fieldName='ADDR_DET'
                editable={(state, dispatch, rowState) => (
                    <TextField
                        style={{ width: 200 }}
                        value={state}
                        onChange={value => dispatch(value)}
                        maxLength={50}
                    />
                )}
            />
            <DataColumn
                fieldName='TELNO1'
                editable={(state, setState) =>{
                    const telNo1 = state ? state.split('-')[0] : '';
                    const telNo2 = state ? state.split('-')[1] : '';
                    const telNo3 = state ? state.split('-')[2] : '';
                    return (
                        <div style={{ width: 180 }}>
                            <TextField
                                style={{width: 50}}
                                value={telNo1}
                                onChange={value => setState(`${value}-${telNo2}-${telNo3}`)}
                            />
                            <TextField
                                style={{width: 50, marginLeft: 8}}
                                value={telNo2}
                                onChange={value => setState(`${telNo1}-${value}-${telNo3}`)}
                            />
                            <TextField
                                style={{width: 50, marginLeft: 8}}
                                value={telNo3}
                                onChange={value => setState(`${telNo1}-${telNo2}-${value}`)}
                            />
                        </div>
                    )
                }}
            />
            <DataColumn
                fieldName='TELNO2'
                editable={(state, setState) =>{
                    const telNo1 = state ? state.split('-')[0] : '';
                    const telNo2 = state ? state.split('-')[1] : '';
                    const telNo3 = state ? state.split('-')[2] : '';
                    return (
                        <div style={{ width: 180 }}>
                            <TextField
                                style={{width: 50}}
                                value={telNo1}
                                onChange={value => setState(`${value}-${telNo2}-${telNo3}`)}
                            />
                            <TextField
                                style={{width: 50, marginLeft: 8}}
                                value={telNo2}
                                onChange={value => setState(`${telNo1}-${value}-${telNo3}`)}
                            />
                            <TextField
                                style={{width: 50, marginLeft: 8}}
                                value={telNo3}
                                onChange={value => setState(`${telNo1}-${telNo2}-${value}`)}
                            />
                        </div>
                    )
                }}
            />
            <DataColumn
                fieldName='IN_CST_CD'
                title='기준매입처명'
                defaultValue={null}
                value={row => row['IN_CST_NM']}
                editable={(state, dispatch, rowState) => (
                    <ComboBox
                        selectProc='SLT_INCUSTOMER_SEARCH'
                        keyField='IN_CST_CD'
                        valueField='IN_CST_NM'
                        value={state}
                        onChange={value => dispatch(value)}
                        nullable
                    />
                )}
                
            />
            <DataColumn
                fieldName='END_DATE'
                defaultValue={null}
                editable={(state, dispatch, rowState) => (
                    <DatePicker
                        value={state}
                        onChange={value => dispatch(value)}
                        nullable
                    />
                )}
            />
            <DataColumn
                fieldName='MAIN_CST'
                value={(row, value) => YNType[value]}
                defaultValue={Object.keys(YNType)[0]}
                editable={(state, dispatch, rowState) => (
                    <ComboBox
                        data={YNType}
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />
            <DataColumn
                fieldName='POSSUM'
                value={(row, value) => YNType[value]}
                defaultValue={Object.keys(YNType)[0]}
                editable={(state, dispatch, rowState) => (
                    <ComboBox
                        data={YNType}
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />
            <DataColumn
                fieldName='POS_LINK'
                editable={(state, dispatch) => (
                    <TextField
                        style={{ width: 50 }}
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />
            <DataColumn
                fieldName='ORD_LINK'
                editable={(state, dispatch) => (
                    <TextField
                        style={{ width: 100 }}
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />
            <DataColumn
                fieldName='MOVE_TRD'
                value={(row, value) => YNType[value]}
                defaultValue={Object.keys(YNType)[0]}
                editable={(state, dispatch, rowState) => (
                    <ComboBox
                        data={YNType}
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />
            <DataColumn
                fieldName='TRD_COND'
                editable={(state, dispatch) => (
                    <TextField
                        style={{ width: 50 }}
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />
            <DataColumn
                fieldName='TRD_ADDR'
                editable={(state, dispatch) => (
                    <TextField
                        style={{ width: 50 }}
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />
            <DataColumn
                fieldName='WORK_NM'
                visible={false}
            />
            <DataColumn
                fieldName='CST_PAYNM'
                visible={false}
            />
            <DataColumn
                fieldName='IN_CST_NM'
                visible={false}
            />
            <DataColumn
                fieldName='REGDATE'
                editable={(state) => state}
            />
              <DataColumn
                fieldName='REGUSER'
                editable={(state) => state}
            />
              <DataColumn
                fieldName='EDTDATE'
                editable={(state) => state}
            />
              <DataColumn
                fieldName='EDTUSER'
                editable={(state) => state}
            />
        </DataGrid>
    )
}

export default CustomerInfo