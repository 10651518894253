import { CLS_DATE, FETCH_CONFIG, LOGIN, LOGOUT, M_LOGIN, M_LOGOUT, REQ_LOGIN, REQ_M_LOGIN, SET_CONFIG } from "./constants"

export const login = (loginData) => ({
    type: LOGIN,
    loginData
})

export const reqLogin = (id, pw, onFailed) => ({
    type: REQ_LOGIN,
    id,
    pw,
    onFailed
})

export const logout = () => ({
    type: LOGOUT
})

export const mobileLogin = (loginData) => ({
    type: M_LOGIN,
    loginData
})

export const reqMobileLogin = (id, pw, onFailed) => ({
    type: REQ_M_LOGIN,
    id,
    pw,
    onFailed
})

export const mobileLogout = () => ({
    type: M_LOGOUT
})

export const clsDate = (clsData) => ({
    type: CLS_DATE,
    clsData
})

export const fetchConfig = () => ({
    type: FETCH_CONFIG
})

export const setConfig = (confData) => ({
    type: SET_CONFIG,
    confData
})