import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import {  makeStyles, Paper } from '@material-ui/core';
import {ComboBox, DatePicker, DataGrid, DataColumn, Tabs, Tab, ComboSearch, NumberField, TextField, IconButton, PaperTitle, ProgressDialog } from '../../component';
import { useSelector } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import { useFieldInputs } from '../../common/Utils';
import { callProc } from '../../common/DBConnector';
import { useSnackbar } from 'notistack';
import CheckIcon from '@material-ui/icons/Check';
import CachedIcon from '@material-ui/icons/Cached';
import CloseIcon from '@material-ui/icons/Close';

const enrollType = {
    0: '계기상품',
    1: '일반상품'
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    tabContainer: {
        display: 'flex',
    },
    tabbar: {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    content: {
        flex: 1,
        marginRight: theme.spacing(1),
    },
    tabContent: {
        display: 'flex', 
        flex: 1, 
        height: 0
    },
    input: {
        width: 300,
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(1),
    },
    hide: {
        display: 'none'
    },
    searchPaper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    searchContainer: {
        flex: 1,
        display: 'inline-block',
        flexDirection: 'row',
    },
    searchItem: {
        display: 'inline-block',
        width: 200,
        marginRight: 100,
        marginBottom: theme.spacing(1),
    },
}))

const TankStockAdjust = () =>{
    const clsState = useSelector(state => state.clsDateReducer);
    const [input, setInput] = useFieldInputs({
        WORK_CD: sessionStorage['WORK_CD'] === 'ALL' ? null : sessionStorage['WORK_CD'],
        BIZ_DATE: clsState[sessionStorage['WORK_CD']],
    });
    const [selectTab, setSelectTab] = useState(0);
    const [editRow, setEditRow] = useState();

    const classes = useStyles();

    
    const condition = useMemo(() => (input['WORK_CD'] && input['BIZ_DATE']) ? input : null, [input]);

    const gridRefresh = () => {
        setInput({ type: 'UPDATE', value: { ...input } });
    }

    const onUpdateStart = row => {
        setEditRow({...row });
        return false;
    }

    return (
        <div className={classes.container}>
            <Paper className={classes.searchPaper}>
                <div className={classes.searchContainer}>
                    <div className={classes.searchItem}>
                        <ComboBox
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={condition && condition['WORK_CD']}
                            onChange={value => {
                                if (input['WORK_CD'] !== value) {
                                    sessionStorage['WORK_CD'] = value;
                                    setInput('BIZ_DATE', clsState[value]);
                                    setInput('WORK_CD', value);
                                }
                            }}
                        />
                    </div>
                    <div className={classes.searchItem}>
                        <DatePicker
                            label='영업일'
                            minDate={clsState[input['WORK_CD']]}
                            value={condition && condition['BIZ_DATE']}
                            onChange={value => {
                                if (input['BIZ_DATE'] !== value) {
                                    setInput('BIZ_DATE', value);
                                    if(condition && condition['BIZ_DATE'] < clsState[condition['WORK_CD']]) {
                                        setInput({ type: 'UPDATE', value: { ...input, 'BIZ_DATE': value } });
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                <IconButton
                    tooltip=''
                    icon={<SearchIcon />}
                    onClick={gridRefresh}
                />
            </Paper>
            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab label='계기상품' />
                    <Tab label='일반상품' />
                </Tabs>
            </div>
            <div className={classes.tabContent}>
                <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                    <TankAdjust condition={condition} onUpdateStart={onUpdateStart} />
                </div>
                <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                    <ItemAdjust condition={condition} onUpdateStart={onUpdateStart} />
                </div>
                <div className={classes.input}>
                    <EnrollPanel
                        condition={condition}
                        editRow={editRow}
                        gridRefresh={gridRefresh}
                        clsState={clsState}
                        selectTab={selectTab}
                    />
                </div>
            </div>
        </div>
    )
}

const TankAdjust = ({ condition, onUpdateStart }) => {

    return (
        <DataGrid 
            title='계기상품 재고조정'
            selectProc='SLT_TANKADJUST'
            updateProc=''
            deleteProc='DLT_TANKADJUST'
            selectParam={condition}
            onUpdateStart={onUpdateStart}
            horizonMode
            virtualized
            selectionMode='single'
        >
            <DataColumn fieldName='WORK_NM' />
            <DataColumn fieldName='ADJ_DATE' />
            <DataColumn fieldName='GDS_NM' title='상품' />
            <DataColumn fieldName='TNK_NO' />
            <DataColumn fieldName='ADJ_QTY' />
            <DataColumn fieldName='REMARK' />
            <DataColumn fieldName='GDS_CD' visible={false} />
            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
    )
}

const ItemAdjust = ({ condition, onUpdateStart }) => {
    return (
        <DataGrid
            title='일반상품 재고조정'
            selectProc='SLT_ITEMADJUST'
            updateProc=''
            deleteProc='DLT_ITEMADJUST'
            selectParam={condition}
            onUpdateStart={onUpdateStart}
            horizonMode
            virtualized
            selectionMode='single'
        >
            <DataColumn fieldName='WORK_NM' />
            <DataColumn fieldName='ADJ_DATE' />
            <DataColumn fieldName='GDS_NM' />
            <DataColumn fieldName='ADJ_QTY' />
            <DataColumn fieldName='REMARK' />
            <DataColumn fieldName='WORK_CD' visible={false} />
            <DataColumn fieldName='GDS_CD' visible={false} />
        </DataGrid>
    )
}

const usePanelStyles = makeStyles((theme) => ({
    panelContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: 0,
    },
    panelContent: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        overflowY: 'auto',
        '& > *': {
            marginBottom: theme.spacing(2),
        },
    },
    btnContainer: {
        alignSelf: 'flex-end',
        padding: theme.spacing(1),
    },
}))

const EnrollPanel = ({ condition, editRow, gridRefresh, clsState, selectTab }) => {
    const classes = usePanelStyles();
    const [editState, setEditState] = useState(0);
    const [befValue, setBefValue] = useState();
    const { enqueueSnackbar } = useSnackbar();

    const [insData, setInsData] = useFieldInputs({
        WORK_CD: condition && condition['WORK_CD'],
        ADJ_DATE: condition && condition['BIZ_DATE'],
        GDS_CD: null,
        TNK_NO: null,
        ADJ_QTY: 0,
        REMARK: '',
    });

    useEffect(() => {
        setInsData({ type: 'INIT' });
        setEditState(0);
    }, [selectTab, setInsData])
    
    useEffect(() => {
        const fetchData = () => {
            setInsData('WORK_CD', condition['WORK_CD']);
            setInsData('ADJ_DATE', condition['BIZ_DATE']);
        }

        condition && fetchData();
    }, [condition, setInsData])

    const isValid = () => {
        if(insData['GDS_CD'] === null) {
            enqueueSnackbar('상품을 선택해 주세요.', { variant: 'error' });
            return false;
        }

        if(selectTab === 0 && insData['TNK_NO'] === null){
            enqueueSnackbar('탱크를 선택해 주세요.', { variant: 'error' });
            return false;
        }

        if(String(insData['ADJ_QTY']) === '0') {
            enqueueSnackbar('수량을 입력해 주세요.', { variant: 'error' });
            return false;
        }

        if(insData['REMARK'] === '') {
            enqueueSnackbar('비고를 입력해 주세요.', { variant: 'error' });
            return false;
        }

        return true;
    }

    const [loading, setLoading] = useState(false);

    const onInsert = async() => {
        setLoading(true);

        if(isValid()){
            let befData = {...insData};
            delete befData.SEQ;
            delete befData.WORK_CD;
            delete befData.ADJ_DATE;
            setBefValue(befData);
            let procName;
            if(String(editState) === '0'){
                procName = selectTab === 0 ? 'INS_TANKADJUST_ENROLL' : 'INS_ITEMADJUST';
            } else {
                procName = selectTab === 0 ? 'UPT_TANKADJUST_ENROLL' : 'UPT_ITEMADJUST';
            }
            const result = await callProc(procName, insData);
            if(!result.err) {
                enqueueSnackbar('저장이 완료되었습니다.');
                setInsData({ type: 'INIT' });
                setEditState(0);
                gridRefresh();
            }
        }

        setLoading(false);
    }

    useEffect(() => {
        const fetchData = () => {
            setInsData({ type: 'UPDATE', value: editRow })
            setEditState(1);
        }
        editRow && fetchData();
    }, [editRow, setInsData, setEditState])

    const onExitEdit = () => {
        setInsData({ type: 'INIT' });
        setEditState(0);
    }

    const getBefValue = () => {
        befValue && setInsData({ type: 'UPDATE', value: { ...befValue }})
    }

    const gdsCd = insData && insData['GDS_CD'];

    const gdsParam = useMemo(() => ({
        WORK_CD: condition && condition['WORK_CD'],
        GDS_TYPE: 'ALL',
        NOT_TYPE: 0,
        GDS_TYPE_M: 'ALL',
        NOT_TYPE_M: 0,
        GAUGE_TYPE: selectTab === 0 ? '0' : '1',
        STA_DATE: condition && condition['BIZ_DATE'],
    }), [condition, selectTab])

    const tnkParam = useMemo(() => (
        gdsCd == null ? undefined : {
        WORK_CD: condition && condition['WORK_CD'],
        BIZ_DATE: condition && condition['BIZ_DATE'],
        GDS_CD: gdsCd
    }), [condition, gdsCd])

    return (
        <Paper className={classes.panelContainer} >
            <ProgressDialog open={loading} />

            <PaperTitle>{`${enrollType[selectTab]} ${String(editState) === '0' ? '추가' : '수정'}`}</PaperTitle>
            <div className={classes.panelContent}>
                {String(editState) === '1' &&
                <TextField
                    label='순번'
                    value={insData['SEQ']}
                    disabled
                />}
                <ComboBox
                    label='사업장'
                    selectProc='SLT_WORKPLACEINFO_AUTH'
                    keyField='WORK_CD'
                    valueField='WORK_NM'
                    value={insData['WORK_CD']}
                    disabled
                />
                <DatePicker
                    label='조정일'
                    minDate={clsState[insData['WORK_CD']]}
                    value={insData['ADJ_DATE']}
                    disabled
                />
                <ComboSearch
                    label='상품'
                    selectProc='SLT_GAUGE_ITEM_GOODS_COMBO'
                    selectParam={gdsParam}
                    keyField='GDS_CD'
                    valueField='GDS_NM'
                    value={insData['GDS_CD']}
                    onChange={value => setInsData('GDS_CD', value)}
                    nullable
                />
                {selectTab === 0 &&
                <ComboSearch
                    label='탱크번호'
                    selectProc='SLT_TANKINFO_NOW_GOODS'
                    selectParam={tnkParam}
                    keyField='TNK_NO'
                    valueField='TNK_NM'
                    value={insData['TNK_NO']}
                    onChange={value => setInsData('TNK_NO', value)}
                    nullable
                />}
                <NumberField
                    label='조정수량'
                    value={insData['ADJ_QTY']}
                    onChange={value => setInsData('ADJ_QTY', value)}
                />
                <TextField
                    label='비고'
                    value={insData['REMARK']}
                    onChange={value => setInsData('REMARK', value)}
                />
            </div>
            <div className={classes.btnContainer}>
                {String(editState) === '1' &&
                <IconButton
                    tooltip='취소'
                    icon={<CloseIcon />}
                    onClick={() => onExitEdit()}
                />}
                <IconButton
                    tooltip='이전값 불러오기'
                    icon={<CachedIcon />}
                    onClick={() => getBefValue()}
                />
                <IconButton
                    tooltip='확인'
                    icon={<CheckIcon />}
                    onClick={() => onInsert()}
                />
            </div>
        </Paper>
    )
}

export default TankStockAdjust