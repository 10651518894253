import React, { useEffect, useState } from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles((theme) => ({
    numberField: {
        textAlign: 'right',
    },
}));

const NumberField = (props) => {
    const { onChange, value, oneWay = false, min = -99999999999, max = 99999999999, ...fieldProps } = props;
    const [onewayValue, setOnewayValue] = useState('');
    useEffect(() => {
        oneWay && setOnewayValue(value);
    }, [value, oneWay])

    const classes = useStyles();

    const handleChange = (e) => {
        let newValue = e.target.value || '';
        if (newValue < min) {
            newValue = min;
        } else if (newValue > max) {
            newValue = max;
        }
        onChange && onChange(newValue);
        if (oneWay) {
            setOnewayValue(newValue);
        }
    }

    return (
        <TextField
            onChange={handleChange}
            value={oneWay ? onewayValue : (value == null ? '' : value)}
            inputProps={{ className: classes.numberField }}
            InputProps={{
                inputComponent: NumberFormatCustom,
            }}
            {...fieldProps}
        />
    )
}

const NumberFormatCustom = ({ inputRef, onChange, ...props }) => {
    return (
        <NumberFormat
            {...props}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
        />
    );
}

export default NumberField;