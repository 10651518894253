import React, { useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { DataGrid, ComboBox, ProgressPanel, DatePicker, DataColumn, NumberField, PaperTitle, ComboSearch, Tabs, Tab, IconButton, ProgressDialog, TextField, RadioGroup, RadioItem } from '../../component';
import { makeStyles, Paper } from '@material-ui/core';
import { callProc, sendKakao } from '../../common/DBConnector';
import { orderType } from '../../common/Dictionary';
import { useFieldInputs } from '../../common/Utils';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { Add, Edit, LocalShipping } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';

const tansCfCombo = [
    { key: 1, value: '배차완료' },
    { key: 2, value: '배차미완료' },
]

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
    info: {
        flex: 1,
        marginRight: theme.spacing(1),
    },
    input: {
        width: 300,
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(1),
    },
    tab: {
        minWidth: 'unset',
        flex: 1,
    },
    hide : {
        display: 'none !important'
    },
    searchPaper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    searchContainer: {
        flex: 1,
        display: 'inline-block',
        flexDirection: 'row',
    },
    searchItem: {
        display: 'inline-block',
        width: 200,
        marginRight: 100,
        marginBottom: theme.spacing(1),
    },
}))

const OrderEnroll = () => {
    const clsState = useSelector(state => state.clsDateReducer);
    const [input, setInput] = useFieldInputs({
        WORK_CD: sessionStorage['WORK_CD'],
        BIZ_DATE: clsState[sessionStorage['WORK_CD']],
        TRN_CONF: 2,
    });
    const [selectTab, setSelectTab] = useState(0);
    const [selectRow, setSelectRow] = useState([]);
    const [editRow, setEditRow] = useState();

    const [workData, setWorkData] = useState(); //검색패널(사업장))//
    const [tspData, setTspData] = useState();

    const { enqueueSnackbar } = useSnackbar();

    const classes = useStyles();

    useEffect(() => {
        const fetchData = async () => {
            const workData = await callProc('SLT_WORKPLACEINFO_AUTH');
            const tspData = await callProc('SLT_TRANSPLACE');

            setWorkData(workData.data.reduce((result, item) => ({
                ...result,
                [item['WORK_CD']]: item['WORK_NM']
            }), {}));

            setTspData(tspData.data.reduce((result, item) => ({
                ...result,
                [item['TSP_TYPE']]: {
                    ...result[item['TSP_TYPE']],
                    [item['WORK_CD']]: {
                        ...result[item['TSP_TYPE']][item['WORK_CD']],
                        item
                    }
                }
            }), {}));
        }
        fetchData();
    }, [])

    const onRowSelect = row => setSelectRow(row)

    const onDelete = async(row) => {
        const valid_result = await callProc('SLT_ORDERINOF_DLT_VALID', row);
        if (valid_result !== undefined) {
            if(valid_result.data[0]['RESULT'] === 0) {
                const result = await callProc('DLT_ORDERINFO', row);
                if (result.err) {
                    return false;
                }
                setEditRow(null);
            }else{
                enqueueSnackbar('삭제 할 수 없습니다.', { variant: 'error' });
                return false;            
            }
        } else {
            return false;
        }
    }

    const onUpdateStart = row => {
        setEditRow(row);
        setSelectTab(0);
        return false;
    }

    const condition = useMemo(() => input['WORK_CD'] && input['BIZ_DATE'] && input['TRN_CONF'] && input, [input]);

    const gridRefresh = () => {
        setInput({ type: 'UPDATE', value: { ...input } });
    }

    return(
        (!workData || !tspData) ? <ProgressPanel/> :
        <div className={classes.container}>
            <Paper className={classes.searchPaper}>
                <div className={classes.searchContainer}>
                    <div className={classes.searchItem}>
                        <ComboBox
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={condition && condition['WORK_CD']}
                            onChange={value => {
                                if (input['WORK_CD'] !== value) {
                                    sessionStorage['WORK_CD'] = value;
                                    setInput('BIZ_DATE', clsState[value]);
                                    setInput('WORK_CD', value);
                                }
                            }}
                        />
                    </div>
                    <div className={classes.searchItem}>
                        <DatePicker
                            label='영업일'
                            minDate={clsState[input['WORK_CD']]}
                            value={condition && condition['BIZ_DATE']}
                            onChange={value => {
                                if (input['BIZ_DATE'] !== value) {
                                    setInput('BIZ_DATE', value);
                                    if(condition && condition['BIZ_DATE'] < clsState[condition['WORK_CD']]) {
                                        setInput({ type: 'UPDATE', value: { ...input, 'BIZ_DATE': value } });
                                    }
                                }
                            }}
                        />
                    </div>
                    <div className={classes.searchItem}>
                        
                        <ComboBox
                            label='배차상태'
                            data={tansCfCombo}
                            keyField='key'
                            valueField='value'
                            value={condition && condition['TRN_CONF']}
                            onDataLoad={(jsonData, rawData) => [{ key: 'ALL', value: '전체' }, ...rawData]}
                            onChange={value => {
                                if (input['TRN_CONF'] !== value) {
                                    setInput('TRN_CONF', value);
                                }
                            }}
                        />
                    
                    </div>
                </div>
                <IconButton
                    tooltip=''
                    icon={<SearchIcon />}
                    onClick={gridRefresh}
                />
            </Paper>

            <div className={classes.content}>
                <div className={classes.info}>
                    <DataGrid
                        title='오더 등록'
                        table='TB_ORDERINFO'
                        selectProc='SLT_ORDERINFO'
                        updateProc='UPT_ORDERINFO'
                        selectParam={condition}
                        onSelect={onRowSelect}
                        onDelete={onDelete}
                        onUpdateStart={onUpdateStart}
                        selectionMode='multi'
                        selectable={row => {
                            let selectGdsCd = selectRow && selectRow.length !== 0 ? selectRow[0]['GDS_CD'] : [];

                            if (!selectRow || selectRow.length === 0) {
                                return true
                            } else if (selectGdsCd === 'GDS0001' || selectGdsCd === 'GDS0002'){
                                return (row['GDS_CD'] === 'GDS0001' || row['GDS_CD'] === 'GDS0002')
                            } else if (!(selectGdsCd === 'GDS0001' || selectGdsCd === 'GDS0002')){
                                return !(row['GDS_CD'] === 'GDS0001' || row['GDS_CD'] === 'GDS0002')
                            }
                        }} 
                        horizonMode
                    >
                        <DataColumn
                            fieldName='ORD_TYPE'
                            value={(row, value) => orderType[value]}
                        />
                        <DataColumn
                            fieldName='BIZ_DATE'
                            title='오더일자'
                        />
                        <DataColumn
                            fieldName='REAL_DEST_CD'
                            value={row => row['REAL_DEST_NM']}
                        />
                        <DataColumn
                            fieldName='BUY_CST_CD'
                            value={row => row['BUY_CST_NM']}
                        />
                        <DataColumn
                            fieldName='GDS_CD'
                            title='상품명'
                            value={row => row['GDS_NM']}
                        />
                        <DataColumn
                            fieldName='SHIP_TRN_CD'
                            value={row => row['SHIP_TRN_NM']}
                        />
                        <DataColumn
                            fieldName='DEST_TRN_CD'
                            value={row => row['DEST_TRN_NM']}
                        />
                        <DataColumn
                            fieldName='WORK_CD'
                            title='사업장명'
                            value={row => row['WORK_NM']}
                        />
                        <DataColumn fieldName='CAR_NO' editable={state => state} />
                        <DataColumn fieldName='WORK_NM' visible={false} />
                        <DataColumn fieldName='GDS_NM' visible={false} />
                        <DataColumn fieldName='BUY_CST_NM' visible={false} />
                        <DataColumn fieldName='SHIP_TRN_NM' visible={false} />
                        <DataColumn fieldName='DEST_TRN_NM' visible={false} />
                        <DataColumn fieldName='REAL_DEST_NM' visible={false} />
                    </DataGrid>
                </div>
                <div className={classes.input}>
                    <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)}>
                        <Tab className={classes.tab} label={editRow ? '수정' : '추가'} icon={editRow ? <Edit /> : <Add />} />
                        <Tab className={classes.tab} label='배차' icon={<LocalShipping />} />
                    </Tabs>
                    <EnrollPanel
                        className={clsx({ [classes.hide]: selectTab !== 0 })}
                        condition={condition}
                        gridRefresh={gridRefresh}
                        editRow={editRow}
                        onExitEdit={() => setEditRow(null)}
                    />
                    <TransportPanel
                        className={clsx({ [classes.hide]: selectTab !== 1 })}
                        gridRefresh={gridRefresh}
                        selectRow={selectRow}
                        workData={workData}
                        condition={condition}
                    />
                </div>
            </div>
        </div>
    )
}

const usePanelStyle = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: 0,
    },
    content: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        overflowY: 'auto',
        '& > *': {
            marginBottom: theme.spacing(2),
        },
    },
    btnContainer: {
        alignSelf: 'flex-end',
        padding: theme.spacing(1),
    },
    searchField: {
        marginLeft: theme.spacing(2),
    },
    field: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(3),
    },
}))

const EnrollPanel = ({ className, condition, gridRefresh, editRow, onExitEdit }) => {
    const [insData, setInsData] = useFieldInputs({
        WORK_CD: condition && condition['WORK_CD'],
        BIZ_DATE: condition && condition['BIZ_DATE'],
        SHIP_REQ_DATE: condition && condition['BIZ_DATE'],
        ORD_TYPE: '0',
        SHIP_TRN_CD: null,
        DEST_TRN_CD: null,
        TNK_NO: null,
        QTY: 0,
    });

    useEffect(() => {
        setInsData('WORK_CD', condition && condition['WORK_CD']);
        setInsData('BIZ_DATE', condition && condition['BIZ_DATE']);
        setInsData('SHIP_REQ_DATE', condition && condition['BIZ_DATE']);
    }, [setInsData, condition])

    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const classes = usePanelStyle();

    const workCd = insData['WORK_CD'];
    const gdsCd = insData['GDS_CD'];
    const shipReqDate = insData['SHIP_REQ_DATE'];
    const ordType = insData['ORD_TYPE'];
    let shipTspType = 0;

    const shipTrnParam = useMemo(() => (
        workCd === undefined || shipTspType === undefined ? null : {
            WORK_CD: workCd,
            TSP_TYPE: shipTspType
        }
    ), [workCd, shipTspType]);

    const tnkParam = useMemo(() => (
        workCd === undefined || gdsCd === undefined || ordType === undefined ? null : {
            WORK_CD: workCd,
            GDS_CD: gdsCd,
            ORD_TYPE: ordType,
            STA_DATE: shipReqDate,
        }
    ), [workCd, gdsCd, ordType, shipReqDate]);
    
    const workCdParam = useMemo(() => (
        workCd === undefined ? null : {
            WORK_CD: workCd,
            TSP_TYPE: 1
        }
    ), [workCd]);

    useEffect(() => {
        editRow ? setInsData({ type: 'INIT', value: editRow }) : setInsData('ORD_CD', null);
    }, [editRow, setInsData])

    const valid = () => {
        if (!insData['WORK_CD']) {
            enqueueSnackbar('사업장을 입력하여 주세요.', { variant: 'error' });
            return false;
        }
        if (!insData['GDS_CD']) {
            enqueueSnackbar('상품을 입력하여 주세요.', { variant: 'error' });
            return false;
        }
        if (String(insData['ORD_TYPE']) === '0' || String(insData['ORD_TYPE']) === '1') {
            if (!insData['BUY_CST_CD']) {
                enqueueSnackbar('매입처를 입력하여 주세요.', { variant: 'error' });
                return false;
            }
        } else {
            if (!insData['SHIP_TRN_CD']) {
                enqueueSnackbar('출하처를 입력하여 주세요.', { variant: 'error' });
                return false;
            }
        }
        if (!insData['REAL_DEST_CD']) {
            enqueueSnackbar('도착지를 입력하여 주세요.', { variant: 'error' });
            return false;
        }
        if (insData['QTY'] === '') {
            enqueueSnackbar('수량을 입력하여 주세요.', { variant: 'error' });
            return false;
        }
        return true;
    }

    const onInsert = async() => {
        setLoading(true);

        if (valid()) {
            let procName;
            if (insData['ORD_CD']) {
                procName = 'UPT_ORDERINFO';
            } else {
                procName = 'INS_ORDERINFO';
            }
            const result = await callProc(procName, insData);
            if (!result.err) {
                onExitEdit();
                enqueueSnackbar('저장이 완료되었습니다.');
                gridRefresh();
            }
        }

        setLoading(false);
    }

    return (
        <Paper className={clsx(classes.container, className)}>
            <ProgressDialog open={loading} />

            <PaperTitle>{insData['ORD_CD'] ? '수정' : '추가'}</PaperTitle>
            <div className={classes.content}>
                {insData['ORD_CD'] &&
                <TextField
                    label='오더코드'
                    value={insData['ORD_CD']}
                    disabled
                />}
                <ComboBox
                    label='오더타입'
                    data={orderType}
                    value={insData['ORD_TYPE']}
                    onChange={value => {
                        if (String(value) === '0' || String(value) === '1') {
                            setInsData('SHIP_TRN_CD', null);
                        } else {
                            setInsData('BUY_CST_CD', null);
                        }
                        setInsData('DEST_TRN_CD', null);
                        setInsData('ORD_TYPE', value);
                    }}
                />
                <ComboBox
                    label='사업장명'
                    selectProc='SLT_WORKPLACEINFO_AUTH'
                    keyField='WORK_CD'
                    valueField='WORK_NM'
                    value={insData['WORK_CD']}
                    onChange={value => {
                        setInsData('BUY_CST_CD', null);
                        setInsData('SHIP_TRN_CD', null);
                        setInsData('DEST_TRN_CD', null);
                        setInsData('REAL_DEST_CD', null);
                        setInsData('WORK_CD', value);
                    }}
                />
                <ComboBox
                    label='상품명'
                    selectProc='SLT_GOODSINFO_OIL'
                    selectParam={workCdParam}
                    keyField='GDS_CD'
                    valueField='GDS_NM'
                    value={insData['GDS_CD']}
                    onChange={value => setInsData('GDS_CD', value)}
                />
                <DatePicker
                    label='오더일자'
                    value={insData['BIZ_DATE']}
                    onChange={value => setInsData('BIZ_DATE', value)}
                    disabled={insData['ORD_CD']}
                />
                <DatePicker
                    label='출하요청일'
                    value={insData['SHIP_REQ_DATE']}
                    onChange={(value) => setInsData('SHIP_REQ_DATE', value)}
                />
                {(String(insData['ORD_TYPE']) === '0' ||
                String(insData['ORD_TYPE']) === '1') &&
                <ComboSearch
                    label='매입처'
                    selectProc='SLT_CUSTOMERINFO_BUY_NOTEND'
                    selectParam={workCdParam}
                    keyField='CST_CD'
                    valueField='CST_NM'
                    value={insData['BUY_CST_CD']}
                    onChange={value => setInsData('BUY_CST_CD', value)}
                />}
                {String(insData['ORD_TYPE']) !== '0' &&
                <ComboBox
                    label='탱크번호'
                    selectProc='SLT_TANK_COMBO'
                    selectParam={tnkParam}
                    keyField='TNK_NO'
                    valueField='TNK_NM'
                    value={insData['TNK_NO']}
                    onChange={value => setInsData('TNK_NO', value)}
                />}
                <ComboSearch
                    label='출하처'
                    selectProc='SLT_TRANSPLACE'
                    selectParam={shipTrnParam}
                    keyField='TSP_CD'
                    valueField='TSP_NM'
                    value={insData['SHIP_TRN_CD']}
                    onChange={value => setInsData('SHIP_TRN_CD', value)}
                    nullable={insData['ORD_TYPE'] === '0' || insData['ORD_TYPE'] === '1'}
                />
                {(String(insData['ORD_TYPE']) === '0' ||
                String(insData['ORD_TYPE']) === '1') &&
                <ComboSearch
                    label='인도처'
                    selectProc='SLT_TRANSPLACE'
                    selectParam={workCdParam}
                    keyField='TSP_CD'
                    valueField='TSP_NM'
                    value={insData['DEST_TRN_CD']}
                    onChange={value => setInsData('DEST_TRN_CD', value)}
                    nullable
                />}
                <ComboSearch
                    label='도착지'
                    selectProc='SLT_TRANSPLACE'
                    selectParam={workCdParam}
                    keyField='TSP_CD'
                    valueField='TSP_NM'
                    value={insData['REAL_DEST_CD']}
                    onChange={value => setInsData('REAL_DEST_CD', value)}
                />
                <NumberField
                    label='수량'
                    value={insData['QTY']}
                    onChange={value => setInsData('QTY', value)}
                    disabled={insData['ORD_CD'] != null}
                />
                <NumberField
                    label='추정정산단가'
                    value={insData['EST_PRICE'] ? insData['EST_PRICE'] : 0}
                    onChange={value => setInsData('EST_PRICE', value)}
                />
                <TextField
                    label='출하매입전표'
                    value={insData['TRN_INNO']}
                    onChange={value => setInsData('TRN_INNO', value)}
                    maxLength={10}
                />
            </div>
            <div className={classes.btnContainer}>
                {insData['ORD_CD'] && 
                <IconButton
                    tooltip='취소'
                    icon={<CloseIcon />}
                    onClick={onExitEdit}
                />}
                <IconButton
                    tooltip='확인'
                    icon={<CheckIcon />}
                    onClick={onInsert}
                />
            </div>
        </Paper>
    )
}

const TransportPanel = ({ className, gridRefresh, selectRow, workData, condition }) => {
    const [insData, setInsData] = useFieldInputs({
        WORK_CD: condition && condition['WORK_CD'],
        ISSUE_TYPE: '0',
    });
    const [loading, setLoading] = useState(false);
    const [trnCarData, setTrnCarData] = useState();


    const conf = useSelector(state => state.configReducer);
    const { enqueueSnackbar } = useSnackbar();
    const classes = usePanelStyle();

    useEffect(() => {
        (selectRow == null || !selectRow.length) && setInsData('ORD_CONT', '');
    }, [selectRow, setInsData])

    useEffect(() => {
        setInsData('WORK_CD', condition && condition['WORK_CD'])
    }, [condition, setInsData])



    const isEnrollable = () => selectRow != null && selectRow.length;

    const isOverQty = () => {
        let stcQty = trnCarData && trnCarData[insData['TSP_CAR_CD']]['STC_QTY']
        let result = true;
        let sumQty = 0;
        if(insData['ISSUE_TYPE'] === '1'){
            sumQty = selectRow && selectRow.reduce((result, item) => result + item['QTY'], 0);
            result = stcQty < sumQty;
        } else {
            result = selectRow && selectRow.reduce((result, item) => {
                if(result === false) {
                    return stcQty < item['QTY']
                }

                return true;
            }, false);
        }

        return result;
    };

    const valid = () => {
        if (!insData['TSP_CAR_CD']) {
            enqueueSnackbar('차량을 선택하여주세요.', { variant: 'error' });
            return false;
        }
        if (insData['ORD_CONT'].trim() === '') {
            enqueueSnackbar('지시사항을 입력하여주세요.', { variant: 'error' });
            return false;
        }

        if(isOverQty()){
            enqueueSnackbar('배차 적재용량을 초과 하였습니다.', { variant: 'error' });
            return false;
        }
        return true;
    }

    const onTrans = async(trnCd) => {
        setLoading(true);

        if (trnCd || valid()) {
            const param = trnCd ?
                { ARR_ORD_CD: selectRow.map(item => item['ORD_CD']), TRN_CD: trnCd } :
                { ARR_ORD_CD: selectRow.map(item => item['ORD_CD']), ...insData };
            const result = await callProc('INS_TRANSPORTINFO', param);
            if (!result.err) {
                const carInfo = (await callProc('SLT_TRANSCARINFO', insData)).data[0];
                if ((carInfo['TELNO1'] || '') !== '') {
                    await sendKakao(
                        carInfo['TELNO1'],
                        conf['SOLAPI_TRN_TID'],
                        conf,
                        `차량번호 ${carInfo['CAR_NO']}에 대한 배차가 완료되었습니다.`,
                        [{
                            "buttonName": "배차 조회",
                            "buttonType": "WL",
                            "linkMo": window.location.origin + '/m',
                            "linkPc": window.location.origin + '/m',
                        }]
                    );
                }
                enqueueSnackbar('저장이 완료되었습니다.');
                gridRefresh();
            }
        }

        setLoading(false);
    }

    const gdsCd = (selectRow && selectRow.length !== 0) ? selectRow[0]['GDS_CD'] : ''
    const insWorkCd = insData['WORK_CD'];
    const carParam = useMemo(() => insWorkCd ? { WORK_CD: insWorkCd || '', GDS_CD: gdsCd} : null, [gdsCd, insWorkCd]);

    useEffect(() => {
        const fetchData = async () => {
            const trnCarData = await callProc('SLT_TRANSCARINFO', { WORK_CD: insWorkCd });

            setTrnCarData(trnCarData.data.reduce((result, item) => ({
                ...result,
                [item['TSP_CAR_CD']]: item
            }), {}));
        }

        insWorkCd && fetchData();
    }, [insWorkCd])



    return (
        <Paper className={clsx(classes.container, className)}>
            <ProgressDialog open={loading} />
            <PaperTitle>배차</PaperTitle>
            <div className={classes.content}>
                <DatePicker
                    label='영업일'
                    value={insData['BIZ_DATE'] || moment().format('YYYY-MM-DD')}
                    onChange={value => setInsData('BIZ_DATE', value)}
                    disabled={!isEnrollable()}
                />
                <ComboBox
                    label='사업장명'
                    data={workData}
                    value={insData['WORK_CD']}
                    onChange={value => setInsData('WORK_CD', value)}
                    disabled
                />
                <ComboBox
                    label='차량번호'
                    selectProc='SLT_TRANSCARINFO_OILCLASS'
                    selectParam={carParam}
                    keyField='TSP_CAR_CD'
                    valueField='CAR_NO'
                    value={insData['TSP_CAR_CD']}
                    onChange={(value, label) => {
                        setInsData('TSP_CAR_CD', value);
                        setInsData('CAR_NO', label);
                    }}
                    disabled={!isEnrollable()}
                />

                <TextField
                    label='지시사항'
                    value={insData['ORD_CONT'] || ''}
                    onChange={value => setInsData('ORD_CONT', value)}
                    disabled={!isEnrollable()}
                    maxLength={50}
                />
                <RadioGroup
                    label='배차방식'
                    className={classes.field}
                    value={insData['ISSUE_TYPE']}
                    defaultValue='0'
                    onChange={value => setInsData('ISSUE_TYPE', value)}
                >
                    <RadioItem value='0' label='각개 배차' />
                    <RadioItem value='1' label='합산 배차' />
                </RadioGroup>
            </div>
            <div className={classes.btnContainer}>
                <IconButton
                    tooltip='확인'
                    icon={<CheckIcon />}
                    onClick={() => onTrans()}
                    disabled={!isEnrollable()}
                />
            </div>
        </Paper>
    )
}

export default OrderEnroll;