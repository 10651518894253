import React, { useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { DataGrid, ComboBox, DatePicker, Tabs, Tab, DataColumn, NumberField, ComboSearch, FilePicker, IconButton, ProgressDialog, PaperTitle, TextField, TimePicker } from '../../component';
import { Dialog, DialogTitle, makeStyles, Paper } from '@material-ui/core';
import { callProc } from '../../common/DBConnector';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { regType } from '../../common/Dictionary';
import { useFieldInputs, xlsToJsonAsync } from '../../common/Utils';
import PublishIcon from '@material-ui/icons/Publish';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import IsoIcon from '@material-ui/icons/Iso';
import CheckIcon from '@material-ui/icons/Check';
import CachedIcon from '@material-ui/icons/Cached';

const cshRecept = {
    '0': '미발행',
    '1': '발행'
}

const calcType = {
    '0': '수량',
    '1': '금액',
}

const truckType = {
    '0': '일반',
    '1': '영업화물 할인'
}

const tabPayCd = {
    0: {PAY_CD: 'P0001', PAY_NM: '현금'},
    1: {PAY_CD: 'P0003', PAY_NM: '외상'},
    2: {PAY_CD: 'P0002', PAY_NM: '신용카드'},
    3: {PAY_CD: 'P0008', PAY_NM: '상품권'},
    4: {PAY_CD: 'P0007', PAY_NM: '포인트'},
    5: {PAY_CD: 'P0004', PAY_NM: '모바일쿠폰'},
    6: {PAY_CD: 'P0006', PAY_NM: '현장할인'},
    7: {PAY_CD: 'P0005', PAY_NM: '조달청할인'},
    8: {PAY_CD: 'P0009', PAY_NM: '간편결제'}
}

const useStyle = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    tabContainer: {
        display: 'flex',
    },
    subTabContainer: {
        backgroundColor: theme.palette.secondary.main,
        boxShadow: theme.shadows[4],
        marginBottom: 8,
    },
    tabbar: {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    tab: {
        minWidth: 100,
    },
    tabContent: {
        display: 'flex', 
        flex: 1, 
        height: 0
    },
    content: {
        flex: 1,
        marginRight: theme.spacing(1),
    },
    input: {
        width: 300,
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(1),
    },
    hide: {
        display: 'none'
    },
    searchPaper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    searchContainer: {
        flex: 1,
        display: 'inline-block',
        flexDirection: 'row',
    },
    searchItem: {
        display: 'inline-block',
        width: 200,
        marginRight: 100,
        marginBottom: theme.spacing(1),
    },
}))

const SaleEnroll = () => {
    const classes = useStyle();
    const clsState = useSelector(state => state.clsDateReducer);
    const [input, setInput] = useFieldInputs({
        WORK_CD: sessionStorage['WORK_CD'] === 'ALL' ? null : sessionStorage['WORK_CD'],
        BIZ_DATE: clsState[sessionStorage['WORK_CD']],
    });
    const [selectTab, setSelectTab] = useState(0);
    const [excelOpen, setExcelOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editRow, setEditRow] = useState();
    const { enqueueSnackbar } = useSnackbar();

    const condition = useMemo(() => (input['WORK_CD'] && input['BIZ_DATE']) ? input : null, [input]);

    const gridRefresh = () => {
        setInput({ type: 'UPDATE', value: { ...input } });
    }

    const valid = (row) => {
        if (row['REG_TYPE'].toString() === '1') {
            enqueueSnackbar('POS데이터는 삭제가 불가합니다.', { variant: 'error' });
            return false;
        }
        return true;
    }

    const onDelete = async (row) => {
        if (valid(row)) {
            const result = await callProc('DLT_SALE_ENROLL', row);

            if (!result.err) {
                setInput({ ...input });
            }
        } else {
            return false;
        }
    }

    const progressOpen = (value) => setLoading(value);
    const onUpdateStart = row => {
        if(String(row['REG_TYPE']) === '1') {
            enqueueSnackbar('POS데이터는 수정이 불가합니다.', { variant: 'error' });
            return false;
        }

        setEditRow({...row, SALE_TIME: row['SALE_TIME'] === null && '00:00:00' });
        return false;
    }
    
    const onTabChange = (e, v) => {
        setEditRow();
        setSelectTab(v);
    }

    return (
        <div className={classes.container}>
            <ProgressDialog open={loading} />
            <Paper className={classes.searchPaper}>
                <div className={classes.searchContainer}>
                    <div className={classes.searchItem}>
                        <ComboBox
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={condition && condition['WORK_CD']}
                            onChange={value => {
                                if (input['WORK_CD'] !== value) {
                                    sessionStorage['WORK_CD'] = value;
                                    setInput('BIZ_DATE', clsState[value]);
                                    setInput('WORK_CD', value);
                                }
                            }}
                        />
                    </div>
                    <div className={classes.searchItem}>
                        <DatePicker
                            label='영업일'
                            minDate={clsState[input['WORK_CD']]}
                            value={condition && condition['BIZ_DATE']}
                            onChange={value => {
                                if (input['BIZ_DATE'] !== value) {
                                    setInput('BIZ_DATE', value);
                                    if (condition && condition['BIZ_DATE'] < clsState[condition['WORK_CD']]) {
                                        setInput({ type: 'UPDATE', value: { ...input, 'BIZ_DATE': value } });
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                <IconButton
                    tooltip=''
                    icon={<SearchIcon />}
                    onClick={gridRefresh}
                />
            </Paper>
            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={onTabChange} appbarProps={{ className: classes.tabbar }}>
                    <Tab className={classes.tab} label='현금' />
                    <Tab className={classes.tab} label='외상' />
                    <Tab className={classes.tab} label='신용카드' />
                    <Tab className={classes.tab} label='상품권' />
                    <Tab className={classes.tab} label='포인트' />
                    <Tab className={classes.tab} label='쿠폰' />
                    <Tab className={classes.tab} label='현장할인' />
                    <Tab className={classes.tab} label='조달청할인' />
                    <Tab className={classes.tab} label='간편결제' />
                </Tabs>
                <div className={classes.subTabContainer}>
                    <IconButton
                        tooltip='엑셀 파일 업로드'
                        icon={<PublishIcon />}
                        onClick={() => setExcelOpen(true)}
                    />
                </div>
            </div>
            <div className={classes.tabContent}>
                <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                    <Sale condition={condition} onUpdateStart={onUpdateStart} onDelete={row => onDelete(row)} initEditRow={() => setEditRow()} payCdParam={'P0001'} title='현금' />
                </div>
                <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                    <Sale condition={condition} onUpdateStart={onUpdateStart} onDelete={row => onDelete(row)} initEditRow={() => setEditRow()} payCdParam={'P0003'} title='외상' />
                </div>
                <div className={clsx(classes.content, { [classes.hide]: selectTab !== 2 })}>
                    <Sale condition={condition} onUpdateStart={onUpdateStart} onDelete={row => onDelete(row)} initEditRow={() => setEditRow()} payCdParam={'P0002'} title='신용카드' />
                </div>
                <div className={clsx(classes.content, { [classes.hide]: selectTab !== 3 })}>
                    <Sale condition={condition} onUpdateStart={onUpdateStart} onDelete={row => onDelete(row)} initEditRow={() => setEditRow()} payCdParam={'P0008'} title='상품권' />
                </div>
                <div className={clsx(classes.content, { [classes.hide]: selectTab !== 4 })}>
                    <Sale condition={condition} onUpdateStart={onUpdateStart} onDelete={row => onDelete(row)} initEditRow={() => setEditRow()} payCdParam={'P0007'} title='포인트' />
                </div>
                <div className={clsx(classes.content, { [classes.hide]: selectTab !== 5 })}>
                    <Sale condition={condition} onUpdateStart={onUpdateStart} onDelete={row => onDelete(row)} initEditRow={() => setEditRow()} payCdParam={'P0004'} title='쿠폰' />
                </div>
                <div className={clsx(classes.content, { [classes.hide]: selectTab !== 6 })}>
                    <Sale condition={condition} onUpdateStart={onUpdateStart} onDelete={row => onDelete(row)} initEditRow={() => setEditRow()} payCdParam={'P0006'} title='현장할인' />
                </div>
                <div className={clsx(classes.content, { [classes.hide]: selectTab !== 7 })}>
                    <Sale condition={condition} onUpdateStart={onUpdateStart} onDelete={row => onDelete(row)} initEditRow={() => setEditRow()} payCdParam={'P0005'} title='조달청할인' />
                </div>
                <div className={clsx(classes.content, { [classes.hide]: selectTab !== 8 })}>
                    <Sale condition={condition} onUpdateStart={onUpdateStart} onDelete={row => onDelete(row)} initEditRow={() => setEditRow()} payCdParam={'P0009'} title='간편결제' />
                </div>
                <div className={classes.input}>
                    <EnrollPanel
                        condition={condition}
                        editRow={editRow}
                        initEditRow={() => setEditRow()}
                        gridRefresh={gridRefresh}
                        clsState={clsState}
                        selectTab={selectTab}
                    />
                </div>
            </div>
            <ExcelDialog
                open={excelOpen}
                close={() => setExcelOpen(false)}
                condition={condition}
                clsState={clsState}
                gridRefresh={gridRefresh}
                progressOpen={progressOpen}
            />
        </div>
    )
}

const Sale = ({ condition, onUpdateStart, onDelete, payCdParam, title, initEditRow }) => {
    const [gridParam, setGridParam] = useState(null);

    useEffect(() => {
        condition && setGridParam({ ...condition, PAY_CD: payCdParam });
    }, [condition, payCdParam])



    return (
        <DataGrid
            title={title}
            selectProc='SLT_SALE_ENROLL'
            selectParam={gridParam}
            updateProc=''
            onDelete={(row) => onDelete(row)}
            onUpdateStart={onUpdateStart}
            onLoad={initEditRow}
            horizonMode
            virtualized
            selectionMode='single'
        >
            <DataColumn fieldName='BIZ_DATE' title='영업일' />
            <DataColumn fieldName='WORK_NM' title='사업장' />
            <DataColumn fieldName='CST_NM' title='거래처' />
            <DataColumn fieldName='CAR_NO' title='차량번호' />
            <DataColumn fieldName='GDS_NM' title='상품명' />
            <DataColumn fieldName='PRICE' />
            <DataColumn fieldName='QTY' />
            <DataColumn fieldName='AMOUNT' />
            <DataColumn 
                fieldName='CSH_RECEPT' 
                value={(row, value) => cshRecept[value]}
                visible={payCdParam === 'P0001'}
            />
            <DataColumn 
                fieldName='CARD_NM' 
                title='결제수단'
                visible={payCdParam !== 'P0001' && payCdParam !== 'P0003'} 
            />
            <DataColumn fieldName='SALE_TIME' title='판매시간' />
            <DataColumn fieldName='REMARK' />
            <DataColumn fieldName='PAY_CD' visible={false} />
            <DataColumn fieldName='PAY_NM' visible={false} />
            <DataColumn fieldName='WORK_CD' visible={false} />
            <DataColumn fieldName='CST_CD' visible={false} />
            <DataColumn fieldName='CAR_CD' visible={false} />
            <DataColumn fieldName='GDS_CD' visible={false} />
            <DataColumn fieldName='TAX_TYPE' visible={false} />
            <DataColumn fieldName='CARD_CD' visible={false} />
            <DataColumn fieldName='TRUCK_TYPE' visible={false} />
            <DataColumn fieldName='REG_TYPE' value={(row, value) => regType[value]} />
        </DataGrid>
    )
}

const useDialogStyles = makeStyles(theme => ({
    dialogPaper: {
        maxWidth: 'none',
        width: 300,
        '& > *': {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    okButton: {
        display: 'flex',
    },
    fieldContainer: {
        overflowY: 'auto',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
            marginBottom: theme.spacing(2),
        },
    },
    btnContainer: {
        alignSelf: 'flex-end',
        padding: theme.spacing(1),
    },
}))

const ExcelDialog = ({ open, close, condition, clsState, gridRefresh, progressOpen }) => {
    const [insData, setInsData] = useFieldInputs(condition);
    const { enqueueSnackbar } = useSnackbar();
    const classes = useDialogStyles();

    useEffect(() => {
        setInsData('WORK_CD', condition && condition['WORK_CD']);
        setInsData('BIZ_DATE', condition && condition['BIZ_DATE']);
    }, [condition, setInsData])

    const fileSelected = async (file) => {
        progressOpen(true);
        const xls = await xlsToJsonAsync(file);
        let result;
        result = await callProc('UPT_GAUGE_EXCEL_GSPOS', { JSON: Object.values(xls)[0], ...insData });
        if (result.err) {
            return false;
        }
        result = await callProc('UPT_SALE_DETAIL_EXCEL_GSPOS', { JSON: Object.values(xls)[1], ...insData });
        if (result.err) {
            return false;
        }
        result = await callProc('UPT_CASHRECEPT_EXCEL_GSPOS', { JSON: Object.values(xls)[2], ...insData });
        if (result.err) {
            return false;
        }
        result = await callProc('UPT_INMONEY_EXCEL_GSPOS', { JSON: Object.values(xls)[3], ...insData });
        if (result.err) {
            return false;
        }
        if(Object.values(xls)[4]){
            result = await callProc('UPT_GOODSOUT_EXCEL_GSPOS', { JSON: Object.values(xls)[4], ...insData });
        }


        result = await callProc('UPT_ERP_DATA_EXCUTE_GSPOS_EXCEL', insData);
        if (!result.err) {
            enqueueSnackbar('엑셀 저장이 완료되었습니다.');
            close();
            gridRefresh();
        }
        progressOpen(false);
    }

    const onRecalc = async()=> {
        progressOpen(true);

        let result = await callProc('UPT_ERP_DATA_EXCUTE_GSPOS_EXCEL', insData);
        if (!result.err) {
            enqueueSnackbar('재계산이 완료되었습니다.');
            close();
            gridRefresh();
        }

        progressOpen(false);
    }

    return (
        <Dialog
            open={open}
            PaperProps={{ className: classes.dialogPaper }}
        >
            <div className={classes.dialogTitle}>
                <DialogTitle>엑셀 업로드</DialogTitle>
                <IconButton
                    tooltip='닫기'
                    onClick={close}
                    icon={<CloseIcon />}
                />
            </div>
            <div className={classes.fieldContainer}>
                <ComboBox
                    label='사업장명'
                    selectProc='SLT_WORKPLACEINFO_AUTH'
                    keyField='WORK_CD'
                    valueField='WORK_NM'
                    value={insData && insData['WORK_CD']}
                    disabled
                    fullWidth={false}
                />
                <DatePicker
                    label='영업일'
                    minDate={clsState[insData && insData['WORK_CD']]}
                    value={insData && insData['BIZ_DATE']}
                    onChange={(value) => setInsData('BIZ_DATE', value)}
                />
            </div>
            <div className={classes.btnContainer}>
                <IconButton
                    tooltip='재계산'
                    icon={<IsoIcon />}
                    onClick={() => onRecalc()}
                />
                <FilePicker
                    onChange={fileSelected}
                />
            </div>
        </Dialog>
    )
}

const usePanelStyles = makeStyles((theme) => ({
    panelContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: 0,
    },
    panelContent: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        overflowY: 'auto',
        '& > *': {
            marginBottom: theme.spacing(2),
        },
    },
    btnContainer: {
        alignSelf: 'flex-end',
        padding: theme.spacing(1),
    },
}))

const EnrollPanel = ({ condition, editRow, gridRefresh, clsState, selectTab, initEditRow }) => {
    const classes = usePanelStyles();
    const [cstParam, setCstParam] = useState();
    const [gdsParam, setGdsParam] = useState();
    const [cardParam, setCardParam] = useState();
    const [carParam, setCarParam] = useState();
    const [editState, setEditState] = useState(0);
    const [befValue, setBefValue] = useState();
    const { enqueueSnackbar } = useSnackbar();

    const [insData, setInsData] = useFieldInputs({
        WORK_CD: condition && condition['WORK_CD'],
        BIZ_DATE: condition && condition['BIZ_DATE'],
        SALE_TIME: '00:00:00',
        CST_CD: null,
        CAR_CD: null,
        GDS_CD: null,
        CARD_CD: null,
        CALC_TYPE: '0',
        QTY: 0,
        AMOUNT: 0,
        CSH_RECEPT: '0',
        TRUCK_TYPE: '0',
        PAY_CD: tabPayCd[selectTab]['PAY_CD'],
    });

    useEffect(() => {
        setInsData({ type: 'INIT' });
        setEditState(0);
    }, [selectTab, setInsData])

    useEffect(() => {
        if (!editRow) {
            setInsData({ type: 'INIT' });
            setEditState(0);
        } else {
            setInsData({ type: 'UPDATE', value: editRow })
            setEditState(1);
        }
    }, [editRow, setInsData])

    useEffect(() => {
        if (condition) {
            setInsData({ type: 'INIT' });
            setEditState(0);
            setGdsParam({
                WORK_CD: condition && condition['WORK_CD'],
                GDS_TYPE: 'ALL',
                NOT_TYPE: 0,
                GDS_TYPE_M: 'ALL',
                NOT_TYPE_M: 0,
                GAUGE_TYPE: 'ALL',
                STA_DATE: condition && condition['BIZ_DATE'],
            });
            setCstParam({
                WORK_CD: condition && condition['WORK_CD'],
                CST_TYPE: [1, 2],
                CST_KIND: [1, 2],
                STA_DATE: condition && condition['BIZ_DATE'],
            })
            setInsData('WORK_CD', condition['WORK_CD']);
            setInsData('BIZ_DATE', condition['BIZ_DATE']);
        }
    }, [condition, setInsData])

    const isValid = () => {
        if(insData['CST_CD'] == null) {
            enqueueSnackbar('거래처를 선택해 주세요.', { variant: 'error' });
            return false;
        }

        if(insData['GDS_CD'] == null) {
            enqueueSnackbar('상품을 선택해 주세요.', { variant: 'error' });
            return false;
        }

        if((tabPayCd[selectTab]['PAY_CD'] !== 'P0001' && tabPayCd[selectTab]['PAY_CD'] !== 'P0003') && 
        insData['CARD_CD'] == null) {
            enqueueSnackbar('결제수단을 선택해 주세요.', { variant: 'error' });
            return false;
        }

        if(String(insData['CALC_TYPE']) === '0' && String(insData['QTY']) === '0') {
            enqueueSnackbar('수량을 입력해 주세요.', { variant: 'error' });
            return false;
        }

        if(String(insData['CALC_TYPE']) === '1' && String(insData['AMOUNT']) === '0') {
            enqueueSnackbar('금액을 입력해 주세요.', { variant: 'error' });
            return false;
        }

        return true;
    }

    const [loading, setLoading] = useState(false);

    const onInsert = async() => {
        setLoading(true);

        if(isValid()){
            let befData = {...insData};
            delete befData.SALE_CD;
            delete befData.WORK_CD;
            delete befData.BIZ_DATE;
            delete befData.PAY_CD;
            setBefValue(befData);
            let procName;
            if(String(editState) === '0'){
                procName = 'INS_OILSALEINFO_ENROLL'
            } else {
                procName = 'UPT_OILSALEINFO_ENROLL'
            }
            const result = await callProc(procName, insData);
            if(!result.err) {
                enqueueSnackbar('저장이 완료되었습니다.');
                setInsData({ type: 'INIT' });
                setEditState(0);
                gridRefresh();
            }
        }

        setLoading(false);
    }

    const onExitEdit = () => {
        initEditRow();
    }

    const getBefValue = () => {
        befValue && setInsData({ type: 'UPDATE', value: {
            ...befValue,
            CSH_RECEPT: tabPayCd[selectTab]['PAY_CD'] === 'P0001' ? befValue['CSH_RECEPT'] : '0',
            TRUCK_TYPE: (tabPayCd[selectTab]['PAY_CD'] === 'P0001' || 
                        tabPayCd[selectTab]['PAY_CD'] === 'P0002') ? befValue['TRUCK_TYPE'] : '0'
        } })
    }

    const cstCd = insData && insData['CST_CD'];
    const payCd = tabPayCd[selectTab]['PAY_CD'];

    useEffect(() => setCarParam((prevCarParam) => (
        cstCd === prevCarParam?.CST_CD ? prevCarParam : {
            WORK_CD: condition?.WORK_CD,
            CST_CD: cstCd
        }
    )), [condition, cstCd])

    useEffect(() => setCardParam((prevCardParam) => (
        !condition?.WORK_CD ||
        (payCd === prevCardParam?.PAY_CD && condition?.WORK_CD === prevCardParam?.WORK_CD) ? 
        prevCardParam : 
        (payCd !== 'P0001' && payCd !== 'P0003') ? {
            WORK_CD: condition?.WORK_CD,
            PAY_CD: payCd
        }: undefined
    )), [condition, payCd])


    return (
        <Paper className={classes.panelContainer} >
            <ProgressDialog open={loading} />
            <PaperTitle>{`${tabPayCd[selectTab]['PAY_NM']} ${String(editState) === '0' ? '추가' : '수정'}`}</PaperTitle>
            <div className={classes.panelContent}>
                {String(editState) === '1' &&
                <TextField
                    label='판매코드'
                    value={insData['SALE_CD']}
                    disabled
                />}
                <ComboBox
                    label='사업장'
                    selectProc='SLT_WORKPLACEINFO_AUTH'
                    keyField='WORK_CD'
                    valueField='WORK_NM'
                    value={insData['WORK_CD']}
                    disabled
                />
                <DatePicker
                    label='영업일'
                    minDate={clsState[insData['WORK_CD']]}
                    value={insData['BIZ_DATE']}
                    disabled
                />
                <TimePicker
                    style={{ width: 140 }}
                    label='판매시간'
                    value={insData['SALE_TIME']}
                    onChange={value => setInsData('SALE_TIME', value)}
                />
                {cstParam &&
                <ComboSearch
                    label='거래처'
                    selectProc='SLT_CUSTOMER_COMBO'
                    selectParam={cstParam}
                    keyField='CST_CD'
                    valueField='CST_NM'
                    value={insData['CST_CD']}
                    onChange={value => setInsData('CST_CD', value)}
                    nullable
                />}
                {carParam &&
                <ComboSearch
                    label='차량번호'
                    selectProc='SLT_CSTCARINFO_CAR'
                    selectParam={carParam}
                    keyField='CAR_CD'
                    valueField='CAR_NO'
                    value={insData['CAR_CD']}
                    onChange={value => setInsData('CAR_CD', value)}
                    nullable
                />}
                {gdsParam &&
                <ComboSearch
                    label='상품'
                    selectProc='SLT_GAUGE_ITEM_GOODS_COMBO'
                    selectParam={gdsParam}
                    keyField='GDS_CD'
                    valueField='GDS_NM'
                    value={insData['GDS_CD']}
                    onChange={value => setInsData('GDS_CD', value)}
                    nullable
                />}
                {tabPayCd[selectTab]['PAY_CD'] === 'P0001' &&
                <ComboBox
                    label='현금영수증'
                    data={cshRecept}
                    value={insData['CSH_RECEPT']}
                    onChange={value => setInsData('CSH_RECEPT', value)}
                />}
                {(cardParam &&
                tabPayCd[selectTab]['PAY_CD'] !== 'P0001' &&
                tabPayCd[selectTab]['PAY_CD'] !== 'P0003') &&
                <ComboSearch
                    label='결제수단'
                    selectProc='SLT_CARDINFO_COMBO'
                    selectParam={cardParam}
                    keyField='CARD_CD'
                    valueField='CARD_NM'
                    value={insData['CARD_CD']}
                    onChange={value => setInsData('CARD_CD', value)}
                    nullable
                />}
                {(tabPayCd[selectTab]['PAY_CD'] === 'P0001' || 
                tabPayCd[selectTab]['PAY_CD'] === 'P0002') &&
                <ComboBox
                    label='영업화물'
                    data={truckType}
                    value={insData['TRUCK_TYPE']}
                    onChange={value => setInsData('TRUCK_TYPE', value)}
                />}
                <ComboBox
                    label='계산구분'
                    data={calcType}
                    value={insData['CALC_TYPE']}
                    onChange={value => {
                        String(value) === '0' ? setInsData('AMOUNT', 0) : setInsData('QTY', 0);
                        setInsData('CALC_TYPE', value);
                    }}
                />
                <NumberField
                    label='수량'
                    value={insData['QTY']}
                    onChange={value => setInsData('QTY', value)}
                    disabled={String(insData['CALC_TYPE']) === '1'}
                />
                <NumberField
                    label='금액'
                    value={insData['AMOUNT']}
                    onChange={value => setInsData('AMOUNT', value)}
                    disabled={String(insData['CALC_TYPE']) === '0'}
                />
            </div>
            <div className={classes.btnContainer}>
                {String(editState) === '1' &&
                <IconButton
                    tooltip='취소'
                    icon={<CloseIcon />}
                    onClick={() => onExitEdit()}
                />}
                <IconButton
                    tooltip='이전값 불러오기'
                    icon={<CachedIcon />}
                    onClick={() => getBefValue()}
                />
                <IconButton
                    tooltip='확인'
                    icon={<CheckIcon />}
                    onClick={() => onInsert()}
                />
            </div>
        </Paper>
    )
}

export default SaleEnroll