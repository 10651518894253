import React, { useEffect, useMemo, useState } from 'react';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DataColumn, Tabs, Tab, ProgressDialog, IconButton, AddressPicker, ComboSearch } from '../../component';
import { makeStyles, Paper } from '@material-ui/core';
import { callProc } from '../../common/DBConnector';
import { KeyboardArrowDown, KeyboardArrowUp, Edit, Close } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },    
    tabContainer : {
        display : 'flex',
    },
    tabbar : {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    btnContainer: {
        alignSelf: 'center',
        margin: theme.spacing(1),
    },
    button: {
        fontSize: 14,
    },
    content : {
        display: 'flex',
        flex: 1,
        height : 0,
    },
    hide : {
        display: 'none'
    },
}));

const TransInfo = () => {
    const [condition, setCondition] = useState(null);
    const [loading, setLoading] = useState(false);
    const [tspType, setTspType] = useState(0);
    
    const [fields, setFields] = useState();
    const [mstData, setMstData] = useState();
    const [hisData, setHisData] = useState();
    const [selectMstRow, setSelectMstRow] = useState();
    const [selectHisRow, setSelectHisRow] = useState();

    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    useEffect(() => {
        const fetchData = async() => {
            setMstData(null);
            setHisData(null);
            setSelectMstRow(null);
            setSelectHisRow(null);

            const { fields, data: mstData } = await callProc('SLT_TRANSPLACE_MST');
            const hisData = (await callProc('SLT_TRANSPLACE_HIS', condition)).data;

            const grouping = data => data.reduce((result, item) => ({
                ...result,
                [item['TSP_TYPE']]: [
                    ...result[item['TSP_TYPE']],
                    item
                ]
            }), { 0: [], 1: [] });

            let hisResult = [];
            for (let i = 0; i < hisData.length; i++) {
                const tspCd = hisData[i]['TSP_CD'];
                const cstCd = hisData[i]['CST_CD'];
                const cstNm = hisData[i]['CST_NM'];
                const idx = mstData.findIndex(item => item['TSP_CD'] === tspCd);
                hisResult = [...hisResult, ...mstData.splice(idx, 1)];
                hisResult[i].CST_CD = cstCd;
                hisResult[i].CST_NM = cstNm;
            }

            setFields(fields);
            setMstData(grouping(mstData));
            setHisData(grouping(hisResult));
        }
        condition && fetchData();
    }, [condition]);

    const onAdd = async() => {
        setLoading(true);

        const selData = selectMstRow.map(item => item['TSP_CD']);
        const result = await callProc('INS_TRANSPLACE_HIS', { ...condition, ARR_TSP_CD: selData });
        if (!result.err) {
            enqueueSnackbar('추가가 완료되었습니다.');
            setCondition({ ...condition });
        }

        setLoading(false);
    }

    const onRemove = async() => {
        setLoading(true);

        const selData = selectHisRow.map(item => item['TSP_CD']);
        const result = await callProc('DLT_TRANSPLACE_HIS', { ...condition, ARR_TSP_CD: selData });
        if (!result.err) {
            enqueueSnackbar('삭제가 완료되었습니다.');
            setCondition({ ...condition });
        }

        setLoading(false);
    }

    return(
        <div className={classes.container}>
            <ProgressDialog open={loading} />
            <SearchPanel onSearch={condition => {
                sessionStorage['WORK_CD'] = condition['WORK_CD'];
                setCondition(condition);
                }}>
                <SearchItem
                    fieldName='WORK_CD'
                    component={(state, dispatch) => (
                        <ComboBox
                            label='사업장명'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => {
                                dispatch(value);
                                setCondition({...condition, WORK_CD: value})
                            }}
                        />
                    )}
                />
            </SearchPanel>
            <div className={classes.tabContainer}>
                <Tabs
                    value={tspType}
                    onChange={(e, v) => setTspType(v)}
                    appbarProps={{ className: classes.tabbar }}
                >
                    <Tab label='출하처' />
                    <Tab label='인도처' />
                </Tabs>
            </div>
            <TrnasGrid
                title='목록'
                fields={fields}
                condition={condition}
                data={mstData && mstData[tspType]}
                tspType={tspType}
                cstVisible={0}
                onSelect={rows => setSelectMstRow(rows)}
                refreshGrid={() => setCondition({ ...condition })}
                insertable
            />
            <Paper className={classes.btnContainer}>
                <IconButton
                    tooltip='제거'
                    icon={<KeyboardArrowUp />}
                    disabled={selectHisRow == null}
                    onClick={onRemove}
                />
                <IconButton
                    tooltip='추가'
                    icon={<KeyboardArrowDown />}
                    disabled={selectMstRow == null}
                    onClick={onAdd}
                />
            </Paper>
            <TrnasGrid
                title='추가됨'
                fields={fields}
                condition={condition}
                data={hisData && hisData[tspType]}
                tspType={tspType}
                cstVisible={1}
                onSelect={rows => setSelectHisRow(rows)}
                refreshGrid={() => setCondition({ ...condition })}
            />
            {/* <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <Ship condition={condition} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <Destination condition={condition} />
            </div> */}
        </div>
    )
}

const TrnasGrid = ({ title, fields, condition, data, insertable, tspType, cstVisible, onSelect, refreshGrid }) => {
    const dataSet = useMemo(() => fields && data && { fields, data }, [fields, data]);

    const onInsert = async(row) => {
        const result = await callProc('INS_TRANSPLACE_MST', row);
        if (result.err) {
            return false;
        } else {
            refreshGrid();
            return true;
        }
    }

    const onUpdate = async(row) => {
        const result = cstVisible === 0 ? await callProc('UPT_TRANSPLACE_MST', row) : await callProc('UPT_TRANSPLACE_HIS', { ...row, WORK_CD_HIS : condition['WORK_CD'] });
        if (result.err) {
            return false;
        } else {
            refreshGrid();
            return true;
        }
    }

    const onDelete = async(row) => {
        const result = await callProc('DLT_TRANSPLACE', row);
        if (result.err) {
            return false;
        } else {
            refreshGrid();
            return true;
        }
    }

    return(
        <DataGrid
            title={title}
            dataSet={dataSet}
            insertProc={insertable ? 'INS_TRANSPLACE_MST' : undefined}
            updateProc='UPT_TRANSPLACE_MST'
            deleteProc='DLT_TRANSPLACE'
            onInsert={insertable ? onInsert : undefined}
            onUpdate={onUpdate}
            onDelete={onDelete}
            selectionMode='multi'
            onSelect={onSelect}
            horizonMode
        >
            <DataColumn
                fieldName='WORK_CD'
                title='수송처사업장'
                value={row => row['WORK_NM']}
                defaultValue={null}
                visible={tspType === 1}
                editable={(state, dispatch) => (
                    <ComboBox
                        selectProc='SLT_WORKPLACEINFO_AUTH'
                        keyField='WORK_CD'
                        valueField='WORK_NM'
                        value={state}
                        onChange={value => dispatch(value)}
                        nullable
                    />
                )}
            />
            <DataColumn
                fieldName='CST_CD'
                title='수송처거래처'
                position='TSP_NM'
                defaultValue={null}
                value={row => row['CST_NM']}
                visible={tspType === 1 && cstVisible === 1}
                editable={(state, dispatch) => (
                    <ComboSearch
                        selectProc='SLT_CUSTOMERINFO_ALL'
                        style={{width: 200}}
                        keyField='CST_CD'
                        valueField='CST_NM'
                        selectParam={condition}
                        value={state}
                        onChange={value => dispatch(value)}
                        nullable
                    />
                )}
            />
            <DataColumn
                fieldName='ADDR'
                editable={(state, dispatch, rowState, setField) =>
                    <div>
                        <AddressPicker
                            open={rowState['ADDR_DIALOG'] || false}
                            onClose={() => setField('ADDR_DIALOG', false)}
                            onSelect={data => {
                                dispatch(data.roadAddr);
                                setField('ZIP_CD', data.zipNo);
                            }}
                        />
                        {state}
                        <IconButton
                            tooltip='수정'
                            icon={<Edit />}
                            onClick={() => setField('ADDR_DIALOG', true)}
                        />
                        {(state || '') !== '' &&
                        <IconButton
                            tooltip='삭제'
                            icon={<Close />}
                            onClick={() => dispatch(null)}
                        />}
                    </div>
                }
            />
            <DataColumn
                fieldName='TSP_TYPE'
                defaultValue={tspType}
                visible={false}
            />
            <DataColumn
                fieldName='WORK_NM'
                visible={false}
            />
            <DataColumn
                fieldName='CST_NM'
                visible={false}
            />
        </DataGrid>
    )
}

export default TransInfo