import React, { useState, useEffect, useRef, Fragment, useMemo, forwardRef } from 'react';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DataColumn, Tab, Tabs, IconButton, FilePicker, PaperTitle, PdfButton, TextField } from '../../component';
import { callProc, uploadFile, getFileList, downloadFile } from '../../common/DBConnector';
import { Button, Dialog, DialogTitle, makeStyles, Paper, ListItemIcon, Menu as PopupMenu, MenuItem as PopupMenuItem, Fab } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { useSnackbar } from 'notistack';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import RepeatIcon from '@material-ui/icons/Repeat';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ViewListIcon from '@material-ui/icons/ViewList';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PostAddIcon from '@material-ui/icons/PostAdd';
import EditIcon from '@material-ui/icons/Edit';
import BlockIcon from '@material-ui/icons/Block';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useSelector } from 'react-redux';
import { docState } from '../../common/Dictionary';
import ElectRemarkDialog from './ElectRemarkDialog';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { Save } from '@material-ui/icons';
import { Edocumentpdf } from '../../reports';
import { useFieldInputs } from '../../common/Utils';

const titleBackColor = '#EFEFEF';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 3,
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
    infoContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: 430,
    },
    info: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflowX: 'hidden',
    },
    actionButton: {
        alignSelf: 'center',
        display: 'flex',
    },
    viewr: {
        display: 'flex',
        flex: 2,
        flexDirection: 'column',
        marginLeft: theme.spacing(1),
    },
    tab: {
        minWidth: 'unset',
        flex: 1,
    },
    docSeq: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    docTitle: {
        width: 0,
        whiteSpace: 'pre',
        paddingTop: 4,
        color: 'gray'
    },
    pdfList: {
        minWidth: 450,
        flex: 1,
    },
    paperTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
        maxWidth: 1200,
    },
    pdfIcons: {
        display: 'inline-block',
        marginLeft: theme.spacing(2),
    },
    viewContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflowY: 'auto',
        padding: theme.spacing(3),
        '& > *': {
            marginBottom: theme.spacing(1),
        },
        maxWidth: 1200
    },
    viewTitleText: {
        fontSize: 24,
        fontWeight: 'bold',
    },
    viewWriterContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    viewWriter: {
        fontSize: 15,
    },
    viewSubTitleText: {
        fontSize: 18,
    },
    viewTable: {
        borderCollapse: 'collapse',
        width: '100%',
        borderWidth: 1,
        borderStyle: 'solid',
    },
    viewHeaderRow: {
        backgroundColor: titleBackColor,
        height: 30,
        fontSize: 14,
    },
    viewRow: {
        height: 30,
        fontSize: 12,
    },
    viewLeftCell: {
        textAlign: 'left',
        fontSize: 16
    },
    viewRightCell: {
        textAlign: 'right',
        fontSize: 16
    },
    viewCenterCell: {
        textAlign: 'center',
        fontSize: 16
    },
    viewDataLeftCell: {
        fontWeight: 'normal',
        textAlign: 'left',
        fontSize: 15
    },
    viewDataRightCell: {
        fontWeight: 'normal',
        textAlign: 'right',
        fontSize: 15
    },
    viewDataCenterCell: {
        fontWeight: 'normal',
        textAlign: 'center',
        fontSize: 15
    },
    viewNotifyContainer: {
        borderWidth: 1, 
        borderStyle: 'solid',
    },
    viewNotifyHeader: {
        display: 'flex',
        backgroundColor: titleBackColor,
        height: 30
    },
    viewNotifyHeaderText: {
        alignSelf: 'center',
        marginLeft: theme.spacing(3),
        fontSize: 16,
        fontWeight: 'bold',
    },
    viewNotifyContent: {
        display: 'flex',
        height: 30
    },
    viewRemarkContent: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 5,
        paddingBottom: 5,
    },
    viewRemarkContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    viewRemarkText: {
        marginLeft: theme.spacing(3),
        fontSize: 15,
        marginBottom: theme.spacing(1),
        whiteSpace: 'pre-wrap',
    },
    viewNotifyContentText: {
        alignSelf: 'center',
        marginLeft: theme.spacing(3),
        fontSize: 15,
    },
    docContentTitle:{
        fontSize: 22,
        fontWeight: 'bold',
        alignSelf: 'center',
        marginTop: theme.spacing(1),
    },
    docContent: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 5,
        paddingBottom: 5,
    },
    docText: {
        marginLeft: theme.spacing(3),
        fontSize: 15,
        marginBottom: theme.spacing(1),
        whiteSpace: 'pre-wrap',
    },
    fileContainer: {
        display: 'flex',
        fontSize: 16,
    },
    fileContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    fileItem: {
        fontSize: 15,
        marginLeft: theme.spacing(2),
    },
}))

const searchType = {
    0: '제목',
    1: '작성자',
    2: '문서번호',
}

const docOpenType = {
    NEW: 'NEW',
    EDIT: 'EDIT',
    COPY: 'COPY',
    RECREATE: 'RECREATE',
    REEDIT: 'REEDIT',
}

const lineType = {
    CONFIRM: 'CONFIRM',
    PROCESS: 'PROCESS',
    REF: 'REF'
}

const saveType = {
    APPROVAL: 'APPROVAL',
    PROCESS_CONFIRM: 'PROCESS_CONFIRM',
    BLOCK: 'BLOCK',
    REMOVE: 'REMOVE',
}

const ElecDocument = () => {
    const classes = useStyles();
    const [condition, setCondition] = useState(null);
    const [selectTab, setSelectTab] = useState(0);
    const [gridData, setGridData] = useState();
    const [fileData, setFileData] = useState(null);
    const [fileKey, setFileKey] = useState(null);
    const [selectRow, setSelectRow] = useState(null);
    const [remarkOpen, setRemarkOpen] = useState(false);
    const [docHeaderData, setDocHeaderData] = useState(null);
    const [docLineData, setDocLineData] = useState(null);
    const [infoEdocLine, setInfoEdocLine] = useState(null);
    const [infoLineRef, setInfoLineRef] = useState(null);
    const [infoRemark, setInfoRemark] = useState(null);
    const [empInfo, setEmpInfo] = useState(null);
    const [enrollOpen, setEnrollOpen] = useState(null);
    const [empOpen, setEmpOpen] = useState(false);
    const [empDialogLoadType, setEmpDialogLoadType] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const snack = useRef();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const loginState = useSelector(state => state.loginReducer);

    useEffect(() => {
        const fetchData = async() => {
            const empInfo = (await callProc('SLT_EMPINFO_ALL')).data;

            setEmpInfo(empInfo);
        };
        fetchData();
    }, [])

    useEffect(() => {
        const fetchData = async() => {
            let selectProc;
            switch (selectTab) {
                case 0:
                    selectProc = 'SLT_EDOC_APPROVAL';
                    break;
                case 1:
                    selectProc = 'SLT_EDOC_NOT_CONFIRM';
                    break;
                case 2:
                    selectProc = 'SLT_EDOC_BLOCK';
                    break;
                case 3:
                    selectProc = 'SLT_EDOC_NOT_PROCESS';
                    break;
                case 4:
                    selectProc = 'SLT_EDOC_LIST';
                    break;
                default: return;
            }
            const gridData = await callProc(selectProc, { ...condition, LOGIN_EMP_CD: loginState['EMP_CD'] });
            setGridData(gridData);
        }
        condition && fetchData();
    }, [selectTab, condition, loginState]);

    useEffect(() => {
        const fetchData = async() => {
            setInfoEdocLine(null);
            setInfoLineRef(null);
            setInfoRemark(null);

            const infoEdocLine = (await callProc('SLT_EDOCLINE_STATE', selectRow)).data;
            const infoLineRef = (await callProc('SLT_EDOCLINE_REF', selectRow)).data;
            const infoRemark = (await callProc('SLT_EDOCREMARK', selectRow)).data;

            setInfoEdocLine(infoEdocLine);
            setInfoLineRef(infoLineRef);
            setInfoRemark(infoRemark);
        };

        selectRow && fetchData();
    }, [selectRow]);

    useEffect(() => {
        const fetchData = async() => {
            const docHeaderData = (await callProc('SLT_EDOCHEADER_COPY', {DOC_CD: selectRow['DOC_CD']})).data[0];


            const docLineData = (await callProc('SLT_EDOCLINE_COPY', {DOC_CD: selectRow['DOC_CD']})).data.reduce((result, item) => ({
                ...result,
                [item['LINE_TYPE']]: [
                    ...(result[item['LINE_TYPE']] || []),
                    item
                ]
            }), []);

            setDocHeaderData(docHeaderData);
            setDocLineData(docLineData);
        };

        selectRow && (enrollOpen === docOpenType.COPY || enrollOpen === docOpenType.EDIT || enrollOpen === docOpenType.REEDIT) && fetchData();
    }, [selectRow, enrollOpen]);

    const pdfComponent = useMemo(() => (
        <PdfButton>
            {selectRow && 
            infoEdocLine && 
            infoLineRef && 
            infoRemark &&
            <Edocumentpdf
                data={selectRow}
                lineData={infoEdocLine}
                lineRef={infoLineRef}
                remarkData={infoRemark}
                fileData={fileData}
                fileKey={selectRow['FILE_KEY']}
            />}
        </PdfButton>
    ), [selectRow, infoEdocLine, infoLineRef, infoRemark, fileData]);


    const onSelected = async(row) => {
        if(row && row['FILE_KEY']) {
            const fileList = await getFileList(row['FILE_KEY']);
            setFileKey(row['FILE_KEY']);
            setFileData(fileList);
        }else {
            setFileKey(null);
            setFileData(null);
        }
        setSelectRow(row);
    }

    const onKeyPress = (e, condition) => {
        if(e.key === 'Enter') {
            setCondition({...condition})
        }
    }

    const reqSave = (type) => {
        snack.current && closeSnackbar(snack.current);

        let msg;
        let variant = 'default';
        switch (type) {
            default:
            case saveType.APPROVAL:
                msg = '승인할까요?';
                break;
            case saveType.BLOCK:
                msg = '보류할까요?';
                variant = 'warning';
                break;
            case saveType.PROCESS_CONFIRM:
                msg = '처리확인할까요?';
                break;
            case saveType.REMOVE:
                msg = '파기할까요?';
                variant = 'warning';
                break;
        }
        snack.current = enqueueSnackbar(msg, {
            variant: variant,
            autoHideDuration: null,
            action: key => <>
                <Button onClick={() => {
                    closeSnackbar(key);
                    onSave(type);
                }}>
                    예
                </Button>
                <Button onClick={() => closeSnackbar(key)}>
                    아니오
                </Button>
            </>,
        });
    }

    const onSave = async (type) => {
        if(!selectRow){
            enqueueSnackbar('선택된 문서가 없습니다.', {variant: 'error'});
            return;
        }
        
        let procName;
        let msg;
        switch (type) {
            default:
            case saveType.APPROVAL:
                procName = 'INS_EDOC_APPROVAL';
                msg = '승인이 완료되었습니다.';
                break;
            case saveType.BLOCK:
                procName = 'INS_EDOC_BLOCK';
                msg = '보류가 완료되었습니다.';
                break;
            case saveType.PROCESS_CONFIRM:
                procName = 'INS_EDOC_PROCESS_CONFIRM';
                msg = '처리확인이 완료되었습니다.';
                break;
            case saveType.REMOVE:
                procName = 'INS_EDOC_REMOVE';
                msg = '파기가 완료되었습니다.';
                break;
        }

        const result = await callProc(procName, {
            DOC_CD: selectRow['DOC_CD'],
            EMP_CD: loginState['EMP_CD'],
        })

        if (!result.err) {
            enqueueSnackbar(msg);
            refreshGrid();
        }
    }

    const docConfirm = () => {
        switch (selectTab) {
            default:
            case 0:
                return <Fragment>
                    {String(selectRow['DOC_STATE']) === '0' || String(selectRow['DOC_STATE']) === '1' ?
                    <IconButton
                        tooltip='문서확인'
                        icon={<CheckIcon />}
                        onClick={() => {
                            setEmpDialogLoadType('confirm');
                            setEmpOpen(true);
                        }}
                    /> : 
                    <IconButton
                        tooltip='승인'
                        icon={<CheckIcon />}
                        onClick={() => reqSave(saveType.APPROVAL)}
                    />}
                    <IconButton
                        tooltip='보류'
                        icon={<BlockIcon />}
                        onClick={() => reqSave(saveType.BLOCK)}
                    />
                </Fragment>
                case 1:
                    return (
                        (selectRow['DOC_STATE'].toString() === '0' &&
                        selectRow['EMP_CD'] === loginState['EMP_CD']) &&
                        <IconButton
                            tooltip='수정'
                            icon={<EditIcon />}
                            onClick={() => {
                                setEnrollOpen(docOpenType.EDIT);
                            }}
                        />
                    )
                case 2:
                    return <Fragment>
                        <IconButton
                            tooltip='재작성'
                            icon={<CreateIcon />}
                            onClick={() => {
                                setEnrollOpen(docOpenType.REEDIT);
                            }}
                        />
                        <IconButton
                            tooltip='파기'
                            icon={<DeleteForeverIcon />}
                            onClick={() => reqSave(saveType.REMOVE)}
                        />
                    </Fragment>
                case 3:
                    return (
                        selectRow['DOC_STATE'].toString() !== '6' ? 
                        <IconButton
                            tooltip='처리완료'
                            icon={<CheckIcon />}
                            onClick={() => {
                                setEmpDialogLoadType('process');
                                setEmpOpen(true);
                            }}
                        /> : 
                        <IconButton
                            tooltip='처리확인'
                            icon={<CheckIcon />}
                            onClick={() => reqSave(saveType.PROCESS_CONFIRM)}
                        />
                    )
        }
    }

    const refreshGrid = () => {
        setCondition({...condition});
    }

    const IconMenuItem = forwardRef(({ onClick, icon, label }, ref) => {
        return (
            <PopupMenuItem onClick={onClick} ref={ref}>
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                {label}
            </PopupMenuItem>
        )
    })

    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => {
                setCondition(condition);
                }}>
                <SearchItem
                    fieldName='SEARCH_TYPE'
                    component={(value, setState) => (
                        <ComboBox
                            label='검색구분'
                            data={searchType}
                            value={value}
                            onChange={value => setState(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='SEARCH_ITEM'
                    defaultValue=''
                    component={(value, setState, condition) => (
                        <TextField
                            label='검색어'
                            value={value}
                            onChange={value => setState(value)}
                            onKeyPress={e => onKeyPress(e, condition)}
                        />
                    )}      
                />
            </SearchPanel>
            <div className={classes.content}>
                <div className={classes.infoContainer}>
                    <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps>
                        <Tab label='결재' className={classes.tab} icon={<PlaylistAddCheckIcon />} />
                        <Tab label='미결' className={classes.tab} icon={<AssignmentTurnedInIcon />} />
                        <Tab label='재결' className={classes.tab} icon={<RepeatIcon />} />
                        <Tab label='미처리' className={classes.tab} icon={<HourglassEmptyIcon />} />
                        <Tab label='조회' className={classes.tab} icon={<ViewListIcon />} />
                    </Tabs>
                    <DataGrid
                        className={classes.info}
                        dataSet={gridData}
                        onSelect={onSelected}
                        selectionMode='single'
                        headerLess
                        virtualized
                        horizonMode
                    >
                        <DataColumn
                            fieldName='DOC_SEQ'
                            title={`문서번호 (${gridData && gridData.data.length})`}
                            value={(row, value) => {
                            return <div>
                                <div className={classes.docSeq}>
                                    <div>
                                        {row['FILE_KEY'] ?
                                        <AttachFileIcon fontSize='small' /> : null}
                                    </div>
                                    <div>
                                        {value}
                                    </div>
                                </div>
                                <div className={classes.docTitle}>{row['DOC_TITLE']}</div>
                            </div>}}
                        />
                        <DataColumn
                            fieldName='DOC_STATE'
                            title='상태'
                            value={(row, value) => docState[value] + (row['DLT_DATE'] ? `(${row['DLT_DATE']})` : '')}
                            cellStyle={{ height: 60, paddingBottom: 22 }}
                        />
                        <DataColumn
                            fieldName='EMP_NM'
                            title='작성자'
                            cellStyle={{ paddingBottom: 22 }}
                        />
                        <DataColumn
                            fieldName='REGDATE'
                            title='등록일'
                            cellStyle={{ paddingBottom: 22 }}
                        />
                        <DataColumn fieldName='DOC_TITLE' visible={false} />
                        <DataColumn fieldName='DOC_CD' visible={false} />
                        <DataColumn fieldName='DOC_DATE' visible={false} />
                        <DataColumn fieldName='REMARK' visible={false} />
                        <DataColumn fieldName='DEP_NM' visible={false} />
                        <DataColumn fieldName='POSITION' visible={false} />
                        <DataColumn fieldName='REGDTTM' visible={false} />
                        <DataColumn fieldName='FILE_KEY' visible={false} />
                        <DataColumn fieldName='CONFM_TYPE' visible={false} />
                        <DataColumn fieldName='EMP_CD' visible={false} />
                        <DataColumn fieldName='DLT_DATE' visible={false} />
                        <DataColumn fieldName='DOC_CONTENT' visible={false} />
                    </DataGrid>
                </div>
                <Paper className={classes.viewr}>
                    <div className={classes.paperTitle}>
                        <PaperTitle>
                            문서 내용
                            {(!selectRow || (selectRow && String(selectRow['CONFM_TYPE']) === '1')) ? null : docConfirm()}
                        </PaperTitle>
                        <div className={classes.actionButton}>
                            {selectRow && pdfComponent}
                            <PopupMenu
                                anchorEl={anchorEl}
                                open={anchorEl != null}
                                onClose={() => setAnchorEl(null)}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                {fileKey && fileData && Object.keys(fileData)[0] !== 'err' && fileData.map((fileItem, idx) =>
                                    <IconMenuItem 
                                        key={idx}
                                        label={fileItem.name}
                                        icon={<GetAppOutlinedIcon />}
                                        onClick={() => downloadFile(fileKey, fileItem.seq)}
                                    />
                                )}
                            </PopupMenu>
                            {fileData && fileData.length > 0 &&
                            <IconButton
                                tooltip='첨부파일'
                                icon={<AttachFileIcon />}
                                onClick={e => setAnchorEl(e.currentTarget)}
                            />}
                            <IconButton
                                tooltip='참고사항'
                                onClick={() => setRemarkOpen(true)}
                                icon={<PostAddIcon />}
                            />
                            {selectRow?.DOC_CD &&
                            <IconButton
                                tooltip='문서복사'
                                onClick={() => setEnrollOpen(docOpenType.COPY)}
                                icon={<FileCopyIcon />}
                            />}
                            <IconButton
                                tooltip='추가'
                                onClick={() => setEnrollOpen(docOpenType.NEW)}
                                icon={<AddIcon />}
                            />
                        </div>
                    </div>
                    {selectRow && infoEdocLine &&
                    <div className={classes.viewContainer}>
                        <label className={classes.viewTitleText}>{`${selectRow['DOC_TITLE']}`}</label>
                        <div className={classes.viewWriterContainer}>
                            <label className={classes.viewWriter}>
                                {`작성자 : ${selectRow['DEP_NM'] || ''} / ${selectRow['EMP_NM'] || ''} / ${selectRow['POSITION'] || ''} / ${selectRow['REGDTTM']}`}
                            </label>
                            <label className={classes.viewWriter}>{`문서번호 : ${selectRow['DOC_CD']}`}</label>
                        </div>
                        
                        <label className={classes.viewSubTitleText}>{`[결제 정보]`}</label>
                        <table className={classes.viewTable}>
                            <thead>
                                <tr className={classes.viewHeaderRow}>
                                    <th className={classes.viewCenterCell}>분류</th>
                                    <th className={classes.viewCenterCell}>부서</th>
                                    <th className={classes.viewCenterCell}>성명</th>
                                    <th className={classes.viewCenterCell}>상태</th>
                                    <th className={classes.viewCenterCell}>결제일시</th>
                                </tr>
                            </thead>
                            <tbody>
                                {infoEdocLine && infoEdocLine.length !== 0 &&
                                infoEdocLine.map((item, idx) => (
                                    <tr key={idx} className={classes.viewRow}>
                                        <th className={classes.viewDataCenterCell}>{item['LINE_TYPE']}</th>
                                        <th className={classes.viewDataCenterCell}>{item['DEP_NM']}</th>
                                        <th className={classes.viewDataCenterCell}>{item['EMP_NM']}</th>
                                        <th className={classes.viewDataCenterCell}>{item['CONFM_TYPE']}</th>
                                        <th className={classes.viewDataCenterCell}>{item['PRO_DTTM']}</th>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <label className={classes.viewSubTitleText}>{`[참조자]`}</label>
                        <div className={classes.viewNotifyContainer}>
                            <div className={classes.viewNotifyHeader}>
                                <label className={classes.viewNotifyHeaderText}>{`참조자`}</label>
                            </div>
                            <div className={classes.viewNotifyContent}>
                                <label className={classes.viewNotifyContentText}>{infoLineRef ? infoLineRef[0]['REF_NM'] : `참조자 없음.`}</label>
                            </div>
                        </div>
                        <label className={classes.viewSubTitleText}>{`[참고 사항]`}</label>
                        <div className={classes.viewNotifyContainer}>
                            <div className={classes.viewNotifyHeader}>
                                <label className={classes.viewNotifyHeaderText}>{`내용`}</label>
                            </div>
                            <div className={classes.viewRemarkContent}>
                                {(!infoRemark || infoRemark.length === 0) ?
                                <label className={classes.viewRemarkText} style={{ marginBottom: 0, }}>{`내용 없음`}</label> :
                                infoRemark && infoRemark.length !== 0 &&
                                infoRemark.map((item, idx) => (
                                    <div key={idx} className={classes.viewRemarkContainer}>
                                        <label className={classes.viewRemarkText} style={{ fontWeight: 'bold' }}>
                                            {`< ${item['DEP_NM']} /  ${item['POSITION']} / ${item['EMP_NM']} / ${item['EDTDATE']} >`}
                                        </label>
                                        <label className={classes.viewRemarkText}>
                                            {`- ${item['REMARK']}`}
                                        </label>
                                    </div>
                                ))}
                            </div>  
                        </div>
                        <label className={classes.docContentTitle}>기안 문서 내용</label>
                        <label className={classes.viewSubTitleText}>{`[문서 내용]`}</label>
                        <div className={classes.viewNotifyContainer}>
                            <div className={classes.viewNotifyHeader}>
                                <label className={classes.viewNotifyHeaderText}>{`내용`}</label>
                            </div>
                            <div className={classes.docContent}>
                                <label className={classes.docText}>
                                    {!selectRow['DOC_CONTENT'] ? '내용 없음' :
                                    selectRow['DOC_CONTENT']}
                                </label>
                            </div>  
                        </div>

                        {fileKey && fileData && Object.keys(fileData)[0] !== 'err' &&
                        <div className={classes.fileContainer}>
                            <label>{`[첨부파일]`}</label>
                            <div className={classes.fileContent}>
                                {fileData.map((file, index) => (
                                    <div key={index} className={classes.fileItem}>
                                        {file.name}
                                    </div>
                                ))}
                            </div>
                        </div>}
                    </div>}
                </Paper>
                {infoRemark &&
                <ElectRemarkDialog
                    open={remarkOpen}
                    onClose={() => setRemarkOpen(false)}
                    onInserted={() => setSelectRow({...selectRow})}
                    docCd={selectRow && selectRow['DOC_CD']}
                    data={infoRemark}
                />}
                <DocConfirmEmpDialog
                    open={empOpen}
                    onClose={() => setEmpOpen(false)}
                    empDialogLoadType={empDialogLoadType}
                    selectRow={selectRow}
                    empInfo={empInfo}
                    refreshGrid={refreshGrid}
                    loginState={loginState}
                />
                <EnrollDialog
                    open={enrollOpen}
                    onClose={() => setEnrollOpen(null)}
                    docHeaderData={docHeaderData}
                    docLineData={docLineData}
                    infoRemark={infoRemark}
                    fileKey={fileKey}
                    fileData={fileData}
                    refreshGrid={refreshGrid}
                />
            </div>
        </div>
    )
}

const docConfirmStyle = makeStyles((theme) => ({
    dialogPaper: {
        width: 400,
        height: 200,
        '& > *': {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    fieldContainer: {
        overflowY: 'auto',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
            marginBottom: theme.spacing(2),
        },
    },
    btnContainer: {
        alignSelf: 'flex-end',
        padding: theme.spacing(1),
    },
}))

const DocConfirmEmpDialog = ({open, onClose, selectRow, empDialogLoadType, empInfo, refreshGrid, loginState}) => {
    const classes = docConfirmStyle();
    const [empCd, setEmpCd] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    const onSave = async () => {
        if(!selectRow){
            enqueueSnackbar('선택된 문서가 없습니다.', {variant: 'error'});
            return;
        }

        let result;
        if(empDialogLoadType === 'confirm'){
            result = await callProc('INS_EDOC_CONFIRM', {
                DOC_CD: selectRow['DOC_CD'],
                CONF_EMP_CD: empCd,
                EMP_CD: loginState['EMP_CD'],
            })
        }else{
            result = await callProc('INS_EDOC_PROCESS', {
                DOC_CD: selectRow['DOC_CD'],
                CONF_EMP_CD: empCd,
                EMP_CD: loginState['EMP_CD'],
            })
        }

        if(!result.err){
            enqueueSnackbar('저장이 완료되었습니다.');
            refreshGrid();
            onClose();
        }
    }

    return (
        <Dialog
            open={open}
            PaperProps={{ className: classes.dialogPaper }}
        >
            <div className={classes.dialogTitle}>
                <DialogTitle>
                    {empDialogLoadType === 'confirm' ? '승인자 등록' : '처리확인자 등록'}
                </DialogTitle>
                <IconButton
                    tooltip='닫기'
                    onClick={onClose}
                    icon={<CloseIcon />}
                />
            </div>
            <div className={classes.fieldContainer}>
                <ComboBox
                    label='사원명'
                    data={empInfo}
                    keyField='EMP_CD'
                    valueField='EMP_NM'
                    value={empCd}
                    onChange={value => setEmpCd(value)}
                />
            </div>
            <div className={classes.btnContainer}>
                <IconButton
                    tooltip='확인'
                    onClick={onSave}
                    icon={<CheckIcon />}
                />
            </div>
        </Dialog>
    )
}

const enrollDialogStyle = makeStyles((theme) => ({
    dialogPaper: {
        maxWidth: 'none',
        width: 1550,
        height: '80%',
        '& > *': {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    fieldContainer: {
        overflowY: 'auto',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
            marginBottom: theme.spacing(2),
        },
    },
    containerRowFlex: {
        display: 'flex',
        flexDirection: 'row',
    },
    empFields: {
        marginRight: theme.spacing(1),
        width: 350,
    },
    cancelBtn: {
        marginRight: theme.spacing(1)
    },

    actionButton: {
        display: 'flex',
    },
    empButton: {
        marginTop: theme.spacing(1),
    },
    titleField: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        flex: 1,
    },
    sumFiled: {
        marginLeft: theme.spacing(1),
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding: 4,
        backgroundColor: 'lightgray',
        overflowY: 'auto',
    },
    contentCard: {
        display: 'flex',
        flex: 1,
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
        margin: 4,
    },
    content: {
        flex: 1,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    contentInput: {
        flex: 1,
        alignItems: 'flex-start',
        overflowY: 'auto'
    },
    bottomContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'sticky',
        bottom: 0,
        padding: 4,
        gap: '8px',
    },
}))

const EnrollDialog = ({open, onClose, docHeaderData, docLineData, infoRemark, fileKey, fileData, refreshGrid}) => {
    const classes = enrollDialogStyle();
    const [docContent, setDocContent] = useState('');
    const [insData, setInsData] = useFieldInputs({
        DOC_TITLE: '',
        CON_JSON: null,
        ACT_JSON: null,
        REF_JSON: null,
    }); 
    const [lineOpenType, setLineOpenType] = useState(null);
    const [files, setFiles] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const fetchData = () => {
            setDocContent(docHeaderData?.DOC_CONTENT);
            setInsData({ 
                type: 'UPDATE', 
                value: {
                    DOC_TITLE: docHeaderData?.DOC_TITLE,
                    CON_JSON: docLineData[2], // 확인자,
                    ACT_JSON: docLineData[3], // 처리자,
                    REF_JSON: docLineData[5], // 참조자,
                }
            })
        };
        
        docHeaderData && docLineData && fetchData();
    }, [docHeaderData, docLineData, setInsData])

    useEffect(() => setFiles(fileData && fileData.length > 0 && (open === docOpenType.EDIT || open === docOpenType.REEDIT) ? [...fileData] : []), [fileData, open]);

    const getDocLine = (items) => items ? items.reduce((result, item, idx) => result + (idx === 0 ? '' : ', ') + item['EMP_NM'], '') : '';
    const onCancel = (field) => setInsData(field, null);
    const valid = () => {
        if(!insData['DOC_TITLE']){
            enqueueSnackbar('제목을 입력해 주세요.', { variant: 'error' });
            return false;
        }

        if(docContent === ''){
            enqueueSnackbar('문서내용을 입력해 주세요.', { variant: 'error' });
            return false;
        }

        if(!insData['CON_JSON']) {
            enqueueSnackbar('확인자를 선택해주세요.', { variant: 'error' });
            return false;
        }

        if(!insData['ACT_JSON']) {
            enqueueSnackbar('처리자를 선택해주세요.', { variant: 'error' });
            return false;
        }

        return true;
    }
    const onSave = async () => {
        if(valid()){
            if(open === docOpenType.COPY || open === docOpenType.NEW ){
                fileKey = null;
            }
    
            if(fileKey || files.length > 0){
                fileKey = await uploadFile(files, fileKey);
                if(fileKey.err) return false;
            }

            const result = await callProc('INS_EDOCHEADER_DOCUMENT', {
                DOC_CD_EDIT: open === (docOpenType.EDIT || docOpenType.REEDIT) ? docHeaderData['DOC_CD'] : null,
                DOC_TITLE: insData['DOC_TITLE'],
                REMARK: open === docOpenType.EDIT ? infoRemark : null,
                FILE_KEY: fileKey,
                DOC_CONTENT: docContent,
                CON_JSON: insData['CON_JSON'],
                ACT_JSON: insData['ACT_JSON'],
                REF_JSON: insData['REF_JSON'],
                RECREATE: open === docOpenType.RECREATE,
            });

            if(open === docOpenType.REEDIT){
                await callProc('INS_EDOC_REMOVE', {
                    DOC_CD: docHeaderData['DOC_CD']
                });
            }

            if(!result.err){
                enqueueSnackbar('저장이 완료되었습니다.');
                onClose();
                refreshGrid();
            }
        }
    }

    return (
        <Fragment>
            <Dialog
                open={true}
                PaperProps={{ className: classes.dialogPaper }}
                style={{ display: open != null ? 'block' : 'none' }}
            >
                <div className={classes.dialogTitle}>
                    <DialogTitle>전자기안문서 등록</DialogTitle>
                    <IconButton
                        tooltip='닫기'
                        onClick={onClose}
                        icon={<CloseIcon />}
                    />
                </div>
                <div className={classes.fieldContainer}>
                    <div className={classes.containerRowFlex}>
                        <TextField
                            style={{ flex: 1 }}
                            label='제목'
                            value={insData['DOC_TITLE']}
                            onChange={value => setInsData('DOC_TITLE', value)}
                        />
                    </div>
                    <div className={classes.containerRowFlex}>
                        <IconButton
                            tooltip='확인자'
                            onClick={() => setLineOpenType(lineType.CONFIRM)}
                            icon={<GroupAddIcon />}
                        />
                        <TextField
                            className={classes.empFields}
                            label='확인자'
                            value={getDocLine(insData['CON_JSON'])}
                            onClick={() => setLineOpenType(lineType.CONFIRM)}
                            disabled
                        />
                        {insData?.CON_JSON &&
                        <IconButton
                            className={classes.cancelBtn}
                            tooltip='취소'
                            onClick={() => onCancel('CON_JSON')}
                            icon={<CloseIcon />}
                        />}
                        <IconButton
                            tooltip='처리자'
                            onClick={() => setLineOpenType(lineType.PROCESS)}
                            icon={<GroupAddIcon />}
                        />
                        <TextField
                            className={classes.empFields}
                            label='처리자'
                            value={getDocLine(insData['ACT_JSON'])}
                            onClick={() => setLineOpenType(lineType.PROCESS)}
                            disabled
                        />
                        {insData?.ACT_JSON &&
                        <IconButton
                            className={classes.cancelBtn}
                            tooltip='취소'
                            onClick={() => onCancel('ACT_JSON')}
                            icon={<CloseIcon />}
                        />}
                        <IconButton
                            tooltip='참조자'
                            onClick={() => setLineOpenType(lineType.REF)}
                            icon={<GroupAddIcon />}
                        />
                        <TextField
                            className={classes.empFields}
                            label='참조자'
                            value={getDocLine(insData['REF_JSON'])}
                            onClick={() => setLineOpenType(lineType.REF)}
                            disabled
                        />
                        {insData?.REF_JSON &&
                        <IconButton
                            className={classes.cancelBtn}   
                            tooltip='취소'
                            onClick={() => onCancel('REF_JSON')}
                            icon={<CloseIcon />}
                        />}
                    </div>
                </div>
                <div className={classes.contentContainer}>
                    <Paper className={classes.contentCard}>
                        <TextField
                            className={classes.content}
                            InputProps={{ className: classes.contentInput }}
                            label='문서내용'
                            value={docContent}
                            onChange={value => setDocContent(value)}
                            multiline
                            variant='outlined'
                        />
                    </Paper>
                    <div className={classes.bottomContainer}>
                        <Paper style={{ display: 'flex', flex: 1, alignItems: 'center', paddingLeft: 16, width: 0 }}>
                            <DialogTitle style={{ padding: 8 }}>첨부파일</DialogTitle>
                            <div style={{ display: 'flex', overflowX: 'auto' }}>
                                {files.map((item, i) => (
                                    <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }} key={i}>
                                        {item.name}
                                        <IconButton
                                            tooltip='삭제'
                                            icon={<CloseIcon />}
                                            onClick={() => {
                                                files.splice(files.indexOf(item), 1);
                                                setFiles([...files]);
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                            <FilePicker
                                multiple
                                onChange={newFiles => {
                                    setFiles([...files, ...newFiles]);
                                }}
                            />
                        </Paper>
                        <Fab
                            size='large'
                            color='primary'
                            onClick={onSave}
                        >
                            <Save />
                        </Fab>
                    </div>
                </div>
                <EmpListDialog 
                    open={lineOpenType != null}
                    onClose={() => setLineOpenType(null)}
                    onConfirm={(value) => {
                        switch (lineOpenType) {
                            default:
                            case lineType.CONFIRM:
                                setInsData('CON_JSON', value);
                                break;
                            case lineType.PROCESS:
                                setInsData('ACT_JSON', value);
                                break;
                            case lineType.REF:
                                setInsData('REF_JSON', value)
                                break;
                        }
                        setLineOpenType(null);
                    }}
                />
            </Dialog>
        </Fragment>
    )
}

const empDialogStyle = makeStyles((theme) => ({
    dialogPaper: {
        width: 400,
        '& > *': {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    grid: {
        height: 400,
    },
    btnContainer: {
        alignSelf: 'flex-end',
        padding: theme.spacing(1),
    },
}))

const EmpListDialog = ({ open, onClose, onConfirm }) => {
    const classes = empDialogStyle();
    const [selectedRows, setSelectedRows] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    
    const valid = () => {
        if (!selectedRows || selectedRows.length === 0) {
            enqueueSnackbar('사원을 선택하세요', { variant: 'error' });
            return false;
        }

        return true;
    }
    
    const onOk = () => {
        if(valid()) {
            onConfirm(selectedRows);
            setSelectedRows(null);
            onClose();
        }
    }

    

    return (
        <Dialog
            open={open}
            PaperProps={{ className: classes.dialogPaper }}
            onClose={onClose}
        >
            <div className={classes.dialogTitle}>
                <DialogTitle>사원 선택</DialogTitle>
                <IconButton
                    tooltip='닫기'
                    onClick={onClose}
                    icon={<CloseIcon />}
                />
            </div>
            <DataGrid
                className={classes.grid}
                selectProc='SLT_EMPINFO_ALL'
                onSelect={row => setSelectedRows(row)}
                headerLess
                borderLess
                selectionMode='multi'
            >
            </DataGrid>
            <div className={classes.btnContainer}>
                <IconButton
                    tooltip='확인'
                    onClick={() => onOk()}
                    icon={<CheckIcon />}
                />
            </div>
        </Dialog>
    )
}

export default ElecDocument;