import React from 'react';
import moment from 'moment';
import { Document, StyleSheet, Text, View } from '@react-pdf/renderer';
import { PdfCell, PdfTable, PdfRow, PdfPage } from '../component';
import { numberFormat } from '../common/Utils';

const styles = StyleSheet.create({
    title: {
        fontSize : 16,
        textAlign : 'center',
    },
    header: {
        flex: 1,
        borderRight: 0,
        fontSize: 9,
    },
    texts: {
        flex: 1,
        borderRight: 0,
        borderBottom: 0,
        fontSize: 9,
    },
    sums: {
        borderRight: 0,
        backgroundColor: '#D3D3D3',
        fontSize: 9,
    },
    tabletitle:{
        marginTop: '10px',
        marginBottom: '3px',
    },
    middleSummaryNormalCell: {
        fontSize: 9,
        borderRight: 0, 
        textAlign: 'left',
        backgroundColor : 'lightyellow',
    },
    middleSummaryNumCell: {
        fontSize: 9,
        borderRight: 0, 
        textAlign: 'right',
        backgroundColor : 'lightyellow',
    },
    bottom: {
        position: 'absolute',
        bottom: 13,
        left: 25,
        right: 25,
    },
})


const OilbankSummaryDYCNCPdf = ({salestockData, 
                            supplyDataDay, 
                            supplyDataMonth, 
                            salePriceChangeData, 
                            gaugeInfoListDataDay, 
                            gaugeInfoListDataMonth, 
                            saleBondList,
                            saleBondListDetail,
                            saleCstViolationData,
                            etcData, 
                            condition,
                            loginState, 
                            workName}) => {
    
    const getTotal = (data, field) => (
        numberFormat(data.reduce((result, item) => result + item[field], 0))
    )

    const getTotalRow = (row, fieldName, idx) => {
        let totalRow;
        if (!salestockData[idx + 1] ||
            fieldName.reduce((result, field) => result || row[field] !== salestockData[idx + 1][field], false)) {

            const groupData = salestockData.reduce((result, item) => (
                fieldName.reduce((result, field) => result && item[field] === row[field], true) ?
                    [...result, item] :
                    result
            ), []);

            totalRow = (
                <PdfRow key={fieldName.reduce((result, field) => result + field) + idx}>
                    <PdfCell style={styles.middleSummaryNormalCell}>
                        {row['GDS_TYPE'] === 'OIL' ? '일반유 소계' : '일반유외(기타) 소계'}
                    </PdfCell>
                    <PdfCell style={styles.middleSummaryNumCell}>
                        {getTotal(groupData, 'SALE_QTY')}
                    </PdfCell>
                    <PdfCell style={{...styles.middleSummaryNumCell, flex: 1.5}}>
                        {getTotal(groupData, 'SALE_AMOUNT')}
                    </PdfCell>
                    <PdfCell style={{...styles.middleSummaryNumCell}}>
                        {getTotal(groupData, 'CUR_STOCK_QTY')}
                    </PdfCell>
                    <PdfCell style={{...styles.middleSummaryNumCell}}>
                        {getTotal(groupData, 'LAST_REAL_QTY')}
                    </PdfCell>
                    <PdfCell style={{...styles.middleSummaryNumCell, flex: 1.1}}>
                        {getTotal(groupData, 'CUR_GAP_QTY')}
                    </PdfCell>
                    <PdfCell style={{...styles.middleSummaryNumCell, flex: 1.1}}>
                        {getTotal(groupData, 'BEF_GAP_QTY')}
                    </PdfCell>
                    <PdfCell style={{...styles.middleSummaryNumCell}}>
                        {getTotal(groupData, 'MONTH_SALE_QTY')}
                    </PdfCell>
                    <PdfCell style={{...styles.middleSummaryNumCell, flex: 1.5}}>
                        {getTotal(groupData, 'MONTH_SALE_AMOUNT')}
                    </PdfCell>
                </PdfRow>
            )
        }
        return totalRow
    }

    const getBondListDetails = () => (
        saleBondListDetail.reduce((result, item) => {
            if(!result[item.ACC_CD]) {
                result[item.ACC_CD] = [];
            }

            result[item.ACC_CD].push(item);

            return result;
        }, {})
    );

    const getSaleDetailRow = (item) => {
        const bondPayDetail = getBondListDetails() ;
        let rowData = [];
        //['0001','0023']
        const val = Object.keys(bondPayDetail).find(arr => {
                    if(arr === item['PAY_CD']) return arr;
                    else return false;
                });
        console.log(val);
        if(item['PAY_CD'] === val){
            rowData = (
            bondPayDetail[item['PAY_CD']].map((item, idx) => (
            <PdfRow key={idx}>
                <PdfCell style={{ ...styles.texts, textAlign: 'left', paddingLeft: 15}}>({item['GDS_TYPE_NM']})</PdfCell>
                <PdfCell style={{ ...styles.texts, textAlign: 'right', paddingRight: 7}}>{numberFormat(item['BEF_AMOUNT'])}</PdfCell>
                <PdfCell style={{ ...styles.texts, textAlign: 'right', paddingRight: 7}}>{numberFormat(item['SALE_AMOUNT'])}</PdfCell>
                <PdfCell style={{ ...styles.texts, textAlign: 'right', paddingRight: 7}}>{numberFormat(item['IN_AMOUNT'])}</PdfCell>
                <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_AMOUNT'])}</PdfCell>
            </PdfRow>)))
        }

        return rowData;
    }

    return (
        <Document>
            <PdfPage>
                <Text style={{ ...styles.title}}>주유소 집계표</Text>
                <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Text>{`인쇄기간: ${moment().format('YYYY-MM-DD HH:mm:ss')}`}</Text>
                    <Text>{`조회기간: ${condition['STA_DATE']} ~ ${condition['END_DATE']}`}</Text> 
                </View>
                <View style={{height: '10'}}>

                </View>
                <Text style={{...styles.tabletitle}}>1. 판매량 및 재고 현황</Text>
                <PdfTable>
                    <PdfRow fixed> 
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'left'}}>상품</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>판매량</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right', flex: 1.5}}>판매금액</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>장부 재고</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>실재고</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right', flex: 1.1}}>당일실재고차</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right', flex: 1.1}}>전일실재고차</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>월 판매량</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right', flex: 1.5}}>월 합계금액</PdfCell>
                    </PdfRow>
                    {salestockData && salestockData.map((item, idx) =>(
                        [<PdfRow key={idx}>
                            <PdfCell style={{ ...styles.texts, textAlign: 'left'}}>{item['GDS_NM']}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['SALE_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right', flex: 1.5}}>{numberFormat(item['SALE_AMOUNT'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_STOCK_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['LAST_REAL_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right', flex: 1.1}}>{numberFormat(item['CUR_GAP_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right', flex: 1.1}}>{numberFormat(item['BEF_GAP_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['MONTH_SALE_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right', flex: 1.5}}>{numberFormat(item['MONTH_SALE_AMOUNT'])}</PdfCell>
                        </PdfRow>,
                        getTotalRow(item, ['GDS_TYPE'], idx)]
                           
                    ))}
                    

                    <PdfRow>  
                        <PdfCell style={{ ...styles.sums, textAlign: 'left'}}>합계</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(salestockData, 'SALE_QTY')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right', flex: 1.5}}>{getTotal(salestockData, 'SALE_AMOUNT')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(salestockData, 'CUR_STOCK_QTY')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(salestockData, 'LAST_REAL_QTY')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right', flex: 1.1}}>{getTotal(salestockData, 'CUR_GAP_QTY')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right', flex: 1.1}}>{getTotal(salestockData, 'BEF_GAP_QTY')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(salestockData, 'MONTH_SALE_QTY')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right', flex: 1.5}}>{getTotal(salestockData, 'MONTH_SALE_AMOUNT')}</PdfCell>
                    </PdfRow>
                </PdfTable>

                <Text style={{...styles.tabletitle}}>2. 일/월 상세 재고 현황(기간별)</Text>
                <PdfTable>
                    <PdfRow fixed> 
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'left'}}>상품명</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>전기재고</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>당기입고</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>자가소비</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>매출보관출고</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>당기출고</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>당기재고</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>재고조정</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>검량</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>자체이관</PdfCell>
                    </PdfRow>

                    {supplyDataDay && supplyDataDay.map((item, idx) =>(
                        <PdfRow key={idx}>
                            <PdfCell style={{ ...styles.texts, textAlign: 'left'}}>{item['GDS_NM']}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['BEF_STOCK_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_IN_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_SELF_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_KEEP_OUT_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_SALE_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_STOCK_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_ADJ_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_TEST_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_MOVE_QTY'])}</PdfCell>
                        </PdfRow>
                    ))}
                    <View style={{borderBottom: 1}}></View>

                    <PdfRow>
                        <PdfCell style={{ ...styles.sums, textAlign: 'left'}}>합계</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(supplyDataDay, 'BEF_STOCK_QTY')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(supplyDataDay, 'CUR_IN_QTY')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(supplyDataDay, 'CUR_SELF_QTY')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(supplyDataDay, 'CUR_KEEP_OUT_QTY')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(supplyDataDay, 'CUR_SALE_QTY')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(supplyDataDay, 'CUR_STOCK_QTY')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(supplyDataDay, 'CUR_ADJ_QTY')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(supplyDataDay, 'CUR_TEST_QTY')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(supplyDataDay, 'CUR_MOVE_QTY')}</PdfCell>
                    </PdfRow>
                </PdfTable>


                <Text style={{...styles.tabletitle}}>2. 일/월 상세 재고 현황(월별)</Text>
                <PdfTable>
                    <PdfRow fixed> 
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'left'}}>상품명</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>전기재고</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>당기입고</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>자가소비</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>매출보관출고</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>당기출고</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>당기재고</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>재고조정</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>검량</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>자체이관</PdfCell>
                    </PdfRow>
                    {supplyDataMonth && supplyDataMonth.map((item, idx) =>(
                        <PdfRow key={idx}>
                            <PdfCell style={{ ...styles.texts, textAlign: 'left'}}>{item['GDS_NM']}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['BEF_STOCK_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_IN_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_SELF_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_KEEP_OUT_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_SALE_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_STOCK_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_ADJ_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_TEST_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_MOVE_QTY'])}</PdfCell>
                        </PdfRow>
                    ))}
                    <View style={{borderBottom: 1}}></View>
                    <PdfRow>
                        <PdfCell style={{ ...styles.sums, textAlign: 'left'}}>합계</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(supplyDataMonth, 'BEF_STOCK_QTY')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(supplyDataMonth, 'CUR_IN_QTY')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(supplyDataMonth, 'CUR_SELF_QTY')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(supplyDataMonth, 'CUR_KEEP_OUT_QTY')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(supplyDataMonth, 'CUR_SALE_QTY')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(supplyDataMonth, 'CUR_STOCK_QTY')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(supplyDataMonth, 'CUR_ADJ_QTY')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(supplyDataMonth, 'CUR_TEST_QTY')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(supplyDataMonth, 'CUR_MOVE_QTY')}</PdfCell>
                    </PdfRow>
                </PdfTable>

                <Text style={{...styles.tabletitle}}>3. 점두가</Text>
                <PdfTable>
                    <PdfRow fixed> 
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'left'}}>상품명</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>변경전</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>현재</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header}}>변경시간</PdfCell>
                    </PdfRow>
                    {salePriceChangeData && salePriceChangeData.map((item, idx) =>(
                        <PdfRow key={idx}>
                            <PdfCell style={{ ...styles.texts, textAlign: 'left'}}>{item['GDS_NM']}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['BEF_PRICE'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_PRICE'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts}}>{item['STA_DTTM']}</PdfCell>
                        </PdfRow>
                    ))}
                    <View style={{borderBottom: 1}}></View>
                </PdfTable>

                <Text style={{...styles.tabletitle}}>4. 일반유 계기외 현황(기간별)</Text>
                <PdfTable>
                    <PdfRow fixed> 
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'left'}}>상품명</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>전기계기</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>당기 계기</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>회전량</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>공회전</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>기타</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>서비스</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>판매량</PdfCell>
                    </PdfRow>
                    {gaugeInfoListDataDay && gaugeInfoListDataDay.map((item, idx) =>(
                        <PdfRow key={idx}>
                            <PdfCell style={{ ...styles.texts, textAlign: 'left'}}>{item['GDS_NM']}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['BEF_GAUGE_ELE'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_GAUGE_ELE'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['GAUGE_ELE'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['NOT_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['ETC_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['SVC_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['SALE_QTY'])}</PdfCell>
                        </PdfRow>
                    ))}
                    <View style={{borderBottom: 1}}></View>
                    <PdfRow>
                        <PdfCell style={{ ...styles.sums, textAlign: 'left'}}>합계</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(gaugeInfoListDataDay, 'BEF_GAUGE_ELE')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(gaugeInfoListDataDay, 'CUR_GAUGE_ELE')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(gaugeInfoListDataDay, 'GAUGE_ELE')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(gaugeInfoListDataDay, 'NOT_QTY')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(gaugeInfoListDataDay, 'ETC_QTY')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(gaugeInfoListDataDay, 'SVC_QTY')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(gaugeInfoListDataDay, 'SALE_QTY')}</PdfCell>
                    </PdfRow>
                </PdfTable>


                <Text style={{...styles.tabletitle}}>4. 일반유 계기외 현황(월별)</Text>
                <PdfTable>
                <PdfRow fixed> 
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'left'}}>상품명</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>전기계기</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>당기 계기</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>회전량</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>공회전</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>기타</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>서비스</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>판매량</PdfCell>
                    </PdfRow>
                    {gaugeInfoListDataMonth && gaugeInfoListDataMonth.map((item, idx) =>(
                        <PdfRow key={idx}>
                            <PdfCell style={{ ...styles.texts, textAlign: 'left'}}>{item['GDS_NM']}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['BEF_GAUGE_ELE'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_GAUGE_ELE'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['GAUGE_ELE'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['NOT_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['ETC_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['SVC_QTY'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['SALE_QTY'])}</PdfCell>
                        </PdfRow>
                    ))}
                    <View style={{borderBottom: 1}}></View>
                    <PdfRow>
                        <PdfCell style={{ ...styles.sums, textAlign: 'left'}}>합계</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(gaugeInfoListDataMonth, 'BEF_GAUGE_ELE')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(gaugeInfoListDataMonth, 'CUR_GAUGE_ELE')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(gaugeInfoListDataMonth, 'GAUGE_ELE')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(gaugeInfoListDataMonth, 'NOT_QTY')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(gaugeInfoListDataMonth, 'ETC_QTY')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(gaugeInfoListDataMonth, 'SVC_QTY')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(gaugeInfoListDataMonth, 'SALE_QTY')}</PdfCell>
                    </PdfRow>
                </PdfTable>

                <Text style={{...styles.tabletitle}}>5. 매출 판매 현황</Text>
                <PdfTable>
                    <PdfRow fixed> 
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'left'}}>구분</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>전기잔액</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>당기발생</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>당기입금</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>당기잔액</PdfCell>
                    </PdfRow>

                    {saleBondList && saleBondList.map((item, idx) => (
                        [<PdfRow key={idx}>
                            <PdfCell style={{ ...styles.texts, textAlign: 'left'}}>{item['PAY_NM']}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['BEF_AMOUNT'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['SALE_AMOUNT'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['IN_AMOUNT'])}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['CUR_AMOUNT'])}</PdfCell>
                        </PdfRow>,
                        getSaleDetailRow(item)]
                    ))}

                    <PdfRow>  
                        <PdfCell style={{ ...styles.sums, textAlign: 'left'}}>매출 합계</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(saleBondList, 'BEF_AMOUNT')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(saleBondList, 'SALE_AMOUNT')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(saleBondList, 'IN_AMOUNT')}</PdfCell>
                        <PdfCell style={{ ...styles.sums, textAlign: 'right'}}>{getTotal(saleBondList, 'CUR_AMOUNT')}</PdfCell>
                    </PdfRow>
                </PdfTable>

                <Text style={{...styles.tabletitle}}>6. 위배 거래처 현황</Text>
                <PdfTable>
                    <PdfRow fixed> 
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'left'}}>거래처명</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header}}>위배일수</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'right'}}>위배금액</PdfCell>
                    </PdfRow>
                    {saleCstViolationData && saleCstViolationData.map((item, idx) =>(
                        <PdfRow key={idx}>
                            <PdfCell style={{ ...styles.texts, textAlign: 'left'}}>{item['CST_NM']}</PdfCell>
                            <PdfCell style={{ ...styles.texts}}>{item['OVER_DATE']}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'right'}}>{numberFormat(item['OVER_AMOUNT'])}</PdfCell>
                        </PdfRow>
                    ))}
                    <View style={{borderBottom: 1}}></View>
 
                </PdfTable>

                <Text style={{...styles.tabletitle}}>7. 기타</Text>
                <PdfTable>
                <PdfRow fixed> 
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'left'}}>사업장명</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'left'}}>내용</PdfCell>
                        <PdfCell cellTop style={{ ...styles.header, textAlign: 'left'}}>값</PdfCell>
                    </PdfRow>
                    {etcData && etcData.map((item, idx) =>(
                        <PdfRow key={idx}>
                            <PdfCell style={{ ...styles.texts, textAlign: 'left'}}>{item['WORK_NM']}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'left'}}>{item['REMARK']}</PdfCell>
                            <PdfCell style={{ ...styles.texts, textAlign: 'left'}}>{item['REMARK_VAL']}</PdfCell>
                        </PdfRow>
                    ))}
                    <View style={{borderBottom: 1}}></View>
                </PdfTable>
                <Text style={{ ...styles.bottom, textAlign: 'left' }} fixed>
                    {workName} / {loginState['EMP_NM']}
                </Text>
                <Text
                    style={{ ...styles.bottom, textAlign: 'right'  }}
                    render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                    fixed
                />
            </PdfPage>
        </Document>
    )
}


export default OilbankSummaryDYCNCPdf;
