import React, { useState, useRef } from 'react';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DataColumn, ComboSearch } from '../../component';
import { makeStyles } from '@material-ui/core';
import { isFieldDiff } from '../../common/Utils';

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },    
}));

const PriceInfo = () => {
    const [condition, setCondition] = useState(null);
    const searchGdsCd = useRef();
    const classes = useStyles();

    return(
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => setCondition(condition)}>
                <SearchItem
                    fieldName='WORK_CD'
                    component={(state, dispatch) => (
                        <ComboBox
                            label='사업장명'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => {
                                searchGdsCd.current={WORK_CD: value}
                                dispatch(value);
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='GDS_CD'
                    component={(state, setState, condition, setField) => {
                        let param = condition['GDS_PARAM'] || null;
                        if (isFieldDiff(['WORK_CD'], condition, param)) {
                            param = { 
                                WORK_CD: condition['WORK_CD'],
                                GDS_TYPE: 'ALL',
                                NOT_TYPE: 0,
                                GDS_TYPE_M: 'ALL',
                                NOT_TYPE_M: 0,
                                GAUGE_TYPE: 'ALL',
                                STA_DATE: '2000-01-01',
                            };
                            setField('GDS_PARAM', param);
                        }
                        return (
                            <ComboSearch
                                label='상품명'
                                selectProc='SLT_GAUGE_ITEM_GOODS_COMBO'
                                selectParam={param}
                                keyField='GDS_CD'
                                valueField='GDS_NM'
                                value={state}
                                onChange={value => setState(value)}
                            />
                        )
                    }}
                />
            </SearchPanel>
            <DataGrid
                title='매출단가'
                table='TB_SALEPRICEINFO'
                selectProc='SLT_SALEPRICEINFO'
                insertProc='INS_SALEPRICEINFO'
                updateProc='UPT_SALEPRICEINFO'
                deleteProc='DLT_SALEPRICEINFO'
                selectParam={condition}
                selectionMode='single'
            >
                <DataColumn
                    fieldName='WORK_CD'
                    title='사업장명'
                    value={row => row['WORK_NM']}
                    defaultValue={condition && condition['WORK_CD']}
                    editable={(state, dispatch) => (
                        <ComboBox
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={value => dispatch(value)}
                            disabled={true}
                        />
                    )}
                />

                <DataColumn
                    fieldName='GDS_CD'
                    title='상품명'
                    value={row => row['GDS_NM']}
                    defaultValue={condition && condition['GDS_CD']}
                    editable={(state, dispatch, rowState, setField) => {
                        let param = rowState['GDS_PARAM'] || null;
                        if (isFieldDiff(['WORK_CD'], rowState, param)) {
                            param = { 
                                WORK_CD: rowState['WORK_CD'],
                                GDS_TYPE: 'ALL',
                                NOT_TYPE: 0,
                                GDS_TYPE_M: 'ALL',
                                NOT_TYPE_M: 0,
                                GAUGE_TYPE: 'ALL',
                                STA_DATE: '2000-01-01',
                            };
                            setField('GDS_PARAM', param);
                        }

                        return (
                            <ComboBox
                                selectProc='SLT_GAUGE_ITEM_GOODS_COMBO'
                                selectParam={param}
                                keyField='GDS_CD'
                                valueField='GDS_NM'
                                value={state}
                                onChange={value => dispatch(value)}
                                disabled={true}
                            />
                        )
                    }}
                />

                <DataColumn
                    fieldName='WORK_NM'
                    visible={false}
                />

                <DataColumn
                    fieldName='GDS_NM'
                    visible={false}
                />
            </DataGrid>
        </div>
    )
}


export default PriceInfo