import React from 'react';
import { Page, StyleSheet} from '@react-pdf/renderer';

const PdfPage = ({ children, ...props }) => {
    return(
        <Page
            size='A4'
            style={styles.page}
            {...props}
        >
            {children}
        </Page>
    )
}
const styles = StyleSheet.create({
    page : {
        fontFamily : 'PdfNormal',
        fontSize : 10,
        padding : 25
    }
}) 
export default PdfPage