import React, { useEffect, useState } from 'react';
import { DataGrid, DataColumn, SearchPanel, SearchItem, ComboBox, EditableList, IconButton } from '../../component';
import { Dialog, DialogTitle, makeStyles } from '@material-ui/core';
import { detPayType } from '../../common/Dictionary';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import { Close } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
}))

const CardInfo = () => {
    const classes = useStyles();
    const [condition, setCondition] = useState(null);

    return(
        <div className={classes.container}>
            <SearchPanel
                onSearch={(condition) => {
                    setCondition(condition);
                }}
            >
                <SearchItem
                    fieldName='WORK_CD'
                    component={(state, dispatch) => (
                        <ComboBox
                            label='사업장명'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
            </SearchPanel> 
            <DataGrid
                title='세부결제수단'
                table='TB_CARDINFO'
                selectProc='SLT_CARDINFO'
                updateProc='UPT_CARDINFO'
                keys={['WORK_CD', 'CARD_CD', 'CST_CD', 'PAY_CD', 'DET_PAY_TYPE']}
                selectParam={condition}
                horizonMode
                selectionMode='single'
            >
                <DataColumn 
                    fieldName='WORK_CD'
                    value={(row) => row['WORK_NM']}
                />
                <DataColumn
                    fieldName='CST_CD'
                    title='연결거래처(회계)'
                    value={row => row['CST_NM']}
                />
                <DataColumn
                    fieldName='PAY_CD'
                    title='결제수단(대분류)'
                    value={row => row['PAY_NM']}
                />
                <DataColumn
                    fieldName='DET_PAY_TYPE'
                    title='세부결제구분'
                    value={(row, value) => detPayType[value]}
                />
                <DataColumn
                    fieldName='EXC_CRD'
                    title='카드사엑셀'
                    value={row => JSON.parse(row['EXC_CRD']).join(', ')}
                    editable={(state, dispatch, rowState, setField) =>
                        <div>
                            <EditableListDialog
                                open={rowState['EDITABLELIST_DIALOG'] || false}
                                onClose={() => setField('EDITABLELIST_DIALOG', false)}
                                value={state}
                                setField={setField}
                                condition={condition}
                            />
                            {JSON.parse(rowState['EXC_CRD']).join(', ')}
                            <IconButton
                                tooltip='수정'
                                icon={<EditIcon />}
                                onClick={() => setField('EDITABLELIST_DIALOG', true)}
                            />
                            {(JSON.parse(rowState['EXC_CRD']).join(', ') || '') !== '' &&
                            <IconButton
                                tooltip='삭제'
                                icon={<Close />}
                                onClick={() => dispatch('[]')}
                            />}
                        </div>
                    }
                />
                <DataColumn
                    fieldName='CST_NM'
                    visible={false}
                />
                <DataColumn
                    fieldName='PAY_NM'
                    visible={false}
                />
                <DataColumn
                    fieldName='WORK_NM'
                    visible={false}
                />
            </DataGrid>
        </div>
    )
}

const useDialogStyle = makeStyles(theme => ({
    dialog: {
        width: 400,
        height: 800,
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    content: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: theme.spacing(2),
        paddingTop: 0,
        height: '100%',
        overflowY: 'auto',
    },
    btnContainer: {
        alignSelf: 'flex-end',
        marginTop: 'auto',
        padding: theme.spacing(2),
    },
    enroll: {
        display: 'flex',
        flexDirection: 'column',
        flex: 2,
        marginBottom: theme.spacing(1),
        height: 0,
    },
}))

const EditableListDialog = ({ open, onClose, value, setField, condition }) => {    
    const [dataSet, setDataSet] = useState();
    const classes = useDialogStyle();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setDataSet({
            data: JSON.parse(value).map(item => ({ EXC_CRD: item })),
            fields: ['EXC_CRD'],
            metaData: {},
        });
    }, [value]);

    return (
        <Dialog
            open={open}
            PaperProps={{ className: classes.dialog }}
            onClose={onClose}
        >
            <div className={classes.title}>
                <DialogTitle>
                    카드사엑셀1
                </DialogTitle>
                <IconButton
                    tooltip='닫기'
                    icon={<CloseIcon />}
                    onClick={onClose}
                />
            </div>
            <div className={classes.content}>
                <EditableList
                    className={classes.enroll}
                    headerLess
                    alwaysAddable
                    dataSet={dataSet}
                    onUpdate={data => {
                        const excCrd = data.map(item => item['EXC_CRD']);
                        if (excCrd.includes(null || undefined)) {
                            enqueueSnackbar('빈값은 저장할 수 없습니다1.');
                            alert('빈값은 저장할 수 없습니다2.');
                        }
                        else{
                        setField('EXC_CRD', JSON.stringify(excCrd));
                        }
                        onClose();
                    }}
                >
                </EditableList>
            </div>
        </Dialog>
    )
}
export default CardInfo