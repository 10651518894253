import React, { useEffect, useState } from 'react';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DataColumn, Tabs, Tab, ComboSearch, DatePicker, IconButton, ProgressPanel } from '../../component';
import { makeStyles, TextField, lighten, Dialog, DialogTitle, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { gdsKind, YNType } from '../../common/Dictionary'
import { useSnackbar } from 'notistack';
import { callProc } from '../../common/DBConnector';
import { FormatLineSpacing, KeyboardArrowDown, KeyboardArrowUp, Check, Close } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },    
    tabContainer : {
        display : 'flex',
    },
    tabbar : {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    content : {
        display: 'flex',
        flex: 1,
        height : 0,
    },
    hide : {
        display: 'none'
    },
}));

const GoodsInfo = () => {
    const [condition, setCondition] = useState(null);
    const [selectTab, setSelectTab] = useState(0);
    const classes = useStyles();
    const [oderbyDialogOpen, setOrderbyDialogOpen] = useState(false);
    const [gdsData, setGdsData] = useState(null);

    const dialogOpen = (data) => {
        setOrderbyDialogOpen(true);
        setGdsData(null);
        setGdsData(data);
    }

    const gridRefresh = () => {
        setCondition({...condition});
    }

    return(
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => setCondition(condition)}>
                <SearchItem
                    fieldName='WORK_CD'
                    component={(state, dispatch) => (
                        <ComboBox
                            label='사업장명'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
            </SearchPanel>
            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab label='계기 상품' />
                    <Tab label='일반 상품' />
                </Tabs>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <GaugeGoods 
                    condition={condition} 
                    dialogOpen={dialogOpen}
                />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <ItemGoods 
                    condition={condition} 
                    dialogOpen={dialogOpen}
                />
            </div>
            <OrderbyDialog
                open={oderbyDialogOpen}
                onClose={() => setOrderbyDialogOpen(false)}
                gdsData={gdsData}
                condition={condition}
                gridRefresh={gridRefresh}
                selectedtab={selectTab}
            />
        </div>
    )
}

const GaugeGoods = ({condition, dialogOpen}) => {
    const { enqueueSnackbar } = useSnackbar();

    const onDelete = async(row) => {
        if(row['GDS_TYPE'] === 1 || 
           row['GDS_TYPE'] === 2 ||
           row['GDS_TYPE'] === 3 ||
           row['GDS_TYPE'] === 4 ||
           row['GDS_TYPE'] === 5){
            enqueueSnackbar('일반유 상품은 삭제가 불가합니다.', { variant: 'error' });
            return false;
        }

        if(row['GDS_TYPE'] !== 1 ||
           row['GDS_TYPE'] !== 2 ||
           row['GDS_TYPE'] !== 3 ||
           row['GDS_TYPE'] !== 4 ||
           row['GDS_TYPE'] !== 5){
            await callProc('DLT_GOODSINFO', row);
        }
    }

    return(
        <DataGrid
            title='계기 상품'
            selectProc='SLT_GOODSINFO'
            insertProc='INS_GOODSINFO'
            updateProc='UPT_GOODSINFO'
            onDelete={row => onDelete(row)}
            selectParam={condition}
            selectionMode='single'
            keys={['GDS_CD']}
            horizonMode
            headerItem={({data}) => 
                <IconButton
                    tooltip='순서등록'
                    icon={<FormatLineSpacing />}
                    onClick={() => dialogOpen(data)}
                />
            }
        >
            <DataColumn
                fieldName='WORK_CD'
                title='사업장명'
                value={row => row['WORK_NM']}
                defaultValue={condition && condition['WORK_CD']}
                editable={(state, dispatch) => (
                    <ComboBox
                        selectProc='SLT_WORKPLACEINFO_AUTH'
                        keyField='WORK_CD'
                        valueField='WORK_NM'
                        value={state}
                        onChange={value => dispatch(value)}
                        disabled
                    />
                )}
            />
            <DataColumn
                fieldName='GDS_NM'
                editable={(state, dispatch) => (
                    <TextField
                        style={{width: 150}}
                        value={state}
                        onChange={e => dispatch(e.target.value)}
                    />
                )}
            />
            <DataColumn
                fieldName='GDS_TYPE'
                value={row => row['CATE_NM']}
                editable={(state, dispatch) => (
                    <ComboSearch
                        style={{width: 150}}
                        selectProc='SLT_CATEGORYINFO'
                        keyField='GDS_TYPE'
                        valueField='CATE_NM'
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />
            <DataColumn
                fieldName='GDS_TYPE_M'
                value={row => row['CATE_NM_M']}
                editable={(state, dispatch) => (
                    <ComboSearch
                        style={{width: 150}}
                        selectProc='SLT_CATEGORY_MIDDLE'
                        keyField='GDS_TYPE_M'
                        valueField='CATE_NM_M'
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />
            <DataColumn
                fieldName='TRN_TYPE'
                value={row => row['TRN_CATE_NM']}
                defaultValue={null}
                editable={(state, dispatch) => (
                    <ComboBox
                        selectProc='SLT_CATEGORY_TRANS'
                        keyField='TRN_TYPE'
                        valueField='TRN_CATE_NM'
                        value={state}
                        onChange={value => dispatch(value)}
                        nullable
                    />
                )}
            />
            <DataColumn
                fieldName='GDS_KIND'
                value={(row, value) => gdsKind[value]}
                editable={(state, dispatch) => (
                    <ComboBox
                        data={gdsKind}
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />
            <DataColumn
                fieldName='OILTRD_GDS_CD'
                editable={(state, dispatch) => (
                    <TextField
                        style={{width: 150}}
                        value={state}
                        onChange={e => dispatch(e.target.value)}
                    />
                )}
            />
            <DataColumn
                fieldName='IN_ACC_CD'
                title='매입 계정과목'
                value={(row) => row['IN_ACC_NM']}
                defaultValue={null}
                editable={(state, dispatch) => (
                    <ComboSearch
                        style={{width: 200}}
                        selectProc='SLT_ACCOUNT_ALL'
                        keyField='ACC_CD'
                        valueField='ACC_NM'
                        value={state}
                        onChange={value => dispatch(value)}
                        nullable
                    />
                )}
            />
            <DataColumn
                fieldName='SALE_ACC_CD'
                title='매출 계정과목'
                value={(row) => row['SALE_ACC_NM']}
                defaultValue={null}
                editable={(state, dispatch) => (
                    <ComboSearch
                        style={{width: 200}}
                        selectProc='SLT_ACCOUNT_ALL'
                        keyField='ACC_CD'
                        valueField='ACC_NM'
                        value={state}
                        onChange={value => dispatch(value)}
                        nullable
                    />
                )}
            />
            <DataColumn
                fieldName='SC_ACC_CD'
                value={(row) => row['SC_ACC_NM']}
                defaultValue={null}
                editable={(state, dispatch) => (
                    <ComboSearch
                        style={{width: 200}}
                        selectProc='SLT_ACCOUNT_ALL'
                        keyField='ACC_CD'
                        valueField='ACC_NM'
                        value={state}
                        onChange={value => dispatch(value)}
                        nullable
                    />
                )}
            />
            <DataColumn
                fieldName='BA_ACC_CD'
                value={(row) => row['BA_ACC_NM']}
                defaultValue={null}
                editable={(state, dispatch) => (
                    <ComboSearch
                        style={{width: 200}}
                        selectProc='SLT_ACCOUNT_ALL'
                        keyField='ACC_CD'
                        valueField='ACC_NM'
                        value={state}
                        onChange={value => dispatch(value)}
                        nullable
                    />
                )}
            />
            <DataColumn
                fieldName='INB_ACC_CD'
                value={(row) => row['INB_ACC_NM']}
                defaultValue={null}
                editable={(state, dispatch) => (
                    <ComboSearch
                        style={{width: 200}}
                        selectProc='SLT_ACCOUNT_ALL'
                        keyField='ACC_CD'
                        valueField='ACC_NM'
                        value={state}
                        onChange={value => dispatch(value)}
                        nullable
                    />
                )}
            />
            <DataColumn
                fieldName='MOVE_ACC_CD'
                value={(row) => row['MOVE_ACC_NM']}
                defaultValue={null}
                editable={(state, dispatch) => (
                    <ComboSearch
                        style={{width: 200}}
                        selectProc='SLT_ACCOUNT_ALL'
                        keyField='ACC_CD'
                        valueField='ACC_NM'
                        value={state}
                        onChange={value => dispatch(value)}
                        nullable
                    />
                )}
            />
            <DataColumn
                fieldName='LST_ACC_CD'
                value={(row) => row['LST_ACC_NM']}
                defaultValue={null}
                editable={(state, dispatch) => (
                    <ComboSearch
                        style={{width: 200}}
                        selectProc='SLT_ACCOUNT_ALL'
                        keyField='ACC_CD'
                        valueField='ACC_NM'
                        value={state}
                        onChange={value => dispatch(value)}
                        nullable
                    />
                )}
            />
            <DataColumn
                fieldName='UNIT'
                title='단위'
                editable={(state, dispatch) => (
                    <TextField
                        style={{width: 100}}
                        value={state}
                        onChange={e => dispatch(e.target.value)}
                    />
                )}
            />
            <DataColumn
                fieldName='STOCK_VIEW'
                value={(row, value) => YNType[value]}
                defaultValue={Object.keys(YNType)[1]}
                editable={(state, dispatch) => (
                    <ComboBox
                        data={YNType}
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />               
            <DataColumn
                fieldName='END_DATE'
                defaultValue={null}
                editable={(state, dispatch) => (
                    <DatePicker
                        value={state}
                        onChange={value => dispatch(value)}
                        nullable
                    />
                )}
            />
            <DataColumn
                fieldName='USE_TYPE'
                visible={false}
            />
            <DataColumn
                fieldName='WORK_NM'
                visible={false}
            />
            <DataColumn
                fieldName='IN_ACC_NM'
                visible={false}
            />
            <DataColumn
                fieldName='SALE_ACC_NM'
                visible={false}
            />
            <DataColumn
                fieldName='SC_ACC_NM'
                visible={false}
            />
            <DataColumn
                fieldName='BA_ACC_NM'
                visible={false}
            />
            <DataColumn
                fieldName='INB_ACC_NM'
                visible={false}
            />
            <DataColumn
                fieldName='MOVE_ACC_NM'
                visible={false}
            />
            <DataColumn
                fieldName='LST_ACC_NM'
                visible={false}
            />
            <DataColumn
                fieldName='CATE_NM'
                visible={false}
            />
            <DataColumn
                fieldName='CATE_NM_M'
                visible={false}
            />
            <DataColumn
                fieldName='TRN_CATE_NM'
                visible={false}
            />
        </DataGrid>
    )
}

const ItemGoods = ({condition, dialogOpen}) => {    
    return(
        <DataGrid
            title='일반 상품'
            selectProc='SLT_ITEMINFO'
            insertProc='INS_ITEMINFO'
            updateProc='UPT_ITEMINFO'
            deleteProc='DLT_ITEMINFO'
            selectParam={condition}
            selectionMode='single'
            horizonMode
            keys={['GDS_CD']}
            headerItem={({data}) => 
                <IconButton
                    tooltip='순서등록'
                    icon={<FormatLineSpacing />}
                    onClick={() => dialogOpen(data)}
                />
            }
        >
            <DataColumn
                fieldName='WORK_CD'
                title='사업장명'
                value={row => row['WORK_NM']}
                defaultValue={condition && condition['WORK_CD']}
                editable={(state, dispatch) => (
                    <ComboBox
                        selectProc='SLT_WORKPLACEINFO_AUTH'
                        keyField='WORK_CD'
                        valueField='WORK_NM'
                        value={state}
                        onChange={value => dispatch(value)}
                        disabled
                    />
                )}
            />
            <DataColumn
                fieldName='GDS_NM'
                editable={(state, dispatch) => (
                    <TextField
                        style={{width: 150}}
                        value={state}
                        onChange={e => dispatch(e.target.value)}
                    />
                )}
            />
            <DataColumn
                fieldName='GDS_TYPE'
                value={row => row['CATE_NM']}
                editable={(state, dispatch) => (
                    <ComboSearch
                        style={{width: 150}}
                        selectProc='SLT_CATEGORYINFO'
                        keyField='GDS_TYPE'
                        valueField='CATE_NM'
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />
            <DataColumn
                fieldName='GDS_TYPE_M'
                value={row => row['CATE_NM_M']}
                editable={(state, dispatch) => (
                    <ComboSearch
                        style={{width: 150}}
                        selectProc='SLT_CATEGORY_MIDDLE'
                        keyField='GDS_TYPE_M'
                        valueField='CATE_NM_M'
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />
            <DataColumn
                fieldName='GDS_KIND'
                value={(row, value) => gdsKind[value]}
                editable={(state, dispatch) => (
                    <ComboBox
                        data={gdsKind}
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />
            <DataColumn
                fieldName='IN_ACC_CD'
                title='매입 계정과목'
                value={(row) => row['IN_ACC_NM']}
                defaultValue={null}
                editable={(state, dispatch) => (
                    <ComboSearch
                        style={{width: 200}}
                        selectProc='SLT_ACCOUNT_ALL'
                        keyField='ACC_CD'
                        valueField='ACC_NM'
                        value={state}
                        onChange={value => dispatch(value)}
                        nullable
                    />
                )}
            />
            <DataColumn
                fieldName='SALE_ACC_CD'
                title='매출 계정과목'
                value={(row) => row['SALE_ACC_NM']}
                defaultValue={null}
                editable={(state, dispatch) => (
                    <ComboSearch
                        style={{width: 200}}
                        selectProc='SLT_ACCOUNT_ALL'
                        keyField='ACC_CD'
                        valueField='ACC_NM'
                        value={state}
                        onChange={value => dispatch(value)}
                        nullable
                    />
                )}
            />
            <DataColumn
                fieldName='SC_ACC_CD'
                value={(row) => row['SC_ACC_NM']}
                defaultValue={null}
                editable={(state, dispatch) => (
                    <ComboSearch
                        style={{width: 200}}
                        selectProc='SLT_ACCOUNT_ALL'
                        keyField='ACC_CD'
                        valueField='ACC_NM'
                        value={state}
                        onChange={value => dispatch(value)}
                        nullable
                    />
                )}
            />
            <DataColumn
                fieldName='BA_ACC_CD'
                value={(row) => row['BA_ACC_NM']}
                defaultValue={null}
                editable={(state, dispatch) => (
                    <ComboSearch
                        style={{width: 200}}
                        selectProc='SLT_ACCOUNT_ALL'
                        keyField='ACC_CD'
                        valueField='ACC_NM'
                        value={state}
                        onChange={value => dispatch(value)}
                        nullable
                    />
                )}
            />
            <DataColumn
                fieldName='INB_ACC_CD'
                value={(row) => row['INB_ACC_NM']}
                defaultValue={null}
                editable={(state, dispatch) => (
                    <ComboSearch
                        style={{width: 200}}
                        selectProc='SLT_ACCOUNT_ALL'
                        keyField='ACC_CD'
                        valueField='ACC_NM'
                        value={state}
                        onChange={value => dispatch(value)}
                        nullable
                    />
                )}
            />
            <DataColumn
                fieldName='MOVE_ACC_CD'
                value={(row) => row['MOVE_ACC_NM']}
                defaultValue={null}
                editable={(state, dispatch) => (
                    <ComboSearch
                        style={{width: 200}}
                        selectProc='SLT_ACCOUNT_ALL'
                        keyField='ACC_CD'
                        valueField='ACC_NM'
                        value={state}
                        onChange={value => dispatch(value)}
                        nullable
                    />
                )}
            />
            <DataColumn
                fieldName='LST_ACC_CD'
                value={(row) => row['LST_ACC_NM']}
                defaultValue={null}
                editable={(state, dispatch) => (
                    <ComboSearch
                        style={{width: 200}}
                        selectProc='SLT_ACCOUNT_ALL'
                        keyField='ACC_CD'
                        valueField='ACC_NM'
                        value={state}
                        onChange={value => dispatch(value)}
                        nullable
                    />
                )}
            />
            <DataColumn
                fieldName='UNIT'
                title='단위'
                editable={(state, dispatch) => (
                    <TextField
                        style={{width: 100}}
                        value={state}
                        onChange={e => dispatch(e.target.value)}
                    />
                )}
            />
            <DataColumn
                fieldName='STOCK_VIEW'
                value={(row, value) => YNType[value]}
                defaultValue={Object.keys(YNType)[1]}
                editable={(state, dispatch) => (
                    <ComboBox
                        data={YNType}
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />
            <DataColumn
                fieldName='SALECOST_TYPE'
                value={(row, value) => YNType[value]}
                defaultValue={Object.keys(YNType)[1]}
                editable={(state, dispatch) => (
                    <ComboBox
                        data={YNType}
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />            
            <DataColumn
                fieldName='REAL_STOCK_MNG'
                value={(row, value) => YNType[value]}
                defaultValue={Object.keys(YNType)[1]}
                editable={(state, dispatch) => (
                    <ComboBox
                        data={YNType}
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />
            <DataColumn
                fieldName='END_DATE'
                defaultValue={null}
                editable={(state, dispatch) => (
                    <DatePicker
                        value={state}
                        onChange={value => dispatch(value)}
                        nullable
                    />
                )}
            />
            <DataColumn
                fieldName='USE_TYPE'
                visible={false}
            />
            <DataColumn
                fieldName='WORK_NM'
                visible={false}
            />
            <DataColumn
                fieldName='IN_ACC_NM'
                visible={false}
            />
            <DataColumn
                fieldName='SALE_ACC_NM'
                visible={false}
            />
            <DataColumn
                fieldName='SC_ACC_NM'
                visible={false}
            />
            <DataColumn
                fieldName='BA_ACC_NM'
                visible={false}
            />
            <DataColumn
                fieldName='INB_ACC_NM'
                visible={false}
            />
            <DataColumn
                fieldName='MOVE_ACC_NM'
                visible={false}
            />
            <DataColumn
                fieldName='LST_ACC_NM'
                visible={false}
            />
            <DataColumn
                fieldName='CATE_NM'
                visible={false}
            />
            <DataColumn
                fieldName='CATE_NM_M'
                visible={false}
            />
        </DataGrid>
    )
}

const orderbyDialogStyle = makeStyles((theme) => ({
    dialogPaper: {
        maxWidth: 'none',
        width: 700,
        height: 700,
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    actionButton: {
        display: 'flex',
    },
    content: {
        overflowY: 'auto',
    },
    itemContainer: {
        cursor: 'default',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    selection: {
        backgroundColor: lighten(theme.palette.primary.main, 0.85),
    },
}))

const OrderbyDialog = ({open, onClose, gdsData, condition, gridRefresh, selectedtab}) => {
    const [data, setData] = useState();
    const [selected, setSelected] = useState();
    const classes = orderbyDialogStyle();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => setData(gdsData), [gdsData]);

    const onUpClick = () => {
        const index = data.indexOf(selected);
        data.splice(index, 1);
        data.splice(index - 1, 0, selected);
        const result = data.map((item, order) => ({
            ...item,
            SEQ: order + 1
        }));
        setData(result);
        setSelected(result.find(item => item['GDS_CD'] === selected['GDS_CD']));
    }

    const onDownClick = () => {
        const index = data.indexOf(selected);
        data.splice(index, 1);
        data.splice(index + 1, 0, selected);
        const result = data.map((item, order) => ({
            ...item,
            SEQ: order + 1
        }));
        setData(result);
        setSelected(result.find(item => item['GDS_CD'] === selected['GDS_CD']));
    }

    const onSave = async() => {
        let result ='';
        if(selectedtab === 0){
            result = await callProc('UPT_GOODSINFO_SEQ', {...condition, JSON: JSON.stringify(data)});
        }else{
            result = await callProc('UPT_ITEMINFO_SEQ', {...condition, JSON: JSON.stringify(data)});
        }
        if(!result.err){
            enqueueSnackbar('저장이 완료되었습니다.');
            onClose();
            gridRefresh();
        }
    }

    return (
        <Dialog
            open={open}
            PaperProps={{className: classes.dialogPaper}}
        >
            <div className={classes.dialogTitle}>
                <DialogTitle>순서정렬</DialogTitle>
                <div className={classes.actionButton}>
                    <IconButton
                        tooltip='확인'
                        icon={<Check />}
                        onClick={onSave}
                    />
                    <IconButton
                        tooltip='위로 이동'
                        icon={<KeyboardArrowUp />}
                        disabled={!data || !selected || data.indexOf(selected) === 0}
                        onClick={onUpClick}
                    />
                    <IconButton
                        tooltip='아래로 이동'
                        icon={<KeyboardArrowDown />}
                        disabled={!data || !selected || data.indexOf(selected) === data.length - 1}
                        onClick={onDownClick}
                    />
                </div>
                <IconButton
                    tooltip='닫기'
                    icon={<Close />}
                    onClick={onClose}
                />
            </div>
            <div className={classes.content}>
                {!data ? <ProgressPanel /> :
                data.map((item, idx) => (
                    <Typography
                        onClick={() => setSelected(item)}
                        className={clsx(classes.itemContainer, {
                            [classes.selection]: item['GDS_CD'] === (selected && selected['GDS_CD'])
                        })}
                    >
                        {`${item['GDS_CD']} : ${item['GDS_NM']} (${gdsKind[item['GDS_KIND']]}) ${item['END_DATE'] ? `※종료일 : ${item['END_DATE']}※` : ``}`}
                    </Typography>
                ))}
            </div>
        </Dialog>
    )
}

export default GoodsInfo;