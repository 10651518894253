import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './index.css';
import moment from 'moment';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { Font } from '@react-pdf/renderer';
import { SnackbarProvider } from 'notistack';
import NotoSansCJKkr from './fonts/NotoSansCJKkr-Regular.otf';
import KaiGenGothicKR from './fonts/KaiGenGothicKR-Regular.ttf';
import KaiGenGothicKRBold from './fonts/KaiGenGothicKR-Bold.ttf';
import Login from './containers/Login';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/ko';
import { App as MobileApp, Login as MobileLogin } from './mobile'
import { pdfjs } from 'react-pdf';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#253053',
        },
        secondary: {
            main: '#fefeff',
        },
        background: {
            default: '#f4f5fd',
            dark: '#a3a9d9',
        },
        text: {
            primary: '#000',
        },
        action: {
            selected: '#666669'
        },
        divider: '#DDDDDD',
    },
    typography: {
        fontFamily: 'NotoSansCJKkr',
        body1: { fontFamily: 'NotoSansCJKkr' }
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [NotoSansCJKkr],
            },
        },
    },
});

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
Font.register({
    family : 'PdfNormal',
    src : KaiGenGothicKR,
})
Font.register({
    family : 'PdfBold',
    src : KaiGenGothicKRBold,
})
Font.registerHyphenationCallback(word => {
    const middle = Math.floor(word.length / 2);
    const parts = word.length === 1 ? [word] : [word.substr(0, middle), word.substr(middle)];
    return parts;
});

moment.locale('ko');

ReactDOM.render(
    <Provider store={store}>
    <ThemeProvider theme={theme}>
    <SnackbarProvider
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        ariaAttributes={{ style: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.text.primary,
            whiteSpace: 'pre-line',
        }}}
        maxSnack={5}
        dense
    >
    <MuiPickersUtilsProvider utils={MomentUtils} locale='ko'>
        <Router>
            <Route exact path='/' component={App} />
            <Route exact path='/login' component={Login} />

            <Route path='/m' component={MobileApp}/>
            <Route exact path='/login/m' component={MobileLogin} />
        </Router>
    </MuiPickersUtilsProvider>
    </SnackbarProvider>
    </ThemeProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
