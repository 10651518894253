import { makeStyles, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ComboBox, DatePicker, SearchItem, SearchPanel, DataColumn, DataGrid, Tab, Tabs, IconButton } from '../../component';
import moment from 'moment';
import clsx from 'clsx';
import * as xlsx from 'xlsx';
import { weekOfYear } from '../../common/Utils';
import { useSnackbar } from 'notistack';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { callProc } from '../../common/DBConnector';
import iconv from 'iconv-lite';

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    tabContainer: {
        display: 'flex',
    },
    subTabContainer : {
        backgroundColor: theme.palette.secondary.main,
        boxShadow: theme.shadows[4],
        marginBottom: 8,
    },
    tabbar: {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
    hide: {
        display: 'none'
    },
}))

const AgencyTradeReport = () => {
    const classes = useStyles();
    const [condition, setCondition] = useState();
    const [selectTab, setSelectTab] = useState(0);
    const [outbound, setOutbound] = useState();
    const [total, setTotal] = useState();
    const [inbound, setInbound] = useState();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const fetchData = async() => {
            setTotal(null);
            setInbound(null);
            setOutbound(null);

            const total = (await callProc('SLT_AGENCY_TRADEREPORT_TOTAL', condition));
            const inbound = (await callProc('SLT_AGENCY_TRADEREPORT_IN', condition));
            const outbound = (await callProc('SLT_AGENCY_TRADEREPORT_OUT', condition));

            setTotal(total);
            setInbound(inbound);
            setOutbound(outbound);
        };
        condition && fetchData();
    }, [condition])

    const createCell = (value) => ({
        v: value != null ? value.toString() : '',
        w: value != null ? value.toString() : '',
        t: 's',
        z: '@',
    });

    const onClickDown = () => {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", "/수급보고신고양식.xls");
        xhr.responseType = "blob";
        xhr.onload = () => {
            const reader = new FileReader();
            reader.onload = async(evt) => {
                const totalData = total.data;
                const inboundData = inbound.data;
                const outboundData = outbound.data;
                const workbook = xlsx.read(evt.target.result, { type: 'binary', cellStyles: true,  });
                const sheets = workbook.Sheets;
                const sheet = sheets[workbook.SheetNames[0]];
                
                sheet['B18'] = createCell(condition['STA_DATE'].replace(/-/g, ''));
                sheet['B19'] = createCell(condition['END_DATE'].replace(/-/g, ''));
                for (let i = 0; i < totalData.length; i++) {
                    const row = totalData[i];
                    sheet['A' + (23 + i)] = createCell(row['OILTRD_GDS_CD']);
                    sheet['B' + (23 + i)] = createCell(row['TRD_TYPE']);
                    sheet['C' + (23 + i)] = createCell(row['BEF_STOCK_QTY']);
                    sheet['D' + (23 + i)] = createCell(row['CUR_IN_QTY']);
                    sheet['E' + (23 + i)] = createCell(row['CUR_SALE_QTY']);
                    sheet['F' + (23 + i)] = createCell(row['CUR_ADJ_QTY']);
                    sheet['G' + (23 + i)] = createCell(row['SELF_QTY']);
                    sheet['H' + (23 + i)] = createCell(row['CUR_STOCK_QTY']);
                }
                for (let i = 0; i < inboundData.length; i++) {
                    const row = inboundData[i];
                    sheet['A' + (36 + i)] = createCell(row['OILTRD_GDS_CD']);
                    sheet['B' + (36 + i)] = createCell(row['TRD_TYPE']);
                    sheet['C' + (36 + i)] = createCell(row['BIZ_DATE']);
                    sheet['D' + (36 + i)] = createCell(row['CST_NM']);
                    sheet['E' + (36 + i)] = createCell(row['WORK_BIZNO']);
                    sheet['F' + (36 + i)] = createCell(row['TRD_ADDR']);
                    sheet['G' + (36 + i)] = createCell(row['TRD_COND']);
                    sheet['H' + (36 + i)] = createCell(row['TRD_TAX']);
                    sheet['I' + (36 + i)] = createCell(row['CUR_IN_QTY']);
                    sheet['J' + (36 + i)] = createCell(row['TRD_TRANS']);
                }
                for (let i = 0; i < outboundData.length; i++) {
                    const row = outboundData[i];
                    sheet['A' + (85 + i)] = createCell(row['OILTRD_GDS_CD']);
                    sheet['B' + (85 + i)] = createCell(row['TRD_TYPE']);
                    sheet['C' + (85 + i)] = createCell(row['BIZ_DATE']);
                    sheet['D' + (85 + i)] = createCell(row['CST_NM']);
                    sheet['E' + (85 + i)] = createCell(row['WORK_BIZNO']);
                    sheet['F' + (85 + i)] = createCell(row['TRD_ADDR']);
                    sheet['G' + (85 + i)] = createCell(row['TRD_COND']);
                    sheet['H' + (85 + i)] = createCell(row['TRD_TAX']);
                    sheet['I' + (85 + i)] = createCell(row['CUR_SALE_QTY']);
                    sheet['J' + (85 + i)] = createCell(row['TRD_TRANS']);
                }
                const csv = xlsx.utils.sheet_to_csv(sheets[workbook.SheetNames[0]]);
                const fileName = '대리점수급보고';
                const file = new Blob([iconv.encode(csv, 'euc-kr')]);
                const a = document.createElement('a');
                a.download = fileName + '.csv';
                a.href = window.URL.createObjectURL(file);
                a.click();
            };
            reader.readAsBinaryString(xhr.response);
        }
        xhr.send();
    }

    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => {
                if (((weekOfYear(condition['STA_DATE']) === 53) && (weekOfYear(condition['END_DATE']) === 1)) ||
                    weekOfYear(condition['STA_DATE']) === weekOfYear(condition['END_DATE'])) {
                    setCondition(condition);
                }else{
                    enqueueSnackbar('주차 계산 오류 : 시작일자 // 종료일자 를 확인하세요', {variant: 'error'});
                }
            }}>
                <SearchItem
                    fieldName='WORK_CD'
                    component={(state, setState) => (
                        <ComboBox
                            label='사업장명'
                            selectProc='SLT_WORKPLACEINFO_AUTH_AGENCY'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                dispatch('WEEK_OF_YEAR', weekOfYear(value));
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='WEEK_OF_YEAR'
                    component={(state) => (
                        <TextField
                            label='주차'
                            value={state}
                            disabled
                        />
                    )}
                />
            </SearchPanel>
            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab label='총괄' />
                    <Tab label='입하' />
                    <Tab label='출하' />
                </Tabs>
                <div className={classes.subTabContainer}>
                    <IconButton
                        tooltip='보고서 내려받기'
                        icon={<AttachFileIcon />}
                        disabled={!total && !outbound && !inbound}
                        onClick={onClickDown}
                    />
                </div>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <Total data={total} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <Inbound data={inbound} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 2 })}>
                <Outbound data={outbound} />
            </div>
        </div>
    )
}

const Total = ({data}) => {
    return (
        <DataGrid
            dataSet={data}
            title='총괄'
            horizonMode
        >
            <DataColumn fieldName='OILTRD_GDS_CD' title='제품' />
            <DataColumn fieldName='TRD_TYPE' title='구분' />
            <DataColumn fieldName='BEF_STOCK_QTY' title='주초재고량(kl)' />
            <DataColumn fieldName='CUR_IN_QTY' title='입하량(kl)' />
            <DataColumn fieldName='CUR_SALE_QTY' title='출하량(kl)' />
            <DataColumn fieldName='CUR_ADJ_QTY' title='증감량(kl)' />
            <DataColumn fieldName='SELF_QTY' title='자가소모량(kl)' />
            <DataColumn fieldName='CUR_STOCK_QTY' title='주말재고량(kl)' />
        </DataGrid>
    )
}

const Inbound = ({data}) => {
    return (
        <DataGrid
            dataSet={data}
            title='입하'
            horizonMode
        >
            <DataColumn fieldName='OILTRD_GDS_CD' title='제품' />
            <DataColumn fieldName='TRD_TYPE' title='구분' />
            <DataColumn fieldName='BIZ_DATE' title='거래일자' />
            <DataColumn fieldName='CST_NM' title='입하거래처명' />
            <DataColumn fieldName='WORK_BIZNO' title='입하거래처사업자번호' />
            <DataColumn fieldName='TRD_ADDR' title='시군코드' />
            <DataColumn fieldName='TRD_COND' title='업태코드' />
            <DataColumn fieldName='TRD_TAX' title='면세코드' />
            <DataColumn fieldName='CUR_IN_QTY' title='입하량(kl)' />
            <DataColumn fieldName='TRD_TRANS' title='수송수단코드' />
        </DataGrid>
    )
}

const Outbound = ({data}) => {
    return (
        <DataGrid
            dataSet={data}
            title='출하'
            horizonMode
        >
            <DataColumn fieldName='OILTRD_GDS_CD' title='제품' />
            <DataColumn fieldName='TRD_TYPE' title='구분' />
            <DataColumn fieldName='BIZ_DATE' title='거래일자' />
            <DataColumn fieldName='CST_NM' title='출하거래처명' />
            <DataColumn fieldName='WORK_BIZNO' title='출하거래처사업자번호' />
            <DataColumn fieldName='TRD_ADDR' title='시군코드' />
            <DataColumn fieldName='TRD_COND' title='업태코드' />
            <DataColumn fieldName='TRD_TAX' title='면세코드' />
            <DataColumn fieldName='CUR_SALE_QTY' title='출하량(kl)' />
            <DataColumn fieldName='TRD_TRANS' title='수송수단코드' />
        </DataGrid>
    )
}


export default AgencyTradeReport;
