import React, { useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DatePicker, Tabs, Tab, DataColumn } from '../../component';
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    tabContainer : {
        display : 'flex',
    },
    tabbar : {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    content : {
        display: 'flex',
        flex: 1,
        height : 0,
    },
    hide : {
        display: 'none'
    },
}))

const InSaleList = () => {
    const [condition, setCondition] = useState();
    const [selectTab, setSelectTab] = useState(0);
    const classes = useStyle();

    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => {
                sessionStorage['WORK_CD'] = condition['WORK_CD'];
                setCondition(condition);
                }}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, setState, condition, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                            }}
                            onDataLoad={jsonData => ({ALL: '전체', ...jsonData})}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
            </SearchPanel>
            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab label='매입' />
                    <Tab label='매출' />
                </Tabs>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <InboundAll condition={condition} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <SaleAll condition={condition} />
            </div>
        </div>
    )
}

const inBoundStyles = makeStyles(theme => ({
    total: {
        backgroundColor: '#a3a9d9 !important'
    }
}));

const InboundAll = ({condition}) => {
    const classes = inBoundStyles();
    const getCellProps = (row, fieldName) => {
        if(String(row['SUM_TYPE']) !== '1')
            return { className: classes.total }
    };

    return (
        <DataGrid
            title='매입'
            selectProc='SLT_INBOUND_ALL'
            selectParam={condition}
            horizonMode
            virtualized
            cellProps={getCellProps}
            excelDownload={{fileName: '매입/매출 상세내역(매입).xlsx', sheetName: '매입'}}
            selectionMode='single'
            filterable
            sortable
        >
            <DataColumn fieldName='BUY_CST_NM' title='거래처' />
            <DataColumn fieldName='GS_SHIP_CD' title='출하처코드' />
            <DataColumn fieldName='SHIP_TRN_NM' title='출하처' />
            <DataColumn fieldName='GS_TSP_CD' title='인도처코드' />
            <DataColumn fieldName='DEST_TRN_NM' title='인도처' />
            <DataColumn fieldName='ORI_PRICE' title='구매단가' />
            <DataColumn fieldName='TRN_PRICE' title='수송단가' />
            <DataColumn fieldName='PRICE' title='구매적용단가' />
            <DataColumn fieldName='SEQ' visible={false} />
            <DataColumn fieldName='SEQ_TYPE' visible={false} />
            <DataColumn fieldName='SUM_TYPE' visible={false} />
            <DataColumn fieldName='BUY_CST_CD' visible={false} />
            <DataColumn fieldName='SHIP_TRN_CD' visible={false} />
            <DataColumn fieldName='DEST_TRN_CD' visible={false} />
            <DataColumn fieldName='GDS_CD' visible={false} />
        </DataGrid>
    )
}

const saleStyles = makeStyles(theme => ({
    total: {
        backgroundColor: '#a3a9d9 !important'
    }
}));
const SaleAll = ({condition}) => {
    const classes = saleStyles();
    const getCellProps = (row, fieldName) => {
        if(String(row['SUM_TYPE']) !== '1')
            return { className: classes.total }
    };

    return (
        <DataGrid
            title='매출'
            selectProc='SLT_SALE_ALL'
            selectParam={condition}
            horizonMode
            virtualized
            cellProps={getCellProps}
            excelDownload={{fileName: '매입/매출 상세내역(매출).xlsx', sheetName: '매출'}}
            selectionMode='single'
            filterable
            sortable
        >
            <DataColumn fieldName='GS_SHIP_CD' title='출하처코드' />
            <DataColumn fieldName='SHIP_TRN_NM' title='출하처' />
            <DataColumn fieldName='GS_TSP_CD' title='인도처코드' />
            <DataColumn fieldName='DEST_TRN_NM' title='인도처' />
            <DataColumn fieldName='PRICE' title='매출적용단가' />
            <DataColumn fieldName='WORK_CD' visible={false} />
            <DataColumn fieldName='SEQ' visible={false} />
            <DataColumn fieldName='SEQ_TYPE' visible={false} />
            <DataColumn fieldName='SUM_TYPE' visible={false} />
            <DataColumn fieldName='CST_CD' visible={false} />
            <DataColumn fieldName='SHIP_TRN_CD' visible={false} />
            <DataColumn fieldName='DEST_TRN_CD' visible={false} />
            <DataColumn fieldName='GDS_CD' visible={false} />
            <DataColumn fieldName='BUY_CST_NM' title='거래처' visible={false} />
            <DataColumn fieldName='ORI_PRICE' title='구매단가' visible={false} />
            <DataColumn fieldName='TRN_PRICE' title='수송단가' visible={false} />
        </DataGrid>
    )
}

export default InSaleList;