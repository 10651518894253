import React, { useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { DataGrid, SearchItem, SearchPanel, ComboBox, ProgressPanel, DatePicker, Tabs, Tab, DataColumn, PdfButton } from '../../component';
import { makeStyles } from '@material-ui/core';
import { callProc } from '../../common/DBConnector';
import { DailyReportPdf } from '../../reports';

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    content : {
        display: 'flex',
        flex: 1,
        height : 0,
    },
    tabbar : {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    hide : {
        display: 'none'
    },
    tabContainer : {
        display : 'flex',
    },
    subTabContainer : {
        backgroundColor: theme.palette.secondary.main,
        boxShadow: theme.shadows[4],
        marginBottom: 8,
    },
    dialog : {
        maxWidth : 'none',
        width : '60%',
        height : '80%'
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    pdf : {
        flex : 1
    }
}))
            
const DailyReport = () => {
    const [condition, _setCondition] = useState(null);
    const [workData, setWorkData] = useState();
    const [selectTab, setSelectTab] = useState(0);
    const [printDttm, setPrintDttm] = useState();

    const [gaugeData, setGaugeData] = useState();
    const [gdsSaleData, setGdsSaleData] = useState();
    const [paySaleData, setPaySaleData] = useState();
    const [supplyData, setSupplyData] = useState();
    const [payMentSumData, setPayMentSumData] = useState();

    const setCondition = (value) => {
        setGaugeData(null);
        setGdsSaleData(null);
        setPaySaleData(null);
        setSupplyData(null);
        setPayMentSumData(null);

        _setCondition(value);
    }



    useEffect(() => {
        const fetchData = async () => {
            const workData = await callProc('SLT_WORKPLACEINFO_AUTH');

            setWorkData(workData.data.reduce((result, item) => ({
                ...result,
                [item['WORK_CD']]: item['WORK_NM']
            }), {}))
        }
        fetchData();
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            setPrintDttm((await callProc('SYS_DATETIME')).data[0]['DTTM']);

            setGaugeData(await callProc('SLT_GAUGEINFO_TANK_SUM', condition));
            setGdsSaleData(await callProc('SLT_GAUGEINFO_GOODSSUM', condition));
            setPayMentSumData(await callProc('SLT_SALEINFO_PAYMENT_SUM',condition));
            setPaySaleData(await callProc('SLT_SALEINFO_PAYMENT', condition));
            setSupplyData(await callProc('SLT_SUPPLY_DAILY', condition));
        }
        condition && fetchData();
    }, [condition])

    const classes = useStyles();

    const pdfComponent = useMemo(() => (
        <PdfButton title='판매일보'>
            {condition &&
            workData &&
            printDttm &&
            gaugeData &&
            gdsSaleData&&
            paySaleData &&
            supplyData &&
            payMentSumData &&
            <DailyReportPdf
                workCd={condition['WORK_CD']}
                workName={workData[condition['WORK_CD']]}
                date={condition['BIZ_DATE']}
                printDttm={printDttm}
                gaugeData={gaugeData.data}
                gdsSaleData={gdsSaleData.data}
                paySaleData={paySaleData.data}
                supplyData={supplyData.data}
                payMentSumData={payMentSumData.data}
            />}
        </PdfButton>
    ), [gaugeData, gdsSaleData, paySaleData, supplyData, payMentSumData, condition, workData, printDttm]);


    return (
        !workData ? <ProgressPanel /> :
        <div className={classes.container}>
            

            <SearchPanel onSearch={(condition) => setCondition(condition)}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={Object.keys(workData)[0]}
                    component={(state, dispatch) => (
                        <ComboBox
                            label='사업장명'
                            data={workData}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='BIZ_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, dispatch) => (
                        <DatePicker
                            label='날짜'
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
            </SearchPanel>
            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab label='판매' />
                    <Tab label='계기' />
                    <Tab label='점두가' />
                    <Tab label='수불현황' />
                    <Tab label='채권' />
                </Tabs>
                <div className={classes.subTabContainer}>
                    {pdfComponent}
                </div>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <Sale gdsSaleData={gdsSaleData} paySaleData={paySaleData} payMentSumData={payMentSumData}/>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <Gauge gaugeData={gaugeData}/>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 2 })}>
                <StandPrice condition={condition} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 3 })}>
                <Supply supplyData={supplyData} />
            </div>
        </div>
    )
}

const Sale = ({ gdsSaleData, paySaleData, payMentSumData}) => (
    <>
    <div
        style={{ display: 'flex', flexDirection:'column', flex: 1, marginRight: 10 }}
    >
        <DataGrid
            title='상품별 판매 집계'
            dataSet={gdsSaleData}
            style={{ flex: 1, marginBottom: 10 }}
        >
            <DataColumn fieldName='TOTAL_OUT_QTY' title='총출하' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='SALE_QTY' title='판매수량' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='GAUGE_QTY' title='계기출하' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='GAUGE_EXT_QTY' title='계기외출하' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='TEST_QTY' title='검량' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='MOVE_QTY' title='이관' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='HOME_OUT_QTY' title='홈로리' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='AMOUNT' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='GROUP_TOTAL' visible={false} />
        </DataGrid>
        <DataGrid
            title='결제유형별 판매 집계'
            dataSet={payMentSumData}
            style={{ flex: 1, marginTop: 10 }}
        >
            <DataColumn fieldName='QTY' total={(values) => values.reduce((result, value) => result + value, 0)} />
            <DataColumn fieldName='SPLY_AMT' visible={false} />
            <DataColumn fieldName='VAT' visible={false} />
            <DataColumn fieldName='AMOUNT' total={(values) => values.reduce((result, value) => result + value, 0)} />
        </DataGrid>
    </div>
    <DataGrid
        title='결제유형별 판매'
        dataSet={paySaleData}
        totalGroup={['GDS_NM']}
        style={{marginLeft : 10, flex : 1}}
    >
        <DataColumn fieldName='GDS_NM' mergeField='GDS_NM'/>
        <DataColumn fieldName='QTY' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='AMOUNT' total={(values) => values.reduce((result, value) => result + value, 0)} />
    </DataGrid>
    </>
)

const Gauge = ({ gaugeData }) => (
    <DataGrid
        title='계기'
        totalGroup={['TNK_NO']}
        dataSet={gaugeData}
    >
        <DataColumn fieldName='TNK_NO' cellStyle={{ textAlign : 'center' }} />
        <DataColumn fieldName='GDS_NM' mergeField='GDS_NM' />
        <DataColumn fieldName='GAUGE_ELE' title='수량' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='SALE_QTY' title='판매수량' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='TEST_QTY' title='검량' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='MOVE_QTY' title='이관' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='HOME_QTY' title='홈로리' total={(values) => values.reduce((result, value) => result + value, 0)} />
    </DataGrid>
)

const StandPrice = ({ condition }) => (
    <DataGrid
        title='점두가'
        selectProc='SLT_SALEINFO_STANDPRICE_DAY'
        selectParam={condition}
    >
        <DataColumn fieldName='GDS_NM' />
        <DataColumn fieldName='BEF_STAND_PRICE' title='전일 점두가' />
        <DataColumn fieldName='STAND_PRICE' title='당일 점두가' />
    </DataGrid>
)

const Supply = ({ supplyData }) => (
    <DataGrid
        title='수불현황'
        dataSet={supplyData}
    >
        <DataColumn fieldName='BEF_STOCK_QTY' title='전일재고' />
        <DataColumn fieldName='SALE_QTY' title='판매수량' />
        <DataColumn fieldName='STOCK_QTY' title='금일재고' />
        <DataColumn fieldName='OUT_QTY' title='이관출고' />
    </DataGrid>
)

export default DailyReport;