import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { SearchPanel, SearchItem, ComboBox, DataGrid, DataColumn, NumberField, ComboSearch } from '../../component';
import { pricType } from '../../common/Dictionary';

const useStyle = makeStyles(() => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
}))

const InPriceStandard = () => {
    const classes = useStyle();
    const [condition, setCondition] = useState(null);

    return(
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => setCondition(condition)}>
                <SearchItem
                    fieldName='IN_CST_CD'
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='매입처'
                            selectProc='SLT_INCUSTOMER'
                            keyField='IN_CST_CD'
                            valueField='IN_CST_NM'
                            value={state}
                            onChange={(value) => {
                                dispatch(value);
                            }}
                        />
                    )}
                />

                <SearchItem
                    fieldName='GDS_CD'
                    component={(state, dispatch) => (
                        <ComboSearch 
                            label='상품명'
                            selectProc='SLT_GOODSINFO_OIL_GROUP_USE'
                            keyField='GDS_CD'
                            valueField='GDS_NM'
                            value={state}
                            onChange={(value) => {
                                dispatch(value);
                            }}
                        />
                    )}
                />

            </SearchPanel>

            <DataGrid
                title='매입기준단가'
                selectProc='SLT_INPRICE_MAIN'
                insertProc='INS_INPRICE_MAIN'
                deleteProc='DLT_INPRICE_MAIN'
                selectParam={condition}
                selectionMode='single'
                horizonMode
            >

                
                <DataColumn
                    fieldName='IN_PRICE_CD'
                    value={row => row['IN_PRICE_CD']}
                    defaultValue={condition && condition['IN_PRICE_CD']}
                    visible={false}
                />

                <DataColumn
                    fieldName='IN_CST_CD'
                    title='매입처명'
                    value={row => row['IN_CST_NM']}
                    defaultValue={condition && condition['IN_CST_CD']}
                    editable={(state, dispatch) => (
                        <ComboBox
                            selectProc='SLT_INCUSTOMER'
                            selectParam={condition}
                            keyField='IN_CST_CD'
                            valueField='IN_CST_NM'
                            value={state}
                            onChange={value => dispatch(value)}
                            disabled
                        />
                    )}
                />

                <DataColumn
                    fieldName='GDS_CD'
                    title='상품명'
                    value={row => row['GDS_NM']}
                    defaultValue={condition && condition['GDS_CD']}
                    editable={(state, dispatch) => (
                        <ComboBox
                            selectProc='SLT_GOODSINFO_OIL_GROUP_USE'
                            keyField='GDS_CD'
                            valueField='GDS_NM'
                            value={state}
                            onChange={value => dispatch(value)}
                            disabled={true}
                        />
                    )}
                />

                <DataColumn
                    fieldName='PRICE_TYPE'
                    value={(row, value) => pricType[value]}
                />

                <DataColumn
                    fieldName='PRICE'
                    editable={(state, dispatch) => (
                        <NumberField
                            style={{width: 150}}
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                /> 

                <DataColumn
                    fieldName='END_DTTM'
                    title='종료일시'
                    defaultValue={'9999-12-31 23:59:59'}
                    visible={false}
                />


                <DataColumn
                    fieldName='IN_CST_NM'
                    visible={false}
                />
                <DataColumn
                    fieldName='GDS_NM'
                    visible={false}
                />
                <DataColumn
                    fieldName='SEQ'
                    visible={false}
                />

            </DataGrid>

        </div>
    )
}

export default InPriceStandard