// import React, { useState } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import { EditableField, EditableList, NumberField, PaperTitle, TextField } from '../../component';
// import { Button, Paper } from '@material-ui/core';

// const useStyles = makeStyles((theme) => ({
//     container : {
//         display: 'flex',
//         flexDirection: 'column',
//         flex: 1,
//     },
//     topContainer : {
//         display: 'flex',
//         marginBottom: theme.spacing(1),
//     },
//     leftGrid : {
//         marginRight: theme.spacing(1),
//     },
//     rightGrid : {
//         marginLeft: theme.spacing(1),
//     }
// }))


// const DefaultHome = () => {
//     const [condition, setCondition] = useState({ WORK_CD: 'B0001' });
//     const classes = useStyles();

//     const sumCard = data => (
//         <NumberField
//             label='합계'
//             value={data.reduce((result, item) => result + parseInt(item['IN_BANKBOOK'] || 0), 0)}
//             disabled
//         />
//     )
//     return (
//         <div className={classes.container}>
//             <Button onClick={() => setCondition({})} children='테스트' />
//             <EditableList
//                 title='테스트'
//                 selectProc='SLT_WORKPLACEINFO'
//                 selectParam={condition}
//                 bottomSticky={sumCard}
//                 onUpdate={async() => new Promise((resolve) => setTimeout(() => resolve(), 2000))}
//                 alwaysAddable
//                 defaultValue={{WORK_CD: 'B0001'}}
//             >
//                 <EditableField
//                     fieldName='WORK_NM'
//                     position='WORK_CD'
//                     title='테스트'
//                 />
//                 {/* <EditableField
//                     fieldName='WORK_CD'
//                     component={() => 
//                         'TEST'
//                     }
//                 /> */}
//             </EditableList>
//         </div>
//     )
// }

// export default DefaultHome;


import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container : {
        flex: 1,
        height: 0
    },
    topContainer : {
        display: 'flex',
        marginBottom: theme.spacing(1),
    },
    leftGrid : {
        marginRight: theme.spacing(1),
    },
    rightGrid : {
        marginLeft: theme.spacing(1),
    }
}))


const DefaultHome = () => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
        </div>
    )
}

export default DefaultHome;