import React from 'react';
import DataGrid from './DataGrid';

const SmallGrid = (props) => (
    <DataGrid
        rowProps={{ style: { height: 30 } }}
        headerRowProps={{ style: { height: 30 } }}
        headerLess
        borderLess
        {...props}
    />
)

export default SmallGrid;