import React, { useState, useEffect, useMemo } from 'react';
import { DataGrid, ComboBox, DatePicker, DataColumn, NumberField, ComboSearch, ProgressDialog, PaperTitle, IconButton } from '../../component';
import { makeStyles, Paper, TextField } from '@material-ui/core';
import { adjType, orderType, regType } from '../../common/Dictionary';
import { useFieldInputs } from '../../common/Utils';
import CheckIcon from '@material-ui/icons/Check';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import CachedIcon from '@material-ui/icons/Cached';
import { callProc } from '../../common/DBConnector';

const adjTypeTrans = {
    '1': '조정',
}

const useStyle = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
        flex: 2,
        marginRight: theme.spacing(1),
    },
    input: {
        width: 300,
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(1),
    },
    enroll: {
        display: 'flex', 
        flex: 1,
        marginLeft: theme.spacing(1),
    },
    searchPaper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    searchContainer: {
        flex: 1,
        display: 'inline-block',
        flexDirection: 'row',
    },
    searchItem: {
        display: 'inline-block',
        width: 200,
        marginRight: 100,
        marginBottom: theme.spacing(1),
    },
    contents: {
        display: 'flex',
        height: 0,
        flex: 1,
    },
    paper: {
        flex: 1,
        height: '100%',
    },
    paperContent: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding: theme.spacing(2),
        paddingTop: 0
    },
    field: {
        width: 180,
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2),
    },
    iconbutton : {
        alignSelf: 'flex-end',
        marginTop: theme.spacing(4),
        marginRight: theme.spacing(1),
    },
}))

const TransAdjustEnroll = () => {
    const classes = useStyle();
    const clsState = useSelector(state => state.clsDateReducer);
    const { enqueueSnackbar } = useSnackbar();
    const [editRow, setEditRow] = useState();
    const [input, setInput] = useFieldInputs({
        WORK_CD: sessionStorage['WORK_CD'] === 'ALL' ? null : sessionStorage['WORK_CD'],
        BIZ_DATE: clsState[sessionStorage['WORK_CD']],
    });

    const deleteValid = (row) => {
        if (String(row['REG_TYPE']) === '2') {
            enqueueSnackbar('등록타입이 일반일 경우에는 삭제가 불가합니다.', { variant: 'error' });
            return false;
        }
        return true;
    }

    const updateValid = (row) => {
        if (String(row['REG_TYPE']) === '2') {
            enqueueSnackbar('등록타입이 일반일 경우에는 수정이 불가능합니다..', {variant: 'error'});
            return false;
        }
        return true;
    }

    const gridRefresh = () => {
        setInput({ type: 'UPDATE', value: { ...input } });
    }


    const onDelete = async (row) => {
        if (deleteValid(row)) {
            const result = await callProc('DLT_TRANSADJUSTENROLLDEL', row);
            
            if (!result.err) {
                setInput({ ...input });
            }
        } else {
            
            return false;
        }
    }

    const onUpdateStart = row => {
        if(updateValid(row)){
            setEditRow({...row});
        }

        return false;
    }
    
    const condition = useMemo(() => (input['WORK_CD'] && input['BIZ_DATE']) ? input : null, [input]);
    
    return (
        <div className={classes.container}>
            <Paper className={classes.searchPaper}>
                <div className={classes.searchContainer}>
                        <div className={classes.searchItem}>
                            <ComboBox
                                // style={{width: 200}}
                                label='사업장'
                                selectProc='SLT_WORKPLACEINFO_AUTH'
                                keyField='WORK_CD'
                                valueField='WORK_NM'
                                value={condition && condition['WORK_CD']}
                                onChange={value => {
                                    if (input['WORK_CD'] !== value) {
                                        sessionStorage['WORK_CD'] = value;
                                        setInput('BIZ_DATE', clsState[value]);
                                        setInput('WORK_CD', value);
                                    }
                                }}
                            />
                        </div>
                        <div className={classes.searchItem}>
                            <DatePicker
                                label='영업일'
                                minDate={clsState[input['WORK_CD']]}
                                value={condition && condition['BIZ_DATE']}
                                onChange={value => {
                                    if (input['BIZ_DATE'] !== value) {
                                        setInput('BIZ_DATE', value);
                                        if(condition && condition['BIZ_DATE'] < clsState[condition['WORK_CD']]) {
                                            setInput({ type: 'UPDATE', value: { ...input, 'BIZ_DATE': value } });
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>

                <IconButton
                    tooltip=''
                    icon={<SearchIcon />}
                    onClick={gridRefresh}
                />
            </Paper>
            <div className={classes.contents}>
                <div className={classes.info}>   
                    <DataGrid
                        title='수송 조정 등록'
                        updateProc=''
                        selectProc='SLT_TRANSADJUSTENROLL'
                        selectParam={condition}
                        selectionMode='single'
                        onDelete={row => onDelete(row)}
                        onUpdateStart={onUpdateStart}
                        defaultExpand
                        horizonMode
                    >
                        <DataColumn fieldName='CAR_NO' mergeField='CAR_NO' />
                        <DataColumn fieldName='TRN_CD' title='수송코드' />
                        <DataColumn fieldName='REG_TYPE' title="등록타입" value={(row, value) => regType[value]} />
                        <DataColumn fieldName='ORD_TYPE' title="오더타입" value={(row, value) => orderType[value]} />
                        <DataColumn fieldName='ADJ_TYPE' title="조정/대체" value={(row, value) => adjType[value]} />
                        <DataColumn fieldName='REWD_PRICE' title='보상금액' />
                        <DataColumn fieldName='TRANS_PRICE' title='운임금액' />
                        <DataColumn fieldName='GDS_NM' title='상품명' />
                        <DataColumn fieldName='REMARK' title='비고' />
                        <DataColumn fieldName='SHIP_TRN_NM' title='출하처' />
                        <DataColumn fieldName='DEST_TRN_NM' title='인도처' />
                        <DataColumn fieldName='REAL_DEST_NM' title='도착지' />
                        <DataColumn fieldName='SPLY_AMT_A' title='운임단가' />
                        <DataColumn fieldName='WORK_CD' visible={false} />
                        <DataColumn fieldName='BIZ_DATE' visible={false} />
                        <DataColumn fieldName='TSP_CAR_CD' visible={false} />
                    </DataGrid>
                </div>
                <div className={classes.input}>
                    <EnrollPanel
                        condition={condition}
                        editRow={editRow}
                        gridRefresh={gridRefresh}
                    />
                </div>
            </div>
        </div>
            

    )
}

const usePanelStyles = makeStyles((theme) => ({
    panelContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: 0,
    },
    panelContent: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        overflowY: 'auto',
        '& > *': {
            marginBottom: theme.spacing(2),
        },
    },
    btnContainer: {
        alignSelf: 'flex-end',
        padding: theme.spacing(1),
    },
}))

const EnrollPanel = ({ condition, editRow, gridRefresh }) => {
    const classes = usePanelStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [editState, setEditState] = useState(0);
    const [tspCarParam, setTspCarParam] = useState();
    const [befValue, setBefValue] = useState();

    const [insData, setInsData] = useFieldInputs({
        WORK_CD: condition && condition['WORK_CD'],
        ADJ_TYPE: '1',
        REMARK: '',
        TSP_CAR_CD: null,
        TRN_CD: null,
        TRANS_PRICE: 0,
        REWD_PRICE:  0,
    });

    useEffect(() => {
        const fetchData = () => {
            setInsData({ type: 'INIT' });
            setEditState(0);
            setInsData('WORK_CD', condition['WORK_CD']);
            setInsData('BIZ_DATE', condition['BIZ_DATE']);
            setTspCarParam({
                WORK_CD: condition && condition['WORK_CD'],
            })
        }

        condition && fetchData();
    }, [condition, setInsData])

   const isValid = () => {
        if(insData['REMARK'] == null || insData['REMARK'] === ''){
            enqueueSnackbar('조정/대체 시 사유를 작성해주세요.', {variant: 'error'});
            return false;
        }
        if(insData['TSP_CAR_CD'] == null || insData['TSP_CAR_CD'] === ''){
            enqueueSnackbar('운송수단을 선택해주세요.', {variant: 'error'});
            return false;
        }
        if(String(insData['TRANS_PRICE']) === '0'  && String(insData['REWD_PRICE']) === '0'){
            enqueueSnackbar('운임 합계 또는 보상 합계를 입력해주세요.', {variant: 'error'});
            return false;
        }
        return true;
    }

    const [loading, setLoading] = useState(false);

    const onInsert = async() => {
        setLoading(true);

        if(isValid()){
            let befData = {...insData};
            delete befData.TRN_CD;
            delete befData.WORK_CD;
            delete befData.BIZ_DATE;
            setBefValue(befData);
            let procName;
            if(String(editState) === '0'){
                procName = 'INS_TRANSADJUSTENROLL'
            } else {
                procName = 'UPT_TRANSADJUSTENROLLDEL'
            }
            const result = await callProc(procName, {...condition, ...insData});

            if (!result.err) {
                enqueueSnackbar('저장이 완료되었습니다.');
                setInsData({ type: 'INIT' });
                setEditState(0);
                gridRefresh();
            }
        }

        setLoading(false);
    }

    useEffect(() => {
        const fetchData = () => {
            setInsData({ type: 'UPDATE', value: {...editRow, ADJ_TYPE: editRow['ADJ_TYPE'] === null ? '2' : editRow['ADJ_TYPE']} });
            if(editRow['ADJ_TYPE'] === null) {
                setEditState(1);
            } else {
                setEditState(2);
            }
        }
        editRow && fetchData();
    }, [editRow, setInsData, setEditState])

    const getBefValue = () => {
        befValue && setInsData({ type: 'UPDATE', value: {
            ...befValue,
        } })
    }

    const onExitEdit = () => {
        setInsData({ type: 'INIT' });
        setEditState(0);
    }

    return (
        <Paper className={classes.panelContainer}>
            <ProgressDialog open={loading} />
            <PaperTitle>
                수송비 조정
            </PaperTitle>
                <div className={classes.panelContent}>
                    {(String(editState) === '1' || String(editState) === '2') &&
                    <TextField
                        label='수송코드'
                        value={insData['TRN_CD']}
                        disabled
                    />}
                     <ComboBox
                    label='사업장'
                    selectProc='SLT_WORKPLACEINFO_AUTH'
                    keyField='WORK_CD'
                    valueField='WORK_NM'
                    value={insData['WORK_CD']}
                    disabled
                    />
                    <DatePicker
                        label='영업일'
                        value={insData['BIZ_DATE']}
                        disabled
                    />
                    <ComboBox
                        className={classes.field}
                        data={adjTypeTrans}
                        value={String(insData['ADJ_TYPE'])}
                        onChange={value => setInsData('ADJ_TYPE', value)}
                        disabled
                    />
                    <TextField
                        label='조정사유'
                        value={insData['REMARK']}
                        onChange={e => setInsData('REMARK', e.target.value)}
                    />
                    {tspCarParam &&
                    <ComboSearch
                        label='운송수단'
                        selectProc='SLT_TRANSCARINFO'
                        selectParam={tspCarParam}
                        keyField='TSP_CAR_CD'
                        valueField='CAR_NO'
                        value={insData['TSP_CAR_CD']}
                        onChange={value => setInsData('TSP_CAR_CD', value)}
                        nullable
                    />}
                    <NumberField
                        label='운임 합계'
                        value={insData['TRANS_PRICE']}
                        onChange={value => setInsData('TRANS_PRICE',value)}
                    />
                    <NumberField
                        label='보상 합계'
                        value={insData['REWD_PRICE']}
                        onChange={value => setInsData('REWD_PRICE', value)}
                    />
                </div>
                <div className={classes.btnContainer}>
                    {(String(editState) === '1' || String(editState) === '2') &&
                    <IconButton
                        tooltip='취소'
                        icon={<CloseIcon />}
                        onClick={() => onExitEdit()}
                    />}
                    <IconButton
                        tooltip='이전값 불러오기'
                        icon={<CachedIcon />}
                        onClick={() => getBefValue()}
                    />
                    <IconButton
                        tooltip='확인'
                        icon={<CheckIcon />}
                        onClick={() => onInsert()}
                    />
                </div>
        </Paper>
    )
}

export default TransAdjustEnroll