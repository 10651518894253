import React from 'react';
import { View, Text, StyleSheet} from '@react-pdf/renderer';



const PdfCell = (props) => {
    const {
        cellLeft = false,
        cellTop = false,
        wrap = false,
        textWrap = false,
        style,
        textStyle,
    } = props;
    return(
        <View
            style={[
                styles.cellBasic ,
                cellTop ? styles.cellTop : undefined ,
                cellLeft ? styles.cellLeft : undefined ,
                style
            ]}
            wrap={wrap}
        >
            <Text style={textStyle} wrap={textWrap}>
                {props.children}
            </Text>
        </View>
    )
}

const styles = StyleSheet.create({
    cellBasic : {
        display : 'table',
        flex : 1,
        borderBottom : 1,
        borderRight : 1,
        borderBottomStyle : 'solid',
        borderRightStyle : 'solid',
        textAlign : 'center',
        paddingTop : 1,
        paddingBottom : 1,
    },
    cellTop : {
        borderTop : 1,
        borderTopStyle : 'solid',
    },
    cellLeft : {
        borderLeft : 1,
        borderLeftStyle : 'solid'
    },
})

export default PdfCell;