import React, { useEffect, useRef, useState } from 'react'
import { makeStyles, Menu as PopupMenu, MenuItem as PopupMenuItem, ListItemIcon,  Dialog, Button } from '@material-ui/core';
import { callProc, sendKakao } from '../../common/DBConnector';
import { ComboBox, ComboSearch, DataColumn, DataGrid, DatePicker, EditableField, EditableList, IconButton, NumberField, ProgressDialog, SearchItem, SearchPanel, TextField } from '../../component';
import CloseIcon from '@material-ui/icons/Close';
import { isFieldDiff, numberFormat } from '../../common/Utils';
import { useSnackbar } from 'notistack';
import { Fragment } from 'react';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import MobileFriendlyIcon from '@material-ui/icons/MobileFriendly';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BackspaceIcon from '@material-ui/icons/Backspace';
import SendIcon from '@material-ui/icons/Send';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { useSelector } from 'react-redux';
import { orderType } from '../../common/Dictionary';
import moment from 'moment';

const tspChkType = {
    '0': '수송미확인',
    '1': '수송확인',
    '2': '수송완료',
}

const rewardType = {
    '1': '수송용량보상',
    '2': '복수인도처보상',
}

const tspChkTypeCombo = [
    { key: 0, value: '수송미확인' },
    { key: 1, value: '수송확인' },
    { key: 2, value: '수송완료' },
]

const useToolbarStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    root: {
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
    },
    profile: {
        display: 'inline-block',
        fontSize: 14,
        textAlign: 'left',
        cursor: 'pointer',
    },
    textLight: {
        color: '#888'
    },
    textContainer: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    }
}));

const IconMenuItem = ({ onClick, icon, label }) => {
    return (
        <PopupMenuItem onClick={onClick}>
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            {label}
        </PopupMenuItem>
    )
}

const TransList = () => {
    const [transIndex, setTransIndex] = useState();
    const [transDatas, setTransDatas] = useState();
    const [condition, setCondition] = useState(null);
    const [trnCd, setTrnCd] = useState({});
    const [reSandParam, setReSendParam] = useState();
    const clsState = useSelector(state => state.clsDateReducer);
    

    const [anchorEl, setAnchorEl] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false)
    const [rewardDialogOpen, setRewardDialogOpen] = useState(false)
    const conf = useSelector(state => state.configReducer);

    const classes = useToolbarStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const snack = useRef();

    useEffect(() => {
        const fetchData = async () => {
            setTransIndex();
            setTransDatas();
            
            const transIndex = await callProc('SLT_TRANS_INDEX', condition); //
            const transDatas = (await callProc('SLT_TRANS_LISTS', condition)).data;

            setTransDatas(transDatas.reduce((result, row) => ({
                ...result,
                [row['TSP_CAR_CD']]: {
                    ...result[row['TSP_CAR_CD']],
                    [row['TRN_CD']]: [
                        ...((result[row['TSP_CAR_CD']] && result[row['TSP_CAR_CD']][row['TRN_CD']]) || []),
                        row,
                    ]
                }
            }), {}));
            setTransIndex(transIndex);
        }
        condition && fetchData();
    }, [condition])


    const onRemove = async() => {
        
        const result = await callProc('DLT_TRANS_TEST',  trnCd);
        if (!result.err) {
            
            enqueueSnackbar('삭제가 완료되었습니다.');
        }

    }

    const onDeleteTrans = () => {

        snack.current && closeSnackbar(snack.current);
        snack.current = enqueueSnackbar('수송 취소 하시겠습니까?', {
            autoHideDuration: null,
            variant: 'warning',
            action: key => <>
                <Button onClick={() => {
                    closeSnackbar(key)
                    onRemove()
                    setCondition({ ...condition })
                    setAnchorEl(null)
                }}>
                    예
                </Button>
                <Button onClick={() => closeSnackbar(key)}>
                    아니오
                </Button>
            </>,
        })

    }



    const transReSand = async(reSandParam) => {

        snack.current && closeSnackbar(snack.current);
        snack.current = enqueueSnackbar('수송 알림 다시 전송 하시겠습니까?', {
            autoHideDuration: null,
            action: key => <>
                <Button onClick={async() => {
                    const carInfo = (await callProc('SLT_TRANSCARINFO', reSandParam)).data[0];
                    if ((carInfo['TELNO1'] || '') !== '') {
                        await sendKakao(
                            carInfo['TELNO1'],
                            conf['SOLAPI_TRN_TID'],
                            conf,
                            `차량번호 ${carInfo['CAR_NO']}에 대한 배차가 완료되었습니다.`,
                            [{
                                "buttonName": "배차 조회",
                                "buttonType": "WL",
                                "linkMo": window.location.origin + '/m',
                                "linkPc": window.location.origin + '/m',
                            }]
                        );

                    }
                    closeSnackbar(key)
                    setCondition({ ...condition })
                    setAnchorEl(null)
                }}>
                    예
                </Button>
                <Button onClick={() => closeSnackbar(key)}>
                    아니오
                </Button>
            </>,
        })


    }
    

    return (
        
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => setCondition(condition)}>
                <SearchItem
                    fieldName='WORK_CD'
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => {
                                dispatch(value);
                            }}
                        />
                    )}
                />

            <SearchItem
                fieldName='BIZ_DATE'
                defaultValue={moment().format('YYYY-MM-DD')}
                component={(state, dispatch) => (
                    <DatePicker
                        label='영업일'
                        value={state}
                        onChange={(value) => {
                            dispatch(value);
                        }}
                    />
                )}
            />

            <SearchItem
                fieldName='TSP_CAR_CD'
                component={(state, setState, condition, setField) => {
                    let param = condition['TSP_CAR_PARAM'] || null;
                    if (isFieldDiff(['WORK_CD'], condition, param)) {
                        param = { WORK_CD: condition['WORK_CD'] };
                        setField('TSP_CAR_PARAM', param);
                    }
                    return (
                        <ComboSearch
                            label='운송수단'
                            selectProc='SLT_TRANSCARINFO'
                            selectParam={param}
                            keyField='TSP_CAR_CD'
                            valueField='CAR_NO'
                            value={state}
                            onChange={value => setState(value)}
                            onDataLoad={jsonData => ({'ALL': '전체', ...jsonData})}
                        />
                    )
                }}
            />

            <SearchItem
                fieldName='TSP_CHK'
                defaultValue='ALL'
                component={(state, dispatch) => (
                    <ComboBox
                        label='출고상태'
                        data={tspChkTypeCombo}
                        keyField='key'
                        valueField='value'
                        value={state}
                        onChange={(value) => dispatch(value)}
                        onDataLoad={(jsonData, rawData) => [{ key: 'ALL', value: '전체' }, ...rawData]}
                    />
                )}
            />

            </SearchPanel>
            <DataGrid
                title='수송 조회'
                dataSet={transIndex}
                expandable={(row) => transDatas && transDatas[row['TSP_CAR_CD']] && Object.keys(transDatas[row['TSP_CAR_CD']]).map((trnCd, index) => (
                        <div
                            className={classes.profile}
                            onClick={(e) => {
                                setAnchorEl(e.currentTarget)
                                setTrnCd({TRN_CD: trnCd})
                                setReSendParam({TSP_CAR_CD: transDatas[row['TSP_CAR_CD']][trnCd][0]['TSP_CAR_CD'], WORK_CD: transDatas[row['TSP_CAR_CD']][trnCd][0]['WORK_CD']})
                            }}
                            key={index}
                        >
                            <div style={{display: 'inline-block', width: 450, margin: 5, border: '1px solid black'}}>
                                <div style={{display: 'flex'}}>
                                    <div style={{height: '100%', width: '30%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                            <Fragment>
                                                {String(transDatas[row['TSP_CAR_CD']][trnCd][0]['TSP_CHK']) === '0' ? 
                                                    (<VisibilityOffIcon style={{width: 80, height: 80, color: '#778899'}} />) : 
                                                    String(transDatas[row['TSP_CAR_CD']][trnCd][0]['TSP_CHK']) === '1' ? 
                                                    (<MobileFriendlyIcon style={{width: 80, height: 80, color: '#6495ED'}} />) : 
                                                    (<LocalShippingOutlinedIcon style={{width: 80, height: 80, color: '#3CB371'}}  />)}
                                                <b style={{height: 20}}>{String(transDatas[row['TSP_CAR_CD']][trnCd][0]['TSP_CHK']) === '0' ?
                                                    '미확인' : 
                                                    String(transDatas[row['TSP_CAR_CD']][trnCd][0]['TSP_CHK']) === '1' ? 
                                                    '수송확인' : 
                                                    '수송완료'}
                                                </b>
                                            </Fragment>
                                    </div>
                                    
                                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'spaceBetween'}}>
                                    {transDatas[row['TSP_CAR_CD']][trnCd].map((row, index) => (
                                            <div style={{display: 'flex', flexDirection: 'column', marginTop: 10, marginBottom: 10}} key={index}>
                                                <b>{orderType[row['ORD_TYPE']]} - {row['REAL_DEST_NM']}</b>
                                                <b>{row['GDS_NM']} / {numberFormat(row['QTY'])} ({numberFormat(row['CALC_QTY'])}) {row['UNIT']}</b>
                                                <b>{row['SHIP_TRN_NM']} - {row['DEST_TRN_NM']}</b>
                                                <b>매입전표 : {row['TRN_INNO']}</b>
                                                <b>수송완료일 : {row['TSP_DTTM']}</b>
                                            </div>
                                        ))}
                                        <div style={{marginTop: 10, marginBottom: 10, borderBottom: '1px solid black'}}>
                                            <h5 style={{margin: 0}}>
                                                정산수량 : {numberFormat(transDatas[row['TSP_CAR_CD']][trnCd][0]['CALC_QTY_SUM'])}
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    ))
                }
                defaultExpand
                
            >
                <DataColumn fieldName='TSP_CAR_CD' visible={false} />
                <DataColumn fieldName='WORK_CD' visible={false} />

            </DataGrid>
            <PopupMenu
                anchorEl={anchorEl}
                open={anchorEl != null}
                onClose={() => setAnchorEl(null)}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <IconMenuItem
                    label='상세내역'
                    icon={<AssignmentIcon />}
                    onClick={() => setDialogOpen(true)}
                />
                <IconMenuItem
                    label='보상설정'
                    icon={<ShoppingCartIcon />}
                    onClick={() => setRewardDialogOpen(true)}
                />
                <IconMenuItem
                    label='알림 다시 전송'
                    icon={<SendIcon />}
                    onClick={() => transReSand(reSandParam)}
                />
                <IconMenuItem
                    label='수송 취소'
                    icon={<BackspaceIcon />}
                    onClick={() => onDeleteTrans()}
                />
            </PopupMenu>
            <TransListDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                trnCd={trnCd}
                condition={condition}
                clsState={clsState}
            />
            <TransRewardDialog
                open={rewardDialogOpen}
                onClose={() => setRewardDialogOpen(false)}
                trnCd={trnCd}
            />
        </div>)
}



const TransListDialogStyle = makeStyles((theme) => ({
    dialogPaper: {
        maxWidth: 'none',
        width: 1500,
        height: 450,
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    actionButton: {
        display: 'flex',
    },
}))

const TransListDialog = ({ open, onClose, trnCd, condition, clsState}) => {
    const classes = TransListDialogStyle();
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();


    const onInsert = async(data) => {
        if(valid(data)){
            setLoading(true);

            const result = await callProc('UPT_TRANSINBOUND', {...trnCd, JSON_DATA: data});
            if(!result.err) {
                enqueueSnackbar('저장이 완료되었습니다.');
            }

            setLoading(false);
        }
    }

    const valid = (data) => {
        if(!data.reduce((result, item) => {
                if(result === true) return String(item['TSP_CHK']) === '2' && item['SHIP_DATE'] == null ? false : true;
                return result;
            }, true)) {

            enqueueSnackbar('수송완료에 수송완료 날짜가 존재하지 않습니다.', { variant: 'error' });
            return false;
        }

        if(!data.reduce((result, item) => {
                if(result === true) return String(item['TSP_CHK']) === '2' && item['CALC_QTY'] === 0 ? false : true;
                return result;
            }, true)) {

            enqueueSnackbar('정산수량을 입력하세요', { variant: 'error' });
            return false;
        }
        
        return true;
    }

    return (
        <Dialog
            open={open}
            PaperProps={{ className: classes.dialogPaper }}
        >
            <ProgressDialog open={loading} />
            <EditableList
                title='상세 내역'
                selectProc='SLT_TRANS_LISTS_DETAIL'
                selectParam={trnCd}
                onUpdate={onInsert}
                fixedRowCount
                headerItem={() => (
                    <IconButton
                        tooltip='닫기'
                        icon={<CloseIcon />}
                        onClick={onClose}
                    />
                )}
            >
                <EditableField
                    fieldName='REAL_DEST_NM'
                    component={({value}) => (
                        <TextField
                            label='도착지'
                            value={value}
                            disabled
                        />
                    )}
                />
                <EditableField
                    fieldName='GDS_NM'
                    component={({value}) => (
                        <TextField
                            label='상품명'
                            value={value}
                            disabled
                        />
                    )}
                />
                <EditableField
                    fieldName='SHIP_TRN_NM'
                    component={({value}) => (
                        <TextField
                            label='오더출하처'
                            value={value}
                            disabled
                        />
                    )}
                />
                <EditableField
                    fieldName='DEST_TRN_NM'
                    component={({value}) => (
                        <TextField
                            label='오더인도처'
                            value={value}
                            disabled
                        />
                    )}
                />
                <EditableField
                    fieldName='SHIP_TRN_CD'
                    component={({value, setState, rowData}) => (
                        <ComboSearch
                            style={{ width: 180 }}
                            label='수송출하처'
                            selectProc='SLT_TRANSPLACE_ALL'
                            keyField='TSP_CD'
                            valueField='TSP_NM'
                            value={value}
                            onChange={value => setState(value)}
                            disabled={(clsState[condition['WORK_CD']] > rowData['SHIP_DATE'])}
                            nullable
                        />
                    )}
                />
                <EditableField
                    fieldName='TSP_CHK'
                    component={({value, setState, rowData, dispatch}) => (
                        <ComboBox
                            style={{width: 120}}
                            label='수송 상태'
                            value={value}
                            data={tspChkType}
                            onChange={value => {
                                dispatch('SHIP_DATE', null);
                                setState(value);
                            }}
                            disabled={(clsState[condition['WORK_CD']] > rowData['SHIP_DATE'])}
                        />
                    )}
                />
                <EditableField
                    fieldName='SHIP_DATE'
                    component={({value, setState, rowData}) => (
                        <DatePicker
                            label='수송완료일시'
                            minDate={(clsState[condition['WORK_CD']] > value) ? undefined : clsState[condition['WORK_CD']]}
                            value={value}
                            onChange={value => setState(value)}
                            disabled={(clsState[condition['WORK_CD']] > value) || String(rowData['TSP_CHK']) !== '2'}
                            nullable
                        />
                    )}
                />
                <EditableField
                    fieldName='TRN_INNO'
                    component={({value, setState, rowData}) => (
                        <TextField
                            style={{width: 100}}
                            label='매입전표'
                            value={value}
                            onChange={value => setState(value)}
                            disabled={(clsState[condition['WORK_CD']] > rowData['SHIP_DATE'])}
                        />
                    )}
                />
                <EditableField
                    fieldName='QTY'
                    component={({value, setState}) => (
                        <NumberField
                            label='오더수량'
                            value={value}
                            onChange={value => setState(value)}
                            disabled
                        />
                    )}  
                />
                <EditableField
                    fieldName='CALC_QTY'
                    component={({value, setState, rowData}) => (
                        <NumberField
                            label='정산수량'
                            value={value}
                            onChange={value => setState(value)}
                            disabled={(clsState[condition['WORK_CD']] > rowData['SHIP_DATE'])}
                        />
                    )}  
                />
                <EditableField
                    fieldName='SPLY_AMT'
                    component={({value, setState}) => (
                        <NumberField
                            label='운임단가'
                            value={value}
                            onChange={value => setState(value)}
                            disabled
                        />
                    )}  
                />
                <EditableField fieldName='REAL_DEST_CD' visible={false} />
                <EditableField fieldName='GDS_CD' visible={false} />
                <EditableField fieldName='DEST_TRN_CD' visible={false} />
                <EditableField fieldName='ORD_CD' visible={false} />
                <EditableField fieldName='ORD_TYPE' visible={false} />
                <EditableField fieldName='WORK_CD' visible={false} />
                <EditableField fieldName='GDS_TYPE' visible={false} />
                <EditableField fieldName='TSP_CAR_CD' visible={false} />
                <EditableField fieldName='BIZ_DATE' visible={false} />
                <EditableField fieldName='TRN_TYPE' visible={false} />
            </EditableList>
        </Dialog>
    )
}


const TransRewardDialogStyle = makeStyles((theme) => ({
    dialogPaper: {
        maxWidth: 'none',
        width: 800,
        height: 450,
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(1),
    },
    actionButton: {
        display: 'flex',
    },
}))

const TransRewardDialog = ({ open, onClose, trnCd, condition }) => {
    const classes = TransRewardDialogStyle();
    
    return (
        <Dialog
            open={open}
            PaperProps={{ className: classes.dialogPaper }}
        >
            <div className={classes.dialogTitle}>
                <IconButton
                    tooltip='닫기'
                    icon={<CloseIcon />}
                    onClick={onClose}
                />
            </div>

            <DataGrid
                title='수송보상'
                selectProc='SLT_TRANS_REWARD'
                insertProc='INS_TRANS_REWARD'
                updateProc='UPT_TRANS_REWARD'
                deleteProc='DLT_TRANS_REWARD'
                selectParam={trnCd}
                horizonMode
            >
                <DataColumn
                    fieldName='REWD_TYPE'
                    value={(row, value) => rewardType[value]}
                    defaultValue={Object.keys(rewardType)[0]}
                    editable={(state, dispatch) => (
                        <ComboBox
                            data={rewardType}
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                />
                <DataColumn
                    fieldName='QTY'
                    editable={(state, dispatch, rowState) => (
                        <NumberField
                            style={{width: 150}}
                            value={state}
                            onChange={value => dispatch(value)}
                            disabled={String(rowState['REWD_TYPE']) === '1' ? false : true}
                        />
                    )}
                />
                <DataColumn
                    fieldName='AMOUNT'
                    editable={(state, dispatch, rowState) => (
                        <NumberField
                            style={{width: 150}}
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                />

                <DataColumn fieldName='SEQ' visible={false} />
                <DataColumn fieldName='TRN_CD' defaultValue={trnCd['TRN_CD']} visible={false} />
            </DataGrid>

        </Dialog>
    )
}



export default TransList
