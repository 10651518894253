import { CircularProgress, makeStyles } from '@material-ui/core';
import { Print } from '@material-ui/icons';
import React, { useState } from 'react';
import { IconButton, PdfDialog } from '.';
import { usePdfRender } from '../common/Utils';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    circular: {
        position: 'absolute',
    },
}));

const PdfButton = ({ children, icon, ...props }) => {
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const url = usePdfRender(children);
    
    return (
        <div className={classes.container}>
            <PdfDialog
                open={open}
                onClose={() => setOpen(false)}
                url={url}
                {...props}
            />
            {!url &&
            <CircularProgress className={classes.circular} />}
            <IconButton
                tooltip='출력'
                icon={icon}
                onClick={() => setOpen(true)}
                disabled={url == null}
            />
        </div>
    )
}

PdfButton.defaultProps = {
    icon: <Print />
}

export default PdfButton;