import React, { useState, cloneElement, useRef, useMemo, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, createMuiTheme, ThemeProvider, withStyles, createStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CloseIcon from '@material-ui/icons/Close';
import { Tabs, Tab, IconButton } from '.';
import { useSnackbar } from 'notistack';

const drawerTheme = createMuiTheme({
    palette: {
        background: {
            paper: '#253053',
        },
        text: {
            primary: '#fefeff',
        },
        action: {
            active: '#fefeff',
        },
        divider: '#475275',
    }
})

const styles = (theme) => createStyles({
    root: {
        display: 'flex',
        flex: 1,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        width: 0,
    },
    hide: {
        display: 'none !important',
    },
    tabbar: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.main,
    },
    tabIndicator: {
        backgroundColor: theme.palette.primary.main,
    },
    appBar: {
        backgroundColor: theme.palette.secondary.main,
    },
    menuButton: {
        marginRight: theme.spacing(1),
    },
    verDivider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(3),
    },
    content: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: 0,
    },
    page: {
        flex: 1,
        padding: theme.spacing(3),
        display: 'flex',
        height: 0,
        overflow: 'auto',
    },
});

export default withStyles(styles, { withTheme: true })(({ theme, classes, children, title, favicon, toolbar, width, main }) => {
    const Main = main;
    const isMobile = () => document.body.offsetWidth < theme.breakpoints.values.md

    const [tabList, setTabList] = useState({ 'home': { menuKey: 'home', text: '홈', to: Main } });
    const [selected, setSelected] = useState('home');
    const [open, setOpen] = useState(!isMobile());

    const { closeSnackbar } = useSnackbar();
    useEffect(() => closeSnackbar(), [selected, closeSnackbar])
    
    useEffect(() => {
        setTabList({ 'home': { menuKey: 'home', text: '홈', to: Main } });
    }, [Main]);

    const onMenuClick = (menuKey, text, to) => {
        !tabList[menuKey] && setTabList({...tabList, [menuKey]: { menuKey, text, to }});
        setSelected(menuKey);
        isMobile() && setOpen(false);
    }

    const goHome = () => setSelected('home');

    const onTabClick = (event, newValue) => {
        setSelected(newValue);
    }

    const onTabClose = useCallback((value) => {
        const { [value]: _, ...list } = tabList;
        setTabList(list);

        if (value === selected) {
            const keys = Object.keys(tabList);
            const idx = keys.indexOf(value);
            const next = keys[idx + 1] || keys[idx - 1];
            setSelected(next);
        }
    }, [tabList, selected])

    const pages = useMemo(() => (
        Object.values(tabList).map(item => {
            const Source = item.to;
            return (
                <div key={item.menuKey} className={clsx(classes.page, {[classes.hide]: selected !== item.menuKey})}>
                    <Source active={selected === item.menuKey} code={item.menuKey} />
                </div>
            )
        })
    ), [tabList, selected, classes.page, classes.hide]);

    const tabs = useMemo(() => (
        Object.values(tabList).map(item => (
            <Tab
                key={item.menuKey}
                label={<TabButton label={item.text} value={item.menuKey} onClose={onTabClose} closeVisible={item.menuKey !== 'home'}/>}
                value={item.menuKey}
            />
        ))
    ), [tabList, onTabClose])

    return (
        <div className={classes.root}>
            <CssBaseline />
            <MenuDrawer
                open={open}
                onClose={() => setOpen(false)}
                title={title}
                favicon={favicon}
                width={width}
                toolbar={toolbar}
                goHome={goHome}
                onMenuClick={onMenuClick}
                isMobile={isMobile}
            >
                {children}
            </MenuDrawer>
            <div
                className={classes.container}
                onClick={() => isMobile() && open && setOpen(false)}
            >
                <AppBar position='relative' className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            icon={<MenuIcon />}
                            onClick={() => setOpen(true)}
                            className={clsx(classes.menuButton, {
                                [classes.hide]: open,
                            })}
                            edge="start"
                            disableTooltip
                        />
                        <Divider orientation='vertical' classes={{root: clsx(classes.verDivider, {[classes.hide]: open})}} flexItem />
                        {toolbar}
                    </Toolbar>
                </AppBar>
                <main className={classes.content}>
                    <Tabs value={selected} onChange={onTabClick}>
                        {tabs}
                    </Tabs>
                    {pages}
                </main>
            </div>
        </div>
    );
})

const useFrameStyles = makeStyles((theme) => ({
    collapseButton: {
        marginRight: theme.spacing(1),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: props => props.width,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: () => 78,
        overflowX: 'hidden',
        [theme.breakpoints.down('sm')]: {
            width: () => 0,
        },
    },
    drawerContent: {
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    collapseContent: {
        overflow: 'hidden',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    logoContainer: {
        position: 'absolute',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: theme.spacing(2),
    },
}));

const MenuDrawer = (props) => {
    const { children, title, favicon, goHome, onMenuClick, open, onClose, isMobile } = props;
    const closing = useRef(false);
    const [mouseOver, setMouseOver] = useState(false);

    const classes = useFrameStyles(props);

    const initList = (list, rootId) => (
        list.map((item, i) => cloneElement(item, {
            key: item.props.menuKey,
            handler: onMenuClick,
            children: item.props.children && initList(React.Children.toArray(item.props.children), rootId + i),
        }))
    )

    const expandItems = initList(React.Children.toArray(children), 't');
    const collapseItems = React.Children.toArray(children).map((item, i) => cloneElement(item, { key: i, children: null }));

    const handleCloseClick = () => {
        closing.current = true;
        onClose();
        setMouseOver(false);
        setTimeout(() => closing.current = false, 200);
    }

    return (
        <ThemeProvider theme={drawerTheme}>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: mouseOver || open,
                    [classes.drawerClose]: !mouseOver && !open,
                })}
                classes={{
                    paper: clsx(classes.drawer, {
                        [classes.drawerOpen]: mouseOver || open,
                        [classes.drawerClose]: !mouseOver && !open,
                    }),
                }}
                onMouseOver={() => !isMobile() && !mouseOver && !closing.current && setMouseOver(true)}
                onMouseLeave={() => !isMobile() && mouseOver && setMouseOver(false)}
            >
                <div className={classes.toolbar}>
                    <div className={classes.logoContainer}>
                        <LogoImage favicon={favicon} onClick={goHome}/>
                        <h2>　{title || 'ERP System'}</h2>
                    </div>
                    <IconButton
                        icon={<ChevronLeftIcon />}
                        className={clsx(classes.collapseButton, {[classes.hide]: !open})}
                        onClick={handleCloseClick}
                        disableTooltip
                    />
                </div>
                <Divider />
                <div className={clsx(classes.drawerContent, {
                    [classes.collapseContent]: !mouseOver && !open,
                })}>
                    <List className={clsx({
                        [classes.hide]: !mouseOver && !open,
                    })}>
                        {expandItems}
                    </List>
                    <List className={clsx({
                        [classes.hide]: mouseOver || open,
                    })}>
                        {collapseItems}
                    </List>
                </div>
            </Drawer>
        </ThemeProvider>
    )
}

const useTabStyles = makeStyles(theme => ({
    tab: {
        width: '100%',
        display: 'inline',
        padding: '0px 28px',
    },
    closeButton: {
        position:'absolute',
        right:0,
        top:0,
        height:'100%',
    },
    closeIcon: {
        fontSize : 'medium',
    }
}))

const TabButton = ({label, value, closeVisible, onClose}) => {
    const classes = useTabStyles();

    return (
        <div className={classes.tab}>
            {label}
            {closeVisible &&
                <IconButton
                    className={classes.closeButton}
                    icon={<CloseIcon className={classes.closeIcon} />}
                    onClick={(e) => {e.stopPropagation(); onClose(value)}}
                    disableTooltip
                />
            }
        </div>
    )
}

const useLogoStyles = makeStyles(theme => ({
    container: {
        backgroundColor: '#FFF',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        width: 45,
        height: 45,
        cursor: 'pointer',
    },
    img: {
        width: 45,
        height: 45,
    }
}))

const LogoImage = ({ onClick, favicon }) => {
    const classes = useLogoStyles();

    return (
        <div className={classes.container} onClick={onClick}>
            <img src={favicon || "/logo192.png"} className={classes.img} alt='logo' />
        </div>
    )
}