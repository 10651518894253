import React from 'react';
import moment from 'moment';
import { Document, StyleSheet, Text, View } from '@react-pdf/renderer';
import { PdfCell, PdfTable, PdfRow, PdfPage } from '../component';

const styles = StyleSheet.create({
    title: {
        fontSize : 16,
        textAlign : 'center',
    },
    header: {
        flex: 1,
        borderRight: 0,
        fontSize: 9,
    },
    texts: {
        flex: 1,
        borderRight: 0,
        borderBottom: 0,
        fontSize: 9,
    },
    sums: {
        borderRight: 0,
        borderBottom: 0,
        backgroundColor: '#D3D3D3',
        fontSize: 9,
    },
    bottom: {
        position: 'absolute',
        bottom: 13,
        left: 25,
        right: 25,
    },
})


const InboundTaxEnrollTodoPdf = ({pdfData, condition, loginState, workName}) => {


    return (
        <Document>
            <PdfPage orientation='landscape'>
                <Text style={{ ...styles.title}}>매입계산서 수취발행 발행예정</Text>
                <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Text>{`인쇄기간: ${moment().format('YYYY-MM-DD HH:mm:ss')}`}</Text>
                    <Text>{`조회기간: ${condition['STA_DATE']} ~ ${condition['END_DATE']}`}</Text> 
                </View>
                <View style={{height: '10'}}>

                </View>
                <PdfTable style={{ borderBottom: 1 }}>
                    {pdfData && pdfData.map((item, idx) => {
                        let style;
                        if (idx === 0) {
                            style = styles.header;
                        } else if (item[0] === '') {
                            style = styles.sums;
                        } else {
                            style = styles.texts;
                        }
                        return (
                            <PdfRow key={idx} fixed={idx === 0}>
                                <PdfCell cellTop={idx === 0} style={{ ...style, textAlign: 'left'}}>
                                    {item[0]}
                                </PdfCell>
                                <PdfCell cellTop={idx === 0} style={{ ...style, textAlign: 'left'}}>
                                    {item[1]}
                                </PdfCell>
                                <PdfCell cellTop={idx === 0} style={{ ...style, textAlign: 'left'}}>
                                    {item[2]}
                                </PdfCell>
                                <PdfCell cellTop={idx === 0} style={{ ...style, textAlign: 'left'}}>
                                    {item[3]}
                                </PdfCell>
                                <PdfCell cellTop={idx === 0} style={{ ...style, textAlign: 'left'}}>
                                    {item[4]}
                                </PdfCell>
                                <PdfCell cellTop={idx === 0} style={{ ...style, textAlign: 'right'}}>
                                    {item[5]}
                                </PdfCell>
                                <PdfCell cellTop={idx === 0} style={{ ...style, textAlign: 'right'}}>
                                    {item[6]}
                                </PdfCell>
                                <PdfCell cellTop={idx === 0} style={{ ...style, textAlign: 'right'}}>
                                    {item[7]}
                                </PdfCell>
                            </PdfRow>
                        )})}
                    
                </PdfTable>
                <Text style={{ ...styles.bottom, textAlign: 'left' }} fixed>
                    {workName} / {loginState['EMP_NM']}
                </Text>
                <Text
                    style={{ ...styles.bottom, textAlign: 'right'  }}
                    render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                    fixed
                />
            </PdfPage>
        </Document>
    )
}


export default InboundTaxEnrollTodoPdf;
