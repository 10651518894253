import { makeStyles, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ComboBox, DatePicker, SearchItem, SearchPanel, DataColumn, DataGrid, Tab, Tabs, IconButton } from '../../component';
import moment from 'moment';
import clsx from 'clsx';
import { lpadByteOfStr, rpadByteOfStr, weekOfYear } from '../../common/Utils';
import { useSnackbar } from 'notistack';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { callProc } from '../../common/DBConnector';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import iconv from 'iconv-lite';

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    tabContainer: {
        display: 'flex',
    },
    subTabContainer : {
        backgroundColor: theme.palette.secondary.main,
        boxShadow: theme.shadows[4],
        marginBottom: 8,
    },
    tabbar: {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
    hide: {
        display: 'none'
    },
}))

const OilTradeReport = () => {
    const classes = useStyles();
    const [condition, setCondition] = useState();
    const [selectTab, setSelectTab] = useState(0);
    const [workplace, setWorkPlace] = useState();
    const [total, setTotal] = useState();
    const [inbound, setInbound] = useState();
    const [carOutbound, setCarOutbound] = useState();
    const [carEtcOutbound, setCarEtcOutbound] = useState();
    const [fileSeq, setFileSeq] = useState();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const fetchData = async() => {
            setWorkPlace(null);
            setTotal(null);
            setInbound(null);
            setCarOutbound(null);
            setCarEtcOutbound(null);
            setFileSeq(null);

            const workplace = (await callProc('SLT_OILTRADEREPORT_WORKPLACE', condition));
            const total = (await callProc('SLT_OILTRADEREPORT_TOTAL', condition));
            const inbound = (await callProc('SLT_OILTRADEREPORT_INBOUND', condition));
            // const outbound = (await callProc('SLT_OILTRADEREPORT_WORKPLACE', condition)).data;
            const carOutbound = (await callProc('SLT_OILTRADEREPORT_CAROUTBOUND', condition));
            const carEtcOutbound = (await callProc('SLT_OILTRADEREPORT_CAR_ETC_OUTBOUND', condition));
            const fileSeq = (await callProc('SLT_OILTRADEREPORT_FILE_SEQ', condition));

            setWorkPlace(workplace);
            setTotal(total);
            setInbound(inbound);
            setCarOutbound(carOutbound);
            setCarEtcOutbound(carEtcOutbound);
            setFileSeq(fileSeq);
        };
        condition && fetchData();
    }, [condition])

    const onClickDown = () => {
        let value = workplace.data.reduce((result, item, idx, arr) => (
            result +
            '00' +
            rpadByteOfStr(item['OILTRD_CD'], 10, ' ') +
            rpadByteOfStr('000' + item['WORK_BIZNO'] + '0', 14, ' ') +
            rpadByteOfStr(item['SUB_BIZNO'], 4, ' ') +
            rpadByteOfStr(item['OILTRD_AREA_CD'], 4, ' ') +
            rpadByteOfStr(item['CEO'], 20, ' ') +
            rpadByteOfStr(item['WORK_NM'], 30, ' ') +
            rpadByteOfStr(item['WEEK_OF_YEAR'], 6, ' ') +
            rpadByteOfStr(item['STA_DATE'].replace(/-/g, ''), 8, ' ') +
            rpadByteOfStr(item['END_DATE'].replace(/-/g, ''), 8, ' ') +
            rpadByteOfStr('', 8, ' ') +
            rpadByteOfStr(item['ADDR'], 100, ' ') +
            rpadByteOfStr(
                (total.data.length === 0 &&
                    inbound.data.length === 0 &&
                    carOutbound.data.length === 0 &&
                    carEtcOutbound.data.length === 0) &&
                    (idx + 1 === arr.length) ? '1' : `0\n`)
        ), '');

        value = total.data.reduce((result, item, idx, arr) => (
            result +
            '01' + 
            rpadByteOfStr(item['OILTRD_CD'], 10, ' ') + 
            rpadByteOfStr(item['WEEK_OF_YEAR'], 6, ' ') +
            rpadByteOfStr(item['OILTRD_GDS_CD'], 2, ' ') +
            rpadByteOfStr(item['UNIT'], 2, ' ') +
            (Number(item['BEF_STOCK_QTY']) >= 0 ? 
            lpadByteOfStr(item['BEF_STOCK_QTY'].toFixed(3), 15, '0') :
            '-' + lpadByteOfStr(item['BEF_STOCK_QTY'].toFixed(3), 14, '0' )) +
            (Number(item['CUR_IN_QTY']) >= 0 ? 
            lpadByteOfStr(item['CUR_IN_QTY'].toFixed(3), 15, '0') :
            '-' + lpadByteOfStr(item['CUR_IN_QTY'].toFixed(3), 14, '0' )) +
            (Number(item['CUR_SALE_QTY']) >= 0 ? 
            lpadByteOfStr(item['CUR_SALE_QTY'].toFixed(3), 15, '0') :
            '-' + lpadByteOfStr(item['CUR_SALE_QTY'].toFixed(3), 14, '0' )) +
            (Number(item['INC_DEC_QTY']) >= 0 ? 
            lpadByteOfStr(item['INC_DEC_QTY'].toFixed(3), 15, '0') :
            '-' + lpadByteOfStr(Math.abs(Number(item['INC_DEC_QTY'])).toFixed(3), 14, '0' )) +
            (Number(item['SELF_QTY']) >= 0 ? 
            lpadByteOfStr(item['SELF_QTY'].toFixed(3), 15, '0') :
            '-' + lpadByteOfStr(item['SELF_QTY'].toFixed(3), 14, '0' )) +
            (Number(item['CUR_STOCK_QTY']) >= 0 ? 
            lpadByteOfStr(item['CUR_STOCK_QTY'].toFixed(3), 15, '0') :
            '-' + lpadByteOfStr(item['CUR_STOCK_QTY'].toFixed(3), 14, '0' )) +
            (Number(item['NOZZLE_QTY']) >= 0 ? 
            lpadByteOfStr(item['NOZZLE_QTY'].toFixed(3), 15, '0') :
            '-' + lpadByteOfStr(item['NOZZLE_QTY'].toFixed(3), 14, '0' )) +
            rpadByteOfStr(
                (inbound.data.length === 0 &&
                    carOutbound.data.length === 0 &&
                    carEtcOutbound.data.length === 0) &&
                    (idx + 1 === arr.length) ? '1' : `0\n`)
        ), value);

        value = inbound.data.reduce((result, item, idx, arr) => (
            result +
            '03' +
            rpadByteOfStr(item['OILTRD_CD'], 10, ' ') +
            rpadByteOfStr(item['WEEK_OF_YEAR'], 6, ' ') + 
            rpadByteOfStr(item['OILTRD_GDS_CD'], 2, ' ') +
            rpadByteOfStr(item['UNIT'], 2, ' ') +
            rpadByteOfStr(item['TRD_SEQ'], 4, ' ') +
            rpadByteOfStr(item['TRD_DATE'].replace(/-/g, ''), 8, ' ') +
            rpadByteOfStr('', 10, ' ') +
            rpadByteOfStr('000' + item['WORK_BIZNO'] + '0', 14, ' ') +
            rpadByteOfStr(item['SUB_BIZNO'], 4, ' ') +
            rpadByteOfStr(item['CST_NM'], 30, ' ') +
            rpadByteOfStr('', 4, ' ') +
            rpadByteOfStr(item['CST_ADDR'], 100, ' ') +
            (Number(item['QTY']) >= 0 ? 
            lpadByteOfStr(item['QTY'].toFixed(3), 15, '0') :
            '-' + lpadByteOfStr(item['QTY'].toFixed(3), 14, '0' )) +
            rpadByteOfStr(
                (carOutbound.data.length === 0 &&
                    carEtcOutbound.data.length === 0) &&
                    (idx + 1 === arr.length) ? '1' : `0\n`)
        ), value)
        
        value = carOutbound.data.reduce((result, item, idx, arr) => (
            result +
            '05' + 
            rpadByteOfStr(item['OILTRD_CD'], 10, ' ') +
            rpadByteOfStr(item['WEEK_OF_YEAR'], 6, ' ') + 
            rpadByteOfStr(item['OILTRD_GDS_CD'], 2, ' ') +
            rpadByteOfStr(item['UNIT'], 2, ' ') +
            rpadByteOfStr(item['CAR_SEQ'], 2, ' ') +
            rpadByteOfStr(item['CAR_CD'], 4, ' ') +
            (Number(item['QTY']) >= 0 ? 
            lpadByteOfStr(item['QTY'].toFixed(3), 15, '0') :
            '-' + lpadByteOfStr(item['QTY'].toFixed(3), 14, '0' )) +
            rpadByteOfStr(
                (carEtcOutbound.data.length === 0) &&
                    (idx + 1 === arr.length) ? '1' : `0\n`)
        ), value);

        value = carEtcOutbound.data.reduce((result, item, idx, arr) => (
            result +
            '06' + 
            rpadByteOfStr(item['OILTRD_CD'], 10, ' ') +
            rpadByteOfStr(item['WEEK_OF_YEAR'], 6, ' ') + 
            rpadByteOfStr(item['OILTRD_GDS_CD'], 2, ' ') +
            rpadByteOfStr(item['UNIT'], 2, ' ') +
            rpadByteOfStr(item['CAR_ETC_SEQ'], 2, ' ') +
            rpadByteOfStr(item['COND_CD'], 4, ' ') +
            (Number(item['QTY']) >= 0 ? 
            lpadByteOfStr(item['QTY'].toFixed(3), 15, '0') :
            '-' + lpadByteOfStr(item['QTY'].toFixed(3), 14, '0' )) +
            ((idx + 1 === arr.length) ? '1' : `0\n`)
        ), value);

        const fileName = 'C' +
            condition['STA_DATE'].replace(/-/g, '') +
            condition['END_DATE'].replace(/-/g, '') +
            (fileSeq.data.length !== 0 ? lpadByteOfStr(fileSeq.data[0]['REPORT_SEQ'], 3, '0') : lpadByteOfStr('1', 3, '0'));
        const file = new Blob([iconv.encode(value, 'euc-kr')]);
        const a = document.createElement('a');
        a.download = fileName + '.rpt';
        a.href = window.URL.createObjectURL(file);
        a.click();
    }

    const onTransfer = async() => {
        const result = await callProc('UPT_OILTRADEREPORT_FILE_SEQ', condition);

        if(!result.err){
            enqueueSnackbar('저장이 완료되었습니다.');
            setCondition({...condition});
        }
    }

    return (
        // !total ? <ProgressPanel /> :
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => {
                if (((weekOfYear(condition['STA_DATE']) === 53) && (weekOfYear(condition['END_DATE']) === 1)) ||
                    weekOfYear(condition['STA_DATE']) === weekOfYear(condition['END_DATE'])) {
                    setCondition(condition);
                }else{
                    enqueueSnackbar('주차 계산 오류 : 시작일자 // 종료일자 를 확인하세요', {variant: 'error'});
                }
            }}>
                <SearchItem
                    fieldName='WORK_CD'
                    component={(state, setState) => (
                        <ComboBox
                            label='사업장명'
                            selectProc='SLT_WORKPLACEINFO_OIL_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                dispatch('WEEK_OF_YEAR', weekOfYear(value));
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='WEEK_OF_YEAR'
                    component={(state) => (
                        <TextField
                            label='주차'
                            value={state}
                            disabled
                        />
                    )}
                />
            </SearchPanel>
            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab label='사업장 정보' />
                    <Tab label='총괄' />
                    <Tab label='입하' />
                    <Tab label='타사출하' />
                    <Tab label='차량출하' />
                    <Tab label='차량 외 출하' />
                </Tabs>
                <div className={classes.subTabContainer}>
                    <IconButton
                        tooltip='보고서 내려받기'
                        icon={<AttachFileIcon />}
                        disabled={!workplace && !total && !inbound && !carOutbound && !carEtcOutbound}
                        onClick={onClickDown}
                    />
                    <IconButton
                        tooltip='보고서 전송완료'
                        icon={<CloudDoneIcon />}
                        disabled={!workplace && !total && !inbound && !carOutbound && !carEtcOutbound}
                        onClick={onTransfer}
                    />
                </div>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <Workplace data={workplace} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <Total data={total} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 2 })}>
                <Inbound data={inbound} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 3 })}>
                {/* <Outbound data={condition} /> */}
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 4 })}>
                <CarOutbound data={carOutbound} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 5 })}>
                <CarEtcOutbound data={carEtcOutbound} />
            </div>
        </div>
    )
}


const Workplace = ({data}) => {
    return (
        <DataGrid
            dataSet={data}
            // selectProc='SLT_OILTRADEREPORT_WORKPLACE'
            // selectParam={condition}
            title='사업장 정보'
            horizonMode
        >
            <DataColumn fieldName='OILTRD_CD' title='사업장 관리번호' />
            <DataColumn fieldName='OILTRD_AREA_CD' title='지역코드' />
            <DataColumn fieldName='WEEK_OF_YEAR' title='보고주차' />
        </DataGrid>
    )
}

const Total = ({data}) => {
    return (
        <DataGrid
            dataSet={data}
            // selectProc='SLT_OILTRADEREPORT_TOTAL'
            // selectParam={condition}
            title='총괄'
            horizonMode
        >
            <DataColumn fieldName='OILTRD_CD' title='사업장 관리번호' />
            <DataColumn fieldName='WEEK_OF_YEAR' title='보고주차' />
            <DataColumn fieldName='UNIT' title='단위코드' />
            <DataColumn fieldName='BEF_STOCK_QTY' title='주초재고량' />
            <DataColumn fieldName='CUR_IN_QTY' title='입하량' />
            <DataColumn fieldName='CUR_SALE_QTY' title='출하량' />
            <DataColumn fieldName='INC_DEC_QTY' title='증감량' />
            <DataColumn fieldName='SELF_QTY' title='자가소비량' />
            <DataColumn fieldName='CUR_STOCK_QTY' title='주말재고량' />
            <DataColumn fieldName='NOZZLE_QTY' title='주유기주유량' />
        </DataGrid>
    )
}

const Inbound = ({data}) => {
    return (
        <DataGrid
            dataSet={data}
            // selectProc='SLT_OILTRADEREPORT_INBOUND'
            // selectParam={condition}
            title='입하'
            horizonMode
        >
            <DataColumn fieldName='OILTRD_CD' title='사업장 관리번호' />
            <DataColumn fieldName='WEEK_OF_YEAR' title='보고주차' />
            <DataColumn fieldName='UNIT' title='단위코드' />
            <DataColumn fieldName='QTY' title='입하량' />
            <DataColumn fieldName='TRD_SEQ' title='거래순번' />
            <DataColumn fieldName='TRD_DATE' title='거래일자' />
        </DataGrid>
    )
}


const CarOutbound = ({data}) => {
    return (
        <DataGrid
            dataSet={data}
            // selectProc='SLT_OILTRADEREPORT_CAROUTBOUND'
            // selectParam={condition}
            title='차량출하'
            horizonMode
        >
            <DataColumn fieldName='OILTRD_CD' title='사업장 관리번호' />
            <DataColumn fieldName='WEEK_OF_YEAR' title='보고주차' />
            <DataColumn fieldName='UNIT' title='단위코드' />
            <DataColumn fieldName='QTY' title='출하량' />
            <DataColumn fieldName='CAR_SEQ' title='차량출하순번' />
            <DataColumn fieldName='CAR_NM' title='차량' />
        </DataGrid>
    )
}

const CarEtcOutbound = ({data}) => {
    return (
        <DataGrid
            dataSet={data}
            // selectProc='SLT_OILTRADEREPORT_CAR_ETC_OUTBOUND'
            // selectParam={condition}
            title='차량 외 출하'
            horizonMode
        >
            <DataColumn fieldName='OILTRD_CD' title='사업장 관리번호' />
            <DataColumn fieldName='WEEK_OF_YEAR' title='보고주차' />
            <DataColumn fieldName='UNIT' title='단위코드' />
            <DataColumn fieldName='QTY' title='출하량' />
            <DataColumn fieldName='CAR_ETC_SEQ' title='차량외출하순번' />
            <DataColumn fieldName='COND_CD' title='업종코드' />
        </DataGrid>
    )
}

export default OilTradeReport;
