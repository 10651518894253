import React, { useMemo, } from 'react';
import moment from 'moment';
import { makeStyles, Paper, } from '@material-ui/core';
import { DataGrid, DataColumn, DatePicker, IconButton, ComboBox, NumberField, } from '../../component';
import SearchIcon from '@material-ui/icons/Search';
import { isFieldDiff, useFieldInputs } from '../../common/Utils';

const useStyle = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    searchPaper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    searchContainer: {
        flex: 1,
        display: 'inline-block',
        flexDirection: 'row',
    },
    searchItem: {
        display: 'inline-block',
        width: 200,
        marginRight: 100,
        marginBottom: theme.spacing(1),
    },
}))

const Achieve = () => {
    const classes = useStyle();
    const [input, setInput] = useFieldInputs({
        BIZ_DATE:  moment().format('YYYY-MM-DD')
    });
    const condition = useMemo(() => input['BIZ_DATE'] && input, [input]);

    const gridRefresh = () => {
        setInput({ type: 'UPDATE', value: { ...input } });
    }

    const getFirstDay = value => value.substring(0, 8) + '01';
    const getLastDay = value => (
        moment(getFirstDay(value), 'YYYY-MM-DD')
        .add(1, 'month')
        .add(-1, 'day')
        .format('YYYY-MM-DD')
    );

    return(
        <div className={classes.container}>
            <Paper className={classes.searchPaper}>
                <div className={classes.searchContainer}>
                    <div className={classes.searchItem}>
                        <DatePicker
                            label='영업일'
                            value={condition && condition['BIZ_DATE']}
                            onChange={value => {
                                if (input['BIZ_DATE'] !== value) {
                                    setInput('BIZ_DATE', value);
                                }
                            }}
                        />
                    </div>
                </div>
                <IconButton
                    tooltip=''
                    icon={<SearchIcon />}
                    onClick={gridRefresh}
                />
            </Paper>

            <DataGrid
                title='목표량'
                table='TB_ACHIEVE'
                selectProc='SLT_ACHIEVE'
                insertProc='INS_ACHIEVE'
                updateProc='UPT_ACHIEVE'
                deleteProc='DLT_ACHIEVE'
                selectParam={condition}
                selectionMode='single'
                horizonMode
            >
                <DataColumn
                    fieldName='WORK_CD'
                    title='사업장명'
                    value={row => row['WORK_NM']}
                    editable={(state, dispatch) => (
                        <ComboBox
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                    
                />
                <DataColumn
                    fieldName='STA_DATE'
                    defaultValue={condition && condition['BIZ_DATE']}
                    editable={(state, dispatch) => (
                        <DatePicker
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                />
                <DataColumn
                    fieldName='END_DATE'
                    editable={(state, dispatch, rowState, setField) => (
                        <DatePicker
                            minDate={rowState['STA_DATE']}
                            maxDate={getLastDay(rowState['STA_DATE'])}
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                />
                <DataColumn
                    fieldName='GDS_CD'
                    title='상품명'
                    value={row => row['GDS_NM']}
                    editable={(state, dispatch, rowState, setField) => {
                        let param = rowState['GDS_PARAM'] || null;
                        if (isFieldDiff(['WORK_CD'], rowState, param)) {
                            param = { WORK_CD: rowState['WORK_CD']};
                            setField('GDS_PARAM', param);
                        }
                        return (
                            <ComboBox
                                style={{ width: 150 }}
                                selectProc='SLT_GOODSINFO_GAUGE_ITEM_SERACHPANEL'
                                selectParam={param}
                                keyField='GDS_CD'
                                valueField='GDS_NM'
                                value={state}
                                onChange={value => dispatch(value)}
                            />
                        )
                    }}
                />
                <DataColumn
                    fieldName='ACH_QTY'
                    editable={(state, dispatch) => (
                        <NumberField
                            style={{width: 100}}
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                />
                <DataColumn
                    fieldName='WORK_NM'
                    visible={false}
                />
                <DataColumn
                    fieldName='GDS_NM'
                    visible={false}
                />
            </DataGrid>

        </div>
    )
}

export default Achieve