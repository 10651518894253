import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle, Fragment, useMemo } from 'react';
import { ComboBox, ProgressPanel, DatePicker, NumberField, PaperTitle, IconButton } from '../../component';
import { Paper, makeStyles,TextField } from '@material-ui/core';
import { callProc } from '../../common/DBConnector';
import { useSnackbar } from 'notistack';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { useFieldInputs } from '../../common/Utils';
import { useSelector } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';


const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 96px)',
    },
    paperTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    insertBox : {
        height: '100%',
        paddingTop: theme.spacing(2),
        overflowY: 'auto',
    },
    field : {
        paddingBottom: theme.spacing(1),
        marginRight: theme.spacing(4),
        display: 'inline-block',
    },
    fieldContent : {
        display: 'inline-block',
        width: 450,
    },
    paperContent: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: 'calc(100% - 62px)',
        padding: theme.spacing(4),
        paddingTop: 0,
    },
    btnContainer: {
        display: 'flex',
        alignSelf: 'flex-end',
        marginTop: 'auto',
        padding: theme.spacing(2),
    },
    unit: {
        width: 100,
    },
    searchPaper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    searchContainer: {
        flex: 1,
        display: 'inline-block',
        flexDirection: 'row',
    },
    searchItem: {
        display: 'inline-block',
        width: 200,
        marginRight: 100,
        marginBottom: theme.spacing(1),
    },
}))

const CardCommEnroll = () => {
    const [edit, setEdit] = useState(false);

    const [, updateState] = useState();
    const insData = useRef([]);
    const sumData = useRef();

    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const clsState = useSelector(state => state.clsDateReducer);

    const [input, setInput] = useFieldInputs({
        WORK_CD: sessionStorage['WORK_CD'],
        ACJ_DATE: clsState[sessionStorage['WORK_CD']],
    });

    const condition = useMemo(() => input['WORK_CD'] && input['ACJ_DATE'] && input, [input]);

    const gridRefresh = () => {
        setInput({ type: 'UPDATE', value: { ...input } });
    }

    useEffect(() => {
        const fetchData = async () => {
            insData.current = null;
            forceUpdate();
            insData.current = (await callProc('SLT_CARDCOMM_ENROLL', condition)).data;
            forceUpdate();
        }
        setEdit(false);
        condition && fetchData();
    }, [condition])

    const forceUpdate = () => updateState({});

    const valid = () => {
        if (insData.current.reduce((result, item) => result || item['AMOUNT'] === '', false)) {
            enqueueSnackbar('금액을 입력하여 주세요.', { variant: 'error' });
            return false;
        }
        return true;
    }

    const onInsert = async() => {

        if (valid()) {
            const result = await callProc('INS_CARDCOMM', {
                ...condition,
                JSON: insData.current,
            })
            if (!result.err) {
                gridRefresh();
                enqueueSnackbar('저장이 완료되었습니다.');
            }
        }

    }

    return(
        <div className={classes.container}>
            <Paper className={classes.searchPaper}>
                <div className={classes.searchContainer}>
                    <div className={classes.searchItem}>
                        <ComboBox
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={condition && condition['WORK_CD']}
                            onChange={value => {
                                if (input['WORK_CD'] !== value) {
                                    sessionStorage['WORK_CD'] = value;
                                    setInput('ACJ_DATE', clsState[value]);
                                    setInput('WORK_CD', value);
                                }
                            }}
                        />
                    </div>
                    <div className={classes.searchItem}>
                        <DatePicker
                            label='영업일'
                            minDate={clsState[input['WORK_CD']]}
                            value={condition && condition['ACJ_DATE']}
                            onChange={value => {
                                if (input['ACJ_DATE'] !== value) {
                                    setInput('ACJ_DATE', value);
                                    if(condition && condition['ACJ_DATE'] < clsState[condition['WORK_CD']]) {
                                        setInput({ type: 'UPDATE', value: { ...input, 'ACJ_DATE': value } });
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                <IconButton
                    tooltip=''
                    icon={<SearchIcon />}
                    onClick={gridRefresh}
                />
            </Paper>
            <Paper className={classes.paper}>
                <div className={classes.paperTitle}>
                    <PaperTitle>
                        카드수수료 정산
                    </PaperTitle>
                    {edit ?
                    <div>
                    <IconButton 
                        tooltip='취소'
                        icon={<CloseIcon />}
                        onClick={() => gridRefresh()}
                    />
                    <IconButton
                        tooltip='확인'
                        icon={<CheckIcon />}
                        onClick={onInsert}
                    />
                    </div>:
                    <IconButton
                        tooltip='수정'
                        icon={<EditIcon />}
                        onClick={() => setEdit(true)}
                        disabled={insData.current == null}
                    />}
                </div>
                <div className={classes.paperContent}>
                    <div>
                        <ComboBox
                            className={classes.field}
                            style={{width: 200}}
                            label='사업장명'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={condition && condition['WORK_CD']}
                            disabled
                        />
                        <TextField
                            className={classes.field}
                            label='영업일'
                            value={(condition && condition['ACJ_DATE']) || ''}
                            disabled
                        />
                        <SumPanel
                                ref={sumData}
                                data={insData}
                        />
                    </div>
                    <div className={classes.insertBox}>
                        {!insData.current ? <ProgressPanel /> :
                        insData.current.map((item, idx) => (
                            <EnrollField
                                key={idx}
                                data={item}
                                edit={edit}
                                onDataChange={(field, value) => {
                                    insData.current[idx][field] = value;
                                    sumData.current.forceUpdate();
                                }}
                                idx={idx}
                            />
                        ))}
                    </div>
                </div>
            </Paper>
        </div>
    )
}

const fieldStyle = makeStyles((theme) => ({
    field : {
        paddingBottom: theme.spacing(1),
        marginRight: theme.spacing(4),
        display: 'block',
    },
    fieldContent : {
        display: 'inline-block',
        width: 450,
    },
    paperContent: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: 'calc(100% - 62px)',
        padding: theme.spacing(4),
        paddingTop: 0,
    },
    btnContainer: {
        display: 'flex',
        alignSelf: 'flex-end',
        marginTop: 'auto',
        padding: theme.spacing(2),
    },
    unit: {
        width: 100,
    },
    numField : {
        textAlign: 'right'
    }
}))

const EnrollField = ({ data, edit, onDataChange, idx }) => {
    const classes = fieldStyle();
    const [balData, setBalData] = useFieldInputs(data);
    
    return (
        <div className={classes.field}>
            <div className={classes.fieldContent}>
                {balData['CST_NM']}
                <NumberField
                    value={balData['AMOUNT']}
                    onChange={value => {
                        setBalData('AMOUNT', value);
                        onDataChange('AMOUNT', value);
                    }}
                    style={{ display: 'block' }}
                    disabled={!edit}
                    inputProps={{ tabindex: idx + 1, className: classes.numField }}
                />
            </div>
        </div>
    )
}

const SumPanel = forwardRef(({ data }, ref) => {
    const [, updateState] = useState();
    const forceUpdate = () => updateState({});
    useImperativeHandle(ref, () => ({
        forceUpdate() { forceUpdate() }
    }));

    return (
        <Fragment>
            <NumberField
                style={{marginLeft: 16}}
                label='합산'
                value={data.current != null && data.current.reduce((result, value) => result + Number(value['AMOUNT']), 0 )}
                disabled
            />
        </Fragment>
    )
})

export default CardCommEnroll