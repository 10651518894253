const bizType = {
    0 : '법인',
    1 : '개인',
}

const cstType = {
    0 : '매입처',
    1 : '매출처',
    2 : '매입/매출처'
}

const trdStnd = {
    1 : '출하후',
    2 : '월마감이내',
    3: '단위'
}

const trdType = {
    0 : '일반',
    1 : '외상',
    2 : '선수금'
}

const YNType = {
    0 : 'N',
    1 : 'Y'
}

const stockType = {
    1: '선입선출방식',
    2: '출고처리방식',
}

const cstGroupType = {
    1 : '일반',
    2 : '현금',
}

const orderType = {
    0 : '매입입고',
    1 : '매입판매',
    2 : '이관',
    3 : '판매',
}

const contractType = {
    0 : '할인율',
    1 : '할인금액',
    2 : '협정단가'
} //거래처 단가=계약조건//

const priceStdType = {
    0 : '점두가',
    1 : '셀프단가',
    2 : '면세단가',
    3 : '목표가',
    4 : '사입단가'
}//거래처 단가 - 단가기준//

const dscrateType = {
    0 : '할인',
    1 : '할증'
} //거래처 단가 할인할증구분//

const taxType = {
    '01' : '일반',
    '02' : '영세율',
    '05' : '면세'
}

const pblType = {
    '01' : '영수',
    '02' : '청구'
}

const occType = {
    1: '외상대입금',
    2: '제카드입금',
    3: '어음입금',
    4: '당좌입금',
    5: '유가증권입금',
    6: '수수료',
    7: '간편결제',
    9: '기초잔액',
    10: '외상대 입금(비상품)'

} //발생유형


const tspType = {
    0: '출하처',
    1: '인도처'
} // 수송처타입

const adjType = {
    1: '조정',
    2: '대체'
}

const regType = {
    1: 'POS',
    2: '일반',
    3: '조정/대체',
    4: '초기값',
    5: '전자결제',
    6: '매출조정상세',
    7: '오더가격대체',
    8: '수동엑셀업로드'
}

const inMoneyRegType = {
    1: 'POS',
    2: '일반',
    3: '조정/대체',
    4: '초기값',
    5: '엑셀업로드',
    6: '입금검증(엑셀)'
}

const posType = {
    'SMARTRO': '스마트로',
    'GSPOS': 'GSPOS',
}

const sortedProType = [
    { KEY: 1, VALUE: '매입과세' },
    { KEY: 2, VALUE: '매입현금과세' },
    { KEY: 11, VALUE: '매입현금면세' },
    { KEY: 3, VALUE: '매입카드과세' },
    { KEY: 4, VALUE: '매입면세' },
    { KEY: 5, VALUE: '매입불공제' },
    { KEY: 6, VALUE: '매출과세' },
    { KEY: 7, VALUE: '매출면세' },
    { KEY: 8, VALUE: '출금' },
    { KEY: 9, VALUE: '출금카드' },
    { KEY: 10, VALUE: '출금혼합' },
]

const proType = sortedProType.reduce((result, item) => ({
    ...result,
    [item['KEY']]: item['VALUE']
}), {})

const IOType = {
    1: '일반',
    2: '계좌',
    3: '자동',
    4: '지로'
}

const cstKind = {
    1: '외상',
    2: '비상품 거래처',
    3: '단순 지출처',
    4: '기타',
    5: '신용카드',
    6: '이관거래처',
    7: '자가소비거래처',
    8: '매출보관거래처',
}

const issueLoc = {
    1: '매출',
    2: '오더매입',
    3: '입금',
    4: '출금',
    5: '전자화계결재',
    6: '분개등록',
    7: '기타입금',
    8: '지급수수료',
    9: '수수료정산',
    10: '입/출금전표(입금)',
    11: '입/출금전표(출금)',
    12: '입/출금전표(혼합)',
    13: '계좌이체',
    14: '본사송금',
    15: '전자회계전표 입/출금',
    16: '초기값',
    17: '일반매입',
    18: '매입조정',
    19: '오더매출',
    20: '계좌이체(엑셀)',
    21: '월말 - 시스템전표',
    22: '연말 - 시스템전표',
    23: '연초 - 시스템전표',
}

const pricType = {
    1: '공급가액',
    2: '합계금액',
}

const detPayType = {
    1: '입금',
    2: '출금'
}


const clsAuth = {
    1: '영업장',
    2: '본사',
}

const docState = {
    0: '미확인',
    1: '확인미정',
    2: '미승인',
    3: '보류',
    4: '승인',
    5: '종결문서',
    6: '처리확인',
    7: '파기',
}

const gdsKind = {
    1: '상품',
    2: '비상품',
}

const trnState = {
    0: '수송 미확인',
    1: '수송 확인',
    2: '수송 완료',
}

const inType = {
    1: '입고',
    2: '반환입고'
}

const crtType = {
    1: '개인',
    2: '법인'
}

const rewdType = {
    1: '보상안됨',
    2: '수송용량보상'
}

const bbkType = {
    1: '분류미지정',
    2: '보통예금',
    3: '정기예금',
    4: '퇴직연금',
    5: '장기성예금'
}

const lineType = {
    0: '작성자',
    1: '승인자',
    2: '확인자', 
    3: '처리자',
    4: '처리확인자',
    5: '참조자'
}

export {
    bizType,
    cstType,
    YNType,
    stockType,
    cstGroupType,
    orderType,
    contractType,
    priceStdType,
    dscrateType,
    taxType,
    pblType,
    occType,
    tspType,
    trdStnd,
    trdType,
    adjType,
    regType,
    inMoneyRegType,
    posType,
    proType,
    sortedProType,
    IOType,
    cstKind,
    issueLoc,
    pricType,
    detPayType,
    clsAuth,
    docState,
    gdsKind,
    trnState,
    inType,
    crtType,
    rewdType,
    bbkType,
    lineType
}