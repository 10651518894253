import React, { useState } from 'react';
import moment from 'moment';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DatePicker, DataColumn, } from '../../component';
import {  makeStyles } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
}))

const OutItemlist = () => {
    const [condition, setCondition] = useState(null);
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => {
                setCondition(condition)}}>
                <SearchItem
                    fieldName='WORK_CD'
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onDataLoad={(jsonData, rawData) => [{ WORK_CD: 'ALL', WORK_NM: '전체' }, ...rawData]}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
            </SearchPanel>

            <DataGrid
                title='출고 내역 조회'
                selectProc='SLT_OUT_ITEM_LIST'
                selectParam={condition}
                selectionMode='single'
                totalGroup={['WORK_CD', 'SALE_TYPE', 'GDS_CD']}
                horizonMode
                virtualized
                excelDownload
                sortable
                filterable
                stickyHeader
            >
                <DataColumn
                    fieldName='ETC_CD'
                    visible={false}
                />
                <DataColumn
                    fieldName='WORK_CD'
                    visible={false}
                />
                <DataColumn fieldName='WORK_NM' total={(values, data, group) => group == null ? '합 계' : `(소 계) ${data[0]['WORK_NM']}`}/>
                <DataColumn fieldName='BIZ_DATE' total={(values, data, group) => group == null ? '' : `${data[0]['SALE_TYPE_NM']}`}/>
                <DataColumn fieldName='DATA_SEQ' total={(values, data, group) => group == null ? '' : `${data[0]['GDS_NM']}`}/>
                <DataColumn fieldName='QTY' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            </DataGrid>
        </div>
    )
}
export default OutItemlist;
