import React, { Fragment, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import {  makeStyles } from '@material-ui/core';
import {ComboBox, DatePicker, DataGrid, DataColumn, Tabs, Tab, SearchItem, SearchPanel } from '../../component';
import moment from 'moment';
import { callProc } from '../../common/DBConnector';

const posTypes = {
    0: '스마트로',
    1: 'GS포스',
    2: 'GS포스(엑셀)',
    3: '미래',
    4: '동아',
    5: 'DON세차포스',
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflow: 'auto',
    },
    content: {
        flex: 1,
        height: 0,
    },
    tabbar: {
        marginBottom: theme.spacing(1),
    },
    hide: {
        display: 'none'
    },
    searchItem: {
        display: 'inline-block',
        width: 200,
        marginRight: 100,
        marginBottom: theme.spacing(1),
    },
}))

const PosOriginList = () =>{
    const [condition, setCondition] = useState();

    const [strGdsUnitSmartroData, setStrGdsUnitSmartroData] = useState(); 
    const [cashInOutSmartroData, setCashInOutSmartroData] = useState(); 
    const [addInfoSmartroData, setAddInfoSmartroData] = useState(); 
    const [asyncSheetSmartroData, setAsyncSheetSmartroData] = useState(); 
    const [cashBilSmartroData, setCashBilSmartroData] = useState(); 
    const [clsGaugeSmartroData, setClsGaugeSmartroData] = useState(); 
    const [saleItemSmartroData, setSaleItemSmartroData] = useState(); 
    const [saleSettInfoSmartroData, setSaleSettInfoSmartroData] = useState(); 
    const [slHeaderSmartroData, setSlHeaderSmartroData] = useState(); 
    const [slTenderSmartroData, setSlTenderSmartroData] = useState(); 

    const [strGdsUnitGsposData, setStrGdsUnitGsposData] = useState(); 
    const [cashInOutGsposData, setCashInOutGsposData] = useState(); 
    const [addInfoGsposData, setAddInfoGsposData] = useState(); 
    const [asyncSheetGsposData, setAsyncSheetGsposData] = useState(); 
    const [cashBilGsposData, setCashBilGsposData] = useState(); 
    const [clsGaugeGsposData, setClsGaugeGsposData] = useState(); 
    const [saleItemGsposData, setSaleItemGsposData] = useState(); 
    const [saleSettInfoGsposData, setSaleSettInfoGsposData] = useState(); 
    const [slHeaderGsposData, setSlHeaderGsposData] = useState(); 
    const [slTenderGsposData, setSlTenderGsposData] = useState(); 

    const [cardInGsposExcelData , setCardInGsposExcelData] = useState(); 
    const [cashReceptGsposExcelData , setCashReceptGsposExcelData] = useState(); 
    const [gaugeGsposExcelData , setGaugeGsposExcelData] = useState(); 
    const [inMoneyGsposExcelData , setInMoneyGsposExcelData] = useState(); 
    const [saleGsposExcelData , setSaleGsposExcelData] = useState(); 
    
    const [cashMiraeData , setCashMiraeData] = useState(); 
    const [closingMiraeData , setClosingMiraeData] = useState(); 
    const [creditCardMiraeData , setCreditCardMiraeData] = useState(); 
    const [saleDataMiraeData , setSaleDataMiraeData] = useState(); 

    const [crdPayOutputDataDoncwData , setCrdPayOutputDataDoncwData] = useState(); 
    const [cshPayOutputDataDoncwData , setCshPayOutputDataDoncwData] = useState(); 

    const classes = useStyles();


    useEffect(() => {
        const fetchData = async () => {
            if(condition && condition['POS_TYPE'] === '0') {
                // 스마트로
                setStrGdsUnitSmartroData(null)
                setCashInOutSmartroData(null)
                setAddInfoSmartroData(null)
                setAsyncSheetSmartroData(null)
                setCashBilSmartroData(null)
                setClsGaugeSmartroData(null)
                setSaleItemSmartroData(null)
                setSaleSettInfoSmartroData(null)
                setSlHeaderSmartroData(null)
                setSlTenderSmartroData(null)

                setStrGdsUnitSmartroData(await callProc('SLT_STRGDSUNIT_SMARTRO', condition))
                setCashInOutSmartroData(await callProc('SLT_CASHINOUT_SMARTRO', condition))
                setAddInfoSmartroData(await callProc('SLT_ADDINFO_SMARTRO', condition))
                setAsyncSheetSmartroData(await callProc('SLT_ASYNCSHEET_SMARTRO', condition))
                setCashBilSmartroData(await callProc('SLT_CASHBIL_SMARTRO', condition))
                setClsGaugeSmartroData(await callProc('SLT_CLSGAUGE_SMARTRO', condition))
                setSaleItemSmartroData(await callProc('SLT_SALEITEM_SMARTRO', condition))
                setSaleSettInfoSmartroData(await callProc('SLT_SALESETTINFO_SMARTRO', condition))
                setSlHeaderSmartroData(await callProc('SLT_SLHEADER_SMARTRO', condition))
                setSlTenderSmartroData(await callProc('SLT_SLTENDER_SMARTRO', condition))

            } else if(condition && condition['POS_TYPE'] === '1'){
                // GS포스
                setStrGdsUnitGsposData(null)
                setCashInOutGsposData(null)
                setAddInfoGsposData(null)
                setAsyncSheetGsposData(null)
                setCashBilGsposData(null)
                setClsGaugeGsposData(null)
                setSaleItemGsposData(null)
                setSaleSettInfoGsposData(null)
                setSlHeaderGsposData(null)
                setSlTenderGsposData(null)

                setStrGdsUnitGsposData(await callProc('SLT_STRGDSUNIT_GSPOS', condition))
                setCashInOutGsposData(await callProc('SLT_CASHINOUT_GSPOS', condition))
                setAddInfoGsposData(await callProc('SLT_ADDINFO_GSPOS', condition))
                setAsyncSheetGsposData(await callProc('SLT_ASYNCSHEET_GSPOS', condition))
                setCashBilGsposData(await callProc('SLT_CASHBIL_GSPOS', condition))
                setClsGaugeGsposData(await callProc('SLT_CLSGAUGE_GSPOS', condition))
                setSaleItemGsposData(await callProc('SLT_SALEITEM_GSPOS', condition))
                setSaleSettInfoGsposData(await callProc('SLT_SALESETTINFO_GSPOS', condition))
                setSlHeaderGsposData(await callProc('SLT_SLHEADER_GSPOS', condition))
                setSlTenderGsposData(await callProc('SLT_SLTENDER_GSPOS', condition))

            } else if(condition && condition['POS_TYPE'] === '2'){
                // GS포스 엑셀
                setCardInGsposExcelData(null)
                setCashReceptGsposExcelData(null)
                setGaugeGsposExcelData(null)
                setInMoneyGsposExcelData(null)
                setSaleGsposExcelData(null)

                setCardInGsposExcelData(await callProc('SLT_CARD_IN_GSPOS_EXCEL', condition))
                setCashReceptGsposExcelData(await callProc('SLT_CASHRECEPT_GSPOS_EXCEL', condition))
                setGaugeGsposExcelData(await callProc('SLT_GAUGE_GSPOS_EXCEL', condition))
                setInMoneyGsposExcelData(await callProc('SLT_INMONEY_GSPOS_EXCEL', condition))
                setSaleGsposExcelData(await callProc('SLT_SALE_GSPOS_EXCEL', condition))

            }else if(condition && condition['POS_TYPE'] === '3'){
                // 미래
                setCashMiraeData(null)
                setClosingMiraeData(null)
                setCreditCardMiraeData(null)
                setSaleDataMiraeData(null)

                setCashMiraeData(await callProc('SLT_FOS_CASH_MIRAE', condition))
                setClosingMiraeData(await callProc('SLT_FOS_CLOSING_MIRAE', condition))
                setCreditCardMiraeData(await callProc('SLT_FOS_CREDITCARD_MIRAE', condition))
                setSaleDataMiraeData(await callProc('SLT_FOS_SALEDATA_MIRAE', condition))

            }else if(condition && condition['POS_TYPE'] === '4'){
                // 동아

            }else if(condition && condition['POS_TYPE'] === '5'){
                // 디온세차포스
                setCrdPayOutputDataDoncwData(null)
                setCshPayOutputDataDoncwData(null)

                setCrdPayOutputDataDoncwData(await callProc('SLT_CRD_PAY_OUTPUT_DATA_DONCW', condition))
                setCshPayOutputDataDoncwData(await callProc('SLT_CSH_PAY_OUTPUT_DATA_DONCW', condition))
            }

            
        }
        condition && fetchData();
    }, [condition])


    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => setCondition(condition)}>
                <SearchItem
                    fieldName='WORK_CD'
                    component={(state, setState) => (
                        <ComboBox
                            label='사업장명'
                            selectProc='SLT_WORKPLACEINFO_OIL_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='BIZ_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState) => (
                        <DatePicker
                            label='영업일'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='POS_TYPE'
                    defaultValue={Object.keys(posTypes)[0]}
                    component={(state, setState) => (
                        <ComboBox
                            label='POS'
                            data={posTypes}
                            value={state}
                            onChange={(value) => {
                                setState(value);
                            }}
                        />
                    )}
                />
            </SearchPanel>
            
            <TapSecBar 
                condition={condition} 

                strGdsUnitSmartroData={strGdsUnitSmartroData}
                cashInOutSmartroData={cashInOutSmartroData}
                addInfoSmartroData={addInfoSmartroData}
                asyncSheetSmartroData={asyncSheetSmartroData}
                cashBilSmartroData={cashBilSmartroData}
                clsGaugeSmartroData={clsGaugeSmartroData}
                saleItemSmartroData={saleItemSmartroData}
                saleSettInfoSmartroData={saleSettInfoSmartroData}
                slHeaderSmartroData={slHeaderSmartroData}
                slTenderSmartroData={slTenderSmartroData}

                strGdsUnitGsposData={strGdsUnitGsposData}
                cashInOutGsposData={cashInOutGsposData}
                addInfoGsposData={addInfoGsposData}
                asyncSheetGsposData={asyncSheetGsposData}
                cashBilGsposData={cashBilGsposData}
                clsGaugeGsposData={clsGaugeGsposData}
                saleItemGsposData={saleItemGsposData}
                saleSettInfoGsposData={saleSettInfoGsposData}
                slHeaderGsposData={slHeaderGsposData}
                slTenderGsposData={slTenderGsposData}

                cardInGsposExcelData={cardInGsposExcelData}
                cashReceptGsposExcelData={cashReceptGsposExcelData}
                gaugeGsposExcelData={gaugeGsposExcelData}
                inMoneyGsposExcelData={inMoneyGsposExcelData}
                saleGsposExcelData={saleGsposExcelData}

                cashMiraeData={cashMiraeData}
                closingMiraeData={closingMiraeData}
                creditCardMiraeData={creditCardMiraeData}
                saleDataMiraeData={saleDataMiraeData}

                crdPayOutputDataDoncwData={crdPayOutputDataDoncwData}
                cshPayOutputDataDoncwData={cshPayOutputDataDoncwData}

            />
            
        </div>
    )
}

const TapSecBar = ({condition, 
        strGdsUnitSmartroData, 
        cashInOutSmartroData, 
        addInfoSmartroData, 
        asyncSheetSmartroData, 
        cashBilSmartroData, 
        clsGaugeSmartroData, 
        saleItemSmartroData, 
        saleSettInfoSmartroData, 
        slHeaderSmartroData, 
        slTenderSmartroData, 
        strGdsUnitGsposData, 
        cashInOutGsposData, 
        addInfoGsposData, 
        asyncSheetGsposData, 
        cashBilGsposData, 
        clsGaugeGsposData, 
        saleItemGsposData, 
        saleSettInfoGsposData, 
        slHeaderGsposData, 
        slTenderGsposData, 
        cardInGsposExcelData, 
        cashReceptGsposExcelData, 
        gaugeGsposExcelData, 
        inMoneyGsposExcelData, 
        saleGsposExcelData, 
        cashMiraeData, 
        closingMiraeData, 
        creditCardMiraeData, 
        saleDataMiraeData, 
        crdPayOutputDataDoncwData, 
        cshPayOutputDataDoncwData, 
    }) => {
    const [selectTab, setSelectTab] = useState(0);

    const classes = useStyles();

    useEffect(() => {
        if(condition && condition['POS_TYPE'] === '0') {
            setSelectTab(0)
        } else if(condition && condition['POS_TYPE'] === '1'){
            setSelectTab(10)
        } else if(condition && condition['POS_TYPE'] === '2'){
            setSelectTab(20)
        }else if(condition && condition['POS_TYPE'] === '3'){
            setSelectTab(25)
        }else if(condition && condition['POS_TYPE'] === '4'){

        }else if(condition && condition['POS_TYPE'] === '5'){
            setSelectTab(29)
        }
    }, [condition])

    return (
        <Fragment>
            <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                <Tab label='t_mst_strgdsunit_smartro' className={(condition && condition['POS_TYPE'] !== '0') && classes.hide} />
                <Tab label='t_rdr_cashinout_smartro' className={(condition && condition['POS_TYPE'] !== '0') && classes.hide}/>
                <Tab label='t_sal_addinfo_smartro' className={(condition && condition['POS_TYPE'] !== '0') && classes.hide}/>
                <Tab label='t_sal_asyncsheet_smartro' className={(condition && condition['POS_TYPE'] !== '0') && classes.hide}/>
                <Tab label='t_sal_cashbil_smartro' className={(condition && condition['POS_TYPE'] !== '0') && classes.hide}/>
                <Tab label='t_sal_clsgauge_smartro' className={(condition && condition['POS_TYPE'] !== '0') && classes.hide}/>
                <Tab label='t_sal_saleitem_smartro' className={(condition && condition['POS_TYPE'] !== '0') && classes.hide}/>
                <Tab label='t_sal_salesettinfo_smartro' className={(condition && condition['POS_TYPE'] !== '0') && classes.hide}/>
                <Tab label='t_sal_slheader_smartro' className={(condition && condition['POS_TYPE'] !== '0') && classes.hide}/>
                <Tab label='t_sal_sltender_smartro' className={(condition && condition['POS_TYPE'] !== '0') && classes.hide}/>

                <Tab label='t_mst_strgdsunit_gspos' className={(condition && condition['POS_TYPE'] !== '1') && classes.hide}/>
                <Tab label='t_rdr_cashinout_gspos' className={(condition && condition['POS_TYPE'] !== '1') && classes.hide}/>
                <Tab label='t_sal_addinfo_gspos' className={(condition && condition['POS_TYPE'] !== '1') && classes.hide}/>
                <Tab label='t_sal_asyncsheet_gspos' className={(condition && condition['POS_TYPE'] !== '1') && classes.hide}/>
                <Tab label='t_sal_cashbil_gspos' className={(condition && condition['POS_TYPE'] !== '1') && classes.hide}/>
                <Tab label='t_sal_clsgauge_gspos' className={(condition && condition['POS_TYPE'] !== '1') && classes.hide}/>
                <Tab label='t_sal_saleitem_gspos' className={(condition && condition['POS_TYPE'] !== '1') && classes.hide}/>
                <Tab label='t_sal_salesettinfo_gspos' className={(condition && condition['POS_TYPE'] !== '1') && classes.hide}/>
                <Tab label='t_sal_slheader_gspos' className={(condition && condition['POS_TYPE'] !== '1') && classes.hide}/>
                <Tab label='t_sal_sltender_gspos' className={(condition && condition['POS_TYPE'] !== '1') && classes.hide}/>

                <Tab label='tb_card_in_gspos_excel' className={(condition && condition['POS_TYPE'] !== '2') && classes.hide}/>
                <Tab label='tb_cashrecept_gspos_excel' className={(condition && condition['POS_TYPE'] !== '2') && classes.hide}/>
                <Tab label='tb_gauge_gspos_excel' className={(condition && condition['POS_TYPE'] !== '2') && classes.hide}/>
                <Tab label='tb_inmoney_gspos_excel' className={(condition && condition['POS_TYPE'] !== '2') && classes.hide}/>
                <Tab label='tb_sale_gspos_excel' className={(condition && condition['POS_TYPE'] !== '2') && classes.hide}/>

                <Tab label='cash_mirae' className={(condition && condition['POS_TYPE'] !== '3') && classes.hide}/>
                <Tab label='closing_mirae' className={(condition && condition['POS_TYPE'] !== '3') && classes.hide}/>
                <Tab label='creditcard_mirae' className={(condition && condition['POS_TYPE'] !== '3') && classes.hide}/>
                <Tab label='saledata_mirae' className={(condition && condition['POS_TYPE'] !== '3') && classes.hide}/>

                <Tab label='crd_pay_output_data_doncw' className={(condition && condition['POS_TYPE'] !== '5') && classes.hide}/>
                <Tab label='csh_pay_output_data_doncw' className={(condition && condition['POS_TYPE'] !== '5') && classes.hide}/>
            </Tabs>
            {/* 스마트로 */}
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <StrGdsUnitSmartro strGdsUnitSmartroData={strGdsUnitSmartroData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <CashInOutSmartro cashInOutSmartroData={cashInOutSmartroData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 2 })}>
                <AddInfoSmartro addInfoSmartroData={addInfoSmartroData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 3 })}>
                <AsyncSheetSmartro asyncSheetSmartroData={asyncSheetSmartroData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 4 })}>
                <CashBilSmartro cashBilSmartroData={cashBilSmartroData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 5 })}>
                <ClsGaugeSmartro clsGaugeSmartroData={clsGaugeSmartroData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 6 })}>
                <SaleItemSmartro saleItemSmartroData={saleItemSmartroData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 7 })}>
                <SaleSettInfoSmartro saleSettInfoSmartroData={saleSettInfoSmartroData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 8 })}>
                <SlHeaderSmartro slHeaderSmartroData={slHeaderSmartroData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 9 })}>
                <SlTenderSmartro slTenderSmartroData={slTenderSmartroData} />
            </div>
            {/* GS포스 */}
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 10 })}>
                <StrGdsUnitGspos strGdsUnitGsposData={strGdsUnitGsposData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 11 })}>
                <CashInOutGspos cashInOutGsposData={cashInOutGsposData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 12 })}>
                <AddInfoGspos addInfoGsposData={addInfoGsposData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 13 })}>
                <AsyncSheetGspos asyncSheetGsposData={asyncSheetGsposData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 14 })}>
                <CashBilGspos cashBilGsposData={cashBilGsposData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 15 })}>
                <ClsGaugeGspos clsGaugeGsposData={clsGaugeGsposData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 16 })}>
                <SaleItemGspos saleItemGsposData={saleItemGsposData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 17 })}>
                <SaleSettInfoGspos saleSettInfoGsposData={saleSettInfoGsposData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 18 })}>
                <SlHeaderGspos slHeaderGsposData={slHeaderGsposData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 19 })}>
                <SlTenderGspos slTenderGsposData={slTenderGsposData} />
            </div>
            {/* GS포스엑셀 */}
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 20 })}>
                <CardInGsposExcel cardInGsposExcelData={cardInGsposExcelData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 21 })}>
                <CashReceptGsposExcel cashReceptGsposExcelData={cashReceptGsposExcelData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 22 })}>
                <GaugeGsposExcel gaugeGsposExcelData={gaugeGsposExcelData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 23 })}>
                <InMoneyGsposExcel inMoneyGsposExcelData={inMoneyGsposExcelData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 24 })}>
                <SaleGsposExcel saleGsposExcelData={saleGsposExcelData} />
            </div>
            {/* 미래 */}
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 25 })}>
                <CashMirae cashMiraeData={cashMiraeData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 26 })}>
                <ClosingMirae closingMiraeData={closingMiraeData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 27 })}>
                <CreditCardMirae creditCardMiraeData={creditCardMiraeData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 28 })}>
                <SaleDataMirae saleDataMiraeData={saleDataMiraeData} />
            </div>
            {/* 디온세차포스 */}
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 29 })}>
                <CrdPayOutputDataDoncw crdPayOutputDataDoncwData={crdPayOutputDataDoncwData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 30 })}>
                <CshPayOutputDataDoncw cshPayOutputDataDoncwData={cshPayOutputDataDoncwData} />
            </div>
        </Fragment>
    )
}

const StrGdsUnitSmartro = ({ strGdsUnitSmartroData }) => {

    const grid = useMemo(() => (
        <DataGrid
            title='t_mst_strgdsunit_smartro'
            dataSet={strGdsUnitSmartroData}
            horizonMode
            virtualized
            stickyHeader
        >
            <DataColumn fieldName='WORK_NM' title='사업장명' />
            <DataColumn fieldName='GOODS_CD' title='GOODS_CD' />
            <DataColumn fieldName='UNIT_APPLY_DT_FR' title='UNIT_APPLY_DT_FR' />
            <DataColumn fieldName='STORE_CD' title='STORE_CD' />
            <DataColumn fieldName='UNIT_APPLY_DT_TO' title='UNIT_APPLY_DT_TO' />
            <DataColumn fieldName='STOREPRC_AMT' title='STOREPRC_AMT' />
            <DataColumn fieldName='SELF_UNIT' title='SELF_UNIT' />
            <DataColumn fieldName='TAXFREE_UNIT' title='TAXFREE_UNIT' />
            <DataColumn fieldName='SAIP_UNIT' title='SAIP_UNIT' />
            <DataColumn fieldName='TEMPO_AMT1' title='TEMPO_AMT1' />
            <DataColumn fieldName='TEMPO_AMT2' title='TEMPO_AMT2' />
            <DataColumn fieldName='TEMPO_AMT3' title='TEMPO_AMT3' />
            <DataColumn fieldName='DEL_YN' title='DEL_YN' />
            <DataColumn fieldName='REG_DT' title='REG_DT' />
            <DataColumn fieldName='REG_NO_EMP' title='REG_NO_EMP' />
            <DataColumn fieldName='CHG_DT' title='CHG_DT' />
            <DataColumn fieldName='CHG_NO_EMP' title='CHG_NO_EMP' />

            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
    ), [strGdsUnitSmartroData]);

    return grid;

}

const CashInOutSmartro = ({ cashInOutSmartroData }) => {

    const grid = useMemo(() => (
        <DataGrid
            title='t_rdr_cashinout_smartro'
            dataSet={cashInOutSmartroData}
            horizonMode
            virtualized
            stickyHeader
        >
            <DataColumn fieldName='WORK_NM' title='사업장명' />
            <DataColumn fieldName='SLIP_NO_INOUTMONEY' title='SLIP_NO_INOUTMONEY' />
            <DataColumn fieldName='STORE_CD' title='STORE_CD' />
            <DataColumn fieldName='BIZHOUR_DATE' title='BIZHOUR_DATE' />
            <DataColumn fieldName='REG_CD' title='REG_CD' />
            <DataColumn fieldName='CUST_CD' title='CUST_CD' />
            <DataColumn fieldName='CUST_CD_REP' title='CUST_CD_REP' />
            <DataColumn fieldName='INOUT_CHK' title='INOUT_CHK' />
            <DataColumn fieldName='INOUT_CD_TYPE' title='INOUT_CD_TYPE' />
            <DataColumn fieldName='INOUT_AMT' title='INOUT_AMT' />
            <DataColumn fieldName='STATE_CD' title='STATE_CD' />
            <DataColumn fieldName='CONFM_YN' title='CONFM_YN' />
            <DataColumn fieldName='PYMT_CD' title='PYMT_CD' />
            <DataColumn fieldName='BUYCOMP_CD_CARD' title='BUYCOMP_CD_CARD' />
            <DataColumn fieldName='CARD_NO' title='CARD_NO' />
            <DataColumn fieldName='APPRV_CD_TYPE' title='APPRV_CD_TYPE' />
            <DataColumn fieldName='APPRV_NO' title='APPRV_NO' />
            <DataColumn fieldName='APPR_DT' title='APPR_DT' />
            <DataColumn fieldName='BIL_NO' title='BIL_NO' />
            <DataColumn fieldName='OFFSET_CD' title='OFFSET_CD' />
            <DataColumn fieldName='RCPT_CD_EMP' title='RCPT_CD_EMP' />
            <DataColumn fieldName='SALESLIP_NO' title='SALESLIP_NO' />
            <DataColumn fieldName='FEESLIP_NO' title='FEESLIP_NO' />
            <DataColumn fieldName='PYMT_SEQ_NO' title='PYMT_SEQ_NO' />
            <DataColumn fieldName='SEND_YN_HQ' title='SEND_YN_HQ' />
            <DataColumn fieldName='SLIP_NO_SEND' title='SLIP_NO_SEND' />
            <DataColumn fieldName='SLIP_NO_ORG_INOUT' title='SLIP_NO_ORG_INOUT' />
            <DataColumn fieldName='REFBIZ_DATE' title='REFBIZ_DATE' />
            <DataColumn fieldName='BANK_CD' title='BANK_CD' />
            <DataColumn fieldName='ACCT_NO' title='ACCT_NO' />
            <DataColumn fieldName='REMIT_DESC' title='REMIT_DESC' />
            <DataColumn fieldName='REFER_TXT' title='REFER_TXT' />
            <DataColumn fieldName='DEL_YN' title='DEL_YN' />
            <DataColumn fieldName='REG_DT' title='REG_DT' />
            <DataColumn fieldName='REG_NO_EMP' title='REG_NO_EMP' />
            <DataColumn fieldName='CHG_DT' title='CHG_DT' />
            <DataColumn fieldName='CHG_NO_EMP' title='CHG_NO_EMP' />

            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
    ), [cashInOutSmartroData]);

    return grid;


}
const AddInfoSmartro = ({ addInfoSmartroData }) => {

    const grid = useMemo(() => (
        <DataGrid
            title='t_sal_addinfo_smartro'
            dataSet={addInfoSmartroData}
            horizonMode
            virtualized
            stickyHeader
        >
            <DataColumn fieldName='WORK_NM' title='사업장명' />
            <DataColumn fieldName='SALESLIP_NO' title='SALESLIP_NO' />
            <DataColumn fieldName='BIZHOUR_DATE' title='BIZHOUR_DATE' />
            <DataColumn fieldName='STORE_CD' title='STORE_CD' />
            <DataColumn fieldName='APPLY_YN_BONUS' title='APPLY_YN_BONUS' />
            <DataColumn fieldName='CASHSHEET_YN_APPLY' title='CASHSHEET_YN_APPLY' />
            <DataColumn fieldName='HOMECAR_NO' title='HOMECAR_NO' />
            <DataColumn fieldName='ARRIVE_NM' title='ARRIVE_NM' />
            <DataColumn fieldName='CAR_CD_ITEM' title='CAR_CD_ITEM' />
            <DataColumn fieldName='DRIVER_NM' title='DRIVER_NM' />
            <DataColumn fieldName='EMP_NO' title='EMP_NO' />
            <DataColumn fieldName='USE_CD_SELF' title='USE_CD_SELF' />
            <DataColumn fieldName='SHIPSLIP_NO' title='SHIPSLIP_NO' />
            <DataColumn fieldName='SHIPSLIP_ITEM' title='SHIPSLIP_ITEM' />
            <DataColumn fieldName='RCPT_CD_CHK' title='RCPT_CD_CHK' />
            <DataColumn fieldName='RCPT_YN_PRT' title='RCPT_YN_PRT' />
            <DataColumn fieldName='RCPT_NO' title='RCPT_NO' />
            <DataColumn fieldName='SUBSIDY_CD_SETTLE' title='SUBSIDY_CD_SETTLE' />
            <DataColumn fieldName='TAXFREE_SUPPLY_CD' title='TAXFREE_SUPPLY_CD' />
            <DataColumn fieldName='TAXFREE_TRADE_CD' title='TAXFREE_TRADE_CD' />
            <DataColumn fieldName='TAXFREE_PYMT_DT' title='TAXFREE_PYMT_DT' />
            <DataColumn fieldName='REMARK_TXT' title='REMARK_TXT' />
            <DataColumn fieldName='REG_DT' title='REG_DT' />
            <DataColumn fieldName='REG_NO_EMP' title='REG_NO_EMP' />
            <DataColumn fieldName='CHG_DT' title='CHG_DT' />
            <DataColumn fieldName='CHG_NO_EMP' title='CHG_NO_EMP' />


            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
    ), [addInfoSmartroData]);

    return grid;

}
const AsyncSheetSmartro = ({ asyncSheetSmartroData }) => {

    const grid = useMemo(() => (
        <DataGrid
            title='t_sal_asyncsheet_smartro'
            dataSet={asyncSheetSmartroData}
            horizonMode
            virtualized
            stickyHeader
        >
            <DataColumn fieldName='WORK_NM' title='사업장명' />
            <DataColumn fieldName='BIZHOUR_DATE' title='BIZHOUR_DATE' />
            <DataColumn fieldName='PYMT_TYPE' title='PYMT_TYPE' />
            <DataColumn fieldName='PYMT_DT' title='PYMT_DT' />
            <DataColumn fieldName='STORE_CD' title='STORE_CD' />
            <DataColumn fieldName='STATE_YN' title='STATE_YN' />
            <DataColumn fieldName='NOZZLE_NO' title='NOZZLE_NO' />
            <DataColumn fieldName='GOODS_CD' title='GOODS_CD' />
            <DataColumn fieldName='PYMT_QTY' title='PYMT_QTY' />
            <DataColumn fieldName='PYMT_UNIT' title='PYMT_UNIT' />
            <DataColumn fieldName='PYMT_AMT' title='PYMT_AMT' />
            <DataColumn fieldName='SUPP_AMT' title='SUPP_AMT' />
            <DataColumn fieldName='VAT_AMT' title='VAT_AMT' />
            <DataColumn fieldName='CUSTCHK_CD' title='CUSTCHK_CD' />
            <DataColumn fieldName='COGNITION_NO_ITEM' title='COGNITION_NO_ITEM' />
            <DataColumn fieldName='CUST_NO_CHK' title='CUST_NO_CHK' />
            <DataColumn fieldName='CASHEET_CD_ISSUE' title='CASHEET_CD_ISSUE' />
            <DataColumn fieldName='RESPONSE_CD' title='RESPONSE_CD' />
            <DataColumn fieldName='APPRV_NO' title='APPRV_NO' />
            <DataColumn fieldName='APPR_DT' title='APPR_DT' />
            <DataColumn fieldName='CAT_TID' title='CAT_TID' />
            <DataColumn fieldName='WAIT_TR_NO' title='WAIT_TR_NO' />
            <DataColumn fieldName='REG_DT' title='REG_DT' />
            <DataColumn fieldName='REG_NO_EMP' title='REG_NO_EMP' />
            <DataColumn fieldName='CHG_DT' title='CHG_DT' />
            <DataColumn fieldName='CHG_NO_EMP' title='CHG_NO_EMP' />
            <DataColumn fieldName='VAN_CUST_NM' title='VAN_CUST_NM' />
            <DataColumn fieldName='VAN_CAR_NO' title='VAN_CAR_NO' />

            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
    ), [asyncSheetSmartroData]);

    return grid;


}
const CashBilSmartro = ({ cashBilSmartroData }) => {

    const grid = useMemo(() => (
        <DataGrid
            title='t_sal_cashbil_smartro'
            dataSet={cashBilSmartroData}
            horizonMode
            virtualized
            stickyHeader
        >
            <DataColumn fieldName='WORK_NM' title='사업장명' />
            <DataColumn fieldName='SALESLIP_NO' title='SALESLIP_NO' />
            <DataColumn fieldName='SEQ_NO' title='SEQ_NO' />
            <DataColumn fieldName='STORE_CD' title='STORE_CD' />
            <DataColumn fieldName='BIZHOUR_DATE' title='BIZHOUR_DATE' />
            <DataColumn fieldName='STATE_YN' title='STATE_YN' />
            <DataColumn fieldName='CASHSHEET_AMT' title='CASHSHEET_AMT' />
            <DataColumn fieldName='CUSTCHK_CD' title='CUSTCHK_CD' />
            <DataColumn fieldName='COGNITION_NO_ITEM' title='COGNITION_NO_ITEM' />
            <DataColumn fieldName='CUST_NO_CHK' title='CUST_NO_CHK' />
            <DataColumn fieldName='CASHEET_CD_ISSUE' title='CASHEET_CD_ISSUE' />
            <DataColumn fieldName='RESPONSE_CD' title='RESPONSE_CD' />
            <DataColumn fieldName='APPRV_NO' title='APPRV_NO' />
            <DataColumn fieldName='APPR_DT' title='APPR_DT' />

            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
    ), [cashBilSmartroData]);

    return grid;


}

const ClsGaugeSmartro = ({ clsGaugeSmartroData }) => {

    const grid = useMemo(() => (
        <DataGrid
            title='t_sal_clsgauge_smartro'
            dataSet={clsGaugeSmartroData}
            horizonMode
            virtualized
            stickyHeader
        >
            <DataColumn fieldName='WORK_NM' title='사업장명' />
            <DataColumn fieldName='BIZHOUR_DATE' title='BIZHOUR_DATE' />
            <DataColumn fieldName='NOZZLE_NO' title='NOZZLE_NO' />
            <DataColumn fieldName='STORE_CD' title='STORE_CD' />
            <DataColumn fieldName='CURRDAY_QTY_ELEC' title='CURRDAY_QTY_ELEC' />
            <DataColumn fieldName='BEFDAY_QTY_EGAUGE' title='BEFDAY_QTY_EGAUGE' />
            <DataColumn fieldName='CURRDAY_QTY_EGAUGE' title='CURRDAY_QTY_EGAUGE' />
            <DataColumn fieldName='CURRDAY_QTY_MACH' title='CURRDAY_QTY_MACH' />
            <DataColumn fieldName='BEFDAY_QTY_MGAUGE' title='BEFDAY_QTY_MGAUGE' />
            <DataColumn fieldName='CURRDAY_QTY_MGAUGE' title='CURRDAY_QTY_MGAUGE' />
            <DataColumn fieldName='AGGR_QTY_POS' title='AGGR_QTY_POS' />
            <DataColumn fieldName='GAP_QTY' title='GAP_QTY' />
            <DataColumn fieldName='APPLY_CD_BASE' title='APPLY_CD_BASE' />
            <DataColumn fieldName='GAUGE_QTY_APPLY' title='GAUGE_QTY_APPLY' />
            <DataColumn fieldName='REFER_TXT' title='REFER_TXT' />
            <DataColumn fieldName='REG_DT' title='REG_DT' />
            <DataColumn fieldName='REG_NO_EMP' title='REG_NO_EMP' />
            <DataColumn fieldName='CHG_DT' title='CHG_DT' />
            <DataColumn fieldName='CHG_NO_EMP' title='CHG_NO_EMP' />
            <DataColumn fieldName='APPLY_POS_QTY' title='APPLY_POS_QTY' />
            <DataColumn fieldName='APPLY_DISCONNECT_QTY' title='APPLY_DISCONNECT_QTY' />

            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
    ), [clsGaugeSmartroData]);

    return grid;

}
const SaleItemSmartro = ({ saleItemSmartroData }) => {

    const grid = useMemo(() => (
        <DataGrid
            title='t_sal_saleitem_smartro'
            dataSet={saleItemSmartroData}
            horizonMode
            virtualized
            stickyHeader
        >
            <DataColumn fieldName='WORK_NM' title='사업장명' />
            <DataColumn fieldName='SALESLIP_NO' title='SALESLIP_NO' />
            <DataColumn fieldName='ITEM_SEQ_NO' title='ITEM_SEQ_NO' />
            <DataColumn fieldName='STORE_CD' title='STORE_CD' />
            <DataColumn fieldName='BIZHOUR_DATE' title='BIZHOUR_DATE' />
            <DataColumn fieldName='STATE_YN' title='STATE_YN' />
            <DataColumn fieldName='GOODS_CD_IND' title='GOODS_CD_IND' />
            <DataColumn fieldName='CVSGOODS_CD_IND' title='CVSGOODS_CD_IND' />
            <DataColumn fieldName='GOODS_CD' title='GOODS_CD' />
            <DataColumn fieldName='GOODS_NM' title='GOODS_NM' />
            <DataColumn fieldName='PRODUNIT_CD' title='PRODUNIT_CD' />
            <DataColumn fieldName='TAXFREE_CD' title='TAXFREE_CD' />
            <DataColumn fieldName='LIMIT_QTY' title='LIMIT_QTY' />
            <DataColumn fieldName='UNIT_CD_TYPE' title='UNIT_CD_TYPE' />
            <DataColumn fieldName='NOZZLE_NO' title='NOZZLE_NO' />
            <DataColumn fieldName='REQ_CNT_CUST' title='REQ_CNT_CUST' />
            <DataColumn fieldName='REQ_AMT_CUST' title='REQ_AMT_CUST' />
            <DataColumn fieldName='SALE_QTY' title='SALE_QTY' />
            <DataColumn fieldName='KEEP_QTY' title='KEEP_QTY' />
            <DataColumn fieldName='GAUGE_QTY' title='GAUGE_QTY' />
            <DataColumn fieldName='GAUGE_UNIT' title='GAUGE_UNIT' />
            <DataColumn fieldName='GAUGE_AMT' title='GAUGE_AMT' />
            <DataColumn fieldName='SALE_UNIT' title='SALE_UNIT' />
            <DataColumn fieldName='SALE_AMT' title='SALE_AMT' />
            <DataColumn fieldName='SUPPLY_AMT_SALE' title='SUPPLY_AMT_SALE' />
            <DataColumn fieldName='VAT_AMT_SALE' title='VAT_AMT_SALE' />
            <DataColumn fieldName='DISCNT_CD' title='DISCNT_CD' />
            <DataColumn fieldName='DISCNT_ITEM' title='DISCNT_ITEM' />
            <DataColumn fieldName='DISCNT_AMT_AGREE' title='DISCNT_AMT_AGREE' />
            <DataColumn fieldName='DISCNT_RATE_OPT' title='DISCNT_RATE_OPT' />
            <DataColumn fieldName='BAL_QTY_TOOLSALE' title='BAL_QTY_TOOLSALE' />
            <DataColumn fieldName='STOREPRC_AMT' title='STOREPRC_AMT' />
            <DataColumn fieldName='PROCS_EQP_CD' title='PROCS_EQP_CD' />
            <DataColumn fieldName='ADJ_LIMIT_OVER' title='ADJ_LIMIT_OVER' />
            <DataColumn fieldName='GAUGE_QTY_START' title='GAUGE_QTY_START' />
            <DataColumn fieldName='GAUGE_QTY_END' title='GAUGE_QTY_END' />
            <DataColumn fieldName='PUMP_DT_FR' title='PUMP_DT_FR' />
            <DataColumn fieldName='PUMP_DT_TO' title='PUMP_DT_TO' />
            <DataColumn fieldName='KHPROCS_NO' title='KHPROCS_NO' />
            <DataColumn fieldName='EMPTYBTL_ITEM' title='EMPTYBTL_ITEM' />
            <DataColumn fieldName='EMPTYTBTLE_UNIT' title='EMPTYTBTLE_UNIT' />
            <DataColumn fieldName='PMA_CD' title='PMA_CD' />
            <DataColumn fieldName='BAR_CD_PROD' title='BAR_CD_PROD' />
            <DataColumn fieldName='ORGSEQ_NO_GOODS' title='ORGSEQ_NO_GOODS' />
            <DataColumn fieldName='REG_DT' title='REG_DT' />
            <DataColumn fieldName='CHG_NO_EMP' title='CHG_NO_EMP' />
            <DataColumn fieldName='CHG_DT' title='CHG_DT' />
            <DataColumn fieldName='REG_NO_EMP' title='REG_NO_EMP' />

            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
    ), [saleItemSmartroData]);

    return grid;

}

const SaleSettInfoSmartro = ({ saleSettInfoSmartroData }) => {

    const grid = useMemo(() => (
        <DataGrid
            title='t_sal_salesettinfo_smartro'
            dataSet={saleSettInfoSmartroData}
            horizonMode
            virtualized
            stickyHeader
        >
            <DataColumn fieldName='WORK_NM' title='사업장명' />
            <DataColumn fieldName='SALESLIP_NO' title='SALESLIP_NO' />
            <DataColumn fieldName='SEQ_NO' title='SEQ_NO' />
            <DataColumn fieldName='STORE_CD' title='STORE_CD' />
            <DataColumn fieldName='BIZHOUR_DATE' title='BIZHOUR_DATE' />
            <DataColumn fieldName='ITEM_SEQ_NO' title='ITEM_SEQ_NO' />
            <DataColumn fieldName='PYMT_SEQ_NO' title='PYMT_SEQ_NO' />
            <DataColumn fieldName='STATE_YN' title='STATE_YN' />
            <DataColumn fieldName='PRESENT_AMT_CHK' title='PRESENT_AMT_CHK' />
            <DataColumn fieldName='CLASFY_CD_GOODS' title='CLASFY_CD_GOODS' />
            <DataColumn fieldName='GOODS_CD' title='GOODS_CD' />
            <DataColumn fieldName='PRODUNIT_CD' title='PRODUNIT_CD' />
            <DataColumn fieldName='TAXFREE_CD' title='TAXFREE_CD' />
            <DataColumn fieldName='PYMT_QTY' title='PYMT_QTY' />
            <DataColumn fieldName='PYMT_UNIT' title='PYMT_UNIT' />
            <DataColumn fieldName='PYMT_AMT' title='PYMT_AMT' />
            <DataColumn fieldName='SUPP_AMT' title='SUPP_AMT' />
            <DataColumn fieldName='VAT_AMT' title='VAT_AMT' />
            <DataColumn fieldName='PYMT_CD' title='PYMT_CD' />
            <DataColumn fieldName='CUST_CD' title='CUST_CD' />
            <DataColumn fieldName='REG_DT' title='REG_DT' />
            <DataColumn fieldName='REG_NO_EMP' title='REG_NO_EMP' />
            <DataColumn fieldName='CHG_DT' title='CHG_DT' />
            <DataColumn fieldName='CHG_NO_EMP' title='CHG_NO_EMP' />

            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
    ), [saleSettInfoSmartroData]);

    return grid;

}
const SlHeaderSmartro = ({ slHeaderSmartroData }) => {

    const grid = useMemo(() => (
        <DataGrid
            title='t_sal_slheader_smartro'
            dataSet={slHeaderSmartroData}
            horizonMode
            virtualized
            stickyHeader
        >
            <DataColumn fieldName='WORK_NM' title='사업장명' />
            <DataColumn fieldName='SALESLIP_NO' title='SALESLIP_NO' />
            <DataColumn fieldName='STORE_CD' title='STORE_CD' />
            <DataColumn fieldName='OFFSET_CD' title='OFFSET_CD' />
            <DataColumn fieldName='BIZHOUR_DATE' title='BIZHOUR_DATE' />
            <DataColumn fieldName='POS_NO' title='POS_NO' />
            <DataColumn fieldName='DEAL_NO' title='DEAL_NO' />
            <DataColumn fieldName='DEAL_CD' title='DEAL_CD' />
            <DataColumn fieldName='SLIP_NO_CD' title='SLIP_NO_CD' />
            <DataColumn fieldName='RETURN_ITEM' title='RETURN_ITEM' />
            <DataColumn fieldName='SALE_CD_ITEM' title='SALE_CD_ITEM' />
            <DataColumn fieldName='SALETYPE_CD' title='SALETYPE_CD' />
            <DataColumn fieldName='SALEWAY_CD' title='SALEWAY_CD' />
            <DataColumn fieldName='CONN_ITEM' title='CONN_ITEM' />
            <DataColumn fieldName='PYMT_TYPE' title='PYMT_TYPE' />
            <DataColumn fieldName='PAY_DATE_COMPLT' title='PAY_DATE_COMPLT' />
            <DataColumn fieldName='COMPLT_DT_RECEIVE' title='COMPLT_DT_RECEIVE' />
            <DataColumn fieldName='ITEM_QTY' title='ITEM_QTY' />
            <DataColumn fieldName='PYMT_CNT' title='PYMT_CNT' />
            <DataColumn fieldName='DISCNT_ITEM' title='DISCNT_ITEM' />
            <DataColumn fieldName='SUPPLY_AMT_SELLOUT' title='SUPPLY_AMT_SELLOUT' />
            <DataColumn fieldName='CONFM_AMT_SUPP' title='CONFM_AMT_SUPP' />
            <DataColumn fieldName='CONFM_AMT_VAT' title='CONFM_AMT_VAT' />
            <DataColumn fieldName='DISCNT_AMT_NORM' title='DISCNT_AMT_NORM' />
            <DataColumn fieldName='DISCNT_AMT_FREE' title='DISCNT_AMT_FREE' />
            <DataColumn fieldName='SALE_AMT_TOT' title='SALE_AMT_TOT' />
            <DataColumn fieldName='PYMT_AMT_TOT' title='PYMT_AMT_TOT' />
            <DataColumn fieldName='SMALL_MONEY' title='SMALL_MONEY' />
            <DataColumn fieldName='KEEPDOC_NO' title='KEEPDOC_NO' />
            <DataColumn fieldName='RECALL_NO_SAVEBIL' title='RECALL_NO_SAVEBIL' />
            <DataColumn fieldName='CAT_NO' title='CAT_NO' />
            <DataColumn fieldName='WAIT_TR_NO' title='WAIT_TR_NO' />
            <DataColumn fieldName='PROCS_YN_RESTAMT' title='PROCS_YN_RESTAMT' />
            <DataColumn fieldName='PROCS_DT_RESTAMT' title='PROCS_DT_RESTAMT' />
            <DataColumn fieldName='ORGSALE_DEALDATE' title='ORGSALE_DEALDATE' />
            <DataColumn fieldName='POS_NO_ORGSALE' title='POS_NO_ORGSALE' />
            <DataColumn fieldName='SALE_NO_ORG' title='SALE_NO_ORG' />
            <DataColumn fieldName='REG_DT' title='REG_DT' />
            <DataColumn fieldName='REG_NO_EMP' title='REG_NO_EMP' />
            <DataColumn fieldName='CHG_DT' title='CHG_DT' />
            <DataColumn fieldName='CHG_NO_EMP' title='CHG_NO_EMP' />

            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
    ), [slHeaderSmartroData]);

    return grid;

}

const SlTenderSmartro = ({ slTenderSmartroData }) => {

    const grid = useMemo(() => (
        <DataGrid
            title='t_sal_sltender_smartro'
            dataSet={slTenderSmartroData}
            horizonMode
            virtualized
            stickyHeader
        >
            <DataColumn fieldName='WORK_NM' title='사업장명' />
            <DataColumn fieldName='SALESLIP_NO' title='SALESLIP_NO' />
            <DataColumn fieldName='PYMT_SEQ_NO' title='PYMT_SEQ_NO' />
            <DataColumn fieldName='BIZHOUR_DATE' title='BIZHOUR_DATE' />
            <DataColumn fieldName='STORE_CD' title='STORE_CD' />
            <DataColumn fieldName='STATE_YN' title='STATE_YN' />
            <DataColumn fieldName='PYMT_CD' title='PYMT_CD' />
            <DataColumn fieldName='PYMT_NM' title='PYMT_NM' />
            <DataColumn fieldName='PYMT_AMT' title='PYMT_AMT' />
            <DataColumn fieldName='ADJ_LIMIT_OVER' title='ADJ_LIMIT_OVER' />
            <DataColumn fieldName='CARD_NO' title='CARD_NO' />
            <DataColumn fieldName='CARD_TRACK' title='CARD_TRACK' />
            <DataColumn fieldName='CARD_NO_END' title='CARD_NO_END' />
            <DataColumn fieldName='DSCTERM_NO' title='DSCTERM_NO' />
            <DataColumn fieldName='INPUT_CD_TYPE' title='INPUT_CD_TYPE' />
            <DataColumn fieldName='APPRV_CD_TYPE' title='APPRV_CD_TYPE' />
            <DataColumn fieldName='APPRV_NO' title='APPRV_NO' />
            <DataColumn fieldName='APPR_DT' title='APPR_DT' />
            <DataColumn fieldName='ISSUE_CD_CARD' title='ISSUE_CD_CARD' />
            <DataColumn fieldName='BUYCOMP_CD_CARD' title='BUYCOMP_CD_CARD' />
            <DataColumn fieldName='COMP_CD_VAN' title='COMP_CD_VAN' />
            <DataColumn fieldName='CAT_TID' title='CAT_TID' />
            <DataColumn fieldName='CUST_CD_ITEM' title='CUST_CD_ITEM' />
            <DataColumn fieldName='DEAL_TYPE' title='DEAL_TYPE' />
            <DataColumn fieldName='CUST_CD' title='CUST_CD' />
            <DataColumn fieldName='TEL_NO_CUST' title='TEL_NO_CUST' />
            <DataColumn fieldName='CUST_CD_REP' title='CUST_CD_REP' />
            <DataColumn fieldName='TEAM_CD' title='TEAM_CD' />
            <DataColumn fieldName='DEPT_CD' title='DEPT_CD' />
            <DataColumn fieldName='CAR_NO' title='CAR_NO' />
            <DataColumn fieldName='CARDNO_NO_CUST' title='CARDNO_NO_CUST' />
            <DataColumn fieldName='BONUSCARD_ID' title='BONUSCARD_ID' />
            <DataColumn fieldName='BONUSCARD_NO' title='BONUSCARD_NO' />
            <DataColumn fieldName='PRT_YN_SHEET' title='PRT_YN_SHEET' />
            <DataColumn fieldName='PRT_AMT_SHEET' title='PRT_AMT_SHEET' />
            <DataColumn fieldName='OFFSET_CD' title='OFFSET_CD' />
            <DataColumn fieldName='PYMT_NO_ORGSEQ' title='PYMT_NO_ORGSEQ' />
            <DataColumn fieldName='PROCS_SEQ' title='PROCS_SEQ' />
            <DataColumn fieldName='LIMIT_CD_BASE' title='LIMIT_CD_BASE' />
            <DataColumn fieldName='CUST_LIMIT_REMAIN' title='CUST_LIMIT_REMAIN' />
            <DataColumn fieldName='CUST_LIMIT_USE' title='CUST_LIMIT_USE' />
            <DataColumn fieldName='CARDNO_NO_CARD' title='CARDNO_NO_CARD' />
            <DataColumn fieldName='FRANCH_NO' title='FRANCH_NO' />
            <DataColumn fieldName='REG_DT' title='REG_DT' />
            <DataColumn fieldName='REG_NO_EMP' title='REG_NO_EMP' />
            <DataColumn fieldName='CHG_DT' title='CHG_DT' />
            <DataColumn fieldName='CHG_NO_EMP' title='CHG_NO_EMP' />

            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
    ), [slTenderSmartroData]);

    return grid;

}


const StrGdsUnitGspos = ({ strGdsUnitGsposData }) => {
    const grid = useMemo(() => (
        <DataGrid
            title='t_mst_strgdsunit_gspos'
            dataSet={strGdsUnitGsposData}
            horizonMode
            virtualized
            stickyHeader
        >
            <DataColumn fieldName='WORK_NM' title='사업장명' />
            <DataColumn fieldName='GOODS_CD' title='GOODS_CD' />
            <DataColumn fieldName='UNIT_APPLY_DT_FR' title='UNIT_APPLY_DT_FR' />
            <DataColumn fieldName='STORE_CD' title='STORE_CD' />
            <DataColumn fieldName='UNIT_APPLY_DT_TO' title='UNIT_APPLY_DT_TO' />
            <DataColumn fieldName='STOREPRC_AMT' title='STOREPRC_AMT' />
            <DataColumn fieldName='SELF_UNIT' title='SELF_UNIT' />
            <DataColumn fieldName='TAXFREE_UNIT' title='TAXFREE_UNIT' />
            <DataColumn fieldName='SAIP_UNIT' title='SAIP_UNIT' />
            <DataColumn fieldName='TEMPO_AMT1' title='TEMPO_AMT1' />
            <DataColumn fieldName='TEMPO_AMT2' title='TEMPO_AMT2' />
            <DataColumn fieldName='TEMPO_AMT3' title='TEMPO_AMT3' />
            <DataColumn fieldName='DEL_YN' title='DEL_YN' />
            <DataColumn fieldName='REG_DT' title='REG_DT' />
            <DataColumn fieldName='REG_NO_EMP' title='REG_NO_EMP' />
            <DataColumn fieldName='CHG_DT' title='CHG_DT' />
            <DataColumn fieldName='CHG_NO_EMP' title='CHG_NO_EMP' />

            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
    ), [strGdsUnitGsposData]);

    return grid;
}

const CashInOutGspos = ({ cashInOutGsposData }) => {

    const grid = useMemo(() => (
        <DataGrid
            title='t_rdr_cashinout_gspos'
            dataSet={cashInOutGsposData}
            horizonMode
            virtualized
            stickyHeader
        >
            <DataColumn fieldName='WORK_NM' title='사업장명' />
            <DataColumn fieldName='SLIP_NO_INOUTMONEY' title='SLIP_NO_INOUTMONEY' />
            <DataColumn fieldName='STORE_CD' title='STORE_CD' />
            <DataColumn fieldName='BIZHOUR_DATE' title='BIZHOUR_DATE' />
            <DataColumn fieldName='REG_CD' title='REG_CD' />
            <DataColumn fieldName='CUST_CD' title='CUST_CD' />
            <DataColumn fieldName='CUST_CD_REP' title='CUST_CD_REP' />
            <DataColumn fieldName='INOUT_CHK' title='INOUT_CHK' />
            <DataColumn fieldName='INOUT_CD_TYPE' title='INOUT_CD_TYPE' />
            <DataColumn fieldName='INOUT_AMT' title='INOUT_AMT' />
            <DataColumn fieldName='STATE_CD' title='STATE_CD' />
            <DataColumn fieldName='CONFM_YN' title='CONFM_YN' />
            <DataColumn fieldName='PYMT_CD' title='PYMT_CD' />
            <DataColumn fieldName='BUYCOMP_CD_CARD' title='BUYCOMP_CD_CARD' />
            <DataColumn fieldName='CARD_NO' title='CARD_NO' />
            <DataColumn fieldName='APPRV_CD_TYPE' title='APPRV_CD_TYPE' />
            <DataColumn fieldName='APPRV_NO' title='APPRV_NO' />
            <DataColumn fieldName='APPR_DT' title='APPR_DT' />
            <DataColumn fieldName='BIL_NO' title='BIL_NO' />
            <DataColumn fieldName='OFFSET_CD' title='OFFSET_CD' />
            <DataColumn fieldName='RCPT_CD_EMP' title='RCPT_CD_EMP' />
            <DataColumn fieldName='SALESLIP_NO' title='SALESLIP_NO' />
            <DataColumn fieldName='FEESLIP_NO' title='FEESLIP_NO' />
            <DataColumn fieldName='PYMT_SEQ_NO' title='PYMT_SEQ_NO' />
            <DataColumn fieldName='SEND_YN_HQ' title='SEND_YN_HQ' />
            <DataColumn fieldName='SLIP_NO_SEND' title='SLIP_NO_SEND' />
            <DataColumn fieldName='SLIP_NO_ORG_INOUT' title='SLIP_NO_ORG_INOUT' />
            <DataColumn fieldName='REFBIZ_DATE' title='REFBIZ_DATE' />
            <DataColumn fieldName='BANK_CD' title='BANK_CD' />
            <DataColumn fieldName='ACCT_NO' title='ACCT_NO' />
            <DataColumn fieldName='REMIT_DESC' title='REMIT_DESC' />
            <DataColumn fieldName='REFER_TXT' title='REFER_TXT' />
            <DataColumn fieldName='DEL_YN' title='DEL_YN' />
            <DataColumn fieldName='REG_DT' title='REG_DT' />
            <DataColumn fieldName='REG_NO_EMP' title='REG_NO_EMP' />
            <DataColumn fieldName='CHG_DT' title='CHG_DT' />
            <DataColumn fieldName='CHG_NO_EMP' title='CHG_NO_EMP' />

            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
    ), [cashInOutGsposData]);

    return grid;
}
const AddInfoGspos = ({ addInfoGsposData }) => {

    const grid = useMemo(() => (
        <DataGrid
            title='t_sal_addinfo_gspos'
            dataSet={addInfoGsposData}
            horizonMode
            virtualized
            stickyHeader
        >
            <DataColumn fieldName='WORK_NM' title='사업장명' />
            <DataColumn fieldName='SALESLIP_NO' title='SALESLIP_NO' />
            <DataColumn fieldName='BIZHOUR_DATE' title='BIZHOUR_DATE' />
            <DataColumn fieldName='STORE_CD' title='STORE_CD' />
            <DataColumn fieldName='APPLY_YN_BONUS' title='APPLY_YN_BONUS' />
            <DataColumn fieldName='CASHSHEET_YN_APPLY' title='CASHSHEET_YN_APPLY' />
            <DataColumn fieldName='HOMECAR_NO' title='HOMECAR_NO' />
            <DataColumn fieldName='ARRIVE_NM' title='ARRIVE_NM' />
            <DataColumn fieldName='CAR_CD_ITEM' title='CAR_CD_ITEM' />
            <DataColumn fieldName='DRIVER_NM' title='DRIVER_NM' />
            <DataColumn fieldName='EMP_NO' title='EMP_NO' />
            <DataColumn fieldName='USE_CD_SELF' title='USE_CD_SELF' />
            <DataColumn fieldName='SHIPSLIP_NO' title='SHIPSLIP_NO' />
            <DataColumn fieldName='SHIPSLIP_ITEM' title='SHIPSLIP_ITEM' />
            <DataColumn fieldName='RCPT_CD_CHK' title='RCPT_CD_CHK' />
            <DataColumn fieldName='RCPT_YN_PRT' title='RCPT_YN_PRT' />
            <DataColumn fieldName='RCPT_NO' title='RCPT_NO' />
            <DataColumn fieldName='SUBSIDY_CD_SETTLE' title='SUBSIDY_CD_SETTLE' />
            <DataColumn fieldName='TAXFREE_SUPPLY_CD' title='TAXFREE_SUPPLY_CD' />
            <DataColumn fieldName='TAXFREE_TRADE_CD' title='TAXFREE_TRADE_CD' />
            <DataColumn fieldName='TAXFREE_PYMT_DT' title='TAXFREE_PYMT_DT' />
            <DataColumn fieldName='REMARK_TXT' title='REMARK_TXT' />
            <DataColumn fieldName='REG_DT' title='REG_DT' />
            <DataColumn fieldName='REG_NO_EMP' title='REG_NO_EMP' />
            <DataColumn fieldName='CHG_DT' title='CHG_DT' />
            <DataColumn fieldName='CHG_NO_EMP' title='CHG_NO_EMP' />


            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
    ), [addInfoGsposData]);

    return grid;
}
const AsyncSheetGspos = ({ asyncSheetGsposData }) => {

    const grid = useMemo(() => (
        <DataGrid
            title='t_sal_asyncsheet_gspos'
            dataSet={asyncSheetGsposData}
            horizonMode
            virtualized
            stickyHeader
        >
            <DataColumn fieldName='WORK_NM' title='사업장명' />
            <DataColumn fieldName='BIZHOUR_DATE' title='BIZHOUR_DATE' />
            <DataColumn fieldName='PYMT_TYPE' title='PYMT_TYPE' />
            <DataColumn fieldName='PYMT_DT' title='PYMT_DT' />
            <DataColumn fieldName='STORE_CD' title='STORE_CD' />
            <DataColumn fieldName='STATE_YN' title='STATE_YN' />
            <DataColumn fieldName='NOZZLE_NO' title='NOZZLE_NO' />
            <DataColumn fieldName='GOODS_CD' title='GOODS_CD' />
            <DataColumn fieldName='PYMT_QTY' title='PYMT_QTY' />
            <DataColumn fieldName='PYMT_UNIT' title='PYMT_UNIT' />
            <DataColumn fieldName='PYMT_AMT' title='PYMT_AMT' />
            <DataColumn fieldName='SUPP_AMT' title='SUPP_AMT' />
            <DataColumn fieldName='VAT_AMT' title='VAT_AMT' />
            <DataColumn fieldName='CUSTCHK_CD' title='CUSTCHK_CD' />
            <DataColumn fieldName='COGNITION_NO_ITEM' title='COGNITION_NO_ITEM' />
            <DataColumn fieldName='CUST_NO_CHK' title='CUST_NO_CHK' />
            <DataColumn fieldName='CASHEET_CD_ISSUE' title='CASHEET_CD_ISSUE' />
            <DataColumn fieldName='RESPONSE_CD' title='RESPONSE_CD' />
            <DataColumn fieldName='APPRV_NO' title='APPRV_NO' />
            <DataColumn fieldName='APPR_DT' title='APPR_DT' />
            <DataColumn fieldName='CAT_TID' title='CAT_TID' />
            <DataColumn fieldName='WAIT_TR_NO' title='WAIT_TR_NO' />
            <DataColumn fieldName='REG_DT' title='REG_DT' />
            <DataColumn fieldName='REG_NO_EMP' title='REG_NO_EMP' />
            <DataColumn fieldName='CHG_DT' title='CHG_DT' />
            <DataColumn fieldName='CHG_NO_EMP' title='CHG_NO_EMP' />
            <DataColumn fieldName='VAN_CUST_NM' title='VAN_CUST_NM' />
            <DataColumn fieldName='VAN_CAR_NO' title='VAN_CAR_NO' />

            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
    ), [asyncSheetGsposData]);

    return grid;
}
const CashBilGspos = ({ cashBilGsposData }) => {

    const grid = useMemo(() => (
        <DataGrid
            title='t_sal_cashbil_gspos'
            dataSet={cashBilGsposData}
            horizonMode
            virtualized
            stickyHeader
        >
            <DataColumn fieldName='WORK_NM' title='사업장명' />
            <DataColumn fieldName='SALESLIP_NO' title='SALESLIP_NO' />
            <DataColumn fieldName='SEQ_NO' title='SEQ_NO' />
            <DataColumn fieldName='STORE_CD' title='STORE_CD' />
            <DataColumn fieldName='BIZHOUR_DATE' title='BIZHOUR_DATE' />
            <DataColumn fieldName='STATE_YN' title='STATE_YN' />
            <DataColumn fieldName='CASHSHEET_AMT' title='CASHSHEET_AMT' />
            <DataColumn fieldName='CUSTCHK_CD' title='CUSTCHK_CD' />
            <DataColumn fieldName='COGNITION_NO_ITEM' title='COGNITION_NO_ITEM' />
            <DataColumn fieldName='CUST_NO_CHK' title='CUST_NO_CHK' />
            <DataColumn fieldName='CASHEET_CD_ISSUE' title='CASHEET_CD_ISSUE' />
            <DataColumn fieldName='RESPONSE_CD' title='RESPONSE_CD' />
            <DataColumn fieldName='APPRV_NO' title='APPRV_NO' />
            <DataColumn fieldName='APPR_DT' title='APPR_DT' />

            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
    ), [cashBilGsposData]);

    return grid;
}

const ClsGaugeGspos = ({ clsGaugeGsposData }) => {

    const grid = useMemo(() => (
        <DataGrid
            title='t_sal_clsgauge_gspos'
            dataSet={clsGaugeGsposData}
            horizonMode
            virtualized
            stickyHeader
        >
            <DataColumn fieldName='WORK_NM' title='사업장명' />
            <DataColumn fieldName='BIZHOUR_DATE' title='BIZHOUR_DATE' />
            <DataColumn fieldName='NOZZLE_NO' title='NOZZLE_NO' />
            <DataColumn fieldName='STORE_CD' title='STORE_CD' />
            <DataColumn fieldName='CURRDAY_QTY_ELEC' title='CURRDAY_QTY_ELEC' />
            <DataColumn fieldName='BEFDAY_QTY_EGAUGE' title='BEFDAY_QTY_EGAUGE' />
            <DataColumn fieldName='CURRDAY_QTY_EGAUGE' title='CURRDAY_QTY_EGAUGE' />
            <DataColumn fieldName='CURRDAY_QTY_MACH' title='CURRDAY_QTY_MACH' />
            <DataColumn fieldName='BEFDAY_QTY_MGAUGE' title='BEFDAY_QTY_MGAUGE' />
            <DataColumn fieldName='CURRDAY_QTY_MGAUGE' title='CURRDAY_QTY_MGAUGE' />
            <DataColumn fieldName='AGGR_QTY_POS' title='AGGR_QTY_POS' />
            <DataColumn fieldName='GAP_QTY' title='GAP_QTY' />
            <DataColumn fieldName='APPLY_CD_BASE' title='APPLY_CD_BASE' />
            <DataColumn fieldName='GAUGE_QTY_APPLY' title='GAUGE_QTY_APPLY' />
            <DataColumn fieldName='REFER_TXT' title='REFER_TXT' />
            <DataColumn fieldName='REG_DT' title='REG_DT' />
            <DataColumn fieldName='REG_NO_EMP' title='REG_NO_EMP' />
            <DataColumn fieldName='CHG_DT' title='CHG_DT' />
            <DataColumn fieldName='CHG_NO_EMP' title='CHG_NO_EMP' />
            <DataColumn fieldName='APPLY_POS_QTY' title='APPLY_POS_QTY' />
            <DataColumn fieldName='APPLY_DISCONNECT_QTY' title='APPLY_DISCONNECT_QTY' />

            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
    ), [clsGaugeGsposData]);

    return grid;
}
const SaleItemGspos = ({ saleItemGsposData }) => {

    const grid = useMemo(() => (
        <DataGrid
            title='t_sal_saleitem_gspos'
            dataSet={saleItemGsposData}
            horizonMode
            virtualized
            stickyHeader
        >
            <DataColumn fieldName='WORK_NM' title='사업장명' />

            <DataColumn fieldName='SALESLIP_NO' title='SALESLIP_NO' />
            <DataColumn fieldName='ITEM_SEQ_NO' title='ITEM_SEQ_NO' />
            <DataColumn fieldName='STORE_CD' title='STORE_CD' />
            <DataColumn fieldName='BIZHOUR_DATE' title='BIZHOUR_DATE' />
            <DataColumn fieldName='STATE_YN' title='STATE_YN' />
            <DataColumn fieldName='GOODS_CD_IND' title='GOODS_CD_IND' />
            <DataColumn fieldName='CVSGOODS_CD_IND' title='CVSGOODS_CD_IND' />
            <DataColumn fieldName='GOODS_CD' title='GOODS_CD' />
            <DataColumn fieldName='GOODS_NM' title='GOODS_NM' />
            <DataColumn fieldName='PRODUNIT_CD' title='PRODUNIT_CD' />
            <DataColumn fieldName='TAXFREE_CD' title='TAXFREE_CD' />
            <DataColumn fieldName='LIMIT_QTY' title='LIMIT_QTY' />
            <DataColumn fieldName='UNIT_CD_TYPE' title='UNIT_CD_TYPE' />
            <DataColumn fieldName='NOZZLE_NO' title='NOZZLE_NO' />
            <DataColumn fieldName='REQ_CNT_CUST' title='REQ_CNT_CUST' />
            <DataColumn fieldName='REQ_AMT_CUST' title='REQ_AMT_CUST' />
            <DataColumn fieldName='SALE_QTY' title='SALE_QTY' />
            <DataColumn fieldName='KEEP_QTY' title='KEEP_QTY' />
            <DataColumn fieldName='GAUGE_QTY' title='GAUGE_QTY' />
            <DataColumn fieldName='GAUGE_UNIT' title='GAUGE_UNIT' />
            <DataColumn fieldName='GAUGE_AMT' title='GAUGE_AMT' />
            <DataColumn fieldName='SALE_UNIT' title='SALE_UNIT' />
            <DataColumn fieldName='SALE_AMT' title='SALE_AMT' />
            <DataColumn fieldName='SUPPLY_AMT_SALE' title='SUPPLY_AMT_SALE' />
            <DataColumn fieldName='VAT_AMT_SALE' title='VAT_AMT_SALE' />
            <DataColumn fieldName='DISCNT_CD' title='DISCNT_CD' />
            <DataColumn fieldName='DISCNT_ITEM' title='DISCNT_ITEM' />
            <DataColumn fieldName='DISCNT_AMT_AGREE' title='DISCNT_AMT_AGREE' />
            <DataColumn fieldName='DISCNT_RATE_OPT' title='DISCNT_RATE_OPT' />
            <DataColumn fieldName='BAL_QTY_TOOLSALE' title='BAL_QTY_TOOLSALE' />
            <DataColumn fieldName='STOREPRC_AMT' title='STOREPRC_AMT' />
            <DataColumn fieldName='PROCS_EQP_CD' title='PROCS_EQP_CD' />
            <DataColumn fieldName='ADJ_LIMIT_OVER' title='ADJ_LIMIT_OVER' />
            <DataColumn fieldName='GAUGE_QTY_START' title='GAUGE_QTY_START' />
            <DataColumn fieldName='GAUGE_QTY_END' title='GAUGE_QTY_END' />
            <DataColumn fieldName='PUMP_DT_FR' title='PUMP_DT_FR' />
            <DataColumn fieldName='PUMP_DT_TO' title='PUMP_DT_TO' />
            <DataColumn fieldName='KHPROCS_NO' title='KHPROCS_NO' />
            <DataColumn fieldName='EMPTYBTL_ITEM' title='EMPTYBTL_ITEM' />
            <DataColumn fieldName='EMPTYTBTLE_UNIT' title='EMPTYTBTLE_UNIT' />
            <DataColumn fieldName='PMA_CD' title='PMA_CD' />
            <DataColumn fieldName='BAR_CD_PROD' title='BAR_CD_PROD' />
            <DataColumn fieldName='ORGSEQ_NO_GOODS' title='ORGSEQ_NO_GOODS' />
            <DataColumn fieldName='REG_DT' title='REG_DT' />
            <DataColumn fieldName='CHG_NO_EMP' title='CHG_NO_EMP' />
            <DataColumn fieldName='CHG_DT' title='CHG_DT' />
            <DataColumn fieldName='REG_NO_EMP' title='REG_NO_EMP' />


            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
    ), [saleItemGsposData]);

    return grid;
}

const SaleSettInfoGspos = ({ saleSettInfoGsposData }) => {

    const grid = useMemo(() => (
        <DataGrid
            title='t_sal_salesettinfo_gspos'
            dataSet={saleSettInfoGsposData}
            horizonMode
            virtualized
            stickyHeader
        >
            <DataColumn fieldName='WORK_NM' title='사업장명' />
            <DataColumn fieldName='SALESLIP_NO' title='SALESLIP_NO' />
            <DataColumn fieldName='SEQ_NO' title='SEQ_NO' />
            <DataColumn fieldName='STORE_CD' title='STORE_CD' />
            <DataColumn fieldName='BIZHOUR_DATE' title='BIZHOUR_DATE' />
            <DataColumn fieldName='ITEM_SEQ_NO' title='ITEM_SEQ_NO' />
            <DataColumn fieldName='PYMT_SEQ_NO' title='PYMT_SEQ_NO' />
            <DataColumn fieldName='STATE_YN' title='STATE_YN' />
            <DataColumn fieldName='PRESENT_AMT_CHK' title='PRESENT_AMT_CHK' />
            <DataColumn fieldName='CLASFY_CD_GOODS' title='CLASFY_CD_GOODS' />
            <DataColumn fieldName='GOODS_CD' title='GOODS_CD' />
            <DataColumn fieldName='PRODUNIT_CD' title='PRODUNIT_CD' />
            <DataColumn fieldName='TAXFREE_CD' title='TAXFREE_CD' />
            <DataColumn fieldName='PYMT_QTY' title='PYMT_QTY' />
            <DataColumn fieldName='PYMT_UNIT' title='PYMT_UNIT' />
            <DataColumn fieldName='PYMT_AMT' title='PYMT_AMT' />
            <DataColumn fieldName='SUPP_AMT' title='SUPP_AMT' />
            <DataColumn fieldName='VAT_AMT' title='VAT_AMT' />
            <DataColumn fieldName='PYMT_CD' title='PYMT_CD' />
            <DataColumn fieldName='CUST_CD' title='CUST_CD' />
            <DataColumn fieldName='REG_DT' title='REG_DT' />
            <DataColumn fieldName='REG_NO_EMP' title='REG_NO_EMP' />
            <DataColumn fieldName='CHG_DT' title='CHG_DT' />
            <DataColumn fieldName='CHG_NO_EMP' title='CHG_NO_EMP' />

            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
    ), [saleSettInfoGsposData]);

    return grid;
}
const SlHeaderGspos = ({ slHeaderGsposData }) => {

    const grid = useMemo(() => (
        <DataGrid
            title='t_sal_slheader_gspos'
            dataSet={slHeaderGsposData}
            horizonMode
            virtualized
            stickyHeader
        >
            <DataColumn fieldName='WORK_NM' title='사업장명' />
            <DataColumn fieldName='SALESLIP_NO' title='SALESLIP_NO' />
            <DataColumn fieldName='STORE_CD' title='STORE_CD' />
            <DataColumn fieldName='OFFSET_CD' title='OFFSET_CD' />
            <DataColumn fieldName='BIZHOUR_DATE' title='BIZHOUR_DATE' />
            <DataColumn fieldName='POS_NO' title='POS_NO' />
            <DataColumn fieldName='DEAL_NO' title='DEAL_NO' />
            <DataColumn fieldName='DEAL_CD' title='DEAL_CD' />
            <DataColumn fieldName='SLIP_NO_CD' title='SLIP_NO_CD' />
            <DataColumn fieldName='RETURN_ITEM' title='RETURN_ITEM' />
            <DataColumn fieldName='SALE_CD_ITEM' title='SALE_CD_ITEM' />
            <DataColumn fieldName='SALETYPE_CD' title='SALETYPE_CD' />
            <DataColumn fieldName='SALEWAY_CD' title='SALEWAY_CD' />
            <DataColumn fieldName='CONN_ITEM' title='CONN_ITEM' />
            <DataColumn fieldName='PYMT_TYPE' title='PYMT_TYPE' />
            <DataColumn fieldName='PAY_DATE_COMPLT' title='PAY_DATE_COMPLT' />
            <DataColumn fieldName='COMPLT_DT_RECEIVE' title='COMPLT_DT_RECEIVE' />
            <DataColumn fieldName='ITEM_QTY' title='ITEM_QTY' />
            <DataColumn fieldName='PYMT_CNT' title='PYMT_CNT' />
            <DataColumn fieldName='DISCNT_ITEM' title='DISCNT_ITEM' />
            <DataColumn fieldName='SUPPLY_AMT_SELLOUT' title='SUPPLY_AMT_SELLOUT' />
            <DataColumn fieldName='CONFM_AMT_SUPP' title='CONFM_AMT_SUPP' />
            <DataColumn fieldName='CONFM_AMT_VAT' title='CONFM_AMT_VAT' />
            <DataColumn fieldName='DISCNT_AMT_NORM' title='DISCNT_AMT_NORM' />
            <DataColumn fieldName='DISCNT_AMT_FREE' title='DISCNT_AMT_FREE' />
            <DataColumn fieldName='SALE_AMT_TOT' title='SALE_AMT_TOT' />
            <DataColumn fieldName='PYMT_AMT_TOT' title='PYMT_AMT_TOT' />
            <DataColumn fieldName='SMALL_MONEY' title='SMALL_MONEY' />
            <DataColumn fieldName='KEEPDOC_NO' title='KEEPDOC_NO' />
            <DataColumn fieldName='RECALL_NO_SAVEBIL' title='RECALL_NO_SAVEBIL' />
            <DataColumn fieldName='CAT_NO' title='CAT_NO' />
            <DataColumn fieldName='WAIT_TR_NO' title='WAIT_TR_NO' />
            <DataColumn fieldName='PROCS_YN_RESTAMT' title='PROCS_YN_RESTAMT' />
            <DataColumn fieldName='PROCS_DT_RESTAMT' title='PROCS_DT_RESTAMT' />
            <DataColumn fieldName='ORGSALE_DEALDATE' title='ORGSALE_DEALDATE' />
            <DataColumn fieldName='POS_NO_ORGSALE' title='POS_NO_ORGSALE' />
            <DataColumn fieldName='SALE_NO_ORG' title='SALE_NO_ORG' />
            <DataColumn fieldName='REG_DT' title='REG_DT' />
            <DataColumn fieldName='REG_NO_EMP' title='REG_NO_EMP' />
            <DataColumn fieldName='CHG_DT' title='CHG_DT' />
            <DataColumn fieldName='CHG_NO_EMP' title='CHG_NO_EMP' />

            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
    ), [slHeaderGsposData]);

    return grid;
}

const SlTenderGspos = ({ slTenderGsposData }) => {

    const grid = useMemo(() => (
        <DataGrid
            title='t_sal_sltender_gspos'
            dataSet={slTenderGsposData}
            horizonMode
            virtualized
            stickyHeader
        >
            <DataColumn fieldName='WORK_NM' title='사업장명' />
            <DataColumn fieldName='SALESLIP_NO' title='SALESLIP_NO' />
            <DataColumn fieldName='PYMT_SEQ_NO' title='PYMT_SEQ_NO' />
            <DataColumn fieldName='BIZHOUR_DATE' title='BIZHOUR_DATE' />
            <DataColumn fieldName='STORE_CD' title='STORE_CD' />
            <DataColumn fieldName='STATE_YN' title='STATE_YN' />
            <DataColumn fieldName='PYMT_CD' title='PYMT_CD' />
            <DataColumn fieldName='PYMT_NM' title='PYMT_NM' />
            <DataColumn fieldName='PYMT_AMT' title='PYMT_AMT' />
            <DataColumn fieldName='ADJ_LIMIT_OVER' title='ADJ_LIMIT_OVER' />
            <DataColumn fieldName='CARD_NO' title='CARD_NO' />
            <DataColumn fieldName='CARD_TRACK' title='CARD_TRACK' />
            <DataColumn fieldName='CARD_NO_END' title='CARD_NO_END' />
            <DataColumn fieldName='DSCTERM_NO' title='DSCTERM_NO' />
            <DataColumn fieldName='INPUT_CD_TYPE' title='INPUT_CD_TYPE' />
            <DataColumn fieldName='APPRV_CD_TYPE' title='APPRV_CD_TYPE' />
            <DataColumn fieldName='APPRV_NO' title='APPRV_NO' />
            <DataColumn fieldName='APPR_DT' title='APPR_DT' />
            <DataColumn fieldName='ISSUE_CD_CARD' title='ISSUE_CD_CARD' />
            <DataColumn fieldName='BUYCOMP_CD_CARD' title='BUYCOMP_CD_CARD' />
            <DataColumn fieldName='COMP_CD_VAN' title='COMP_CD_VAN' />
            <DataColumn fieldName='CAT_TID' title='CAT_TID' />
            <DataColumn fieldName='CUST_CD_ITEM' title='CUST_CD_ITEM' />
            <DataColumn fieldName='DEAL_TYPE' title='DEAL_TYPE' />
            <DataColumn fieldName='CUST_CD' title='CUST_CD' />
            <DataColumn fieldName='TEL_NO_CUST' title='TEL_NO_CUST' />
            <DataColumn fieldName='CUST_CD_REP' title='CUST_CD_REP' />
            <DataColumn fieldName='TEAM_CD' title='TEAM_CD' />
            <DataColumn fieldName='DEPT_CD' title='DEPT_CD' />
            <DataColumn fieldName='CAR_NO' title='CAR_NO' />
            <DataColumn fieldName='CARDNO_NO_CUST' title='CARDNO_NO_CUST' />
            <DataColumn fieldName='BONUSCARD_ID' title='BONUSCARD_ID' />
            <DataColumn fieldName='BONUSCARD_NO' title='BONUSCARD_NO' />
            <DataColumn fieldName='PRT_YN_SHEET' title='PRT_YN_SHEET' />
            <DataColumn fieldName='PRT_AMT_SHEET' title='PRT_AMT_SHEET' />
            <DataColumn fieldName='OFFSET_CD' title='OFFSET_CD' />
            <DataColumn fieldName='PYMT_NO_ORGSEQ' title='PYMT_NO_ORGSEQ' />
            <DataColumn fieldName='PROCS_SEQ' title='PROCS_SEQ' />
            <DataColumn fieldName='LIMIT_CD_BASE' title='LIMIT_CD_BASE' />
            <DataColumn fieldName='CUST_LIMIT_REMAIN' title='CUST_LIMIT_REMAIN' />
            <DataColumn fieldName='CUST_LIMIT_USE' title='CUST_LIMIT_USE' />
            <DataColumn fieldName='CARDNO_NO_CARD' title='CARDNO_NO_CARD' />
            <DataColumn fieldName='FRANCH_NO' title='FRANCH_NO' />
            <DataColumn fieldName='REG_DT' title='REG_DT' />
            <DataColumn fieldName='REG_NO_EMP' title='REG_NO_EMP' />
            <DataColumn fieldName='CHG_DT' title='CHG_DT' />
            <DataColumn fieldName='CHG_NO_EMP' title='CHG_NO_EMP' />

            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
    ), [slTenderGsposData]);

    return grid;
}


const CardInGsposExcel = ({ cardInGsposExcelData }) => {

    const grid = useMemo(() => (
        <DataGrid
            title='tb_card_in_gspos_excel'
            dataSet={cardInGsposExcelData}
            horizonMode
            virtualized
            stickyHeader
        >
            <DataColumn fieldName='WORK_NM' title='사업장명' />
            <DataColumn fieldName='SALESLIP_NO' title='SALESLIP_NO' />
            <DataColumn fieldName='BIZ_DATE' title='BIZ_DATE' />
            <DataColumn fieldName='CARD_CD' title='CARD_CD' />
            <DataColumn fieldName='AMOUNT' title='AMOUNT' />
            <DataColumn fieldName='PAY_CD' title='PAY_CD' />
            <DataColumn fieldName='REGDATE' title='REGDATE' />
            <DataColumn fieldName='REGUSER' title='REGUSER' />
            <DataColumn fieldName='EDTDATE' title='EDTDATE' />
            <DataColumn fieldName='EDTUSER' title='EDTUSER' />

            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
    ), [cardInGsposExcelData]);

    return grid;

}

const CashReceptGsposExcel = ({ cashReceptGsposExcelData }) => {

    const grid = useMemo(() => (
        <DataGrid
            title='tb_cashrecept_gspos_excel'
            dataSet={cashReceptGsposExcelData}
            horizonMode
            virtualized
            stickyHeader
        >
            <DataColumn fieldName='WORK_NM' title='사업장명' />
            <DataColumn fieldName='SEQ' title='SEQ' />
            <DataColumn fieldName='BIZ_DATE' title='BIZ_DATE' />
            <DataColumn fieldName='RECPT_DTTM' title='RECPT_DTTM' />
            <DataColumn fieldName='GDS_CD' title='GDS_CD' />
            <DataColumn fieldName='GDS_NM' title='GDS_NM' />
            <DataColumn fieldName='CSH_AMT' title='CSH_AMT' />
            <DataColumn fieldName='AMOUNT' title='AMOUNT' />
            <DataColumn fieldName='RECPT_TYPE' title='RECPT_TYPE' />
            <DataColumn fieldName='RECPT_NO' title='RECPT_NO' />
            <DataColumn fieldName='REGDATE' title='REGDATE' />
            <DataColumn fieldName='REGUSER' title='REGUSER' />
            <DataColumn fieldName='EDTDATE' title='EDTDATE' />
            <DataColumn fieldName='EDTUSER' title='EDTUSER' />

            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
    ), [cashReceptGsposExcelData]);

    return grid;

}

const GaugeGsposExcel = ({ gaugeGsposExcelData }) => {

    const grid = useMemo(() => (
        <DataGrid
            title='tb_gauge_gspos_excel'
            dataSet={gaugeGsposExcelData}
            horizonMode
            virtualized
            stickyHeader
        >
            <DataColumn fieldName='WORK_NM' title='사업장명' />
            <DataColumn fieldName='NZL_NO' title='NZL_NO' />
            <DataColumn fieldName='BIZ_DATE' title='BIZ_DATE' />
            <DataColumn fieldName='GDS_CD' title='GDS_CD' />
            <DataColumn fieldName='GDS_NM' title='GDS_NM' />
            <DataColumn fieldName='BEF_GAUGE_ELE' title='BEF_GAUGE_ELE' />
            <DataColumn fieldName='CUR_GAUGE_ELE' title='CUR_GAUGE_ELE' />
            <DataColumn fieldName='GAUGE_ELE' title='GAUGE_ELE' />
            <DataColumn fieldName='BEF_GAUGE_MAC' title='BEF_GAUGE_MAC' />
            <DataColumn fieldName='CUR_GAUGE_MAC' title='CUR_GAUGE_MAC' />
            <DataColumn fieldName='GAUGE_MAC' title='GAUGE_MAC' />
            <DataColumn fieldName='APPLY_QTY' title='APPLY_QTY' />
            <DataColumn fieldName='NOT_GAU_QTY' title='NOT_GAU_QTY' />
            <DataColumn fieldName='GAU_QTY' title='GAU_QTY' />
            <DataColumn fieldName='KEEP_SALE_QTY' title='KEEP_SALE_QTY' />
            <DataColumn fieldName='KEEP_CST_QTY' title='KEEP_CST_QTY' />
            <DataColumn fieldName='DUTYFREE_QTY' title='DUTYFREE_QTY' />
            <DataColumn fieldName='SELF_QTY' title='SELF_QTY' />
            <DataColumn fieldName='MOVE_QTY' title='MOVE_QTY' />
            <DataColumn fieldName='HOME_QTY' title='HOME_QTY' />
            <DataColumn fieldName='TEST_QTY' title='TEST_QTY' />
            <DataColumn fieldName='REGDATE' title='REGDATE' />
            <DataColumn fieldName='REGUSER' title='REGUSER' />
            <DataColumn fieldName='EDTDATE' title='EDTDATE' />
            <DataColumn fieldName='EDTUSER' title='EDTUSER' />

            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
    ), [gaugeGsposExcelData]);

    return grid;


}

const InMoneyGsposExcel = ({ inMoneyGsposExcelData }) => {

    const grid = useMemo(() => (
        <DataGrid
            title='tb_inmoney_gspos_excel'
            dataSet={inMoneyGsposExcelData}
            horizonMode
            virtualized
            stickyHeader
        >
            <DataColumn fieldName='WORK_NM' title='사업장명' />
            <DataColumn fieldName='SALESLIP_NO' title='SALESLIP_NO' />
            <DataColumn fieldName='BIZ_DATE' title='BIZ_DATE' />
            <DataColumn fieldName='CST_CD' title='CST_CD' />
            <DataColumn fieldName='CST_NM' title='CST_NM' />
            <DataColumn fieldName='AMOUNT' title='AMOUNT' />
            <DataColumn fieldName='PAY_CD' title='PAY_CD' />
            <DataColumn fieldName='REGDATE' title='REGDATE' />
            <DataColumn fieldName='REGUSER' title='REGUSER' />
            <DataColumn fieldName='EDTDATE' title='EDTDATE' />
            <DataColumn fieldName='EDTUSER' title='EDTUSER' />

            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
    ), [inMoneyGsposExcelData]);

    return grid;


}

const SaleGsposExcel = ({ saleGsposExcelData }) => {

    const grid = useMemo(() => (
        <DataGrid
            title='tb_sale_gspos_excel'
            dataSet={saleGsposExcelData}
            horizonMode
            virtualized
            stickyHeader
        >
            <DataColumn fieldName='WORK_NM' title='사업장명' />
            <DataColumn fieldName='SALESLIP_NO' title='SALESLIP_NO' />
            <DataColumn fieldName='SEQ' title='SEQ' />
            <DataColumn fieldName='BIZ_DATE' title='BIZ_DATE' />
            <DataColumn fieldName='CST_CD' title='CST_CD' />
            <DataColumn fieldName='GDS_CD' title='GDS_CD' />
            <DataColumn fieldName='GDS_NM' title='GDS_NM' />
            <DataColumn fieldName='CAR_NO' title='CAR_NO' />
            <DataColumn fieldName='CARD_CD' title='CARD_CD' />
            <DataColumn fieldName='PAY_CD' title='PAY_CD' />
            <DataColumn fieldName='POS_STD_PRICE' title='POS_STD_PRICE' />
            <DataColumn fieldName='POS_PRICE' title='POS_PRICE' />
            <DataColumn fieldName='QTY' title='QTY' />
            <DataColumn fieldName='AMOUNT' title='AMOUNT' />
            <DataColumn fieldName='BAL_AMT' title='BAL_AMT' />
            <DataColumn fieldName='POS_SALE_DTTM' title='POS_SALE_DTTM' />
            <DataColumn fieldName='REGDATE' title='REGDATE' />
            <DataColumn fieldName='REGUSER' title='REGUSER' />
            <DataColumn fieldName='EDTDATE' title='EDTDATE' />
            <DataColumn fieldName='EDTUSER' title='EDTUSER' />

            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
    ), [saleGsposExcelData]);

    return grid;

}



const CashMirae = ({ cashMiraeData }) => {

    const grid = useMemo(() => (
        <DataGrid
            title='cash_mirae'
            dataSet={cashMiraeData}
            horizonMode
            virtualized
            stickyHeader
        >
            <DataColumn fieldName='WORK_NM' title='사업장명' />
            <DataColumn fieldName='SERIAL' title='SERIAL' />
            <DataColumn fieldName='APP_DATE' title='APP_DATE' />
            <DataColumn fieldName='ODT_NO' title='ODT_NO' />
            <DataColumn fieldName='APP_MONEY' title='APP_MONEY' />
            <DataColumn fieldName='APP_NO' title='APP_NO' />
            <DataColumn fieldName='SC_INDEX' title='SC_INDEX' />

            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
    ), [cashMiraeData]);

    return grid;


}

const ClosingMirae = ({ closingMiraeData }) => {

    const grid = useMemo(() => (
        <DataGrid
            title='closing_mirae'
            dataSet={closingMiraeData}
            horizonMode
            virtualized
            stickyHeader
        >
            <DataColumn fieldName='WORK_NM' title='사업장명' />
            <DataColumn fieldName='CLOSING_CODE' title='CLOSING_CODE' />
            <DataColumn fieldName='BEFORE_SERIAL' title='BEFORE_SERIAL' />
            <DataColumn fieldName='CURRENT_SERIAL' title='CURRENT_SERIAL' />
            <DataColumn fieldName='BEFORE_DATETIME' title='BEFORE_DATETIME' />
            <DataColumn fieldName='CURRENT_DATETIME' title='CURRENT_DATETIME' />
            <DataColumn fieldName='SC_INDEX' title='SC_INDEX' />

            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
    ), [closingMiraeData]);

    return grid;


}
const CreditCardMirae = ({ creditCardMiraeData }) => {

    const grid = useMemo(() => (
        <DataGrid
            title='creditcard_mirae'
            dataSet={creditCardMiraeData}
            horizonMode
            virtualized
            stickyHeader
        >
            <DataColumn fieldName='WORK_NM' title='사업장명' />
            <DataColumn fieldName='SERIAL' title='SERIAL' />
            <DataColumn fieldName='CREDIT_NO' title='CREDIT_NO' />
            <DataColumn fieldName='APPROVAL_NO' title='APPROVAL_NO' />
            <DataColumn fieldName='APPROVAL_DATE' title='APPROVAL_DATE' />
            <DataColumn fieldName='APPROVAL_TIME' title='APPROVAL_TIME' />
            <DataColumn fieldName='PURCHASE_CODE' title='PURCHASE_CODE' />
            <DataColumn fieldName='PURCHASE_NAME' title='PURCHASE_NAME' />
            <DataColumn fieldName='ISSUE_NAME' title='ISSUE_NAME' />
            <DataColumn fieldName='FRANCHISE_NO' title='FRANCHISE_NO' />
            <DataColumn fieldName='VALIDITY_DATE' title='VALIDITY_DATE' />
            <DataColumn fieldName='MONEY' title='MONEY' />
            <DataColumn fieldName='SC_INDEX' title='SC_INDEX' />

            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
    ), [creditCardMiraeData]);

    return grid;

}

const SaleDataMirae = ({ saleDataMiraeData }) => {

    const grid = useMemo(() => (
        <DataGrid
            title='saledata_mirae'
            dataSet={saleDataMiraeData}
            horizonMode
            virtualized
            stickyHeader
        >
            <DataColumn fieldName='WORK_NM' title='사업장명' />
            <DataColumn fieldName='SERIAL' title='SERIAL' />
            <DataColumn fieldName='DATETIME' title='DATETIME' />
            <DataColumn fieldName='DPN_NO' title='DPN_NO' />
            <DataColumn fieldName='DPN_COST' title='DPN_COST' />
            <DataColumn fieldName='DPN_MONEY' title='DPN_MONEY' />
            <DataColumn fieldName='DPN_LITER' title='DPN_LITER' />
            <DataColumn fieldName='DPN_TOTAL' title='DPN_TOTAL' />
            <DataColumn fieldName='PAY1_TYPE' title='PAY1_TYPE' />
            <DataColumn fieldName='PAY1_MONEY' title='PAY1_MONEY' />
            <DataColumn fieldName='PAY1_LITER' title='PAY1_LITER' />
            <DataColumn fieldName='PAY1_ETC' title='PAY1_ETC' />
            <DataColumn fieldName='PAY2_TYPE' title='PAY2_TYPE' />
            <DataColumn fieldName='PAY2_MONEY' title='PAY2_MONEY' />
            <DataColumn fieldName='PAY2_LITER' title='PAY2_LITER' />
            <DataColumn fieldName='PAY2_ETC' title='PAY2_ETC' />
            <DataColumn fieldName='PAY3_TYPE' title='PAY3_TYPE' />
            <DataColumn fieldName='PAY3_MONEY' title='PAY3_MONEY' />
            <DataColumn fieldName='PAY3_LITER' title='PAY3_LITER' />
            <DataColumn fieldName='PAY3_ETC' title='PAY3_ETC' />
            <DataColumn fieldName='PAY4_TYPE' title='PAY4_TYPE' />
            <DataColumn fieldName='PAY4_MONEY' title='PAY4_MONEY' />
            <DataColumn fieldName='PAY4_LITER' title='PAY4_LITER' />
            <DataColumn fieldName='PAY4_ETC' title='PAY4_ETC' />
            <DataColumn fieldName='PAY5_TYPE' title='PAY5_TYPE' />
            <DataColumn fieldName='PAY5_MONEY' title='PAY5_MONEY' />
            <DataColumn fieldName='PAY5_LITER' title='PAY5_LITER' />
            <DataColumn fieldName='PAY5_ETC' title='PAY5_ETC' />
            <DataColumn fieldName='CUST_COST' title='CUST_COST' />
            <DataColumn fieldName='BUSINESS_CODE' title='BUSINESS_CODE' />
            <DataColumn fieldName='CAR_NO' title='CAR_NO' />
            <DataColumn fieldName='CAR_ID' title='CAR_ID' />
            <DataColumn fieldName='OWNER_NAME' title='OWNER_NAME' />
            <DataColumn fieldName='SALE_TYPE' title='SALE_TYPE' />
            <DataColumn fieldName='TICKET' title='TICKET' />
            <DataColumn fieldName='MR_CARD' title='MR_CARD' />
            <DataColumn fieldName='SN_CARD' title='SN_CARD' />
            <DataColumn fieldName='IS_CARD' title='IS_CARD' />
            <DataColumn fieldName='BOY_NO' title='BOY_NO' />
            <DataColumn fieldName='SC_INDEX' title='SC_INDEX' />

            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
    ), [saleDataMiraeData]);

    return grid;


}


const CrdPayOutputDataDoncw = ({ crdPayOutputDataDoncwData }) => {

    const grid = useMemo(() => (
        <DataGrid
            title='crd_pay_output_data_doncw'
            dataSet={crdPayOutputDataDoncwData}
            horizonMode
            virtualized
            stickyHeader
        >
            <DataColumn fieldName='WORK_NM' title='사업장명' />
            <DataColumn fieldName='BIZ_DATE' title='BIZ_DATE' />
            <DataColumn fieldName='MACH_CD' title='MACH_CD' />
            <DataColumn fieldName='RF_KEY_NUM' title='RF_KEY_NUM' />
            <DataColumn fieldName='CRD_PAYMENT_HISTORY' title='CRD_PAYMENT_HISTORY' />
            <DataColumn fieldName='OUT_TRAN_ID' title='OUT_TRAN_ID' />
            <DataColumn fieldName='CARD_NAME' title='CARD_NAME' />
            <DataColumn fieldName='CARD_NUM' title='CARD_NUM' />
            <DataColumn fieldName='ITM_CD' title='ITM_CD' />
            <DataColumn fieldName='TRD_DATE' title='TRD_DATE' />
            <DataColumn fieldName='QTY' title='QTY' />
            <DataColumn fieldName='PRICE' title='PRICE' />
            <DataColumn fieldName='AMOUNT_PAYMENT' title='AMOUNT_PAYMENT' />
            <DataColumn fieldName='SUPPLY_PRICE' title='SUPPLY_PRICE' />
            <DataColumn fieldName='VAT' title='VAT' />
            <DataColumn fieldName='PAY_KINDS' title='PAY_KINDS' />
            <DataColumn fieldName='FRANCHISEE_NUM' title='FRANCHISEE_NUM' />
            <DataColumn fieldName='APPROVAL_NUM' title='APPROVAL_NUM' />
            <DataColumn fieldName='APPROVAL_DATE' title='APPROVAL_DATE' />
            <DataColumn fieldName='PURCHASING_AGENCY' title='PURCHASING_AGENCY' />
            <DataColumn fieldName='PURCHASING_AGENCY_CODE' title='PURCHASING_AGENCY_CODE' />
            <DataColumn fieldName='LSSUER' title='LSSUER' />
            <DataColumn fieldName='LSSUER_CODE' title='LSSUER_CODE' />
            <DataColumn fieldName='INSTALLMENT' title='INSTALLMENT' />
            <DataColumn fieldName='SALE_FLAG' title='SALE_FLAG' />
            <DataColumn fieldName='SALE_KIND' title='SALE_KIND' />
            <DataColumn fieldName='SALE_CD' title='SALE_CD' />
            <DataColumn fieldName='ETC_KIND' title='ETC_KIND' />
            <DataColumn fieldName='CLOSE_FLAG' title='CLOSE_FLAG' />
            <DataColumn fieldName='CLOSE_DATE' title='CLOSE_DATE' />
            <DataColumn fieldName='CLS_CFM_FLAG' title='CLS_CFM_FLAG' />
            <DataColumn fieldName='CLS_CFM_DATE' title='CLS_CFM_DATE' />
            <DataColumn fieldName='REMARK' title='REMARK' />
            <DataColumn fieldName='REG_DATE' title='REG_DATE' />
            <DataColumn fieldName='REG_USER' title='REG_USER' />
            <DataColumn fieldName='EDT_DATE' title='EDT_DATE' />
            <DataColumn fieldName='EDT_USER' title='EDT_USER' />
            <DataColumn fieldName='REG_DATE_KI' title='REG_DATE_KI' />

            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
    ), [crdPayOutputDataDoncwData]);

    return grid;

}

const CshPayOutputDataDoncw = ({ cshPayOutputDataDoncwData }) => {

    const grid = useMemo(() => (
        <DataGrid
            title='csh_pay_output_data_doncw'
            dataSet={cshPayOutputDataDoncwData}
            horizonMode
            virtualized
            stickyHeader
        >
            <DataColumn fieldName='WORK_NM' title='사업장명' />
            <DataColumn fieldName='BIZ_DATE' title='BIZ_DATE' />
            <DataColumn fieldName='MACH_CD' title='MACH_CD' />
            <DataColumn fieldName='RF_KEY_NUM' title='RF_KEY_NUM' />
            <DataColumn fieldName='CSH_PAYMENT_HISTORY' title='CSH_PAYMENT_HISTORY' />
            <DataColumn fieldName='OUT_TRAN_ID' title='OUT_TRAN_ID' />
            <DataColumn fieldName='ITM_CD' title='ITM_CD' />
            <DataColumn fieldName='TRD_DATE' title='TRD_DATE' />
            <DataColumn fieldName='QTY' title='QTY' />
            <DataColumn fieldName='PRICE' title='PRICE' />
            <DataColumn fieldName='AMOUNT_PAYMENT' title='AMOUNT_PAYMENT' />
            <DataColumn fieldName='SUPPLY_PRICE' title='SUPPLY_PRICE' />
            <DataColumn fieldName='VAT' title='VAT' />
            <DataColumn fieldName='PAY_KINDS' title='PAY_KINDS' />
            <DataColumn fieldName='FRANCHISEE_NUM' title='FRANCHISEE_NUM' />
            <DataColumn fieldName='APPROVAL_NUM' title='APPROVAL_NUM' />
            <DataColumn fieldName='APPROVAL_DATE' title='APPROVAL_DATE' />
            <DataColumn fieldName='USE_CLASSIFICATION' title='USE_CLASSIFICATION' />
            <DataColumn fieldName='USER_NUM' title='USER_NUM' />
            <DataColumn fieldName='SALE_FLAG' title='SALE_FLAG' />
            <DataColumn fieldName='SALE_KIND' title='SALE_KIND' />
            <DataColumn fieldName='ETC_KIND' title='ETC_KIND' />
            <DataColumn fieldName='CLOSE_FLAG' title='CLOSE_FLAG' />
            <DataColumn fieldName='CLOSE_DATE' title='CLOSE_DATE' />
            <DataColumn fieldName='CLS_CFM_FLAG' title='CLS_CFM_FLAG' />
            <DataColumn fieldName='CLS_CFM_DATE' title='CLS_CFM_DATE' />
            <DataColumn fieldName='REMARK' title='REMARK' />
            <DataColumn fieldName='REG_DATE' title='REG_DATE' />
            <DataColumn fieldName='REG_USER' title='REG_USER' />
            <DataColumn fieldName='EDT_DATE' title='EDT_DATE' />
            <DataColumn fieldName='EDT_USER' title='EDT_USER' />
            <DataColumn fieldName='REG_DATE_KI' title='REG_DATE_KI' />

            <DataColumn fieldName='WORK_CD' visible={false} />
        </DataGrid>
    ), [cshPayOutputDataDoncwData]);

    return grid;

}

export default PosOriginList

