import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DatePicker, DataColumn, Tab, Tabs, PdfButton } from '../../component';
import { makeStyles } from '@material-ui/core';
import { proType } from '../../common/Dictionary';
import { InboundTaxListPdf } from '../../reports';
import { useSelector } from 'react-redux';
import { totalCount } from '../../common/Utils';

const sort = (a, b) => a['PRO_TYPE'] > b['PRO_TYPE'] ? 1 : -1;

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        width: 0,
    },
    tabContainer: {
        display: 'flex',
    },
    tabbar: {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    tab: {
        minWidth: 100,
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
    hide: {
        display: 'none'
    },
}))


const InboundTaxList = () => {
    const [condition, setCondition] = useState(null);
    const [selectTab, setSelectTab] = useState(0);

    const classes = useStyles();

    const [workData, setWorkData] = useState();
    
    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => {
                sessionStorage['WORK_CD'] = condition['WORK_CD'];
                setCondition(condition)}}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            onDataLoad={(jsonData, rawData) => {
                                const result = [{ WORK_CD: 'ALL', WORK_NM: '전체' }, ...rawData]
                                setWorkData(result);
                                return result;
                            }}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
            </SearchPanel>
            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab className={classes.tab} label='전체' />
                    <Tab className={classes.tab} label='매입과세' />
                    <Tab className={classes.tab} label='매입현금과세' />
                    <Tab className={classes.tab} label='매입카드과세' />
                    <Tab className={classes.tab} label='매입면세' />
                    <Tab className={classes.tab} label='매입불공제' />
                </Tabs>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <InTaxInfo condition={condition} workData={workData} proTypePram={0} title={'전체'} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <InTaxInfo condition={condition} workData={workData} proTypePram={1} title={'매입과세'} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 2 })}>
                <InTaxInfo condition={condition} workData={workData} proTypePram={2} title={'매입현금과세'} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 3 })}>
                <InTaxInfo condition={condition} workData={workData} proTypePram={3} title={'매입카드과세'} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 4 })}>
                <InTaxInfo condition={condition} workData={workData} proTypePram={4} title={'매입면세'} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 5 })}>
                <InTaxInfo condition={condition} workData={workData} proTypePram={5} title={'매입불공제'} />
            </div>
        </div>
    )
}

const InTaxInfo = ({ condition, workData, proTypePram, title }) => {
    const [gridParam, setGridParam] = useState(null);
    const [pdfData, setPdfData] = useState();
    const loginState = useSelector(state => state.loginReducer);


    useEffect(() => {
        condition && setGridParam({...condition, PRO_TYPE: proTypePram})
    }, [condition, proTypePram])

    const pdfComponent = useMemo(() => (
        <PdfButton title='매입계산서정보'>
            {pdfData &&
            <InboundTaxListPdf
                pdfData={pdfData}
                condition={condition}
                loginState={loginState}
                workName={workData.find(item => item['WORK_CD'] === condition['WORK_CD'])['WORK_NM']}
            />}
        </PdfButton>
    ), [pdfData, condition, loginState, workData]);


    return (
        <>
            <DataGrid
                title={title}
                selectProc='SLT_INBOUND_TAXINVOICE_LIST'
                selectParam={gridParam}
                totalGroup={['PRO_TYPE']}
                totalSort={sort}
                selectionMode='single'
                horizonMode
                virtualized
                excelDownload={{ fileName: `매입계산서정보-${title}.xlsx`, sheetName: title }}
                stickyHeader
                sortable
                filterable
                headerItem={({visibleAoa}) => {
                    setPdfData(visibleAoa);
                    return pdfComponent;
                }}
            >
                <DataColumn 
                    fieldName='ISSUE_DATE'  
                    total={(values, data, group) => group ? `(소 계 ${totalCount(values)} 건) ${proType[group['PRO_TYPE']]}` : `- 합 계 ${totalCount(values)} 건 -`} 
                />
                <DataColumn fieldName='PRO_TYPE' value={(row, value) => proType[value]} />
                <DataColumn fieldName='SPLY_AMT' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='VAT' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='AMOUNT' total={(values) => values.reduce((result, value) => result + value, 0)} />
            </DataGrid> 
        </>
    )
}

export default InboundTaxList;