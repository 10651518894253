import React, { useState, useRef } from 'react';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DataColumn, ComboSearch} from '../../component';
import { makeStyles } from '@material-ui/core';
import { contractType, priceStdType, dscrateType } from '../../common/Dictionary';

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
}))

const CstPriceInfo = () => {
    const classes = useStyles();
    const [condition, setCondition] = useState(null);
    const cstParam = useRef();
    const gsdParam = useRef();

    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => setCondition(condition)}>
                <SearchItem
                    fieldName='WORK_CD'
                    component={(state, dispatch, rowState, setField) => (
                        <ComboBox
                            label='사업장명'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => {
                                cstParam.current={WORK_CD : value}
                                setField('CST_CD', undefined);
                                dispatch(value);
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='CST_CD'
                    component={(state, dispatch) => (
                        <ComboSearch
                            label='거래처'
                            selectProc='SLT_CUSTOMERINFO_SALE'
                            selectParam={cstParam.current}
                            keyField='CST_CD'
                            valueField='CST_NM'
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                />
            </SearchPanel>
            <DataGrid
                title='거래처단가'
                table='TB_CSTPRICEINFO'
                selectProc='SLT_CSTPRICEINFO'
                insertProc='INS_CSTPRICEINFO'
                updateProc='UPT_CSTPRICEINFO'
                deleteProc='DLT_CSTPRICEINFO'
                selectParam={condition}
                selectionMode='single'
                horizonMode
            >
                <DataColumn
                    fieldName='WORK_CD'
                    title='사업장명'
                    value={row => row['WORK_NM']}
                    defaultValue={condition && condition['WORK_CD']}
                    editable={(state, dispatch) => (
                        <ComboBox
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => {
                                gsdParam.current={WORK_CD: value};
                                dispatch(value);
                            }}
                            disabled
                        />
                    )}
                />

                <DataColumn
                    fieldName='CST_CD'
                    title='거래처명'
                    value={row => row['CST_NM']}
                    defaultValue={condition && condition['CST_CD']}
                    editable={(state, dispatch) => (
                        <ComboBox
                            selectProc='SLT_CUSTOMERINFO_SALE'
                            selectParam={cstParam.current}
                            keyField='CST_CD'
                            valueField='CST_NM'
                            value={state}
                            onChange={value => dispatch(value)}
                            disabled
                        />
                    )}
                />

                <DataColumn
                    fieldName='GDS_CD'
                    title='상품명'
                    value={row => row['GDS_NM']}
                    editable={(state, dispatch) => (
                        <ComboBox
                            selectProc='SLT_GOODSINFO_GAUGE_ITEM_SERACHPANEL'
                            selectParam={gsdParam.current}
                            keyField='GDS_CD'
                            valueField='GDS_NM'
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                /> 

                <DataColumn
                    fieldName='CONTRACT_CD'
                    title='계약조건'
                    value={(row, value) => contractType[value]}
                    defaultValue={Object.keys(contractType)[0]}
                    editable={(state, dispatch) => (
                        <ComboBox
                            data={contractType}
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                />

                <DataColumn
                    fieldName='PRICE_STD_TYPE'
                    title='단가기준'
                    value={(row, value) => priceStdType[value]}
                    defaultValue={Object.keys(priceStdType)[0]}
                    editable={(state, dispatch) => (
                        <ComboBox
                            data={priceStdType}
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                />

                <DataColumn
                    fieldName='DSCRATE_TYPE'
                    title='할인/할증구분'
                    value={(row, value) => dscrateType[value]}
                    defaultValue={Object.keys(dscrateType)[0]}
                    editable={(state, dispatch) => (
                        <ComboBox
                            data={dscrateType}
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                />

                <DataColumn
                    fieldName='WORK_NM'
                    visible={false}
                />

                <DataColumn
                    fieldName='CST_NM'
                    visible={false}
                />

                <DataColumn
                    fieldName='GDS_NM'
                    visible={false}
                />

            </DataGrid>
        </div>
    )
}

export default CstPriceInfo