import React from 'react';
import clsx from 'clsx';
import { PaddingCell } from "./DataGrid";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    cell: {
        padding: '0px 12px',
        paddingRight: '0px',
        height: '48px',
        left: 'unset',
        zIndex: 4,
    },
    headerContainer: {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: theme.palette.divider,
        whiteSpace: 'nowrap',
        textAlign: 'center',
    },
}));

const BandItem = ({ children, className, span, ...props }) => {
    const classes = useStyles();
    return(
        <PaddingCell
            component='th'
            className={clsx(classes.cell, className)}
            colSpan={span}
            {...props}
        >
            <div className={classes.headerContainer}>
                {children}
            </div>
        </PaddingCell>
    )
}

export default BandItem;