import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DatePicker, DataColumn, IconButton, Tabs, Tab, ProgressDialog } from '../../component';
import { Dialog, DialogTitle, makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { proType } from '../../common/Dictionary';
import { callProc } from '../../common/DBConnector';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        width: 0,
    },
    tabContainer: {
        display: 'flex',
    },
    tabbar: {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    tab: {
        minWidth: 100,
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
    hide: {
        display: 'none'
    },
}))

const ElectAccountingInOut = () => {
    const [condition, setCondition] = useState(null);
    const [selectedRow, setSelectedRow] = useState();
    const [selectTab, setSelectTab] = useState(0);
    const classes = useStyles();

    const clsState = useSelector(state => state.clsDateReducer);

    const onSelectedRow = (selection) => setSelectedRow(selection)

    const refreshGrid = () => setCondition({...condition});

    return (
        <div className={classes.container}>
            <SearchPanel onSearch={condition => {
                if (condition['WORK_CD'] !== 'ALL'){
                    sessionStorage['WORK_CD'] = condition['WORK_CD'];
                }
                sessionStorage['STA_DATE'] = condition['STA_DATE'];
                sessionStorage['END_DATE'] = condition['END_DATE'];
                setCondition(condition);
                }}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, setState) => (
                        <ComboBox
                            label='사업장명'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            onDataLoad={jsonData => ({ALL: '전체', ...jsonData})}
                            value={state}
                            onChange={(value) => {
                                setState(value);
                            }}
                        />
                    )}
                />
               <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={sessionStorage['STA_DATE'] || moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={sessionStorage['END_DATE'] || moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
            </SearchPanel>
            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab className={classes.tab} label='미 등록' />
                    <Tab className={classes.tab} label='등록' />
                </Tabs>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <NotEnroll 
                    condition={condition} 
                    onSelectedRow={onSelectedRow}
                />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <Enroll 
                    condition={condition} 
                    onSelectedRow={onSelectedRow}
                    refreshGrid={refreshGrid}
                />
            </div>
            <InOutDialog
                open={selectedRow != null}
                onClose={() => setSelectedRow(null)}
                data={selectedRow}
                refreshGrid={refreshGrid}
                clsState={clsState}
            />
        </div>
    )  
}

const NotEnroll = ({ condition, onSelectedRow }) => {
    const [gridParam, setGridParam] = useState(null);

    useEffect(() => {
        condition && setGridParam({ ...condition, LOAD_TYPE: 0 }) // 미등록
    }, [condition])

    return (
        <DataGrid
            title='미 등록'
            selectProc='SLT_EDOC_ACCOUNTING_INOUT'
            selectParam={gridParam}
            horizonMode
            selectionMode='multi'
            headerItem={({selection}) => (
                <IconButton
                    tooltip='확인'
                    icon={<CheckIcon />}
                    onClick={() => onSelectedRow(selection)}
                />
            )}
            stickyHeader
        >
            <DataColumn
                fieldName='PRO_TYPE'
                title='유형'
                value={(row, value) => proType[value]}
            />
            <DataColumn
                fieldName='PRO_WORK_CD'
                visible={false}
            />
            <DataColumn
                fieldName='ACC_CD'
                visible={false}
            />
            <DataColumn
                fieldName='CST_CD'
                visible={false}
            />
            <DataColumn
                fieldName='DEBTOR'
                visible={false}
            />
            <DataColumn
                fieldName='CREDITOR'
                visible={false}
            />
            <DataColumn
                fieldName='DOC_CD'
                visible={false}
            />
            <DataColumn
                fieldName='PRO_CD'
                visible={false}
            />
            <DataColumn
                fieldName='CRT_CD'
                visible={false}
            />
            <DataColumn
                fieldName='SEQ'
                title='순번'
                visible={false}
            />
        </DataGrid>
    )
}

const Enroll = ({ condition, onSelectedRow, refreshGrid }) => {
    const [gridParam, setGridParam] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        condition && setGridParam({ ...condition, LOAD_TYPE: 1 }) // 등록
    }, [condition])

    const valid = (selection) => {
        if(!selection || selection.length === 0){
            enqueueSnackbar('선택된 행이 없습니다.', {variant: 'error'});
            return false;
        }

        return true;
    }

    const cancelData = async(selection) => {
        if(valid(selection)){
            const result = await callProc('UPT_ELECT_ACCOUNTING_INOUT_CANCEL', { JSON: selection });
            
            if(!result.err){
                enqueueSnackbar('저장이 완료되었습니다.');
                refreshGrid();
            }
        }
        
    }

    return (
        <DataGrid
            title='등록'
            selectProc='SLT_EDOC_ACCOUNTING_INOUT'
            selectParam={gridParam}
            horizonMode
            selectionMode='multi'
            headerItem={({selection}) => (
                <Fragment>
                    <IconButton
                        tooltip='확인'
                        icon={<CheckIcon />}
                        onClick={() => onSelectedRow(selection)}
                    />
                    <IconButton
                        tooltip='취소'
                        icon={<CloseIcon />}
                        onClick={() => cancelData(selection)}
                    />
                </Fragment>
            )}
            stickyHeader
        >
            <DataColumn
                fieldName='PRO_TYPE'
                title='유형'
                value={(row, value) => proType[value]}
            />
            <DataColumn
                fieldName='PRO_WORK_CD'
                visible={false}
            />
            <DataColumn
                fieldName='ACC_CD'
                visible={false}
            />
            <DataColumn
                fieldName='CST_CD'
                visible={false}
            />
            <DataColumn
                fieldName='DEBTOR'
                visible={false}
            />
            <DataColumn
                fieldName='CREDITOR'
                visible={false}
            />
            <DataColumn
                fieldName='DOC_CD'
                visible={false}
            />
            <DataColumn
                fieldName='PRO_CD'
                visible={false}
            />
            <DataColumn
                fieldName='CRT_CD'
                visible={false}
            />
            <DataColumn
                fieldName='SEQ'
                title='순번'
                visible={false}
            />
        </DataGrid>
    )
}

const dialogStyle = makeStyles((theme) => ({
    dialogPaper: {
        maxWidth: 'none',
        width: 800,
        height: 450,
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    actionButton: {
        display: 'flex',
    },
}))

const InOutDialog = ({ open, onClose, data, refreshGrid, clsState }) => {
    const classes = dialogStyle();
    const [dataSet, setDataSet] = useState();
    const [inOutDate, setInOutDate] = useState(moment().format('YYYY-MM-DD'));
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        data && setDataSet({
            data,
            fields: ['SEQ', 'DOC_CD', 'PRO_CD', 'PRO_WORK_CD', 'WORK_NM', 'ACC_CD', 'ACC_NM', 'CST_CD', 'CST_NM', 'DEBTOR', 'CREDITOR', 'INOUT_DATE', 'CRT_CD']
        })
    }, [data]);

    const valid = () => {
        const clsValid = Object.values(data.reduce((result, item) => {
            const workCd = item['PRO_WORK_CD'];
            if (inOutDate < clsState[workCd]) {
                if (!result[workCd]) {
                    return { ...result, [workCd]: item['WORK_NM'] };
                }
            }
            return result;
        }, {}));

        if (clsValid.length) {
            const msg = clsValid.reduce((result, workNm) => result + '\n' + workNm, '다음 사업장이 마감되어 있습니다.');
            enqueueSnackbar(msg, { variant: 'error' })
            return false;
        }
        return true;
    }

    const onInsert = async() => {
        setLoading(true)
        if (valid()) {
            const result = await callProc('INS_ELECT_ACCOUNTING_INOUT', {
                INOUT_DATE: inOutDate,
                JSON: data
            });

            if (!result.error) {
                enqueueSnackbar('저장이 완료 되었습니다.');
                refreshGrid();
                onClose();
            }
        }
        setLoading(false)
    }

    return (
        <Dialog
            PaperProps={{ className: classes.dialogPaper }}
            open={open}
        >
            <div className={classes.dialogTitle}>
                <div className={classes.actionButton}>{}
                    <DialogTitle>입/출금 등록</DialogTitle>
                    <DatePicker
                        style={{width: 150}}
                        label='입/출금일'
                        value={inOutDate}
                        onChange={value => setInOutDate(value)}
                    />
                    {loading? <ProgressDialog open={loading} /> :
                    <IconButton
                        tooltip='확인'
                        icon={<CheckIcon />}
                        disabled={data && data.length === 0}
                        onClick={onInsert}
                    /> }
                    
                </div>
                <IconButton
                    tooltip='닫기'
                    icon={<CloseIcon />}
                    onClick={onClose}
                />
            </div>
            <DataGrid
                dataSet={dataSet}
                headerLess
            >
                <DataColumn
                    fieldName='DEBTOR'
                    title='차변(출금)'
                    total={(values) => values.reduce((result, value) => result + value, 0)}
                />

                <DataColumn
                    fieldName='CREDITOR'
                    title='대변(입금)'
                    total={(values) => values.reduce((result, value) => result + value, 0)}
                />

                <DataColumn
                    fieldName='DOC_CD'
                    visible={false}
                />

                <DataColumn
                    fieldName='PRO_CD'
                    visible={false}
                />

                <DataColumn
                    fieldName='PRO_WORK_CD'
                    visible={false}
                />

                <DataColumn
                    fieldName='ACC_CD'
                    visible={false}
                />

                <DataColumn
                    fieldName='CST_CD'
                    visible={false}
                />

                <DataColumn
                    fieldName='INOUT_DATE'
                    visible={false}
                />
                <DataColumn
                    fieldName='CRT_CD'
                    visible={false}
                />
                <DataColumn
                    fieldName='SEQ'
                    title='순번'
                    visible={false}
                />
            </DataGrid>
        </Dialog>
    )
}

export default ElectAccountingInOut;