import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DatePicker, DataColumn, Tab, Tabs, ComboSearch, PdfButton } from '../../component';
import { makeStyles } from '@material-ui/core';
import { isFieldDiff } from '../../common/Utils';
import { GoodsNotGoodsInHistoryPdf } from '../../reports';
import { useSelector } from 'react-redux';

const useStyle = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    tabContainer : {
        display : 'flex',
    },
    tabbar : {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    content : {
        display: 'flex',
        flex: 1,
        height : 0,
    },
    hide : {
        display: 'none'
    },
}))

const GoodsNotGoodsInHistory = () => {
    const classes = useStyle();
    const [condition, setCondition] = useState(null);
    const [selectTab, setSelectTab] = useState(0);
    const [workData, setWorkData] = useState();

    return(
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => {
                sessionStorage['WORK_CD'] = condition['WORK_CD'];
                setCondition(condition);
                }}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            onDataLoad={(jsonData, rawData) => {
                                const result = [{ WORK_CD: 'ALL', WORK_NM: '전체' }, ...rawData]
                                setWorkData(result);
                                return result;
                            }}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='GDS_CD'
                    component={(state, setState, condition, setField) => {
                        let param = condition['GDS_PARAM'] || null;
                        if (isFieldDiff(['WORK_CD', 'STA_DATE'], condition, param)) {
                            param = { 
                                WORK_CD: condition['WORK_CD'] ,
                                GDS_TYPE: 'ALL',
                                NOT_TYPE: 0,
                                GDS_TYPE_M: 'ALL',
                                NOT_TYPE_M: 0,
                                GAUGE_TYPE: 'ALL',
                                STA_DATE: condition['STA_DATE'],
                            };
                            setField('GDS_PARAM', param);
                        }
                        return (
                            <ComboSearch
                                label='상품명'
                                selectProc='SLT_GAUGE_ITEM_GOODS_COMBO'
                                selectParam={param}
                                keyField='GDS_CD'
                                valueField='GDS_NM'
                                onDataLoad={(jsonData, rawData) => [{ GDS_CD: 'ALL', GDS_NM: '전체' }, ...rawData]}
                                value={state}
                                onChange={(value) => setState(value)}
                            />
                        )
                    }}
                />
                <SearchItem
                    fieldName='CST_CD'
                    component={(state, setState, condition, setField) => {
                        let param = condition['CST_PARAM'] || null;
                        if (isFieldDiff(['WORK_CD', 'STA_DATE'], condition, param)) {
                            param = { 
                                WORK_CD: condition['WORK_CD'],
                                CST_TYPE: [0, 2],
                                CST_KIND: [1, 2],
                                STA_DATE: condition['STA_DATE'],
                            };
                            setField('CST_PARAM', param);
                        }
                        return (
                            <ComboSearch
                                label='거래처'
                                selectProc='SLT_CUSTOMER_COMBO'
                                selectParam={param}
                                keyField='CST_CD'
                                valueField='CST_NM'
                                onDataLoad={(jsonData, rawData) => [{ CST_CD: 'ALL', CST_NM: '전체' }, ...rawData ]}
                                value={state}
                                onChange={(value) => setState(value)}
                            />
                        )
                    }}
                />
            </SearchPanel>
            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab label='전체' />
                    <Tab label='상품' />
                    <Tab label='비상품' />
                </Tabs>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <All condition={condition} workData={workData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <Goods condition={condition} workData={workData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 2 })}>
                <NotGoods condition={condition} workData={workData} />
            </div>

        </div>
        
    )
}



const All = ({condition, workData}) => {
    const [pdfData, setPdfData] = useState();
    const loginState = useSelector(state => state.loginReducer);

    const pdfComponent = useMemo(() => (
        <PdfButton title='상품비상품매입원장'>
            {pdfData &&
            <GoodsNotGoodsInHistoryPdf
                pdfData={pdfData}
                condition={condition}
                loginState={loginState}
                workName={workData.find(item => item['WORK_CD'] === condition['WORK_CD'])['WORK_NM']}
            />}
        </PdfButton>
    ), [pdfData, condition, loginState, workData]);
   
    return(
        <>
        <DataGrid
            title='전체 원장'
            selectProc='SLT_GOOD_NOTGOOD_ALL_INBOUND'
            selectParam={condition}
            horizonMode
            virtualized
            stickyHeader
            excelDownload={{ fileName: '상품비상품매입원장-전체.xlsx', sheetName: '전체' }}
            headerItem={({visibleAoa}) => {
                setPdfData(visibleAoa);
                return pdfComponent;
            }}
        >   
            <DataColumn fieldName='BIZ_DATE' title='영업일' />
            <DataColumn fieldName='CST_NM' title='매입처' />
            <DataColumn fieldName='GDS_NM' title='상품명' total={(values) => '합계'} />
            <DataColumn fieldName='QTY' title='매입 수량' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='PRICE' title='매입 단가' />
            <DataColumn fieldName='SPLY_AMT' title='공급가액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='VAT' title='부가세' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='AMOUNT' title='매입 금액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='OUT_AMT' title='지급 금액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='BAL_AMT' title='매입 잔액' />
            <DataColumn fieldName='WORK_CD' visible={false} />
            <DataColumn fieldName='CST_CD' visible={false} />
            <DataColumn fieldName='GDS_CD' visible={false} />
        </DataGrid>
        </>
    )
}


const Goods = ({condition, workData}) => {
    const [pdfData, setPdfData] = useState();
    const loginState = useSelector(state => state.loginReducer);

    const pdfComponent = useMemo(() => (
        <PdfButton title='상품비상품매입원장'>
            {pdfData &&
            <GoodsNotGoodsInHistoryPdf
                pdfData={pdfData}
                condition={condition}
                loginState={loginState}
                workName={workData.find(item => item['WORK_CD'] === condition['WORK_CD'])['WORK_NM']}
            />}
        </PdfButton>
    ), [pdfData, condition, loginState, workData]);


    return (
        <>
        <DataGrid
            title='상품 원장'
            selectProc='SLT_GOOD_INBOUND'
            selectParam={condition}
            horizonMode
            virtualized
            stickyHeader
            excelDownload={{ fileName: '상품비상품매입원장-상품.xlsx', sheetName: '상품' }}
            headerItem={({visibleAoa}) => {
                setPdfData(visibleAoa);
                return pdfComponent;
            }}
        >
            <DataColumn fieldName='BIZ_DATE' title='영업일' />
            <DataColumn fieldName='CST_NM' title='매입처' />
            <DataColumn fieldName='GDS_NM' title='상품명' total={(values) => '합계'} />
            <DataColumn fieldName='QTY' title='매입 수량' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='PRICE' title='매입 단가' />
            <DataColumn fieldName='SPLY_AMT' title='공급가액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='VAT' title='부가세' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='AMOUNT' title='매입 금액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='OUT_AMT' title='지급 금액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='BAL_AMT' title='매입 잔액' />
            <DataColumn fieldName='WORK_CD' visible={false} />
            <DataColumn fieldName='CST_CD' visible={false} />
            <DataColumn fieldName='GDS_CD' visible={false} />
        </DataGrid>
        </>
    )
}

const NotGoods = ({condition, workData}) => {
    const [pdfData, setPdfData] = useState();
    const loginState = useSelector(state => state.loginReducer);

    const pdfComponent = useMemo(() => (
        <PdfButton title='상품비상품매입원장'>
            {pdfData &&
            <GoodsNotGoodsInHistoryPdf
                pdfData={pdfData}
                condition={condition}
                loginState={loginState}
                workName={workData.find(item => item['WORK_CD'] === condition['WORK_CD'])['WORK_NM']}
            />}
        </PdfButton>
    ), [pdfData, condition, loginState, workData]);


    return (
        <>
        <DataGrid
            title='비상품 원장'
            selectProc='SLT_NOTGOOD_INBOUND'
            selectParam={condition}
            horizonMode
            virtualized
            stickyHeader
            excelDownload={{ fileName: '상품비상품매입원장-비상품.xlsx', sheetName: '비상품' }}
            headerItem={({visibleAoa}) => {
                setPdfData(visibleAoa);
                return pdfComponent;
            }}
        >
            <DataColumn fieldName='BIZ_DATE' title='영업일' />
            <DataColumn fieldName='CST_NM' title='매입처' />
            <DataColumn fieldName='GDS_NM' title='상품명' total={(values) => '합계'} />
            <DataColumn fieldName='QTY' title='매입 수량' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='PRICE' title='매입 단가' />
            <DataColumn fieldName='SPLY_AMT' title='공급가액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='VAT' title='부가세' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='AMOUNT' title='매입 금액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='OUT_AMT' title='지급 금액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='BAL_AMT' title='매입 잔액' />
            <DataColumn fieldName='WORK_CD' visible={false} />
            <DataColumn fieldName='CST_CD' visible={false} />
            <DataColumn fieldName='GDS_CD' visible={false} />
        </DataGrid>
        </>
    )
}

export default GoodsNotGoodsInHistory