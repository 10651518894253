import React, { cloneElement, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useFieldInputs } from '../common/Utils';

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 4,
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        boxShadow: theme.shadows[1]
    },
    content: {
        display: 'inline-block',
        flexDirection: 'row',
        flex: 1,
    },
}))

const SearchPanel = ({ children, onSearch, itemWidth, itemSpacing, disabled }) => {
    const [condition, dispatch] = useFieldInputs(
        React.Children.toArray(children).reduce((result, child) => ({
            ...result,
            [child.props.fieldName]: child.props.defaultValue
        }), {})
    );
    const init = useRef(false);

    useEffect(() => {
        const hasNull = Object.values(condition).reduce((result, item) => result || item === undefined, false)
        if (!init.current && !hasNull) {
            onSearch(condition);
            init.current = true;
        }
        // eslint-disable-next-line
    }, [condition])

    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div className={classes.content}>
                {React.Children.toArray(children).map(child =>
                    cloneElement(child, {
                        condition: condition,
                        dispatch: dispatch,
                        width: child.props.width || itemWidth,
                        spacing: child.props.spacing || itemSpacing,
                    })
                )}
            </div>
            <IconButton onClick={() => onSearch({ ...condition })} disabled={disabled}>
                <SearchIcon />
            </IconButton>
        </div>
    )
}

SearchPanel.propTypes = {
    onSearch: PropTypes.func,
    itemWidth: PropTypes.number,
    itemSpacing: PropTypes.number,
}

SearchPanel.defaultProps = {
    itemWidth: 200,
    itemSpacing: 100,
    disabled: false,
}

export default SearchPanel;