import React from 'react';
import { Dialog, CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    dialog : {
        backgroundColor: 'transparent',
        overflow: 'hidden',
    },
}))

const ProgressDialog = ({ open }) => {
    const classes = useStyles();
    return (
        <Dialog open={open} PaperProps={{ className: classes.dialog }}>
            <CircularProgress />
        </Dialog>
    )
}

export default ProgressDialog;