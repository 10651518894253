import React, { useMemo, useState } from 'react';
import { DataGrid, ComboBox, DatePicker, IconButton, SearchItem, SearchPanel, TextField, DataColumn, ComboSearch } from '../../component';
import { makeStyles, Paper } from '@material-ui/core';
import { useSelector } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import { useFieldInputs } from '../../common/Utils';
import { docState, lineType } from '../../common/Dictionary';
import { useSnackbar } from 'notistack';
import { callProc } from '../../common/DBConnector';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
    infoContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: 460,
    },
    info: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    input: {
        display: 'flex',
        flex: 2,
        flexDirection: 'column',
        marginLeft: theme.spacing(1),
    },
}));

const docType = {
    1: '전자회계결재',
    2: '전자기안문서',
}

const confirmType = {
    0: '미확인',
    1: '확인',
}

const ElecLineEdit = () => {
    const clsState = useSelector(state => state.clsDateReducer);
    const [condition, setCondition] = useState(null);
    const [selectRow, setSelectRow] = useState(null);
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const onKeyPress = (e, condition) => {
        if(e.key === 'Enter') {
            setCondition(condition);
        }
    }

    const onUpdateStart = row => {
        if(String(row['CONFM_TYPE']) === '1') {
            enqueueSnackbar('이미 확인된 담당자는 변경 할 수 없습니다.', { variant: 'warning' });
            return false;
        }
    }

    const onUpdate = async (row) => {
        if(!row['EMP_CD']) {
            enqueueSnackbar('담당자를 선택해주세요.');
            return false;
        }

        const result = await callProc('UPT_EDOCLINE_TYPE', row);
        
        if(!result.err) {
            enqueueSnackbar('저장이 완료되었습니다.');
        }
    }

    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => {
                setCondition(condition);
            }}>
                <SearchItem
                    fieldName='DOC_SEQ'
                    defaultValue={null}
                    component={(value, setState, condition) => (
                        <TextField
                            label='문서번호'
                            value={value}
                            onChange={value => setState(value)}
                            onKeyPress={e => onKeyPress(e, condition)}
                        />
                    )}      
                />
            </SearchPanel>
            <div className={classes.content}>
                <div className={classes.infoContainer}>
                    <DataGrid 
                        className={classes.info}
                        selectProc='SLT_EDOCHEADER_LIST'
                        selectParam={condition}
                        selectionMode='single'
                        onSelect={row => setSelectRow(row)}
                        headerLess
                        virtualized
                        horizonMode
                    >
                        <DataColumn fieldName='DOC_CD' visible={false} />
                        <DataColumn 
                            fieldName='DOC_TYPE'
                            value={(row, value) => docType[value]}
                        />
                        <DataColumn 
                            fieldName='DOC_STATE' 
                            value={(row, value) => docState[value]}
                        />
                        <DataColumn
                            fieldName='EMP_NM'
                            title='작성자'
                        />                        
                    </DataGrid>
                </div>
                <DataGrid 
                    className={classes.input}
                    selectProc='SLT_EDOCLINE_LIST'
                    selectParam={selectRow}
                    onUpdateStart={onUpdateStart}
                    onUpdate={onUpdate}
                    headerLess
                    horizonMode
                >
                    <DataColumn 
                        fieldName='LINE_TYPE' 
                        value={(row, value) => lineType[value]}
                        editable={(state) => lineType[state]}
                    />
                    <DataColumn 
                        fieldName='CONFM_TYPE' 
                        value={(row, value) => confirmType[value]}
                        editable={(state) => confirmType[state]}
                    />
                    <DataColumn 
                        fieldName='EMP_CD' 
                        title='담당자'
                        value={row => row['EMP_NM']}
                        editable={(state, dispatch) => (
                            <ComboSearch
                                style={{ width: 150 }}
                                selectProc='SLT_EMPINFO_ALL'
                                keyField='EMP_CD'
                                valueField='EMP_NM'
                                value={state}
                                onChange={value => dispatch(value)}
                            />
                        )}
                    />
                    <DataColumn 
                        fieldName='PRO_DTTM' 
                        editable={(state) => state}
                    />
                    <DataColumn fieldName='DOC_CD' visible={false} />
                    <DataColumn fieldName='SEQ' visible={false} />
                    <DataColumn fieldName='EMP_NM' visible={false} />
                </DataGrid>
            </div>
        </div>
    );
}

export default ElecLineEdit;