import React, { useEffect, useState } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DatePicker, DataColumn, Tab, Tabs } from '../../component';
import { makeStyles } from '@material-ui/core';
import { regType } from '../../common/Dictionary';
import { totalCount } from '../../common/Utils';


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        width: 0,
    },
    tabContainer: {
        display: 'flex',
    },
    tabbar: {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    tab: {
        minWidth: 100,
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
    hide: {
        display: 'none'
    },
}))


const SaleList = () => {
    const [condition, setCondition] = useState(null);
    const [selectTab, setSelectTab] = useState(0);

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => {
                sessionStorage['WORK_CD'] = condition['WORK_CD'];
                setCondition(condition)}}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            onDataLoad={(jsonData, rawData) => {
                                const result = [{ WORK_CD: 'ALL', WORK_NM: '전체' }, ...rawData];
                                return result;
                            }}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
            </SearchPanel>
            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab className={classes.tab} label='전체' />
                    <Tab className={classes.tab} label='현금' />
                    <Tab className={classes.tab} label='현금(소득공제)' />
                    <Tab className={classes.tab} label='외상' />
                    <Tab className={classes.tab} label='신용카드' />
                    <Tab className={classes.tab} label='상품권' />
                    <Tab className={classes.tab} label='포인트' />
                    <Tab className={classes.tab} label='쿠폰' />
                    <Tab className={classes.tab} label='현장할인' />
                    <Tab className={classes.tab} label='간편결제' />
                </Tabs>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <Sale condition={condition} payCdParam={'ALL'} cshRecept={0} title={'전체'} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <Sale condition={condition} payCdParam={'P0001'} cshRecept={0} title={'현금'} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 2 })}>
                <Sale condition={condition} payCdParam={'P0001'} cshRecept={1} title={'현금(소득공제)'} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 3 })}>
                <Sale condition={condition} payCdParam={'P0003'} cshRecept={0} title={'외상'} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 4 })}>
                <Sale condition={condition} payCdParam={'P0002'} cshRecept={0} title={'신용카드'} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 5 })}>
                <Sale condition={condition} payCdParam={'P0008'} cshRecept={0} title={'상품권'} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 6 })}>
                <Sale condition={condition} payCdParam={'P0007'} cshRecept={0} title={'포인트'} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 7 })}>
                <Sale condition={condition} payCdParam={'P0004'} cshRecept={0} title={'쿠폰'} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 8 })}>
                <Sale condition={condition} payCdParam={'P0006'} cshRecept={0} title={'현장할인'} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 9 })}>
                <Sale condition={condition} payCdParam={'P0009'} cshRecept={0} title={'간편결제'} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 10 })}>
                <Sale condition={condition} payCdParam={'P0000'} cshRecept={0} title={'기타'} />
            </div>
        </div>
    )
}

const Sale = ({ condition, payCdParam, cshRecept, title }) => {
    const [gridParam, setGridParam] = useState(null);

    useEffect(() => {
        condition && setGridParam({...condition, PAY_CD: payCdParam, CSH_RECEPT: cshRecept})
    }, [condition, payCdParam, cshRecept])



    return (
        <>
            <DataGrid
                title={title}
                selectProc='SLT_SALE_LIST'
                selectParam={gridParam}
                selectionMode='single'
                horizonMode
                totalGroup={['GDS_TYPE']}
                totalSort={(a, b) => a['GDS_TYPE'] < b['GDS_TYPE'] ? -1 : 1}
                virtualized
                excelDownload={{ fileName: `매출조회-${title}.xlsx`, sheetName: title }}
                stickyHeader
                sortable
                filterable
            >
                <DataColumn fieldName='PRICE' title='단가' />
                <DataColumn 
                    fieldName='GDS_NM' 
                    total={(values, data, group) => 
                        group == null ? `- 합 계 ${totalCount(values)} 건 -` : 
                        `(소 계 ${totalCount(values)} 건) ${data[0]['GDS_TYPE_NM']}` 
                    } 
                />
                <DataColumn fieldName='QTY' title='수량' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='SPLY_AMT' title='공급가액' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='VAT' title='부가세' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='AMOUNT' title='금액' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='SALE_TIME' title='판매시간' />
                <DataColumn fieldName='TAX_TYPE' title='과세' />
                <DataColumn fieldName='CARD_NM' title='결제수단' />
                <DataColumn
                    fieldName='REG_TYPE'
                    value={(row, value) => regType[value]}
                />
                <DataColumn fieldName='REMARK' title='비고' />
                <DataColumn fieldName='GDS_TYPE' visible={false} />
                <DataColumn fieldName='GDS_TYPE_NM' visible={false} />
                <DataColumn fieldName='WORK_CD' visible={false} />
                
            </DataGrid> 
        </>
    )
}

export default SaleList;