import React from 'react';
import { DataGrid, DataColumn, TextField } from '../../component';





const Incustomer = () => {



    return (
        <DataGrid
            title='기준매입 거래처'
            table='TB_INCUSTOMER'
            selectProc='SLT_INCUSTOMER'
            insertProc='INS_INCUSTOMER'
            updateProc='UPT_INCUSTOMER'
            deleteProc='DLT_INCUSTOMER'
            selectionMode='single'
 
            horizonMode
            refreshable
        >
       
          <DataColumn
                fieldName='IN_CST_NM'
                editable={(state, dispatch) => (
                    <TextField
                        style={{width: 150}}
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />


        </DataGrid>
    )
}



export default Incustomer;