import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import { SearchPanel, SearchItem, ComboBox, DataGrid, DataColumn, DatePicker } from '../../component';

const useStyle = makeStyles(() => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    title: {
        fontWeight: 'bold',
        backgroundColor: '#f1f2f4',
    }
}))

const TotalTrialBalance = () => {
    const classes = useStyle();
    const [condition, setCondition] = useState(null);

    const getCellProps = row => {
        let className;
        switch (row['SEQ']) {
            case 1:
                className = classes.title;
                break;
            default:
                break;
        }
        return { className };
    };

    return(
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => {
                if (condition['WORK_CD'] !== 'ALL'){
                    sessionStorage['WORK_CD'] = condition['WORK_CD'];
                }
                sessionStorage['STA_DATE'] = condition['STA_DATE'];
                sessionStorage['END_DATE'] = condition['END_DATE'];
                setCondition(condition);
                }}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            onDataLoad={(jsonData) => ({ ALL: '전체', ...jsonData })}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={sessionStorage['STA_DATE'] || moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={sessionStorage['END_DATE'] || moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
            </SearchPanel>

            <DataGrid
                title='합계잔액시산표'
                selectProc='SLT_TOTALBALANCE_TRIALBALANCE'
                selectParam={condition}
                selectionMode='single'  
                horizonMode    
                cellProps={getCellProps}    
            >
                <DataColumn fieldName='BAL_DEBTOR' title='차변잔액' />
                <DataColumn fieldName='DEBTOR' />
                <DataColumn fieldName='CLASS_NM' title='계정과목' />
                <DataColumn fieldName='CREDITOR' />
                <DataColumn fieldName='BAL_CREDITOR' title='대변잔액' />
                <DataColumn fieldName='SEQ' visible={false} />
            </DataGrid>
        </div>
    )
}

export default TotalTrialBalance