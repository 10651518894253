import React from 'react';
import { View, StyleSheet} from '@react-pdf/renderer';

const PdfRow = ({ children, ...props }) => {
    return(
        <View style={styles.tableRow} {...props}>
            {children}
        </View>
    )
}
const styles = StyleSheet.create({
    tableRow :{
        flexDirection : 'row',
        display : 'flex',
    }
})

export default PdfRow