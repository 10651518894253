import React, { useMemo, useState, useEffect } from 'react';
import clsx from 'clsx';
import { DataGrid, ComboBox, DataColumn, DatePicker, Tabs, Tab, NumberField, ComboSearch, IconButton, PaperTitle, FilePicker, ProgressDialog, TextField} from '../../component';
import { makeStyles, Paper } from '@material-ui/core';
import { callProc } from '../../common/DBConnector';
import { useSelector } from 'react-redux';
import { inMoneyRegType } from '../../common/Dictionary';
import SearchIcon from '@material-ui/icons/Search';
import { useFieldInputs, xlsToJsonAsync } from '../../common/Utils';
import { useSnackbar } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import CachedIcon from '@material-ui/icons/Cached';

const tabOccType = {
    0: {OCC_TYPE: 1, OCC_NM: '외상대 입금'},
    1: {OCC_TYPE: 10, OCC_NM: '외상대 입금(비상품)'},
    2: {OCC_TYPE: 2, OCC_NM: '제키드 입금'},
    3: {OCC_TYPE: 7, OCC_NM: '간편결제 입금'},
}

const useStyle = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    tabContainer: {
        display: 'flex',
    },
    subTabContainer: {
        backgroundColor: theme.palette.secondary.main,
        boxShadow: theme.shadows[4],
        marginBottom: 8,
    },
    tabbar: {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    tab: {
        minWidth: 100,
    },
    tabContent: {
        display: 'flex', 
        flex: 1, 
        height: 0
    },
    content: {
        flex: 1,
        marginRight: theme.spacing(1),
    },
    input: {
        width: 300,
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(1),
    },
    hide: {
        display: 'none'
    },
    searchPaper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    searchContainer: {
        flex: 1,
        display: 'inline-block',
        flexDirection: 'row',
    },
    searchItem: {
        display: 'inline-block',
        width: 200,
        marginRight: 100,
        marginBottom: theme.spacing(1),
    },
}))

const WorkInMoneyEnroll = () => {
    const classes = useStyle();
    const [selectTab, setSelectTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const clsState = useSelector(state => state.clsDateReducer);
    const [editRow, setEditRow] = useState();
    const [input, setInput] = useFieldInputs({
        WORK_CD: sessionStorage['WORK_CD'] === 'ALL' ? null : sessionStorage['WORK_CD'],
        BIZ_DATE: clsState[sessionStorage['WORK_CD']],
    });
    const { enqueueSnackbar } = useSnackbar();

    const condition = useMemo(() => (input['WORK_CD'] && input['BIZ_DATE']) ? input : null, [input]);

    const gridRefresh = () => {
        setInput({ type: 'UPDATE', value: { ...input } });
    }

    const valid = () => {
        return true;
    }

    const onDelete = async (row) => {
        if (valid()) {
            const result = await callProc('DLT_INMONEY', row);

            if (!result.err) {
                setInput({ ...input });
            }
        } else {
            return false;
        }
    }

    const progressOpen = (value) => setLoading(value);
    const onUpdateStart = row => {
        setEditRow(row);
        return false;
    }


    const fileSelected = async (file) => {
        progressOpen(true);
        const xls = await xlsToJsonAsync(file);
        let result;
        result = await callProc('INS_INMONEY_EXCEL', { INMONEY_JSON: Object.values(xls)[0], MOVE_JSON: Object.values(xls)[1] });
        if (!result.err) {
            enqueueSnackbar('엑셀 저장이 완료되었습니다.');
            gridRefresh();
        }
        progressOpen(false);
    }

    return(
        <div className={classes.container}>
            <ProgressDialog open={loading} />
            <Paper className={classes.searchPaper}>
                <div className={classes.searchContainer}>
                    <div className={classes.searchItem}>
                        <ComboBox
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={condition && condition['WORK_CD']}
                            onChange={value => {
                                if (input['WORK_CD'] !== value) {
                                    sessionStorage['WORK_CD'] = value;
                                    setInput('BIZ_DATE', clsState[value]);
                                    setInput('WORK_CD', value);
                                }
                            }}
                        />
                    </div>
                    <div className={classes.searchItem}>
                        <DatePicker
                            label='영업일'
                            minDate={clsState[input['WORK_CD']]}
                            value={condition && condition['BIZ_DATE']}
                            onChange={value => {
                                if (input['BIZ_DATE'] !== value) {
                                    setInput('BIZ_DATE', value);
                                    if(condition && condition['BIZ_DATE'] < clsState[condition['WORK_CD']]) {
                                        setInput({ type: 'UPDATE', value: { ...input, 'BIZ_DATE': value } });
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                <IconButton
                    tooltip=''
                    icon={<SearchIcon />}
                    onClick={gridRefresh}
                />
            </Paper>
            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab label='외상대 입금' />
                    <Tab label='외상대 입금(비상품)' />
                    <Tab label='제카드 입금' />
                    <Tab label='간편결제 입금' />
                </Tabs>
                <div className={classes.subTabContainer}>
                    <FilePicker
                        onChange={fileSelected}
                    />
                </div>
            </div>
            <div className={classes.tabContent}>
                <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                    <InMoney condition={condition} onUpdateStart={onUpdateStart} onDelete={row => onDelete(row)} initEditRow={() => setEditRow()} occParam={1} title='외상대 입금' />
                </div>
                <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                    <InMoney condition={condition} onUpdateStart={onUpdateStart} onDelete={row => onDelete(row)} initEditRow={() => setEditRow()} occParam={10} title='외상대 입금(비상품)' />
                </div>
                <div className={clsx(classes.content, { [classes.hide]: selectTab !== 2 })}>
                    <InMoney condition={condition} onUpdateStart={onUpdateStart} onDelete={row => onDelete(row)} initEditRow={() => setEditRow()} occParam={2} title='제카드 입금' />
                </div>
                <div className={clsx(classes.content, { [classes.hide]: selectTab !== 3 })}>
                    <InMoney condition={condition} onUpdateStart={onUpdateStart} onDelete={row => onDelete(row)} initEditRow={() => setEditRow()} occParam={7} title='간편결제 입금' />
                </div>
                <div className={classes.input}>
                    <EnrollPanel
                        condition={condition}
                        editRow={editRow}
                        initEditRow={() => setEditRow()}
                        gridRefresh={gridRefresh}
                        clsState={clsState}
                        selectTab={selectTab}
                    />
                </div>
            </div>
        </div>
    )
}

const InMoney = ({ condition, onUpdateStart, onDelete, occParam, title, initEditRow }) => {
    const [gridParam, setGridParam] = useState(null);

    useEffect(() => {
        condition && setGridParam({ ...condition, OCC_TYPE: occParam });
    }, [condition, occParam])



    return (
        <DataGrid
            title={title}
            selectProc='SLT_INMONEY'
            selectParam={gridParam}
            updateProc=''
            onDelete={(row) => onDelete(row)}
            onUpdateStart={onUpdateStart}
            onLoad={initEditRow}
            horizonMode
            virtualized
            selectionMode='single'
        >
            <DataColumn fieldName='REG_TYPE' value={(row, value) => inMoneyRegType[value]} />
            <DataColumn fieldName='CARD_NM' title='결제수단' />
            <DataColumn fieldName='WORK_CD' visible={false} />
            <DataColumn fieldName='CST_CD' visible={false} />
            <DataColumn fieldName='CARD_CD' visible={false} />
        </DataGrid>
    )
}

const usePanelStyles = makeStyles((theme) => ({
    panelContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: 0,
    },
    panelContent: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        overflowY: 'auto',
        '& > *': {
            marginBottom: theme.spacing(2),
        },
    },
    btnContainer: {
        alignSelf: 'flex-end',
        padding: theme.spacing(1),
    },
}))

const EnrollPanel = ({ condition, editRow, gridRefresh, clsState, selectTab, initEditRow }) => {
    const classes = usePanelStyles();
    const [cstParam, setCstParam] = useState();
    const [cardParam, setCardParam] = useState();
    const [editState, setEditState] = useState(0);
    const [befValue, setBefValue] = useState();
    const { enqueueSnackbar } = useSnackbar();

    const [insData, setInsData] = useFieldInputs({
        WORK_CD: condition && condition['WORK_CD'],
        INM_DATE: condition && condition['BIZ_DATE'],
        CST_CD: null,
        CARD_CD: null,
        AMOUNT: 0,
        REMARK: '',
        OCC_TYPE: tabOccType[selectTab]['OCC_TYPE'],
        PAY_CD: String(tabOccType[selectTab]['OCC_TYPE']) === '2' || 
                String(tabOccType[selectTab]['OCC_TYPE']) === '7' ? 
                'P0001' : null
    });

    useEffect(() => {
        setInsData({ type: 'INIT' });
        setEditState(0);
    }, [selectTab, setInsData])

    useEffect(() => {
        if (!editRow) {
            setInsData({ type: 'INIT' });
            setEditState(0);
        } else {
            setInsData({ type: 'UPDATE', value: editRow })
            setEditState(1);
        }
    }, [editRow, setInsData])

    useEffect(() => {
        if (condition) {
            setInsData({ type: 'INIT' });
            setEditState(0);
            setCstParam({
                WORK_CD: condition && condition['WORK_CD'],
                CST_TYPE: [1, 2],
                CST_KIND: [1, 2, 3, 4],
                STA_DATE: condition && condition['BIZ_DATE'],
            })
            setInsData('WORK_CD', condition['WORK_CD']);
            setInsData('BIZ_DATE', condition['BIZ_DATE']);
        }
    }, [condition, setInsData])

    const isValid = () => {
        if(insData['CST_CD'] == null) {
            enqueueSnackbar('거래처를 선택해 주세요.', { variant: 'error' });
            return false;
        }

        if(insData['CARD_CD'] == null) {
            enqueueSnackbar('결제수단을 선택해 주세요.', { variant: 'error' });
            return false;
        }

        if(String(insData['AMOUNT']) === '0') {
            enqueueSnackbar('금액을 입력해 주세요.', { variant: 'error' });
            return false;
        }

        return true;
    }

    const [loading, setLoading] = useState(false);

    const onInsert = async() => {
        setLoading(true);

        if(isValid()){
            let befData = {...insData};
            delete befData.INM_CD;
            delete befData.WORK_CD;
            delete befData.INM_DATE;
            delete befData.OCC_TYPE;
            delete befData.PAY_CD;
            setBefValue(befData);
            let procName;
            if(String(editState) === '0'){
                procName = 'INS_INMONEY'
            } else {
                procName = 'UPT_INMONEY'
            }
            const result = await callProc(procName, insData);
            if(!result.err) {
                enqueueSnackbar('저장이 완료되었습니다.');
                setInsData({ type: 'INIT' });
                setEditState(0);
                gridRefresh();
            }
        }

        setLoading(false);
    }

    const onExitEdit = () => {
        initEditRow();
    }

    const getBefValue = () => {
        befValue && setInsData({ type: 'UPDATE', value: {
            ...befValue,
        } })
    }

    const payCd = insData && insData['PAY_CD'];

    useEffect(() => setCardParam((prevCardParam) => (
        !condition?.WORK_CD || payCd === prevCardParam?.PAY_CD ?
        prevCardParam : {
            WORK_CD: condition?.WORK_CD,
            PAY_CD: payCd
        }
    )), [condition, payCd])


    return (
        <Paper className={classes.panelContainer} >
            <ProgressDialog open={loading} />
            <PaperTitle>{`${tabOccType[selectTab]['OCC_NM']} ${String(editState) === '0' ? '추가' : '수정'}`}</PaperTitle>
            <div className={classes.panelContent}>
                {String(editState) === '1' &&
                <TextField
                    label='입금코드'
                    value={insData['INM_CD']}
                    disabled
                />}
                <ComboBox
                    label='사업장'
                    selectProc='SLT_WORKPLACEINFO_AUTH'
                    keyField='WORK_CD'
                    valueField='WORK_NM'
                    value={insData['WORK_CD']}
                    disabled
                />
                <DatePicker
                    label='입금일'
                    minDate={clsState[insData['WORK_CD']]}
                    value={insData['INM_DATE']}
                    disabled
                />
                {cstParam &&
                <ComboSearch
                    label='거래처'
                    selectProc='SLT_CUSTOMER_COMBO'
                    selectParam={cstParam}
                    keyField='CST_CD'
                    valueField='CST_NM'
                    value={insData['CST_CD']}
                    onChange={value => setInsData('CST_CD', value)}
                    nullable
                />}
                {cardParam &&
                <ComboSearch
                    label='결제수단'
                    selectProc='SLT_CARDINFO_COMBO'
                    selectParam={cardParam}
                    keyField='CARD_CD'
                    valueField='CARD_NM'
                    value={insData['CARD_CD']}
                    onChange={value => setInsData('CARD_CD', value)}
                    nullable
                />}
                <NumberField
                    label='금액'
                    value={insData['AMOUNT']}
                    onChange={value => setInsData('AMOUNT', value)}
                />
                <TextField
                    label='비고'
                    value={insData['REMARK']}
                    onChange={value => setInsData('REMARK', value)}
                />
            </div>
            <div className={classes.btnContainer}>
                {String(editState) === '1' &&
                <IconButton
                    tooltip='취소'
                    icon={<CloseIcon />}
                    onClick={() => onExitEdit()}
                />}
                <IconButton
                    tooltip='이전값 불러오기'
                    icon={<CachedIcon />}
                    onClick={() => getBefValue()}
                />
                <IconButton
                    tooltip='확인'
                    icon={<CheckIcon />}
                    onClick={() => onInsert()}
                />
            </div>
        </Paper>
    )
}



export default WorkInMoneyEnroll