import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles, Paper, TextField, Tooltip, IconButton } from '@material-ui/core';
import { callProc } from '../../common/DBConnector';
import { ProgressPanel, SearchPanel, SearchItem, ComboBox, DatePicker, DataGrid, DataColumn, PaperTitle } from '../../component';
import { useFieldInputs } from '../../common/Utils';
import CheckIcon from '@material-ui/icons/Check';

const transOutType = {
    '0' : '전체',
    '1' : '출고 대기',
    '2' : '출고 완료',
}

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
    info: {
        flex: 2,
        marginRight: theme.spacing(1),
    },
    input: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginLeft: theme.spacing(1),
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    paperContent: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: theme.spacing(2),
        paddingTop: 0,
    },
    inputField: {
        display: 'block',
    },
    btnContainer: {
        alignSelf: 'flex-end',
        marginTop: 'auto',
    },
}))

const TransOutEnroll = () => {
    const [condition, setCondition] = useState(null);
    const [selectRow, setSelectRow] = useState();

    const [workData, setWorkData] = useState(); // 사업장
    const [gdsData, setGdsData] = useState(); // 상품
    const [nzlData, setNzlData] = useState();

    const [insData, setInsData] = useFieldInputs({});
    const [insProg, setInsProg] = useState(false);

    const classes = useStyles();

    useEffect(() => {
        const fetchData = async () => {
            const workData = await callProc('SLT_WORKPLACEINFO_AUTH');
            const gdsData = await callProc('SLT_GOODSINFO');

            setWorkData(workData.data.reduce((result, item) => ({
                ...result,
                [item['WORK_CD']]: item['WORK_NM']
            }), {}))

            setGdsData(gdsData.data.reduce((result, item) => ({
                ...result,
                [item['GDS_CD']] : item['GDS_NM']
            }), {}))
        }
        fetchData();
    }, [])

    const fetchNzlData = async(row) => {
        setNzlData(null);
        const data = (await callProc('SLT_NOZZLEINFO_DATE', { 'WORK_CD': row['SHIP_TRN_CD'], 'BIZ_DATE': row['SHIP_REQ_DATE'], 'GDS_CD': row['GDS_CD'] }))
            .data.reduce((result, item) => ({
                ...result,
                [item['NZL_NO']] : item['NZL_NO']
            }), {});

        setNzlData(data);
        setInsData('NZL_NO', Object.keys(data)[0]);
    }

    const onRowSelect = (row) => {
        setSelectRow(row);
        if (isEnrollable(row)) {
            fetchNzlData(row);
            setInsData('OUT_DATE', row['SHIP_REQ_DATE']);
        } else {
            if (row) {
                setNzlData({ [row['NZL_NO']]: row['NZL_NO'] })
                setInsData('NZL_NO', row['NZL_NO']);
                setInsData('OUT_DATE', row['OUT_DATE']);
            } else {
                setInsData('NZL_NO', null);
                setInsData('OUT_DATE', null);
            }
        }
        
    }

    const onInsert = async() => {
        setInsProg(true);

        await callProc('INS_TANKOUTBOUNDINFO', { ...selectRow, ...insData, 'OUT_QTY': selectRow['QTY'] })
        refreshGrid();

        setInsProg(false);
    }

    const refreshGrid = () => setCondition({...condition})

    const isEnrollable = (row) => row != null && row['QTY'] > row['OUT_QTY']

    return (
        (!workData || !gdsData) ? <ProgressPanel/> :
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => setCondition(condition)}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={'ALL'}
                    component={(state, dispatch) => (
                        <ComboBox
                            label='출하사업장'
                            data={{ 'ALL': '전체', ...workData }}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='ORD_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, dispatch) => (
                        <DatePicker
                            label='오더일자'
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='TRANS_OUT_TYPE'
                    defaultValue={Object.keys(transOutType)[1]}
                    component={(state, dispatch) => (
                        <ComboBox
                            label='출고상태'
                            data={transOutType}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
            </SearchPanel>

            <div className={classes.content}>
                <div className={classes.info}>
                    <DataGrid
                        title='오더 정보'
                        selectProc='SLT_ORDERINFO_TRANSOUT'
                        selectParam={condition}
                        onSelect={onRowSelect}
                    >
                        <DataColumn
                            fieldName='GDS_CD'
                            title='상품명'
                            value={(row, value) => gdsData[value]}
                        />
                        <DataColumn
                            fieldName='BIZ_DATE'
                            title='오더일자'
                        />
                        <DataColumn
                            fieldName='SHIP_TRN_CD'
                            title='출하처'
                            value={(row, value) => workData[value]}
                        />
                        <DataColumn
                            fieldName='DEST_TRN_CD'
                            title='인도처'
                            value={(row, value) => workData[value]}
                        />
                        <DataColumn
                            fieldName='NZL_NO'
                            visible={false}
                        />
                        <DataColumn
                            fieldName='OUT_DATE'
                            visible={false}
                        />
                    </DataGrid>
                </div>
                <div className={classes.input}>
                    <Paper className={classes.paper}>
                        <PaperTitle>
                            출고 등록
                        </PaperTitle>
                        <div className={classes.paperContent}>
                            <TextField
                                label='사업장명'
                                className={classes.inputField}
                                value={selectRow ? workData[selectRow['SHIP_TRN_CD']] : ''}
                                disabled
                            />
                            <TextField
                                label='수량'
                                className={classes.inputField}
                                value={selectRow ? selectRow['QTY'] : ''}
                                disabled
                            />
                            <div style={{ width: 168 }}>
                                {nzlData === null ?
                                <ProgressPanel small /> :    
                                <ComboBox
                                    label='노즐번호'
                                    className={classes.inputField}
                                    data={nzlData}
                                    value={insData['NZL_NO']}
                                    onChange={(value) => setInsData('NZL_NO', value)}
                                    disabled={!isEnrollable(selectRow)}
                                />
                                }
                            </div>
                            <DatePicker
                                label='출고일'
                                className={classes.inputField}
                                value={insData['OUT_DATE'] || moment().format('YYYY-MM-DD')}
                                onChange={(value) => setInsData('OUT_DATE', value)}
                                disabled={!isEnrollable(selectRow)}
                            />
                            <div className={classes.btnContainer}>
                                {insProg ? <ProgressPanel /> :
                                <Tooltip title='확인'>
                                <IconButton
                                    component='div'
                                    onClick={onInsert}
                                    disabled={!isEnrollable(selectRow)}
                                >
                                    <CheckIcon />
                                </IconButton>
                                </Tooltip>}
                            </div>
                        </div>
                    </Paper>
                </div>
            </div>
        </div>
    )
}

export default TransOutEnroll;