import React from 'react';
import { Document, Link, StyleSheet, Text, View} from '@react-pdf/renderer';
import { PdfCell, PdfTable, PdfRow, PdfPage } from '../component';

const titleBackColor = '#EFEFEF';

const styles = StyleSheet.create({
    titleSort : {
        alignSelf: 'left',
        fontSize : 12,
        marginBottom : 0,
    },
    docContent : {
        display: 'flex',
        flexDirection: 'row',
        fontSize: 9,
        marginTop: 8,
        justifyContent: 'space-between',
    },
    subTitle : {
        fontSize: 10,
        marginTop: 8,
    },
    tableContent : {
        marginTop: 8,
        border: 1,
    },
    headerCell: {
        fontSize: 10,
        height: 20,
        justifyContent: 'center',
        // borderRight: 0,
        border: 0,
        backgroundColor: titleBackColor,
    },
    contentCell: {
        fontSize: 9,
        height: 20,
        justifyContent: 'center',
        // borderRight: 0,
        border: 0,
    },
    remarkTitle: {
        height: 20, 
        // border: 1,
        justifyContent: 'center',
        backgroundColor: titleBackColor,
    },
    remarkContainer: {
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
        border: 0,
    },
    remarkContent : {
        width: '100%',
        marginBottom: 4,
        fontSize: 9,
    },
    proDataHeaderCenter: { //processData
        display: 'flex', 
        flex: 'none', 
        fontSize: 7.5,
        height: 15,
        justifyContent: 'center',
        // borderRight: 0,
        border: 0,
        backgroundColor: titleBackColor,
    },
    proDataHeaderLeft: { //processData
        display: 'flex', 
        flex: 'none', 
        fontSize: 7.5,
        height: 15,
        justifyContent: 'center',
        textAlign: 'left',
        // borderRight: 0,
        border: 0,
        backgroundColor: titleBackColor,
    },
    proDataHeaderRight: { //processData
        display: 'flex', 
        flex: 'none', 
        fontSize: 7.5,
        height: 15,
        justifyContent: 'center',
        textAlign: 'right',
        // borderRight: 0,
        border: 0,
        backgroundColor: titleBackColor,
    },
    proDataContentCellCenter: {
        display: 'flex', 
        flex: 'none', 
        fontSize: 7.5,
        paddingTop: 3,
        paddingBottom: 3,
        // height: 15,
        justifyContent: 'center',
        // borderRight: 0,
        border: 0,
    },
    proDataContentCellLeft: {
        display: 'flex', 
        flex: 'none', 
        fontSize: 7.5,
        paddingTop: 3,
        paddingBottom: 3,
        // height: 15,
        justifyContent: 'center',
        textAlign: 'left',
        // borderRight: 0,
        border: 0,
    },
    proDataContentCellRight: {
        display: 'flex', 
        flex: 'none', 
        fontSize: 7.5,
        paddingTop: 3,
        paddingBottom: 3,
        // height: 15,
        justifyContent: 'center',
        textAlign: 'right',
        // borderRight: 0,
        border: 0,
    },
    pageNumber: {
        position: 'absolute',
        bottom: 15,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
    fileContainer: {
        flexDirection: 'row',
        marginTop: 16,
    },
    fileLink: {
        marginLeft: 4,
        marginBottom: 8,
    },
})

const EdocumentPdf = ({ data, lineData, lineRef, remarkData, fileData, fileKey}) => {
    return (
        <Document title={`전자결재_${data['REGDATE'].replace(/-/g, '')}_${data['DOC_CD']}`}>
            <PdfPage>
                <View style={styles.titleSort}>
                    <Text>{`${data['DOC_TITLE']}`}</Text>
                    <View style={styles.docContent}>
                        <Text>{`작성자 : ${data['DEP_NM'] || ''} / ${data['EMP_NM'] || ''} ${data['POSITION'] || ''} ${data['REGDTTM']}`}</Text>
                        <Text>{`문서번호 : ${data['DOC_CD']}`}</Text>
                    </View>
                </View>
                <View style={styles.subTitle}>
                    <Text>{`[결재 정보]`}</Text>
                </View>
                <View style={styles.tableContent}>
                    <PdfTable>
                        <PdfRow>
                            <PdfCell cellTop cellLeft style={{...styles.headerCell}}>분류</PdfCell>
                            <PdfCell cellTop style={{...styles.headerCell}}>부서</PdfCell>
                            <PdfCell cellTop style={{...styles.headerCell}}>성명</PdfCell>
                            <PdfCell cellTop style={{...styles.headerCell}}>상태</PdfCell>
                            <PdfCell cellTop style={{...styles.headerCell, borderRight: 1}}>결재일시</PdfCell>
                        </PdfRow>
                        {lineData && lineData.map((item, idx) => (
                            <PdfRow key={idx}>
                                <PdfCell cellLeft style={{ ...styles.contentCell }}>{item['LINE_TYPE']}</PdfCell>
                                <PdfCell style={{ ...styles.contentCell }}>{item['DEP_NM']}</PdfCell>
                                <PdfCell style={{ ...styles.contentCell }}>{item['EMP_NM']}</PdfCell>
                                <PdfCell style={{ ...styles.contentCell }}>{item['CONFM_TYPE']}</PdfCell>
                                <PdfCell style={{ ...styles.contentCell}}>{item['PRO_DTTM']}</PdfCell>
                            </PdfRow>
                        ))}
                    </PdfTable>
                </View>
                <View style={styles.subTitle}>
                    <Text>{`[참조자]`}</Text>
                </View>
                <View style={styles.tableContent}>
                    <View style={styles.remarkTitle}>
                        <Text style={{marginLeft: 16, fontSize: 10}}>참조자</Text>
                    </View>
                    <View style={styles.remarkContainer}>
                        {!lineRef.length ?
                        <Text style={styles.remarkContent}>참조자 없음</Text> :
                        <View>
                            <Text style={styles.remarkContent}>
                                {`${lineRef[0]['REF_NM']}`}
                            </Text>
                        </View>
                        }
                    </View>
                </View>
                <View style={styles.subTitle}>
                    <Text>{`[참고 사항]`}</Text>
                </View>
                <View style={styles.tableContent}>
                    <View style={styles.remarkTitle}>
                        <Text style={{marginLeft: 16, fontSize: 10}}>내용</Text>
                    </View>
                    <View style={styles.remarkContainer}>
                        {!remarkData.length ?
                        <Text style={styles.remarkContent}>내용 없음</Text> :
                        remarkData.map(item => (
                        <View key={item['SEQ']}>
                                <Text style={styles.remarkContent}>
                                    {`${item['DEP_NM']} / ${item['POSITION']} / ${item['EMP_NM']} / ${item['EDTDATE']}`}
                                </Text>
                                <Text style={styles.remarkContent}>
                                    {`- ${item['REMARK']}`}
                                </Text>
                            </View>
                        ))}
                    </View>
                </View>
                <View style={{marginTop: 16, height: 20, fontSize: 15, alignSelf: 'center', justifyContent: 'center'}}>
                    <Text>기안 문서 내용</Text>
                </View>
                <View style={styles.subTitle}>
                    <Text>{`[문서 내용]`}</Text>
                </View>
                <View style={styles.tableContent}>
                    <View style={styles.remarkTitle}>
                        <Text style={{marginLeft: 16, fontSize: 10}}>내용</Text>
                    </View>
                    <View style={styles.remarkContainer}>
                        <Text style={styles.remarkContent}>{data['DOC_CONTENT']}</Text>
                    </View>
                </View>
                {fileData && fileData.length > 0 &&
                <View style={styles.fileContainer}>
                    <Text>
                        [첨부 파일]
                    </Text>
                    <View>
                        {fileData.map(item =>
                            <Link
                                key={item.seq}
                                style={styles.fileLink}
                                src={`${document.URL}api/file_down?fileKey=${fileKey}&seq=${item.seq}`}
                            >
                                {item.name}
                            </Link>
                        )}
                    </View>
                </View>}
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </PdfPage>
        </Document>
    )
}

export default EdocumentPdf;