import React from 'react';
import SaleEnroll from './SaleEnroll';
import SaleList from './SaleList';
import CreditCheck from './CreditCheck';
import CustomerSale from './CustomerSale';
import CardSaleInfo from './CardSaleInfo';
import TaxInvoiceEnroll from './TaxInvoiceEnroll';
import CstTaxInvoiceEnroll from './CstTaxInvoiceEnroll';
import GdsTaxInvoiceEnroll from './GdsTaxInvoiceEnroll';
import TaxInvoiceMng from './TaxInvoiceMng';
import SaleAdjustEnroll from './SaleAdjustEnroll';
import PosErrorData from './PosErrorData';
import OilTradeReport from './OilTradeReport';
import SaleListSum from './SaleListSum';
import SaleAdjustEnrollDel from './SaleAdjustEnrollDel';
import SaleDetail from './SaleDetail';
import SaleAdjustEnrollDelDetail from './SaleAdjustEnrollDelDetail';
import SaleAdjustEnrollDelDetailList from './SaleAdjustEnrollDelDetailList';
import SaleAdjustEnrollCarWash from './SaleAdjustEnrollCarWash';
import SaleAdjustEnrollDelCarWash from './SaleAdjustEnrollDelCarWash';
import VenExcleUpload from './VenExcleUpload';
import AgencyTradeReport from './AgencyTradeReport';
import AgencySale from './AgencySale';
import SaleEnrollManual from './SaleEnrollManual';
import TaxInvoiceList from './TaxInvoiceList';
import CustomerSaleHistory from './CustomerSaleHistory';

const TaxInvoiceInfo = () => <TaxInvoiceMng restAuth />;

export {
    SaleEnroll,
    SaleEnrollManual,
    SaleList,
    CreditCheck,
    CustomerSale,
    CardSaleInfo,
    TaxInvoiceEnroll,
    CstTaxInvoiceEnroll,
    GdsTaxInvoiceEnroll,
    TaxInvoiceMng,
    TaxInvoiceInfo,
    SaleAdjustEnroll,
    PosErrorData,
    OilTradeReport,
    SaleListSum,
    SaleAdjustEnrollDel,
    SaleDetail,
    SaleAdjustEnrollDelDetail,
    SaleAdjustEnrollDelDetailList,
    SaleAdjustEnrollCarWash,
    SaleAdjustEnrollDelCarWash,
    VenExcleUpload,
    AgencyTradeReport,
    AgencySale,
    TaxInvoiceList,
    CustomerSaleHistory,
}