import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, Divider, List, ListItem, makeStyles } from '@material-ui/core';
import { IconButton, ProgressDialog, TextField } from '../../component';
import { Add, Check, Close, Delete, Edit } from '@material-ui/icons';
import { useFieldInputs } from '../../common/Utils';
import { callProc } from '../../common/DBConnector';
import { useSnackbar } from 'notistack';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    dialog : {
        display: 'flex',
        flexDirection: 'column',
        maxWidth : 'none',
        minWidth : 700,
        minHeight : 600,
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        paddingTop: 0,
    },
    remarkContainer: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    remark: {
        flex: 1,
    },
    remarkInput: {
        height: '400px !important',
    },
    listItem: {
        justifyContent: 'space-between',
    },
    itemContent: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
}));

const ElectRemarkDialog = ({ open, onClose, onInserted, docCd, data = [] }) => {
    const [insData, setInsData] = useFieldInputs({ REMARK: '' });
    const [loading, setLoading] = useState(false);
    
    const loginState = useSelector(state => state.loginReducer);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const classes = useStyles();

    useEffect(() => {
        open && setInsData({ type: 'INIT' })
    }, [open, setInsData])

    const valid = () => {
        if (!insData['REMARK'] || insData['REMARK'].trim() === '') {
            enqueueSnackbar('내용을 입력해주세요.', {variant: 'error'});
            return false;
        }
        return true;
    }

    const onInsert = async() => {
        setLoading(true);

        if (valid()) {
            let procName;
            if (insData['SEQ'] != null) {
                procName = 'UPT_EDOCREMARK';
            } else {
                procName = 'INS_EDOCREMARK';
            }
            const result = await callProc(procName, { DOC_CD: docCd, ...insData });
            if (!result.err) {
                onInserted();
                enqueueSnackbar('저장이 완료되었습니다.');
                onClose();
            }
        }

        setLoading(false);
    }

    const reqDelete = (item) => {
        closeSnackbar();
        enqueueSnackbar('삭제할까요?', {
            variant: 'warning',
            autoHideDuration: null,
            action: key => <>
                <Button onClick={() => onDelete(item, key)}>
                    예
                </Button>
                <Button onClick={() => closeSnackbar(key)}>
                    아니오
                </Button>
            </>,
        })
    }

    const onDelete = async(item, key) => {
        setLoading(true);

        closeSnackbar(key);
        const result = await callProc('DLT_EDOCREMARK', item);
        if (!result.err) {
            onInserted();
            enqueueSnackbar('삭제가 완료되었습니다.');
            onClose();
        }

        setLoading(false);
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{ className: classes.dialog }}
        >
            <div className={classes.dialogTitle}>
                <DialogTitle>
                    참고 사항
                </DialogTitle>
                <div>
                    <IconButton
                        tooltip='닫기'
                        onClick={onClose}
                        icon={<Close />}
                    />
                </div>
            </div>
            <div className={classes.content}>
                <List>
                    {data.map(item => (
                        <Fragment key={item['SEQ']}>
                            <ListItem className={classes.listItem}>
                                <div className={classes.itemContent}>
                                    <div>
                                        {`${item['DEP_NM']} / ${item['POSITION']} / ${item['EMP_NM']} / ${item['EDTDATE']}`}
                                    </div>
                                    {insData['SEQ'] === item['SEQ'] ?
                                    <div className={classes.remarkContainer}>
                                        <TextField
                                            className={classes.remark}
                                            inputProps={{ className: classes.remarkInput }}
                                            label='내용'
                                            value={insData['REMARK']}
                                            onChange={value => setInsData('REMARK', value)}
                                            multiline
                                        />
                                        <div>
                                            <IconButton
                                                tooltip='확인'
                                                icon={<Check />}
                                                onClick={onInsert}
                                            />
                                            <IconButton
                                                tooltip='취소'
                                                icon={<Close />}
                                                onClick={() => setInsData('SEQ', '')}
                                            />
                                        </div>
                                    </div> :
                                    <div dangerouslySetInnerHTML={{ __html: `- ${item['REMARK'].replaceAll('\n', '<br/>')}` }}>
                                    </div>}
                                </div>
                                {insData['SEQ'] !== item['SEQ'] && loginState['EMP_CD'] === item['EMP_CD'] &&
                                <Fragment>
                                    <IconButton
                                        tooltip='수정'
                                        icon={<Edit />}
                                        onClick={() => {
                                            setInsData({ type: 'UPDATE', value: item });
                                            closeSnackbar();
                                        }}
                                    />
                                    <IconButton
                                        tooltip='삭제'
                                        icon={<Delete />}
                                        onClick={() => reqDelete(item)}
                                    />
                                </Fragment>}
                            </ListItem>
                            <Divider />
                        </Fragment> 
                    ))}
                    <ListItem className={classes.remarkContainer}>
                        {insData['SEQ'] == null ?
                        <Fragment>
                            <TextField
                                className={classes.remark}
                                inputProps={{ className: classes.remarkInput }}
                                label='내용'
                                value={insData['REMARK']}
                                onChange={value => setInsData('REMARK', value)}
                                multiline
                            />
                            <IconButton
                                tooltip='확인'
                                icon={<Check />}
                                onClick={onInsert}
                            />
                        </Fragment> :
                            <IconButton
                                tooltip='추가'
                                icon={<Add />}
                                onClick={() => {
                                    setInsData({ type: 'INIT' });
                                    closeSnackbar();
                                }}
                            />}
                    </ListItem>
                </List>
            </div>
            <ProgressDialog open={loading} />
        </Dialog>
    )
}

export default ElectRemarkDialog;