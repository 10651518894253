import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DatePicker, DataColumn, Tab, Tabs, NumberField, DateTimePicker, ComboSearch, TextField } from '../../component';
import { makeStyles } from '@material-ui/core';
import { isFieldDiff } from '../../common/Utils';

const useStyle = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    tabContainer : {
        display : 'flex',
    },
    tabbar : {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    content : {
        display: 'flex',
        flex: 1,
        height : 0,
    },
    hide : {
        display: 'none'
    },
}))

const BasicDataEnroll = () => {
    const classes = useStyle();
    const [condition, setCondition] = useState(null);
    const [selectTab, setSelectTab] = useState(0);

    return(
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => {setCondition(condition)}}>
                <SearchItem
                    fieldName='WORK_CD'
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
            </SearchPanel>
            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab label='계기' />
                    <Tab label='외상 매출처 채권' />
                    <Tab label='카드사' />
                    <Tab label='모바일/포인트/할인' />
                    <Tab label='계기상품 기초재고' />
                    <Tab label='일반상품 기초재고' />
                    <Tab label='희석액/회원카드 기초재고' />
                </Tabs>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <BasicGauge condition={condition} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <BasicCstCredit condition={condition} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 2 })}>
                <BasicCardCredit condition={condition} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 3 })}>
                <BasicEtcCredit condition={condition} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 4 })}>
                <BasicGoodsStock condition={condition} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 5 })}>
                <BasicItemStock condition={condition} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 6 })}>
                <BasicSelfWashStock condition={condition} />
            </div>
        </div>
    )
}

const BasicGauge = ({condition}) => {
    const gdsParam = useRef();

    return(
        <DataGrid
            title='기초 계기'
            selectProc='SLT_BASIC_GAUGE_ENROLL'
            insertProc='INS_BASIC_GAUGE_ENROLL'
            updateProc='UPT_BASIC_GAUGE_ENROLL'
            deleteProc='DLT_BASIC_GAUGE_ENROLL'
            selectParam={condition}
            keys={['TNK_TRANS_CD', 'BIZ_DATE']}
            selectionMode='single'
        >
            <DataColumn
                fieldName='BIZ_DATE'
                title='일자'
                editable={(state, dispatch) => (
                    <DatePicker
                        value={state}
                        onChange={value => {
                            dispatch(value);
                        }}
                    />
                )}
            />
            <DataColumn
                fieldName='WORK_CD'
                title='사업장'
                value={row => row['WORK_NM']}
                editable={(state, dispatch) => (
                    <ComboBox
                        selectProc='SLT_WORKPLACEINFO_AUTH'
                        keyField='WORK_CD'
                        valueField='WORK_NM'
                        value={condition['WORK_CD']}
                        onChange={value => {
                            gdsParam.current={WORK_CD: value};
                            dispatch(value);
                        }}
                        disabled
                    />
                )}
            />
            <DataColumn
                fieldName='GDS_TYPE'
                value={row => row['CATE_NM']}
                editable={(state, dispatch) => (
                    <ComboBox
                        selectProc='SLT_CATEGORYINFO'
                        keyField='GDS_TYPE'
                        valueField='CATE_NM'
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />
            <DataColumn
                fieldName='NZL_NO'
                title='노즐'
                editable={(state, dispatch, rowState, setField) => {
                    let param = rowState['NZL_PARAM'] || null;
                    if (isFieldDiff(['WORK_CD', 'BIZ_DATE', 'GDS_TYPE'], rowState, param)) {
                        param = { WORK_CD: rowState['WORK_CD'], BIZ_DATE: rowState['BIZ_DATE'], GDS_TYPE: rowState['GDS_TYPE'] };
                        setField('NZL_PARAM', param);
                    }
                    return(
                    <ComboBox
                        selectProc='SLT_NOZZLEINFO_DATE'
                        selectParam={param}
                        keyField='NZL_NO'
                        valueField='NZL_NO'
                        value={state}
                        onChange={value => dispatch(value)}
                    />)
                }}
            />

            <DataColumn
                fieldName='QTY'
                title='계기값'
                editable={(state, dispatch) => (
                    <NumberField
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />

            <DataColumn
                fieldName='SALE_TYPE'
                title='판매유형'
                defaultValue={'S00'} // 초기값
                visible={false}
            />
            <DataColumn
                fieldName='WORK_NM'
                visible={false}
            />
            <DataColumn
                fieldName='CATE_NM'
                visible={false}
            />
        </DataGrid>
    )
}

const BasicCstCredit = ({condition}) => {
    const cstPram = useRef();

    return(
        <DataGrid
            title='외상거래처 채권'
            selectProc='SLT_BASIC_CSTCREDIT_ENROLL'
            insertProc='INS_BASIC_CSTCREDIT_ENROLL'
            updateProc='UPT_BASIC_CSTCREDIT_ENROLL'
            deleteProc='DLT_BASIC_CSTCREDIT_ENROLL'
            selectParam={condition}
            selectionMode='single'
            keys={['ACJ_CD', 'ACJ_DATE']}
            horizonMode
        >
            <DataColumn fieldName='ACJ_CD' title='코드' />

            <DataColumn
                fieldName='ACJ_DATE'
                title='일자'
                editable={(state, dispatch) => (
                    <DatePicker
                        style={{width: 150}}
                        value={state}
                        onChange={value => {
                            dispatch(value);
                        }}
                    />
                )}
                
            />

            <DataColumn
                fieldName='WORK_CD'
                title='사업장'
                value={row => row['WORK_NM']}
                editable={(state, dispatch) => (
                    <ComboBox
                        selectProc='SLT_WORKPLACEINFO_AUTH'
                        style={{width: 150}}
                        keyField='WORK_CD'
                        valueField='WORK_NM'
                        value={condition['WORK_CD']}
                        onChange={value => {
                            cstPram.current={ ...cstPram.current, WORK_CD: value }
                            dispatch(value);
                        }}
                        disabled
                    />
                )}
            />

            <DataColumn
                fieldName='CST_CD'
                title='거래처'
                value={row => row['CST_NM']}
                editable={(state, dispatch) => (
                    <ComboSearch
                        selectProc='SLT_CUSTOMERINFO_NOTEND'
                        style={{width: 200}}
                        selectParam={cstPram.current}
                        keyField='CST_CD'
                        valueField='CST_NM'
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />

            <DataColumn 
                fieldName='DEBTOR' 
                title='금액' 
                total={(values) => values.reduce((result, value) => result + value, 0)} 
                editable={(state, dispatch) => (
                    <NumberField
                        style={{width: 150}}
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />
            <DataColumn 
                fieldName='SUMUP' 
                title='비고'
                editable={(state, dispatch) => (
                    <TextField
                        style={{width: 200}}
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )} />

            <DataColumn
                fieldName='CST_NM'
                visible={false}
            />

            <DataColumn
                fieldName='WORK_NM'
                visible={false}
            />

            
        </DataGrid>
    )
}

const BasicCardCredit = ({condition}) => {
    const cstPram = useRef();

    return(
        <DataGrid
            title='카드사 채권'
            selectProc='SLT_BASIC_CARDCREDIT_ENROLL'
            insertProc='INS_BASIC_CARDCREDIT_ENROLL'
            updateProc='UPT_BASIC_CARDCREDIT_ENROLL'
            deleteProc='DLT_BASIC_CARDCREDIT_ENROLL'
            selectParam={condition}
            keys={['ACJ_CD', 'ACJ_DATE']}
            horizonMode
        >
             <DataColumn fieldName='ACJ_CD' title='코드' />

            <DataColumn
                fieldName='ACJ_DATE'
                title='일자'
                editable={(state, dispatch) => (
                    <DatePicker
                        style={{width: 150}}
                        value={state}
                        onChange={value => {
                            dispatch(value);
                        }}
                    />
                )}
                
            />

            <DataColumn
                fieldName='WORK_CD'
                title='사업장'
                value={row => row['WORK_NM']}
                editable={(state, dispatch) => (
                    <ComboBox
                        selectProc='SLT_WORKPLACEINFO_AUTH'
                        style={{width: 150}}
                        keyField='WORK_CD'
                        valueField='WORK_NM'
                        value={condition['WORK_CD']}
                        onChange={value => {
                            cstPram.current={ ...cstPram.current, WORK_CD: value }
                            dispatch(value);
                        }}
                        disabled
                    />
                )}
            />

            <DataColumn
                fieldName='CARD_CD'
                title='카드사'
                value={row => row['CARD_NM']}
                editable={(state, dispatch, rowState, setField) => {
                    let param = rowState['CARD_PARAM'] || null;
                    if (isFieldDiff(['WORK_CD'], rowState, param)) {
                        param = { WORK_CD: rowState['WORK_CD'], PAY_CD: 'P0002'};
                        setField('CARD_PARAM', param);
                    }
                    return (
                        <ComboSearch
                            style={{width: 200}}
                            selectProc='SLT_CARDINFO_COMBO'
                            selectParam={param}
                            keyField='CARD_CD'
                            valueField='CARD_NM'
                            value={state}
                            onChange={value => dispatch(value)}
                            nullable
                        />
                    )
                }}
            />

            <DataColumn 
                fieldName='DEBTOR' 
                title='금액' 
                total={(values) => values.reduce((result, value) => result + value, 0)} 
                editable={(state, dispatch) => (
                    <NumberField
                        style={{width: 150}}
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />
            <DataColumn 
                fieldName='SUMUP' 
                title='비고'
                editable={(state, dispatch) => (
                    <TextField
                        style={{width: 200}}
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )} />

            <DataColumn
                fieldName='WORK_NM'
                visible={false}
            />
            <DataColumn
                fieldName='CARD_NM'
                visible={false}
            />


        </DataGrid>
    )
}

const BasicEtcCredit = ({condition}) => {
    const cstPram = useRef();

    return(
        <DataGrid
            title='모바일/포인트/할인'
            selectProc='SLT_BASIC_ETCCREDIT_ENROLL'
            insertProc='INS_BASIC_ETCCREDIT_ENROLL'
            updateProc='UPT_BASIC_ETCCREDIT_ENROLL'
            deleteProc='DLT_BASIC_ETCCREDIT_ENROLL'
            selectParam={condition}
            keys={['ACJ_CD', 'ACJ_DATE']}
            horizonMode
        >
            <DataColumn fieldName='ACJ_CD' title='코드' />

            <DataColumn
                fieldName='ACJ_DATE'
                title='일자'
                editable={(state, dispatch) => (
                    <DatePicker
                        style={{width: 150}}
                        value={state}
                        onChange={value => {
                            dispatch(value);
                        }}
                    />
                )}
                
            />

            <DataColumn
                fieldName='WORK_CD'
                title='사업장'
                value={row => row['WORK_NM']}
                editable={(state, dispatch) => (
                    <ComboBox
                        selectProc='SLT_WORKPLACEINFO_AUTH'
                        style={{width: 150}}
                        keyField='WORK_CD'
                        valueField='WORK_NM'
                        value={condition['WORK_CD']}
                        onChange={value => {
                            cstPram.current={ ...cstPram.current, WORK_CD: value }
                            dispatch(value);
                        }}
                        disabled
                    />
                )}
            />

            <DataColumn
                fieldName='CARD_CD'
                title='결제수단'
                value={row => row['CARD_NM']}
                editable={(state, dispatch) => (
                    <ComboSearch
                        selectProc='SLT_CARDINFO_ETC'
                        style={{width: 200}}
                        keyField='CARD_CD'
                        valueField='CARD_NM'
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />

            <DataColumn 
                fieldName='DEBTOR' 
                title='금액' 
                total={(values) => values.reduce((result, value) => result + value, 0)} 
                editable={(state, dispatch) => (
                    <NumberField
                        style={{width: 150}}
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />
            <DataColumn 
                fieldName='SUMUP' 
                title='비고' 
                editable={(state, dispatch) => (
                    <TextField
                        style={{width: 200}}
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )} />

            <DataColumn
                fieldName='CARD_NM'
                visible={false}
            />

            <DataColumn
                fieldName='WORK_NM'
                visible={false}
            />


        </DataGrid>
    )
}

const BasicGoodsStock = ({condition}) => {
    return (
        <DataGrid
            title='계기상품 기초재고'
            selectProc='SLT_BASIC_GOODS_STOCK'
            insertProc='INS_BASIC_GOODS_STOCK'
            updateProc='UPT_BASIC_GOODS_STOCK'
            deleteProc='DLT_BASIC_GOODS_STOCK'
            selectParam={condition}
            keys={['CALC_CD', 'BIZ_DATE']}
        >
            <DataColumn
                fieldName='BIZ_DATE'
                title='일자'
                editable={(state, dispatch) => (
                    <DatePicker
                        value={state}
                        onChange={value => {
                            dispatch(value);
                        }}
                    />
                )}

            />
            <DataColumn
                fieldName='WORK_CD'
                title='사업장'
                value={row => row['WORK_NM']}
                defaultValue={condition && condition['WORK_CD']}
                editable={(state, dispatch) => (
                    <ComboBox
                        selectProc='SLT_WORKPLACEINFO_AUTH'
                        keyField='WORK_CD'
                        valueField='WORK_NM'
                        value={state}
                        onChange={value => {
                            dispatch(value);
                        }}
                        disabled
                    />
                )}
            />
            <DataColumn
                fieldName='REAL_DEST_CD'
                title='기초재고 사업장'
                value={row => row['TSP_NM']}
                editable={(state, dispatch, rowState, setField) => {
                    let param = rowState['TSP_PARAM'] || null;
                    if (isFieldDiff(['WORK_CD'], rowState, param)) {
                        param = { WORK_CD: rowState['WORK_CD'], TSP_TYPE: 1 };
                        setField('TSP_PARAM', param);
                    }
                    return (
                        <ComboSearch
                            selectProc='SLT_TRANSPLACE'
                            selectParam={param}
                            keyField='TSP_CD'
                            valueField='TSP_NM'
                            value={state}
                            onChange={value => {
                                dispatch(value);
                            }}
                        />
                    )
                }}
            />

            <DataColumn
                fieldName='GDS_CD'
                title='상품'
                value={row => row['GDS_NM']}
                editable={(state, dispatch, rowState, setField) => {
                    let param = rowState['GDS_PARAM'] || null;
                    if (isFieldDiff(['WORK_CD'], rowState, param)) {
                        param = { WORK_CD: rowState['WORK_CD']};
                        setField('GDS_PARAM', param);
                    }
                    return (
                        <ComboBox
                            selectProc='SLT_GOODSINFO_USE'
                            selectParam={param}
                            keyField='GDS_CD'
                            valueField='GDS_NM'
                            value={state}
                            onChange={value => {
                                dispatch(value);
                            }}
                        />
                    )
                }}
            />
            <DataColumn
                fieldName='WORK_NM'
                visible={false}
            />
            <DataColumn
                fieldName='TSP_NM'
                visible={false}
            />

            <DataColumn
                fieldName='GDS_NM'
                visible={false}
            />

        </DataGrid>
    )
}

const BasicItemStock = ({condition}) => {
    const gdsParam = useRef();

    return (
        <DataGrid
            title='일반상품 기초재고'
            selectProc='SLT_BASIC_ITEM_STOCK'
            insertProc='INS_BASIC_ITEM_STOCK'
            updateProc='UPT_BASIC_ITEM_STOCK'
            deleteProc='DLT_BASIC_ITEM_STOCK'
            selectParam={condition}
            keys={['IIN_CD', 'BIZ_DATE']}
            horizonMode
        >
            <DataColumn
                fieldName='BIZ_DATE'
                title='일자'
                editable={(state, dispatch) => (
                    <DateTimePicker
                        value={state}
                        onChange={value => {
                            dispatch(value);
                        }}
                    />
                )}
            />

            <DataColumn
                fieldName='WORK_CD'
                title='사업장'
                value={row => row['WORK_NM']}
                editable={(state, dispatch) => (
                    <ComboBox
                        selectProc='SLT_WORKPLACEINFO_AUTH'
                        keyField='WORK_CD'
                        valueField='WORK_NM'
                        value={condition['WORK_CD']}
                        onChange={value => {
                            gdsParam.current={WORK_CD: value}
                            dispatch(value);
                        }}
                        disabled
                    />
                )}
            />

            <DataColumn
                fieldName='GDS_CD'
                title='상품'
                value={row => row['GDS_NM']}
                editable={(state, dispatch) => (
                    <ComboSearch
                        selectProc='SLT_ITEM_USE'
                        selectParam={gdsParam.current}
                        style={{width: 200}}
                        keyField='GDS_CD'
                        valueField='GDS_NM'
                        value={state}
                        onChange={value => {
                            dispatch(value);
                        }}
                    />
                )}
            />

            <DataColumn
                fieldName='QTY'
                title='수량'
                editable={(state, dispatch) => (
                    <TextField
                        style={{width: 100}}
                        value={state}
                        onChange={value => dispatch(value)}
                    />
                )}
            />
            <DataColumn
                fieldName='WORK_NM'
                visible={false}
            />

            <DataColumn
                fieldName='GDS_NM'
                visible={false}
            />

        </DataGrid>
    )
}

const BasicSelfWashStock = ({condition}) => {

    return (
        <DataGrid
            title='희석액/회원카드'
            selectProc='SLT_BASIC_SELFWASH_STOCK'
            insertProc='INS_BASIC_SELFWASH_STOCK'
            updateProc='UPT_BASIC_SELFWASH_STOCK'
            deleteProc='DLT_BASIC_SELFWASH_STOCK'
            selectParam={condition}
            keys={['SELF_IN_CD', 'BIZ_DATE']}
        >
            <DataColumn
                fieldName='BIZ_DATE'
                title='일자'
                editable={(state, dispatch) => (
                    <DatePicker
                        value={state}
                        onChange={value => {
                            dispatch(value);
                        }}
                    />
                )}
            />

            <DataColumn
                fieldName='WORK_CD'
                title='사업장'
                value={row => row['WORK_NM']}
                defaultValue={condition && condition['WORK_CD']}
                editable={(state, dispatch) => (
                    <ComboBox
                        selectProc='SLT_WORKPLACEINFO_AUTH'
                        keyField='WORK_CD'
                        valueField='WORK_NM'
                        value={state}
                        onChange={value => {
                            dispatch(value);
                        }}
                        disabled
                    />
                )}
            />
            <DataColumn
                fieldName='GDS_CD'
                title='상품'
                value={row => row['GDS_NM']}
                editable={(state, dispatch, rowState, setField) => {
                    let param = rowState['GDS_PARAM'] || null;
                    if (isFieldDiff(['WORK_CD'], rowState, param)) {
                        param = { WORK_CD: rowState['WORK_CD']};
                        setField('GDS_PARAM', param);
                    }
                    return (
                        <ComboSearch
                            selectProc='SLT_ITEM_USE'
                            selectParam={param}
                            keyField='GDS_CD'
                            valueField='GDS_NM'
                            value={state}
                            onChange={value => {
                                dispatch(value);
                            }}
                        />
                    )
                }}
            />

            <DataColumn
                fieldName='WORK_NM'
                visible={false}
            />

            <DataColumn
                fieldName='GDS_NM'
                visible={false}
            />

        </DataGrid>
    )
}

export default BasicDataEnroll