import React, { useState, useEffect, useRef, Fragment, forwardRef, useImperativeHandle, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { ComboBox, ProgressPanel, DatePicker, NumberField, ComboSearch, PaperTitle, IconButton } from '../../component';
import { Paper, makeStyles, TextField, List, ListItem, ListItemIcon } from '@material-ui/core';
import { callProc } from '../../common/DBConnector';
import { useSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/Add';
import { useFieldInputs } from '../../common/Utils';
import RemoveIcon from '@material-ui/icons/Remove';
import CheckIcon from '@material-ui/icons/Check';
import { useSelector } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';

const stateType = {
    0: '차변',
    1: '대변',
}

const inOutType = {
    '10': '입금',
    '11': '출금',
    '12': '혼합',
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flex: 1,
        height: '100%',
        flexDirection: 'column'
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
        flex: 2,
        height: 0,
        marginTop: theme.spacing(1),
    },
    enroll: {
        display: 'flex',
        flexDirection: 'column',
        flex: 3,
        marginBottom: theme.spacing(1),
        height: 0,
    },
    paperTitle: {
        display: 'flex',
        justifyContent: 'end',
        padding: theme.spacing(1),
    },
    field: {
        width: 180,
        marginRight: theme.spacing(1),
    },
    mixList: {
        overflowY: 'auto',
    },
    searchPaper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    searchContainer: {
        flex: 1,
        display: 'inline-block',
        flexDirection: 'row',
    },
    searchItem: {
        display: 'inline-block',
        width: 200,
        marginRight: 100,
        marginBottom: theme.spacing(1),
    },
}))

const InOutAccountingEnroll = () => {
    const [, updateState] = useState();
    const upData = useRef([]);
    const sumPanel = useRef();
    const [selection, setSelection] = useState();

    const { enqueueSnackbar } = useSnackbar();

    const clsState = useSelector(state => state.clsDateReducer);

    const [input, setInput] = useFieldInputs({
        WORK_CD: sessionStorage['WORK_CD'],
        ACJ_DATE: clsState[sessionStorage['WORK_CD']],
    });

    const condition = useMemo(() => input['WORK_CD'] && input['ACJ_DATE'] && input, [input]);

    const gridRefresh = () => {
        setInput({ type: 'UPDATE', value: { ...input } });
    }

    const classes = useStyles();

    useEffect(() => {
        const fetchData = async () => {
            upData.current = [];
            forceUpdate();
            
            const mstData = (await callProc('SLT_INOUTMONEY', condition)).data;
            const hisData = (await callProc('SLT_INOUTMONEY_DETAIL', condition)).data;
            const group = mstData.reduce((result, item) => ({
                ...result,
                [item['ACJ_CD']]: {
                    ...item,
                    ACC_LIST: []
                }
            }), {});

            const result = hisData.reduce((result, item) => ({
                ...result,
                [item['ACJ_CD']]: {
                    ...result[item['ACJ_CD']],
                    ACC_LIST: [...result[item['ACJ_CD']]['ACC_LIST'], item]
                }
            }), group);

            upData.current = Object.values(result);
            forceUpdate();
        }
        condition && fetchData();
    }, [condition])

    const forceUpdate = () => updateState({});

    const addData = (listItem) => {
        upData.current = [...upData.current, listItem];
        forceUpdate();
    }

    const addMixData = (listItem) => {
        upData.current[selection]['ACC_LIST'] = [...upData.current[selection]['ACC_LIST'], listItem];
        forceUpdate();
    }

    const deleteData = (idx) => {
        upData.current.splice(idx, 1);
        forceUpdate();
    }

    const deleteMixData = (idx) => {
        upData.current[selection]['ACC_LIST'].splice(idx, 1);
        forceUpdate();
    }

    const addNewData = () => {
        upData.current = [{
            ACJ_CD: 1,
            ACC_LIST: [],
            ISSUE_LOC: 10,
            ACC_CD: null,
            DEBTOR: 0,
            CREDITOR: 0,
            CST_CD: null,
            PAY_CD: null,
            CARD_CD: null,
            SUMUP: ''
        }];

        forceUpdate();
    }

    const addNewMixData = () => {
        upData.current[selection]['ACC_LIST'] = [{
            ACJ_CD: upData.current[selection]['ACJ_CD'],
            STATE_TYPE: 0,
            ACC_CD: null,
            CST_CD: null,
            DEBTOR: 0,
            CREDITOR: 0,
            SUMUP: ''
        }];

        forceUpdate();
    }

    const valid = () => {
        if(upData.current.reduce((result, item) => {
            if(item['ISSUE_LOC'].toString() !== '12'){
                return result || item['ACC_CD'] == null;
            }
            return result;
        }, false)){
            enqueueSnackbar('빈 계정과목이 있습니다. 확인해주세요.', { variant: 'error' });
            return false;
        }

        const amtValid = upData.current.reduce((result, item) => {
            if (item['ISSUE_LOC'].toString() === '12' && result) {
                const debtor = item['ACC_LIST'].reduce((result, item) => result + item['DEBTOR'], 0);
                const creditor = item['ACC_LIST'].reduce((result, item) => result + item['CREDITOR'], 0);
                return debtor === creditor;
            }
            return result;
        }, true);

        if (!amtValid) {
            enqueueSnackbar('혼합 구분의 차변 / 대변 합이 다릅니다. 확인해주세요.', { variant: 'error' });
            return false;
        }

        return true;
    }

    const onInsert = async () => {
        if (valid()) {
            const result = await callProc('INS_ACCOUNTJOURNAL_INOUT', {
                ...condition,
                JSON: upData.current,
            });

            if (!result.err) {
                enqueueSnackbar('저장이 완료되었습니다.');
                upData.current = [];
                gridRefresh();
            }
        }
    }


    return (
        <div className={classes.container}>
            <Paper className={classes.searchPaper}>
                <div className={classes.searchContainer}>
                    <div className={classes.searchItem}>
                        <ComboBox
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={condition && condition['WORK_CD']}
                            onChange={value => {
                                if (input['WORK_CD'] !== value) {
                                    sessionStorage['WORK_CD'] = value;
                                    setInput('ACJ_DATE', clsState[value]);
                                    setInput('WORK_CD', value);
                                }
                            }}
                        />
                    </div>
                    <div className={classes.searchItem}>
                        <DatePicker
                            label='영업일'
                            minDate={clsState[input['WORK_CD']]}
                            value={condition && condition['ACJ_DATE']}
                            onChange={value => {
                                if (input['ACJ_DATE'] !== value) {
                                    setInput('ACJ_DATE', value);
                                    if(condition && condition['ACJ_DATE'] < clsState[condition['WORK_CD']]) {
                                        setInput({ type: 'UPDATE', value: { ...input, 'ACJ_DATE': value } });
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                <IconButton
                    tooltip=''
                    icon={<SearchIcon />}
                    onClick={gridRefresh}
                />
            </Paper>
            <div className={classes.content}>
                <Paper className={classes.enroll} >
                    <div className={classes.paperTitle}>
                        <PaperTitle>
                            전표 등록
                            </PaperTitle>
                        <IconButton
                            tooltip='추가'
                            icon={<AddIcon />}
                            disabled={upData.current != null && upData.current.length > 0}
                            onClick={() => addNewData()}
                        />

                        <IconButton
                            style={{ marginLeft: 32 }}
                            tooltip='확인'
                            icon={<CheckIcon />}
                            // disabled={upData.current != null && upData.current.length === 0}
                            onClick={() => onInsert()}
                        />

                    </div>
                    <List style={{ overflowY: 'auto' }}>
                        {!upData.current ? <ProgressPanel /> :
                            upData.current.map((item, idx, arr) => (
                                <ListItemEnroll
                                    key={item['ACJ_CD']}
                                    data={item}
                                    arr={arr}
                                    condition={condition}
                                    addData={listItem => addData(listItem)}
                                    deleteData={() => {
                                        deleteData(idx);
                                    }}
                                    onDataChange={(field, value) => {
                                        if (upData.current[idx] == null) {
                                            return;
                                        }
                                        upData.current[idx][field] = value;
                                        if (field === 'ISSUE_LOC') {
                                            forceUpdate();
                                        }
                                    }}
                                    onClick={() => {
                                        setSelection(idx);
                                    }}
                                    selection={selection === idx}
                                />
                            ))}
                    </List>
                </Paper>
                <Paper className={classes.info} disabled>
                    <div className={classes.paperTitle}>
                        <PaperTitle>
                            혼합 등록
                            </PaperTitle>
                        <SumPanel
                            ref={sumPanel}
                            data={upData.current[selection] && upData.current[selection]['ACC_LIST'] &&
                                (upData.current[selection]['ISSUE_LOC'].toString() !== '12' ? null : upData.current[selection]['ACC_LIST'])}
                        />
                        <IconButton
                            tooltip='추가'
                            icon={<AddIcon />}
                            disabled={!upData.current[selection] ||
                                upData.current[selection]['ACC_LIST'].length !== 0 ||
                                upData.current[selection]['ISSUE_LOC'].toString() !== '12'
                            }
                            onClick={() => addNewMixData()}
                        />
                    </div>
                    <List className={classes.mixList}>
                        {upData.current[selection] &&
                            upData.current[selection]['ISSUE_LOC'].toString() === '12' &&
                            upData.current[selection]['ACC_LIST'].map((item, idx, arr) => (
                                <MixList
                                    key={selection.toString() + idx}
                                    data={item}
                                    condition={condition}
                                    addData={listItem => addMixData(listItem)}
                                    deleteData={() => {
                                        deleteMixData(idx);
                                    }}
                                    onDataChange={(field, value) => {
                                        if (upData.current[selection] == null)
                                            return;

                                        upData.current[selection]['ACC_LIST'][idx][field] = value;
                                        sumPanel.current.forceUpdate();
                                    }}
                                />
                            ))}
                    </List>
                </Paper>
            </div>
        </div>
    )
}


const listItemStyle = makeStyles((theme) => ({
    field: {
        width: 180,
        marginRight: theme.spacing(1),
    },
    selected: {
        backgroundColor: 'lightGray !important',
    }
}))

const ListItemEnroll = ({ data, arr, condition, addData, deleteData, onDataChange, onClick, selection }) => {
    const [insData, setInsData] = useFieldInputs(data);

    const classes = listItemStyle();

    const fieldUpdate = useCallback((fieldName, value) => {
        setInsData(fieldName, value);
        onDataChange(fieldName, value);
    }, [onDataChange, setInsData]);


    return (
        <ListItem
            className={clsx({ [classes.selected]: selection })}
            onClick={onClick}
            button
        >
            <ListItemIcon>
                <IconButton
                    tooltip='추가'
                    icon={<AddIcon />}
                    onClick={() =>
                        addData({
                            ...insData,
                            ACJ_CD: !arr.length ? 1 : arr[arr.length - 1]['ACJ_CD'] + 1,
                            DEBTOR: Number(insData['DEBTOR']),
                            CREDITOR: Number(insData['CREDITOR']),
                            ACC_LIST: insData['ACC_LIST'].map(item => ({ ...item, ACJ_CD: !arr.length ? 1 : arr[arr.length - 1]['ACJ_CD'] + 1 })),
                            DOC_CD: null,
                            PRO_CD: null,
                        })
                    }
                />
                <IconButton
                    tooltip='삭제'
                    icon={<RemoveIcon />}
                    onClick={() => deleteData()}
                />
            </ListItemIcon>
            <ComboBox
                className={classes.field}
                style={{ width: 100 }}
                label='구분'
                data={inOutType}
                value={insData['ISSUE_LOC']}
                onChange={value => {
                    if (value.toString() === '12') {
                        fieldUpdate('ACC_CD', null);
                        fieldUpdate('DEBTOR', 0);
                        fieldUpdate('CREDITOR', 0);
                        fieldUpdate('CST_CD', null);
                        fieldUpdate('CARD_CD', null);
                        fieldUpdate('SUMUP', '');
                    }else if(value.toString() === '11'){
                        fieldUpdate('CREDITOR', 0);
                    }else{
                        fieldUpdate('DEBTOR', 0);
                    }
                    fieldUpdate('ISSUE_LOC', value);
                }}
            />
            <ComboSearch
                className={classes.field}
                label='계정과목'
                selectProc='SLT_ACCOUNT_ALL'
                keyField='ACC_CD'
                valueField='ACC_NM'
                value={insData['ACC_CD']}
                onChange={value => {
                    fieldUpdate('ACC_CD', value);
                }}
                disabled={insData['ISSUE_LOC'].toString() === '12'}
                nullable
            />
            <NumberField
                className={classes.field}
                style={{ width: 120 }}
                label='차변'
                value={insData['DEBTOR']}
                onChange={value => {
                    fieldUpdate('DEBTOR', Number(value));
                }}
                disabled={insData['ISSUE_LOC'].toString() === '10' || insData['ISSUE_LOC'].toString() === '12'}
            />
            <NumberField
                className={classes.field}
                style={{ width: 120 }}
                label='대변'
                value={insData['CREDITOR']}
                onChange={value => {
                    fieldUpdate('CREDITOR', Number(value));
                }}
                disabled={insData['ISSUE_LOC'].toString() === '11' || insData['ISSUE_LOC'].toString() === '12'}
            />
            <ComboSearch
                className={classes.field}
                style={{ width: 250 }}
                label='거래처명'
                selectProc='SLT_CUSTOMERINFO_ALL'
                selectParam={condition}
                keyField='CST_CD'
                valueField='CST_NM'
                value={insData['CST_CD']}
                onChange={value => {
                    fieldUpdate('CST_CD', value);
                }}
                nullable
                disabled={insData['ISSUE_LOC'].toString() === '12'}
            />
            <ComboSearch
                className={classes.field}
                label='결제수단'
                selectProc={insData['ISSUE_LOC'].toString() === '10' ? 'SLT_CARDINFO_ALL' : 'SLT_CARDINFO_ALL_OUT'}
                selectParam={condition}
                keyField='CARD_CD'
                valueField='CARD_NM'
                value={insData['CARD_CD']}
                onChange={value => {
                    fieldUpdate('CARD_CD', value);
                }}
                nullable
                disabled={insData['ISSUE_LOC'].toString() === '12'}
            />
            <TextField
                className={classes.field}
                label='적요'
                value={insData['SUMUP'] || ''}
                onChange={e => {
                    fieldUpdate('SUMUP', e.target.value);
                }}
                disabled={insData['ISSUE_LOC'].toString() === '12'}
            />
        </ListItem>
    )
}


const MixList = ({ data, arr, condition, addData, deleteData, onDataChange }) => {
    const [insData, setInsData] = useFieldInputs(data);

    const classes = listItemStyle();

    const fieldUpdate = useCallback((fieldName, value) => {
        setInsData(fieldName, value);
        onDataChange(fieldName, value);
    }, [onDataChange, setInsData]);

    return (
        <ListItem>
            <ListItemIcon>
                <IconButton
                    tooltip='추가'
                    icon={<AddIcon />}
                    onClick={() =>
                        addData({
                            ...insData,
                            DEBTOR: Number(insData['DEBTOR']),
                            CREDITOR: Number(insData['CREDITOR']),
                        })
                    }
                />
                <IconButton
                    tooltip='삭제'
                    icon={<RemoveIcon />}
                    onClick={() => deleteData()}
                />
            </ListItemIcon>
            <ComboBox
                className={classes.field}
                style={{ width: 100 }}
                label='구분'
                data={stateType}
                value={insData['STATE_TYPE']}
                onChange={value => {
                    if(value.toString() === '0'){
                        fieldUpdate('CREDITOR', 0);
                    }else{
                        fieldUpdate('DEBTOR', 0);
                    }
                    fieldUpdate('STATE_TYPE', value);
                }}
            />
            <ComboSearch
                className={classes.field}
                label='계정과목'
                selectProc='SLT_ACCOUNT_ALL'
                keyField='ACC_CD'
                valueField='ACC_NM'
                value={insData['ACC_CD']}
                onChange={value => {
                    fieldUpdate('ACC_CD', value);
                }}
                nullable
            />
            <ComboSearch
                className={classes.field}
                style={{ width: 250 }}
                label='거래처명'
                selectProc='SLT_CUSTOMERINFO_ALL'
                selectParam={condition}
                keyField='CST_CD'
                valueField='CST_NM'
                value={insData['CST_CD']}
                onChange={value => {
                    fieldUpdate('CST_CD', value);
                }}
                nullable
            />
            <NumberField
                className={classes.field}
                style={{ width: 120 }}
                label='차변'
                value={insData['DEBTOR']}
                onChange={value => {
                    fieldUpdate('DEBTOR', Number(value));
                }}
                disabled={insData['STATE_TYPE'].toString() === '1'}
            />
            <NumberField
                className={classes.field}
                style={{ width: 120 }}
                label='대변'
                value={insData['CREDITOR']}
                onChange={value => {
                    fieldUpdate('CREDITOR', Number(value));
                }}
                disabled={insData['STATE_TYPE'].toString() === '0'}
            />
            <TextField
                className={classes.field}
                label='적요'
                value={insData['SUMUP'] || ''}
                onChange={e => {
                    fieldUpdate('SUMUP', e.target.value);
                }}
            />
        </ListItem>
    )
}


const SumPanel = forwardRef(({ data }, ref) => {
    const [, updateState] = useState();
    const forceUpdate = () => updateState({});
    useImperativeHandle(ref, () => ({
        forceUpdate() { forceUpdate() }
    }));

    return (
        <Fragment>
            <NumberField
                style={{ marginLeft: 16 }}
                label='차변 합'
                value={data != null ? data.reduce((result, value) => result + Number(value['DEBTOR']), 0) : 0}
                disabled
            />
            <NumberField
                style={{ marginLeft: 16 }}
                label='대변 합'
                value={data != null ? data.reduce((result, value) => result + Number(value['CREDITOR']), 0) : 0}
                disabled
            />
        </Fragment>
    )
})

export default InOutAccountingEnroll;