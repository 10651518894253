import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';

export default ({ tooltip, icon, disableTooltip, ...btnProps }) => {
    return (
        disableTooltip ?
        <IconButton component='div' {...btnProps}>
            {icon}
        </IconButton> :
        <Tooltip title={tooltip}>
            <IconButton component='div' {...btnProps}>
                {icon}
            </IconButton>
        </Tooltip>
    )
}