import React, { useState, cloneElement, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles, createMuiTheme, ThemeProvider, withStyles, createStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { IconButton } from '../component';
import { useHistory } from 'react-router-dom';
import { Backdrop } from '@material-ui/core';

const mobilePath = '/m';

const drawerTheme = createMuiTheme({
    palette: {
        background: {
            paper: '#253053',
        },
        text: {
            primary: '#fefeff',
        },
        action: {
            active: '#fefeff',
        },
        divider: '#475275',
    }
})

const styles = (theme) => createStyles({
    root: {
        display: 'flex',
    },
    hide: {
        display: 'none !important',
    },
    backdrop: {
        zIndex: 10,
    },
    appBar: {
        zIndex: 0,
        backgroundColor: theme.palette.secondary.main,
    },
    menuButton: {
        marginRight: theme.spacing(1),
    },
    verDivider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(3),
    },
});

export default withStyles(styles)(({ classes, children, toolbar, width }) => {
    const [open, setOpen] = useState(false);
    const history = useHistory();

    const onMenuClick = (menuKey, text, to) => {
        history.push(to.toLowerCase());
        setOpen(false);
    }

    const goHome = () => history.push(mobilePath);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Backdrop
                className={classes.backdrop}
                open={open}
                onClick={() => setOpen(false)}
            />
            <MenuDrawer
                open={open}
                onClose={() => setOpen(false)}
                width={width}
                toolbar={toolbar}
                goHome={goHome}
                onMenuClick={onMenuClick}
            >
                {children}
            </MenuDrawer>
            <AppBar position='relative' className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        icon={<MenuIcon />}
                        onClick={() => setOpen(true)}
                        className={classes.menuButton}
                        edge="start"
                        disableTooltip
                    />
                    <Divider orientation='vertical' classes={{ root: classes.verDivider }} flexItem />
                    {toolbar}
                </Toolbar>
            </AppBar>
        </div>
    );
})

const useFrameStyles = makeStyles((theme) => ({
    collapseButton: {
        marginRight: theme.spacing(1),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        position: 'absolute',
        height: '100vh',
        left: props => -props.width,
        top: 0,
        width: props => props.width,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        left: () => 0,
        overflowX: 'hidden',
        transition: theme.transitions.create('left', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('left', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
    },
    drawerContent: {
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    collapseContent: {
        overflow: 'hidden',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    logoContainer: {
        position: 'absolute',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: theme.spacing(2),
    },
}));

const MenuDrawer = (props) => {
    const { children, goHome, onMenuClick, open, onClose } = props;
    const closing = useRef(false);

    const classes = useFrameStyles(props);

    const initList = (list, root) => list.map((item) => {
        const { children, to, menuKey } = item.props;
        const path = root + '/' + (to ? to.name : menuKey);
        return cloneElement(item, {
            key: menuKey,
            handler: onMenuClick,
            children: children && initList(React.Children.toArray(children), path),
            to: to && path,
            opened: true,
        });
    })

    const expandItems = initList(React.Children.toArray(children), mobilePath);

    const handleCloseClick = () => {
        closing.current = true;
        onClose();
        setTimeout(() => closing.current = false, 200);
    }

    return (
        <ThemeProvider theme={drawerTheme}>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.toolbar}>
                    <div className={classes.logoContainer}>
                        <LogoImage onClick={goHome}/>
                        <h2>　ERP System</h2>
                    </div>
                    <IconButton
                        icon={<ChevronLeftIcon />}
                        className={classes.collapseButton}
                        onClick={handleCloseClick}
                        disableTooltip
                    />
                </div>
                <Divider />
                <div className={clsx(classes.drawerContent, {
                    [classes.collapseContent]: !open,
                })}>
                    <List>
                        {expandItems}
                    </List>
                </div>
            </Drawer>
        </ThemeProvider>
    )
}

const useLogoStyles = makeStyles(theme => ({
    container: {
        backgroundColor: '#FFF',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        width: 45,
        height: 45,
        cursor: 'pointer',
    },
    img: {
        width: 45,
        height: 45,
    }
}))

const LogoImage = ({onClick}) => {
    const classes = useLogoStyles();

    return (
        <div className={classes.container} onClick={onClick}>
            <img src="/logo192.png" className={classes.img} alt='logo' />
        </div>
    )
}