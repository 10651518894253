import React from 'react';
import { List, Divider, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    divider: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
}));

const MenuDivider = () => {
    const classes = useStyles();
    return (
        <List>
            <Divider classes={{root: classes.divider}} />
        </List>
    )
}

export default MenuDivider;