import React, { useState } from 'react';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DataColumn, ComboSearch, Tab, Tabs, DatePicker } from '../../component';
import { makeStyles } from '@material-ui/core';
import { isFieldDiff, totalCount } from '../../common/Utils';
import { Fragment } from 'react';
import clsx from 'clsx';
import moment from 'moment';

const useStyle = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    tabContainer: {
        display: 'flex',
    },
    tabbar: {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    tab: {
        minWidth: 100,
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
    hide: {
        display: 'none'
    },
}))

const TransPriceList = () => {
    const classes = useStyle();
    const [condition, setCondition] = useState();
    const [selectTab, setSelectTab] = useState(0);

    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => {
                sessionStorage['WORK_CD'] = condition['WORK_CD'];
                setCondition(condition)}}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            // onDataLoad={}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='TSP_CAR_CD'
                    component={(state, setState, condition, setField) => {
                        let param = condition['TSP_CAR_PARAM'] || null;
                        if (isFieldDiff(['WORK_CD'], condition, param)) {
                            param = { WORK_CD: condition['WORK_CD'] };
                            setField('TSP_CAR_PARAM', param);
                        }
                        return (
                            <ComboSearch
                                label='운송수단'
                                selectProc='SLT_TRANSCARINFO'
                                selectParam={param}
                                keyField='TSP_CAR_CD'
                                valueField='CAR_NO'
                                onDataLoad={(jsonData, rawData) => {
                                    const result = [{ TSP_CAR_CD: 'ALL', CAR_NO: '전체' }, ...rawData];
                                    return result;
                                }}
                                value={state}
                                onChange={value => setState(value)}
                            />
                        )
                    }}
                />
                 <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
            </SearchPanel>
            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab className={classes.tab} label='차량별' />
                    <Tab className={classes.tab} label='기간별' />
                </Tabs>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <TransPriceCar condition={condition} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <TransPriceDay condition={condition} />
            </div>
        </div>

    )
}


const TransPriceCar = ({ condition }) => {


    return (
        <Fragment>
            <DataGrid
                title='수송비 차량별 조회'
                selectProc='SLT_TRANSPRICELISTCAR'
                selectParam={condition}
                horizonMode
                totalGroup={['CAR_NO']}
                virtualized
                excelDownload={{ fileName: '수송비 차량별 조회.xlsx', sheetName: '차량별' }}
                stickyHeader
                sortable
                filterable
            >
                
                <DataColumn fieldName='CAR_NO' title='운송수단' />
                <DataColumn fieldName='SHIP_TRN_NM' title='출발지' />
                <DataColumn fieldName='REAL_DEST_NM' title='도착지' />
                <DataColumn fieldName='TRN_PRICE' title='수송단가' />

                <DataColumn 
                    fieldName='CAR_NO' 
                    title='차량번호' 
                    total={(values, data, group) => 
                        group == null ? `- 합 계 ${totalCount(values)} 건 -` : `(소 계 ${totalCount(values)} 건) ${data[0]['CAR_NO']}`} 
                />
                <DataColumn fieldName='QTY' title='정산 수량' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='TRN_AMT' title='수송비' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='REWD_AMT' title='추가보상' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='TOTAL_AMT' title='수송비합계' total={(values) => values.reduce((result, value) => result + value, 0)} />
            </DataGrid>
        </Fragment>
    )

}


const TransPriceDay = ({ condition }) => {


    return (
        <Fragment>
            <DataGrid
                title='수송비 기간별 조회'
                selectProc='SLT_TRANSPRICELISTDAY'
                selectParam={condition}
                horizonMode
                virtualized
                excelDownload={{ fileName: '수송비 기간별 조회.xlsx', sheetName: '기간별' }}
                stickyHeader
                sortable
                filterable
            >
                <DataColumn fieldName='CAR_NO' title='수송차량' total={(values) => '합계'} />
                <DataColumn fieldName='QTY' title='정산 수량' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='TRN_PRICE' title='수송비' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='REWD_AMT' title='추가보상' total={(values) => values.reduce((result, value) => result + value, 0)} />
                <DataColumn fieldName='TOT_AMT' title='수송비합계' total={(values) => values.reduce((result, value) => result + value, 0)} />
            </DataGrid>
        </Fragment>
    )

}

export default TransPriceList;
