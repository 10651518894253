import React, { useState, useRef } from 'react';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DataColumn, ComboSearch, Tabs, Tab, IconButton, TextField} from '../../component';
import { makeStyles, Button } from '@material-ui/core';
import clsx from 'clsx';
import { isFieldDiff } from '../../common/Utils';
import { useSnackbar } from 'notistack';
import { callProc } from '../../common/DBConnector';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },    
    tabContainer : {
        display : 'flex',
    },
    tabbar : {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    content : {
        display: 'flex',
        flex: 1,
        height : 0,
    },
    hide : {
        display: 'none'
    },
}))

const CstCarInfo = () => {
    const [condition, setCondition] = useState(null);
    const [selectTab, setSelectTab] = useState(0);
    const classes = useStyles();
    const gridRefresh = () => {
        setCondition({...condition});
    }

    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => setCondition(condition)}>
                <SearchItem
                    fieldName='WORK_CD'
                    component={(state, dispatch) => (
                        <ComboBox
                            label='사업장명'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={state}
                            onChange={(value) => {
                                dispatch(value);
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='CST_CD'
                    component={(state, dispatch, condition, setField) => {
                        let param = condition['CST_PARAM'] || null;
                        if (isFieldDiff(['WORK_CD'], condition, param)) {
                            param = { 
                                WORK_CD: condition['WORK_CD'],
                                CST_TYPE: [1, 2],
                                CST_KIND: [1, 2],
                            };
                            setField('CST_PARAM', param);
                        }
                        return (
                            <ComboSearch
                                label='거래처명'
                                selectProc='SLT_CUSTOMER_COMBO '
                                selectParam={param}
                                keyField='CST_CD'
                                valueField='CST_NM'
                                value={state}
                                onChange={(value) => dispatch(value)}
                            />
                        )
                    }}
                />
            </SearchPanel>
            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab label='거래처 차량' />
                    <Tab label='종료 차량' />
                </Tabs>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <CstCar condition={condition} onload={gridRefresh}/>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <CstCarDelete condition={condition} onload={gridRefresh}/>
            </div>
        </div>
    )
}

const CstCar = ({condition, onload}) =>{
    const onDelete = async (row) => {
        const result = await callProc('DLT_CSTCARINFO', row);

        if (!result.err) {
            onload();
            return false;
        }
    }
    return(
        <DataGrid
            title='거래처 차량'
            // table='TB_CSTCARINFO'
            selectProc='SLT_CSTCARINFO'
            insertProc='INS_CSTCARINFO'
            onDelete={row => onDelete(row)}
            selectParam={condition}
            selectionMode='single'
            horizonMode
            excelDownload
        >
            <DataColumn
                fieldName='WORK_CD' 
                title='사업장명'
                value={(row) => row['WORK_NM']}
                defaultValue={condition && condition['WORK_CD']}
                editable={(state, dispatch) => (
                    <ComboBox
                        selectProc='SLT_WORKPLACEINFO_AUTH'
                        keyField='WORK_CD'
                        valueField='WORK_NM'
                        value={state}
                        onChange={(value) => dispatch(value)}
                        disabled
                    />
                )}
            />
            <DataColumn
                fieldName = 'CST_CD'
                title = '거래처명'
                value = {(row) => row['CST_NM']}
                defaultValue={condition && condition['CST_CD']}
                editable = {(state, dispatch, rowState, setField) => {
                    let param = condition['CST_PARAM'] || null;
                    if (isFieldDiff(['WORK_CD'], rowState, param)) {
                        param = { 
                            WORK_CD: rowState['WORK_CD'],
                            CST_TYPE: [0, 2],
                            CST_KIND: [1, 2],
                        };
                        setField('CST_PARAM', param);
                    }
                    return (
                        <ComboBox
                            selectProc='SLT_CUSTOMER_COMBO '
                            selectParam={param}
                            keyField='CST_CD'
                            valueField='CST_NM'
                            value={state}
                            onChange={(value) => dispatch(value)}
                            disabled
                        />
                    )
                }}
            />
            <DataColumn
                fieldName = 'CAR_NO'
                title = '차량번호'
                editable = {(state, dispatch) => (
                    <TextField
                        style={{width: 100}}
                        value={state}
                        onChange={state => dispatch(state)}
                    />
                )}
            />
            <DataColumn
                fieldName='CAR_CD'
                visible={false}
            />
            <DataColumn
                fieldName='WORK_NM'
                visible={false}
            />
            <DataColumn
                fieldName='CST_NM'
                visible={false}
            />
        </DataGrid>
    )
}
const CstCarDelete = ({condition, onload}) =>{    
    const snack = useRef();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const onUpdate = async(row) => {
        snack.current && closeSnackbar(snack.current);
        snack.current = await enqueueSnackbar("차량을 재등록 하시겠습니까?", {
            variant: "warning",
            autoHideDuration: null,
            action: key => <>
                <Button onClick={() => {
                    closeSnackbar(key);
                    callProc('UPT_CSTCARINFO_DELETE', row);
                    onload();
                }}>
                    예
                </Button>
                <Button onClick={() => closeSnackbar(key)}>
                    아니오
                </Button>
            </>,
        })
    }
    return(
        <DataGrid
            title='종료 차량'
            // table='TB_CSTCARINFO'
            selectProc='SLT_CSTCARINFO_DELETE'
            selectParam={condition}
            selectionMode='single'
            horizonMode
        >
            <DataColumn
                position='WORK_CD'
                value={row => (
                    <IconButton
                        tooltip='차량 재등록'
                        icon={<EditIcon />}
                        onClick={() => onUpdate(row)}
                    />
                )}
            />
            <DataColumn fieldName='WORK_NM'/>
            <DataColumn fieldName='CST_NM'/>
            <DataColumn fieldName='CAR_NO'/>
            <DataColumn fieldName='REGDATE'/>
            <DataColumn fieldName='REGUSER'/>
            <DataColumn fieldName='EDTDATE'/>
            <DataColumn fieldName='EDTUSER'/>     
            <DataColumn fieldName='CAR_CD' visible={false}/>
            <DataColumn fieldName='WORK_CD' visible={false}/>
            <DataColumn fieldName='CST_CD' visible={false}/>
        </DataGrid>
    )
}
export default CstCarInfo