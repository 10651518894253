import React from 'react';
import moment from 'moment';
import { Document, StyleSheet, Text, View } from '@react-pdf/renderer';
import { PdfCell, PdfTable, PdfRow, PdfPage } from '../component';
import { numberFormat, numberPointFormat } from '../common/Utils';


const styles = StyleSheet.create({
    title: {
        fontSize: 16,
        textAlign: 'center',
        marginBottom: 10,
    },
    subTitle: {
        fontSize: 13,
        fontWeight: 'medium',
        marginBottom: 5,
    },
    supplyContainer: {
        width: '50%'
    },
    supplyFields: {
        marginTop: 2,
        width: '98%',
        flexDirection: 'row',
    },
    supplyText: {
        width: 100
    },
    takenContainer: {
        width: '50%'
    },
    takenFields: {
        marginTop: 2,
        width: '98%',
        flexDirection: 'row',
    },
    takenText: {
        width: '36%'
    },
    headerNormalCell: {
        borderTop:1,
        borderRight: 0,
        textAlign: 'left',
    },
    headerNumCell: {
        borderTop:1,
        borderRight: 0,
        textAlign: 'right',
    },
    headerEmptyCell: {
        borderTop: 1, 
        borderRight: 0,
    },
    emptyCell: {
        border: 0, 
    },
    normalCell: {
        border: 0, 
        textAlign: 'left',
    },
    numCell: {
        border: 0, 
        textAlign: 'right',
    },
    middleSummaryNormalCell: {
        borderRight: 0, 
        textAlign: 'left',
        backgroundColor : '#D3D3D3',
    },
    middleSummaryNumCell: {
        borderRight: 0, 
        textAlign: 'right',
        backgroundColor : '#D3D3D3',
    }
})
const TradingReportCarPdf = ({ date, data, sumData, cstBondData, inMoneyData, workInfo, cstInfo, conf }) => {
    const getTotal = (data, field, digit) => (
        digit ? numberFormat(data.reduce((result, item) => result + item[field], 0)) : 
        numberPointFormat(data.reduce((result, item) => result + item[field], 0), digit)
    )

    const getTotalRow = (row, fieldName, idx) => {
        let totalRow;
        if (!data[idx + 1] ||
            fieldName.reduce((result, field) => result || row[field] !== data[idx + 1][field], false)) {

            const groupData = data.reduce((result, item) => (
                fieldName.reduce((result, field) => result && item[field] === row[field], true) ?
                    [...result, item] :
                    result
            ), []);

            totalRow = (
                <PdfRow key={fieldName.reduce((result, field) => result + field) + idx}>
                    <PdfCell style={styles.middleSummaryNormalCell}>
                    </PdfCell>
                    <PdfCell style={styles.middleSummaryNormalCell}>
                        {'(소계) '}
                    </PdfCell>
                    <PdfCell style={styles.middleSummaryNormalCell}/>
                    <PdfCell style={styles.middleSummaryNumCell}>
                        {getTotal(groupData, 'QTY', 3)}
                    </PdfCell>
                    <PdfCell style={styles.middleSummaryNormalCell}>
                    </PdfCell>
                    <PdfCell style={styles.middleSummaryNumCell}>
                        {getTotal(groupData, 'SPLY_AMT')}
                    </PdfCell>
                    <PdfCell style={styles.middleSummaryNumCell}>
                        {getTotal(groupData, 'VAT')}
                    </PdfCell>
                    <PdfCell style={styles.middleSummaryNumCell}>
                        {getTotal(groupData, 'AMOUNT')}
                    </PdfCell>
                </PdfRow>
            )
        }
        return totalRow
    }

    return (
        <Document title={`차량별 일자별 매출 거래명세서_${workInfo['WORK_PRNT_NM']}_${date['STA_DATE'].replace(/-/g, '')}~${date['END_DATE'].replace(/-/g, '')}`}>
            <PdfPage>
                <Text style={styles.title}>차량별 일자별 매출 거래 명세서</Text>
                <View style={{ flexDirection: 'row', marginBottom: 8, marginTop: 8, }}>
                    <View style={styles.supplyContainer}>
                        <Text>[ 공급자 ]</Text>

                        <View style={{ marginTop: 4, width: '98%', border: 1 }}></View>
                        <View style={styles.supplyFields}>
                            <Text style={styles.supplyText}>상호( 대표자 )  </Text>
                            <Text>{`:  ${workInfo['WORK_PRNT_NM']}`}</Text>
                        </View>
                        <View style={styles.supplyFields}>
                            <Text style={styles.supplyText}>사업자( 주민 )등록번호  </Text>
                            <Text>{`:  ${workInfo['WORK_BIZNO'].substr(0, 3)}-${workInfo['WORK_BIZNO'].substr(3, 2)}-${workInfo['WORK_BIZNO'].substr(5)}`}</Text>
                        </View>
                        <View style={styles.supplyFields}>
                            <Text style={styles.supplyText}>발행 일자 </Text>
                            <Text>{`:  ${moment().format('YYYY년 MM월 DD일')}`}</Text>
                        </View>
                        <View style={styles.supplyFields}>
                            <Text style={styles.supplyText}>연락처 </Text>
                            <Text>{`:  ${workInfo['WORK_TELNO1']}`}</Text>
                        </View>
                        <View style={styles.supplyFields}>
                            <Text style={styles.supplyText}>거래 금액 </Text>
                            <Text>{`:  ${numberFormat(cstBondData.length !== 0 ? cstBondData[0]['CUR_SALE_AMT'] : 0)} 원`}</Text>
                        </View>
                        <View style={styles.supplyFields}>
                            <Text style={styles.supplyText}>입금 계좌 </Text>
                            <Text style={{ width: 170 }}>{`:  ${workInfo['IN_BANK']}`}</Text>
                        </View>
                    </View>

                    <View style={styles.takenContainer}>
                        <Text>[ 공급 받는자 ]</Text>
                        <View style={{ marginTop: 4, width: '98%', border: 1 }}></View>
                        <View style={styles.takenFields}>
                            <Text style={styles.takenText}>상호( 대표자 ) </Text>
                            <Text>{`:  ${cstInfo && cstInfo['CST_NM']}`}</Text>
                        </View>
                        <View style={styles.takenFields}>
                            <Text style={styles.takenText}>사업자( 주민 )등록번호 </Text>
                            <Text>{cstInfo && cstInfo['WORK_BIZNO'] && `:  ${cstInfo['WORK_BIZNO'].substr(0, 3)}-${cstInfo['WORK_BIZNO'].substr(3, 2)}-${cstInfo['WORK_BIZNO'].substr(5)}`}</Text>
                        </View>
                        <View style={styles.takenFields}>
                            <Text style={styles.takenText}>거래 기간 </Text>
                            <Text>{`:  ${moment(date['STA_DATE']).format('YYYY년 MM월 DD일')} ~ ${moment(date['END_DATE']).format('YYYY년 MM월 DD일')}`}</Text>
                        </View>
                        <View style={{ marginTop: 8, width: '98%' }}>
                            <PdfTable>
                                <PdfRow>
                                    <PdfCell style={{ border: 0, backgroundColor: '#D3D3D3' }}>전기채권잔액</PdfCell>
                                    <PdfCell style={{ border: 0, backgroundColor: '#D3D3D3' }}>당기발생채권</PdfCell>
                                    <PdfCell style={{ border: 0, backgroundColor: '#D3D3D3' }}>당기입금액</PdfCell>
                                    <PdfCell style={{ border: 0, backgroundColor: '#D3D3D3' }}>당기채권잔액</PdfCell>
                                </PdfRow>
                            </PdfTable>
                            <View style={{ marginTop: 2, width: '98%', border: 1 }}></View>
                            <View style={{ marginTop: 2, width: '100%' }}>
                                <PdfTable>
                                    <PdfRow>
                                        <PdfCell style={{ border: 0, backgroundColor: '#D3D3D3' }}>{numberFormat(cstBondData.length !== 0 && cstBondData[0]['BEF_BAL_AMT'])}</PdfCell>
                                        <PdfCell style={{ border: 0, backgroundColor: '#D3D3D3' }}>{numberFormat(cstBondData.length !== 0 && cstBondData[0]['CUR_SALE_AMT'])}</PdfCell>
                                        <PdfCell style={{ border: 0, backgroundColor: '#D3D3D3' }}>{numberFormat(cstBondData.length !== 0 && cstBondData[0]['CUR_IN_AMT'])}</PdfCell>
                                        <PdfCell style={{ border: 0, backgroundColor: '#D3D3D3' }}>{numberFormat(cstBondData.length !== 0 && cstBondData[0]['CUR_BAL_AMT'])}</PdfCell>
                                    </PdfRow>
                                </PdfTable>
                            </View>
                        </View>
                    </View>

                </View>
                <View style={{ marginTop: 8 }}>
                    <Text>[ 참조 사항 ]</Text>
                    <Text style={{ marginTop: 4, textAlign: 'justify' }}>
                        본 거래 명세서 및 채권 잔액의 내용에 대하여 확인 후 1주일 내에 이의가 없을 경우 본 내용에 대하여 동의하시는것으로 합니다.
                    </Text>
                    <Text style={{ textAlign: 'justify' }}>
                        계좌 이체시 [공급자] 정보의 입금 계좌로 입금하지 않을 경우 발생되는 고객의 불이익에 대해서는 고객이 전적으로 책임을
                        지며 당사는 어떠한 책임도 지지 않습니다.
                    </Text>
                    <Text>
                        {`문의 : 관리팀 ${workInfo['WORK_TELNO1']}`}
                    </Text>
                </View>
                <View style={{ marginTop: 8 }}>
                    <PdfTable>
                        <PdfRow fixed>
                            <PdfCell style={styles.headerNormalCell}>상      품</PdfCell>
                            <PdfCell style={styles.headerNormalCell}>차 량 번 호</PdfCell>
                            <PdfCell style={styles.headerNormalCell}>영  업  일</PdfCell>
                            <PdfCell style={styles.headerNumCell}>수      량</PdfCell>
                            <PdfCell style={styles.headerNumCell}>단      가</PdfCell>
                            <PdfCell style={styles.headerNumCell}>공 급 가 액</PdfCell>
                            <PdfCell style={styles.headerNumCell}>세      액</PdfCell>
                            <PdfCell style={styles.headerNumCell}>합 계 금 액</PdfCell>
                        </PdfRow>
                        {data && data.map((item, idx) => {
                            return (
                            [<PdfRow key={idx}>
                                <PdfCell style={styles.normalCell}>{item['GDS_NM']}</PdfCell>
                                <PdfCell style={styles.normalCell}>{item['CAR_NO']}</PdfCell>
                                <PdfCell style={styles.normalCell}>{item['BIZ_DATE']}</PdfCell>
                                <PdfCell style={styles.numCell}>{numberPointFormat(item['QTY'], 3)}</PdfCell>
                                <PdfCell style={styles.numCell}>{numberPointFormat(item['PRICE'], 3)}</PdfCell>
                                <PdfCell style={styles.numCell}>{numberFormat(item['SPLY_AMT'])}</PdfCell>
                                <PdfCell style={styles.numCell}>{numberFormat(item['VAT'])}</PdfCell>
                                <PdfCell style={styles.numCell}>{numberFormat(item['AMOUNT'])}</PdfCell>
                            </PdfRow>,
                            getTotalRow(item, ['CAR_NO'], idx)] )
                            })}
                        <PdfRow>
                            <PdfCell style={{...styles.headerEmptyCell, backgroundColor: '#D3D3D3'}}></PdfCell>
                            <PdfCell style={{...styles.headerNormalCell, backgroundColor: '#D3D3D3'}}>합   계</PdfCell>
                            <PdfCell style={{...styles.headerEmptyCell, backgroundColor: '#D3D3D3'}}></PdfCell>
                            <PdfCell style={{...styles.headerNumCell, backgroundColor: '#D3D3D3'}}>
                                {getTotal(data, 'QTY', 3)}
                            </PdfCell>
                            <PdfCell style={{...styles.headerEmptyCell, backgroundColor: '#D3D3D3'}}></PdfCell>
                            <PdfCell style={{...styles.headerNumCell, backgroundColor: '#D3D3D3'}}>
                                {getTotal(data, 'SPLY_AMT')}
                            </PdfCell>
                            <PdfCell style={{...styles.headerNumCell, backgroundColor: '#D3D3D3'}}>
                                {getTotal(data, 'VAT')}
                            </PdfCell>
                            <PdfCell style={{...styles.headerNumCell, backgroundColor: '#D3D3D3'}}>
                                {getTotal(data, 'AMOUNT')}
                            </PdfCell>
                        </PdfRow>
                    </PdfTable>
                </View>
                {conf['TRD_REPORT_SUM'] === '0' ? null :
                    <View style={{ marginTop: 8 }}>
                    <PdfTable>
                        <PdfRow>
                            <PdfCell style={styles.headerEmptyCell}>
                                상        품
                            </PdfCell>
                            <PdfCell style={styles.headerNumCell}>
                                수        량
                            </PdfCell>
                            <PdfCell style={styles.headerNumCell}>
                                합 계 금 액
                            </PdfCell>
                        </PdfRow>
                        {sumData && sumData.map((item, idx) => (
                            <PdfRow key={idx}>
                                <PdfCell style={styles.normalCell}>{item['GDS_NM']}</PdfCell>
                                <PdfCell style={styles.numCell}>{numberFormat(item['QTY'])}</PdfCell>
                                <PdfCell style={styles.numCell}>{numberFormat(item['AMOUNT'])}</PdfCell>
                            </PdfRow>
                        ))}
                        <PdfRow>
                            <PdfCell style={{ borderRight: 0, textAlign: 'left', backgroundColor : '#D3D3D3' }}>
                                상품 합계
                            </PdfCell>
                            <PdfCell style={{ borderRight: 0, textAlign: 'right', backgroundColor : '#D3D3D3' }}>
                                {getTotal(sumData, 'QTY')}
                            </PdfCell>
                            <PdfCell style={{ borderRight: 0, textAlign: 'right', backgroundColor : '#D3D3D3' }}>
                                {getTotal(sumData, 'AMOUNT')}
                            </PdfCell>
                        </PdfRow>
                    </PdfTable>
                </View>}
                {inMoneyData ? 
                <View style={{ marginTop: 8 }}>
                    <PdfTable>
                        <Text style={styles.subTitle}> </Text>
                        <Text style={styles.subTitle}>[입금 내역서]</Text>
                        <PdfRow>
                            <PdfCell style={styles.headerEmptyCell}>
                                입 금 일
                            </PdfCell>
                            <PdfCell style={styles.headerEmptyCell}>
                                입 금 수 단
                            </PdfCell>
                            <PdfCell style={styles.headerNumCell}>
                                합 계 금 액
                            </PdfCell>
                        </PdfRow>
                        {inMoneyData && inMoneyData.map((item, idx) => (
                            <PdfRow key={idx}>
                                <PdfCell style={styles.emptyCell}>{item['BIZ_DATE']}</PdfCell>
                                <PdfCell style={styles.emptyCell}>{item['CARD_NM']}</PdfCell>
                                <PdfCell style={styles.numCell}>{numberFormat(item['AMOUNT'])}</PdfCell>
                            </PdfRow>
                        ))}
                        <PdfRow>
                            <PdfCell style={{ borderRight: 0, textAlign: 'left', backgroundColor : '#D3D3D3' }}>
                                외상수금 합계
                            </PdfCell>
                            <PdfCell style={{ borderRight: 0, textAlign: 'left', backgroundColor : '#D3D3D3' }}></PdfCell>
                            <PdfCell style={{ borderRight: 0, textAlign: 'right', backgroundColor : '#D3D3D3' }}>
                                {getTotal(inMoneyData, 'AMOUNT')}
                            </PdfCell>
                        </PdfRow>
                    </PdfTable>
                </View> :
                null}
            </PdfPage>
        </Document>
    )
}

export default TradingReportCarPdf;