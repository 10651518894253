import React, { useState } from 'react';
import moment from 'moment';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DatePicker, DataColumn } from '../../component';
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    }
}))

const CashReport = () => {
    const [condition, setCondition] = useState(null);

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => {
                sessionStorage['WORK_CD'] = condition['WORK_CD'];
                setCondition(condition)}}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            onDataLoad={jsonData => ({ALL: '전체', ...jsonData})}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
            </SearchPanel>
                <DataGrid
                    title='자금 관리'
                    selectProc='SLT_CASH_REPORT'
                    selectParam={condition}
                    selectionMode='single'
                    horizonMode
                    virtualized
                    excelDownload
                >
                    <DataColumn 
                        fieldName='ACC_NM' 
                        title='계정과목명'
                        total={(values) => '합계'}
                    />
                    <DataColumn fieldName='IN_AMT' title='수입' total={(values) => values.reduce((result, value) => result + value, 0)} />
                    <DataColumn fieldName='OUT_AMT' title='지출' total={(values) => values.reduce((result, value) => result + value, 0)} />
                    <DataColumn fieldName='BAL_AMT' title='잔액'  />
                </DataGrid>
        </div>
    )
}
export default CashReport;