import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DatePicker, DataColumn, Tab, Tabs, PdfButton } from '../../component';
import { makeStyles } from '@material-ui/core';
import { CstGoodsNotGoodsInHistoryPdf } from '../../reports';
import { useSelector } from 'react-redux';
import { totalCount } from '../../common/Utils';

const useStyle = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    tabContainer : {
        display : 'flex',
    },
    tabbar : {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    content : {
        display: 'flex',
        flex: 1,
        height : 0,
    },
    hide : {
        display: 'none'
    },
}))

const CstGoodsNotGoodsInHistory = () => {
    const classes = useStyle();
    const [condition, setCondition] = useState(null);
    const [selectTab, setSelectTab] = useState(0);
    const [workData, setWorkData] = useState();
    
    return(
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => {
                sessionStorage['WORK_CD'] = condition['WORK_CD'];
                setCondition(condition);
                }}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            onDataLoad={(jsonData, rawData) => {
                                const result = [{ WORK_CD: 'ALL', WORK_NM: '전체' }, ...rawData];
                                setWorkData(result);
                                return result;
                            }}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
            </SearchPanel>
            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab label='전체' />
                    <Tab label='상품' />
                    <Tab label='비상품' />
                </Tabs>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <All condition={condition} workData={workData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <Goods condition={condition} workData={workData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 2 })}>
                <NotGoods condition={condition} workData={workData} />
            </div>
        </div>
    )
}

const All = ({condition, workData}) => {
    const [pdfData, setPdfData] = useState();
    const loginState = useSelector(state => state.loginReducer);

    const pdfComponent = useMemo(() => (
        <PdfButton title='매입처별채무현황'>
            {pdfData &&
            <CstGoodsNotGoodsInHistoryPdf
                pdfData={pdfData}
                condition={condition}
                loginState={loginState}
                workName={workData.find(item => item['WORK_CD'] === condition['WORK_CD'])['WORK_NM']}
            />}
        </PdfButton>
    ), [pdfData, condition, loginState, workData]);

    return (
        <>
        <DataGrid
            title='매입처 전체'
            selectProc='SLT_IN_CST_GOOD_NOTGOOD_LIST'
            selectParam={condition}
            totalGroup={['WORK_CD']}
            horizonMode
            virtualized
            sortable
            filterable
            excelDownload={{ fileName: '매입처별채무현황-전체.xlsx', sheetName: '전체' }}
            headerItem={({visibleAoa}) => {
                setPdfData(visibleAoa);
                return pdfComponent;
            }}
            stickyHeader
        >
            <DataColumn 
                fieldName='WORK_NM' 
                total={(values, data, group) =>
                    group == null ? `- 합 계 ${totalCount(values)} 건 -` : `(소 계 ${totalCount(values)} 건) ${data[0]['WORK_NM']}`}
            />
            <DataColumn fieldName='CST_NM' title='매입처' />
            <DataColumn fieldName='BEF_BAL_P' title='전기 매입잔액(+)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='BEF_BAL_M' title='전기 매입잔액(-)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_IN_AMT' title='매입 금액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_OUT_AMT' title='지급 금액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_BAL_P' title='당기 매입잔액(+)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_BAL_M' title='당기 매입잔액(-)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='WORK_CD' visible={false} />
            <DataColumn fieldName='CST_CD' visible={false} />
        </DataGrid>
        </>
    )
}

const Goods = ({condition, workData}) => {
    const [pdfData, setPdfData] = useState();
    const loginState = useSelector(state => state.loginReducer);

    const pdfComponent = useMemo(() => (
        <PdfButton title='매입처별채무현황'>
            {pdfData &&
            <CstGoodsNotGoodsInHistoryPdf
                pdfData={pdfData}
                condition={condition}
                loginState={loginState}
                workName={workData.find(item => item['WORK_CD'] === condition['WORK_CD'])['WORK_NM']}
            />}
        </PdfButton>
    ), [pdfData, condition, loginState, workData]);

    return (
        <>
        <DataGrid
            title='매입처 상품'
            selectProc='SLT_IN_CST_GOOD_LIST'
            selectParam={condition}
            totalGroup={['WORK_CD']}
            horizonMode
            virtualized
            sortable
            filterable
            excelDownload={{ fileName: '매입처별채무현황-상품.xlsx', sheetName: '상품' }}
            headerItem={({visibleAoa}) => {
                setPdfData(visibleAoa);
                return pdfComponent;
            }}
            stickyHeader
        >
            <DataColumn 
                fieldName='WORK_NM' 
                total={(values, data, group) =>
                    group == null ? `- 합 계 ${totalCount(values)} 건 -` : `(소 계 ${totalCount(values)} 건) ${data[0]['WORK_NM']}`}
            />
            <DataColumn fieldName='CST_NM' title='매입처' />
            <DataColumn fieldName='BEF_BAL_P' title='전기 매입잔액(+)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='BEF_BAL_M' title='전기 매입잔액(-)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_IN_AMT' title='매입 금액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_OUT_AMT' title='지급 금액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_BAL_P' title='당기 매입잔액(+)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='CUR_BAL_M' title='당기 매입잔액(-)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            <DataColumn fieldName='WORK_CD' visible={false} />
            <DataColumn fieldName='CST_CD' visible={false} />
        </DataGrid>
        </>
        )
}

const NotGoods = ({condition, workData}) => {
    const [pdfData, setPdfData] = useState();
    const loginState = useSelector(state => state.loginReducer);

    const pdfComponent = useMemo(() => (
        <PdfButton title='매입처별채무현황'>
            {pdfData &&
            <CstGoodsNotGoodsInHistoryPdf
                pdfData={pdfData}
                condition={condition}
                loginState={loginState}
                workName={workData.find(item => item['WORK_CD'] === condition['WORK_CD'])['WORK_NM']}
            />}
        </PdfButton>
    ), [pdfData, condition, loginState, workData]);

    return (
    <>
    <DataGrid
        title='매입처 비상품'
        selectProc='SLT_IN_CST_NOTGOOD_LIST'
        selectParam={condition}
        totalGroup={['WORK_CD']}
        horizonMode
        virtualized
        sortable
        filterable
        excelDownload={{ fileName: '매입처별채무현황-비상품.xlsx', sheetName: '비상품' }}
        headerItem={({visibleAoa}) => {
            setPdfData(visibleAoa);
            return pdfComponent;
        }}
        stickyHeader
    >
        <DataColumn 
                fieldName='WORK_NM' 
                total={(values, data, group) =>
                    group == null ? `- 합 계 ${totalCount(values)} 건 -` : `(소 계 ${totalCount(values)} 건) ${data[0]['WORK_NM']}`}
            />
        <DataColumn fieldName='CST_NM' title='매입처' />
        <DataColumn fieldName='BEF_BAL_P' title='전기 매입잔액(+)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
        <DataColumn fieldName='BEF_BAL_M' title='전기 매입잔액(-)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
        <DataColumn fieldName='CUR_IN_AMT' title='매입 금액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
        <DataColumn fieldName='CUR_OUT_AMT' title='지급 금액' total={(values) => values.reduce((result, value) => result + value, 0)}/>
        <DataColumn fieldName='CUR_BAL_P' title='당기 매입잔액(+)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
        <DataColumn fieldName='CUR_BAL_M' title='당기 매입잔액(-)' total={(values) => values.reduce((result, value) => result + value, 0)}/>
        <DataColumn fieldName='WORK_CD' visible={false} />
        <DataColumn fieldName='CST_CD' visible={false} />
    </DataGrid>
    </>
    )
}

export default CstGoodsNotGoodsInHistory