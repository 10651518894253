import React, { useState, useEffect, useMemo } from 'react';
import { DataGrid, ComboBox, DatePicker, DataColumn, NumberField, ComboSearch, PaperTitle, IconButton } from '../../component';
import { makeStyles, Paper, TextField } from '@material-ui/core';
import { adjType, orderType, regType } from '../../common/Dictionary';
import { useFieldInputs } from '../../common/Utils';
import CheckIcon from '@material-ui/icons/Check';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import { callProc } from '../../common/DBConnector';

const adjTypeTrans = {
    '1': '조정',
}

const useStyle = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
        flex: 2,
        marginRight: theme.spacing(1),
    },
    enroll: {
        display: 'flex', 
        flex: 1,
        marginLeft: theme.spacing(1),
    },
    searchPaper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    searchContainer: {
        flex: 1,
        display: 'inline-block',
        flexDirection: 'row',
    },
    searchItem: {
        display: 'inline-block',
        width: 200,
        marginRight: 100,
        marginBottom: theme.spacing(1),
    },
    contents: {
        display: 'flex',
        height: '100%',
    },
    paper: {
        flex: 1,
        height: '100%',
    },
    paperContent: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding: theme.spacing(2),
        paddingTop: 0
    },
    field: {
        width: 180,
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2),
    },
    iconbutton : {
        alignSelf: 'flex-end',
        marginTop: theme.spacing(4),
        marginRight: theme.spacing(1),
    },
}))

const TransAdjustEnrollDel = () => {
    const classes = useStyle();
    const clsState = useSelector(state => state.clsDateReducer);
    const [selectedRow, setSelectedRow] = useState();
    const [input, setInput] = useFieldInputs({
        WORK_CD: sessionStorage['WORK_CD'],
        BIZ_DATE: clsState[sessionStorage['WORK_CD']],
    });

    const [insData, setInsData, clear] = useFieldInputs({
        TRN_CD: '' ,
        WORK_CD: '',
        ADJ_TYPE: '1',
        REMARK: '',
        TSP_CAR_CD: '',
        TRANS_PRICE: 0,
        REWD_PRICE:  0,
        BIZ_DATE: ''
    });

    useEffect(() => {
        selectedRow && setInsData({ type: 'UPDATE', value: { ...selectedRow, ADJ_TYPE: '1' } });
    }, [selectedRow, setInsData])

    const { enqueueSnackbar } = useSnackbar();

    const condition = useMemo(() => input['WORK_CD'] && input['BIZ_DATE'] && input, [input]);

    const gridRefresh = () => {
        setInput({ type: 'UPDATE', value: { ...input } });
    }

    const valid = () => {
        if(insData['TRN_CD'] === ''){
            enqueueSnackbar('조정/대체 대상을 선택해주세요.', {variant: 'error'});
            return false;
        }
        if(insData['REMARK'] == null || insData['REMARK'] === ''){
            enqueueSnackbar('조정/대체 시 사유를 작성해주세요.', {variant: 'error'});
            return false;
        }
        return true;
    }

    const onInsert = async() => {
        if(valid()){
            const result = await callProc('UPT_TRANSADJUSTENROLLDEL', insData);

            if (!result.err) {
                gridRefresh();
                clear();
                enqueueSnackbar('저장이 완료되었습니다.');
            }
        }
    }


    return (
        <div className={classes.container}>
            <Paper className={classes.searchPaper}>
                <div className={classes.searchContainer}>
                        <div className={classes.searchItem}>
                            <ComboBox
                                // style={{width: 200}}
                                label='사업장'
                                selectProc='SLT_WORKPLACEINFO_AUTH'
                                keyField='WORK_CD'
                                valueField='WORK_NM'
                                value={condition && condition['WORK_CD']}
                                onChange={value => {
                                    if (input['WORK_CD'] !== value) {
                                        sessionStorage['WORK_CD'] = value;
                                        setInput('BIZ_DATE', clsState[value]);
                                        setInput('WORK_CD', value);
                                    }
                                }}
                            />
                        </div>
                        <div className={classes.searchItem}>
                            <DatePicker
                                label='영업일'
                                minDate={clsState[input['WORK_CD']]}
                                value={condition && condition['BIZ_DATE']}
                                onChange={value => {
                                    if (input['BIZ_DATE'] !== value) {
                                        setInput('BIZ_DATE', value);
                                        if(condition && condition['BIZ_DATE'] < clsState[condition['WORK_CD']]) {
                                            setInput({ type: 'UPDATE', value: { ...input, 'BIZ_DATE': value } });
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>

                <IconButton
                    tooltip=''
                    icon={<SearchIcon />}
                    onClick={gridRefresh}
                />
            </Paper>
            <div className={classes.contents}>   
                <DataGrid
                    title='수송 조정/대체'
                    selectProc='SLT_TRANSADJUSTENROLLDEL'
                    deleteProc='DLT_TRANSADJUSTENROLLDEL'
                    onSelect={row => setSelectedRow(row)}
                    selectParam={condition}
                    selectionMode='single'
                    className={classes.info}
                    defaultExpand
                    horizonMode
                >
                    <DataColumn fieldName='CAR_NO' mergeField='CAR_NO'   />
                    <DataColumn fieldName='REG_TYPE' title="등록타입" value={(row, value) => regType[value]} />
                    <DataColumn fieldName='ORD_TYPE' title="오더타입" value={(row, value) => orderType[value]} />
                    <DataColumn fieldName='ADJ_TYPE' title="조정/대체" value={(row, value) => adjType[value]} />
                    <DataColumn fieldName='REWD_PRICE' title='보상금액' />
                    <DataColumn fieldName='TRANS_PRICE' title='운임금액' />
                    <DataColumn fieldName='GDS_NM' title='상품명' />
                    <DataColumn fieldName='SHIP_TRN_NM' title='출하처' />
                    <DataColumn fieldName='DEST_TRN_NM' title='인도처' />
                    <DataColumn fieldName='REAL_DEST_NM' title='도착지' />
                    <DataColumn fieldName='QTY' title='수량' />
                    <DataColumn fieldName='SPLY_AMT' title='운임단가' />
                    <DataColumn fieldName='SPLY_AMT_A' title='운임단가(A)' />
                    <DataColumn fieldName='REMARK' title='비고' />
                    <DataColumn fieldName='WORK_CD' visible={false} />
                    <DataColumn fieldName='TRN_CD' visible={false} />
                    <DataColumn fieldName='BIZ_DATE' visible={false} />
                    <DataColumn fieldName='TSP_CAR_CD' visible={false}  />


                </DataGrid>

                <Paper style={{ width: '100%' }} className={classes.paper}>
                    <PaperTitle>
                        수송 조정/대체
                    </PaperTitle>
                    <div className={classes.paperContent}>
                        <ComboBox
                            className={classes.field}
                            data={adjTypeTrans}
                            value={insData['ADJ_TYPE']}
                            onChange={value => setInsData('ADJ_TYPE', value)}
                            disabled={true}
                        />
                        <TextField
                            className={classes.field}
                            style={{ width: 300 }}
                            label='조정사유'
                            value={insData['REMARK'] || ''}
                            onChange={e => setInsData('REMARK', e.target.value)}
                        />
                        
                        <ComboSearch
                            className={classes.field}
                            label='운송수단'
                            selectProc='SLT_TRANSCARINFO'
                            selectParam={condition}
                            keyField='TSP_CAR_CD'
                            valueField='CAR_NO'
                            value={insData['TSP_CAR_CD']}
                            onChange={value => setInsData('TSP_CAR_CD', value)}
                            
                        />
                            

                        <NumberField
                            className={classes.field}
                            label='운임 합계'
                            value={insData['TRANS_PRICE']}
                            onChange={value => setInsData('TRANS_PRICE',value)}
                        />

                        <NumberField
                            className={classes.field}
                            label='보상 합계'
                            value={insData['REWD_PRICE']}
                            onChange={value => setInsData('REWD_PRICE', value)}
                        />
                        <IconButton
                            className={classes.iconbutton}
                            tooltip='확인'
                            icon={<CheckIcon />}
                            onClick={onInsert}
                        />
                    </div>
                </Paper>

            </div>


        </div>
            

    )
}



export default TransAdjustEnrollDel