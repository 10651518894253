import React, { useState, useEffect, useRef, useMemo } from 'react';
import { ComboBox, ProgressPanel, DatePicker, NumberField, ProgressDialog, PaperTitle, IconButton } from '../../component';
import { Paper, makeStyles,TextField, Button } from '@material-ui/core';
import { callProc } from '../../common/DBConnector';
import { useSnackbar } from 'notistack';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { useFieldInputs } from '../../common/Utils';
import { useSelector } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 96px)',
    },
    paperTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    insertBox : {
        height: '100%',
        paddingTop: theme.spacing(2),
        overflowY: 'auto',
    },
    field : {
        paddingBottom: theme.spacing(1),
        marginRight: theme.spacing(4),
        display: 'inline-block',
    },
    fieldContent : {
        display: 'inline-block',
        width: 450,
    },
    paperContent: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: 'calc(100% - 62px)',
        padding: theme.spacing(4),
        paddingTop: 0,
    },
    btnContainer: {
        display: 'flex',
        alignSelf: 'flex-end',
        marginTop: 'auto',
        padding: theme.spacing(2),
    },
    unit: {
        width: 100,
    },
    searchPaper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    searchContainer: {
        flex: 1,
        display: 'inline-block',
        flexDirection: 'row',
    },
    searchItem: {
        display: 'inline-block',
        width: 200,
        marginRight: 100,
        marginBottom: theme.spacing(1),
    },
}))

const BankBookBalance = () => {
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const [balData, setBalData] = useState();
    const insData = useRef();
    const snack = useRef();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const clsState = useSelector(state => state.clsDateReducer);

    const [input, setInput] = useFieldInputs({
        WORK_CD: sessionStorage['WORK_CD'],
        BIZ_DATE: clsState[sessionStorage['WORK_CD']],
    });

    const condition = useMemo(() => input['WORK_CD'] && input['BIZ_DATE'] && input, [input]);

    const gridRefresh = () => {
        setInput({ type: 'UPDATE', value: { ...input } });
    }

    useEffect(() => {
        const fetchData = async () => {
            setBalData(null);
            const balData = (await callProc('SLT_BANKBOOKBAL', condition)).data;
            setBalData(balData);
            insData.current = balData;
        }
        setEdit(false);
        condition && fetchData();
    }, [condition])

    const classes = useStyles();

    const valid = () => {
        if (balData.reduce((result, item) => result || item['AMOUNT'] === '', false)) {
            enqueueSnackbar('금액을 입력하여 주세요.', { variant: 'error' });
            return false;
        }
        return true;
    }

    const onInsert = async() => {
        setLoading(true);

        if (valid()) {
            const result = await callProc('INS_BANKBOOKBAL', {
                ...condition,
                JSON: insData.current,
            })
            if (!result.err) {
                gridRefresh();
                enqueueSnackbar('저장이 완료되었습니다.');
            }
        }

        setLoading(false);
    }

    const reqBefData = () => {
        snack.current && closeSnackbar(snack.current);

        snack.current = enqueueSnackbar('전일값을 불러오시겠습니까?', {
            variant: 'default',
            autoHideDuration: null,
            action: key => <>
                <Button onClick={() => {
                    closeSnackbar(key);
                    onBefData();
                }}>
                    예
                </Button>
                <Button onClick={() => closeSnackbar(key)}>
                    아니오
                </Button>
            </>,
        })
    }

    const onBefData = async() => {
        setLoading(true);
        const result = await callProc('INS_BEF_BANKBOOKBA', condition);
        if(!result.err) {
            gridRefresh();
            enqueueSnackbar('전일값을 불러왔습니다.');
        }
        setLoading(false);
    }

    return(
        <div className={classes.container}>
            <ProgressDialog open={loading} />
            <Paper className={classes.searchPaper}>
                <div className={classes.searchContainer}>
                    <div className={classes.searchItem}>
                        <ComboBox
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={condition && condition['WORK_CD']}
                            onChange={value => {
                                if (input['WORK_CD'] !== value) {
                                    sessionStorage['WORK_CD'] = value;
                                    setInput('BIZ_DATE', clsState[value]);
                                    setInput('WORK_CD', value);
                                }
                            }}
                        />
                    </div>
                    <div className={classes.searchItem}>
                        <DatePicker
                            label='영업일'
                            minDate={clsState[input['WORK_CD']]}
                            value={condition && condition['BIZ_DATE']}
                            onChange={value => {
                                if (input['BIZ_DATE'] !== value) {
                                    setInput('BIZ_DATE', value);
                                    if(condition && condition['BIZ_DATE'] < clsState[condition['WORK_CD']]) {
                                        setInput({ type: 'UPDATE', value: { ...input, 'BIZ_DATE': value } });
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                <IconButton
                    tooltip='작성'
                    icon={<SearchIcon />}
                    onClick={gridRefresh}
                />
            </Paper>
            <Paper className={classes.paper}>
                <div className={classes.paperTitle}>
                    <PaperTitle>
                        계좌잔액 등록
                    </PaperTitle>
                    {edit ?
                    <div>
                        <IconButton
                            tooltip='취소'
                            icon={<CloseIcon />} 
                            onClick={() => gridRefresh()}
                        />
                        <IconButton
                            tooltip='확인'
                            icon={<CheckIcon />}
                            onClick={onInsert}
                        />
                    </div>:
                    <div>
                        <IconButton
                            tooltip='수정'
                            icon={<EditIcon />}
                            onClick={() => setEdit(true)}
                            disabled={balData == null}
                        />
                        <IconButton
                            tooltip='이전값 불러오기'
                            icon={<RefreshIcon />}
                            onClick={() => reqBefData()}
                        />
                    </div>}
                </div>
                <div className={classes.paperContent}>
                    <div>
                        <ComboBox
                            className={classes.field}
                            style={{width: 200}}
                            label='사업장명'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={condition && condition['WORK_CD']}
                            disabled
                        />
                        <TextField
                            className={classes.field}
                            label='영업일'
                            value={(condition && condition['BIZ_DATE']) || ''}
                            disabled
                        />
                    </div>
                    <div className={classes.insertBox}>
                        {!balData ? <ProgressPanel /> :
                        balData.map((item, idx) => (
                            <EnrollField
                                key={idx}
                                data={item}
                                edit={edit}
                                onDataChange={(field, value) => insData.current[idx][field] = value}
                                idx={idx}
                            />
                        ))}
                    </div>
                </div>
            </Paper>
        </div>
    )
}

const fieldStyle = makeStyles((theme) => ({
    field : {
        paddingBottom: theme.spacing(1),
        marginRight: theme.spacing(4),
        display: 'block',
    },
    fieldContent : {
        display: 'inline-block',
        width: 450,
    },
    paperContent: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: 'calc(100% - 62px)',
        padding: theme.spacing(4),
        paddingTop: 0,
    },
    btnContainer: {
        display: 'flex',
        alignSelf: 'flex-end',
        marginTop: 'auto',
        padding: theme.spacing(2),
    },
    unit: {
        width: 100,
    },
    numField : {
        textAlign: 'right'
    }
}))

const EnrollField = ({ data, edit, onDataChange, idx }) => {
    const classes = fieldStyle();
    const [balData, setBalData] = useFieldInputs(data);
    
    return (
        <div className={classes.field}>
            <div className={classes.fieldContent}>
                {balData['BBK_NO'] + '  /  ' + balData['BBK_NM']}
                <NumberField
                    value={balData['AMOUNT']}
                    onChange={value => {
                        setBalData('AMOUNT', value);
                        onDataChange('AMOUNT', value);
                    }}
                    style={{ display: 'block' }}
                    disabled={!edit}
                    inputProps={{ tabindex: idx + 1, className: classes.numField }}
                />
            </div>
            <div className={classes.fieldContent}>
                비고
                <TextField
                    value={balData['REMARK']}
                    onChange={e => {
                        setBalData('REMARK', e.target.value);
                        onDataChange('REMARK', e.target.value);
                    }}
                    style={{ display: 'block', width: 450 }}
                    disabled={!edit}
                    fullWidth
                />
            </div>
        </div>
    )
}

export default BankBookBalance