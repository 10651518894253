import React from 'react';
import moment from 'moment';
import { Document, StyleSheet, Text, View } from '@react-pdf/renderer';
import { PdfCell, PdfTable, PdfRow, PdfPage } from '../component';
import { numberFormat } from '../common/Utils';



const styles = StyleSheet.create({
    title: {
        fontSize : 16,
        textAlign : 'center',
        marginBottom: 8
    },
    headerCell: {
        height: 25,
        justifyContent: 'center'
    },
    context: {
        height: 25,
        justifyContent: 'center',
    },
    pageNumber: {
        position: 'absolute',
        bottom: 15,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
})


const OrderInCalcPdf = ({tankAvg, gdsAvg, inQty, condWorkName, inQtySum, tankAvgList, qtySum, qtyGapSum, gdsAvgSum}) => {
    
    const getTotal = (data, field) => (
        numberFormat(data.reduce((result, item) => result + item[field], 0))
    )

    // const realInQty = (idx) => (
    //         inQty[idx] ? numberFormat(parseInt(inQty[idx])) : 0
    // )

    //상품별 array 전체 총 합계내기위한계산
    const getTotal2 = (data) => {
        const totalList = Object.values(data)
        return totalList.reduce((result, item) => result + item, 0)
    }



    return (
        <Document>
            <PdfPage>
                <Text style={styles.title}>{`${condWorkName} 탱크 입고 현황`}</Text>
                <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
                    <Text>{`인쇄기간: ${moment().format('YYYY-MM-DD HH:mm:ss')}`}</Text>
                </View>

                <PdfTable>
                    <PdfRow>
                        <PdfCell cellLeft cellTop style={styles.headerCell}>상품</PdfCell>
                        <PdfCell cellTop style={styles.headerCell}>탱크번호</PdfCell>
                        <PdfCell cellTop style={styles.headerCell}>현재고</PdfCell>
                        <PdfCell cellTop style={styles.headerCell}>입고가능용량</PdfCell>
                        <PdfCell cellTop style={styles.headerCell}>발주량</PdfCell>
                    </PdfRow>
                    {tankAvg && tankAvg.data.map((item, idx) => (
                        <PdfRow key={idx}>
                            <PdfCell cellLeft style={styles.context}>{item['DS_CATE_NM']}</PdfCell>
                            <PdfCell style={styles.context}>{item['TNK_NO']}</PdfCell>
                            <PdfCell style={styles.context}>{numberFormat(item['QTY'])}</PdfCell>
                            <PdfCell style={styles.context}>{numberFormat(item['GAP_QTY'])}</PdfCell>
                            <PdfCell style={styles.context}>
                                {inQty[item['TNK_NO']]}
                            </PdfCell>
                        </PdfRow>
                    ))}
                    {tankAvgList.length > 0 && tankAvgList.map((item, idx) => (
                        <PdfRow key={idx}>
                            <PdfCell cellLeft style={styles.context}>(소 계){item}</PdfCell>
                            <PdfCell style={styles.context}></PdfCell>
                            <PdfCell style={styles.context}>{numberFormat(qtySum[item])}</PdfCell>
                            <PdfCell style={styles.context}>{numberFormat(qtyGapSum[item])}</PdfCell>
                            <PdfCell style={styles.context}>
                                {numberFormat(inQtySum[item])}
                            </PdfCell>
                        </PdfRow>
                    ))}
                    <PdfRow>
                        <PdfCell cellLeft style={styles.context}>합계</PdfCell>
                        <PdfCell style={styles.context}></PdfCell>
                        <PdfCell style={styles.context}>{numberFormat(getTotal2(qtySum))}</PdfCell>
                        <PdfCell style={styles.context}>{numberFormat(getTotal2(qtyGapSum))}</PdfCell>
                        <PdfCell style={styles.context}>
                            {numberFormat(getTotal2(inQtySum) === 0 ? '' : getTotal2(inQtySum))}
                        </PdfCell>
                    </PdfRow>
                    
                </PdfTable>
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
            </PdfPage>

            <PdfPage>
                <Text style={styles.title}>{`${condWorkName} 탱크 입고 현황`}</Text>
                <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
                    <Text>{`인쇄기간: ${moment().format('YYYY-MM-DD HH:mm:ss')}`}</Text>
                </View>

                <PdfTable>
                    <PdfRow>
                        <PdfCell cellLeft cellTop style={styles.headerCell}>상품</PdfCell>
                        <PdfCell cellTop style={styles.headerCell}>평균 판매량</PdfCell>
                        <PdfCell cellTop style={styles.headerCell}>발주량</PdfCell>
                        <PdfCell cellTop style={styles.headerCell}>매입 금액</PdfCell>
                    </PdfRow>
                    {gdsAvg && gdsAvg.data.map((item, idx) => (
                        <PdfRow key={idx}>
                            <PdfCell cellLeft style={styles.context}>{item['CATE_NM']}</PdfCell>
                            <PdfCell style={styles.context}>{numberFormat(item['AVG_GAUGE_ELE'])}</PdfCell>
                            <PdfCell style={styles.context}>{numberFormat(inQtySum[item['CATE_NM']] || 0)}</PdfCell>
                            <PdfCell style={styles.context}>{numberFormat(gdsAvgSum[item['CATE_NM']] === 0 ? '' : gdsAvgSum[item['CATE_NM']])}</PdfCell>
                        </PdfRow>
                    ))}
                    <PdfRow>
                            <PdfCell cellLeft style={styles.context}>합계</PdfCell>
                            <PdfCell style={styles.context}>{numberFormat(getTotal(gdsAvg.data, 'AVG_GAUGE_ELE'))}</PdfCell>
                            <PdfCell style={styles.context}>{numberFormat(getTotal2(inQtySum))}</PdfCell>
                            <PdfCell style={styles.context}>{numberFormat(getTotal2(gdsAvgSum) === 0 ? '' : getTotal2(gdsAvgSum))}</PdfCell>
                    </PdfRow>
                </PdfTable>
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
            </PdfPage>
        </Document>
    )
}


export default OrderInCalcPdf;
