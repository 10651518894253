import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ComboBox, DataGrid, DataColumn, TextField } from '../../component';
import { crtType } from '../../common/Dictionary';

const useStyle = makeStyles(() => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
}))

const CreditCard = () => {
    const classes = useStyle();

    return(
        <div className={classes.container}>
            <DataGrid
                title='신용카드정보'
                table='TB_CREDITCARD'
                selectProc='SLT_CREDITCARD'
                insertProc='INS_CREDITCARD'
                updateProc='UPT_CREDITCARD'
                deleteProc='DLT_CREDITCARD'
                selectionMode='single'
                horizonMode
                refreshable
            >
                <DataColumn
                    fieldName='CRT_CD'
                />
                <DataColumn
                    fieldName='CRT_NM'
                    editable={(state, dispatch) => (
                        <TextField
                            style={{width: 150}}
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                />
                <DataColumn
                    fieldName='CRT_TYPE'
                    value={(row, value) => crtType[value]}
                    editable={(state, dispatch) => (
                        <ComboBox
                            data={crtType}
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                />
            </DataGrid>

        </div>
    )
}

export default CreditCard