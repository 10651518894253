import { makeStyles } from '@material-ui/core';
import React, { useMemo } from 'react';
import { DatePicker, DateTimePicker, NumberField, TextField, TimePicker } from '.';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'inline-flex',
        marginRight: 16,
        marginTop: 4,
        marginBottom: 4,
    }
}));

const EditableField = ({ fieldName, component, defaultValue, position, rowData, title, visible, _type, _value, _dispatch }) => {
    const classes = useStyles();

    const content = useMemo(() => {
        if (component) {
            const setState = (value) => _dispatch(fieldName, value);
            return component({ setState, dispatch: _dispatch, value: _value, title, rowData });
        } else {
            switch (_type) {
                default:
                case 'VARCHAR':
                case 'VAR_STRING':
                case 'STRING':
                    return (
                        <TextField
                            label={title}
                            value={_value}
                            onChange={value => _dispatch(fieldName, value)}
                            field={fieldName}
                        />
                    )
                case 'DECIMAL':
                case 'NEWDECIMAL':
                case 'LONG':
                case 'LONGLONG':
                case 'INT24':
                case 'FLOAT':
                case 'DOUBLE':
                case 'TINY':
                    return (
                        <NumberField
                            label={title}
                            value={_value}
                            onChange={value => _dispatch(fieldName, value)}
                            field={fieldName}
                        />
                    )
                case 'DATETIME':
                case 'DATETIME2':
                    return (
                        <DateTimePicker
                            label={title}
                            value={_value}
                            onChange={value => _dispatch(fieldName, value)}
                            field={fieldName}
                        />
                    )
                case 'DATE':
                case 'NEWDATE':
                    return (
                        <DatePicker
                            label={title}
                            value={_value}
                            onChange={value => _dispatch(fieldName, value)}
                            field={fieldName}
                        />
                    )
                case 'TIME':
                case 'TIME2':
                    return (
                        <TimePicker
                            label={title}
                            value={_value}
                            onChange={value => _dispatch(fieldName, value)}
                            field={fieldName}
                        />
                    )
            }
        }
    }, [component, fieldName, title, _dispatch, _type, _value, rowData]);
    
    return (visible &&
        <div className={classes.container}>
            {content}
        </div>
    );
}

EditableField.defaultProps = {
    visible: true,
}

export default EditableField;