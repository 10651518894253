import React from 'react';
import moment from 'moment';
import { Document, StyleSheet, Text, View } from '@react-pdf/renderer';
import { PdfPage } from '../component';
import { numberFormat } from '../common/Utils';
import { Fragment } from 'react';

const styles = StyleSheet.create({
    title: {
        fontSize : 13,
        textAlign : 'center',
    },
    bottom: {
        position: 'absolute',
        bottom: 13,
        left: 25,
        right: 25,
    },
})


const InOutBondPayPrintPdf = ({pdfData2, loginState, workName, condition}) => {

    return (
        <Document>
            {pdfData2 && pdfData2.map((item, idx) => {

            return ( 
        <Fragment key={idx}>
            {/* 고객 보관용 */}
            <PdfPage >
                <Text style={{ ...styles.title, textDecoration: 'underline'}}>매입 / 매출 채무 및 채권 확인 조회서</Text>
                <View style={{height: 10}}>

                </View> 
                <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10}}>
                    <Text style={{textDecoration: 'underline'}}>{item['CST_NM']}                                                                                귀하</Text>
                    <Text style={{fontSize: 8}}>[고객 보관용]</Text> 
                </View>
               <View>
                    <Text style={{marginBottom: 3}}> 
                        귀사의 번창하심을 축원 하오며, 항상 각별하신 애호와 협조에 감사합니다.
                     </Text>
                    <Text style={{lineHeight: 1.3}}>  
                        바쁘실줄 아오나 당사의 회계 감사에 필요한 {moment(condition['BIZ_DATE']).format('YYYY년 MM월 DD일')} 현재의 귀사에 대한 당시의 채무 및 채권 잔액과 내용을 확인하고자하오니
                        귀사의 장부와 대조 확인 하시고 그 상이 유무를 아래 확인 조회 통지서란에 금액과 내용을 기입, 서명 날인하여 첨부한 부분을 즉시 
                        당사의 감사인인 {item['ACC_INFO']} 앞으로 직접 우송하여 주시기 바랍니다.</Text>
                    <Text style={{marginBottom: 3, lineHeight: 1.3}}>  
                        감사인의 주소가 기입된 회신용 봉투를 동봉 합니다. {"\n"}
                        본 조회 내용은 공인 회계사법 제 2 0 조 및 주식회사의 외부 감사에 관한 법률 제 9 조에 의거 그 비밀이 보장되고 회계 감사 목적으로만 이용될 것입니다.
                    </Text>
                    <View style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                        <Text>{item['WORK_NM']}</Text>
                        <Text>{item['ACC_INFO']}      (인)</Text>
                    </View>
               </View>
               <View style={{height: 10}}>
                
                </View>
                
                <Text>
                    1 . 당사가 지급할 금액 ( 귀사가 당사로부터 받아야 할 금액 )
                </Text>
                <View style={{border: '1px solid black', margin: '10 0 10 0'}}>
                    <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                        <Text style={{flex: 1, textAlign: 'left', padding: '4'}}>계  정  과  목</Text>
                        <Text style={{flex: 1.5, textAlign: 'left', padding: '4'}}>적        요</Text>
                        <Text style={{flex: 1, textAlign: 'right', padding: '4'}}>금        액</Text>
                        <Text style={{flex: 1, textAlign: 'left', padding: '4'}}>비        고</Text>
                    </View>
                    <View style={{borderBottom: '1px solid black'}}></View>
                    <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                        <Text style={{flex: 1, textAlign: 'left', padding: '4'}}>외상매입금</Text>
                        <Text style={{flex: 1.5, textAlign: 'left', padding: '4'}}>외상 상품 매입 채무 잔액</Text>
                        <Text style={{flex: 1, textAlign: 'right', padding: '4'}}>{numberFormat(item['ACC_PAY'])}</Text>
                        <Text style={{flex: 1, textAlign: 'left', padding: '4'}}>당사 상품 매입 내역</Text>
                    </View>
                    <View style={{borderBottom: '1px solid black'}}></View>
                    <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                        <Text style={{flex: 1, textAlign: 'left', padding: '4'}}>미지급금</Text>
                        <Text style={{flex: 1.5, textAlign: 'left', padding: '4'}}>외상 비상품 매입 채무 잔액</Text>
                        <Text style={{flex: 1, textAlign: 'right', padding: '4'}}>{numberFormat(item['ACC_PAY_NON'])}</Text>
                        <Text style={{flex: 1, textAlign: 'left', padding: '4'}}>당사 서비스 기타 매입 내역</Text>
                    </View>
                    <View style={{borderBottom: '1px solid black'}}></View>
                    <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                        <Text style={{flex: 1, textAlign: 'left', padding: '4'}}>기타 채무</Text>
                        <Text style={{flex: 1.5, textAlign: 'left', padding: '4'}}>기타 자산 및 어음 / 수표 미지급 잔액</Text>
                        <Text style={{flex: 1, textAlign: 'right', padding: '4'}}>{numberFormat(item['ACC_PAY_ETC'])}</Text>
                        <Text style={{flex: 1, textAlign: 'left', padding: '4'}}>당사 비매입 내역</Text>
                    </View>
                    <View style={{borderBottom: '1px solid black'}}></View>
                    <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                        <Text style={{flex: 2.5, padding: '4', textAlign: 'center'}}>합        계</Text>
                        <Text style={{flex: 1, padding: '4', textAlign: 'right'}}>{numberFormat(item['ACC_PAY_SUM'])}</Text>
                        <Text style={{flex: 1, padding: '4'}}></Text>
                    </View>
                </View>
                
                <Text>
                    2 . 당사가 받을 금액 ( 귀사가 당사로부터 지급해야 할 금액 )
                </Text>
                <View style={{border: '1px solid black', margin: '10 0 10 0'}}>
                    <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                        <Text style={{flex: 1, textAlign: 'left', padding: '4'}}>계  정  과  목</Text>
                        <Text style={{flex: 1.5, textAlign: 'left', padding: '4'}}>적        요</Text>
                        <Text style={{flex: 1, textAlign: 'right', padding: '4'}}>금        액</Text>
                        <Text style={{flex: 1, textAlign: 'left', padding: '4'}}>비        고</Text>
                    </View>
                    <View style={{borderBottom: '1px solid black'}}></View>
                    <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                        <Text style={{flex: 1, textAlign: 'left', padding: '4'}}>외상매출금</Text>
                        <Text style={{flex: 1.5, textAlign: 'left', padding: '4'}}>외상 상품 매출 채권 잔액</Text>
                        <Text style={{flex: 1, textAlign: 'right', padding: '4'}}>{numberFormat(item['ACC_REC'])}</Text>
                        <Text style={{flex: 1, textAlign: 'left', padding: '4'}}>당사 상품 매출 내역</Text>
                    </View>
                    <View style={{borderBottom: '1px solid black'}}></View>
                    <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                        <Text style={{flex: 1, textAlign: 'left', padding: '4'}}>미수금</Text>
                        <Text style={{flex: 1.5, textAlign: 'left', padding: '4'}}>외상 비상품 매출 채권 잔액</Text>
                        <Text style={{flex: 1, textAlign: 'right', padding: '4'}}>{numberFormat(item['ACC_REC_NON'])}</Text>
                        <Text style={{flex: 1, textAlign: 'left', padding: '4'}}>당사 서비스 기타 매출 내역</Text>
                    </View>
                    <View style={{borderBottom: '1px solid black'}}></View>
                    <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                        <Text style={{flex: 1, textAlign: 'left', padding: '4'}}>기타 채권</Text>
                        <Text style={{flex: 1.5, textAlign: 'left', padding: '4'}}>기타 자산 및 어음 / 수표 미수금 잔액</Text>
                        <Text style={{flex: 1, textAlign: 'right', padding: '4'}}>{numberFormat(item['ACC_REC_ETC'])}</Text>
                        <Text style={{flex: 1, textAlign: 'left', padding: '4'}}>당사 비매출 내역</Text>
                    </View>
                    <View style={{borderBottom: '1px solid black'}}></View>
                    <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                        <Text style={{flex: 2.5, padding: '4', textAlign: 'center'}}>합        계</Text>
                        <Text style={{flex: 1, padding: '4', textAlign: 'right'}}>{numberFormat(item['ACC_REC_SUM'])}</Text>
                        <Text style={{flex: 1, padding: '4'}}></Text>
                    </View>
                </View>

                <View style={{borderBottom: '4px dashed black', marginBottom: 10}}></View>
                <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                    <Text style={{fontSize: 8}}>[고객 보관용]</Text> 
                </View>
                <Text style={{ ...styles.title, textDecoration: 'underline'}}>확인 조회 통지서</Text>
                <View style={{height: 10}}>

                </View> 
                <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10}}>
                    <Text style={{textDecoration: 'underline'}}>{item['WORK_NM']}                                                                                귀중</Text>
                    <Text>조회 기준일 : {moment(condition['BIZ_DATE']).format('YYYY년 MM월 DD일')}</Text> 
                </View>
                <View>
                    <Text style={{marginBottom: 3}}>
                        1.  위 확인 조회서에 기재된 금액과 내용은 당사 장부와 일치하고 있으므로 이에 서명 날인하여 통지합니다.
                    </Text>
                    <Text style={{lineHeight: 1.3}}>
                        2.  아래와 같은 차이점이 있음을 알려드립니다. ( 계정과목, 매입/ 매출일, 내용, 금액을 기재하여 주시고 지면이 부족할경우에는
                        별지를 첨부하여 주시기 바랍니다. ) 단, 상이점이 없고 일치할 때는 " 차이 없고 일치함 " 으로 기재 부탁합니다.
                    </Text>
                </View>
                <View style={{borderBottom: '1px solid black', height: 14}}></View>
                <View style={{borderBottom: '1px solid black', height: 14}}></View>
                <View style={{borderBottom: '1px solid black', height: 14}}></View>
                <View style={{borderBottom: '1px solid black', height: 14, marginBottom: 10}}></View>

                <View style={{width: '100%', display: 'flex',  flexDirection: 'row' }}>
                    <View style={{width: '50%', height: 100, display: 'flex', flexDirection: 'column', fontSize: '8'}}>
                        <Text>조회서 확인일:                            년             월             일</Text>
                        <Text style={{marginTop: 50, textDecoration: 'underline', fontSize: '8'}}>거래처 코드 : {item['CST_CD']}</Text>
                        <Text style={{marginTop: 10, textDecoration: 'underline', fontSize: '8'}}>거래처명: {item['CST_NM']}</Text>
                    </View>
                    <View style={{width: '50%'}}>
                        <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                            <Text style={{width: '30%', textAlign: 'center', padding: '4', fontSize: '8'}}>회사 또는 기관명</Text>
                            <Text style={{width: '10%', textAlign: 'center', padding: '4', fontSize: '8'}}>:</Text>
                            <Text style={{width: '60%', textAlign: 'left', padding: '4', fontSize: '8'}}>{item['CST_NM']}</Text>
                        </View>
                        <View style={{borderBottom: '1px solid black'}}></View>
                        <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                            <Text style={{width: '30%', textAlign: 'center', padding: '4', fontSize: '8'}}>주                     소</Text>
                            <Text style={{width: '10%', textAlign: 'center', padding: '4', fontSize: '8'}}>:</Text>
                            <Text style={{width: '60%', textAlign: 'left', padding: '4', fontSize: '8'}}>{item['CST_ADDR']}</Text>
                        </View>
                        <View style={{borderBottom: '1px solid black'}}></View>
                        <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                            <Text style={{width: '30%', textAlign: 'center', padding: '4', fontSize: '8'}}>대    표    자    명</Text>
                            <Text style={{width: '10%', textAlign: 'center', padding: '4', fontSize: '8'}}>:</Text>
                            <Text style={{width: '50%', textAlign: 'left', padding: '4', fontSize: '8'}}>{item['CEO']}</Text>
                            <Text style={{width: '10%', textAlign: 'right', padding: '4', fontSize: '8'}}>(인)</Text>
                        </View>
                        <View style={{borderBottom: '1px solid black'}}></View>
                        <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                            <Text style={{width: '30%', textAlign: 'center', padding: '4', fontSize: '8'}}>사 업 자 등록번호</Text>
                            <Text style={{width: '10%', textAlign: 'center', padding: '4', fontSize: '8'}}>:</Text>
                            <Text style={{width: '60%', textAlign: 'left', padding: '4', fontSize: '8'}}>{item['WORK_BIZNO']}</Text>
                        </View>
                        <View style={{borderBottom: '1px solid black'}}></View>
                        <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                            <Text style={{width: '30%', textAlign: 'center', padding: '4', fontSize: '8'}}>담   당   자   확   인</Text>
                            <Text style={{width: '10%', textAlign: 'center', padding: '4', fontSize: '8'}}>:</Text>
                            <Text style={{width: '40%', textAlign: 'left', padding: '4', fontSize: '8'}}></Text>
                            <Text style={{width: '20%', textAlign: 'right', padding: '4', fontSize: '8'}}>( 인/서명 )</Text>
                        </View>
                        <View style={{borderBottom: '1px solid black'}}></View>
                    </View>
                </View>
                <View style={{width: '50%'}}>
                    <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                        <Text style={{width: '15%', textAlign: 'left', padding: '4', fontSize: '8'}}>연 락 처</Text>
                        <Text style={{width: '10%', textAlign: 'center', padding: '4', fontSize: '8'}}>:</Text>
                        <Text style={{width: '85%', textAlign: 'left', padding: '4', fontSize: '8'}}>{item['WORK_TELNO1']}</Text>
                    </View>
                    
                    <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                        <Text style={{width: '15%', textAlign: 'left', padding: '4', fontSize: '8'}}>주     소</Text>
                        <Text style={{width: '10%', textAlign: 'center', padding: '4', fontSize: '8'}}>:</Text>
                        <Text style={{width: '85%', textAlign: 'left', padding: '4', fontSize: '8'}}>{item['WORK_ADDR']} {item['WORK_NM']}</Text>
                    </View>
                    
                    <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                        <Text style={{width: '15%', textAlign: 'left', padding: '4', fontSize: '8'}}></Text>
                        <Text style={{width: '10%', textAlign: 'center', padding: '4', fontSize: '8'}}></Text>
                        <Text style={{width: '85%', textAlign: 'left', padding: '4', fontSize: '8'}}>{item['ACC_INFO']}</Text>
                    </View>
                          
                </View>
                

                
                <Text style={{ ...styles.bottom, textAlign: 'left', fontSize: '8' }} fixed>
                {workName} / {loginState['EMP_NM']} / {moment().format('YYYY-MM-DD hh:mm:ss')}
                </Text>
            </PdfPage>
            
            {/* 회사보관용 */}
            <PdfPage >
                <Text style={{ ...styles.title, textDecoration: 'underline'}}>매입 / 매출 채무 및 채권 확인 조회서</Text>
                <View style={{height: 10}}>

                </View> 
                <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10}}>
                    <Text style={{textDecoration: 'underline'}}>{item['CST_NM']}                                                                                귀하</Text>
                    <Text style={{fontSize: 8}}>[회사 보관용]</Text> 
                </View>
               <View>
                    <Text style={{marginBottom: 3}}> 
                        귀사의 번창하심을 축원 하오며, 항상 각별하신 애호와 협조에 감사합니다.
                     </Text>
                    <Text style={{lineHeight: 1.3}}>  
                        바쁘실줄 아오나 당사의 회계 감사에 필요한 {moment(condition['BIZ_DATE']).format('YYYY년 MM월 DD일')} 현재의 귀사에 대한 당시의 채무 및 채권 잔액과 내용을 확인하고자하오니
                        귀사의 장부와 대조 확인 하시고 그 상이 유무를 아래 확인 조회 통지서란에 금액과 내용을 기입, 서명 날인하여 첨부한 부분을 즉시 
                        당사의 감사인인 {item['ACC_INFO']} 앞으로 직접 우송하여 주시기 바랍니다.</Text>
                    <Text style={{marginBottom: 3, lineHeight: 1.3}}>  
                        감사인의 주소가 기입된 회신용 봉투를 동봉 합니다. {"\n"}
                        본 조회 내용은 공인 회계사법 제 2 0 조 및 주식회사의 외부 감사에 관한 법률 제 9 조에 의거 그 비밀이 보장되고 회계 감사 목적으로만 이용될 것입니다.
                    </Text>
                    <View style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                        <Text>{item['WORK_NM']}</Text>
                        <Text>{item['ACC_INFO']}</Text>
                    </View>
               </View>
               <View style={{height: 10}}>
                
                </View>
                
                <Text>
                    1 . 당사가 지급할 금액 ( 귀사가 당사로부터 받아야 할 금액 )
                </Text>
                <View style={{border: '1px solid black', margin: '10 0 10 0'}}>
                    <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                        <Text style={{flex: 1, textAlign: 'left', padding: '4'}}>계  정  과  목</Text>
                        <Text style={{flex: 1.5, textAlign: 'left', padding: '4'}}>적        요</Text>
                        <Text style={{flex: 1, textAlign: 'right', padding: '4'}}>금        액</Text>
                        <Text style={{flex: 1, textAlign: 'left', padding: '4'}}>비        고</Text>
                    </View>
                    <View style={{borderBottom: '1px solid black'}}></View>
                    <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                        <Text style={{flex: 1, textAlign: 'left', padding: '4'}}>외상매입금</Text>
                        <Text style={{flex: 1.5, textAlign: 'left', padding: '4'}}>외상 상품 매입 채무 잔액</Text>
                        <Text style={{flex: 1, textAlign: 'right', padding: '4'}}>{numberFormat(item['ACC_PAY'])}</Text>
                        <Text style={{flex: 1, textAlign: 'left', padding: '4'}}>당사 상품 매입 내역</Text>
                    </View>
                    <View style={{borderBottom: '1px solid black'}}></View>
                    <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                        <Text style={{flex: 1, textAlign: 'left', padding: '4'}}>미지급금</Text>
                        <Text style={{flex: 1.5, textAlign: 'left', padding: '4'}}>외상 비상품 매입 채무 잔액</Text>
                        <Text style={{flex: 1, textAlign: 'right', padding: '4'}}>{numberFormat(item['ACC_PAY_NON'])}</Text>
                        <Text style={{flex: 1, textAlign: 'left', padding: '4'}}>당사 서비스 기타 매입 내역</Text>
                    </View>
                    <View style={{borderBottom: '1px solid black'}}></View>
                    <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                        <Text style={{flex: 1, textAlign: 'left', padding: '4'}}>기타 채무</Text>
                        <Text style={{flex: 1.5, textAlign: 'left', padding: '4'}}>기타 자산 및 어음 / 수표 미지급 잔액</Text>
                        <Text style={{flex: 1, textAlign: 'right', padding: '4'}}>{numberFormat(item['ACC_PAY_ETC'])}</Text>
                        <Text style={{flex: 1, textAlign: 'left', padding: '4'}}>당사 비매입 내역</Text>
                    </View>
                    <View style={{borderBottom: '1px solid black'}}></View>
                    <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                        <Text style={{flex: 2.5, padding: '4', textAlign: 'center'}}>합        계</Text>
                        <Text style={{flex: 1, padding: '4', textAlign: 'right'}}>{numberFormat(item['ACC_PAY_SUM'])}</Text>
                        <Text style={{flex: 1, padding: '4'}}></Text>
                    </View>
                </View>
                
                <Text>
                    2 . 당사가 받을 금액 ( 귀사가 당사로부터 지급해야 할 금액 )
                </Text>
                <View style={{border: '1px solid black', margin: '10 0 10 0'}}>
                    <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                        <Text style={{flex: 1, textAlign: 'left', padding: '4'}}>계  정  과  목</Text>
                        <Text style={{flex: 1.5, textAlign: 'left', padding: '4'}}>적        요</Text>
                        <Text style={{flex: 1, textAlign: 'right', padding: '4'}}>금        액</Text>
                        <Text style={{flex: 1, textAlign: 'left', padding: '4'}}>비        고</Text>
                    </View>
                    <View style={{borderBottom: '1px solid black'}}></View>
                    <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                        <Text style={{flex: 1, textAlign: 'left', padding: '4'}}>외상매출금</Text>
                        <Text style={{flex: 1.5, textAlign: 'left', padding: '4'}}>외상 상품 매출 채권 잔액</Text>
                        <Text style={{flex: 1, textAlign: 'right', padding: '4'}}>{numberFormat(item['ACC_REC'])}</Text>
                        <Text style={{flex: 1, textAlign: 'left', padding: '4'}}>당사 상품 매출 내역</Text>
                    </View>
                    <View style={{borderBottom: '1px solid black'}}></View>
                    <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                        <Text style={{flex: 1, textAlign: 'left', padding: '4'}}>미수금</Text>
                        <Text style={{flex: 1.5, textAlign: 'left', padding: '4'}}>외상 비상품 매출 채권 잔액</Text>
                        <Text style={{flex: 1, textAlign: 'right', padding: '4'}}>{numberFormat(item['ACC_REC_NON'])}</Text>
                        <Text style={{flex: 1, textAlign: 'left', padding: '4'}}>당사 서비스 기타 매출 내역</Text>
                    </View>
                    <View style={{borderBottom: '1px solid black'}}></View>
                    <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                        <Text style={{flex: 1, textAlign: 'left', padding: '4'}}>기타 채권</Text>
                        <Text style={{flex: 1.5, textAlign: 'left', padding: '4'}}>기타 자산 및 어음 / 수표 미수금 잔액</Text>
                        <Text style={{flex: 1, textAlign: 'right', padding: '4'}}>{numberFormat(item['ACC_REC_ETC'])}</Text>
                        <Text style={{flex: 1, textAlign: 'left', padding: '4'}}>당사 비매출 내역</Text>
                    </View>
                    <View style={{borderBottom: '1px solid black'}}></View>
                    <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                        <Text style={{flex: 2.5, padding: '4', textAlign: 'center'}}>합        계</Text>
                        <Text style={{flex: 1, padding: '4', textAlign: 'right'}}>{numberFormat(item['ACC_REC_SUM'])}</Text>
                        <Text style={{flex: 1, padding: '4'}}></Text>
                    </View>
                </View>

                <View style={{borderBottom: '4px dashed black', marginBottom: 10}}></View>
                <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                    <Text style={{fontSize: 8}}>[회사 보관용]</Text> 
                </View>
                <Text style={{ ...styles.title, textDecoration: 'underline'}}>확인 조회 통지서</Text>
                <View style={{height: 10}}>

                </View> 
                <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10}}>
                    <Text style={{textDecoration: 'underline'}}>{item['WORK_NM']}                                                                                귀중</Text>
                    <Text>조회 기준일 : {moment(condition['BIZ_DATE']).format('YYYY년 MM월 DD일')}</Text> 
                </View>
                <View>
                    <Text style={{marginBottom: 3}}>
                        1.  위 확인 조회서에 기재된 금액과 내용은 당사 장부와 일치하고 있으므로 이에 서명 날인하여 통지합니다.
                    </Text>
                    <Text style={{lineHeight: 1.3}}>
                        2.  아래와 같은 차이점이 있음을 알려드립니다. ( 계정과목, 매입/ 매출일, 내용, 금액을 기재하여 주시고 지면이 부족할경우에는
                        별지를 첨부하여 주시기 바랍니다. ) 단, 상이점이 없고 일치할 때는 " 차이 없고 일치함 " 으로 기재 부탁합니다.
                    </Text>
                </View>
                <View style={{borderBottom: '1px solid black', height: 14}}></View>
                <View style={{borderBottom: '1px solid black', height: 14}}></View>
                <View style={{borderBottom: '1px solid black', height: 14}}></View>
                <View style={{borderBottom: '1px solid black', height: 14, marginBottom: 10}}></View>

                <View style={{width: '100%', display: 'flex',  flexDirection: 'row' }}>
                    <View style={{width: '50%', height: 100, display: 'flex', flexDirection: 'column', fontSize: '8'}}>
                        <Text>조회서 확인일:                            년             월             일</Text>
                        <Text style={{marginTop: 50, textDecoration: 'underline', fontSize: '8'}}>거래처 코드 : {item['CST_CD']}</Text>
                        <Text style={{marginTop: 10, textDecoration: 'underline', fontSize: '8'}}>거래처명: {item['CST_NM']}</Text>
                    </View>
                    <View style={{width: '50%'}}>
                        <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                            <Text style={{width: '30%', textAlign: 'center', padding: '4', fontSize: '8'}}>회사 또는 기관명</Text>
                            <Text style={{width: '10%', textAlign: 'center', padding: '4', fontSize: '8'}}>:</Text>
                            <Text style={{width: '60%', textAlign: 'left', padding: '4', fontSize: '8'}}>{item['CST_NM']}</Text>
                        </View>
                        <View style={{borderBottom: '1px solid black'}}></View>
                        <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                            <Text style={{width: '30%', textAlign: 'center', padding: '4', fontSize: '8'}}>주                     소</Text>
                            <Text style={{width: '10%', textAlign: 'center', padding: '4', fontSize: '8'}}>:</Text>
                            <Text style={{width: '60%', textAlign: 'left', padding: '4', fontSize: '8'}}>{item['CST_ADDR']}</Text>
                        </View>
                        <View style={{borderBottom: '1px solid black'}}></View>
                        <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                            <Text style={{width: '30%', textAlign: 'center', padding: '4', fontSize: '8'}}>대    표    자    명</Text>
                            <Text style={{width: '10%', textAlign: 'center', padding: '4', fontSize: '8'}}>:</Text>
                            <Text style={{width: '50%', textAlign: 'left', padding: '4', fontSize: '8'}}>{item['CEO']}</Text>
                            <Text style={{width: '10%', textAlign: 'right', padding: '4', fontSize: '8'}}>(인)</Text>
                        </View>
                        <View style={{borderBottom: '1px solid black'}}></View>
                        <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                            <Text style={{width: '30%', textAlign: 'center', padding: '4', fontSize: '8'}}>사 업 자 등록번호</Text>
                            <Text style={{width: '10%', textAlign: 'center', padding: '4', fontSize: '8'}}>:</Text>
                            <Text style={{width: '60%', textAlign: 'left', padding: '4', fontSize: '8'}}>{item['WORK_BIZNO']}</Text>
                        </View>
                        <View style={{borderBottom: '1px solid black'}}></View>
                        <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                            <Text style={{width: '30%', textAlign: 'center', padding: '4', fontSize: '8'}}>담   당   자   확   인</Text>
                            <Text style={{width: '10%', textAlign: 'center', padding: '4', fontSize: '8'}}>:</Text>
                            <Text style={{width: '40%', textAlign: 'left', padding: '4', fontSize: '8'}}></Text>
                            <Text style={{width: '20%', textAlign: 'right', padding: '4', fontSize: '8'}}>( 인/서명 )</Text>
                        </View>
                        <View style={{borderBottom: '1px solid black'}}></View>
                    </View>
                </View>
                <View style={{width: '50%'}}>
                    <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                        <Text style={{width: '15%', textAlign: 'left', padding: '4', fontSize: '8'}}>연 락 처</Text>
                        <Text style={{width: '10%', textAlign: 'center', padding: '4', fontSize: '8'}}>:</Text>
                        <Text style={{width: '85%', textAlign: 'left', padding: '4', fontSize: '8'}}>{item['WORK_TELNO1']}</Text>
                    </View>
                    
                    <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                        <Text style={{width: '15%', textAlign: 'left', padding: '4', fontSize: '8'}}>주     소</Text>
                        <Text style={{width: '10%', textAlign: 'center', padding: '4', fontSize: '8'}}>:</Text>
                        <Text style={{width: '85%', textAlign: 'left', padding: '4', fontSize: '8'}}>{item['WORK_ADDR']} {item['WORK_NM']}</Text>
                    </View>
                    
                    <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                        <Text style={{width: '15%', textAlign: 'left', padding: '4', fontSize: '8'}}></Text>
                        <Text style={{width: '10%', textAlign: 'center', padding: '4', fontSize: '8'}}></Text>
                        <Text style={{width: '85%', textAlign: 'left', padding: '4', fontSize: '8'}}>{item['ACC_INFO']}</Text>
                    </View>
                          
                </View>
                

                
                <Text style={{ ...styles.bottom, textAlign: 'left', fontSize: '8' }} fixed>
                {workName} / {loginState['EMP_NM']} / {moment().format('YYYY-MM-DD hh:mm:ss')}
                </Text>
            </PdfPage>
          
            
        </Fragment> 
            


            )})}
        </Document>
    )
}


export default InOutBondPayPrintPdf;
