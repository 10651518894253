import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, LineChart, Line, ResponsiveContainer } from 'recharts';
import { Grid, Paper, makeStyles, Typography } from '@material-ui/core';
import { DataColumn, SmallGrid, ProgressPanel } from '../../component';
import { callProc } from '../../common/DBConnector';
import { numberFormat } from '../../common/Utils';

const COLORS = ['#8884d8', '#82CA9D', '#DCAE36', '#EA5985'];

const useStyles = makeStyles(theme => ({
    gridItem: {
        display: 'flex',
    },
    paper: {
        flex: 1,
        padding: theme.spacing(2),
    },
    paperTitle: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    content: {
        display: 'flex',
    },
    errorMsg : {
        whiteSpace: 'nowrap',
        padding: theme.spacing(2),
    },
    splitCell : {
        padding : 0
    },
}))

const SaleReport = () => {
    const [yearlyData, setYearlyData] = useState();
    const [prevMonthlyData, setPrevMonthlyData] = useState();
    const [dailyData, setDailyData] = useState();

    useEffect(() =>{
        const fetchData = async() => {
            const yearlyData = (await callProc('SLT_SALEINFO_GOODS_YEARLY', {
                YEAR : moment().year()
            })).data;

            setYearlyData(Object.values(yearlyData.reduce((result, item) => ({
                ...result,
                [item['MONTH']] : {
                    ...result[item['MONTH']],
                    MONTH : item['MONTH'],
                    [item['GDS_NM']] : item['AMOUNT_SUM']
                }
            }), {})));
            setPrevMonthlyData((await callProc('SLT_SALEINFO_GOODS_MONTHLY_DAY', {
                DATE : moment().add(-1, 'day').format('YYYY-MM-DD')
            })).data);
            setDailyData((await callProc('SLT_SALEINFO_GOODS_DAILY', {
                DATE : moment().add(-1, 'day').format('YYYY-MM-DD')
            })).data);
        }
        fetchData();
    }, []);

    const classes = useStyles();

    return (
        (!yearlyData || !prevMonthlyData || !dailyData) ? <ProgressPanel /> :
        <Grid container style={{ height: 'fit-content' }} spacing={2}>
            <Grid item xs={6} className={classes.gridItem}>
            <Paper className={classes.paper}>
                <div className={classes.paperTitle}>
                    <Typography component="h2" variant="h6" gutterBottom>
                        월별
                    </Typography>
                    <Typography component="h2" variant="h6" gutterBottom>
                        {moment().format('YYYY년 MM월')}
                    </Typography>
                </div>
                <div className={classes.content}>
                    <SmallGrid
                        selectProc='SLT_SALEINFO_GOODS_MONTHLY'
                        selectParam={{ DATE : moment().format('YYYY-MM-DD') }}
                        headerLess
                    >
                        <DataColumn
                            fieldName='BEF_QTY_SUM_L'
                            visible={false}
                        />
                        <DataColumn
                            fieldName='BEF_QTY_SUM_D'
                            visible={false}
                        />
                        <DataColumn
                            fieldName='BEF_AMOUNT_SUM'
                            visible={false}
                        />
                        <DataColumn
                            fieldName='QTY_SUM_L'
                            title='수량(L)'
                            total={(values) => values.reduce((result, value) => result + value)}
                        />
                        <DataColumn
                            fieldName='QTY_SUM_D'
                            title='수량(Drum)'
                            total={(values) => values.reduce((result, value) => result + value)}
                        />
                        <DataColumn
                            fieldName='AMOUNT_SUM'
                            title='매출'
                            total={(values) => values.reduce((result, value) => result + value)}
                        />
                        <DataColumn
                            fieldName='QTY_GAP_PER'
                            visible={false}
                        />
                    </SmallGrid>
                </div>
            </Paper>
            </Grid>


            <Grid item xs={6} className={classes.gridItem}>
            <Paper className={classes.paper}>
                <Typography component="h2" variant="h6" gutterBottom>
                    올해 매출
                </Typography>
                <div className={classes.content}>
                    <ResponsiveContainer width='100%' height={200}>
                        <LineChart
                            data={yearlyData}
                            margin={{
                            top: 5, right: 30, left: 30, bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="MONTH" unit='월' />
                            <YAxis tickFormatter={(value) => numberFormat(value)} />
                            <Tooltip
                                labelFormatter={(value) => value + '월'}
                                formatter={(value) => numberFormat(value)}
                            />
                            <Legend />
                            {Object.keys(yearlyData[0]).map((item, idx) =>(
                                item !== 'MONTH' &&
                                <Line
                                    type='monotone'
                                    dataKey={item}
                                    stroke={COLORS[idx % COLORS.length]}
                                    activeDot={{ r: 8 }}
                                />
                            ))}
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </Paper>
            </Grid>


            <Grid item xs={12} className={classes.gridItem}>
            <Paper className={classes.paper}>
                <Typography component="h2" variant="h6" gutterBottom>
                    월별
                </Typography>
                <div className={classes.content}>
                    {!prevMonthlyData.length ?
                    <text className={classes.errorMsg}>데이터가 존재하지 않습니다.</text> :
                    <BarChart
                        width={500}
                        height={200}
                        data={prevMonthlyData}
                        margin={{
                        top: 5, right: 30, left: 30, bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="GDS_NM" />
                        <YAxis tickFormatter={(value) => numberFormat(value)} />
                        <Tooltip formatter={(value) => numberFormat(value)} />
                        <Legend />
                        <Bar
                            dataKey="BEF_AMOUNT_SUM2"
                            name={`${moment().add(-2, 'month').month() + 1}월매출`}
                            fill={COLORS[2]}
                        />
                        <Bar
                            dataKey="BEF_AMOUNT_SUM"
                            name={`${moment().add(-1, 'month').month() + 1}월매출`}
                            fill={COLORS[1]}
                        />
                        <Bar
                            dataKey="AMOUNT_SUM"
                            name={`${moment().month() + 1}월매출`}
                            fill={COLORS[0]}
                        />
                    </BarChart>}
                    <SmallGrid
                        selectProc='SLT_SALEINFO_GOODS_MONTHLY_DAY'
                        selectParam={{ DATE : moment().add(-1, 'day').format('YYYY-MM-DD') }}
                        headerLess
                    >
                        <DataColumn
                            fieldName='BEF_QTY_SUM_L2'
                            title={`${moment().add(-2, 'month').month() + 1}월수량(L)`}
                            total={(values) => values.reduce((result, value) => result + value, 0)}
                        />
                        <DataColumn
                            fieldName='BEF_QTY_SUM_D2'
                            title={`${moment().add(-2, 'month').month() + 1}월수량(Drum)`}
                            total={(values) => values.reduce((result, value) => result + value, 0)}
                        />
                        <DataColumn
                            fieldName='BEF_AMOUNT_SUM2'
                            title={`${moment().add(-2, 'month').month() + 1}월매출`}
                            total={(values) => values.reduce((result, value) => result + value, 0)}
                        />
                        <DataColumn
                            fieldName='BEF_QTY_SUM_L'
                            title={`${moment().add(-1, 'month').month() + 1}월수량(L)`}
                            total={(values) => values.reduce((result, value) => result + value, 0)}
                        />
                        <DataColumn
                            fieldName='BEF_QTY_SUM_D'
                            title={`${moment().add(-1, 'month').month() + 1}월수량(Drum)`}
                            total={(values) => values.reduce((result, value) => result + value, 0)}
                        />
                        <DataColumn
                            fieldName='BEF_AMOUNT_SUM'
                            title={`${moment().add(-1, 'month').month() + 1}월매출`}
                            total={(values) => values.reduce((result, value) => result + value, 0)}
                        />
                        <DataColumn
                            fieldName='QTY_SUM_L'
                            title={`${moment().month() + 1}월수량(L)`}
                            total={(values) => values.reduce((result, value) => result + value, 0)}
                        />
                        <DataColumn
                            fieldName='QTY_SUM_D'
                            title={`${moment().month() + 1}월수량(Drum)`}
                            total={(values) => values.reduce((result, value) => result + value, 0)}
                        />
                        <DataColumn
                            fieldName='AMOUNT_SUM'
                            title={`${moment().month() + 1}월매출`}
                            total={(values) => values.reduce((result, value) => result + value, 0)}
                        />
                        <DataColumn
                            fieldName='QTY_GAP_PER'
                            title='전월대비'
                            cellStyle={{ textAlign : 'end' }}
                        />
                    </SmallGrid>
                </div>
            </Paper>
            </Grid>


            <Grid item xs={12} className={classes.gridItem}>
            <Paper className={classes.paper}>
                <div className={classes.paperTitle}>
                    <Typography component="h2" variant="h6" gutterBottom>
                        일별
                    </Typography>
                    <Typography component="h2" variant="h6" gutterBottom>
                        {moment().add(-1, 'day').format('YYYY년 MM월 DD일')}
                    </Typography>
                </div>
                <div className={classes.content}>
                    {!dailyData.length ?
                    <text className={classes.errorMsg}>데이터가 존재하지 않습니다.</text> :
                    <BarChart
                        width={500}
                        height={200}
                        data={dailyData}
                        margin={{
                        top: 5, right: 30, left: 30, bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="GDS_NM" />
                        <YAxis tickFormatter={(value) => numberFormat(value)} />
                        <Tooltip formatter={(value) => numberFormat(value)} />
                        <Legend />
                        <Bar
                            dataKey="BEF_AMOUNT_SUM"
                            name='전일매출'
                            fill={COLORS[1]}
                        />
                        <Bar
                            dataKey="AMOUNT_SUM"
                            name='매출'
                            fill={COLORS[0]}
                        />
                    </BarChart>}
                    <SmallGrid
                        selectProc='SLT_SALEINFO_GOODS_DAILY'
                        selectParam={{ DATE : moment().add(-1, 'day').format('YYYY-MM-DD') }}
                        headerLess
                    >
                        <DataColumn
                            fieldName='BEF_QTY_SUM_L'
                            title='전일수량(L)'
                            total={(values) => values.reduce((result, value) => result + value, 0)}
                        />
                        <DataColumn
                            fieldName='BEF_QTY_SUM_D'
                            title='전일수량(Drum)'
                            total={(values) => values.reduce((result, value) => result + value, 0)}
                        />
                        <DataColumn
                            fieldName='BEF_AMOUNT_SUM'
                            title='전일매출'
                            total={(values) => values.reduce((result, value) => result + value, 0)}
                        />
                        <DataColumn
                            fieldName='QTY_SUM_L'
                            title='수량(L)'
                            total={(values) => values.reduce((result, value) => result + value, 0)}
                        />
                        <DataColumn
                            fieldName='QTY_SUM_D'
                            title='수량(Drum)'
                            total={(values) => values.reduce((result, value) => result + value, 0)}
                        />
                        <DataColumn
                            fieldName='AMOUNT_SUM'
                            title='매출'
                            total={(values) => values.reduce((result, value) => result + value, 0)}
                        />
                        <DataColumn
                            fieldName='QTY_GAP_PER'
                            title='전일대비'
                            cellStyle={{ textAlign : 'end' }}
                        />
                    </SmallGrid>
                </div>
            </Paper>
            </Grid>


            <Grid item xs={12} className={classes.gridItem}>
            <Paper className={classes.paper}>
                <Typography component="h2" variant="h6" gutterBottom>
                    월별
                </Typography>
                <div className={classes.content}>
                    <SmallGrid
                        selectProc='SLT_SALEINFO_WORK_GOODS_MONTHLY_DAY'
                        selectParam={{ DATE : moment().add(-1, 'day').format('YYYY-MM-DD') }}
                        headerLess
                    >
                        <DataColumn
                            fieldName='WORK_NM'
                            mergeField='WORK_NM'
                        />
                        <DataColumn
                            fieldName='BEF_QTY_SUM_L2'
                            title={`${moment().add(-2, 'month').month() + 1}월수량(L)`}
                            total={(values) => values.reduce((result, value) => result + value, 0)}
                        />
                        <DataColumn
                            fieldName='BEF_QTY_SUM_D2'
                            title={`${moment().add(-2, 'month').month() + 1}월수량(Drum)`}
                            total={(values) => values.reduce((result, value) => result + value, 0)}
                        />
                        <DataColumn
                            fieldName='BEF_AMOUNT_SUM2'
                            title={`${moment().add(-2, 'month').month() + 1}월매출`}
                            total={(values) => values.reduce((result, value) => result + value, 0)}
                        />
                        <DataColumn
                            fieldName='BEF_QTY_SUM_L'
                            title={`${moment().add(-1, 'month').month() + 1}월수량(L)`}
                            total={(values) => values.reduce((result, value) => result + value, 0)}
                        />
                        <DataColumn
                            fieldName='BEF_QTY_SUM_D'
                            title={`${moment().add(-1, 'month').month() + 1}월수량(Drum)`}
                            total={(values) => values.reduce((result, value) => result + value, 0)}
                        />
                        <DataColumn
                            fieldName='BEF_AMOUNT_SUM'
                            title={`${moment().add(-1, 'month').month() + 1}월매출`}
                            total={(values) => values.reduce((result, value) => result + value, 0)}
                        />
                        <DataColumn
                            fieldName='QTY_SUM_L'
                            title={`${moment().month() + 1}월수량(L)`}
                            total={(values) => values.reduce((result, value) => result + value, 0)}
                        />
                        <DataColumn
                            fieldName='QTY_SUM_D'
                            title={`${moment().month() + 1}월수량(Drum)`}
                            total={(values) => values.reduce((result, value) => result + value, 0)}
                        />
                        <DataColumn
                            fieldName='AMOUNT_SUM'
                            title={`${moment().month() + 1}월매출`}
                            total={(values) => values.reduce((result, value) => result + value, 0)}
                        />
                        <DataColumn
                            fieldName='QTY_GAP_PER'
                            title='전월대비'
                            cellStyle={{ textAlign : 'end' }}
                        />
                    </SmallGrid>
                </div>
            </Paper>
            </Grid>


            <Grid item xs={12} className={classes.gridItem}>
            <Paper className={classes.paper}>
                <div className={classes.paperTitle}>
                    <Typography component="h2" variant="h6" gutterBottom>
                        일별
                    </Typography>
                    <Typography component="h2" variant="h6" gutterBottom>
                        {moment().add(-1, 'day').format('YYYY년 MM월 DD일')}
                    </Typography>
                </div>
                <div className={classes.content}>
                    <SmallGrid
                        selectProc='SLT_SALEINFO_WORK_GOODS_DAILY'
                        selectParam={{ DATE : moment().add(-1, 'day').format('YYYY-MM-DD') }}
                        headerLess
                    >
                        <DataColumn
                            fieldName='WORK_NM'
                            mergeField='WORK_NM'
                        />
                        <DataColumn
                            fieldName='BEF_QTY_SUM_L'
                            title='전일수량(L)'
                            total={(values) => values.reduce((result, value) => result + value, 0)}
                        />
                        <DataColumn
                            fieldName='BEF_QTY_SUM_D'
                            title='전일수량(Drum)'
                            total={(values) => values.reduce((result, value) => result + value, 0)}
                        />
                        <DataColumn
                            fieldName='BEF_AMOUNT_SUM'
                            title='전일매출'
                            total={(values) => values.reduce((result, value) => result + value, 0)}
                        />
                        <DataColumn
                            fieldName='QTY_SUM_L'
                            title='수량(L)'
                            total={(values) => values.reduce((result, value) => result + value, 0)}
                        />
                        <DataColumn
                            fieldName='QTY_SUM_D'
                            title='수량(Drum)'
                            total={(values) => values.reduce((result, value) => result + value, 0)}
                        />
                        <DataColumn
                            fieldName='AMOUNT_SUM'
                            title='매출'
                            total={(values) => values.reduce((result, value) => result + value, 0)}
                        />
                        <DataColumn
                            fieldName='QTY_GAP_PER'
                            title='전일대비'
                            cellStyle={{ textAlign : 'end' }}
                        />
                    </SmallGrid>
                </div>
            </Paper>
            </Grid>
        </Grid>
    )
}

export default SaleReport;