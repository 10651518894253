import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { Button, Dialog, DialogTitle, makeStyles } from '@material-ui/core';
import { ComboBox, DatePicker, ProgressPanel, IconButton } from '../component';
import { useSnackbar } from 'notistack';
import { callProc } from '../common/DBConnector';
import { useFieldInputs } from '../common/Utils';
import SendIcon from '@material-ui/icons/Send';
import IsoIcon from '@material-ui/icons/Iso';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';

const defCondition = {
    WORK_CD: 'ALL',
    STA_DATE: moment().add(-1, 'day').format('YYYY-MM-DD'),
    END_DATE: moment().add(-1, 'day').format('YYYY-MM-DD'),
}

const useStyles = makeStyles(theme => ({
    title: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
    },
    field: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
}))

const SendPos = ({ visible, onClose }) => {
    const [workData, setWorkData] = useState();
    const [insData, setInsData] = useFieldInputs(defCondition);
    const clsState = useSelector(state => state.clsDateReducer);
    const snack = useRef();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const classes = useStyles();

    useEffect(() => {
        const fetchData = async () => {
            const workData = await callProc('SLT_WORKPLACEINFO_AUTH');

            setWorkData(workData.data.reduce((result, item) => ({
                ...result,
                [item['WORK_CD']]: item['WORK_NM']
            }), { 'ALL': '전체' }))
        }

        visible && fetchData();
    }, [visible]);

    const onSend = async() => {
        snack.current && closeSnackbar(snack.current);
        snack.current = await enqueueSnackbar("`POS백업 데이터`를 삭제후 `POS원본 데이터를` 서버로 재전송후 작업을 진행합니다. 계속 진행 하시겠습니까?\r\n" +
                                              "※`POS원본 데이터`를 수정시에만 사용 하시기 바랍니다.※", {
            variant: "warning",
            autoHideDuration: null,
            action: key => <>
                <Button onClick={() => {
                    closeSnackbar(key);
                    callProc('UPT_POSTRANS_COMMAND', {...insData, TRANS_TYPE: 1});
                    onClose();
                }}>
                    예
                </Button>
                <Button onClick={() => closeSnackbar(key)}>
                    아니오
                </Button>
            </>,
        })
    }

    const onRecalc = async() => {
        await callProc('UPT_POSTRANS_COMMAND', {...insData, TRANS_TYPE: 2});
        onClose();
    }

    const onStaDateChange = (value) => {
        const strStaDate = value;
        const staDate = moment(strStaDate, 'YYYY-MM-DD');
        const endDate = moment(insData['END_DATE'], 'YYYY-MM-DD');
        const dateDiff = moment.duration(staDate.diff(endDate)).asDays();

        setInsData('STA_DATE', strStaDate);
        if (dateDiff < -6) {
            setInsData('END_DATE', staDate.add(6, 'days').format('YYYY-MM-DD'));
        }
        if (dateDiff > 0) {
            setInsData('END_DATE', strStaDate);
        }
    }

    const onEndDateChange = (value) => {
        const strEndDate = value;
        const endDate = moment(strEndDate, 'YYYY-MM-DD');
        const staDate = moment(insData['STA_DATE'], 'YYYY-MM-DD');
        const dateDiff = moment.duration(endDate.diff(staDate)).asDays();

        setInsData('END_DATE', strEndDate);
        if (dateDiff > 6) {
            setInsData('STA_DATE', endDate.add(-6, 'days').format('YYYY-MM-DD'));
        }
        if (dateDiff < 0) {
            setInsData('STA_DATE', strEndDate);
        }
    }

    return (
        <Dialog
            open={visible}
            onClose={() => {
                onClose();
                snack.current && closeSnackbar(snack.current);
            }}
        >
            <div className={classes.title}>
                <DialogTitle>
                    POS 전송
                </DialogTitle>
                <IconButton onClick={() => onClose()}>
                    <CloseIcon />
                </IconButton>
            </div>
            {!workData ? <ProgressPanel /> :
            <div className={classes.content}>
                <ComboBox
                    label='사업장명'
                    className={classes.field}
                    selectProc='SLT_WORKPLACEINFO_AUTH'
                    keyField='WORK_CD'
                    valueField='WORK_NM'
                    value={insData['WORK_CD']}
                    onChange={(value) => {
                        setInsData('WORK_CD', value);
                    }}
                    onDataLoad={jsonData => ({ALL: '전체', ...jsonData})}
                    fullWidth={false}
                />
                <DatePicker
                    label='시작일자'
                    className={classes.field}
                    minDate={clsState[insData['WORK_CD']] || defCondition['STA_DATE']}
                    value={insData['STA_DATE']}
                    onChange={onStaDateChange}
                />
                <DatePicker
                    label='종료일자'
                    className={classes.field}
                    minDate={clsState[insData['WORK_CD']] || defCondition['END_DATE']}
                    value={insData['END_DATE']}
                    onChange={onEndDateChange}
                />
                <div className={classes.buttonContainer}>
                    <IconButton
                        tooltip='재계산'
                        icon={<IsoIcon />}
                        onClick={() => onRecalc()}
                    />
                    <IconButton
                        tooltip='원본전송'
                        icon={<SendIcon />}
                        onClick={() => onSend()}
                    />
                </div>
            </div>
            }
        </Dialog>
    )
}

export default SendPos;