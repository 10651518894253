import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';

const FilePicker = ({ onChange, children = <AttachFileIcon />, tooltip = '파일 선택', ...props }) => {
    const handleChange = async(e) => {
        const files = [];
        for (let i = 0; i < e.target.files.length; i++) {
            files.push(e.target.files[i]);
        }
        await onChange(props.multiple ? files : files[0]);
    }

    return (
        <Tooltip title={tooltip}>
            <IconButton
                component="label"
            >
                {children}
                <input type='file' value='' style={{ display: 'none' }} onChange={handleChange} {...props} />
            </IconButton>
        </Tooltip>
    )
}

export default FilePicker;