import {  makeStyles } from '@material-ui/core';
import moment from 'moment';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { BandItem, ComboBox, DataColumn, DataGrid, DatePicker, GridHeaderBand, PdfButton, SearchItem, SearchPanel, SmallGrid, Tab, Tabs } from '../../component';
import { callProc } from '../../common/DBConnector';
import { useSelector } from 'react-redux';
import { AgencySummaryPdf } from '../../reports';

const useStyle = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    subTabContainer: {
        backgroundColor: theme.palette.secondary.main,
        boxShadow: theme.shadows[4],
        marginBottom: theme.spacing(1),
    },
    tabContainer: {
        display: 'flex',
    },
    
    tabbar: {
        flex: 1,
        marginBottom: theme.spacing(1),
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
    hide: {
        display: 'none'
    },
}))

const workParam = { WORK_TYPE : [1] };

const AgencySummary = () => {
    const classes = useStyle();
    const [condition, setCondition] = useState(null);
    const [selectTab, setSelectTab] = useState(0);
    const [salestockData, setSalestockData] = useState();
    const [supplyDataDay, setSupplyDataDay] = useState();
    const [supplyDataMonth, setSupplyDataMonth] = useState();
    const [saleBondList, setSaleBondList] = useState();
    const [saleBondListDetail, setSaleBondListDetail] = useState();
    const [workData, setWorkData] = useState();
    const loginState = useSelector(state => state.loginReducer);
 
    useEffect(() => {
        const fetchData = async () => {
            const workData = await callProc('SLT_WORKPLACEINFO_AUTH', workParam);

            setWorkData(workData.data.reduce((result, item) => ({
                ...result,
                [item['WORK_CD']]: item['WORK_NM']
            }), {}))

        }
        fetchData();
    }, []);

    const condWorkName = workData && condition && workData[condition['WORK_CD']];
    const pdfComponent = useMemo(() => (
        <PdfButton title='대리점 집계표'>
            {salestockData 
            && supplyDataDay 
            && supplyDataMonth 
            && saleBondList
            && saleBondListDetail &&
            <AgencySummaryPdf
                salestockData={salestockData && salestockData.data}
                supplyDataDay={supplyDataDay && supplyDataDay.data}
                supplyDataMonth={supplyDataMonth && supplyDataMonth.data}
                saleBondList={saleBondList && saleBondList.data}
                saleBondListDetail={saleBondListDetail && saleBondListDetail.data}
                condition={condition}
                loginState={loginState}
                workName={condWorkName}
            />}
        </PdfButton>
    ), [salestockData, 
        supplyDataDay, 
        supplyDataMonth, 
        saleBondList,
        saleBondListDetail,
        condition, 
        loginState, 
        condWorkName]);

    useEffect(() => {
        const fetchData = async () => {
            setSalestockData(null);
            setSupplyDataDay(null);
            setSupplyDataMonth(null);
            setSaleBondList(null);

            setSalestockData(await callProc('SLT_SALE_STOCK_AGENCY', condition));
            setSupplyDataDay(await callProc('SLT_RANGE_STOCK_AGENCY', condition));
            setSupplyDataMonth(await callProc('SLT_MONTH_STOCK_AGENCY', condition));
            setSaleBondList(await callProc('SLT_SALE_BOND_LIST_AGENCY', condition));
            setSaleBondListDetail(await callProc('SLT_SALE_BOND_LIST_DETAIL_AGENCY_PRINT', condition));
        }
        condition && fetchData();
    }, [condition])

    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => { 
                if (condition['WORK_CD'] !== 'ALL'){
                    sessionStorage['WORK_CD'] = condition['WORK_CD'];
                }
                setCondition(condition); }}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, dispatch) => {
                        return (
                            <ComboBox
                                label='사업장'
                                selectProc='SLT_WORKPLACEINFO_AUTH'
                                selectParam={workParam}
                                keyField='WORK_CD'
                                valueField='WORK_NM'
                                onDataLoad={jsonData => ({ ALL: '전체', ...jsonData })}
                                value={state}
                                onChange={(value) => dispatch(value)}
                            />
                        )
                    }}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
            </SearchPanel>

            <div className={classes.tabContainer}>
                <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps={{ className: classes.tabbar }}>
                    <Tab label='판매량 및 재고 현황' />
                    <Tab label='일/월 상세 재고 현황' />
                    <Tab label='매출 시재 현황' />
                </Tabs>
                <div className={classes.subTabContainer}>

                    {pdfComponent}
                </div>
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 0 })}>
                <SaleStock salestockData={salestockData} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 1 })}>
                <Supply supplyDataDay={supplyDataDay} supplyDataMonth={supplyDataMonth} />
            </div>
            <div className={clsx(classes.content, { [classes.hide]: selectTab !== 2 })}>
                <SaleBondList saleBondList={saleBondList} condition={condition} />
            </div>
        </div>
    )
}

const SaleStock = ({ salestockData }) => (
    <DataGrid
        title='판매량 및 재고 현황'
        dataSet={salestockData}
        horizonMode
        totalGroup={['GDS_TYPE_M']}
        >
        <DataColumn fieldName='WORK_CD' visible={false} />
        <DataColumn fieldName='GDS_TYPE' visible={false} />
        <DataColumn fieldName='GDS_TYPE_M' visible={false} />
        <DataColumn fieldName='CATE_NM_M' visible={false} />
        <DataColumn fieldName='CATE_NM' title='상품명' />
        <DataColumn fieldName='CUR_SALE_QTY' title='판매량' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='CUR_SALE_AMT' title='판매금액' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='CUR_STOCK_QTY' title='장부재고' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='CUR_REAL_QTY' title='실재고' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='CUR_GAP_QTY' title='당일실재고차' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='BEF_GAP_QTY' title='전일실재고차' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='MONTH_SALE_QTY' title='월 판매량' total={(values) => values.reduce((result, value) => result + value, 0)} />
        <DataColumn fieldName='MONTH_SALE_AMT' title='월 판매금액' total={(values) => values.reduce((result, value) => result + value, 0)} />
    </DataGrid>
)

const Supply = ({ supplyDataDay, supplyDataMonth }) => {
    const classes = useStyle();

    return (
        <div className={classes.container}>
            <DataGrid
                title='기간별 상세 재고 현황'
                dataSet={supplyDataDay}
                horizonMode
                
            >
                <GridHeaderBand>
                    <BandItem
                        span={11}
                    >
                        일반재고
                    </BandItem>
                </GridHeaderBand>
                <DataColumn fieldName='WORK_CD' visible={false} />
                <DataColumn fieldName='GDS_CD' visible={false} />
                <DataColumn fieldName='BEF_STOCK_QTY' title='전기재고' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='CUR_IN_QTY' title='당기입고' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='CUR_OUT_QTY' title='당기출고' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='CUR_SELF_QTY' title='자가소비' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='CUR_STOCK_QTY' title='당기재고' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='TEST_QTY' title='검량' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='MOVE_QTY' title='자체이관' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='CUR_ADJ_QTY' title='재고조정' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            </DataGrid>

            <DataGrid
                style={{ marginTop: 8 }}
                title='월별 상세 재고 현황'
                dataSet={supplyDataMonth}
                horizonMode
            >
                <GridHeaderBand>
                    <BandItem
                        span={11}
                    >
                        일반재고
                    </BandItem>
                </GridHeaderBand>
                <DataColumn fieldName='WORK_CD' visible={false} />
                <DataColumn fieldName='GDS_CD' visible={false} />
                <DataColumn fieldName='BEF_STOCK_QTY' title='전기재고' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='CUR_IN_QTY' title='당기입고' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='CUR_OUT_QTY' title='당기출고' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='CUR_SELF_QTY' title='자가소비' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='CUR_STOCK_QTY' title='당기재고' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='TEST_QTY' title='검량' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='MOVE_QTY' title='자체이관' total={(values) => values.reduce((result, value) => result + value, 0)}/>
                <DataColumn fieldName='CUR_ADJ_QTY' title='재고조정' total={(values) => values.reduce((result, value) => result + value, 0)}/>
            </DataGrid>
        </div>
    )
}

const SaleBondList = ({ saleBondList, condition }) => (
    <DataGrid
        title='매출 시재 현황'
        dataSet={saleBondList}
        expandable={(row) => (
            row['EXPAND_TYPE'] === 0 ? null :
            <SmallGrid
                selectProc='SLT_SALE_BOND_LIST_DETAIL'
                selectParam={{ ...row, ...condition }}
            >
                <DataColumn fieldName='GDS_TYPE_CD' visible={false} />
                <DataColumn fieldName='GDS_TYPE_NM' title='구분' />
                <DataColumn fieldName='BEF_AMOUNT' title='전기잔액' />
                <DataColumn fieldName='SALE_AMOUNT' title='당기발생' />
                <DataColumn fieldName='IN_AMOUNT' title='당기입금' />
                <DataColumn fieldName='CUR_AMOUNT' title='당기잔액' />
            </SmallGrid>
        )}
        defaultExpand
        
    >
        <DataColumn fieldName='PAY_CD' visible={false} />
        <DataColumn fieldName='PAY_NM' title='구분' />
        <DataColumn fieldName='BEF_AMOUNT' title='전기잔액' />
        <DataColumn fieldName='SALE_AMOUNT' title='당기발생' />
        <DataColumn fieldName='IN_AMOUNT' title='당기입금' />
        <DataColumn fieldName='CUR_AMOUNT' title='당기잔액' />
        <DataColumn fieldName='EXPAND_TYPE' title='확장구분' visible={false} />
    </DataGrid>
)

export default AgencySummary;