import React, { useState, useEffect, useRef, Fragment, useMemo } from 'react';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DataColumn, Tab, Tabs, IconButton, FilePicker, PdfViewer } from '../../component';
import { callProc, uploadFile, getFileList, downloadFile } from '../../common/DBConnector';
import { Button, Dialog, DialogTitle, makeStyles, Paper, TextField, ListItemIcon, Menu as PopupMenu, MenuItem as PopupMenuItem } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { useSnackbar } from 'notistack';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import RepeatIcon from '@material-ui/icons/Repeat';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ViewListIcon from '@material-ui/icons/ViewList';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PostAddIcon from '@material-ui/icons/PostAdd';
import EdocumentPdf from '../../reports/Edocumentpdf';
import EditIcon from '@material-ui/icons/Edit';
import BlockIcon from '@material-ui/icons/Block';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useSelector } from 'react-redux';
import { docState } from '../../common/Dictionary';
import ElectRemarkDialog from './ElectRemarkDialog';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';

const enrollType = {
    NEW: 'NEW',
    EDIT: 'EDIT',
    COPY: 'COPY',
    RECREATE: 'RECREATE',
}

const lineType = {
    CONFIRM: 'CONFIRM',
    PROCESS: 'PROCESS',
    REFERENCE: 'REFERENCE',
}

const saveType = {
    APPROVAL: 'APPROVAL',
    PROCESS_CONFIRM: 'PROCESS_CONFIRM',
    BLOCK: 'BLOCK',
    REMOVE: 'REMOVE',
}

const searchType = {
    0: '제목',
    1: '작성자',
    2: '문서번호',
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 3,
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
    infoContainer: {
        // flex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: 450,
    },
    info: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflowX: 'hidden',
    },
    actionButton: {
        display: 'flex',
    },
    viewr: {
        display: 'flex',
        flex: 2,
        flexDirection: 'column',
        marginLeft: theme.spacing(1),
    },
    tab: {
        minWidth: 'unset',
        flex: 1,
    },
    docSeq: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    docTitle: {
        width: 0,
        whiteSpace: 'pre',
        paddingTop: 4,
        color: 'gray'
    },
    pdfList: {
        minWidth: 450,
        flex: 1,
    },
    paperTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    pdfIcons: {
        display: 'inline-block',
        marginLeft: theme.spacing(2),
    },
}))

const ElectDocument = () => {
    const classes = useStyles();
    const [condition, setCondition] = useState(null);
    const [selectTab, setSelectTab] = useState(0);
    const [gridData, setGridData] = useState();
    const [fileData, setFileData] = useState();
    const [empInfo, setEmpInfo] = useState();
    const [lineData, setLineData] = useState(); // 결재라인
    const [lineRef, setLineRef] = useState(); // 참조자
    const [remarkData, setRemarkData] = useState();

    const [selectedRow, setSelectedRow] = useState();
    const [remarkOpen, setRemarkOpen] = useState(false);
    const [docOpenType, setDocOpenType] = useState();
    const [empOpen, setEmpOpen] = useState(false);
    const empDialogLoadType = useRef();
    const snack = useRef();
    const { closeSnackbar } = useSnackbar();

    const loginState = useSelector(state => state.loginReducer);


    useEffect(() => {
        const fetchData = async() => {
            let selectProc;
            switch (selectTab) {
                case 0:
                    selectProc = 'SLT_EDOC_APPROVAL';
                    break;
                case 1:
                    selectProc = 'SLT_EDOC_NOT_CONFIRM';
                    break;
                case 2:
                    selectProc = 'SLT_EDOC_BLOCK';
                    break;
                case 3:
                    selectProc = 'SLT_EDOC_NOT_PROCESS';
                    break;
                case 4:
                    selectProc = 'SLT_EDOC_LIST';
                    break;
                default: return;
            }
            const gridData = await callProc(selectProc, { ...condition, LOGIN_EMP_CD: loginState['EMP_CD'] });
            const fileData = (await Promise.all(gridData.data.map(item => {
                return item['FILE_KEY'] && getFileList(item['FILE_KEY'])
            }))).reduce((result, item, idx) => (
                item ? {
                    ...result,
                    [gridData.data[idx]['FILE_KEY']]: item.err ? [] : item
                } : result
            ), {});
            setFileData(fileData);
            setGridData(gridData);
        }
        setSelectedRow();
        fetchData();
    }, [selectTab, condition, loginState]);

    useEffect(() => {
        const fetchData = async() => {
            const empData = (await callProc('SLT_EMPINFO_ALL')).data;
            const lineData = (await callProc('SLT_EDOCLINE_STATE', selectedRow)).data;
            const lineRef = (await callProc('SLT_EDOCLINE_REF', selectedRow)).data;
            const remarkData = (await callProc('SLT_EDOCREMARK', selectedRow)).data;

            setEmpInfo(empData);
            setLineData(lineData);
            setLineRef(lineRef);
            setRemarkData(remarkData);
        }
        setEmpInfo();
        setLineData();
        setLineRef();
        setRemarkData();
        snack.current && closeSnackbar(snack.current);
        selectedRow && fetchData();
    }, [selectedRow, closeSnackbar]);

    const refreshGrid = () => {
        setCondition({ ...condition });
    };


    const onFieldKey = (e, condition) => {
        if (e.key === 'Enter') {
            setCondition({...condition});
        }
    }

    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => setCondition(condition)}>
                <SearchItem
                    fieldName='SEARCH_TYPE'
                    component={(state, dispatch) => (
                        <ComboBox
                            label='검색구분'
                            data={searchType}
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='SEARCH_ITEM'
                    defaultValue=''
                    component={(state, dispatch, condition) => (
                        <TextField
                            label='검색어'
                            value={state}
                            onChange={e => dispatch(e.target.value)}
                            onKeyPress={(e) => onFieldKey(e, condition)}
                        />
                    )}
                />
            </SearchPanel>
            <div className={classes.content}>
                <div className={classes.infoContainer}>
                    <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps>
                        <Tab label='결재' className={classes.tab} icon={<PlaylistAddCheckIcon />} />
                        <Tab label='미결' className={classes.tab} icon={<AssignmentTurnedInIcon />} />
                        <Tab label='재결' className={classes.tab} icon={<RepeatIcon />} />
                        <Tab label='미처리' className={classes.tab} icon={<HourglassEmptyIcon />} />
                        <Tab label='조회' className={classes.tab} icon={<ViewListIcon />} />
                    </Tabs>
                    <DataGrid
                        className={classes.info}
                        dataSet={gridData}
                        selectionMode='single'
                        onSelect={setSelectedRow}
                        headerLess
                    >

                        <DataColumn
                            fieldName='DOC_SEQ'
                            title={`문서번호 (${gridData && gridData.data.length})`}
                            value={(row, value) => {
                            return <div>
                                <div className={classes.docSeq}>
                                    <div>
                                        {!row['FILE_KEY'] || !fileData[row['FILE_KEY']] ||  !fileData[row['FILE_KEY']].length ? null :
                                        <AttachFileIcon fontSize='small' />}
                                    </div>
                                    <div>
                                        {value}
                                    </div>
                                </div>
                                <div className={classes.docTitle}>{row['DOC_TITLE']}</div>
                            </div>}}
                        />
                        <DataColumn
                            fieldName='DOC_STATE'
                            title='상태'
                            value={(row, value) => docState[value] + (row['DLT_DATE'] ? `(${row['DLT_DATE']})` : '')}
                            cellStyle={{ height: 60, paddingBottom: 22 }}
                        />
                        <DataColumn
                            fieldName='EMP_NM'
                            title='작성자'
                            cellStyle={{ paddingBottom: 22 }}
                        />
                        <DataColumn
                            fieldName='REGDATE'
                            title='등록일'
                            cellStyle={{ paddingBottom: 22 }}
                        />
                        <DataColumn fieldName='DOC_TITLE' visible={false} />
                        <DataColumn fieldName='DOC_CD' visible={false} />
                        <DataColumn fieldName='DOC_DATE' visible={false} />
                        <DataColumn fieldName='REMARK' visible={false} />
                        <DataColumn fieldName='DEP_NM' visible={false} />
                        <DataColumn fieldName='POSITION' visible={false} />
                        <DataColumn fieldName='REGDTTM' visible={false} />
                        <DataColumn fieldName='FILE_KEY' visible={false} />
                        <DataColumn fieldName='CONFM_TYPE' visible={false} />
                        <DataColumn fieldName='DOC_CONTENT' visible={false} />
                        <DataColumn fieldName='EMP_CD' visible={false} />
                        <DataColumn fieldName='DLT_DATE' visible={false} />
                    </DataGrid>
                </div>
                <Paper className={classes.viewr}>
                    <PdfView
                        setEmpOpen={setEmpOpen}
                        setRemarkOpen={setRemarkOpen}
                        selectedRow={selectedRow}
                        selectTab={selectTab}
                        refreshGrid={refreshGrid}
                        empDialogLoadType={value => empDialogLoadType.current = value}
                        setDocOpenType={setDocOpenType}
                        loginState={loginState}
                        snack={snack}
                        lineData={lineData}
                        lineRef={lineRef}
                        remarkData={remarkData}
                        fileData={selectedRow && fileData[selectedRow['FILE_KEY']] ? fileData[selectedRow['FILE_KEY']] : []}
                    />

                </Paper>

                <ElectRemarkDialog
                    open={remarkOpen}
                    onClose={() => setRemarkOpen(false)}
                    onInserted={() => setSelectedRow({ ...selectedRow })}
                    docCd={selectedRow && selectedRow['DOC_CD']}
                    data={remarkData}
                />

                <DocumentEnrollDialog
                    openType={docOpenType}
                    onClose={() => setDocOpenType(null)}
                    condition={condition}
                    refreshGrid={() => refreshGrid()}
                    docCd={selectedRow && selectedRow['DOC_CD']}
                    remarkData={remarkData}
                    fileKey={selectedRow && selectedRow['FILE_KEY']}
                    fileData={selectedRow && fileData[selectedRow['FILE_KEY']]}
                />
                
                <DocConfirmEmpDialog
                    open={empOpen}
                    onClose={() => setEmpOpen(false)}
                    refreshGrid={refreshGrid}
                    empInfo={empInfo}
                    selectedRow={selectedRow}
                    empDialogLoadType={empDialogLoadType.current}
                    loginState={loginState}
                />

            </div>
        </div>
    )
}

const PdfView = ({ setEmpOpen, setRemarkOpen, selectedRow, selectTab, refreshGrid, empDialogLoadType, setDocOpenType, loginState, snack, lineData, lineRef, remarkData, fileData}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const valid = () => {
        if(!selectedRow){
            enqueueSnackbar('선택된 문서가 없습니다.', {variant: 'error'});
            return false;
        }

        return true;
    }

    const reqSave = (insType) => {
        snack.current && closeSnackbar(snack.current);

        let msg;
        let variant = 'default';
        switch (insType) {
            default:
            case saveType.APPROVAL:
                msg = '승인할까요?';
                break;
            case saveType.BLOCK:
                msg = '보류할까요?';
                variant = 'warning';
                break;
            case saveType.PROCESS_CONFIRM:
                msg = '처리확인할까요?';
                break;
            case saveType.REMOVE:
                msg = '파기할까요?';
                variant = 'warning';
                break;
        }
        snack.current = enqueueSnackbar(msg, {
            variant: variant,
            autoHideDuration: null,
            action: key => <>
                <Button onClick={() => {
                    closeSnackbar(key);
                    onSave(insType);
                }}>
                    예
                </Button>
                <Button onClick={() => closeSnackbar(key)}>
                    아니오
                </Button>
            </>,
        })
    }
    
    const onSave = async(insType) => {
        if (valid()) {
            let procName;
            let msg;
            switch (insType) {
                default:
                case saveType.APPROVAL:
                    procName = 'INS_EDOC_APPROVAL';
                    msg = '승인이 완료되었습니다.';
                    break;
                case saveType.BLOCK:
                    procName = 'INS_EDOC_BLOCK';
                    msg = '보류가 완료되었습니다.';
                    break;
                case saveType.PROCESS_CONFIRM:
                    procName = 'INS_EDOC_PROCESS_CONFIRM';
                    msg = '처리확인이 완료되었습니다.';
                    break;
                case saveType.REMOVE:
                    procName = 'INS_EDOC_REMOVE';
                    msg = '파기가 완료되었습니다.';
                    break;
            }

            const result = await callProc(procName, {
                DOC_CD: selectedRow['DOC_CD'],
                EMP_CD: loginState['EMP_CD'],
            })

            if (!result.err) {
                enqueueSnackbar(msg);
                refreshGrid();
            }
        }
    }

    const docConfirm = (selectTab) => {
        switch (selectTab) {
            default:
            case 0:
                return <Fragment>
                    {(selectedRow['DOC_STATE'].toString() === '0' ||
                    selectedRow['DOC_STATE'].toString() === '1') ?
                    <IconButton
                        tooltip='문서확인'
                        icon={<CheckIcon />}
                        onClick={() => {
                            empDialogLoadType('confirm');
                            setEmpOpen(true);
                        }}
                    /> :
                    <IconButton
                        tooltip='승인'
                        icon={<CheckIcon />}
                        onClick={() => reqSave(saveType.APPROVAL)}
                    />}
                    <IconButton
                        tooltip='보류'
                        icon={<BlockIcon />}
                        onClick={() => reqSave(saveType.BLOCK)}
                    />
                </Fragment>
            case 1:
                return (
                    (selectedRow['DOC_STATE'].toString() === '0' &&
                    selectedRow['EMP_CD'] === loginState['EMP_CD']) &&
                    <IconButton
                        tooltip='수정'
                        icon={<EditIcon />}
                        onClick={() => {
                            setDocOpenType(enrollType.EDIT);
                        }}
                    />
                )
            case 2:
                return <Fragment>
                    <IconButton
                        tooltip='재작성'
                        icon={<PostAddIcon />}
                        onClick={() => {
                            // onSave(saveType.REMOVE);
                            setDocOpenType(enrollType.RECREATE);
                        }}
                    />
                    <IconButton
                        tooltip='파기'
                        icon={<DeleteForeverIcon />}
                        onClick={() => reqSave(saveType.REMOVE)}
                    />
                </Fragment>
            case 3:
                return (
                    selectedRow['DOC_STATE'].toString() !== '6' ? 
                    <IconButton
                        tooltip='처리완료'
                        icon={<CheckIcon />}
                        onClick={() => {
                            empDialogLoadType('process');
                            setEmpOpen(true);
                        }}
                    /> : 
                    <IconButton
                        tooltip='처리확인'
                        icon={<CheckIcon />}
                        onClick={() => reqSave(saveType.PROCESS_CONFIRM)}
                    />
                )
        }
    }
    const pdfDoc = useMemo(() => {
        if (selectedRow && lineData && lineRef && remarkData && fileData) {
            return (
                <EdocumentPdf
                    data={selectedRow}
                    lineData={lineData}
                    lineRef={lineRef}
                    remarkData={remarkData}
                    fileData={fileData}
                    fileKey={selectedRow['FILE_KEY']}
                />
            )
        } else {
            return null;
        }
    }, [selectedRow, lineData, lineRef, remarkData, fileData])


    const IconMenuItem = ({ onClick, icon, label }) => {
        return (
            <PopupMenuItem onClick={onClick}>
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                {label}
            </PopupMenuItem>
        )
    }


    return (
        <PdfViewer 
            className={classes.pdfList}
            title={
                <div>
                    문서내용
                    {(!selectedRow || (selectedRow && String(selectedRow['CONFM_TYPE']) === '1')) ? null : docConfirm(selectTab)}
                </div>
            }
            header={
                <div className={classes.pdfIcons}>
                    <PopupMenu
                        anchorEl={anchorEl}
                        open={anchorEl != null}
                        onClose={() => setAnchorEl(null)}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        {fileData && Object.keys(fileData)[0] !== 'err' && fileData.map((fileItem, idx) =>
                            <IconMenuItem 
                                key={idx}
                                label={fileItem.name}
                                icon={<GetAppOutlinedIcon />}
                                onClick={() => downloadFile(selectedRow['FILE_KEY'], fileItem.seq)}
                            />
                        )}
                    </PopupMenu>
                    {fileData && fileData.length > 0 &&
                    <IconButton
                        tooltip='첨부파일'
                        icon={ <AttachFileIcon /> }
                        onClick={e => setAnchorEl(e.currentTarget)}
                    />}
                    {!selectedRow ? null :
                    <Fragment>
                        <IconButton
                            tooltip='참고사항'
                            icon={<PostAddIcon />}
                            onClick={() => setRemarkOpen(true)}
                        />
                        <IconButton
                            tooltip='문서복사'
                            icon={<FileCopyIcon />}
                            onClick={() => {
                                setDocOpenType(enrollType.COPY);
                            }}
                        />
                    </Fragment>}

                    <IconButton
                        tooltip='추가'
                        icon={<AddIcon />}
                        onClick={() => {
                            setDocOpenType(enrollType.NEW);
                        }}
                    />
                </div>
            }
        >
            {pdfDoc}
        </PdfViewer>

    )
}

const dialogStyle = makeStyles((theme) => ({
    dialogPaper: {
        maxWidth: 'none',
        width: '80%',
        height: '80%',
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    actionButton: {
        display: 'flex',
    },
    empContainer: {
        marginTop: theme.spacing(1),
    },
    titleField: {
        flex: 1,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    sumFiled: {
        marginLeft: theme.spacing(1),
    },
    content: {
        flex: 1,
        height: 0,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    contentInput: {
        flex: 1,
        alignItems: 'flex-start',
        overflowY: 'auto'
    },
    fileContainer: {
        display: 'flex',
        boxShadow: theme.shadows[15],
    },
    fileContent: {
        display: 'flex',
        overflowX: 'auto',
        alignItems: 'center',
        whiteSpace: 'nowrap',
    },
    fileItem: {
        margin: '0px 8px',
    },
}))


const DocumentEnrollDialog = ({ openType, onClose, refreshGrid, docCd, remarkData, fileKey, fileData }) => {
    const classes = dialogStyle();
    const [lineOpenType, setLineOpenType] = useState();
    const [, updateState] = useState();
    const title = useRef('');
    const [docContent, setDocContent] = useState('');
    const conEmpData = useRef(); // 확인자
    const actEmpData = useRef(); // 처리자
    const refEmpData = useRef(); // 참조자
    const [files, setFiles] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => setFiles(fileData && (openType === enrollType.EDIT || openType === enrollType.RECREATE ) ? [...fileData] : []), [fileData, openType]);
    
    useEffect(() => {
        
        const fetchData = async() => {
            const headerData = (await callProc('SLT_EDOCHEADER_COPY', {DOC_CD: docCd})).data[0];
            title.current = await headerData['DOC_TITLE'];
            setDocContent(headerData['DOC_CONTENT']);
            const lineData = (await callProc('SLT_EDOCLINE_COPY', {DOC_CD: docCd})).data;

            conEmpData.current = lineData.reduce((result, item) => ({
                ...result,
                [item['LINE_TYPE']]: [
                    ...(result[item['LINE_TYPE']] || []),
                    item
                ]
            }), [])[2]; // 확인자 

            actEmpData.current = lineData.reduce((result, item) => ({
                ...result,
                [item['LINE_TYPE']]: [
                    ...(result[item['LINE_TYPE']] || []),
                    item
                ]
            }), [])[3]; // 처리자 

            refEmpData.current = lineData.reduce((result, item) => ({
                ...result,
                [item['LINE_TYPE']]: [
                    ...(result[item['LINE_TYPE']] || []),
                    item
                ]
            }), [])[5]; // 참조자 

            forceUpdate();
        }

        if(openType === enrollType.COPY || openType === enrollType.RECREATE || openType === enrollType.EDIT) {
            fetchData();
        }
    }
    , [openType, docCd])

    const forceUpdate = () => updateState({});

    const valid = () => {
        if (title.current === '') {
            enqueueSnackbar('제목을 입력해 주세요.', { variant: 'error' });
            return false;
        }
        
        if(docContent === ''){
            enqueueSnackbar('문서내용을 입력해 주세요.', { variant: 'error' });
            return false;
        }

        if (!conEmpData.current || conEmpData.current.length <= 0) {
            enqueueSnackbar('확인자를 입력해 주세요.', { variant: 'error' });
            return false;
        }

        if (!actEmpData.current || actEmpData.current.length <= 0) {
            enqueueSnackbar('처리자를 입력해 주세요.', { variant: 'error' });
            return false;
        }

        return true;
    }

    const onSave = async() => {
        if (valid()) {
            if(openType === enrollType.COPY || openType === enrollType.NEW ){
                fileKey = null;
            }

            if (fileKey || files.length > 0) {
                fileKey = await uploadFile(files, fileKey);
                if (fileKey.err) return;
            }

            if (openType === enrollType.EDIT){
                await callProc('INS_EDOC_REMOVE',  {DOC_CD: docCd}); 
            }

            const result = await callProc('INS_EDOCHEADER_DOCUMENT', {
                DOC_CD_EDIT: openType === enrollType.EDIT ? docCd : null,
                DOC_TITLE: title.current,
                REMARK: openType === enrollType.EDIT ? remarkData : null,
                FILE_KEY: fileKey,
                DOC_CONTENT: docContent,
                CON_JSON: JSON.stringify(conEmpData.current),
                ACT_JSON: JSON.stringify(actEmpData.current),
                REF_JSON: JSON.stringify(refEmpData.current),
                RECREATE: openType === enrollType.RECREATE,
            });

            if (!result.err) {
                title.current = '';
                setDocContent('');
                conEmpData.current = null;
                actEmpData.current = null;
                refEmpData.current = null;
                setFiles([]);
                enqueueSnackbar('저장이 완료되었습니다.');
                onClose();
                refreshGrid();

            }
        }
    }

    const reset = () => {
        title.current = '';
        setDocContent('');
        conEmpData.current = null;
        actEmpData.current = null;
        refEmpData.current = null;
    }



    return (
            <Dialog
                open={openType != null}
                PaperProps={{ className: classes.dialogPaper }}
            >
                <div className={classes.dialogTitle}>
                    <div className={classes.actionButton}>
                        <DialogTitle>전자기안문서 등록</DialogTitle>
                        <IconButton
                            tooltip='확인'
                            onClick={() => {
                                onSave()
                            }}
                            icon={<CheckIcon />}
                        />
                    </div>
                    <IconButton
                        tooltip='닫기'
                        onClick={() => {
                            onClose()
                            openType === enrollType.EDIT && reset()
                        }}
                        icon={<CloseIcon />}
                    />
                </div>
                <TitleTextField
                    titleText={title.current}
                    onDataChange={(value) => title.current = value}
                />
                <div className={classes.empContainer}>
                    <IconButton
                        tooltip='확인자 추가'
                        onClick={() => setLineOpenType(lineType.CONFIRM)}
                        icon={<GroupAddIcon />}
                    />

                    <TextField
                        className={classes.titleField}
                        style={{ width: 350 }}
                        label='확인자'
                        value={(conEmpData.current && conEmpData.current.reduce((result, item, idx) => result + (idx === 0 ? '' : ', ') + item['EMP_NM'], '')) || ''}
                        onClick={() => setLineOpenType(lineType.CONFIRM)}
                        disabled
                    />

                    <IconButton
                        tooltip='처리자 추가'
                        onClick={() => setLineOpenType(lineType.PROCESS)}
                        icon={<GroupAddIcon />}
                    />

                    <TextField
                        className={classes.titleField}
                        style={{ width: 350 }}
                        label='처리자'
                        value={(actEmpData.current && actEmpData.current.reduce((result, item, idx) => result + (idx === 0 ? '' : ', ') + item['EMP_NM'], '')) || ''}
                        onClick={() => setLineOpenType(lineType.PROCESS)}
                        disabled
                    />

                    <IconButton
                        tooltip='참조자 추가'
                        onClick={() => setLineOpenType(lineType.REFERENCE)}
                        icon={<GroupAddIcon />}
                    />

                    <TextField
                        className={classes.titleField}
                        style={{ width: 400 }}
                        label='참조자'
                        value={(refEmpData.current && refEmpData.current.reduce((result, item, idx) => result + (idx === 0 ? '' : ', ') + item['EMP_NM'], '')) || ''}
                        onClick={() => setLineOpenType(lineType.REFERENCE)}
                        disabled
                    />
                </div>
                <TextField
                    className={classes.content}
                    InputProps={{ className: classes.contentInput }}
                    label='문서 내용'
                    value={docContent}
                    onChange={e => setDocContent(e.target.value)}
                    multiline
                    variant='outlined'
                />
                <EmpListDialog
                    open={lineOpenType != null}
                    onClose={() => setLineOpenType(null)}
                    onConfirm={items => {
                        switch (lineOpenType) {
                            default:
                            case lineType.CONFIRM:
                                conEmpData.current = items;
                                break;
                            case lineType.PROCESS:
                                actEmpData.current = items;
                                break;
                            case lineType.REFERENCE:
                                refEmpData.current = items;
                                break;
                        }
                        setLineOpenType(null);
                    }}
                />
                <div className={classes.fileContainer}>
                    <DialogTitle>첨부파일</DialogTitle>
                    <div className={classes.fileContent}>
                        {files.map((item, i) => (
                            <div className={classes.fileItem} key={i}>
                                {item.name}
                                <IconButton
                                    tooltip='삭제'
                                    icon={<CloseIcon />}
                                    onClick={() => {
                                        files.splice(files.indexOf(item), 1);
                                        setFiles([...files]);
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                    <FilePicker
                        multiple
                        onChange={newFiles => setFiles([...files, ...newFiles])}
                    />
                </div>
            </Dialog>
    )
}

const titleFieldStyle = makeStyles((theme) => ({
    titleField: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
    },
}))

const TitleTextField = ({ titleText, onDataChange }) => {
    const classes = titleFieldStyle();
    const [title, setTitle] = useState(titleText);

    useEffect(() => {
        setTitle(titleText);
    }, [titleText])

    return (
        <TextField
            className={classes.titleField}
            label='제목'
            value={title}
            onChange={e => {
                setTitle(e.target.value);
                onDataChange(e.target.value);
            }}
        />
    )
}

const empStyle = makeStyles((theme) => ({
    dialogPaper: {
        width: 400,
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    grid: {
        height: 400,
    },
    actionButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(2),
    },
}))

const EmpListDialog = ({ open, onClose, onConfirm }) => {
    const classes = empStyle();
    const empData = useRef();

    return (
        <Dialog
            open={open}
            PaperProps={{ className: classes.dialogPaper }}
            onClose={onClose}
        >
            <div className={classes.dialogTitle}>
                <DialogTitle>사원 선택</DialogTitle>
                <IconButton
                    tooltip='닫기'
                    onClick={onClose}
                    icon={<CloseIcon />}
                />
            </div>
            <DataGrid
                className={classes.grid}
                selectProc='SLT_EMPINFO_ALL'
                onSelect={rows => empData.current = rows}
                selectionMode='multi'
                headerLess
                borderLess
            />
            <div className={classes.actionButton}>
                <IconButton
                    tooltip='확인'
                    onClick={() => onConfirm(empData.current)}
                    icon={<CheckIcon />}
                />
            </div>
        </Dialog>
    )
}

const docConfStyle = makeStyles((theme) => ({
    dialogPaper: {
        width: 400,
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    actionButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(2),
    },
    fieldContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    Field: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(2),
    }
}))

const DocConfirmEmpDialog = ({ open, onClose, refreshGrid, empInfo, selectedRow, empDialogLoadType, loginState }) => {
    const classes = docConfStyle();
    const [empCd, setEmpCd] = useState();
    const { enqueueSnackbar } = useSnackbar();

    const valid = () => {
        if(!selectedRow){
            enqueueSnackbar('선택된 문서가 없습니다.', {variant: 'error'});
            return false;
        }

        return true;
    }

    const onSave = async () => {
        if (valid()) {
            let result;
            if (empDialogLoadType === 'confirm') {
                result = await callProc('INS_EDOC_CONFIRM', {
                    DOC_CD: selectedRow['DOC_CD'],
                    CONF_EMP_CD: empCd,
                    EMP_CD: loginState['EMP_CD'],
                });
            }else{
                result = await callProc('INS_EDOC_PROCESS', {
                    DOC_CD: selectedRow['DOC_CD'],
                    CONF_EMP_CD: empCd,
                    EMP_CD: loginState['EMP_CD'],
                });
            }

            if (!result.err) {
                enqueueSnackbar('저장이 완료되었습니다.');
                onClose();
                refreshGrid();
            }
        }
    }

    return(
        <Dialog
            open={open}
            PaperProps={{ className: classes.dialogPaper }}
        >
            <div className={classes.dialogTitle}>
                <DialogTitle>
                    {empDialogLoadType === 'confirm' ? '승인자 등록' : '처리확인자 등록'}
                </DialogTitle>
                <IconButton
                    tooltip='닫기'
                    onClick={() => {
                        onClose();
                    }}
                    icon={<CloseIcon />}
                />
            </div>
            <div className={classes.fieldContainer}>
                <ComboBox
                    className={classes.Field}
                    label='사원명'
                    data={empInfo}
                    keyField='EMP_CD'
                    valueField='EMP_NM'
                    value={empCd}
                    onChange={value => setEmpCd(value)}
                    fullWidth={false}
                />
            </div>
            <div className={classes.actionButton}>
                <IconButton
                    tooltip='확인'
                    onClick={onSave}
                    icon={<CheckIcon />}
                />
            </div>
        </Dialog>
    )
}

export default ElectDocument;