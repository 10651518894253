import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DatePicker, DataColumn, PdfButton, ComboSearch, } from '../../component';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { KeepStockHistoryPdf } from '../../reports/';
import { isFieldDiff } from '../../common/Utils';

const useStyles = makeStyles(() => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    }
}))

const KeepStockHistory = () => {
    const [condition, setCondition] = useState(null);
    const [pdfData, setPdfData] = useState();
    const classes = useStyles();
    const loginState = useSelector(state => state.loginReducer);
    const [workData, setWorkData] = useState();

    const pdfComponent = useMemo(() => (
        <PdfButton title='상품 수불장'>
            {pdfData &&
            <KeepStockHistoryPdf
                pdfData={pdfData}
                condition={condition}
                loginState={loginState}
                workName={workData.find(item => item['WORK_CD'] === condition['WORK_CD'])['WORK_NM']}
            />}
        </PdfButton>
    ), [pdfData, condition, loginState, workData]);


    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => {
                sessionStorage['WORK_CD'] = condition['WORK_CD'];
                setCondition(condition)}}>
                <SearchItem
                    fieldName='WORK_CD'
                    defaultValue={sessionStorage['WORK_CD']}
                    component={(state, dispatch) => (
                        <ComboBox 
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            onDataLoad={(jsonData, rawData) => {
                                const result = [...rawData]
                                setWorkData(result);
                                return result;
                            }}
                            value={state}
                            onChange={(value) => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='STA_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='시작일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value > condition['END_DATE']) {
                                    dispatch('END_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='END_DATE'
                    defaultValue={moment().add(-1, 'day').format('YYYY-MM-DD')}
                    component={(state, setState, condition, dispatch) => (
                        <DatePicker
                            label='종료일자'
                            value={state}
                            onChange={(value) => {
                                setState(value);
                                if (value < condition['STA_DATE']) {
                                    dispatch('STA_DATE', value);
                                }
                            }}
                        />
                    )}
                />
                <SearchItem
                    fieldName='GDS_CD'
                    component={(state, setState, condition, setField) => {
                        let param = condition['GDS_PARAM'] || null;
                        if (isFieldDiff(['WORK_CD', 'STA_DATE'], condition, param)) {
                            param = { 
                                WORK_CD: condition['WORK_CD'],
                                GDS_TYPE: 'ALL',
                                NOT_TYPE: 0,
                                GDS_TYPE_M: 'ALL',
                                NOT_TYPE_M: 0,
                                GAUGE_TYPE: 'ALL',
                                STA_DATE: condition['STA_DATE'],
                            };
                            setField('GDS_PARAM', param);
                        }
                        return (
                            <ComboSearch
                                label='상품명'
                                selectProc='SLT_GAUGE_ITEM_GOODS_COMBO'
                                selectParam={param}
                                keyField='GDS_CD'
                                valueField='GDS_NM'
                                onDataLoad={(jsonData, rawData) => [{ GDS_CD: 'ALL', GDS_NM: '전체' }, ...rawData]}
                                value={state}
                                onChange={(value) => setState(value)}
                            />
                        )
                    }}
                />
            </SearchPanel>
                <DataGrid
                    title='매출보관 수불장'
                    selectProc='SLT_KEEP_STOCK'
                    selectParam={condition}
                    totalGroup={['CST_CD', 'GDS_CD']}
                    eachTotalGroup
                    selectionMode='single'
                    horizonMode
                    virtualized
                    excelDownload
                    headerItem={({visibleAoa}) => {
                        setPdfData(visibleAoa);
                        return pdfComponent;
                    }}
                    stickyHeader
                    filterable
                >
                    <DataColumn 
                        fieldName='CST_NM' 
                        total={(values, data, group) => {
                            let result = '- 합 계 -'
                            if(group && !group['GDS_CD'] && group['CST_CD']) {
                                result = '(소 계)';
                            } else if (group && group['GDS_CD'] && group['CST_CD']){
                                result = '';
                            }
                            return result;
                        }} 
                    />
                    <DataColumn 
                        fieldName='GDS_NM' 
                        total={(values, data, group) => {
                            let result = '';
                            if(group && !group['GDS_CD'] && group['CST_CD']) {
                                result = data[0]['CST_NM'];
                            } else if (group && group['GDS_CD'] && group['CST_CD']){
                                result = `(소 계) ${data[0]['GDS_NM']}`;
                            }
                            return result;
                        }} 
                    />
                    <DataColumn fieldName='BEF_QTY' title='전기 재고량' />
                    <DataColumn fieldName='IN_QTY' title='입고 수량' total={(values) => values.reduce((result, value) => result + value, 0)} />
                    <DataColumn fieldName='OUT_QTY' title='출고 수량' total={(values) => values.reduce((result, value) => result + value, 0)} />
                    <DataColumn fieldName='CUR_QTY' title='당기 재고량' />
                    <DataColumn fieldName='WORK_CD' visible={false} />
                    <DataColumn fieldName='CST_CD' visible={false} />
                    <DataColumn fieldName='GDS_CD' visible={false} />
                    <DataColumn fieldName='BEF_GDS_CD' visible={false} />
                    <DataColumn fieldName='BEF_CST_CD' visible={false} />
                </DataGrid>
        </div>
    )
}
export default KeepStockHistory;