import React, { useState, useEffect, useMemo } from 'react';
import { DataGrid, ComboBox, ProgressPanel, DatePicker, DataColumn, IconButton } from '../../component';
import {  makeStyles, Paper } from '@material-ui/core';
import { callProc } from '../../common/DBConnector';
import { useSelector } from 'react-redux';
import { useFieldInputs } from '../../common/Utils';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
    container : {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    searchPaper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },
    searchContainer: {
        flex: 1,
        display: 'inline-block',
        flexDirection: 'row',
    },
    searchItem: {
        display: 'inline-block',
        width: 200,
        marginRight: 100,
        marginBottom: theme.spacing(1),
    },
}))

const TransEnroll = () => {
    const classes = useStyles();
    const clsState = useSelector(state => state.clsDateReducer);
    const [input, setInput] = useFieldInputs({
        WORK_CD: sessionStorage['WORK_CD'],
        BIZ_DATE: clsState[sessionStorage['WORK_CD']],
    });
    const [workData, setWorkData] = useState(); //검색패널(사업장))//

    useEffect(() => {
        const fetchData = async () => {
            const workData = await callProc('SLT_WORKPLACEINFO_AUTH');

            setWorkData(workData.data.reduce((result, item) => ({
                ...result,
                [item['WORK_CD']]: item['WORK_NM']
            }), {})) //검색패널 사업장 데이터//
        }
        fetchData();
    }, [])  

    const condition = useMemo(() => input['WORK_CD'] && input['BIZ_DATE'] && input, [input]);

    const gridRefresh = () => {
        setInput({ type: 'UPDATE', value: { ...input } });
    }

    return(
        !workData ? <ProgressPanel/> :
        <div className={classes.container}>
            <Paper className={classes.searchPaper}>
                <div className={classes.searchContainer}>
                    <div className={classes.searchItem}>
                        <ComboBox
                            // style={{width: 200}}
                            label='사업장'
                            selectProc='SLT_WORKPLACEINFO_AUTH'
                            keyField='WORK_CD'
                            valueField='WORK_NM'
                            value={condition && condition['WORK_CD']}
                            onChange={value => {
                                if (input['WORK_CD'] !== value) {
                                    sessionStorage['WORK_CD'] = value;
                                    setInput('BIZ_DATE', clsState[value]);
                                    setInput('WORK_CD', value);
                                }
                            }}
                        />
                    </div>
                    <div className={classes.searchItem}>
                        <DatePicker
                            label='영업일'
                            minDate={clsState[input['WORK_CD']]}
                            value={condition && condition['BIZ_DATE']}
                            onChange={value => {
                                if (input['BIZ_DATE'] !== value) {
                                    setInput('BIZ_DATE', value);
                                    if(condition && condition['BIZ_DATE'] < clsState[condition['WORK_CD']]) {
                                        setInput({ type: 'UPDATE', value: { ...input, 'BIZ_DATE': value } });
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                <IconButton
                    tooltip=''
                    icon={<SearchIcon />}
                    onClick={gridRefresh}
                />
            </Paper>
            <DataGrid
                 title='수송등록'
                 table='TB_TRANSPORTINFO'
                 selectProc='SLT_TRANSPORTINFO'
                 updateProc='UPT_TRANSPORTINFO'
                 selectParam={condition}
                 horizonMode
            >
                <DataColumn fieldName='WORK_CD' visible={false} />
            </DataGrid>
        </div>
    )
}
export default TransEnroll