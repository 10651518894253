import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { KeyboardTimePicker } from '@material-ui/pickers';

const format = 'HH:mm:ss';

const TimePicker = (props) => {   
    const { onChange, value, maxTime, minTime, ...fieldProps } = props;
    const [inputValue, setInputValue] = useState();
    const tmpValue = useRef();

    const isValid = useCallback(date => {
        if (!date) return false;

        if (typeof(date) === 'string') {
            date = moment(date, format);
        }
        return date.isValid();
    }, []);

    const setTmpValue = useCallback(value => {
        if (minTime > value) {
            tmpValue.current = minTime;
        } else if (maxTime < value) {
            tmpValue.current = maxTime;
        } else {
            tmpValue.current = value;
        }
    }, [maxTime, minTime])

    useEffect(() => {
        if (onChange) {
            if (value) {
                onChange(value);
            } else {
                onChange(moment().format(format));
            }
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (isValid(value)) {
            setTmpValue(value);
            setInputValue(tmpValue.current);
        }
    }, [value, maxTime, minTime, isValid, setTmpValue])

    useEffect(() => {
        inputValue && onChange && onChange(tmpValue.current);
        // eslint-disable-next-line
    }, [inputValue]);
    
    const onEdit = (date, value) => {
        setInputValue(undefined);
        if (isValid(value)) {
            setTmpValue(value);
        }
    }

    const onBlur = () => {
        setInputValue(tmpValue.current);
    }

    return (
        <KeyboardTimePicker
            ampm={false}
            value={value}
            inputValue={inputValue}
            onChange={onEdit}
            onBlur={onBlur}
            onClose={onBlur}
            variant='inline'
            format={format}
            autoOk
            {...fieldProps}
        />
    )
}

export default TimePicker;