import React, { useState, useEffect, useRef, Fragment, forwardRef, useImperativeHandle, useMemo, useCallback } from 'react';
import { DataGrid, SearchItem, SearchPanel, ComboBox, DatePicker, DataColumn, NumberField, ProgressPanel, Tab, Tabs, ComboSearch, IconButton, FilePicker, PdfViewer } from '../../component';
import { callProc, uploadFile, getFileList, downloadFile } from '../../common/DBConnector';
import { Dialog, DialogTitle, ListItem, makeStyles, Paper, TextField, List, ListItemIcon, Button, Menu as PopupMenu, MenuItem as PopupMenuItem } from '@material-ui/core';
import { dataToWorkbook, downloadWorkbook, useFieldInputs } from '../../common/Utils';
import CheckIcon from '@material-ui/icons/Check';
import { useSnackbar } from 'notistack';
import { sortedProType, IOType, cstKind, docState } from '../../common/Dictionary';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import RepeatIcon from '@material-ui/icons/Repeat';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ViewListIcon from '@material-ui/icons/ViewList';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import RemoveIcon from '@material-ui/icons/Remove';
import RefreshIcon from '@material-ui/icons/Refresh';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PostAddIcon from '@material-ui/icons/PostAdd';
import EdocumentStatePdf from '../../reports/EdocumentStatePdf';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import EditIcon from '@material-ui/icons/Edit';
import BlockIcon from '@material-ui/icons/Block';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CreateIcon from '@material-ui/icons/Create';
import { useSelector } from 'react-redux';
import ElectRemarkDialog from './ElectRemarkDialog';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import GridOnIcon from '@material-ui/icons/GridOn';

const enrollType = {
    NEW: 'NEW',
    EDIT: 'EDIT',
    COPY: 'COPY',
    RECREATE: 'RECREATE',
    REEDIT: 'REEDIT',
}

const lineType = {
    CONFIRM: 'CONFIRM',
    PROCESS: 'PROCESS',
    REFERENCE: 'REFERENCE',
}

const saveType = {
    APPROVAL: 'APPROVAL',
    PROCESS_CONFIRM: 'PROCESS_CONFIRM',
    BLOCK: 'BLOCK',
    REMOVE: 'REMOVE',
}

const searchType = {
    0: '제목',
    1: '작성자',
    2: '문서번호',
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 3,
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flex: 1,
        height: 0,
    },
    infoContainer: {
        // flex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: 450,
    },
    info: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflowX: 'hidden',
    },
    actionButton: {
        display: 'flex',
    },
    viewr: {
        display: 'flex',
        flex: 2,
        flexDirection: 'column',
        marginLeft: theme.spacing(1),
    },
    tab: {
        minWidth: 'unset',
        flex: 1,
    },
    docSeq: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    docTitle: {
        width: 0,
        whiteSpace: 'pre',
        paddingTop: 4,
        color: 'gray'
    },
    pdfList: {
        minWidth: 450,
        flex: 1,
    },
    paperTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    pdfIcons: {
        display: 'inline-block',
        marginLeft: theme.spacing(2),
    },
}))



const ElectAccounting = () => {
    const classes = useStyles();
    const [condition, setCondition] = useState(null);
    const [selectTab, setSelectTab] = useState(0);
    const [gridData, setGridData] = useState();
    const [fileData, setFileData] = useState();
    const [workInfo, setWorkInfo] = useState();
    const [cstInfo, setCstInfo] = useState();
    const [accInfo, setAccInfo] = useState();
    const [cstBankInfo, setCstBankInfo] = useState();
    const [bankInfo, setBankInfo] = useState();
    const [creditInfo, setCreditInfo] = useState();
    const [empInfo, setEmpInfo] = useState();
    const [lineData, setLineData] = useState(); // 결재라인
    const [lineRef, setLineRef] = useState(); // 참조자
    const [listData, setListData] = useState(); // 결재항목
    const [efmsData, setEfmsData] = useState(); // efms입/출금
    const [edocAccData, setEdocAccData] = useState(); // 출금혼합 분개 데이터
    const [remarkData, setRemarkData] = useState();
    const [remarkOpen, setRemarkOpen] = useState(false);

    const [accOpenType, setAccOpenType] = useState();
    const [cstOpen, setCstOpen] = useState(false);
    const [empOpen, setEmpOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState();
    const [copyClick, setCopyClick] = useState(false);
    const empDialogLoadType = useRef();
    const snack = useRef();
    const { closeSnackbar } = useSnackbar();

    const loginState = useSelector(state => state.loginReducer);

    useEffect(() => {
        const fetchData = async () => {
            const workData = (await callProc('SLT_WORKPLACEINFO_AUTH')).data;
            const accData = (await callProc('SLT_ACCOUNT_ALL')).data;
            const creditData = (await callProc('SLT_CREDITCARD_ALL')).data;
            const empData = (await callProc('SLT_EMPINFO_ALL')).data;
            const bankData = (await callProc('SLT_BANK_ALL')).data;

            setWorkInfo(workData);
            setAccInfo(accData);
            setCreditInfo(creditData);
            setEmpInfo(empData);
            setBankInfo(bankData);
        }
        condition && fetchData();
    }, [condition])

    useEffect(() => {
        const fetchData = async () => {
            const cstData = (await callProc('SLT_CUSTOMERINFO_EDOC_ALL')).data;
            setCstInfo(cstData.reduce((result, item) => ({
                ...result,
                [item['WORK_CD']]: [
                    ...(result[item['WORK_CD']] || []),
                    item
                ]
            }), workInfo.reduce((result, item) => ({ ...result, [item['WORK_CD']]: [] }), {})));
            setCstBankInfo(cstData.reduce((result, item) => ({
                ...result,
                [item['WORK_CD']]: {
                    ...result[item['WORK_CD']],
                    [item['CST_CD']]: item
                }
            }), {}));
        }
        !cstOpen && workInfo && fetchData();
    }, [cstOpen, workInfo])


    useEffect(() => {
        const fetchData = async() => {
            let selectProc;
            switch (selectTab) {
                case 0:
                    selectProc = 'SLT_EDOC_ACCOUNTING_APPROVAL';
                    break;
                case 1:
                    selectProc = 'SLT_EDOC_ACCOUNTING_NOT_CONFIRM';
                    break;
                case 2:
                    selectProc = 'SLT_EDOC_ACCOUNTING_BLOCK';
                    break;
                case 3:
                    selectProc = 'SLT_EDOC_ACCOUNTING_NOT_PROCESS';
                    break;
                case 4:
                    selectProc = 'SLT_EDOC_ACCOUNTING_LIST';
                    break;
                default: return;
            }
            const gridData = await callProc(selectProc, { ...condition, LOGIN_EMP_CD: loginState['EMP_CD'] });
            const fileData = (await Promise.all(gridData.data.map(item => {
                return item['FILE_KEY'] && getFileList(item['FILE_KEY'])
            }))).reduce((result, item, idx) => (
                item ? {
                    ...result,
                    [gridData.data[idx]['FILE_KEY']]: item.err ? [] : item
                } : result
            ), {});
            setFileData(fileData);
            setGridData(gridData);
        }
        setSelectedRow();
        fetchData();
    }, [selectTab, condition, loginState]);

    useEffect(() => {
        const fetchData = async() => {
            const lineData = (await callProc('SLT_EDOCLINE_STATE', selectedRow)).data;
            const lineRef = (await callProc('SLT_EDOCLINE_REF', selectedRow)).data;
            const listData = (await callProc('SLT_EDOCLIST_SALE_INBOUND', selectedRow)).data;
            const efmsData = (await callProc('SLT_EDOCLIST_EFMS_IN_OUTMONEY', selectedRow)).data;
            const edocAccData = (await callProc('SLT_EDOCACC_STATEMENT', selectedRow)).data;
            const remarkData = (await callProc('SLT_EDOCREMARK', selectedRow)).data;

            setLineData(lineData);
            setLineRef(lineRef);
            setListData(listData);
            setEfmsData(efmsData);
            setEdocAccData(edocAccData);
            setRemarkData(remarkData);
        }
        setLineData();
        setLineRef();
        setListData();
        setEfmsData();
        setEdocAccData();
        setRemarkData();
        snack.current && closeSnackbar(snack.current);
        selectedRow && fetchData();
    }, [selectedRow, closeSnackbar]);


    const refreshGrid = () => {
        setCondition({ ...condition });
    };

    const onFieldKey = (e, condition) => {
        if (e.key === 'Enter') {
            setCondition({...condition});
        }
    }

    return (
        <div className={classes.container}>
            <SearchPanel onSearch={(condition) => setCondition(condition)}>
                <SearchItem
                    fieldName='SEARCH_TYPE'
                    component={(state, dispatch) => (
                        <ComboBox
                            label='검색구분'
                            data={searchType}
                            value={state}
                            onChange={value => dispatch(value)}
                        />
                    )}
                />
                <SearchItem
                    fieldName='SEARCH_ITEM'
                    defaultValue=''
                    component={(state, dispatch, condition) => (
                        <TextField
                            label='검색어'
                            value={state}
                            onChange={e => dispatch(e.target.value)}
                            onKeyPress={(e) => onFieldKey(e, condition)}
                        />
                    )}
                />
            </SearchPanel>
            <div className={classes.content}>
                <div className={classes.infoContainer}>
                    <Tabs value={selectTab} onChange={(e, v) => setSelectTab(v)} appbarProps>
                        <Tab label='결재' className={classes.tab} icon={<PlaylistAddCheckIcon />} />
                        <Tab label='미결' className={classes.tab} icon={<AssignmentTurnedInIcon />} />
                        <Tab label='재결' className={classes.tab} icon={<RepeatIcon />} />
                        <Tab label='미처리' className={classes.tab} icon={<HourglassEmptyIcon />} />
                        <Tab label='조회' className={classes.tab} icon={<ViewListIcon />} />
                    </Tabs>
                    <DataGrid
                        className={classes.info}
                        dataSet={gridData}
                        selectionMode='single'
                        onSelect={setSelectedRow}
                        headerLess
                    >
                        <DataColumn
                            fieldName='DOC_SEQ'
                            title={`문서번호 (${gridData && gridData.data.length})`}
                            value={(row, value) => {
                            return <div>
                                <div className={classes.docSeq}>
                                    <div>
                                        {!row['FILE_KEY'] || !fileData[row['FILE_KEY']] || !fileData[row['FILE_KEY']].length ? null :
                                        <AttachFileIcon fontSize='small' />}
                                    </div>
                                    <div>
                                        {value}
                                    </div>
                                </div>
                                <div className={classes.docTitle}>{row['DOC_TITLE']}</div>
                            </div>}}
                        />
                        <DataColumn
                            fieldName='DOC_STATE'
                            title='상태'
                            value={(row, value) => docState[value] + (row['DLT_DATE'] ? `(${row['DLT_DATE']})` : '')}
                            cellStyle={{ height: 60, paddingBottom: 22 }}
                        />
                        <DataColumn
                            fieldName='EMP_NM'
                            title='작성자'
                            cellStyle={{ paddingBottom: 22 }}
                        />
                        <DataColumn
                            fieldName='REGDATE'
                            title='등록일'
                            cellStyle={{ paddingBottom: 22 }}
                        />
                        <DataColumn fieldName='DOC_TITLE' visible={false} />
                        <DataColumn fieldName='DOC_CD' visible={false} />
                        <DataColumn fieldName='DOC_DATE' visible={false} />
                        <DataColumn fieldName='REMARK' visible={false} />
                        <DataColumn fieldName='DEP_NM' visible={false} />
                        <DataColumn fieldName='POSITION' visible={false} />
                        <DataColumn fieldName='REGDTTM' visible={false} />
                        <DataColumn fieldName='FILE_KEY' visible={false} />
                        <DataColumn fieldName='CONFM_TYPE' visible={false} />
                        <DataColumn fieldName='EMP_CD' visible={false} />
                        <DataColumn fieldName='DLT_DATE' visible={false} />
                    </DataGrid>
                </div>
                <Paper className={classes.viewr}>
                    <PdfView
                        setCstOpen={setCstOpen}
                        setEmpOpen={setEmpOpen}
                        selectedRow={selectedRow}
                        selectTab={selectTab}
                        refreshGrid={refreshGrid}
                        empDialogLoadType={value => empDialogLoadType.current = value}
                        setAccOpenType={setAccOpenType}
                        loginState={loginState}
                        snack={snack}
                        setRemarkOpen={setRemarkOpen}
                        lineData={lineData}
                        lineRef={lineRef}
                        listData={listData}
                        efmsData={efmsData}
                        edocAccData={edocAccData}
                        remarkData={remarkData}
                        fileData={selectedRow && fileData[selectedRow['FILE_KEY']] ? fileData[selectedRow['FILE_KEY']] : []}
                        setCopyClick={setCopyClick}
                    />
                    
                </Paper>
                <AccountingEnroll
                    openType={accOpenType}
                    onClose={() => setAccOpenType(null)}
                    condition={condition}
                    refreshGrid={() => refreshGrid()}
                    workInfo={workInfo}
                    cstInfo={cstInfo}
                    accInfo={accInfo}
                    cstBankInfo={cstBankInfo}
                    bankInfo={bankInfo}
                    creditInfo={creditInfo}
                    docCd={selectedRow && selectedRow['DOC_CD']}
                    setCstOpen={setCstOpen}
                    remarkData1={remarkData}
                    fileKey={selectedRow && selectedRow['FILE_KEY']}
                    fileData={selectedRow && fileData[selectedRow['FILE_KEY']]}
                    copyClick={copyClick}
                />

                <NewCstDialog
                    open={cstOpen}
                    onClose={() => setCstOpen(false)}
                    workInfo={workInfo}
                />

                <DocConfirmEmpDialog
                    open={empOpen}
                    onClose={() => setEmpOpen(false)}
                    refreshGrid={refreshGrid}
                    empInfo={empInfo}
                    selectedRow={selectedRow}
                    empDialogLoadType={empDialogLoadType.current}
                    loginState={loginState}
                />
                <ElectRemarkDialog
                    open={remarkOpen}
                    onClose={() => setRemarkOpen(false)}
                    onInserted={() => setSelectedRow({ ...selectedRow })}
                    docCd={selectedRow && selectedRow['DOC_CD']}
                    data={remarkData}
                />
            </div>
        </div>
    )
}

const PdfView = ({ setCstOpen, setEmpOpen, selectedRow, selectTab, refreshGrid, empDialogLoadType, setAccOpenType, loginState, snack, setRemarkOpen, 
                    lineData, lineRef, listData, efmsData, edocAccData, remarkData, fileData, setCopyClick}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    
    
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const valid = () => {
        if(!selectedRow){
            enqueueSnackbar('선택된 문서가 없습니다.', {variant: 'error'});
            return false;
        }

        return true;
    }

    const reqSave = (insType) => {
        snack.current && closeSnackbar(snack.current);

        let msg;
        let variant = 'default';
        switch (insType) {
            default:
            case saveType.APPROVAL:
                msg = '승인할까요?';
                break;
            case saveType.BLOCK:
                msg = '보류할까요?';
                variant = 'warning';
                break;
            case saveType.PROCESS_CONFIRM:
                msg = '처리확인할까요?';
                break;
            case saveType.REMOVE:
                msg = '파기할까요?';
                variant = 'warning';
                break;
        }
        snack.current = enqueueSnackbar(msg, {
            variant: variant,
            autoHideDuration: null,
            action: key => <>
                <Button onClick={() => {
                    closeSnackbar(key);
                    onSave(insType);
                }}>
                    예
                </Button>
                <Button onClick={() => closeSnackbar(key)}>
                    아니오
                </Button>
            </>,
        })
    }
    
    const onSave = async(insType) => {
        if (valid()) {
            let procName;
            let msg;
            switch (insType) {
                default:
                case saveType.APPROVAL:
                    procName = 'INS_EDOC_APPROVAL';
                    msg = '승인이 완료되었습니다.';
                    break;
                case saveType.BLOCK:
                    procName = 'INS_EDOC_BLOCK';
                    msg = '보류가 완료되었습니다.';
                    break;
                case saveType.PROCESS_CONFIRM:
                    procName = 'INS_EDOC_PROCESS_CONFIRM';
                    msg = '처리확인이 완료되었습니다.';
                    break;
                case saveType.REMOVE:
                    procName = 'INS_EDOC_REMOVE';
                    msg = '파기가 완료되었습니다.';
                    break;
            }

            const result = await callProc(procName, {
                DOC_CD: selectedRow['DOC_CD'],
                EMP_CD: loginState['EMP_CD'],
            })

            if (!result.err) {
                enqueueSnackbar(msg);
                refreshGrid();
            }
        }
    }

    const docConfirm = (selectTab) => {
        switch (selectTab) {
            default:
            case 0:
                return <Fragment>
                    {(selectedRow['DOC_STATE'].toString() === '0' ||
                    selectedRow['DOC_STATE'].toString() === '1') ?
                    <IconButton
                        tooltip='문서확인'
                        icon={<CheckIcon />}
                        onClick={() => {
                            empDialogLoadType('confirm');
                            setEmpOpen(true);
                        }}
                    /> :
                    <IconButton
                        tooltip='승인'
                        icon={<CheckIcon />}
                        onClick={() => reqSave(saveType.APPROVAL)}
                    />}
                    <IconButton
                        tooltip='보류'
                        icon={<BlockIcon />}
                        onClick={() => reqSave(saveType.BLOCK)}
                    />
                </Fragment>
            case 1:
                return (
                    (selectedRow['DOC_STATE'].toString() === '0' &&
                    selectedRow['EMP_CD'] === loginState['EMP_CD']) &&
                    <IconButton
                        tooltip='수정'
                        icon={<EditIcon />}
                        onClick={() => {
                            setAccOpenType(enrollType.EDIT);
                            setCopyClick(false);
                        }}
                    />
                )
            case 2:
                return <Fragment>
                    <IconButton
                        tooltip='재작성'
                        icon={<CreateIcon />}
                        onClick={() => {
                            setAccOpenType(enrollType.REEDIT);
                        }}
                    />
                    <IconButton
                        tooltip='파기'
                        icon={<DeleteForeverIcon />}
                        onClick={() => reqSave(saveType.REMOVE)}
                    />
                </Fragment>
            case 3:
                return (
                    selectedRow['DOC_STATE'].toString() !== '6' ? 
                    <IconButton
                        tooltip='처리완료'
                        icon={<CheckIcon />}
                        onClick={() => {
                            empDialogLoadType('process');
                            setEmpOpen(true);
                        }}
                    /> : 
                    <IconButton
                        tooltip='처리확인'
                        icon={<CheckIcon />}
                        onClick={() => reqSave(saveType.PROCESS_CONFIRM)}
                    />
                )
        }
    }

    const pdfDoc = useMemo(() => {
        if (selectedRow && lineData && lineRef && listData && efmsData && edocAccData && remarkData && fileData) {
            return (
                <EdocumentStatePdf
                    data={selectedRow}
                    lineData={lineData}
                    lineRef={lineRef}
                    listData={listData}
                    efmsData={efmsData}
                    edocAccData={edocAccData}
                    remarkData={remarkData}
                    fileData={fileData}
                    fileKey={selectedRow['FILE_KEY']}
                />
            )
        } else {
            return null;
        }
    }, [selectedRow, lineData, lineRef, listData, efmsData, edocAccData, remarkData, fileData])

    const IconMenuItem = ({ onClick, icon, label }) => {
        return (
            <PopupMenuItem onClick={onClick}>
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                {label}
            </PopupMenuItem>
        )
    }

    return (
        <PdfViewer 
            className={classes.pdfList}
            title={
                <div>
                    문서내용
                    {(!selectedRow || (selectedRow && String(selectedRow['CONFM_TYPE']) === '1')) ? null : docConfirm(selectTab)}
                </div>
            }
            header={
                <div className={classes.pdfIcons}>
                    <PopupMenu
                        anchorEl={anchorEl}
                        open={anchorEl != null}
                        onClose={() => setAnchorEl(null)}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        {fileData && Object.keys(fileData)[0] !== 'err' && fileData.map((fileItem, idx) =>
                            <IconMenuItem 
                                key={idx}
                                label={fileItem.name}
                                icon={<GetAppOutlinedIcon />}
                                onClick={() => downloadFile(selectedRow['FILE_KEY'], fileItem.seq)}
                            />
                        )}
                    </PopupMenu>
                    {fileData && fileData.length > 0 &&
                    <IconButton
                        tooltip='첨부파일'
                        icon={ <AttachFileIcon /> }
                        onClick={e => setAnchorEl(e.currentTarget)}
                    />}
                    {!selectedRow ? null :
                    <IconButton
                        tooltip='참고사항'
                        icon={<PostAddIcon />}
                        onClick={() => setRemarkOpen(true)}
                    />}
                    <IconButton
                        tooltip='거래처 등록'
                        icon={<NoteAddIcon />}
                        onClick={() => setCstOpen(true)}
                    />
                    {!selectedRow ? null :
                    <IconButton
                        tooltip='문서복사'
                        icon={<FileCopyIcon />}
                        onClick={() => {
                            setAccOpenType(enrollType.COPY);
                            setCopyClick(true);
                        }}
                    />}

                    <IconButton
                        tooltip='추가'
                        icon={<AddIcon />}
                        onClick={() => {
                            setAccOpenType(enrollType.NEW);
                        }}
                    />
                </div>
            }
        >
            {pdfDoc}
        </PdfViewer>
    )
}

const dialogStyle = makeStyles((theme) => ({
    dialogPaper: {
        maxWidth: 'none',
        width: '80%',
        height: '80%',
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    actionButton: {
        display: 'flex',
    },
    empButton: {
        marginTop: theme.spacing(1),
    },
    titleField: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        flex: 1,
    },
    sumFiled: {
        marginLeft: theme.spacing(1),
    },
    content: {
        flex: 1,
        height: 0,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        overflowY: 'scroll',
    },
    fileContainer: {
        display: 'flex',
        boxShadow: theme.shadows[15],
    },
    fileContent: {
        display: 'flex',
        overflowX: 'auto',
        alignItems: 'center',
        whiteSpace: 'nowrap',
    },
    fileItem: {
        margin: '0px 8px',
    },
}))


const AccountingEnroll = ({ openType, onClose, refreshGrid, workInfo, cstInfo, accInfo, cstBankInfo, bankInfo, creditInfo, docCd, setCstOpen, remarkData1, fileKey, fileData, copyClick }) => {
    const classes = dialogStyle();
    const [lineOpenType, setLineOpenType] = useState();
    const [, updateState] = useState();
    const title = useRef('');
    const conEmpData = useRef(); // 확인자
    const actEmpData = useRef(); // 처리자
    const refEmpData = useRef(); // 참조자
    const remarkData = useRef(); // 참고사항
    const proData = useRef([]);
    const indigData = useRef([]); // 출금혼합일경우 분개등록 데이터
    const sumPanel = useRef(); // 합계
    const [indigParent, setIndigParent] = useState();
    const [files, setFiles] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    const initIndigData = useCallback((proSeq, proIOType) => {
        indigData.current = {
            ...indigData.current,
            [proSeq]: [
                {
                    PRO_SEQ: proSeq,
                    SEQ: 1,
                    STATE_TYPE: '0',
                    ACC_CD: accInfo[0]['ACC_CD'],
                    CST_CD: null,
                    DEBTOR: 0,
                    CREDITOR: 0,
                    SUMUP: '',
                },
                {
                    PRO_SEQ: proSeq,
                    SEQ: 2,
                    STATE_TYPE: '1',
                    ACC_CD: accInfo[0]['ACC_CD'],
                    CST_CD: null,
                    DEBTOR: 0,
                    CREDITOR: 0,
                    SUMUP: '',
                }
            ]
        }
    }, [accInfo]);

    useEffect(() => setFiles(fileData && (openType === enrollType.EDIT || openType === enrollType.REEDIT ) ? [...fileData] : []), [fileData, openType]);

    useEffect(() => {
        const fetchData = async() => {
            proData.current = [];
            forceUpdate();

            const headerData = (await callProc('SLT_EDOCHEADER_COPY', {DOC_CD: docCd})).data[0];
            title.current = headerData['DOC_TITLE'];
            remarkData.current = '';
            proData.current = (await callProc('SLT_EDOCLIST_COPY', {DOC_CD: docCd})).data;
            const lineData = (await callProc('SLT_EDOCLINE_COPY', {DOC_CD: docCd})).data;
            const indigDataList = (await callProc('SLT_EDOCACC_COPY', {DOC_CD: docCd})).data;

            conEmpData.current = lineData.reduce((result, item) => ({
                ...result,
                [item['LINE_TYPE']]: [
                    ...(result[item['LINE_TYPE']] || []),
                    item
                ]
            }), [])[2]; // 확인자 

            actEmpData.current = lineData.reduce((result, item) => ({
                ...result,
                [item['LINE_TYPE']]: [
                    ...(result[item['LINE_TYPE']] || []),
                    item
                ]
            }), [])[3]; // 처리자 

            refEmpData.current = lineData.reduce((result, item) => ({
                ...result,
                [item['LINE_TYPE']]: [
                    ...(result[item['LINE_TYPE']] || []),
                    item
                ]
            }), [])[5]; // 참조자 

            indigData.current = indigDataList.reduce((result, item) => ({
                ...result,
                [item['PRO_SEQ']]: [
                    ...(result[item['PRO_SEQ']] || []),
                    item
                ]
            }), [])

            for (let i = 0; i < proData.current.length; i++) {
                const proSeq = proData.current[i]['SEQ'];
                const proIOType = proData.current[i]['IO_TYPE']
                if (!indigData.current[proSeq]) {
                    initIndigData(proSeq, proIOType);
                }
            }

            forceUpdate();
            
        }
        if(openType === enrollType.COPY || openType === enrollType.EDIT || openType === enrollType.REEDIT){
            fetchData();
        }
    }, [openType, docCd, initIndigData])

    const onDataChange = useCallback((idx, field, value) => {
        const data = proData.current[idx];
        if (field === 'ACC_CD') {
            indigData.current[data['SEQ']][0]['ACC_CD'] = value;
        } else if (field === 'AMOUNT') {
            indigData.current[data['SEQ']][0]['DEBTOR'] = value;
        }

        switch (field) {
            case 'SPLY_AMT':
            case 'AMOUNT':
            case 'OUT_AMOUNT':
                data[field] = Number(value);
                break;
            default:
                data[field] = value;
                break;
        }
    }, []);

    const forceUpdate = () => updateState({});

    const addNewItem = () => {
        proData.current = [{
            SEQ: 1,
            PRO_TYPE: sortedProType[0]['KEY'],
            IO_TYPE: Object.keys(IOType)[0],
            WORK_CD: workInfo[0]['WORK_CD'],
            CST_CD: null,
            GDS_NM: '',
            ACC_CD: accInfo[0]['ACC_CD'],
            CRT_CD: null,
            PRICE: 0,
            QTY: 0,
            SPLY_AMT: 0,
            AMOUNT: 0,
            BANK_CD: null,
            BBK_NO: '',
            OUT_REMARK: '',
            OUT_AMOUNT: 0,
            ISSUE_DATE: null,
            SUMUP: '',
        }];
        initIndigData(1);
        forceUpdate();
    };

    const onInit = () => {
        proData.current = [];
        forceUpdate();
    }

    const addData = (item, indigItem) => {
        proData.current = [...proData.current, item];
        indigData.current = {
            ...indigData.current,
            [item['SEQ']]: indigItem.map(itm => ({ ...itm, PRO_SEQ: item['SEQ'] }))
        }
        forceUpdate();
    }

    const deleteData = (idx) => {
        delete indigData.current[proData.current[idx]['SEQ']];
        proData.current.splice(idx, 1);
        forceUpdate();
    }

    const valid = () => {
        if (proData.current.length <= 0) {
            enqueueSnackbar('생성된 데이터가 없습니다.', { variant: 'error' });
            return false;
        }

        if (title.current === '') {
            enqueueSnackbar('제목을 입력해 주세요.', { variant: 'error' });
            return false;
        }

        if (!conEmpData.current || conEmpData.current.length <= 0) {
            enqueueSnackbar('확인자를 입력해 주세요.', { variant: 'error' });
            return false;
        }

        if (!actEmpData.current || actEmpData.current.length <= 0) {
            enqueueSnackbar('처리자를 입력해 주세요.', { variant: 'error' });
            return false;
        }

        return true;
    }

    const onSave = async () => {
        if (valid()) {
            if(openType === enrollType.COPY || openType === enrollType.NEW ){
                fileKey = null;
            }

            if (fileKey || files.length > 0) {
                fileKey = await uploadFile(files, fileKey);
                if (fileKey.err) return;
            }
            

            const result = await callProc('INS_EDOCHEADER_STATEMENT', {
                DOC_CD_EDIT: openType === (enrollType.EDIT || enrollType.REEDIT) ? docCd : null,
                DOC_TITLE: title.current,
                REMARK: openType === enrollType.EDIT ? remarkData1 : null,
                FILE_KEY: fileKey,
                CON_JSON: conEmpData.current,
                ACT_JSON: actEmpData.current,
                REF_JSON: refEmpData.current,
                PRO_JSON: proData.current,
                INDIG_JSON: Object.values(indigData.current).reduce((result, item) => [...result, ...item], []),
                RECREATE: openType === enrollType.RECREATE,
            });

            if (openType === enrollType.REEDIT) {
                await callProc('INS_EDOC_REMOVE', {
                                DOC_CD: docCd})
            } 

            if (!result.err) {
                title.current = '';
                remarkData.current = '';
                conEmpData.current = null;
                actEmpData.current = null;
                refEmpData.current = null;
                proData.current = [];
                indigData.current = [];
                setFiles([]);
                enqueueSnackbar('저장이 완료되었습니다.');
                onClose();
                refreshGrid();
            }
        }
    }

    const reset = () => {
        title.current = '';
        remarkData.current = '';
        conEmpData.current = null;
        actEmpData.current = null;
        refEmpData.current = null;
        proData.current = [];
        indigData.current = [];
    }

    const excelDown = async(row) => {
        const fields = ['PRO_TYPE', 'IO_TYPE', 'WORK_NM', 'CST_NM', 'WORK_BIZNO', 'GDS_NM', 'ACC_CD', 'CRT_CD', 'PRICE', 'QTY', 'SPLY_AMT', 'AMOUNT', 'BANK_CD', 'BBK_NO', 'IN_PERSON', 'OUT_REMARK', 'OUT_AMOUNT', 'ISSUE_DATE', 'SUMUP']

        downloadWorkbook('전자회계결제.xls', 
        await dataToWorkbook('전자회계결제', {data: row, fields}, { 
            PRO_TYPE: '유형',
            IO_TYPE: '구분',
            WORK_NM: '대상',
            CST_NM: '거래처',
            WORK_BIZNO: '사업자등록번호',
            GDS_NM: '상품',
            ACC_CD: '계정과목',
            CRT_CD: '신용카드',
            PRICE: '단가',
            QTY: '수량',
            SPLY_AMT: '공급가액',
            AMOUNT: '합계금액',
            BANK_CD: '입금 은행',
            BBK_NO: '계좌번호',
            IN_PERSON: '입금인',
            OUT_REMARK: '출금통장표시',
            OUT_AMOUNT: '출금액',
            ISSUE_DATE: '발행일',
            SUMUP: '적요',
        }));
    }


    return (
        (!workInfo || !cstInfo || !accInfo || !cstBankInfo || !bankInfo) ? null :
            <Dialog
                open={openType != null}
                PaperProps={{ className: classes.dialogPaper }}
            >
                <div className={classes.dialogTitle}>
                    <div className={classes.actionButton}>
                        <DialogTitle>전표등록</DialogTitle>
                        <IconButton
                            tooltip='추가'
                            onClick={addNewItem}
                            icon={<PlaylistAddIcon />}
                            disabled={proData.current.length > 0}
                        />
                        <IconButton
                            tooltip='초기화'
                            onClick={onInit}
                            icon={<RefreshIcon />}
                        />
                        <IconButton
                            tooltip='확인'
                            onClick={onSave}
                            icon={<CheckIcon />}
                        />
                        <SumPanel
                            ref={sumPanel}
                            data={proData}
                        />
                    </div>
                    <IconButton
                        tooltip='닫기'
                        onClick={() => {
                            onClose()
                            openType === enrollType.EDIT && reset()
                        }}
                        icon={<CloseIcon />}
                    />
                </div>
                <div style={{ display: 'flex' }}>
                    <TitleTextField
                        titleText={title.current}
                        onDataChange={(value) => title.current = value}
                    />

                    {/* <IconButton
                        style={{ marginRight: 8 }}
                        tooltip='참고사항'
                        onClick={() => setRemarkOpen(true)}
                        icon={<PostAddIcon />}
                    /> */}

                    <IconButton
                        style={{ marginRight: 8 }}
                        tooltip='거래처 등록'
                        icon={<NoteAddIcon />}
                        onClick={() => setCstOpen(true)}
                    />
                    <IconButton
                        tooltip='엑셀 다운로드'
                        icon={<GridOnIcon />}
                        onClick={() => excelDown(proData.current.length > 0 && proData.current)}
                    />
                </div>
                <div>
                    <IconButton
                        className={classes.empButton}
                        tooltip='확인자 추가'
                        onClick={() => setLineOpenType(lineType.CONFIRM)}
                        icon={<GroupAddIcon />}
                    />

                    <TextField
                        className={classes.titleField}
                        style={{ width: 350 }}
                        label='확인자'
                        value={(conEmpData.current && conEmpData.current.reduce((result, item, idx) => result + (idx === 0 ? '' : ', ') + item['EMP_NM'], '')) || ''}
                        onClick={() => setLineOpenType(lineType.CONFIRM)}
                        disabled
                    />

                    <IconButton
                        className={classes.empButton}
                        tooltip='처리자 추가'
                        onClick={() => setLineOpenType(lineType.PROCESS)}
                        icon={<GroupAddIcon />}
                    />

                    <TextField
                        className={classes.titleField}
                        style={{ width: 350 }}
                        label='처리자'
                        value={(actEmpData.current && actEmpData.current.reduce((result, item, idx) => result + (idx === 0 ? '' : ', ') + item['EMP_NM'], '')) || ''}
                        onClick={() => setLineOpenType(lineType.PROCESS)}
                        disabled
                    />

                    <IconButton
                        className={classes.empButton}
                        tooltip='참조자 추가'
                        onClick={() => setLineOpenType(lineType.REFERENCE)}
                        icon={<GroupAddIcon />}
                    />

                    <TextField
                        className={classes.titleField}
                        style={{ width: 400 }}
                        label='참조자'
                        value={(refEmpData.current && refEmpData.current.reduce((result, item, idx) => result + (idx === 0 ? '' : ', ') + item['EMP_NM'], '')) || ''}
                        onClick={() => setLineOpenType(lineType.REFERENCE)}
                        disabled
                    />
                </div>
                <EmpListDialog
                    open={lineOpenType != null}
                    onClose={() => setLineOpenType(null)}
                    onConfirm={items => {
                        switch (lineOpenType) {
                            default:
                            case lineType.CONFIRM:
                                conEmpData.current = items;
                                break;
                            case lineType.PROCESS:
                                actEmpData.current = items;
                                break;
                            case lineType.REFERENCE:
                                refEmpData.current = items;
                                break;
                        }
                        setLineOpenType(null);
                    }}
                />
                <List className={classes.content}>
                    {!proData.current ? <ProgressPanel /> :
                    proData.current.map((item, idx, arr) => (
                        <ListEnroll
                            key={item['SEQ']}
                            data={item}
                            workInfo={workInfo}
                            cstInfo={cstInfo}
                            accInfo={accInfo}
                            cstBankInfo={cstBankInfo}
                            bankInfo={bankInfo}
                            creditInfo={creditInfo}
                            addData={(item) => addData({
                                ...item,
                                SEQ: !arr.length ? 1 : arr[arr.length - 1]['SEQ'] + 1
                            }, indigData.current[item['SEQ']])}
                            deleteData={() => {
                                deleteData(idx);
                                sumPanel.current.forceUpdate();
                            }}
                            onDataChange={(fieldName, value) => onDataChange(idx, fieldName, value)}
                            openIndigDialog={() => setIndigParent(item)}
                            sumPanelUpdate={sumPanel.current && sumPanel.current.forceUpdate}
                        />
                    ))}
                </List>
                <div className={classes.fileContainer}>
                    <DialogTitle>첨부파일</DialogTitle>
                    <div className={classes.fileContent}>
                        {files.map((item, i) => (
                            <div className={classes.fileItem} key={i}>
                                {item.name}
                                <IconButton
                                    tooltip='삭제'
                                    icon={<CloseIcon />}
                                    onClick={() => {
                                        files.splice(files.indexOf(item), 1);
                                        setFiles([...files]);
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                    <FilePicker
                        multiple
                        onChange={newFiles => setFiles([...files, ...newFiles])}
                    />
                </div>
                <IndignationDialog
                    onClose={() => setIndigParent(undefined)}
                    onSave={(item) => {
                        indigData.current = { ...indigData.current, ...item };
                    }}
                    parentData={indigParent}
                    data={indigParent && indigData.current[indigParent['SEQ']]}
                    cstInfo={cstInfo}
                    accInfo={accInfo}
                />
            </Dialog>
    )
}

const titleFieldStyle = makeStyles((theme) => ({
    titleField: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        flex: 1,
    },
}))

const TitleTextField = ({ titleText, onDataChange }) => {
    const classes = titleFieldStyle();
    const [title, setTitle] = useState(titleText);

    useEffect(() => {
        setTitle(titleText);
    }, [titleText])

    return (
        <TextField
            className={classes.titleField}
            label='제목'
            value={title}
            onChange={e => {
                setTitle(e.target.value);
                onDataChange(e.target.value);
            }}
        />
    )
}

const empStyle = makeStyles((theme) => ({
    dialogPaper: {
        width: 400,
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    grid: {
        height: 400,
    },
    actionButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(2),
    },
}))

const EmpListDialog = ({ open, onClose, onConfirm }) => {
    const classes = empStyle();
    const empData = useRef();

    return (
        <Dialog
            open={open}
            PaperProps={{ className: classes.dialogPaper }}
            onClose={onClose}
        >
            <div className={classes.dialogTitle}>
                <DialogTitle>사원 선택</DialogTitle>
                <IconButton
                    tooltip='닫기'
                    onClick={onClose}
                    icon={<CloseIcon />}
                />
            </div>
            <DataGrid
                className={classes.grid}
                selectProc='SLT_EMPINFO_ALL'
                onSelect={rows => {
                    empData.current = rows
                }}
                selectionMode='multi'
                headerLess
                borderLess
            />
            <div className={classes.actionButton}>
                <IconButton
                    tooltip='확인'
                    onClick={() => onConfirm(empData.current)}
                    icon={<CheckIcon />}
                />
            </div>
        </Dialog>
    )
}

const newCstkStyle = makeStyles((theme) => ({
    dialogPaper: {
        width: 400,
        height: 600,
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    actionButton: {
        display: 'flex',
    },
    fieldContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    Field: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1),
    }
}))

const NewCstDialog = ({ open, onClose, workInfo }) => {
    const classes = newCstkStyle();
    const [insData, setInsData] = useFieldInputs({
        CST_KIND: '2',
        CST_NM: '',
        WORK_BIZNO: '',
        CEO: '',
        BIZ_COND: '',
        BIZ_ITEM: '',
        BIZ_EMAIL: '',
    });
    const { enqueueSnackbar } = useSnackbar();

    const valid = () => {
        if (insData['CST_NM'] === '') {
            enqueueSnackbar('거래처명을 입력해주세요.', { variant: 'error' });
            return false;
        }

        return true;
    }

    const onSave = async () => {
        if (valid()) {
            const result = await callProc('INS_CUSTOMERINFO_STATEMENT', insData);

            if (!result.err) {
                enqueueSnackbar('저장이 완료되었습니다.');
                // onClose();
            }
        }
    }

    return (
        <Dialog
            open={open}
            PaperProps={{ className: classes.dialogPaper }}
        >
            <div className={classes.dialogTitle}>
                <div className={classes.actionButton}>
                    <DialogTitle>거래처 등록</DialogTitle>
                    <IconButton
                        tooltip='확인'
                        onClick={onSave}
                        icon={<CheckIcon />}
                    />
                </div>
                <IconButton
                    tooltip='닫기'
                    onClick={() => {
                        onClose();
                    }}
                    icon={<CloseIcon />}
                />
            </div>
            <div className={classes.fieldContainer}>
                <ComboBox
                    className={classes.Field}
                    label='사업장명'
                    data={workInfo}
                    keyField='WORK_CD'
                    valueField='WORK_NM'
                    value={insData['WORK_CD']}
                    onChange={value => setInsData('WORK_CD', value)}
                    fullWidth={false}
                />
                <ComboBox
                    className={classes.Field}
                    label='거래처 종류'
                    data={cstKind}
                    value={insData['CST_KIND']}
                    onChange={value => setInsData('CST_KIND', value)}
                    fullWidth={false}
                />
                <TextField
                    className={classes.Field}
                    label='거래처명'
                    value={insData['CST_NM']}
                    onChange={e => setInsData('CST_NM', e.target.value)}
                />
                <TextField
                    className={classes.Field}
                    label='사업자번호'
                    value={insData['WORK_BIZNO']}
                    onChange={e => setInsData('WORK_BIZNO', e.target.value)}
                />
                <TextField
                    className={classes.Field}
                    label='대표자'
                    value={insData['CEO']}
                    onChange={e => setInsData('CEO', e.target.value)}
                />
                <TextField
                    className={classes.Field}
                    label='업태'
                    value={insData['BIZ_COND']}
                    onChange={e => setInsData('BIZ_COND', e.target.value)}
                />
                <TextField
                    className={classes.Field}
                    label='종목'
                    value={insData['BIZ_ITEM']}
                    onChange={e => setInsData('BIZ_ITEM', e.target.value)}
                />
                <TextField
                    className={classes.Field}
                    label='이메일'
                    value={insData['EMAIL']}
                    onChange={e => setInsData('EMAIL', e.target.value)}
                />
            </div>
        </Dialog>
    )
}

const docConfStyle = makeStyles((theme) => ({
    dialogPaper: {
        width: 400,
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    actionButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(2),
    },
    fieldContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    Field: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1),
    }
}))

const DocConfirmEmpDialog = ({ open, onClose, refreshGrid, empInfo, selectedRow, empDialogLoadType, loginState }) => {
    const classes = docConfStyle();
    const [empCd, setEmpCd] = useState();
    const { enqueueSnackbar } = useSnackbar();

    const valid = () => {
        if(!selectedRow){
            enqueueSnackbar('선택된 문서가 없습니다.', {variant: 'error'});
            return false;
        }

        return true;
    }

    const onSave = async () => {
        if (valid()) {
            let result;
            if (empDialogLoadType === 'confirm') {
                result = await callProc('INS_EDOC_CONFIRM', {
                    DOC_CD: selectedRow['DOC_CD'],
                    CONF_EMP_CD: empCd,
                    EMP_CD: loginState['EMP_CD'],
                });
            }else{
                result = await callProc('INS_EDOC_PROCESS', {
                    DOC_CD: selectedRow['DOC_CD'],
                    CONF_EMP_CD: empCd,
                    EMP_CD: loginState['EMP_CD'],
                });
            }

            if (!result.err) {
                enqueueSnackbar('저장이 완료되었습니다.');
                onClose();
                refreshGrid();
            }
        }
    }

    return(
        <Dialog
            open={open}
            PaperProps={{ className: classes.dialogPaper }}
        >
            <div className={classes.dialogTitle}>
                <DialogTitle>
                    {empDialogLoadType === 'confirm' ? '승인자 등록' : '처리확인자 등록'}
                </DialogTitle>
                <IconButton
                    tooltip='닫기'
                    onClick={() => {
                        onClose();
                    }}
                    icon={<CloseIcon />}
                />
            </div>
            <div className={classes.fieldContainer}>
                <ComboBox
                    className={classes.Field}
                    label='사원명'
                    data={empInfo}
                    keyField='EMP_CD'
                    valueField='EMP_NM'
                    value={empCd}
                    onChange={value => setEmpCd(value)}
                    fullWidth={false}
                />
            </div>
            <div className={classes.actionButton}>
                <IconButton
                    tooltip='확인'
                    onClick={onSave}
                    icon={<CheckIcon />}
                />
            </div>
        </Dialog>
    )
}

const listItemStyle = makeStyles((theme) => ({
    listContainer: {
        backgroundColor: 'lightgrey',
        marginTop: 4,
        marginBottom: 4,
        // paddingTop: 4,
        paddingBottom: 4,
    },
    listItem: {
        display: 'table-footer-group',
    },
    listIcon: {
        display: 'table-cell',
        verticalAlign: 'middle',
    },
    listIconBox: {
        width: '100%',
        display: 'flex',
    },
    fieldContainer: {
        display: 'flex',
        marginTop: 4,
    },
    field: {
        width: 180,
        marginRight: theme.spacing(1),
    },
}))

const ListEnroll = ({ data, workInfo, cstInfo, accInfo, cstBankInfo, bankInfo, creditInfo, addData, deleteData, onDataChange, openIndigDialog, sumPanelUpdate }) => {
    const [insData, setInsData] = useFieldInputs(data);
    const [proTypeData, setProTypeData] = useState(data['PRO_TYPE']);
    const [ioTypData, setIOTypeData] = useState(data['IO_TYPE']);
    const classes = listItemStyle();
    const workCd = useRef();

    const fieldUpdate = useCallback((fieldName, value) => {
        switch (fieldName) {
            case 'IO_TYPE':
                setIOTypeData(value);
                break;
            case 'PRO_TYPE':
                setProTypeData(value);
                break;
            case 'SPLY_AMT':
            case 'AMOUNT':
                setInsData(fieldName, Number(value));
                sumPanelUpdate && sumPanelUpdate();
                break;
            case 'OUT_AMOUNT':
                setInsData(fieldName, Number(value));
                break;
            default:
                setInsData(fieldName, value);
                break;
        }
        onDataChange(fieldName, value);
    }, [onDataChange, setInsData, sumPanelUpdate]);

    const ioTypeDisable = useMemo(() => {
        const result = proTypeData.toString() === '6' || proTypeData.toString() === '7'
        result && fieldUpdate('IO_TYPE', null);
        return result;
    }, [proTypeData, fieldUpdate]);

    const cstInfoDisable = useMemo(() => {
        const result = proTypeData.toString() === '10' &&
        ((ioTypData && ioTypData.toString() === '1') ||
            (ioTypData && ioTypData.toString() === '2') ||
            (ioTypData && ioTypData.toString() === '3') ||
            (ioTypData && ioTypData.toString() === '4'));
        result && fieldUpdate('CST_CD', null);
        return result;
    }, [proTypeData, ioTypData, fieldUpdate]);

    const gdsNmDisable = useMemo(() => {
        const result = (proTypeData.toString() === '8' ||
            proTypeData.toString() === '9' ||
            proTypeData.toString() === '10') &&
        ((ioTypData && ioTypData.toString() === '1') ||
            (ioTypData && ioTypData.toString() === '2') ||
            (ioTypData && ioTypData.toString() === '3') ||
            (ioTypData && ioTypData.toString() === '4'));
        result && fieldUpdate('GDS_NM', '');
        return result;
    }, [proTypeData, ioTypData, fieldUpdate]);

    const creditInfoDisable = useMemo(() => {
        const result = proTypeData.toString() !== '3' && proTypeData.toString() !== '9';
        result && fieldUpdate('CRT_CD', null);
        return result;
    }, [proTypeData, fieldUpdate]);

    const accIconDisable = useMemo(() => {
        const result = proTypeData.toString() !== '10';
        // result && fieldUpdate('ACC_CD', null);
        return result;
    }, [proTypeData]);

    const priceDisable = useMemo(() => {
        const result =(proTypeData.toString() === '8' ||
            proTypeData.toString() === '9' ||
            proTypeData.toString() === '10') &&
        ((ioTypData && ioTypData.toString() === '1') ||
            (ioTypData && ioTypData.toString() === '2') ||
            (ioTypData && ioTypData.toString() === '3') ||
            (ioTypData && ioTypData.toString() === '4'));
        result && fieldUpdate('PRICE', 0);
        return result;
    }, [proTypeData, ioTypData, fieldUpdate]);

    const qtyDisable = useMemo(() => {
        const result = (proTypeData.toString() === '8' ||
            proTypeData.toString() === '9' ||
            proTypeData.toString() === '10') &&
        ((ioTypData && ioTypData.toString() === '1') ||
            (ioTypData && ioTypData.toString() === '2') ||
            (ioTypData && ioTypData.toString() === '3') ||
            (ioTypData && ioTypData.toString() === '4'));
        result && fieldUpdate('QTY', 0);
        return result;
    }, [proTypeData, ioTypData, fieldUpdate]);

    const splyamtDisable = useMemo(() => {
        const result =(proTypeData.toString() === '8' ||
            proTypeData.toString() === '9' ||
            proTypeData.toString() === '10') &&
        ((ioTypData && ioTypData.toString() === '1') ||
            (ioTypData && ioTypData.toString() === '2') ||
            (ioTypData && ioTypData.toString() === '3') ||
            (ioTypData && ioTypData.toString() === '4'));
        result && fieldUpdate('SPLY_AMT', 0);
        return result;
    }, [proTypeData, ioTypData, fieldUpdate]);

    const bankCdDisable = useMemo(() => {
        const result = ((proTypeData.toString() === '1' ||
            proTypeData.toString() === '2' ||
            proTypeData.toString() === '3' ||
            proTypeData.toString() === '4' ||
            proTypeData.toString() === '5' ||
            proTypeData.toString() === '8' ||
            proTypeData.toString() === '9' ||
            proTypeData.toString() === '10') && (
                (ioTypData && ioTypData.toString() === '1') ||
                (ioTypData && ioTypData.toString() === '4'))) ||
            proTypeData.toString() === '6' ||
            proTypeData.toString() === '7';
        result && fieldUpdate('BANK_CD', null);
        return result;
    }, [proTypeData, ioTypData, fieldUpdate]);

    const bbkNoDisable = useMemo(() => {
        const result = ((proTypeData.toString() === '1' ||
            proTypeData.toString() === '2' ||
            proTypeData.toString() === '3' ||
            proTypeData.toString() === '4' ||
            proTypeData.toString() === '5' ||
            proTypeData.toString() === '8' ||
            proTypeData.toString() === '9' ||
            proTypeData.toString() === '10') && (
                (ioTypData && ioTypData.toString() === '1') ||
                (ioTypData && ioTypData.toString() === '4'))) ||
            proTypeData.toString() === '6' ||
            proTypeData.toString() === '7';
        result && fieldUpdate('BBK_NO', '');
        return result;
    }, [proTypeData, ioTypData, fieldUpdate]);

    const inPersonDisable = useMemo(() => {
        const result = ((proTypeData.toString() === '1' ||
            proTypeData.toString() === '2' ||
            proTypeData.toString() === '3' ||
            proTypeData.toString() === '4' ||
            proTypeData.toString() === '5' ||
            proTypeData.toString() === '8' ||
            proTypeData.toString() === '9' ||
            proTypeData.toString() === '10') && (
                (ioTypData && ioTypData.toString() === '1') ||
                (ioTypData && ioTypData.toString() === '4'))) ||
            proTypeData.toString() === '6' ||
            proTypeData.toString() === '7';
        result && fieldUpdate('IN_PERSON', '');
        return result;
    }, [proTypeData, ioTypData, fieldUpdate]);

    const outRemarkDisable = useMemo(() => {
        const result = ((proTypeData.toString() === '1' ||
            proTypeData.toString() === '2' ||
            proTypeData.toString() === '3' ||
            proTypeData.toString() === '4' ||
            proTypeData.toString() === '5' ||
            proTypeData.toString() === '8' ||
            proTypeData.toString() === '9' ||
            proTypeData.toString() === '10') && (
                (ioTypData && ioTypData.toString() === '1') ||
                (ioTypData && ioTypData.toString() === '4'))) ||
            proTypeData.toString() === '6' ||
            proTypeData.toString() === '7';
        result && fieldUpdate('OUT_REMARK', '');
        return result;
    }, [proTypeData, ioTypData, fieldUpdate]);

    const outMoneyDisable = useMemo(() => {
        const result = ((proTypeData.toString() === '1' ||
            proTypeData.toString() === '2' ||
            proTypeData.toString() === '3' ||
            proTypeData.toString() === '4' ||
            proTypeData.toString() === '5' ||
            proTypeData.toString() === '8' ||
            proTypeData.toString() === '9' ||
            proTypeData.toString() === '10') && (
                (ioTypData && ioTypData.toString() === '1'))) ||
            proTypeData.toString() === '6' ||
            proTypeData.toString() === '7';
        result && fieldUpdate('OUT_AMOUNT', 0);
        return result;
    }, [proTypeData, ioTypData, fieldUpdate]);

    const issueDateDisable = useMemo(() => {
        const result = (proTypeData.toString() === '8' ||
            proTypeData.toString() === '10') &&
        ((ioTypData && ioTypData.toString() === '1') ||
            (ioTypData && ioTypData.toString() === '2') ||
            (ioTypData && ioTypData.toString() === '3') ||
            (ioTypData && ioTypData.toString() === '4'));
        result && fieldUpdate('ISSUE_DATE', null);
        return result;
    }, [proTypeData, ioTypData, fieldUpdate]);

    const sumupDisable = useMemo(() => {
        const result = proTypeData.toString() === '10' &&
        ((ioTypData && ioTypData.toString() === '1') ||
            (ioTypData && ioTypData.toString() === '2') ||
            (ioTypData && ioTypData.toString() === '3') ||
            (ioTypData && ioTypData.toString() === '4'));
        result && fieldUpdate('SUMUP', '');
        return result;
    }, [proTypeData, ioTypData, fieldUpdate]);
       
    return (
        <div className={classes.listContainer}>
            <ListItem className={classes.listItem}>
                <ListItemIcon className={classes.listIcon}>
                    <div className={classes.listIconBox}>
                        <IconButton
                            tooltip='복사 행 추가'
                            onClick={() => {
                                addData({
                                    ...insData,
                                    PRO_TYPE: proTypeData,
                                    IO_TYPE: ioTypData,
                                    SPLY_AMT: Number(insData['SPLY_AMT']),
                                    AMOUNT: Number(insData['AMOUNT']),
                                    OUT_AMOUNT: Number(insData['OUT_AMOUNT']),
                                })
                            }}
                            icon={<AddIcon />}
                        />

                        <IconButton
                            tooltip='행 삭제'
                            onClick={deleteData}
                            icon={<RemoveIcon />}
                        />
                    </div>
                </ListItemIcon>
                <div className={classes.fieldContainer}>
                    <ComboBox
                        className={classes.field}
                        style={{ width: 120 }}
                        label='유형'
                        data={sortedProType}
                        keyField='KEY'
                        valueField='VALUE'
                        value={proTypeData}
                        onChange={value => {
                            fieldUpdate('PRO_TYPE', value);
                        }}
                    />
                    <ComboBox
                        className={classes.field}
                        style={{ width: 70 }}
                        label='구분'
                        data={IOType}
                        value={ioTypData}
                        onChange={value => {
                            fieldUpdate('IO_TYPE', value);
                        }}
                        disabled={ioTypeDisable}
                        nullable
                    />
                    <ComboSearch
                        className={classes.field}
                        label='대상'
                        data={workInfo}
                        keyField='WORK_CD'
                        valueField='WORK_NM'
                        value={insData['WORK_CD']}
                        onChange={value => {
                            if (workCd.current != null) {
                                fieldUpdate('CST_CD', null);
                                fieldUpdate('BANK_CD', null);
                                fieldUpdate('BBK_NO', null);
                                fieldUpdate('IN_PERSON', null);
                            }
                            workCd.current = value;
                            fieldUpdate('WORK_CD', value);
                        }}
                    />
                    <ComboSearch
                        className={classes.field}
                        label='거래처'
                        data={cstInfo[workCd.current]}
                        keyField='CST_CD'
                        valueField='CST_NM'
                        value={insData['CST_CD']}
                        onChange={value => {
                            if (((ioTypData && String(ioTypData) === '2') || (ioTypData && String(ioTypData) === '3')) &&
                                value !== insData['CST_CD']) {
                                fieldUpdate('BANK_CD', value != null ? cstBankInfo[workCd.current][value]['BANK_CD'] : null);
                                fieldUpdate('BBK_NO', value != null ? cstBankInfo[workCd.current][value]['BBK_NO'] : null);
                                fieldUpdate('IN_PERSON', value != null ? cstBankInfo[workCd.current][value]['IN_PERSON'] : null);
                            }
                            fieldUpdate('CST_CD', value);
                        }}
                        disabled={cstInfoDisable}
                        nullable
                    />
                    <TextField
                        className={classes.field}
                        style={{ width: 120 }}
                        label='상품'
                        value={insData['GDS_NM']}
                        onChange={e => {
                            fieldUpdate('GDS_NM', e.target.value);
                        }}
                        disabled={gdsNmDisable}
                    />
                    <ComboSearch
                        className={classes.field}
                        style={{width: 220}}
                        label='계정과목'
                        data={accInfo}
                        keyField='ACC_CD'
                        valueField='ACC_NM'
                        value={insData['ACC_CD']}
                        onChange={value => {
                            fieldUpdate('ACC_CD', value);
                        }}
                        nullable
                    />

                    <ComboSearch
                        className={classes.field}
                        label='신용카드'
                        data={cstInfo[workCd.current]}
                        keyField='CST_CD'
                        valueField='CST_NM'
                        value={insData['CRT_CD']}
                        onChange={value => {
                            fieldUpdate('CRT_CD', value);
                        }}
                        disabled={creditInfoDisable}
                        nullable
                    />

                    <IconButton
                        tooltip='분개 등록'
                        onClick={openIndigDialog}
                        icon={<ListAltIcon />}
                        disabled={accIconDisable}
                    />


                </div>
                <div className={classes.fieldContainer}>
                    <NumberField
                        className={classes.field}
                        style={{ width: 80 }}
                        label='단가'
                        value={insData['PRICE']}
                        onChange={value => {
                            fieldUpdate('PRICE', value);
                        }}
                        disabled={priceDisable}
                    />
                    <NumberField
                        className={classes.field}
                        style={{ width: 70 }}
                        label='수량'
                        value={insData['QTY']}
                        onChange={value => {
                            fieldUpdate('QTY', value);
                        }}
                        disabled={qtyDisable}
                    />
                    <NumberField
                        className={classes.field}
                        style={{ width: 100 }}
                        label='공급가액'
                        value={insData['SPLY_AMT']}
                        onChange={value => {
                            fieldUpdate('SPLY_AMT', value);
                        }}
                        onBlur={() => {
                            if (!outMoneyDisable) { 
                                if (String(proTypeData) !== '4' && String(proTypeData) !== '7' && String(proTypeData) !== '11') {
                                    fieldUpdate('OUT_AMOUNT', Math.floor((insData['SPLY_AMT'] * 1.1).toFixed(2)));
                                } else {
                                    fieldUpdate('OUT_AMOUNT', insData['SPLY_AMT']);
                                }
                            }

                            if (String(proTypeData) !== '4' && String(proTypeData) !== '7' && String(proTypeData) !== '11') {
                                fieldUpdate('AMOUNT', Math.floor((insData['SPLY_AMT'] * 1.1).toFixed(2)));
                            } else {
                                fieldUpdate('AMOUNT', insData['SPLY_AMT']);
                            }
                        }}
                        disabled={splyamtDisable}
                    />
                    <NumberField
                        className={classes.field}
                        style={{ width: 100 }}
                        label='합계금액'
                        value={insData['AMOUNT']}
                        onChange={value => {
                            fieldUpdate('AMOUNT', value);
                        }}
                        onBlur={() => {
                            if (!outMoneyDisable) {
                                fieldUpdate('OUT_AMOUNT', insData['AMOUNT']);
                            }
                            if (!splyamtDisable && insData['SPLY_AMT'].toString() === '0') {
                                fieldUpdate('SPLY_AMT', insData['AMOUNT']);
                            }
                        }}
                    />
                    <ComboSearch
                        className={classes.field}
                        style={{ width: 120 }}
                        label='은행'
                        data={bankInfo}
                        keyField='BANK_CD'
                        valueField='BANK_NM'
                        value={insData['BANK_CD']}
                        onChange={value => {
                            fieldUpdate('BANK_CD', value);
                        }}
                        disabled={bankCdDisable}
                        nullable
                    />
                    <TextField
                        className={classes.field}
                        style={{ width: 120 }}
                        label='계좌번호'
                        value={insData['BBK_NO'] || ''}
                        onChange={e => {
                            fieldUpdate('BBK_NO', e.target.value);
                        }}
                        disabled={bbkNoDisable}
                    />
                    <TextField
                        className={classes.field}
                        style={{ width: 120 }}
                        label='입금인'
                        value={insData['IN_PERSON'] || ''}
                        onChange={e => {
                            fieldUpdate('IN_PERSON', e.target.value);
                        }}
                        disabled={inPersonDisable}
                    />
                    <TextField
                        className={classes.field}
                        style={{ width: 180 }}
                        label='출금통장표시'
                        value={insData['OUT_REMARK'] || ''}
                        onChange={e => {
                            fieldUpdate('OUT_REMARK', e.target.value);
                        }}
                        disabled={outRemarkDisable}
                    />
                    <NumberField
                        className={classes.field}
                        style={{ width: 100 }}
                        label='출금액'
                        value={insData['OUT_AMOUNT']}
                        onChange={value => {
                            fieldUpdate('OUT_AMOUNT', value);
                        }}
                        disabled={outMoneyDisable}
                    />
                    <DatePicker
                        className={classes.field}
                        style={{ width: 140 }}
                        label='발행(생)일'
                        value={insData['ISSUE_DATE']}
                        onChange={value => {
                            fieldUpdate('ISSUE_DATE', value);
                        }}
                        disabled={issueDateDisable}
                    />
                    <TextField
                        className={classes.field}
                        style={{ width: 170 }}
                        label='적요'
                        value={insData['SUMUP'] || ''}
                        onChange={e => {
                            fieldUpdate('SUMUP', e.target.value);
                        }}
                        disabled={sumupDisable}
                    />
                </div>
            </ListItem>
        </div>
    )
}

const stateType = {
    0: '차변',
    1: '대변'
}

const indigStyle = makeStyles((theme) => ({
    dialogPaper: {
        maxWidth: 'none',
        width: '60%',
        height: '60%'
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
    },
    actionButton: {
        display: 'flex',
    },
}))

const IndignationDialog = ({ onClose, onSave, parentData, data = [], cstInfo, accInfo }) => {
    const classes = indigStyle();
    const indigData = useRef([]);
    const [, updateState] = useState();
    const sumPanel = useRef(); // 합계

    useEffect(() => {
        if (!parentData) {
            indigData.current = [];
        } else {
            indigData.current = data.map(item => ({ 
                ...item,
                ACC_CD: item['SEQ'] === 1 || item['SEQ'] !== 2 ? item['ACC_CD'] : String(parentData['IO_TYPE']) !== '1' ? '0003' : '0001'
             }));
            forceUpdate();
        }
    }, [data, parentData])

    const forceUpdate = () => updateState({});

    const addData = (item) => {
        indigData.current = ([...indigData.current, item]);
        forceUpdate();
    }

    const deleteData = (idx) => {
        indigData.current.splice(idx, 1);
        forceUpdate();
    }

    return (
        <Dialog
            open={parentData != null}
            PaperProps={{ className: classes.dialogPaper }}
        >
            <div className={classes.dialogTitle}>
                <div className={classes.actionButton}>
                    <DialogTitle>분개 등록</DialogTitle>
                    <IconButton
                        tooltip='확인'
                        onClick={() => {
                            onSave({ [parentData['SEQ']]: indigData.current });
                            onClose();
                        }}
                        icon={<CheckIcon />}
                    />
                    <IndigSumPanel
                        ref={sumPanel}
                        data={indigData}
                    />
                </div>
                <IconButton
                    tooltip='닫기'
                    onClick={onClose}
                    icon={<CloseIcon />}
                />
            </div>
            <List>
                {indigData.current.map((item, idx, arr) => (
                    <IndigListItem
                        key={item['SEQ']}
                        data={item}
                        workCd={parentData && parentData['WORK_CD']}
                        cstInfo={cstInfo}
                        accInfo={accInfo}
                        addData={(item) => addData({
                            ...item,
                            SEQ: !arr.length ? 1 : arr[arr.length - 1]['SEQ'] + 1
                        })}
                        deleteData={() => {
                            deleteData(idx);
                            sumPanel.current.forceUpdate();
                        }}
                        onDataChange={(field, value) => {
                            item[field] = value;
                            sumPanel.current.forceUpdate();
                        }}
                        disabled={idx < 2}
                    />
                ))}
            </List>
        </Dialog>
    )
}

const IndigListItemStyle = makeStyles((theme) => ({
    listContainer: {
        backgroundColor: 'lightgrey',
        marginTop: 4,
        marginBottom: 4,
        // paddingTop: 4,
        paddingBottom: 4,
    },
    listItem: {
        display: 'table-footer-group',
    },
    listIcon: {
        display: 'table-cell',
        verticalAlign: 'middle',
    },
    fieldContainer: {
        display: 'flex',
        marginTop: 4,
    },
    field: {
        width: 180,
        marginRight: theme.spacing(1),
    },
}))

const IndigListItem = ({ data, workCd, cstInfo, accInfo, addData, deleteData, onDataChange, disabled }) => {
    const [insData, setInsData] = useFieldInputs(data);
    const classes = IndigListItemStyle();
    return (
        <div className={classes.listContainer}>
            <ListItem className={classes.listItem}>
                <ListItemIcon className={classes.listIcon}>
                    <IconButton
                        tooltip='복사 행 추가'
                        onClick={() => {
                            addData({
                                ...insData,
                                DEBTOR: Number(insData['DEBTOR']),
                                CREDITOR: Number(insData['CREDITOR']),
                            })
                        }}
                        icon={<AddIcon />}
                    />

                    <IconButton
                        tooltip='행 삭제'
                        onClick={() => deleteData()}
                        icon={<RemoveIcon />}
                        disabled={disabled}
                    />
                </ListItemIcon>
                <div className={classes.fieldContainer}>
                    <ComboBox
                        className={classes.field}
                        style={{ width: 80 }}
                        label='전표구분'
                        data={stateType}
                        value={insData['STATE_TYPE']}
                        onChange={value => {
                            setInsData('STATE_TYPE', value);
                            onDataChange('STATE_TYPE', value);
                        }}
                        disabled={disabled}
                    />
                    <ComboSearch
                        className={classes.field}
                        style={{width: 220}}
                        label='계정과목'
                        data={accInfo}
                        keyField='ACC_CD'
                        valueField='ACC_NM'
                        value={insData['ACC_CD']}
                        onChange={value => {
                            setInsData('ACC_CD', value);
                            onDataChange('ACC_CD', value);
                        }}
                        disabled={disabled}
                    />
                    <ComboSearch
                        className={classes.field}
                        label='거래처'
                        data={cstInfo[workCd]}
                        keyField='CST_CD'
                        valueField='CST_NM'
                        value={insData['CST_CD']}
                        onChange={value => {
                            setInsData('CST_CD', value);
                            onDataChange('CST_CD', value);
                        }}
                        nullable
                    />
                    <NumberField
                        className={classes.field}
                        style={{ width: 120 }}
                        label='차변'
                        value={insData['DEBTOR']}
                        onChange={value => {
                            setInsData('DEBTOR', value);
                            onDataChange('DEBTOR', Number(value));
                        }}
                        disabled={disabled || (insData['STATE_TYPE'] && insData['STATE_TYPE'].toString() === '1')}
                    />
                    <NumberField
                        className={classes.field}
                        style={{ width: 120 }}
                        label='대변'
                        value={insData['CREDITOR']}
                        onChange={value => {
                            setInsData('CREDITOR', value);
                            onDataChange('CREDITOR', Number(value));
                        }}
                        disabled={insData['STATE_TYPE'] && insData['STATE_TYPE'].toString() === '0'}
                    />
                    <TextField
                        className={classes.field}
                        style={{ width: 250 }}
                        label='적요'
                        value={insData['SUMUP'] || ''}
                        onChange={e => {
                            setInsData('SUMUP', e.target.value);
                            onDataChange('SUMUP', e.target.value);
                        }}
                    />
                </div>
            </ListItem>
        </div>
    )
}

const sumPanelStyle = makeStyles((theme) => ({
    sumFiled: {
        marginLeft: theme.spacing(1),
    }
}))

const SumPanel = forwardRef(({ data }, ref) => {
    const [, updateState] = useState();
    const forceUpdate = () => updateState({});
    useImperativeHandle(ref, () => ({
        forceUpdate() { forceUpdate() }
    }));

    const classes = sumPanelStyle();

    return (
        <Fragment>
            <NumberField
                className={classes.sumFiled}
                label='공급가액 합'
                value={data.current != null && data.current.reduce((result, value) => result + value['SPLY_AMT'], 0)}
                disabled
            />
            <NumberField
                className={classes.sumFiled}
                label='합계금액 합'
                value={data.current != null && data.current.reduce((result, value) => result + value['AMOUNT'], 0)}
                disabled
            />
            <NumberField
                className={classes.sumFiled}
                label='출금액 합'
                value={data.current != null && data.current.reduce((result, value) => result + value['OUT_AMOUNT'], 0)}
                disabled
            />
        </Fragment>
    )
})

const IndigSumPanel = forwardRef(({ data }, ref) => {
    const [, updateState] = useState();
    const forceUpdate = () => updateState({});
    useImperativeHandle(ref, () => ({
        forceUpdate() { forceUpdate() }
    }));

    const classes = sumPanelStyle();

    return (
        <Fragment>
            <NumberField
                className={classes.sumFiled}
                label='차변 합'
                value={data.current != null && data.current.reduce((result, value) => result + Number(value['DEBTOR']), 0)}
                disabled
            />
            <NumberField
                className={classes.sumFiled}
                label='대변 합'
                value={data.current != null && data.current.reduce((result, value) => result + Number(value['CREDITOR']), 0)}
                disabled
            />
        </Fragment>
    )
})

export default ElectAccounting;